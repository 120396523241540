<template>
  <div style="padding: 0px !important; position: relative;">
    <div style="position: absolute; right:  2.6rem; z-index: 10; top: -1.5rem;">
      <div class="d-flex justify-end">
        <span v-if="loading" class="success--text loading-text"
          >Menyimpan<span>.</span><span>.</span><span>.</span></span
        >
        <span
          v-if="alertMessage.show"
          :class="alertMessage.isFail ? 'error--text' : 'success--text'"
        >
          Form {{ alertMessage.text.toLowerCase() }}
          {{ alertMessage.isFail ? 'gagal' : 'berhasil' }} disimpan
        </span>
      </div>
    </div>
    <div class="mx-4 mt-2">
      <v-tabs
        grow
        v-model="tabs"
        class="mx-auto"
        style="border-bottom: 2px solid #f5f5f5; width: 95%;"
      >
        <v-tab
          v-for="(tab, index) in tabsResource"
          :key="`tab-${index}`"
          class="text-none"
          >{{ tab?.text }}
          <template v-if="isEMR ? tab.icon === 'mdi-check-circle' : true">
            <v-icon
              v-if="tab.icon"
              :color="generateBadgeColor(tab?.icon, index)"
              class="mb-4"
              small
            >
              {{ tab?.icon }}
            </v-icon>
          </template>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabs" class="scroll">
        <v-tab-item>
          <section>
            <pregnancy-history :isEMR="isEMR" ref="pregnancyHistory" />
          </section>
        </v-tab-item>
        <v-tab-item>
          <section>
            <kb-and-menstrual-history
              :isEMR="isEMR"
              ref="kbAndMenstrualHistory"
            />
          </section>
        </v-tab-item>
        <v-tab-item>
          <section>
            <postpartum-record :isEMR="isEMR" ref="postpartumRecord" />
          </section>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <Snackbar
      :show="snackbar.show"
      :color="snackbar.color"
      :text="snackbar.text"
      :close="
        () => {
          snackbar = {
            show: false,
            color: 'success',
            text: '',
          };
        }
      "
    />

    <v-footer
      style="background-color: white; border-top: 1px solid #e0e0e0;"
      class="d-flex justify-space-between "
    >
      <div class="d-flex mx-6 mt-2">
        <v-btn
          @click="updateTab({ destination: tabs - 1 })"
          :disabled="tabs === 0"
          color="primary"
          class="mr-3 nav-btn"
          depressed
          light
          outlined
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn
          class="nav-btn"
          :disabled="tabs === 2"
          @click="updateTab({ destination: tabs + 1 })"
          color="primary"
          depressed
          light
        >
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </div>
      <v-spacer />
      <v-btn
        v-if="tabs === 2 && !isEMR"
        color="primary"
        depressed
        light
        class="text-capitalize"
        @click="handleSave()"
      >
        {{ isNew ? 'Simpan' : 'Simpan Perubahan' }}
      </v-btn>
    </v-footer>
  </div>
</template>

<script>
import { PregnancyHistory, PostpartumRecord, kbAndMenstrualHistory } from './';
import { Snackbar } from '@/components/SharedComponent';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'LabourAssessment',
);
const { mapActions: staffActions } = createNamespacedHelpers('MasterStaff');

export default {
  name: 'LabourAssessment',
  props: {
    patientData: Object,
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Snackbar,
    PregnancyHistory,
    PostpartumRecord,
    kbAndMenstrualHistory,
  },
  data() {
    return {
      snackbar: {
        show: false,
        color: 'success',
        text: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getForm',
      'getIsNew',
      'getLoading',
      'getLoginData',
      'getLabourTab',
      'getLabourIsChanged',
      'getLabourTabsResource',
      'getShowSuccessMessage',
    ]),
    form: {
      get() {
        return this.getForm;
      },
    },
    tabs: {
      get() {
        return this.getLabourTab;
      },
      set(value) {
        this.updateTab({ destination: value });
      },
    },
    isNew: {
      get() {
        return this.getIsNew;
      },
    },
    loading: {
      get() {
        return this.getLoading;
      },
    },
    tabsResource: {
      get() {
        return this.getLabourTabsResource;
      },
    },
    isChanged: {
      get() {
        return this.getLabourIsChanged;
      },
    },
    alertMessage: {
      get() {
        return this.getShowSuccessMessage;
      },
    },
  },
  watch: {
    tabs: {
      handler(destination, origin) {
        this.formValidation({ destination, origin });
        if (
          this.form[this.tabsResource[destination].key]?.timestamps !==
          undefined
        ) {
          setTimeout(() => {
            this.handleValidation(destination);
          }, 400);
        } else {
          setTimeout(() => {
            this.handleValidation(origin);
          }, 400);
        }
        if (this.isChanged) {
          this.handlePostLabourAssessment(origin);
          this.updateTab({ destination, origin });
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.setPatientData({
      id_inpatient: this.patientData.id_inpatient,
      id_emr: this.patientData.id_emr,
    });
    this.handleGetAllStaff();
    this.handleGetLabourAssessment();
  },
  methods: {
    ...mapMutations([
      'setTab',
      'clearForm',
      'setPatientData',
      'setDefaultValue',
      'resetTimeAutofill',
    ]),
    ...mapActions([
      'formValidation',
      'resolveGetLabourAssessment',
      'resolvePostLabourAssessment',
    ]),
    ...staffActions(['resolveGetAllStaff']),
    updateTab(value) {
      this.setTab(value);
    },
    handleGetAllStaff() {
      this.resolveGetAllStaff({
        halaman: 1,
        itemCount: -1,
        sort: '',
        keyword: '',
      }).then(() => {
        if (this.form.pregnancyHistory.timestamps === undefined) {
          this.setDefaultValue({
            staff: this.getLoginData.role.some(
              item =>
                item.toLowerCase() === 'perawat' ||
                item.toLowerCase() === 'bidan',
            )
              ? this.getLoginData.id
              : '',
          });
        }
      });
    },
    handlePostLabourAssessment(index) {
      this.resolvePostLabourAssessment(index);
    },
    handleSave() {
      this.resolvePostLabourAssessment(2).then(() => {
        let snackbarTimeoutId = null;
        this.snackbar = {
          show: true,
          text: `Formulir Assesmen Persalinan berhasil ${
            this.isNew ? 'disimpan' : 'diubah'
          }`,
          color: 'success',
        };

        if (snackbarTimeoutId) {
          clearTimeout(snackbarTimeoutId);
        }

        snackbarTimeoutId = setTimeout(() => {
          this.snackbar = {
            show: false,
            text: '',
            color: 'success',
          };
          snackbarTimeoutId = null;
        }, 4000);
      });
    },
    handleValidation(index) {
      if (index === 0) {
        this?.$refs?.pregnancyHistory?.$refs?.form?.validate();
      }
      if (index === 1) {
        this?.$refs?.kbAndMenstrualHistory?.$refs?.form?.validate();
      }
      if (index === 2) {
        this?.$refs?.postpartumRecord?.$refs?.form?.validate();
      }
    },
    generateBadgeColor(icon, index) {
      let color;

      if (icon === 'mdi-alert-circle') {
        color = 'error';
      }
      if (icon === 'mdi-check-circle' && this.tabs === index) {
        color = 'success';
      }
      if (icon === 'mdi-check-circle' && this.tabs !== index) {
        color = '#9ca2a5';
      }

      return color || '';
    },
    handleGetLabourAssessment(id) {
      this.resolveGetLabourAssessment(id).then(() => {
        if (
          !this.form.kbAndMenstrualHistory.timestamps &&
          !this.form.noteAndPostpartum.timestamps &&
          !this.form.pregnancyHistory.timestamps &&
          this.isEMR
        ) {
          this.resetTimeAutofill();
        }
        if (
          !this.isEMR &&
          this.form.pregnancyHistory.timestamps !== undefined
        ) {
          setTimeout(() => {
            this.handleValidation(0);
          }, 400);
        }
      });
    },
  },
  beforeDestroy() {
    this.clearForm();
  },
};
</script>

<style lang="scss" scoped>
section {
  display: block !important;
  margin: 0 1.7rem;
}
.scroll {
  height: 72vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px !important;
    border-radius: 5px;
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px !important;
    border-radius: 5px;
    background-color: #9e9e9e !important;
  }
}
</style>
