import Constant from '@/const';
import axios from 'axios';

const getPrognosa = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/master/prognosis'),
    );
    return response;
  } catch (error) {
    const err = error;
    return err;
  }
};

export default getPrognosa;
