<template>
  <v-container class="pt-5 px-14" fluid>
    <h4>MATA</h4>
    <v-row>
      <v-col cols="6">
        <skeleton :isLoading="isLoading">
          <label>Tanda-Tanda Infeksi / Sekret Puluren</label>
        </skeleton>
        <v-radio-group
          v-model="form.eyes_infection"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada" :value="true"></v-radio>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak" :value="false"></v-radio>
          </skeleton>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-divider class="my-10" />
    <h4>
      MULUT
    </h4>
    <v-row>
      <v-col>
        <skeleton :isLoading="isLoading">
          <label>Reflek Hisap</label>
        </skeleton>
        <v-radio-group
          v-model="form.mouth.suction_reflex"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada" :value="true"></v-radio> </skeleton
          ><skeleton :isLoading="isLoading">
            <v-radio light label="Tidak" :value="false"></v-radio>
          </skeleton>
        </v-radio-group>
        <skeleton :isLoading="isLoading">
          <label>Bibir Sianosis</label>
        </skeleton>
        <v-radio-group
          v-model="form.mouth.cyanotic_lips"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada" :value="true"></v-radio> </skeleton
          ><skeleton :isLoading="isLoading">
            <v-radio light label="Tidak" :value="false"></v-radio>
          </skeleton>
        </v-radio-group>
      </v-col>
      <v-col>
        <skeleton :isLoading="isLoading">
          <label>Bibir Sumbing</label>
        </skeleton>
        <v-radio-group
          v-model="form.mouth.harelip"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada" :value="true"></v-radio> </skeleton
          ><skeleton :isLoading="isLoading">
            <v-radio light label="Tidak" :value="false"></v-radio>
          </skeleton>
        </v-radio-group>
        <skeleton :isLoading="isLoading"> <label>Palatosichis</label></skeleton>
        <v-radio-group
          v-model="form.mouth.palatosic"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada" :value="true"></v-radio>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak" :value="false"></v-radio>
          </skeleton>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-divider class="my-10" />
    <h4>
      ANGGOTA GERAK
    </h4>
    <v-row>
      <v-col>
        <skeleton :isLoading="isLoading"> <label>Gerakan</label></skeleton>
        <v-radio-group
          v-model="form.limb.movement"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Normal" :value="true"></v-radio
          ></skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak Normal" :value="false"></v-radio>
          </skeleton>
        </v-radio-group>
        <skeleton :isLoading="isLoading">
          <label>Polidactili</label>
        </skeleton>
        <v-radio-group
          v-model="form.limb.polydactyly"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada" :value="true"></v-radio
          ></skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak" :value="false"></v-radio
          ></skeleton>
        </v-radio-group>
      </v-col>
      <v-col>
        <skeleton :isLoading="isLoading"> <label>Sindactili</label></skeleton>
        <v-radio-group
          v-model="form.limb.syndactyly"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada" :value="true"></v-radio
          ></skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak" :value="false"></v-radio
          ></skeleton>
        </v-radio-group>
        <skeleton :isLoading="isLoading">
          <label>Kelainan Lainnya</label>
        </skeleton>
        <div class="d-flex">
          <skeleton :isLoading="isLoading">
            <v-text-field
              dense
              single-line
              light
              :readonly="isDisableInput"
              v-model="form.limb.other_abnormalities.description"
              class="mr-5"
              placeholder="Masukkan kelainan lainnya"
          /></skeleton>
          <v-radio-group
            v-model="form.limb.other_abnormalities.check"
            :disabled="!form?.limb?.other_abnormalities?.description"
            :readonly="isDisableInput"
            dense
            light
            row
            class="mt-2"
          >
            <skeleton class="mr-3" :isLoading="isLoading">
              <v-radio light label="Ada" :value="true"></v-radio
            ></skeleton>
            <skeleton :isLoading="isLoading">
              <v-radio light label="Tidak" :value="false"></v-radio
            ></skeleton>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-10" />
    <h4>
      DADA
    </h4>
    <v-row>
      <v-col>
        <skeleton :isLoading="isLoading">
          <label>Frekuensi Nafas (normal 30-60 x/menit)</label></skeleton
        >
        <v-radio-group
          v-model="form.chest.respiratory_frequency.check"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Normal" :value="true"></v-radio
          ></skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak Normal" :value="false"></v-radio
          ></skeleton>
        </v-radio-group>
        <skeleton :isLoading="isLoading">
          <v-text-field
            dense
            single-line
            light
            v-if="form.chest.respiratory_frequency.check"
            :readonly="isDisableInput"
            v-model="form.chest.respiratory_frequency.value"
            class="mr-5"
            placeholder="00"
            type="number"
            suffix="x/menit"
        /></skeleton>
      </v-col>
      <v-col>
        <skeleton :isLoading="isLoading">
          <label>Denyut Jantung (normal 100-16 x/menit)</label></skeleton
        >
        <v-radio-group
          :readonly="isDisableInput"
          v-model="form.chest.heart_rate.check"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Normal" :value="true"></v-radio
          ></skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak Normal" :value="false"></v-radio
          ></skeleton>
        </v-radio-group>
        <skeleton :isLoading="isLoading">
          <v-text-field
            dense
            single-line
            light
            v-if="form.chest.heart_rate.check"
            :readonly="isDisableInput"
            v-model="form.chest.heart_rate.value"
            class="mr-5"
            type="number"
            placeholder="00"
            suffix="x/menit"
        /></skeleton>
      </v-col>
    </v-row>
    <v-divider class="my-10" />
    <h4>
      PERUT
    </h4>
    <v-row>
      <v-col>
        <skeleton :isLoading="isLoading">
          <label>Pendarahan Tali Pusat</label></skeleton
        >
        <v-radio-group
          v-model="form.belly.umbilical_cord_bleeding"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada" :value="true"></v-radio
          ></skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak" :value="false"></v-radio
          ></skeleton>
        </v-radio-group>
      </v-col>
      <v-col>
        <skeleton :isLoading="isLoading">
          <label
            >Penonjolan Sekitar Tali Pusat Pada Saat Menangis</label
          ></skeleton
        >
        <v-radio-group
          v-model="form.belly.protrusion_around_the_umbilical_cord"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada" :value="true"></v-radio
          ></skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak" :value="false"></v-radio
          ></skeleton>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-divider class="my-10" />
    <h4>
      KELAMIN
    </h4>
    <v-row v-if="gender.toLowerCase() === 'laki-laki'">
      <v-col>
        <skeleton :isLoading="isLoading"> <label>Skrotum</label></skeleton>
        <v-radio-group
          v-model="form.sex.boy.scrotum"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada, Sudah Turun" :value="true"></v-radio
          ></skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak, Belum Turun" :value="false"></v-radio
          ></skeleton>
        </v-radio-group>
        <skeleton :isLoading="isLoading"> <label>Testis</label></skeleton>
        <v-radio-group
          v-model="form.sex.boy.testicles"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada, Sudah Turun" :value="true"></v-radio
          ></skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak, Belum Turun" :value="false"></v-radio
          ></skeleton>
        </v-radio-group>
      </v-col>
      <v-col>
        <skeleton :isLoading="isLoading"> <label>Lubang Penis</label></skeleton>
        <v-radio-group
          v-model="form.sex.boy.penis_hole"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada" :value="true"></v-radio
          ></skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak" :value="false"></v-radio
          ></skeleton>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <skeleton :isLoading="isLoading">
          <label>Lubang Vagina</label></skeleton
        >
        <v-radio-group
          v-model="form.sex.girl.vagina_hole"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada" :value="true"></v-radio
          ></skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak" :value="false"></v-radio
          ></skeleton>
        </v-radio-group>
      </v-col>
      <v-col>
        <skeleton :isLoading="isLoading">
          <label>Lubang Uretra</label></skeleton
        >
        <v-radio-group
          v-model="form.sex.girl.uretra_hole"
          :readonly="isDisableInput"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada" :value="true"></v-radio
          ></skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak" :value="false"></v-radio
          ></skeleton>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-divider class="my-10" />
    <h4>
      ANUS
    </h4>
    <v-row>
      <v-col cols="6">
        <skeleton :isLoading="isLoading"> <label>Berlubang</label></skeleton>
        <v-radio-group
          :readonly="isDisableInput"
          v-model="form.anus_hole"
          dense
          light
          row
          class="mt-2"
        >
          <skeleton class="mr-3" :isLoading="isLoading">
            <v-radio light label="Ada" :value="true"></v-radio
          ></skeleton>
          <skeleton :isLoading="isLoading">
            <v-radio light label="Tidak" :value="false"></v-radio
          ></skeleton>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-divider class="my-10" />
    <h4>
      KELAINAN
    </h4>
    <v-row>
      <v-col cols="6">
        <skeleton :isLoading="isLoading">
          <label>Kelainan Bayi</label></skeleton
        >
        <skeleton :isLoading="isLoading">
          <v-textarea
            light
            dense
            single-line
            :readonly="isDisableInput"
            v-model="form.abnormalities"
            placeholder="Masukkan deskripsi"
            no-resize
            outlined
        /></skeleton>
      </v-col>
    </v-row>
    <v-divider class="my-10" />
    <h4>
      CATATAN
    </h4>
    <v-row>
      <v-col>
        <skeleton :isLoading="isLoading">
          <label>Catatan Tambahan</label></skeleton
        >
        <skeleton :isLoading="isLoading">
          <v-textarea
            light
            dense
            single-line
            :readonly="isDisableInput"
            v-model="form.notes.postscript"
            placeholder="Masukkan deskripsi"
            no-resize
            outlined
        /></skeleton>
      </v-col>
      <v-col>
        <skeleton :isLoading="isLoading">
          <label>Catatan Bawaan</label></skeleton
        >
        <skeleton :isLoading="isLoading">
          <v-textarea
            light
            dense
            single-line
            :readonly="isDisableInput"
            v-model="form.notes.built_in_note"
            placeholder="Masukkan deskripsi"
            no-resize
            outlined
        /></skeleton>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Skeleton from '../../../../../SharedComponent/Skeleton.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('NeonatusAssessment');

export default {
  name: 'PhysicalNeonatus',
  components: {
    Skeleton,
  },
  props: {
    gender: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    'form.limb.other_abnormalities.description'(val) {
      if (!val) {
        this.form.limb.other_abnormalities.check = null;
      }
    },
  },
  computed: {
    ...mapGetters(['getForm']),
    form: {
      get() {
        return this.getForm.physical;
      },
    },
    isDisableInput: {
      get() {
        return this.isEMR;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  text-align: start;
  padding: 0 0.75vw;

  .date-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1.5vw;
  }
}

h4 {
  font-weight: 400;
  color: #137bc0;
  text-align: start;
  margin-bottom: 1.5vw;
}

label {
  font-weight: 500;
  font-size: 0.8vw;
}
</style>
