import Swal from 'sweetalert2';

const ModalConfirmation = ({
  title = '',
  icon = 'question',
  desc = '',
  showCancelButton = true,
  confirmButton = 'Yakin',
  cancelButton = 'Kembali',
  confirmStyle = 'button-confirm',
  cancelStyle = 'button-cancel',
  reverseButtons = true,
} = {}) => {
  let iconHtml;

  switch (icon) {
    case 'question':
      iconHtml = '<span class="mdi mdi-help-circle-outline"></span>';
      break;
    case 'danger':
      iconHtml =
        '<span style="color: red; border-color: #EB4747;" class="mdi mdi-close"></span>';
      break;
    case 'exclamation':
      iconHtml =
        '<span style="color: #F2994A; border-color: #EB4747;" class="mdi mdi-exclamation"></span>';
      break;
    default:
      iconHtml = '<span class="mdi mdi-help-circle-outline"></span>';
      break;
  }

  return Swal.fire({
    title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px; font-style: normal; text-transform: none !important;">${title}</div>`,
    iconHtml: iconHtml,
    html: `<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;">${desc}</div>`,
    showCancelButton: showCancelButton,
    reverseButtons: reverseButtons,
    customClass: {
      confirmButton: confirmStyle,
      cancelButton: cancelStyle,
    },
    confirmButtonText: `${confirmButton}`,
    cancelButtonText: `${cancelButton}`,
  });
};
export default ModalConfirmation;
