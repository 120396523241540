import Constant from '@/const';
import axios from 'axios';

const updatePatientPharmacy = async ({ payload, id }) => {
  try {
    const response = await axios.put(
      Constant.apiUrl.concat(`/pharmacy/${id}`),
      payload,
    );
    return response;
  } catch (error) {
    const variableError = error;
    throw variableError;
  }
};

export default updatePatientPharmacy;
