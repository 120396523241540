import Constant from '@/const';
import axios from 'axios';

const getDrugsKfaData = async params => {
  try {
    const response = await axios.get(Constant.apiUrl.concat('/ihs/kfa/drugs'), {
      params,
    });
    return response;
  } catch (error) {
    const err = error;
    return err;
  }
};

export default getDrugsKfaData;
