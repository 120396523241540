<template>
  <v-card class="pa-3">
    <v-form ref="form">
      <v-row class="px-15 mx-10 mt-0 mb-16 scrollbar">
        <v-col>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Nomor Surat</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ numberLetter }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Nama Pasien</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patient.name }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">No RM</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.rmNumber }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Tanggal Lahir</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patient.birthDate }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Jenis Kelamin</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patient.gender }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Alamat</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patient.address }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Asal Klinik</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.polyName }}</label>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold required">Tipe Penjamin</label>
            </v-col>
            <v-col cols="7">
              <v-select
                :readonly="!isEditable"
                append-icon="mdi-chevron-down"
                :rules="[v => !!v || 'Wajib diisi']"
                dense
                class="pt-0 mt-0"
                placeholder="Pilih Tipe"
                :items="resource.guarantorType"
                v-model="input.insurance_type"
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold required">Jenis Kasus</label>
            </v-col>
            <v-col cols="7">
              <v-autocomplete
                :readonly="!isEditable"
                append-icon="mdi-chevron-down"
                :rules="[v => !!v || 'Wajib diisi']"
                dense
                :clearable="isEditable"
                auto-select-first
                class="pt-0 mt-0"
                placeholder="Pilih Jenis"
                :items="resource.caseType"
                v-model="input.case_type"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row v-for="(_, index) in input.dpjp" :key="'dpjp-' + index">
            <v-col cols="5" class="text-left">
              <label
                class="font-weight-bold "
                :class="index === 0 ? 'required' : ''"
                >Nama DPJP {{ index + 1 }}
                {{ index === 0 ? '(Utama)' : '' }}</label
              >
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :readonly="!isEditable"
                append-icon="mdi-chevron-down"
                dense
                :clearable="isEditable"
                auto-select-first
                class="pt-0 mt-0"
                :placeholder="'Nama DPJP ' + (index + 1)"
                :rules="index === 0 ? [v => !!v > 0 || 'Wajib diisi'] : []"
                v-model="input.dpjp[index]"
                item-text="name"
                item-value="name"
                :items="resource.doctor"
              ></v-autocomplete>
            </v-col>
            <v-col cols="1" class="d-flex">
              <v-btn v-if="isEditable" icon @click="addItem('doctor', index)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                v-if="isEditable && index !== 0"
                icon
                @click="deleteItem('doctor', index)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Jenis Pelayanan</label>
            </v-col>
            <v-col cols="6">
              <v-select
                :readonly="!isEditable"
                append-icon="mdi-chevron-down"
                class="pt-0"
                placeholder="Pilih jenis pelayanan"
                hide-details="false"
                dense
                :items="resource.serviceType"
                v-model="input.service_type"
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            v-for="(diagnose, index) in input.diagnosis_in"
            :key="'diagnose-' + index"
          >
            <v-col cols="5" class="text-left">
              <label
                :class="`font-weight-bold ${index === 0 ? 'required' : ''}`"
                >Diagnosa Masuk {{ index + 1 }}</label
              >
            </v-col>
            <v-col cols="6">
              <v-combobox
                :readonly="!isEditable"
                append-icon="mdi-chevron-down"
                dense
                :placeholder="'Pilih Diagnosa Masuk ' + (index + 1)"
                :items="resource.diagnose"
                :loading="diagnoseLoading"
                item-text="text"
                v-model="input.diagnosis_in[index]"
                @keyup="handleSearchDiagnose"
                class="pt-0 mt-0"
                :rules="index === 0 ? [v => !!v > 0 || 'Wajib diisi'] : []"
                :clearable="isEditable"
              ></v-combobox>
            </v-col>
            <v-col cols="1" class="d-flex">
              <v-btn v-if="isEditable" icon @click="addItem('diagnose', index)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                v-if="isEditable && index !== 0"
                icon
                @click="deleteItem('diagnose', index)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold required"
                >Tanggal Rencana Rawat Inap</label
              >
            </v-col>
            <v-col cols="6">
              <Datepicker
                :readonly="!isEditable"
                :show="datePicker"
                :date="plan_date"
                type="input"
                :min="today"
                :outlined="false"
                @date-change="handleDateChange"
              />
            </v-col>
          </v-row>
          <v-row align="start">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Catatan</label>
            </v-col>
            <v-col cols="6">
              <v-textarea
                :readonly="!isEditable"
                v-model="input.note"
                height="120"
                outlined
                placeholder="Masukkan catatan"
                :maxLength="3000"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="pa-0">
      <v-btn
        class="text-none"
        v-if="!isDetail"
        plain
        @click="$emit('close-form')"
      >
        Kembali
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        tile
        outlined
        color="primary"
        class="text-none rounded"
        @click="printInpatientLetter()"
        :loading="socialLoading"
        :disabled="!isPrintable"
      >
        <font-awesome-icon icon="print" class="mr-2" />
        Print
      </v-btn>
      <v-btn
        v-if="isEditable"
        color="primary"
        tile
        class="text-none rounded"
        depressed
        @click="addInpatientLetter()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
// utils
import moment from 'moment-timezone';
import Constant from '@/const';
import Swal from 'sweetalert2';
import 'jspdf-autotable';
import { scrollToEmptyInput } from '@/helper';
const axios = require('axios');
const _ = require('lodash');

// mixins
import AlertMixin from '@/mixin/alertMixin';
import Letter from '@/mixin/Letter';
import jwtMixin from '@/mixin/jwtMixin';
import printMixin from '@/mixin/printMixin';
import formatMixin from '@/mixin/formatMixin';

// components
import Datepicker from '@/components/SharedComponent/Datepicker/Datepicker.vue';

// api
import { getRegistrationById } from '@/fetchApi/patient';
import { getAllDiagnose } from '@/fetchApi/MasterData/Diagnose';

export default {
  name: 'InpatientWarrant',
  mixins: [AlertMixin, jwtMixin, printMixin, Letter, formatMixin],
  components: {
    Datepicker,
  },
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    isDetail: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      datePicker: false,
      isPrintable: false,
      clientData: this.$store.getters.userLoggedIn.clinic,
      doctorSip: '',
      plan_date: moment().format('YYYY-MM-DD'),
      resource: {
        guarantorType: ['Umum', 'BPJS', 'Asuransi'],
        caseType: [
          'Bedah',
          'Non Bedah',
          'Neonatus',
          'Trauma',
          'Non- trauma',
          'Obsgyn',
          'Interna',
          'Anak',
          'Syaraf',
          'Jantung',
        ],
        serviceType: ['Preventif', 'Kuratif', 'Rehabilitatif', 'Paliatif'],
        diagnose: [],
        doctor: [],
        // rooms: [],
      },
      input: {
        insurance_type: '',
        case_type: '',
        ward: 'Umum',
        dpjp: [''],
        diagnosis_in: [''],
        service_type: '',
        note: '',
        plan_date: this.plan_date,
      },
      patient: {
        name: '',
        birthDate: '',
        gender: '',
        address: '',
      },
      isRegistered: false,
      diagnoseLoading: false,
      socialLoading: false,
      searchDiagnose: [],
      timestamps: new Date(),
      numberLetter: '',
    };
  },
  computed: {
    today: {
      get() {
        return moment().format('YYYY-MM-DD');
      },
    },
    isPhysiotherapy() {
      return (
        this.patientData?.meta?.polyName?.toLowerCase() === 'poli fisioterapi'
      );
    },
    isEditable: {
      get() {
        return !this.isRegistered && !this.isDetail;
      },
    },
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        this.getDoctor();
        this.getInpatientLetter();
        this.getSocialData();
      });
      this.getDiagnose('');
    });
  },
  watch: {
    searchDiagnose(val) {
      const oldValues = this.input.diagnosis_in.map(diagnosa => {
        return diagnosa;
      });
      const index = val.findIndex((v, i) => {
        return v !== oldValues[i];
      });
      this.diagnoseLoading = true;
      this.getDiagnose(val[index]);

      this.getDiagnose('');
    },
  },
  methods: {
    handleSearchDiagnose: _.debounce(function(event) {
      this.getDiagnose(event.target.value);
    }, 600),
    handleDateChange(updatedDate) {
      this.plan_date = updatedDate;
    },
    async getSocialData() {
      try {
        this.socialLoading = true;
        const response = await getRegistrationById(this.patientData.rmNumber);
        const address = {
          text: response.address.text,
          sub_district: response.address.sub_district.name,
          district: response.address.district.name,
          city: response.address.city.name,
          province: response.address.province.name,
        };
        this.patient = {
          name: response.name,
          birthDate: moment(response.birth.date, 'YYYY-MM-DD').format(
            'DD MMMM YYYY',
          ),
          gender: response.gender ? 'Laki-laki' : 'Perempuan',
          address: this.formatAddress(address),
        };
      } catch (error) {
        this.showErrorAxios(error);
      } finally {
        this.socialLoading = false;
      }
    },
    async getDiagnose(keyword) {
      try {
        this.diagnoseLoading = true;
        const params = {
          search: keyword || '',
          sort: '-code',
          itemCount: 10,
          page: 1,
        };

        const response = await getAllDiagnose(params);
        this.resource.diagnose = response.data.map(diagnose => {
          return {
            id: diagnose._id.toString(),
            code: diagnose.code,
            text: `${diagnose.code} ${diagnose.description}`,
          };
        });
      } catch {
        this.resource.diagnose = [];
      } finally {
        this.diagnoseLoading = false;
      }
    },
    addItem(type, index) {
      switch (type) {
        case 'doctor':
          this.input.dpjp.splice(index + 1, 0, '');
          break;
        case 'diagnose':
          this.input.diagnosis_in.splice(index + 1, 0, '');
          break;
      }
    },
    deleteItem(type, index) {
      switch (type) {
        case 'diagnose':
          this.input.diagnosis_in.splice(index, 1);
          break;
        case 'doctor':
          this.input.dpjp.splice(index, 1);
          break;
      }
    },
    async getDoctor() {
      const data = await axios
        .get(
          Constant.apiUrl.concat(
            `/master/staff?role[]=Dokter&role[]=Bidan&role[]=dokter&role[]=bidan${
              this.isPhysiotherapy
                ? '&role[]=fisioterapis&role[]=Fisioterapis'
                : ''
            }`,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.resource.doctor = data.map(doctor => {
              return { name: doctor.detail.name, id: doctor._id };
            });
          }
        });
      return data;
    },
    async addInpatientLetter() {
      if (await this.$refs.form.validate()) {
        const payload = {
          id_registration: this.patientData.id_registration,
          letter_number: this.numberLetter,
          detail: {
            ...this.input,
            plan_date: this.plan_date,
            dpjp: this.input.dpjp.filter(x => x),
            diagnosis_in: this.input.diagnosis_in
              .filter(x => x)
              .map(arr => {
                return arr.id;
              }),
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          },
        };

        axios
          .post(
            Constant.apiUrl.concat('/letter/control-inpatient-letter'),
            payload,
          )
          .then(() => {
            Swal.fire(
              'Surat Keterangan Rawat Inap Berhasil Dibuat',
              '',
              'success',
            );
            this.getInpatientLetter();
          })
          .catch(error => {
            this.showErrorAxios(error);
          });
      } else {
        scrollToEmptyInput();
      }
    },
    printInpatientLetter() {
      const doc = this.generateInpatientLetter(
        {
          ...this.patientData,
          meta: {
            ...this.patientData.meta,
            ...this.patient,
          },
          numberLetter: this.numberLetter,
          timestamps: this.timestamps,
        },
        this.input,
      );
      this.printDocument(doc, {
        docName: 'Surat Perintah Rawat Inap',
        rmNumber: this.patientData.rmNumber,
      });
    },
    generateNumber() {
      axios
        .get(
          Constant.apiUrl.concat(`/letter/control-inpatient-letter/generate/`),
        )
        .then(response => {
          this.numberLetter = response.data.data;
        });
    },
    async getDataAssessment() {
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/patient/checkup/regist/${this.patientData.id_registration}/service`,
          ),
        );
        const { data } = response.data;
        let diagnose;
        if (data.assesment?.main_diagnose) {
          diagnose = [
            {
              _id: data.assesment?.main_diagnose?.diagnose._id,
              description: `${data.assesment?.main_diagnose?.diagnose.code} ${data.assesment?.main_diagnose?.diagnose.description}`,
            },
          ];
          this.remapDiagnose(diagnose);
        }
      } catch {
        this.input = {
          ...this.input,
        };
      }
    },
    remapDiagnose(diagnose) {
      let diagnosis_in = [''];
      diagnosis_in = diagnose.map(v => {
        return {
          id: v._id,
          text: v.description,
        };
      });
      this.input = {
        ...this.input,
        diagnosis_in,
      };
    },
    getInpatientLetter() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/control-inpatient-letter/registration/${this.patientData.id_registration}`,
          ),
        )
        .then(response => {
          const inpatient = response.data.data;
          this.isRegistered = _.get(inpatient, 'isRegistered', false);
          this.plan_date = _.get(inpatient, 'detail.plan_date', '');
          this.input = {
            insurance_type: _.get(inpatient, 'detail.insurance_type', ''),
            case_type: _.get(inpatient, 'detail.case_type', ''),
            dpjp: _.get(inpatient, 'detail.dpjp', ['']).filter(x => x !== null),
            service_type: _.get(inpatient, 'detail.service_type', ''),
            note: _.get(inpatient, 'detail.note', ''),
            plan_date: _.get(inpatient, 'detail.plan_date', ''),
            sip: _.get(inpatient, 'detail.sip', ''),
          };
          this.timestamps = _.get(
            inpatient,
            'detail.timestamps.created_at',
            new Date(),
          );
          this.numberLetter = inpatient.letter_number;
          this.remapDiagnose(
            inpatient.detail.diagnosis_in.map(item => {
              return {
                _id: item._id,
                description: `${item.code} ${item.description}`,
              };
            }),
          );
          this.isPrintable = true;
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.isPrintable = false;
            this.generateNumber();
            this.getDataAssessment();
            this.input.dpjp[0] = this.patientData.meta.doctor;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.body-container {
  padding-left: 150px;
}

.form-body {
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }

  &__data {
    height: 25px;
  }
}

.scrollbar {
  height: 55vh;
  overflow-y: auto;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #c2c2c2;
  }
}

.required::after {
  content: '*';
  color: #eb4747;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
