<template>
  <v-form ref="form" class="form-container mt-4">
    <section>
      <h4>RIWAYAT KB & PENYAKIT</h4>
      <v-row no-gutters>
        <v-col cols="6" class="pr-10 mb-4">
          <label class="required">Status KB</label>
          <v-radio-group
            class="input-field"
            :readonly="isEMR"
            row
            v-model="form.kbStatus"
          >
            <v-radio label="Belum pernah KB" :value="false"></v-radio>
            <v-radio label="Pernah KB" :value="true"></v-radio>
          </v-radio-group>
          <div
            class="input-field d-flex flex-wrap"
            style="width: 100% !important; gap: 0.25rem; margin-top: -2rem;"
            v-if="form?.kbStatus === true"
            :rules="isEMR ? [] : [form.kbMethod.length !== 0 || 'Wajib diisi']"
          >
            <div
              v-for="(item, index) in items.kbMethod"
              :key="index"
              style="min-width: 4.5rem;"
            >
              <v-checkbox
                :rules="
                  isEMR ? [] : [form.kbMethod.length !== 0 || 'Wajib diisi']
                "
                v-model="form.kbMethod"
                :label="item"
                color="primary"
                :value="item"
                hide-details
                :update:error="true"
                :readonly="isEMR"
              />
            </div>
          </div>
          <span
            v-show="
              form.kbMethod.length === 0 &&
                form?.kbStatus === true &&
                this.showError === true
            "
            class="errorMessage"
            >Wajib diisi</span
          >
        </v-col>
        <v-col cols="6" class="pl-10 mt-4">
          <label>Riwayat Penyakit Keluarga</label>
          <v-text-field
            dense
            single-line
            v-model="form.familyDiseaseHistory"
            placeholder="Masukkan keterangan"
            class="input-field"
            :readonly="isEMR"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label>Riwayat Penyakit atau Operasi</label>
          <v-text-field
            dense
            single-line
            v-model="form.diseaseSurgeryHistory"
            placeholder="Masukkan keterangan"
            class="input-field"
            :readonly="isEMR"
          />
        </v-col>
      </v-row>

      <v-divider class="divider" />
    </section>

    <section>
      <h4>
        POLA MAKAN & IMUNISASI TT
      </h4>
      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label>Pola Makan Sebelum Hamil</label>
          <v-text-field
            dense
            single-line
            v-model="form.dietAndImunization.dietBeforePregrant"
            placeholder="Masukkan keterangan"
            class="input-field"
            :readonly="isEMR"
          />
        </v-col>
        <v-col cols="6" class="pl-10">
          <label class="required">Imunisasi TT</label>
          <v-radio-group
            row
            :readonly="isEMR"
            v-model="form.dietAndImunization.imunizationTT"
          >
            <v-radio label="Sudah" :value="true"></v-radio>
            <v-radio label="Belum" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label>Pola Makan Setelah Hamil</label>
          <v-text-field
            dense
            single-line
            v-model="form.dietAndImunization.dietAfterPregrant"
            placeholder="Masukkan keterangan"
            class="input-field"
            :readonly="isEMR"
          />
        </v-col>
      </v-row>
    </section>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'ObstetricsAssessment',
);

export default {
  name: 'kbAndImunizationHistory',
  props: {
    isEMR: Boolean,
  },
  data() {
    return {
      showError: false,
    };
  },
  mounted() {
    if (this.form.kbMethod.length === 0) {
      this.showError = true;
    }
  },
  computed: {
    ...mapGetters(['getForm', 'getItems']),
    form: {
      get() {
        return this.getForm.kbAndImunizationHistory;
      },
    },
    items: {
      get() {
        return this.getItems;
      },
    },
  },
  watch: {
    'form.kbStatus': {
      handler() {
        if (this.form?.kbStatus === false) {
          this.showError = false;
        }
      },
      deep: true,
    },
    'form.kbMethod': {
      handler() {
        if (this.form.kbMethod.length === 0) {
          this.showError = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations(['']),
    ...mapActions(['']),
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: start;
  gap: 1.5rem;
  .divider {
    margin-top: 1.5rem;
    width: 100%;
  }
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: #3f484a;
  }
  .input-field {
    margin-top: 0.5rem;
    width: 100%;
  }
  h4 {
    font-weight: 400;
    color: #137bc0;
    text-align: start;
    margin-bottom: 0.7vw;
  }
}
section {
  width: 100%;
}
.errorMessage {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #eb4747;
}
.required:after {
  content: ' *';
  color: red;
}
::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
