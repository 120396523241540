var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-main',[_c('dashboard-queue-header',{attrs:{"loading":_vm.loading,"clinic":_vm.clinicName}}),_c('section',{staticClass:"text-left"},[_c('v-row',[_c('v-col',{staticClass:"pa-10",attrs:{"cols":"8"}},[_c('div',{class:{
            'grid-container': _vm.publicQueue.length <= 4,
            'grid-container-6': _vm.publicQueue.length > 4,
          }},_vm._l((_vm.publicQueue),function(item){return _c('div',{key:item._id,staticClass:"grid-item"},[_c('div',{staticClass:"queue-card__room"},[_vm._v(" "+_vm._s(item.room)+" ")]),_c('div',{class:{
                'queue-card__code queue-card__normal_font': item.isNormalFont,
                'queue-card__code queue-card__small_font': !item.isNormalFont,
              }},[_c('label',[_vm._v(" "+_vm._s(item.queueNormal?.[0]?.queue_code || '-')+" ")])]),_c('div',{staticClass:"queue-card__poly"},[_vm._v(" "+_vm._s(item.id_unit?.name || '-')+" ")])])}),0)]),_c('v-col',{staticClass:"pa-10"},[_c('v-card',{staticClass:"bordered-card pass-queue-card",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"mb-4 px-0",on:{"click":_vm.decrementNumbersOfTabOpen}},[_vm._v(" ANTREAN DILEWATI ")]),_c('div',{staticClass:"container-limit"},[_c('div',{staticClass:"list-container",class:{ animate: _vm.isOverflow },attrs:{"id":"list-container"}},_vm._l((_vm.missedPatientsForPublicView),function(item,index){return _c('div',{key:`missed-queue-${index}`},[_c('h3',[_vm._v(_vm._s(item.poly)+" ("+_vm._s(item.doctor)+")")]),_c('div',{staticClass:"queue-list"},_vm._l((item.queueCodeList),function(code,idx){return _c('span',{key:`code-${idx}`,staticClass:"primary--text"},[_vm._v(_vm._s(code))])}),0)])}),0)])],1)],1)],1)],1),_c('audio',{ref:"notification"},[_c('source',{attrs:{"src":require("../../../../../assets/sound/bell.mp3"),"type":"audio/mpeg"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }