<template>
  <v-form ref="form">
    <section>
      <!-- view doctor -->
      <template v-if="isDoctor">
        <h4>ASSESMEN & PLAN</h4>
        <v-row>
          <v-col class="pb-0">
            <label>Diagnosa</label>
          </v-col>
          <v-col class="pb-0">
            <label>ICD-10</label>
          </v-col>
        </v-row>
        <v-row
          v-for="(item, index) in form.doctor.diagnose"
          :key="`row-${index}`"
        >
          <v-col :class="`pb-0 ${index ? 'pt-0' : ''}`">
            <div class="d-flex mb-3">
              <v-text-field
                v-model="item.name"
                :readonly="!isEdit"
                :rules="item.diagnose ? rule : []"
                single-line
                validate-on-blur
                dense
                placeholder="Masukkan Diagnosa"
              />
              <v-btn
                @click="deleteDoctorDiagnose(index)"
                :disabled="form.doctor.diagnose.length === 1 || !isEdit"
                fab
                small
                color="#c2c2c2"
                text
                ><v-icon>mdi-delete</v-icon></v-btn
              >
              <v-btn
                @click="addDoctorDiagnose"
                fab
                small
                color="#9ca2a5"
                text
                :disabled="!isEdit"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </div>
          </v-col>
          <v-col :class="`pb-0 ${index ? 'pt-0' : ''}`">
            <v-combobox
              single-line
              dense
              validate-on-blur
              append-icon="mdi-chevron-down"
              placeholder="Pilih ICD-10"
              item-text="name"
              :rules="item.name ? rule : []"
              :clearable="isEdit"
              :readonly="!isEdit"
              @focus="activeIndex.icd = index"
              v-model="item.diagnose"
              :search-input.sync="search.icd[index]"
              :loading="item.loading"
              :items="resource.icd"
          /></v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <label>Rencana Tindakan</label>
            <div
              v-for="(_, index) in form.doctor.planning"
              :key="`planning-${index}`"
              class="d-flex"
            >
              <v-text-field
                v-model="form.doctor.planning[index]"
                :readonly="!isEdit"
                single-line
                dense
                placeholder="Rencana Tindakan"
              />
              <v-btn
                :disabled="form.doctor.planning.length === 1 || !isEdit"
                @click="deleteDoctorPlanning(index)"
                fab
                small
                color="#c2c2c2"
                text
                ><v-icon>mdi-delete</v-icon></v-btn
              >
              <v-btn
                @click="addDoctorPlanning"
                :disabled="!isEdit"
                fab
                small
                color="#9ca2a5"
                text
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </div>
          </v-col>
          <v-col cols="6">
            <label>Catatan</label>
            <vue-editor
              v-model="form.note"
              class="pt-4"
              :disabled="!isEdit"
              :placeholder="isEdit ? 'Masukkan Catatan' : ''"
              :editorToolbar="customToolbar"
            ></vue-editor>
          </v-col>
        </v-row>
      </template>
      <!-- view non doctor (midwife or nurse) -->
      <template v-else>
        <h4>ASSESMEN & PLAN</h4>
        <v-row
          v-for="(item, index) in form.nurse.diagnose"
          :key="`row-nurse-${index}`"
        >
          <v-col class="pb-0">
            <label>Diagnosa {{ index + 1 }}</label>
            <div class="d-flex">
              <div style="width: 100%;" class="text-end">
                <v-combobox
                  single-line
                  validate-on-blur
                  dense
                  append-icon="mdi-chevron-down"
                  placeholder="Pilih Diagnosa"
                  item-text="name"
                  :clearable="isEdit"
                  :rules="item.planning.find(i => i?.planning) ? rule : []"
                  :readonly="!isEdit"
                  @change="getPlanningByDiagnoseId(index, item.diagnose?.id)"
                  :items="resource.nurseDiaganose"
                  :loading="loading.nurseDiaganose"
                  v-model="item.diagnose"
                />
                <p class="pa-0 ma-0 caption mt-n4">
                  {{ isEdit ? 'Ketik untuk input diagnosa lainnya' : '' }}
                </p>
              </div>
              <v-btn
                :disabled="form.nurse.diagnose.length === 1 || !isEdit"
                @click="deleteNurseDiagnose(index)"
                fab
                small
                color="#c2c2c2"
                text
                ><v-icon>mdi-delete</v-icon></v-btn
              >
              <v-btn
                @click="addNurseDiagnose"
                :disabled="!isEdit"
                fab
                small
                color="#9ca2a5"
                text
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </div>
          </v-col>
          <v-col class="pb-0 pt-4">
            <label>Rencana Tindakan</label>
            <div
              class="d-flex mb-2"
              v-for="(i, idx) in item.planning"
              :key="`diagnose-${index}-planning-${idx}`"
            >
              <div style="width: 100%;" class="text-end">
                <v-combobox
                  single-line
                  dense
                  validate-on-blur
                  append-icon="mdi-chevron-down"
                  item-text="name"
                  placeholder="Rencana Tindakan"
                  :rules="item.diagnose ? rule : []"
                  :clearable="isEdit"
                  :readonly="!isEdit"
                  v-model="i.planning"
                  :items="item.planningResource"
                  :loading="item.loading"
                />
                <p class="pa-0 ma-0 caption mt-n4">
                  {{
                    isEdit ? 'Ketik untuk input rencana tindakan lainnya' : ''
                  }}
                </p>
              </div>
              <v-btn
                :disabled="item.planning.length === 1 || !isEdit"
                @click="
                  deleteNursePlanning({
                    diagnoseIndex: index,
                    planningIndex: idx,
                  })
                "
                fab
                small
                color="#c2c2c2"
                text
                ><v-icon>mdi-delete</v-icon></v-btn
              >
              <v-btn
                @click="addNursePlanning(index)"
                :disabled="!isEdit"
                fab
                small
                color="#9ca2a5"
                text
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <label>Catatan</label>
            <vue-editor
              class="pt-4"
              v-model="form.note"
              :disabled="!isEdit"
              :placeholder="isEdit ? 'Masukkan Catatan' : ''"
              :editorToolbar="customToolbar"
            ></vue-editor>
            <span
              v-if="error.assessmentNote.state && isEdit"
              class="error--text caption"
              >{{ error.assessmentNote.message }}</span
            >
          </v-col>
        </v-row>
      </template>
    </section>
  </v-form>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import {
  getAllDiagnose,
  getNurseDiagnose,
  getNurseIntervention,
} from '@/fetchApi/MasterData/Diagnose';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers('CPPTStore');

const _ = require('lodash');
export default {
  name: 'AssessmentAndPlan',
  components: {
    VueEditor,
  },
  data() {
    return {
      rule: [v => !!v || 'Wajib diisi'],
      activeIndex: {
        icd: '',
      },
      resource: {
        icd: [],
        nurseDiaganose: [],
      },
      search: {
        icd: [],
      },
      loading: {
        nurseDiagnose: false,
      },
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    };
  },
  watch: {
    'search.icd'(val) {
      const index = this.activeIndex.icd;
      this.searchIcd(this, val[index], index);
    },
    'form.note'(val) {
      const char = val.replace(/<[^>]*>/g, ''); // regex to remove html tag inside 'val'
      if (char.length <= 3000) {
        this.setErrorVueEditor({
          assessmentNote: {
            state: false,
            message: '',
          },
        });
      }
      if (char.length > 3000) {
        this.setErrorVueEditor({
          assessmentNote: {
            state: true,
            message: 'Maksimal 3000 karakter',
          },
        });
      }
    },
  },
  computed: {
    ...mapGetters(['getForm', 'getErrorVueEditor']),
    error: {
      get() {
        return this.getErrorVueEditor;
      },
      set(value) {
        this.setErrorVueEditor(value);
      },
    },
    form: {
      get() {
        return this.getForm.assessment;
      },
    },
    isDetail: {
      get() {
        return this.getForm.isDetail;
      },
    },
    isEdit: {
      get() {
        return this.isDetail ? this.getForm.isEdit : true;
      },
    },
    rolePpa: {
      get() {
        return this.getForm.generalInformation.ppa.role;
      },
    },
    user: {
      get() {
        return this.isDetail
          ? this.rolePpa
          : this.$store.getters.userLoggedIn.role;
      },
    },
    isDoctor: {
      get() {
        return this.user.some(item => item.toLowerCase() === 'dokter');
      },
    },
  },
  mounted() {
    if (this.isDoctor) {
      this.getDiagnose();
    } else {
      this.getNurseDiagnose();
      this.form?.nurse?.diagnose?.forEach((i, index) => {
        this.getPlanningByDiagnoseId(index, i.diagnose?.id);
      });
    }
  },
  methods: {
    ...mapMutations([
      'setErrorVueEditor',
      'addDoctorDiagnose',
      'deleteDoctorDiagnose',
      'deleteDoctorPlanning',
      'addDoctorPlanning',
      'addNurseDiagnose',
      'deleteNurseDiagnose',
      'addNursePlanning',
      'deleteNursePlanning',
    ]),
    searchIcd: _.debounce((v, keyword, index) => {
      v.getDiagnose(keyword, index);
    }, 600),
    async getNurseDiagnose(keyword) {
      try {
        this.loading.nurseDiaganose = true;
        const params = {
          page: 1,
          itemCount: 12,
          search: keyword,
        };
        const response = await getNurseDiagnose(params);
        const { data } = response;
        this.resource.nurseDiaganose = data.map(item => {
          return { id: item._id, name: _.startCase(_.toLower(item.name)) };
        });
      } catch {
        this.resource.nurseDiaganose = [];
      } finally {
        this.loading.nurseDiaganose = false;
      }
    },
    async getDiagnose(keyword, index) {
      try {
        this.form.doctor.diagnose[index || 0].loading = true;
        const params = {
          search: keyword || '',
          page: 1,
          itemCount: 20,
          sort: '-code',
        };
        const response = await getAllDiagnose(params);
        const { data } = response;
        this.resource.icd = data.map(diagnose => {
          return {
            id: diagnose._id,
            name: diagnose.display_name,
          };
        });
      } catch {
        this.resource.icd = [];
      } finally {
        this.form.doctor.diagnose.forEach(i => {
          // eslint-disable-next-line no-param-reassign
          i.loading = false;
        });
      }
    },
    async getPlanningByDiagnoseId(index, id) {
      if (!id) return;
      try {
        this.form.nurse.diagnose[index].loading = true;
        const response = await getNurseIntervention(id);
        const { data } = response;
        this.form.nurse.diagnose[index].planningResource = data.map(i => {
          return {
            id: i._id,
            name: _.capitalize(_.toLower(i.name)),
          };
        });
      } catch {
        this.form.nurse.diagnose[index].planningResource = [];
      } finally {
        this.form?.nurse?.diagnose?.forEach(v => {
          // eslint-disable-next-line no-param-reassign
          v.loading = false;
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
section {
  text-align: start;
  padding-top: 2vw;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 3vw;

  label {
    font-weight: 500;
    font-size: 0.8vw;
  }

  .quillWrapper {
    min-height: 10vh;
    max-width: 40vw;
  }

  ::v-deep .ql-editor {
    max-height: 1vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 9px;
      background-color: #ededed;
    }
    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 9px;
      background-color: #9e9e9e;
    }
  }
}

h4 {
  font-weight: 400;
  color: #137bc0;
  margin-bottom: 0.7vw;
}

.required:after {
  content: ' *';
  color: red;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
