<template>
  <div class="main-div">
    <v-layout xs12 align-center py-3 px-8>
      <v-flex xs8>
        <h2 class="title">Pendaftaran IGD</h2>
      </v-flex>
      <v-flex xs1>
        <v-btn icon mt-3 @click="getTriasePatient()">
          <font-awesome-icon icon="sync-alt" class="btn-refresh" />
        </v-btn>
      </v-flex>
      <v-flex xs3>
        <v-text-field
          class="input"
          background-color="grey lighten-3"
          placeholder="Search"
          solo
          dense
          hide-details
          flat
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout class="px-5">
      <v-data-table
        :headers="igd.headers"
        :items="igd.items"
        :loading="isLoading"
        :items-per-page="itemsPerRow"
        :page.sync="igd.page.page"
        hide-default-footer
        class="igdTable"
      >
        <template v-slot:[`item.no`]="{ item }">
          <td>
            {{
              (igd.page.page - 1) * itemsPerRow + igd.items.indexOf(item) + 1
            }}
          </td>
        </template>
        <template v-slot:[`item.category`]="{ item }">
          <v-btn
            v-if="item.category === 1"
            color="red"
            class="white--text"
            depressed
            block
            small
            >PACS 1</v-btn
          >
          <v-btn
            v-else-if="item.category === 2 || item.category === 3"
            color="#F2C94C"
            class="white--text"
            depressed
            block
            small
            >PACS {{ item.category }}</v-btn
          >
          <v-btn
            v-else-if="item.category === 4"
            color="#27AE60"
            class="white--text"
            depressed
            block
            small
            >PACS 4</v-btn
          >
          <v-btn v-else color="black" class="white--text" depressed>DOA</v-btn>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn outlined small color="primary" @click="selectItem(item)"
            >Daftar</v-btn
          >
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" type="info" tile>
            Maaf data triase tidak ditemukan
          </v-alert>
        </template>
      </v-data-table>
    </v-layout>

    <div class="d-flex justify-start ml-6">
      <v-pagination
        v-model="igd.page.page"
        :length="igdPages"
        :total-visible="5"
        @input="getTriasePatient()"
      ></v-pagination>
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" width="1200">
        <DataMatching
          v-if="dialog"
          :data-patient="selectedPatient"
          :get-triase-patient="getTriasePatient"
          @close-form="
            dialog = false;
            getTriasePatient();
          "
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
// import SocialDataPatients from './components/SocialDataPatients';
import Constant from '@/const';
import AlertMixin from '@/mixin/alertMixin';
import DataMatching from './components/IGD/DataMatching.vue';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'IGDRegist',
  mixins: [AlertMixin],
  components: {
    // SocialDataPatients
    DataMatching,
  },
  data() {
    return {
      dialog: false,
      igd: {
        headers: [
          { text: 'No.', value: 'no' },
          { text: 'No. TRIASE', value: 'noTriase' },
          { text: 'Nama', value: 'name' },
          { text: 'Jenis Kelamin', value: 'gender', sortable: false },
          { text: 'Alamat', value: 'address' },
          { text: 'Kategori', value: 'category' },
          { text: '', value: 'actions', sortable: false },
        ],
        items: [],
        page: {
          descending: true,
          page: 1,
          rowsPerPage: 8,
        },
      },
      igdPages: 0,
      isLoading: false,
      selectedPatient: null,
      search: '',
    };
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 7;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  watch: {
    search() {
      this.igd.page.page = 1;
      this.searchData(this);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getTriasePatient();
    });
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.igd.page.page = 1;
      v.getTriasePatient();
    }, 650),
    closeForm() {
      this.dialog = false;
    },
    selectItem(item) {
      this.selectedPatient = item;
      this.dialog = true;
    },
    async getTriasePatient() {
      this.isLoading = true;
      const itemCount = this.itemsPerRow;
      try {
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/patient/igd/triase?search=${this.search}&page=${this.igd.page.page}&itemCount=${itemCount}`,
          ),
        );
        const { data } = res.data;
        this.igdPages = Math.ceil(res.data.length / itemCount);
        this.igd.items = data.map(arr => {
          return {
            noTriase: _.get(arr, 'triase_number', ''),
            name: _.get(arr, 'detail_triase[0].triase_level.name', ''),
            gender: _.get(arr, 'detail_triase[0].triase_level.gender', '')
              ? 'Laki-laki'
              : 'Perempuan',
            address: _.get(arr, 'detail_triase[0].triase_level.address', ''),
            category: _.get(arr, 'detail_triase[0].triase_level.pacs', ''),
          };
        });
      } catch (error) {
        this.igd.items = [];
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: left;
  font-weight: bold !important;
  line-height: 26px;
}
.igdTable {
  width: 100%;
  height: 430px;
}
</style>
