var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-navigation-drawer',{staticClass:"pa-2 sidebar",attrs:{"mini-variant":_vm.mini,"mini-variant-width":_vm.isLargeDisplay ? 90 : 60,"color":"#DEEEF9","permanent":"","width":_vm.isLargeDisplay ? 450 : 400},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-list-item',{staticClass:"py-2 mb-5",class:{
          'mx-auto': _vm.mini,
          'pa-0': _vm.mini && !_vm.isLargeDisplay,
          'pa-2 pl-4': !_vm.mini && _vm.isLargeDisplay,
          'pl-3 px-0': !_vm.mini && !_vm.isLargeDisplay,
        }},[(_vm.mini)?_c('v-btn',{attrs:{"small":!_vm.isLargeDisplay,"color":"black","text":"","fab":""}},[_c('v-icon',{attrs:{"small":!_vm.isLargeDisplay}},[_vm._v("mdi-menu")])],1):_vm._e(),(_vm.isLargeDisplay)?_c('h2',{staticClass:"sidebar-title"},[_vm._v(" EMR Rawat Inap ")]):_c('h3',{staticClass:"sidebar-title mr-10"},[_vm._v(" EMR Rawat Inap ")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"small":"","fab":"","text":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c('v-icon',{attrs:{"large":_vm.isLargeDisplay}},[_vm._v("mdi-chevron-left")])],1)],1),(!_vm.mini)?_vm._l((_vm.itemList),function(item,index){return _c('div',{key:`list-${index}`,staticClass:"list",class:{ active: _vm.selectedForm === item.label },on:{"click":function($event){return _vm.changeForm(item.label)}}},[_c('div',{staticClass:"label-container"},[_c('label',[_vm._v(_vm._s(item.label))])])])}):_vm._e()],2)],1),_c('v-card',{staticClass:"pa-0 main",attrs:{"tile":""}},[(_vm.headerData)?_c('inpatient-e-m-r-header',{attrs:{"data":_vm.headerData},on:{"close":_vm.closeForm}}):_vm._e(),(_vm.selectedForm === 'Assesmen Awal Rawat Inap')?_c('early-inpatient-assessment',{attrs:{"isEMR":_vm.isEMR}}):_vm._e(),(_vm.selectedForm === 'Assesmen Kebidanan Ibu Hamil')?_c('obstetrics-assessment',{attrs:{"patient-data":_vm.patientData,"isEMR":_vm.isEMR},on:{"close":_vm.closeForm}}):_vm._e(),(_vm.selectedForm === 'Assesmen Persalinan')?_c('labour-assessment',{attrs:{"patient-data":_vm.patientData,"isEMR":_vm.isEMR},on:{"close":_vm.closeForm}}):_vm._e(),(_vm.selectedForm === 'Assesmen Neonatus')?_c('neonatus-assessment',{attrs:{"baby":{
        ..._vm.patientData,
        birthDateAndAge: _vm.birthDateAndAge,
      },"isEMR":_vm.isEMR}}):_vm._e(),(_vm.selectedForm === 'Catatan Pemberian Obat')?_c('drug-record',{attrs:{"patient-data":_vm.patientData}}):_vm._e(),(_vm.selectedForm === 'CPPT')?_c('c-p-p-t-dashboard',{attrs:{"isEMR":_vm.isEMR,"mini":_vm.mini}}):_vm._e(),(_vm.selectedForm === 'General Consent')?_c('general-consent-form',{attrs:{"is-inpatient-emr":"","service-type":"Rawat Inap","rm-number":_vm.patientData.rmNumber,"id-inpatient":_vm.patientData.id_inpatient}}):_vm._e(),(_vm.selectedForm === 'Informed Consent')?_c('list-informed-consent'):_vm._e(),(_vm.selectedForm === 'Resume Medis')?_c('medical-resume-form',{attrs:{"mini":_vm.mini,"isEMR":_vm.isEMR}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }