<template>
  <v-container>
    <v-toolbar
      elevation="0"
      style="border-bottom: 3px solid #F4F5F5;"
      height="75"
    >
      <v-toolbar-title class="text-left">
        <h4>Informasi Ketersediaan Bed</h4>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="getAllBedByRoom()">
        <v-icon>
          mdi-autorenew
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-row class="justify-space-around py-5" no-gutters>
      <v-card
        width="22%"
        :height="height"
        color="#137BC0"
        tile
        class="card-title py-5 px-4"
      >
        <v-row no-gutters align="center" class="row-card pb-2">
          <v-img
            :src="bed1"
            contain
            max-height="32"
            max-width="32"
            class="mr-4"
          />
          <label class="title-card text-white">Seluruh Bed</label>
        </v-row>
        <v-row no-gutters align="left" class="pt-5">
          <label class="bed text-white">{{ roomDetail.totalBed }} Bed</label>
        </v-row>
      </v-card>
      <v-card
        width="22%"
        :height="height"
        color="white"
        tile
        class="card-title py-5 px-4"
      >
        <v-row no-gutters align="center" class="row-card pb-2">
          <v-img
            :src="bed2"
            contain
            max-height="32"
            max-width="32"
            class="mr-4"
          />
          <label class="title-card">Bed Tersedia</label>
        </v-row>
        <v-row no-gutters align="left" class="pt-5">
          <label class="bed">{{ roomDetail.totalAvailable }} Bed</label>
        </v-row>
        <v-row no-gutters align="left" class="beds-container">
          <v-chip
            v-for="(bed, i) in roomDetail.availableBed"
            :key="i"
            color="#EB4747"
            class="beds beds-available mr-2 mb-2"
            small
            caption
            ><span class="chip-center">{{ bed }}</span></v-chip
          >
        </v-row>
      </v-card>
      <v-card
        width="22%"
        :height="height"
        color="white"
        tile
        class="card-title py-5 px-4"
      >
        <v-row no-gutters align="center" class="row-card pb-2">
          <v-img
            :src="bed3"
            contain
            max-height="32"
            max-width="32"
            class="mr-4"
          />
          <label class="title-card">Bed Ditempati</label>
        </v-row>
        <v-row no-gutters align="left" class="pt-5">
          <label class="bed">{{ roomDetail.totalOccupied }} Bed</label>
        </v-row>
        <v-row no-gutters align="left" class="beds-container">
          <v-chip
            v-for="(bed, i) in roomDetail.occupiedBed"
            :key="i"
            color="#EB4747"
            class="beds beds-occupied mr-2 mb-2"
            small
            caption
            ><span class="chip-center">{{ bed }}</span></v-chip
          >
        </v-row>
      </v-card>
      <v-card
        width="22%"
        :height="height"
        color="white"
        tile
        class="card-title py-5 px-4"
      >
        <v-row no-gutters align="center" class="row-card pb-2">
          <v-img
            :src="bed4"
            contain
            max-height="32"
            max-width="32"
            class="mr-4"
          />
          <label class="title-card">Bed Rusak</label>
        </v-row>
        <v-row no-gutters align="left" class="pt-5">
          <label class="bed">{{ roomDetail.totalBroken }} Bed</label>
        </v-row>
        <v-row no-gutters align="left" class="beds-container">
          <v-chip
            v-for="(bed, i) in roomDetail.brokenBed"
            :key="i"
            color="#EB4747"
            class="beds beds-broken mr-2 mb-2"
            small
            caption
            ><span class="chip-center">{{ bed }}</span></v-chip
          >
        </v-row>
      </v-card>
    </v-row>
    <!-- <div class="pl-1" style="display: grid; grid-row-gap: 4px;">
        <v-card
          width="200"
          height="40"
          color="#329C5F"
          tile
          class="avail-card pa-2"
        >
          <label class="white--text"
            >TERSEDIA :
            <span class="font-weight-bold">{{ totalAvailable }}</span>
            bed</label
          ></v-card
        >
        <v-card width="200" height="40" color="#DD4C4C" tile class="pa-2"
          ><label class="white--text"
            >TERISI :
            <span class="font-weight-bold">{{ totalOccupied }}</span> bed</label
          ></v-card
        >
        <v-card
          width="200"
          height="40"
          color="#F2994A"
          tile
          class="broken-card pa-2"
          ><label class="white--text"
            >RUSAK :
            <span class="font-weight-bold">{{ totalBroken }}</span> bed</label
          ></v-card
        >
      </div> -->
    <!-- <v-row class="justify-center">
      <template v-for="(bed, index) in roomDetail">
        <v-col cols="4" :key="'room-' + index">
          <v-row no-gutters>
            <v-card
              width="150"
              height="128"
              color="#E4F4FF"
              tile
              class="card-sub pa-4"
            >
              <v-row no-gutters align="center" class="row-card pb-2 mb-2">
                <v-img
                  :src="pic"
                  contain
                  max-height="32"
                  max-width="32"
                  class="mr-2"
                />
                <label class="sub-card">{{ bed.room_name }} :</label>
              </v-row>
              <label class="bed">{{ bed.totalBed }} Bed</label>
            </v-card>
            <div class="pl-1" style="display: grid; grid-row-gap: 4px;">
              <v-card
                width="170"
                height="40"
                color="#329C5F"
                tile
                class="avail-card pa-2"
              >
                <label class="white--text"
                  >TERSEDIA :
                  <span class="font-weight-bold">{{ bed.available }}</span>
                  bed</label
                ></v-card
              >
              <v-card width="170" height="40" color="#DD4C4C" tile class="pa-2"
                ><label class="white--text"
                  >TERISI :
                  <span class="font-weight-bold">{{ bed.occupied }}</span>
                  bed</label
                ></v-card
              >
              <v-card
                width="170"
                height="40"
                color="#F2994A"
                tile
                class="broken-card pa-2"
                ><label class="white--text"
                  >RUSAK :
                  <span class="font-weight-bold">{{ bed.broken }}</span>
                  bed</label
                ></v-card
              >
            </div>
          </v-row>
        </v-col>
      </template>
    </v-row> -->
  </v-container>
</template>

<script>
import bed1 from '@/assets/image/bed-1.svg';
import bed2 from '@/assets/image/bed-2.svg';
import bed3 from '@/assets/image/bed-3.svg';
import bed4 from '@/assets/image/bed-4.svg';
import Constant from '@/const';

const axios = require('axios');

export default {
  name: 'BedAvailability',
  data() {
    return {
      bed1: bed1,
      bed2: bed2,
      bed3: bed3,
      bed4: bed4,
      roomDetail: {
        totalBed: 10,
        totalAvailable: 5,
        totalOccupied: 3,
        totalBroken: 2,
        availableBed: [1, 2, 3, 4, 5, 6],
        occupiedBed: [1, 2, 3],
        brokenBed: [1, 2],
      },
    };
  },
  mounted() {
    this.getAllBedByRoom();
  },
  computed: {
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '40vh';
          break;
        case 'lg':
          height = '34vh';
          break;
        case 'xl':
          height = '25vh';
          break;
      }
      return height;
    },
    //   totalBed() {
    //     return this.roomDetail.reduce((a, { totalBed }) => a + totalBed, 0);
    //   },
    //   totalAvailable() {
    //     return this.roomDetail.reduce((a, { available }) => a + available, 0);
    //   },
    //   totalOccupied() {
    //     return this.roomDetail.reduce((a, { occupied }) => a + occupied, 0);
    //   },
    //   totalBroken() {
    //     return this.roomDetail.reduce((a, { broken }) => a + broken, 0);
    //   },
  },
  methods: {
    getAllBedByRoom() {
      axios
        .get(Constant.apiUrl.concat('/master/bed/stat/all'))
        .then(response => {
          const data = response.data.data;
          this.roomDetail.totalAvailable = data.available.length;
          this.roomDetail.totalBroken = data.broken.length;
          this.roomDetail.totalOccupied = data.occupied.length;
          this.roomDetail.totalBed = data.total.length;
          this.roomDetail.availableBed = data.available.beds
            .slice(0, 10)
            .map(bed => bed.bed_number);
          this.roomDetail.brokenBed = data.broken.beds
            .slice(0, 10)
            .map(bed => bed.bed_number);
          this.roomDetail.occupiedBed = data.occupied.beds
            .slice(0, 10)
            .map(bed => bed.bed_number);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  border-radius: 4px !important;
  color: #616161;
  .row-card {
    // border-bottom: 1px solid #757575;
    // border-bottom-style: dashed;
  }
  .title-card {
    font-size: 1.1rem;
    font-weight: 400;
    text-align: left;
  }
  .bed {
    font-size: 3.2rem;
    font-weight: 500;
  }
  .text-white {
    color: white;
  }
}

.beds-container {
  display: flex;
  align-items: flex-end;
}

.beds {
  font-weight: 500;
  font-size: 12px;
  justify-content: center;
  border: none !important;
  color: #404040;
  border-radius: 50px;
}
.beds-available {
  background-color: #97e2ae !important;
}
.beds-occupied {
  background-color: #79c1f2 !important;
}
.beds-broken {
  background-color: #c2c2c2 !important;
}

// @media screen and (min-width: 1003px) {
//   .chip-center {
//     margin-left: -10px;
//   }
// }

@media screen and (max-width: 1703px) {
  .beds-container {
    height: 60px;
    margin-top: 0;
  }

  .beds {
    width: 16.2%;
  }
  .chip-center {
    margin-left: -5px;
  }
}

@media screen and (min-width: 1703px) {
  .beds-container {
    height: 60px;
    margin-top: 50px;
  }

  .beds {
    width: 16.2%;
  }
  .chip-center {
    margin-left: -5px;
  }
}

@media screen and (min-width: 1904px) {
  .beds-container {
    height: 60px;
    margin-top: 0;
  }

  .beds {
    width: 16.2%;
  }
}
// .card-sub {
//   border-radius: 4px !important;
//   color: #4f4f4f;
//   .row-card {
//     border-bottom: 1px solid #757575;
//     border-bottom-style: dashed;
//   }
//   .sub-card {
//     font-size: 1rem;
//     font-weight: 500;
//   }
//   .bed {
//     font-size: 1.9rem;
//     font-weight: 500;
//   }
// }
// .avail-card {
//   border-top-right-radius: 8px !important;
// }
// .broken-card {
//   border-bottom-right-radius: 8px !important;
// }
</style>
