import {
  putDiagnose,
  postDiagnose,
  getAllDiagnose,
  deleteDiagnose,
  getDiagnoseById,
} from '@/fetchApi/MasterData/Diagnose';

const state = {
  dashboard: {
    dialog: false,
    filter: {
      halaman: 0,
      itemCount: 0,
      sortBy: '',
      keyword: '',
    },
  },
  form: {
    id: '',
    code: '',
    description: '',
  },
  items: {
    dataDiagnose: {
      isLoading: false,
      data: [],
    },
  },
};
const getters = {
  // Get State Dialog
  getDialog: state => state.dashboard.dialog,

  // Get State Items
  getItems: state => state.items,

  // Get State Form
  getForm: state => state.form,
};
const mutations = {
  // Set State Dialog
  setDialog(state, payload) {
    state.dashboard.dialog = payload;
  },

  // Set State Items
  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: value,
      isLoading,
    };
  },

  // Set State Form
  setForm(state, payload) {
    state.form = payload;
  },

  // Set Filter
  setFilter(state, payload) {
    state.dashboard.filter = payload;
  },

  // Clear Form
  clearForm(state) {
    state.form = {
      id: '',
      code: '',
      description: '',
    };
  },
};
const actions = {
  // Get Data
  // Gett All Data Diagnose
  async resolveGetAllDiagnose(
    { commit, state },
    { halaman, itemCount, sortBy, keyword },
  ) {
    commit('setItems', {
      label: 'dataDiagnose',
      value: [...state.items.dataDiagnose.data],
      isLoading: true,
    });
    try {
      const params = {
        search: keyword || '',
        page: halaman,
        itemCount,
        sort: sortBy,
      };
      const diagnoseResponse = await getAllDiagnose(params);

      const diagnoseMapper = diagnoseResponse?.data?.map(item => ({
        id: item._id.toString(),
        code: item.code.toString(),
        description: item.description,
      }));
      commit('setItems', {
        label: 'dataDiagnose',
        value: diagnoseMapper,
        isLoading: false,
      });
      return diagnoseResponse?.length;
    } catch {
      commit('setItems', {
        label: 'dataDiagnose',
        value: [],
        isLoading: false,
      });
      return 0;
    }
  },

  // Get Data Diagnose By Id
  async resolveGetDiagnoseById({ state, commit }) {
    const responseGetDiagnoseById = await getDiagnoseById(state.form.id);
    try {
      commit('setForm', {
        id: responseGetDiagnoseById._id,
        code: responseGetDiagnoseById.code,
        description: responseGetDiagnoseById.description,
      });
      return responseGetDiagnoseById;
    } catch (error) {
      return error;
    }
  },
  // End Get Data

  // Post Data
  async resolvePostDiagnose({ state, commit, dispatch }) {
    const payload = {
      ...state.form,
    };
    delete payload.id;

    try {
      const postDiagnoseRes = await postDiagnose(payload);
      if (postDiagnoseRes.status === 200) {
        commit('setDialog', false);
        commit('clearForm');
        await dispatch('resolveGetAllDiagnose', {
          ...state.dashboard.filter,
        });
      }
      return postDiagnoseRes.status;
    } catch (error) {
      return error;
    }
  },
  // End Post Data

  // Put Data
  async resolvePutDiagnose({ state, commit, dispatch }) {
    const payload = {
      ...state.form,
    };
    delete payload.id;

    try {
      const putDiagnoseRes = await putDiagnose(state.form.id, payload);
      if (putDiagnoseRes.status === 200) {
        commit('setDialog', false);
        commit('clearForm');
        await dispatch('resolveGetAllDiagnose', {
          ...state.dashboard.filter,
        });
      }
      return putDiagnoseRes.status;
    } catch (error) {
      return error;
    }
  },
  // End Put Data

  // Delete Data
  async resolveDeleteDiagnose(_, id) {
    try {
      const responseDeleteDiagnose = await deleteDiagnose(id);
      return responseDeleteDiagnose;
    } catch (error) {
      return error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
