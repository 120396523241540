<template>
  <v-card :class="`${drawer ? 'show' : 'hide'}`" light elevation="0">
    <div class="d-flex justify-space-between align-center">
      <v-tabs>
        <v-tab style="letter-spacing: 0.001px;" class="text-capitalize"
          >Riwayat CPPT</v-tab
        >
      </v-tabs>
      <v-btn @click="close" text small fab
        ><v-icon color="#757575">mdi-arrow-right</v-icon></v-btn
      >
    </div>
    <v-container class="list-wrapper" fluid>
      <v-expansion-panels multiple accordion flat focusable light>
        <v-expansion-panel
          v-for="(item, index) in history"
          :key="`history-${index}`"
          class="mb-2"
          expand
          focusable
          ><v-expansion-panel-header
            class="rounded-lg"
            color="#f5f5f5"
            style="color: #404040 !important; font-weight: 500;"
            >{{ item.date }}</v-expansion-panel-header
          >
          <v-expansion-panel-content class="rounded-lg">
            <v-card light flat color="#f5f5f5" class="pa-4">
              <v-card
                class="mb-5"
                v-for="(v, i) in item.history"
                :key="`change-${i}`"
                light
                flat
                elevation="0"
                tile
              >
                <div
                  class="d-flex py-2 px-4"
                  style="border-bottom: 1px solid #ededed;"
                >
                  <span>{{ `${v.dateHistory}, ${v.time}` }}</span
                  ><span
                    ><ul>
                      <li class="text-capitalize">
                        {{ `${v.ppa.name}, ${v.ppa.role}` }}
                      </li>
                    </ul></span
                  >
                </div>
                <div class="text-start py-2 px-4">
                  <v-chip class="chip" v-if="v.isEdited"
                    >Terakhir diubah pada tanggal {{ v.edited_at.date }}, pukul
                    {{ v.edited_at.time }}</v-chip
                  >
                  <h4>INFORMASI UMUM</h4>
                  <v-row>
                    <v-col>
                      <label>PPA</label>
                      <p>{{ v.ppa.name }}</p>
                      <label>No Bed</label>
                      <p>{{ v.bed }}</p>
                    </v-col>
                    <v-col
                      ><label>Tanggal & Jam</label>
                      <p>{{ `${v.dateHistory}, ${v.time}` }}</p></v-col
                    >
                  </v-row>
                  <h4>DATA SUBJEKTIF</h4>
                  <label>Anamnesis</label>
                  <p v-html="v.anamnesis"></p>
                  <h4>DATA OBJEKTIF</h4>
                  <v-row>
                    <v-col>
                      <label>Tekanan Darah</label>
                      <p>
                        {{
                          `${v.objective.sistole}/${v.objective.diastole} mmHg`
                        }}
                      </p>
                      <label>Suhu</label>
                      <p>{{ v.objective.temperature }} &deg;C</p>
                      <label>Nadi</label>
                      <p>{{ v.objective.heartRate }} x/menit</p>
                      <label>RR</label>
                      <p>{{ v.objective.rr }} x/menit</p>
                    </v-col>
                    <v-col>
                      <label>Saturasi</label>
                      <p>{{ v.objective.saturation }} %</p>
                      <label>GDS</label>
                      <p>
                        {{ v.objective.gds }}
                      </p>
                      <label>Data Objektif Lainnya</label>
                      <p v-html="v.objective.other"></p>
                    </v-col>
                  </v-row>
                  <h4>ASSESMEN & PLAN</h4>
                  <v-row
                    class="mb-1"
                    v-if="
                      v.ppa.role.some(item => item.toLowerCase() === 'dokter')
                    "
                  >
                    <v-col>
                      <label>Diagnosa (ICD 10)</label>
                      <p
                        v-if="
                          !v.assessment.diagnose?.length ||
                            !v.assessment.diagnose?.[0]
                        "
                      >
                        -
                      </p>
                      <ul v-else class="mb-4">
                        <li
                          v-for="(diagnose, idx) in v.assessment.diagnose"
                          :key="`diagnose-${idx}`"
                        >
                          {{ diagnose }} ({{ v.assessment.icd[idx] }})
                        </li>
                      </ul>
                      <label>Rencana Tindakan</label>
                      <p
                        v-if="
                          !v.assessment.planning?.length ||
                            !v.assessment.planning?.[0]
                        "
                      >
                        -
                      </p>
                      <ul v-else>
                        <li
                          v-for="(i, index) in v.assessment.planning"
                          :key="`planning-doctor-${index}`"
                        >
                          {{ i }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col>
                      <label>Catatan</label>
                      <p v-html="v.assessment.note"></p>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col>
                      <p
                        v-if="
                          !v.assessment.diagnose || !v.assessment.diagnose?.[0]
                        "
                      >
                        -
                      </p>
                      <div
                        v-for="(x, idx) in v.assessment.diagnose"
                        :key="`diagnose-nurse-${idx}`"
                      >
                        <label class="sub-title">Diagnosa {{ idx + 1 }}</label>
                        <p>{{ x.name }}</p>
                        <p class="sub-title mb-0">Rencana Tindakan</p>
                        <p v-if="!x.planning?.length || !x.planning?.[0]">-</p>
                        <ul v-else>
                          <li
                            v-for="(r, planningIdx) in x.planning"
                            :key="`planning-${planningIdx}-diagnose-${idx}`"
                          >
                            {{ r }}
                          </li>
                        </ul>
                      </div>
                    </v-col>
                    <v-col>
                      <label>Catatan</label>
                      <p v-html="v.assessment.note"></p>
                    </v-col>
                  </v-row>
                  <h4>INSTRUKSI PPA</h4>
                  <label
                    >Instruksi Tenaga
                    {{
                      v.ppa.role.some(item => item.toLowerCase() === 'perawat')
                        ? 'Kesehatan'
                        : 'Medis'
                    }}</label
                  >
                  <p v-html="v.ppaInstruction"></p>
                </div>
              </v-card>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('CPPTStore');
export default {
  name: 'HistoryCPPTNew',
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['getHistory']),
    history: {
      get() {
        return this.getHistory;
      },
    },
  },
  mounted() {
    this.resolveGetCpptHistory();
  },
  methods: {
    ...mapActions(['resolveGetCpptHistory']),
    close() {
      this.$emit('close-history');
    },
  },
};
</script>

<style lang="scss" scoped>
.show {
  display: inline;
  font-size: 0.75vw;
}
.hide {
  display: none;
}

.list-wrapper {
  max-height: 69vh;
  max-width: 33vw;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 9px;
    background-color: #ededed;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 9px;
    background-color: #9e9e9e;
  }
  h4 {
    font-weight: 400;
    color: #137bc0;
    margin-bottom: 0.4vw;
  }

  label {
    color: #757575;
  }

  p {
    color: black;
    margin-bottom: 0.5vw;
  }
}

.chip {
  font-size: 0.7vw;
  margin-bottom: 0.4vw;
  background-color: #f5f5f5;
}

.col {
  max-width: 15vw;
}
</style>
