<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Jurnal Transaksi</h3>
      <v-btn
        outlined
        color="primary"
        class="text-none entry-style"
        @click="onClicked(1)"
        >Masukkan Entry</v-btn
      >
    </v-card-title>
    <v-row no-gutters class="pa-5" style="height: 71vh;">
      <v-col cols="4" class="px-5 mx-5">
        <v-form class="form-style" v-model="valid" ref="form">
          <label class="font-weight-bold body-2 pb-3">Kode Jurnal</label>
          <label class="body-2">{{ input.journalCode }}</label>

          <label class="font-weight-bold body-2">Tipe Transaksi</label>
          <v-select
            placeholder="Pilih Tipe Transaksi"
            dense
            :items="resource.transactionType"
            v-model="input.transactionType"
            :rules="rules.transactionType"
          ></v-select>

          <label class="font-weight-bold body-2">Tipe Jurnal</label>
          <v-select
            placeholder="Pilih Tipe Jurnal"
            dense
            :items="resource.journalType"
            v-model="input.journalType"
            :rules="rules.journalType"
          ></v-select>

          <label class="font-weight-bold body-2">Unit</label>
          <v-select
            placeholder="Pilih Unit"
            dense
            item-text="name"
            return-object
            :items="resource.unit"
            :loading="loading"
            v-model="input.unit"
            :rules="rules.unit"
          ></v-select>

          <label class="font-weight-bold body-2">No. Bukti</label>
          <v-text-field
            placeholder="Masukkan No Bukti"
            dense
            v-model="input.invoiceNumber"
            :rules="rules.invoice"
          ></v-text-field>

          <label class="font-weight-bold body-2">Uraian</label>
          <v-textarea
            placeholder="Masukkan Uraian"
            outlined
            dense
            rows="2"
            no-resize
            v-model="input.detail"
            :rules="rules.description"
          ></v-textarea>

          <label class="font-weight-bold body-2">Tanggal Transaksi</label>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatedDate"
                placeholder="Pilih Tanggal"
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :max="today"
              v-model="input.transDate"
              @change="
                menu = false;
                checkBackdate();
              "
            />
          </v-menu>

          <label class="font-weight-bold body-2">Catatan</label>
          <label>{{ input.backdateReason }}</label>
          <!-- <v-textarea
            placeholder="Masukkan Catatan"
            outlined
            dense
            rows="2"
            no-resize
            hide-details="false"
          ></v-textarea> -->
        </v-form>
      </v-col>

      <v-col cols="7">
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          :items-per-page="table.items.length"
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-left px-4">
                {{ table.items.indexOf(item) + 1 }}
              </td>
              <td class="text-left px-4">{{ item.code }}</td>
              <td class="text-left px-4">{{ item.name }}</td>
              <td class="text-right px-4">{{ formatted(item.debit) }}</td>
              <td class="text-right px-4">{{ formatted(item.credit) }}</td>
            </tr>
          </template>
          <template v-slot:footer>
            <div class="font-weight-bold total-style body-2">
              <label>TOTAL</label>
              <label>{{ formatted(totalDebit) }}</label>
              <label>{{ formatted(totalCredit) }}</label>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col class="px-5 mx-5">
        <v-row no-gutters class="pt-5">
          <v-col cols="2">
            <div class="text-left">
              <v-btn
                class="text-none"
                color="primary"
                outlined
                depressed
                @click="addFiles()"
                ><v-icon>mdi-file-upload</v-icon>Upload Dokumen</v-btn
              >
              <br />
              <label class="caption grey--text"
                >jenis file .pdf, .xlsx, .jpg, .jpeg
                <strong>max 2MB</strong></label
              >
              <input
                type="file"
                ref="files"
                accept="image/png, image/jpeg, application/pdf, application/xlsx"
                multiple
                v-on:change="handleFilesUpload()"
                v-show="false"
              />
            </div>
          </v-col>
          <v-col cols="6" class="files-style">
            <v-row no-gutters>
              <template v-for="(arrs, fileIndex) in document.files">
                <v-col cols="6" class="pb-2 pr-2" :key="'file-' + fileIndex">
                  <v-card outlined class="pa-1" @click="openFile(arrs)">
                    <v-row no-gutters align="center">
                      <v-col cols="1">
                        <v-icon
                          v-if="arrs.type.search(/.(jpeg|jpg|png)/i) !== -1"
                          color="primary"
                          small
                          >mdi-file-image</v-icon
                        >
                        <v-icon
                          v-else-if="arrs.type.search(/.pdf/i) !== -1"
                          color="primary"
                          small
                          >mdi-file-pdf-box</v-icon
                        >
                        <v-icon v-else class="icon">mdi-file-code</v-icon>
                      </v-col>
                      <v-col
                        cols="10"
                        class="text-left px-2 d-flex justify-space-between align-center"
                      >
                        <label class="caption font-weight-bold">{{
                          arrs.name
                        }}</label>
                        <span
                          class="grey--text caption text-right"
                          style="width: 35%;"
                          >({{ formatSize(arrs.size) }})</span
                        >
                      </v-col>
                      <v-col cols="1">
                        <v-btn icon @click="removeFiles(fileIndex)" x-small
                          ><v-icon>mdi-delete</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </template>
              <template v-for="(item, fileIndex) in document.failedFiles">
                <v-col
                  cols="6"
                  class="pb-2 pr-2"
                  :key="'failedFile-' + fileIndex"
                >
                  <v-card outlined class="pa-1">
                    <v-row no-gutters align="center">
                      <v-col cols="1">
                        <v-icon
                          v-if="
                            item.file.type.search(/.(jpeg|jpg|png)/i) !== -1
                          "
                          color="primary"
                          small
                          >mdi-file-image</v-icon
                        >
                        <v-icon
                          v-else-if="item.file.type.search(/.pdf/i) !== -1"
                          color="primary"
                          small
                          >mdi-file-pdf-box</v-icon
                        >
                        <v-icon v-else class="icon">mdi-file-code</v-icon>
                      </v-col>
                      <v-col
                        cols="10"
                        class="px-2 text-left d-flex justify-space-between align-center"
                      >
                        <label class="caption font-weight-bold">{{
                          item.file.name
                        }}</label>
                        <span
                          class="red--text caption text-right"
                          style="width: 35%;"
                          >({{ formatSize(item.file.size) }})</span
                        >
                      </v-col>
                      <v-col cols="1">
                        <button
                          class="btn-back"
                          @click="removeWarning(fileIndex)"
                        >
                          <v-icon color="red" small>mdi-close-circle</v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </v-card>
                  <div class="text-left">
                    <v-icon color="red" small>mdi-alert-circle</v-icon>
                    <label class="caption red--text">
                      File gagal diupload karena melebihi 2MB!</label
                    >
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-card-actions class="pb-6">
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="primary"
        class="text-none"
        @click="$emit('close-dialog')"
        >Batal</v-btn
      >
      <v-btn
        color="primary"
        class="text-none"
        depressed
        @click="saveJournal()"
        :disabled="!valid"
        >Simpan</v-btn
      >
    </v-card-actions>
    <v-dialog
      v-model="dialog"
      :width="selectedForm === 0 ? 512 : 1350"
      persistent
    >
      <v-card v-if="selectedForm === 0">
        <v-card-title class="justify-center py-5">
          <h3>Permohanan Jurnal Backdate</h3>
        </v-card-title>
        <v-divider />
        <v-row no-gutters style="display: grid;" class="text-left pa-5">
          <label class="font-weight-bold pb-3">Alasan :</label>
          <v-textarea
            placeholder="Tuliskan Alasan"
            outlined
            dense
            rows="5"
            no-resize
            v-model="backdateNote"
            :rules="rules.reason"
          ></v-textarea>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            width="112"
            outlined
            color="primary"
            class="text-none"
            @click="dialog = false"
            >Kembali</v-btn
          >
          <v-btn
            width="112"
            color="primary"
            class="text-none"
            depressed
            :disabled="backdateNote === ''"
            @click="saveBackdate()"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-card>
      <EntryJournal
        v-else-if="selectedForm === 1 && dialog"
        @close-dialog="dialog = false"
        @entry-table="entryTable"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import EntryJournal from './EntryJournal.vue';
import Constant from '@/const';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');

export default {
  name: 'TransactionJournal',
  mixins: [alertMixin],
  components: {
    EntryJournal,
  },
  props: {
    isCorrection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Kode Akun', value: 'code' },
          { text: 'Nama Akun', value: 'name' },
          { text: 'Debit', value: 'debit', class: 'text-center' },
          { text: 'Kredit', value: 'credit', class: 'text-center' },
        ],
        items: [],
      },
      today: moment(new Date()).format('YYYY-MM-DD'),
      document: {
        files: [],
        failedFiles: [],
      },
      dialog: false,
      selectedForm: null,
      resource: {
        transactionType: [
          'Transfer',
          'Utang',
          'Piutang',
          'Pendapatan',
          'Beban',
        ],
        journalType: ['Jurnal Umum', 'Jurnal Penutup', 'Jurnal Penyesuaian'],
        unit: [],
      },
      loading: false,
      input: {
        journalCode: '',
        transactionType: '',
        journalType: '',
        unit: '',
        invoiceNumber: '',
        detail: '',
        transDate: moment(new Date()).format('YYYY-MM-DD'),
        backdateReason: '',
        value: 0,
      },
      backdateNote: '',
      rules: {
        transactionType: [v => !!v || 'Pilih Tipe Transaksi'],
        journalType: [v => !!v || 'Pilih Tipe Jurnal'],
        unit: [v => !!v || 'Pilih Unit'],
        invoice: [v => !!v || 'Masukkan nomor bukti'],
        description: [v => !!v || 'Masukkan uraian'],
        reason: [v => !!v || 'Masukkan alasan'],
      },
      valid: true,
    };
  },
  mounted() {
    this.getUnit();
    this.getJournalCode();
  },
  computed: {
    formatedDate() {
      return this.input.transDate
        ? moment(this.input.transDate)
            .locale('id')
            .format('DD MMMM YYYY')
        : null;
    },
    totalDebit() {
      return this.table.items.reduce((a, { debit }) => a + debit, 0);
    },
    totalCredit() {
      return this.table.items.reduce((a, { credit }) => a + credit, 0);
    },
  },
  methods: {
    checkBackdate() {
      if (this.input.transDate < this.today) {
        Swal.fire({
          title: 'Peringatan',
          text: 'Jurnal ini harus disetujui Wakil Direktur bidang Keuangan',
          icon: 'warning',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Lanjutkan',
          cancelButtonText: 'Kembali',
          confirmButtonColor: '#EB5757',
          reverseButtons: true,
        }).then(result => {
          if (result) {
            this.onClicked(0);
          }
        });
      }
    },
    onClicked(idx) {
      this.selectedForm = idx;
      this.dialog = true;
    },
    addFiles() {
      this.$refs.files.click();
    },
    async submitFiles(identifier) {
      const formData = new FormData();

      for (let i = 0; i < this.document.files.length; i++) {
        const file = this.document.files[i];

        formData.append(`files`, file);
      }
      formData.append('identifier', identifier);
      formData.append('tag', 'journal');
      formData.append('created_by', this.$store.getters.userLoggedIn.id);

      await axios
        .post(Constant.apiUrl.concat('/upload'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    handleFilesUpload() {
      const filesUploaded = this.$refs.files.files;
      for (let i = 0; i < filesUploaded.length; i++) {
        if (this.verify(filesUploaded[i]) === '') {
          this.document.files.push(filesUploaded[i]);
        } else {
          this.document.failedFiles.push({
            file: filesUploaded[i],
            message: this.verify(filesUploaded[i]),
          });
        }
      }

      this.$refs.files.value = '';
    },
    verify(file) {
      const maxSize = 2000000;
      const allowedTypes = [
        'image/png',
        'image/jpeg',
        'application/pdf',
        'application/xlsx',
      ];
      if (file.size > maxSize) {
        return 'Maksimum Ukuran file 2MB';
      }

      const ext = file.name.split('.');

      if (!allowedTypes.includes(file.type)) {
        return `Tipe file .${ext[1]} tidak diperbolehkan`;
      }

      return '';
    },
    removeFiles(fileIndex) {
      this.document.files.splice(fileIndex, 1);
    },
    removeWarning(fileIndex) {
      this.document.failedFiles.splice(fileIndex, 1);
    },
    saveBackdate() {
      this.input.backdateReason = this.backdateNote;
      Swal.fire({
        title: 'Permohonan Jurnal Backdate Berhasil Disimpan',
        icon: 'success',
        confirmButtonText: 'Selesai',
      });
      this.dialog = false;
    },
    saveJournal() {
      if (this.$refs.form.validate()) {
        if (this.isCorrection) {
          Swal.fire({
            title: 'Koreksi Jurnal Transaksi Berhasil Disimpan',
            icon: 'success',
            confirmButtonText: 'Selesai',
          });
          this.$emit('close-dialog');
        } else {
          // const formData = new FormData();
          // // this.document.files.forEach(arr => {
          // //   formData.append('files', arr);
          // // });

          // formData.append('journal_code', this.input.journalCode);
          // formData.append('transaction_type', this.input.transactionType);
          // formData.append('journal_type', this.input.journalType);
          // formData.append('unit', this.input.unit ? this.input.unit.name : '');
          // formData.append('proof_code', this.input.invoiceNumber);
          // formData.append('description', this.input.detail);
          // formData.append('date', this.input.transDate);
          // formData.append('backdate_reason', this.input.backdateReason);
          // formData.append('transaction_value', this.totalDebit);
          // this.table.items.forEach((arr, idx) => {
          //   formData.append(`entries[${idx}][id_accountBalance]`, arr.id);
          //   formData.append(`entries[${idx}][debit]`, arr.debit);
          //   formData.append(`entries[${idx}][type]`, arr.type);
          //   formData.append(`entries[${idx}][name]`, arr.typeName.name);
          //   formData.append(`entries[${idx}][credit]`, arr.credit);
          // });
          // formData.append(
          //   'timestamps[created_by]',
          //   this.$store.getters.userLoggedIn.id,
          // );
          const formData = {
            journal_code: this.input.journalCode,
            transaction_type: this.input.transactionType,
            journal_type: this.input.journalType,
            unit: this.input.unit ? this.input.unit.name : '',
            proof_code: this.input.invoiceNumber,
            description: this.input.detail,
            date: this.input.transDate,
            backdate_reason: this.input.backdateReason,
            transaction_value: this.totalDebit,
            entries: this.table.items.map(arr => {
              return {
                id_accountBalance: arr.id,
                debit: arr.debit,
                type: arr.type,
                name: arr.typeName.name,
                credit: arr.credit,
              };
            }),
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          };

          axios
            .post(Constant.apiUrl.concat(`/finance/journal/`), formData)
            .then(response => {
              const { _id: id } = response.data.data;
              this.submitFiles(id);
              Swal.fire({
                title: 'Jurnal Transaksi Berhasil Disimpan',
                icon: 'success',
                confirmButtonText: 'Selesai',
              });
              this.$emit('close-dialog');
            })
            .catch(err => {
              this.showErrorAxios(err);
            });
        }
      }
    },
    getUnit() {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=1&itemCount=&search=&sort=name',
          ),
        )
        .then(response => {
          const { data } = response.data;
          this.resource.unit = data.map(arr => {
            return {
              name: arr.name,
              id: arr._id,
            };
          });
          this.loading = false;
        });
    },
    openFile(item) {
      const file = URL.createObjectURL(item);
      window.open(file);
    },
    formatSize(size) {
      return size < 1000000
        ? `${(size / 1000).toFixed(1)} KB`
        : `${(size / 1000000).toFixed(1)} MB`;
    },
    entryTable(val) {
      const data = val.map(arr => {
        return {
          code: arr.accountCode.code,
          id: arr.accountCode.id,
          name: arr.accountCode.name,
          type: arr.type,
          typeName: arr.name,
          debit: arr.debit,
          credit: arr.credit,
        };
      });
      data.forEach(item => {
        this.table.items.push(item);
      });
    },
    formatted(val) {
      var format = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      });
      return val ? format.format(val) : format.format(0);
    },
    getJournalCode() {
      axios
        .get(Constant.apiUrl.concat('/finance/journal/code'))
        .then(response => {
          this.input.journalCode = response.data.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.entry-style {
  border-width: 2px;
  position: absolute;
  right: 2vw;
}
.form-style {
  display: grid;
  grid-template-columns: 12vw 15vw;
  text-align: left;
  align-items: baseline;
}
.files-style {
  height: 10vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: gray;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}
.total-style {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  background-color: #f0f9ff;
}
</style>
