<template>
  <div class="storage-request">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Permintaan Barang Gudang
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>
          <v-btn @click="show = !show" text>
            <span class="text-capitalize">Filters</span>
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
          <v-btn class="mr-2" color="grey" icon text @click="refresh()">
            <v-icon :class="{ loadingrefresh: isLoadingRefresh }"
              >mdi-autorenew</v-icon
            >
          </v-btn>
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <div class="filter-menu mt-4" v-show="show">
        <v-row no-gutters justify="center">
          <v-col cols="2" v-show="isWarehouse">
            <v-combobox
              :items="unit"
              :loading="loading"
              item-value="id"
              item-text="name"
              v-model="unitFrom"
              outlined
              dense
              clearable
              class="mr-4"
              hide-details="auto"
              label="Unit"
              @change="getRequest()"
            ></v-combobox>
          </v-col>
          <v-col cols="3"
            ><v-menu
              v-model="datePicker1"
              dense
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="reqDate"
                  outlined
                  dense
                  class="mr-4"
                  hide-details="auto"
                  label="Tanggal Permintaan"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="minReqDate"
                :max="maxReqDate"
                range
                v-model="inputReqDate"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="
                    inputReqDate = [];
                    getRequest();
                  "
                >
                  Reset
                </v-btn>
              </v-date-picker>
            </v-menu></v-col
          >
          <v-col cols="3"
            ><v-menu
              dense
              v-model="datePicker2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  class="mr-4"
                  hide-details="auto"
                  label="Tanggal Konfirmasi"
                  v-bind="attrs"
                  v-on="on"
                  v-model="confirmDate"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="minConfirmDate"
                :max="maxConfirmDate"
                range
                no-title
                v-model="inputConfirmDate"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="
                    inputConfirmDate = [];
                    getRequest();
                  "
                >
                  Reset
                </v-btn>
              </v-date-picker>
            </v-menu></v-col
          >
          <v-col cols="1" align-self="center">
            <v-btn medium color="error" @click="resetFilter()">Reset</v-btn>
          </v-col>
        </v-row>
      </div>

      <v-container fluid>
        <v-row>
          <v-col>
            <v-data-table
              class="flex-grow-1"
              :headers="table.headers"
              :items="table.items"
              :height="height"
              :items-per-page="itemsPerRow"
              :page.sync="table.pagination.page"
              :loading="loading"
              hide-default-footer
            >
              <template v-slot:[`item.noQueue`]="{ item }">
                <td>
                  {{
                    (table.pagination.page - 1) * itemsPerRow +
                      table.items.indexOf(item) +
                      1
                  }}
                </td>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-card
                  color="#FFF2F2"
                  v-if="item.status === 'requested'"
                  class="text-none text-capitalize text-center orange--text"
                  flat
                  width="80"
                  height="25"
                  >{{ item.status }}</v-card
                >
                <v-card
                  color="#F0F9FF"
                  v-else-if="item.status === 'accepted'"
                  class="text-none text-capitalize text-center primary--text"
                  flat
                  width="80"
                  height="25"
                  >{{ item.status }}</v-card
                >
                <v-card
                  color="#FFF9E5"
                  v-else-if="item.status === 'rejected'"
                  class="text-none text-capitalize text-center red--text"
                  flat
                  width="80"
                  height="25"
                  >{{ item.status }}</v-card
                >
                <v-card
                  color="#E5FFF0"
                  v-else-if="item.status === 'received'"
                  class="text-none text-capitalize text-center green--text"
                  flat
                  width="80"
                  height="25"
                  >{{ item.status }}</v-card
                >
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      icon
                      class="hover"
                      @click="selectItem(0, item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Detail Permintaan</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.delete`]="{ item }">
                <v-tooltip bottom v-if="item.status === 'requested'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      icon
                      class="hover"
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteReq(item)"
                      v-if="!isWarehouse"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Batal Pesan</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <Snackbar
      :show="snackbar.show"
      :color="snackbar.color"
      :text="snackbar.text"
      :close="
        () => {
          clearSnackbar();
        }
      "
    />

    <v-footer color="white">
      <v-container>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="pagination"
            total-visible="7"
            @input="getRequest()"
          ></v-pagination>
          <v-spacer></v-spacer>

          <v-btn color="primary" fab dark @click="selectItem(1, null)">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-footer>
    <v-dialog v-model="dialog" width="1300" :fullscreen="selectedForm === 0">
      <DetailStorageRequest
        v-if="selectedForm === 0 && dialog"
        :selected-item="selectedItem"
        @close-dialog="closeDialog()"
      />
      <InputStorageRequest
        v-else-if="selectedForm === 1 && dialog"
        @close-dialog="closeDialog()"
      />
    </v-dialog>
  </div>
</template>

<script>
import DetailStorageRequest from './StorageRequestComponent/DetailStorageRequest.vue';
import InputStorageRequest from './StorageRequestComponent/InputStorageRequest.vue';
import jwtMixin from '@/mixin/jwtMixin';
import Constant from '@/const';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import alertMixin from '@/mixin/alertMixin';
import { Snackbar } from '@/components/SharedComponent';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'StorageRequestStore',
);

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'StorageRequest',
  mixins: [jwtMixin, alertMixin],
  components: { InputStorageRequest, DetailStorageRequest, Snackbar },
  data() {
    return {
      isLoadingRefresh: false,
      dialog: false,
      detailDialog: false,
      show: false,
      inputReqDate: [],
      inputConfirmDate: [],
      unitFrom: '',
      datePicker1: false,
      datePicker2: false,
      unit: [],
      table: {
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 5,
          totalItems: null,
        },
        headers: [
          { text: 'No.', value: 'noQueue', align: 'center' },
          { text: 'Tanggal Permintaan', value: 'requestDate' },
          { text: 'Tanggal Konfirmasi', value: 'confirmDate' },
          { text: 'Dari', value: 'from.name' },
          { text: 'Ke', value: 'to.name' },
          { text: 'User', value: 'user' },
          { text: 'Status', value: 'status' },
          { text: '', value: 'actions', sortable: false, width: '5%' },
          { text: '', value: 'delete', sortable: false },
        ],
        items: [],
      },
      selectedForm: null,
      pagination: 0,
      search: '',
      selectedItem: '',
      loading: false,
    };
  },
  mounted() {
    this.getUnit();
    this.getRequest();
    this.clearSnackbar();
  },
  computed: {
    ...mapGetters(['getSnackbar']),
    snackbar: {
      get() {
        return this.getSnackbar;
      },
    },
    isWarehouse() {
      return this.$store.getters.userLoggedIn.is_warehouse;
    },
    userUnit() {
      return this.$store.getters.userLoggedIn.unit_id;
    },
    reqDate() {
      let text = '';
      if (this.inputReqDate.length > 0) {
        text = this.inputReqDate.join(' - ');
      }
      return text;
    },
    minReqDate() {
      let date = '';
      if (this.inputReqDate.length > 0) {
        date = this.inputReqDate[0];
      }
      return date;
    },
    maxReqDate() {
      let date = '';
      if (this.inputReqDate.length > 0) {
        date = this.inputReqDate[1];
      }
      return date;
    },
    confirmDate() {
      let text = '';
      if (this.inputConfirmDate.length > 0) {
        text = this.inputConfirmDate.join(' - ');
      }
      return text;
    },
    minConfirmDate() {
      let date = '';
      if (this.inputConfirmDate.length > 0) {
        date = this.inputConfirmDate[0];
      }
      return date;
    },
    maxConfirmDate() {
      let date = '';
      if (this.inputConfirmDate.length > 0) {
        date = this.inputConfirmDate[1];
      }
      return date;
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '60vh';
          break;
        case 'lg':
          height = '63vh';
          break;
        case 'xl':
          height = '70vh';
          break;
      }
      return height;
    },
  },
  watch: {
    search() {
      this.searchData(this);
    },
    inputReqDate() {
      if (this.inputReqDate.length > 0) {
        this.table.pagination.page = 1;
        this.getRequest();
      }
    },
    inputConfirmDate() {
      if (this.inputConfirmDate.length > 0) {
        this.table.pagination.page = 1;
        this.getRequest();
      }
    },
  },
  methods: {
    ...mapMutations(['clearSnackbar']),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.table.pagination.page = 1;
      v.getRequest();
    }, 250),
    refresh() {
      this.isLoadingRefresh = true;
      this.getRequest();
    },
    selectItem(val, item) {
      if (item) {
        this.selectedItem = {
          id: item.id,
          created_at: item.requestDate,
          code: item.code,
          from: item.from,
          to: item.to,
          status: item.status,
        };
      }
      this.selectedForm = val;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.getRequest();
    },
    resetFilter() {
      (this.inputReqDate = []), (this.inputConfirmDate = []);
      if (this.isWarehouse) {
        this.unitFrom = '';
      }
      this.getRequest();
    },
    getUnit() {
      this.loading = true;
      axios
        .get(Constant.apiUrl.concat(`/master/unit`))
        .then(response => {
          const data = response.data.data;

          this.unit = data.map(v => {
            return {
              id: v._id,
              name: v.name,
            };
          });
        })
        .catch(() => {
          this.unit = [];
        })
        .finally(() => {
          this.dialog = false;
        });
    },
    getRequest() {
      this.loading = true;
      const keyword = this.search;
      const itemCount = this.itemsPerRow;
      let unit;
      if (this.isWarehouse) {
        unit = this.unitFrom?.id || '';
      } else {
        unit = this.userUnit;
      }
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/request?page=${
              this.table.pagination.page
            }&itemCount=${itemCount}&search=${keyword ||
              ''}&unitFrom=${unit}&startDate=${this.inputReqDate[0] ||
              ''}&endDate=${this.inputReqDate[1] || ''}&startConfirmDate=${this
              .inputConfirmDate[0] || ''}&endConfirmDate=${this
              .inputConfirmDate[1] || ''}`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          const { length: dataLength } = response.data;
          this.pagination = Math.ceil(dataLength / itemCount);
          this.table.items = data.map(arr => {
            return {
              id: arr._id,
              requestDate: moment(
                arr.timestamps.created_at,
                'YYYY-MM-DD',
              ).format('DD/MM/YYYY'),
              confirmDate: arr.confirmation_date
                ? moment(arr.confirmation_date).format('DD/MM/YYYY')
                : '-',
              from: {
                name: arr.clinic.name,
                id: arr.clinic._id,
              },
              to: {
                name: arr.warehouse.name,
                id: arr.warehouse._id,
              },
              user: arr.staff.detail.name,
              status: arr.status,
              code: arr.code,
            };
          });
          this.loading = false;
        })
        .catch(() => {
          this.table.items = [];
          this.pagination = 1;
          this.loading = false;
        })
        .finally(() => {
          this.Loading = false;
          this.isLoadingRefresh = false;
        });
    },
    deleteReq(data) {
      Swal.fire({
        title: 'Yakin?',
        text: `Anda akan menghapus ini?'`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.value) {
          axios
            .delete(Constant.apiUrl.concat(`/stock/request/${data.id}`))
            .then(response => {
              if (response.status === 200) {
                Swal.fire(`Pesanan Berhasil Dihapus!`, '', 'success');
                this.getRequest();
              }
            })
            .catch(err => {
              this.showErrorAxios(err);
            });
        }
      });
    },
  },
  beforeDestroy() {
    this.clearSnackbar();
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 30%;
}

.loadingrefresh {
  -webkit-animation: fa-spin 0.2s infinite linear;
  -moz-animation: fa-spin 0.2s infinite linear;
  -o-animation: fa-spin 0.2s infinite linear;
  animation: fa-spin 0.2s infinite linear;
}
</style>
