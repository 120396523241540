<template>
  <v-form :value="isValid" ref="form">
    <v-row class="px-15 mb-2">
      <v-col class="grid">
        <label class="required">Tindakan yang Dilakukan</label>
        <v-combobox
          dense
          single-line
          clearable
          v-if="!isDetail && isEditable"
          v-model="signIn.action"
          :items="resource.actions"
          @change="onChangeActionEvent"
          :rules="rules"
          item-text="text"
          append-icon="mdi-chevron-down"
          placeholder="Pilih Tindakan yang Dilakukan"
        />
        <v-text-field
          dense
          light
          hide-details
          readonly
          v-else
          :value="signIn.action.text"
        />
      </v-col>
      <v-col></v-col>
    </v-row>
    <div class="px-15 mb-5">
      <v-divider></v-divider>
    </div>
    <v-row class="px-15">
      <v-col class="grid">
        <label class="required">Waktu Pelaksanaan</label>
        <div class="d-flex">
          <v-text-field
            dense
            readonly
            v-model="signIn.time.date"
            class="mr-2"
          />
          <v-text-field
            dense
            :rules="[...rules, ...timeRule]"
            :readonly="isDetail || !isEditable"
            @input="formatTime(signIn.time.time)"
            v-model="signIn.time.time"
            placeholder="00:00"
            class="ml-2"
          />
        </div>
        <label class="required">Petugas yang Mengisi</label>
        <v-autocomplete
          dense
          single-line
          :rules="rules"
          :loading="isLoading.doctor"
          :items="resource.doctor.concat(resource.staff)"
          @change="onChangeSignInStaffFillEvent"
          :clearable="isEditable"
          :readonly="!isEditable"
          v-model="signIn.staffFill"
          item-text="name"
          append-icon="mdi-chevron-down"
          placeholder="Petugas yang Mengisi"
          return-object
        />
      </v-col>
      <v-col class="grid">
        <label class="required">Dokter Pelaksana Tindakan</label>
        <v-autocomplete
          dense
          single-line
          :clearable="isEditable"
          :readonly="!isEditable"
          :rules="rules"
          :loading="isLoading.doctor"
          :items="resource.doctor"
          v-model="signIn.doctor"
          @change="onChangeSignInDoctorEvent"
          item-text="name"
          append-icon="mdi-chevron-down"
          placeholder="Dokter Pelaksana Tindakan"
          return-object
        />
        <label class="required">Petugas Pendamping</label>
        <v-autocomplete
          dense
          single-line
          :clearable="isEditable"
          :rules="rules"
          :readonly="!isEditable"
          :loading="isLoading.doctor"
          :items="resource.staff"
          v-model="signIn.staff"
          @change="onChangeSignInStaffEvent"
          item-text="name"
          append-icon="mdi-chevron-down"
          placeholder="Petugas Pendamping"
          return-object
        />
      </v-col>
    </v-row>
    <div class="px-15 my-5">
      <v-divider></v-divider>
    </div>
    <v-row class="px-15">
      <v-col class="grid">
        <label class="required pr-15"
          >Apakah pasien telah dikonfirmasi nama, sisi operasi, dan informed
          consent?</label
        >
        <v-radio-group
          row
          :rules="rules"
          :readonly="!isEditable"
          v-model="signIn.dataConfirmation"
        >
          <v-radio label="Sudah" :value="true"></v-radio>
          <v-radio label="Belum" :value="false"></v-radio>
        </v-radio-group>
        <label class="required pr-15"
          >Apakah alat anestesi dan pengobatan telah diperiksa lengkap?</label
        >
        <v-radio-group
          row
          :readonly="!isEditable"
          :rules="rules"
          v-model="signIn.isAnestheticDeviceComplitelyChecked"
        >
          <v-radio label="Sudah" :value="true"></v-radio>
          <v-radio label="Belum" :value="false"></v-radio>
        </v-radio-group>
        <label class="required pr-15"
          >Apakah area operasi sudah ditandai?</label
        >
        <v-radio-group
          :readonly="!isEditable"
          :rules="rules"
          row
          v-model="signIn.isOperatingAreaMarked"
        >
          <v-radio label="Sudah" :value="true"></v-radio>
          <v-radio label="Belum" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col>
        <div class="grid">
          <label>Riwayat Alergi</label>
          <label class="font-weight-medium">{{
            Array.isArray(signIn.allergy) && signIn.allergy.length > 0
              ? signIn?.allergy?.join(', ')
              : '-'
          }}</label>
          <label class="required">Keadaan Pasien Yang Berisiko</label>
          <v-radio-group
            :rules="rules"
            :readonly="!isEditable"
            class="mt-n1"
            row
            v-model="signIn.isHasRisk"
          >
            <v-radio
              class="mt-n1"
              label="Tidak Ada Keadaan Berisiko"
              :value="false"
            ></v-radio>
            <v-radio class="mt-n1" label="Ada" :value="true"></v-radio>
          </v-radio-group>
          <label v-if="signIn.isHasRisk"></label>
          <v-combobox
            v-if="signIn.isHasRisk"
            :readonly="!isEditable"
            multiple
            :rules="riskRules"
            class="mt-n10"
            v-model.lazy="signIn.risk"
            :items="resource.risk"
            append-icon="mdi-chevron-down"
            placeholder="Keadaan Pasien Yang Berisiko"
          />
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const moment = require('moment-timezone');
const { mapGetters, mapActions } = createNamespacedHelpers(
  'SurgicalSafetyChecklist',
);

export default {
  name: 'SignInPhase',
  data() {
    return {
      rules: [v => !!v || v === false || 'Wajib diisi'],
      riskRules: [v => v?.length > 0 || 'Wajib diisi'],
      timeRule: [v => this.checkTimeFormat(v) || 'Waktu Tidak Valid'],
      isValid: false,
    };
  },
  watch: {
    'signIn.isHasRisk'(value) {
      if (value) {
        this.signIn.risk = this.signIn.risk.filter(
          i => i !== 'Tidak Ada Keadaan Berisiko',
        );
      }
    },
  },
  computed: {
    ...mapGetters([
      'getSignIn',
      'getResource',
      'getLoading',
      'getIsEdit',
      'getIsEmr',
      'getIsDetail',
    ]),
    isEditable: {
      get() {
        if (this.isEmr) {
          return false;
        } else if (!this.isDetail) {
          return true;
        } else {
          return this.isEdit;
        }
      },
    },
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    signIn: {
      get() {
        return this.getSignIn;
      },
    },
    resource: {
      get() {
        return this.getResource;
      },
    },
    isLoading: {
      get() {
        return this.getLoading;
      },
    },
    isEdit: {
      get() {
        return this.getIsEdit;
      },
    },
    isDetail: {
      get() {
        return this.getIsDetail;
      },
    },
  },
  methods: {
    ...mapActions([
      'onChangeAction',
      'onChangeSignInDoctor',
      'onChangeSignInStaffFill',
      'onChangeSignInStaff',
    ]),
    onChangeActionEvent() {
      this.onChangeAction();
    },
    onChangeSignInDoctorEvent() {
      this.onChangeSignInDoctor();
    },
    onChangeSignInStaffFillEvent() {
      this.onChangeSignInStaffFill();
    },
    onChangeSignInStaffEvent() {
      this.onChangeSignInStaff();
    },
    checkTimeFormat(time) {
      if (time.length !== 5) return false;
      const timeInput = moment(time, 'HH:mm');
      return timeInput.isValid();
    },
    insertString(originalString, newString, index) {
      return (
        originalString.substr(0, index) +
        newString +
        originalString.substr(index)
      );
    },
    formatTime(timeString) {
      if (timeString.length > 5) {
        this.signIn.time.time = timeString.slice(0, 5);
        return;
      }
      let time = timeString.replace(/\D/g, '').substr(0, 4);
      let size = time.length;

      if (size > 2) time = this.insertString(time, ':', 2);

      this.signIn.time.time = time;
    },
  },
};
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: 40% 50%;
  row-gap: 3dvh;
  text-align: start;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
