const state = {
  nursingProblem: [
    {
      content: '',
      treatmentPlan: [
        {
          content: '',
        },
      ],
    },
  ],
};

const getters = {
  getNursingProblem: state => state.nursingProblem,
};

const mutations = {
  clearNursingProblemState() {
    Object.assign(state, {
      nursingProblem: [
        {
          content: '',
          treatmentPlan: [
            {
              content: '',
            },
          ],
        },
      ],
    });
  },
  setNursingProblem(state, payload) {
    state.nursingProblem = payload;
  },
  addNursingProblem(state) {
    state.nursingProblem.push({
      content: '',
      treatmentPlan: [
        {
          content: '',
        },
      ],
    });
  },
  onChangeNursingProblemState(state, index) {
    state.nursingProblem[index].treatmentPlan = [
      {
        content: '',
      },
    ];
  },
  deleteNursingProblem(state, index) {
    state.nursingProblem.splice(index, 1);
  },
  addTreatmentPlan(state, index) {
    state.nursingProblem[index].treatmentPlan.push({
      content: [],
    });
  },
  deleteTreatmentPlan(state, { index, idx }) {
    state.nursingProblem[index].treatmentPlan.splice(idx, 1);
  },
};

export default {
  state,
  getters,
  mutations,
};
