<template>
  <v-card>
    <v-card-text>
      <v-row align="center">
        <v-col cols="7">
          <div class="d-flex align-center header justify-space-between">
            <span>
              <label class="grey--text mr-2">No. RM</label>
              <label class="font-weight-bold">{{ patientData?.noRM }}</label>
            </span>
            <span>
              <label class="grey--text mr-2">Nama</label>
              <label class="font-weight-bold"
                >{{ patientData?.name }} ({{
                  patientData?.gender === 'Laki-laki' ? 'L' : 'P'
                }})</label
              >
            </span>
            <span>
              <label class="grey--text mr-2">
                Tanggal Lahir
              </label>
              <label class="font-weight-bold"
                >{{ patientData?.birthDate }} ({{
                  patientData?.age
                }}
                Tahun)</label
              >
            </span>
          </div>
        </v-col>
        <v-col cols="5" class="header d-flex justify-end align-center">
          <label class="font-weight-bold mr-2" style="font-size: 18px;">
            Penandaan Operasi atau Tindakan Medis
          </label>
          <v-btn fab text @click="handleCloseDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="mt-n4" />
    <v-container fluid class="container-form">
      <v-form
        ref="form"
        style="height: 61vh;overflow: auto;overflow-x: hidden; padding: 0 15px 0 0;"
        class="container-form__text-input text-left py-0 my-0 mt-2 px-8 scrollbar"
      >
        <!-- First Section  -->
        <v-row no-gutters>
          <v-col cols="6" class="pr-10 mt-6">
            <v-row class="align-center">
              <v-col cols="4">
                <label
                  for="date"
                  class="container-form__text-input__label required"
                >
                  Tanggal
                </label>
              </v-col>
              <v-col cols="8">
                <v-menu
                  dense
                  ref="menu"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      validate-on-blur
                      :rules="[v => !!v || 'Wajib diisi']"
                      placeholder="Pilih Tanggal"
                      :append-icon="state === 'add' ? 'mdi-chevron-down' : ''"
                      readonly
                      v-bind="attrs"
                      v-on="state === 'add' ? on : null"
                      dense
                      :value="handleConvertDate(form.date)"
                      :clearable="state === 'add'"
                      hide-details="false"
                    />
                  </template>
                  <v-date-picker v-model="form.date" no-title scrollable />
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pl-10">
            <v-row class="align-center ">
              <v-col cols="4">
                <label
                  for=""
                  class="container-form__text-input__label required"
                >
                  Yang Bertandatangan
                </label>
              </v-col>
              <v-col cols="8">
                <v-radio-group
                  row
                  class="mt-6"
                  v-model="form.signer"
                  :readonly="state === 'emr'"
                >
                  <v-radio class="text-black" label="Pasien" value="pasien" />
                  <v-radio class="text-black" label="Wali" value="wali" />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" class="pr-10">
            <v-row class="align-center">
              <v-col cols="4">
                <label
                  for="action-by-ic"
                  class="container-form__text-input__label required"
                >
                  Tindakan yang Dilakukan
                </label>
              </v-col>
              <v-col cols="8">
                <v-combobox
                  validate-on-blur
                  :rules="[v => !!v || 'Wajib diisi']"
                  item-text="text"
                  id="action-by-ic"
                  :append-icon="state === 'add' ? 'mdi-chevron-down' : ''"
                  placeholder="Tindakan yang Dilakukan"
                  dense
                  :readonly="state === 'edit' || state === 'emr'"
                  v-model="form.action"
                  @input="handleSetAutoStaff"
                  :loading="items.informConsentList.isLoading"
                  :items="items.informConsentList.data"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pl-10">
            <v-row class="align-center">
              <v-col cols="4">
                <label
                  for="patient_receiver_info"
                  class="container-form__text-input__label required"
                >
                  Nama {{ form.signer.replace(/^\w/, c => c.toUpperCase()) }}
                </label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  :readonly="state === 'emr'"
                  validate-on-blur
                  :rules="
                    form.signer === 'pasien' ? [v => !!v || 'Wajib diisi'] : []
                  "
                  v-if="form.signer === 'pasien'"
                  v-model="form.signer_name_patient"
                  placeholder="Nama Pasien"
                  dense
                />
                <v-text-field
                  :readonly="state === 'emr'"
                  validate-on-blur
                  :rules="
                    form.signer === 'wali' ? [v => !!v || 'Wajib diisi'] : []
                  "
                  v-else
                  v-model="form.signer_name"
                  placeholder="Nama Wali"
                  dense
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" class="pr-10">
            <v-row class="align-center">
              <v-col cols="4">
                <label
                  for="staff_action"
                  class="container-form__text-input__label required"
                >
                  Dokter Pelaksana Tindakan
                </label>
              </v-col>
              <v-col cols="8">
                <v-combobox
                  :readonly="state === 'emr'"
                  item-value="id"
                  item-text="name"
                  id="staff_action"
                  :append-icon="state !== 'emr' ? 'mdi-chevron-down' : ''"
                  placeholder="Dokter Pelaksana Tindakan"
                  dense
                  v-model="form.doctor_executor"
                  :loading="items.staff.isLoading"
                  :items="items.staff.data"
                  validate-on-blur
                  :rules="[v => !!v || 'Wajib diisi']"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pl-10">
            <v-row class="align-center"> </v-row>
          </v-col>
        </v-row>
        <!-- End First Section -->
        <v-divider class="mt-2" />
        <!-- Section 2 -->
        <div class="container-form__mark">
          <div v-if="state !== 'emr'" class="d-flex justify-end align-center">
            <v-btn
              style="letter-spacing: 0.01vw;"
              class="ml-3 text-capitalize"
              depressed
              color="red"
              outlined
              @click="handleDeleteMark()"
              :disabled="isMarkEmpty && markState === 'detail'"
            >
              {{
                markState === 'detail' || markState === 'add'
                  ? 'Hapus Tanda'
                  : 'Selesai'
              }}
            </v-btn>
            <v-btn
              style="letter-spacing: 0.01vw;"
              class="ml-3 text-capitalize"
              depressed
              color="primary"
              @click="handleAddMark()"
            >
              {{
                markState === 'detail' || markState === 'remove'
                  ? 'Berikan Tanda'
                  : 'Selesai'
              }}
            </v-btn>
          </div>
          <div class="container-form__mark__body">
            <v-stage
              @click="handleInputMark($event, 'mark_body')"
              :config="{
                width: backgroundMark?.mark_body?.width * (108 / 100),
                height: backgroundMark?.mark_body?.height * (108 / 100),
              }"
              class="operation-stage"
              ref="mark_body"
            >
              <v-layer>
                <v-image
                  :config="{ image: backgroundMark.mark_body, x: 7, y: 7 }"
                />
                <v-image
                  v-for="(item, index) in form.mark_body"
                  :key="index"
                  :config="imageConfig(item)"
                  @click="handleRemoveMark(item, 'mark_body')"
                />
              </v-layer>
            </v-stage>
          </div>

          <div class="container-form__mark__container">
            <div class="container-form__mark__part">
              <v-stage
                @click="handleInputMark($event, 'mark_face_front')"
                :config="{
                  width: backgroundMark?.mark_face_front?.width * (108 / 100),
                  height: backgroundMark?.mark_face_front?.height * (108 / 100),
                }"
                class="operation-stage"
                ref="mark_face_front"
              >
                <v-layer>
                  <v-image
                    :config="{
                      image: backgroundMark.mark_face_front,
                      x: 7,
                      y: 7,
                    }"
                  />
                  <v-image
                    v-for="(item, index) in form.mark_face_front"
                    :key="index"
                    :config="imageConfig(item)"
                    @click="handleRemoveMark(item, 'mark_face_front')"
                  />
                </v-layer>
              </v-stage>
            </div>
            <div class="container-form__mark__part">
              <v-stage
                @click="handleInputMark($event, 'mark_face_side')"
                :config="{
                  width: backgroundMark?.mark_face_side?.width * (108 / 100),
                  height: backgroundMark?.mark_face_side?.height * (108 / 100),
                }"
                class="operation-stage"
                ref="mark_face_side"
              >
                <v-layer>
                  <v-image
                    :config="{
                      image: backgroundMark.mark_face_side,
                      x: 7,
                      y: 7,
                    }"
                  />
                  <v-image
                    v-for="(item, index) in form.mark_face_side"
                    :key="index"
                    :config="imageConfig(item)"
                    @click="handleRemoveMark(item, 'mark_face_side')"
                  />
                </v-layer>
              </v-stage>
            </div>
          </div>

          <div class="container-form__mark__container">
            <div class="container-form__mark__part">
              <v-stage
                @click="handleInputMark($event, 'mark_palm_upper')"
                :config="{
                  width: backgroundMark?.mark_palm_upper?.width * (108 / 100),
                  height: backgroundMark?.mark_palm_upper?.height * (108 / 100),
                }"
                class="operation-stage"
                ref="mark_palm_upper"
              >
                <v-layer>
                  <v-image
                    :config="{
                      image: backgroundMark.mark_palm_upper,
                      x: 7,
                      y: 7,
                    }"
                  />
                  <v-image
                    v-for="(item, index) in form.mark_palm_upper"
                    :key="index"
                    :config="imageConfig(item)"
                    @click="handleRemoveMark(item, 'mark_palm_upper')"
                  />
                </v-layer>
              </v-stage>
            </div>
            <div class="container-form__mark__part">
              <v-stage
                @click="handleInputMark($event, 'mark_palm_lower')"
                :config="{
                  width: backgroundMark?.mark_palm_lower?.width * (108 / 100),
                  height: backgroundMark?.mark_palm_lower?.height * (108 / 100),
                }"
                class="operation-stage"
                ref="mark_palm_lower"
              >
                <v-layer>
                  <v-image
                    :config="{
                      image: backgroundMark.mark_palm_lower,
                      x: 7,
                      y: 7,
                    }"
                  />
                  <v-image
                    v-for="(item, index) in form.mark_palm_lower"
                    :key="index"
                    :config="imageConfig(item)"
                    @click="handleRemoveMark(item, 'mark_palm_lower')"
                  />
                </v-layer>
              </v-stage>
            </div>
          </div>

          <div class="container-form__mark__container">
            <div class="container-form__mark__part">
              <v-stage
                @click="handleInputMark($event, 'mark_foot_upper')"
                :config="{
                  width: backgroundMark?.mark_foot_upper?.width * (108 / 100),
                  height: backgroundMark?.mark_foot_upper?.height * (108 / 100),
                }"
                class="operation-stage"
                ref="mark_foot_upper"
              >
                <v-layer>
                  <v-image
                    :config="{
                      image: backgroundMark.mark_foot_upper,
                      x: 7,
                      y: 7,
                    }"
                  />
                  <v-image
                    v-for="(item, index) in form.mark_foot_upper"
                    :key="index"
                    :config="imageConfig(item)"
                    @click="handleRemoveMark(item, 'mark_foot_upper')"
                  />
                </v-layer>
              </v-stage>
            </div>
            <div class="container-form__mark__part">
              <v-stage
                @click="handleInputMark($event, 'mark_foot_lower')"
                :config="{
                  width: backgroundMark?.mark_foot_lower?.width * (108 / 100),
                  height: backgroundMark?.mark_foot_lower?.height * (108 / 100),
                }"
                class="operation-stage"
                ref="mark_foot_lower"
              >
                <v-layer>
                  <v-image
                    :config="{
                      image: backgroundMark.mark_foot_lower,
                      x: 7,
                      y: 7,
                    }"
                  />
                  <v-image
                    v-for="(item, index) in form.mark_foot_lower"
                    :key="index"
                    :config="imageConfig(item)"
                    @click="handleRemoveMark(item, 'mark_foot_lower')"
                  />
                </v-layer>
              </v-stage>
            </div>
          </div>
        </div>
        <!-- End Section 2 -->
      </v-form>
    </v-container>
    <v-card-actions class="px-10 py-4">
      <v-spacer />
      <v-btn
        v-if="state !== 'emr'"
        color="primary"
        class="text-capitalize ml-4"
        dense
        depressed
        @click="handleSubmit(idOM)"
      >
        <span>{{ state === 'add' ? 'Simpan' : 'Simpan Perubahan' }}</span>
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="text-capitalize ml-4"
        dense
        depressed
        @click="handlePrint(idOM)"
      >
        <span>Print</span>
      </v-btn>
    </v-card-actions>
    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show && state === 'edit'"
      elevation="0"
      :color="snackbar.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="
            snackbar.show = false;
            clearSnackbar();
          "
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </v-card>
</template>

<script>
import OperationMarking from './OperationMarking';
import mark_body_maleSVG from '@/assets/image/mark_body_male_image.svg';
import mark_body_femaleSVG from '@/assets/image/mark_body_female_image.svg';
import mark_face_frontSVG from '@/assets/image/mark_face_front_image.svg';
import mark_face_sideSVG from '@/assets/image/mark_face_side_image.svg';
import mark_palm_upperSVG from '@/assets/image/mark_palm_upper_image.svg';
import mark_palm_lowerSVG from '@/assets/image/mark_palm_lower_image.svg';
import mark_foot_upperSVG from '@/assets/image/mark_foot_upper_image.svg';
import mark_foot_lowerSVG from '@/assets/image/mark_foot_lower_image.svg';
import redCrossSVG from '@/assets/image/red_cross.svg';

export default {
  ...OperationMarking,
  name: 'FormOperationMarking',
  props: {
    state: String,
    idOM: {
      type: String,
      default: '',
    },
    isBackground: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      markState: 'detail',
      backgroundMark: {
        mark_body: null,
        mark_face_front: null,
        mark_face_side: null,
        mark_palm_upper: null,
        mark_palm_lower: null,
        mark_foot_upper: null,
        mark_foot_lower: null,
        redCross: null,
      },
    };
  },
  mounted() {
    if (this.isBackground) {
      return;
    }
    this.resolveGetInformConsentList();
    this.resolveGetStaff();
  },
  created() {
    const images = [
      {
        ref: 'mark_body',
        src:
          this.patientData.gender === 'Laki-laki'
            ? mark_body_maleSVG
            : mark_body_femaleSVG,
        width: 472.61,
        height: 483.425,
      },
      {
        ref: 'mark_face_front',
        src: mark_face_frontSVG,
        width: 240,
        height: 122.949,
      },
      {
        ref: 'mark_face_side',
        src: mark_face_sideSVG,
        width: 240,
        height: 118.786,
      },
      {
        ref: 'mark_palm_upper',
        src: mark_palm_upperSVG,
        width: 240,
        height: 140.794,
      },
      {
        ref: 'mark_palm_lower',
        src: mark_palm_lowerSVG,
        width: 240,
        height: 141.151,
      },
      {
        ref: 'mark_foot_upper',
        src: mark_foot_upperSVG,
        width: 160,
        height: 136.543,
      },
      {
        ref: 'mark_foot_lower',
        src: mark_foot_lowerSVG,
        width: 160,
        height: 154.783,
      },
      { ref: 'redCross', src: redCrossSVG, width: 16, height: 16 },
    ];

    images.forEach(imageInfo => {
      const img = new window.Image();
      img.src = imageInfo.src;
      img.width = imageInfo.width;
      img.height = imageInfo.height;
      img.onload = () => {
        this.backgroundMark[imageInfo.ref] = img;
      };
    });
  },
};
</script>

<style lang="scss" scoped>
.scrollbar {
  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}
.header {
  color: #404040;
}

.container-form {
  height: 60vh;
  overflow: auto;
  overflow-x: hidden;
  padding: 0 15px 0 0;

  &__text-input {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__label {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__mark {
    padding: 1.25rem 7.9rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    &__body {
      display: flex;
      width: 782px;
      height: 538px;
      padding: 27px 154.39px 27.575px 155px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #c2c2c2;
    }
    &__container {
      display: flex;
      gap: 1.25rem;
    }
    &__part {
      display: flex;
      width: 381px;
      height: 200px;
      padding: 39px 70px 38.051px 71px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #c2c2c2;
    }
  }
}

.required:after {
  content: ' *';
  color: red;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

.button-error {
  background-color: white !important;
  border: 1px solid #eb4646 !important;
  border-radius: 4px !important;
  color: #eb4646 !important;
}
</style>
