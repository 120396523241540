import JsPDF from 'jspdf';
import printMixin from '@/mixin/printMixin';
import 'jspdf-autotable';
import moment from 'moment-timezone';
import checkboxTrue from '@/assets/image/checkboxBlueTrue.png';
import checkboxFalse from '@/assets/image/checkboxFalse.png';

export default {
  methods: {
    generateDrugHandover(data) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const marginX = 1;
      const marginY = 1.9;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };

      doc.setTextColor(64, 64, 64);

      doc.setFontSize(12);
      doc.setFont('Helvetica', 'bold');
      alignCenter('E-Resep', undefined, marginY);

      doc.autoTable({
        body: [
          ['No', ':', data.recpie_number],
          [
            'Tanggal',
            ':',
            moment(data.date)
              .locale('id')
              .format('DD MMMM YYYY'),
          ],
          ['Dokter', ':', data.staff.name],
          ['SIP', ':', data.staff.sip || '-'],
          ['Nama Pasien', ':', `${data.patient.name} (${data.rm_number})`],
          [
            'Tanggal Lahir',
            ':',
            `${moment(data.patient.birth_date)
              .locale('id')
              .format('DD MMMM YYYY')} (${data.patient.age} Tahun)`,
          ],
          ['Alamat', ':', data.patient.address.text],
          [
            'BB/TB',
            ':',
            `${data.assessment?.weight || '-'} kg / ${data.assessment?.height ||
              '-'} cm`,
          ],
          [
            'Alergi',
            ':',
            data.assessment?.allergy_history?.length === 0 ||
            !data.assessment?.allergy_history
              ? '-'
              : data.assessment?.allergy_history?.join(', '),
          ],
        ],
        startY: addLine(5),
        styles: {
          halign: 'left',
          textColor: [64, 64, 64],
        },
        columnStyles: {
          0: { cellWidth: 1.5 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 5 },
        },
        margin: {
          left: marginX - 0.08,
          right: marginX,
        },
        theme: 'plain',
      });

      let lastLine = doc.autoTable.previous.finalY - 0.6;

      const drugs = data.drugs;
      const arr = [];
      drugs.forEach((drug, i) => {
        if (!drug.isMixRecipe) {
          arr.push([
            {
              content: `R/: ${i + 1}`,
              styles: { fontStyle: 'bold' },
            },
          ]);

          arr.push([
            {
              content: `${drug.name} ${drug.dosage} ${drug.unit_dosage} ${drug.preparation} - ${drug.quantity} ${drug.packaging} - ${drug.usage}`,
            },
          ]);
        } else {
          arr.push([
            {
              content: `R/: ${i + 1} (${drug.name} - ${drug.quantity} ${
                drug.packaging
              } - ${drug.usage})`,
              styles: { fontStyle: 'bold' },
            },
          ]);
          drug.drugs.forEach(item => {
            arr.push([
              {
                content: `   • ${item.name} ${item.dosage} ${
                  item.unit_dosage
                } ${item.preparation} - ${item.quantity} ${item.packaging ||
                  ''}`,
              },
            ]);
          });
        }
      });
      doc.autoTable({
        body: arr,
        startY: lastLine + 0.7,
        styles: {
          halign: 'left',
          textColor: [64, 64, 64],
        },
        margin: {
          left: marginX - 0.08,
          right: marginX - 0.5,
          top: marginY,
        },
        theme: 'plain',
      });

      let lastLineY = doc.autoTable.previous.finalY;

      doc.setFont('Helvetica', 'normal');
      doc.setFontSize(10);
      doc.text(
        'TELAAH RESEP',
        marginX,
        lastLine >= 8.99 ? marginY : lastLineY + 0.4,
        {
          align: 'left',
          charSpace: 0,
        },
      );

      doc.setFont('Helvetica', 'bold');
      doc.text(
        'A. Administrasi',
        marginX,
        lastLine >= 8.99 ? marginY + 0.3 : lastLineY + 0.7,
        {
          align: 'left',
          charSpace: 0,
        },
      );
      doc.text(
        'B. Farmasetis',
        marginX + 3.58,
        lastLine >= 8.99 ? marginY + 0.3 : lastLineY + 0.7,
        {
          align: 'left',
          charSpace: 0,
        },
      );

      const objectToArray = obj => {
        return Object.keys(obj).map(key => ({ key, value: obj[key] }));
      };

      const administrativeArray = objectToArray(data.administrative);
      const pharmaceuticalArray = objectToArray(data.pharmaceutical);
      const clinicalArray = objectToArray(data.clinical);
      const drugReviewArray = objectToArray(data.drugs_review);

      for (let z = 0; z < administrativeArray.length; z++) {
        doc.addImage(
          administrativeArray[z].value === true ? checkboxTrue : checkboxFalse,
          'PNG',
          marginX + 1.95,
          (lastLine >= 8.99 ? marginY + 0.46 : lastLineY + 0.87) + z * 0.3,
          0.13,
          0.13,
        );
        doc.addImage(
          administrativeArray[z].value === true ? checkboxFalse : checkboxTrue,
          'PNG',
          marginX + 2.65,
          (lastLine >= 8.99 ? marginY + 0.46 : lastLineY + 0.87) + z * 0.3,
          0.13,
          0.13,
        );
      }

      for (let z = 0; z < pharmaceuticalArray.length; z++) {
        doc.addImage(
          pharmaceuticalArray[z].value === true ? checkboxTrue : checkboxFalse,
          'PNG',
          marginX + 5.35,
          (lastLine >= 8.99 ? marginY + 0.46 : lastLineY + 0.87) + z * 0.3,
          0.13,
          0.13,
        );
        doc.addImage(
          pharmaceuticalArray[z].value === true ? checkboxFalse : checkboxTrue,
          'PNG',
          marginX + 6.05,
          (lastLine >= 8.99 ? marginY + 0.46 : lastLineY + 0.87) + z * 0.3,
          0.13,
          0.13,
        );
      }

      doc.autoTable({
        body: [
          ['Nama Pasien', 'Ya', 'Tidak', 'Nama Obat', 'Ya', 'Tidak'],
          ['Umur', 'Ya', 'Tidak', 'Bentuk Kekuatan', 'Ya', 'Tidak'],
          ['Nama Dokter', 'Ya', 'Tidak', 'Dosis', 'Ya', 'Tidak'],
          ['Tanggal Resep', 'Ya', 'Tidak', 'Jumlah Obat', 'Ya', 'Tidak'],
          ['', '', '', 'Aturan Cara Penggunaan', 'Ya', 'Tidak'],
        ],
        startY: lastLine >= 8.99 ? marginY + 0.4 : lastLineY + 0.8,
        styles: {
          halign: 'left',
          textColor: [64, 64, 64],
        },
        columnStyles: {
          0: { cellWidth: 2.2 },
          1: { cellWidth: 0.7 },
          2: { cellWidth: 0.7 },
          3: { cellWidth: 2 },
          4: { cellWidth: 0.7 },
          5: { cellWidth: 0.7 },
        },
        margin: {
          left: marginX - 0.08,
          right: marginX,
        },
        theme: 'plain',
      });

      lastLine >= 7.18 && lastLine <= 8.99 && doc.addPage();
      let lastLine2 =
        lastLine >= 7.18 && lastLine <= 8.99
          ? marginY
          : doc.autoTable.previous.finalY - 0.6;

      for (let z = 0; z < clinicalArray.length; z++) {
        doc.addImage(
          clinicalArray[z].value === true ? checkboxTrue : checkboxFalse,
          'PNG',
          marginX + 1.95,
          lastLine2 +
            (lastLine >= 7.18 && lastLine <= 8.99 ? 0.18 : 0.97) +
            z * 0.3,
          0.13,
          0.13,
        );
        doc.addImage(
          clinicalArray[z].value === true ? checkboxFalse : checkboxTrue,
          'PNG',
          marginX + 2.65,
          lastLine2 +
            (lastLine >= 7.18 && lastLine <= 8.99 ? 0.18 : 0.97) +
            z * 0.3,
          0.13,
          0.13,
        );
      }
      doc.text(
        'C. Aspek Klinis',
        marginX,
        lastLine >= 7.18 && lastLine <= 8.99 ? lastLine2 : lastLine2 + 0.8,
        {
          align: 'left',
          charSpace: 0,
        },
      );
      doc.autoTable({
        body: [
          ['Tepat Indikasi', 'Ya', 'Tidak'],
          ['Tepat Dosis', 'Ya', 'Tidak'],
          ['Tepat Waktu Penggunaan', 'Ya', 'Tidak'],
          ['Duplikasi Obat', 'Ya', 'Tidak'],
          ['Alergi', 'Ya', 'Tidak'],
          ['ROTD', 'Ya', 'Tidak'],
        ],
        startY:
          lastLine >= 7.18 && lastLine <= 8.99
            ? lastLine2 + 0.1
            : lastLine2 + 0.9,
        styles: {
          halign: 'left',
          textColor: [64, 64, 64],
        },
        columnStyles: {
          0: { cellWidth: 2.2 },
          1: { cellWidth: 0.7 },
          2: { cellWidth: 0.7 },
        },
        margin: {
          left: marginX - 0.08,
          right: marginX,
        },
        theme: 'plain',
      });

      let lastLine3 = doc.autoTable.previous.finalY - 0.6;
      doc.setFontSize(8);
      doc.setFont('Helvetica', 'normal');
      doc.setTextColor(117, 117, 117);
      doc.text(
        data.timestamps.recipe_review.date
          ? `* Petugas Telaah Resep : ${
              data.timestamps.recipe_review.staff.name
            } (Ditelaah pada ${moment(
              data.timestamps.recipe_review.date,
            ).format('DD-MM-YYYY HH:mm [WIB]')})`
          : '* Petugas Telaah Resep : -',
        marginX,
        lastLine3 + 0.8,
        {
          align: 'left',
          charSpace: 0,
        },
      );

      lastLine <= 7.18 && doc.addPage();
      doc.setTextColor(64, 64, 64);
      doc.setFontSize(10);

      doc.setFont('Helvetica', 'normal');
      doc.text(
        'TELAAH OBAT',
        marginX,
        lastLine >= 7.18 ? lastLine3 + 1.2 : marginY,
        {
          align: 'left',
          charSpace: 0,
        },
      );

      doc.setFont('Helvetica', 'bold');
      doc.text(
        'Kriteria',
        marginX + 0.01,
        lastLine >= 7.18 ? lastLine3 + 1.5 : marginY + 0.3,
        {
          align: 'left',
          charSpace: 0,
        },
      );

      for (let z = 0; z < drugReviewArray.length; z++) {
        doc.addImage(
          drugReviewArray[z].value === null
            ? checkboxFalse
            : drugReviewArray[z].value === true
            ? checkboxTrue
            : checkboxFalse,
          'PNG',
          marginX + 1.95,
          (lastLine >= 7.18 ? lastLine3 + 1.76 : marginY + 0.57) + z * 0.3,
          0.13,
          0.13,
        );
        doc.addImage(
          drugReviewArray[z].value === null
            ? checkboxFalse
            : drugReviewArray[z].value === true
            ? checkboxFalse
            : checkboxTrue,
          'PNG',
          marginX + 2.65,
          (lastLine >= 7.18 ? lastLine3 + 1.76 : marginY + 0.57) + z * 0.3,
          0.13,
          0.13,
        );
      }

      doc.autoTable({
        body: [
          ['Benar Pasien', 'Ya', 'Tidak'],
          ['Benar Obat', 'Ya', 'Tidak'],
          ['Benar Dosis', 'Ya', 'Tidak'],
          ['Benar Cara Pemberian', 'Ya', 'Tidak'],
          ['Benar Waktu Pemberian', 'Ya', 'Tidak'],
          ['Tidak ED', 'Ya', 'Tidak'],
        ],
        startY: lastLine >= 7.18 ? lastLine3 + 1.7 : marginY + 0.5,
        styles: {
          halign: 'left',
          textColor: [64, 64, 64],
        },
        columnStyles: {
          0: { cellWidth: 2.2 },
          1: { cellWidth: 0.7 },
          2: { cellWidth: 0.7 },
        },
        margin: {
          left: marginX - 0.06,
          right: marginX,
        },
        theme: 'plain',
      });

      let lastLine4 = doc.autoTable.previous.finalY - 0.6;

      doc.setFontSize(8);
      doc.setFont('Helvetica', 'normal');
      doc.setTextColor(117, 117, 117);
      doc.text(
        data.timestamps.drug_review.date
          ? `* Petugas Telaah Obat : ${data.timestamps.drug_review.staff.name ||
              '-'} (Ditelaah pada ${moment(
              data.timestamps.drug_review.date,
            ).format('DD-MM-YYYY HH:mm [WIB]')})`
          : '* Petugas Telaah Obat : -',
        marginX,
        lastLine4 + 1,
        {
          align: 'left',
          charSpace: 0,
        },
      );
      doc.text(
        data.timestamps.prescription_receptionist.date
          ? `* Petugas Penerimaan Resep : ${
              data.timestamps.prescription_receptionist.staff.name
            } (${moment(data.timestamps.prescription_receptionist.date).format(
              'DD-MM-YYYY HH:mm [WIB]',
            )})`
          : '* Petugas Penerimaan Resep : -',
        marginX,
        lastLine4 + 1.4,
        {
          align: 'left',
          charSpace: 0,
        },
      );
      doc.text(
        data.timestamps.drug_taker.date
          ? `* Petugas Pengambilan Obat/Racik : ${
              data.timestamps.drug_taker.staff.name
            } (${moment(data.timestamps.drug_taker.date).format(
              'DD-MM-YYYY HH:mm [WIB]',
            )})`
          : '* Petugas Pengambilan Obat/Racik : -',
        marginX,
        lastLine4 + 1.6,
        {
          align: 'left',
          charSpace: 0,
        },
      );
      doc.text(
        data.timestamps.drug_delivery.date
          ? `* Petugas Penyerahan Obat : ${
              data.timestamps.drug_delivery.staff.name
            } (${moment(data.timestamps.drug_delivery.date).format(
              'DD-MM-YYYY HH:mm [WIB]',
            )})`
          : '* Petugas Penyerahan Obat : -',
        marginX,
        lastLine4 + 1.8,
        {
          align: 'left',
          charSpace: 0,
        },
      );

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Header
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
        );
      }
      return doc;
    },
  },
};
