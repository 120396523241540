import Constant from '@/const';
import axios from 'axios';

const getInsurance = async () => {
  try {
    const response = await axios.get(Constant.apiUrl.concat('/insurance'));
    return response;
  } catch (error) {
    const err = error;
    return err;
  }
};

export default getInsurance;
