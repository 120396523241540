const state = {
  anamnesis: [''],
  allergy: [''],
  priorDisease: [''],
  treatmentHistory: '',
  psychologyStatusCheckbox: false,
  psychologyStatus: [],
  socialEconomy: '',
  spiritual: '',
};

const getters = {
  // get anamnesis
  getAnamnesis: state => state.anamnesis,
  // get allergy
  getAllergy: state => state.allergy,
  // get prior disease
  getPriorDisease: state => state.priorDisease,
  // get treatment history
  getTreatmentHistory: state => state.treatmentHistory,
  // get psychology status checkbox
  getPsychologyStatusCheckbox: state => state.psychologyStatusCheckbox,
  // get psychology status
  getPsychologyStatus: state =>
    state.psychologyStatus.filter(
      item => item.toLowerCase() !== 'tidak ada kelainan',
    ),
  // get social economy
  getSocialEconomy: state => state.socialEconomy,
  // get spiritual
  getSpiritual: state => state.spiritual,
};

const mutations = {
  addAnamnesis(state) {
    state.anamnesis.push('');
  },
  deleteAnamnesis(state, payload) {
    state.anamnesis.splice(payload.index, 1);
  },
  addAllergy(state) {
    state.allergy.push('');
  },
  deleteAllergy(state, payload) {
    state.allergy.splice(payload.index, 1);
  },
  addPriorDisease(state) {
    state.priorDisease.push('');
  },
  deletePriorDisease(state, payload) {
    state.priorDisease.splice(payload.index, 1);
  },
  setAnamnesis(state, payload) {
    state.anamnesis = payload;
  },
  setAllergy(state, payload) {
    state.allergy = payload;
  },
  setPriorDisease(state, payload) {
    state.priorDisease = payload;
  },
  setTreatmentHistory(state, payload) {
    state.treatmentHistory = payload;
  },
  setPsychologyStatusCheckbox(state, payload) {
    state.psychologyStatusCheckbox = payload;
  },
  setPsychologyStatus(state, payload) {
    state.psychologyStatus = payload;
  },
  setSocialEconomy(state, payload) {
    state.socialEconomy = payload;
  },
  setSpiritual(state, payload) {
    state.spiritual = payload;
  },
  clearSubjectiveState(state) {
    Object.assign(state, {
      anamnesis: [''],
      allergy: [''],
      priorDisease: [''],
      treatmentHistory: '',
      psychologyStatusCheckbox: '',
      psychologyStatus: [],
      socialEconomy: '',
      spiritual: '',
    });
  },
};

export default {
  state,
  getters,
  mutations,
};
