import {
  getIHSCodes,
  putLaboratory,
  getCheckAvail,
  postLaboratory,
  getAllLaboratory,
  deleteLaboratory,
  getLaboratoryById,
  putStatusLaboratory,
  getServiceLaboratory,
} from '@/fetchApi/MasterData/Laboratory';

const state = {
  dashboard: {
    dialog: false,
    filter: {
      halaman: 0,
      itemCount: 0,
      sortBy: '',
      keyword: '',
    },
    snackbar: {
      show: false,
      color: 'success',
      text: '',
    },
  },
  items: {
    dataLaboratory: {
      isLoading: false,
      data: [],
    },
    dataIHSCodes: {
      isLoading: false,
      data: [],
    },
    dataServiceLab: {
      isLoading: false,
      data: [],
    },
  },
};

const getters = {
  // Get State Dialog
  getDialog: state => state.dashboard.dialog,

  // Get State Items
  getItems: state => state.items,

  // Get State Form
  getForm: state => state.form,

  // Get snackbar
  getSnackbar: state => state.dashboard.snackbar,
};

const mutations = {
  // Set State Dialog
  setDialog(state, payload) {
    state.dashboard.dialog = payload;
  },

  // Set State Items
  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: value,
      isLoading,
    };
  },

  // Set State Form
  setForm(state, payload) {
    state.form = payload;
  },

  // Set Filter
  setFilter(state, payload) {
    state.dashboard.filter = payload;
  },

  // Set snackbar
  setSnackbar(state, payload) {
    state.dashboard.snackbar = payload;
  },
};
const actions = {
  // Get Data
  // Gett All Data Laboratory
  async resolveGetAllLaboratory(
    { commit, state },
    { halaman, itemCount, keyword, category },
  ) {
    commit('setItems', {
      label: 'dataLaboratory',
      value: [...state.items.dataLaboratory.data],
      isLoading: true,
    });
    try {
      const laboratoryResponse = await getAllLaboratory({
        halaman,
        itemCount,
        category: category || '',
        keyword,
      });
      const laboratoryMapper = laboratoryResponse?.data?.map((item, idx) => ({
        id: item._id,
        id_service: item.id_service,
        no: idx + 1 + itemCount * halaman - itemCount,
        name: item.name,
        category: item.category,
        isActive: item.isActive,
        lis_code: item.code_lis,
        ihs_code: item.code_ihs,
      }));
      commit('setItems', {
        label: 'dataLaboratory',
        value: laboratoryMapper,
        isLoading: false,
      });
      return laboratoryResponse?.length;
    } catch {
      commit('setItems', {
        label: 'dataLaboratory',
        value: [],
        isLoading: false,
      });
      return 0;
    }
  },
  // Get Data Service Lab By Id
  async resolveGetCheckAvail(_, id) {
    try {
      const response = await getCheckAvail(id);
      return response;
    } catch (error) {
      return error;
    }
  },
  // Get Data Service Lab
  async resolveGetServiceLab({ commit }, keyword = '') {
    commit('setItems', {
      label: 'dataServiceLab',
      value: [],
      isLoading: true,
    });
    try {
      const response = await getServiceLaboratory(keyword);
      const mapper = response.map(item => item);
      commit('setItems', {
        label: 'dataServiceLab',
        value: mapper,
        isLoading: false,
      });
    } catch (error) {
      commit('setItems', {
        label: 'dataServiceLab',
        value: [],
        isLoading: false,
      });
      return error;
    }
  },
  // Get Check Avail Service Lab
  async resolveGetLaboratoryById(_, id) {
    try {
      const response = await getLaboratoryById(id);
      return response;
    } catch (error) {
      return error;
    }
  },
  // Get Data IHS Codes
  async resolveGetIHSCodes({ commit }, keyword = '') {
    commit('setItems', {
      label: 'dataIHSCodes',
      value: [],
      isLoading: true,
    });
    try {
      const response = await getIHSCodes(keyword);
      const mapper = response.map(item => {
        return {
          ...item,
          displayName: item.loinc_number + ' - ' + item.loinc_long_common_name,
        };
      });
      commit('setItems', {
        label: 'dataIHSCodes',
        value: mapper,
        isLoading: false,
      });
    } catch (error) {
      commit('setItems', {
        label: 'dataIHSCodes',
        value: [],
        isLoading: false,
      });
      return error;
    }
  },
  // End Get All Data

  // Post Data
  async resolvePostLaboratory(_, payload) {
    try {
      const response = await postLaboratory(payload);
      return response;
    } catch (error) {
      return error;
    }
  },
  // End Post Data

  // Put Data
  async resolvePutLaboratory(_, { id, payload }) {
    try {
      const response = await putLaboratory(id, payload);
      return response;
    } catch (error) {
      return error;
    }
  },

  async resolvePutStatusLaboratory(_, { _id, isActive }) {
    try {
      await putStatusLaboratory({ _id, isActive });
    } catch (error) {
      return error;
    }
  },
  // End Put Data

  // Delete Data
  async resolveDeleteLaboratory({ commit }, id) {
    try {
      const responseDeleteLaboratory = await deleteLaboratory(id);
      if (responseDeleteLaboratory.status === 200) {
        commit('setSnackbar', {
          show: () => {
            commit('setSnackbar', { ...state.snackbar, show: true });
            return setTimeout(() => {
              commit('setSnackbar', { ...state.snackbar, show: false });
            }, 4000);
          },
          text: 'Jenis Pemeriksaan Berhasil Dihapus',
          color: 'success',
        });
      } else {
        commit('setSnackbar', {
          show: () => {
            commit('setSnackbar', { ...state.snackbar, show: true });
            return setTimeout(() => {
              commit('setSnackbar', { ...state.snackbar, show: false });
            }, 4000);
          },
          text: 'Jenis Pemeriksaan Gagal Dihapus',
          color: 'error',
        });
      }
      return responseDeleteLaboratory;
    } catch (error) {
      return error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
