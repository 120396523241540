const moment = require('moment-timezone');
import {
  getCity,
  getRegion,
  getDistrict,
  getAssurance,
  getSubDistrict,
  getGenerateRmNumber,
  getRegistrationById,
} from '../../../fetchApi/patient';

const state = {
  patientRegistration: {
    idEmr: '',
    rmNumber: '',
    age: null,
    name: '',
    nickname: '',
    phoneNumber: '',
    gender: true,
    birthDate: '',
    birthPlace: '',
    addressDistrict: null,
    addressProvince: null,
    addressSubDistrict: null,
    addressCity: null,
    addressText: '',
    identityType: '',
    identityNumber: '',
    religion: '',
    bloodType: '',
    education: '',
    ethnicity: '',
    profession: '',
    martialStatus: '',
    father: '',
    mother: '',
    assuranceType: 'umum',
    assuranceName: '',
    assuranceNumber: '',
    assuranceIsProlanis: true,
    languageMastering: '',
    communicationObstacles: '',
    disabilities: 0,
    statusIsNew: true,
    statusType: 'laboratorium',
  },
  generatedRmNumber: '',
  addressItems: {
    region: [],
    city: [],
    district: [],
    subDistrict: [],
  },
  assuranceData: [],
};

const initialState = {
  patientRegistration: {
    idEmr: '',
    rmNumber: '',
    age: null,
    name: '',
    nickname: '',
    phoneNumber: '',
    gender: true,
    birthDate: '',
    birthPlace: '',
    addressDistrict: null,
    addressProvince: null,
    addressSubDistrict: null,
    addressCity: null,
    addressText: '',
    identityType: '',
    identityNumber: '',
    religion: '',
    bloodType: '',
    education: '',
    ethnicity: '',
    profession: '',
    martialStatus: '',
    father: '',
    mother: '',
    assuranceType: 'umum',
    assuranceName: '',
    assuranceNumber: '',
    assuranceIsProlanis: true,
    languageMastering: '',
    communicationObstacles: '',
    disabilities: 0,
    statusIsNew: true,
    statusType: 'laboratorium',
  },
  generatedRmNumber: '',
  addressItems: {
    region: [],
    city: [],
    district: [],
    subDistrict: [],
  },
  assuranceData: [],
};

const getters = {
  // Get patient registration lab
  getPatientRegistration: state => state.patientRegistration,

  // API
  getGeneratedRmNumber: state => state.generatedRmNumber,
  getAssuranceData: state => state.assuranceData,
  // Address
  getAddressItems: state => state.addressItems,
};

const mutations = {
  clearStatePatientRegistration(state) {
    Object.assign(state, initialState);
  },
  setGeneratedRmNumber(state, payload) {
    state.generatedRmNumber = payload;
  },
  setAllPatientRegistration(state, payload) {
    state.patientRegistration = payload;
  },
  setPatientRegistration(state, payload) {
    const { label, value } = payload;
    state.patientRegistration[label] = value;
  },
  setAssuranceData(state, payload) {
    state.assuranceData = payload;
  },
  setAddressItems(state, payload) {
    const { label, value } = payload;
    state.addressItems[label] = value;
  },
};

const actions = {
  // Resolve get generated rm number
  async resolveGetGeneratedRmNumber({ commit }) {
    try {
      const response = await getGenerateRmNumber();
      commit('setGeneratedRmNumber', response.data.data);
    } catch {
      commit('setGeneratedRmNumber', '');
    }
  },

  // Resolve get address provinsi reqruitment
  async resolveGetRegion({ commit }) {
    try {
      const response = await getRegion();

      commit('setAddressItems', { label: 'region', value: response });
    } catch {
      commit('setAddressItems', { label: 'region', value: [] });
    }
  },

  // Resolve get address kabupaten reqruitment
  async resolveGetCity({ commit, state }) {
    try {
      const response = await getCity(
        state.patientRegistration.addressProvince.id,
      );

      commit('setAddressItems', { label: 'city', value: response });
    } catch {
      commit('setAddressItems', { label: 'city', value: [] });
    }
  },

  // Resolve get address kecamatan reqruitment
  async resolveGetDistrict({ commit, state }) {
    try {
      const response = await getDistrict(
        state.patientRegistration.addressCity.id,
      );

      commit('setAddressItems', { label: 'district', value: response });
    } catch {
      commit('setAddressItems', { label: 'district', value: [] });
    }
  },

  // Resolve get address kelurahan reqruitment
  async resolveGetSubDistrict({ commit, state }) {
    try {
      const response = await getSubDistrict(
        state.patientRegistration.addressDistrict.id,
      );

      commit('setAddressItems', { label: 'subDistrict', value: response });
    } catch {
      commit('setAddressItems', { label: 'subDistrict', value: [] });
    }
  },

  // Resolve get assurance data
  async resolveGetAssurance({ commit }) {
    try {
      const response = await getAssurance();
      commit('setAssuranceData', response);
    } catch {
      commit('setAssuranceData', '');
    }
  },

  // Resolve get registration by id
  async resolveGetRegistrationById({ commit, state }, idRegistration) {
    try {
      const response = await getRegistrationById(idRegistration);
      const data = {
        ...state.patientRegistration,
        idEmr: response.id_emr,
        name: response.name,
        nickname: response.nickname,
        phoneNumber: response.phone_number,
        gender: response.gender,
        age: response.age,
        birthDate:
          response.birth.date !== '' &&
          !moment(response.birth.date, 'DD/MM/YYYY', true).isValid()
            ? moment(response.birth.date).format('DD/MM/YYYY')
            : response.birth.date,
        birthPlace: response.birth.place,
        addressDistrict: response.address.district,
        addressProvince: response.address.province,
        addressSubDistrict: response.address.sub_district,
        addressText: response.address.text,
        addressCity: response.address.city,
        identityType: response.identity.type,
        identityNumber: response.identity.number,
        religion: response.religion,
        bloodType: response.blood_type,
        education: response.education,
        ethnicity: response.ethnicity,
        profession: response.profession,
        martialStatus: response.martial_status,
        father: response.father,
        mother: response.mother,
        languageMastering: response.language_mastering,
        communicationObstacles: response.communication_obstacles,
        disabilities: response.disabilities,
        assuranceType: response.assurance.type,
        assuranceName: response.assurance.name,
        assuranceNumber: response.assurance.number,
        assuranceIsProlanis: response.assurance.isProlanis,
      };
      commit('setAllPatientRegistration', data);
    } catch {
      commit('setAllPatientRegistration', state.patientRegistration);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
