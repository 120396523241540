import { createNamespacedHelpers } from 'vuex';
import alertMixin from '../../../../../mixin/alertMixin';
import PatientQueueMixin from '@/mixin/PatientQueueMixin';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'PatientQueue',
);
export default {
  name: 'DashboardPatientQueueCard',
  mixins: [alertMixin, PatientQueueMixin],
  props: {
    poly: {
      type: String,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCalling: false,
      isTestSoundActive: false,
    };
  },
  computed: {
    ...mapGetters([
      'getQueue',
      'getRemainingCount',
      'getIsMissedCount',
      'getIsStarted',
      'getIsLoading',
      'getRoom',
    ]),
    queue: {
      get() {
        return this.getQueue;
      },
    },
    room: {
      get() {
        return this.getRoom;
      },
    },
    remainingCount: {
      get() {
        return this.getRemainingCount;
      },
    },
    isMissedCount: {
      get() {
        return this.getIsMissedCount;
      },
    },
    isStarted: {
      get() {
        return this.getIsStarted;
      },
    },
    isLoading: {
      get() {
        return this.getIsLoading;
      },
    },
  },
  mounted() {
    this.onMessageUpdate({
      refresh: this.handleGetPatientQueue,
      isCalling: false,
    });
    this.$socket.client.on('message:update-sound', data => {
      this.isCalling = data;
    });
    this.soundFinish();
  },
  beforeDestroy() {
    this.clearState();
  },
  methods: {
    ...mapActions([
      'resolveGetPatientQueue',
      'resolveModifyQueue',
      'resolveStartQueue',
    ]),
    ...mapMutations(['clearState']),
    testSound() {
      this.$socket.client.emit('message:check-sound');
    },
    soundFinish() {
      this.$socket.client.on('message:check-sound-finish', () => {
        setTimeout(() => (this.isTestSoundActive = false), 3000);
      });
      this.$socket.client.on('message:check-sound-active', () => {
        this.isTestSoundActive = true;
      });
    },
    async handleGetPatientQueue() {
      await this.resolveGetPatientQueue({
        payload: this.payload,
      });
    },
    async handleModifyQueue(data, isHold) {
      const payload = {
        ...this.payload,
        id_item: data._id,
        isHold,
      };
      await this.resolveModifyQueue(payload);
      await this.handleGetPatientQueue();
    },
    async handleStartQueue() {
      await this.resolveStartQueue(this.payload);
      this.handleGetPatientQueue();
    },
    async setQueue(isHold = false) {
      if (!this.isStarted) {
        this.handleStartQueue();
        this.call();
        return;
      }
      await this.handleModifyQueue(this.queue?.[0], isHold);
      this.call();
    },
    call() {
      const payload = {
        queue_code: this.queue?.[0]?.queue_code || null,
        room: this.room || null,
        patient_name: this.queue?.[0]?.patient_name,
      };
      this.emitMessageCall(payload);
    },
    toDetailQueue() {
      window.open(
        `/pelayanan/${this.poly}/antrean/${this.payload.id_staff}`,
        '_blank',
      );
      localStorage.setItem('detailQueuePayload', JSON.stringify(this.payload));
    },
    toDetailPatient() {
      window.open(`/antrean-pasien`, '_blank');
    },
  },
};
