import Constant from '@/const';
import axios from 'axios';

const getEarlyAssessmentInpatient = async (id, type) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/inpatient/firstassesment/get/${id}?type=${type}`,
      ),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getEarlyAssessmentInpatient;
