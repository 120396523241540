import Constant from '@/const';
import axios from 'axios';

const postLabourAssessment = async (idInpatient, payload) => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat(`/birthassessment/${idInpatient}`),
      payload,
    );
    return response;
  } catch (error) {
    return error;
  }
};

export default postLabourAssessment;
