<template>
  <v-card class="px-7 py-3">
    <div class="d-flex justify-space-between">
      <h2 class="text-start mb-3">Detail Perubahan Data CPPT</h2>
      <v-btn @click="close()" small text fab><v-icon>mdi-close</v-icon></v-btn>
    </div>
    <div class="header mb-3">
      <label>No. RM</label>
      <span>{{ patient.rmNumber }}</span>
      <label>Nama</label>
      <span>{{ patient.name }}</span>
      <label>Tanggal Lahir</label>
      <span>{{ patient.birthDate }}</span>
      <label>Alamat</label>
      <span>{{ patient.address }}</span>
    </div>
    <v-divider></v-divider>
    <v-container fluid class="pa-0 pr-4 ma-0">
      <v-row>
        <v-col>
          <div class="grid-data">
            <label>Tanggal Riwayat</label>
            <span>{{ `${cpptData?.date}, ${cpptData?.time}` }}</span>
            <label>PPA</label>
            <span class="text-capitalize">{{
              `${cpptData?.ppa?.name}, ${cpptData?.ppa?.role}`
            }}</span>
            <label>Waktu Perubahan Dilakukan</label>
            <v-combobox
              dense
              light
              hide-details
              :items="historyList"
              v-model="selectedDate"
              @change="getCppptCompareData"
              item-text="datetime"
              item-value="id"
              append-icon="mdi-chevron-down"
              placeholder="Pilih tanggal"
            >
            </v-combobox>
          </div>
        </v-col>
        <v-col class="d-flex justify-end align-end">
          <template v-if="data.after && data.before">
            <div class="rect"></div>
            <span>Data yang diubah</span>
          </template>
        </v-col>
      </v-row>
      <div class="table-wrapper">
        <!-- data before -->
        <v-skeleton-loader
          v-if="loading"
          type="table:table-heading, table-thead, table-tbody"
        ></v-skeleton-loader>
        <detail-changes-list
          v-else
          :isDoctor="isDoctor"
          :role="cpptData.ppa.role"
          :data="data.before"
          isBefore
        />
        <!-- data after -->
        <v-skeleton-loader
          v-if="loading"
          type="table:table-heading, table-thead, table-tbody"
        ></v-skeleton-loader>
        <detail-changes-list
          v-else
          :role="cpptData.ppa.role"
          :time="[selectedDate?.date, selectedDate?.time]"
          :isDoctor="isDoctor"
          :data="data.after"
        />
      </div>
    </v-container>
    <v-card-actions class="d-flex justify-end">
      <v-btn
        color="primary"
        @click="close()"
        light
        depressed
        style="letter-spacing: 0.1px;"
        class="
        text-capitalize"
        >Kembali</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import DetailChangesList from './DetailChangesList.vue';
import { createNamespacedHelpers } from 'vuex';
import { getCompareData } from '@/fetchApi/Inpatient/CPPT';
import {
  mapCompareData,
  mapCompareDataNurseAssessment,
  mapCompareDataDoctorAssessment,
} from '@/store/modules/Inpatient/CPPT/mapCpptData';

const { mapGetters } = createNamespacedHelpers('CPPTStore');
export default {
  name: 'DetailChanges',
  components: { DetailChangesList },
  data() {
    return {
      selectedDate: '',
      loading: false,
      data: {
        before: '',
        after: '',
      },
    };
  },
  props: {
    index: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['getPatientData', 'getTableItems', 'getHistory']),
    historyList: {
      get() {
        return this.getHistory[this.index].history.slice(0, -1).map(item => {
          return {
            datetime: `${item.timestamps.date}, ${item.timestamps.time}`,
            date: item.timestamps.date,
            time: item.timestamps.time,
            id: item.history_id,
          };
        });
      },
    },
    cpptData: {
      get() {
        return this.getTableItems[this.index];
      },
    },
    isDoctor: {
      get() {
        return this.cpptData.ppa.role.some(
          item => item.toLowerCase() === 'dokter',
        );
      },
    },
    patient: {
      get() {
        return this.getPatientData;
      },
    },
    id_cppt: {
      get() {
        return this.getTableItems[this.index].id;
      },
    },
  },
  mounted() {
    this.selectedDate = this.historyList[0]; // set initial state to the newest update
    if (this.selectedDate) {
      this.getCppptCompareData();
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async getCppptCompareData() {
      try {
        this.loading = true;
        const response = await getCompareData(
          this.id_cppt,
          this.selectedDate.id,
        );
        const { compareFirst, compareSecond } = response.data.data;

        this.data.before = mapCompareData(compareFirst);
        this.data.after = mapCompareData(compareSecond);
        this.findDifference(this.data.before, this.data.after);

        if (this.isDoctor) {
          this.data.before = {
            ...this.data.before,
            ...mapCompareDataDoctorAssessment(compareFirst.assesment.doctor),
          };
          this.data.after = {
            ...this.data.after,
            ...mapCompareDataDoctorAssessment(compareSecond.assesment.doctor),
          };
          if (
            !this.arraysHaveSameContent(
              this.data.before.doctor.diagnose.value,
              this.data.after.doctor.diagnose.value,
            )
          ) {
            this.data.before.doctor.diagnose.isChanged = true;
            this.data.after.doctor.diagnose.isChanged = true;
          }
          if (
            !this.arraysHaveSameContent(
              this.data.before.doctor.planning.value,
              this.data.after.doctor.planning.value,
            )
          ) {
            this.data.before.doctor.planning.isChanged = true;
            this.data.after.doctor.planning.isChanged = true;
          }
        } else {
          // // mapping nurse assessment
          this.data.before = {
            ...this.data.before,
            ...mapCompareDataNurseAssessment(
              compareFirst.assesment.nurse.diagnose,
            ),
          };
          this.data.after = {
            ...this.data.after,
            ...mapCompareDataNurseAssessment(
              compareSecond.assesment.nurse.diagnose,
            ),
          };
          this.findDifferenceNurseAssessmentObject(
            this.data.before.nurse.value,
            this.data.after.nurse.value,
          );
        }
      } catch {
        this.data.after = null;
        this.data.before = null;
      } finally {
        this.loading = false;
      }
    },
    findDifference(obj1, obj2) {
      Object.values(obj1).forEach((i, index) => {
        if (i.value !== Object.values(obj2)[index].value) {
          this.data.before[
            Object.keys(this.data.before)[index]
          ].isChanged = true;

          this.data.after[Object.keys(this.data.after)[index]].isChanged = true;
        }
      });
    },
    findDifferenceNurseAssessmentObject(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        this.data.before.nurse.isChanged = true;
        this.data.after.nurse.isChanged = true;
      }

      arr1.forEach((i, index) => {
        if (i.name !== arr2[index].name) {
          this.data.before.nurse.value[index].isChanged = true;
          this.data.after.nurse.value[index].isChanged = true;
        }

        if (
          !this.arraysHaveSameContent(
            i.planning.value,
            arr2[index].planning.value,
          )
        ) {
          this.data.before.nurse.value[index].planning.isChanged = true;
          this.data.after.nurse.value[index].planning.isChanged = true;
        }
      });
    },
    arraysHaveSameContent(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      const sortedArr1 = arr1.slice().sort();
      const sortedArr2 = arr2.slice().sort();

      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          return false;
        }
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-size: 0.8vw;
}

.container {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px !important;
    border-radius: 5px;
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px !important;
    border-radius: 5px;
    background-color: rgb(163, 163, 163) !important;
  }

  .rect {
    width: 1vw;
    height: 1vw;
    margin-right: 0.3vw;
    border-radius: 4px;
    background-color: #dd9404;
  }
}
.header {
  display: flex;
  gap: 1vw;

  label {
    color: #616161;
  }

  span {
    color: #404040;
    font-weight: 500;
    margin-right: 1.2vw;
  }
}

.grid-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: start;
  width: 70%;
  row-gap: 0.5vw;
  padding-top: 1vw;

  label {
    font-weight: 500;
    color: #404040;
  }
}

.table-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2vw;
  padding-top: 1vw;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
