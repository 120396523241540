const moment = require('moment-timezone');
const _ = require('lodash');
const { limitText } = require('@/helper');

export default {
  methods: {
    mapWaitingListData(source) {
      return source.map(item => {
        return {
          id: item._id,
          date: item.detail?.plan_date
            ? moment(item.detail?.plan_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
            : '-',
          name: item.id_emr?.social?.name || '-',
          id_emr: item.id_emr?._id,
          rm: item.id_emr?.medical_record_number || '-',
          guarantor: item.detail?.insurance_type || '-',
          unit: item.id_registration?.id_unit?.name || '-',
          gender: item.id_emr?.social?.gender,
          age: item.id_emr?.social?.age,
          isNew: item.id_registration?.status?.isNew,
          case: item.detail?.case_type || '-',
          idRegistration: item.id_registration?._id,
        };
      });
    },
    mapRegisteredData(source) {
      return source.map(item => {
        return {
          id: item._id,
          idRegistration: item.registration?._id,
          isFinish: item.timestamps?.finished_by ? true : false,
          id_bed: item.id_bed,
          id_emr: item.id_emr,
          idPatient: item.id_emr,
          name: item.social?.name || '-',
          gender: item.social?.gender,
          birthDate: moment(item.social?.birth.date, 'YYYY-MM-DD').format(
            'DD-MM-YYYY',
          ),
          guarantor_type: _.capitalize(item.registration?.assurance?.type),
          age: item.social?.age,
          isNew: item.registration.status?.isNew,
          bed: _.get(item, 'bed_number', ''),
          rm: item.medical_record_number,
          case: item.letter?.service_type || '-',
          registered_date: item.timestamps?.created_at
            ? moment(item.timestamps?.created_at).format('DD-MM-YYYY, HH:mm')
            : '-',
          exit_date: item.timestamps?.finished_at
            ? moment(item.timestamps?.finished_at).format('DD-MM-YYYY, HH:mm')
            : '-',
          dpjp: limitText(item.letter?.dpjp?.[0], 20) || '-',
          doctor: item.letter?.dpjp || '-',
        };
      });
    },
  },
};
