import Constant from '@/const';
import axios from 'axios';

const getDiagnoseCheckup = async idRegistration => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/patient/registration/${idRegistration}/checkup`),
    );
    return response?.data?.data?.all_diagnose;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getDiagnoseCheckup;
