<template>
  <v-form ref="form">
    <section>
      <h4>DATA OBJEKTIF</h4>
      <v-row>
        <v-col>
          <label class="required">Tekanan Darah</label>
          <div class="d-flex align-center">
            <v-text-field
              v-model="form.sistole"
              :rules="[rule, rangeRules(form.sistole, 'sistol')]"
              :readonly="!isEdit"
              placeholder="Sistolik"
              type="number"
              validate-on-blur
              dense
              light
              single-line
            />
            <span class="mx-3">/</span>
            <v-text-field
              v-model="form.diastole"
              :rules="[rule, rangeRules(form.diastole, 'diastol')]"
              :readonly="!isEdit"
              placeholder="Diastolik"
              type="number"
              suffix="mmHg"
              validate-on-blur
              dense
              light
              single-line
            />
          </div>
          <label class="required">Suhu</label>
          <v-text-field
            v-model="form.temperature"
            :rules="[rule, rangeRules(form.temperature, 'temperature')]"
            :readonly="!isEdit"
            placeholder="00"
            suffix="°C"
            validate-on-blur
            type="number"
            dense
            light
            single-line
          />
          <label class="required">Nadi</label>
          <v-text-field
            v-model="form.heartRate"
            :rules="[rule, rangeRules(form.heartRate, 'heart-rate')]"
            :readonly="!isEdit"
            placeholder="00"
            suffix="x/menit"
            validate-on-blur
            type="number"
            dense
            light
            single-line
          />
          <label class="required">RR</label>
          <v-text-field
            v-model="form.rr"
            :rules="[rule, rangeRules(form.rr, 'rr')]"
            :readonly="!isEdit"
            placeholder="00"
            suffix="x/menit"
            validate-on-blur
            type="number"
            dense
            light
            single-line
          />
        </v-col>
        <v-col>
          <label class="required">Saturasi</label>
          <v-text-field
            v-model="form.saturation"
            :rules="[rule, rangeRules(form.saturation, 'saturation')]"
            :readonly="!isEdit"
            placeholder="00"
            suffix="%"
            validate-on-blur
            type="number"
            dense
            light
            single-line
          />
          <label>GDS</label>
          <v-text-field
            v-model="form.gds"
            class="mb-7"
            :readonly="!isEdit"
            placeholder="00"
            dense
            light
            hide-details
            single-line
          />
          <label>Data Objektif Lainnya</label>
          <vue-editor
            v-model="form.other"
            class="pt-4"
            :disabled="!isEdit"
            :placeholder="isEdit ? 'Data Objektif Lainnya' : ''"
            :editorToolbar="customToolbar"
          ></vue-editor>
          <span
            v-if="error.otherObjective.state && isEdit"
            class="error--text caption"
            >{{ error.otherObjective.message }}</span
          >
        </v-col>
      </v-row>
    </section>
  </v-form>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers('CPPTStore');

export default {
  name: 'ObjectiveData',
  components: {
    VueEditor,
  },
  data() {
    return {
      rule: v => !!v || 'Wajib Diisi',
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    };
  },
  watch: {
    'form.other'(val) {
      const char = val?.replace(/<[^>]*>/g, ''); // regex to remove html tag inside 'val'
      if (char?.length <= 3000) {
        this.setErrorVueEditor({
          otherObjective: {
            state: false,
            message: '',
          },
        });
      }
      if (char?.length > 3000) {
        this.setErrorVueEditor({
          otherObjective: {
            state: true,
            message: 'Maksimal 3000 karakter',
          },
        });
      }
    },
  },
  computed: {
    ...mapGetters(['getForm', 'getErrorVueEditor']),
    error: {
      get() {
        return this.getErrorVueEditor;
      },
      set(value) {
        this.setErrorVueEditor(value);
      },
    },
    form: {
      get() {
        return this.getForm.objective;
      },
    },
    isEdit: {
      get() {
        return this.getForm.isDetail ? this.getForm.isEdit : true;
      },
    },
  },
  methods: {
    ...mapMutations(['setErrorVueEditor']),
    rangeRules(value, type) {
      switch (type) {
        case 'sistol':
          if (value < 40 || value > 250) {
            return 'Angka tekanan darah tidak valid';
          } else {
            return true;
          }
        case 'diastol':
          if (value < 30 || value > 180) {
            return 'Angka tekanan darah tidak valid';
          } else {
            return true;
          }
        case 'heart-rate':
          if (value < 30 || value > 160) {
            return 'Angka nadi tidak valid';
          } else {
            return true;
          }
        case 'rr':
          if (value < 5 || value > 70) {
            return 'Angka RR tidak valid';
          } else {
            return true;
          }
        case 'temperature':
          if (value < 20 || value > 45) {
            return 'Angka suhu tidak valid';
          } else {
            return true;
          }
        case 'height':
          if (value <= 1) {
            return 'Angka tinggi badan tidak valid';
          } else {
            return true;
          }
        case 'saturation':
          if (value <= 1) {
            return 'Angka saturasi wajib diisi';
          } else {
            return true;
          }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  text-align: start;
  padding-top: 2vw;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 3vw;

  label {
    font-weight: 500;
    font-size: 0.8vw;
  }

  .quillWrapper {
    min-height: 10vh;
    max-width: 40vw;
  }

  ::v-deep .ql-editor {
    max-height: 1vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 9px;
      background-color: #ededed;
    }
    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 9px;
      background-color: #9e9e9e;
    }
  }
}

h4 {
  font-weight: 400;
  color: #137bc0;
  margin-bottom: 0.7vw;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
