import Constant from '@/const';
import axios from 'axios';

const getStaff = async ({ id_unit = '', isActive = true } = {}) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/master/staff?id_unit=${id_unit}&isActive=${isActive}`,
      ),
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getStaff;
