<template>
  <div>
    <div class="title-container">
      <h2>{{ data.title }}</h2>
      <v-btn fab text @click="closeForm()"><v-icon>mdi-close</v-icon></v-btn>
    </div>
    <div class="meta-container" v-if="data.firstCol">
      <label class="mr-8">{{ data.firstCol.label }}</label>
      <label class="mr-13 font-weight-bold">{{ data.firstCol.value }}</label>
      <label class="mr-8">{{ data.secondCol.label }}</label>
      <label class="mr-13 font-weight-bold">{{ data.secondCol.value }}</label>
      <label class="mr-8">{{ data.thirdCol.label }}</label>
      <label class="mr-13 font-weight-bold">{{ data.thirdCol.value }}</label>
      <label class="mr-8">{{ data.fourthCol?.label }}</label>
      <label class="mr-13 font-weight-bold">{{ data.fourthCol?.value }}</label>
      <label class="mr-8">{{ data.fifthCol?.label }}</label>
      <label class="mr-13 font-weight-bold">{{ data.fifthCol?.value }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InpatientEMRHeader',
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    closeForm() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.title-container {
  display: flex;
  padding: 0 0.5vw 0 3vw;
  justify-content: space-between;
  align-items: center;
}
.meta-container {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start;
  text-align: start;
  padding: 0 3vw 0.5vw 3vw;
  min-height: 2vw;

  label {
    font-size: 0.75vw;
  }
}
</style>
