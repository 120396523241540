<template>
  <v-form ref="form" class="form-container mt-4">
    <section>
      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label class="required">Palpasi Uterus</label>
          <v-text-field
            :rules="isEMR ? [] : rules.required"
            @keydown="checkKeyDownAlphaNumeric($event)"
            dense
            single-line
            v-model="form.uterinePalpation"
            class="input-field"
            placeholder="Palpasi Uterus"
            :readonly="isEMR"
          />
        </v-col>
        <v-col cols="6" class="pl-10">
          <label class="required">Presentasi Janin</label>
          <v-autocomplete
            dense
            single-line
            v-model="form.fetalPresentation"
            :items="[
              'Normal',
              'Shoulder/Transverse',
              'Face/Brow',
              'Breech (Complete)',
              'Breech (Flooting)',
              'Breech (Frank)',
            ]"
            class="input-field"
            placeholder="Pilih presentasi janin"
            append-icon="mdi-chevron-down"
            :readonly="isEMR"
            :rules="isEMR ? [] : rules.required"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label class="required">Tinggi Fundus Uteri</label>
          <v-text-field
            :rules="isEMR ? [] : rules.required"
            dense
            single-line
            v-model="form.fundusUteriHeight"
            class="input-field"
            placeholder="0"
            type="number"
            suffix="cm"
            :readonly="isEMR"
          />
        </v-col>
        <v-col cols="6" class="pl-10">
          <label class="required">Taksiran Berat Janin</label>
          <v-text-field
            :rules="isEMR ? [] : rules.required"
            dense
            single-line
            v-model="form.fetalWeightEstimation"
            class="input-field"
            placeholder="0"
            type="number"
            suffix="gram"
            :readonly="isEMR"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label class="required">Letak</label>
          <v-text-field
            :rules="isEMR ? [] : rules.required"
            @keydown="checkKeyDownAlphaNumeric($event)"
            dense
            single-line
            v-model="form.place"
            class="input-field"
            placeholder="Letak"
            :readonly="isEMR"
          />
        </v-col>
        <v-col cols="6" class="pl-10">
          <label class="required">Kontraksi</label>
          <v-radio-group
            class="input-field"
            :rules="isEMR ? [] : rules.radioRules"
            row
            v-model="form.isConstraction"
            :readonly="isEMR"
          >
            <v-radio label="Tidak" :value="false"></v-radio>
            <v-radio label="Ya" :value="true"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </section>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'ObstetricsAssessment',
);
export default {
  name: 'ObstreticalHistory',
  props: {
    isEMR: Boolean,
  },
  computed: {
    ...mapGetters(['getForm', 'getRules']),
    form: {
      get() {
        return this.getForm.obstreticalHistory;
      },
    },
    rules: {
      get() {
        return this.getRules;
      },
    },
  },
  methods: {
    ...mapMutations(['']),
    ...mapActions(['']),
    checkKeyDownAlphaNumeric(event) {
      if (!/[a-zA-Z0-9\s]/.test(event.key)) {
        this.ignoredValue = event.key ? event.key : '';
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: start;
  gap: 1.5rem;
  .divider {
    margin-top: 1.5rem;
    width: 100%;
  }
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: #3f484a;
  }
  .input-field {
    margin-top: 0.5rem;
    width: 100%;
  }
  h4 {
    font-weight: 400;
    color: #137bc0;
    text-align: start;
    margin-bottom: 0.7vw;
  }
}
section {
  width: 100%;
}
.required:after {
  content: ' *';
  color: red;
}
::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
