import Constant from '@/const';
import axios from 'axios';

const postGeneratePregnancyDueDate = async payload => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat(`/pregnancyassessment/edd`),
      payload,
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default postGeneratePregnancyDueDate;
