<template>
  <v-card>
    <v-container fluid class="pt-0">
      <div class="head">
        <v-card-title class="d-flex justify-space-between pa-5">
          <h4>Akses User</h4>
          <v-icon @click="onCloseButton()">mdi-close</v-icon>
        </v-card-title>
        <div class="meta mb-12">
          <span class="meta-text font-weight-bold">Nama</span>
          <span class="meta-text text-uppercase">{{
            staffDetail.name || '-'
          }}</span>
          <span class="meta-text font-weight-bold">Role Utama</span>
          <span class="meta-text text-capitalize">{{
            staffDetail.role.role[0] || '-'
          }}</span>
          <span class="meta-text font-weight-bold">Role Sekunder</span>
          <span class="meta-text text-capitalize mr-4 text-truncate">{{
            staffDetail.role.role.slice(1).join(', ') || '-'
          }}</span>
          <span class="meta-text font-weight-bold">Spesialis</span>
          <span class="meta-text text-capitalize">{{
            staffDetail.specialist || '-'
          }}</span>
        </div>
      </div>
      <v-row>
        <v-col class="list-card" cols="3">
          <v-card class="mx-auto" max-width="310" flat tile>
            <v-list>
              <v-list-item-group v-model="selectedItemList" color="primary">
                <v-list-item
                  v-for="(item, i) in staffDetail.role.unit"
                  :key="i"
                >
                  <v-list-item-content
                    @click="getAccessByUnit(item.id_unit._id)"
                  >
                    <v-list-item-title class="d-flex justify-start">{{
                      item.id_unit.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col class="treeview-select" cols="9">
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="isLoading"
          ></v-progress-linear>
          <v-row>
            <v-col cols="4">
              <v-treeview
                dense
                light
                return-object
                transition
                selectable
                class="text-left body-2 mb-4"
                :items="treeviewItems.laboratory"
                v-model="selectedTreeViewItems.laboratory"
              ></v-treeview>
              <v-treeview
                dense
                light
                return-object
                transition
                selectable
                class="text-left body-2 mb-4"
                :items="treeviewItems.service"
                v-model="selectedTreeViewItems.service"
              ></v-treeview>
              <v-treeview
                dense
                light
                return-object
                transition
                selectable
                class="text-left body-2 mb-4"
                :items="treeviewItems.inventory"
                v-model="selectedTreeViewItems.inventory"
              ></v-treeview>
              <v-treeview
                dense
                light
                return-object
                transition
                selectable
                class="text-left body-2 mb-4"
                :items="treeviewItems.setting"
                v-model="selectedTreeViewItems.setting"
              ></v-treeview>
            </v-col>
            <v-col cols="4"
              ><v-treeview
                dense
                light
                return-object
                transition
                selectable
                class="text-left body-2 mb-4"
                :items="treeviewItems.masterData"
                v-model="selectedTreeViewItems.masterData"
              ></v-treeview>
              <v-treeview
                dense
                light
                return-object
                transition
                selectable
                class="text-left body-2 mb-4"
                :items="treeviewItems.pharmacy"
                v-model="selectedTreeViewItems.pharmacy"
              ></v-treeview>
              <v-treeview
                dense
                light
                return-object
                transition
                selectable
                class="text-left body-2 mb-4"
                :items="treeviewItems.reporting"
                v-model="selectedTreeViewItems.reporting"
              ></v-treeview>
              <v-treeview
                dense
                light
                return-object
                transition
                selectable
                class="text-left body-2 mb-4"
                :items="treeviewItems.bridging"
                v-model="selectedTreeViewItems.bridging"
              ></v-treeview>
            </v-col>
            <v-col cols="4">
              <v-treeview
                dense
                light
                return-object
                transition
                selectable
                class="text-left body-2 mb-4"
                :items="treeviewItems.registration"
                v-model="selectedTreeViewItems.registration"
              ></v-treeview>
              <v-treeview
                dense
                light
                return-object
                transition
                selectable
                class="text-left body-2 mb-4"
                :items="treeviewItems.finance"
                v-model="selectedTreeViewItems.finance"
              ></v-treeview>
              <v-treeview
                dense
                light
                return-object
                transition
                selectable
                class="text-left body-2 mb-4"
                :items="treeviewItems.emr"
                v-model="selectedTreeViewItems.emr"
              ></v-treeview>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end pr-15 pt-5">
        <v-btn
          class="mr-10"
          flat
          depressed
          color="primary"
          @click="updateAccess()"
          >Simpan</v-btn
        >
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
const axios = require('axios');
import AlertMixin from '@/mixin/alertMixin';
import Constant from '@/const';

export default {
  name: 'AccessRights',
  mixins: [AlertMixin],
  props: {
    staffDetail: Object,
  },
  data() {
    return {
      isLoading: false,
      isLoadingUpdate: false,
      selectedItemList: 0,
      activeIdUnit: '',
      items: [],
      treeviewItems: {
        laboratory: [],
        service: [],
        inventory: [],
        masterData: [],
        pharmacy: [],
        reporting: [],
        registration: [],
        finance: [],
        emr: [],
        setting: [],
        bridging: [],
      },
      selectedTreeViewItems: {
        laboratory: [],
        service: [],
        inventory: [],
        masterData: [],
        pharmacy: [],
        reporting: [],
        registration: [],
        finance: [],
        emr: [],
        setting: [],
        bridging: [],
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getAccessByUnit(this.staffDetail.role.unit[0].id_unit._id);
    });
  },
  methods: {
    onCloseButton() {
      this.$emit('close-dialog');
    },
    extractDataId(array) {
      const id = array.map(item => item.id);
      return id;
    },
    async updateAccess() {
      this.isLoadingUpdate = true;
      const access = [];
      const {
        laboratory,
        service,
        inventory,
        masterData,
        pharmacy,
        reporting,
        registration,
        finance,
        emr,
        setting,
        bridging,
      } = this.selectedTreeViewItems;
      const allItems = [
        laboratory,
        service,
        inventory,
        masterData,
        pharmacy,
        reporting,
        registration,
        finance,
        emr,
        setting,
        bridging,
      ];
      allItems.forEach(item => {
        access.push(...this.extractDataId(item));
      });
      const payload = {
        access,
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.name,
        },
      };
      try {
        const response = await axios.put(
          Constant.apiUrl.concat(
            `/master/staff/staffAccess/update/${this.staffDetail.id}/${this.activeIdUnit}`,
          ),
          payload,
        );
        if (response.status) {
          this.showFillSuccess('Berhasil Mengubah Hak Akses');
        }
      } catch (error) {
        this.showErrorAxios(error);
      } finally {
        this.isLoadingUpdate = false;
      }
    },
    async getAccessByUnit(idUnit) {
      this.isLoading = true;
      this.activeIdUnit = idUnit; // save id unit for payload updating access
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/master/staff/staffAccess/${this.staffDetail.id}/${idUnit}`,
          ),
        );
        const { data } = response.data;
        data.forEach((item, index) => {
          this.mapMenuByUnit(item, index);
        });
      } catch {
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
    mapMenuByUnit(menu, index) {
      switch (menu.menu) {
        case 'Pelayanan':
          this.treeviewItems.service = [
            {
              id: index,
              name: menu.menu,
              children: menu.children.map(item => {
                return {
                  id: item.code,
                  _id: item._id,
                  name: item.subMenu,
                };
              }),
            },
          ];
          this.selectedTreeViewItems.service = menu.children
            .filter(submenu => submenu.status)
            .map(submenu => {
              return {
                _id: submenu.id,
                id: submenu.code,
                name: submenu.subMenu,
              };
            });
          break;
        case 'Master Data':
          this.treeviewItems.masterData = [
            {
              id: index,
              name: menu.menu,
              children: menu.children.map(item => {
                return {
                  id: item.code,
                  _id: item._id,
                  name: item.subMenu,
                };
              }),
            },
          ];
          this.selectedTreeViewItems.masterData = menu.children
            .filter(submenu => submenu.status)
            .map(submenu => {
              return {
                _id: submenu.id,
                id: submenu.code,
                name: submenu.subMenu,
              };
            });
          break;
        case 'Inventaris':
          this.treeviewItems.inventory = [
            {
              id: index,
              name: menu.menu,
              children: menu.children.map(item => {
                return {
                  id: item.code,
                  _id: item._id,
                  name: item.subMenu,
                };
              }),
            },
          ];
          this.selectedTreeViewItems.inventory = menu.children
            .filter(submenu => submenu.status)
            .map(submenu => {
              return {
                _id: submenu.id,
                id: submenu.code,
                name: submenu.subMenu,
              };
            });
          break;
        case 'Farmasi':
          this.treeviewItems.pharmacy = [
            {
              id: index,
              name: menu.menu,
              children: menu.children.map(item => {
                return {
                  id: item.code,
                  _id: item._id,
                  name: item.subMenu,
                };
              }),
            },
          ];
          this.selectedTreeViewItems.pharmacy = menu.children
            .filter(submenu => submenu.status)
            .map(submenu => {
              return {
                _id: submenu.id,
                id: submenu.code,
                name: submenu.subMenu,
              };
            });
          break;
        case 'Reporting':
          this.treeviewItems.reporting = [
            {
              id: index,
              name: menu.menu,
              children: menu.children.map(item => {
                return {
                  id: item.code,
                  _id: item._id,
                  name: item.subMenu,
                };
              }),
            },
          ];
          this.selectedTreeViewItems.reporting = menu.children
            .filter(submenu => submenu.status)
            .map(submenu => {
              return {
                _id: submenu.id,
                id: submenu.code,
                name: submenu.subMenu,
              };
            });
          break;
        case 'Laboratorium':
          this.treeviewItems.laboratory = [
            {
              id: index,
              name: menu.menu,
              children: menu.children.map(item => {
                return {
                  id: item.code,
                  _id: item._id,
                  name: item.subMenu,
                };
              }),
            },
          ];
          this.selectedTreeViewItems.laboratory = menu.children
            .filter(submenu => submenu.status)
            .map(submenu => {
              return {
                _id: submenu.id,
                id: submenu.code,
                name: submenu.subMenu,
              };
            });
          break;
        case 'Pendaftaran':
          this.treeviewItems.registration = [
            {
              id: index,
              name: menu.menu,
              children: menu.children.map(item => {
                return {
                  id: item.code,
                  _id: item._id,
                  name: item.subMenu,
                };
              }),
            },
          ];
          this.selectedTreeViewItems.registration = menu.children
            .filter(submenu => submenu.status)
            .map(submenu => {
              return {
                _id: submenu.id,
                id: submenu.code,
                name: submenu.subMenu,
              };
            });
          break;
        case 'Keuangan':
          this.treeviewItems.finance = [
            {
              id: index,
              name: menu.menu,
              children: menu.children.map(item => {
                return {
                  id: item.code,
                  _id: item._id,
                  name: item.subMenu,
                };
              }),
            },
          ];
          this.selectedTreeViewItems.finance = menu.children
            .filter(submenu => submenu.status)
            .map(submenu => {
              return {
                _id: submenu.id,
                id: submenu.code,
                name: submenu.subMenu,
              };
            });
          break;
        case 'EMR':
          this.treeviewItems.emr = [
            {
              id: index,
              name: menu.menu,
              children: menu.children.map(item => {
                return {
                  id: item.code,
                  _id: item._id,
                  name: item.subMenu,
                };
              }),
            },
          ];
          this.selectedTreeViewItems.emr = menu.children
            .filter(submenu => submenu.status)
            .map(submenu => {
              return {
                _id: submenu.id,
                id: submenu.code,
                name: submenu.subMenu,
              };
            });
          break;
        case 'Setting':
          this.treeviewItems.setting = [
            {
              id: index,
              name: menu.menu,
              children: menu.children.map(item => {
                return {
                  id: item.code,
                  _id: item._id,
                  name: item.subMenu,
                };
              }),
            },
          ];
          this.selectedTreeViewItems.setting = menu.children
            .filter(submenu => submenu.status)
            .map(submenu => {
              return {
                _id: submenu.id,
                id: submenu.code,
                name: submenu.subMenu,
              };
            });
          break;
        case 'Bridging':
          this.treeviewItems.bridging = [
            {
              id: index,
              name: menu.menu,
              children: menu.children.map(item => {
                return {
                  id: item.code,
                  _id: item._id,
                  name: item.subMenu,
                };
              }),
            },
          ];
          this.selectedTreeViewItems.bridging = menu.children
            .filter(submenu => submenu.status)
            .map(submenu => {
              return {
                _id: submenu.id,
                id: submenu.code,
                name: submenu.subMenu,
              };
            });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.head {
  position: sticky;
  top: 0;
  background-color: white;
  border-bottom: solid 3px #f4f5f5;
  z-index: 99;
}
.meta {
  width: 100%;
  text-align: left;
  padding-left: 1.25rem;
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 0.75fr 1.25fr 1fr 2.5fr 0.65fr 3fr;
  .meta-text {
    font-size: 0.9rem;
  }
}
.list-card {
  border-top: solid 2px #f4f5f5;
  height: 70vh;
  overflow-y: auto;
}
.treeview-select {
  height: 70vh;
  overflow-y: auto;
  border-top: solid 3px #f4f5f5;
  border-left: solid 3px #f4f5f5;
}
</style>
