<template>
  <div class="detail-input">
    <div class="detail-input__label">
      <label for="checkingType" :class="required ? 'required' : ''">
        {{ propsLabel }}
      </label>
    </div>
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: 'TemplateInput',
  props: {
    id: {
      type: String,
      default: '',
    },
    propsLabel: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-input {
  display: flex;
  align-items: baseline;
  padding-bottom: 10px;

  &__label {
    justify-content: flex-start;
    flex: 1;
    display: flex;
    color: #222;
    margin-right: 10px;
    max-width: 160px;
    min-width: 160px;
  }
}

.required:after {
  content: ' *';
  color: red;
}
</style>
