import Constant from '../../../const';
const axios = require('axios');

const getAllSurgicalSafetyChecklist = async idEmr => {
  const response = await axios.get(
    Constant.apiUrl.concat(`/document/surgical-safety-checklist-all/${idEmr}`),
  );

  return response;
};

export default getAllSurgicalSafetyChecklist;
