<template>
  <v-row no-gutters class="d-flex flex-column">
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="font-weight-bold text-left mr-auto"></label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Januari</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Februari</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Maret</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 April</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Mei</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Juni</label>
      </v-col>
    </div>

    <div style="display: grid;" class="text-left">
      <label class="text-left primary--text font-weight-bold">ASET</label>
      <label class="text-left font-weight-bold">ASET LANCAR</label>
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto pl-3">Kas dan Setara Kas</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex subtotalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto"
        >JUMLAH ASET LANCAR</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div style="display: grid;" class="text-left">
      <label class="text-left font-weight-bold">ASET TIDAK LANCAR</label>
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto pl-3">Biaya Perolehan</label>
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto pl-6 grey--text">Tanah</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex subtotalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto"
        >JUMLAH ASET TIDAK LANCAR</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex subtotalComp-style px-2 primary--text font-weight-bold">
      <label class="text-left mr-auto">JUMLAH ASET</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div style="display: grid;" class="text-left">
      <label class="text-left primary--text font-weight-bold">UTANG</label>
      <label class="text-left font-weight-bold">UTANG JANGKA PENDEK</label>
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto pl-3">Utang Usaha</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex subtotalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto"
        >JUMLAH UTANG JANGKA PENDEK</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex subtotalComp-style px-2 primary--text font-weight-bold">
      <label class="text-left mr-auto">JUMLAH UTANG</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div style="display: grid;" class="text-left">
      <label class="text-left primary--text font-weight-bold">EKUITAS</label>
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto pl-3">Modal Awal</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex subtotalComp-style px-2 primary--text font-weight-bold">
      <label class="text-left mr-auto">JUMLAH EKUITAS</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex totalComp-style px-2 font-weight-bold">
      <label class="text-left mr-auto">JUMLAH UTANG DAN EKUITAS</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'Comparative',
};
</script>

<style lang="scss" scoped>
.column-style {
  max-width: 12%;
  text-align: right;
}
.subtotalComp-style {
  background-color: #deeef9;
  height: 5vh;
  align-items: center;
  margin: 3px 0;
}

.totalComp-style {
  background-color: #addaf9;
  height: 5vh;
  align-items: center;
  margin: 3px 0;
}
</style>
