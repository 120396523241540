import Constant from '@/const';
import axios from 'axios';

const deleteLaboratory = async id => {
  try {
    const response = await axios.delete(
      Constant.apiUrl.concat(`/master/laboratory/${id}`),
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default deleteLaboratory;
