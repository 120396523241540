<template>
  <v-container grid-list-md text-md-center class="order-or-new" fluid>
    <v-layout row class="row-style">
      <v-flex xs6 class="block-detail">
        <h1 class="mb-3">Non Racik</h1>
        <v-container class="ma-0 pa-0 non-racik-block">
          <div class="scroll">
            <v-card
              class="queue"
              v-for="(queue, index) in nonracik"
              :key="'nonracik-' + index"
            >
              <v-layout row>
                <v-flex md1 class="number">
                  <h1>{{ index + 1 }}</h1>
                </v-flex>
                <v-flex md4 class="outer">
                  <v-flex class="outer">
                    <div class="name">
                      <h2>{{ queue.name }}</h2>
                    </div>
                  </v-flex>
                  <h4>{{ queue.gender }}, {{ queue.age }} Tahun</h4>
                </v-flex>
                <v-flex md4 class="unit">
                  <h3>{{ queue.unit }}</h3>
                </v-flex>
                <v-flex md3 class="status">
                  <h4>{{ queue.status }}</h4>
                </v-flex>
              </v-layout>
            </v-card>
          </div>
        </v-container>
      </v-flex>
      <v-flex xs6 class="block-detail non-racik-block">
        <h1 class="mb-3">Racik</h1>
        <v-container class="ma-0 pa-0 racik-block">
          <div class="scroll">
            <v-card
              class="queue"
              v-for="(queue, index) in racik"
              :key="'racik-' + index"
            >
              <v-layout row>
                <v-flex md1 class="number">
                  <h1>{{ index + 1 }}</h1>
                </v-flex>
                <v-flex md4 class="outer">
                  <v-flex class="outer">
                    <div class="name">
                      <h2>{{ queue.name }}</h2>
                    </div>
                  </v-flex>
                  <h4>{{ queue.gender }}, {{ queue.age }} Tahun</h4>
                </v-flex>
                <v-flex md4 class="unit">
                  <h3>{{ queue.unit }}</h3>
                </v-flex>
                <v-flex md3 class="status">
                  <h4>{{ queue.status }}</h4>
                </v-flex>
              </v-layout>
            </v-card>
          </div>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Constant from '@/const';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'DrugQueue',
  data() {
    return {
      time: null,
      racik: [],
      nonracik: [],
      resource: {
        status: ['Diterima', 'Dibuat', 'Penyerahan'],
      },
    };
  },
  mounted() {
    this.getPatientQueue();
  },
  methods: {
    getPatientQueue() {
      axios.get(Constant.apiUrl.concat('/pharmacy/queue')).then(response => {
        const queueData = response.data.data;

        this.nonracik = queueData.queue.map(queue => {
          return {
            age: queue.age,
            name: _.get(queue, 'name'),
            status: _.startCase(queue.status),
            unit: _.get(queue, 'polyclinic'),
            gender: _.get(queue, 'gender'),
          };
        });

        this.racik = queueData.queueMix.map(mix => {
          return {
            age: _.get(mix, 'age'),
            name: _.get(mix, 'name'),
            status: _.startCase(mix.status),
            unit: _.get(mix, 'polyclinic'),
            gender: _.get(mix, 'gender'),
          };
        });
      });
    },
    // setTimeoutOpenNextForm() {
    //   setTimeout(() => {
    //     this.openNextForm();
    //   }, 500);
    // },
    // openNextForm() {
    //   const item = { index: 2, noMr: this.noMr };
    //   this.$emit('change-form', item);
    // },
    // refreshAndAutoUpdate() {
    //   if (this.time !== null) {
    //     this.cancelAutoUpdate();
    //   }
    //   this.tableLoading = [true, true, true];
    //   this.getPatientQueue();

    //   this.time = setInterval(this.getPatientQueue, 15000);
    // },
    // cancelAutoUpdate() {
    //   window.clearInterval(this.time);
    // }
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>

<style lang="scss" scoped>
.order-or-new {
  overflow: hidden !important;
  // position: relative;
  font-family: Roboto, sans-serif !important;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-image: url('~@/assets/image/klinik.png');
  background-repeat: no-repeat;
  background-size: cover;

  .btn-absolute {
    display: absolute;
    top: 20%;
    bottom: 70%;
  }
  .row-style {
    height: 100%;
    color: white;

    background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.7));
    margin: 0 !important;
    padding: 2em 3em !important;
    .block-detail {
      overflow-y: hidden;
      display: inherit;
      flex-direction: column;
      height: 100% !important;
      justify-content: flex-start;
      position: relative;
    }
    .title {
      margin: 1em;
      font-size: 4rem;
    }
    .racik-block {
      overflow-y: hidden;
    }
    .non-racik-block {
      overflow-y: hidden;
    }
    .queue {
      display: inherit;
      color: white;
      justify-content: flex-start;
      background: rgba(0, 0, 0, 0.2);
      margin: 0.8em 1em;
      padding: 1em;
      min-height: 6em;
      .outer {
        overflow-x: hidden;
        .name {
          white-space: nowrap;
          animation: loop-anim 5s linear infinite;
        }
      }
      .unit {
        padding: auto;
        margin: auto;
      }
      .number {
        padding: auto;
        margin: auto;
      }
      .status {
        border: 0.02em solid #f4f4f4;
        margin: auto 0;
        border-radius: 0.25em;
        padding: auto;
      }
    }
  }
}
@keyframes loop-anim {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -50%; /* This works because of the div between "outer" and "loop" */
  }
}
@keyframes loop-scroll {
  0% {
    margin-top: 30%;
  }
  100% {
    margin-top: -100%; /* This works because of the div between "outer" and "loop" */
  }
}

@keyframes stretch {
  0% {
    transform: scale(0.65);
  }
}
</style>
