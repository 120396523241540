<template>
  <div :class="!isTotalView && 'bordered'">
    <div class="d-flex justify-space-between">
      <h3 class="text-start" v-if="!isTotalView">Total Kunjungan</h3>
      <v-btn
        v-if="isAssuranceType"
        @click="switchView()"
        text
        class="text-capitalize"
        color="primary"
        style="letter-spacing: 0.1px;"
      >
        <span class="primary--text font-weight-bold">
          Lihat Total Kunjungan Harian
        </span>
        <v-icon color="primary" class="ml-1">mdi-arrow-right</v-icon>
      </v-btn>
    </div>
    <v-progress-linear
      width="3"
      color="primary"
      size="20"
      indeterminate
      v-show="loading.updating"
    />
    <div
      :class="
        `table grey--text font-weight-bold ${
          isTotalView ? 'grid-header' : 'flex-header'
        }`
      "
    >
      <span v-for="(item, index) in header" :key="`header-${index}`">{{
        item
      }}</span>
    </div>
    <div
      v-for="(item, index) in table"
      :key="`row-${index}`"
      :class="`table ${isTotalView ? 'grid-content' : 'flex-content'}`"
    >
      <span
        v-for="(value, index) in Object.values(item)"
        :key="`span-${index}`"
        >{{ value }}</span
      >
    </div>
    <div class="table footer font-weight-bold">
      <div class="d-flex justify-center">TOTAL</div>
      <span class="text-end">{{ total }}</span>
    </div>
  </div>
</template>

<script>
const { mapGetters } = createNamespacedHelpers('PatientVisitStore');
import { createNamespacedHelpers } from 'vuex';

export default {
  name: 'DetailTablePatientVisit',
  props: {
    isAssuranceType: {
      type: Boolean,
      default: false,
    },
    isTotalView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getTable', 'getLoading']),
    loading: {
      get() {
        return this.getLoading;
      },
    },
    table: {
      get() {
        if (this.isTotalView) {
          return this.getTable.daily;
        }
        return this.isAssuranceType
          ? this.getTable.assurance
          : this.getTable.unit;
      },
    },
    total: {
      get() {
        return this.table.reduce((a, { amount }) => a + amount, 0);
      },
    },
    header: {
      get() {
        return this.isTotalView
          ? ['Unit', 'BPJS', 'Umum', 'Asuransi', 'Jumlah']
          : [`${this.isAssuranceType ? 'Tipe Penjamin' : 'Unit'}`, 'Jumlah'];
      },
    },
  },
  methods: {
    switchView() {
      this.$emit('switch-view');
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin row-table($background, $isGrid: false) {
  @if $isGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background-color: $background;
    border-bottom: 2px solid #e7e7e7;
    padding: 0.6vw 1.6vw;

    span {
      font-size: 0.8vw;
    }

    span:nth-child(-n + 4) {
      text-align: start;
    }
    span:last-child {
      text-align: end;
    }
  } @else {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5vh;
    padding: 0.4vw 1.6vw;
    background-color: $background;
    width: auto;
    border-bottom: 2px solid #e7e7e7;

    span {
      font-size: 0.9vw;
    }
  }
}

.bordered {
  border: 2px solid #e0e0e0;
  border-radius: 0.6vw;
  padding: 1vw;
}

.table {
  &.flex-header {
    @include row-table(#fafafa);
  }
  &.grid-header {
    @include row-table(#fafafa, true);
  }
  &.flex-content {
    @include row-table(transparent);
  }
  &.grid-content {
    @include row-table(transparent, true);
  }
  &.footer {
    display: grid;
    grid-template-columns: 85% 15%;
    background-color: #fafafa;
    padding: 0.9vw 1.6vw;
    border-bottom: 2px solid #e7e7e7;
  }
}
</style>
