<template>
  <v-card>
    <div class="fixed-header">
      <v-toolbar flat prominent class="mb-5 pa-3">
        <v-container fluid>
          <div class="d-flex justify-space-between">
            <h2 class="text-left mb-7">Tambah Obat</h2>
            <v-btn @click="$emit('close-dialog')" small fab text>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-row>
            <v-col cols="8">
              <v-row class="header-grid">
                <span class="font-weight-bold text-left">No RM</span>
                <span class="black--text mx-6 text-left">{{
                  patientData.rmNumber
                }}</span>

                <span class=" font-weight-bold text-left">Name</span>
                <span class="black--text mx-6  text-left"
                  >{{ patientData.meta.name }} ({{
                    patientData.meta.gender ? 'L' : 'P'
                  }})</span
                >

                <span class=" font-weight-bold text-left">Tanggal Lahir</span>
                <span class="black--text mx-6  text-left"
                  >{{ patientData.meta.birthDate }} ({{
                    patientData.meta.age
                  }}
                  tahun)</span
                >
                <span class=" font-weight-bold text-left">DPJP</span>
                <span class="black--text text-left mx-6 ">{{
                  patientData.meta.doctor
                }}</span>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row class="mb-4">
                <span class="text-left font-weight-bold">Riwayat Alergi</span>
              </v-row>
              <v-row class="mt-n4">
                <span
                  class="red--text font-weight-bold body-2"
                  v-if="!allergy?.length"
                  >Tidak ada alergi</span
                >
                <v-chip
                  v-for="(allergy, idx) in allergy"
                  :key="idx"
                  color="#EB4747"
                  class="allergy mr-2 mb-2"
                  label
                  small
                  caption
                  >{{ allergy }}</v-chip
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>
      <v-tabs v-model="tabs" grow>
        <v-tab class="text-none">Non Racik</v-tab>
        <v-tab class="text-none">Racik</v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-container fluid>
          <v-row no-gutters style="margin-bottom: -140px">
            <v-col cols="4">
              <div class="col-layout py-5 px-10">
                <label class="body-2 font-weight-bold text-left"
                  >Nama Obat</label
                >
                <v-combobox
                  placeholder="Pilih nama obat"
                  dense
                  hide-details="false"
                  :loading="loading"
                  :items="resource.drugs"
                  item-text="displayName"
                  append-icon="mdi-chevron-down"
                  :search-input.sync="searchDrug"
                  v-model="input.nonracik.drugName"
                  @change="
                    if (input.nonracik.drugName) {
                      input.nonracik.dosage = input.nonracik.drugName.strength;
                      input.nonracik.usage.dropdownInput.package =
                        input.nonracik.drugName.preparation;
                    } else {
                      return '';
                    }
                  "
                  clearable
                  @click:clear="onClear()"
                ></v-combobox>

                <v-spacer></v-spacer>
                <v-checkbox
                  v-model="input.nonracik.isExtra"
                  class="padding mt-n7"
                  dense
                  label="Obat Extra"
                ></v-checkbox>

                <label class="body-2 mt-n10 font-weight-bold text-left"
                  >Jenis Sediaan</label
                >
                <label class="text-left body-2 mt-n10">{{
                  input.nonracik.drugName
                    ? input.nonracik.drugName.preparation
                    : ''
                }}</label>

                <label class="body-2 mt-n10 font-weight-bold text-left"
                  >Dosis</label
                >
                <v-text-field
                  placeholder="Masukkan dosis"
                  dense
                  class=" mt-n10"
                  hide-details="false"
                  v-model.number="input.nonracik.dosage"
                  :suffix="
                    input.nonracik.drugName
                      ? input.nonracik.drugName.strength_unit
                      : ''
                  "
                ></v-text-field>

                <label class="body-2 mt-n10 font-weight-bold text-left"
                  >Jumlah</label
                >
                <v-text-field
                  placeholder="Masukkan jumlah"
                  dense
                  class=" mt-n10 pb-0"
                  hide-details="false"
                  v-model.number="input.nonracik.amount"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="4">
              <v-row>
                <div class="col-layout usage py-5 px-10 mt-2">
                  <label class="body-2 font-weight-bold text-left"
                    >Aturan Pakai</label
                  >
                  <v-row>
                    <v-col cols="2" class="pa-0">
                      <v-radio-group v-model="input.radio_usage">
                        <v-radio class="mb-5" :value="0"></v-radio>
                        <v-radio :value="1"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="10" class="pa-0" style="margin-left: -20px">
                      <div class="usage-rules">
                        <v-combobox
                          :disabled="input.radio_usage !== 0"
                          class="form-body__data pt-0 mt-0 mb-5 pt-2"
                          append-icon=""
                          auto-select-first
                          dense
                          v-model="input.nonracik.usage.dropdownInput.day"
                          :items="[
                            '1',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                            '10',
                          ]"
                        ></v-combobox>
                        <span>x</span>
                        <v-combobox
                          :disabled="input.radio_usage !== 0"
                          append-icon=""
                          class="form-body__data pt-0 mt-0 mb-5 pt-2"
                          dense
                          auto-select-first
                          :items="[
                            '1/4',
                            '1/3',
                            '1/2',
                            '2/3',
                            '3/4',
                            '1',
                            '2',
                            '3',
                            '4',
                            '5',
                          ]"
                          v-model="input.nonracik.usage.dropdownInput.usage"
                        ></v-combobox>
                        <v-combobox
                          :disabled="input.radio_usage !== 0"
                          class="form-body__data pt-0 mt-0 mb-5 pt-2"
                          dense
                          auto-select-first
                          append-icon="mdi-chevron-down"
                          :items="resource.packaging.nonracik"
                          v-model="input.nonracik.usage.dropdownInput.package"
                        >
                        </v-combobox>
                      </div>
                      <div class="usage-rules2">
                        <v-text-field
                          :disabled="input.radio_usage !== 1"
                          class="form-body__data pt-2 mt-0"
                          dense
                          v-model="input.nonracik.usage.manualInput.usage"
                        ></v-text-field>
                        <v-combobox
                          :disabled="input.radio_usage !== 1"
                          class="form-body__data pt-0 mt-0 mb-5 pt-2"
                          dense
                          auto-select-first
                          append-icon="mdi-chevron-down"
                          :items="resource.packaging.nonracik"
                          v-model="input.nonracik.usage.manualInput.package"
                        >
                        </v-combobox>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- <v-text-field
                placeholder="Masukkan aturan pakai"
                dense
                hide-details="false"
                v-model="input.nonracik.usageRule"
              ></v-text-field> -->
                </div>
              </v-row>
              <v-row>
                <div class="col-layout py-5 px-10">
                  <label class="body-2 font-weight-bold text-left">Rute</label>
                  <v-select
                    dense
                    placeholder="Pilih Rute"
                    :items="resource.route"
                    v-model="input.nonracik.route"
                    hide-details="false"
                    append-icon="mdi-chevron-down"
                  ></v-select>

                  <label class="body-2 font-weight-bold text-left"
                    >Aturan Umum</label
                  >
                  <v-select
                    dense
                    placeholder="Aturan Umum"
                    :items="resource.generalRules"
                    append-icon="mdi-chevron-down"
                    v-model="input.nonracik.generalRules"
                    hide-details="false"
                  ></v-select>
                </div>
              </v-row>
            </v-col>
            <v-col cols="4">
              <div class="col-layout py-5 px-10">
                <label class="body-2 font-weight-bold text-left"
                  >Instruksi Khusus</label
                >
                <v-text-field
                  placeholder="Instruksi khusus"
                  dense
                  hide-details="false"
                  v-model.number="input.nonracik.special_instruction"
                ></v-text-field>
                <label class="body-2 font-weight-bold text-left"
                  >Keterangan</label
                >
                <v-textarea
                  placeholder="Masukkan keterangan"
                  dense
                  no-resize
                  outlined
                  hide-details="false"
                  v-model="input.nonracik.note"
                ></v-textarea>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="4">
              <h3
                ref="nurse"
                class="pl-10 text-left font-weight-bold primary--text"
              >
                Tambah Bahan Obat Racik
              </h3>
              <div class="col-layout table-col py-5 px-10">
                <label class="body-2 font-weight-bold text-left"
                  >Nama Obat</label
                >
                <v-combobox
                  placeholder="Pilih nama obat"
                  dense
                  hide-details="false"
                  :loading="loading"
                  :items="resource.drugs"
                  append-icon="mdi-chevron-down"
                  item-text="displayName"
                  :search-input.sync="searchDrugRacik"
                  v-model="input.racik.drugName"
                  clearable
                  @change="
                    input.racik.drugName
                      ? (input.racik.dosage = input.racik.drugName.strength)
                      : '';
                    input.racik.drugName
                      ? (mixDosageValue = input.racik.drugName.strength)
                      : '';
                  "
                  @click:clear="onClear()"
                ></v-combobox>

                <label class="body-2 font-weight-bold text-left"
                  >Jenis Sediaan</label
                >
                <label class="text-left body-2">{{
                  input.racik.drugName ? input.racik.drugName.preparation : ''
                }}</label>

                <label class="body-2 font-weight-bold text-left">Dosis</label>
                <v-text-field
                  placeholder="Masukkan dosis"
                  dense
                  hide-details="false"
                  v-model.number="input.racik.dosage"
                  :suffix="
                    input.racik.drugName
                      ? input.racik.drugName.strength_unit
                      : ''
                  "
                ></v-text-field>
              </div>
              <v-row class="mb-3 ml-1">
                <v-col cols="12">
                  <v-chip-group
                    class="d-flex justify-space-between"
                    active-class="white--text blue"
                    v-model="selectedMultiple"
                  >
                    <v-chip
                      style="cursor: pointer; min-width: 20px"
                      class="d-flex justify-center"
                      v-for="(x, i) in multiple"
                      :value="x.value"
                      :key="i"
                      ><strong>{{ x.text }}</strong></v-chip
                    >
                  </v-chip-group>
                </v-col>
              </v-row>
              <v-row class="pl-5 mb-5 mt-3">
                <v-btn
                  outlined
                  height="30"
                  width="220"
                  color="primary"
                  style="margin-top: -20px"
                  class="d-flex justify-space-between text-none caption font-weight-medium"
                  @click="addRacik()"
                  ><v-icon small color="primary" class="mr-2">mdi-plus</v-icon
                  >Tambah ke Bahan Obat Racik</v-btn
                >
              </v-row>
              <div class="mix-header">
                <label>R/</label>
                <label class="text-left pl-2">Nama Obat</label>
                <label>Jenis Sediaan</label>
                <label>Dosis</label>
                <label>Action</label>
              </div>
              <div class="items-container">
                <template v-if="table.items.length > 0">
                  <div
                    v-for="(item, index) in table.items"
                    :key="`row-${index}`"
                    class="mix-item"
                  >
                    <span>{{ index + 1 }}</span>
                    <span class="text-left pl-2">{{ item.drugName }}</span>
                    <span>{{ item.preparation }}</span>
                    <span>{{
                      `${item.dosage_needed} ${
                        item.strength_unit === '-' ? '' : item.strength_unit
                      }`
                    }}</span>
                    <v-btn icon @click="deleteItem(item)">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template v-else>
                  <div class="text-center item-no-content">
                    <span>Belum ada obat yang ditambahkan</span>
                  </div>
                </template>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="col-layout py-5 px-10">
                <label class="body-2 font-weight-bold text-left"
                  >Nama Racikan</label
                >
                <v-text-field
                  placeholder="Tulis nama racikan"
                  dense
                  hide-details="false"
                  v-model="input.racik.recipeName"
                ></v-text-field>

                <v-spacer></v-spacer>
                <v-checkbox
                  v-model="input.racik.isExtra"
                  class="padding mt-n7"
                  dense
                  label="Obat Extra"
                ></v-checkbox>

                <label class="body-2 mt-n10 font-weight-bold text-left "
                  >Jumlah</label
                >
                <div class="d-flex mt-n10">
                  <v-text-field
                    placeholder="Masukkan jumlah"
                    dense
                    hide-details="false"
                    v-model.number="input.racik.amount"
                  ></v-text-field>
                  <v-combobox
                    class="form-body__data mt-0 ml-3"
                    clearable
                    auto-select-first
                    dense
                    item-text="name"
                    v-model="input.racik.packaging"
                    :items="resource.packaging.packageRacik"
                    @change="
                      input.racik.usage.dropdownInput.package =
                        input.racik.packaging.value
                    "
                    append-icon="mdi-chevron-down"
                  ></v-combobox>
                </div>

                <label class="body-2 mt-n10 font-weight-bold text-left"
                  >Aturan Pakai</label
                >
                <!-- <v-text-field
                  placeholder="Masukkan aturan pakai"
                  dense
                  hide-details="false"
                  v-model="input.racik.usageRule"
                ></v-text-field> -->
                <div class="usage-rules mt-n10">
                  <v-combobox
                    class="form-body__data pt-0 mt-0 mb-5 pt-2"
                    append-icon=""
                    auto-select-first
                    dense
                    v-model="input.racik.usage.dropdownInput.day"
                    :items="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
                  ></v-combobox>
                  <span>x</span>
                  <v-combobox
                    :disabled="input.radio_usage !== 0"
                    append-icon=""
                    class="form-body__data pt-0 mt-0 mb-5 pt-2"
                    dense
                    auto-select-first
                    :items="[
                      '1/4',
                      '1/3',
                      '1/2',
                      '2/3',
                      '3/4',
                      '1',
                      '2',
                      '3',
                      '4',
                      '5',
                    ]"
                    v-model="input.racik.usage.dropdownInput.usage"
                  ></v-combobox>
                  <v-combobox
                    :disabled="input.radio_usage !== 0"
                    class="form-body__data pt-0 mt-0 mb-5 pt-2"
                    dense
                    auto-select-first
                    append-icon="mdi-chevron-down"
                    :items="resource.packaging.nonracik"
                    v-model="input.racik.usage.dropdownInput.package"
                  >
                  </v-combobox>
                </div>

                <label class="body-2  mt-n10 font-weight-bold text-left"
                  >Rute</label
                >
                <v-select
                  dense
                  class=" mt-n10"
                  placeholder="Pilih Rute"
                  append-icon="mdi-chevron-down"
                  :items="resource.route"
                  v-model="input.racik.route"
                  hide-details="false"
                ></v-select>

                <label class="body-2 mt-n10 font-weight-bold text-left"
                  >Aturan Umum</label
                >
                <v-select
                  dense
                  class=" mt-n10"
                  placeholder="Aturan Umum"
                  :items="resource.generalRules"
                  append-icon="mdi-chevron-down"
                  v-model="input.racik.generalRules"
                  hide-details="false"
                ></v-select>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="col-layout py-5 px-10">
                <label class="body-2 font-weight-bold text-left"
                  >Instruksi Khusus</label
                >
                <v-text-field
                  placeholder="Instruksi khusus"
                  dense
                  hide-details="false"
                  v-model.number="input.racik.special_instruction"
                ></v-text-field>
                <label class="body-2 font-weight-bold text-left"
                  >Keterangan</label
                >
                <v-textarea
                  placeholder="Masukkan keterangan"
                  dense
                  no-resize
                  outlined
                  hide-details="false"
                  v-model="input.racik.note"
                ></v-textarea>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <v-card-actions class="pb-10 fixed-footer">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        tile
        outlined
        depressed
        width="150"
        v-if="tabs !== 1"
        class="text-none btn-rounded"
        @click="$emit('close-dialog')"
        >Kembali</v-btn
      >
      <v-btn
        color="primary"
        tile
        depressed
        width="150"
        class="text-none btn-rounded"
        @click="addData()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
// import moment from 'moment-timezone';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'DrugInput',
  mixins: [alertMixin],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    allergy: {
      type: Array,
    },
  },
  data() {
    return {
      tabs: null,
      resource: {
        generalRules: [
          '15 menit sebelum makan',
          '½ jam sebelum makan',
          '1 jam sebelum makan',
          'Sebelum makan',
          '1 jam setelah makan',
          '½ jam sebelum makan',
          '2 jam  sesudah makan',
          'Sebelum suapan pertama',
          'Bersama atau tanpa makan',
          'Bersama makan',
          'Kunyah ',
          'Kunyah bersama makan',
          'Sebelum atau sesudah makan',
          'Sesudah makan',
          'Setelah suapan pertama',
          'Taruh diatas lidah',
          'Taruh dibawah lidah ',
        ],
        route: [
          'Per Oral',
          'Sublingual',
          'Per Vagina',
          'Per Rectal',
          'Intravena',
          'Intramuscular',
          'Subcutan',
          'Okular (mata)',
          'Otic (telinga)',
          'Nasal (hidung)',
          'Inhalasi',
          'Transdermal',
          'Intratekal',
          'Topikal',
        ],
        packaging: {
          packageRacik: [
            { name: 'Sirup', value: 'Sendok Teh' },
            { name: 'Bungkus', value: 'Bungkus' },
            { name: 'Kapsul', value: 'Kapsul' },
            { name: 'Salep', value: 'Oleskan' },
          ],
          nonracik: [
            'Bungkus',
            'Hisap',
            'Kapsul',
            'Kumur',
            'ml',
            'mg',
            'Oleskan',
            'Semprot',
            'Sendok Makan',
            'Sendok Obat',
            'Sendok Sup',
            'Sendok Teh',
            'Tablet',
            'Tetes',
            'Ampul',
            'gr',
            'Ovula',
            'Patch',
            'Rectal Tube',
            'pcs',
            'TPM',
            'Unit',
            'Flexpen',
            'Vial',
          ],
        },
        drugs: [],
      },
      multiple: [
        {
          text: '1/8',
          value: 8,
        },
        {
          text: '1/5',
          value: 5,
        },
        {
          text: '1/4',
          value: 4,
        },
        {
          text: '1/3',
          value: 3,
        },
        {
          text: '1/2',
          value: 2,
        },
        {
          text: '2/3',
          value: 0.6,
        },
        {
          text: '3/4',
          value: 0.75,
        },
        {
          text: '1',
          value: 1,
        },
      ],
      selectedMultiple: '',
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Nama Obat', value: 'drugName' },
          { text: 'Jenis Sediaan', value: 'preparation' },
          { text: 'Dosis', value: 'dosage_needed' },
          { text: '', value: 'action' },
        ],
        items: [],
      },
      input: {
        radio_usage: 0,
        nonracik: {
          drugName: '',
          amount: null,
          usageRule: '',
          isExtra: false,
          usage: {
            dropdownInput: {
              usage: '',
              day: '',
              package: '',
            },
            manualInput: {
              usage: '',
              day: '',
              package: '',
            },
          },
          route: '',
          note: '',
          status: '',
          dosage: '',
          generalRules: '',
          special_instruction: '',
        },
        racik: {
          drugName: '',
          amount: null,
          isExtra: false,
          recipeName: '',
          usage: {
            dropdownInput: {
              usage: '',
              day: '',
              package: '',
            },
          },
          route: '',
          status: '',
          packaging: {
            name: '',
            value: '',
          },
          generalRules: '',
          note: '',
          special_instruction: '',
        },
      },
      loading: false,
      searchDrug: '',
      searchDrugRacik: '',
    };
  },
  mounted() {
    this.getDrugs('');
  },
  computed: {
    poly() {
      let result;
      const routeArray = this.$route.path.split('/');
      switch (routeArray[routeArray.length - 1]) {
        case 'klinik-igd':
          result = 'igd';
          break;
        case 'rawat-inap':
          result = 'rawat_inap';
          break;
      }
      return result;
    },
  },
  watch: {
    searchDrug(val) {
      if (val) {
        // this.getDrugs(val);
        this.searchDataDrug(this);
      }
    },
    searchDrugRacik(val) {
      if (val) {
        // this.getDrugs(val);
        this.searchDataDrugRacik(this);
      }
    },
    selectedMultiple(value) {
      if (value && this.mixDosageValue !== '') {
        if (value === 0.75 || value === 0.6) {
          this.input.racik.dosage = this.mixDosageValue * value;
        } else {
          this.input.racik.dosage = this.mixDosageValue / value;
        }
      }
      if (value === undefined) {
        this.input.racik.dosage = this.mixDosageValue;
      }
    },
  },
  methods: {
    searchDataDrug: _.debounce(v => v.getDrugs(v.searchDrug), 650),
    searchDataDrugRacik: _.debounce(v => v.getDrugs(v.searchDrugRacik), 650),
    getDrugs(keyword) {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all/names?page=1&itemCount=30&category=Obat&name=${
              !keyword ? '' : keyword
            }`,
          ),
        )
        .then(response => {
          let data = response.data.data;
          this.resource.drugs = data.map(arr => {
            return {
              id: arr.drugs._id,
              id_stock: arr._id,
              name: arr.drugs.detail.name,
              text: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
              displayName: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit}`,
              strength_unit: arr.drugs.detail.strength_unit,
              strength: arr.drugs.detail.strength,
              preparation: arr.drugs.detail.preparation,
              packaging: arr.package,
              price: arr.price,
              expiry: arr.expiry_date,
              stock: arr.quantity,
            };
          });
        })
        .catch(() => {
          this.resource.drugs = [];
        })
        .finally(() => (this.loading = false));
    },
    addRacik() {
      this.table.items.push({
        id: this.input.racik.drugName.id,
        drugName: this.input.racik.drugName.name,
        preparation: this.input.racik.drugName.preparation,
        dosage: this.input.racik.drugName.strength,
        dosage_needed: this.input.racik.dosage,
        packaging: this.input.racik.drugName.packaging,
        strength_unit: this.input.racik.drugName.strength_unit,
        strength: this.input.racik.drugName.strength,
        price: this.input.racik.drugName.price,
      });

      this.selectedMultiple = '';
      this.mixDosageValue = '';
      this.input.racik.drugName = '';
      this.input.racik.dosage = '';
      this.getDrugs('');
    },
    deleteItem(item) {
      const index = this.table.items.indexOf(item);

      this.table.items.splice(index, 1);
    },
    addData() {
      let payload;
      if (this.tabs === 0) {
        payload = {
          id_registration: this.patientData.id_registration,
          id_emr: this.patientData.id_emr,
          isTakeHome: false,
          patient_status: this.poly === 'igd' ? 'igd' : 'rawat inap',
          recipe: [
            {
              id_drugs: this.input.nonracik.drugName.id,
              name: this.input.nonracik.drugName.name,
              is_extra_drugs: this.input.nonracik.isExtra,
              preparation: this.input.nonracik.drugName.preparation,
              dosage: this.input.nonracik.dosage,
              unit_dosage: this.input.nonracik.drugName.strength_unit,
              quantity: this.input.nonracik.amount,
              usage:
                this.input.radio_usage === 0
                  ? `${this.input.nonracik.usage.dropdownInput.day} x ${this.input.nonracik.usage.dropdownInput.usage} ${this.input.nonracik.usage.dropdownInput.package}`
                  : `${this.input.nonracik.usage.manualInput.usage} ${this.input.nonracik.usage.manualInput.package}`,
              route: this.input.nonracik.route,
              general_rule: this.input.nonracik.generalRules,
              special_instruction: this.input.nonracik.special_instruction,
              isOrdered: false,
              requested_quantity: this.input.nonracik.amount,
              packaging:
                this.input.radio_usage === 0
                  ? this.input.nonracik.usage.manualInput.package
                  : this.input.nonracik.usage.dropdownInput.package,
              description: this.input.nonracik.note,
              price: this.input.nonracik.drugName.price,
              staff: this.$store.getters.userLoggedIn.id,

              staff_approve: this.$store.getters.userLoggedIn.name,
            },
          ],
          medical_tools: [],
          mix_recipe: [],
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        };
      } else {
        payload = {
          id_registration: this.patientData.id_registration,
          id_emr: this.patientData.id_emr,
          isTakeHome: false,
          patient_status: this.poly === 'igd' ? 'igd' : 'rawat inap',
          recipe: [],
          medical_tools: [],
          mix_recipe: [
            {
              name: this.input.racik.recipeName,
              is_extra_drugs: this.input.racik.isExtra,
              requested_quantity: this.input.racik.amount,
              quantity: this.input.racik.amount,
              packaging: this.input.racik.packaging.name,
              usage: `${this.input.racik.usage.dropdownInput.day} x ${this.input.racik.usage.dropdownInput.usage} ${this.input.racik.usage.dropdownInput.package}`,
              route: this.input.racik.route,
              general_rule: this.input.racik.generalRules,
              special_instruction: this.input.racik.special_instruction,
              description: this.input.racik.note,
              isOrdered: false,
              drugs: this.table.items.map(arr => {
                return {
                  dosage: arr.dosage.toString(),
                  id_drugs: arr.id,
                  dosage_needed: arr.dosage_needed,
                  _id: arr.id,
                  name: arr.drugName,
                  preparation: arr.preparation,
                  price: arr.price,
                  unit_dosage: arr.strength_unit,
                  quantity: 1,
                };
              }),
              price: 0,
              staff: this.$store.getters.userLoggedIn.id,
              staff_approve: this.$store.getters.userLoggedIn.name,
            },
          ],
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        };
      }

      if (this.tabs === 1 && (this.table.items.length < 1 || 0)) {
        Swal.fire(
          'Oops...',
          'Obat racik harus tidak boleh kosong atau harus lebih dari 1',
          'error',
        );
        return;
      } else if (this.tabs === 0 && !this.input.nonracik.drugName.name) {
        Swal.fire('Oops...', 'Data obat non racik belum lengkap', 'error');
        return;
      } else {
        axios
          .post(
            Constant.apiUrl.concat(
              `/inpatient/drugadministration/${this.patientData.id_registration}?type=${this.poly}`,
            ),
            payload,
          )
          .then(() => {
            Swal.fire('Data obat berhasil ditambahkan', '', 'success');
            this.$emit('close-dialog');
          })
          .catch(err => {
            this.showErrorAxios(err);
          });
      }
    },
    onClear() {
      this.getDrugs('');
      this.input.nonracik.dosage = '';
      this.input.racik.dosage = '';
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-header {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.fixed-footer {
  position: sticky;
  bottom: 0;
  z-index: 1000;
  background: #fff;
}

.form-body {
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }
  &__data {
    height: 20px;
  }
}
.usage-rules {
  display: grid;
  grid-template-columns: 0.6fr 0.3fr 0.6fr 3fr;
  column-gap: 10px;
  span {
    padding-top: 9px;
  }
}
.usage-rules2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 10px;
}
.items-container {
  max-height: 70px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 2px !important;
    border-radius: 2px;
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    width: 2px !important;
    border-radius: 2px;
    background-color: rgb(163, 163, 163) !important;
    // background-color: black !important;
  }
}
.mix-header {
  display: grid;
  grid-template-columns: 10% 40% 20% 15% 15%;
  font-size: 0.8rem;
  border-bottom: 1px solid #86bcdb;
  border-top: 1px solid #86bcdb;
  font-weight: 500;
  width: 100%;
  align-content: center;
  background-color: #deeef9;
  height: 2.2rem;
}
.mix-item {
  display: grid;
  grid-template-columns: 10% 40% 20% 15% 15%;
  font-size: 0.8rem;
  width: 100%;
  align-content: center;
  align-items: center;
  background-color: #deeef9;
  height: 2.5rem;
}
.item-no-content {
  height: 2.5rem;
  color: #8c8e8f;
  background-color: #deeef9;
  font-size: 0.8rem;
  padding-top: 10px;
  align-items: center;
  align-content: center;
}
.col-layout {
  display: grid;
  grid-template-columns: 17vh 32vh;
  grid-template-rows: repeat(5, 8vh);
  &.usage {
    display: grid;
    // grid-template-columns: 19vh 34vh;
    grid-template-rows: 8vh;
  }
  &.table-col {
    grid-template-rows: repeat(3, 8vh);
    display: grid;
    // grid-template-columns: 19vh 34vh;
  }
}

@media screen and (min-width: 1904px) {
  .col-layout {
    grid-template-columns: 14vh 25vh;
  }
}
.header-grid {
  padding-left: 12px;
  display: grid;
  font-size: 0.9rem;
  grid-template-columns: repeat(2, 1fr 2fr);
  row-gap: 5px;
}
.allergy {
  background-color: #fff2f2 !important;
  font-weight: 400;
  font-size: 13px;
  justify-content: center;
  border: 0.5px solid #eb4747 !important;
  color: #eb4747;
}

.btn-rounded {
  border-radius: 4px;
}
</style>
