import Constant from '@/const';
import axios from 'axios';

const putUpdatePcareData = async payload => {
  try {
    await axios.put(
      Constant.apiUrl.concat('/configuration/pcare/password'),
      payload,
    );
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default putUpdatePcareData;
