<template>
  <section>
    <div class="d-flex justify-end" style="height: 2.3vh;">
      <span v-if="loading" class="success--text loading-text"
        >Menyimpan<span>.</span><span>.</span><span>.</span>
      </span>
      <span
        v-if="alertMessage.show"
        :class="alertMessage.isFail ? 'error--text' : 'success--text'"
      >
        Form {{ alertMessage.text.toLowerCase() }}
        {{ alertMessage.isFail ? 'gagal' : 'berhasil' }} disimpan
      </span>
    </div>
    <skeleton :isLoading="isLoading" class="mx-8">
      <v-tabs grow v-model="tabs" style="border-bottom: 2px solid #f5f5f5;">
        <v-tab
          v-for="(tab, index) in tabsResource"
          :key="`tab-${index}`"
          class="text-none"
          >{{ tab.text }}
          <template v-if="isEMR ? tab.icon === 'mdi-check-circle' : true">
            <v-icon
              v-if="tab.icon"
              :color="generateBadgeColor(tab.icon, index)"
              class="mb-4"
              small
              >{{ tab.icon }}
            </v-icon>
          </template>
        </v-tab>
      </v-tabs>
    </skeleton>
    <div class="content">
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <mother-and-baby-info
            :isEMR="isEMR"
            :isLoading="isLoading"
            ref="information"
        /></v-tab-item>
        <v-tab-item>
          <physical-neonatus
            :isLoading="isLoading"
            :isEMR="isEMR"
            :gender="baby.meta.gender"
        /></v-tab-item>
        <v-tab-item>
          <apgar-score :isLoading="isLoading" :isEMR="isEMR"
        /></v-tab-item>
        <v-tab-item>
          <mother-and-baby-sign
            :isLoading="isLoading"
            :isEMR="isEMR"
            ref="stamp"
        /></v-tab-item>
      </v-tabs-items>
    </div>
    <v-footer
      style="background-color: white; border-top: 1px solid #e0e0e0"
      class="d-flex pt-2 justify-space-between"
    >
      <div class="d-flex">
        <v-btn
          @click="navigate(tabs - 1)"
          :disabled="tabs === 0"
          color="primary"
          class="mr-3 nav-btn"
          depressed
          light
          outlined
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          class="nav-btn"
          :disabled="tabs === 3"
          @click="navigate(tabs + 1)"
          color="primary"
          depressed
          light
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <div class="d-flex">
        <v-btn
          v-if="tabs === 3 && isAllFormSaved"
          @click="print"
          depressed
          light
          outlined
          :loading="getLoading.get"
          color="primary"
          class="text-capitalize mr-3"
          style="letter-spacing: 0.1px;"
          >Print Kartu Anak
        </v-btn>
        <v-btn
          v-if="tabs === 3 && !isEMR"
          :loading="getLoading.get"
          @click="save"
          depressed
          light
          color="primary"
          class="text-capitalize"
          style="letter-spacing: 0.1px;"
          >{{ getForm.stamp.isSaved ? 'Simpan Perubahan' : 'Simpan' }}
        </v-btn>
      </div>
    </v-footer>
    <v-snackbar
      timeout="4000"
      content-class="d-flex justify-space-between white--text align-center"
      elevation="0"
      small
      light
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn fab small text @click.native="() => (snackbar.show = false)"
        ><v-icon color="white" small>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
  </section>
</template>

<script>
// components
import Skeleton from '../../../../../SharedComponent/Skeleton';
import MotherAndBabyInfo from './MotherAndBabyInfo';
import PhysicalNeonatus from './PhysicalNeonatus';
import ApgarScore from './ApgarScore.vue';
import MotherAndBabySign from './MotherAndBabySign';

// mixins
import PrintMixin from '@/mixin/printMixin';
import printKidCard from '@/mixin/InpatientMixin/printKidCard';

// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'NeonatusAssessment',
);

const moment = require('moment-timezone');

export default {
  name: 'NeonatusAssessment',
  props: {
    baby: {
      type: Object,
      default: null,
    },
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [PrintMixin, printKidCard],
  data() {
    return {
      isLoading: false,
      snackbar: {
        show: false,
        color: '',
        text: '',
      },
    };
  },
  components: {
    Skeleton,
    MotherAndBabyInfo,
    PhysicalNeonatus,
    ApgarScore,
    MotherAndBabySign,
  },
  watch: {
    async tabs(destination, origin) {
      this.formValidation({ destination, origin });
      switch (origin) {
        case 0:
          if (this.getIsInitialInformationModified) {
            this.resolvePostNeonatusAssessment(origin);
          }
          break;
        case 1:
          if (this.getIsInitialPhysicalModified) {
            this.resolvePostNeonatusAssessment(origin);
          }
          break;
        case 2:
          if (this.getIsInitialApgarModified) {
            await this.resolvePostNeonatusAssessment(origin);
            // get data directly for print
            this.resolveGetNeonatusAssessment({
              tab: origin,
              validate: this.validateForm,
            });
          }
          break;
        case 3:
          if (this.getIsInitialStampModified) {
            this.resolvePostNeonatusAssessment(origin);
          }
          break;
      }
      this.resolveGetNeonatusAssessment({
        tab: destination,
        validate: this.validateForm,
      });
    },
  },
  computed: {
    ...mapGetters([
      'getForm',
      'getTabsResource',
      'getShowSuccessMessage',
      'getLoading',
      'getInitialState',
      'getTab',
      'getIsInitialInformationModified',
      'getIsInitialPhysicalModified',
      'getIsInitialApgarModified',
      'getIsInitialStampModified',
    ]),
    isNurseOrMidwife: {
      get() {
        return this.$store.getters.userLoggedIn.role.some(
          item =>
            item.toLowerCase() === 'perawat' || item.toLowerCase() === 'bidan',
        );
      },
    },
    isAllFormSaved: {
      get() {
        return (
          this.getForm.information.isSaved &&
          this.getForm.physical.isSaved &&
          this.getForm.apgar_score.isSaved &&
          this.getForm.stamp.isSaved
        );
      },
    },
    informationForm: {
      get() {
        return this.getForm.information;
      },
    },
    initialInformationState: {
      get() {
        return this.getInitialState.information;
      },
    },
    tabs: {
      get() {
        return this.getTab;
      },
      set(value) {
        this.setTab(value);
      },
    },
    tabsResource: {
      get() {
        return this.getTabsResource;
      },
    },
    alertMessage: {
      get() {
        return this.getShowSuccessMessage;
      },
    },
    loading: {
      get() {
        return this.getLoading.save;
      },
    },
  },
  methods: {
    ...mapActions([
      'resolvePostNeonatusAssessment',
      'resolveGetNeonatusAssessment',
      'formValidation',
    ]),
    ...mapMutations([
      'setTab',
      'clearNeonatusState',
      'setForm',
      'setInitialState',
    ]),
    async getAllData() {
      try {
        this.isLoading = true;
        for (let i = 0; i < 4; i++) {
          if (!i) {
            await this.resolveGetNeonatusAssessment({
              tab: i,
              validate: this.validateForm,
            });
            /** if information data isn't saved yet, then fill the needed inputs using autoFill func */
            if (!this.getForm.information.isSaved && !this.isEMR) {
              this.autofill();
            }
          }
          await this.resolveGetNeonatusAssessment({
            tab: i,
            validate: this.validateForm,
          });
        }
        /** only autofill when one or more of the tabs is filled (EMR view) */
        if (
          this.isEMR &&
          !this.getForm.information.isSaved &&
          (this.getForm.physical.isSaved ||
            this.getForm.apgar_score.isSaved ||
            this.getForm.stamp.isSaved)
        ) {
          this.autofill();
        }
      } catch {
        this.snackbar = {
          show: true,
          text:
            'Terjadi Kesalahan, gagal mendapatkan data assesmen awal rawat inap',
          color: 'error',
        };
      } finally {
        this.isLoading = false;
      }
    },
    navigate(tab) {
      this.tabs = tab;
    },
    async save() {
      try {
        await this.resolvePostNeonatusAssessment(3);
        this.snackbar = {
          show: true,
          text: `Formulir Assesmen Neonatus Berhasil ${
            this.getForm.stamp.isSaved ? 'Diubah' : 'Disimpan'
          }`,
          color: 'success',
        };
      } catch {
        this.snackbar = {
          show: true,
          text: `Terjadi Kesalahan, Formulir Assesmen Neonatus Gagal ${
            this.getForm.stamp.isSaved ? 'Diubah' : 'Disimpan'
          }`,
          color: 'error',
        };
      } finally {
        this.resolveGetNeonatusAssessment({
          tab: 3,
          validate: this.validateForm,
        });
      }
    },
    generateBadgeColor(icon, index) {
      let color;

      if (icon === 'mdi-alert-circle') {
        color = 'error';
      }
      if (icon === 'mdi-check-circle' && this.tabs === index) {
        color = 'success';
      }
      if (icon === 'mdi-check-circle' && this.tabs !== index) {
        color = '#9ca2a5';
      }

      return color || '';
    },
    print() {
      const printData = {
        rm_number: this.baby.rmNumber,
        babyName: this.baby.meta.name,
        birthdate: this.baby.birthDateAndAge,
        gender: this.baby.meta.gender,
        birthType: this.getForm.information.baby.birthType,
        mother: {
          name: this.getForm.information.mother.name,
          profession: this.getForm.information.mother.profession,
        },
        father: {
          name: this.getForm.information.father.name,
          profession: this.getForm.information.father.profession,
        },
        apgar: {
          minute1: this.getForm?.apgar_score?.total?.minute1?.text
            ? `${this.getForm.apgar_score.total.minute1.score} - ${this.getForm.apgar_score.total.minute1.text}`
            : null,
          minute5: this.getForm?.apgar_score?.total?.minute5?.text
            ? `${this.getForm.apgar_score.total.minute5.score} - ${this.getForm.apgar_score.total.minute5.text}`
            : null,
          minute10: this.getForm?.apgar_score?.total?.minute10?.text
            ? `${this.getForm.apgar_score.total.minute10.score} - ${this.getForm.apgar_score.total.minute10.text}`
            : null,
        },
        weight: this.getForm.information.baby.weight,
        headCircumference: this.getForm.information.baby.headCircumference,
        length: this.getForm.information.baby.length,
        chestCircumference: this.getForm.information.baby.chestCircumference,
        postscript: this.getForm.physical.notes.postscript,
        built_in_note: this.getForm.physical.notes.built_in_note,
        abnormalities: this.getForm.physical.abnormalities,
        images: {
          baby_right: {
            img: this.getForm.stamp.baby.right_leg.url,
            height: this.$refs['stamp']?.$refs['right_leg']?.$refs['right_leg']
              ?.height,
            width: this.$refs['stamp']?.$refs['right_leg']?.$refs['right_leg']
              ?.width,
          },
          baby_left: {
            img: this.getForm.stamp.baby.left_leg.url,
            height: this.$refs['stamp']?.$refs['left_leg']?.$refs['left_leg']
              ?.height,
            width: this.$refs['stamp']?.$refs['left_leg']?.$refs['left_leg']
              ?.width,
          },
          mother_left: {
            img: this.getForm.stamp.mother.left_thumb.url,
            height: this.$refs['stamp']?.$refs['left_thumb']?.$refs[
              'left_thumb'
            ]?.height,
            width: this.$refs['stamp']?.$refs['left_thumb']?.$refs['left_thumb']
              ?.width,
          },
          mother_right: {
            img: this.getForm.stamp.mother.right_thumb.url,
            height: this.$refs['stamp']?.$refs['right_thumb']?.$refs[
              'right_thumb'
            ]?.height,
            width: this.$refs['stamp']?.$refs['right_thumb']?.$refs[
              'right_thumb'
            ]?.width,
          },
        },
      };

      const doc = this.printKidCardDocument(printData);

      this.printDocument(doc, {
        docName: 'Kartu Anak',
        rmNumber: this.baby.rmNumber,
      });
    },
    validateForm() {
      this.$refs['information']?.$refs?.form?.validate();
    },
    autofill() {
      const staff = {
        id: this.$store.getters.userLoggedIn.id,
        name: this.$store.getters.userLoggedIn.name,
      };
      this.setForm({
        key: 'information',
        payload: {
          ...this.informationForm,
          staff: this.isNurseOrMidwife ? staff : '',
          dateFill: {
            raw: moment().format('YYYY-MM-DD'),
            formatted: moment().format('DD-MM-YYYY'),
          },
          dateVisit: {
            raw: moment().format('YYYY-MM-DD'),
            formatted: moment().format('DD-MM-YYYY'),
          },
          time: {
            visit: moment().format('HH:mm'),
            fill: moment().format('HH:mm'),
          },
        },
      });
      this.setInitialState({
        key: 'information',
        payload: {
          ...this.initialInformationState,
          staff: this.isNurseOrMidwife ? staff : '',
          dateFill: {
            raw: moment().format('YYYY-MM-DD'),
            formatted: moment().format('DD-MM-YYYY'),
          },
          dateVisit: {
            raw: moment().format('YYYY-MM-DD'),
            formatted: moment().format('DD-MM-YYYY'),
          },
          time: {
            visit: moment().format('HH:mm'),
            fill: moment().format('HH:mm'),
          },
        },
      });
    },
  },
  // component life cycle
  mounted() {
    this.getAllData();
  },
  beforeDestroy() {
    this.clearNeonatusState();
  },
};
</script>

<style scoped lang="scss">
.text-none {
  letter-spacing: 0.1px;
  font-size: 0.8vw;
}

.content {
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px !important;
    border-radius: 5px;
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px !important;
    border-radius: 5px;
    background-color: rgb(163, 163, 163) !important;
  }
}

.nav-btn {
  margin-top: 0.5vw;
  height: 3.5vh !important;
  padding: 0px;
}

::v-deep .v-snack__content {
  padding: 0px 0px 0px 1vw;
}
</style>
