<template>
  <div style="height:100%; position: relative;">
    <v-btn
      @click="$emit('close-dialog')"
      style="position: absolute; right: 1vw; top: 1vh;"
      text
      small
      fab
    >
      <v-icon>
        mdi-close
      </v-icon>
    </v-btn>
    <iframe :src="url" height="100%" width="100%"></iframe>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { getICare } from '@/fetchApi/ICare';

export default {
  name: 'ICareBPJS',
  data() {
    return {
      url: '',
    };
  },
  props: {
    bpjsCode: {
      type: String,
      default: '',
    },
  },
  methods: {
    async resolveGetICare() {
      try {
        const response = await getICare(this.bpjsCode);
        if (!response.data.data.response) {
          Swal.fire({
            type: 'error',
            title: response.data.data.metaData.message,
          }).then(() => {
            this.$emit('close-dialog');
          });
        }
        this.url = response.data?.data?.response?.url;
      } catch (error) {
        Swal.fire({
          type: 'error',
          title: 'Terjadi kesalahan, Tidak dapat membuka I-Care',
        }).then(() => {
          this.$emit('close-dialog');
        });
      }
    },
  },
  mounted() {
    this.resolveGetICare();
  },
};
</script>
