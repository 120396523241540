const moment = require('moment-timezone');

const mapMedicalResumeData = (source, state) => {
  return {
    allergyHistory: source.allergy_history?.length
      ? source.allergy_history
      : [''],
    isEditable: Boolean(source.doctor),
    doctor: source.doctor || '',
    date: {
      in: {
        formatted: source.dateTime?.dateIn
          ? moment(source.dateTime?.dateIn).format('DD-MM-YYYY')
          : state.form.date.in.formatted,
        raw: source.dateTime?.dateIn
          ? moment(source.dateTime?.dateIn).format('YYYY-MM-DD')
          : state.form.date.in.raw,
      },
      out: {
        formatted: source.dateTime?.dateout
          ? moment(source.dateTime?.dateout).format('DD-MM-YYYY')
          : '',
        raw: source.dateTime?.dateout
          ? moment(source.dateTime?.dateout).format('YYYY-MM-DD')
          : '',
      },
    },
    time: {
      in: source.dateTime?.dateIn
        ? moment(source.dateTime?.dateIn).format('HH:mm')
        : state.form.time.in,
      out: source.dateTime?.dateout
        ? moment(source.dateTime?.dateout).format('HH:mm')
        : '',
    },
    mainDiagnose: {
      name: source.main_diagnose?.name || '',
      id_diagnose: source.main_diagnose?.id_diagnose
        ? {
            id_diagnose: source.main_diagnose?.id_diagnose?._id || '',
            name: `${source.main_diagnose.id_diagnose.code} ${source.main_diagnose.id_diagnose.description}`,
          }
        : '',
      search: null,
    },
    secondaryDiagnose: source.secondary_diagnose?.map(item => {
      return {
        name: item.name,
        loading: false,
        id_diagnose: {
          id_diagnose: item.id_diagnose?._id || '',
          name: item.id_diagnose
            ? `${item.id_diagnose.code} ${item.id_diagnose.description}`
            : '',
        },
      };
    }) || [
      {
        name: '',
        id_diagnose: '',
        loading: false,
      },
    ],
    procedure: source.procedure?.map(item => {
      return {
        name: item.name,
        loading: false,
        id_procedure: {
          id_procedure: item.id_procedure?._id || '',
          name: item.id_procedure
            ? `${item.id_procedure.code} ${item.id_procedure.description}`
            : '',
        },
      };
    }) || [
      {
        name: '',
        id_procedure: '',
        loading: false,
      },
    ],
    room: source.room || '',
    reasonIn: source.reasonIn || '',
    diagnoseIn: source.diagnoseIn || '',
    diseaseHistory: source.disease_history || '',
    physicalCheckup: source.physical_checkup || '',
    labResult: source.lab_result || '',
    supportCheckup: source.support_checkup || '',
    therapy: source.therapy || [''],
    consultationResult: source.consultation_result || '',
    takehomeDrug: source.takehome_drug || [''],
    diet: source.diet || '',
    instruction: source.instruction || '',
    finishedType: {
      name: source.finished_type?.name || '',
      description: source.finished_type?.description || '',
    },
    treatmentContinued: {
      name: source.treatment_continued?.name || '',
      description: source.treatment_continued?.description || '',
    },
  };
};

module.exports = { mapMedicalResumeData };
