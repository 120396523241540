<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title cols="10" class="d-flex text-left my-auto pt-2 pl-2">
        <h2 class="mr-3">Reschedule</h2>
        <v-tooltip nudge-right="380" color="#EBF3F9" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="primary"
              style="cursor: pointer"
              >mdi-information</v-icon
            >
          </template>
          <span class="black--text font-weight-medium"
            >Perubahan ini hanya berlaku untuk 1 kali waktu. Untuk perubahan
            yang sifatnya tetap, silakan ke halaman Pengaturan</span
          >
        </v-tooltip>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <router-link to="/pendaftaran/jadwal-dokter">
        <v-btn icon class="mr-5">
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </router-link>
      <router-link to="/pendaftaran/jadwal-dokter/pengaturan">
        <v-btn outlined color="primary" depressed class="setting-btn" tile>
          <!-- to="jadwal-dokter/pengaturan" -->
          <v-icon class="pa-0" color="primary">mdi-cog</v-icon>
        </v-btn>
      </router-link>
    </v-toolbar>
    <v-divider></v-divider>
    <v-row class="pa-3">
      <v-col cols="6" class="pa-5">
        <v-tabs v-model="tab" center-active grow>
          <v-tab class="text-capitalize font-weight-bold"> Reschedule </v-tab>
          <v-tab class="text-capitalize font-weight-bold">
            Libur Praktik
          </v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <div class="display-grid">
                <label class="body-2 font-weight-bold text-start"
                  >Nama Dokter</label
                >
                <v-combobox
                  :items="doctors"
                  :loading="showDoctorLoading"
                  placeholder="Pilih Dokter"
                  append-icon="mdi-chevron-down"
                  dense
                  item-value="doctorId"
                  item-text="doctorName"
                  clearable
                  @change="getUnitDoctor()"
                  v-model="changeSchedule.schedule.doctor"
                ></v-combobox>
                <label class="body-2 font-weight-bold text-start"
                  >Nama Poli</label
                >
                <v-combobox
                  placeholder="Pilih Poli"
                  dense
                  :items="rescheduleResource.poly"
                  append-icon="mdi-chevron-down"
                  item-text="name"
                  v-model="changeSchedule.schedule.polyclinic"
                  @change="showScheduleDoctor()"
                ></v-combobox>
                <label class="body-2 font-weight-bold text-start">
                  Pilih Jadwal Praktik
                </label>
                <v-combobox
                  placeholder="Pilih Jadwal Praktik"
                  dense
                  append-icon="mdi-chevron-down"
                  item-text="displayText"
                  item-value="displayText"
                  :items="rescheduleResource.schedule"
                  v-model="changeSchedule.schedule.date"
                ></v-combobox>
                <label class="body-2 font-weight-bold text-start">
                  Jadwal Praktik Pengganti
                </label>
                <Datepicker
                  :outlined="false"
                  :show="changeDate"
                  :date="date"
                  :min="today"
                  :calendar-event="true"
                  @date-change="handleDateChange"
                />
                <label class="body-2 font-weight-bold text-start"
                  >Jam Mulai Praktik</label
                >
                <v-menu
                  ref="dateStart"
                  v-model="dateStart"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="changeSchedule.reschedule.startTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="changeSchedule.reschedule.startTime"
                      placeholder="Jam Mulai"
                      append-icon="mdi-chevron-down"
                      v-bind="attrs"
                      v-on="on"
                      class="my-0 ml-0 mb-3 pa-0"
                      dense
                      hide-details="false"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="dateStart"
                    v-model="changeSchedule.reschedule.startTime"
                    :max="changeSchedule.reschedule.endTime"
                    full-width
                    format="24hr"
                    @click:minute="
                      $refs.dateStart.save(changeSchedule.reschedule.startTime)
                    "
                  ></v-time-picker>
                </v-menu>
                <label class="body-2 font-weight-bold text-start"
                  >Jam Selesai Praktik</label
                >
                <v-menu
                  ref="dateEnd"
                  v-model="dateEnd"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="changeSchedule.reschedule.endTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="changeSchedule.reschedule.endTime"
                      placeholder="Jam Selesai"
                      append-icon="mdi-chevron-down"
                      v-bind="attrs"
                      v-on="on"
                      class="my-0 ml-0 mb-3 pa-0"
                      dense
                      hide-details="false"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    :min="changeSchedule.reschedule.startTime"
                    v-if="dateEnd"
                    v-model="changeSchedule.reschedule.endTime"
                    full-width
                    format="24hr"
                    @click:minute="
                      $refs.dateEnd.save(changeSchedule.reschedule.endTime)
                    "
                  ></v-time-picker>
                </v-menu>
                <label class="body-2 font-weight-bold text-start"
                  >Kuota Pasien</label
                >
                <v-text-field
                  v-model="changeSchedule.reschedule.quota"
                  dense
                  placeholder="Jumlah Kuota Pasien"
                  type="number"
                  min="1"
                  :rules="requiredRule"
                  oninput="if(this.value < 0) this.value = 1;"
                ></v-text-field>
                <div></div>
                <div class="text-end">
                  <v-btn
                    width="80px"
                    color="primary"
                    depressed
                    class="text-none"
                    @click="addData()"
                    :loading="isSaveLoading"
                    :disabled="!saveValid"
                    >Simpan</v-btn
                  >
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="display-grid">
                <label class="body-2 font-weight-bold text-start"
                  >Nama Dokter</label
                >
                <v-combobox
                  :items="doctors"
                  placeholder="Pilih Dokter"
                  append-icon="mdi-chevron-down"
                  dense
                  item-value="doctorId"
                  item-text="doctorName"
                  clearable
                  v-model="leaveSchedule.schedule.doctor"
                  @change="getUnitDoctor()"
                ></v-combobox>
                <label class="body-2 font-weight-bold text-start mb-4"
                  >Nama Poli</label
                >
                <v-combobox
                  placeholder="Pilih Poli"
                  dense
                  :items="leaveScheduleResource.poly"
                  append-icon="mdi-chevron-down"
                  item-text="name"
                  v-model="leaveSchedule.schedule.polyclinic"
                  @change="showScheduleDoctor()"
                ></v-combobox>
                <label class="body-2 font-weight-bold text-start">
                  Pilih Jadwal Praktik
                </label>
                <v-combobox
                  placeholder="Pilih Jadwal Praktik"
                  dense
                  append-icon="mdi-chevron-down"
                  item-text="displayText"
                  item-value="displayText"
                  :items="leaveScheduleResource.schedule"
                  v-model="leaveSchedule.schedule.date"
                ></v-combobox>
                <label class="body-2 font-weight-bold text-start">
                  Alasan izin tidak praktik
                </label>
                <v-textarea
                  outlined
                  placeholder="Alasan izin tidak praktik"
                  v-model="leaveSchedule.reason"
                  dense
                  hide-details="false"
                ></v-textarea>
                <div></div>
                <div class="text-end">
                  <v-btn
                    width="80px"
                    color="primary"
                    depressed
                    class="text-none"
                    @click="addData()"
                    :loading="isSaveLoading"
                    :disabled="!saveValid"
                    >Simpan</v-btn
                  >
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
      <v-col cols="6" class="pa-4">
        <v-row v-show="selected === 'reschedule'" align="baseline">
          <v-col cols="2" class="text-left">
            <label class="body-2 font-weight-bold">Pilih Poli</label>
          </v-col>
          <v-col cols="3">
            <v-select
              placeholder="Pilih Poli"
              dense
              append-icon="mdi-chevron-down"
              :items="poly"
              item-value="unitId"
              @change="getSpecialistDoctor"
              item-text="unitName"
              v-model="polyclinic"
              return-object
            ></v-select>
          </v-col>
          <v-col cols="2" class="text-left">
            <label class="body-2 font-weight-bold">Tanggal Praktik</label>
          </v-col>
          <v-col cols="4">
            <v-menu
              ref="practice"
              v-model="practice"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="practiceDate"
                  placeholder="MM/DD/YYYY"
                  append-icon="mdi-chevron-down"
                  v-on="on"
                  dense
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="praDate"
                scrollable
                @input="practice = false"
                @change="getSpecialistDoctor()"
                :max="nextWeek"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row justify-end v-show="selected === 'reschedule'">
          <v-col cols="11">
            <doctor-schedule-list-card
              :data="{
                loading: getSpecialistLoading,
                schedule: doctorSchedule,
                date: practiceDate,
                day,
                generateDayName,
              }"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import DoctorScheduleListCard from './Schedule/DoctorScheduleListCard.vue';
import alertMixin from '@/mixin/alertMixin';
import Datepicker from '@/components/SharedComponent/Datepicker/Datepicker.vue';

const axios = require('axios');
const moment = require('moment');
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
  name: 'ScheduleChange',
  components: { DoctorScheduleListCard, Datepicker },
  mixins: [alertMixin],
  data() {
    return {
      getSpecialistLoading: false,
      showDoctorLoading: false,
      isSaveLoading: false,
      doctors: [],
      doctor: [],
      poly: [],
      tab: 0,
      today: moment().format('YYYY-MM-DD'),
      nextWeek: moment()
        .add(6, 'days')
        .format('YYYY-MM-DD'),
      practiceDate: '',
      menu: false,
      selected: 'reschedule',
      defaultDate: false,
      date: null,
      changeDate: false,
      change: null,

      requiredRule: [v => !!v || 'Wajib diisi!'],
      input: {
        doctor: '',
        poly: '',
      },
      polyclinic: '',
      doctorSchedule: [],
      rescheduleResource: {
        poly: [],
        schedule: [],
      },
      leaveScheduleResource: {
        poly: [],
        schedule: [],
      },
      leaveSchedule: {
        type: '',
        reason: '',
        schedule: {
          doctor: '',
          polyclinic: '',
          date: '',
        },
      },
      changeSchedule: {
        type: '',
        reason: '',
        schedule: {
          doctor: '',
          polyclinic: '',
          date: '',
        },
        reschedule: {
          date: '',
          startTime: '',
          endTime: '',
          quota: '',
          room: '',
        },
      },
      rescheduleDatePicker: '',
      praDate: '',
      dateEnd: false,
      dateStart: false,
      practice: false,
    };
  },
  computed: {
    saveValid() {
      return this.tab == 0
        ? this.changeSchedule.schedule.doctor !== '' &&
            this.changeSchedule.schedule.polyclinic !== '' &&
            this.changeSchedule.schedule.date !== '' &&
            this.changeSchedule.reschedule.date !== '' &&
            this.changeSchedule.reschedule.startTime !== '' &&
            this.changeSchedule.reschedule.endTime !== '' &&
            this.changeSchedule.reschedule.quota !== ''
        : this.leaveSchedule.schedule.doctor !== '' &&
            this.leaveSchedule.schedule.polyclinic !== '' &&
            this.leaveSchedule.schedule.date !== '';
    },
    day() {
      return this.generateDayName(moment(this.practiceDate, 'DD-MM-YYY').day());
    },
  },
  methods: {
    handleDateChange(updatedDate) {
      this.date = updatedDate;
      this.changeSchedule.reschedule.date = updatedDate;
    },
    generateDayName(value) {
      switch (+value) {
        case 1:
          return 'Senin';
        case 2:
          return 'Selasa';
        case 3:
          return 'Rabu';
        case 4:
          return 'Kamis';
        case 5:
          return 'Jumat';
        case 6:
          return 'Sabtu';
        case 7:
          return 'Minggu';
      }
    },
    getUnitDoctor() {
      if (this.tab === 0) {
        this.changeSchedule.schedule.polyclinic = '';
        this.changeSchedule.schedule.date = '';
        if (!this.changeSchedule.schedule.doctor) return;
        this.rescheduleResource.poly = this.changeSchedule.schedule.doctor.unit;
      } else {
        this.leaveSchedule.schedule.polyclinic = '';
        this.leaveSchedule.schedule.date = '';
        if (!this.leaveSchedule.schedule.doctor) return;
        this.leaveScheduleResource.poly = this.leaveSchedule.schedule.doctor.unit;
      }
    },
    generatePayload() {
      let schedule = {};
      let reschedule = {};
      if (this.tab === 0) {
        schedule = this.changeSchedule.schedule;
        reschedule = this.changeSchedule.reschedule;
      } else {
        schedule = this.leaveSchedule.schedule;
      }
      let payload = {
        type: this.tab === 0 ? 'reschedule' : 'cancel',
        reason: this.tab === 0 ? '' : this.leaveSchedule.reason,
        created_by: this.$store.getters.userLoggedIn.id,
        schedule: {
          id_staff: schedule.doctor.doctorId,
          id_unit: schedule.polyclinic.id,
          date: schedule.date.date,
          startTime: schedule.date.startTime,
          endTime: schedule.date.endTime,
        },
      };
      if (this.tab === 0) {
        payload = {
          ...payload,
          reschedule: {
            date: moment(this.date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            startTime: reschedule.startTime,
            endTime: reschedule.endTime,
            room: reschedule.room,
            quota: parseInt(reschedule.quota),
          },
        };
      }
      return payload;
    },
    getPoly() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?installation=Instalasi Rawat Jalan',
          ),
        )
        .then(response => {
          if (response.status === 200) {
            this.poly = response.data.data.map(unit => {
              return {
                unitId: unit._id.toString(),
                unitName: unit.name,
                code: unit.code,
              };
            });
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    getSpecialistDoctor() {
      this.getSpecialistLoading = true;
      axios
        .post(Constant.apiUrl.concat('/schedule'), {
          startDate: this.praDate
            ? this.praDate
            : moment().format('YYYY-MM-DD'),
          endDate: this.praDate
            ? this.praDate
            : moment()
                .add(7, 'days')
                .format('YYYY-MM-DD'),
          id_unit: this.polyclinic.unitId,
          id_staff: '',
          isActive: true,
        })
        .then(response => {
          if (response.status == 200) {
            const data = response.data.data;
            this.doctorSchedule = data.map((specialistDoctor, idx) => {
              return {
                scheduleId: idx,
                doctorName: specialistDoctor.id_staff,
                schedule: specialistDoctor.schedule,
                unit: specialistDoctor.id_unit,
              };
            });
          }
          this.getSpecialistLoading = false;
        })
        .catch(error => {
          if (error.response.status == 404) {
            this.doctorSchedule = [];
          }
          this.getSpecialistLoading = false;
        });
    },
    addData() {
      this.isSaveLoading = true;
      const payload = this.generatePayload();
      axios
        .post(Constant.apiUrl.concat('/schedule/modify'), payload)
        .then(response => {
          if (response.status === 200) {
            Swal.fire('Jadwal Dokter berhasil diubah!', '', 'success');
            this.changeSchedule = {
              type: '',
              reason: '',
              schedule: {
                doctor: '',
                polyclinic: '',
                date: '',
              },
              reschedule: {
                date: '',
                startTime: '',
                endTime: '',
                quota: '',
              },
            };
            this.date = '';
          }
          this.isSaveLoading = false;
        })
        .catch(() => {
          this.isSaveLoading = false;
          Swal.fire({
            icon: 'error',
            title: 'Jadwal dokter belum berhasil diubah. Silakan ulangi!',
          });
        });
    },
    getDoctor() {
      this.showDoctorLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/staff?role[]=Dokter&role[]=Bidan&role[]=dokter&role[]=bidan&role[]=fisioterapis&role[]=Fisioterapis',
          ),
        )
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.doctors = data
              .filter(v => v.role.status)
              .map(doctor => {
                return {
                  doctorId: doctor._id.toString(),
                  doctorName: doctor.detail.name,
                  unit: doctor.role.unit.map(v => {
                    return {
                      id: v.id_unit._id,
                      name: v.id_unit.name,
                    };
                  }),
                };
              });
          }
        });
      this.showDoctorLoading = false;
    },
    showScheduleDoctor() {
      let input = {};
      if (this.tab === 0) {
        this.changeSchedule.schedule.date = '';
        input = { ...this.changeSchedule.schedule };
      } else {
        this.leaveSchedule.schedule.date = '';
        input = { ...this.leaveSchedule.schedule };
      }
      axios
        .post(Constant.apiUrl.concat('/schedule'), {
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment()
            .add(7, 'days')
            .format('YYYY-MM-DD'),
          id_unit: input.polyclinic.id,
          id_staff: input.doctor.doctorId,
          isActive: true,
        })
        .then(res => {
          const { schedule } = res.data.data[0];
          const dataSchedule = schedule.map(v => {
            const day = moment(v.date)
              .locale('id')
              .format('dddd');
            const date = moment(v.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
            return {
              displayText: `${day}, ${date} ; Pukul ${v.startTime} - ${v.endTime}`,
              date: v.date,
              startTime: v.startTime,
              endTime: v.endTime,
            };
          });
          if (this.tab === 0) this.rescheduleResource.schedule = dataSchedule;
          else this.leaveScheduleResource.schedule = dataSchedule;
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getPoly();
      this.getDoctor();
      this.getSpecialistDoctor();
    });
  },
  watch: {
    rescheduleDatePicker() {
      if (!this.rescheduleDatePicker) return;
      this.changeSchedule.reschedule.date = moment(
        this.rescheduleDatePicker,
      ).format('DD/MM/YYYY');
    },
    praDate() {
      if (!this.praDate) return;
      this.practiceDate = moment(this.praDate).format('DD/MM/YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.display-grid {
  display: grid;
  grid-template-columns: 40% 60%;
  width: 90%;
  padding-top: 2rem;
  row-gap: 0.7rem;
}

.subtitle {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.title {
  font-size: 22px;
  font-weight: 700;
}

.setting-btn {
  border-radius: 8px;
  border-width: 1px;
  margin-left: 1rem;
}
</style>
