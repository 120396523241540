import Constant from '@/const';
import axios from 'axios';

const postGeneralConsent = async payload => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat('/document/general-consent'),
      payload,
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default postGeneralConsent;
