import Constant from '@/const';
import axios from 'axios';

const deleteServices = async payload => {
  try {
    const response = await axios.delete(
      Constant.apiUrl.concat(
        `/master/service/${payload.id}?insurance_type=${payload.assurance}`,
      ),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default deleteServices;
