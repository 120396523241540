import Constant from '@/const';
import axios from 'axios';

const getIHSCodes = async keyword => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/master/laboratory/ihs?page=1&itemCount=20&search=${keyword}`,
      ),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getIHSCodes;
