<template>
  <v-row no-gutters class="d-flex flex-column">
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="font-weight-bold text-left mr-auto"
        >ALIRAN KAS DARI AKTIVITAS OPERASIONAL</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Januari</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Februari</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Maret</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 April</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Mei</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Juni</label>
      </v-col>
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto pl-3"
        >Surplus (Defisit) Tahun Berjalan</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
    </div>

    <div class="d-flex subtotalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto"></label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
    </div>

    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto pl-2"
        >(Kenaikan) Penurunan Piutang Usaha</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
    </div>

    <div class="d-flex subtotalComp-style px-2">
      <label class="font-weight-medium text-left mr-auto"
        >Kas Bersih Diterima (Digunakan) untuk aktivitas operasional</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
    </div>

    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="font-weight-bold text-left mr-auto"
        >ALIRAN KAS DARI AKTIVITAS INVESTASI</label
      >
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto pl-2"
        >(Kenaikan) Penurunan Investasi</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
    </div>
    <div class="d-flex subtotalComp-style px-2">
      <label class="font-weight-medium text-left mr-auto"
        >Kas Bersih Diterima (Digunakan) untuk aktivitas investasi</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
    </div>

    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="font-weight-bold text-left mr-auto"
        >ALIRAN KAS DARI AKTIVITAS PENDANAAN</label
      >
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto pl-2"
        >(Kenaikan) Penurunan Modal Pemilik</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
    </div>
    <div class="d-flex subtotalComp-style px-2">
      <label class="font-weight-medium text-left mr-auto"
        >Kas Bersih Diterima (Digunakan) untuk aktivitas pendanaan</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">{{ formattedCurrency(10000000) }}</label>
      </v-col>
    </div>

    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="font-weight-bold text-left mr-auto"
        >KENAIKAN (PENURUNAN) BERSIH KAS DAN SETARA KAS</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">{{
          formattedCurrency(10000000)
        }}</label>
      </v-col>
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="font-weight-bold text-left mr-auto"
        >KENAIKAN DAN SETARA KAS AWAL PERIODE</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">{{
          formattedCurrency(10000000)
        }}</label>
      </v-col>
    </div>

    <div class="d-flex px-2 totalComp-style">
      <label class="font-weight-bold text-left mr-auto"
        >KAS DAN SETARA KAS AKHIR PERIODE</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">{{
          formattedCurrency(10000000)
        }}</label>
      </v-col>
    </div>
  </v-row>
</template>

<script>
import formatMixin from '@/mixin/formatMixin.js';

export default {
  name: 'Comparative',
  mixins: [formatMixin],
};
</script>

<style lang="scss" scoped>
.column-style {
  max-width: 10%;
  text-align: right;
}
.subtotalComp-style {
  background-color: #deeef9;
  height: 5vh;
  align-items: center;
  margin: 5px 0;
}

.totalComp-style {
  background-color: #addaf9;
  height: 5vh;
  align-items: center;
  margin: 5px 0;
}
</style>
