import Constant from '@/const';
import axios from 'axios';

const deleteCpptById = async id => {
  try {
    const response = await axios.delete(
      Constant.apiUrl.concat(`/inpatient/cppt/${id}`),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default deleteCpptById;
