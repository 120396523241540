<template>
  <v-card>
    <v-card-title class="d-flex justify-center black--text headline">
      <span v-if="state === 'add'">Tambah Tarif Layanan</span>
      <span v-if="state === 'edit'">Ubah Tarif Layanan</span>
      <span v-if="state === 'detail'">Detail Tarif Layanan</span>
    </v-card-title>
    <v-container fluid class="container-layout">
      <!-- Tarif Layanan -->
      <v-row>
        <v-col cols="6" lg="4">
          <v-form ref="form" lazy-validation>
            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="5">
                <div class="text-left">
                  <label
                    :class="
                      (state !== 'detail' ? 'required' : '') +
                        ' font-weight-medium black--text'
                    "
                    for="Nama Layanan"
                  >
                    Nama Layanan</label
                  >
                </div>
              </v-col>
              <v-col cols="7">
                <v-text-field
                  :readonly="state === 'detail'"
                  v-model="form.name"
                  :rules="[
                    v => !!v || 'Nama Layanan Harus diisi',
                    v => v?.length >= 3 || 'Nama Layanan Minimal 3 Karakter',
                    v =>
                      v?.length <= 100 || 'Nama Layanan Maksimal 100 Karakter',
                  ]"
                  placeholder="Masukkan Nama Layanan"
                  dense
                  validate-on-blur
                />
              </v-col>
            </v-row>

            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="5">
                <div class="text-left">
                  <label
                    :class="
                      (state !== 'detail' ? 'required' : '') +
                        ' font-weight-medium black--text'
                    "
                    for="Kategori"
                  >
                    Kategori Layanan</label
                  >
                </div>
              </v-col>
              <v-col cols="7">
                <v-select
                  :readonly="state === 'detail'"
                  v-model="form.category"
                  :items="resource.category"
                  placeholder="Pilih Kategori Layanan"
                  dense
                  :clearable="state !== 'detail'"
                  :rules="[v => !!v || 'Kategori Layanan Harus diisi']"
                  :append-icon="state === 'detail' ? '' : 'mdi-chevron-down'"
                  validate-on-blur
                />
              </v-col>
            </v-row>

            <v-row
              v-if="this.form.category === 'Laboratorium'"
              no-gutters
              align="baseline"
              class="px-7"
            >
              <v-col cols="5">
                <div class="text-left">
                  <label class="font-weight-medium black--text">
                    Kode LIS</label
                  >
                </div>
              </v-col>
              <v-col cols="7">
                <v-text-field
                  :readonly="state === 'detail'"
                  v-model="form.code_lis"
                  placeholder="Masukkan Kode LIS"
                  @keydown="checkKeyDownAlphaNumeric($event)"
                  dense
                  :clearable="state !== 'detail'"
                />
              </v-col>
            </v-row>

            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="5">
                <div class="text-left">
                  <label
                    class="font-weight-medium black--text "
                    for="Layanan Induk"
                  >
                    Layanan Induk</label
                  >
                </div>
              </v-col>
              <v-col cols="7">
                <v-text-field
                  :readonly="state === 'detail'"
                  v-if="this.form.category !== 'Laboratorium'"
                  v-model="form.sub_category"
                  :clearable="state !== 'detail'"
                  placeholder="Masukkan Layanan Induk"
                  dense
                  :rules="
                    form.sub_category === '' || form.sub_category === null
                      ? []
                      : [
                          v =>
                            v?.length >= 3 ||
                            v?.length <= 0 ||
                            'Nama Layanan Induk Minimal 3 Karakter',
                          v =>
                            v?.length <= 100 ||
                            'Nama Layanan Induk Maksimal 100 Karakter',
                        ]
                  "
                  validate-on-blur
                />
                <v-autocomplete
                  :readonly="state === 'detail'"
                  v-if="this.form.category === 'Laboratorium'"
                  :items="[
                    'Pemeriksaan Hematologi',
                    'Pemeriksaan Kimia Klinik',
                    'Pemeriksaan Imuno - Serologi',
                    'Pemeriksaan Urinalisa',
                    'Pemeriksaan Faeces',
                    'Pemeriksaan Elektrolit',
                  ]"
                  v-model="form.sub_category"
                  placeholder="Masukkan Layanan Induk"
                  :clearable="state !== 'detail'"
                  dense
                  :append-icon="state === 'detail' ? '' : 'mdi-chevron-down'"
                />
              </v-col>
            </v-row>

            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="5">
                <div class="text-left">
                  <label
                    :class="
                      (state !== 'detail' ? 'required' : '') +
                        ' font-weight-medium black--text'
                    "
                    for="Unit"
                  >
                    Unit</label
                  >
                </div>
              </v-col>
              <v-col cols="7">
                <v-select
                  :items="items.dataUnit.data"
                  :loading="items.dataUnit.isLoading"
                  :readonly="state === 'detail'"
                  class="hide-input"
                  v-model="form.unit"
                  :rules="[v => !!v || 'Unit Harus diisi']"
                  item-text="name"
                  placeholder="Pilih Unit"
                  dense
                  multiple
                  single-line
                  hide-selected
                  return-object
                  :append-icon="state === 'detail' ? '' : 'mdi-chevron-down'"
                  validate-on-blur
                />
              </v-col>
            </v-row>

            <v-row
              v-if="state !== 'add'"
              no-gutters
              align="baseline"
              class="px-7 mb-8"
            >
              <v-col cols="5">
                <div class="text-left">
                  <label class="font-weight-medium black--text ">
                    Tipe Penjamin</label
                  >
                </div>
              </v-col>
              <v-col cols="7" class="text-left">
                <label class="text-uppercase">{{ assurance }} </label>
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col class="input-main text-left" cols="6" lg="8">
          <div class="text-left ml-1 mb-2">
            <label> Unit</label>
          </div>
          <v-chip
            v-for="(unit, index) in form.unit"
            :key="unit.id"
            class="ma-1"
            @click:close="form.unit.splice(index, 1)"
            :close="state !== 'detail'"
          >
            {{ unit.name }}
          </v-chip>
          <v-btn
            v-if="state !== 'detail'"
            @click="deleteAllUnit()"
            class="text-none"
            outlined
            color="error"
            absolute
            left
            bottom
          >
            Hapus semua unit
          </v-btn>
        </v-col>
      </v-row>

      <v-spacer></v-spacer>

      <v-row align="center" v-if="state === 'add'">
        <v-col cols="12">
          <v-tabs grow center-active v-model="tabs">
            <v-tab class="text-none">Umum</v-tab>
            <v-tab>BPJS</v-tab>
            <v-tab class="text-none">Asuransi</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <PatientCost :state="state" :tabs="tabs" />
            </v-tab-item>
            <v-tab-item>
              <PatientCost :state="state" :tabs="tabs" />
            </v-tab-item>
            <v-tab-item>
              <PatientCost :state="state" :tabs="tabs" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <v-row align="center" v-else>
        <v-col cols="12">
          <PatientCost :state="state" :tabs="tabAssurance()" />
        </v-col>
      </v-row>
    </v-container>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined width="100" @click="onCloseButtonClick()">
        Kembali
      </v-btn>
      <v-btn
        v-if="state !== 'detail'"
        color="primary"
        width="100"
        depressed
        :loading="isLoading"
        @click="handleActionUnit()"
      >
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import jwtMixin from '@/mixin/jwtMixin';
import PatientCost from './PatientCost.vue';
import formatMixin from '@/mixin/formatMixin';
import Swal from 'sweetalert2';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('MasterServices');

export default {
  name: 'FormServices',
  components: {
    PatientCost,
  },
  mixins: [jwtMixin, formatMixin],
  props: {
    state: {
      type: String,
      default: 'add',
    },
    assurance: {
      type: String,
      default: 'umum',
    },
  },
  data() {
    return {
      tabs: null,
      resource: {
        category: [
          'Administrasi',
          'Konsultasi/ Tindakan Medis',
          'Pemeriksaan Penunjang',
          'Pelayanan Penunjang',
          'Laboratorium',
        ],
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['getForm', 'getItems', 'getTable']),

    items: {
      get() {
        return this.getItems;
      },
    },

    form: {
      get() {
        return this.getForm;
      },
    },

    table: {
      get() {
        return this.getTable;
      },
    },

    isGeneralFormFilled() {
      return this.mapFormFilled(0);
    },

    isBpjsFormFilled() {
      return this.mapFormFilled(1);
    },

    isAssuranceFormFilled() {
      return this.mapFormFilled(2);
    },

    isGeneralReadyToSave() {
      return this.mapReadyToSave(this.isGeneralFormFilled, 0);
    },

    isBpjsReadyToSave() {
      return this.mapReadyToSave(this.isBpjsFormFilled, 1);
    },

    isAssuranceReadyToSave() {
      return this.mapReadyToSave(this.isAssuranceFormFilled, 2);
    },
  },
  methods: {
    ...mapActions([
      'resolveGetAHP',
      'resolveGetBHP',
      'resolveGetAllUnit',
      'resolvePutService',
      'resolvePostServices',
      'resolveGetServiceById',
    ]),

    tabAssurance() {
      const assurances = ['umum', 'bpjs', 'asuransi'];
      if (assurances.includes(this.assurance)) {
        return this.form.cost.findIndex(
          arr => arr.assurance === this.assurance,
        );
      }
      return -1;
    },

    onCloseButtonClick() {
      this.$emit('close-dialog');
    },

    deleteAllUnit() {
      this.form.unit = null;
    },

    checkKeyDownAlphaNumeric(event) {
      if (!/[a-zA-Z0-9\s]/.test(event.key)) {
        this.ignoredValue = event.key ? event.key : '';
        event.preventDefault();
      }
    },

    mapFormFilled(index) {
      const cost = this.form.cost[index];
      return (
        !Object.values(cost.medical_service).every(val => val === 0) ||
        cost.unit_load > 0 ||
        cost.margin > 0 ||
        cost.consumable_tools.length > 0 ||
        cost.consumable_material.length > 0
      );
    },

    mapReadyToSave(val, index) {
      const cost = this.form.cost[index];
      if (
        val &&
        (!Object.values(cost.medical_service).every(val => val === 0) ||
          cost.unit_load > 0 ||
          cost.margin > 0)
      ) {
        return true;
      }
      return false;
    },

    handleActionUnit() {
      this.isLoading = true;
      if (!this.$refs.form.validate()) {
        this.isLoading = false;
        return;
      }

      if (this.assurance === 'add') {
        if (
          this.isGeneralFormFilled &&
          this.isBpjsFormFilled &&
          !this.isGeneralReadyToSave &&
          !this.isBpjsReadyToSave
        ) {
          Swal.fire(
            'Biaya Layanan Umum dan BPJS Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          this.isLoading = false;
          return;
        }

        if (
          !this.isGeneralReadyToSave &&
          !this.isAssuranceReadyToSave &&
          this.isGeneralFormFilled &&
          this.isAssuranceFormFilled
        ) {
          Swal.fire(
            'Biaya Layanan Umum dan Asuransi Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          this.isLoading = false;
          return;
        }

        if (
          !this.isAssuranceReadyToSave &&
          !this.isBpjsReadyToSave &&
          this.isAssuranceFormFilled &&
          this.isBpjsFormFilled
        ) {
          Swal.fire(
            'Biaya Layanan BPJS dan Asuransi Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          this.isLoading = false;
          return;
        }

        // handle when single tab hasn't filled
        if (this.isGeneralFormFilled && !this.isGeneralReadyToSave) {
          Swal.fire(
            'Biaya Layanan Umum Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          this.isLoading = false;
          return;
        }

        if (this.isBpjsFormFilled && !this.isBpjsReadyToSave) {
          Swal.fire(
            'Biaya Layanan BPJS Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          this.isLoading = false;
          return;
        }

        if (this.isAssuranceFormFilled && !this.isAssuranceReadyToSave) {
          Swal.fire(
            'Biaya Layanan Asuransi Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          this.isLoading = false;
          return;
        }

        // handle when all tabs hasn't filled
        if (
          (!this.isGeneralFormFilled &&
            !this.isAssuranceFormFilled &&
            !this.isBpjsFormFilled) ||
          (!this.isGeneralReadyToSave &&
            !this.isAssuranceReadyToSave &&
            !this.isBpjsReadyToSave)
        ) {
          Swal.fire(
            'Biaya Semua Layanan Belum Diisi',
            'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
            'warning',
          );
          this.isLoading = false;
          return;
        }
      }

      switch (this.state) {
        case 'add':
          this.resolvePostServices().then(status => {
            this.isLoading = false;
            if (status === 200) {
              Swal.fire(
                'Berhasil',
                'Data Tarif Layanan Berhasil Ditambahkan',
                'success',
              );
            } else {
              Swal.fire(
                'Gagal',
                'Data Tarif Layanan Gagal Ditambahkan',
                'error',
              );
            }
          });
          break;
        case 'edit':
          this.resolvePutService({
            assurance: this.assurance,
            index: this.tabAssurance(),
          }).then(status => {
            this.isLoading = false;
            if (status === 200) {
              Swal.fire(
                'Berhasil',
                'Data Tarif Layanan Berhasil Diubah',
                'success',
              );
            } else {
              Swal.fire('Gagal', 'Data Tarif Layanan Gagal Diubah', 'error');
            }
          });
          break;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.resolveGetAHP();
      this.resolveGetBHP();
      if (this.state !== 'add') {
        this.resolveGetServiceById(this.assurance).then(() => {
          this.resolveGetAllUnit();
        });
      } else {
        this.resolveGetAllUnit();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.input-main {
  position: relative;
  width: 100%;
  height: 250px;
}
.hide-input ::v-deep .v-select__selection.v-select__selection--comma {
  display: none;
}
.container-layout {
  height: 82vh;
  overflow: auto;
}
.total {
  border-radius: 8px;
  padding: 20px;
}
.required:after {
  content: ' *';
  color: red;
}
</style>
