<template>
  <div>
    <v-layout pa-4 align-center>
      <v-flex xs12 md6 class="text-left">
        <h3>Permintaan Obat dan Alkes</h3>
      </v-flex>
      <v-flex xs3>
        <Datepicker
          :show="datePicker"
          :date="date"
          :isClearable="true"
          @date-change="handleDateChange"
        />
      </v-flex>
      <v-flex xs1>
        <router-link :to="routeBack" active>
          <font-awesome-icon style="color: #3f484a" icon="home" />
        </router-link>
        <v-btn class="ml-2" @click="refresh()" icon>
          <font-awesome-icon icon="sync-alt" :class="{ refresh: isRefresh }" />
        </v-btn>
      </v-flex>
      <v-flex xs4 md4>
        <v-text-field
          solo
          dense
          flat
          label="Cari Pasien"
          prepend-inner-icon="mdi-magnify"
          class="search"
          v-model="search"
          background-color="grey lighten-3"
          hide-details="false"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <div class="header">
      <v-tabs v-model="tab" grow slider-color="blue">
        <v-tab key="service">
          <label class="text-none font-weight-bold" @change="getAllPatient()"
            >Pelayanan ({{ count.service }})</label
          >
        </v-tab>
        <v-tab key="takeHomeDrug">
          <label class="text-none font-weight-bold" @change="getAllPatient()"
            >Obat dibawa pulang ({{ count.takeHome }})</label
          >
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item key="service">
        <v-data-table
          :headers="headers"
          :items="data.service"
          item-key="id"
          :items-per-page="itemsPerRow"
          single-expand
          :expanded="expanded"
          :height="height"
          hide-default-footer
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          class="px-2"
          :page.sync="servicePage"
          :loading="isLoading"
        >
          <template v-slot:[`item.no`]="{ item }">
            <tr class="text-left">
              <td>
                {{
                  (servicePage - 1) * itemsPerRow +
                    data.service.indexOf(item) +
                    1
                }}
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length" class="expand px-0">
              <v-card flat>
                <v-row no-gutters class="pb-2">
                  <v-col cols="1"></v-col>
                  <v-col
                    cols="3"
                    class="text-left pl-5 grey--text caption"
                    style="max-width: 300px"
                  >
                    <span
                      >{{ item.gender }} <br />
                      {{ item.age }} Tahun</span
                    ></v-col
                  >
                </v-row>
              </v-card>

              <v-card flat color="grey lighten-4" class="text-right">
                <v-container class="py-0 px-0">
                  <v-row no-gutters>
                    <v-col>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="onClick(item, 1)"
                          >
                            <v-icon>mdi-account</v-icon>
                          </v-btn>
                        </template>
                        <span>Data Sosial</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="onClick(item, 2)"
                          >
                            <v-icon>mdi-file-edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Resep</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="onClick(item, 3)"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </template>
                        <span>Batalkan Resep</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </td>
          </template>
          <template v-slot:no-data> Data tidak ditemukan </template>
        </v-data-table>
      </v-tab-item>

      <v-tab-item key="takeHomeDrug">
        <v-data-table
          :headers="headers"
          :items="data.takehome"
          item-key="id"
          :items-per-page="itemsPerRow"
          single-expand
          :expanded="expanded"
          :height="height"
          hide-default-footer
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          class="px-2"
          :page.sync="takeHomePage"
          :loading="isLoading"
        >
          <template v-slot:[`item.no`]="{ item }">
            <tr class="text-left">
              <td>
                {{
                  (takeHomePage - 1) * itemsPerRow +
                    data.takehome.indexOf(item) +
                    1
                }}
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length" class="expand px-0">
              <v-card flat>
                <v-row no-gutters class="pb-2">
                  <v-col cols="1"></v-col>
                  <v-col
                    cols="3"
                    class="text-left pl-5 grey--text caption"
                    style="max-width: 300px"
                  >
                    <span
                      >{{ item.gender }} <br />
                      {{ item.age }} Tahun</span
                    ></v-col
                  >
                </v-row>
              </v-card>

              <v-card flat color="grey lighten-4" class="text-right">
                <v-container class="py-0 px-1">
                  <v-row no-gutters>
                    <v-col>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="onClick(item, 1)"
                          >
                            <v-icon>mdi-account</v-icon>
                          </v-btn>
                        </template>
                        <span>Data Sosial</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="onClick(item, 2)"
                          >
                            <v-icon>mdi-file-edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Resep</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="onClick(item, 3)"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </template>
                        <span>Batalkan Resep</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>

                  <v-dialog>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="icon"
                        slot="activator"
                        v-bind="attrs"
                        v-on="on"
                      ></div>
                    </template>
                  </v-dialog>
                </v-container>
              </v-card>
            </td>
          </template>
          <template v-slot:no-data> Data tidak ditemukan </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>

    <v-footer color="white">
      <v-container>
        <v-row>
          <v-pagination
            v-model="servicePage"
            :length="serviceLengthPage"
            :total-visible="7"
            v-if="tab === 0"
            @input="getAllPatient()"
          ></v-pagination>
          <v-pagination
            v-model="takeHomePage"
            :length="takeHomeLengthPage"
            :total-visible="7"
            v-if="tab === 1"
            @input="getAllPatient()"
          ></v-pagination>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-footer>

    <v-dialog
      v-model="dialog"
      :max-width="1200"
      persistent
      :fullscreen="selectedDialog === 2"
    >
      <SocialData
        v-if="selectedDialog === 1 && dialog"
        :medical-record="selectedPatient.rm_number"
        @close-form="dialog = false"
      ></SocialData>
      <drug-request-form
        v-else-if="selectedDialog === 2 && dialog"
        :patient="selectedPatient"
        @close-form="
          dialog = false;
          getAllPatient();
        "
      />
    </v-dialog>
    <v-snackbar
      content-class="d-flex justify-space-between"
      v-model="snackbar.show"
      :color="snackbar.color"
      bottom
    >
      {{ snackbar.text }}
      <v-icon small color="white" @click="snackbar.show = false"
        >mdi-close</v-icon
      >
    </v-snackbar>
  </div>
</template>

<script>
import DrugRequestForm from './DrugToolsRequest/DrugRequestForm.vue';
import jwtMixin from '@/mixin/jwtMixin';
import { createNamespacedHelpers } from 'vuex';
import {
  getPharmacyPatientCount,
  getPharmacyPatientByCategory,
} from '@/fetchApi/Pharmacy';
import SocialData from '../../SharedComponent/SocialData.vue';
import Swal from 'sweetalert2';
import { updatePatientPharmacy } from '@/fetchApi/Pharmacy';
import Datepicker from '@/components/SharedComponent/Datepicker/Datepicker.vue';

const _ = require('lodash');
const moment = require('moment-timezone');

const { mapGetters, mapMutations } = createNamespacedHelpers(
  'DrugRequestStore',
);

export default {
  name: 'DrugToolsRequest',
  mixins: [jwtMixin],
  components: {
    DrugRequestForm,
    SocialData,
    Datepicker,
  },
  data() {
    return {
      expanded: [],
      search: '',
      date: moment().format('YYYY-MM-DD'),
      datePicker: false,
      isRefresh: false,
      headers: [
        { text: 'No', align: 'start', value: 'no' },
        { text: 'Nama', value: 'name' },
        { text: 'No. RM.', value: 'rm_number' },
        { text: 'Tipe Penjaminan', value: 'assurance' },
        { text: 'Unit', value: 'unit' },
        { text: 'Nama Dokter', value: 'doctor' },
      ],
      data: {
        service: [],
        takehome: [],
      },
      count: {
        service: 0,
        takeHome: 0,
      },
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      isExpanded: false,
      dialog: false,
      isLoading: false,
      servicePage: 1,
      takeHomePage: 1,
      rowsPerPage: 5,
      serviceLengthPage: 0,
      takeHomeLengthPage: 0,
      selectedDialog: null,
      selectedPatient: null,
    };
  },
  watch: {
    tab() {
      this.getAllPatient();
    },
    search() {
      this.searchData(this);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    ...mapGetters(['getTab']),
    tab: {
      get() {
        return this.getTab;
      },
      set(value) {
        this.setTab(value);
      },
    },
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[2];
    },
    routeBack() {
      return `/farmasi/${this.routeController}`;
    },
    routeName() {
      switch (this.routeController) {
        case 'rawat-inap':
          return 'rawat inap';

        case 'rawat-jalan':
          return 'rawat jalan';

        case 'igd':
          return 'IGD';

        default:
          return '';
      }
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '58vh';
          break;
        case 'lg':
          height = '60vh';
          break;
        case 'xl':
          height = '67vh';
          break;
      }
      return height;
    },
  },
  methods: {
    ...mapMutations(['setTab']),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.takeHomePage = 1;
      v.getAllPatient();
    }, 650),
    handleDateChange(updatedDate) {
      this.date = updatedDate;
      this.servicePage = 1;
      this.getAllPatient();
    },
    refresh() {
      this.isRefresh = true;
      this.getAllPatient();
    },
    async getPatientCount() {
      try {
        const params = {
          category: 'requested',
          date: this.date || '',
          type: this.routeName.toLowerCase(),
          search: this.search,
        };
        const response = await getPharmacyPatientCount(params);
        this.count = {
          service: response.pharmacyNotTakeHomeCount,
          takeHome: response.pharmacyTakeHomeCount,
        };
      } catch {
        this.count = {
          service: 0,
          takeHome: 0,
        };
      }
    },
    async getAllPatient() {
      try {
        this.getPatientCount(); // get amount of patient first
        this.isLoading = true;
        const params = {
          date: this.date || '',
          category: 'requested',
          type: this.routeName.toLowerCase(),
          page: this.tab ? this.takeHomePage : this.servicePage,
          itemCount: this.itemsPerRow,
          search: this.search || '',
          takeHome: this.tab,
        };
        const response = await getPharmacyPatientByCategory(params);
        const patient = response.data.data;
        const dataLength = response.data.length;
        if (this.tab) {
          this.takeHomeLengthPage = Math.ceil(dataLength / this.itemsPerRow);
          this.data.takehome = patient.map(data => {
            return {
              id: data._id.toString(),
              id_staff_order: data.id_staff_order,
              id_emr: data.id_recipe.id_emr,
              id_registration: data.id_recipe.id_registration,
              name: data.name,
              rm_number: data.medical_record_number,
              assurance: data.assurance.toUpperCase(),
              unit: data.unit,
              gender: data.gender ? 'Laki-laki' : 'Perempuan',
              age: data.age,
              doctor: data.doctor,
              officer: data.staff_order,
            };
          });
        } else {
          this.serviceLengthPage = Math.ceil(dataLength / this.itemsPerRow);
          this.data.service = patient.map(data => {
            return {
              id: data._id.toString(),
              id_emr: data.id_recipe.id_emr,
              id_registration: data.id_recipe.id_registration,
              name: data.name,
              id_staff_order: data.id_staff_order,
              rm_number: data.medical_record_number,
              assurance: data.assurance.toUpperCase(),
              unit: data.unit,
              gender: data.gender ? 'Laki-laki' : 'Perempuan',
              age: data.age,
              doctor: data.doctor,
              officer: data.staff_order,
            };
          });
        }
      } catch {
        this.data.takehome = [];
        this.takeHomeLengthPage = 1;
        this.isLoading = false;
      } finally {
        this.isLoading = false;
        this.isRefresh = false;
      }
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllPatient();
      this.interval = setInterval(this.getAllPatient, 60000);
    },
    onClick(item, val) {
      if (val === 3) {
        this.cancelOrder(item.name, item.id_staff_order, item.id);
        return;
      }
      this.selectedDialog = val;
      this.selectedPatient = item;
      this.dialog = true;
    },
    cancelOrder(name, id_staff, id) {
      Swal.fire({
        title:
          '<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Yakin Ingin Membatalkan Resep?</p></div>',
        iconHtml: '<span class="mdi mdi-help-circle-outline"></span>',
        html: `<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%;"><p>Setelah dibatalkan, data permintaan obat pasien</p><p>${name} akan hilang</p></div>`,
        showCancelButton: true,
        customClass: {
          cancelButton: 'button-error',
          confirmButton: 'button-confirm',
        },
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
      })
        .then(async result => {
          if (result.isConfirmed) {
            const payload = {
              status: {
                type: 'cancel',
                id_staff,
              },
              recipe_verifier: this.$store.getters.userLoggedIn.id,
            };
            await updatePatientPharmacy({ payload, id });
            this.refreshAndAutoUpdate();
            this.snackbar = {
              show: true,
              text: 'Permintaan obat berhasil dibatalkan',
              color: '#31b057',
            };
          }
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            text: 'Terjadi kesalahan, permintaan obat gagal dibatalkan',
            color: 'error',
          };
        });
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: left;
  padding: 10px 25px 25px 25px;
}

.header {
  border-bottom: 1px solid #e7e7e7;
}
.icon {
  float: right;
  padding-left: 20px;
}

.expand {
  border: none;
  background-color: white;
}
.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
::v-deep {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
.pagination {
  position: absolute;
  bottom: 3vh;
}
</style>
