<template>
  <v-card class="card">
    <div class="card-header">
      <h2>Assesmen Pasien Infeksius dan Immunokompromise</h2>
      <v-btn fab text @click="closeForm()"><v-icon>mdi-close</v-icon></v-btn>
    </div>
    <div class="card-body">
      <v-container class="text-start">
        <h3 class="grey--text text--lighten-1 font-weight-regular">AIRBORNE</h3>
        <!-- AIRBORNE -->
        <v-row>
          <v-col cols="3">
            <v-checkbox
              data-assessment-igd="active-tbc"
              hide-details
              label="TBC Aktif"
              :readonly="isEmr"
              v-model="active_tbc.checked"
            ></v-checkbox>
            <v-checkbox
              data-assessment-igd="campak"
              hide-details
              label="Campak (Measless)"
              :readonly="isEmr"
              v-model="measless.checked"
            ></v-checkbox>
            <v-checkbox
              data-assessment-igd="mdr-tb"
              hide-details
              label="(MDR-TB)"
              :readonly="isEmr"
              v-model="mdr_tb.checked"
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              data-assessment-igd="sars"
              hide-details
              label="SARS"
              :readonly="isEmr"
              v-model="sars.checked"
            ></v-checkbox>
            <v-checkbox
              data-assessment-igd="aspergilosis"
              hide-details
              label="Aspergilosis"
              :readonly="isEmr"
              v-model="aspergilosis.checked"
            ></v-checkbox>
            <v-checkbox
              data-assessment-igd="aricella-zooster"
              hide-details
              label="Aricella-Zooster"
              :readonly="isEmr"
              v-model="aricella_zooster.checked"
            ></v-checkbox>
          </v-col>
          <v-col cols="4" class="d-flex"
            ><label class="mr-8">Lainnya</label
            ><v-text-field
              data-assessment-igd="other-airbone"
              dense
              placeholder="Masukkan Lainnya"
              :readonly="isEmr"
              v-model="other_airborne"
          /></v-col>
        </v-row>
        <v-divider class="my-7"></v-divider>
        <h3 class="grey--text text--lighten-1 font-weight-regular">DROPLET</h3>
        <!-- DROPLET -->
        <v-row>
          <v-col cols="3">
            <v-checkbox
              data-assessment-igd="ispa"
              hide-details
              label="ISPA"
              :readonly="isEmr"
              v-model="ispa.checked"
            ></v-checkbox>
            <v-checkbox
              data-assessment-igd="swine-flu"
              hide-details
              label="Swine Flu (H1N1)"
              :readonly="isEmr"
              v-model="swine_flu.checked"
            ></v-checkbox>
            <v-checkbox
              data-assessment-igd="rebulla"
              hide-details
              label="Rebulla"
              :readonly="isEmr"
              v-model="rebulla.checked"
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              data-assessment-igd="dipteria"
              hide-details
              label="Dipteria"
              :readonly="isEmr"
              v-model="dipteria.checked"
            ></v-checkbox>
            <v-checkbox
              data-assessment-igd="pneumonia"
              hide-details
              label="Pneumonia"
              :readonly="isEmr"
              v-model="pneumonia.checked"
            ></v-checkbox>
            <v-checkbox
              data-assessment-igd="mumps"
              hide-details
              label="Mumps (Parotitis)"
              :readonly="isEmr"
              v-model="mumps.checked"
            ></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              data-assessment-igd="bird-flu"
              hide-details
              class="mb-3"
              label="Flu Burung (H5N1)"
              :readonly="isEmr"
              v-model="bird_flu.checked"
            ></v-checkbox>
            <div class="d-flex">
              <label class="mr-8">Lainnya</label
              ><v-text-field
                data-assessment-igd="other-droplet"
                dense
                placeholder="Masukkan Lainnya"
                :readonly="isEmr"
                v-model="other_droplet"
              />
            </div>
          </v-col>
        </v-row>
        <v-divider class="my-7"></v-divider>
        <h3 class="grey--text text--lighten-1 font-weight-regular">KONTAK</h3>
        <!-- KONTAK -->
        <v-row>
          <v-col cols="3">
            <v-checkbox
              data-assessment-igd="hepatitis-a"
              hide-details
              label="Hepatitis A"
              :readonly="isEmr"
              v-model="hepatits_a.checked"
            ></v-checkbox>
            <v-checkbox
              data-assessment-igd="mdro-hiv"
              hide-details
              label="MDRO HIV"
              :readonly="isEmr"
              v-model="mdro_hiv.checked"
            ></v-checkbox>
            <v-checkbox
              data-assessment-igd="herpes-simplek"
              hide-details
              label="Herpes Simplek"
              :readonly="isEmr"
              v-model="herpes_simplek.checked"
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              data-assessment-igd="scabies"
              hide-details
              label="Scabies"
              :readonly="isEmr"
              v-model="scabies.checked"
            ></v-checkbox>
            <v-checkbox
              data-assessment-igd="konjungtivitas"
              hide-details
              label="Konjungtivitas"
              :readonly="isEmr"
              v-model="konjungtivity.checked"
            ></v-checkbox>
            <v-checkbox
              data-assessment-igd="varicella-zooster"
              hide-details
              label="Varicella-Zooster"
              :readonly="isEmr"
              v-model="varicella_zooster.checked"
            ></v-checkbox>
          </v-col>
          <v-col cols="4" class="d-flex"
            ><label class="mr-8">Lainnya</label
            ><v-text-field
              data-assessment-igd="other-contact"
              dense
              placeholder="Masukkan Lainnya"
              :readonly="isEmr"
              v-model="other_contact"
          /></v-col>
        </v-row>
      </v-container>
    </div>
    <v-footer class="d-flex justify-end">
      <v-btn
        v-if="!isEmr"
        @click="saveData"
        color="primary"
        class="text-capitalize"
        depressed
        >Simpan</v-btn
      >
    </v-footer>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'InfectiousAssessment',
);
export default {
  name: 'InfectiousAssessment',
  props: {
    existingData: Object,
  },
  computed: {
    ...mapIGDAssessmentGetters(['getIsEmr']),
    ...mapGetters([
      'getActiveTbc',
      'getMeasless',
      'getMdrTb',
      'getSars',
      'getAspergilosis',
      'getAricellaZooster',
      'getPneumonia',
      'getOtherAirborne',
      'getIspa',
      'getSwineFlu',
      'getRebulla',
      'getDipteria',
      'getMumps',
      'getBirdFlu',
      'getHepatitisA',
      'getMdroHiv',
      'getHerpesSimplek',
      'getScabies',
      'getKonjungtivity',
      'getVaricellaZooster',
      'getOtherDroplet',
      'getOtherContact',
    ]),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    active_tbc: {
      get() {
        return this.getActiveTbc;
      },
      set(value) {
        this.setActiveTbc(value);
      },
    },
    measless: {
      get() {
        return this.getMeasless;
      },
      set(value) {
        this.setMeasless(value);
      },
    },
    mdr_tb: {
      get() {
        return this.getMdrTb;
      },
      set(value) {
        this.setMdrTb(value);
      },
    },
    sars: {
      get() {
        return this.getSars;
      },
      set(value) {
        this.setSars(value);
      },
    },
    aspergilosis: {
      get() {
        return this.getAspergilosis;
      },
      set(value) {
        this.setAspergilosis(value);
      },
    },
    aricella_zooster: {
      get() {
        return this.getAricellaZooster;
      },
      set(value) {
        this.setAricellaZooster(value);
      },
    },
    other_airborne: {
      get() {
        return this.getOtherAirborne;
      },
      set(value) {
        this.setOtherAirborne(value);
      },
    },
    ispa: {
      get() {
        return this.getIspa;
      },
      set(value) {
        this.setIspa(value);
      },
    },
    swine_flu: {
      get() {
        return this.getSwineFlu;
      },
      set(value) {
        this.setSwineFlu(value);
      },
    },
    rebulla: {
      get() {
        return this.getRebulla;
      },
      set(value) {
        this.setRebulla(value);
      },
    },
    dipteria: {
      get() {
        return this.getDipteria;
      },
      set(value) {
        this.setDipteria(value);
      },
    },
    pneumonia: {
      get() {
        return this.getPneumonia;
      },
      set(value) {
        this.setPneumonia(value);
      },
    },
    mumps: {
      get() {
        return this.getMumps;
      },
      set(value) {
        this.setMumps(value);
      },
    },
    bird_flu: {
      get() {
        return this.getBirdFlu;
      },
      set(value) {
        this.setBirdFlu(value);
      },
    },
    hepatits_a: {
      get() {
        return this.getHepatitisA;
      },
      set(value) {
        this.setHepatitisA(value);
      },
    },
    mdro_hiv: {
      get() {
        return this.getMdroHiv;
      },
      set(value) {
        this.setMdroHiv(value);
      },
    },
    herpes_simplek: {
      get() {
        return this.getHerpesSimplek;
      },
      set(value) {
        this.setHerpesSimplek(value);
      },
    },
    scabies: {
      get() {
        return this.getScabies;
      },
      set(value) {
        this.setScabies(value);
      },
    },
    konjungtivity: {
      get() {
        return this.getKonjungtivity;
      },
      set(value) {
        this.setKonjungtivity(value);
      },
    },
    varicella_zooster: {
      get() {
        return this.getVaricellaZooster;
      },
      set(value) {
        this.setVaricellaZooster(value);
      },
    },
    other_droplet: {
      get() {
        return this.getOtherDroplet;
      },
      set(value) {
        this.setOtherDroplet(value);
      },
    },
    other_contact: {
      get() {
        return this.getOtherContact;
      },
      set(value) {
        this.setOtherContact(value);
      },
    },
  },
  mounted() {
    if (Object.keys(this.existingData).length > 0) {
      this.setState(this.existingData);
    }
  },
  beforeDestroy() {
    this.clearInfectiousAssessmentState();
  },
  methods: {
    ...mapMutations([
      'clearInfectiousAssessmentState',
      'setActiveTbc',
      'setMeasless',
      'setMdrTb',
      'setSars',
      'setAspergilosis',
      'setAricellaZooster',
      'setOtherAirborne',
      'setOtherDroplet',
      'setOtherContact',
      'setIspa',
      'setSwineFlu',
      'setRebulla',
      'setDipteria',
      'setPneumonia',
      'setMumps',
      'setBirdFlu',
      'setHepatitisA',
      'setMdroHiv',
      'setHerpesSimplek',
      'setScabies',
      'setKonjungtivity',
      'setVaricellaZooster',
    ]),
    setState(data) {
      this.setActiveTbc(data.active_tbc);
      this.setMeasless(data.measless);
      this.setMdrTb(data.mdr_tb);
      this.setSars(data.sars);
      this.setAspergilosis(data.aspergilosis);
      this.setAricellaZooster(data.aricella_zooster);
      this.setOtherAirborne(data.other_airborne);
      this.setOtherDroplet(data.other_droplet);
      this.setOtherContact(data.other_contact);
      this.setIspa(data.ispa);
      this.setSwineFlu(data.swine_flu);
      this.setRebulla(data.rebulla);
      this.setDipteria(data.dipteria);
      this.setPneumonia(data.pneumonia);
      this.setMumps(data.mumps);
      this.setBirdFlu(data.bird_flu);
      this.setHepatitisA(data.hepatits_a);
      this.setMdroHiv(data.mdro_hiv);
      this.setHerpesSimplek(data.herpes_simplek);
      this.setScabies(data.scabies);
      this.setKonjungtivity(data.konjungtivity);
      this.setVaricellaZooster(data.varicella_zooster);
    },
    closeForm() {
      this.$emit('close-form');
    },
    saveData() {
      this.$emit('save', {
        active_tbc: this.active_tbc,
        measless: this.measless,
        mdr_tb: this.mdr_tb,
        sars: this.sars,
        aspergilosis: this.aspergilosis,
        aricella_zooster: this.aricella_zooster,
        ispa: this.ispa,
        swine_flu: this.swine_flu,
        rebulla: this.rebulla,
        dipteria: this.dipteria,
        pneumonia: this.pneumonia,
        mumps: this.mumps,
        bird_flu: this.bird_flu,
        hepatits_a: this.hepatits_a,
        mdro_hiv: this.mdro_hiv,
        herpes_simplek: this.herpes_simplek,
        scabies: this.scabies,
        konjungtivity: this.konjungtivity,
        varicella_zooster: this.varicella_zooster,
        other_airborne: this.other_airborne,
        other_droplet: this.other_droplet,
        other_contact: this.other_contact,
      });
      this.closeForm();
    },
  },
};
</script>

<style scope lang="scss">
.card {
  height: 90vh;
  overflow: auto;

  #{&}-header {
    display: grid;
    height: 8vh;
    align-items: center;
    grid-template-columns: 95% 5%;
    margin-bottom: 2rem;
    box-shadow: 1px 7px 5px -3px rgba(0, 0, 0, 0.07);
    -webkit-box-shadow: 1px 7px 5px -3px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 1px 7px 5px -3px rgba(0, 0, 0, 0.07);
  }
  #{&}-body {
    padding: 0 4rem;
    min-height: 69vh;
    max-height: 69vh;
    overflow: auto;
  }

  footer {
    background-color: white !important;
    padding: 1rem 3rem;
  }
}
</style>
