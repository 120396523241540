import {
  getAllVisits,
  getDialyVisits,
  exportDataVisits,
} from '@/fetchApi/Reporting/PatientVisit';

import { getAllUnit } from '@/fetchApi/MasterData/Unit';

const moment = require('moment-timezone');

const currentYear = moment().format('YYYY');

const state = {
  loading: {
    mounting: false,
    updating: false,
    export: false,
    unit: false,
  },
  series: {
    unit: [],
    assurance: [],
  },
  table: {
    unit: [],
    assurance: [],
    daily: [],
  },
  filter: {
    year: moment().format('YYYY'),
    month: {
      raw: '',
      formatted: '',
    },
    unit: '',
    startDate: {
      raw: moment().format('YYYY-MM-DD'),
      formatted: moment().format('DD-MM-YYYY'),
    },
    endDate: {
      raw: '',
      formatted: '',
    },
  },
  resource: {
    unit: [],
    years: [],
  },
  snackbar: {
    show: false,
    color: '',
    text: '',
  },
};

const getters = {
  getSeries: state => state.series,
  getTable: state => state.table,
  getResources: state => state.resource,
  getFilter: state => state.filter,
  getLoading: state => state.loading,
  getSnackbar: state => state.snackbar,
};

const mutations = {
  clearState(state) {
    state.loading = {
      mounting: false,
      updating: false,
      export: false,
      unit: false,
    };
    state.series = {
      unit: [],
      assurance: [],
    };
    state.table = {
      unit: [],
      assurance: [],
      daily: [],
    };
    state.filter = {
      year: moment().format('YYYY'),
      month: {
        raw: '',
        formatted: '',
      },
      unit: '',
      startDate: {
        raw: moment().format('YYYY-MM-DD'),
        formatted: moment().format('DD-MM-YYYY'),
      },
      endDate: {
        raw: '',
        formatted: '',
      },
    };
    state.resource = {
      unit: [],
      years: [],
    };

    state.snackbar = {
      show: false,
      color: '',
      text: '',
    };
  },
  setResources(state, { type, data }) {
    state.resource[type] = data;
  },
  setSeries(state, { type, data }) {
    state.series[type] = data.map(item => {
      return {
        name: item.unit || item.assurance,
        data: item.amount?.days || item.amount.map(i => i.total),
      };
    });
  },
  setTable(state, { type, data }) {
    state.table[type] = data.map(item => {
      // data for dialy table
      if (!isNaN(item.assurance?.general)) {
        return {
          unit: item.unit, // for unit name
          bpjs: item.assurance?.bpjs,
          general: item.assurance?.general,
          assurance: item.assurance?.assurance,
          amount:
            (item.assurance?.bpjs || 0) +
            (item.assurance?.general || 0) +
            (item.assurance?.assurance || 0),
        };
      } else {
        // data for yearly and monthly table
        return {
          unit: item.unit || item.assurance, // for unit name
          amount: item.value, // amount for main table (yearly or monthly table)
        };
      }
    });
  },
  setFilter(state, { type, data }) {
    state.filter[type] = data;
  },
  setLoading(state, { type, data }) {
    state.loading[type] = data;
  },
  setSnackbar(state, { show, text, color }) {
    state.snackbar = {
      show,
      text,
      color,
    };
  },
};

const actions = {
  async exportData({ commit, state }, { isAssurance, isTotalView }) {
    try {
      let params;
      commit('setLoading', {
        type: 'export',
        data: true,
      });
      if (isTotalView) {
        params = {
          isAssurance: true,
          startDate: state.filter?.startDate?.raw || null,
          endDate: state.filter.endDate.raw || state.filter.startDate.raw,
        };
      } else {
        params = {
          month: state.filter?.month?.formatted?.split('-')?.[0],
          isAssurance: isAssurance ? true : undefined,
          year:
            state.filter?.month?.formatted?.split('-')?.[1] ||
            state.filter?.year ||
            currentYear,
          id_unit: state.filter?.unit?.id || '',
        };
      }

      const response = await exportDataVisits(params);

      return response;
    } catch {
      commit('setSnackbar', {
        show: true,
        color: 'error',
        text: 'Terjadi kesalahan, gagal mengekspor data',
      });
    } finally {
      commit('setLoading', {
        type: 'export',
        data: false,
      });
    }
  },

  async resolveGetAllUnit({ commit }) {
    try {
      commit('setLoading', {
        type: 'unit',
        data: true,
      });
      const installationFilter = Array.from(
        ['rawat jalan', 'rawat inap', 'gawat darurat', 'penunjang medis'],
        i => `instalasi ${i}`,
      );
      const response = await getAllUnit();
      commit('setResources', {
        type: 'unit',
        data: response.data
          .filter(item =>
            installationFilter.includes(
              item.detail?.installation?.toLowerCase(),
            ),
          )
          .map(item => ({ name: item.name, id: item._id })),
      });
    } catch {
      commit('setSnackbar', {
        show: true,
        color: 'error',
        text: 'Terjadi kesalahan, gagal mendapatkan master data unit',
      });
    } finally {
      commit('setLoading', {
        type: 'unit',
        data: false,
      });
    }
  },
  async resolveGetDataVisits({ state, commit }, isMounting = false) {
    try {
      commit('setLoading', {
        type: isMounting ? 'mounting' : 'updating',
        data: true,
      });
      const params = {
        month: state.filter?.month?.formatted?.split('-')?.[0],
        year:
          state.filter?.month?.formatted?.split('-')?.[1] ||
          state.filter?.year ||
          currentYear,
        id_unit: state.filter?.unit?.id || '',
      };
      const response = await getAllVisits(params);
      commit('setSeries', {
        data: response.data?.data?.unit?.graph,
        type: 'unit',
      });
      commit('setTable', {
        data: response.data?.data?.unit?.graph,
        type: 'unit',
      });
      commit('setSeries', {
        data: response.data?.data?.assurance?.graph,
        type: 'assurance',
      });
      commit('setTable', {
        data: response.data?.data?.assurance?.graph,
        type: 'assurance',
      });
      commit('setResources', {
        type: 'years',
        data: response.data?.data?.listYear,
      });
    } catch {
      commit('setSnackbar', {
        show: true,
        color: 'error',
        text: 'Terjadi kesalahan, gagal mendapatkan data kunjungan',
      });
    } finally {
      commit('setLoading', {
        type: isMounting ? 'mounting' : 'updating',
        data: false,
      });
    }
  },
  async resolveGetDialyVisit({ state, commit }, isMounting = false) {
    try {
      commit('setLoading', {
        type: isMounting ? 'mounting' : 'updating',
        data: true,
      });
      const params = {
        startDate: state.filter.startDate.raw || '',
        endDate: state.filter.endDate.raw || state.filter.startDate.raw,
      };
      const response = await getDialyVisits(params);
      commit('setTable', {
        type: 'daily',
        data: response.data.data.daily_assurance.table,
      });
    } catch {
      commit('setSnackbar', {
        show: true,
        color: 'error',
        text: 'Terjadi kesalahan, gagal mendapatkan data kunjungan',
      });
    } finally {
      commit('setLoading', {
        type: isMounting ? 'mounting' : 'updating',
        data: false,
      });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
