import Constant from '@/const';
import axios from 'axios';

const deleteInpatient = async id => {
  try {
    const response = await axios.put(
      Constant.apiUrl.concat(`/inpatient/${id}`),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default deleteInpatient;
