const physicalExamination = [
  {
    label: 'Kepala',
    respond: 'head',
  },
  {
    label: 'Mata',
    respond: 'eye',
  },
  {
    label: 'Telinga',
    respond: 'ear',
  },
  {
    label: 'Hidung',
    respond: 'nose',
  },
  {
    label: 'Mulut',
    respond: 'mouth',
  },
  {
    label: 'Leher',
    respond: 'neck',
  },
  {
    label: 'Rambut',
    respond: 'hair',
  },
  {
    label: 'Bibir',
    respond: 'lips',
  },
  {
    label: 'Gigi Geligi',
    respond: 'teeth',
  },
  {
    label: 'Lidah',
    respond: 'tongue',
  },
  {
    label: 'Langit-langit',
    respond: 'palate',
  },
  {
    label: 'Tenggorokan',
    respond: 'throat',
  },
  {
    label: 'Dada',
    respond: 'chest',
  },
  {
    label: 'Tonsil',
    respond: 'tonsil',
  },
  {
    label: 'Payudara',
    respond: 'breast',
  },
  {
    label: 'Perut',
    respond: 'stomach',
  },
  {
    label: 'Genitalia',
    respond: 'genitalia',
  },
  {
    label: 'Anus/Dubur',
    respond: 'anus',
  },
  {
    label: 'Lengan Atas',
    respond: 'upper_arm',
  },
  {
    label: 'Lengan Bawah',
    respond: 'forearm',
  },
  {
    label: 'Jari Tangan',
    respond: 'finger',
  },
  {
    label: 'Kuku Tangan',
    respond: 'hand_nail',
  },
  {
    label: 'Persendian Tangan',
    respond: 'hand_joint',
  },
  {
    label: 'Tungkai Atas',
    respond: 'upper_limb',
  },
  {
    label: 'Tungkai Bawah',
    respond: 'lower_limb',
  },
  {
    label: 'Jari Kaki',
    respond: 'toe',
  },
  {
    label: 'Kuku Kaki',
    respond: 'toe_nail',
  },
  {
    label: 'Persendian Kaki',
    respond: 'leg_joint',
  },
  {
    label: 'Persendian Tangan',
    respond: 'hand_joint',
  },
  {
    label: 'Punggung',
    respond: 'back',
  },
];
export default physicalExamination;
