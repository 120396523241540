<template>
  <v-card flat :class="data.class" class="pa-5 main-card">
    <v-row>
      <v-col md="8">
        <div>
          <h3 class="font-weight-regular">{{ data.text }}</h3>
          <h2 class="mb-3 mb-lg-0">
            {{ data.amount.toLocaleString('id-ID') }}
            {{ index === 'drug' ? 'Stok' : 'Pasien' }}
          </h2>
          <div class="visit-data" v-if="index === 'total' || index === 'today'">
            <span>Rawat Jalan</span><span>:</span
            ><span>{{ data.outward.toLocaleString('id-ID') }}</span>
            <span>Apotek</span><span>:</span
            ><span>{{ data.drugsales.toLocaleString('id-ID') }}</span>
          </div>
          <v-btn
            color="white"
            @click="openDetail"
            v-else
            class="mt-xl-10 mt-lg-4 primary--text text-capitalize"
            depressed
            width="110"
            >Lihat Detail</v-btn
          >
        </div>
      </v-col>
      <v-col md="4">
        <v-img contain lazy-src :src="data.icon" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'VisitCard',
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
  },
  methods: {
    openDetail() {
      this.$emit('open-dialog');
    },
  },
};
</script>

<style scoped lang="scss">
.main-card {
  height: 10vw;

  span {
    font-size: 0.85vw;
  }
  &.total {
    background: linear-gradient(180deg, #b3d1ff 0%, #99b4ff 100%);
    border-radius: 5px;
    color: #fff;
    width: 97%;
    text-align: left;
  }
  &.today {
    text-align: left;
    background: linear-gradient(180deg, #ffd28e 0%, #ff8957 100%);
    border-radius: 5px;
    color: #fff;
    width: 97%;
    text-align: left;
  }
  &.drugsales {
    background: linear-gradient(180deg, #ddb3ff 0%, #a199ff 100%);
    border-radius: 5px;
    color: #fff;
    width: 97%;
    text-align: left;
  }
  .visit-data {
    display: grid;
    width: 80%;
    grid-template-columns: 1fr 3px 1fr;
    column-gap: 10px;
    row-gap: 5px;
  }
}
</style>
