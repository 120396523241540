import Constant from '@/const';
import axios from 'axios';

const getICare = bpjsNumber => {
  try {
    const response = axios.post(
      Constant.apiUrl.concat(`/integration/pcare/icare/${bpjsNumber}`),
    );
    return response;
  } catch (error) {
    const err = error;
    return err;
  }
};

export default getICare;
