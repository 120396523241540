<template>
  <v-container fluid class="main-container px-7">
    <div class="d-flex justify-space-between">
      <h1>
        Laporan Kunjungan Pasien
      </h1>
      <div class="d-flex align-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mr-3"
              icon
              @click="refreshAndAutoUpdate"
            >
              <v-icon :class="{ refresh: loading.mounting }">
                mdi-autorenew
              </v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
        <v-btn
          color="primary"
          class="text-capitalize"
          depressed
          style="letter-spacing: 0.1px;"
          @click="exportDataVisitors"
          :loading="loading.export"
        >
          Export
        </v-btn>
      </div>
    </div>
    <div class="d-flex justify-space-between mb-4">
      <v-tabs v-model="tab" v-if="!isTotalView">
        <v-tab class="text-capitalize" style="letter-spacing: 0.1px;">
          Unit
        </v-tab>
        <v-tab class="text-capitalize" style="letter-spacing: 0.1px;">
          Tipe Penjamin
        </v-tab>
      </v-tabs>
      <v-btn
        v-else
        @click="switchView()"
        text
        class="text-capitalize"
        color="primary"
        style="letter-spacing: 0.1px;"
      >
        <v-icon color="primary" class="mr-1">mdi-arrow-left</v-icon>
        <span class="primary--text font-weight-bold">
          Dashboard
        </span>
      </v-btn>
      <div class="d-flex">
        <v-combobox
          open-on-clear
          outlined
          dense
          hide-details
          append-icon="mdi-chevron-down"
          :items="
            isTotalView
              ? ['Per Tanggal', 'Rentang Tanggal']
              : ['Tahun', 'Bulan']
          "
          v-model="filterType"
        />
        <template v-if="!isTotalView">
          <v-combobox
            open-on-clear
            outlined
            dense
            hide-details
            append-icon="mdi-chevron-down"
            class="ml-3"
            @change="getDataVisit()"
            :items="resources.years"
            v-model="year"
            v-if="filterType === 'Tahun'"
          />
          <v-menu
            v-else
            dense
            ref="menu"
            v-model="menu.month"
            :close-on-content-click="false"
            :return-value.sync="month.formatted"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                flat
                single-line
                solo
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                v-model="month.formatted"
                prepend-inner-icon="mdi-calendar"
                class="ml-3"
                placeholder="MM-YYYY"
              />
            </template>
            <v-date-picker
              v-model="month.raw"
              :max="currentMonth"
              :min="minMonth"
              type="month"
              locale="id"
              scrollable
              @input="$refs.menu.save(formatMonth(month.raw))"
            />
          </v-menu>
          <v-combobox
            open-on-clear
            outlined
            dense
            hide-details
            key="id"
            item-text="name"
            class="ml-3"
            append-icon="mdi-chevron-down"
            @change="getDataVisit()"
            v-if="tab"
            :items="[{ name: 'Semua Unit', id: '' }, ...resources.unit]"
            v-model="filterUnit"
            :loading="loading.unit"
          />
        </template>
        <div class="d-flex align-center" v-else>
          <v-menu
            dense
            ref="menu_startdate"
            v-model="menu.startDate"
            :close-on-content-click="false"
            :return-value.sync="startDate.formatted"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                flat
                single-line
                solo
                outlined
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
                v-model="startDate.formatted"
                prepend-inner-icon="mdi-calendar"
                class="mx-3"
                :placeholder="
                  filterType === 'Per Tanggal'
                    ? 'Pilih Tanggal'
                    : 'Tanggal Awal'
                "
              />
            </template>
            <v-date-picker
              v-model="startDate.raw"
              locale="id"
              :max="endDate.raw || today"
              scrollable
              @input="$refs.menu_startdate.save(formatDate(startDate.raw))"
            ></v-date-picker>
          </v-menu>
          <template v-if="filterType === 'Rentang Tanggal'">
            <span>s.d</span>
            <v-menu
              dense
              ref="menu_enddate"
              v-model="menu.endDate"
              :close-on-content-click="false"
              :return-value.sync="endDate.formatted"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  flat
                  single-line
                  solo
                  outlined
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  v-model="endDate.formatted"
                  class="ml-3"
                  prepend-inner-icon="mdi-calendar"
                  placeholder="Tanggal Akhir"
                />
              </template>
              <v-date-picker
                v-model="endDate.raw"
                locale="id"
                scrollable
                :min="startDate.raw"
                :max="today"
                @input="$refs.menu_enddate.save(formatDate(endDate.raw))"
              ></v-date-picker> </v-menu
          ></template>
        </div>
      </div>
    </div>
    <v-tabs-items v-model="tab" v-if="!isTotalView">
      <div class="content-wrapper">
        <v-tab-item> <patient-visit-data ref="chart-0" /> </v-tab-item>
        <v-tab-item>
          <patient-visit-data
            :filter-type="filterType"
            @switch-view="switchView"
            ref="chart-1"
            isAssuranceType
          />
        </v-tab-item>
      </div>
    </v-tabs-items>
    <detail-table-patient-visit v-else :is-total-view="isTotalView" />
    <snackbar
      :show="snackbar.show"
      :color="snackbar.color"
      :text="snackbar.text"
    />
  </v-container>
</template>

<script>
// utils
import moment from 'moment-timezone';

//  mixins
import jwtMixin from '@/mixin/jwtMixin';

// components
import { Snackbar } from '@/components/SharedComponent';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'PatientVisitStore',
);

// components
import PatientVisitData from './PatientVisitData';
import DetailTablePatientVisit from './DetailTablePatientVisit';

export default {
  name: 'PatientVisit',
  mixins: [jwtMixin],
  components: { PatientVisitData, DetailTablePatientVisit, Snackbar },
  data() {
    return {
      tab: 0,
      isTotalView: false,
      filterType: 'Tahun',
      menu: {
        month: false,
        startDate: false,
        endDate: false,
      },
    };
  },
  watch: {
    tab(value) {
      if (!value) {
        this.setFilter({ type: 'unit', data: { name: 'Semua Unit', id: '' } });
      }
      this.getDataVisit();
    },
    'month.formatted'(val) {
      if (!val) return;
      this.getDataVisit();
    },
    'startDate.formatted'(val) {
      if (!val) return;
      if (this.filterType === 'Rentang Tanggal') {
        if (!this.endDate.formatted) return;
      }
      this.resolveGetDialyVisit();
    },
    'endDate.formatted'(val) {
      if (!val) return;
      if (!this.startDate.formatted) return;
      this.resolveGetDialyVisit();
    },
    isTotalView(value) {
      if (value) {
        this.filterType = 'Per Tanggal';
        // reset date filter
        this.setFilter({
          type: 'startDate',
          data: {
            raw: moment().format('YYYY-MM-DD'),
            formatted: moment().format('DD-MM-YYYY'),
          },
        });
        this.setFilter({
          type: 'endDate',
          data: {
            raw: '',
            formatted: '',
          },
        });
        this.resolveGetDialyVisit();
      } else {
        this.filterType = 'Tahun';
      }
    },
    filterType(value) {
      if (value === 'Tahun') {
        this.setFilter({ type: 'month', data: { raw: '', formatted: '' } });
        this.getDataVisit();
      } else {
        this.setFilter({ type: 'year', data: moment().format('YYYY') });
      }
    },
  },
  computed: {
    ...mapGetters(['getResources', 'getFilter', 'getSnackbar', 'getLoading']),
    loading: {
      get() {
        return this.getLoading;
      },
    },
    snackbar: {
      get() {
        return this.getSnackbar;
      },
    },
    today: {
      get() {
        return moment().format();
      },
    },
    currentMonth: {
      get() {
        return moment().format('YYYY-MM');
      },
    },
    minMonth: {
      get() {
        return moment(`01-${this.resources.years[0]}`, 'MM-YYYY').format(
          'YYYY-MM',
        );
      },
    },
    resources: {
      get() {
        return this.getResources;
      },
    },
    year: {
      get() {
        return this.getFilter.year;
      },
      set(value) {
        this.setFilter({ type: 'year', data: value });
      },
    },
    month: {
      get() {
        return this.getFilter.month;
      },
      set(value) {
        this.setFilter({ type: 'month', data: value });
      },
    },
    startDate: {
      get() {
        return this.getFilter.startDate;
      },
      set(value) {
        this.setFilter({ type: 'startDate', data: value });
      },
    },
    endDate: {
      get() {
        return this.getFilter.endDate;
      },
      set(value) {
        this.setFilter({ type: 'endDate', data: value });
      },
    },
    filterUnit: {
      get() {
        return this.getFilter.unit || 'Semua Unit';
      },
      set(value) {
        this.setFilter({ type: 'unit', data: value });
      },
    },
  },
  mounted() {
    this.refreshAndAutoUpdate();
    this.resolveGetAllUnit();
  },
  methods: {
    ...mapMutations(['setFilter', 'setSnackbar', 'clearState']),
    ...mapActions([
      'resolveGetDataVisits',
      'resolveGetDialyVisit',
      'resolveGetAllUnit',
      'exportData',
    ]),
    async getDataVisit(isMounting = false) {
      await this.resolveGetDataVisits(isMounting);
      // update chart xaxis
      this.$refs[`chart-${this.tab}`].$refs.realtimeChart.updateOptions({
        xaxis: {
          categories:
            this.filterType === 'Tahun'
              ? [
                  'Jan',
                  'Feb',
                  'Mar',
                  'Apr',
                  'Mei',
                  'Jun',
                  'Jul',
                  'Agu',
                  'Sep',
                  'Okt',
                  'Nov',
                  'Des',
                ]
              : null, // when user choose month filter, then let the chart decide its own xaxis
        },
      });
    },
    switchView() {
      this.isTotalView = !this.isTotalView;
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
    formatMonth(month, text) {
      if (text) {
        return moment(month, 'YYYY-MM')
          .locale('id')
          .format('MMMM YYYY');
      }
      return moment(month, 'YYYY-MM').format('MM-YYYY');
    },
    async exportDataVisitors() {
      try {
        const response = await this.exportData({
          isAssurance: this.tab,
          isTotalView: this.isTotalView,
        });

        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Laporan Kunjungan Pasien.xlsx`);
        document.body.appendChild(link);
        link.click();
        this.setSnackbar({
          show: true,
          color: 'success',
          text: 'Berhasil mengekspor data',
        });
      } catch (e) {
        this.setSnackbar({
          show: true,
          color: 'error',
          text: 'Terjadi kesalahan, gagal mengekspor data',
        });
      }
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getDataVisit(true); // argument 'true' means he is on mounting (see store)
      this.resolveGetDialyVisit(true); // argument 'true' means he is on mounting (see store)
      this.interval = setInterval(() => {
        this.resolveGetDialyVisit;
        this.getDataVisit;
      }, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
    this.clearState();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

.content-wrapper {
  overflow: auto;
  max-height: 73vh;
  &::-webkit-scrollbar {
    width: 7px;
    border-radius: 8px;
    background-color: #ededed;
  }
  &::-webkit-scrollbar-thumb {
    width: 7px;
    border-radius: 8px;
    background-color: #9e9e9e;
  }
}

::v-deep .apexcharts-toolbar {
  display: none !important;
}

.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
