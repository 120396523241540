<template>
  <div>
    <div
      v-if="isLoading"
      :class="maxContent ? 'max-content skeleton' : 'skeleton'"
    >
      <span class="hidden">
        <slot></slot>
      </span>
    </div>
    <span v-else><slot></slot></span>
  </div>
</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    isLoading: Boolean,
    type: {
      type: String,
      default: 'text',
    },
    maxContent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.max-content {
  max-width: max-content;
  height: max-content;
}
.skeleton {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  border-radius: 6px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.hidden {
  opacity: 0;
}
</style>
