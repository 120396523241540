const state = {
  kid: {
    isThin: { check: false, score: 0 },
    isLossWeight: { check: false, score: 0 },
    isHasCondition: { check: false, score: 0 },
    isMalnutritionRisk: { check: false, score: 0 },
  },
  adult: {
    isUnplannedWeightLoss: { check: 'no', score: 0 },
    isUnplannedWeightLossAmount: '',
    isFoodIntakePoorDueToDecreasedAppetite: { check: false, score: 0 },
    isHaveSeriousIllness: { check: false, score: 0 },
  },
};

const getters = {
  // kid
  //  get isThin data
  getIsThin: state => state.kid.isThin,
  //  get is lost weight data
  getIsLossWeight: state => state.kid.isLossWeight,
  //  get is has condition data
  getIsHasCondition: state => state.kid.isHasCondition,
  //  get is malnutrition data
  getIsMalnutritionRisk: state => state.kid.isMalnutritionRisk,

  // adult
  //  get is unplanned weight loss data
  getIsUnplannedWeightLoss: state => state.adult.isUnplannedWeightLoss,
  //  get is unplanned weight loss amount
  getIsUnplannedWeightLossAmount: state =>
    state.adult.isUnplannedWeightLossAmount,
  //  get is food intake poor due to decreaed appetite data
  getIsFoodIntakePoorDueToDecreasedAppetite: state =>
    state.adult.isFoodIntakePoorDueToDecreasedAppetite,
  //  get is have serious illnes
  getIsHaveSeriousIllness: state => state.adult.isHaveSeriousIllness,
};

const mutations = {
  clearNutritionScreeningState(state) {
    Object.assign(state, {
      kid: {
        isThin: { check: false, score: 0 },
        isLossWeight: { check: false, score: 0 },
        isHasCondition: { check: false, score: 0 },
        isMalnutritionRisk: { check: false, score: 0 },
      },
      adult: {
        isUnplannedWeightLoss: { check: 'no', score: 0 },
        isUnplannedWeightLossAmount: '',
        isFoodIntakePoorDueToDecreasedAppetite: { check: false, score: 0 },
        isHaveSeriousIllness: { check: false, score: 0 },
      },
    });
  },
  // set kid values
  setIsThin(state, payload) {
    state.kid.isThin = payload;
  },
  setIsLossWeight(state, payload) {
    state.kid.isLossWeight = payload;
  },
  setIsHasCondition(state, payload) {
    state.kid.isHasCondition = payload;
  },
  setIsMalnutritionRisk(state, payload) {
    state.kid.isMalnutritionRisk = payload;
  },

  //   set adult values
  setIsUnplannedWeightLoss(state, payload) {
    state.adult.isUnplannedWeightLoss = payload;
  },
  setIsUnplannedWeightLossAmount(state, payload) {
    state.adult.isUnplannedWeightLossAmount = payload;
  },
  setIsFoodIntakePoorDueToDecreasedAppetite(state, payload) {
    state.adult.isFoodIntakePoorDueToDecreasedAppetite = payload;
  },
  setIsHaveSeriousIllness(state, payload) {
    state.adult.isHaveSeriousIllness = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
