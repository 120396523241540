import { getStaff, getDiagnose } from '@/fetchApi/laboratory';
import {
  getAllService,
  getCheckupRegist,
  putInformedConsent,
  getAllInformConsent,
  postInformedConsent,
  getInformConsentById,
} from '../../../fetchApi/InformConsent';

const state = {
  dataPatient: {},
  idIC: '',
  isEditable: false,
  stateForm: 'add',
  showDialog: false,
  isCheckup: false,
  snackbar: {
    show: false,
    color: 'success',
    text: '',
  },
  isChanged: false,
  informationForm: {
    // Check Section
    diagnose: {
      isCheck: false,
      value: [],
    },
    base_diagnose: {
      isCheck: false,
      value: '',
    },
    action: {
      isCheck: false,
      value: {
        id: null,
        name: '',
      },
      etc: '',
    },
    action_indication: {
      isCheck: false,
      value: '',
    },
    action_risk: {
      isCheck: false,
      value: '',
    },
    procedure: {
      isCheck: false,
      value: '',
    },
    purpose: {
      isCheck: false,
      value: '',
    },
    complication: {
      isCheck: false,
      value: '',
    },
    prognosis: {
      isCheck: false,
      value: '',
    },
    alt_risk: {
      isCheck: false,
      value: '',
    },
    cost: {
      isCheck: false,
      value: '',
    },
    others: {
      isCheck: false,
      value: '',
    },
  },
  statementLetter: {
    doctor: '',
    staff_executor: '',
    staff_companion: null,
    staff_information: '',
    patient_receiver_info: '',

    signer: 'pasien',
    signer_name_patient: '',
    signer_name: '',
    signer_as: '',
    signer_age: null,
    signer_age_patient: null,
    no_telp: '',
    patient_action_agreement: null,
    address: '',
    family_name: '',
    family_as: '',
  },
  initialForm: {
    informationForm: {
      // Check Section
      diagnose: {
        isCheck: false,
        value: [],
      },
      base_diagnose: {
        isCheck: false,
        value: '',
      },
      action: {
        isCheck: false,
        value: {
          id: null,
          name: '',
        },
        etc: '',
      },
      action_indication: {
        isCheck: false,
        value: '',
      },
      action_risk: {
        isCheck: false,
        value: '',
      },
      procedure: {
        isCheck: false,
        value: '',
      },
      purpose: {
        isCheck: false,
        value: '',
      },
      complication: {
        isCheck: false,
        value: '',
      },
      prognosis: {
        isCheck: false,
        value: '',
      },
      alt_risk: {
        isCheck: false,
        value: '',
      },
      cost: {
        isCheck: false,
        value: '',
      },
      others: {
        isCheck: false,
        value: '',
      },
    },
    statementLetter: {
      doctor: '',
      staff_executor: '',
      staff_companion: null,
      staff_information: '',
      patient_receiver_info: '',

      signer: 'pasien',
      signer_name_patient: '',
      signer_name: '',
      signer_as: '',
      signer_age: null,
      signer_age_patient: null,
      no_telp: '',
      patient_action_agreement: null,
      address: '',
      family_name: '',
      family_as: '',
    },
  },
  // items
  items: {
    staff: {
      loading: false,
      items: [],
    },
    diagnosis: {
      loading: false,
      items: [],
    },
    services: {
      loading: false,
      items: [],
    },
    // Informed Consent List
    informConsentList: {
      items: [],
      loading: false,
    },
  },
};
const getters = {
  getShowDialog: state => state.showDialog,
  getInformationForm: state => state.informationForm,
  getItems: state => state.items,
  getDataPatient: state => state.dataPatient,
  getStatementLetter: state => state.statementLetter,
  getIsCheckup: state => state.isCheckup,
  getIdIC: state => state.idIC,

  getIsEditable: state => state.isEditable,
  // Get snackbar
  getSnackbar: state => state.snackbar,
  // Get state form
  getStateForm: state => state.stateForm,
  // Get isChanged
  getIsChanged: state => state.isChanged,
  // Get Initial Form
  getInitialForm: state => state.initialForm,
};
const mutations = {
  clearFormInformedConsent(state) {
    state.informationForm = {
      // Check Section
      diagnose: {
        isCheck: false,
        value: [],
      },
      base_diagnose: {
        isCheck: false,
        value: '',
      },
      action: {
        isCheck: false,
        value: {
          id: null,
          name: '',
        },
        etc: '',
      },
      action_indication: {
        isCheck: false,
        value: '',
      },
      action_risk: {
        isCheck: false,
        value: '',
      },
      procedure: {
        isCheck: false,
        value: '',
      },
      purpose: {
        isCheck: false,
        value: '',
      },
      complication: {
        isCheck: false,
        value: '',
      },
      prognosis: {
        isCheck: false,
        value: '',
      },
      alt_risk: {
        isCheck: false,
        value: '',
      },
      cost: {
        isCheck: false,
        value: '',
      },
      others: {
        isCheck: false,
        value: '',
      },
    };
    state.statementLetter = {
      ...state.statementLetter,
      doctor: '',
      staff_executor: '',
      staff_companion: null,
      staff_information: '',
      patient_action_agreement: '',
      patient_receiver_info: '',
    };
    state.initialForm = {
      informationForm: {
        diagnose: {
          isCheck: false,
          value: [],
        },
        base_diagnose: {
          isCheck: false,
          value: '',
        },
        action: {
          isCheck: false,
          value: {
            id: null,
            name: '',
          },
          etc: '',
        },
        action_indication: {
          isCheck: false,
          value: '',
        },
        action_risk: {
          isCheck: false,
          value: '',
        },
        procedure: {
          isCheck: false,
          value: '',
        },
        purpose: {
          isCheck: false,
          value: '',
        },
        complication: {
          isCheck: false,
          value: '',
        },
        prognosis: {
          isCheck: false,
          value: '',
        },
        alt_risk: {
          isCheck: false,
          value: '',
        },
        cost: {
          isCheck: false,
          value: '',
        },
        others: {
          isCheck: false,
          value: '',
        },
      },
      statementLetter: {
        ...state.initialForm.statementLetter,
        doctor: '',
        patient_action_agreement: '',
        staff_executor: '',
        staff_companion: null,
        staff_information: '',
        patient_receiver_info: '',
      },
    };
    state.isChanged = false;
  },
  clearItemsInformedConsent(state) {
    state.items = {
      staff: {
        loading: false,
        items: [],
      },
      diagnosis: {
        loading: false,
        items: [],
      },
      services: {
        loading: false,
        items: [],
      },
      informConsentList: {
        loading: false,
        items: [],
      },
    };
    state.stateForm = 'add';
  },
  setShowDialog(state, payload) {
    state.showDialog = payload;
  },
  setInformationForm(state, payload) {
    const { label, value } = payload;
    state.informationForm[label] = value;
  },
  setInformationFormAction(state, payload) {
    const { label, value } = payload;
    state.informationForm.action.value[label] = value;
  },
  setInformationFormActionETC(state, payload) {
    state.informationForm.action.etc = payload;
  },
  setStatementLetter(state, payload) {
    const { label, value } = payload;
    state.statementLetter[label] = value;
  },
  setAllInformationForm(state, payload) {
    state.informationForm = payload;
  },
  setAllStatementLetter(state, payload) {
    state.statementLetter = payload;
  },
  setItems(state, payload) {
    const { label, value, loading } = payload;
    state.items[label] = {
      ...state[label],
      items: value,
      loading: loading,
    };
  },
  setDataPatient(state, payload) {
    state.dataPatient = payload;
  },
  setIsCheckup(state, payload) {
    state.isCheckup = payload;
  },
  setIdIC(state, payload) {
    state.idIC = payload;
  },
  setIsEditable(state, payload) {
    state.isEditable = payload;
  },
  // Set snackbar
  setSnackbar(state, payload) {
    state.snackbar = payload;
  },
  // Set state form
  setStateForm(state, payload) {
    state.stateForm = payload;
  },
  // Set is Change
  setIsChanged(state, payload) {
    state.isChanged = payload;
  },
  // Set initial form
  setInitialForm(state, payload) {
    const { label, value } = payload;
    state.initialForm[label] = value;
  },
};
const actions = {
  // Resolve get all informed consent list
  async resolveGetInformConsentList({ commit }, idEMR) {
    commit('setItems', {
      label: 'informConsentList',
      value: [],
      loading: true,
    });
    try {
      const informedConsentList = await getAllInformConsent(idEMR);
      commit('setItems', {
        label: 'informConsentList',
        value: informedConsentList,
        loading: false,
      });
    } catch (error) {
      commit('setItems', {
        label: 'informConsentList',
        value: [],
        loading: false,
      });
    }
  },

  // Resolve get inform consent by id
  async resolveGetInformConsentById({ commit, state }, idInformConsent) {
    try {
      const informedConsent = await getInformConsentById(idInformConsent);
      const {
        information_checklist,
        doctor,
        staff_executor,
        staff_companion,
        staff_information,
        patient_receiver_info,
        signer,
        signer_name,
        signer_as,
        signer_age,
        no_telp,
        patient_action_agreement,
        address,
        family_name,
        family_as,
      } = informedConsent.detail;

      const dataStatementLetter = {
        doctor: doctor.id,
        staff_executor: staff_executor.id,
        staff_companion: staff_companion.id,
        staff_information: staff_information.id,
        patient_receiver_info,
        signer,
        signer_name,
        signer_as,
        signer_age,
        no_telp,
        patient_action_agreement,
        address,
        family_name,
        family_as,
      };

      const dataInformationFormInitial = {
        diagnose: { ...information_checklist.diagnose },
        base_diagnose: { ...information_checklist.base_diagnose },
        action: {
          isCheck: information_checklist.action.isCheck,
          value: {
            id:
              information_checklist.action.value.id === null
                ? 'etc'
                : information_checklist.action.value.id,
            name:
              information_checklist.action.value.id === null
                ? 'Lain-lain'
                : information_checklist.action.value.name,
          },
          etc:
            information_checklist.action.value.id === null
              ? information_checklist.action.value.name
              : '',
        },
        action_indication: { ...information_checklist.action_indication },
        action_risk: { ...information_checklist.action_risk },
        procedure: { ...information_checklist.procedure },
        purpose: { ...information_checklist.purpose },
        complication: { ...information_checklist.complication },
        prognosis: { ...information_checklist.prognosis },
        alt_risk: { ...information_checklist.alt_risk },
        cost: { ...information_checklist.cost },
        others: { ...information_checklist.others },
      };

      const dataInformationForm = {
        ...informedConsent.detail.information_checklist,
      };
      commit('setAllInformationForm', dataInformationForm);
      commit('setAllStatementLetter', dataStatementLetter);
      if (dataStatementLetter.signer === 'pasien') {
        commit('setStatementLetter', {
          label: 'signer_name_patient',
          value: dataStatementLetter.signer_name,
        });
        commit('setStatementLetter', {
          label: 'signer_name',
          value: '',
        });
        commit('setStatementLetter', {
          label: 'signer_age_patient',
          value: dataStatementLetter.age,
        });
      }
      commit(
        'setInformationFormActionETC',
        dataInformationFormInitial.action.etc,
      );
      commit('setInformationFormAction', {
        label: 'id',
        value: dataInformationFormInitial.action.value.id,
      });
      commit('setInformationFormAction', {
        label: 'name',
        value: dataInformationFormInitial.action.value.name,
      });
      commit('setStatementLetter', {
        label: 'signer_name_patient',
        value: state.dataPatient.name,
      });
      commit('setStatementLetter', {
        label: 'signer_age_patient',
        value: state.dataPatient.age,
      });
      commit('setInitialForm', {
        label: 'informationForm',
        value: dataInformationFormInitial,
      });
      commit('setInitialForm', {
        label: 'statementLetter',
        value: { ...dataStatementLetter },
      });
      return informedConsent;
    } catch {
      commit('setAllStatementLetter', {});
    }
  },

  // Resolve get staff
  async resolveGetStaff({ commit }) {
    commit('setItems', { label: 'staff', value: [], loading: true });
    try {
      const response = await getStaff();
      const staff = response.data.data;
      const data = staff
        .filter(
          i =>
            i.role?.role.some(item => item.toLowerCase() === 'dokter') &&
            i.role?.status,
        )
        .map(item => {
          return {
            id: item._id,
            name: item.detail.name,
          };
        });
      commit('setItems', { label: 'staff', value: data, loading: false });
    } catch {
      commit('setItems', { label: 'staff', value: [], loading: false });
    }
  },

  // Resolve get all diagnose
  async resolveGetDiagnose({ commit }, { keyword }) {
    commit('setItems', { label: 'diagnosis', value: [], loading: true });
    try {
      const diagnose = await getDiagnose({ keyword });
      commit('setItems', {
        label: 'diagnosis',
        value: diagnose.map(item => {
          return {
            id: item._id,
            code: item.code,
            name: `${item.code} ${item.description}`,
          };
        }),
        loading: false,
      });
    } catch {
      commit('setItems', { label: 'diagnosis', value: [], loading: false });
    }
  },

  // Resolve get all service
  async resolveGetAllService({ commit }, { keyword, guarantorType }) {
    commit('setItems', { label: 'services', value: [], loading: true });
    try {
      const services = await getAllService(keyword, guarantorType);
      let data = services.map(service => {
        return {
          id: service._id.toString(),
          name: service.name,
        };
      });
      data.unshift({
        id: 'etc',
        name: 'Lain-lain',
      });
      commit('setItems', { label: 'services', value: data, loading: false });
    } catch {
      commit('setItems', { label: 'services', value: [], loading: false });
    }
  },

  // Resolve get checkup by id regist for autofill
  async resolveGetCheckupRegist({ commit, state }, { idRegist, unit }) {
    try {
      const checkup = await getCheckupRegist(idRegist, unit);
      checkup.assesment?.other_diagnose?.push(
        checkup?.assesment?.main_diagnose,
      );

      // if statement below only runs when patient has checkup and is not from inpatient unit
      if (
        !state.dataPatient.id_inpatient &&
        checkup.timestamps?.doctor_by?._id !== undefined
      ) {
        commit('setIsCheckup', true);
        const diagnoseCheckup = checkup?.assesment?.other_diagnose.flatMap(
          item => {
            return {
              id: item.diagnose._id,
              name: item.diagnose.description,
              code: item.diagnose.code,
            };
          },
        );
        commit('setItems', {
          label: 'diagnosis',
          value: diagnoseCheckup,
          loading: false,
        });
        commit('setStatementLetter', {
          label: 'doctor',
          value: checkup.timestamps?.doctor_by?._id || '',
        });
        commit('setStatementLetter', {
          label: 'signer_name_patient',
          value: state.dataPatient.name,
        });
        commit('setStatementLetter', {
          label: 'address',
          value: state.dataPatient.address,
        });
        commit('setStatementLetter', {
          label: 'signer_age_patient',
          value: state.dataPatient.age,
        });
        commit('setInitialForm', {
          label: 'statementLetter',
          value: {
            ...state.initialForm.statementLetter,
            doctor: checkup.timestamps?.doctor_by?._id || '',
            address: state.dataPatient.address,
            signer_name_patient: state.dataPatient.name,
            signer_age_patient: state.dataPatient.age,
          },
        });
      } else {
        commit('setStatementLetter', {
          label: 'signer_name_patient',
          value: state.dataPatient.name,
        });
        commit('setStatementLetter', {
          label: 'address',
          value: state.dataPatient.address,
        });
        commit('setStatementLetter', {
          label: 'signer_age_patient',
          value: state.dataPatient.age,
        });
        commit('setInitialForm', {
          label: 'statementLetter',
          value: {
            ...state.initialForm.statementLetter,
            address: state.dataPatient.address,
            signer_name_patient: state.dataPatient.name,
            signer_age_patient: state.dataPatient.age,
          },
        });
        commit('setIsCheckup', false);
      }
    } catch {
      commit('setIsCheckup', false);
    }
  },

  // Post informed Consent
  async resolvePostInformedConsent({ commit, state }) {
    const informationFormPayload = {
      ...state.informationForm,
      action: {
        isCheck: state.informationForm.action.isCheck,
        value: {
          id:
            state.informationForm.action.value.id === 'etc'
              ? null
              : state.informationForm.action.value.id,
          name:
            state.informationForm.action.value.id === 'etc'
              ? state.informationForm.action.etc
              : state.informationForm.action.value.name,
        },
      },
    };
    const payload = {
      ...state.statementLetter,
      isInpatient: state.dataPatient.id_inpatient ? true : undefined,
      id_inpatient: state.dataPatient.id_inpatient,
      signer_name:
        state.statementLetter.signer === 'pasien'
          ? state.statementLetter.signer_name_patient
          : state.statementLetter.signer_name,
      signer_as:
        state.statementLetter.signer === 'pasien'
          ? ''
          : state.statementLetter.signer_as,
      information_checklist: informationFormPayload,
      signer_age:
        state.statementLetter.signer === 'pasien'
          ? state.statementLetter.signer_age_patient
          : state.statementLetter.signer_age,
    };
    delete payload.signer_name_patient;
    delete payload.signer_age_patient;
    try {
      const addInformedConsent = await postInformedConsent(
        state.dataPatient.id_emr,
        payload,
      );
      if (addInformedConsent.status === 200) {
        commit('setSnackbar', {
          show: () => {
            commit('setSnackbar', { ...state.snackbar, show: true });
            return setTimeout(() => {
              commit('setSnackbar', { ...state.snackbar, show: false });
            }, 4000);
          },
          text: 'Formulir Informed Consent berhasil disimpan',
          color: 'success',
        });
        commit('setStateForm', 'edit');
        commit('setIsChanged', true);
        commit('setIdIC', addInformedConsent.data.data._id);
      }
    } catch (error) {
      commit('setSnackbar', {
        show: () => {
          commit('setSnackbar', { ...state.snackbar, show: true });
          return setTimeout(() => {
            commit('setSnackbar', { ...state.snackbar, show: false });
          }, 4000);
        },
        text: 'Formulir Informed Consent gagal disimpan',
        color: 'error',
      });
    }
  },

  // Put informed Consent
  async resolvePutInformedConsent({ state }) {
    const informationFormPayload = {
      ...state.informationForm,
      action: {
        isCheck: state.informationForm.action.isCheck,
        value: {
          id:
            state.informationForm.action.value.id === 'etc'
              ? null
              : state.informationForm.action.value.id,
          name:
            state.informationForm.action.value.id === 'etc'
              ? state.informationForm.action.etc
              : state.informationForm.action.value.name,
        },
      },
    };
    const payload = {
      ...state.statementLetter,
      signer_name:
        state.statementLetter.signer === 'pasien'
          ? state.statementLetter.signer_name_patient
          : state.statementLetter.signer_name,
      signer_as:
        state.statementLetter.signer === 'pasien'
          ? ''
          : state.statementLetter.signer_as,
      information_checklist: informationFormPayload,
      signer_age:
        state.statementLetter.signer === 'pasien'
          ? state.statementLetter.signer_age_patient
          : state.statementLetter.signer_age,
    };
    delete payload.signer_name_patient;
    delete payload.signer_age_patient;
    try {
      const editInformedConsent = await putInformedConsent(state.idIC, payload);
      if (editInformedConsent.status === 200) {
        return editInformedConsent;
      }
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
