<template>
  <v-card tile light elevation="0">
    <v-card-title>
      Data {{ isBefore ? 'Sebelum' : 'Sesudah' }} Diubah
    </v-card-title>
    <p v-if="!data" class="pt-6 grey--text">
      Tidak ada riwayat perubahan
    </p>
    <div v-else class="py-4 px-2 text-start">
      <v-chip v-if="!isBefore" class="chip"
        >Diubah pada tanggal {{ time[0] }}, pukul {{ time[1] }}</v-chip
      >
      <v-row class="py-2">
        <v-col>
          <h4>DATA SUBJEKTIF</h4>
          <label>Anamnesis</label>
          <p
            v-html="data?.anamnesis?.value"
            :class="mark(data?.anamnesis?.isChanged)"
          ></p>
          <h4>DATA OBJEKTIF</h4>
          <div class="objective-grid">
            <label>Tekanan Darah</label>
            <div>
              <span :class="mark(data?.sistole?.isChanged)">{{
                data?.sistole?.value
              }}</span>
              <span>/</span>
              <span :class="mark(data?.diastole?.isChanged)"
                >{{ data?.diastole?.value }} mmHg</span
              >
            </div>
            <label>Suhu</label>
            <span :class="mark(data?.temperature?.isChanged)"
              >{{ data?.temperature?.value }} &deg;C</span
            >
            <label>Nadi</label>
            <span :class="mark(data?.heartRate?.isChanged)"
              >{{ data?.heartRate?.value }} x/menit</span
            >
            <label>RR</label>
            <span :class="mark(data?.rr?.isChanged)"
              >{{ data?.rr?.value }} x/menit</span
            >
            <label>Saturasi</label>
            <span :class="mark(data?.saturation?.isChanged)"
              >{{ data?.saturation?.value }} %</span
            >
          </div>
          <label>GDS</label>
          <p :class="mark(data?.gds?.isChanged)">
            {{ data?.gds?.value }}
          </p>
          <label>Data Objektif Lainnya</label>
          <p
            v-html="data?.otherObjective?.value"
            :class="mark(data?.otherObjective?.isChanged)"
          ></p>
        </v-col>
        <v-col>
          <h4>ASSESMEN & PLAN</h4>
          <template v-if="isDoctor">
            <label>Diagnosa (ICD 10)</label>
            <p
              v-if="
                !data.doctor?.diagnose?.value?.length ||
                  !data.doctor?.diagnose?.value?.[0]
              "
            >
              -
            </p>
            <ul class="mb-3" v-else>
              <li
                v-for="(i, index) in data?.doctor?.diagnose.value"
                :key="`diagnose-doctor-${index}`"
                :class="mark(data?.doctor?.diagnose?.isChanged)"
              >
                {{ i }}
              </li>
            </ul>
            <label>Rencana Tindakan</label>
            <p
              v-if="
                !data?.doctor?.planning?.value?.length ||
                  !data?.doctor?.planning?.value?.[0]
              "
            >
              -
            </p>
            <ul class="mb-3" v-else>
              <li
                v-for="(i, index) in data?.doctor?.planning.value"
                :key="`planning-doctor-${index}`"
                :class="mark(data?.doctor?.planning?.isChanged)"
              >
                {{ i }}
              </li>
            </ul>
          </template>
          <template v-else>
            <p v-if="!data.nurse?.value.length || !data.nurse?.value?.[0]">
              -
            </p>
            <div
              v-else
              v-for="(item, index) in data.nurse?.value"
              :key="`diagnose-nurse-${index}`"
            >
              <label>Diagnosa {{ index + 1 }}</label>
              <p :class="mark(data.nurse.isChanged || item.isChanged)">
                {{ item.name }}
              </p>
              <label>Rencana Tindakan</label>
              <p
                v-if="
                  !item?.planning?.value?.length || !item?.planning?.value?.[0]
                "
              >
                -
              </p>
              <ul v-else class="mb-3">
                <li
                  v-for="(v, idx) in item?.planning.value"
                  :key="`diagnose-nurse-${index}-planning-${idx}`"
                  :class="
                    mark(data?.nurse?.isChanged || item?.planning?.isChanged)
                  "
                >
                  {{ v }}
                </li>
              </ul>
            </div>
          </template>
          <label>
            Catatan
          </label>
          <p
            v-html="data.assessmentNote.value"
            :class="mark(data?.assessmentNote?.isChanged)"
          ></p>
          <h4>INSTRUKSI PPA</h4>
          <label
            >Instruksi Tenaga
            {{
              role.some(item => item.toLowerCase() === 'perawat')
                ? 'Kesehatan'
                : 'Medis'
            }}</label
          >
          <p
            :class="mark(data?.ppaInstruction?.isChanged)"
            v-html="data?.ppaInstruction?.value"
          ></p>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'DetailChangesList',
  props: {
    time: {
      default: null,
    },
    data: {
      default: null,
    },
    isDoctor: {
      default: null,
    },
    role: {
      default: '',
    },
    isBefore: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    mark(value) {
      if (value) {
        return 'mark';
      } else {
        return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-card__title {
  font-size: 0.8vw;
  background-color: #f4f5f5;
  display: flex;
  justify-content: center;
  color: #9e9e9e;
  border-bottom: 1px solid #c2c2c2;
}

.col {
  max-width: 23vw;
}

.objective-grid {
  display: grid;
  width: 80%;
  margin-bottom: 1vw;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.2vw;
}

.chip {
  background-color: #f5f5f5 !important;
  font-size: 0.75vw;
}

h4 {
  font-weight: 400;
  color: #137bc0;
  margin-bottom: 0.4vw;
}

label {
  color: #757575;
}

p {
  color: black;
  margin-bottom: 0.5vw;
}

.mark {
  color: #dd9404;
}
</style>
