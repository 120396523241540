const moment = require('moment-timezone');

export default {
  methods: {
    mapSatuSehatPatients(source) {
      return source.map((item, index) => {
        return {
          no: (this.pagination.page - 1) * this.itemsPerPage + index + 1,
          datein: moment(item.registration_date).format('DD-MM-YYYY'),
          dateout: moment(item.registration_date).format('DD-MM-YYYY'),
          name: item.patient_name || '-',
          rmNumber: item.medical_record_number || '-',
          bpjs: item.assurance_number || '-',
          nik: item.nik_patient_number || '-',
          bridgingStatus: this.bridgingStatus(item),
          idRegistration: item.id_registration,
          poly: item.location,
        };
      });
    },
    bridgingStatus(data) {
      if (data.processed_status === 'processed') return 'Diproses';
      if (data.processed_status === 'pending' && !data.bridging?.status)
        return {
          status: 'Gagal',
          message: 'Gagal Terkirim ke IHS',
        };
      switch (data.bridging?.status) {
        case 400:
        case null:
        case 500:
          return {
            status: 'Gagal',
            message:
              data.bridging.message ||
              data.bridging?.data?.bridging?.message ||
              'Gagal Terkirim ke IHS',
          };
        case 200:
          return moment(data.success_at).format('DD-MM-YYYY');
        case 'pending':
        case undefined:
          return 'Diproses';
        default:
          return '-';
      }
    },
  },
};
