const moment = require('moment-timezone');
const Constant = require('@/const');

// start of neonatus helper function
const generateTotalApgar = score => {
  if (score >= 0 && score <= 3) {
    return {
      name: 'Asfiksi Berat',
      score,
    };
  }
  if (score >= 4 && score <= 6) {
    return {
      name: 'Asfiksi Ringan Sedang',
      score,
    };
  }
  if (score >= 7 && score <= 9) {
    return {
      name: 'Sedikit Asfiksi',
      score,
    };
  }
  if (score === 10) {
    return {
      name: 'Bayi Normal',
      score,
    };
  }
  return {
    name: '',
    score: null,
  };
};

const generateImageUrl = path => {
  if (!path || path === '/') {
    return '';
  }
  {
    return Constant.default.apiUrl.concat(`/${path}`);
  }
};

const isBooleanData = data => {
  return typeof data === 'boolean' ? data : null;
};

const isNumberData = data => {
  return typeof data === 'number' ? data : null;
};

const findRecentImage = array => {
  if (!array) return null;
  return array.find(item => !item.is_deleted);
};
// end of neonatus helper function

const mapToPayload = (source, type, userLoggedIn) => {
  let payload;

  const input = source.form[type];

  let id = {
    id_registration: source.id.registration,
    id_emr: source.id.emr,
    id_inpatient: source.id.inpatient,
  };

  const timestamps = {
    created_at: moment().format(),
    created_by: userLoggedIn,
  };

  // assert for total apgar score
  let minute1TotalScore, minute5TotalScore, minute10TotalScore;
  if (type === 'apgar_score') {
    if (
      Object.values(input.minute1).every(
        item => item.score || +item.score === 0,
      )
    ) {
      minute1TotalScore = Object.values(input.minute1)
        .map(item => item.score)
        .reduce((a, b) => a + b, 0);
    }
    if (
      Object.values(input.minute5).every(
        item => item.score || +item.score === 0,
      )
    ) {
      minute5TotalScore = Object.values(input.minute5)
        .map(item => item.score)
        .reduce((a, b) => a + b, 0);
    }
    if (
      Object.values(input.minute10).every(
        item => item.score || item.score === 0,
      )
    ) {
      minute10TotalScore = Object.values(input.minute10)
        .map(item => item.score)
        .reduce((a, b) => a + b, 0);
    }
  }

  switch (type) {
    case 'information':
      payload = {
        ...id,
        information: {
          timestamps,
          is_saved: true,
          staff: input?.staff?.id || null,
          time_fill: {
            date: input.dateFill.raw,
            time: input.time.fill,
          },
          time_arrived: {
            date: input.dateVisit.raw,
            time: input.time.visit,
          },
          mother_information: {
            mother: {
              id_emr_mother: input.mother?.data?.id_emr || null,
              rm_number: input.mother?.data?.rm_number || '',
              name: input.mother?.data?.name || '',
              birthdate: input.mother?.data?.birthDate?.raw || '',
              profession: input.mother?.data?.profession || '',
              address: input?.mother?.data?.address,
            },
            id_emr_mother: input.mother?.data?.id_emr || null,
            rm_number: input.mother?.data?.rm_number || '',
            name: input.mother?.name || '',
            birthdate: input.mother?.birthDate?.raw || '',
            profession: input.mother?.profession || '',
            status: input.mother?.status || '',
          },
          father_information: input.father,
          baby_information: {
            time_birth: {
              date: input.baby.birthTime.date.raw,
              time: input.baby.birthTime.time,
            },
            type_birth: input.baby.birthType,
            weight: input.baby.weight,
            length: input.baby.length,
            chest_circumference: input.baby.chestCircumference,
            head_circumference: input.baby.headCircumference,
          },
        },
      };
      break;
    case 'physical':
      payload = {
        ...id,
        physical: {
          timestamps,
          is_saved: true,
          eyes_infection: input.eyes_infection,
          mouth: {
            suction_reflex: input.mouth.suction_reflex,
            cyanotic_lips: input.mouth.cyanotic_lips,
            harelip: input.mouth.harelip,
            palatosic: input.mouth.palatosic,
          },
          limb: {
            movement: input.limb.movement,
            polydactyly: input.limb.polydactyly,
            syndactyly: input.limb.syndactyly,
            other_abnormalities: {
              description: input.limb.other_abnormalities.description,
              check: input.limb.other_abnormalities.check,
            },
          },
          chest: {
            respiratory_frequency: {
              check: input.chest.respiratory_frequency.check,
              value: input.chest.respiratory_frequency.check
                ? input.chest.respiratory_frequency.value
                : '',
            },
            heart_rate: {
              check: input.chest.heart_rate.check,
              value: input.chest.heart_rate.check
                ? input.chest.heart_rate.value
                : '',
            },
          },
          belly: {
            umbilical_cord_bleeding: input.belly.umbilical_cord_bleeding,
            protrusion_around_the_umbilical_cord:
              input.belly.protrusion_around_the_umbilical_cord,
          },
          sex: {
            boy: {
              scrotum: input.sex.boy.scrotum,
              testicles: input.sex.boy.testicles,
              penis_hole: input.sex.boy.penis_hole,
            },
            girl: {
              vagina_hole: input.sex.girl.vagina_hole,
              uretra_hole: input.sex.girl.uretra_hole,
            },
          },
          anus_hole: input.anus_hole,
          abnormalities: input.abnormalities,
          notes: {
            postscript: input.notes.postscript,
            built_in_note: input.notes.built_in_note,
          },
        },
      };
      break;
    case 'apgar_score':
      payload = {
        ...id,
        apgar_score: {
          is_saved: true,
          timestamps,
          minute1: {
            ...input.minute1,
            total_score: generateTotalApgar(minute1TotalScore),
          },
          minute5: {
            ...input.minute5,
            total_score: generateTotalApgar(minute5TotalScore),
          },
          minute10: {
            ...input.minute10,
            total_score: generateTotalApgar(minute10TotalScore),
          },
        },
      };
      break;
    case 'stamp':
      payload = {
        ...id,
        stamp: {
          is_saved: true,
          ...timestamps,
          baby: {
            right_leg: {
              identifier: `${source.id.inpatient}babyrightleg`,
            },
            left_leg: {
              identifier: `${source.id.inpatient}babyleftleg`,
            },
          },
          mother: {
            right_thumb: {
              identifier: `${source.id.inpatient}motherrightthumb`,
            },
            left_thumb: {
              identifier: `${source.id.inpatient}motherleftthumb`,
            },
          },
        },
      };
  }

  return payload;
};

const mapDataByKey = (source, type) => {
  const data = source[type];

  switch (type) {
    case 'information':
      return {
        isSaved: data?.is_saved,
        staff: data.staff?._id
          ? {
              id: data?.staff?._id || '',
              name: data?.staff?.name || '',
            }
          : '',
        dateFill: {
          raw: data?.time_fill?.date || '',
          formatted: data?.time_fill?.date
            ? moment(data?.time_fill?.date, 'YYYY-MM-DD').format('DD-MM-YYYY')
            : moment().format('DD-MM-YYYY'),
        },
        dateVisit: {
          raw: data?.time_arrived?.date || moment().format('YYYY-MM-DD'),
          formatted: data?.time_arrived?.date
            ? moment(data?.time_arrived?.date, 'YYYY-MM-DD').format(
                'DD-MM-YYYY',
              )
            : moment().format('DD-MM-YYYY'),
        },
        time: {
          visit: data?.time_arrived?.time || moment().format('HH:mm'),
          fill: data?.time_fill?.time || moment().format('HH:mm'),
        },
        mother: {
          data: data?.mother_information?.mother?.id_emr_mother
            ? {
                address: data?.mother_information?.mother?.address || '',
                profession: data?.mother_information?.mother?.profession || '',
                rm_number: data?.mother_information?.mother?.rm_number || '',
                text: `${data?.mother_information?.mother?.rm_number} - ${data?.mother_information?.mother?.name}`,
                name: data?.mother_information?.mother?.name || '',
                id_emr: data?.mother_information?.mother?.id_emr_mother || null,
                birthDate: {
                  raw: data?.mother_information?.mother?.birthdate || '',
                  formatted: data?.mother_information?.mother?.birthdate
                    ? moment(
                        data?.mother_information?.mother?.birthdate,
                        'YYYY-MM-DD',
                      ).format('DD-MM-YYYY')
                    : '',
                },
              }
            : '',
          name: data?.mother_information?.name || '',
          profession: data?.mother_information?.profession || '',
          birthDate: {
            raw: data?.mother_information?.birthdate || '',
            formatted: data?.mother_information?.birthdate
              ? moment(
                  data?.mother_information?.birthdate,
                  'YYYY-MM-DD',
                ).format('DD-MM-YYYY')
              : '',
          },
          status: {
            g: data?.mother_information?.status?.g || '',
            p: data?.mother_information?.status?.p || '',
            a: data?.mother_information?.status?.a || '',
          },
        },
        father: {
          name: data?.father_information?.name || '',
          profession: data?.father_information?.profession || '',
        },
        baby: {
          birthTime: {
            date: {
              raw: data?.baby_information?.time_birth?.date || '',
              formatted: data?.baby_information?.time_birth?.date
                ? moment(
                    data?.baby_information?.time_birth?.date,
                    'YYYY-MM-DD',
                  ).format('DD-MM-YYYY')
                : '',
            },
            time: data?.baby_information?.time_birth?.time || '',
          },
          birthType: data?.baby_information?.type_birth || '',
          weight: data?.baby_information?.weight || '',
          length: data?.baby_information?.length || '',
          chestCircumference: data?.baby_information?.chest_circumference || '',
          headCircumference: data?.baby_information?.head_circumference || '',
        },
      };
    case 'physical':
      return {
        eyes_infection: isBooleanData(data?.eyes_infection),
        mouth: {
          suction_reflex: isBooleanData(data?.mouth?.suction_reflex),
          cyanotic_lips: isBooleanData(data?.mouth?.cyanotic_lips),
          harelip: isBooleanData(data?.mouth?.harelip),
          palatosic: isBooleanData(data?.mouth?.palatosic),
        },
        limb: {
          movement: isBooleanData(data?.limb?.movement),
          polydactyly: isBooleanData(data?.limb?.polydactyly),
          syndactyly: isBooleanData(data?.limb?.syndactyly),
          other_abnormalities: {
            description: data?.limb?.other_abnormalities?.description || '',
            check: isBooleanData(data?.limb?.other_abnormalities?.check),
          },
        },
        chest: {
          respiratory_frequency: {
            check: isBooleanData(data?.chest?.respiratory_frequency?.check),
            value: data?.chest?.respiratory_frequency?.value || '',
          },
          heart_rate: {
            check: isBooleanData(data?.chest?.heart_rate?.check),
            value: data?.chest?.heart_rate?.value || '',
          },
        },
        belly: {
          umbilical_cord_bleeding: isBooleanData(
            data?.belly?.umbilical_cord_bleeding,
          ),
          protrusion_around_the_umbilical_cord: isBooleanData(
            data?.belly?.protrusion_around_the_umbilical_cord,
          ),
        },
        sex: {
          boy: {
            scrotum: isBooleanData(data?.sex?.boy?.scrotum),
            testicles: isBooleanData(data?.sex?.boy?.testicles),
            penis_hole: isBooleanData(data?.sex?.boy?.penis_hole),
          },
          girl: {
            vagina_hole: isBooleanData(data?.sex?.girl?.vagina_hole),
            uretra_hole: isBooleanData(data?.sex?.girl?.uretra_hole),
          },
        },
        anus_hole: isBooleanData(data?.anus_hole),
        abnormalities: data?.abnormalities || '',
        notes: {
          postscript: data?.notes?.postscript || '',
          built_in_note: data?.notes?.built_in_note || '',
        },
        isSaved: data?.is_saved,
      };
    case 'apgar_score':
      return {
        minute1: {
          appearance: {
            name: data?.minute1?.appearance?.name || '',
            score: isNumberData(data?.minute1?.appearance?.score),
          },
          pulse: {
            name: data?.minute1?.pulse?.name || '',
            score: isNumberData(data?.minute1?.pulse?.score),
          },
          grimace: {
            name: data?.minute1?.grimace?.name || '',
            score: isNumberData(data?.minute1?.grimace?.score),
          },
          activity: {
            name: data?.minute1?.activity?.name || '',
            score: isNumberData(data?.minute1?.activity?.score),
          },
          respiration: {
            name: data?.minute1?.respiration?.name || '',
            score: isNumberData(data?.minute1?.respiration?.score),
          },
        },
        minute5: {
          appearance: {
            name: data?.minute5?.appearance?.name || '',
            score: isNumberData(data?.minute5?.appearance?.score),
          },
          pulse: {
            name: data?.minute5?.pulse?.name || '',
            score: isNumberData(data?.minute5?.pulse?.score),
          },
          grimace: {
            name: data?.minute5?.grimace?.name || '',
            score: isNumberData(data?.minute5?.grimace?.score),
          },
          activity: {
            name: data?.minute5?.activity?.name || '',
            score: isNumberData(data?.minute5?.activity?.score),
          },
          respiration: {
            name: data?.minute5?.respiration?.name || '',
            score: isNumberData(data?.minute5?.respiration?.score),
          },
        },
        minute10: {
          appearance: {
            name: data?.minute10?.appearance?.name || '',
            score: isNumberData(data?.minute10?.appearance?.score),
          },
          pulse: {
            name: data?.minute10?.pulse?.name || '',
            score: isNumberData(data?.minute10?.pulse?.score),
          },
          grimace: {
            name: data?.minute10?.grimace?.name || '',
            score: isNumberData(data?.minute10?.grimace?.score),
          },
          activity: {
            name: data?.minute10?.activity?.name || '',
            score: isNumberData(data?.minute10?.activity?.score),
          },
          respiration: {
            name: data?.minute10?.respiration?.name || '',
            score: isNumberData(data?.minute10?.respiration?.score),
          },
        },
        total: {
          minute1: {
            text: data?.minute1?.total_score?.name || '',
            score: isNumberData(data?.minute1?.total_score?.score),
          },
          minute5: {
            text: data?.minute5?.total_score?.name || '',
            score: isNumberData(data?.minute5?.total_score?.score),
          },
          minute10: {
            text: data?.minute10?.total_score?.name || '',
            score: isNumberData(data?.minute10?.total_score?.score),
          },
        },
        isSaved: data.is_saved,
      };
    case 'stamp':
      return {
        isSaved: data.is_saved,
        baby: {
          right_leg: {
            id:
              findRecentImage(data?.baby?.right_leg?.files?.[0]?.files)?._id ||
              '',
            file:
              findRecentImage(data?.baby?.right_leg?.files?.[0]?.files)
                ?.original_file_name || null,
            url: generateImageUrl(
              findRecentImage(data?.baby?.right_leg?.files?.[0]?.files)?.path,
            ),
            rejectMessage: null,
            identifier: data?.baby?.right_leg?.files?.[0]?.identifier,
            isDragHovered: false,
            isUploadable: false,
          },
          left_leg: {
            id:
              findRecentImage(data?.baby?.left_leg?.files?.[0]?.files)?._id ||
              '',
            file:
              findRecentImage(data?.baby?.left_leg?.files?.[0]?.files)
                ?.original_file_name || null,
            url: generateImageUrl(
              findRecentImage(data?.baby?.left_leg?.files?.[0]?.files)?.path,
            ),
            rejectMessage: null,
            identifier: data?.baby?.left_leg?.files?.[0]?.identifier,
            isDragHovered: false,
            isUploadable: false,
          },
        },
        mother: {
          right_thumb: {
            id:
              findRecentImage(data?.mother?.right_thumb?.files?.[0]?.files)
                ?._id || '',
            file:
              findRecentImage(data?.mother?.right_thumb?.files?.[0]?.files)
                ?.original_file_name || null,
            url: generateImageUrl(
              findRecentImage(data?.mother?.right_thumb?.files?.[0]?.files)
                ?.path,
            ),
            rejectMessage: null,
            identifier: data?.mother?.right_thumb?.files?.[0]?.identifier,
            isDragHovered: false,
            isUploadable: false,
          },
          left_thumb: {
            id:
              findRecentImage(data?.mother?.left_thumb?.files?.[0]?.files)
                ?._id || '',
            file:
              findRecentImage(data?.mother?.left_thumb?.files?.[0]?.files)
                ?.original_file_name || null,
            url: generateImageUrl(
              findRecentImage(data?.mother?.left_thumb?.files?.[0]?.files)
                ?.path,
            ),
            rejectMessage: null,
            identifier: data?.mother?.left_thumb?.files?.[0]?.identifier,
            isDragHovered: false,
            isUploadable: false,
          },
        },
      };
  }
};

module.exports = { mapToPayload, mapDataByKey };
