import Constant from '@/const';
const axios = require('axios');

const getPatientServiceByUnit = async params => {
  const response = await axios.get(
    Constant.apiUrl.concat('/patient/registration/unit'),
    { params },
  );

  return response;
};

export default getPatientServiceByUnit;
