import Constant from '@/const';
import axios from 'axios';

const getDistrict = async cityId => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/master/region/district?regency=${cityId}`),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getDistrict;
