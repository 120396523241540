<template>
  <v-card>
    <v-card-text class="pb-3 toolbar-padding">
      <v-toolbar flat>
        <template v-if="patientData !== null">
          <div>
            <span class="text__label">No RM</span>
            <span class="black--text font-weight-bold mx-6">{{
              patientData.rmNumber
            }}</span>
          </div>
          <div>
            <span class="text__label">Name</span>
            <span class="black--text font-weight-bold mx-6"
              >{{ patientData.meta.name }} ({{
                patientData.meta.gender ? 'L' : 'P'
              }})</span
            >
          </div>
          <div>
            <span class="text__label">Tanggal Lahir</span>
            <span class="black--text font-weight-bold mx-6"
              >{{ patientData.meta.birthDate }} ({{
                patientData.meta.age
              }}
              tahun)</span
            >
          </div>
        </template>

        <div class="col-2">
          <v-row>
            <v-col cols="5">
              <span class="font-weight-bold">No. Reg</span>
            </v-col>
            <v-col>
              <v-card
                flat
                tile
                class="red--text font-weight-bold"
                style="border: 2px solid #F65555;"
                >{{
                  patientData !== null ? patientData.triaseNumber : triaseNumber
                }}</v-card
              >
            </v-col>
          </v-row>
        </div>
        <v-spacer></v-spacer>
        <span class="black--text font-weight-bold headline"
          >Triase Instalasi Gawat Darurat</span
        >
      </v-toolbar>
      <v-main>
        <v-tabs grow center-active v-model="tab">
          <v-tab class="text-capitalize font-weight-bold">Level Triase</v-tab>
          <v-tab class="text-capitalize font-weight-bold"
            >Working Psychological Scoring System</v-tab
          >
          <v-tab class="text-capitalize font-weight-bold">Triase</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item transition="false">
            <div class="tab-item">
              <LevelTriase
                v-if="tab === 0"
                @close-form="$emit('close-form')"
                :triase-level="triaseLevel"
                :wpss="wpss"
                :triase="triase"
                :patientData="patientData"
                :triaseNumber="triaseNumber"
              />
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="tab-item">
              <WPSS
                v-if="tab === 1"
                @close-form="$emit('close-form')"
                :triase-level="triaseLevel"
                :wpss="wpss"
                :triase="triase"
                :patientData="patientData"
                :triaseNumber="triaseNumber"
              />
            </div>
          </v-tab-item>
          <v-tab-item eager>
            <div class="tab-item">
              <TriaseCard
                v-if="tab === 2"
                :triase-level="triaseLevel"
                :wpss="wpss"
                :triase="triase"
                :triaseNumber="triaseNumber"
                :patientData="patientData"
                @close-form="$emit('close-form')"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-main>
    </v-card-text>
  </v-card>
</template>

<script>
import LevelTriase from './Triase/LevelTriase';
import WPSS from './Triase/WPSS';
import TriaseCard from './Triase/TriaseCard';
import Constant from '@/const';

const axios = require('axios');

export default {
  name: 'Triase',
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  components: {
    LevelTriase,
    WPSS,
    TriaseCard,
  },
  data() {
    return {
      tab: null,
      triaseLevel: {
        name: '',
        gender: '',
        pic: '',
        address: '',
        parentsName: '',
        pacs: 1,
        awareness: 1,
        sistole: null,
        diastole: null,
        pulse: null,
        respiration: null,
        weight: null,
        temperature: null,
        saturation: null,
        pain: '',
        height: null,
      },
      wpss: {
        awareness: 0,
        blood_pressure_sistolic: 0,
        pulse: 0,
        temperature: 0,
        respiratory: 0,
        saturation: 0,
        note: '',
        decision: 'ruang resusitasi',
      },
      triase: {
        entry: 'brandcar',
        mounted: ['', ''],
        reason: {
          type: 'datang sendiri',
          info: '',
        },
        vehicle: '',
        agent: '',
        arrival_condition: [],
        trauma_mechanism: {
          individual_accident: {
            status: false,
            place: '',
            date: '',
            time: '',
          },
          accident_between: {
            status: false,
            vehicle1: '',
            vehicle2: '',
            place: '',
            date: '',
            time: '',
          },
          fall_trauma: {
            status: false,
            info: '',
          },
          burns_trauma: {
            status: false,
            info: '',
          },
          electric_trauma: {
            status: false,
            info: '',
          },
          substance_trauma: {
            status: false,
            info: '',
          },
          icd_10: null,
          primary_complaint: [''],
        },
      },
      triaseNumber: null,
    };
  },
  async mounted() {
    this.patientData === null
      ? await this.getTriaseNumber()
      : await this.getTriase();
  },
  methods: {
    async getTriaseNumber() {
      await axios
        .get(Constant.apiUrl.concat('/patient/igd/triase/nomortriase'))
        .then(response => {
          this.triaseNumber = response.data.triase_number;
        });
    },
    async getTriase() {
      await axios
        .get(
          Constant.apiUrl.concat(
            `/patient/igd/triase/${this.patientData.triaseNumber}`,
          ),
        )
        .then(response => {
          const [detail_triase] = response.data.data.detail_triase;
          this.triaseNumber = response.data.data.triase_number;
          this.triaseLevel = {
            ...detail_triase.triase_level,
            pic: detail_triase.triase_level.person_in_charge,
            parentsName: detail_triase.triase_level.parent_name,
            sistole: detail_triase.triase_level.blood_pressure_sistole,
            diastole: detail_triase.triase_level.blood_pressure_diastole,
            weight: detail_triase.triase_level.weight_body,
            height: detail_triase.triase_level.height_body,
            respiration: detail_triase.triase_level.respiratory,
          };
          this.wpss = detail_triase.wpss;
          this.triase = detail_triase.triase;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-item {
  min-height: 70vh;
  &::-webkit-scrollbar {
    width: 2px;
    background-color: #e5fff0;
  }
  &::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: #cdd2d7;
  }
}
.toolbar-padding {
  ::v-deep .v-toolbar__content {
    padding: 0px;
  }
}
</style>
