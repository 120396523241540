import { render, staticRenderFns } from "./PatientListSsc.vue?vue&type=template&id=6019a274&scoped=true"
import script from "./PatientListSsc.vue?vue&type=script&lang=js"
export * from "./PatientListSsc.vue?vue&type=script&lang=js"
import style0 from "./PatientListSsc.vue?vue&type=style&index=0&id=6019a274&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6019a274",
  null
  
)

export default component.exports