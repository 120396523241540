<template>
  <div>
    <div class="img-container" v-if="picture.url">
      <img
        :ref="stamp"
        :src="picture.url"
        style="cursor: pointer"
        :alt="`${identifier}-image`"
        class="image"
        @click="viewImage()"
      />
      <v-btn v-if="!isEMR" @click="deleteImage" text fab light small>
        <v-icon color="white">mdi-delete</v-icon>
      </v-btn>
    </div>
    <div
      v-else
      :class="
        `dropzone ${picture.isDragHovered && !isEMR ? 'dropable' : ''} ${
          isEMR ? 'disable' : ''
        }`
      "
      @drop.prevent="drop($event)"
      @dragenter.prevent="dragToggle()"
      @dragleave.prevent="dragToggle()"
      @dragover.prevent
      @click="addFile"
    >
      <v-icon
        v-if="picture.isDragHovered"
        size="60"
        color="#e0e0e0"
        class="up-animation"
        >mdi-arrow-up
      </v-icon>
      <v-icon size="100" color="#e0e0e0">mdi-image-outline</v-icon>
      <span
        >Seret foto anda disini atau klik untuk mencari. Maksimal 2 MB <br />
        (JPG, JPEG, PNG)</span
      >
      <input
        type="file"
        id="file"
        :ref="`file_${identifier}`"
        accept="image/png, image/jpeg"
        multiple
        v-show="false"
        v-on:change="handleFileUpload()"
      />
    </div>
    <span class="red--text" v-if="picture.rejectMessage"
      >{{ picture.rejectMessage }}
    </span>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations } = createNamespacedHelpers(
  'NeonatusAssessment',
);
export default {
  name: 'StampPictureUpload',
  props: {
    identifier: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    stamp: {
      type: String,
      default: '',
    },
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['getForm']),
    picture: {
      get() {
        return this.getForm.stamp[this.type][this.stamp];
      },
    },
  },
  methods: {
    ...mapMutations(['setStampPicture', 'setToBeDeletedFile']),
    drop(event) {
      if (this.isEMR) return;
      this.handleFileUpload(event.dataTransfer.files[0]);
      this.setStampPicture({
        type: this.type,
        stamp: this.stamp,
        payload: {
          isDragHovered: false,
        },
      });
    },
    addFile() {
      if (this.isEMR) return;
      this.$refs[`file_${this.identifier}`].click();
    },
    handleFileUpload(dropFile) {
      // evaluate if there is any file from drag and drop action
      if (dropFile) {
        this.evaluateFile(dropFile);
      } else {
        this.evaluateFile(this.$refs[`file_${this.identifier}`].files[0]);
      }
    },
    evaluateFile(file) {
      if (this.verify(file) !== 'ok') {
        this.setStampPicture({
          type: this.type,
          stamp: this.stamp,
          payload: {
            rejectMessage: this.verify(file),
          },
        });
      } else {
        this.setStampPicture({
          type: this.type,
          stamp: this.stamp,
          payload: {
            file,
            identifier: this.identifier,
            url: URL.createObjectURL(file),
            isUploadable: true,
            rejectMessage: '',
          },
        });
      }
    },
    verify(file) {
      if (!file) return;
      const maxSize = 2000000;
      let allowedTypes;
      allowedTypes = ['image/png', 'image/jpeg'];
      if (file?.size > maxSize) {
        return 'Foto lebih dari 2 MB. Silakan ulangi';
      }

      if (!allowedTypes.includes(file?.type)) {
        return 'Tipe file yang anda unggah tidak valid. Silakan ulangi';
      }

      return 'ok';
    },
    dragToggle() {
      this.setStampPicture({
        type: this.type,
        stamp: this.stamp,
        payload: {
          isDragHovered: !this.picture.isDragHovered,
        },
      });
    },
    viewImage() {
      this.$emit('view-image', this.picture.url);
    },
    deleteImage() {
      // if the picture is non uploadable which is already in database, then it needs to be deleted from database aswell
      if (!this.picture.isUploadable) {
        this.setToBeDeletedFile({
          identifier: this.picture.identifier,
          file_id: [this.picture.id],
          tag: this.picture.identifier,
        });
      }
      // empty the dropzone area
      this.setStampPicture({
        type: this.type,
        stamp: this.stamp,
        payload: {
          file: null,
          url: '',
          rejectMessage: null,
          identifier: '',
          isDragHovered: false,
          isUploadable: false,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-container {
  position: relative;
  width: fit-content;
  overflow: hidden;

  &:hover {
    button {
      right: 0.3vw;
      transition: 0.3s ease;
    }
  }

  .image {
    max-width: 100%; /* Scale the image to fit within the parent container's width */
    max-height: 60vh; /* Scale the image to fit within the parent container's height */
    width: auto; /* Reset the width to its natural size */
    height: auto;
    margin-top: 0.5vw;

    transition: filter 0.3s; /* Add transitions for filter */

    &:hover {
      filter: brightness(
        0.7
      ); /* Reduce brightness on hover (adjust as needed) */
    }
  }

  button {
    position: absolute;
    top: 1.5vh;
    right: -2vw;
    transition: 0.3s ease;
  }
}

.dropzone {
  border: 1px dashed #c2c2c2;
  background-color: #fafafa;
  margin-top: 0.5vw;
  text-align: center;
  border-radius: 0.2vw;
  width: 100%;
  height: 60vh;
  color: #757575;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &.dropable {
    background-color: #f0f9ff;
    border: 1px dashed #6ba6e1;
  }

  &.disable {
    cursor: no-drop;
  }

  .up-animation {
    z-index: 10;
    position: absolute;
    top: 38%;
    right: 40%;
    animation: upload 0.8s infinite ease-in-out;
  }
}

@keyframes upload {
  0% {
    top: 38%;
  }
  100% {
    top: 27%;
  }
}
</style>
