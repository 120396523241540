<template>
  <v-card class="main-card">
    <v-card-title class="header">
      <h2 class="header__text-title">Ganti Password</h2>
      <p class="header__text-note text-start">
        Password minimal 8 karakter. Terdiri dari huruf kapital, huruf kecil,
        dan angka
      </p>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" class="content text-start">
        <div>
          <label class="content__label">Masukkan Password Baru</label>
          <v-text-field
            :append-icon="showInput ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showInput ? 'text' : 'password'"
            persistent-placeholder
            @click:append="showInput = !showInput"
            v-model="input.password"
            :rules="rules.password"
          />
        </div>
        <div>
          <label class="content__label">Konfirmasi Password Baru</label>
          <v-text-field
            :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmation ? 'text' : 'password'"
            persistent-placeholder
            @click:append="showConfirmation = !showConfirmation"
            v-model="input.confirmation"
            :rules="rules.confirmation"
          />
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="red" class="text-none mr-2" @click="handleClose()"
        >Batal</v-btn
      >
      <v-btn
        color="primary"
        class="text-none"
        depressed
        :disabled="
          this.input.password === null && this.input.confirmation === null
        "
        @click="handleSave"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('MasterStaff');

export default {
  name: 'ChangePassword',
  props: {
    state: {
      type: String,
      required: true,
    },
    dataStaff: {
      type: Object,
    },
  },
  data() {
    return {
      showInput: false,
      showConfirmation: false,
      input: {
        password: null,
        confirmation: null,
      },
      rules: {
        password: [],
        confirmation: [],
      },
    };
  },
  watch: {
    'input.password'() {
      this.rules.password = [];
    },
    'input.confirmation'() {
      this.rules.confirmation = [];
    },
  },
  methods: {
    ...mapActions(['resolvePutChangePassword']),
    handleSave() {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
      this.rules = {
        password: [
          v => regex.test(v) || 'Password tidak sesuai dengan ketentuan',
        ],
        confirmation: [
          v =>
            v === this.input.password ||
            'Konfirmasi password tidak sama. Silahkan Ulangi',
        ],
      };
      if (
        this.input.confirmation === this.input.password &&
        regex.test(this.input.password)
      ) {
        if (this.$refs.form.validate()) {
          switch (this.state) {
            case 'dashboard':
              this.resolvePutChangePassword({
                id_staff: this.$store.getters.userLoggedIn.id,
                password: this.input.confirmation,
              }).finally(() => this.handleClose());
              break;
            case 'master':
              this.resolvePutChangePassword({
                id_staff: this.dataStaff.id,
                password: this.input.confirmation,
              }).finally(() => this.handleClose());
              break;
          }
        }
      }
    },
    handleClose() {
      this.$emit('close-dialog');
      this.input = {
        password: null,
        confirmation: null,
      };
      (this.rules = {
        password: [],
        confirmation: [],
      }),
        this.$refs.form.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  border-radius: 8px;
  background: #fff;
  .header {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    &__text-title {
      color: #404040;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
    }
    &__text-note {
      color: #404040;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
  .content {
    &__label {
      color: #616161;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
</style>
