<template>
  <v-card class="main">
    <v-card-text class="p-0">
      <v-row class="content-wrap">
        <v-col
          cols="6"
          style="background-color: #137bc0;"
          class="content-wrap__old-patient"
        >
          <span class="content-wrap__old-patient__title">Pasien Lama</span>
          <p class="content-wrap__old-patient__desc">
            Pasien yang sudah pernah berobat ke Klinik
          </p>
          <v-btn
            class="text-capitalize content-wrap__old-patient__button"
            color="white"
            depressed
            @click="clickAlreadyRegist"
            >Pilih</v-btn
          >
        </v-col>
        <v-col
          cols="6"
          style="background-color: #ffffff;"
          class="content-wrap__new-patient"
        >
          <span class="content-wrap__old-patient__title">Pasien Baru</span>
          <p class="content-wrap__old-patient__desc">
            Pasien yang belum pernah berobat ke Klinik
          </p>
          <v-btn
            class="text-capitalize content-wrap__new-patient__button"
            color="primary"
            depressed
            outlined
            @click="clickRegist"
            >Daftar</v-btn
          >
        </v-col>
        <v-icon class="content-wrap__close-button" @click="closeDialog">
          mdi-close
        </v-icon>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'OldNewDialogV2',
  methods: {
    closeDialog() {
      this.$emit('close-form');
    },
    clickRegist() {
      this.$emit('on-clicked', null);
    },
    clickAlreadyRegist() {
      this.$emit('on-clicked', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  font-family: 'Roboto';
  overflow: hidden;
  .content-wrap {
    height: 400px;
    &__old-patient,
    &__new-patient {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 0 2.4rem;
      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 16px;
      }
      &__desc {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
      &__button {
        width: 100%;
      }
    }

    &__old-patient {
      color: #ffffff;
      &__button {
        width: 100%;
        background-color: #ffffff;
        color: #137bc0;
      }
    }

    &__new-patient {
      color: #404040;
    }

    &__close-button {
      margin: 10px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.v-card__text {
  padding: 0 !important;
}
</style>
