import Constant from '@/const';
import axios from 'axios';

const postGuarantorChange = async (idBilling, payload) => {
  try {
    const response = await axios.put(
      Constant.apiUrl.concat(`/billing/guarantor/${idBilling}`),
      payload,
    );
    return response;
  } catch (error) {
    const err = error;
    return err;
  }
};

export default postGuarantorChange;
