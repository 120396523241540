import store from '@/store';
import Constant from '@/const';

const axios = require('axios');
const axiosClient = axios.create();

axiosClient.interceptors.request.use(
  async config => {
    const newConfig = {
      ...config,
      baseURL: Constant.apiUrl,
      headers: {
        Authorization: `Bearer ${store?.getters?.token}`,
        userapps: store.getters?.userLoggedIn?.name,
        clientapps: store.getters?.userLoggedIn?.clinic?.name,
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    return newConfig;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    let res = error.response;
    if (res.status == 401) {
      window.location.href = `${window.location.origin}/login`;
    }
    return Promise.reject(error);
  },
);

export default axiosClient;
