<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters class="px-5 py-3">
      <v-row no-gutters align="center">
        <v-col cols="9" class="text-left">
          <h2>Laporan Arus Kas</h2>
        </v-col>
        <v-col cols="1" class="text-right">
          <router-link to="/keuangan/akuntansi">
            <v-btn icon><v-icon>mdi-home</v-icon></v-btn>
          </router-link>
        </v-col>
        <v-col class="" cols="1">
          <v-btn depressed color="primary" class="text-none" small
            >Export</v-btn
          >
        </v-col>
        <v-col cols="1" class="text-left pl-3">
          <v-btn icon><v-icon>mdi-refresh</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters align="end" style="min-height: 10vh;">
        <v-col class="d-flex pb-3" cols="4">
          <v-btn
            depressed
            outlined
            @click="report = 'standard'"
            :color="report === 'standard' ? 'primary' : 'grey'"
            class="text-none font-weight-bold"
            >Standar</v-btn
          >
          <v-btn
            depressed
            outlined
            @click="report = 'comparative'"
            :color="report === 'comparative' ? 'primary' : 'grey'"
            class="text-none font-weight-bold"
            >Komparatif</v-btn
          >
        </v-col>

        <div class="filter-style" v-if="report === 'standard'">
          <v-col class="text-left pa-0">
            <label class="font-weight-bold body-2">Jenis Laporan</label>
          </v-col>
          <v-col class="pa-0">
            <v-select
              dense
              placeholder="Pilih Jenis Laporan"
              single-line
              :items="resource.reportTypeStandard"
              v-model="filter.reportTypeStandard"
            />
          </v-col>

          <template v-if="filter.reportTypeStandard === 'Custom'">
            <v-col class="text-right py-0">
              <label class="font-weight-bold body-2">Awal</label>
            </v-col>
            <v-col class="pa-0">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                nudge-left="55"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    placeholder="Pilih Tanggal Awal"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-chevron-down"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :max="today"
                  @input="
                    $refs.menu.save(startDate);
                    menu = false;
                  "
                />
              </v-menu>
            </v-col>
            <v-col class="text-right py-0">
              <label class="font-weight-bold body-2">Akhir</label>
            </v-col>
            <v-col class="pa-0">
              <v-menu
                ref="menuEnd"
                v-model="menuEnd"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
                offset-y
                nudge-left="150"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    placeholder="Pilih Tanggal Akhir"
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-chevron-down"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  :max="today"
                  @input="
                    $refs.menuEnd.save(endDate);
                    menuEnd = false;
                  "
                />
              </v-menu>
            </v-col>
          </template>
          <template v-else>
            <v-col class="text-right py-0">
              <label class="font-weight-bold body-2">Tahun</label>
            </v-col>
            <v-col class="pa-0">
              <v-select
                dense
                placeholder="Pilih Tahun"
                hide-details="false"
                single-line
                :items="resource.yearItems"
                v-model="filter.yearStandard"
              />
            </v-col>
            <v-col class="text-right py-0">
              <label class="font-weight-bold body-2">Periode</label>
            </v-col>
            <v-col class="pa-0">
              <v-radio-group
                row
                v-if="filter.reportTypeStandard === 'Semester'"
                hide-details="false"
              >
                <v-radio label="H1"></v-radio>
                <v-radio label="H2"></v-radio>
              </v-radio-group>
              <v-select
                v-else
                dense
                placeholder="Pilih Periode"
                hide-details="false"
                single-line
                v-model="filter.periodStandard"
                :items="resource.periodStandard"
                :readonly="filter.reportTypeStandard === 'Tahunan'"
              />
            </v-col>
          </template>
        </div>
        <div class="filter-style" v-else>
          <v-col class="text-left pa-0">
            <label class="font-weight-bold body-2">Jenis Laporan</label>
          </v-col>
          <v-col class="pa-0">
            <v-select
              dense
              placeholder="Pilih Jenis Laporan"
              hide-details="false"
              single-line
              :items="resource.reportTypeComp"
              v-model="filter.reportTypeComp"
            />
          </v-col>

          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>

          <v-col class="text-right py-0">
            <label class="font-weight-bold body-2">Tahun Awal</label>
          </v-col>
          <v-col class="pa-0">
            <v-select
              dense
              placeholder="Pilih Tahun"
              hide-details="false"
              single-line
              :items="resource.yearItems"
              v-model="filter.yearStart"
            />
          </v-col>

          <v-col class="text-right py-0">
            <label class="font-weight-bold body-2">Tahun Akhir</label>
          </v-col>
          <v-col class="pa-0">
            <v-select
              dense
              placeholder="Pilih Tahun"
              hide-details="false"
              single-line
              :items="resource.yearItems"
              v-model="filter.yearEnd"
            />
          </v-col>

          <v-col class="text-right py-0">
            <label class="font-weight-bold body-2">Periode</label>
          </v-col>
          <v-col class="pa-0" v-if="filter.reportTypeComp === 'Tahunan'">
            <v-select
              placeholder="Pilih periode"
              dense
              v-model="filter.periodComp"
              hide-details="false"
              :items="resource.yearItems"
              multiple
            />
          </v-col>
          <v-col class="pa-0" v-else>
            <v-menu
              transition="scale-transition"
              min-width="auto"
              :close-on-content-click="false"
              offset-y
              nudge-left="60"
            >
              <template v-slot:activator="{ on }">
                <!-- <v-textarea
                  append-icon="mdi-chevron-down"
                  placeholder="Pilih periode"
                  readonly
                  v-on="on"
                  auto-grow
                  rows="1"
                  dense
                  v-model="filter.periodComp"
                  :rules="rules"
                ></v-textarea> -->
                <v-combobox
                  placeholder="Pilih periode"
                  readonly
                  v-on="on"
                  dense
                  v-model="filter.periodComp"
                  :rules="rules"
                />
              </template>
              <v-card>
                <v-card-text>
                  <v-treeview
                    v-model="filter.periodComp"
                    :items="resource.periodComp"
                    selectable
                    item-disabled="locked"
                  >
                    <template v-slot></template>
                  </v-treeview>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
        </div>
      </v-row>
    </v-row>
    <v-divider></v-divider>

    <div v-if="report === 'standard'" class="px-5 body-2">
      <v-row no-gutters class="text-center d-flex flex-column py-3">
        <h3>Nama Klinik</h3>
        <h3>LAPORAN ARUS KAS</h3>
        <label class="body-2"
          >(Periode {{ filter.periodStandard }}
          {{ filter.yearStandard }})</label
        >
      </v-row>
      <Standard />
    </div>

    <div v-else class="pa-5 body-2">
      <label class="grey--text" v-if="filter.periodComp.length === 0"
        >Masukkan data filter terlebih dahulu</label
      >
      <div v-else>
        <v-row no-gutters class="text-center d-flex flex-column pb-3">
          <h3>Nama Klinik</h3>
          <h3>LAPORAN ARUS KAS</h3>
          <label class="body-2"
            >(Periode tahun {{ filter.yearStart }} -
            {{ filter.yearEnd }})</label
          >
        </v-row>
        <Comparative />
      </div>
    </div>
  </v-container>
</template>

<script>
import Standard from './CashFlow/Standard.vue';
import Comparative from './CashFlow/Comparative.vue';
import moment from 'moment-timezone';

const _ = require('lodash');

export default {
  name: 'CashFLow',
  components: {
    Standard,
    Comparative,
  },
  data() {
    return {
      report: 'standard',
      resource: {
        reportTypeStandard: [
          'Bulanan',
          'Triwulan',
          'Semester',
          'Tahunan',
          'Custom',
        ],
        reportTypeComp: ['Bulanan', 'Triwulan', 'Semester', 'Tahunan'],
        monthNames: [
          'Januari',
          'Februari',
          'Maret',
          'April',
          'Mei',
          'Juni',
          'Juli',
          'Agustus',
          'September',
          'Oktober',
          'November',
          'Desember',
        ],
        monthComp: [
          {
            name: 2021,
            id: 1,
            children: [
              { name: 'Januari', id: 'Januari 2021', locked: false },
              { name: 'Februari', id: 'Februari 2021', locked: false },
              { name: 'Maret', id: 'Maret 2021', locked: false },
              { name: 'April', id: 'April 2021', locked: false },
              { name: 'Mei', id: 'Mei 2021', locked: false },
              { name: 'Juni', id: 'Juni 2021', locked: false },
              { name: 'Juli', id: 'Juli 2021', locked: false },
              { name: 'Agustus', id: 'Agustus 2021', locked: false },
              { name: 'September', id: 'September 2021', locked: false },
              { name: 'Oktober', id: 'Oktober 2021', locked: false },
              { name: 'November', id: 'November 2021', locked: false },
              { name: 'Desember', id: 'Desember 2021', locked: false },
            ],
          },
        ],
        quarterComp: [
          {
            name: 2021,
            id: 1,
            children: [
              { name: 'Q1', id: 'Q1 2021', locked: false },
              { name: 'Q2', id: 'Q2 2021', locked: false },
              { name: 'Q3', id: 'Q3 2021', locked: false },
              { name: 'Q4', id: 'Q4 2021', locked: false },
            ],
          },
        ],
        semesterComp: [
          {
            name: 2021,
            id: 1,
            children: [
              { name: 'H1', id: 'H1 2021', locked: false },
              { name: 'H2', id: 'H2 2021', locked: false },
            ],
          },
        ],
        yearItems: _.range(new Date().getFullYear(), 2019, -1),
        periodStandard: [],
        periodComp: null,
        quarter: ['Q1', 'Q2', 'Q3', 'Q4'],
      },
      selection: [],
      filter: {
        reportTypeStandard: '',
        reportTypeComp: '',
        yearStandard: null,
        yearStart: null,
        yearEnd: null,
        periodStandard: '',
        periodComp: [],
      },
      menu: false,
      menuEnd: false,
      startDate: null,
      endDate: null,
      rules: [v => v.length !== 6 || 'Maksimal 6 Periode'],
      today: moment(new Date()).format('YYYY-MM-DD'),
    };
  },
  watch: {
    'filter.reportTypeStandard': {
      handler: function(val) {
        switch (val) {
          case 'Bulanan':
            this.resource.periodStandard = this.resource.monthNames;
            break;
          case 'Triwulan':
            this.resource.periodStandard = this.resource.quarter;
            break;
          // case 'Semester':
          //   this.resource.period = this.resource.semester;
          //   break;
          // case 'Tahunan':
          //   this.resource.period = this.yearItems;
          //   break;
          default:
            break;
        }
      },
    },
    'filter.reportTypeComp': {
      handler: function(val) {
        switch (val) {
          case 'Bulanan':
            this.resource.periodComp = this.resource.monthComp;
            break;
          case 'Triwulan':
            this.resource.periodComp = this.resource.quarterComp;
            break;
          case 'Semester':
            this.resource.periodComp = this.resource.semesterComp;
            break;
          default:
            break;
        }
      },
    },
    'filter.periodComp'(val) {
      var variable = '';
      switch (this.filter.reportTypeComp) {
        case 'Bulanan':
          variable = 'monthComp';
          break;
        case 'Triwulan':
          variable = 'quarterComp';
          break;
        case 'Semester':
          variable = 'semesterComp';
          break;
        default:
          break;
      }

      if (val.length === 6) {
        this.resource[variable].forEach((year, index) => {
          year.children.forEach((month, idx) => {
            if (this.filter.periodComp.find(x => x === month.id)) {
              this.resource[variable][index].children[idx].locked = false;
            } else {
              this.resource[variable][index].children[idx].locked = true;
            }
          });
        });
      } else {
        this.resource[variable].forEach((year, index) => {
          year.children.forEach((x, idx) => {
            this.resource[variable][index].children[idx].locked = false;
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-style {
  display: grid;
  grid-template-columns: repeat(2, 8vw 10vw) 5vw 10vw;
  // grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: baseline;
}
::v-deep .v-treeview-node__root {
  min-height: 30px;
}

::v-deep .v-treeview-node__root .v-treeview-node__checkbox {
  display: none !important;
}

::v-deep .v-treeview-node__children .v-treeview-node__checkbox {
  display: unset !important;
  margin-left: 0px;
}

::v-deep .v-treeview-node__label {
  text-align: left;
}

::v-deep .v-textarea textarea {
  min-height: 26px !important;
}
</style>
