import Constant from '@/const';
import axios from 'axios';

const getAllUnit = async ({
  halaman = 1,
  itemCount = -1,
  sortBy = '',
  keyword = '',
  installation = '',
} = {}) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/master/unit?installation=${installation}&page=${halaman}&itemCount=${itemCount}&sort=${sortBy}&search=${keyword}`,
      ),
    );
    return response.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getAllUnit;
