var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"0px !important","position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","right":"2.6rem","z-index":"10","top":"-1.5rem"}},[_c('div',{staticClass:"d-flex justify-end"},[(_vm.loading)?_c('span',{staticClass:"success--text loading-text"},[_vm._v("Menyimpan"),_c('span',[_vm._v(".")]),_c('span',[_vm._v(".")]),_c('span',[_vm._v(".")])]):_vm._e(),(_vm.alertMessage.show)?_c('span',{class:_vm.alertMessage.isFail ? 'error--text' : 'success--text'},[_vm._v(" Form "+_vm._s(_vm.alertMessage.text.toLowerCase())+" "+_vm._s(_vm.alertMessage.isFail ? 'gagal' : 'berhasil')+" disimpan ")]):_vm._e()])]),_c('div',{staticClass:"mx-4 mt-2"},[_c('v-tabs',{staticClass:"mx-auto",staticStyle:{"border-bottom":"2px solid #f5f5f5","width":"95%"},attrs:{"grow":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.tabsResource),function(tab,index){return _c('v-tab',{key:`tab-${index}`,staticClass:"text-none"},[_vm._v(_vm._s(tab?.text)+" "),(_vm.isEMR ? tab.icon === 'mdi-check-circle' : true)?[(tab.icon)?_c('v-icon',{staticClass:"mb-4",attrs:{"color":_vm.generateBadgeColor(tab?.icon, index),"small":""}},[_vm._v(" "+_vm._s(tab?.icon)+" ")]):_vm._e()]:_vm._e()],2)}),1),_c('v-tabs-items',{staticClass:"scroll",model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',[_c('section',[_c('pregnancy-history',{ref:"pregnancyHistory",attrs:{"isEMR":_vm.isEMR}})],1)]),_c('v-tab-item',[_c('section',[_c('kb-and-menstrual-history',{ref:"kbAndMenstrualHistory",attrs:{"isEMR":_vm.isEMR}})],1)]),_c('v-tab-item',[_c('section',[_c('postpartum-record',{ref:"postpartumRecord",attrs:{"isEMR":_vm.isEMR}})],1)])],1)],1),_c('Snackbar',{attrs:{"show":_vm.snackbar.show,"color":_vm.snackbar.color,"text":_vm.snackbar.text,"close":() => {
        _vm.snackbar = {
          show: false,
          color: 'success',
          text: '',
        };
      }}}),_c('v-footer',{staticClass:"d-flex justify-space-between",staticStyle:{"background-color":"white","border-top":"1px solid #e0e0e0"}},[_c('div',{staticClass:"d-flex mx-6 mt-2"},[_c('v-btn',{staticClass:"mr-3 nav-btn",attrs:{"disabled":_vm.tabs === 0,"color":"primary","depressed":"","light":"","outlined":""},on:{"click":function($event){return _vm.updateTab({ destination: _vm.tabs - 1 })}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{staticClass:"nav-btn",attrs:{"disabled":_vm.tabs === 2,"color":"primary","depressed":"","light":""},on:{"click":function($event){return _vm.updateTab({ destination: _vm.tabs + 1 })}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1),_c('v-spacer'),(_vm.tabs === 2 && !_vm.isEMR)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","depressed":"","light":""},on:{"click":function($event){return _vm.handleSave()}}},[_vm._v(" "+_vm._s(_vm.isNew ? 'Simpan' : 'Simpan Perubahan')+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }