import { render, staticRenderFns } from "./ProlanisBPJSReport.vue?vue&type=template&id=5e66fd44&scoped=true"
import script from "./ProlanisBPJSReport.vue?vue&type=script&lang=js"
export * from "./ProlanisBPJSReport.vue?vue&type=script&lang=js"
import style0 from "./ProlanisBPJSReport.vue?vue&type=style&index=0&id=5e66fd44&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e66fd44",
  null
  
)

export default component.exports