import getDrugUsageReport from '../../../fetchApi/Report/getDrugUsageReport';

const state = {
  dataChart: [],
  seriesChart: [],
  labelChart: [],
};

const getters = {
  getDataChart: state => state.dataChart,
  getSeriesChart: state => state.seriesChart,
  getLabelChart: state => state.labelChart,
};

const mutations = {
  setDataChart(state, payload) {
    state.dataChart = payload;
  },
  setSeriesChart(state, payload) {
    state.seriesChart = payload;
  },
  setLabelChart(state, payload) {
    state.labelChart = payload;
  },
};

const actions = {
  async resolveGetReport({ commit, dispatch }, { startDate, endDate, type }) {
    try {
      const { data } = await getDrugUsageReport({
        startDate,
        endDate,
        type,
      });

      dispatch('mapDataToChart', data.data);
      commit('setDataChart', data.data);
    } catch (error) {
      commit('setDataChart', []);
      dispatch('mapDataToChart', []);
      return error;
    }
  },

  mapDataToChart({ commit }, data) {
    const series = [];
    const label = [];
    for (const item of data) {
      series.push(item.quantity);
      label.push(item.name);
    }

    commit('setSeriesChart', series);
    commit('setLabelChart', label);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
