import Constant from '@/const';
import axios from 'axios';

const getRegistrationById = async idRegistration => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/patient/emr/social/${idRegistration}`),
    );
    return response.data.data;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getRegistrationById;
