<template>
  <v-snackbar
    absolute
    light
    content-class="font-weight-medium pr-0"
    :value="show"
    :color="color"
    :height="15"
    timeout="4100"
    style="bottom: 2rem !important;"
  >
    <div class="d-flex justify-space-between align-center">
      <span style="color: white">
        {{ text }}
      </span>
      <v-btn style="color: white" text fab small right @click="close"
        ><v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
