<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-toolbar flat outlined height="158">
        <v-row no-gutters align="center">
          <v-col cols="6" class="text-left">
            <h2>Buku Besar</h2>
          </v-col>
          <v-col cols="1" class="text-right">
            <router-link to="/keuangan/akuntansi">
              <v-btn icon><v-icon>mdi-home</v-icon></v-btn>
            </router-link>
          </v-col>
          <v-col class="" cols="1">
            <v-btn depressed color="primary" class="text-none">Export</v-btn>
          </v-col>
          <v-col cols="3" class="pl-5">
            <v-text-field
              background-color="grey lighten-3"
              placeholder="Search"
              solo
              dense
              hide-details
              flat
              prepend-inner-icon="mdi-magnify"
              single-line
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="text-left pl-3">
            <v-btn icon><v-icon>mdi-refresh</v-icon></v-btn>
          </v-col>

          <v-col cols="1" class="text-left">
            <label class="font-weight-bold body-2">Kode Akun</label>
          </v-col>
          <v-col cols="2">
            <v-select
              outlined
              dense
              placeholder="Pilih Kode Akun"
              hide-details="false"
              single-line
              v-model="filter.accountCode"
              :items="resource.accountCode"
              item-text="account_code"
              @change="getLedger"
              :loading="isLoading"
              return-object
            />
          </v-col>

          <v-col cols="1" class="text-left ml-5">
            <label class="font-weight-bold body-2">Nama Akun</label>
          </v-col>
          <v-col cols="2">
            <v-select
              outlined
              dense
              placeholder="Pilih Nama Akun"
              hide-details="false"
              single-line
              :items="resource.accountCode"
              v-model="filter.accountCode"
              item-text="name"
              @change="getLedger"
              :loading="isLoading"
              return-object
            />
          </v-col>

          <v-col cols="4"></v-col>
          <v-col cols="7" class="text-left pt-2">
            <div v-if="filter.accountCode !== ''">
              <v-chip close>{{ filter.accountCode.name }}</v-chip>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  placeholder="Pilih Tanggal"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="false"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="
                  $refs.menu.save(date);
                  menu = false;
                "
              />
            </v-menu>
          </v-col>

          <v-icon>mdi-minus</v-icon>

          <v-col cols="2">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="endDate"
              transition="scale-transition"
              offset-y
              nudge-left="55"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  placeholder="Pilih Tanggal"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="false"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="
                  $refs.menu2.save(endDate);
                  menu2 = false;
                "
              />
            </v-menu>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-row>
    <v-data-table
      hide-default-footer
      fixed-header
      :items-per-page="table.items.length"
      height="340"
      :headers="table.headers"
      :items="table.items"
    >
      <template v-slot:no-data>
        Masukkan Kode Akun dan Nama Akun
      </template>
      <template v-slot:[`item.no`]="{ item }">
        <td>{{ table.items.indexOf(item) + 1 }}</td>
      </template>
      <template v-slot:footer>
        <div class="footer-total">
          <label class="text-right font-weight-bold px-5">TOTAL</label>
          <label class="font-weight-bold">{{
            formattedCurrency(totalDebit)
          }}</label>
          <label class="font-weight-bold">{{
            formattedCurrency(totalCredit)
          }}</label>
          <label></label>
        </div>
        <div class="footer-balance py-2">
          <label></label>
          <label class="font-weight-bold">Saldo Akhir Periode</label>
          <label class="font-weight-bold">0,00</label>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import formatMixin from '@/mixin/formatMixin';

const axios = require('axios');

export default {
  name: 'LedgerDashboard',
  mixins: [formatMixin],
  data() {
    return {
      menu: false,
      menu2: false,
      date: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      table: {
        headers: [
          { text: 'No', value: 'no', width: '6%' },
          { text: 'Tanggal', value: 'displayDate', width: '10%' },
          { text: 'Nomor Bukti', value: 'invNumber', width: '15%' },
          { text: 'Nomor Jurnal', value: 'journalNumber', width: '14%' },
          { text: 'Uraian', value: 'description', width: '20%' },
          { text: 'Debit', value: 'debit', width: '13%' },
          { text: 'Kredit', value: 'credit', width: '13%' },
          { text: 'Saldo', value: 'balance' },
        ],
        items: [],
      },
      resource: {
        accountCode: [],
      },
      filter: {
        accountCode: '',
        startDate: null,
        endDate: null,
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getAccount();
  },
  computed: {
    totalDebit() {
      return this.table.items.reduce((a, { debit }) => a + debit, 0);
    },
    totalCredit() {
      return this.table.items.reduce((a, { credit }) => a + credit, 0);
    },
  },
  methods: {
    getLedger() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/finance/journal?target=ledger&code=${this.filter.accountCode.account_code}&name=${this.filter.accountCode.name}&startDate=${this.date}&endDate=${this.endDate}`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          this.table.items = data.map(arr => {
            return {
              date: arr.date,
              displayDate: moment(arr.date).format('DD/MM/YYYY'),
              invNumber: arr.proof_code,
              journalNumber: arr.journal_code,
              description: arr.description,
              debit: arr.entries.debit,
              credit: arr.entries.credit,
              balance: 0,
            };
          });
        });
    },
    getAccount() {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat('/finance/ledger/account?&target=selection'),
        )
        .then(response => {
          const { data } = response.data;
          this.resource.accountCode = data.filter(x => x.meta.level === 5);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table__wrapper {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
    background-color: #a3a3a3;
  }
  &::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: black;
  }
}
.footer-total {
  display: grid;
  grid-template-columns: 5fr 1fr 1fr 1fr;
  height: 6vh;
  background-color: #d9edff;
  align-content: center;
  font-size: 0.9rem;
}

.footer-balance {
  display: grid;
  grid-template-columns: 6fr 2fr 1fr;
  font-size: 0.9rem;
}
</style>
