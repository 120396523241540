// import Constant from '@/const';
// import axios from 'axios';

import axiosClient from '../../apiClient';

const getAllDrugs = async ({
  halaman = 1,
  itemCount = 10,
  sortBy = '',
  keyword = '',
  category = '',
} = {}) => {
  return await axiosClient.get(
    `/master/drugs?page=${halaman}&itemCount=${itemCount}&sort=${sortBy}&search=${keyword}&category=${category}`,
  );
};

export default getAllDrugs;
