import Constant from '@/const';
import axios from 'axios';

const getLastCheckupData = idEmr => {
  try {
    const response = axios.get(
      Constant.apiUrl.concat(`/patient/emr/checkup/last/${idEmr}`),
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getLastCheckupData;
