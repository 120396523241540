import Constant from '@/const';
import axios from 'axios';

const postBed = async payload => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat(`/master/bed/`),
      payload,
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default postBed;
