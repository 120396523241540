<template>
  <div class="inventory">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Tarif Layanan
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            color="grey"
            icon
            text
            @click="refreshAndAutoUpdate"
          >
            <v-icon>mdi-autorenew </v-icon>
          </v-btn>
          <v-text-field
            class="input"
            v-model="search"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
          <v-btn
            medium
            color="primary"
            @click="exportMD()"
            class="ml-3 pa-3 font-weight-medium text-none export-button"
            :loading="isLoadingExport"
          >
            <v-icon small class="mr-1">mdi-download</v-icon>
            Unduh
          </v-btn>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-container fluid>
        <v-col class="pa-0">
          <v-data-table
            class="flex-grow-1"
            :headers="table.headers"
            :items="items.dataServices.data"
            :loading="items.dataServices.isLoading"
            :items-per-page="itemsPerRow"
            @click:row="
              (item, slot) => {
                selectItem(item);
              }
            "
            hide-default-footer
          >
            <template v-slot:item="{ item, index }">
              <tr class="text-left" :key="index">
                <td>
                  {{ (table.pagination.page - 1) * itemsPerRow + index + 1 }}
                </td>
                <td>{{ item.servicesName }}</td>
                <td>{{ item.subCategories }}</td>
                <td>{{ item.category }}</td>
                <!-- <td>{{ item.unit.name }}</td> -->
                <td class="text-uppercase">{{ item.assurance }}</td>
                <td>Rp. {{ formatNumber(item.total) }}</td>
                <td>
                  <v-switch
                    v-model="item.status"
                    :label="`${item.status ? 'Aktif' : 'Nonaktif'}`"
                    color="info"
                    @click="
                      handleChangeStatus(item.id, item.status, item.assurance)
                    "
                    inset
                    dense
                  ></v-switch>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        class="mx-2 hover"
                        v-bind="attrs"
                        v-on="on"
                        @click="handleOpenDialog('detail', item)"
                      >
                        <v-icon>mdi-clipboard-text</v-icon>
                      </v-btn>
                    </template>
                    <span>Detail Layanan</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        class="mx-2 hover"
                        v-bind="attrs"
                        v-on="on"
                        @click="handleOpenDialog('edit', item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Ubah Layanan</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        class="mx-2 hover"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small @click="handleDeleteServices(item)">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Hapus Layanan</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-container>
    </v-main>

    <Snackbar
      :show="snackbar.show"
      :color="snackbar.color"
      :text="snackbar.text"
      :close="
        () => {
          clearSnackbar();
        }
      "
    />

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="servicesPages"
            :total-visible="10"
            @input="handleGetAllServices()"
          ></v-pagination>
          <v-spacer></v-spacer>
          <v-btn color="primary" fab dark @click="handleOpenDialog('add')">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-row>

        <v-dialog v-model="dialog" max-width="1400px" persistent fullscreen>
          <FormServices
            v-if="dialog"
            :state="state"
            :assurance="assurance"
            @close-dialog="handleCloseDialog"
          />
        </v-dialog>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import FormServices from './components/Services/FormServices.vue';
import jwtMixin from '@/mixin/jwtMixin';
import AlertMixin from '@/mixin/alertMixin';
import { createNamespacedHelpers } from 'vuex';
import { Snackbar } from '@/components/SharedComponent';
import Constant from '@/const';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'MasterServices',
);

const _ = require('lodash');
const axios = require('axios');

export default {
  name: 'MasterdataService',
  mixins: [jwtMixin, AlertMixin],
  components: {
    FormServices,
    Snackbar,
  },

  data() {
    return {
      isLoadingExport: false,
      state: 'add',
      assurance: '',
      servicesPages: 0,
      table: {
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 7,
          totalItems: null,
        },
        headers: [
          { text: 'No.', value: 'noQueue', width: '7%' },
          { text: 'Nama Layanan', value: 'servicesName' },
          { text: 'Layanan Induk', value: 'subCategories' },
          { text: 'Kategori', value: 'category' },
          { text: 'Tipe Penjamin', value: 'assurance', width: '12%' },
          { text: 'Biaya', value: 'total', width: '10%' },
          { text: 'Status', value: 'status', width: '10%' },
          { text: '', value: 'actions', sortable: false, width: '13%' },
        ],
      },
      search: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
  },
  computed: {
    ...mapGetters(['getItems', 'getDialog', 'getSnackbar']),

    items: {
      get() {
        return this.getItems;
      },
    },

    dialog: {
      get() {
        return this.getDialog;
      },
      set(val) {
        return this.setDialog(val);
      },
    },

    snackbar: {
      get() {
        return this.getSnackbar;
      },
    },

    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    ...mapMutations([
      'setDialog',
      'setFilter',
      'clearForm',
      'setForm',
      'setSnackbar',
      'clearSnackbar',
    ]),
    ...mapActions([
      'resolveGetAllServices',
      'resolveDeleteServices',
      'resolvePutStatusServices',
    ]),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.handleGetAllServices();
    }, 650),

    handleOpenDialog(state, data) {
      this.state = state;
      this.assurance = data?.assurance;
      state !== 'add' && this.setForm({ ...this.form, id: data.id });
      this.setDialog(true);
    },

    handleCloseDialog() {
      this.clearForm();
      this.setDialog(false);
    },

    async exportMD() {
      try {
        this.isLoadingExport = true;
        const res = await axios.get(
          Constant.apiUrl.concat(`/master/service/export`),
        );
        if (res.status === 200) {
          const { url } = res.config;
          window.open(url, '_blank');
          this.setSnackbar({
            show: true,
            color: 'success',
            text: 'Dokumen berhasil diunduh',
          });
        }
        this.isLoadingExport = false;
      } catch (error) {
        if (error) {
          this.setSnackbar({
            show: true,
            color: 'error',
            text: 'Terjadi kesalahan',
          });
        }
        this.isLoadingExport = false;
      }
    },

    handleDeleteServices(data) {
      Swal.fire({
        title: 'Apakah Anda Yakin?',
        text: `Anda akan menghapus Data ${data.servicesName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(async result => {
        if (result.value) {
          try {
            const payload = {
              id: data.id,
              assurance: data.assurance,
            };
            await this.resolveDeleteServices(payload);
            Swal.fire(
              'Berhasil',
              `Data Layanan ${data.servicesName} Berhasil Dihapus`,
              'success',
            );
            this.handleGetAllServices();
          } catch {
            Swal.fire({
              title:
                '<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 25px; line-height: 30px;"><p>Data Ini Tidak Dapat Dihapus</p></div>',
              icon: 'error',
              html:
                '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 16px; color: #616161; line-height: 150%; text-transform: none;"><p>Anda tidak dapat menghapus data layanan</p><p>terkait administrasi pasien</p></div>',
              confirmButtonText: 'Kembali',
              showCancelButton: false,
            });
          }
        }
      });
    },

    handleChangeStatus(id, status, assurance) {
      this.resolvePutStatusServices({
        id: id,
        isActive: status,
        assurance: assurance,
      });
    },

    handleGetAllServices() {
      const itemCount = this.itemsPerRow;
      const halaman = this.table.pagination.page;
      let sortBy = '';
      const keyword = this.search;

      this.setFilter({
        halaman,
        itemCount,
        sortBy,
        keyword,
      });

      this.resolveGetAllServices({
        halaman,
        itemCount,
        sortBy,
        keyword,
      }).then(item => {
        this.servicesPages = Math.ceil(item / itemCount);
      });
    },

    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.handleGetAllServices();
      this.interval = setInterval(this.handleGetAllServices, 60000);
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
  },
  beforeDestroy() {
    this.clearSnackbar();
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 20%;
}

.hover:hover {
  color: #2196f3;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
