import Constant from '@/const';
import axios from 'axios';

const getIncomeData = () => {
  try {
    const response = axios.get(
      Constant.apiUrl.concat('/report/visitors/dashboard/income'),
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getIncomeData;
