<template>
  <v-card flat class="main">
    <v-container fluid :class="{ 'hidden-overflow': carousel !== 2 }">
      <header-content
        v-if="!isFromServiceDashboard"
        :data="headerData"
        title="Formulir Surgical Safety Check List"
        pl="3vw"
        mb="2vw"
        :closeForm="close"
        closeBtn
      />
      <section class="stepper">
        <div class="stepper-content" @click="onClickStepper(1)">
          <div :class="`stepper-number${carousel === 1 ? '-active' : ''}`">
            <h4>1</h4>
          </div>
          <h4 class="font-weight-regular">Fase Sign In</h4>
        </div>
        <v-divider></v-divider>
        <div class="stepper-content" @click="onClickStepper(2)">
          <div :class="`stepper-number${carousel === 2 ? '-active' : ''}`">
            <h4>2</h4>
          </div>
          <h4 class="font-weight-regular">Fase Time Out</h4>
        </div>
        <v-divider></v-divider>
        <div class="stepper-content" @click="onClickStepper(3)">
          <div :class="`stepper-number${carousel === 3 ? '-active' : ''}`">
            <h4>3</h4>
          </div>
          <h4 class="font-weight-regular">Fase Sign Out</h4>
        </div>
      </section>
      <section class="slider-wrapper">
        <div class="slide" id="slide-1">
          <sign-in-phase ref="signin-phase" />
        </div>
        <div class="slide" id="slide-2">
          <time-out-phase ref="timeout-phase" />
        </div>
        <div class="slide" id="slide-3">
          <sign-out-phase ref="signout-phase" />
        </div>
      </section>
    </v-container>
    <v-footer
      class="d-flex px-15"
      :class="{
        'justify-space-between': carousel > 1,
        'justify-end': carousel === 1,
      }"
      v-if="!isDetail"
    >
      <v-btn
        v-if="carousel > 1"
        color="primary"
        @click="before()"
        outlined
        class="px-0"
        ><v-icon>mdi-chevron-left</v-icon></v-btn
      >
      <v-btn
        v-if="carousel === 3 && !isDetail"
        :loading="loading"
        @click="saveData()"
        depressed
        color="primary"
        class="text-capitalize ml-3"
      >
        Simpan</v-btn
      >
      <v-btn
        @click="next()"
        class="px-0"
        v-if="carousel < 3"
        depressed
        color="primary"
        ><v-icon>mdi-chevron-right</v-icon></v-btn
      >
    </v-footer>
    <v-footer v-else class="d-flex px-15 justify-space-between">
      <div class="d-flex">
        <v-btn
          v-if="carousel > 1"
          color="primary"
          @click="before()"
          outlined
          class="mr-5 px-0"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-btn
          @click="next()"
          v-if="carousel < 3"
          depressed
          class="px-0"
          color="primary"
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </div>
      <div class="d-flex">
        <v-btn
          color="error"
          class="text-capitalize"
          outlined
          depressed
          light
          v-if="isEdit"
          @click="
            onClickActionButton();
            handleGetByID();
          "
          >Batal</v-btn
        >
        <v-btn
          color="primary"
          class="text-capitalize ml-5"
          @click="isEdit ? saveData() : onClickActionButton()"
          :loading="loading"
          v-if="!isEmr"
          depressed
          light
          >{{ isEdit ? 'Simpan Perubahan' : 'Edit' }}</v-btn
        >
        <v-btn
          color="primary"
          :class="{ 'btn-print-disabled': isEdit }"
          class="text-capitalize ml-5"
          v-if="isDetail && carousel === 3"
          @click="printSurgicalSafetyChecklistDocument()"
          depressed
          light
          >Print</v-btn
        >
      </div>
    </v-footer>
    <v-snackbar :timeout="4000" v-model="snackbar.edit" color="success">
      Formulir Surgical Safety Checklist berhasil diubah
      <v-btn fab text small @click.native="snackbar.edit = false"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
  </v-card>
</template>

<script>
import SignInPhase from './SSCComponents/SignInPhase.vue';
import SignOutPhase from './SSCComponents/SignOutPhase.vue';
import TimeOutPhase from './SSCComponents/TimeOutPhase.vue';
import HeaderContent from '../../../SharedComponent/HeaderContent.vue';
import AlertMixin from '@/mixin/alertMixin';
import { createNamespacedHelpers } from 'vuex';
import Swal from 'sweetalert2';
import Documents from '@/mixin/documents';
import PrintMixin from '@/mixin/printMixin';

const { mapGetters, mapActions, mapMutations } = createNamespacedHelpers(
  'SurgicalSafetyChecklist',
);
const _ = require('lodash');
const moment = require('moment-timezone');
export default {
  name: 'SurgicalSafetyChecklist',
  components: { SignInPhase, SignOutPhase, TimeOutPhase, HeaderContent },
  mixins: [AlertMixin, PrintMixin, Documents],
  props: {
    closeForm: {
      type: Function,
    },
    isFromServiceDashboard: Boolean,
  },
  data() {
    return {
      carousel: 1,
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      'getSignIn',
      'getTimeOut',
      'getSignOut',
      'getInitialState',
      'getIsDetail',
      'getIsEdit',
      'getIsEmr',
      'getIdSsc',
      'getSnackbar',
    ]),
    snackbar: {
      get() {
        return this.getSnackbar;
      },
    },
    idSsc: {
      get() {
        return this.getIdSsc;
      },
    },
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    allState: {
      get() {
        return {
          signIn: this.getSignIn,
          timeOut: this.getTimeOut,
          signOut: this.getSignOut,
        };
      },
    },
    isDetail: {
      get() {
        return this.getIsDetail;
      },
    },
    isEdit: {
      get() {
        return this.getIsEdit;
      },
      set(value) {
        this.setIsEdit(value);
      },
    },
    initialState: {
      get() {
        return this.getInitialState;
      },
    },
    isStateChanged: {
      get() {
        return !_.isEqual(this.allState, this.initialState);
      },
    },
    headerData() {
      return [
        {
          label: 'No. RM',
          text: this.$store.getters.selectedPatient.rmNumber,
        },
        {
          label: 'Nama',
          text: `${this.$store.getters.selectedPatient.meta.name} (${
            this.$store.getters.selectedPatient.meta.gender === 'Laki-laki'
              ? 'L'
              : 'P'
          })`,
        },
        {
          label: 'Tanggal Lahir',
          text: `${this.$store.getters.selectedPatient.meta.birthDate} (${this.$store.getters.selectedPatient.meta.age} Tahun)`,
        },
      ];
    },
  },
  mounted() {
    this.snackbar.edit = false;
    this.getStaff();
    this.showActionByEmr();
  },
  beforeDestroy() {
    this.clearStoreState();
  },
  methods: {
    ...mapActions([
      'resolveGetStaff',
      'resolveGetActionByEmr',
      'resolvePostSurgicalSafetyChecklistData',
      'resolveGetSurgicalSafetyChecklistById',
    ]),
    ...mapMutations(['clearSurgicalSafetyChecklistState', 'setIsEdit']),
    close() {
      if (this.isStateChanged) {
        Swal.fire({
          title:
            '<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Formulir Surgical Safety</p><p>Checklist Belum Tersimpan?</p></div>',
          iconHtml: '<span class="mdi mdi-alert-circle-outline"></span>',
          html:
            '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%;"><p>Data yang anda input akan hilang. Kembali dan</p><p>simpan formulir terlebih dahulu</p></div>',
          showCancelButton: true,
          customClass: {
            confirmButton: 'button-error',
            cancelButton: 'button-confirm',
          },
          confirmButtonText: 'Keluar',
          cancelButtonText: 'Kembali',
        }).then(result => {
          if (result.isConfirmed) {
            this.closeForm(false);
          }
        });
      } else {
        this.closeForm(false);
      }
    },
    clearStoreState() {
      this.clearSurgicalSafetyChecklistState();
    },
    printSurgicalSafetyChecklistDocument() {
      if (this.isEdit) return;
      const patientData = {
        name: this.$store.getters.selectedPatient.meta.name,
        age: this.$store.getters.selectedPatient.meta.age,
        birthDate: moment(
          this.$store.getters.selectedPatient.meta.birthDateUnformatted,
          'YYYY-MM-DD',
        ).format('DD-MM-YYYY'),
        gender: this.$store.getters.selectedPatient.meta.gender,
        rmNumber: this.$store.getters.selectedPatient.rmNumber,
      };
      const doc = this.generateSscDoc(
        this.$store.getters.userLoggedIn.clinic,
        patientData,
        {
          signin: this.getSignIn,
          timeout: this.getTimeOut,
          signout: this.getSignOut,
        },
      );
      this.printDocument(doc, {
        docName: 'Surgical Safety Checklist',
        rmNumber: patientData.rmNumber,
      });
    },
    onClickActionButton() {
      this.setIsEdit(!this.isEdit);
    },
    onClickStepper(v) {
      // if user in sign in phase and want to navigate directly to sign out phase
      if (this.carousel === 1 && v === 3) {
        if (this.$refs['signin-phase'].$refs.form.validate()) {
          this.carousel = v;
          const targetSection = document.getElementById(`slide-${v}`);
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
        return;
      }
      // if user in time out phase and want to navigate to sign out phase
      if (v === 3) {
        if (this.$refs['timeout-phase'].$refs.form.validate()) {
          this.carousel = v;
          const targetSection = document.getElementById(`slide-${v}`);
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
        return;
      }
      // navigate to previous phase
      if (v > 1) {
        if (this.$refs['signin-phase'].$refs.form.validate()) {
          this.carousel = v;
          const targetSection = document.getElementById(`slide-${v}`);
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
        return;
      }
      // navigate to sign in phase
      if (v === 1) {
        this.carousel = v;
        const targetSection = document.getElementById(`slide-${v}`);
        targetSection.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    },
    next() {
      this.onClickStepper(this.carousel + 1);
    },
    before() {
      this.onClickStepper(this.carousel - 1);
    },
    showActionByEmr() {
      this.resolveGetActionByEmr(this.$store.getters.selectedPatient.id_emr);
    },
    async saveData() {
      if (!this.$refs['timeout-phase'].$refs.form.validate()) {
        this.onClickStepper(2);
        return;
      }
      if (this.$refs['signout-phase'].$refs.form.validate()) {
        try {
          this.loading = true;
          await this.resolvePostSurgicalSafetyChecklistData({
            idEmr: this.$store.getters.selectedPatient.id_emr,
            userLoggedIn: this.$store.getters.userLoggedIn.id,
          });
          if (this.isDetail) {
            await this.resolveGetSurgicalSafetyChecklistById(this.idSsc);
            this.setIsEdit(false);
          } else {
            this.closeForm();
          }
        } catch (error) {
          this.showErrorAxios(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async handleGetByID() {
      await this.resolveGetSurgicalSafetyChecklistById(this.idSsc);
    },
    async getStaff() {
      this.resolveGetStaff();
    },
  },
};
</script>

<style lang="scss" scoped>
$active-color: #036de2;
@mixin stepper-state($isActive: true) {
  @if $isActive {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;
    color: white;
    background-color: $active-color;
  } @else {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;
    color: white;
    background-color: #9ca2a5;
  }
}

.main {
  padding: 0.4rem;
  height: 90dvh;

  .container {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: #c2c2c2;
    }
    ::-webkit-scrollbar-thumb {
      width: 3px;
      background-color: #919191;
    }
  }

  .slider-wrapper {
    display: flex;
    column-gap: 1vw;
    width: 100%;
    max-height: 65vh;
    overflow: hidden;
    aspect-ratio: 16 / 9;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    .slide {
      flex: 1 0 100%;
      overflow-y: auto;
      overflow-x: hidden;
      scroll-snap-align: start;
    }
  }

  #{&}-header {
    margin-bottom: 3vh;
    > .col {
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: start;
      gap: 1rem;
    }

    .col:last-child {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 2rem;
      }
    }

    p {
      font-weight: 500;
      margin: 0;
      padding: 0;
    }
  }

  .stepper {
    display: flex;
    justify-content: center;
    max-width: 35vw;
    margin: auto;
    align-items: center;
    gap: 1vw;
    margin-bottom: 5vh;

    &-content {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.7vw;
    }

    &-number-active {
      @include stepper-state();
    }

    &-number {
      @include stepper-state(false);
    }
  }

  footer {
    position: absolute;
    width: 100%;
    bottom: 3vh;
    right: 1vw;
    background: #fff;

    .btn-disabled {
      background-color: #9e9e9e !important;
    }
    .btn-print-disabled {
      background-color: #9e9e9e !important;
      border: 1px solid #9e9e9e !important;
      color: white !important;
    }
  }
}

.button-error {
  background-color: white !important;
  border: 1px solid #eb4646 !important;
  border-radius: 4px !important;
  color: #eb4646 !important;
}
</style>
