<template>
  <v-container class="pa-0" fluid>
    <v-row no-gutters>
      <v-toolbar flat outlined height="130" style="display: grid;">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters align="center" justify="space-between">
              <v-col cols="6" class="text-left">
                <h2>Posting Jurnal</h2>
              </v-col>
              <v-col cols="1" class="text-right">
                <router-link to="/keuangan/akuntansi">
                  <v-btn icon><v-icon>mdi-home</v-icon></v-btn>
                </router-link>
              </v-col>
              <v-col class="mx-3" cols="1">
                <v-btn depressed color="primary" class="text-none"
                  >Export</v-btn
                >
              </v-col>
              <v-col cols="3" class="pr-0">
                <v-text-field
                  class="input"
                  background-color="grey lighten-3"
                  placeholder="Search"
                  solo
                  dense
                  hide-details
                  flat
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  v-model="search"
                ></v-text-field>
              </v-col>
              <v-btn icon @click="refreshAndAutoUpdate()"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </v-row>
          </v-col>
          <v-col class="pt-3" cols="10">
            <v-row no-gutters align="center">
              <v-col>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="filter.date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filter.date"
                      placeholder="Pilih Tanggal"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details="false"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filter.date"
                    @input="
                      $refs.menu.save(filter.date);
                      menu = false;
                      getPostJournal();
                    "
                  />
                </v-menu>
              </v-col>

              <v-icon>mdi-minus</v-icon>

              <v-col>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="filter.endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filter.endDate"
                      placeholder="Pilih Tanggal"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details="false"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filter.endDate"
                    @input="
                      $refs.menu2.save(filter.endDate);
                      menu2 = false;
                      getPostJournal();
                    "
                  />
                </v-menu>
              </v-col>
              <v-col class="pl-3 pr-2">
                <v-select
                  placeholder="Tipe Transaksi"
                  outlined
                  dense
                  hide-details="false"
                  :items="resource.transactionType"
                ></v-select>
              </v-col>
              <v-col class="pr-3 pl-1">
                <v-select
                  placeholder="User"
                  outlined
                  dense
                  hide-details="false"
                  :items="resource.user"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  placeholder="Unit"
                  outlined
                  dense
                  hide-details="false"
                  :items="resource.unit"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-row>

    <v-row no-gutters>
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        single-expand
        :expanded.sync="expanded"
        item-key="journalNumber"
        show-expand
        fixed-header
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:[`item.no`]="{ item }">
          <td>{{ table.items.indexOf(item) + 1 }}</td>
        </template>
        <template v-slot:[`item.invoiceNumber`]="{ item }">
          <td>{{ item.invoiceNumber }}</td>
        </template>
        <template v-slot:[`item.valueTransaction`]="{ item }">
          <td>{{ formattedCurrency(item.valueTransaction) }}</td>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn icon color="primary" @click="onClick(item)"
            ><v-icon>mdi-information</v-icon></v-btn
          >
        </template>
        <template v-slot:expanded-item="{ item }">
          <td></td>
          <td></td>
          <td class="text-left">
            <label class="caption grey--text font-weight-bold"
              >Dibuat oleh</label
            >
            <br />
            <label class="caption grey--text">Danang Aji Nugraha</label>
          </td>
          <td class="text-left">
            <label class="caption grey--text font-weight-bold"
              >Dibuat pada</label
            >
            <br />
            <label class="caption grey--text">{{ item.created_at }}</label>
          </td>
          <td class="text-left">
            <label class="caption grey--text font-weight-bold">Kode Akun</label>
            <br />
            <a text class="caption primary--text">1.00.00.00.01</a>
            <br />
            <a class="caption primary--text">4.00.00.00.01</a>
          </td>
          <td class="text-left">
            <label class="caption grey--text font-weight-bold">Nama Akun</label>
            <br />
            <label class="caption grey--text">Kas Mandiri</label> <br />
            <label class="caption grey--text">Pendapatan Pasien Umum</label>
          </td>
          <td class="text-right">
            <label class="caption grey--text font-weight-bold">Debit</label>
            <br />
            <label class="caption grey--text">1.000.000,00</label> <br />
            <label class="caption grey--text">0,00</label>
          </td>
          <td class="text-right">
            <label class="caption grey--text font-weight-bold">Kredit</label>
            <br />
            <label class="caption grey--text">0,00</label> <br />
            <label class="caption grey--text">1.000.000,00</label>
          </td>
        </template>
      </v-data-table>
    </v-row>

    <v-dialog v-model="dialog">
      <JournalAgreement
        v-if="dialog"
        @close-dialog="
          dialog = false;
          getPostJournal();
        "
        :id="selectedItem.id"
      />
    </v-dialog>
  </v-container>
</template>

<script>
// import Swal from 'sweetalert2';
import JournalAgreement from './PostJournal/JournalAgreement.vue';
import Constant from '@/const';
import moment from 'moment-timezone';
import formatMixin from '@/mixin/formatMixin';

const axios = require('axios');

export default {
  name: 'JournalList',
  mixins: [formatMixin],
  components: {
    JournalAgreement,
  },
  data() {
    return {
      expanded: [],
      menu: false,
      menu2: false,
      table: {
        headers: [
          { text: '', value: 'data-table-expand', width: '5%' },
          {
            text: 'No',
            value: 'no',
            sortable: false,
          },
          {
            text: 'No. Jurnal',
            value: 'journalNumber',
            width: '15%',
            sortable: false,
          },
          { text: 'Tanggal', value: 'date', sortable: false },
          {
            text: 'Tipe Transaksi',
            value: 'transactionType',
            sortable: false,
            width: '15%',
          },
          { text: 'Uraian', value: 'detail', sortable: false, width: '25%' },
          { text: 'Unit', value: 'unit', sortable: false, width: '15%' },
          {
            text: 'No. Bukti',
            value: 'invoiceNumber',
            sortable: false,
            width: '15%',
          },
          {
            text: 'Nilai Transaksi',
            value: 'valueTransaction',
            sortable: false,
          },
          { text: '', value: 'action', sortable: false },
        ],
        items: [],
      },
      selectedData: null,
      selectedItem: null,
      dialog: false,
      search: '',
      loading: false,
      tabs: null,
      isCorrection: false,
      resource: {
        transactionType: [
          'Transfer',
          'Utang',
          'Piutang',
          'Pendapatan',
          'Beban',
        ],
        user: [],
        unit: [],
      },
      filter: {
        date: null,
        endDate: null,
        transactionType: '',
        user: '',
        unit: '',
      },
    };
  },
  mounted() {
    this.refreshAndAutoUpdate();
  },
  computed: {
    isFinance() {
      return this.$store.getters.userLoggedIn.role === 'Keuangan';
    },
  },
  methods: {
    getPostJournal() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/finance/journal?target=journal?startDate=${this.filter.date ||
              ''}?endDate=${this.filter.endDate || ''}`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          this.table.items = data
            .filter(
              x => !x.status.posted && x.status.journal === 'Dalam Proses',
            )
            .map(arr => {
              return {
                id: arr._id,
                journalNumber: arr.journal_code,
                date: moment(arr.date).format('DD/MM/YYYY'),
                transactionType: arr.transaction_type,
                detail: arr.description,
                unit: arr.unit,
                invoiceNumber: arr.proof_code,
                valueTransaction: arr.transaction_value,
                created_at: moment(arr.timestamps.created_at).format(
                  'DD/MM/YY',
                ),
                entries: arr.entries,
              };
            });
        });
    },
    onClick(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getPostJournal();
      this.interval = setInterval(this.getPostJournal, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.btn-style {
  position: absolute;
  bottom: 5vh;
  right: 5vh;
}
a {
  text-decoration: none;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
  vertical-align: baseline;
  font-size: 0.8rem;
}
::v-deep .v-data-table {
  width: 100%;
}
</style>
