<template>
  <v-card>
    <v-card-text class="pb-0">
      <v-toolbar flat>
        <div class="noRm ">
          <span>No RM</span>
          <span class="black--text font-weight-bold mx-6">{{
            patientData.rmNumber
          }}</span>
        </div>
        <div class="name ">
          <span>Name</span>
          <span class="black--text font-weight-bold mx-6"
            >{{ patientData.meta.name }} ({{
              patientData.meta.gender ? 'L' : 'P'
            }})</span
          >
        </div>
        <div class="birthDate">
          <span>Tanggal Lahir</span>
          <span class="black--text font-weight-bold mx-6"
            >{{ patientData.meta.birthDate }} ({{
              patientData.meta.age
            }}
            tahun)</span
          >
        </div>

        <v-spacer></v-spacer>

        <span class="black--text font-weight-bold headline"
          >Catatan Perkembangan Pasien Terintegrasi</span
        >
      </v-toolbar>
      <v-main>
        <v-container fluid class="pb-0">
          <v-tabs grow center-active v-model="tabs">
            <v-tab class="text-capitalize font-weight-bold"
              >Riwayat Catatan Perkembangan Pasien Terintegrasi</v-tab
            >
            <v-tab class="text-capitalize font-weight-bold"
              >Input Perkembangan Pasien Terintegrasi</v-tab
            >
          </v-tabs>

          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <HistoryCPPT
                :patient-data="patientData"
                @close-form="$emit('close-form')"
              />
            </v-tab-item>
            <v-tab-item>
              <InputCPPT
                :emr-data="emrData"
                :is-emr="isEmr"
                :patient-data="patientData"
                @close-form="$emit('close-form')"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-main>
    </v-card-text>
  </v-card>
</template>

<script>
import InputCPPT from './CPPT/InputCPPT.vue';
import HistoryCPPT from './CPPT/HistoryCPPT.vue';

export default {
  name: 'MedicalResume',
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    isEmr: Boolean,
    emrData: {
      type: Object,
      default: null,
    },
  },
  components: {
    InputCPPT,
    HistoryCPPT,
  },
  data() {
    return {
      tabs: null,
      dialog: false,
    };
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-form');
    },
  },
};
</script>

<style lang="scss" scoped></style>
