import Constant from '@/const';
import axios from 'axios';

const getConfigurationData = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/configuration/pcare'),
    );
    return response.data.data;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getConfigurationData;
