<template>
  <v-card :elevation="0">
    <div class="header">
      <h3>Tipe Penjamin Pasien</h3>
      <v-btn text fab small @click="close"
        ><v-icon color="#9ca2a5">mdi-close</v-icon></v-btn
      >
    </div>
    <v-container fluid>
      <v-form ref="form">
        <div
          v-if="
            (!isDataBpjsMatch || showBridgingStatus) && assuranceType === 'bpjs'
          "
          :class="
            `chip ${!isDataBpjsMatch ? 'unmatch' : ''} ${
              bpjs.pcareBridging && showBridgingStatus
                ? 'connect'
                : 'disconnect'
            }`
          "
        >
          <template v-if="!showBridgingStatus">
            <v-icon color="error" class="mr-3">mdi-alert</v-icon>
            <span>Data pasien tidak sesuai dengan BPJS</span>
            <span
              @click="() => (dialog = true)"
              style="cursor: pointer"
              class="primary--text ml-2"
            >
              <u>Lihat detail</u>
            </span>
          </template>
          <template v-if="showBridgingStatus">
            <v-icon color="#2d965a" class="mr-3">mdi-check-circle</v-icon>
            <span>Bridging BPJS Terhubung</span>
            <v-icon
              @click="() => (showBridgingStatus = false)"
              color="#757575"
              class="ml-3"
              >mdi-close
            </v-icon>
          </template>
        </div>
        <label>No RM</label>
        <p>{{ patientData.rm_number }}</p>
        <label>Nama Pasien</label>
        <p v-if="assuranceType !== 'bpjs'">{{ patientData.name }}</p>
        <v-text-field
          placeholder="Masukkan nama"
          v-else
          v-model="patientName.byNeurovi"
          dense
          single-line
        />
        <label>Tipe Penjamin</label>
        <v-radio-group row dense class="mt-0 mb-4" v-model="assuranceType">
          <v-radio label="BPJS" value="bpjs"></v-radio>
          <v-radio label="Asuransi" value="asuransi"></v-radio>
          <v-radio label="Umum" value="umum"></v-radio>
        </v-radio-group>
        <template v-if="assuranceType === 'bpjs'">
          <label for="bpjs-no" class="required">No BPJS</label>
          <v-text-field
            v-model="bpjs.number"
            :loading="loading.getBpjsNumber || loading.checkBpjsNumber"
            :error-messages="bpjs.errorMessage"
            :append-icon="bpjs.isActive ? 'mdi-check-circle' : ''"
            :rules="rules.bpjsNumber"
            name="bpjs-no"
            placeholder="0000000000"
            type="number"
            counter="13"
            dense
            single-line
          />
        </template>
        <v-row v-if="assuranceType === 'asuransi'">
          <v-col class="pa-0 ma-0 px-4">
            <label for="assurance">Nama Asuransi</label>
            <v-autocomplete
              append-icon="mdi-chevron-down"
              dense
              single-line
              placeholder="Pilih Nama Asuransi"
              name="assurance"
              :loading="loading.getInsurance"
              v-model="assurance.name"
              :items="resource.assurance"
            />
          </v-col>
          <v-col class="pa-0 ma-0">
            <label for="polis">No Polis Asuransi</label>
            <v-text-field
              v-model="assurance.polis"
              name="polis"
              type="number"
              placeholder="0000000000"
              dense
              single-line
            />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <div class="footer">
      <v-btn
        style="width: 4vw; height: 3.5vh;"
        color="#137bc0"
        @click="close"
        outlined
        class="text-capitalize mr-3"
      >
        Batal
      </v-btn>
      <v-btn
        style="width: 7.5vw; height: 3.5vh;"
        :loading="loading.postGuarantorChange"
        @click="resolvePostData"
        color="#137bc0"
        depressed
        dark
        :class="`text-capitalize ${isSaveBtnDisable ? 'disable-btn' : ''}`"
      >
        Simpan Perubahan
      </v-btn>
    </div>
    <v-snackbar :color="snackbar.color" timeout="4000" v-model="snackbar.show">
      {{ snackbar.text }}
      <v-btn
        text
        fab
        dense
        depressed
        small
        @click.native="snackbar.show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="dialog" width="auto">
      <b-p-j-s-correction-data
        @close-form="() => (dialog = false)"
        :data="bpjs.dataCorrection"
        v-if="dialog"
      />
    </v-dialog>
  </v-card>
</template>

<script>
// api
import { getInsurance } from '@/fetchApi/MasterData/Insurance';
import { getRegistrationById, postGuarantorChange } from '@/fetchApi/patient';
import { getActivation, getPcareConnection } from '@/fetchApi/PCare';

// components
import BPJSCorrectionData from '../../Registration/components/BPJSCorrectionData.vue';

// utils
const _ = require('lodash');
const moment = require('moment-timezone');
import Swal from 'sweetalert2';

export default {
  name: 'AssuranceTypeChange',
  components: { BPJSCorrectionData },
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      assuranceType: null,
      isDataBpjsMatch: true,
      showBridgingStatus: false,
      isFormValid: true,
      dialog: false,
      snackbar: {
        show: false,
        color: null,
        text: '',
      },
      bpjs: {
        name: '',
        number: '',
        isActive: null,
        errorMessage: '',
        dataCorrection: {
          name: '',
          no: '',
          birthDate: '',
          age: '',
          ktpNo: '',
          pcareBridging: false,
        },
      },
      assurance: {
        name: '',
        polis: '',
      },
      resource: {
        assurance: [],
      },
      loading: {
        getInsurance: false,
        getBpjsNumber: false,
        checkBpjsNumber: false,
        postGuarantorChange: false,
      },
      patientName: {
        byBpjs: '',
        byNeurovi: '',
      },
      rules: {
        bpjsNumber: [
          value => {
            if (value.toString().length > 13)
              return 'Tidak boleh lebih dari 13 digit';
            if (value.toString().length < 13) return 'No BPJS harus 13 digit';
            return true;
          },
          value => !!value || 'Wajib diisi',
        ],
      },
    };
  },
  watch: {
    isDataBpjsMatch(value) {
      if (!value) {
        this.showBridgingStatus = false;
      }
    },
    'bpjs.number'(value) {
      // removing active bpjs icon from field when user is typing
      this.bpjs = {
        ...this.bpjs,
        isActive: false,
        errorMessage: '',
      };

      if (!value) this.isFormValid = false;
      if (value.toString().length >= 13) this.isFormValid = false;
      if (value.toString().length <= 13) this.isFormValid = true;

      if (value.toString().length === 13)
        this.checkBpjsNumberWithDebounce(this);
    },
    'patientName.byNeurovi'() {
      this.checkIfDataMatch();
      if (!this.isDataBpjsMatch) {
        this.showBridgingStatus = false;
      }
    },
  },
  computed: {
    isSaveBtnDisable: {
      get() {
        if (
          this.patientData.assurance.toLowerCase() === this.assuranceType ||
          !this.isFormValid ||
          (!this.isDataBpjsMatch && this.patientName.byBpjs)
        )
          return true;

        return false;
      },
    },
  },
  mounted() {
    this.mapDataFromProps();
    this.resolveGetInsurance();
    this.resolveGetBpjsNumberFromSocialData();
    this.resolveCheckPcareConnection();
  },
  methods: {
    checkBpjsNumberWithDebounce: _.debounce(v => {
      v.resolveCheckBpjsNumber();
    }, 650),
    checkIfDataMatch() {
      if (!this.patientName.byBpjs) {
        this.isDataBpjsMatch = true;
        return;
      }
      this.isDataBpjsMatch =
        this.patientName.byBpjs.toLowerCase() ===
        this.patientName.byNeurovi.toLowerCase();
    },
    close(snackbar) {
      this.$emit('close-form', snackbar);
    },
    mapDataFromProps() {
      this.bpjs = {
        ...this.bpjs,
        name: this.patientData.name,
      };
      this.assuranceType = this.patientData.assurance.toLowerCase();
      this.assurance = {
        name: this.patientData.assuranceData?.name || '',
        polis: this.patientData.assuranceData?.number || '',
      };
    },
    async resolveGetInsurance() {
      try {
        const response = await getInsurance();
        this.resource.assurance = response.data.data.map(item => item.name);
      } catch {
        this.resource.assurance = [];
        this.snackbar = {
          show: true,
          text: 'Terjadi kesalahan, gagal mendapatkan data daftar asuransi',
          color: 'error',
        };
      }
    },
    async resolveGetBpjsNumberFromSocialData() {
      try {
        this.loading.getBpjsNumber = true;

        const response = await getRegistrationById(this.patientData.rm_number);

        const { number, type } = response.assurance;

        this.bpjs.number = type.toLowerCase() === 'bpjs' ? number : '';
        this.patientName.byNeurovi = response.name; // set name by Neurovi for comparing with name by BPJS
        this.checkIfDataMatch(); // check is name mathed
      } catch {
        this.snackbar = {
          show: true,
          text: 'Terjadi kesalahan, gagal mendapatkan data sosial pasien',
          color: 'error',
        };
      } finally {
        this.loading.getBpjsNumber = false;
      }
    },
    async resolveCheckBpjsNumber() {
      try {
        this.loading.checkBpjsNumber = true;

        const response = await getActivation('bpjs', this.bpjs.number);

        const { aktif, nama, noKTP, tglLahir, noKartu } = response.data.data;

        this.isFormValid = aktif; // if BPJS number is inactive, then disable the save button

        const patientBirthDate = moment(tglLahir, 'DD-MM-YYYY');

        this.bpjs = {
          ...this.bpjs,
          isActive: aktif,
          errorMessage: !aktif ? 'No BPJS tidak aktif' : '',
          dataCorrection: {
            name: nama,
            no: noKartu,
            birthDate: tglLahir,
            age: moment().diff(patientBirthDate, 'years'),
            ktpNo: noKTP,
          },
        };
        this.patientName.byBpjs = nama; // set name by BPJS for comparing with name by Neurovi
        this.checkIfDataMatch(); // check is name matched
      } catch {
        this.isFormValid = false; // if BPJS number is inactive, then disable the save button
        this.bpjs = {
          ...this.bpjs,
          isActive: false,
          errorMessage: 'No BPJS tidak ditemukan. Silakan cek kembali',
        };
      } finally {
        this.loading.checkBpjsNumber = false;
      }
    },
    async resolveCheckPcareConnection() {
      try {
        const response = await getPcareConnection();
        this.bpjs = {
          ...this.bpjs,
          pcareBridging: response.data?.message === 'Bridging BPJS berhasil',
        };
        this.showBridgingStatus = true;
      } catch {
        this.bpjs = {
          ...this.bpjs,
          pcareBridging: false,
        };
      }
    },
    async resolvePostData() {
      try {
        if (this.isSaveBtnDisable) return;
        if (!this.$refs.form.validate()) {
          this.isFormValid = false;
          return;
        }

        this.loading.postGuarantorChange = true;

        const payload = {
          patient_name: this?.patientName?.byNeurovi,
          type: this.assuranceType,
          name: this.assurance.name || '',
          number:
            this.assuranceType === 'bpjs'
              ? this.bpjs.number
              : this.assurance.polis || '',
          id_registration: this.patientData.id_registration,
        };

        // if user changed guarantor to bpjs, then execute code below
        if (this.assuranceType === 'bpjs') {
          Swal.fire({
            title:
              '<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Pengiriman Data ke PCare</p></div>',
            iconHtml: '<span class="mdi mdi-alert-circle-outline"></span>',
            html: `<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%;"><p>Data kunjungan pasien ${this.patientData.name} akan terkirim otomatis ke PCare. Perubahan ini tidak dapat diulangi, apakah Anda yakin?</p></div>`,
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'button-confirm',
              cancelButton: 'button-cancel-error',
            },
            confirmButtonText: 'Ya, Ubah',
            cancelButtonText: 'Batal',
          }).then(async res => {
            if (res.isConfirmed) {
              const response = await postGuarantorChange(
                this.patientData.id,
                payload,
              );
              if (response.status === 200) {
                this.close({
                  show: true,
                  text: 'Tipe penjamin berhasil diubah',
                  color: '#31b057',
                });
              } else {
                this.snackbar = {
                  show: true,
                  text: 'Terjadi kesalahan, gagal mengubah tipe penjamin',
                  color: 'error',
                };
              }
            }
          });
          return;
        }
        // ========================= // end of bpjs post data

        await postGuarantorChange(this.patientData.id, payload);

        this.close({
          show: true,
          text: 'Tipe penjamin berhasil diubah',
          color: '#31b057',
        });
      } catch {
        this.snackbar = {
          show: true,
          text: 'Terjadi kesalahan, gagal mengubah tipe penjamin',
          color: 'error',
        };
      } finally {
        this.loading.postGuarantorChange = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  width: 30vw;
  height: 60vh;

  .header {
    border-bottom: 1px solid #e0e0e0;
    position: relative;
    padding-top: 0.6vh;
    height: 5vh;
    display: flex;
    align-content: center;
    justify-content: center;

    h3 {
      font-size: 1.1vw;
      color: #404040;
    }

    button {
      position: absolute;
      top: 0.1vh;
      right: 0.8vw;
    }
  }

  .container {
    font-size: 0.95vw;
    display: block;
    text-align: start;
    padding: 1.5vw 3vw;
    height: 49vh;
    overflow: auto;

    p {
      margin: 0.6vw 0 1.5vw 0;
      padding: 0;
    }

    label {
      font-weight: 500;
      color: #3f484a;
    }

    .chip {
      padding: 0.5vw;
      margin-bottom: 1vw;
      width: fit-content;
      color: #222222;
      height: fit-content;
      border: 1px solid #bdbdbd;
      border-radius: 0.5vw;

      &.connect {
        background-color: #e5fff0;
      }
      &.unmatch {
        background-color: #fff3f3;
        font-size: 0.85vw;
      }
      &.disconnect {
        background-color: #fff3f3;
        font-size: 0.85vw;
      }
    }
  }

  .footer {
    display: flex;
    border-top: 1px solid #e0e0e0;
    padding: 0.5vw 1vw;
    justify-content: flex-end;
    align-content: center;

    button {
      letter-spacing: 0.001px;
      font-size: 0.75vw !important;

      &.disable-btn {
        background-color: #9e9e9e !important;
      }
    }
  }
}

.required:after {
  content: ' *';
  color: red;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

::v-deep .v-snack__content {
  padding: 0px 0px 0px 1vw;
}
</style>
