<template>
  <div class="form-container">
    <v-form ref="form">
      <!-- First Row -->
      <v-row class="pa-5" no-gutters>
        <!-- Left Col -->
        <v-col cols="6" class="pr-10">
          <v-row class="text-left form-container__rowText">
            <v-col cols="4"><label for="noRm">No RM</label></v-col>
            <v-col cols="8"
              ><label for="noRm" class="font-weight-black">{{
                patientRegistration('rmNumber') !== ''
                  ? patientRegistration('rmNumber')
                  : generatedRmNumber
              }}</label></v-col
            >
          </v-row>
          <v-row
            v-for="(field, index) in fieldsInput.slice(0, 6)"
            :key="index"
            :class="[
              'text-left',
              field.id === 'gender'
                ? 'form-container__rowSwitch'
                : 'form-container__rowText',
            ]"
          >
            <v-col cols="4">
              <label
                for="noRm"
                class="form-container__label "
                :class="{ required: field.required }"
                >{{ field.label }}</label
              >
            </v-col>
            <v-col cols="8">
              <template v-if="field.type === 'text'">
                <v-text-field
                  dense
                  :placeholder="field.placeholder"
                  :required="field.required"
                  :id="field.id"
                  :value="patientRegistration(field.id)"
                  @input="handleInput($event, field.id)"
                  :rules="rules[field.id]"
                  :maxlength="field.id === 'birthDate' ? '10' : '300'"
                />
              </template>
              <template v-else-if="field.type === 'select'">
                <v-select
                  dense
                  append-icon="mdi-chevron-down"
                  :required="field.required"
                  :id="field.id"
                  :items="field.items"
                  item-text="label"
                  item-value="value"
                  :placeholder="field.placeholder"
                  :value="patientRegistration(field.id)"
                  @input="handleInput($event, field.id)"
                  :rules="rules[field.id]"
                />
              </template>
              <template v-else-if="field.type === 'radio'">
                <v-radio-group
                  row
                  class="mt-0 pt-0"
                  :value="patientRegistration(field.id)"
                  @change="handleInput($event, field.id)"
                >
                  <v-radio
                    v-for="(option, optionIndex) in field.options"
                    :key="optionIndex"
                    :label="option.label"
                    :value="option.value"
                  ></v-radio>
                </v-radio-group>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <!-- Right Col -->
        <v-col cols="6" class="pl-10">
          <v-row
            v-for="(field, index) in fieldsInput.slice(6, 9)"
            :key="index"
            :class="[
              'text-left',
              field.id === 'guarantorType'
                ? 'form-container__rowSwitch'
                : 'form-container__rowText',
            ]"
          >
            <v-col cols="4">
              <label for="noRm" :class="{ required: field.required }">{{
                field.label
              }}</label>
            </v-col>
            <v-col cols="8">
              <template v-if="field.type === 'text'">
                <v-text-field
                  dense
                  :placeholder="field.placeholder"
                  :required="field.required"
                  :value="patientRegistration(field.id)"
                  @input="handleInput($event, field.id)"
                  :rules="rules[field.id]"
                />
              </template>
              <template v-else-if="field.type === 'select'">
                <v-select
                  dense
                  append-icon="mdi-chevron-down"
                  :required="field.required"
                  :items="field.items"
                  item-text="label"
                  item-value="value"
                  :placeholder="field.placeholder"
                  :value="patientRegistration(field.id)"
                  @input="handleInput($event, field.id)"
                  :rules="rules[field.id]"
                />
              </template>
              <template v-else-if="field.type === 'radio'">
                <v-radio-group
                  row
                  class="mt-0 pt-0"
                  :value="patientRegistration(field.id)"
                  :required="field.required"
                  :rules="rules[field.id]"
                  @change="handleInput($event, field.id)"
                >
                  <v-radio
                    v-for="(option, optionIndex) in field.options"
                    :key="optionIndex"
                    :label="option.label"
                    :value="option.value"
                  ></v-radio>
                </v-radio-group>
              </template>
            </v-col>
          </v-row>

          <span v-if="patientRegistration('assuranceType') === 'asuransi'">
            <v-row
              v-for="(field, index) in fieldsInput.slice(9, 11)"
              :key="index"
              :class="[
                'text-left',
                field.id === 'guarantorType'
                  ? 'form-container__rowSwitch'
                  : 'form-container__rowText',
              ]"
            >
              <v-col cols="4">
                <label for="noRm" :class="{ required: field.required }">{{
                  field.label
                }}</label>
              </v-col>
              <v-col cols="8">
                <template v-if="field.type === 'text'">
                  <v-text-field
                    dense
                    :placeholder="field.placeholder"
                    :required="field.required"
                    :value="patientRegistration(field.id)"
                    @input="handleInput($event, field.id)"
                    :rules="rules[field.id]"
                  />
                </template>
                <template v-else-if="field.type === 'select'">
                  <v-select
                    dense
                    append-icon="mdi-chevron-down"
                    :required="field.required"
                    :items="field.items"
                    item-text="label"
                    item-value="value"
                    :placeholder="field.placeholder"
                    :value="patientRegistration(field.id)"
                    @input="handleInput($event, field.id)"
                    :rules="rules[field.id]"
                  />
                </template>
              </v-col>
            </v-row>
          </span>
        </v-col>
      </v-row>
      <!-- Seperator -->
      <v-divider class="my-4" />
      <!-- Second Row -->
      <v-row class="pa-5" no-gutters>
        <!-- Left Col -->
        <v-col cols="6" class="pr-10">
          <v-row
            v-for="(field, index) in fieldsInput.slice(11, 19)"
            :key="index"
            :class="[
              'text-left',
              field.id === 'addressText'
                ? 'form-container__rowTextArea'
                : 'form-container__rowText',
            ]"
          >
            <v-col cols="4">
              <label
                for="noRm"
                class="form-container__label "
                :class="{ required: field.required }"
                >{{ field.label }}</label
              >
            </v-col>
            <v-col cols="8">
              <template v-if="field.type === 'text'">
                <v-text-field
                  dense
                  :placeholder="field.placeholder"
                  :required="field.required"
                  :value="patientRegistration(field.id)"
                  @input="handleInput($event, field.id)"
                  :rules="rules[field.id]"
                />
              </template>
              <template v-if="field.type === 'textArea'">
                <v-textarea
                  dense
                  outlined
                  :id="field.id"
                  :placeholder="field.placeholder"
                  :required="field.required"
                  :value="patientRegistration(field.id)"
                  @input="handleInput($event, field.id)"
                  :rules="rules[field.id]"
                ></v-textarea>
              </template>
              <template v-else-if="field.type === 'select'">
                <v-select
                  dense
                  append-icon="mdi-chevron-down"
                  :required="field.required"
                  :items="field.items"
                  item-text="label"
                  item-value="value"
                  :placeholder="field.placeholder"
                  :value="patientRegistration(field.id)"
                  @input="handleInput($event, field.id)"
                  :rules="rules[field.id]"
                />
              </template>
              <template v-else-if="field.type === 'radio'">
                <v-radio-group
                  row
                  class="mt-0 pt-0"
                  :value="patientRegistration(field.id)"
                  @change="handleInput($event, field.id)"
                >
                  <v-radio
                    v-for="(option, optionIndex) in field.options"
                    :key="optionIndex"
                    :label="option.label"
                    :value="option.value"
                  ></v-radio>
                </v-radio-group>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <!-- Right Col -->
        <v-col cols="6" class="pl-10">
          <v-row
            v-for="(field, index) in fieldsInput.slice(19, 28)"
            :key="index"
            class="text-left form-container__rowText"
          >
            <v-col cols="4">
              <label
                for="noRm"
                class="form-container__label "
                :class="{ required: field.required }"
                >{{ field.label }}</label
              >
            </v-col>
            <v-col cols="8">
              <template v-if="field.type === 'text'">
                <v-text-field
                  dense
                  :placeholder="field.placeholder"
                  :required="field.required"
                  :value="patientRegistration(field.id)"
                  @input="handleInput($event, field.id)"
                  :rules="rules[field.id]"
                />
              </template>
              <template v-else-if="field.type === 'select'">
                <v-select
                  dense
                  append-icon="mdi-chevron-down"
                  :required="field.required"
                  :items="field.items"
                  item-text="label"
                  item-value="value"
                  :placeholder="field.placeholder"
                  :value="patientRegistration(field.id)"
                  @input="handleInput($event, field.id)"
                  :rules="rules[field.id]"
                />
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const {
  mapGetters: patientGetters,
  mapActions: patientActions,
  mapMutations: patientMutations,
} = createNamespacedHelpers('PatientRegistration');

const moment = require('moment-timezone');
export default {
  name: 'SocialDataPatientsLaboratory',
  computed: {
    ...patientGetters([
      'getGeneratedRmNumber',
      'getPatientRegistration',
      'getAssuranceData',
      'getAddressItems',
    ]),
    patientRegistration: {
      get() {
        return key => {
          return this.getPatientRegistration[key];
        };
      },
    },
    addressItems() {
      return function(key) {
        return this.getAddressItems[key];
      };
    },
    generatedRmNumber: {
      get() {
        return this.getGeneratedRmNumber;
      },
    },
  },
  methods: {
    ...patientActions([
      'resolveGetGeneratedRmNumber',
      'resolveGetRegistrationById',
      'resolveGetAssurance',
      'resolveGetRegion',
      'resolveGetCity',
      'resolveGetDistrict',
      'resolveGetSubDistrict',
    ]),
    ...patientMutations(['setPatientRegistration']),
    insertString(originalString, newString, index) {
      return (
        originalString.substr(0, index) +
        newString +
        originalString.substr(index)
      );
    },
    handleInput(event, label) {
      if (label === 'age') {
        this.setPatientRegistration({ label, value: Number(event) });
      }
      if (label === 'birthDate') {
        if (event.length > 10) {
          this.setPatientRegistration({
            label: label,
            value: event.slice(0, 10),
          });
          return;
        }
        let date = event.replace(/\D/g, '').substr(0, 10);
        let size = date.length;

        if (size > 4) date = this.insertString(date, '/', 4);

        if (size > 2) date = this.insertString(date, '/', 2);
        this.setPatientRegistration({ label: 'birthDate', value: date });
        // eslint-disable-next-line no-param-reassign
        event = date;
        if (date.length === 10) {
          this.setPatientRegistration({
            label: 'age',
            value: moment().diff(moment(date, 'DD/MM/YYYY'), 'years'),
          });
        } else {
          this.setPatientRegistration({
            label: 'age',
            value: '',
          });
        }
      }
      this.setPatientRegistration({ label, value: event });

      const addressFields = {
        addressProvince: {
          index: 13,
          type: 'city',
          method: this.resolveGetCity,
        },
        addressCity: {
          index: 14,
          type: 'district',
          method: this.resolveGetDistrict,
        },
        addressDistrict: {
          index: 15,
          type: 'subDistrict',
          method: this.resolveGetSubDistrict,
        },
      };

      if (addressFields[label]) {
        const { index, type, method } = addressFields[label];

        method().finally(() => {
          this.fieldsInput[index].items = this.addressItems(type).map(item => ({
            label: item.name,
            value: { name: item.name, id: item._id },
          }));
        });
      }
    },
    checkDateFormat(date) {
      const dateInput = moment(date, 'DD/MM/YYYY');
      return dateInput.isValid();
    },
  },
  mounted() {
    if (this.patientRegistration('rmNumber') !== '') {
      this.resolveGetRegistrationById(
        this.patientRegistration('rmNumber'),
      ).finally(() => {
        Promise.all([
          this.resolveGetCity(this.addressItems('region')._id),
          this.resolveGetDistrict(this.addressItems('city')._id),
          this.resolveGetSubDistrict(this.addressItems('district')._id),
        ]).finally(() => {
          this.fieldsInput[13].items = this.addressItems('city').map(item => ({
            label: item.name,
            value: { name: item.name, id: item._id },
          }));

          this.fieldsInput[14].items = this.addressItems('district').map(
            item => ({
              label: item.name,
              value: { name: item.name, id: item._id },
            }),
          );

          this.fieldsInput[15].items = this.addressItems('subDistrict').map(
            item => ({
              label: item.name,
              value: { name: item.name, id: item._id },
            }),
          );
        });
      });
    } else {
      this.resolveGetGeneratedRmNumber();
    }
    this.resolveGetAssurance().finally(() => {
      this.fieldsInput[9].items = this.getAssuranceData?.map(item => {
        return {
          label: item.name,
          value: item.name,
        };
      });
    });
    this.resolveGetRegion().finally(() => {
      this.fieldsInput[12].items = this.addressItems('region').map(item => {
        return {
          label: item.name,
          value: { name: item.name, id: item._id },
        };
      });
    });
  },
  watch: {
    'getPatientRegistration.assuranceType': {
      handler(val) {
        if (val === 'bpjs') {
          this.setPatientRegistration({ label: 'assuranceType', value: null });
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      rules: {
        name: [v => !!v || 'Perlu mengisi nama'],
        gender: [v => v !== '' || 'Perlu memilih jenis kelamin'],
        nickname: [v => !!v || 'Perlu memilih status pasien'],
        birthPlace: [
          v => !!v || 'Perlu mengisi tempat lahir',
          v => (v && v.length <= 30) || 'Tempat lahir maksimal 30 karakter',
        ],
        birthDate: [
          v => !!v || 'Perlu mengisi tanggal lahir',
          v => this.checkDateFormat(v) || 'Tanggal Tidak Valid',
          v => {
            const range = v.split('/');
            const tanggal = parseInt(range[0]);
            const bulan = parseInt(range[1]);
            const tahun = parseInt(range[2]);
            const hariIni = new Date();
            return (
              tahun < hariIni.getFullYear() ||
              (tahun == hariIni.getFullYear() &&
                bulan < hariIni.getMonth() + 1) ||
              (tahun == hariIni.getFullYear() &&
                bulan == hariIni.getMonth() + 1 &&
                tanggal <= hariIni.getDate()) ||
              'Tanggal tidak boleh lebih besar dari hari ini'
            );
          },
        ],
        addressText: [
          v => !!v || 'Perlu mengisi alamat',
          v => (v && v.length <= 300) || 'Alamat maksimal 300 karakter',
        ],
        assuranceType: [
          v =>
            (v !== undefined && v !== null && v !== '') ||
            'Perlu memilih jenis jaminan',
        ],
        identityNumber: [
          v => {
            if (this.patientRegistration('identityType') === 'KTP') {
              return v.length <= 16 || 'NIK tidak dapat lebih dari 16';
            }
            return true;
          },
        ],
      },
      fieldsInput: [
        {
          id: 'name',
          label: 'Nama Lengkap',
          placeholder: 'Masukkan Nama Pasien',
          required: true,
          type: 'text',
        },
        {
          id: 'nickname',
          label: 'Status Pasien',
          placeholder: 'Pilih Status',
          items: [
            { label: 'Nyonya', value: 'nyonya' },
            { label: 'Tuan', value: 'tuan' },
            { label: 'Nona', value: 'nona' },
            { label: 'Saudara', value: 'saudara' },
            { label: 'Anak', value: 'anak' },
            { label: 'Bayi', value: 'bayi' },
          ],
          required: true,
          type: 'select',
        },
        {
          id: 'gender',
          label: 'Jenis Kelamin',
          placeholder: '',
          required: true,
          options: [
            { label: 'Laki-laki', value: true },
            { label: 'Perempuan', value: false },
          ],
          type: 'radio',
        },
        {
          id: 'birthPlace',
          label: 'Tempat Lahir',
          placeholder: 'Masukkan Tempat Lahir',
          required: true,
          type: 'text',
        },
        {
          id: 'birthDate',
          label: 'Tanggal Lahir',
          placeholder: 'dd/mm/yyyy',
          required: true,
          type: 'text',
        },
        {
          id: 'age',
          label: 'Usia',
          placeholder: '',
          required: false,
          type: 'text',
        },
        {
          id: 'identityType',
          label: 'Jenis Identitas',
          placeholder: 'Pilih Identitas',
          required: false,
          items: [
            { label: 'Tidak Ada Identitas', value: 'Tidak Ada Identitas' },
            { label: 'KTP', value: 'KTP' },
            { label: 'SIM', value: 'SIM' },
            { label: 'Passport', value: 'Passport' },
          ],
          type: 'select',
        },
        {
          id: 'identityNumber',
          label: 'No Identitas',
          placeholder: 'Masukkan Nomor Identitas',
          required: false,
          type: 'text',
        },
        {
          id: 'assuranceType',
          label: 'Jenis Penjamin',
          placeholder: '',
          required: true,
          options: [
            { label: 'Asuransi', value: 'asuransi' },
            { label: 'Umum', value: 'umum' },
          ],
          type: 'radio',
        },
        {
          id: 'assuranceName',
          label: 'Nama Asuransi',
          placeholder: 'Pilih Nama Asuransi',
          required: false,
          items: [],
          type: 'select',
        },
        {
          id: 'assuranceNumber',
          label: 'No Polis Asuransi',
          placeholder: 'Masukkan Nomor Polis Asuransi',
          required: false,
          type: 'text',
        },
        {
          id: 'addressText',
          label: 'Alamat',
          placeholder: 'Masukkan Alamat',
          required: true,
          type: 'textArea',
        },
        {
          id: 'addressProvince',
          label: 'Provinsi',
          placeholder: 'Provinsi',
          required: false,
          items: [],
          type: 'select',
        },
        {
          id: 'addressCity',
          label: 'Kabupaten',
          placeholder: 'Kabupaten',
          required: false,
          items: [],
          type: 'select',
        },
        {
          id: 'addressDistrict',
          label: 'Kecamatan',
          placeholder: 'Kecamatan',
          required: false,
          items: [],
          type: 'select',
        },
        {
          id: 'addressSubDistrict',
          label: 'Kelurahan',
          placeholder: 'Kelurahan',
          required: false,
          items: [],
          type: 'select',
        },
        {
          id: 'phoneNumber',
          label: 'No Telepon',
          placeholder: 'Masukkan No Telepon',
          required: false,
          type: 'text',
        },
        {
          id: 'religion',
          label: 'Agama',
          placeholder: 'Pilih Agama',
          required: false,
          items: [
            { label: 'Islam', value: 'islam' },
            { label: 'Kristen', value: 'kristen' },
            { label: 'Budha', value: 'budha' },
            { label: 'Hindu', value: 'hindu' },
            { label: 'Katolik', value: 'katolik' },
          ],
          type: 'select',
        },
        {
          id: 'bloodType',
          label: 'Golongan Darah',
          placeholder: 'Pilih Golongan Darah',
          required: false,
          items: [
            { label: 'A', value: 'a' },
            { label: 'AB', value: 'ab' },
            { label: 'B', value: 'b' },
            { label: 'O', value: 'o' },
          ],
          type: 'select',
        },
        {
          id: 'education',
          label: 'Pendidikan',
          placeholder: 'Pilih Pendidikan',
          required: false,
          items: [
            { label: 'Tidak Sekolah', value: '-' },
            { label: 'TK', value: 'tk' },
            { label: 'SD', value: 'sd' },
            { label: 'SMP', value: 'smp' },
            { label: 'SMA', value: 'sma' },
            { label: 'Diploma I', value: 'diploma1' },
            { label: 'Diploma II', value: 'diploma2' },
            { label: 'Diploma III', value: 'diploma3' },
            { label: 'Diploma IV', value: 'diploma4' },
            { label: 'Sarjana', value: 'sarjana' },
            { label: 'Magister', value: 'magister' },
            { label: 'Doktor', value: 'doktor' },
          ],
          type: 'select',
        },
        {
          id: 'profession',
          label: 'Pekerjaan',
          placeholder: 'Masukkan Pekerjaan',
          required: false,
          type: 'text',
        },
        {
          id: 'martialStatus',
          label: 'Status Kawin',
          placeholder: 'Pilih Status Kawin',
          required: false,
          items: [
            { label: 'Belum Kawin', value: 'belum kawin' },
            { label: 'Kawin', value: 'kawin' },
            { label: 'Duda', value: 'duda' },
            { label: 'Janda', value: 'janda' },
          ],
          type: 'select',
        },
        {
          id: 'father',
          label: 'Nama Ayah',
          placeholder: 'Masukkan Nama Ayah',
          required: false,
          type: 'text',
        },
        {
          id: 'mother',
          label: 'Nama Ibu',
          placeholder: 'Masukkan Nama Ibu',
          required: false,
          type: 'text',
        },
        {
          id: 'ethnicity',
          label: 'Etnis',
          placeholder: 'Pilih Etnis',
          required: false,
          items: [
            { label: 'Suku Bali', value: 'bali' },
            { label: 'Suku Banjar', value: 'banjar' },
            { label: 'Suku Batak', value: 'batak' },
            { label: 'Suku Betawi', value: 'betawi' },
            { label: 'Suku Bugis', value: 'bugis' },
            { label: 'Suku Cina, RRC, Taiwan', value: 'cina' },
            { label: 'Suku Cirebon', value: 'cirebon' },
            { label: 'Suku Dayak', value: 'dayak' },
            { label: 'Suku Gorontalo', value: 'gorontalo' },
            { label: 'Suku Jawa', value: 'jawa' },
            { label: 'Suku Madura', value: 'madura' },
            { label: 'Suku Makassar', value: 'makassar' },
            { label: 'Suku Melayu', value: 'melayu' },
            { label: 'Suku Minahasa', value: 'minahasa' },
            { label: 'Suku Minangkabau', value: 'minangkabau' },
            { label: 'Suku Nias', value: 'nias' },
            { label: 'Suku Sasak', value: 'sasak' },
            { label: 'Suku Asal Aceh', value: 'aceh' },
            { label: 'Suku Asal Banten', value: 'banten' },
            { label: 'Suku Asal Kalimantan lainnya', value: 'kalimantan' },
            { label: 'Suku Asal Maluku', value: 'maluku' },
            { label: 'Suku Asal Nusa Tenggara Timur', value: 'ntt' },
            { label: 'Suku Asal Papua', value: 'papua' },
            { label: 'Suku Asal Sulawesi lainnya', value: 'sulawesi' },
            { label: 'Suku Asal Sumatera lainnya', value: 'sumatera' },
            { label: 'Suku Luar Negeri', value: 'ln' },
            { label: 'Suku Asal Jambi', value: 'jambi' },
            { label: 'Suku Asal Lampung', value: 'lampung' },
            { label: 'Suku Asal Sumatera Selatan', value: 'sumatra selatan' },
            { label: 'Suku Nusa Tenggara Barat lainnya', value: 'ntb' },
            { label: 'Suku Sunda', value: 'sunda' },
          ],
          type: 'select',
        },
        {
          id: 'languageMastering',
          label: 'Bahasa yang Dikuasai',
          placeholder: 'Bahasa yang Dikuasai',
          required: false,
          type: 'text',
        },
        {
          id: 'communicationObstacles',
          label: 'Hambatan dalam Berkomunikasi',
          placeholder: 'Hambatan dalam Berkomunikasi',
          required: false,
          items: [
            { label: 'Tidak Ada', value: '' },
            { label: 'Bahasa', value: 'bahasa' },
            { label: 'Lingkungan', value: 'lingkungan' },
            { label: 'Fisik', value: 'fisik' },
            { label: 'Psikologi', value: 'psikologi' },
          ],
          type: 'select',
        },
        {
          id: 'disabilities',
          label: 'Penyandang Disablitas',
          placeholder: 'Disablitas',
          required: false,
          items: [
            { label: 'Tidak ada', value: 0 },
            { label: 'Fisik', value: 1 },
            { label: 'Mental', value: 2 },
            { label: 'Fisik dan Mental', value: 3 },
          ],
          type: 'select',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  overflow-y: scroll;
  height: 65vh;
  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #c2c2c2;
  }
  &__rowText {
    height: 8vh;
    display: flex;
    align-items: center;
  }
  &__rowSwitch {
    height: 7.5vh;
  }
  &__rowTextArea {
    height: 25vh;
  }
  &__label {
    color: #222;
  }
}
.required::after {
  content: '*';
  color: #eb4747;
  padding-left: 5px;
}
</style>
