import JsPDF from 'jspdf';
import moment from 'moment-timezone';

export default {
  methods: {
    pdfTextPrint(doc, text, y, mode = 'center', offset = 0) {
      const textWidth =
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      let textOffset = 0;
      if (mode === 'center') {
        textOffset = (doc.internal.pageSize.width - textWidth) / 2 + offset;
      } else if (mode === 'right') {
        textOffset = doc.internal.pageSize.width - textWidth - offset;
      } else if (mode === 'left') {
        textOffset += offset;
      }
      doc.text(text, textOffset, y);
    },
    printEticket(data, drugs) {
      const clientName = this.$store.getters.userLoggedIn.clinic.name.toLowerCase();
      // start of PK ticket
      const defaultTicket = new JsPDF({
        orientation: 'landscape',
        unit: 'cm',
        format: [5.9, 3.8],
      });

      defaultTicket.setProperties({
        title: `E-ticket Farmasi no.RM: ${data.rmNumber}`,
      });

      drugs.forEach((drug, index) => {
        defaultTicket.setFont('helvetica', 'bold');
        defaultTicket.setFontSize(6);
        this.pdfTextPrint(
          defaultTicket,
          this.$store.getters.userLoggedIn.clinic.name,
          0.35,
        );
        defaultTicket.setDrawColor('black');
        defaultTicket.setLineWidth(0.02);
        defaultTicket.rect(0.1, 0, 5.6, 0.54);
        defaultTicket.rect(0.1, 0.55, 5.6, 3);
        defaultTicket.setFont('helvetica', 'normal');
        defaultTicket.setFontSize(5.5);
        defaultTicket.setLineWidth(0.02);
        defaultTicket.autoTable({
          body: [
            [
              `No. RM. ${data.rmNumber} / ${data.birthDate}`,
              `Tgl. ${moment(drug.date, 'D/M/YYYY').format('DD/MM/YYYY')}`,
            ],
            [
              {
                content: `${data.name}`,
                colSpan: 3,
                styles: { fontSize: 8 },
              },
            ],
            [
              {
                content: `${
                  Array.isArray(drug.drugs)
                    ? drug.drugs.map(
                        (i, index) =>
                          `${index ? ' ' : ''}${i.name} ${i.dosage_needed} ${
                            i.unit_dosage
                          }`,
                      )
                    : `${drug.drug_name || drug.name} | ${drug.quantity} ${
                        drug.packaging
                      }`
                }`,
                colSpan: 3,
                styles: {
                  fontSize: Array.isArray(drug.drugs) ? 5 : 7,
                  cellPadding: 0.015,
                },
              },
            ],
            [
              {
                content: !Array.isArray(drug.drugs)
                  ? `Exp. ${
                      drug.expiry && drug.expiry !== '-'
                        ? moment(drug.expiry, 'YYYY-MM-DD').format('DD/MM/YYYY')
                        : '-'
                    }`
                  : `${drug.quantity} ${drug.packaging}`,
                colSpan: 1,
              },
            ],
          ],
          startY: 0.7,
          styles: {
            fontSize: 6.5,
            halign: 'left',
            cellPadding: 0.015,
            textColor: 'black',
          },
          columnStyles: {
            0: { cellWidth: 3.6 },
            1: { cellWidth: 1.9, halign: 'right' },
          },
          didDrawCell: e => {
            e.doc.internal.write(0, 'Tw');
          },
          margin: {
            left: 0.2,
            right: 0.1,
            bottom: 0,
          },
          theme: 'plain',
        });

        defaultTicket.setFont('helvetica');
        defaultTicket.setFontSize(5.5);
        this.pdfTextPrint(defaultTicket, drug.general_rule || '-', 3.2);
        this.pdfTextPrint(defaultTicket, drug.description || '', 3.4);
        defaultTicket.setFont('helvetica', 'bold');
        defaultTicket.setFontSize(7.5);
        this.pdfTextPrint(defaultTicket, drug.usage, 3);
        if (index !== drugs.length - 1) {
          defaultTicket.addPage();
        }
      });
      // end of PK eticket

      // start of BH eticket

      const bhTicket = new JsPDF({
        orientation: 'landscape',
        unit: 'cm',
        format: [5.9, 3.8],
      });

      bhTicket.setProperties({
        title: `E-ticket Farmasi no.RM: ${data.rmNumber}`,
      });

      drugs.forEach((drug, index) => {
        bhTicket.setFont('Times-Roman');
        bhTicket.setFontSize(6);
        this.pdfTextPrint(
          bhTicket,
          this.$store.getters.userLoggedIn.clinic.name.toUpperCase(),
          0.25,
        );
        bhTicket.setFontSize(5);
        this.pdfTextPrint(
          bhTicket,
          'Jl. Perumnas 207 Gorongan, Condongcatur, Depok, Sleman',
          0.45,
        );
        this.pdfTextPrint(bhTicket, 'Telp : (0274) 488314', 0.65);
        this.pdfTextPrint(
          bhTicket,
          'Apoteker : apt. Bayu Bakti Angga S., M. Pharm. Sci',
          0.85,
        );
        bhTicket.setFontSize(4);
        this.pdfTextPrint(
          bhTicket,
          'SIPA : 503/356-2024-KES/356-2024-20240123/SIPA/2024',
          1.05,
        );
        bhTicket.setDrawColor('black');
        bhTicket.setLineWidth(0.02);
        bhTicket.rect(0.1, 0, 5.6, 1.09);
        bhTicket.rect(0.1, 1.09, 5.6, 2.5);
        bhTicket.setFont('helvetica', 'normal');
        bhTicket.setFontSize(6);
        bhTicket.setLineWidth(0.02);
        bhTicket.autoTable({
          body: [
            [
              `No. RM. ${data.rmNumber} / ${data.birthDate}`,
              `Tgl. ${moment(drug.date, 'D/M/YYYY').format('DD/MM/YYYY')}`,
            ],
            [
              {
                content: `${data.name}`,
                colSpan: 3,
                styles: { fontSize: 6 },
              },
            ],
            [
              {
                content: `${
                  Array.isArray(drug.drugs)
                    ? drug.drugs.map(
                        (i, index) =>
                          `${index ? ' ' : ''}${i.name} ${i.dosage_needed} ${
                            i.unit_dosage
                          }`,
                      )
                    : drug.drug_name || drug.name
                }`,
                colSpan: 3,
                styles: {
                  fontSize: Array.isArray(drug.drugs) ? 5 : 7,
                  cellPadding: 0.015,
                },
              },
            ],
            [
              {
                content: !Array.isArray(drug.drugs)
                  ? `Exp. ${
                      drug.expiry && drug.expiry !== '-'
                        ? moment(drug.expiry, 'YYYY-MM-DD').format('DD/MM/YYYY')
                        : '-'
                    }`
                  : '',
                colSpan: 1,
              },
            ],
          ],
          startY: 1.1,
          styles: {
            fontSize: 6.5,
            halign: 'left',
            cellPadding: 0.015,
            textColor: 'black',
            // lineWidth: 0.02,
            // lineColor: 10,
          },
          columnStyles: {
            0: { cellWidth: 3.6 },
            1: { cellWidth: 1.9, halign: 'right' },
          },
          didDrawCell: e => {
            e.doc.internal.write(0, 'Tw');
          },
          margin: {
            left: 0.2,
            right: 0.1,
            bottom: 0,
          },
          theme: 'plain',
        });
        bhTicket.setFont('helvetica');
        bhTicket.setFontSize(5.5);
        this.pdfTextPrint(bhTicket, drug.general_rule || '-', 3.2);
        this.pdfTextPrint(bhTicket, drug.description || '', 3.4);
        bhTicket.setFont('helvetica', 'bold');
        bhTicket.setFontSize(7.5);
        this.pdfTextPrint(bhTicket, drug.usage, 3);
        if (index !== drugs.length - 1) {
          bhTicket.addPage();
        }
      });

      // end of BH eticket

      switch (clientName) {
        case 'klinik bangun husada':
          return bhTicket;
        case 'klinik permata keluarga':
          return defaultTicket;
        default:
          return defaultTicket;
      }
    },
  },
};
