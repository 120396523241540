import Constant from '@/const';
import axios from 'axios';

const putPharmacyDrug = async (params, id_recipe, payload) => {
  try {
    await axios.put(
      Constant.apiUrl.concat(`/patient/recipe/${id_recipe}`),
      payload,
      params,
    );
  } catch (error) {
    throw new Error(error);
  }
};

export default putPharmacyDrug;
