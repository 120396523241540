<template>
  <section class="mb-8 pt-15 pr-3">
    <v-form ref="form">
      <v-row>
        <v-col cols="12" class="d-flex justify-space-between mb-5">
          <div
            class="emoticon"
            v-for="(emoti, index) in resource.emoticon"
            :key="'emoticon-' + index"
          >
            <img :src="emoti.src" :alt="emoti.alt" />
            <label>{{ emoti.label }}</label>
          </div>
        </v-col>
        <v-col cols="12"
          ><v-slider
            v-model="form.pain"
            :tick-labels="ticksLabels"
            :readonly="isEMR"
            class="mx-0"
            :max="10"
            step="1"
            ticks="always"
            tick-size="5"
          ></v-slider
        ></v-col>
        <v-col cols="12" class="range pb-0">
          <div>
            <div class="range-no-pain"></div>
          </div>
          <div>
            <div class="range-light-pain"></div>
          </div>
          <div>
            <div class="range-medium-pain"></div>
          </div>
          <div>
            <div class="range-heavy-pain"></div>
          </div>
        </v-col>
        <v-col cols="12" class="range-label-container pt-0 mb-15">
          <label>Tidak Nyeri</label>
          <label>Nyeri Ringan</label>
          <div></div>
          <label>Nyeri Sedang</label>
          <div></div>
          <label>Nyeri Berat</label>
        </v-col>
        <v-row v-show="form.pain">
          <v-col>
            <label class="required">Faktor Nyeri</label>
            <v-combobox
              v-model="form.painFactor"
              :items="resource.painFactor"
              :readonly="isEMR"
              placeholder="Faktor Nyeri"
              :rules="rule"
              append-icon="mdi-chevron-down"
              multiple
            >
              <template v-slot:prepend-item>
                <p class="grey--text ma-4 text-start body-1">
                  Pilih atau Ketik Untuk Pilihan Lainnya
                </p>
              </template>
            </v-combobox>
            <label class="required">Rasa Nyeri Seperti</label>
            <v-combobox
              v-model="form.painLike"
              :rules="rule"
              :items="resource.painLike"
              :readonly="isEMR"
              placeholder="Rasa Nyeri Seperti"
              append-icon="mdi-chevron-down"
              multiple
            >
              <template v-slot:prepend-item>
                <p class="grey--text ma-4 text-start body-1">
                  Pilih atau Ketik Untuk Pilihan Lainnya
                </p>
              </template>
            </v-combobox>
            <label class="required mb-4"
              >Nyeri Menjalar ke Bagian Tubuh Lain</label
            >
            <v-radio-group
              hide-details
              row
              class="mb-4"
              :readonly="isEMR"
              v-model="form.painRadiation"
            >
              <v-radio label="Tidak" :value="false"></v-radio>
              <v-radio label="Ya" :value="true"></v-radio>
            </v-radio-group>
            <label class="required pt-3">Tingkat Keparahan</label>
            <v-radio-group
              hide-details
              row
              :readonly="isEMR"
              v-model="form.severity"
            >
              <v-radio label="Tidak Nyeri" :value="0"></v-radio>
              <v-radio label="Ringan" :value="1"></v-radio>
              <v-radio label="Sedang" :value="2"></v-radio>
              <v-radio label="Berat" :value="3"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col>
            <label class="required pt-3 mb-4">Frekuensi Nyeri</label>
            <v-radio-group
              hide-details
              row
              class="mb-4"
              :readonly="isEMR"
              v-model="form.painFrequency"
            >
              <v-radio label="Jarang" :value="0"></v-radio>
              <v-radio label="Terus Menerus" :value="1"></v-radio>
              <v-radio label="Hilang Timbul" :value="2"></v-radio>
            </v-radio-group>
            <label class="required pt-3 mb-4">Durasi Nyeri</label>
            <v-radio-group
              hide-details
              class="mb-4"
              row
              :readonly="isEMR"
              v-model="form.painDuration"
            >
              <v-radio label="<30 menit" :value="0"></v-radio>
              <v-radio label=">30 menit" :value="1"></v-radio>
            </v-radio-group>
            <label>Deskripsi</label>
            <v-textarea
              no-resize
              height="150"
              placeholder="Masukkan deskripsi"
              :readonly="isEMR"
              v-model="form.description"
              outlined
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'EarlyAssessmentInpatientStore',
);

import HappyFace from '@/assets/image/HappyFace.svg';
import SmillingFace from '@/assets/image/SmilingFace.svg';
import NoExpressionFace from '@/assets/image/NoExpressionFace.svg';
import FrowningFace from '@/assets/image/FrowningFace.svg';
import WorriedFace from '@/assets/image/WorriedFace.svg';
import Cryingface from '@/assets/image/CryingFace.svg';

export default {
  name: 'PainAssessmentInpatient',
  props: {
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rule: !this.isEMR ? [v => v?.length || 'Wajib diisi'] : [],
      ticksLabels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      pain: 0,
      resource: {
        emoticon: [
          {
            src: HappyFace,
            alt: 'Happy Face',
            label: 'Tidak Nyeri',
          },
          {
            src: SmillingFace,
            alt: 'Smiling Face',
            label: 'Agak Nyeri',
          },
          {
            src: NoExpressionFace,
            alt: 'No Expression Face',
            label: 'Nyeri Sedikit',
          },
          {
            src: FrowningFace,
            alt: 'Frowning Face',
            label: 'Nyeri',
          },
          {
            src: WorriedFace,
            alt: 'Worried Face',
            label: 'Lebih Nyeri',
          },
          {
            src: Cryingface,
            alt: 'Crying Face',
            label: 'Sangat Nyeri',
          },
        ],
        painFactor: ['Cahaya', 'Gelap', 'Gerakan', 'Berbaring'],
        painLike: [
          'Ditusuk',
          'Dipukul',
          'Berdenyut',
          'Ditikam',
          'Kram',
          'Ditarik',
          'Dibakar',
          'Tajam',
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['getPainAssessment']),
    form: {
      get() {
        return this.getPainAssessment;
      },
    },
  },
  methods: {
    ...mapMutations(['resetPainAssessment']),
  },
};
</script>

<style lang="scss" scoped>
$standard-font-size: 0.9vw;

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.grid-display {
  @include grid-display(10vw minmax(16vw, 26vw), true);
  column-gap: 1vw;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
.emoticon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.8dvh;

  label {
    font-size: 1vw;
  }
}

.range-label-container {
  display: grid;
  width: 100%;
  grid-template-columns: 10% 20% 10% 20% 9% 31%;
  justify-content: center;
  font-size: 0.9vw;
}
.range {
  display: grid;
  grid-template-columns: 10% 30% 29% auto;

  #{&}-no-pain {
    width: 100%;
    margin-bottom: 1.5vh;
    margin-right: 1vw;
    height: 2vh;
    border: 2.5px solid #fff;
    border-top: 0px;
  }

  #{&}-light-pain {
    width: 68%;
    margin-bottom: 1.5vh;
    height: 2vh;
    border: 2px solid #137bc0;
    border-top: 0px;
  }

  #{&}-medium-pain {
    width: 69%;
    margin-bottom: 1.5vh;
    height: 2vh;
    border: 2px solid #137bc0;
    border-top: 0px;
  }

  #{&}-heavy-pain {
    width: 95%;
    margin-bottom: 1.5vh;
    height: 2vh;
    border: 2px solid #137bc0;
    border-top: 0px;
  }
}

img {
  width: 3.2vw;
}

.col {
  text-align: start;
  padding: 0 1.5vw;
}

.required:after {
  content: ' *';
  color: red;
}

label {
  font-weight: 500;
  font-size: 0.8vw;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
