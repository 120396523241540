import Constant from '@/const';
import axios from 'axios';

const getPatientByEmr = params => {
  try {
    const response = axios.get(Constant.apiUrl.concat('/patient/emr/all'), {
      params,
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getPatientByEmr;
