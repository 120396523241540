<template>
  <v-card>
    <v-container fluid class="container-height pa-0">
      <v-toolbar flat prominent height="110">
        <v-container fluid class="py-0">
          <div class="d-flex justify-space-between">
            <h2 class="text-left my-7">Permintaan Alkes dan BHP</h2>
            <v-btn @click="$emit('close-dialog')" small fab text>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-row>
            <v-col cols="8">
              <v-row class="header-grid">
                <span class="font-weight-bold text-left">No RM</span>
                <span class="black--text mx-6 text-left">{{
                  patientData.rmNumber
                }}</span>

                <span class=" font-weight-bold text-left">Name</span>
                <span class="black--text mx-6  text-left"
                  >{{ patientData.meta.name }} ({{
                    patientData.meta.gender ? 'L' : 'P'
                  }})</span
                >

                <span class=" font-weight-bold text-left">Tanggal Lahir</span>
                <span class="black--text mx-6  text-left"
                  >{{ patientData.meta.birthDate }} ({{
                    patientData.meta.age
                  }}
                  tahun)</span
                >
                <span class=" font-weight-bold text-left">DPJP</span>
                <span class="black--text text-left mx-6 ">{{
                  patientData.meta.doctor
                }}</span>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row class="mb-4">
                <span class="text-left font-weight-bold">Riwayat Alergi</span>
              </v-row>
              <v-row class="mt-n4">
                <span
                  class="red--text font-weight-bold body-2"
                  v-if="allergy.length === 0"
                  >Tidak ada alergi</span
                >
                <template v-for="(allergy, idx) in allergy">
                  <v-chip
                    :key="idx"
                    color="#EB4747"
                    class="allergy mr-2 mb-2"
                    label
                    small
                    caption
                    >{{ allergy }}</v-chip
                  >
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>
      <v-container fluid class="text-left mt-5">
        <v-row>
          <v-col cols="4">
            <v-row no-gutters justify="start" class="pt-5" align="baseline">
              <v-col style="padding-left: 15px;" cols="4">
                <label class=" body-2 font-weight-bold">Nama Alkes/BHP</label>
              </v-col>
              <v-col cols="8" class="pb-5 pl-3">
                <v-combobox
                  placeholder="Pilih Alkes/BHP"
                  dense
                  hide-details="false"
                  :loading="searchLoading"
                  :items="resource.consumable"
                  item-text="name"
                  append-icon="mdi-chevron-down"
                  :search-input.sync="searchConsumable"
                  v-model="input.name"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row no-gutters justify="start" class="pb-5" align="baseline">
              <v-col style="padding-left: 15px;" cols="4">
                <label class="body-2 font-weight-bold">Jumlah</label>
              </v-col>
              <v-col cols="8" class="pl-3 mb-3">
                <v-text-field
                  :suffix="input.name.packaging"
                  placeholder="Jumlah"
                  dense
                  v-model="input.amount"
                ></v-text-field>
              </v-col>
              <v-btn
                color="primary"
                depressed
                outlined
                height="30"
                width="180"
                class="text-none caption font-weight-medium d-flex justify-space-between ml-4"
                @click="addToTable()"
                ><v-icon small>mdi-plus</v-icon>Tambah Alkes dan BHP</v-btn
              >
            </v-row>
          </v-col>
          <v-col cols="8" class="mt-5">
            <section class="table-header">
              <label class="text-center">No</label>
              <label class="text-center">Tanggal</label>
              <label class="text-left">Nama Alkes dan BHP</label>
              <label class="text-center">Jumlah</label>
              <label class="text-center">Jumlah yang diberikan</label>
              <label class="text-center">Satuan</label>
              <label class="text-center">Status</label>
            </section>
            <section class="table-items-container">
              <div
                v-for="(item, index) in table.items"
                :key="`item-${index}`"
                class="table-items"
              >
                <label class="text-center">{{ index + 1 }}</label>
                <label class="text-center">{{ item.dateDisplay }}</label>
                <label class="text-left">{{ item.name }}</label>
                <label class="text-center">{{ item.quantity }}</label>
                <label class="text-center">{{ item.quantity }}</label>
                <label class="text-center">{{ item.packaging || '-' }}</label>
                <label class="text-center">{{ item.status || '' }}</label>
                <v-btn
                  icon
                  v-if="!item.isConfirmed && item.isDeletable"
                  @click="deleteItem(item)"
                  ><v-icon small>mdi-delete</v-icon></v-btn
                >
              </div>
            </section>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-card-actions class="pb-10">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        depressed
        outlined
        tile
        class="text-none btn-rounded"
        width="150"
        @click="$emit('close-dialog')"
        >Kembali</v-btn
      >
      <v-btn
        color="primary"
        depressed
        tile
        class="text-none btn-rounded"
        width="150"
        @click="addData()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'BHPOrder',
  mixins: [alertMixin],
  props: {
    patientData: {
      type: Object,
    },
    idEmr: {
      type: String,
    },
    patientStatus: {
      type: String,
    },
    allergy: {
      type: Array,
    },
  },
  data() {
    return {
      resource: {
        consumable: [],
      },
      loading: false,
      searchLoading: false,
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Tanggal', value: 'dateDisplay' },
          { text: 'Nama Alkes/BHP', value: 'name' },
          { text: 'Jumlah', value: 'quantity' },
          { text: 'Jumlah Diberikan', value: 'quantityGiven' },
          { text: 'Satuan', value: 'packaging' },
          { text: 'Status', value: 'status' },
          { text: '', value: 'action' },
        ],
        items: [],
      },
      input: {
        name: '',
        amount: null,
      },
      isDeletable: true,
      searchConsumable: '',
    };
  },
  computed: {
    poly() {
      let result;
      const routeArray = this.$route.path.split('/');
      switch (routeArray[routeArray.length - 1]) {
        case 'klinik-igd':
          result = 'igd';
          break;
        case 'rawat-inap':
          result = 'rawat_inap';
          break;
      }
      return result;
    },
  },
  watch: {
    searchConsumable(value) {
      if (value) {
        this.searchData(this, value);
      }
    },
  },
  mounted() {
    this.getConsumable();
    this.showAllConsumable();
  },
  methods: {
    searchData: _.debounce((v, keyword) => v.getConsumable(keyword), 650),
    addFromHistory(tools) {
      tools.forEach(tool => {
        this.table.items.push({
          ...tool,
          isDeletable: true,
        });
      });
    },
    getConsumable(keyword) {
      this.searchLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all/names?page=1&itemCount=30&category=Alat Kesehatan&name=${
              !keyword ? '' : keyword
            }`,
          ),
        )
        .then(response => {
          let data = response.data.data;
          this.resource.consumable = data.map(bhp => {
            return {
              id: {
                id_drugs: bhp.id_drugs,
                id_goods: bhp.id_goods,
              },
              name: bhp.drugs.detail.name,
              hpp: bhp.drugs.detail.hpp * 1.25,
              packaging: bhp.package,
              price: bhp.price,
            };
          });
          this.searchLoading = false;
        });
    },
    addData() {
      const payload = {
        id_registration: this.patientData.id_registration,
        id_emr: this.idEmr,
        isTakeHome: false,
        patient_status: this.patientStatus,
        medical_tools: this.table.items.filter(
          item => item.isDeletable === true,
        ),
        mix_recipe: [],
        recipe: [],
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.id,
        },
      };
      axios
        .post(
          Constant.apiUrl.concat(
            `/inpatient/drug/recomendation/${this.patientData.id_registration}?type=${this.poly}`,
          ),
          payload,
        )
        .then(response => {
          if (response.status === 200) {
            Swal.fire('Permintaan Alkes/BHP berhasil diorder!', '', 'success');
            this.$emit('close-dialog');
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    addToTable() {
      if (this.input.name.id.id_goods !== null) {
        this.table.items.push({
          id_goods: this.input.name.id.id_goods,
          date: moment(new Date()).format(),
          dateDisplay: moment(new Date()).format('DD/MM/YYYY hh:mm'),
          name: this.input.name.name,
          isOrdered: true,
          quantity: this.input.amount,
          packaging: this.input.name.packaging,
          staff: this.$store.getters.userLoggedIn.id,
          price: this.input.name.price,
          isConfirmed: false,
          isDeletable: true,
          status: 'Order belum dikonfirmasi',
        });
      } else {
        this.table.items.push({
          price: this.input.name.price,
          id_drugs: this.input.name.id.id_drugs,
          date: moment(new Date()).format(),
          dateDisplay: moment(new Date()).format('DD/MM/YYYY hh:mm'),
          name: this.input.name.name,
          quantity: this.input.amount,
          isOrdered: true,
          packaging: this.input.name.packaging,
          staff: this.$store.getters.userLoggedIn.id,
          isConfirmed: false,
          isDeletable: true,
          status: 'Order belum dikonfirmasi',
        });
      }

      this.input = {
        name: '',
        amount: '',
      };
    },
    deleteItem(item) {
      const index = this.table.items.indexOf(item);
      this.table.items.splice(index, 1);
    },
    showAllConsumable() {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/inpatient/drugadministration/medical_tools/${this.patientData.id_registration}`,
          ),
        )
        .then(response => {
          const data = response.data.data;
          this.table.items = data.map(consume => {
            return {
              date: consume.date,
              dateDisplay: moment(consume.date).format('DD/MM/YYYY hh:mm'),
              name: consume.name,
              quantity: consume.quantity,
              packaging: consume.packaging,
              isOrdered: true,
              price: consume.price,
              isDeletable: false,
              staff: consume.staff,
              status: consume.isApproved
                ? 'Order sudah dikonfirmasi'
                : 'Order belum dikonfirmasi',
            };
          });
          this.loading = false;
        })
        .catch(() => {
          this.table.items = [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: grid;
  width: 100%;
  height: 8vh;
  grid-template-columns: 5% 20% 30% 10% 10% 7% 15% 3%;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  background-color: #f4f5f5;
  border-bottom: 1px solid #9ca2a5;
}
.table-items-container {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.table-items {
  display: grid;
  width: 100%;
  border-bottom: 0.5px solid #e4ecf0;
  height: 8vh;
  grid-template-columns: 5% 20% 30% 10% 10% 7% 15% 3%;
  align-items: center;
  font-size: 0.8rem;
}
.allergy {
  background-color: #fff2f2 !important;
  font-weight: 400;
  font-size: 13px;
  justify-content: center;
  border: 0.5px solid #eb4747 !important;
  color: #eb4747;
}
.header-grid {
  padding-left: 12px;
  font-size: 0.9rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr 2fr);
  row-gap: 5px;
}
.container-height {
  height: 81vh;
  overflow: auto;
}

.btn-rounded {
  border-radius: 4px;
}
</style>
