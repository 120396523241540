import Constant from '@/const';
import axios from 'axios';

const getNurseIntervention = async id => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/master/nursediagnose/intervention/${id}`),
    );
    return response.data;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getNurseIntervention;
