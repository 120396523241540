<template>
  <v-container fluid class="main">
    <v-row>
      <v-col cols="6" class="text-left pb-0 mr-15">
        <h2 class="title">Laporan Pasien Rujukan BPJS</h2>
        <v-row class="pa-3 update pt-4">
          <p>
            Update:
            <span> {{ timestamp }}</span>
          </p>
        </v-row>
      </v-col>
      <v-spacer />
      <v-col align-self="center" cols="3" class="d-flex align-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="grey"
              icon
              text
              @click="
                () => {
                  getInstance();
                  getDoctor();
                  getQuota();
                }
              "
              class="btn-refresh mr-8"
              :class="{ loadingrefresh: isLoading }"
            >
              <v-icon :class="{ loadingrefresh: isLoading }"
                >mdi-autorenew
              </v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
        <v-menu transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details="false"
              v-model="monthDateFormat"
              class="input"
              outlined
              append-icon="mdi-chevron-down"
              readonly
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            prepend-inner-icon="mdi-calendar-month"
            type="month"
            v-model="monthDate"
            @input="
              getInstance();
              getDoctor();
              getQuota();
            "
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <div class="container-wrapper">
      <v-card class="total-card rounded-lg" elevation="0">
        <div class="pl-4 pt-4 text-left white--text">
          <p class="caption mb-0" style="font-weight: 500">
            Total Kuota BPJS
          </p>
          <p class="mt-6" style="font-weight: 700; font-size: 22px">
            {{ quotaTotal }}
            pasien
          </p>
        </div>
      </v-card>
      <v-card class="used-card rounded-lg" elevation="0">
        <div class="pl-4 pt-4 text-left white--text">
          <p class="caption mb-0" style="font-weight: 500">
            Kuota Terpakai
          </p>
          <p class="mt-6" style="font-weight: 700; font-size: 22px">
            {{ usedQuota }}
            pasien
          </p>
        </div>
      </v-card>
      <v-card class="remain-card rounded-lg" elevation="0">
        <div class="pl-4 pt-4 text-left white--text">
          <p class="caption mb-0" style="font-weight: 500">
            Sisa Kuota
            <v-tooltip v-if="warning === true && !isLoading" bottom>
              <template #activator="{ on: tooltip }">
                <v-icon
                  color="white"
                  v-on="{ ...tooltip }"
                  small
                  style="cursor: pointer;"
                >
                  mdi-alert-circle-outline
                </v-icon>
              </template>
              <span
                >Kuota bulan ini telah terpakai melebihi batas maksimal</span
              >
            </v-tooltip>
          </p>

          <p class="mt-6" style="font-weight: 700; font-size: 22px">
            {{ remainQuota }} pasien
          </p>
        </div>
      </v-card>
    </div>
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col class="text-left title">
            <h5>Tujuan Instansi RS Rujukan</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <table>
              <thead>
                <tr class="grid-th">
                  <th class="text-center">No.</th>
                  <th class="text-start">Nama Instansi RS</th>
                  <th class="text-start">Jumlah Pasien</th>
                </tr>
              </thead>
              <v-divider />
              <tbody class="scrollbar" id="scroll-target">
                <transition-group name="slide-fade" appear>
                  <div v-for="(item, index) in hospital.items" :key="index + 1">
                    <tr class="grid-td row-td">
                      <td class="text-center">{{ index + 1 }}</td>
                      <td class="text-left">{{ item.hospitalName }}</td>
                      <td class="text-left">{{ item.amount }}</td>
                    </tr>
                    <v-divider />
                  </div>
                </transition-group>
                <transition name="slide-fade" appear>
                  <div
                    v-if="hospital.items.length === 0"
                    class="text-center mt-4"
                    style="font-weight: 400; font-size: 12px;"
                  >
                    <span>Tidak ada data yang ditemukan</span>
                  </div>
                </transition>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col class="text-left title">
            <h5>Nama Dokter Perujuk</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <table>
              <thead>
                <tr class="grid-th">
                  <th class="text-center">No.</th>
                  <th class="text-start">Nama Instansi RS</th>
                  <th class="text-start">Jumlah Pasien</th>
                </tr>
              </thead>
              <v-divider />
              <tbody class="scrollbar" id="scroll-target">
                <transition-group name="slide-fade" appear>
                  <div v-for="(item, index) in doctors.items" :key="index + 1">
                    <tr class="grid-td row-td">
                      <td class="text-center">{{ index + 1 }}</td>
                      <td class="text-left">{{ item.doctorsName }}</td>
                      <td class="text-left">{{ item.amount }}</td>
                    </tr>
                    <v-divider />
                  </div>
                </transition-group>
                <transition name="slide-fade" appear>
                  <div
                    v-if="doctors.items.length === 0"
                    class="text-center mt-4"
                    style="font-weight: 400; font-size: 12px;"
                  >
                    <span>Tidak ada data yang ditemukan</span>
                  </div>
                </transition>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <router-link
        to="/reporting/laporan-pelayanan/pasien-rujukan-bpjs/detail"
        active
      >
        <v-btn
          color="#137BC0"
          class="#137BC0--text text-none mr-2"
          elevation="0"
          outlined
        >
          <span style="letter-spacing: 0px !important;">
            Lihat Detail Pasien
            <v-icon style="scale: 0.8;">
              mdi-arrow-right
            </v-icon>
          </span>
        </v-btn>
      </router-link>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment-timezone';
import { getReportDashboardBPJS } from '@/fetchApi/Report/BPJSReport';

export default {
  data() {
    return {
      isLoading: false,
      timestamp: moment().format('DD MMMM YYYY'),
      quotaTotal: 0,
      usedQuota: 0,
      remainQuota: null,
      warning: false,
      hospital: {
        headers: [
          { text: 'No', value: 'no', width: '12%' },
          { text: 'Nama Instansi RS', value: 'hospitalName' },
          { text: 'Jumlah Pasien', value: 'amount', width: '25%' },
        ],
        items: [],
      },
      doctors: {
        headers: [
          { text: 'No', value: 'no', width: '12%' },
          { text: 'Nama Dokter', value: 'doctorsName' },
          { text: 'Jumlah Pasien', value: 'amount', width: '25%' },
        ],
        items: [],
      },
      date: '',
      monthDate: moment().format('YYYY-MM'),
    };
  },
  computed: {
    monthDateFormat() {
      return moment(this.monthDate, 'YYYY-MM').format('MM-YYYY');
    },
  },
  created() {
    setInterval(this.getNow, 500);
  },
  mounted() {
    this.$nextTick(() => {
      this.getInstance();
      this.getDoctor();
      this.getQuota();
    });
  },
  methods: {
    getQuota() {
      this.isLoading = true;
      getReportDashboardBPJS({
        date: this.monthDate + '-1',
        type: 'total',
      })
        .then(response => {
          const data = response.data.data;
          this.remainQuota = data.remain_quota;
          this.quotaTotal = data.total_quota;
          this.usedQuota = data.used_quota;
          this.quota();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    quota() {
      if (this.usedQuota > this.quotaTotal) {
        this.remainQuota = 0;
        this.warning = true;
      } else {
        this.remainQuota = this.quotaTotal - this.usedQuota;
        this.warning = false;
      }
    },
    getInstance() {
      getReportDashboardBPJS({
        date: this.monthDate + '-1',
        type: 'instance',
      })
        .then(response => {
          const data = response.data.data;
          this.hospital.items = data.map(hospital => {
            return {
              hospitalName: hospital.instansi,
              amount: hospital.count,
            };
          });
        })
        .catch(err => {
          this.hospital.items = [];
          return err;
        });
    },
    getDoctor() {
      getReportDashboardBPJS({
        date: this.monthDate + '-1',
        type: 'doctor',
      })
        .then(response => {
          const data = response.data.data;
          this.doctors.items = data.map(doctors => {
            return {
              doctorsName: doctors.doctor,
              amount: doctors.count,
            };
          });
        })
        .catch(err => {
          this.doctors.items = [];
          return err;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 2rem;
  .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #404040;
  }
  .update {
    color: #757575;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .container-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }
  .total-card {
    width: 100%;
    background-image: linear-gradient(to right, #80a1ff, #b2d1ff);
  }
  .used-card {
    width: 100%;
    background-image: linear-gradient(to right, #ff8a58, #ffd08d);
  }
  .remain-card {
    width: 100%;
    background-image: linear-gradient(to right, #4b966b, #7ccf9f);
  }
  .remain-card-warning {
    width: 100%;
    background-image: linear-gradient(to right, #eb5757, #eba4a4);
  }

  table {
    width: 100%;
    border: 1px solid #e0e0e0;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  .grid-th {
    display: grid;
    grid-template-columns: 12% auto 25% 0.4rem;
  }
  .grid-td {
    display: grid;
    grid-template-columns: 12% auto 25%;
  }

  .grid-th th {
    padding: 12px 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #9e9e9e;
  }
  .grid-td td {
    padding: 12px 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: #404040;
  }

  .scrollbar {
    display: block;
    height: 32vh;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0.4rem;
      border-radius: 1rem;
      background-color: rgb(214, 214, 214);
    }
    &::-webkit-scrollbar-thumb {
      width: 0.4rem;
      border-radius: 1rem;
      background-color: rgb(146, 146, 146);
    }
  }
}

.loadingrefresh {
  -webkit-animation: fa-spin 0.2s infinite linear;
  -moz-animation: fa-spin 0.2s infinite linear;
  -o-animation: fa-spin 0.2s infinite linear;
  animation: fa-spin 0.2s infinite linear;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
