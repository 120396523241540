<template>
  <div>
    <v-container class="ma-0 pa-0 mt-2 mx-9">
      <v-row no-gutters align="center">
        <v-col cols="6" class="text-left">
          <v-toolbar-title>
            <h3>Laporan Jasa Medis</h3>
          </v-toolbar-title>
        </v-col>
        <v-spacer></v-spacer>
        <router-link :to="routeBack" active>
          <font-awesome-icon color="grey" icon="home" />
        </router-link>
        <v-btn rounded color="primary" depressed class="ml-4 text-none"
          >Export</v-btn
        >
        <v-text-field
          background-color="grey lighten-3"
          prepend-inner-icon="mdi-magnify"
          placeholder="Search"
          class="ml-4"
          solo
          dense
          flat
          hide-details
          single-line
          v-model="search"
        ></v-text-field>
        <v-btn color="grey" icon @click="getDebt()">
          <v-icon>mdi-autorenew</v-icon>
        </v-btn>
      </v-row>
      <v-row class="pa-0 my-0">
        <v-col cols="3" class="date">
          <v-menu
            v-model="datePicker"
            dense
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                dense
                class="input mr-4"
                label="Bulan"
                readonly
                v-bind="attrs"
                v-on="on"
                v-model="date"
                hide-details="auto"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              :max="today"
              type="month"
              width="262px"
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-combobox
            class="line-long mt-0 pt-0"
            :items="resource.doctors"
            label="Pilih Dokter"
            outlined
            dense
            item-value="doctorId"
            item-text="doctorName"
            clearable
            v-model="doctor"
          ></v-combobox>
        </v-col>
      </v-row>
    </v-container>
    <v-tabs grow centered v-model="tabs">
      <v-tab
        v-for="tab in resource.tabs"
        :key="`tab-${tab}`"
        class="text-none font-weight-bold"
        >{{ tab }}</v-tab
      >
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <PeriodeTotal
          :date="date"
          :doctor="doctor"
          :data="tableItems.periodTotalItems"
        />
      </v-tab-item>
      <v-tab-item>
        <All :date="date" :doctor="doctor" :data="tableItems.allItems" />
      </v-tab-item>
      <v-tab-item>
        <Bpjs :date="date" :doctor="doctor" :data="tableItems.bpjsItems" />
      </v-tab-item>
      <v-tab-item>
        <Cash :date="date" :doctor="doctor" :data="tableItems.cashItems" />
      </v-tab-item>
      <v-tab-item>
        <OtherAssurance
          :date="date"
          :doctor="doctor"
          :data="tableItems.otherAssuranceItems"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-col>
      <template v-if="paymentState === true">
        <v-dialog v-model="dialog" width="1000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              elevation="0"
              color="primary"
              class="btn-add"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark> mdi-plus-thick </v-icon>
            </v-btn>
          </template>
          <MedPaymentInput v-if="dialog" @close-dialog="closeDialog" />
        </v-dialog>
      </template>
    </v-col>
  </div>
</template>

<script>
import MedPaymentInput from './MedService/MedPaymentInput.vue';
import Constant from '@/const';
import moment from 'moment-timezone';
import Bpjs from './MedService/Tables/Bpjs.vue';
import PeriodeTotal from './MedService/Tables/PeriodeTotal.vue';
import All from './MedService/Tables/All.vue';
import Cash from './MedService/Tables/Cash.vue';
import OtherAssurance from './MedService/Tables/OtherAssurance.vue';

const axios = require('axios');

export default {
  name: 'MedService',
  components: {
    MedPaymentInput,
    PeriodeTotal,
    All,
    Bpjs,
    Cash,
    OtherAssurance,
  },
  data() {
    return {
      tabs: null,
      search: '',
      paymentState: false,
      menu: false,
      menuLast: false,
      menuPay: false,
      menuPayend: false,
      filterDebt: false,
      filterPayment: false,
      dialog: false,
      date: '',
      datePicker: false,
      doctor: '',
      today: moment(new Date()).format('YYYY-MM-DD'),
      tableItems: {
        periodTotalItems: [],
        allItems: [],
        bpjsItems: [],
        cashItems: [],
        otherAssuranceItems: [],
      },
      resource: {
        paymentType: ['Cash', 'Transfer'],
        tabs: [
          'Total Periode',
          'Keseluruhan',
          'BPJS',
          'Tunai',
          'Asuransi Lainnya',
        ],
        period: [],
        doctors: [],
      },
      loading: false,
      filtered: {
        doctor: '',
        period: '',
        type: '',
      },
    };
  },
  computed: {
    routeBack() {
      return '/keuangan/billing';
    },
  },
  mounted() {
    this.getDoctor();
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    getDoctor() {
      axios.get(Constant.apiUrl.concat('/master/staff')).then(response => {
        if (response.status === 200) {
          var data = response.data.data;
          this.resource.doctors = data
            .filter(
              v =>
                v.role.role.some(item => item.toLowerCase() === 'dokter') &&
                v.role.status === true,
            )
            .map(doctor => {
              return {
                doctorId: doctor._id.toString(),
                doctorName: doctor.detail.name,
              };
            });
        }
      });
    },
    getDebt() {
      const month = this.date ? this.date : moment();
      const startOfMonth = moment(month)
        .startOf('month')
        .format('YYYY-MM-DD');
      const endOfMonth = moment(month)
        .endOf('month')
        .format('YYYY-MM-DD');
      this.loading = true;
      try {
        axios
          .get(
            Constant.apiUrl.concat(
              `/master/staff/fee?startDate=${startOfMonth ||
                ''}&endDate=${endOfMonth || ''}`,
            ),
          )
          .then(response => {
            const { data } = response.data;
            this.debt.pagination.pageLength = Math.ceil(data.length / 5);
            const debt = data.map(arr => {
              return {
                serviceDate: '-',
                doctorName: arr.detail.name,
                outpatient: arr.service_history.find(
                  x => x.type === 'rawat jalan',
                ),
                igd: arr.service_history.find(x => x.type === 'igd'),
                inpatient: arr.service_history.find(
                  x => x.type === 'rawat inap',
                ),
                medserviceTotal: this.formatNumber(arr.total),
              };
            });
            debt.forEach((x, idx) => {
              debt[idx].remaindebtTotal =
                x.outpatient && x.igd && x.inpatient
                  ? x.inpatient.history[0].fee.owed +
                    x.outpatient.history[0].fee.owed +
                    x.igd.history[0].fee.owed
                  : 0;
            });

            this.debt.items = debt.filter(x => x.remaindebtTotal !== 0);
          });
      } catch {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.date {
  width: 295px;
}

.btn-add {
  position: absolute;
  bottom: 50px;
  right: 30px;
}
.page {
  position: absolute;
  bottom: 5vh;
}
.caption-payment {
  font-size: 10px;
  font-weight: 500;
  color: #a6adb4;
}
::v-deep i.v-icon.notranslate.mdi.mdi-calendar-month.theme--light {
  color: #3498db;
}
</style>
