import {
  postNeonatusAssessment,
  getNeonatusAssessment,
} from '@/fetchApi/Inpatient/Neonatus';
import {
  mapToPayload,
  mapDataByKey,
} from './MapHelper/mapNeonatusAssessmentData';
import { postUploadFile, deleteFile } from '@/fetchApi/UploadFile';
import { generateNeonatusBadge } from './InpatientAssessmentUtils';

const _ = require('lodash');

const state = {
  id: {
    emr: '',
    registration: '',
    inpatient: '',
  },
  tab: 0,
  tabsResource: [
    {
      key: 'information',
      text: 'Informasi Ibu dan Bayi',
    },
    {
      key: 'physical',
      text: 'Pengkajian Fisik',
    },
    {
      key: 'apgar_score',
      text: 'Apgar Score',
    },
    {
      key: 'stamp',
      text: 'Cap Ibu & Bayi',
    },
  ],
  form: {
    information: {
      staff: '',
      dateFill: {
        raw: '',
        formatted: '',
      },
      dateVisit: {
        raw: '',
        formatted: '',
      },
      time: {
        visit: '',
        fill: '',
      },
      mother: {
        data: '',
        name: '',
        profession: '',
        birthDate: {
          raw: '',
          formatted: '',
        },
        status: {
          g: '',
          p: '',
          a: '',
        },
      },
      father: {
        name: '',
        profession: '',
      },
      baby: {
        birthTime: {
          date: {
            raw: '',
            formatted: '',
          },
          time: '',
        },
        birthType: '',
        weight: '',
        length: '',
        chestCircumference: '',
        headCircumference: '',
      },
      isSaved: false,
    },
    physical: {
      eyes_infection: null,
      mouth: {
        suction_reflex: null,
        cyanotic_lips: null,
        harelip: null,
        palatosic: null,
      },
      limb: {
        movement: null,
        polydactyly: null,
        syndactyly: null,
        other_abnormalities: {
          description: '',
          check: null,
        },
      },
      chest: {
        respiratory_frequency: {
          check: null,
          value: '',
        },
        heart_rate: {
          check: null,
          value: '',
        },
      },
      belly: {
        umbilical_cord_bleeding: null,
        protrusion_around_the_umbilical_cord: null,
      },
      sex: {
        boy: {
          scrotum: null,
          testicles: null,
          penis_hole: null,
        },
        girl: {
          vagina_hole: null,
          uretra_hole: null,
        },
      },
      anus_hole: null,
      abnormalities: '',
      notes: {
        postscript: '',
        built_in_note: '',
      },
      isSaved: true,
    },
    apgar_score: {
      minute1: {
        appearance: {
          name: '',
          score: null,
        },
        pulse: {
          name: '',
          score: null,
        },
        grimace: {
          name: '',
          score: null,
        },
        activity: {
          name: '',
          score: null,
        },
        respiration: {
          name: '',
          score: null,
        },
      },
      minute5: {
        appearance: {
          name: '',
          score: null,
        },
        pulse: {
          name: '',
          score: null,
        },
        grimace: {
          name: '',
          score: null,
        },
        activity: {
          name: '',
          score: null,
        },
        respiration: {
          name: '',
          score: null,
        },
      },
      minute10: {
        appearance: {
          name: '',
          score: null,
        },
        pulse: {
          name: '',
          score: null,
        },
        grimace: {
          name: '',
          score: null,
        },
        activity: {
          name: '',
          score: null,
        },
        respiration: {
          name: '',
          score: null,
        },
      },
      total: {
        minute1: {
          text: '',
          score: null,
        },
        minute5: {
          text: '',
          score: null,
        },
        minute10: {
          text: '',
          score: null,
        },
      },
      isSaved: false,
    },
    stamp: {
      isSaved: false,
      baby: {
        right_leg: {
          file: null,
          url: '',
          rejectMessage: null,
          identifier: '',
          isDragHovered: false,
          isUploadable: false,
        },
        left_leg: {
          file: null,
          url: '',
          rejectMessage: null,
          identifier: '',
          isDragHovered: false,
          isUploadable: false,
        },
      },
      mother: {
        right_thumb: {
          file: null,
          url: '',
          rejectMessage: null,
          identifier: '',
          isDragHovered: false,
          isUploadable: false,
        },
        left_thumb: {
          file: null,
          url: '',
          rejectMessage: null,
          identifier: '',
          isDragHovered: false,
          isUploadable: false,
        },
      },
    },
  },
  initialState: {
    information: {
      staff: '',
      dateFill: {
        raw: '',
        formatted: '',
      },
      dateVisit: {
        raw: '',
        formatted: '',
      },
      time: {
        visit: '',
        fill: '',
      },
      mother: {
        data: '',
        name: '',
        profession: '',
        birthDate: {
          raw: '',
          formatted: '',
        },
        status: {
          g: '',
          p: '',
          a: '',
        },
      },
      father: {
        name: '',
        profession: '',
      },
      baby: {
        birthTime: {
          date: {
            raw: '',
            formatted: '',
          },
          time: '',
        },
        birthType: '',
        weight: '',
        length: '',
        chestCircumference: '',
        headCircumference: '',
      },
      isSaved: false,
    },
    physical: {
      eyes_infection: null,
      mouth: {
        suction_reflex: null,
        cyanotic_lips: null,
        harelip: null,
        palatosic: null,
      },
      limb: {
        movement: null,
        polydactyly: null,
        syndactyly: null,
        other_abnormalities: {
          description: '',
          check: null,
        },
      },
      chest: {
        respiratory_frequency: {
          check: null,
          value: '',
        },
        heart_rate: {
          check: null,
          value: '',
        },
      },
      belly: {
        umbilical_cord_bleeding: null,
        protrusion_around_the_umbilical_cord: null,
      },
      sex: {
        boy: {
          scrotum: null,
          testicles: null,
          penis_hole: null,
        },
        girl: {
          vagina_hole: null,
          uretra_hole: null,
        },
      },
      anus_hole: null,
      abnormalities: '',
      notes: {
        postscript: '',
        built_in_note: '',
      },
      isSaved: true,
    },
    apgar_score: {
      minute1: {
        appearance: {
          name: '',
          score: null,
        },
        pulse: {
          name: '',
          score: null,
        },
        grimace: {
          name: '',
          score: null,
        },
        activity: {
          name: '',
          score: null,
        },
        respiration: {
          name: '',
          score: null,
        },
      },
      minute5: {
        appearance: {
          name: '',
          score: null,
        },
        pulse: {
          name: '',
          score: null,
        },
        grimace: {
          name: '',
          score: null,
        },
        activity: {
          name: '',
          score: null,
        },
        respiration: {
          name: '',
          score: null,
        },
      },
      minute10: {
        appearance: {
          name: '',
          score: null,
        },
        pulse: {
          name: '',
          score: null,
        },
        grimace: {
          name: '',
          score: null,
        },
        activity: {
          name: '',
          score: null,
        },
        respiration: {
          name: '',
          score: null,
        },
      },
      total: {
        minute1: {
          text: '',
          score: null,
        },
        minute5: {
          text: '',
          score: null,
        },
        minute10: {
          text: '',
          score: null,
        },
      },
      isSaved: false,
    },
    stamp: {
      isSaved: false,
      baby: {
        right_leg: {
          file: null,
          url: '',
          rejectMessage: null,
          identifier: '',
          isDragHovered: false,
          isUploadable: false,
        },
        left_leg: {
          file: null,
          url: '',
          rejectMessage: null,
          identifier: '',
          isDragHovered: false,
          isUploadable: false,
        },
      },
      mother: {
        right_thumb: {
          file: null,
          url: '',
          rejectMessage: null,
          identifier: '',
          isDragHovered: false,
          isUploadable: false,
        },
        left_thumb: {
          file: null,
          url: '',
          rejectMessage: null,
          identifier: '',
          isDragHovered: false,
          isUploadable: false,
        },
      },
    },
  },
  toBeDeletedFile: [],
  loading: {
    save: false,
    get: false,
  },
  showSuccessMessage: {
    text: null,
    show: false,
  },
};

const getters = {
  getTab: state => state.tab,
  getTabsResource: state => state.tabsResource,
  getInitialState: state => state.initialState,
  getShowSuccessMessage: state => state.showSuccessMessage,
  getForm: state => state.form,
  getLoading: state => state.loading,
  getId: state => state.id,
  getIsInitialInformationModified: state => {
    const current = state.form.information;

    return !_.isEqual(current, state.initialState.information);
  },
  getIsInitialPhysicalModified: state => {
    const current = state.form.physical;

    return !_.isEqual(current, state.initialState.physical);
  },
  getIsInitialApgarModified: state => {
    const current = state.form.apgar_score;

    return !_.isEqual(current, state.initialState.apgar_score);
  },
  getIsInitialStampModified: state => {
    const current = state.form.stamp;

    return !_.isEqual(current, state.initialState.stamp);
  },
};

const mutations = {
  clearNeonatusState(state) {
    state.tab = 0;
    state.tabsResource = [
      {
        key: 'information',
        text: 'Informasi Ibu dan Bayi',
      },
      {
        key: 'physical',
        text: 'Pengkajian Fisik',
      },
      {
        key: 'apgar_score',
        text: 'Apgar Score',
      },
      {
        key: 'stamp',
        text: 'Cap Ibu & Bayi',
      },
    ];
    state.form = {
      information: {
        staff: '',
        dateFill: {
          raw: '',
          formatted: '',
        },
        dateVisit: {
          raw: '',
          formatted: '',
        },
        time: {
          visit: '',
          fill: '',
        },
        mother: {
          data: '',
          name: '',
          profession: '',
          birthDate: {
            raw: '',
            formatted: '',
          },
          status: {
            g: '',
            p: '',
            a: '',
          },
        },
        father: {
          name: '',
          profession: '',
        },
        baby: {
          birthTime: {
            date: {
              raw: '',
              formatted: '',
            },
            time: '',
          },
          birthType: '',
          weight: '',
          length: '',
          chestCircumference: '',
          headCircumference: '',
        },
        isSaved: false,
      },
      physical: {
        eyes_infection: null,
        mouth: {
          suction_reflex: null,
          cyanotic_lips: null,
          harelip: null,
          palatosic: null,
        },
        limb: {
          movement: null,
          polydactyly: null,
          syndactyly: null,
          other_abnormalities: {
            description: '',
            check: null,
          },
        },
        chest: {
          respiratory_frequency: {
            check: null,
            value: '',
          },
          heart_rate: {
            check: null,
            value: '',
          },
        },
        belly: {
          umbilical_cord_bleeding: null,
          protrusion_around_the_umbilical_cord: null,
        },
        sex: {
          boy: {
            scrotum: null,
            testicles: null,
            penis_hole: null,
          },
          girl: {
            vagina_hole: null,
            uretra_hole: null,
          },
        },
        anus_hole: null,
        abnormalities: '',
        notes: {
          postscript: '',
          built_in_note: '',
        },
        isSaved: true,
      },
      apgar_score: {
        minute1: {
          appearance: {
            name: '',
            score: null,
          },
          pulse: {
            name: '',
            score: null,
          },
          grimace: {
            name: '',
            score: null,
          },
          activity: {
            name: '',
            score: null,
          },
          respiration: {
            name: '',
            score: null,
          },
        },
        minute5: {
          appearance: {
            name: '',
            score: null,
          },
          pulse: {
            name: '',
            score: null,
          },
          grimace: {
            name: '',
            score: null,
          },
          activity: {
            name: '',
            score: null,
          },
          respiration: {
            name: '',
            score: null,
          },
        },
        minute10: {
          appearance: {
            name: '',
            score: null,
          },
          pulse: {
            name: '',
            score: null,
          },
          grimace: {
            name: '',
            score: null,
          },
          activity: {
            name: '',
            score: null,
          },
          respiration: {
            name: '',
            score: null,
          },
        },
        total: {
          minute1: {
            text: '',
            score: null,
          },
          minute5: {
            text: '',
            score: null,
          },
          minute10: {
            text: '',
            score: null,
          },
        },
        isSaved: false,
      },
      stamp: {
        isSaved: false,
        baby: {
          right_leg: {
            file: null,
            url: '',
            rejectMessage: null,
            identifier: '',
            isDragHovered: false,
            isUploadable: false,
          },
          left_leg: {
            file: null,
            url: '',
            rejectMessage: null,
            identifier: '',
            isDragHovered: false,
            isUploadable: false,
          },
        },
        mother: {
          right_thumb: {
            file: null,
            url: '',
            rejectMessage: null,
            identifier: '',
            isDragHovered: false,
            isUploadable: false,
          },
          left_thumb: {
            file: null,
            url: '',
            rejectMessage: null,
            identifier: '',
            isDragHovered: false,
            isUploadable: false,
          },
        },
      },
    };
    state.initialState = {
      information: {
        staff: '',
        dateFill: {
          raw: '',
          formatted: '',
        },
        dateVisit: {
          raw: '',
          formatted: '',
        },
        time: {
          visit: '',
          fill: '',
        },
        mother: {
          data: '',
          name: '',
          profession: '',
          birthDate: {
            raw: '',
            formatted: '',
          },
          status: {
            g: '',
            p: '',
            a: '',
          },
        },
        father: {
          name: '',
          profession: '',
        },
        baby: {
          birthTime: {
            date: {
              raw: '',
              formatted: '',
            },
            time: '',
          },
          birthType: '',
          weight: '',
          length: '',
          chestCircumference: '',
          headCircumference: '',
        },
        isSaved: false,
      },
      physical: {
        eyes_infection: null,
        mouth: {
          suction_reflex: null,
          cyanotic_lips: null,
          harelip: null,
          palatosic: null,
        },
        limb: {
          movement: null,
          polydactyly: null,
          syndactyly: null,
          other_abnormalities: {
            description: '',
            check: null,
          },
        },
        chest: {
          respiratory_frequency: {
            check: null,
            value: '',
          },
          heart_rate: {
            check: null,
            value: '',
          },
        },
        belly: {
          umbilical_cord_bleeding: null,
          protrusion_around_the_umbilical_cord: null,
        },
        sex: {
          boy: {
            scrotum: null,
            testicles: null,
            penis_hole: null,
          },
          girl: {
            vagina_hole: null,
            uretra_hole: null,
          },
        },
        anus_hole: null,
        abnormalities: '',
        notes: {
          postscript: '',
          built_in_note: '',
        },
        isSaved: true,
      },
      apgar_score: {
        minute1: {
          appearance: {
            name: '',
            score: null,
          },
          pulse: {
            name: '',
            score: null,
          },
          grimace: {
            name: '',
            score: null,
          },
          activity: {
            name: '',
            score: null,
          },
          respiration: {
            name: '',
            score: null,
          },
        },
        minute5: {
          appearance: {
            name: '',
            score: null,
          },
          pulse: {
            name: '',
            score: null,
          },
          grimace: {
            name: '',
            score: null,
          },
          activity: {
            name: '',
            score: null,
          },
          respiration: {
            name: '',
            score: null,
          },
        },
        minute10: {
          appearance: {
            name: '',
            score: null,
          },
          pulse: {
            name: '',
            score: null,
          },
          grimace: {
            name: '',
            score: null,
          },
          activity: {
            name: '',
            score: null,
          },
          respiration: {
            name: '',
            score: null,
          },
        },
        total: {
          minute1: {
            text: '',
            score: null,
          },
          minute5: {
            text: '',
            score: null,
          },
          minute10: {
            text: '',
            score: null,
          },
        },
        isSaved: false,
      },
      stamp: {
        isSaved: false,
        baby: {
          right_leg: {
            file: null,
            url: '',
            rejectMessage: null,
            identifier: '',
            isDragHovered: false,
            isUploadable: false,
          },
          left_leg: {
            file: null,
            url: '',
            rejectMessage: null,
            identifier: '',
            isDragHovered: false,
            isUploadable: false,
          },
        },
        mother: {
          right_thumb: {
            file: null,
            url: '',
            rejectMessage: null,
            identifier: '',
            isDragHovered: false,
            isUploadable: false,
          },
          left_thumb: {
            file: null,
            url: '',
            rejectMessage: null,
            identifier: '',
            isDragHovered: false,
            isUploadable: false,
          },
        },
      },
    };
    state.loading = {
      save: false,
      get: false,
    };
    state.showSuccessMessage = {
      text: null,
      show: false,
    };
  },
  setTabsResource(state, { index, payload }) {
    state.tabsResource.splice(index, 1, {
      ...state.tabsResource[index],
      ...payload,
    });
  },
  setLoading(state, { key, payload }) {
    state.loading[key] = payload;
  },
  setTab(state, value) {
    state.tab = value;
  },
  setForm(state, { key, payload }) {
    state.form[key] = {
      ...payload,
    };
  },
  setApgarScore(state, { min, key, payload }) {
    state.form.apgar_score[`minute${min}`][key] = payload;
  },
  setStampPicture(state, { type, stamp, payload }) {
    state.form.stamp[type][stamp] = {
      ...state.form.stamp[type][stamp],
      ...payload,
    };
  },
  setInitialState(state, { key, payload }) {
    state.initialState[key] = payload;
  },
  setShowSuccessMessage(state, payload) {
    state.showSuccessMessage = payload;
  },
  setNeonatusNeededIds(state, payload) {
    state.id = payload;
  },
  setIsSavedStateByType(state, { key, value }) {
    state.form[key].isSaved = value;
    state.initialState[key].isSaved = value;
  },
  setToBeDeletedFile(state, payload) {
    state.toBeDeletedFile.push(payload);
  },
  setReduceToBeDeletedFile(state, index) {
    state.toBeDeletedFile.splice(index, 1);
  },
  emptyToBeDeletedFileList(state) {
    state.toBeDeletedFile = [];
  },
};

const actions = {
  async resolveGetNeonatusAssessment({ state, commit }, { tab, validate }) {
    const selectedTab = state.tabsResource[tab];
    commit('emptyToBeDeletedFileList'); // empty the to be deleted file list everytime user get neonatus assessment data

    try {
      commit('setLoading', { key: 'get', payload: true });
      const response = await getNeonatusAssessment(
        state.id.inpatient,
        selectedTab.key,
      );
      commit('setForm', {
        key: selectedTab.key,
        payload: mapDataByKey(response, selectedTab.key),
      });
      commit('setInitialState', {
        key: selectedTab.key,
        payload: mapDataByKey(response, selectedTab.key),
      });
    } catch {
      commit('setIsSavedStateByType', { key: selectedTab.key, value: false });
    } finally {
      commit('setLoading', { key: 'get', payload: false });
      if (state.form[selectedTab?.key].isSaved) {
        commit('setTabsResource', {
          index: tab,
          payload: {
            icon: generateNeonatusBadge(state.form[selectedTab.key], tab),
          },
        });
        if (state.form['information'].isSaved) {
          validate();
        }
      }
    }
  },
  async resolvePostNeonatusAssessment(
    { dispatch, state, commit, rootGetters },
    tab,
  ) {
    const selectedTab = state.tabsResource[tab];
    try {
      commit('setLoading', { key: 'save', payload: true });
      const payload = mapToPayload(
        state,
        selectedTab.key,
        rootGetters['userLoggedIn'].id,
      );

      if (selectedTab.key === 'stamp') {
        await dispatch('resolveSubmitFile');
        if (state.toBeDeletedFile.length) {
          await dispatch('resolveDeleteFile');
        }
      }
      await postNeonatusAssessment(state.id.inpatient, payload);
      dispatch('resolveGetNeonatusAssessment', { tab });
      commit('setShowSuccessMessage', {
        text: selectedTab.text,
        show: true,
      });
      setTimeout(() => {
        commit('setShowSuccessMessage', {
          text: '',
          show: false,
        });
      }, 3000);
    } catch (error) {
      commit('setShowSuccessMessage', {
        text: selectedTab.text,
        show: true,
        isFail: true,
      });
      setTimeout(() => {
        commit('setShowSuccessMessage', {
          text: '',
          show: false,
        });
      }, 3000);
    } finally {
      commit('setLoading', { key: 'save', payload: false });
    }
  },
  async resolveSubmitFile({ state, rootGetters }) {
    let allFiles = [];
    for (const person in state.form.stamp) {
      for (const bodyPart in state.form.stamp[person]) {
        allFiles.push({
          file: state.form.stamp[person][bodyPart].file,
          identifier: state.form.stamp[person][bodyPart].identifier,
          tag: state.form.stamp[person][bodyPart].identifier,
          isUploadable: state.form.stamp[person][bodyPart].isUploadable,
        });
      }
    }
    for (let i = 0; i < allFiles.length; i++) {
      if (!allFiles[i].file || !allFiles[i].isUploadable) continue;

      const formData = new FormData();

      formData.append('files', allFiles[i].file);
      formData.append('identifier', `${allFiles[i].identifier}`);
      formData.append('tag', `${allFiles[i].identifier}`);
      formData.append('created_by', rootGetters['userLoggedIn'].id);

      await postUploadFile(formData); // save file to database
    }
  },
  async resolveDeleteFile({ state, commit }) {
    for (let i = 0; i < state.toBeDeletedFile.length; i++) {
      await deleteFile(state.toBeDeletedFile[i]);
      commit('setReduceToBeDeletedFile', i); // delete item from list after deletation
    }
  },
  formValidation({ state, commit }, { destination, origin }) {
    if (destination > origin) {
      for (let i = origin; i < destination; i++) {
        const selectedTab = state.tabsResource[i];
        commit('setTabsResource', {
          index: i,
          payload: {
            icon: generateNeonatusBadge(state.form[selectedTab.key], i),
          },
        });
      }
    } else {
      for (let i = origin; i > destination; i--) {
        const selectedTab = state.tabsResource[i];
        commit('setTabsResource', {
          index: i,
          payload: {
            icon: generateNeonatusBadge(state.form[selectedTab.key], i),
          },
        });
      }
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
