const state = {
  anamnesis: [''],
  allergy_history: [''],
  disease_history: [''],
  treatment_history: '',
  psychologyStatusCheckbox: false,
  psychological_status: [],
  social_economy: '',
  spiritual: '',
};

const getters = {
  getSubjectData: state => state,
};

const mutations = {
  addAnamnesis(state) {
    state.anamnesis.push('');
  },
  deleteAnamnesis(state, payload) {
    state.anamnesis.splice(payload, 1);
  },
  addAllergy(state) {
    state.allergy_history.push('');
  },
  deleteAllergy(state, payload) {
    state.allergy_history.splice(payload, 1);
  },
  addPriorDisease(state) {
    state.disease_history.push('');
  },
  deletePriorDisease(state, payload) {
    state.disease_history.splice(payload, 1);
  },
  clearSubjective(state) {
    Object.assign(state, {
      anamnesis: [''],
      allergy_history: [''],
      disease_history: [''],
      treatment_history: '',
      psychologyStatusCheckbox: false,
      psychological_status: [],
      social_economy: '',
      spiritual: '',
    });
  },
  setSubjective(state, payload) {
    Object.assign(state, { ...payload });
  },
};

export default {
  state,
  getters,
  mutations,
};
