<template>
  <v-card flat class="queue">
    <v-row>
      <v-col cols="2">
        <span>ANTREAN SAAT INI</span>
        <v-progress-circular
          indeterminate
          width="2"
          size="35"
          color="primary"
          v-if="isLoading"
        ></v-progress-circular>
        <span
          v-else
          :class="{ 'grey--text': !isStarted, 'black--text': isStarted }"
          class="black--text font-weight-bold"
          >{{ queue[0]?.queue_code || '-' }}</span
        >
        <span
          v-if="!isLoading"
          :class="{ 'grey--text': !isStarted, 'black--text': isStarted }"
          class="subtitle-1 font-weight-regular"
          >{{ queue[0]?.patient_name || '-' }}</span
        >
      </v-col>
      <v-col cols="2">
        <span>ANTREAN SELANJUTNYA</span>
        <v-progress-circular
          indeterminate
          width="2"
          size="35"
          color="primary"
          v-if="isLoading"
        ></v-progress-circular>
        <span
          v-else
          :class="{ 'grey--text': !isStarted, 'black--text': isStarted }"
          class="black--text font-weight-bold"
          >{{ queue[1]?.queue_code || '-' }}</span
        >
        <span
          v-if="!isLoading"
          :class="{ 'grey--text': !isStarted, 'black--text': isStarted }"
          class="subtitle-1 font-weight-regular"
          >{{ queue[1]?.patient_name || '-' }}</span
        >
      </v-col>
      <v-col cols="2">
        <span>ANTREAN DILEWATI</span>
        <v-progress-circular
          indeterminate
          width="2"
          size="35"
          color="primary"
          v-if="isLoading"
        ></v-progress-circular>
        <span v-else class="black--text font-weight-bold">{{
          isMissedCount
        }}</span>
        <span
          data-service-queue="detail-pass"
          @click="toDetailQueue"
          class="subtitle-1 font-weight-regular primary--text"
          >Lihat Detail</span
        >
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="
            queue?.length === 0 || (isStarted && queue?.length < 2)
              ? () => {}
              : setQueue()
          "
          data-service-queue="next"
          :color="
            queue?.length === 0 || (isStarted && queue?.length < 2)
              ? 'grey'
              : 'primary'
          "
          :loading="isLoading"
          dark
          dense
          depressed
          class="text-capitalize"
          >{{ isStarted ? 'selanjutnya' : 'Mulai Antrean'
          }}<v-icon class="ml-1">mdi-arrow-right</v-icon></v-btn
        >
        <v-btn
          data-service-queue="call"
          @click="call()"
          :disabled="!isStarted || queue?.length === 0 || isCalling"
          color="primary"
          :loading="isLoading"
          outlined
          dense
          depressed
          class="text-capitalize"
          ><v-icon class="mr-1">mdi-bell-badge</v-icon>panggil lagi</v-btn
        >
      </v-col>
      <v-col cols="2">
        <span
          v-if="!isTestSoundActive"
          @click="testSound"
          class="primary--text"
          style="
            position: absolute;
            font-size: 0.9vw;
            font-weight: 500;
            color: #c2c2c2;
            right: 1rem;
            top: 1rem;
            letter-spacing: 0.001vw;
          "
        >
          <v-icon color="primary">
            mdi-volume-high
          </v-icon>
          Test Sound
        </span>
        <label
          v-else
          class="grey--text"
          style="
            position: absolute;
            font-size: 0.9vw;
            font-weight: 500;
            color: #c2c2c2;
            right: 1rem;
            top: 1rem;
            letter-spacing: 0.001vw;
          "
        >
          <v-icon disabled>
            mdi-volume-high
          </v-icon>
          Sedang Diproses...
        </label>
        <span
          @click="toDetailQueue"
          class="primary--text font-weight-regular"
          style="
            position: absolute;
            font-size: 0.9vw;
            font-weight: 500;
            color: #c2c2c2;
            right: 13rem;
            top: 1rem;
            letter-spacing: 0.001vw;
          "
        >
          Detail Antrean
        </span>
        <span
          @click="toDetailPatient"
          class="primary--text font-weight-regular"
          style="
            position: absolute;
            font-size: 0.9vw;
            font-weight: 500;
            color: #c2c2c2;
            right: 20.5rem;
            top: 1rem;
            letter-spacing: 0.001vw;
          "
        >
          Display Antrean (TV)
        </span>
        <span></span>
        <v-btn
          data-service-queue="pass"
          text
          :loading="isLoading"
          class="primary--text text-capitalize"
          color="primary"
          :disabled="!isStarted || queue?.length < 2"
          @click="setQueue(true)"
          >Lewati Antrean
          <v-icon class="ml-1" color="primary">mdi-arrow-right</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DashboardPatientQueueCardScript from './DashboardPatientQueueCard.js';

export default DashboardPatientQueueCardScript;
</script>

<style lang="scss" scoped>
.queue {
  position: relative;
  border: 2px solid #86bcbd;
  display: flex;
  align-items: center;
  border-radius: 0.8vw;
  width: 98%;
  margin: auto;
  min-height: 8vw;
  max-height: fit-content;
  padding: 0.6vw 2vw;

  .col {
    display: flex;
    text-align: start;
    flex-direction: column;
    gap: 0.2vw;

    span:nth-child(1) {
      font-size: 0.9vw;
      font-weight: 500;
      color: #c2c2c2;
    }
    span:nth-child(2) {
      font-size: 1.7vw;
    }
  }

  .col:nth-child(3) {
    span:nth-child(3) {
      cursor: pointer;
    }
    span:nth-child(3):hover {
      text-decoration: underline;
    }
  }

  .col:nth-child(4) {
    display: flex;
    flex-direction: row;
    gap: 1.5vw;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 1vw;

    button {
      letter-spacing: 0.02vw;
      height: 2vw;
      width: fit-content;
      font-size: 0.8vw;
    }

    button:nth-child(2) {
      border: 2px solid !important;
    }
  }

  .col:nth-child(5) {
    justify-content: space-between;
    text-align: end;
    letter-spacing: 0.001vw;
    align-content: flex-end;

    span:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    button {
      width: fit-content;
      letter-spacing: 0.001vw;
      margin-left: auto;
    }
  }
}
</style>
