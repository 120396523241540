<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    :mini-variant-width="isLargeDisplay ? 90 : 60"
    color="#DEEEF9"
    class="pa-2 sidebar"
    permanent
    :width="isLargeDisplay ? 450 : 400"
  >
    <v-list-item
      class="d-flex justify-space-between"
      :class="{
        'mb-3 py-3': mini,
        'pa-0': mini && !isLargeDisplay,
        'pa-2': isLargeDisplay,
      }"
    >
      <v-btn :small="!isLargeDisplay" color="black" text fab v-if="mini"
        ><v-icon :small="!isLargeDisplay">mdi-menu</v-icon></v-btn
      >
      <h3 v-if="isLargeDisplay" class="sidebar-title">Riwayat Assesmen</h3>
      <h4 v-else class="sidebar-title mr-10">Riwayat Assesmen</h4>
      <v-btn small class="ml-xl-3 ml-lg-10" fab text @click.stop="mini = !mini">
        <v-icon large>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>
    <label class="grey--text" v-if="data.length < 1 && !isLoading && !mini"
      >Belum ada riwayat assesmen</label
    >
    <v-list-item v-if="!mini" class="sidebar-history">
      <v-skeleton-loader
        v-if="isLoading"
        class="mb-3"
        type="article"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-if="isLoading"
        class="mb-3"
        type="article"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-if="isLoading"
        class="mb-3"
        type="article"
      ></v-skeleton-loader>
      <history-card
        class="mb-2"
        :data="item"
        v-for="(item, index) in data"
        :key="`card-${index}`"
      />
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
import HistoryCard from './HistoryCard.vue';
import moment from 'moment-timezone';

import Constant from '@/const';
const axios = require('axios');

export default {
  name: 'HistorySidebar',
  components: {
    HistoryCard,
  },
  computed: {
    isLargeDisplay() {
      return this.$vuetify.breakpoint.name === 'xl';
    },
    isAdult() {
      const birthDate = moment(
        this.$store.getters.selectedPatient.meta.birthDate,
        'DD MMMM YYYY',
      );
      const diff = moment().diff(birthDate, 'month');
      return diff > 228;
    },
  },
  data() {
    return {
      mini: false,
      drawer: true,
      isLoading: false,
      data: [],
    };
  },
  mounted() {
    this.showAllHistories();
  },
  methods: {
    async showAllHistories() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/patient/emr/${this.$store.getters.selectedPatient.id_emr}`,
          ),
        );
        const datas = response.data.data.checkup
          .filter(data => {
            if (data.unit[0].name.toLowerCase() !== 'poli fisioterapi') {
              return (
                data.timestamps?.doctor_created_at &&
                data.timestamps.doctor_created_at.length > 0
              );
            } else {
              return (
                (data.timestamps?.doctor_created_at &&
                  data.timestamps.doctor_created_at.length > 0) ||
                (data.timestamps?.nurse_created_at &&
                  data.timestamps.nurse_created_at.length > 0)
              );
            }
          })
          .sort((a, b) => {
            const moment1 = moment(b.timestamps.doctor_created_at);
            const moment2 = moment(a.timestamps.doctor_created_at);
            return moment1.diff(moment2);
          });
        this.data = datas.map((item, index) => {
          const isGeneral =
            item.unit[0].name.toLowerCase() === 'poli fisioterapi';
          return {
            index,
            poly: item.unit[0].name.toUpperCase(),
            date: moment(item.timestamps.doctor_created_at).format(
              'DD MMMM YYYY',
            ),
            time: moment(item.timestamps.doctor_created_at).format('HH:mm'),
            diagnoses: !isGeneral
              ? [item.assesment?.main_diagnose?.diagnose?.description].concat(
                  item.assesment?.other_diagnose?.map(
                    diagnose => diagnose.diagnose.description,
                  ),
                )
              : item.assesment?.diagnoses,
            therapies: item.alldrugs.map(drug => {
              return {
                label: drug.drugs
                  ? `Racik (${drug.usage}, ${drug.quantity} ${drug.packaging})`
                  : 'Non Racik',
                drug: drug.drugs
                  ? drug.drugs
                      .map(mix => {
                        return `${mix.name} ${mix.dosage} ${mix.unit_dosage} ${mix.preparation}`;
                      })
                      .join(', ')
                  : `${drug.name} ${drug.dosage} ${drug.unit_dosage} ${drug.preparation}, ${drug.usage}, ${drug.quantity} ${drug.preparation}`,
              };
            }),
            doctor: item.timestamps?.doctor_by[0]?.detail[0]?.name,
            nurse: item.timestamps?.nurse_by[0]?.detail[0]?.name,
            nutritionScreening: this.nutritionScreeningResult(
              item?.nutrition_screening || null,
            ),
            fallRisk: this.fallRiskResult(item?.assesment?.fallRisk || null),
            imt: this.imtChipDecision(item?.nutrition_category || ''),
            isGeneral: isGeneral,
          };
        });
      } catch (error) {
        this.data = [];
      } finally {
        this.isLoading = false;
      }
    },
    fallRiskResult(data) {
      if (!data) return null;
      let result, score;
      if (this.isAdult) {
        score =
          (data.fallHistory?.score || 0) +
          (data.howToWalk?.score || 0) +
          (data.mentalStatus?.score || 0) +
          (data.secondaryDiagnosis?.score || 0) +
          (data.useHeparinLockTherapy?.score || 0) +
          (data.walkTool?.score || 0);
        if (isNaN(score)) {
          result = null;
        }
        if (score <= 24) {
          result = {
            text: 'Tidak Berisiko Jatuh',
            color: 'green',
          };
        }
        if (score > 24 && score <= 50) {
          result = {
            text: 'Risiko Jatuh Rendah',
            color: 'yellow',
          };
        }
        if (score > 50) {
          result = {
            text: 'Risiko Jatuh Tinggi',
            color: 'red',
          };
        }
      } else {
        score =
          (data.ageScore || 0) +
          (data.cognitiveImpairment?.score || 0) +
          (data.diagnosis?.score || 0) +
          (data.medicinalUse?.score || 0) +
          (data.surgery?.score || 0) +
          (data.genderScore || 0);
        if (isNaN(score)) {
          result = null;
        }
        if (score < 7) {
          result = {
            text: 'Tidak Berisiko Jatuh',
            color: 'green',
          };
        }
        if (score >= 7 && score <= 11) {
          result = {
            text: 'Risiko Jatuh Rendah',
            color: 'yellow',
          };
        }
        if (score > 11) {
          result = {
            text: 'Risiko Jatuh Tinggi',
            color: 'red',
          };
        }
      }
      return result;
    },
    nutritionScreeningResult(data) {
      if (!data) return null;
      let result, score;
      if (this.isAdult) {
        score =
          (data.isFoodIntakePoorDueToDecreasedAppetite?.score || 0) +
          (data.isHaveSeriousIllness?.score || 0) +
          (data.isUnplannedWeightLoss?.score || 0);

        if (isNaN(score)) {
          result = null;
        }
        if (score <= 1) {
          result = {
            text: 'Tidak Berisiko Malnutrisi',
            color: 'green',
            score,
          };
        }
        if (score === 2) {
          result = {
            text: 'Risiko Malnutrisi',
            color: 'yellow',
            score,
          };
        }
        if (score >= 3) {
          result = {
            text: 'Malnutrisi',
            color: 'red',
            score,
          };
        }
      } else {
        score =
          (data.isThereCausesMalnourished?.score || 0) +
          (data.isThereOfTheseCondition?.score || 0) +
          (data.isThin?.score || 0) +
          (data.isWeightLossLastMonth?.score || 0);

        if (isNaN(score)) {
          result = null;
        }
        if (score === 0) {
          result = {
            text: 'Berisiko Rendah',
            color: 'green',
          };
        }
        if (score >= 1 && score <= 3) {
          result = {
            text: 'Berisiko Sedang',
            color: 'yellow',
          };
        }
        if (score >= 4 && score <= 5) {
          result = {
            text: 'Berisiko Tinggi',
            color: 'red',
          };
        }
      }
      return result;
    },
    imtChipDecision(text) {
      if (!text) return null;
      let result;
      switch (text.toLowerCase()) {
        case 'gizi buruk':
        case 'underweight':
        case 'obesitas':
        case 'obesity':
          result = {
            text,
            color: 'red',
          };
          break;
        case 'gizi kurang':
        case 'gizi lebih':
        case 'overweight':
          result = {
            text,
            color: 'yellow',
          };
          break;
        case 'gizi baik':
        case 'normal':
          result = {
            text,
            color: 'green',
          };
          break;
        case 'berisiko gizi lebih':
          result = {
            text,
            color: 'light-yellow',
          };
          break;
      }
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 3px;
    background-color: #c2c2c2;
  }
  ::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: #919191;
  }

  #{&}-title {
    margin-right: 6rem;
    padding-left: 0.8rem;
  }
  #{&}-history {
    display: block;
  }
}
</style>
