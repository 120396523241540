import moment from 'moment-timezone';
import { getStaff } from '../../../fetchApi/laboratory';
import {
  putOperationMarking,
  getInformConsentList,
  postOperationMarking,
  getAllOperationMarking,
  getOperationMarkingById,
} from '../../../fetchApi/OperationMarking';

const state = {
  dashboard: {
    dialog: false,
    snackbar: {
      show: false,
      color: 'success',
      text: '',
    },
  },
  form: {
    action: null,
    date: new Date().toISOString().split('T')[0],
    doctor_executor: '',
    signer: 'pasien',
    signer_name_patient: '',
    signer_name: '',
    mark_body: [],
    mark_face_front: [],
    mark_face_side: [],
    mark_foot_lower: [],
    mark_foot_upper: [],
    mark_palm_lower: [],
    mark_palm_upper: [],
  },
  initialStateForm: {
    action: null,
    date: new Date().toISOString().split('T')[0],
    doctor_executor: '',
    signer: 'pasien',
    signer_name_patient: '',
    signer_name: '',
    mark_body: [],
    mark_face_front: [],
    mark_face_side: [],
    mark_foot_lower: [],
    mark_foot_upper: [],
    mark_palm_lower: [],
    mark_palm_upper: [],
  },
  items: {
    operationMarking: {
      isLoading: false,
      data: [],
    },
    informConsentList: {
      isLoading: false,
      data: [],
    },
    staff: {
      isLoading: false,
      data: [],
    },
  },
};
const getters = {
  // Get State Dialog
  getDialog: state => state.dashboard.dialog,

  // Get Snackbar
  getSnackbar: state => state.dashboard.snackbar,

  // Get State Items
  getItems: state => state.items,

  // Get State Form
  getForm: state => state.form,

  // Get Initial State Form
  getInitialState: state => state.initialStateForm,

  // Get Patient Data from IC state
  getPatientData: (state, getters, rootState) => {
    return rootState.InformedConsent.dataPatient;
  },
};
const mutations = {
  // Set State Dialog
  setDialog(state, payload) {
    state.dashboard.dialog = payload;
  },

  // Set State Items
  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: value,
      isLoading,
    };
  },

  // Set State Form
  setForm(state, payload) {
    state.form = payload;
  },

  // Set State initialStateForm
  setInitialState(state, payload) {
    state.initialStateForm = payload;
  },

  // Set Auto State Staff
  setAutoStaff(state, payload) {
    state.form = {
      ...state.form,
      doctor_executor: payload,
    };
  },

  // Set Autofill Name Patient
  setAutoNamePatient(state, payload) {
    state.form = {
      ...state.form,
      signer_name_patient: payload,
    };
    state.initialStateForm = {
      ...state.initialStateForm,
      signer_name_patient: payload,
    };
  },

  // Set Autofill Name Patient
  setMark(state, { label, payload }) {
    state.form = {
      ...state.form,
      [label]: payload,
    };
  },

  // Set snackbar
  setSnackbar(state, payload) {
    state.dashboard.snackbar = payload;
  },

  // Clear Form
  clearForm(state) {
    state.form = {
      action: null,
      date: new Date().toISOString().split('T')[0],
      doctor_executor: '',
      signer: 'pasien',
      signer_name: '',
      signer_name_patient: '',
      mark_body: [],
      mark_face_front: [],
      mark_face_side: [],
      mark_foot_lower: [],
      mark_foot_upper: [],
      mark_palm_lower: [],
      mark_palm_upper: [],
    };
    state.initialStateForm = {
      action: null,
      date: new Date().toISOString().split('T')[0],
      doctor_executor: '',
      signer: 'pasien',
      signer_name_patient: '',
      signer_name: '',
      mark_body: [],
      mark_face_front: [],
      mark_face_side: [],
      mark_foot_lower: [],
      mark_foot_upper: [],
      mark_palm_lower: [],
      mark_palm_upper: [],
    };
  },

  // Clear Snackbar
  clearSnackbar(state) {
    state.dashboard.snackbar = {
      show: false,
      color: 'success',
      text: '',
    };
  },
};
const actions = {
  // Resolve get all operation marking list
  async resolveGetOperationMarking({ commit, rootState }) {
    commit('setItems', {
      label: 'operationMarking',
      value: [],
      isLoading: true,
    });
    try {
      const getAllOperationMarkingResponse = await getAllOperationMarking(
        rootState.InformedConsent.dataPatient.id_emr,
      );
      const operationMarkingMapped = getAllOperationMarkingResponse.map(
        item => {
          return {
            ...item,
            date: moment(item.date).format('DD-MM-YYYY'),
          };
        },
      );
      commit('setItems', {
        label: 'operationMarking',
        value: operationMarkingMapped,
        isLoading: false,
      });
    } catch (error) {
      commit('setItems', {
        label: 'operationMarking',
        value: [],
        isLoading: false,
      });
    }
  },

  // Resolve get all operation marking list
  async resolveGetOperationMarkingById({ commit, rootState }, idOM) {
    try {
      const response = await getOperationMarkingById(idOM);

      let mappedResponse = {
        ...response,
        action: {
          ...response.action,
          text: `${response.action.date}, ${response.action.time} (${response.action.name})`,
        },
        signer_name: response.signer === 'wali' ? response.signer_name : '',
        signer_name_patient:
          response.signer === 'pasien' ? response.signer_name : '',
      };

      const markTypes = [
        'mark_body',
        'mark_face_front',
        'mark_face_side',
        'mark_foot_lower',
        'mark_foot_upper',
        'mark_palm_lower',
        'mark_palm_upper',
      ];

      markTypes.forEach(markType => {
        mappedResponse[markType] = response[markType].map(item => ({
          position: { x: item.x, y: item.y },
        }));
      });
      commit('setForm', mappedResponse);
      commit('setInitialState', mappedResponse);
      commit(
        'setAutoNamePatient',
        rootState.InformedConsent.dataPatient.name.split(',')[0].trim(),
      );
      return mappedResponse;
    } catch (error) {
      commit('setSnackbar', {
        show: () => {
          commit('setSnackbar', { ...state.snackbar, show: true });
          return setTimeout(() => {
            commit('clearSnackbar');
          }, 4000);
        },
        text: 'Formulir Penandaan Operasi gagal dibuka',
        color: 'error',
      });
      commit('setDialog', false);
      commit('clearForm');
      return error;
    }
  },

  // Resolve get all informed consent list
  async resolveGetInformConsentList({ commit, rootState }) {
    commit('setItems', {
      label: 'informConsentList',
      value: [],
      isLoading: true,
    });
    commit(
      'setAutoNamePatient',
      rootState.InformedConsent.dataPatient.name.split(',')[0].trim(),
    );
    try {
      const getAllInformConsentListResponse = await getInformConsentList(
        rootState.InformedConsent.dataPatient.id_emr,
      );
      const getAllInformConsentListResponseMapper = getAllInformConsentListResponse?.map(
        item => {
          return {
            ...item,
            text: `${item.date}, ${item.time} (${item.name})`,
          };
        },
      );
      commit('setItems', {
        label: 'informConsentList',
        value: getAllInformConsentListResponseMapper,
        isLoading: false,
      });
      return getAllInformConsentListResponse;
    } catch (error) {
      commit('setItems', {
        label: 'informConsentList',
        value: [],
        isLoading: false,
      });
    }
  },

  // Resolve Get Staff Dokter Only
  async resolveGetStaff({ commit }) {
    commit('setItems', {
      label: 'staff',
      value: [],
      isLoading: true,
    });
    try {
      const staffResponse = await getStaff();
      const staffResponseMapper = staffResponse
        .filter(
          item =>
            item.role?.status &&
            item.role?.role.some(item => item.toLowerCase() === 'dokter'),
        )
        .map(i => {
          return {
            name: i.detail.name,
            id: i._id,
          };
        });
      commit('setItems', {
        label: 'staff',
        value: staffResponseMapper,
        isLoading: false,
      });
    } catch {
      commit('setItems', {
        label: 'staff',
        value: [],
        isLoading: false,
      });
    }
  },

  // Post Data
  async resolvePostOperationMarking({ state, rootState, commit, dispatch }) {
    const {
      form: {
        action: { id_ic, name, date, time },
        doctor_executor,
        signer,
        signer_name_patient,
        signer_name,
      },
    } = state;

    let payload = {
      ...state.form,
      action: { id_ic, name, date, time },
      date: moment(state.form.date).format('YYYY-MM-DD'),
      doctor_executor: doctor_executor.id,
      signer_name: signer === 'pasien' ? signer_name_patient : signer_name,
    };

    const markTypes = [
      'mark_body',
      'mark_face_front',
      'mark_face_side',
      'mark_foot_lower',
      'mark_foot_upper',
      'mark_palm_lower',
      'mark_palm_upper',
    ];

    markTypes.forEach(markType => {
      payload[markType] = state.form[markType].map(item => ({
        x: item.position.x,
        y: item.position.y,
      }));
    });
    delete payload.signer_name_patient;
    try {
      const addOperationMarking = await postOperationMarking(
        rootState.InformedConsent.dataPatient.id_emr,
        payload,
      );
      if (addOperationMarking.status === 200) {
        commit('setSnackbar', {
          show: true,
          text: 'Formulir Penandaan Operasi berhasil disimpan',
          color: 'success',
        });
        commit('setDialog', false);
        dispatch('resolveGetInformConsentList');
        commit('clearForm');
        return setTimeout(() => {
          commit('clearSnackbar');
        }, 4000);
      }
    } catch (error) {
      commit('setSnackbar', {
        show: true,
        text: 'Formulir Penandaan Operasi gagal disimpan',
        color: 'error',
      });
      commit('setDialog', false);
      commit('clearForm');
      return setTimeout(() => {
        commit('clearSnackbar');
      }, 4000);
    }
  },
  // End Post Data

  // Put Data
  async resolvePutOperationMarking({ state, commit }, idOM) {
    const {
      form: {
        action: { id_ic, name, date, time },
        doctor_executor,
        signer,
        signer_name_patient,
        signer_name,
      },
    } = state;

    let payload = {
      ...state.form,
      action: { id_ic, name, date, time },
      date: moment(state.form.date).format('YYYY-MM-DD'),
      doctor_executor: doctor_executor.id,
      signer_name: signer === 'pasien' ? signer_name_patient : signer_name,
    };

    const markTypes = [
      'mark_body',
      'mark_face_front',
      'mark_face_side',
      'mark_foot_lower',
      'mark_foot_upper',
      'mark_palm_lower',
      'mark_palm_upper',
    ];

    markTypes.forEach(markType => {
      payload[markType] = state.form[markType].map(item => ({
        x: item.position.x,
        y: item.position.y,
      }));
    });
    delete payload.signer_name_patient;
    try {
      const response = await putOperationMarking(idOM, payload);
      if (response.status === 200) {
        let mappedResponse = {
          ...response.data.data,
          action: {
            ...response.data.data.action,
            text: `${response.data.data.action.date}, ${response.data.data.action.time} (${response.data.data.action.name})`,
          },
          signer_name:
            response.data.data.signer === 'wali'
              ? response.data.data.signer_name
              : '',
          signer_name_patient:
            response.data.data.signer === 'pasien'
              ? response.data.data.signer_name
              : '',
        };

        const markTypes = [
          'mark_body',
          'mark_face_front',
          'mark_face_side',
          'mark_foot_lower',
          'mark_foot_upper',
          'mark_palm_lower',
          'mark_palm_upper',
        ];

        markTypes.forEach(markType => {
          mappedResponse[markType] = response.data.data[markType].map(item => ({
            position: { x: item.x, y: item.y },
          }));
        });
        commit('setForm', mappedResponse);
        commit('setInitialState', mappedResponse);
        commit('setSnackbar', {
          show: true,
          text: 'Formulir Penandaan Operasi berhasil diubah',
          color: 'success',
        });
        return setTimeout(() => {
          commit('clearSnackbar');
        }, 4000);
      }
    } catch (error) {
      commit('setSnackbar', {
        show: true,
        text: 'Formulir Penandaan Operasi gagal diubah',
        color: 'error',
      });
      return setTimeout(() => {
        commit('clearSnackbar');
      }, 4000);
    }
  },
  // End Put Data
};

export default {
  state,
  getters,
  mutations,
  actions,
};
