<template>
  <v-row no-gutters class="d-flex flex-column py-3">
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="font-weight-bold text-left mr-auto"
        >EKUITAS AWAL PERIODE</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Januari</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Februari</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Maret</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 April</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Mei</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Juni</label>
      </v-col>
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto">Modal Persyarikatan</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex subtotalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto"
        >SALDO EKUITAS AWAL PERIODE</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex" style="height: 3vh;">
      <label class="font-weight-bold text-left mr-auto"
        >PENINGKATAN EKUITAS</label
      >
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto">Saldo Laba</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex subtotalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto"
        >JUMLAH PENINGKATAN EKUITAS</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex" style="height: 3vh;">
      <label class="font-weight-bold text-left mr-auto"
        >PENURUNAN EKUITAS</label
      >
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto">Saldo Rugi</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>
    <div class="d-flex subtotalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto"
        >JUMLAH PENURUNAN EKUITAS</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex totalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto"
        >SALDO EKUITAS AKHIR PERIODE</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'Comparative',
};
</script>

<style lang="scss" scoped>
.column-style {
  max-width: 12%;
  text-align: right;
}
.subtotalComp-style {
  background-color: #deeef9;
  height: 5vh;
  align-items: center;
  margin: 5px 0;
}

.totalComp-style {
  background-color: #addaf9;
  height: 5vh;
  align-items: center;
  margin: 5px 0;
}
</style>
