<template>
  <div class="main">
    <v-data-table :headers="headers" :items="items" hide-default-footer>
      <template v-slot:item.status="{ item }">
        <v-chip color="green" label class="chip-green">
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:no-data>
        <p>Tidak ada data yang ditampilkan</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment-timezone';
export default {
  name: 'TableComponent',
  props: {
    patientData: Object,
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Variabel',
          align: 'start',
          sortable: false,
          value: 'variable',
        },
        {
          text: 'Data',
          align: 'start',
          sortable: false,
          value: 'data',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: false,
          value: 'status',
        },
      ],
      items: this.isEmpty
        ? []
        : [
            {
              variable: 'Nama Pasien',
              data: this.patientData.meta.name,
              status: 'Teridentifikasi',
            },
            {
              variable: 'Tanggal Lahir',
              data: moment(this.patientData.meta.birthDate, [
                'DD/MM/YYYY',
                'DD MMMM YYYY',
              ]).format('DD-MM-YYYY'),
              status: 'Teridentifikasi',
            },
          ],
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  ::v-deep .v-list-item .v-list-item__content {
    line-height: 1.1;
    flex: 1 0 100%;
    text-align: start;
  }

  ::v-deep {
    .v-data-table
      > .v-data-table__wrapper
      tbody
      tr.v-data-table__expanded__content {
      box-shadow: none;
    }

    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr
      > td {
      font-size: 0.725rem;
    }
    tbody {
      border-left: 2px solid blue !important ;
    }
  }
  ::v-deep .v-data-table-header {
    color: #9e9e9e;
    background-color: #f4f5f5;
  }
  ::v-deep .v-chip {
    background-color: #e5fff0;
  }

  .chip-green {
    border: 1px solid #2d965a !important;
    color: #2d965a;
    background-color: #e5fff0 !important;
    padding: 0px 6px;
    height: 1.5rem;
    margin-left: 0;
    font-size: 0.65rem;
  }
}
</style>
