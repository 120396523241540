import Constant from '@/const';
import axios from 'axios';

const getSocialDataByRmNumber = async rm => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/patient/emr/social/${rm}`),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getSocialDataByRmNumber;
