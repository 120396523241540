<template>
  <v-form ref="form">
    <v-container class="pa-7 d-flex flex-column align-start" fluid>
      <h2>Password I-Care</h2>
      <v-chip :color="chip.color"
        ><v-icon :color="chip.iconColor" class="mr-2">{{ chip.icon }}</v-icon>
        <strong class="font-weight-bold" v-show="bpjsError">
          BPJS Error atau Tidak Terhubung.&nbsp;
        </strong>
        {{ chip.text }}</v-chip
      >
      <label class="required mb-n4">
        Username
      </label>
      <v-text-field
        v-model="username"
        @keydown="onEnter"
        :rules="rules"
        readonly
        dense
        light
      />
      <label class="required mb-n4">
        Password
      </label>
      <v-text-field
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        v-model="password"
        @keydown="onEnter"
        :rules="rules"
        :readonly="!isEditing"
        dense
        light
      />
      <div class="button-container">
        <v-btn
          color="error"
          class="mr-3 text-capitalize"
          v-if="isEditing"
          @click="edit()"
          outlined
          dense
          depressed
          light
          >Batal</v-btn
        >
        <v-btn
          @click="isEditing ? update() : edit()"
          color="primary"
          class="text-capitalize"
          :loading="loading.update"
          dense
          depressed
          light
          >{{ isEditing ? 'Simpan Perubahan' : 'Ubah' }}</v-btn
        >
      </div>
    </v-container>
    <v-snackbar
      timeout="4000"
      content-class="d-flex justify-space-between"
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-icon small style="cursor: pointer" @click="snackbar.show = false"
        >mdi-close</v-icon
      >
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  getIcareData,
  putUpdateIcareData,
  icareAuthenticationCheck,
} from '@/fetchApi/Setting/icarePassword';
import Swal from 'sweetalert2';

export default {
  name: 'IcarePassword',
  data() {
    return {
      bpjsError: false,
      chip: {
        icon: '',
        color: '',
        text: '',
        iconColor: '',
      },
      rules: [v => !!v || 'Wajib diisi'],
      snackbar: {
        show: false,
        text: 'Password bridging berhasil diubah',
        color: '#31b057',
      },
      loading: {
        update: false,
      },
      username: '',
      password: '',
      showPassword: false,
      isEditing: false,
    };
  },
  mounted() {
    this.icareAuthCheck();
    this.getIcareData();
  },
  methods: {
    async icareAuthCheck() {
      try {
        this.bpjsError = false;
        const response = await icareAuthenticationCheck();
        const { data } = response.data;
        if (data?.code >= 400) {
          this.chip = {
            icon: 'mdi-close-circle',
            color: '#fff3f3',
            text: data?.message,
            iconColor: 'error',
          };
          return;
        } else {
          this.chip = {
            icon: 'mdi-check-circle',
            color: '#e5fff0',
            text: data?.message,
            iconColor: 'success',
          };
        }
      } catch (error) {
        this.bpjsError = true;
        this.chip = {
          icon: 'mdi-alert',
          color: '#fff3f3',
          text:
            'Anda masih bisa mengubah password dan akan terautentikasi jika BPJS terhubung kembali',
          iconColor: 'error',
        };
        return;
      }
    },
    async getIcareData() {
      try {
        const { username, password } = await getIcareData();
        this.username = username;
        this.password = password;
      } catch {
        Swal.fire(
          'Error',
          'Terjadi kesalahan saat mendapatkan data username dan password',
          'error',
        );
      }
    },
    async update() {
      try {
        this.loading.update = true;
        await putUpdateIcareData({ password: this.password });
        this.snackbar = {
          show: true,
          text: 'Password ICare berhasil diubah',
          color: '#31b057',
        };
      } catch (error) {
        this.snackbar = {
          show: true,
          text: 'Terjadi kesalahan, gagal mengubah password ICare',
          color: 'error',
        };
      } finally {
        this.loading.update = false;
        this.isEditing = false;
        this.getIcareData();
        this.icareAuthCheck();
      }
    },
    onEnter(e) {
      if (e.keyCode === 13) {
        this.update();
        e.preventDefault();
      }
    },
    edit() {
      this.isEditing = !this.isEditing;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  gap: 1vw;
  .v-chip {
    font-weight: 400;
    border: 1px solid #bdbdbd !important;
    border-radius: 5px;

    i {
      font-size: 1vw;
    }
  }

  label {
    font-size: 0.9vw;
    color: #3f484a;
    font-weight: 500;
  }

  .v-input {
    width: 30vw;
  }

  .button-container {
    width: 30vw;
    display: flex;
    justify-content: flex-end;

    button {
      //   padding: 0 !important;
      height: 1.4vw !important;
      letter-spacing: 0.0001px;
    }
  }
  .required:after {
    content: ' *';
    color: red;
  }
}
</style>
