import Constant from '@/const';
import axios from 'axios';

const postNeonatusAssessment = async (id, payload) => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat(`/inpatient/neonatus/${id}`),
      payload,
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default postNeonatusAssessment;
