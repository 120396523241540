<template>
  <div class="main-div">
    <main class="main">
      <v-container fluid>
        <v-layout align-start justify-start column fill-width>
          <v-flex class="big-header">
            <v-layout align-space-between justify-start row fill-height>
              <v-flex xs6 align-self-center class="header-text pl-10">
                <h1 class="primary-header">Keuangan</h1>
                <h3 class="secondary-header">
                  Cek semua laporan yang bersangkutan dengan keuangan
                </h3>
              </v-flex>
              <v-flex xs1></v-flex>
              <v-flex xs5>
                <v-img
                  contain
                  class="finance-image"
                  lazy-src
                  :src="resource.picLocation[0]"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex class="text-submenu">
            <h3 class="text-head py-4">Kategori</h3>
          </v-flex>
          <v-flex class="menu-space">
            <v-layout align-start justify-start row fill-height>
              <v-flex
                xs1
                v-for="(item, index) in resource.category"
                class="menu-widget"
                :key="'finance-report-' + index"
              >
                <v-layout fill-height column>
                  <router-link v-if="!item.hasDialog" :to="item.route">
                    <v-card flat rounded="lg" class="logo-place pa-3">
                      <v-img
                        contain
                        lazy-src
                        :src="resource.picLocation[index + 1]"
                      />
                    </v-card>
                  </router-link>
                  <div v-if="item.hasDialog" @click="openDialog(item.text)">
                    <v-card flat rounded="lg" class="logo-place pa-3">
                      <v-img
                        contain
                        lazy-src
                        :src="resource.picLocation[index + 1]"
                      />
                    </v-card>
                  </div>
                  <v-flex class="font-weight-medium">
                    {{ item.text }}
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
      <v-dialog
        v-if="dialog && selectedDialog !== ''"
        v-model="dialog"
        width="300"
        persistent
      >
        <v-card class="pb-7">
          <v-card-title class="justify-end pa-0">
            <v-btn icon color="red" @click="dialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-col class="py-0">
            <h3>{{ selectedDialog[0].title }}</h3>
          </v-col>
          <v-col
            v-for="(item, index) in selectedDialog[0].list"
            :key="`item-${index}`"
          >
            <v-btn
              outlined
              color="primary"
              class="text-none"
              style="border-width: 2px;"
              width="230"
              :to="item.route"
              >{{ item.text }}</v-btn
            >
            <!-- :to="item.route.journal_list" -->
          </v-col>
        </v-card>
      </v-dialog>
    </main>
  </div>
</template>

<script>
import finance from '@/assets/image/finance.svg';
import kas_bank from '@/assets/image/finance_kas_bank.svg';
import hutang from '@/assets/image/finance_hutang.svg';
import piutang from '@/assets/image/finance_piutang.svg';
import aset from '@/assets/image/finance_aset.svg';
import persediaan from '@/assets/image/finance_persediaan.svg';
import persetujuan from '@/assets/image/finance_persetujuan.svg';

export default {
  name: 'PharmacySubmenu',
  mixins: [],
  components: {},
  data() {
    return {
      dialog: false,
      selectedDialog: '',
      resource: {
        picLocation: [
          finance,
          kas_bank,
          hutang,
          piutang,
          aset,
          persediaan,
          persetujuan,
        ],
        dialog: [
          // ADD NEW OBJECT HERE IF THERE IS NEW DIALOG FROM EACH MENU
          {
            title: 'Utang',
            list: [
              {
                text: 'Utang Suplier',
                route: '/keuangan/utang-supplier',
              },
              {
                text: 'Pembayaran Utang Suplier',
                route: '/keuangan/pembayaran-utang-supplier',
              },
              {
                text: 'Jasa Sistem',
                route: '/keuangan/jasa-sistem',
              },
              {
                text: 'Pembayaran Jasa Medis',
                route: '/keuangan/pembayaran-jasa-medis',
              },
            ],
          },
        ],
        category: [
          // {
          //   route: `/keuangan/laporan-keuangan/laporan-verifikasi`,
          //   text: 'Laporan Verifikasi',
          // },
          {
            route: `/keuangan/`,
            text: 'Kas dan Bank',
          },
          {
            route: `/keuangan/`,
            text: 'Utang',
            hasDialog: true,
          },
          {
            route: `/keuangan/`,
            text: 'Piutang',
          },
          {
            route: `/keuangan/`,
            text: 'Aset',
          },
          {
            route: `/keuangan/`,
            text: 'Persediaan',
          },
          {
            route: `/keuangan/`,
            text: 'Persetujuan',
          },
        ],
      },
    };
  },
  watch: {},
  computed: {
    route() {
      return this.$route.path;
    },
  },
  methods: {
    openDialog(title) {
      this.dialog = true;
      this.selectedDialog = this.resource.dialog.filter(x => x.title === title);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Roboto;
  font-style: normal;
  .main {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    padding: 0 25px 0 25px;

    .big-header {
      align-items: center;
      display: flex;
      width: 100%;
      min-height: 48vh;
      background: #f0f9ff;
      font-family: Roboto;
      font-style: normal;
      border-radius: 25px;
      .header-text {
        padding-left: 25px;
        text-align: left;
        .primary-header {
          font-size: 2.5em;
          color: #000000;
          font-weight: bold;
        }
        .secondary-header {
          font-size: 1.15em;
          font-weight: 500;
          color: #a6adb4;
        }
      }
      .finance-image {
        z-index: 1;
        // right: 35px;
        // top: 30px;
        position: relative;
        width: 90%;
      }
    }
    .text-submenu {
      .text-head {
        // padding: 40px 0 30px 0;
        color: #000000;
        font-weight: bold;
      }
    }
    .menu-space {
      width: 100%;
      .menu-widget {
        margin: 20px;
        .logo-place {
          margin: auto;
          background: #f0f9ff;
          width: 100px;
          height: 100px;
          &:hover {
            border: 2px solid $primary-color;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
