<template>
  <v-card>
    <v-card-text>
      <header-content
        :data="headerData"
        pt="2vw"
        pl="2vw"
        pr="2vw"
        mb="2vw"
        title="Assesmen IGD"
      />
      <v-main>
        <v-tabs grow center-active v-model="tab">
          <v-tab class="text-capitalize font-weight-bold">Asesmen Dokter</v-tab>
          <v-tab class="text-capitalize font-weight-bold"
            >Asesmen Perawat</v-tab
          >
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <DoctorAssessment
              @close-form="close()"
              :triase="triaseLevel"
              :patient-data="patientData"
              :nursery="nurseAssessment"
              :doctor="doctorAssessment"
              v-if="!isLoadingDoctorAssesment"
            />
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
              :size="50"
              class="progress-circular"
            ></v-progress-circular>
          </v-tab-item>
          <v-tab-item>
            <NurseAssessment
              @close-form="close()"
              :patient-data="patientData"
              :hasinfectiousdata="hasInfectiousData"
              :existingFluidBalanceData="existingTable"
              :existingInfectiousData="existingInfectiousData || {}"
              v-if="!isLoadingDoctorAssesment"
            />
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
              class="progress-circular"
              :size="50"
            ></v-progress-circular>
          </v-tab-item>
        </v-tabs-items>
      </v-main>
    </v-card-text>
  </v-card>
</template>

<script>
import HeaderContent from '../../../SharedComponent/HeaderContent.vue';
import DoctorAssessment from './DoctorAssessment.vue';
import NurseAssessment from './NurseAssessment';
import moment from 'moment-timezone';
import AlertMixin from '@/mixin/alertMixin';
import { createNamespacedHelpers } from 'vuex';
const { mapMutations: mapIGDAssessmentMutations } = createNamespacedHelpers(
  'IGDAssessment',
);
import {
  getAssessmentIgd,
  getTriase,
} from '../../../../fetchApi/Assessment/IGD';

const { mapMutations: mapSubjectiveDataMutations } = createNamespacedHelpers(
  'SubjectiveData',
);
const { mapMutations: mapObjectiveDataMutations } = createNamespacedHelpers(
  'ObjectiveData',
);
const { mapMutations: mapPainAssessmentMutations } = createNamespacedHelpers(
  'PainAssessment',
);
const {
  mapMutations: mapNutritionScreeningMutations,
} = createNamespacedHelpers('NutritionScreening');
const { mapMutations: mapFallRiskMutations } = createNamespacedHelpers(
  'FallRisk',
);
const { mapMutations: mapEmergencyActionMutations } = createNamespacedHelpers(
  'EmergencyAction',
);
const { mapMutations: mapNursingProblemMutations } = createNamespacedHelpers(
  'NursingProblem',
);
const { mapMutations: mapFluidBalanceMutations } = createNamespacedHelpers(
  'FluidBalance',
);
const { mapMutations: mapDoctorAssessmentMutations } = createNamespacedHelpers(
  'DoctorAssessmentIGD',
);
const {
  mapMutations: mapSupportingInvestigationMutations,
} = createNamespacedHelpers('SupportingInvestigation');
const { mapMutations: mapPhysicalCheckMutations } = createNamespacedHelpers(
  'PhysicalCheck',
);
const {
  mapMutations: mapInfectiousAssessmentMutations,
  mapGetters: mapInfectiousAssessmentGetters,
} = createNamespacedHelpers('InfectiousAssessment');

export default {
  components: {
    HeaderContent,
    NurseAssessment,
    DoctorAssessment,
  },
  props: {
    patientData: {
      type: Object,
    },
  },
  mixins: [AlertMixin],
  data() {
    return {
      tab: null,
      triaseLevel: null,
      doctorAssessment: null,
      nurseAssessment: null,
      isLoadingDoctorAssesment: false,
      hasInfectiousData: false,
      existingTable: [],
      existingInfectiousData: '',
    };
  },
  computed: {
    ...mapInfectiousAssessmentGetters([
      'getActiveTbc',
      'getMeasless',
      'getMdrTb',
      'getSars',
      'getAspergilosis',
      'getAricellaZooster',
      'getPneumonia',
      'getOtherAirborne',
      'getIspa',
      'getSwineFlu',
      'getRebulla',
      'getDipteria',
      'getMumps',
      'getBirdFlu',
      'getHepatitisA',
      'getMdroHiv',
      'getHerpesSimplek',
      'getScabies',
      'getKonjungtivity',
      'getVaricellaZooster',
      'getOtherDroplet',
      'getOtherContact',
    ]),
    active_tbc: {
      get() {
        return this.getActiveTbc;
      },
      set(value) {
        this.setActiveTbc(value);
      },
    },
    measless: {
      get() {
        return this.getMeasless;
      },
      set(value) {
        this.setMeasless(value);
      },
    },
    mdr_tb: {
      get() {
        return this.getMdrTb;
      },
      set(value) {
        this.setMdrTb(value);
      },
    },
    sars: {
      get() {
        return this.getSars;
      },
      set(value) {
        this.setSars(value);
      },
    },
    aspergilosis: {
      get() {
        return this.getAspergilosis;
      },
      set(value) {
        this.setAspergilosis(value);
      },
    },
    aricella_zooster: {
      get() {
        return this.getAricellaZooster;
      },
      set(value) {
        this.setAricellaZooster(value);
      },
    },
    other_airborne: {
      get() {
        return this.getOtherAirborne;
      },
      set(value) {
        this.setOtherAirborne(value);
      },
    },
    ispa: {
      get() {
        return this.getIspa;
      },
      set(value) {
        this.setIspa(value);
      },
    },
    swine_flu: {
      get() {
        return this.getSwineFlu;
      },
      set(value) {
        this.setSwineFlu(value);
      },
    },
    rebulla: {
      get() {
        return this.getRebulla;
      },
      set(value) {
        this.setRebulla(value);
      },
    },
    dipteria: {
      get() {
        return this.getDipteria;
      },
      set(value) {
        this.setDipteria(value);
      },
    },
    pneumonia: {
      get() {
        return this.getPneumonia;
      },
      set(value) {
        this.setPneumonia(value);
      },
    },
    mumps: {
      get() {
        return this.getMumps;
      },
      set(value) {
        this.setMumps(value);
      },
    },
    bird_flu: {
      get() {
        return this.getBirdFlu;
      },
      set(value) {
        this.setBirdFlu(value);
      },
    },
    hepatits_a: {
      get() {
        return this.getHepatitisA;
      },
      set(value) {
        this.setHepatitisA(value);
      },
    },
    mdro_hiv: {
      get() {
        return this.getMdroHiv;
      },
      set(value) {
        this.setMdroHiv(value);
      },
    },
    herpes_simplek: {
      get() {
        return this.getHerpesSimplek;
      },
      set(value) {
        this.setHerpesSimplek(value);
      },
    },
    scabies: {
      get() {
        return this.getScabies;
      },
      set(value) {
        this.setScabies(value);
      },
    },
    konjungtivity: {
      get() {
        return this.getKonjungtivity;
      },
      set(value) {
        this.setKonjungtivity(value);
      },
    },
    varicella_zooster: {
      get() {
        return this.getVaricellaZooster;
      },
      set(value) {
        this.setVaricellaZooster(value);
      },
    },
    other_droplet: {
      get() {
        return this.getOtherDroplet;
      },
      set(value) {
        this.setOtherDroplet(value);
      },
    },
    other_contact: {
      get() {
        return this.getOtherContact;
      },
      set(value) {
        this.setOtherContact(value);
      },
    },
    headerData() {
      return [
        {
          label: 'No RM',
          text: this.patientData.rmNumber,
        },
        {
          label: 'Nama',
          text: `${this.patientData.meta.name} (${
            this.patientData.meta.gender ? 'L' : 'P'
          })`,
        },
        {
          label: 'Tanggal Lahir',
          text: `${moment(this.patientData.meta.birthDate, 'YYYY-MM-DD').format(
            'DD/MM/YYYY',
          )} (${this.patientData.meta.age})`,
        },
      ];
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getAssessment();
    });
  },
  beforeDestroy() {
    this.setIsEmr(false);
  },
  methods: {
    ...mapIGDAssessmentMutations(['setIsEmr']),
    ...mapDoctorAssessmentMutations([
      'clearDoctorAssessmentState',
      'setDoctorAnamnesis',
      'setSecondaryDiagnose',
      'setDifferentialDiagnose',
      'setMainDiagnose',
      'setCaseType',
      'setEye',
      'setMotoric',
      'setVerbal',
      'setActionPlan',
      'setDoctorSistol',
      'setDoctorDiastol',
      'setDoctorPulse',
      'setDoctorWeight',
      'setDoctorHeight',
      'setDoctorBellyCircumference',
      'setDoctorRespirationRate',
      'setDoctorSaturation',
      'setDoctorTemperature',
      'setFollowUp',
      'setReferTo',
      'setIgdTherapy',
    ]),
    ...mapSupportingInvestigationMutations([
      'setOtherNotes',
      'setLab',
      'setRadiology',
      'clearSupportingInvestigationState',
    ]),
    ...mapPhysicalCheckMutations([
      'clearPhysicalCheckState',
      'setHead',
      'setPhysicalEye',
      'setEar',
      'setNose',
      'setMouth',
      'setNeck',
      'setHair',
      'setLips',
      'setTeeth',
      'setTongue',
      'setPalate',
      'setThroat',
      'setTonsil',
      'setChest',
      'setBreast',
      'setBack',
      'setStomach',
      'setGenitalia',
      'setAnus',
      'setUpperArm',
      'setForeArm',
      'setFinger',
      'setHandNail',
      'setHandJoint',
      'setUpperLimb',
      'setLowerLimb',
      'setToe',
      'setToeNail',
      'setLegJoint',
    ]),
    ...mapSubjectiveDataMutations([
      'clearSubjectiveState',
      'setAnamnesis',
      'setAllergy',
      'setPriorDisease',
      'setTreatmentHistory',
      'setPsychologyStatusCheckbox',
      'setPsychologyStatus',
      'setSocialEconomy',
      'setSpiritual',
    ]),
    ...mapObjectiveDataMutations([
      'clearObjectiveState',
      'setSistol',
      'setDiastol',
      'setPulse',
      'setWeight',
      'setHeight',
      'setBellyCircumference',
      'setRespirationRate',
      'setSaturation',
      'setTemperature',
    ]),
    ...mapPainAssessmentMutations([
      'clearPainAssessmentState',
      'setScore',
      'setPainFactor',
      'setPainLike',
      'setPainRadiation',
      'setSeverity',
      'setPainFrequency',
      'setPainDuration',
      'setDescription',
    ]),
    ...mapNutritionScreeningMutations([
      'clearNutritionScreeningState',
      'setIsThin',
      'setIsLossWeight',
      'setIsHasCondition',
      'setIsMalnutritionRisk',
      'setIsUnplannedWeightLoss',
      'setIsUnplannedWeightLossAmount',
      'setIsFoodIntakePoorDueToDecreasedAppetite',
      'setIsHaveSeriousIllness',
    ]),
    ...mapFallRiskMutations([
      'setSurgery',
      'setDiagnose',
      'setCogntiveImpairment',
      'setMedicamentosa',
      'setHeparinLockTherapy',
      'setMentalStatus',
      'setFallHistory',
      'setSecDiagnose',
      'clearFallRiskState',
      'setWalker',
      'setWalk',
    ]),
    ...mapInfectiousAssessmentMutations([
      'clearInfectiousAssessmentState',
      'setActiveTbc',
      'setMeasless',
      'setMdrTb',
      'setSars',
      'setAspergilosis',
      'setAricellaZooster',
      'setOtherAirborne',
      'setOtherDroplet',
      'setOtherContact',
      'setIspa',
      'setSwineFlu',
      'setRebulla',
      'setDipteria',
      'setPneumonia',
      'setMumps',
      'setBirdFlu',
      'setHepatitisA',
      'setMdroHiv',
      'setHerpesSimplek',
      'setScabies',
      'setKonjungtivity',
      'setVaricellaZooster',
    ]),
    ...mapFluidBalanceMutations(['clearFluidBalanceState']),
    ...mapEmergencyActionMutations([
      'setBasicLifeSupport',
      'setInvasiveAction',
      'setSplintAction',
      'setSupport',
    ]),
    ...mapNursingProblemMutations([
      'setNursingProblem',
      'clearNursingProblemState',
    ]),
    close() {
      this.$emit('close-form');
    },
    clearAllState() {
      this.clearDoctorAssessmentState();
      this.clearSupportingInvestigationState();
      this.clearSubjectiveState();
      this.clearObjectiveState();
      this.clearPainAssessmentState();
      this.clearNutritionScreeningState();
      this.clearFallRiskState();
      this.clearInfectiousAssessmentState();
      this.clearNursingProblemState();
      this.clearFluidBalanceState();
      this.clearPhysicalCheckState();
    },

    async getTriase() {
      try {
        const response = await getTriase(this.patientData.triaseNumber);
        const [detail_triase] = response.data.data.detail_triase;
        this.triaseLevel = {
          ...detail_triase.triase_level,
          pic: detail_triase.triase_level.person_in_charge,
          parentsName: detail_triase.triase_level.parent_name,
        };
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    async getAssessment() {
      try {
        this.isLoadingDoctorAssesment = true;
        const { doctor, nursery, triase } = await getAssessmentIgd({
          id_registration: this.patientData.id_registration,
          triase_number: this.$store.getters.selectedPatient.triaseNumber,
        });
        const {
          subjective = null,
          pain_assesment = null,
          objective = null,
          nutrition_screening = null,
          nursing_problem = null,
          infectious_and_immunocompromise = null,
          igd_actions = null,
          fluid_balance = null,
          fall_risk_assessment = null,
        } = nursery || {};
        const {
          anamnesis = null,
          assesment = null,
          case_type = null,
          gcs = null,
          objective: objectiveDoctor = null,
          other = null,
          physical = null,
          planning = null,
        } = doctor || {};

        // Map Nursery
        // Set Objective State
        if (objective) {
          this.setSistol(objective?.sistol || null);
          this.setDiastol(objective?.diastol || null);
          this.setPulse(objective?.heart_rate || null);
          this.setWeight(objective?.weight || null);
          this.setHeight(objective?.height || null);
          this.setBellyCircumference(objective?.belly_circumference || null);
          this.setRespirationRate(objective?.respiration_rate || null);
          this.setSaturation(objective?.saturation || null);
          this.setTemperature(objective?.temperature || null);
        }
        if (subjective) {
          // Set Subjective State
          this.setAnamnesis(subjective?.anamnesis || ['']);
          this.setAllergy(subjective?.allergy_history || ['']);
          this.setPriorDisease(subjective?.disease_history || ['']);
          this.setTreatmentHistory(subjective?.treatment_history || '');
          this.setPsychologyStatusCheckbox(
            subjective?.psychological_status?.[0] !== 'Tidak Ada Kelainan',
          );
          this.setPsychologyStatus(subjective?.psychological_status || []);
          this.setSocialEconomy(subjective?.social_economy || '');
          this.setSpiritual(subjective?.spiritual || '');
        }

        this.setScore(
          pain_assesment?.detail?.score || +triase?.triase_level?.pain || 0,
        );
        if (pain_assesment) {
          // Set Pain Assessment State
          this.setPainFactor(pain_assesment?.detail?.pain_factor || []);
          this.setPainLike(pain_assesment?.detail?.pain_like || []);
          this.setPainRadiation(
            pain_assesment?.detail?.pain_radiation || false,
          );
          this.setSeverity(pain_assesment?.detail?.severity || 0);
          this.setPainFrequency(pain_assesment?.detail?.pain_frequency || 0);
          this.setPainDuration(pain_assesment?.detail?.pain_duration || 0);
          this.setDescription(pain_assesment?.detail?.description || null);
        }
        if (nutrition_screening) {
          // Set Nutrition Screening State

          // Adult
          this.setIsFoodIntakePoorDueToDecreasedAppetite(
            nutrition_screening?.adult
              ?.isFoodIntakePoorDueToDecreasedAppetite || {
              check: false,
              score: 0,
            },
          );
          this.setIsHaveSeriousIllness(
            nutrition_screening?.adult?.isHaveSeriousIllness || {
              check: false,
              score: 0,
            },
          );
          this.setIsUnplannedWeightLoss(
            this.isHasUnplannedWeightLoss(
              nutrition_screening?.adult?.isUnplannedWeightLoss?.check,
            )
              ? true
              : nutrition_screening?.adult?.isUnplannedWeightLoss || {
                  check: 'no',
                  score: 0,
                },
          );
          this.setIsUnplannedWeightLossAmount(
            this.isHasUnplannedWeightLoss(
              nutrition_screening?.adult?.isUnplannedWeightLoss?.check,
            )
              ? nutrition_screening?.adult?.isUnplannedWeightLoss || ''
              : '',
          );

          // Children
          this.setIsThin(
            nutrition_screening?.children?.isThin || { check: false, score: 0 },
          );
          this.setIsLossWeight(
            nutrition_screening?.children?.isWeightLossLastMonth || {
              check: false,
              score: 0,
            },
          );
          this.setIsHasCondition(
            nutrition_screening?.children?.isThereOfTheseCondition || {
              check: false,
              score: 0,
            },
          );
          this.setIsMalnutritionRisk(
            nutrition_screening?.children?.isThereCausesMalnourished || {
              check: false,
              score: 0,
            },
          );
        }

        if (fall_risk_assessment) {
          // Set Fall Risk State

          // Adult
          this.setFallHistory(
            fall_risk_assessment?.adult?.fallHistory || {
              check: false,
              score: 0,
            },
          );
          this.setSecDiagnose(
            fall_risk_assessment?.adult?.secondaryDiagnosis || {
              check: false,
              score: 0,
            },
          );
          this.setWalker(
            fall_risk_assessment?.adult?.walkTool || {
              check: 'normal',
              score: 0,
            },
          );
          this.setHeparinLockTherapy(
            fall_risk_assessment?.adult?.useHeparinLockTherapy || {
              check: false,
              score: 0,
            },
          );
          this.setWalk(
            fall_risk_assessment?.adult?.howToWalk || {
              check: 'normal',
              score: 0,
            },
          );
          this.setMentalStatus(
            fall_risk_assessment?.adult?.mentalStatus || {
              check: 'accordingToAbility',
              score: 0,
            },
          );

          // Children
          this.setSurgery(fall_risk_assessment?.children?.surgery || '');
          this.setDiagnose(fall_risk_assessment?.children?.diagnosis || '');
          this.setCogntiveImpairment(
            fall_risk_assessment?.children?.cognitiveImpairment || '',
          );
          this.setMedicamentosa(
            fall_risk_assessment?.children?.medicinalUse || '',
          );
        }

        if (fluid_balance) {
          // Set Fluid Balance Table Data
          this.existingTable = fluid_balance.map(item => {
            return {
              time: item.added_at,
              income: ['Oral', 'Cairan IV'],
              sub_income: [
                {
                  label: '-',
                  value: item.oral || 0,
                },
                {
                  label: 'Infus',
                  value: item.in.filter(i => i.name == 'Infus')[0].amount,
                },
                {
                  label: 'Infus Elektrolit',
                  value: item.in.filter(i => i.name == 'Infus Elektrolit')[0]
                    .amount,
                },
              ],
              otherSubIncome: item.in
                .filter(
                  i => i.name !== 'Infus' && i.name !== 'Infus Elektrolit',
                )
                .map(v => {
                  return {
                    label: v.name,
                    value: v.amount,
                  };
                }),
              outcome: item.out.map(i => {
                return {
                  label: i.name,
                  value: i.amount,
                };
              }),
            };
          });
        }

        if (infectious_and_immunocompromise) {
          // Set Infectious Assessment State
          this.setActiveTbc(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.airborne?.chosen,
              'TBC Aktif',
              'airborne',
            ),
          );
          this.setMeasless(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.airborne?.chosen,
              'Campak (Measless)',
              'airborne',
            ),
          );
          this.setMdrTb(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.airborne?.chosen,
              '(MDR TB)',
              'airborne',
            ),
          );
          this.setSars(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.airborne?.chosen,
              'SARS',
              'airborne',
            ),
          );
          this.setAspergilosis(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.airborne?.chosen,
              'Aspergilosis',
              'airborne',
            ),
          );
          this.setAricellaZooster(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.airborne?.chosen,
              'Aricella Zooster',
              'airborne',
            ),
          );
          this.setOtherAirborne(
            infectious_and_immunocompromise.airborne?.other || '',
          );
          this.setOtherDroplet(
            infectious_and_immunocompromise.droplet?.other || '',
          );
          this.setOtherContact(
            infectious_and_immunocompromise.contact?.other || '',
          );
          if (
            infectious_and_immunocompromise.droplet?.other ||
            infectious_and_immunocompromise.airborne?.other ||
            infectious_and_immunocompromise.contact?.other
          ) {
            this.hasInfectiousData = true;
          }
          this.setIspa(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.droplet?.chosen,
              'ISPA',
              'droplet',
            ),
          );
          this.setSwineFlu(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.droplet?.chosen,
              'Swine Flu (H1N1)',
              'droplet',
            ),
          );
          this.setRebulla(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.droplet?.chosen,
              'Rebulla',
              'droplet',
            ),
          );
          this.setDipteria(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.droplet?.chosen,
              'Dipteria',
              'droplet',
            ),
          );
          this.setPneumonia(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.droplet?.chosen,
              'Pneumonia',
              'droplet',
            ),
          );
          this.setMumps(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.droplet?.chosen,
              'Mumps (Parotitis)',
              'droplet',
            ),
          );
          this.setBirdFlu(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.droplet?.chosen,
              'Flu Burung (H5N1)',
              'droplet',
            ),
          );
          this.setHepatitisA(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.contact?.chosen,
              'Hepatitis A',
              'contact',
            ),
          );
          this.setMdroHiv(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.contact?.chosen,
              'MDRO HIV',
              'contact',
            ),
          );
          this.setHerpesSimplek(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.contact?.chosen,
              'Herpes Simplek',
              'contact',
            ),
          );
          this.setScabies(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.contact?.chosen,
              'Scabies',
              'contact',
            ),
          );
          this.setKonjungtivity(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.contact?.chosen,
              'Konjungtivitas',
              'contact',
            ),
          );
          this.setVaricellaZooster(
            this.setInfectiousAssessmentData(
              infectious_and_immunocompromise.contact?.chosen,
              'Varicella-Zooster',
              'contact',
            ),
          );

          this.existingInfectiousData = {
            active_tbc: this.active_tbc,
            measless: this.measless,
            mdr_tb: this.mdr_tb,
            sars: this.sars,
            aspergilosis: this.aspergilosis,
            aricella_zooster: this.aricella_zooster,
            ispa: this.ispa,
            swine_flu: this.swine_flu,
            rebulla: this.rebulla,
            dipteria: this.dipteria,
            pneumonia: this.pneumonia,
            mumps: this.mumps,
            bird_flu: this.bird_flu,
            hepatits_a: this.hepatits_a,
            mdro_hiv: this.mdro_hiv,
            herpes_simplek: this.herpes_simplek,
            scabies: this.scabies,
            konjungtivity: this.konjungtivity,
            varicella_zooster: this.varicella_zooster,
            other_airborne: this.other_airborne,
            other_contact: this.other_contact,
            other_droplet: this.other_droplet,
          };

          // Set Emergency Action Data
          this.setBasicLifeSupport(igd_actions.basic_life_support);
          this.setInvasiveAction(igd_actions.invasive_actions);
          this.setSplintAction(igd_actions.splint_actions);
          this.setSupport(igd_actions.support);

          // Set Nursing Problem Data
          this.setNursingProblem(
            nursing_problem.map(item => {
              return {
                content: {
                  text: item.data?.text || '',
                  value: item.data?.value || 0,
                },
                treatmentPlan: item.plan.map(i => {
                  return {
                    content: {
                      text: i,
                    },
                  };
                }),
              };
            }),
          );
        }

        // Mapping Doctor
        if (case_type) {
          this.setCaseType(case_type);
        }
        if (anamnesis) {
          this.setDoctorAnamnesis(anamnesis?.length > 0 ? anamnesis : ['']);
        }
        if (gcs) {
          // prepare resource for GCS mapping
          const gcsEyePick = [
            { text: 'Open spontaneously', value: 4 },
            { text: 'Open to verbal command', value: 3 },
            { text: 'Open to pain', value: 2 },
            { text: 'No eye opening', value: 1 },
          ];
          const gcsMotorPick = [
            { text: 'Obeys command', value: 6 },
            { text: 'Localising pain', value: 5 },
            { text: 'Withdrawal from pain', value: 4 },
            { text: 'Flexion to pain', value: 3 },
            { text: 'Extension to pain', value: 2 },
            { text: 'No motor response', value: 1 },
          ];
          const gcsVerbalPick = [
            { text: 'Orientated', value: 5 },
            { text: 'Confused', value: 4 },
            { text: 'Inappropriate words', value: 3 },
            { text: 'Incomprehensible sounds', value: 2 },
            { text: 'No verbal response', value: 1 },
          ];

          // Set GCS State
          this.setEye(gcsEyePick.find(item => item.text === gcs?.eye) || '');
          this.setVerbal(
            gcsVerbalPick.find(item => item.text === gcs?.verbal) || '',
          );
          this.setMotoric(
            gcsMotorPick.find(item => item.text === gcs?.motoric) || '',
          );
        }
        if (objectiveDoctor) {
          // Set Doctor Objective State
          this.setDoctorSistol(objectiveDoctor?.sistol || null);
          this.setDoctorDiastol(objectiveDoctor?.diastol || null);
          this.setDoctorPulse(objectiveDoctor?.heart_rate || null);
          this.setDoctorWeight(objectiveDoctor?.weight || null);
          this.setDoctorHeight(objectiveDoctor?.height || null);
          this.setDoctorBellyCircumference(
            objectiveDoctor?.belly_circumference || null,
          );
          this.setDoctorRespirationRate(
            objectiveDoctor?.respiration_rate || null,
          );
          this.setDoctorSaturation(objectiveDoctor?.saturation || null);
          this.setDoctorTemperature(objectiveDoctor?.temperature || null);
        }
        if (physical) {
          // Set Physical State
          this.setHead(physical.head || '');
          this.setPhysicalEye(physical.eye || '');
          this.setEar(physical.ear || '');
          this.setNose(physical.nose || '');
          this.setMouth(physical.mouth || '');
          this.setNeck(physical.neck || '');
          this.setHair(physical.hair || '');
          this.setLips(physical.lips || '');
          this.setTeeth(physical.teeth || '');
          this.setTongue(physical.tongue || '');
          this.setPalate(physical.palate || '');
          this.setThroat(physical.throat || '');
          this.setTonsil(physical.tonsil || '');
          this.setChest(physical.chest || '');
          this.setBreast(physical.breast || '');
          this.setBack(physical.back || '');
          this.setStomach(physical.stomach || '');
          this.setGenitalia(physical.genitalia || '');
          this.setAnus(physical.anus || '');
          this.setUpperArm(physical.upper_arm || '');
          this.setForeArm(physical.forearm || '');
          this.setFinger(physical.finger || '');
          this.setHandNail(physical.hand_nail || '');
          this.setHandJoint(physical.hand_joint || '');
          this.setUpperLimb(physical.upper_limb || '');
          this.setLowerLimb(physical.lower_limb || '');
          this.setToe(physical.toe || '');
          this.setToeNail(physical.toe_nail || '');
          this.setLegJoint(physical.leg_joint || '');
        }
        if (other) {
          // Set Supporting Investigation State
          this.setOtherNotes(other.other_notes);
          this.setLab(other.laboratory_result);
          this.setRadiology(other.radiology_result);
        }
        if (planning) {
          // Set Planning State
          this.setActionPlan(
            planning.action_plan.length > 0
              ? planning.action_plan.map(item => {
                  return {
                    info: item.info,
                    time: item.time,
                  };
                })
              : [
                  {
                    info: '',
                    time: '',
                  },
                ],
          );
          // Set Follow Up
          this.setFollowUp(planning.follow_up[0]?.action);
          this.setReferTo(planning.follow_up[0]?.description);
          this.setIgdTherapy(planning.igd_therapy);
        }
        if (assesment) {
          // Set Diagnose State
          this.setDifferentialDiagnose(
            assesment.differential_diagnose.length > 0
              ? assesment.differential_diagnose.map(item => {
                  return {
                    text: item.diagnose,
                    description: item.description,
                  };
                })
              : [
                  {
                    text: null,
                    description: '',
                  },
                ],
          );
          this.setMainDiagnose({
            detail: assesment.main_diagnose?.description || '',
            text: assesment.main_diagnose?.diagnose?._id
              ? {
                  code: assesment.main_diagnose?.diagnose?.code || '',
                  displayName: `${assesment.main_diagnose?.diagnose?.code} - ${assesment.main_diagnose?.diagnose?.description}`,
                  id: assesment.main_diagnose?.diagnose?._id || '',
                  isNonSpecialist:
                    assesment.main_diagnose?.diagnose?.nonSpesialis || '',
                  text: assesment.main_diagnose?.diagnose?.description || '',
                }
              : null,
          });
          this.setSecondaryDiagnose(
            assesment.other_diagnose.length > 0
              ? assesment.other_diagnose.map(item => {
                  return {
                    detail: item.description || '',
                    text: {
                      displayName: `${item.diagnose?.code} - ${item.diagnose?.description}`,
                      id: item.diagnose._id,
                      text: item.diagnose?.description,
                      isNonSpecialist: item.diagnose?.nonSpesialis,
                      code: item?.diagnose.code,
                    },
                  };
                })
              : [{ text: null, detail: '' }],
          );
        }
      } catch {
        this.clearAllState();
      } finally {
        this.isLoadingDoctorAssesment = false;
      }
    },
    setInfectiousAssessmentData(array, text, type) {
      if (array?.includes(text)) {
        this.hasInfectiousData = true;
        return {
          text,
          type,
          checked: true,
        };
      } else {
        return {
          text,
          type,
          checked: '',
        };
      }
    },
    isHasUnplannedWeightLoss(data) {
      return data !== 'no' && data !== 'notSure' && data;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-circular {
  margin-top: 40vh;
}
</style>
