<template>
  <v-card>
    <v-card-title>
      <v-layout>
        <v-flex xs4>
          <h3 style="text-align: left">Assesmen Pelayanan Imunisasi</h3>
        </v-flex>
        <v-flex xs2>
          <v-layout>
            <v-flex><p class="title-head">No.RM</p></v-flex>
            <v-flex
              ><p class="data-head">{{ patientData.rmNumber }}</p></v-flex
            >
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout>
            <v-flex><p class="title-head">Nama</p></v-flex>
            <v-flex
              ><p class="data-head">
                {{ patientData.meta.name }} ({{
                  patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
                }})
              </p></v-flex
            >
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout>
            <v-flex><p class="title-head">Tanggal Lahir</p></v-flex>
            <v-flex
              ><p class="data-head">
                {{ patientData.meta.birthDate }} ({{ patientData.meta.age }}
                tahun)
              </p></v-flex
            >
          </v-layout>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs1>
          <v-btn text fab class="btn-close">
            <v-icon @click="$emit('close-form')">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-tabs v-model="tab" grow>
        <v-tab key="history" class="text-none font-weight-bold"
          >Riwayat Asesmen Pasien</v-tab
        >
        <v-tab key="input" class="text-none font-weight-bold"
          >Input Assesmen Pelayanan Imunisasi</v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="history">
          <VaccineHistoryAssesment
            @close-form="$emit('close-form')"
            :tab="tab"
            :patient-data="patientData"
          />
        </v-tab-item>
        <v-tab-item key="input">
          <VaccineInputAssesment
            @close-form="$emit('close-form')"
            :tab="tab"
            :patient-data="patientData"
            :emr-data="emrData"
            :is-emr="isEmr"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import VaccineHistoryAssesment from './VaccineHistoryAssesment.vue';
import VaccineInputAssesment from './VaccineInputAssesment.vue';

export default {
  data() {
    return {
      tab: null,
    };
  },
  components: { VaccineHistoryAssesment, VaccineInputAssesment },
  props: {
    patientData: Object,
    emrData: {
      type: Object,
      default: null,
    },
    isEmr: Boolean,
  },
  methods: {
    addData() {
      Swal.fire('Data Tindakan berhasil disimpan!', '', 'success');
      this.$emit('close-form');
    },
  },
};
</script>

<style lang="scss" scoped>
.title-head {
  font-size: 14px;
}

.data-head {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
}
.head-table {
  border-top: 1px solid #f4f5f5;
  border-bottom: 1px solid #f4f5f5;
  text-align: left;
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }
}

.body-table {
  border-bottom: 1px solid #f4f5f5;
  &__data {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
  }
}

.form-body {
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }
  &__data {
    height: 20px;
  }
}

.btn-add {
  display: flex;
  justify-content: flex-start;
  width: 185px;
  height: 57px !important;
}

.btn-close {
  position: absolute;
  top: 5px;
  right: 30px;
  // color: #9aa0a4;
  cursor: pointer;
}

.btn-close:hover {
  color: black;
}
</style>
