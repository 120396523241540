<template>
  <v-container fluid>
    <v-row class="pa-3">
      <v-col cols="6">
        <v-row class="justify-start" align="baseline">
          <h3 class="primary--text mb-1 pl-3">Ubah Jadwal</h3>
        </v-row>
        <v-row class="justify-start mb-1" align="baseline">
          <v-col cols="5"
            ><h4 class="mb-2 text-start">Nama Dokter</h4>
            <v-combobox
              :items="doctors"
              placeholder="Pilih Dokter"
              dense
              auto-select-first
              item-value="id"
              item-text="name"
              clearable
              v-model="inputFilterSchedule.id_staff"
              hide-details="false"
              :loading="doctorLoading"
              @change="getScheduleDoctor()"
            ></v-combobox
          ></v-col>
          <v-col cols="5">
            <h4 class="mb-2 text-start">Nama Poli</h4>
            <v-select
              auto-select-first
              placeholder="Pilih Poli"
              :items="poly"
              item-value="id"
              item-text="name"
              v-model="inputFilterSchedule.id_unit"
              clearable
              dense
              hide-details="false"
              @change="getScheduleDoctor()"
            ></v-select>
          </v-col>
        </v-row>
        <v-expansion-panels
          :disabled="!selectedPoly"
          v-model="panel"
          class="rounded-lg"
          style="border: 2px solid #c2c2c2"
        >
          <v-expansion-panel v-model="panel">
            <v-expansion-panel-header
              class="font-weight-medium"
              style="color: #757575"
            >
              {{ `Jadwal ${this.selectedPoly}` }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-5">
              <v-row class="px-3">
                <v-col cols="3" class="text-left">
                  <label class="font-weight-medium ml-3">Kode Antrean</label>
                </v-col>
                <v-col cols="2" class="text-center">
                  <v-text-field
                    v-model="inputSchedule.queue_code"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-form ref="form" lazy-validation>
                <v-row
                  v-for="(schedule, idx) in inputSchedule.schedule"
                  :key="'schedule-' + idx"
                  class="px-12 mb-5 text-start form-container"
                >
                  <h4 class="font-weight-medium primary--text ml-n3">
                    {{ generateDayName(+schedule.day) }}
                  </h4>
                  <v-row
                    v-for="(item, index) in schedule.data"
                    :key="'item-' + index"
                    class="mt-1 row-grid"
                  >
                    <div cols="3" class="pa-0 text-start">
                      <label>Jam Mulai</label>
                      <v-text-field
                        v-model="item.start_time"
                        type="time"
                        :rules="[
                          timeRules(item.start_time, item.end_time, 'start'),
                        ]"
                        @keydown="preventDefaultBehaviour($event)"
                        single-line
                        dense
                      />
                    </div>
                    <div cols="3" class="pa-0">
                      <label>Jam Selesai</label>
                      <v-text-field
                        v-model="item.end_time"
                        :rules="[
                          timeRules(item.end_time, item.start_time, 'end'),
                        ]"
                        type="time"
                        @keydown="preventDefaultBehaviour($event)"
                        single-line
                        dense
                      />
                    </div>
                    <div cols="2" class="pa-0">
                      <label>Kuota</label>
                      <v-text-field
                        type="number"
                        placeholder="0"
                        class="my-0 ml-0 mt-1 pa-0"
                        v-model="item.quota"
                        single-line
                        dense
                      ></v-text-field>
                    </div>
                    <div lg="2" xl="3" class="pa-0">
                      <label>Ruangan</label>
                      <v-text-field
                        class="my-0 ml-0 mt-1 pa-0"
                        v-model="item.room"
                        single-line
                        dense
                      ></v-text-field>
                    </div>
                    <div class="pa-0 d-flex align-center" lg="2" xl="1">
                      <v-icon
                        @click="deleteSchedule(idx, index)"
                        class="mr-2"
                        :color="
                          inputSchedule.schedule[idx].data.length === 1
                            ? 'grey'
                            : 'red'
                        "
                        medium
                        >mdi-delete</v-icon
                      >
                      <div
                        @click="addSchedule(idx, index)"
                        class="plus-btn"
                        :class="{ disable: isFormIncomplete(idx, index) }"
                      >
                        <v-icon color="white" small>mdi-plus</v-icon>
                      </div>
                    </div>
                  </v-row>
                </v-row>
              </v-form>
              <v-row class="pa-3">
                <v-spacer />
                <v-col cols="7"
                  ><v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red"
                      @click="deleteScheduleDoctor()"
                      outlined
                      class="text-capitalize"
                      style="letter-spacing: 0.1px;"
                      v-if="isHaveSchedule"
                      >Hapus Jadwal</v-btn
                    >
                    <v-btn
                      depressed
                      class="text-none"
                      color="primary"
                      @click="addData()"
                      style="letter-spacing: 0.1px;"
                      :loading="loadingButton"
                      :disabled="isDisabled || anyErrorField"
                      >Simpan</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="6" class="pr-6">
        <v-row class="justify-start" align="baseline">
          <h3 class="primary--text mb-3">Lihat Jadwal Dokter</h3>
        </v-row>
        <v-row>
          <h4 class="mb-2">Nama Poli</h4>
        </v-row>
        <v-row>
          <v-col cols="3" class="pa-0">
            <v-select
              placeholder="Pilih Poli"
              class="mt-0 pt-0 pa-0"
              :items="poly"
              item-value="id"
              item-text="name"
              return-object
              clearable
              v-model="polyclinic"
              dense
              @change="getAllSpecialistDoctor"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="justify-end">
          <doctor-schedule-list-card
            :data="{
              loading: getSpecialistLoading,
              schedule: doctorSchedule,
              generateDayName,
            }"
          />
        </v-row>
      </v-col>
    </v-row>
    <Snackbar
      :show="snackbar.show"
      :color="snackbar.color"
      :text="snackbar.text"
      :close="
        () => {
          clearSnackbar();
        }
      "
    />
  </v-container>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import alertMixin from '@/mixin/alertMixin';
import DoctorScheduleListCard from './Schedule/DoctorScheduleListCard.vue';
import { ModalConfirmation, Snackbar } from '@/components/SharedComponent';
const { scrollToEmptyInput } = require('@/helper');

const axios = require('axios');
const moment = require('moment');

export default {
  name: 'SchedulePractice',
  components: {
    DoctorScheduleListCard,
    Snackbar,
  },
  mixins: [alertMixin],
  data() {
    return {
      doctor: [],
      poly: [],
      doctors: [],
      polyclinic: '',
      disabled: false,
      selectedPoly: '',
      panel: null,
      inputFilterSchedule: {
        id_staff: '',
        id_unit: '',
      },
      fixSchedule: [],
      inputSchedule: {
        queue_code: '',
        schedule: [
          {
            day: 1,
            data: [
              {
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
          {
            day: 2,
            data: [
              {
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
          {
            day: 3,
            data: [
              {
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
          {
            day: 4,
            data: [
              {
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
          {
            day: 5,
            data: [
              {
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
          {
            day: 6,
            data: [
              {
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
          {
            day: 7,
            data: [
              {
                endTimeMenu: false,
                start_time: '',
                end_time: '',
                quota: '',
                room: '',
              },
            ],
          },
        ],
      },
      doctorSchedule: [],
      loadingButton: false,
      doctorLoading: false,
      getSpecialistLoading: false,
      isDisabled: false,
      snackbar: {
        show: false,
        color: 'success',
        text: 'Jadwal dokter berhasil dihapus',
      },
      isHaveSchedule: false,
    };
  },
  computed: {
    anyErrorField: {
      get() {
        const errorElement = document.querySelector(
          '.v-messages.error--text:first-of-type',
        );
        return errorElement?.class?.includes('error--text');
      },
    },
  },
  watch: {
    selectedPoly(newValue) {
      if (!newValue) {
        this.panel = null;
      }
    },
  },
  methods: {
    preventDefaultBehaviour(event) {
      if (event.key === ' ') {
        event.preventDefault();
      }
    },
    timeRules(input, comparasion, type) {
      if (!comparasion) return true;
      const a = moment(input, 'HH:mm').format();
      const b = moment(comparasion, 'HH:mm').format();
      if (type === 'start') {
        return moment(a).isBefore(b) || 'Tidak boleh setelah jam selesai';
      }
      if (type === 'end') {
        return moment(a).isAfter(b) || 'Tidak boleh sebelum jam mulai';
      }
    },
    generateDayName(value) {
      switch (+value) {
        case 1:
          return 'Senin';
        case 2:
          return 'Selasa';
        case 3:
          return 'Rabu';
        case 4:
          return 'Kamis';
        case 5:
          return 'Jumat';
        case 6:
          return 'Sabtu';
        case 7:
          return 'Minggu';
      }
    },
    isFormIncomplete(day, schedule) {
      if (
        !this.inputSchedule.schedule[day].data[schedule].start_time ||
        !this.inputSchedule.schedule[day].data[schedule].end_time ||
        !this.inputSchedule.schedule[day].data[schedule].quota ||
        !this.inputSchedule.schedule[day].data[schedule].room
      ) {
        return true;
      } else {
        return false;
      }
    },
    addData() {
      if (!this.inputSchedule.queue_code) {
        this.snackbar = {
          show: true,
          color: 'error',
          text: 'Kode antrean belum diisi',
        };
        return;
      }
      if (!this.$refs.form.validate()) {
        scrollToEmptyInput();
        return;
      }
      this.fixSchedule = [];
      this.loadingButton = true;
      this.isDisabled = true;

      for (let i = 0; i < this.inputSchedule.schedule.length; i++) {
        for (let y = 0; y < this.inputSchedule.schedule[i].data.length; y++) {
          if (
            // filter empty schedule input
            !this.inputSchedule.schedule[i].data[y].start_time &&
            !this.inputSchedule.schedule[i].data[y].end_time &&
            !this.inputSchedule.schedule[i].data[y].quota &&
            !this.inputSchedule.schedule[i].data[y].room
          ) {
            continue;
          }
          if (
            // warn user when some input are empty
            !this.inputSchedule.schedule[i].data[y].start_time ||
            !this.inputSchedule.schedule[i].data[y].end_time ||
            !this.inputSchedule.schedule[i].data[y].quota ||
            !this.inputSchedule.schedule[i].data[y].room
          ) {
            Swal.fire(
              `Input jadwal dokter pada hari ${this.generateDayName(
                this.inputSchedule.schedule[i].day,
              )} belum lengkap`,
              '',
              'error',
            );
            this.loadingButton = false;
            this.isDisabled = false;
            return;
          }
          if (
            this.inputSchedule.schedule[i].data[y].start_time &&
            this.inputSchedule.schedule[i].data[y].end_time &&
            this.inputSchedule.schedule[i].data[y].quota &&
            this.inputSchedule.schedule[i].data[y].room
          ) {
            this.fixSchedule.push({
              day: this.inputSchedule.schedule[i].day,
              start_time: this.inputSchedule.schedule[i].data[y].start_time,
              end_time: this.inputSchedule.schedule[i].data[y].end_time,
              quota: this.inputSchedule.schedule[i].data[y].quota,
              room: this.inputSchedule.schedule[i].data[y].room,
            });
          }
        }
      }
      axios
        .post(Constant.apiUrl.concat('/schedule/default'), {
          id_staff: this.inputFilterSchedule.id_staff.id,
          id_unit: this.inputFilterSchedule.id_unit,
          schedule: this.fixSchedule,
          queue_code: this.inputSchedule.queue_code,
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        })
        .then(response => {
          if (response.status === 200) {
            Swal.fire('Jadwal Dokter berhasil ditambahkan!', '', 'success');
          }
        })
        .catch(err => {
          this.showErrorAxios(err);
        })
        .finally(() => {
          this.loadingButton = false;
          this.isDisabled = false;
          this.getScheduleDoctor();
        });
    },
    addSchedule(day, schedule) {
      if (this.isFormIncomplete(day, schedule)) return;
      this.inputSchedule.schedule[day].data.push({
        endTimeMenu: false,
        start_time: '',
        end_time: '',
        quota: '',
        room: '',
      });
    },
    deleteSchedule(dayIndex, itemIndex) {
      if (this.inputSchedule.schedule[dayIndex].data.length === 1) return;
      this.inputSchedule.schedule[dayIndex].data.splice(itemIndex, 1);
    },
    getScheduleDoctor() {
      if (
        this.inputFilterSchedule.id_staff &&
        this.inputFilterSchedule.id_unit
      ) {
        axios
          .get(
            Constant.apiUrl.concat(
              `/schedule/default/unit-doctor/${this.inputFilterSchedule.id_staff.id}/${this.inputFilterSchedule.id_unit}`,
            ),
          )
          .then(response => {
            this.isHaveSchedule = true;
            const data = response.data.data;
            if (data !== 0) {
              this.inputSchedule = {
                queue_code: '',
                schedule: [
                  {
                    day: 1,
                    data: [
                      {
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                  {
                    day: 2,
                    data: [
                      {
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                  {
                    day: 3,
                    data: [
                      {
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                  {
                    day: 4,
                    data: [
                      {
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                  {
                    day: 5,
                    data: [
                      {
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                  {
                    day: 6,
                    data: [
                      {
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                  {
                    day: 7,
                    data: [
                      {
                        endTimeMenu: false,
                        start_time: '',
                        end_time: '',
                        quota: '',
                        room: '',
                      },
                    ],
                  },
                ],
              };
              this.inputSchedule = {
                ...this.inputSchedule,
                queue_code: data[0].queue_code,
              };
              data.forEach(arr => {
                const schedule = {
                  day: arr.day,
                  data: arr.schedule.map(item => {
                    return {
                      endTimeMenu: false,
                      start_time: item.start_time,
                      end_time: item.end_time,
                      quota: item.quota,
                      room: item.room,
                    };
                  }),
                };
                this.inputSchedule.schedule.splice(arr.day - 1, 1, schedule);
              });
            } else {
              throw false;
            }
          })
          .catch(() => {
            this.isHaveSchedule = false;
            this.inputSchedule = {
              schedule: [
                {
                  day: 1,
                  data: [
                    {
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
                {
                  day: 2,
                  data: [
                    {
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
                {
                  day: 3,
                  data: [
                    {
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
                {
                  day: 4,
                  data: [
                    {
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
                {
                  day: 5,
                  data: [
                    {
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
                {
                  day: 6,
                  data: [
                    {
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
                {
                  day: 7,
                  data: [
                    {
                      endTimeMenu: false,
                      start_time: '',
                      end_time: '',
                      quota: '',
                      room: '',
                    },
                  ],
                },
              ],
            };
          })
          .finally(() => {
            if (
              this.inputFilterSchedule.id_staff &&
              this.inputFilterSchedule.id_unit
            ) {
              this.panel = 0;
              this.getSelectedPoly(this.inputFilterSchedule.id_unit);
            } else {
              this.panel = null;
              this.selectedPoly = '';
            }
          });
      }
    },
    getSchedulePoly() {
      if (this.inputFilterSchedule.id_unit)
        axios
          .get(
            Constant.apiUrl.concat(
              `/schedule/default?id_unit=${this.inputFilterSchedule.id_unit}`,
            ),
          )
          .then(response => {
            if (response.status == 200) {
              let schedule = response.data.data;
              this.doctorSchedule = schedule.map(s => {
                return {
                  doctorName: s.id_staff.detail[0].name,
                  schedule: s.schedule,
                };
              });
            }
          });
    },
    getPoly() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=1&itemCount=&search=&sort=name',
          ),
        )
        .then(response => {
          if (response.status === 200) {
            this.poly = response.data.data
              .filter(
                x =>
                  x.detail.installation === 'Installasi Rawat Inap' ||
                  x.detail.installation === 'Instalasi Rawat Jalan',
              )
              .map(unit => {
                return {
                  id: unit._id.toString(),
                  name: unit.name,
                };
              });
          }
        });
    },
    getSelectedPoly(polyId) {
      const poly = this.poly.find(v => v.id === polyId);
      this.selectedPoly = poly.name;
    },
    getDoctor() {
      this.doctorLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/staff?role[]=Dokter&role[]=Bidan&role[]=dokter&role[]=bidan&role[]=fisioterapis&role[]=Fisioterapis',
          ),
        )
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.doctors = data
              .filter(v => v.role.status)
              .map(doctor => {
                return {
                  id: doctor._id.toString(),
                  name: doctor.detail.name,
                };
              });
          }
        })
        .catch(() => {
          this.doctors = [];
        })
        .finally(() => {
          this.doctorLoading = false;
        });
    },
    getSpecialistDoctor() {
      this.getSpecialistLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/schedule/default?id_unit=${this.polyclinic.id || ''}`,
          ),
        )
        .then(response => {
          if (response.status == 200) {
            let specialist = response.data.data;
            this.doctorSchedule = specialist.map(specialistDoctor => {
              return {
                scheduleId: specialistDoctor._id.toString(),
                doctorName: specialistDoctor.id_staff.detail[0].name,
                schedule: specialistDoctor.schedule,
              };
            });
          }
          this.getSpecialistLoading = false;
        })
        .catch(error => {
          if (error.response.status == 404) {
            this.doctorSchedule = '';
          }
          this.getSpecialistLoading = false;
        });
    },
    getAllSpecialistDoctor() {
      this.getSpecialistLoading = true;
      axios
        .post(Constant.apiUrl.concat('/schedule'), {
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment()
            .add(7, 'days')
            .format('YYYY-MM-DD'),
          id_unit: this.polyclinic.id,
          id_staff: '',
          isActive: true,
        })
        .then(response => {
          if (response.status == 200) {
            const data = response.data.data;
            this.doctorSchedule = data.map((specialistDoctor, idx) => {
              return {
                scheduleId: idx,
                doctorName: specialistDoctor.id_staff,
                schedule: specialistDoctor.schedule,
                unit: specialistDoctor.id_unit,
              };
            });
          }
          this.getSpecialistLoading = false;
        })
        .catch(error => {
          if (error.response.status == 404) {
            this.doctorSchedule = [];
          }
          this.getSpecialistLoading = false;
        });
    },
    clearSnackbar() {
      this.snackbar = {
        show: false,
        color: 'success',
        text: 'Jadwal dokter berhasil dihapus',
      };
    },
    deleteScheduleDoctor() {
      ModalConfirmation({
        title: `<p>Yakin Ingin Menghapus Jadwal</p><p>Dokter Ini?</p>`,
        icon: 'question',
        desc: `<p>Anda akan menghapus jadwal </p><p>${
          this.inputFilterSchedule.id_staff.name
        } di ${
          this.poly.find(item => item.id === this.inputFilterSchedule.id_unit)
            .name
        }</p>`,
        confirmButton: 'Yakin',
        cancelButton: 'Kembali',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .delete(
              Constant.apiUrl.concat(
                `/schedule/default/${this.inputFilterSchedule.id_staff.id}/${this.inputFilterSchedule.id_unit}`,
              ),
            )
            .then(response => {
              if (response.status == 200) {
                this.snackbar = {
                  show: true,
                  color: 'success',
                  text: 'Jadwal dokter berhasil dihapus',
                };
                this.getScheduleDoctor();
              }
            })
            .catch(() => {
              this.snackbar = {
                show: true,
                color: 'error',
                text: 'Jadwal dokter gagal dihapus',
              };
              this.getScheduleDoctor();
            });
        }
      });
    },
  },
  mounted() {
    this.clearSnackbar();
    this.getPoly();
    this.getDoctor();
    this.getAllSpecialistDoctor();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .label-input .theme--light.v-input input,
.theme--light.v-input textarea {
  color: #fff;
  text-align: center;
}

.plus-btn {
  background-color: #137bc0;
  border-radius: 4px;
  padding: 0.3vw 0.4vw;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &.disable {
    background-color: gray;
  }
}

.form-container {
  display: block;

  label {
    color: #757575;
    font-size: 0.8vw;
  }

  .row-grid {
    display: grid;
    gap: 1vw;
    grid-template-columns: repeat(4, 2fr) 1fr;
  }
}

::v-deep
  .v-text-field__slot
  input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
