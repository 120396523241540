import Constant from '@/const';
import axios from 'axios';

const postServices = async payload => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat(`/master/service/`),
      payload,
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default postServices;
