import Constant from '@/const';
import axios from 'axios';

const getPatientPharmacy = async id => {
  try {
    const response = await axios.get(Constant.apiUrl.concat(`/pharmacy/${id}`));
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getPatientPharmacy;
