const resources = {
  usageOne: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  usageTwo: ['1/4', '1/3', '1/2', '2/3', '3/4', '1', '2', '3', '4', '5'],
  packaging: {
    nonRacik: [
      'Bungkus',
      'Hisap',
      'Kapsul',
      'Kumur',
      'ml',
      'mg',
      'Oleskan',
      'Semprot',
      'Sendok Makan',
      'Sendok Obat',
      'Sendok Sup',
      'Sendok Teh',
      'Tablet',
      'Tetes',
      'Ampul',
      'gr',
      'Ovula',
      'Patch',
      'Rectal Tube',
      'pcs',
      'TPM',
      'Unit',
      'Flexpen',
      'Vial',
    ],
    racikAmount: ['Bungkus', 'Kapsul', 'Salep', 'Sirup'],
    racik: [
      'Bungkus',
      'Hisap',
      'Kapsul',
      'Kumur',
      'ml',
      'mg',
      'Oleskan',
      'Semprot',
      'Sendok Makan',
      'Sendok Obat',
      'Sendok Sup',
      'Sendok Teh',
      'Tablet',
      'Tetes',
      'Ampul',
      'gr',
      'Ovula',
      'Patch',
      'Rectal Tube',
      'pcs',
      'TPM',
      'Unit',
      'Flexpen',
      'Vial',
    ],
  },
  general_rules: [
    '15 menit sebelum makan',
    '½ jam sebelum makan',
    '1 jam sebelum makan',
    'Sebelum makan',
    '1 jam setelah makan',
    '2 jam  sesudah makan',
    'Sebelum suapan pertama',
    'Bersama atau tanpa makan',
    'Bersama makan',
    'Kunyah ',
    'Kunyah bersama makan',
    'Sebelum atau sesudah makan',
    'Sesudah makan',
    'Setelah suapan pertama',
    'Taruh diatas lidah',
    'Taruh dibawah lidah ',
  ],
  route: [
    'Per Oral',
    'Sublingual',
    'Per Vagina',
    'Per Rectal',
    'Intravena',
    'Intramuscular',
    'Subcutan',
    'Okular (mata)',
    'Otic (telinga)',
    'Nasal (hidung)',
    'Inhalasi',
    'Transdermal',
    'Intratekal',
    'Topikal',
    'Intrakutan',
  ],
  schedule: {
    today: [
      { time: 'Pagi', placeHolder: '05:30' },
      { time: 'Siang', placeHolder: '13:30' },
      { time: 'Sore', placeHolder: '17:30' },
      { time: 'Malam', placeHolder: '21:30' },
    ],
    nextDay: [
      { time: 'Pagi', placeHolder: '05:30' },
      { time: 'Siang', placeHolder: '13:30' },
      { time: 'Sore', placeHolder: '17:30' },
      { time: 'Malam', placeHolder: '21:30' },
    ],
  },
  drugs: [],
};

export default resources;
