const moment = require('moment-timezone');
const _ = require('lodash');
const { formatAddress } = require('@/helper');

const mapPatientServiceDashboardData = source => {
  return source.map(item => {
    return {
      id_registration: item._id.toString(),
      id_emr: item.id_emr.toString(),
      hasGeneralConsent: item.emr?.document?.generalConsentCreated,
      isInformedConsentCreated: item.document?.informConsentCreated,
      noRegist: item.registration_number.toString(),
      polyId: item.id_unit,
      polyName: item.unit[0].name,
      doctorId: item.doctor[0] ? item.doctor[0]._id : '',
      doctor: item.doctor[0] ? item.doctor[0].detail[0].name : '',
      role: item.doctor[0] ? item.doctor[0].role[0].role : '',
      noQueue: item.queue.number.toString(),
      pcare: item?.pcare,
      queue: item.queue_code
        ? `${item.queue_code}-${item.queue.number.toString().padStart(3, '0')}`
        : item.queue.number.toString().padStart(3, '0'),
      isNew: item.status.isNew ? 'Pasien Baru' : 'Pasien Lama',
      status: item.status,
      isFinish: item.queue.isFinish,
      time: item.time,
      date: moment(item.date)
        .locale('id')
        .format('LL'),
      profession: item.emr.social[0].profession,
      commmunicationObstacles: _.startCase(
        item.emr.social[0].communication_obstacles || 'tidak ada',
      ),
      //medical record
      noRM: item.emr.medical_record_number,
      name: item.emr.social[0].name,
      birthDate: moment(item.emr.social[0].birth.date)
        .locale('id')
        .format('LL'),
      birthDateUnformatted: item.emr.social[0].birth.date,
      birthPlace: item.emr.social[0].birth.place,
      phoneNumber: item.emr.social[0].phone_number,
      age: item.emr.social[0].age,
      gender: item.emr.social[0].gender ? 'Laki-laki' : 'Perempuan',
      address: formatAddress(item.emr.social[0].address),
      identityType: item.emr.social[0].identity.type,
      identityNumber: item.emr.social[0].identity.number,
      //assurance
      guarantorType: _.startCase(
        item.assurance.type === 'assurance' ? 'asuransi' : item.assurance.type,
      ).toUpperCase(),
      guarantorName: item.emr.social[0].assurance.name,
      guarantorNumber: item.emr.social[0].assurance.number,
      isProlanis: item.emr.social[0].assurance.isProlanis,
      identification_patient: {
        assesment_doctor_by: item?.identification_patient.assesment_doctor_by,
        assesment_nurse_by: item?.identification_patient.assesment_nurse_by,
        intervention: item?.identification_patient.intervention,
      },
      activityStatus: {
        assessment:
          item.activity_status?.assesment_doctor_status || 'not_scheduled',
        doctorAssessment:
          item.activity_status?.assesment_doctor_status || 'not_scheduled',
        nurseAssessment:
          item.activity_status?.assesment_nurse_status || 'not_scheduled',
        recipeStatus: item.activity_status?.recipe_status || 'not_scheduled',
        serviceStatus: item.activity_status?.service_status || 'not_scheduled',
        labStatus: item.activity_status?.lab_status || 'not_scheduled',
      },
      //allergy
      unitName: item.unit[0]?.name,
      timestamps: {
        created_at: moment(item.timestamps.created_at)
          .locale('id')
          .format('LT'),
        registered_at: item.timestamps.created_at,
      },
    };
  });
};

// map data for store "selected data" needs
const mapSelectedPatientData = source => {
  return {
    id_emr: source.id_emr,
    commmunicationObstacles: source.commmunicationObstacles,
    isInformedConsentCreated: source.isInformedConsentCreated,
    id_registration: source.id_registration,
    patientType: source.status.type,
    rmNumber: source.noRM,
    noQueue: source.noQueue,
    queue: source.queue,
    isFinish: source.isFinish,
    returnStatus: source.returnStatus,
    registered_at: source.timestamps.registered_at,
    identification_patient: source?.identification_patient,
    meta: {
      name: source.name,
      birthDate: source.birthDate,
      birthDateUnformatted: source.birthDateUnformatted,
      phoneNumber: source.phoneNumber,
      gender: source.gender,
      age: source.age,
      address: source.address,
      identityType: source.identityType,
      identityNumber: source.identityNumber,
      doctor: source.doctor,
      doctorId: source.doctorId,
      role: source.role,
      id_poly: source.polyId,
      polyName: source.unitName,
      profession: source.profession,
      guarantorType: source.guarantorType,
      guarantorName: source.guarantorName,
      guarantorNumber: source.guarantorNumber,
    },
  };
};

module.exports = { mapPatientServiceDashboardData, mapSelectedPatientData };
