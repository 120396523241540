import Constant from '@/const';
import axios from 'axios';

const getLabRequestById = async idRegistration => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/supportservice/laboratory/request/${idRegistration}`,
      ),
    );
    return response?.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getLabRequestById;
