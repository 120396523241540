<template>
  <v-form ref="form" class="form-container mt-4">
    <section>
      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label class="required">Nama Petugas</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-autocomplete
              :rules="isEMR ? [] : rules.required"
              dense
              single-line
              v-model="form.staff"
              :items="resource"
              item-text="name"
              item-value="id"
              placeholder="Pilih nama petugas"
              append-icon="mdi-chevron-down"
              class="input-field"
              :readonly="isEMR"
            />
          </Skeleton>
        </v-col>
        <v-col cols="6" class="pl-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label class="required">Waktu Pengisian</label>
          </Skeleton>
          <v-row no-gutters>
            <v-col cols="9" class="pr-5">
              <Skeleton :is-loading="isLoading">
                <v-text-field
                  readonly
                  dense
                  single-line
                  v-model="form.date"
                  class="input-field"
                  placeholder="DD-MM-YYYY"
                />
              </Skeleton>
            </v-col>
            <v-col cols="3" class="pl-5">
              <Skeleton :is-loading="isLoading">
                <v-text-field
                  type="time"
                  single-line
                  dense
                  v-model="form.time"
                  class="input-field"
                  :rules="isEMR ? [] : rules.required"
                  :readonly="isEMR"
                />
              </Skeleton>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="divider" />
    </section>

    <section>
      <Skeleton :is-loading="isLoading" :max-content="true">
        <h4>
          RIWAYAT SOSIAL
        </h4>
      </Skeleton>
      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label class="required">Kehamilan Saat Ini</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-autocomplete
              :rules="isEMR ? [] : rules.required"
              dense
              single-line
              v-model="form.socialHistory.currentPregnancy"
              :items="[
                'Direncanakan',
                'Tidak Direncanakan',
                'Diterima',
                'Tidak Direncanakan dan Tidak Diterima',
              ]"
              append-icon="mdi-chevron-down"
              placeholder="Pilih kehamilan saat ini"
              class="input-field"
              :readonly="isEMR"
            />
          </Skeleton>
        </v-col>
        <v-col cols="6" class="pl-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label>Perasaaan tentang Kehamilan Saat Ini</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-textarea
              dense
              single-line
              v-model="form.socialHistory.currentPregnancyFeeling"
              class="input-field"
              height="130"
              placeholder="Masukkan deskripsi"
              no-resize
              outlined
              :readonly="isEMR"
            />
          </Skeleton>
        </v-col>
      </v-row>
      <v-divider class="divider" />
    </section>

    <section v-for="(item, index) in form.maritalHistory" :key="index">
      <v-divider v-if="index !== 0" class="divider mb-6" />
      <Skeleton :is-loading="isLoading" :max-content="true">
        <h4>STATUS PERKAWINAN {{ index + 1 }}</h4>
      </Skeleton>
      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label>Status</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-autocomplete
              dense
              single-line
              v-model="form.maritalHistory[index].status"
              :items="['Kawin', 'Cerai Hidup', 'Cerai Mati']"
              append-icon="mdi-chevron-down"
              placeholder="Pilih status"
              class="input-field"
              :readonly="isEMR"
            />
          </Skeleton>
        </v-col>
        <v-spacer />
        <v-col cols="6" class="pl-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label>Abortus</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-text-field
              dense
              single-line
              v-model.number="form.maritalHistory[index].abortus"
              class="input-field"
              placeholder="0"
              type="number"
              suffix="Kali"
              :readonly="isEMR"
            />
          </Skeleton>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label>Pada Umur</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-text-field
              dense
              single-line
              v-model.number="form.maritalHistory[index].age"
              class="input-field"
              placeholder="0"
              type="number"
              suffix="Tahun"
              :readonly="isEMR"
            />
          </Skeleton>
        </v-col>
        <v-col cols="6" class="pl-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label>Dengan Suami Umur</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-text-field
              dense
              single-line
              v-model.number="form.maritalHistory[index].husbandAge"
              class="input-field"
              placeholder="0"
              type="number"
              suffix="Tahun"
              :readonly="isEMR"
            />
          </Skeleton>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label>Lamanya</label>
          </Skeleton>
          <v-row no-gutters>
            <v-col cols="6" class="pr-5">
              <Skeleton :is-loading="isLoading">
                <v-text-field
                  dense
                  single-line
                  v-model.number="form.maritalHistory[index].durationMarriage"
                  class="input-field"
                  placeholder="0"
                  type="number"
                  :readonly="isEMR"
                />
              </Skeleton>
            </v-col>
            <v-col cols="6" class="pl-5">
              <Skeleton :is-loading="isLoading">
                <v-autocomplete
                  dense
                  single-line
                  v-model="form.maritalHistory[index].durationMarriageUnit"
                  :items="['Tahun', 'Bulan', 'Minggu']"
                  append-icon="mdi-chevron-down"
                  placeholder="Pilih status"
                  class="input-field"
                  :readonly="isEMR"
                />
              </Skeleton>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="pl-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label>Jumlah Anak</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-text-field
              dense
              single-line
              v-model.number="form.maritalHistory[index].totalChildren"
              class="input-field"
              placeholder="0"
              type="number"
              suffix="Anak"
              :readonly="isEMR"
            />
          </Skeleton>
        </v-col>
      </v-row>
      <v-row v-if="index + 1 === form.maritalHistory.length && !isEMR">
        <v-spacer />
        <Skeleton :is-loading="isLoading">
          <v-btn
            v-if="index !== 0 && !isEMR"
            color="error"
            text
            tile
            class="text-none"
            @click="handleRemoveMaritalHistory(index)"
            >Hapus</v-btn
          >
        </Skeleton>
        <Skeleton :is-loading="isLoading">
          <v-btn
            v-if="!isEMR"
            color="primary"
            outlined
            tile
            depressed
            class="text-none mx-2"
            @click="handleAddMaritalHistory"
            >Tambah Baru</v-btn
          >
        </Skeleton>
      </v-row>
      <v-divider
        class="divider"
        v-if="form.maritalHistory.length === index + 1"
      />
    </section>

    <section>
      <Skeleton :is-loading="isLoading" :max-content="true">
        <h4>
          RIWAYAT MENSTRUASI
        </h4>
      </Skeleton>
      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label>Intensitas</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-radio-group
              class="input-field"
              row
              v-model="form.menstruationHistory.intensity"
              :readonly="isEMR"
            >
              <v-radio label="Teratur" :value="true"></v-radio>
              <v-radio label="Tidak Teratur" :value="false"></v-radio>
            </v-radio-group>
          </Skeleton>
        </v-col>
        <v-col cols="6" class="pl-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label>Banyaknya</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-text-field
              dense
              single-line
              v-model.number="form.menstruationHistory.amount"
              class="input-field"
              placeholder="0"
              type="number"
              suffix="cc"
              :readonly="isEMR"
            />
          </Skeleton>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label>Haid Pertama</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-text-field
              dense
              single-line
              v-model.number="form.menstruationHistory.firstPeriod"
              class="input-field"
              placeholder="0"
              type="number"
              suffix="Tahun"
              :readonly="isEMR"
            />
          </Skeleton>
        </v-col>
        <v-col cols="6" class="pl-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label>Dysmenorrhea</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-radio-group
              class="input-field"
              row
              v-model="form.menstruationHistory.dysmenorrhea"
              :readonly="isEMR"
            >
              <v-radio label="Tidak Ada" :value="false"></v-radio>
              <v-radio label="Ada" :value="true"></v-radio>
            </v-radio-group>
          </Skeleton>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <Skeleton :is-loading="isLoading" :max-content="true">
            <label>Siklus</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-text-field
              dense
              single-line
              v-model.number="form.menstruationHistory.periodCycle"
              class="input-field"
              placeholder="0"
              type="number"
              suffix="Hari"
              :readonly="isEMR"
            />
          </Skeleton>
        </v-col>
      </v-row>
    </section>
  </v-form>
</template>

<script>
import Skeleton from '../../../../../SharedComponent/Skeleton.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'ObstetricsAssessment',
);
const { mapGetters: staffGetters } = createNamespacedHelpers('MasterStaff');

export default {
  name: 'SocialMenstrualHistory',
  props: {
    isEMR: Boolean,
  },
  components: {
    Skeleton,
  },
  computed: {
    ...mapGetters(['getForm', 'getRules', 'getIsLoading']),
    ...staffGetters(['getItems']),
    isLoading: {
      get() {
        return this.getIsLoading || this.getItems.dataStaff.isLoading;
      },
    },
    form: {
      get() {
        return this.getForm.socialMenstrualHistory;
      },
    },
    resource: {
      get() {
        return this.getItems.dataStaff.data
          .filter(
            item =>
              item.role.role.some(
                item =>
                  item.toLowerCase() === 'perawat' ||
                  item.toLowerCase() === 'bidan',
              ) && item.role.status,
          )
          .map(item => {
            return { name: item.name, id: item.id };
          });
      },
    },
    rules: {
      get() {
        return this.getRules;
      },
    },
  },
  methods: {
    ...mapMutations(['']),
    ...mapActions(['addMaritalHistory', 'removeMaritalHistory']),
    handleAddMaritalHistory() {
      this.addMaritalHistory();
    },
    handleRemoveMaritalHistory(index) {
      this.removeMaritalHistory(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: start;
  gap: 1.5rem;
  .divider {
    margin-top: 1.5rem;
    width: 100%;
  }
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: #3f484a;
  }
  .input-field {
    margin-top: 0.5rem;
    width: 100%;
  }
  h4 {
    font-weight: 400;
    color: #137bc0;
    text-align: start;
    margin-bottom: 0.7vw;
  }
}
section {
  width: 100%;
}
.required:after {
  content: ' *';
  color: red;
}
::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

::v-deep
  .v-text-field__slot
  input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
