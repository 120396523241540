import moment from 'moment-timezone';
import { generateBadgeObstetrics } from './InpatientAssessmentUtils';
import {
  getObstetricsAssessment,
  postObstetricsAssessment,
  postGeneratePregnancyDueDate,
} from '@/fetchApi/Inpatient/ObstreticalAssessment';
import { getPregnancyHistory } from '@/fetchApi/Inpatient/LabourAssessment';
const _ = require('lodash');

const state = {
  dashboard: {
    tab: 0,
    isNew: false,
    loading: false,
    isLoading: true,
    showSuccessMessage: {
      text: null,
      show: false,
    },
    tabsResource: [
      {
        key: 'socialMenstrualHistory',
        text: 'Riwayat Sosial & Menstruasi',
      },
      {
        key: 'pregnancyHistory',
        text: 'Riwayat Kehamilan',
      },
      {
        key: 'kbAndImunizationHistory',
        text: 'Riwayat KB & Imunisasi',
      },
      {
        key: 'obstreticalHistory',
        text: 'Riwayat Obstetri',
      },
    ],
  },
  patientData: {
    id_inpatient: '',
    id_emr: '',
  },
  form: {
    id_emr: '',
    socialMenstrualHistory: {
      staff: '',
      date: '',
      time: '',
      socialHistory: {
        currentPregnancy: '',
        currentPregnancyFeeling: '',
      },
      maritalHistory: [
        {
          status: '',
          age: null,
          durationMarriage: null,
          durationMarriageUnit: 'Tahun',
          abortus: null,
          husbandAge: null,
          totalChildren: null,
        },
      ],
      menstruationHistory: {
        intensity: true,
        firstPeriod: null,
        periodCycle: null,
        amount: null,
        dysmenorrhea: false,
      },
    },
    pregnancyHistory: {
      currentPregnancy: {
        hpht: null,
        estimatedDate: null,
        complaintsPerTrimester: [''],
        firstFetalMovement: null,
        totalFetalMovement: '1',
      },
      previousPregnancyHistory: [
        {
          pregnancyTo: 1,
          ageYear: null,
          ageMonth: null,
          gender: '',
          helper: '',
          helperEtc: '',
          place: '',
          placeEtc: '',
          isAlive: { name: 'Hidup', key: true },
          bbl: null,
          complication: '',
          currentCondition: '',
        },
      ],
    },
    kbAndImunizationHistory: {
      kbStatus: null,
      kbMethod: [],
      diseaseSurgeryHistory: '',
      familyDiseaseHistory: '',
      dietAndImunization: {
        dietBeforePregrant: '',
        dietAfterPregrant: '',
        imunizationTT: true,
      },
    },
    obstreticalHistory: {
      uterinePalpation: '',
      fundusUteriHeight: null,
      place: '',
      fetalPresentation: '',
      fetalWeightEstimation: null,
      isConstraction: false,
    },
  },
  initialForm: {
    id_emr: '',
    socialMenstrualHistory: {
      staff: '',
      date: '',
      time: '',
      socialHistory: {
        currentPregnancy: '',
        currentPregnancyFeeling: '',
      },
      maritalHistory: [
        {
          status: '',
          age: null,
          durationMarriage: null,
          durationMarriageUnit: 'Tahun',
          abortus: null,
          husbandAge: null,
          totalChildren: null,
        },
      ],
      menstruationHistory: {
        intensity: true,
        firstPeriod: null,
        periodCycle: null,
        amount: null,
        dysmenorrhea: false,
      },
    },
    pregnancyHistory: {
      currentPregnancy: {
        hpht: null,
        estimatedDate: null,
        complaintsPerTrimester: [''],
        firstFetalMovement: null,
        totalFetalMovement: '1',
      },
      previousPregnancyHistory: [
        {
          pregnancyTo: 1,
          ageYear: null,
          ageMonth: null,
          gender: '',
          helper: '',
          helperEtc: '',
          place: '',
          placeEtc: '',
          isAlive: { name: 'Hidup', key: true },
          bbl: null,
          complication: '',
          currentCondition: '',
        },
      ],
    },
    kbAndImunizationHistory: {
      kbStatus: null,
      kbMethod: [],
      diseaseSurgeryHistory: '',
      familyDiseaseHistory: '',
      dietAndImunization: {
        dietBeforePregrant: '',
        dietAfterPregrant: '',
        imunizationTT: true,
      },
    },
    obstreticalHistory: {
      uterinePalpation: '',
      fundusUteriHeight: null,
      place: '',
      fetalPresentation: '',
      fetalWeightEstimation: null,
      isConstraction: false,
    },
  },
  items: {
    kbMethod: [
      'Suntik',
      'IUD',
      'Pil',
      'Kondom',
      'Kalender',
      'MOW',
      'MOP',
      'Implan',
    ],
    place: ['Rumah', 'RS', 'BPS', 'Puskesmas', 'Lain-Lain'],
    helper: ['Bidan', 'Dokter', 'Dukun', 'Lain-Lain'],
  },
  rules: {
    required: [v => !!v || 'Wajib diisi'],
    radioRules: [v => v !== null || 'Wajib diisi'],
  },
};

const getters = {
  // Get Is New or Not
  getIsNew: state => state.dashboard.isNew,

  // Get Tab
  getObstetricsTab: state => state.dashboard.tab,

  getLoading: state => state.dashboard.loading,

  // Get Show Message
  getShowSuccessMessage: state => state.dashboard.showSuccessMessage,

  // Get Loading
  getIsLoading: state => state.dashboard.isLoading,

  // Get Tabs Resource
  getObstetricsTabsResource: state => state.dashboard.tabsResource,

  // Get Change
  getObstetricsIsChanged: state => !_.isEqual(state.initialForm, state.form),

  // Get State patientData
  getPatientData: state => state.patientData,

  // Get State Form
  getForm: state => state.form,

  // Get State initialForm
  getInitialForm: state => state.initialForm,

  // Get Login Data
  getLoginData: (_, getters, rootState) => {
    return rootState.userLoggedIn;
  },

  // Get State Rules
  getRules: state => state.rules,

  // Get State Items
  getItems: state => state.items,
};

const mutations = {
  // Set Tab
  setTab(state, value) {
    state.dashboard.tab = value.destination;
  },

  setTabsResource(state, { index, payload }) {
    state.dashboard.tabsResource.splice(index, 1, {
      ...state.dashboard.tabsResource[index],
      ...payload,
    });
  },

  setShowSuccessMessage(state, payload) {
    state.dashboard.showSuccessMessage = payload;
  },

  // Set Patient Data
  setPatientData(state, value) {
    state.patientData = value;
  },

  // Set isNew
  setIsNew(state, value) {
    state.dashboard.isNew = value;
  },

  // Set All State Form
  setForm(state, payload) {
    state.form = payload;
  },

  // Set State initialForm
  setInitialForm(state, payload) {
    state.initialForm = payload;
  },

  setPreviousPregnancyHistory(state, payload) {
    state.form.pregnancyHistory.previousPregnancyHistory = payload;
  },
  setInitialPreviousPregnancyHistory(state, payload) {
    state.initialForm.pregnancyHistory.previousPregnancyHistory = payload;
  },

  // Clear Form
  clearForm(state) {
    state.form = {
      id_emr: '',
      socialMenstrualHistory: {
        staff: '',
        date: '',
        time: '',
        socialHistory: {
          currentPregnancy: '',
          currentPregnancyFeeling: '',
        },
        maritalHistory: [
          {
            status: '',
            age: null,
            durationMarriage: null,
            durationMarriageUnit: 'Tahun',
            abortus: null,
            husbandAge: null,
            totalChildren: null,
          },
        ],
        menstruationHistory: {
          intensity: true,
          firstPeriod: null,
          periodCycle: null,
          amount: null,
          dysmenorrhea: false,
        },
      },
      pregnancyHistory: {
        currentPregnancy: {
          hpht: null,
          estimatedDate: null,
          complaintsPerTrimester: [''],
          firstFetalMovement: null,
          totalFetalMovement: '1',
        },
        previousPregnancyHistory: [
          {
            pregnancyTo: 1,
            ageYear: null,
            ageMonth: null,
            gender: '',
            helper: '',
            helperEtc: '',
            place: '',
            placeEtc: '',
            isAlive: { name: 'Hidup', key: true },
            bbl: null,
            complication: '',
            currentCondition: '',
          },
        ],
      },
      kbAndImunizationHistory: {
        kbStatus: null,
        kbMethod: [],
        diseaseSurgeryHistory: '',
        familyDiseaseHistory: '',
        dietAndImunization: {
          dietBeforePregrant: '',
          dietAfterPregrant: '',
          imunizationTT: true,
        },
      },
      obstreticalHistory: {
        uterinePalpation: '',
        fundusUteriHeight: null,
        place: '',
        fetalPresentation: '',
        fetalWeightEstimation: null,
        isConstraction: false,
      },
    };

    state.initialForm = {
      id_emr: '',
      socialMenstrualHistory: {
        staff: '',
        date: '',
        time: '',
        socialHistory: {
          currentPregnancy: '',
          currentPregnancyFeeling: '',
        },
        maritalHistory: [
          {
            status: '',
            age: null,
            durationMarriage: null,
            durationMarriageUnit: 'Tahun',
            abortus: null,
            husbandAge: null,
            totalChildren: null,
          },
        ],
        menstruationHistory: {
          intensity: true,
          firstPeriod: null,
          periodCycle: null,
          amount: null,
          dysmenorrhea: false,
        },
      },
      pregnancyHistory: {
        currentPregnancy: {
          hpht: null,
          estimatedDate: null,
          complaintsPerTrimester: [''],
          firstFetalMovement: null,
          totalFetalMovement: '1',
        },
        previousPregnancyHistory: [
          {
            pregnancyTo: 1,
            ageYear: null,
            ageMonth: null,
            gender: '',
            helper: '',
            helperEtc: '',
            place: '',
            placeEtc: '',
            isAlive: { name: 'Hidup', key: true },
            bbl: null,
            complication: '',
            currentCondition: '',
          },
        ],
      },
      kbAndImunizationHistory: {
        kbStatus: null,
        kbMethod: [],
        diseaseSurgeryHistory: '',
        familyDiseaseHistory: '',
        dietAndImunization: {
          dietBeforePregrant: '',
          dietAfterPregrant: '',
          imunizationTT: true,
        },
      },
      obstreticalHistory: {
        uterinePalpation: '',
        fundusUteriHeight: null,
        place: '',
        fetalPresentation: '',
        fetalWeightEstimation: null,
        isConstraction: false,
      },
    };
    state.dashboard.tab = 0;
    state.dashboard.tabsResource = [
      {
        key: 'socialMenstrualHistory',
        text: 'Riwayat Sosial & Menstruasi',
      },
      {
        key: 'pregnancyHistory',
        text: 'Riwayat Kehamilan',
      },
      {
        key: 'kbAndImunizationHistory',
        text: 'Riwayat KB & Imunisasi',
      },
      {
        key: 'obstreticalHistory',
        text: 'Riwayat Obstetri',
      },
    ];
  },

  // Set Estimate Date HPHT
  setEstimatedDate(state, payload) {
    state.form.pregnancyHistory.currentPregnancy.estimatedDate = payload;
  },

  // Set Default Value
  setDefaultValue(state, value) {
    const { staff } = value;
    state.form.socialMenstrualHistory.date = moment().format('YYYY-MM-DD');
    state.form.socialMenstrualHistory.time = moment().format('HH:mm');
    state.initialForm.socialMenstrualHistory.date = moment().format(
      'YYYY-MM-DD',
    );
    state.initialForm.socialMenstrualHistory.time = moment().format('HH:mm');
    state.form.socialMenstrualHistory.staff = staff;
    state.initialForm.socialMenstrualHistory.staff = staff;
  },

  // Set Loading
  setIsLoading(state, value) {
    state.dashboard.isLoading = value;
  },
};

const actions = {
  addMaritalHistory({ state }) {
    const newMaritalHistoryEntry = {
      status: '',
      age: null,
      durationMarriage: null,
      durationMarriageUnit: 'Tahun',
      abortus: null,
      husbandAge: null,
      totalChildren: null,
    };

    state.form.socialMenstrualHistory.maritalHistory.push(
      newMaritalHistoryEntry,
    );
  },
  removeMaritalHistory({ state }, index) {
    state.form.socialMenstrualHistory.maritalHistory.splice(index, 1);
  },
  addComplaintsPerTrimester({ state }) {
    state.form.pregnancyHistory.currentPregnancy.complaintsPerTrimester.push(
      '',
    );
  },
  removeComplaintsPerTrimester({ state }, index) {
    state.form.pregnancyHistory.currentPregnancy.complaintsPerTrimester.splice(
      index,
      1,
    );
  },
  addPregnancyHistory({ state }) {
    state.form.pregnancyHistory.previousPregnancyHistory.push({
      pregnancyTo:
        state.form.pregnancyHistory.previousPregnancyHistory.length + 1,
      ageYear: null,
      ageMonth: null,
      gender: '',
      helper: '',
      helperEtc: '',
      place: '',
      placeEtc: '',
      isAlive: { name: 'Hidup', key: true },
      bbl: null,
      complication: '',
      currentCondition: '',
    });
  },
  removePregnancyHistory({ state }, index) {
    state.form.pregnancyHistory.previousPregnancyHistory.splice(index, 1);
  },
  formValidation({ commit, state }, payload) {
    if (payload.destination > payload.origin) {
      for (let i = payload.origin; i < payload.destination; i++) {
        commit('setTabsResource', {
          index: i,
          payload: {
            icon: generateBadgeObstetrics(state.form, i),
          },
        });
      }
    } else {
      for (let i = payload.origin; i > payload.destination; i--) {
        commit('setTabsResource', {
          index: i,
          payload: {
            icon: generateBadgeObstetrics(state.form, i),
          },
        });
      }
    }
  },

  // Get
  // Generate Pregnancy Due Date
  async resolveGetPregnancyHistory({ commit, state }) {
    try {
      const response = await getPregnancyHistory(state.patientData.id_emr);
      commit(
        'setPreviousPregnancyHistory',
        response.data.data.map(item => {
          return {
            ...item,
            isAlive: item.isAlive
              ? { name: 'Hidup', key: true }
              : { name: 'Mati', key: false },
            place: state.items.place.some(
              val => val === item.place || item.place === '',
            )
              ? item.place
              : 'Lain-Lain',
            placeEtc: state.items.place.some(
              val => val === item.place || item.place === '',
            )
              ? ''
              : item.place,
            helper: state.items.helper.some(
              val => val === item.helper || item.helper === '',
            )
              ? item.helper
              : 'Lain-Lain',
            helperEtc: state.items.helper.some(
              val => val === item.helper || item.helper === '',
            )
              ? ''
              : item.helper,
          };
        }),
      );
      commit(
        'setInitialPreviousPregnancyHistory',
        response.data.data.map(item => {
          return {
            ...item,
            isAlive: item.isAlive
              ? { name: 'Hidup', key: true }
              : { name: 'Mati', key: false },
            place: state.items.place.some(
              val => val === item.place || item.place === '',
            )
              ? item.place
              : 'Lain-Lain',
            placeEtc: state.items.place.some(
              val => val === item.place || item.place === '',
            )
              ? ''
              : item.place,
            helper: state.items.helper.some(
              val => val === item.helper || item.helper === '',
            )
              ? item.helper
              : 'Lain-Lain',
            helperEtc: state.items.helper.some(
              val => val === item.helper || item.helper === '',
            )
              ? ''
              : item.helper,
          };
        }),
      );
    } catch (error) {
      commit('setPreviousPregnancyHistory', [
        {
          pregnancyTo: 1,
          ageYear: null,
          ageMonth: null,
          gender: '',
          helper: '',
          helperEtc: '',
          place: '',
          placeEtc: '',
          isAlive: null,
          bbl: null,
          complication: '',
          currentCondition: '',
        },
      ]);
      commit('setInitialPreviousPregnancyHistory', [
        {
          pregnancyTo: 1,
          ageYear: null,
          ageMonth: null,
          gender: '',
          helper: '',
          helperEtc: '',
          place: '',
          placeEtc: '',
          isAlive: null,
          bbl: null,
          complication: '',
          currentCondition: '',
        },
      ]);
      return error;
    }
  },

  async resolveGetObstetricsAssessment({ commit, state, dispatch }) {
    commit('setIsLoading', true);
    try {
      const response = await getObstetricsAssessment(
        state.patientData.id_inpatient,
      );
      const data = response.data.data;
      const dataInitial = response.data.data;
      const dataMapper = {
        ...data,
        socialMenstrualHistory:
          data.socialMenstrualHistory.staff === undefined
            ? {
                ...state.form.socialMenstrualHistory,
                staff: state.form.socialMenstrualHistory.staff.id,
              }
            : {
                ...data.socialMenstrualHistory,
                staff: data.socialMenstrualHistory?.staff?.id || '',
              },
        pregnancyHistory: {
          ...data.pregnancyHistory,
          currentPregnancy: {
            ...data.pregnancyHistory.currentPregnancy,
            complaintsPerTrimester:
              data.pregnancyHistory.currentPregnancy?.complaintsPerTrimester
                .length === 0
                ? ['']
                : data.pregnancyHistory.currentPregnancy
                    ?.complaintsPerTrimester,
          },
          previousPregnancyHistory:
            data.pregnancyHistory.previousPregnancyHistory.length === 0
              ? [
                  {
                    pregnancyTo: 1,
                    ageYear: null,
                    ageMonth: null,
                    gender: '',
                    helper: '',
                    helperEtc: '',
                    place: '',
                    placeEtc: '',
                    isAlive: { name: 'Hidup', key: true },
                    bbl: null,
                    complication: '',
                    currentCondition: '',
                  },
                ]
              : data.pregnancyHistory.previousPregnancyHistory.map(item => {
                  return {
                    ...item,
                    isAlive: item.isAlive
                      ? { name: 'Hidup', key: true }
                      : { name: 'Mati', key: false },
                    place: state.items.place.some(
                      val => val === item.place || item.place === '',
                    )
                      ? item.place
                      : 'Lain-Lain',
                    placeEtc: state.items.place.some(
                      val => val === item.place || item.place === '',
                    )
                      ? ''
                      : item.place,
                    helper: state.items.helper.some(
                      val => val === item.helper || item.helper === '',
                    )
                      ? item.helper
                      : 'Lain-Lain',
                    helperEtc: state.items.helper.some(
                      val => val === item.helper || item.helper === '',
                    )
                      ? ''
                      : item.helper,
                  };
                }),
        },
        kbAndImunizationHistory:
          data.kbAndImunizationHistory.kbStatus === undefined
            ? {
                ...state.form.kbAndImunizationHistory,
              }
            : data.kbAndImunizationHistory,
        obstreticalHistory:
          data.obstreticalHistory === undefined
            ? { ...state.form.obstreticalHistory }
            : data.obstreticalHistory,
      };
      const dataInitialMapper = {
        ...dataInitial,
        socialMenstrualHistory:
          dataInitial.socialMenstrualHistory.staff === undefined
            ? {
                ...state.form.socialMenstrualHistory,
                staff: state.form.socialMenstrualHistory.staff.id,
              }
            : {
                ...dataInitial.socialMenstrualHistory,
                staff: dataInitial.socialMenstrualHistory?.staff?.id || '',
                socialHistory: {
                  ...dataInitial.socialMenstrualHistory.socialHistory,
                },
                menstruationHistory: {
                  ...dataInitial.socialMenstrualHistory.menstruationHistory,
                },
                maritalHistory: dataInitial.socialMenstrualHistory.maritalHistory.map(
                  item => {
                    return {
                      ...item,
                    };
                  },
                ),
              },
        pregnancyHistory: {
          ...dataInitial.pregnancyHistory,
          currentPregnancy: {
            ...dataInitial.pregnancyHistory.currentPregnancy,
            complaintsPerTrimester:
              dataInitial.pregnancyHistory.currentPregnancy
                ?.complaintsPerTrimester.length === 0
                ? ['']
                : dataInitial.pregnancyHistory.currentPregnancy
                    ?.complaintsPerTrimester,
          },
          previousPregnancyHistory:
            data.pregnancyHistory.previousPregnancyHistory.length === 0
              ? [
                  {
                    pregnancyTo: 1,
                    ageYear: null,
                    ageMonth: null,
                    gender: '',
                    helper: '',
                    helperEtc: '',
                    place: '',
                    placeEtc: '',
                    isAlive: { name: 'Hidup', key: true },
                    bbl: null,
                    complication: '',
                    currentCondition: '',
                  },
                ]
              : data.pregnancyHistory.previousPregnancyHistory.map(item => {
                  return {
                    ...item,
                    isAlive: item.isAlive
                      ? { name: 'Hidup', key: true }
                      : { name: 'Mati', key: false },
                    place: state.items.place.some(
                      val => val === item.place || item.place === '',
                    )
                      ? item.place
                      : 'Lain-Lain',
                    placeEtc: state.items.place.some(
                      val => val === item.place || item.place === '',
                    )
                      ? ''
                      : item.place,
                    helper: state.items.helper.some(
                      val => val === item.helper || item.place === '',
                    )
                      ? item.helper
                      : 'Lain-Lain',
                    helperEtc: state.items.helper.some(
                      val => val === item.helper || item.place === '',
                    )
                      ? ''
                      : item.helper,
                  };
                }),
        },
        kbAndImunizationHistory:
          dataInitial.kbAndImunizationHistory.kbStatus === undefined
            ? {
                ...state.form.kbAndImunizationHistory,
              }
            : {
                ...dataInitial.kbAndImunizationHistory,
                dietAndImunization: {
                  ...dataInitial.kbAndImunizationHistory.dietAndImunization,
                },
              },
        obstreticalHistory:
          dataInitial.obstreticalHistory === undefined
            ? { ...state.form.obstreticalHistory }
            : { ...dataInitial.obstreticalHistory },
      };
      commit('setForm', dataMapper);
      commit('setInitialForm', dataInitialMapper);
      const sections = state.dashboard.tabsResource.map(item => item.key);
      sections.forEach((section, index) => {
        if (dataMapper[section].timestamps !== undefined) {
          commit('setTabsResource', {
            index: index,
            payload: {
              icon: generateBadgeObstetrics(dataMapper, index),
            },
          });
        } else {
          commit('setTabsResource', {
            index: index,
            payload: {
              icon: '',
            },
          });
        }
      });
      commit('setIsLoading', false);
      if (data.pregnancyHistory.timestamps === undefined) {
        dispatch('resolveGetPregnancyHistory');
      }
      if (data.obstreticalHistory.timestamps !== undefined) {
        commit('setIsNew', false);
      } else {
        commit('setIsNew', true);
      }
      return response;
    } catch (error) {
      dispatch('resolveGetPregnancyHistory');
      commit('setIsNew', true);
      commit('setIsLoading', false);
      return error;
    }
  },

  // Post
  // Post Update ObstetricsAssessment
  async resolvePostObstetricsAssessment({ state, dispatch, commit }, index) {
    const selectedTab = state.dashboard?.tabsResource[index];
    let payload;
    switch (index) {
      case 0:
        payload = {
          socialMenstrualHistory: {
            ...state.form?.socialMenstrualHistory,
            staff: state.form.socialMenstrualHistory?.staff || null,
          },
        };
        break;
      case 1:
        payload = {
          pregnancyHistory: {
            ...state.form.pregnancyHistory,
            previousPregnancyHistory: state.form.pregnancyHistory?.previousPregnancyHistory?.map(
              item => {
                return {
                  ...item,
                  place:
                    item.place === 'Lain-Lain' ? item.placeEtc : item.place,
                  helper:
                    item.helper === 'Lain-Lain' ? item.helperEtc : item.helper,
                  isAlive: item.isAlive?.key,
                };
              },
            ),
          },
        };
        break;
      case 2:
        payload = {
          kbAndImunizationHistory: {
            ...state.form?.kbAndImunizationHistory,
            kbMethod:
              state.form.kbAndImunizationHistory?.kbStatus === false
                ? []
                : state.form?.kbAndImunizationHistory?.kbMethod,
          },
        };
        break;
      case 3:
        payload = {
          obstreticalHistory: {
            ...state.form?.obstreticalHistory,
          },
        };
        break;
    }
    payload.id_emr = state.patientData.id_emr;
    try {
      state.dashboard.loading = true;
      if (!_.isEqual(state.initialForm, state.form)) {
        const res = await postObstetricsAssessment(
          state.patientData.id_inpatient,
          payload,
        );
        if (res.status === 200) {
          commit('setShowSuccessMessage', {
            text: selectedTab.text,
            show: true,
          });
          setTimeout(() => {
            commit('setShowSuccessMessage', {
              text: '',
              show: false,
            });
          }, 3000);
          dispatch('resolveGetObstetricsAssessment');
        } else {
          commit('setShowSuccessMessage', {
            text: selectedTab.text,
            show: true,
            isFail: true,
          });
          setTimeout(() => {
            commit('setShowSuccessMessage', {
              text: '',
              show: false,
            });
          }, 3000);
          return res;
        }
      }
    } catch (error) {
      return error;
    } finally {
      state.dashboard.loading = false;
    }
  },

  // Generate Pregnancy Due Date
  async resolveGeneratePregnancyDueDate({ commit, state }) {
    try {
      const response = await postGeneratePregnancyDueDate({
        date: state.form.pregnancyHistory.currentPregnancy.hpht,
      });
      commit('setEstimatedDate', response.data.data.date);
    } catch (error) {
      return error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
