<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

<script>
const axios = require('axios');

export default {
  name: 'App',
  beforeMount() {
    axios.defaults.headers.common.Authorization = `Bearer ${this.$store?.getters?.token}`;
    axios.defaults.headers.common.userapps = this.$store.getters?.userLoggedIn?.name;
    axios.defaults.headers.common.clientapps = this.$store.getters?.userLoggedIn?.clinic?.name;
  },
};
</script>

<style lang="scss" scoped>
a {
  color: inherit !important;
  text-decoration: none;
}

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  ::v-deep .v-input {
    font-family: Roboto, sans-serif;
    font-size: 0.9rem;
  }
  ::v-deep .v-select__selections {
    font-family: Roboto, sans-serif;
    font-size: 0.9rem;
  }
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
