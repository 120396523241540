const ml = ['concentrate', 'drop', 'larutan', 'solutio'];
const oles = ['gel', 'salep', 'lotion', 'emulgel'];
const spray = ['inhaler', 'inhalasi', 'semprot'];
const teaSpoon = ['suspensi', 'sirup'];

function generatePackaging(preparation) {
  let packaging = '';
  const text = preparation.toLowerCase();
  if (text.includes('kapsul')) {
    packaging = 'Kapsul';
  } else if (teaSpoon.some(v => text.includes(v))) {
    packaging = 'Sendok Teh';
  } else if (text.includes('tetes')) {
    packaging = 'Tetes';
  } else if (text == 'suppositoria') {
    packaging = 'pcs';
  } else if (text == 'flex pen') {
    packaging = 'Flexpen';
  } else if (text == 'serbuk') {
    packaging = 'gr';
  } else if (text == 'serbuk injeksi') {
    packaging = 'Vial';
  } else if (text == 'infus') {
    packaging = 'TPM';
  } else if (text == 'injeksi') {
    packaging = 'Ampul';
  } else if (text == 'ovula') {
    packaging = 'Ovula';
  } else if (text == 'patch') {
    packaging = 'Patch';
  } else if (text == 'rectal tube') {
    packaging = 'Rectal Tube';
  } else if (text.includes('tablet') || text.includes('kaplet')) {
    packaging = 'Tablet';
  } else if (ml.some(v => text.includes(v))) {
    packaging = 'ml';
  } else if (oles.some(v => text.includes(v))) {
    packaging = 'Oleskan';
  } else if (spray.some(v => text.includes(v))) {
    packaging = 'Semprot';
  }

  return packaging;
}

export default generatePackaging;
