/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
const utils = {
  methods: {
    getTextRows(doc, inputValue, width) {
      const regex = /(\*{2})+/g;
      const textWithoutBoldMarks = inputValue.replace(regex, '');
      const boldStr = 'bold';
      const normalStr = 'normal';
      const fontSize = doc.getFontSize() / 65;
      let splitTextWithoutBoldMarks = doc.splitTextToSize(
        textWithoutBoldMarks,
        420 / (doc.getFontSize() === 9 ? 60 : 70),
      );
      let charsMapLength = 0;
      let position = 0;
      let isBold = false;

      let textRows = splitTextWithoutBoldMarks.map((row, i) => {
        const charsMap = row.split('');

        const chars = charsMap.map((char, j) => {
          position = charsMapLength + j + i;

          let currentChar = inputValue.charAt(position);

          if (currentChar === '*') {
            const spyNextChar = inputValue.charAt(position + 1);
            if (spyNextChar === '*') {
              // double asterix marker exist on these position's so we toggle the bold state
              isBold = !isBold;
              currentChar = inputValue.charAt(position + 2);

              // now we remove the markers, so loop jumps to the next real printable char
              let removeMarks = inputValue.split('');
              removeMarks.splice(position, 2);
              inputValue = removeMarks.join('');
            }
          }

          return { char: currentChar, bold: isBold };
        });
        charsMapLength += charsMap.length;
        let charsWihoutsSpacing = Object.entries(chars).filter(
          ([key, value]) => value.char != ' ',
        );
        let widthRow = 0;

        charsWihoutsSpacing.forEach(([key, value]) => {
          doc.setFont('Helvetica', value.bold ? boldStr : normalStr);
          widthRow += doc.getStringUnitWidth(value.char) * fontSize;
        });

        let totalBlankSpaces = charsMap.length - charsWihoutsSpacing.length;
        let blankSpacing = (width - widthRow) / totalBlankSpaces;

        return { blankSpacing: blankSpacing, chars: { ...chars } };
      });
      return textRows;
    },
    printBoldCharacters(doc, text, startY, startX, width) {
      const startXCached = startX;
      const boldStr = 'bold';
      const normalStr = 'normal';
      const fontSize = doc.getFontSize() / 70;
      const lineSpacing = doc.getFontSize() === 9 ? 0.15 : 0.17;

      let textObject = this.getTextRows(doc, text, width);

      textObject.map((row, rowPosition) => {
        Object.entries(row.chars).map(([key, value]) => {
          doc.setFont('Helvetica', value.bold ? boldStr : normalStr);
          doc.text(value.char, startX, startY);

          if (value.char == ' ' && rowPosition < textObject.length - 1) {
            startX += row.blankSpacing;
          } else {
            startX += doc.getStringUnitWidth(value.char) * fontSize;
          }
        });
        startX = startXCached;
        startY += lineSpacing;
      });
    },
    textCapitalize(inputString) {
      if (typeof inputString !== 'string') {
        throw new Error('Input must be a string.');
      }

      const words = inputString.split(' ');

      const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });

      return capitalizedWords.join(' ');
    },
  },
};

export default utils;
