import Constant from '@/const';
import axios from 'axios';

const putService = async (id, assurance, payload) => {
  try {
    const response = await axios.put(
      Constant.apiUrl.concat(
        `/master/service/${id}?insurance_type=${assurance}`,
      ),
      payload,
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default putService;
