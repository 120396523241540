<template>
  <div>
    <!-- Jasa Medis -->
    <v-card-title class="pl-7 text-h6 blue--text pt-8">Jasa Medis</v-card-title>
    <v-row>
      <v-col cols="4">
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text"
                for="Jasa Medis Dokter Umum"
              >
                Jasa Medis Dokter Umum</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              :readonly="state === 'detail'"
              v-model.number="findCost.medical_service.staff"
              prefix="Rp."
              :rules="medicalServiceRules"
              validate-on-blur
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text "
                for="Jasa Medis Dokter Spesialis"
              >
                Jasa Medis Dokter Spesialis</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              :readonly="state === 'detail'"
              v-model.number="findCost.medical_service.specialize"
              prefix="Rp."
              :rules="medicalServiceRules"
              validate-on-blur
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text "
                for="Jasa Medis Perawat"
              >
                Jasa Medis Perawat</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              :readonly="state === 'detail'"
              v-model.number="findCost.medical_service.nurse"
              prefix="Rp."
              :rules="medicalServiceRules"
              validate-on-blur
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text "
                for="Jasa Medis Bidan"
              >
                Jasa Medis Bidan</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              :readonly="state === 'detail'"
              v-model.number="findCost.medical_service.midwife"
              prefix="Rp."
              :rules="medicalServiceRules"
              validate-on-blur
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text"
                for="Jasa Medis Psikologi"
              >
                Jasa Medis Psikologi</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              :readonly="state === 'detail'"
              v-model.number="findCost.medical_service.psychologist"
              prefix="Rp."
              :rules="medicalServiceRules"
              validate-on-blur
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text "
                for="Jasa Medis Ahli Gizi"
              >
                Jasa Medis Ahli Gizi</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              :readonly="state === 'detail'"
              v-model.number="findCost.medical_service.nutritionists"
              prefix="Rp."
              :rules="medicalServiceRules"
              validate-on-blur
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text "
                for="Jasa Medis Analis Kesehatan"
              >
                Jasa Medis Analis Kesehatan</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              :readonly="state === 'detail'"
              v-model.number="findCost.medical_service.health_analyst"
              prefix="Rp."
              :rules="medicalServiceRules"
              validate-on-blur
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="6">
            <div class="text-left">
              <label
                class="text-subtitle-2 black--text "
                for="Jasa Medis Radiografer"
              >
                Jasa Medis Radiografer</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              :readonly="state === 'detail'"
              v-model.number="findCost.medical_service.radiographer"
              prefix="Rp."
              :rules="medicalServiceRules"
              validate-on-blur
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Alat Medis Habis Pakai -->
    <v-card-title class="pl-7 text-h6 blue--text"
      >Alat Medis Habis Pakai</v-card-title
    >
    <v-row class="mb-7">
      <v-col cols="4">
        <v-form ref="formMedicalTools">
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Alat Medis Habis Pakai"
                >
                  Alat Medis Habis Pakai
                </label>
              </div>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-autocomplete
                :readonly="state === 'detail'"
                placeholder="Pilih Alat Medis"
                dense
                :items="items.dataAHP.data"
                :loading="items.dataAHP.isLoading"
                @keyup="handleChangeAHP"
                :rules="
                  state === 'detail'
                    ? []
                    : [v => !!v || 'Alat Medis Habis Pakai Harus diisi']
                "
                item-text="name"
                :item-value="item => item"
                v-model="formTable.medicalTools.detail"
                :clearable="state !== 'detail'"
                :append-icon="state === 'detail' ? '' : 'mdi-chevron-down'"
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label class="text-subtitle-2 black--text " for="Jumlah">
                  Jumlah</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :readonly="state === 'detail'"
                placeholder="Masukkan Jumlah"
                suffix="pcs"
                type="number"
                v-model.number="formTable.medicalTools.quantity"
                :rules="
                  state === 'detail' ? [] : [v => !!v || 'Jumlah Harus diisi']
                "
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7 mb-4">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text"
                  for="Alat Medis Habis Pakai"
                >
                  Harga</label
                >
              </div>
            </v-col>
            <v-col cols="6" class="text-left font-weight-medium">
              <label for="">
                {{
                  formTable.medicalTools.detail
                    ? ` ${currency(
                        formTable.medicalTools?.detail?.hpp *
                          formTable.medicalTools?.quantity,
                      )}`
                    : ''
                }}
              </label>
            </v-col>
          </v-row>
        </v-form>
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="12" class="text-right">
            <v-btn
              v-if="state !== 'detail'"
              color="primary"
              :disabled="
                !formTable.medicalTools.detail &&
                  !formTable.medicalTools?.quantity
              "
              depressed
              class="text-none font-weight-medium"
              @click="addMedicalTools()"
              outlined
            >
              Tambahkan
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters align="baseline" class="px-7">
          <v-checkbox
            label="Input harga saja"
            hide-details
            v-model="findCost.add_material_price"
          ></v-checkbox>
        </v-row>
        <v-row
          no-gutters
          align="baseline"
          class="px-7"
          v-show="findCost.add_material_price"
        >
          <v-col cols="6">
            <div class="text-left">
              <v-col cols="6">
                <div class="text-left">
                  <label
                    class="text-subtitle-2 black--text mr-2"
                    for="Alat Medis Habis Pakai"
                  >
                    Harga</label
                  >
                  <v-tooltip color="#404040" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        style="cursor: pointer"
                        color="primary"
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span class=""
                      >Jika terdapat harga di luar AHP yang dipilih</span
                    >
                  </v-tooltip>
                </div>
              </v-col>
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              v-model="findCost.additional_material_price"
              prefix="Rp."
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <v-data-table
          :headers="table[tabs].medicalTools.headers"
          :items="findCost.consumable_tools"
          :items-per-page="findCost.consumable_tools?.length"
        >
          <template v-slot:[`item.no`]="{ item }">
            <td class="text-left">
              {{ form.cost[tabs].consumable_tools.indexOf(item) + 1 }}
            </td>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <td class="text-left">{{ item.name }}</td>
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <td class="text-left">{{ item.quantity }}</td>
          </template>
          <template v-slot:[`item.unit`]="{ item }">
            <td class="text-left">{{ item.unit }}</td>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <td class="text-left">{{ currency(item.price) }}</td>
          </template>
          <template
            v-if="state !== 'detail'"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 icon-style"
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteMedicalTools(item)"
                  color="red"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span> Hapus </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Bahan Medis Habis Pakai -->

    <v-card-title class="pl-7 text-h6 blue--text"
      >Bahan Medis Habis Pakai</v-card-title
    >
    <v-row class="mb-7">
      <v-col cols="4">
        <v-form ref="formConsumables">
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Bahan Medis Habis Pakai"
                >
                  Bahan Medis Habis Pakai</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :readonly="state === 'detail'"
                placeholder="Pilih Bahan Medis"
                dense
                :items="items.dataBHP.data"
                :loading="items.dataBHP.isLoading"
                @keyup="handleChangeBHP"
                item-text="name"
                :item-value="item => item"
                v-model="formTable.consumables.detail"
                :append-icon="state === 'detail' ? '' : 'mdi-chevron-down'"
                :rules="
                  state === 'detail'
                    ? []
                    : [v => !!v || 'Bahan Medis Habis Pakai Harus diisi']
                "
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label class="text-subtitle-2 black--text " for="Jumlah">
                  Jumlah</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :readonly="state === 'detail'"
                placeholder="Masukkan Jumlah"
                suffix="pcs"
                v-model.number="formTable.consumables.quantity"
                type="number"
                :rules="
                  state === 'detail' ? [] : [v => !!v || 'Jumlah Harus diisi']
                "
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text"
                  for="Bahan Medis Habis Pakai"
                >
                  Harga</label
                >
              </div>
            </v-col>

            <v-col cols="6" class="text-left font-weight-medium">
              <label for="">
                {{
                  formTable.consumables.detail
                    ? `${currency(
                        formTable.consumables?.detail?.hpp *
                          formTable.consumables?.quantity,
                      )}`
                    : ''
                }}
              </label>
            </v-col>
          </v-row>
        </v-form>
        <v-row no-gutters align="baseline" class="px-7">
          <v-col cols="12" class="text-right">
            <v-btn
              v-if="state !== 'detail'"
              color="primary"
              depressed
              class="text-none font-weight-medium"
              @click="addConsumables()"
              outlined
              :disabled="
                !formTable.consumables.detail &&
                  !formTable.consumables?.quantity
              "
            >
              Tambahkan
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters align="baseline" class="px-7">
          <v-checkbox
            label="Input harga saja"
            hide-details
            v-model="findCost.add_tool_price"
          ></v-checkbox>
        </v-row>
        <v-row
          no-gutters
          align="baseline"
          class="px-7"
          v-show="findCost.add_tool_price"
        >
          <v-col cols="6">
            <div class="text-left">
              <v-col cols="6">
                <div class="text-left">
                  <label
                    class="text-subtitle-2 black--text mr-2"
                    for="Bahan Medis Habis Pakai"
                  >
                    Harga</label
                  >
                  <v-tooltip color="#404040" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        style="cursor: pointer"
                        color="primary"
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span class=""
                      >Jika terdapat harga di luar BHP yang dipilih</span
                    >
                  </v-tooltip>
                </div>
              </v-col>
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              v-model="findCost.additional_tool_price"
              prefix="Rp."
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <v-data-table
          :headers="table[tabs].consumables.headers"
          :items="findCost.consumable_material"
          :items-per-page="findCost.consumable_material?.length"
        >
          <template v-slot:[`item.no`]="{ item }">
            <td class="text-left">
              {{ form.cost[tabs].consumable_material.indexOf(item) + 1 }}
            </td>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <td class="text-left">{{ item.name }}</td>
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <td class="text-left">{{ item.quantity }}</td>
          </template>
          <template v-slot:[`item.unit`]="{ item }">
            <td class="text-left">{{ item.unit }}</td>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <td class="text-left">{{ currency(item.price) }}</td>
          </template>
          <template
            v-if="state !== 'detail'"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 icon-style"
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteComsumables(item)"
                  color="red"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span> Hapus </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-row no-gutters align="baseline" class="px-16">
          <v-col cols="6">
            <div class="text-left">
              <label class="font-weight-medium black--text " for="Beban Unit">
                Beban Unit</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              :readonly="state === 'detail'"
              v-model.number="findCost.unit_load"
              prefix="Rp."
            />
          </v-col>
        </v-row>

        <v-row no-gutters align="baseline" class="px-16">
          <v-col cols="6">
            <div class="text-left">
              <label class="font-weight-medium black--text " for="Margin">
                Margin</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              :readonly="state === 'detail'"
              v-model.number="findCost.margin"
              prefix="Rp."
            />
          </v-col>
        </v-row>

        <v-row no-gutters align="baseline" class="px-16">
          <v-col cols="6">
            <div class="text-left">
              <label class="font-weight-medium black--text " for="Unit Load">
                Biaya Administrasi</label
              >
            </div>
          </v-col>
          <v-col cols="6">
            <v-currency-field
              :readonly="state === 'detail'"
              v-model.number="form.cost[tabs].admin_load"
              prefix="Rp."
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <v-row no-gutters align="baseline" class="px-16">
              <v-col cols="12">
                <v-row class="primary total white--text mt-8" align="baseline">
                  <span class="pr-3">Total Biaya Layanan</span>
                  <label>{{ totalPrice }}</label>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import formatMixin from '@/mixin/formatMixin';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('MasterServices');

const _ = require('lodash');
export default {
  name: 'PatientCost',
  mixins: [formatMixin],
  props: {
    tabs: Number,
    state: String,
  },
  data() {
    return {
      medicalServiceRules: [
        v => !!v || 'Tidak bisa kosong',
        v =>
          v === '0'
            ? true
            : v?.length >= 3 || v?.length <= 0 || 'Minimal 3 Karakter',
        v => v?.length <= 100 || 'Maksimal 100 Karakter',
      ],
    };
  },
  computed: {
    ...mapGetters(['getForm', 'getTable', 'getFormTable', 'getItems']),

    findCost() {
      return this.form.cost[this.tabs];
    },

    items: {
      get() {
        return this.getItems;
      },
    },

    form: {
      get() {
        return this.getForm;
      },
    },

    table: {
      get() {
        return this.getTable;
      },
    },

    formTable: {
      get() {
        return this.getFormTable;
      },
    },

    totalConsumables() {
      const result = this.findCost.consumable_material?.reduce(
        (a, { price }) => a + price,
        0,
      );
      return result;
    },

    totalMedicalTools() {
      const result = this.findCost.consumable_tools?.reduce(
        (a, { price }) => a + price,
        0,
      );
      return result;
    },

    materialPrice() {
      return this.findCost.add_material_price
        ? Number(this.findCost.additional_material_price)
        : 0;
    },
    toolPrice() {
      return this.findCost.add_tool_price
        ? Number(this.findCost.additional_tool_price)
        : 0;
    },

    totalPrice() {
      const biaya =
        Number(this.form.cost[this.tabs].medical_service.staff) +
        Number(this.form.cost[this.tabs].medical_service.specialize) +
        Number(this.form.cost[this.tabs].medical_service.nutritionists) +
        Number(this.form.cost[this.tabs].medical_service.health_analyst) +
        Number(this.form.cost[this.tabs].medical_service.psychologist) +
        Number(this.form.cost[this.tabs].medical_service.nurse) +
        Number(this.form.cost[this.tabs].medical_service.radiographer) +
        Number(this.form.cost[this.tabs].medical_service.midwife) +
        Number(this.form.cost[this.tabs].margin) +
        Number(this.form.cost[this.tabs].admin_load) +
        Number(this.form.cost[this.tabs].unit_load);

      const totalFromTables =
        this.totalConsumables +
        this.totalMedicalTools +
        this.materialPrice +
        this.toolPrice;

      return this.currency(biaya + totalFromTables);
    },
  },
  methods: {
    ...mapActions(['resolveGetAHP', 'resolveGetBHP']),

    handleChangeAHP: _.debounce(function(event) {
      this.resolveGetAHP(event.target.value);
    }, 600),

    handleChangeBHP: _.debounce(function(event) {
      this.resolveGetBHP(event.target.value);
    }, 600),

    addConsumables() {
      if (!this.$refs.formConsumables.validate()) {
        return;
      }
      this.findCost.consumable_material.push({
        no: this.table[this.tabs].consumables.items?.length + 1,
        id: this.formTable.consumables.detail.id,
        name: this.formTable.consumables.detail.name,
        quantity: this.formTable.consumables.quantity,
        unit: this.formTable.consumables.detail.packaging,
        price:
          this.formTable.consumables.detail.hpp *
          this.formTable.consumables.quantity,
      });
      this.formTable.consumables = {
        detail: '',
        quantity: '',
      };
      this.$refs.formConsumables.resetValidation();
    },

    addMedicalTools() {
      if (!this.$refs.formMedicalTools.validate()) {
        return;
      }
      this.findCost.consumable_tools.push({
        no: this.table[this.tabs].medicalTools.items?.length + 1,
        id: this.formTable.medicalTools.detail.id,
        name: this.formTable.medicalTools.detail.name,
        quantity: this.formTable.medicalTools.quantity,
        unit: this.formTable.medicalTools.detail.packaging,
        price:
          this.formTable.medicalTools.detail.hpp *
          this.formTable.medicalTools.quantity,
      });
      this.formTable.medicalTools = {
        detail: '',
        quantity: '',
      };
      this.$refs.formMedicalTools.resetValidation();
    },

    // addPrice(type) {
    //   if (this.findCost[`add_${type}_price`]) return;
    //   this.findCost[`additional_${type}_price`] = 0;
    // },

    deleteMedicalTools(item) {
      const index = this.findCost.consumable_tools.indexOf(item);
      this.findCost.consumable_tools.splice(index, 1);
    },

    deleteComsumables(item) {
      const index = this.findCost.consumable_material.indexOf(item);
      this.findCost.consumable_material.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.total {
  border-radius: 8px;
  padding: 20px;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }
  .v-data-table-header {
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
