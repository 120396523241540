<template>
  <v-container fluid class="pa-0">
    <div class="px-5 py-3">
      <v-row no-gutters align="center">
        <v-col cols="9" class="text-left">
          <h2>Laporan Posisi Keuangan</h2>
        </v-col>
        <v-col cols="1" class="text-right">
          <router-link to="/keuangan/akuntansi">
            <v-btn icon><v-icon>mdi-home</v-icon></v-btn>
          </router-link>
        </v-col>
        <v-col class="" cols="1">
          <v-btn depressed color="primary" class="text-none" small
            >Export</v-btn
          >
        </v-col>
        <v-col cols="1" class="text-left">
          <v-btn icon @click="onClick()"><v-icon>mdi-refresh</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" style="min-height: 10vh;">
        <v-col class="d-flex" cols="3">
          <v-btn
            depressed
            outlined
            @click="report = 'standard'"
            :color="report === 'standard' ? 'primary' : 'grey'"
            class="text-none font-weight-bold"
            >Standar</v-btn
          >
          <v-btn
            depressed
            outlined
            @click="report = 'comparative'"
            :color="report === 'comparative' ? 'primary' : 'grey'"
            class="text-none font-weight-bold"
            >Komparatif</v-btn
          >
        </v-col>

        <div class="standard-style" v-show="report === 'standard'">
          <v-col class="text-right py-0">
            <label class="font-weight-bold body-2">Pilih Tanggal</label>
          </v-col>
          <v-col class="pa-0">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              nudge-left="55"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedDate"
                  placeholder="Pilih Tanggal"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-chevron-down"
                  hide-details="false"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                :max="today"
                @input="
                  $refs.menu.save(date);
                  menu = false;
                  getPositionStandard();
                "
              />
            </v-menu>
          </v-col>
        </div>

        <div class="filter-style" v-show="report === 'comparative'">
          <v-col class="text-right py-0">
            <label class="font-weight-bold body-2">Pilih Tanggal</label>
          </v-col>
          <v-col class="pa-0">
            <v-menu
              ref="menuComp"
              v-model="menuComp"
              :close-on-content-click="false"
              :return-value.sync="dateComp"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  v-model="dateComp"
                  multiple
                  chips
                  small-chips
                  placeholder="Pilih Tanggal"
                  v-bind="attrs"
                  v-on="on"
                  dense
                  hide-details="false"
                  deletable-chips
                >
                </v-combobox>
              </template>
              <v-date-picker
                v-model="dateComp"
                multiple
                :max="today"
                :allowed-dates="allowedDates"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuComp = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuComp.save(dateComp)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </div>
      </v-row>
    </div>
    <v-divider></v-divider>

    <v-row no-gutters class="text-center d-flex flex-column py-3">
      <label class="font-weight-bold">{{ $store.state.clinic.name }}</label>
      <h4>LAPORAN POSISI KEUANGAN</h4>
      <label class="caption" v-show="report === 'standard'"
        >(Periode {{ date ? formattedDate : formattedToday }})</label
      >
    </v-row>

    <div v-if="report === 'standard'" class="px-5 body-2">
      <Standard :data="standardData" />
    </div>

    <div v-else class="px-5 body-2">
      <Comparative />
    </div>
  </v-container>
</template>

<script>
import Standard from './FinancialPosition/Standard.vue';
import Comparative from './FinancialPosition/Comparative.vue';
import moment from 'moment-timezone';
import Constant from '@/const';

const axios = require('axios');

export default {
  name: 'FinancialPosition',
  components: {
    Standard,
    Comparative,
  },
  data() {
    return {
      report: 'standard',
      selection: [],
      menu: false,
      menuComp: false,
      date: null,
      dateComp: [],
      rules: [v => v.length !== 6 || 'Maksimal 6 Periode'],
      today: moment(new Date()).format('YYYY-MM-DD'),
      formattedToday: moment(new Date()).format('DD MMMM YYYY'),
      standardData: null,
    };
  },
  mounted() {
    this.getPositionStandard();
  },
  computed: {
    formattedDate() {
      return this.date
        ? moment(this.date)
            .locale('id')
            .format('DD MMMM YYYY')
        : null;
    },
  },
  methods: {
    allowedDates(val) {
      if (this.dateComp.length === 6) {
        return this.dateComp.some(x => x === val);
      } else {
        return val;
      }
    },
    getPositionStandard() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/finance/ledger/account?target=financial_position&startDate=${
              this.date ? this.date : this.today
            }&endDate=${this.date ? this.date : this.today}`,
          ),
        )
        .then(response => {
          const [data] = response.data.data;
          this.standardData = data;
        });
    },
    onClick() {
      if (this.report === 'standard') this.getPositionStandard();
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-style {
  display: grid;
  grid-template-columns: 8vw 50vw;
  // grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: baseline;
}

.standard-style {
  display: grid;
  grid-template-columns: 8vw 15vw;
  // grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: baseline;
}

::v-deep .v-chip {
  margin: 0 2px 0 2px !important;
}
</style>
