const moment = require('moment-timezone');
const _ = require('lodash');

export default {
  methods: {
    isCheckInCancelable(item) {
      if (!item.isMjkn && !item.isBooking) {
        return false;
      }
      if ((item.isMjkn || item.isBooking) && item.isServed) {
        return false;
      }
      return true;
    },
    mapDashboardData(source, itemCount, page) {
      return source.map((patient, idx) => {
        return {
          no: idx + itemCount * page - itemCount,
          isCheckInCancelable: this.isCheckInCancelable(patient),
          idRegistration: patient._id.toString(),
          noRegist: patient.registration_number.toString(),
          polyId: patient.id_unit,
          poly: patient.unit[0].name,
          id: patient?.doctor[0]?._id,
          doctor: patient?.doctor[0]?.detail[0]?.name,
          isMjkn: patient.isMjkn,
          isCheckIn: patient?.isCheckIn,
          noQueue: patient?.queue?.number
            ? patient.queue.number.toString()
            : '',
          pcare: patient?.pcare,
          hasGeneralConsent: patient.emr?.document?.generalConsentCreated,
          queue:
            patient.queue_code != ''
              ? `${
                  patient.queue_code
                }-${patient.queue.number.toString().padStart(3, '0')}`
              : '-',
          isFinish: patient.queue?.isFinish ? 'Ya' : 'Tidak',
          time: patient.time,
          date: moment(patient.date)
            .locale('id')
            .format('LL'),
          //medical record
          idPatient: patient.id_emr.toString(),
          noRM: patient.emr.medical_record_number,
          name: patient.emr.social[0].name,
          birthDate: moment(patient.emr.social[0].birth.date)
            .locale('id')
            .format('DD/MM/YYYY'),
          birthPlace: patient.emr.social[0].birth.place,
          age: patient.emr.social[0].age,
          gender: patient.emr.social[0].gender ? 'Laki-laki' : 'Perempuan',
          address: this.formatAddress(patient.emr.social[0].address),
          identityType: patient.emr.social[0].identity.type,
          identityNumber: patient.emr.social[0].identity.number,
          profession: patient.emr.social[0].profession,
          isBooking: patient.isBooking,
          //assurance
          guarantorType: _.startCase(patient.assurance.type),
          guarantorName: patient.emr.social[0].assurance.name,
          guarantorNumber: patient.emr.social[0].assurance.number,
          isNew: patient.status.isNew,
          isProlanis: patient.emr.social[0].assurance.isProlanis,
          isServed: patient.isServed,
          phoneNumber: patient.emr.social[0].phone_number,
          queue_code: patient.queue_code,
          registered_at: {
            date: moment(patient.timestamps.created_at).format('DD MMMM YYYY'),
            time: moment(patient.timestamps.created_at).format('HH:mm'),
          },
          hovered: false,
          timestamps: {
            created_at: moment(patient.timestamps.created_at)
              .locale('id')
              .format('LT'),
            created_by: patient.timestamps.created_by?.name,
          },
        };
      });
    },
  },
};
