import JsPDF from 'jspdf';
import Canvas from 'canvas';
import JsBarcode from 'jsbarcode';

export default {
  methods: {
    printBracelet(data) {
      const doc = new JsPDF({
        orientation: 'l',
        unit: 'mm',
        format: [24, 290],
      });
      doc.setProperties({
        title: `Gelang Pasien-${this.$store.getters.selectedPatient.rmNumber}`,
      });

      const marginLeft = 62;
      const enter = 4.5;

      doc.setFontSize(8);
      doc.setFont('helvetica', 'bold');
      doc.text(this.$store?.getters?.userLoggedIn?.clinic?.name, marginLeft, 6);
      doc.setFont('helvetica', 'normal');
      doc.text(data.rm, 43.5, 19);
      doc.text(data.name, marginLeft, 11);
      doc.text(`No RM: ${data.rm}`, marginLeft, 11 + enter);
      doc.text(
        `${data.gender ? 'Laki-laki' : 'Perempuan'}, ${data.birthDate} (${
          data.age
        } Tahun)`,
        marginLeft,
        11 + enter * 2,
      );

      const transformRm = Canvas.createCanvas();
      JsBarcode(transformRm, `${data.rm}`, {
        height: 38,
        font: 'times',
        width: 1,
        margin: 0,
        displayValue: false,
        fontOptions: 'bold',
        fontSize: 9,
      });
      const jpegUrl = transformRm.toDataURL('image/jpeg');
      doc.addImage(jpegUrl, 'JPEG', 40, 4.8);

      return doc;
    },
  },
};
