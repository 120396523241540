import Constant from '@/const';
import axios from 'axios';

const putUpdateSocialData = async (rm, payload) => {
  try {
    const response = await axios.put(
      Constant.apiUrl.concat(`/patient/emr/social/${rm}`),
      payload,
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default putUpdateSocialData;
