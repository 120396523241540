<template>
  <v-card>
    <v-card-title class="d-flex justify-center">
      <h3>Ringkasan Total Harga</h3>
      <v-btn absolute right icon @click="back()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="d-flex flex-column align-left">
      <div class="d-flex flex-col align-center mt-6">
        <label class="mr-5 body-1">Tagihan Tambahan</label>
        <v-switch class="mt-0" inset hide-details="false" v-model="isAddOn" />
      </div>
      <div class="mt-5">
        <v-data-table
          :headers="tableHeader"
          :items="isAddOn ? recipeSummary.addOnData : recipeSummary.data"
          items-per-page="5"
        >
          <template v-slot:[`item.no`]="{ index }">
            <tr class="d-flex align-center">
              <td>{{ index + 1 }}</td>
            </tr>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <tr v-if="item.type !== 'Racik'" class="d-flex align-center">
              <td>{{ item.name }}</td>
              <div v-if="item.isAddOn" class="d-flex align-center ml-3">
                <div class="addon-chip d-flex flex-col align-center">
                  <v-icon small :style="{ color: '#BB4802' }" class="mr-1"
                    >mdi-cash</v-icon
                  >
                  Tambahan
                </div>
              </div>
            </tr>
            <div v-else class="d-flex flex-row">
              <div>
                <label
                  v-for="drug in item.drugs"
                  class="d-flex my-4 align-center"
                  :key="drug._id"
                  >{{ drug.name }}</label
                >
              </div>
              <div v-if="item.isAddOn" class="d-flex align-center ml-3">
                <div class="addon-chip d-flex flex-col align-center">
                  <v-icon small :style="{ color: '#BB4802' }" class="mr-1"
                    >mdi-cash</v-icon
                  >
                  Tambahan
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <tr class="d-flex align-center">
              <td>{{ currency(item.price) }}</td>
            </tr>
          </template>
          <template v-slot:body.append>
            <tr class="total-footer">
              <td colspan="2"></td>
              <td class="text-left font-weight-bold">Total</td>
              <td class="text-left "></td>
              <td class="text-left font-weight-bold">
                {{
                  currency(
                    isAddOn
                      ? recipeSummary.addOnTotalPrice
                      : recipeSummary.totalPrice,
                  )
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        @click="back()"
        :disabled="loading"
        color="primary"
        class="text-none"
        dense
        depressed
      >
        Kembali
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import formatMixin from '@/mixin/formatMixin';

const { mapGetters, mapMutations } = createNamespacedHelpers(
  'DrugRequestStore',
);

export default {
  name: 'RecipeSummary',
  mixins: [formatMixin],
  computed: {
    ...mapGetters(['getRecipeSummary']),
    recipeSummary: {
      get() {
        return this.getRecipeSummary;
      },
    },
  },
  data() {
    return {
      isAddOn: false,
      tableHeader: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'Tipe', value: 'type', sortable: false },
        { text: 'Nama Obat atau Alkes', value: 'name', sortable: false },
        { text: 'Jumlah', value: 'quantity', sortable: false },
        { text: 'Harga', value: 'price', sortable: false },
      ],
      data: [],
      totalPrice: 0,
    };
  },
  mounted() {},
  methods: {
    ...mapMutations(['setDialog']),
    back() {
      this.setDialog({ key: 'recipeSummary', value: false });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table .v-data-table-header {
    background-color: #f4f5f5;

    span {
      color: #9e9e9e;
    }
  }

  .total-footer {
    background-color: #f4f5f5 !important;
    color: #404040 !important;
  }
}

.addon-chip {
  background-color: #fffbea;
  padding: 5px;
  color: #bb4802;
}
</style>
