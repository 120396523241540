<template>
  <v-container class="pa-0" fluid>
    <v-row no-gutters>
      <v-toolbar flat outlined height="112">
        <v-row no-gutters align="center">
          <v-col cols="12" class="text-left">
            <h2>Daftar Akun</h2>
          </v-col>
          <v-col class="pl-0" cols="3">
            <v-select
              clearable
              outlined
              dense
              placeholder="Pilih Status"
              :items="status"
              v-model="filter.status"
              hide-details="false"
              single-line
              @change="getAccountList()"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="px-0 text-right" cols="2">
            <v-btn depressed tile outlined color="primary" class="text-none"
              ><v-icon>mdi-arrow-down</v-icon> Export</v-btn
            >
          </v-col>
          <v-btn icon @click="refresh()"><v-icon>mdi-refresh</v-icon></v-btn>
          <v-col cols="3" class="pr-0">
            <v-text-field
              class="input"
              background-color="grey lighten-3"
              placeholder="Search"
              solo
              dense
              hide-details
              flat
              prepend-inner-icon="mdi-magnify"
              single-line
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-row>

    <v-alert
      text
      type="success"
      :value="alert"
      outlined
      class="ma-3 px-5 py-3 text-left"
    >
      Berhasil {{ actionText }} <strong>akun {{ accountText }}</strong>
    </v-alert>

    <v-row no-gutters>
      <v-data-iterator
        hide-default-footer
        :items="items"
        :items-per-page="items.length"
        style="width: 100%"
        :loading="loading"
      >
        <template v-slot:header>
          <v-toolbar flat dense>
            <v-toolbar-items class="header-style px-6">
              <label class="text-left">Kode Akun</label>
              <label class="text-left">Nama Akun</label>
              <label class="text-left">Kategori</label>
              <label class="text-left">Status</label>
              <label class="text-right">Saldo Akun</label>
              <label></label>
            </v-toolbar-items>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="loading"
          ></v-progress-linear>
        </template>
        <template v-slot:item="props">
          <v-treeview
            activatable
            expand-icon="mdi-chevron-down"
            :items="[props.item]"
            class="text-left"
          >
            <template v-slot:label="{ item }">
              <div class="body-head-style">
                <label>{{ item.account_code }}</label>
                <div class="body-style">
                  <label>{{ item.name }}</label>
                  <label>{{ item.category }}</label>

                  <v-card
                    :class="item.status ? 'card-active' : 'card-deactive'"
                    flat
                    width="100"
                    class="text-center"
                    ><label>{{
                      item.status ? 'Aktif' : 'Tidak Aktif'
                    }}</label></v-card
                  >

                  <label class="text-right">{{
                    formatCurrency(item.balance)
                  }}</label>
                  <div class="text-right" v-if="isFinance">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="#E5A82E"
                          @click="onClicked(0, item)"
                          v-bind="attrs"
                          v-on="on"
                          ><v-icon>mdi-square-edit-outline</v-icon></v-btn
                        >
                      </template>
                      <span>Ubah</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="item.meta.level !== 6"
                          icon
                          color="primary"
                          @click="onClicked(1, item)"
                          v-bind="attrs"
                          v-on="on"
                          ><v-icon>mdi-plus</v-icon></v-btn
                        >
                      </template>
                      <span>Tambah</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="red"
                          @click="onClicked(2, item)"
                          v-bind="attrs"
                          v-on="on"
                          ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                        >
                      </template>
                      <span>Hapus</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </template>
          </v-treeview>
        </template>
      </v-data-iterator>
    </v-row>
    <v-dialog v-model="dialog" width="520">
      <EditAccount
        v-if="selectedForm === 0 && dialog"
        :data="selectedData"
        @close-form="onAction"
        @close-dialog="dialog = false"
      />
      <InputNewAccount
        v-if="selectedForm === 1 && dialog"
        :data="selectedData"
        @close-form="onAction"
        @close-dialog="dialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import InputNewAccount from './AccountList/InputNewAccount';
import EditAccount from './AccountList/EditAccount';
import Swal from 'sweetalert2';
import Constant from '@/const';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');

export default {
  name: 'AccountList',
  mixins: [alertMixin],
  components: {
    InputNewAccount,
    EditAccount,
  },
  data() {
    return {
      items: [],
      selectedData: null,
      selectedForm: null,
      dialog: false,
      status: ['Aktif', 'Tidak Aktif'],
      filter: {
        status: '',
      },
      alert: false,
      actionText: '',
      accountText: '',
      search: '',
      loading: false,
    };
  },
  mounted() {
    this.getAccountList();
  },
  computed: {
    isFinance() {
      return this.$store.getters.userLoggedIn.role === 'Keuangan';
    },
  },
  methods: {
    statusFilter(status) {
      switch (status) {
        case 'Aktif':
          return true;
        case 'Tidak Aktif':
          return false;
        case '':
          return '';
        case null:
          return '';
      }
    },
    onClicked(idx, item) {
      if (idx === 2) {
        Swal.fire({
          // text: `Apakah Anda yakin ingin menghapus akun ${item.code}?`,
          html: `<label style="font-weight: 500;">Apakah Anda yakin ingin menghapus akun <span style="color:#2D9CDB;">${item.account_code}</span>?</label>`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#2D9CDB',
          cancelButtonColor: '#EB5757',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          reverseButtons: true,
        }).then(result => {
          if (result.isConfirmed) {
            axios
              .delete(
                Constant.apiUrl.concat(`/finance/ledger/account/${item._id}`),
              )
              .then(() => {
                this.onAction('delete', item.account_code);
              })
              .catch(err => this.showErrorAxios(err));
          }
        });
      } else {
        this.selectedForm = idx;
        this.selectedData = item;
        this.dialog = true;
      }
    },
    onAction(val, code) {
      try {
        switch (val) {
          case 'save':
            this.actionText = 'menambahkan';
            this.accountText = code;
            break;
          case 'edit':
            this.actionText = 'mengubah';
            this.accountText = code;
            break;
          case 'delete':
            this.actionText = 'menghapus';
            this.accountText = code;
            break;
          default:
            this.dialog = false;
        }
        this.alert = true;
        setTimeout(this.toggleAlert, 5000);
        this.dialog = false;
        this.getAccountList();
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    toggleAlert() {
      this.alert = false;
    },
    refresh() {
      this.filter.status = '';
      this.getAccountList();
    },
    getAccountList() {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/finance/ledger/account?target=list&status=${this.statusFilter(
              this.filter.status,
            )}`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          this.items = data.map((arr, idx) => {
            return {
              ...arr,
              id: idx + 1,
            };
          });
          this.loading = false;
        });
    },
    formatCurrency(val) {
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      });

      return formatter.format(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-style {
  display: grid;
  grid-template-columns: 31vh repeat(4, 16%) 15%;
  align-content: center;
  font-weight: 500;
  font-size: 0.9rem;
  width: 100%;
  border-bottom: 1px solid lightgray;
}
.body-head-style {
  display: grid;
  // grid-template-columns: 28vh auto;
  font-size: 0.9rem;
  align-items: center;
  font-weight: 500;
}
.body-style {
  display: grid;
  grid-template-columns: repeat(4, 15%) 15%;
  font-size: 0.9rem;
  align-items: center;
  font-weight: 500;
  position: absolute;
  left: 18vw;
  width: 100%;
}
.card-active {
  background-color: #e5fff0;
  border: 1px solid #2d965a;
  color: #2d965a;
}
.card-deactive {
  background-color: #fff2f2;
  border: 1px solid #eb4747;
  color: #eb4747;
}
</style>
