import getAllSurgicalSafetyChecklist from '@/fetchApi/Service/Documents/getAllSurgicalSafetyChecklist';
import AlertMixin from '@/mixin/alertMixin';
import { createNamespacedHelpers } from 'vuex';
import Documents from '@/mixin/documents';
import PrintMixin from '@/mixin/printMixin';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'SurgicalSafetyChecklist',
);
const moment = require('moment-timezone');
export default {
  mixins: [AlertMixin, Documents, PrintMixin],
  data() {
    return {
      dialog: false,
      loading: false,
      isHasIc: true,
      table: {
        headers: [
          {
            text: 'Sign in',
            value: 'sign_in',
            sortable: false,
          },
          {
            text: 'Time Out',
            value: 'time_out',
            sortable: false,
          },
          {
            text: 'Sign Out',
            value: 'sign_out',
            sortable: false,
          },
          {
            text: 'Tindakan yang Dilakukan',
            value: 'action',
            sortable: false,
          },
          {
            text: '',
            width: '10%',
            value: 'click_action',
            sortable: false,
          },
        ],
        items: [],
      },
      actionItems: [
        {
          label: 'Lihat Detail',
        },
        {
          label: 'Print Dokumen SSC',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getLoading', 'getSignIn', 'getSnackbar']),
    loading: {
      get() {
        return this.getLoading;
      },
    },
    snackbar: {
      get() {
        return this.getSnackbar;
      },
    },
    signin: {
      get() {
        return this.getSignIn;
      },
    },
  },
  mounted() {
    this.showAllSurgicalSafetyChecklistData();
  },
  methods: {
    ...mapActions([
      'resolveGetSurgicalSafetyChecklistById',
      'resolveGetPatientAllergy',
    ]),
    ...mapMutations(['clearSurgicalSafetyChecklistState']),
    openDialog(id) {
      this.clearSurgicalSafetyChecklistState();
      if (!this.isHasIc) return;
      if (id) {
        this.showSurgicalSafetyChecklistById(id);
      }
      if (!id) {
        this.resolveGetPatientAllergy(
          this.$store.getters.selectedPatient.id_emr,
        );
      }
      this.dialog = true;
    },
    async printExistingSsc(id) {
      const result = await this.resolveGetSurgicalSafetyChecklistById(id);
      this.printSurgicalSafetyChecklistDocument(result);
    },
    closeDialog() {
      this.showAllSurgicalSafetyChecklistData();
      this.dialog = false;
    },
    async showAllSurgicalSafetyChecklistData() {
      try {
        this.loading = true;
        const response = await getAllSurgicalSafetyChecklist(
          this.$store.getters.selectedPatient.id_emr,
        );
        const { data } = response.data;
        this.table.items = data.map(item => {
          return {
            sign_in: `${item.signInPhaseTime.date}, ${item.signInPhaseTime.time}`,
            time_out: `${item.timeOutPhaseTime.date}, ${item.timeOutPhaseTime.time}`,
            sign_out: `${item.signOutPhaseTime.date}, ${item.signOutPhaseTime.time}`,
            action: item.action,
            id: item.id,
          };
        });
      } catch (error) {
        if (error.response.data.pesan === 'Inform Consent tidak ditemukan!') {
          this.isHasIc = false;
        }
        this.table.items = [];
      } finally {
        this.loading = false;
      }
    },
    async showSurgicalSafetyChecklistById(id) {
      try {
        await this.resolveGetSurgicalSafetyChecklistById(id);
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    async printSurgicalSafetyChecklistDocument(data) {
      const patientData = {
        name: this.$store.getters.selectedPatient.meta.name,
        age: this.$store.getters.selectedPatient.meta.age,
        birthDate: moment(
          this.$store.getters.selectedPatient.meta.birthDateUnformatted,
          'YYYY-MM-DD',
        ).format('DD-MM-YYYY'),
        gender: this.$store.getters.selectedPatient.meta.gender,
        rmNumber: this.$store.getters.selectedPatient.rmNumber,
      };
      if (!data) {
        await this.resolveGetPatientAllergy(
          this.$store.getters.selectedPatient.id_emr,
        );
        const doc = this.generateSscDoc(
          this.$store.getters.userLoggedIn.clinic,
          patientData,
          { signin: { allergy: this.signin.allergy } },
        );
        this.printDocument(doc, {
          docName: 'Surgical Safety Checklist',
          rmNumber: patientData.rmNumber,
        });
      } else {
        const doc = this.generateSscDoc(
          this.$store.getters.userLoggedIn.clinic,
          patientData,
          data,
        );
        this.printDocument(doc, {
          docName: 'Surgical Safety Checklist',
          rmNumber: patientData.rmNumber,
        });
      }
    },
  },
};
