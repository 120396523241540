import Constant from '@/const';
import axios from 'axios';

const putInformedConsent = async (idEMR, payload) => {
  try {
    const response = await axios.put(
      Constant.apiUrl.concat(`/document/inform-consent/${idEMR}`),
      payload,
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default putInformedConsent;
