import AlertMixin from '@/mixin/alertMixin';

export default {
  mixin: [AlertMixin],
  computed: {
    isJwtVerified() {
      return this.$store.getters.isJwtVerified;
    },
  },
  methods: {
    runApiCalls(apiCalls = null) {
      if (apiCalls === null) return;

      if (this.isJwtVerified) {
        apiCalls();
      } else {
        this.showErrorJwt();
      }
    },
  },
};
