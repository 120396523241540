<template>
  <v-card class="main">
    <v-card-title>
      <h4>Pencarian Pasien</h4>
    </v-card-title>
    <v-card-text class="container-content text-left">
      <p>Temukan Data Pasien Lama</p>
      <div class="filter-wrapper">
        <v-text-field
          placeholder="Masukkan Nama/NoRM"
          ref="nameInput"
          outlined
          dense
          clearable
          class="filter-wrapper__name"
          @input="debouncedSearch"
          v-model="filter.name"
          :rules="filter.rules.name"
          maxlength="300"
        />
        <v-text-field
          placeholder="Masukkan Alamat"
          outlined
          dense
          clearable
          class="filter-wrapper__address"
          @input="debouncedSearch"
          v-model="filter.address"
          :disabled="filter.name.length < 3"
        />
        <v-btn
          depressed
          color="red"
          class="text-capitalize white--text filter-wrapper__reset"
          outlined
          :disabled="filter.name === '' && filter.address === ''"
          @click="reset()"
          >Reset</v-btn
        >
      </div>
      <div>
        <table>
          <thead>
            <tr class="grid-th">
              <th class="text-center">No</th>
              <th class="text-start">No RM</th>
              <th
                class="text-start"
                @click="handleSort"
                style="cursor: pointer;"
              >
                Nama
                <v-icon
                  class="toggleUpDown"
                  :class="{ rotate: table.pagination.sort }"
                  small
                  >mdi-arrow-up</v-icon
                >
              </th>
              <th class="text-start">Tanggal Lahir</th>
              <th class="text-start">Usia</th>
              <th class="text-start">Jenis Kelamin</th>
              <th class="text-start">Alamat</th>
              <th class="text-start">Kunjungan Terakhir</th>
              <th class="text-start"></th>
            </tr>
          </thead>
          <v-divider />
          <tbody
            class="scrollbar"
            id="scroll-target"
            v-scroll:#scroll-target="onScroll"
          >
            <transition name="fade">
              <div
                v-if="
                  (patientDataLength === 0 && !patientLoading) ||
                    (patientData.length === 0 && !patientLoading)
                "
                class="no-data"
              >
                <p v-if="filter.name === '' && filter.address === ''">
                  Silahkan Masukan Nama/No RM Untuk Memulai Menemukan Data
                  Pasien Lama
                </p>
                <p v-else>
                  Maaf Data Pasien Tidak Ditemukan.
                  <br />
                  Silahkan Masukan Data Pencarian Yang Berbeda atau
                  <a @click="handleRegist">
                    Daftar Pasien Baru
                  </a>
                </p>
              </div>
            </transition>

            <transition-group name="slide-fade" tag="tbody" appear>
              <tr
                v-for="(item, index) in patientData"
                :key="item.rmNumber"
                class="grid-td row-td"
              >
                <td class="ml-3">{{ index + 1 }}</td>
                <td class="text-left">{{ item.rmNumber }}</td>
                <td class="text-left">{{ item.name }}</td>
                <td class="text-left">{{ item.birthDate }}</td>
                <td class="text-left">{{ item.age }}</td>
                <td class="text-left">{{ item.gender }}</td>
                <td class="text-left">{{ item.address }}</td>
                <td class="text-left">{{ item.lastVisit }}</td>
                <td class="text-left">
                  <v-btn
                    color="primary"
                    class="text-capitalize"
                    depressed
                    style="width: 1rem; height: 2rem;"
                    @click="$emit('on-clicked', item.rmNumber)"
                    >Daftar</v-btn
                  >
                </td>
              </tr>
            </transition-group>

            <transition name="slide-fade">
              <div
                v-show="
                  (patientData.length !== patientDataLength &&
                    patientLoading) ||
                    patientLoading
                "
              >
                <tr v-for="n in 3" :key="n" class="grid-td">
                  <td v-for="n in 9" :key="n">
                    <Skeleton :isLoading="true">
                      <span>text</span>
                    </Skeleton>
                  </td>
                </tr>
              </div>
            </transition>
          </tbody>
        </table>
      </div>
    </v-card-text>
    <div>
      <div class="footer-wrapper">
        <v-btn depressed class="text-capitalize" @click="handleBack"
          >Kembali</v-btn
        >
        <v-spacer />
        <v-btn
          color="#137BC0"
          class="#137BC0--text text-none"
          @click="handleRegist"
          elevation="0"
          outlined
          >Pasien Baru
          <v-icon right style="margin-top: 2px;">
            mdi-arrow-right
          </v-icon></v-btn
        >
      </div>
    </div>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Skeleton from '@/components/SharedComponent/Skeleton.vue';
const {
  mapGetters: oldNewpatientGetters,
  mapActions: oldNewpatientActions,
  mapMutations: oldNewpatientMutations,
} = createNamespacedHelpers('OldNewPatientRegistration');

const _ = require('lodash');

export default {
  name: 'SearchDataPatient',
  components: { Skeleton },
  computed: {
    ...oldNewpatientGetters(['getPatient']),
    patientLoading: {
      get() {
        return this.getPatient.loading;
      },
    },
    patientData: {
      get() {
        return this.getPatient.data;
      },
    },
    patientDataLength: {
      get() {
        return this.getPatient?.length;
      },
    },
  },
  methods: {
    ...oldNewpatientActions(['resolveGetPatientRegistration']),
    ...oldNewpatientMutations([
      'clearState',
      'setPatientData',
      'setPatientLength',
    ]),
    debouncedSearch: _.debounce(function() {
      const { filter } = this;
      if (filter.name?.length < 3 && filter.address?.length < 3) {
        return;
      }
      if (filter.name?.length >= 300) {
        return;
      }
      this.handleGetPatientData();
    }, 700),
    reset() {
      this.filter.name = '';
      this.filter.address = '';
    },
    handleBack() {
      this.$emit('back');
    },
    handleRegist() {
      this.$emit('to-regist');
    },
    handleSort() {
      this.table.pagination.sort = !this.table.pagination.sort;
      const el = document.getElementById('scroll-target');
      el.scrollTop = 0;
      this.table.pagination.sort
        ? this.handleGetPatientData({ sort: 'asc' })
        : this.handleGetPatientData({ sort: 'desc' });
    },
    handleGetPatientData({
      isScroll = false,
      sort = this.table.pagination.sort ? 'asc' : 'desc',
    } = {}) {
      !isScroll && (this.table.pagination.page = 1);
      const { name, address } = this.filter;
      const { page, rowsPerPage } = this.table.pagination;
      if (name === '' && address === '') {
        this.setPatientLength(0);
        this.setPatientData([]);
      } else {
        this.resolveGetPatientRegistration({
          ...this,
          name: name,
          address: address,
          page: page,
          itemCount: rowsPerPage,
          sort: sort,
          isScroll,
        });
      }
    },
    onScroll() {
      if (!this.patientLoading) {
        const el = document.getElementById('scroll-target');
        if (el) {
          if (el.scrollTop + el.clientHeight + 3 >= el.scrollHeight) {
            this.table.pagination.page += 1;
            this.patientData.length !== this.patientDataLength &&
              this.handleGetPatientData({ isScroll: true });
          }
        }
      }
    },
  },
  watch: {
    'filter.name'(newValue) {
      if (!newValue) {
        this.filter.name = '';
        this.filter.address = '';
        this.handleGetPatientData();
      }
    },
    'filter.address'(newValue) {
      if (!newValue) {
        this.filter.address = '';
        this.handleGetPatientData();
      }
    },
    deep: true,
  },
  mounted() {
    this.$refs.nameInput.focus();
    this.clearState();
  },
  data() {
    return {
      filter: {
        name: '',
        address: '',
        rules: {
          name: [
            v => v?.length <= 300 || 'Nama maksimal 300 karakter',
            v => v?.length === 0 || v?.length >= 3 || 'Nama minimal 3 karakter',
          ],
        },
      },
      table: {
        pagination: {
          sort: true,
          page: 1,
          rowsPerPage: 10,
          totalItems: null,
        },
      },
      pageLength: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  // height: 89dvh;
  padding: 1rem 1.5rem;
  color: #000000;
  h4 {
    color: #616161;
  }
  .container-content {
    height: 72dvh;
  }
  .filter-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
    padding-bottom: 0rem;
    &__name {
      width: 40%;
    }
    &__address {
      width: 50%;
    }
    &__reset {
      width: 10%;
    }
  }
  .scrollbar {
    display: block;
    height: 51dvh;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0.4rem;
      border-radius: 1rem;
      background-color: rgb(214, 214, 214);
    }
    &::-webkit-scrollbar-thumb {
      width: 0.4rem;
      border-radius: 1rem;
      background-color: rgb(146, 146, 146);
    }
  }
  .no-data {
    text-align: center;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  table {
    width: 100%;
  }

  .grid-th {
    display: grid;
    grid-template-columns: 5% 7% 17.8% 10% 4.9% 10% 23.8% 13.8% auto;
    width: 70rem + 0.4rem;
  }

  .grid-td {
    display: grid;
    grid-template-columns: 5% 7% 18% 10% 5% 10% 24% 14% 7%;
    width: 70rem;
  }

  .grid-th th {
    padding: 12px 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #9e9e9e;
  }
  .grid-td td {
    padding: 12px 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: #404040;
  }

  .row-td:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
  .toggleUpDown {
    margin-top: -3px;
    transition: transform 0.3s ease-in-out !important;
  }

  .toggleUpDown.rotate {
    transform: rotate(180deg);
  }
}

.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
