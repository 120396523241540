import { render, staticRenderFns } from "./ObstreticalHistory.vue?vue&type=template&id=6fe8ef12&scoped=true"
import script from "./ObstreticalHistory.vue?vue&type=script&lang=js"
export * from "./ObstreticalHistory.vue?vue&type=script&lang=js"
import style0 from "./ObstreticalHistory.vue?vue&type=style&index=0&id=6fe8ef12&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fe8ef12",
  null
  
)

export default component.exports