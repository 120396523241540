<template>
  <v-flex class="big-header">
    <v-layout align-space-between justify-start row fill-height>
      <v-flex xs6 align-self-center class="header-text pl-10">
        <h1 class="primary-header">{{ title }}</h1>
        <h3 class="secondary-header">
          {{ subtitle }}
        </h3>
      </v-flex>
      <v-flex xs1></v-flex>
      <v-flex xs5>
        <v-img
          contain
          class="finance-image"
          lazy-src
          :src="require(`@/assets/image/${image}`)"
        />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: () => '',
    },
    subtitle: {
      type: String,
      required: true,
      default: () => '',
    },
    image: {
      type: String,
      required: true,
      default: () => 'illustration_card_payment.svg',
    },
  },
};
</script>

<style lang="scss" scoped>
.page-banner {
  display: flex;
  text-align: left;
  background: #f0f9ff;
  border-radius: 25px;
  padding: 30px;
  height: 48vh;

  .detail {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .illustration {
    img {
      width: 100%;
    }
  }

  .primary-header {
    font-size: 2.5em;
    color: #000000;
    font-weight: bold;
  }
  .secondary-header {
    font-size: 1.15em;
    font-weight: 500;
    color: #a6adb4;
  }
}

.big-header {
  align-items: center;
  display: flex;
  width: 100%;
  min-height: 48vh;
  background: #f0f9ff;
  font-family: Roboto;
  font-style: normal;
  border-radius: 25px;
  .header-text {
    padding-left: 25px;
    text-align: left;
    .primary-header {
      font-size: 2.5em;
      color: #000000;
      font-weight: bold;
    }
    .secondary-header {
      font-size: 1.15em;
      font-weight: 500;
      color: #a6adb4;
    }
  }
  .finance-image {
    z-index: 1;
    position: relative;
    width: 60%;
  }
}
</style>
