<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>{{ isDetail ? 'Detail' : 'Edit' }} Jurnal Transaksi</h3>
      <v-btn
        outlined
        color="primary"
        class="text-none entry-style"
        :disabled="isDetail"
        @click="onClicked(1)"
        >Masukkan Entry</v-btn
      >
    </v-card-title>
    <v-row no-gutters class="pa-5" style="height: 71vh;">
      <v-col cols="4" class="px-5 mx-5">
        <div class="form-style">
          <label class="font-weight-bold body-2 pb-3">Kode Jurnal</label>
          <label class="body-2">{{ item.journalNumber }}</label>

          <label class="font-weight-bold body-2">Tipe Transaksi</label>
          <v-select
            placeholder="Pilih Tipe Transaksi"
            dense
            v-model="input.transactionType"
            :items="resource.transactionType"
            :disabled="isDetail"
          ></v-select>

          <label class="font-weight-bold body-2">Tipe Jurnal</label>
          <v-select
            placeholder="Pilih Tipe Jurnal"
            dense
            v-model="input.journalType"
            :items="resource.journalType"
            :disabled="isDetail"
          ></v-select>

          <label class="font-weight-bold body-2">Unit</label>
          <v-select
            placeholder="Pilih Unit"
            dense
            item-text="name"
            return-object
            v-model="input.unit"
            :items="resource.unit"
            :disabled="isDetail"
          ></v-select>

          <label class="font-weight-bold body-2">No. Bukti</label>
          <v-text-field
            placeholder="Masukkan No Bukti"
            dense
            v-model="input.invoiceNumber"
            :disabled="isDetail"
          ></v-text-field>

          <label class="font-weight-bold body-2">Uraian</label>
          <v-textarea
            placeholder="Masukkan Uraian"
            outlined
            dense
            rows="2"
            no-resize
            v-model="input.detail"
            :disabled="isDetail"
          ></v-textarea>

          <label class="font-weight-bold body-2">Tanggal Transaksi</label>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatedDate"
                placeholder="Pilih Tanggal"
                dense
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="isDetail"
              ></v-text-field>
            </template>
            <v-date-picker
              :max="today"
              v-model="input.date"
              @change="
                menu = false;
                checkBackdate();
              "
            />
          </v-menu>

          <label class="font-weight-bold body-2">Catatan</label>
          <label class="body-2">{{ this.input.note }}</label>
          <!-- <v-textarea
            placeholder="Masukkan Catatan"
            outlined
            dense
            rows="2"
            no-resize
            hide-details="false"
          ></v-textarea> -->
        </div>
      </v-col>

      <v-col cols="7">
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          :items-per-page="table.items.length"
          class="datatable"
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-left px-4">
                {{ table.items.indexOf(item) + 1 }}
              </td>
              <td class="text-left px-4">{{ item.code }}</td>
              <td class="text-left px-4">{{ item.codeName }}</td>
              <td class="text-right px-4">{{ formatted(item.debit) }}</td>
              <td class="text-right px-4">{{ formatted(item.credit) }}</td>
            </tr>
          </template>
          <template v-slot:footer>
            <div class="font-weight-bold total-style body-2 px-5">
              <label>TOTAL</label>
              <label class="text-right">{{ formatted(totalDebit) }}</label>
              <label class="text-right">{{ formatted(totalCredit) }}</label>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col class="px-5 mx-5">
        <v-row no-gutters>
          <v-col cols="2" class="text-left">
            <label v-show="isMaxFile" class="red--text caption"
              >Maksimum hanya 4 file!</label
            >
          </v-col>
        </v-row>

        <v-row no-gutters :class="isMaxFile ? '' : 'pt-5'">
          <v-col cols="2">
            <div class="text-left">
              <v-btn
                class="text-none"
                color="primary"
                outlined
                depressed
                :disabled="isDetail"
                @click="addFiles()"
                ><v-icon>mdi-file-upload</v-icon>Upload Dokumen</v-btn
              >
              <br />
              <label class="caption grey--text"
                >jenis file .pdf, .xlsx, .jpg, .jpeg
                <strong>max 2mb</strong></label
              >
              <input
                type="file"
                ref="files"
                accept="image/png, image/jpeg, application/pdf, application/xlsx"
                multiple
                v-on:change="handleFilesUpload()"
                v-show="false"
              />
            </div>
          </v-col>
          <v-col cols="5" class="files-style">
            <v-row no-gutters>
              <template v-for="(arrs, fileIndex) in document.files">
                <v-col cols="6" class="pb-2 pr-2" :key="'file-' + fileIndex">
                  <v-card outlined class="pa-1" :disabled="isDetail">
                    <v-row no-gutters align="center">
                      <v-col @click="openFile(arrs)" cols="1">
                        <v-icon
                          v-if="arrs.name.search(/.(jpeg|jpg|png)/i) !== -1"
                          color="primary"
                          small
                          >mdi-file-image</v-icon
                        >
                        <v-icon
                          v-else-if="arrs.name.search(/.pdf/i) !== -1"
                          color="primary"
                          small
                          >mdi-file-pdf-box</v-icon
                        >
                        <v-icon v-else class="icon">mdi-file-code</v-icon>
                      </v-col>
                      <v-col
                        @click="openFile(arrs)"
                        cols="10"
                        class="text-left px-2 d-flex justify-space-between align-center"
                      >
                        <label class="caption font-weight-bold">{{
                          arrs.name
                        }}</label>
                        <!-- <span
                          class="grey--text caption text-right"
                          style="width: 35%;"
                          >({{ formatSize(arrs.size) }})</span
                        > -->
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                          icon
                          @click="removeFiles(fileIndex, arrs.id)"
                          x-small
                          v-show="!isDetail"
                          ><v-icon>mdi-delete</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </template>
              <template v-for="(item, fileIndex) in document.failedFiles">
                <v-col
                  cols="6"
                  class="pb-2 pr-2"
                  :key="'failedFile-' + fileIndex"
                >
                  <v-card outlined class="pa-1">
                    <v-row no-gutters align="center">
                      <v-col cols="1">
                        <v-icon
                          v-if="item.file.type.search(/(jpeg|jpg|png)/i) !== -1"
                          color="primary"
                          small
                          >mdi-file-image</v-icon
                        >
                        <v-icon
                          v-else-if="item.file.type.search(/pdf/i) !== -1"
                          color="primary"
                          small
                          >mdi-file-pdf-box</v-icon
                        >
                        <v-icon v-else class="icon">mdi-file-code</v-icon>
                      </v-col>
                      <v-col
                        cols="10"
                        class="px-2 text-left d-flex justify-space-between align-center"
                      >
                        <label class="caption font-weight-bold">{{
                          item.file.name
                        }}</label>
                        <span
                          class="red--text caption text-right"
                          style="width: 35%;"
                          >({{ formatSize(item.file.size) }})</span
                        >
                      </v-col>
                      <v-col cols="1">
                        <button
                          class="btn-back"
                          @click="removeWarning(fileIndex)"
                        >
                          <v-icon color="red" small>mdi-close-circle</v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </v-card>
                  <div class="text-left">
                    <v-icon color="red" small>mdi-alert-circle</v-icon>
                    <label class="caption red--text">
                      File gagal diupload karena melebihi 2MB!</label
                    >
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="2" style="max-width: 12vw;" class="text-left">
            <label class="font-weight-bold body-2">Riwayat Aktivitas</label>
          </v-col>
          <v-col cols="4" class="text-left">
            <label class="grey--text body-2"
              >Printer menambahkan Jurnal pada {{ input.created_at.date }}
              {{ input.created_at.time }}
            </label>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-card-actions class="pb-6">
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="primary"
        class="text-none"
        width="100"
        @click="detailClick()"
        >{{ isDetail ? 'Kembali' : 'Batal' }}</v-btn
      >
      <v-btn
        color="primary"
        class="text-none"
        depressed
        @click="saveJournal()"
        width="100"
        >{{ isDetail ? 'Edit' : 'Simpan' }}</v-btn
      >
    </v-card-actions>
    <v-dialog v-model="dialog" :width="selectedForm === 0 ? 512 : 1350">
      <v-card v-if="selectedForm === 0">
        <v-card-title class="justify-center py-5">
          <h3>Permohanan Jurnal Backdate</h3>
        </v-card-title>
        <v-divider />
        <v-row no-gutters style="display: grid;" class="text-left pa-5">
          <label class="font-weight-bold pb-3">Alasan :</label>
          <v-textarea
            placeholder="Tuliskan Alasan"
            outlined
            dense
            rows="5"
            hide-details="false"
            no-resize
          ></v-textarea>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            width="112"
            outlined
            color="primary"
            class="text-none"
            @click="dialog = false"
            >Kembali</v-btn
          >
          <v-btn
            width="112"
            color="primary"
            class="text-none"
            depressed
            @click="saveBackdate()"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-card>
      <EntryJournal
        v-else-if="selectedForm === 1"
        @close-dialog="dialog = false"
      />
    </v-dialog>
    <v-snackbar
      :timeout="2000"
      :value="snackbar"
      absolute
      centered
      bottom
      tile
      color="red accent-2"
    >
      Berhasil menghapus file
    </v-snackbar>
  </v-card>
</template>

<script>
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import EntryJournal from './EntryJournal.vue';
import Constant from '@/const';
import alertMixin from '../../../../../mixin/alertMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  mixins: [alertMixin],
  name: 'TransactionJournal',
  props: {
    item: {
      type: Object,
    },
  },
  components: {
    EntryJournal,
  },
  data() {
    return {
      idJournal: '',
      snackbar: false,
      menu: false,
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Kode Akun', value: 'code' },
          { text: 'Nama Akun', value: 'codeName' },
          { text: 'Debit', value: 'debit' },
          { text: 'Kredit', value: 'credit' },
        ],
        items: [],
      },
      today: moment(new Date()).format('YYYY-MM-DD'),
      document: {
        files: [],
        failedFiles: [],
      },
      dialog: false,
      selectedForm: null,
      isDetail: true,
      input: {
        transactionType: '',
        journalType: '',
        unit: '',
        invoiceNumber: '',
        detail: '',
        date: null,
        note: '',
        created_at: '',
      },
      resource: {
        transactionType: [
          'Transfer',
          'Utang',
          'Piutang',
          'Pendapatan',
          'Beban',
        ],
        journalType: ['Jurnal Umum', 'Jurnal Penutup', 'Jurnal Penyesuaian'],
        unit: [],
      },
      isMaxFile: false,
    };
  },
  mounted() {
    this.getUnit();
    // this.document.files = this.item.files;
    this.getJournalById(this.item.id);
  },
  computed: {
    formatedDate() {
      return this.input.date
        ? moment(this.input.date)
            .locale('id')
            .format('DD MMMM YYYY')
        : null;
    },
    totalDebit() {
      return this.table.items.reduce((a, { debit }) => a + debit, 0);
    },
    totalCredit() {
      return this.table.items.reduce((a, { credit }) => a + credit, 0);
    },
  },
  methods: {
    openFile(item) {
      if (item.path) {
        window.open(Constant.apiUrl.concat(`/${item.path}`));
      } else {
        const url = URL.createObjectURL(item);
        window.open(url);
      }
    },
    getJournalById(id) {
      axios
        .get(Constant.apiUrl.concat(`/finance/journal/${id}`))
        .then(response => {
          const [data] = response.data.data;
          this.input = {
            transactionType: _.get(data, 'transaction_type', ''),
            journalType: _.get(data, 'journal_type', ''),
            unit: _.get(data, 'unit', ''),
            invoiceNumber: _.get(data, 'proof_code', ''),
            detail: _.get(data, 'description', ''),
            date: data.date
              ? moment(data.date).format('YYYY-MM-DD')
              : this.today,
            note: _.get(data, 'note', ''),
            created_at: {
              date: moment(_.get(data, 'timestamps.created_at', '')).format(
                'DD/MM/YYYY',
              ),
              time: moment(_.get(data, 'timestamps.created_at', '')).format(
                'HH:mm',
              ),
            },
          };
          this.idJournal = data._id;
          this.table.items = data.entries.map(arr => {
            return {
              code: arr.accountbalances?.account_code,
              codeName: arr.accountbalances?.name,
              debit: arr.debit,
              credit: arr.credit,
              id_accountBalance: arr.id_accountBalance,
              name: arr.name,
              type: arr.type,
            };
          });

          this.document.files =
            data.files.length > 0
              ? data.files
                  .filter(file => !file.is_deleted)
                  .map(arr => {
                    return {
                      name: arr.original_file_name,
                      path: arr.path,
                      id: arr._id,
                      type: arr.original_file_name.split('.')[
                        arr.original_file_name.split('.').length - 1
                      ],
                    };
                  })
              : [];
        });
    },
    checkBackdate() {
      if (this.input.date < this.today) {
        Swal.fire({
          title: 'Peringatan',
          text: 'Jurnal ini harus disetujui Wakil Direktur bidang Keuangan',
          icon: 'warning',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Lanjutkan',
          cancelButtonText: 'Kembali',
          confirmButtonColor: '#EB5757',
          reverseButtons: true,
        }).then(result => {
          if (result) {
            this.onClicked(0);
          }
        });
      }
    },
    onClicked(idx) {
      this.selectedForm = idx;
      this.dialog = true;
    },
    addFiles() {
      this.$refs.files.click();
    },
    handleFilesUpload() {
      const filesUploaded = this.$refs.files.files;
      if (
        filesUploaded.length > 4 ||
        this.document.files.length + filesUploaded.length > 4
      ) {
        this.isMaxFile = true;
      } else if (filesUploaded.length + this.document.files.length <= 4) {
        this.isMaxFile = false;
        for (let i = 0; i < filesUploaded.length; i++) {
          if (this.verify(filesUploaded[i]) === '') {
            this.document.files.push(filesUploaded[i]);
          } else {
            this.document.failedFiles.push({
              file: filesUploaded[i],
              message: this.verify(filesUploaded[i]),
            });
          }
        }
      }

      this.$refs.files.value = '';
    },
    verify(file) {
      const maxSize = 2000000;
      const allowedTypes = [
        'image/png',
        'image/jpeg',
        'application/pdf',
        'application/xlsx',
      ];
      if (file.size > maxSize) {
        return 'Maksimum Ukuran file 2MB';
      }

      const ext = file.name.split('.');

      if (!allowedTypes.includes(file.type)) {
        return `Tipe file .${ext[1]} tidak diperbolehkan`;
      }

      return '';
    },
    async removeFiles(fileIndex, id) {
      if (this.document.files[fileIndex].path) {
        const payload = {
          tag: 'journal',
          identifier: this.idJournal,
          file_id: [id],
        };
        await axios
          .delete(
            Constant.apiUrl.concat('/upload'),
            { data: payload },
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          )
          .then(() => {
            this.snackbar = true;
          })
          .catch(error => {
            this.showErrorAxios(error);
          });
      }
      this.document.files.splice(fileIndex, 1);
    },
    removeWarning(fileIndex) {
      this.document.failedFiles.splice(fileIndex, 1);
    },
    saveBackdate() {
      Swal.fire('Permohonan Jurnal Backdate Berhasil Disimpan', '', 'success');
      this.dialog = false;
    },
    async submitFiles(identifier) {
      const formData = new FormData();
      const newFile = this.document.files.filter(file => !file['path']);

      for (let i = 0; i < newFile.length; i++) {
        const file = newFile[i];

        formData.append(`files`, file);
      }
      formData.append('identifier', identifier);
      formData.append('tag', 'journal');
      formData.append('created_by', this.$store.getters.userLoggedIn.id);
      await axios
        .post(Constant.apiUrl.concat('/upload'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    saveJournal() {
      if (this.isDetail) {
        this.isDetail = false;
      } else {
        // const formData = new FormData();

        // this.document.files.forEach(arr => {
        //   formData.append('new_files', arr);
        // });
        // formData.append('transaction_type', this.input.transactionType);
        // formData.append('journal_type', this.input.journalType);
        // formData.append('unit', this.input.unit);
        // formData.append('proof_code', this.input.invoiceNumber);
        // formData.append('description', this.input.detail);
        // formData.append('date', this.input.date);
        // formData.append('note', this.input.note);
        // formData.append('transaction_value', this.totalDebit);
        // this.table.items.forEach((arr, idx) => {
        //   formData.append(
        //     `entries[${idx}][id_accountBalance]`,
        //     arr.id_accountBalance,
        //   );
        //   formData.append(`entries[${idx}][debit]`, arr.debit);
        //   formData.append(`entries[${idx}][type]`, arr.type);
        //   formData.append(`entries[${idx}][name]`, arr.name);
        //   formData.append(`entries[${idx}][credit]`, arr.credit);
        // });
        // formData.append(
        //   'timestamps[created_by]',
        //   this.$store.getters.userLoggedIn.id,
        // );
        const payload = {
          transaction_type: this.input.transactionType,
          journal_type: this.input.journalType,
          unit: this.input.unit,
          proof_code: this.input.invoiceNumber,
          description: this.input.detail,
          date: this.input.date,
          note: this.input.note,
          transaction_value: this.totalDebit,
          entries: this.table.items.map(item => {
            return {
              id_accountBalance: item.id_accountBalance,
              debit: item.debit,
              type: item.type,
              name: item.name,
              credit: item.credit,
            };
          }),
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        };

        axios
          .put(
            Constant.apiUrl.concat(`/finance/journal/${this.item.id}`),
            payload,
          )
          .then(response => {
            const { _id: id } = response.data.data;
            this.submitFiles(id);
            Swal.fire({
              title: 'Edit Jurnal Transaksi Berhasil Disimpan',
              icon: 'success',
              confirmButtonText: 'Selesai',
            });
            this.$emit('close-dialog');
          })
          .catch(err => {
            this.showErrorAxios(err);
          });
      }
    },
    detailClick() {
      if (this.isDetail) {
        this.$emit('close-dialog');
      } else {
        this.isDetail = true;
      }
    },
    getUnit() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=1&itemCount=&search=&sort=name',
          ),
        )
        .then(response => {
          const { data } = response.data;
          this.resource.unit = data.map(arr => {
            return arr.name;
            // id: arr._id,
          });
        });
    },
    formatSize(size) {
      return size < 1000000
        ? `${(size / 1000).toFixed(1)} KB`
        : `${(size / 1000000).toFixed(1)} MB`;
    },
    formatted(val) {
      var format = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      });
      return val ? format.format(val) : format.format(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.entry-style {
  border-width: 2px;
  position: absolute;
  right: 2vw;
}
.form-style {
  display: grid;
  grid-template-columns: 12vw 15vw;
  text-align: left;
  align-items: baseline;
}
.files-style {
  height: 10vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: gray;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}
.total-style {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  background-color: #f0f9ff;
}
.datatable {
  height: 55vh !important;
}
</style>
