<template>
  <v-card>
    <v-card-title class="justify-end">
      <h3>Penerimaan Barang Supplier</h3>
    </v-card-title>
    <v-container fluid class="px-10 cont-layout">
      <v-row no-gutters>
        <v-col class="text-left" cols="4">
          <h3>Cari Nomor Pemesanan</h3>
          <v-row no-gutters class="py-3">
            <v-col>
              <v-combobox
                :loading="isLoading"
                dense
                outlined
                hide-details="false"
                placeholder="Pilih Supplier"
                single-line
                :disabled="
                  itemsReceive.some(item => item.items.length > 0) ||
                    isConfirmation
                "
                class="pr-2"
                :items="resource.factory"
                item-text="supplierName"
                v-model="input.supplier"
                @change="getOrderNumber()"
              ></v-combobox>
            </v-col>
            <v-col>
              <v-combobox
                :disabled="
                  input.supplier === '' ||
                    itemsReceive.some(item => item.items.length > 0) ||
                    isConfirmation
                "
                dense
                outlined
                hide-details="false"
                placeholder="Pilih Nomor Pemesanan"
                single-line
                class="pl-2"
                :items="resource.orderNumber"
                item-text="orderNumber"
                v-model="input.orderNumber"
              ></v-combobox>
            </v-col>
          </v-row>
          <div class="text-right">
            <v-btn color="primary" tile depressed class="text-none">
              <v-icon>mdi-magnify</v-icon>Cari
            </v-btn>
          </div>
        </v-col>
        <v-col class="text-left px-5">
          <h3>Detail Pemesanan</h3>
          <v-row no-gutters class="py-3">
            <v-col cols="6">
              <div class="detail-order">
                <label class="font-weight-bold body-2">Nomor Pemesanan</label>
                <label class="body-2">{{
                  input.orderNumber ? input.orderNumber.orderNumber : ''
                }}</label>

                <label class="font-weight-bold body-2">Nama Supplier</label>
                <label class="body-2">{{
                  input.supplier ? input.supplier.supplierName : ''
                }}</label>

                <label class="font-weight-bold body-2">Tanggal Pemesanan</label>
                <label class="body-2">{{
                  input.orderNumber ? input.orderNumber.orderDate : ''
                }}</label>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="detail-order">
                <label class="font-weight-bold body-2"
                  >Tanggal Jatuh Tempo</label
                >
                <label class="body-2"></label>

                <label class="font-weight-bold body-2"
                  >Tanggal Penerimaan</label
                >
                <label class="body-2">{{ resource.receivedDate }}</label>

                <label class="font-weight-bold">Total Biaya</label>
                <label>
                  {{
                    input.orderNumber
                      ? currency(input.orderNumber.total || 0)
                      : 0
                  }}</label
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <h3 class="text-left">Input Data Penerimaan Barang</h3>
      <v-form ref="form">
        <v-row no-gutters>
          <v-col class="text-left" cols="4">
            <div class="input-data">
              <label class="font-weight-bold body-2 required"
                >Nomor Tanda Terima</label
              >
              <v-text-field
                dense
                placeholder="Masukkan nomor tanda terima"
                v-model="input.receiveNumber"
                :rules="rules"
                @blur="checkReceiveNumber()"
              ></v-text-field>

              <label class="font-weight-bold body-2 required"
                >Nomor Invoice/Faktur</label
              >
              <v-text-field
                dense
                :rules="rules"
                placeholder="Masukkan nomor faktur"
                v-model="input.invoiceNumber"
                @blur="checkInvNumber()"
              ></v-text-field>

              <label class="font-weight-bold body-2">Barcode</label>
              <v-text-field
                dense
                placeholder="Masukkan barcode"
                v-model="input.barcode"
              ></v-text-field>

              <label class="font-weight-bold body-2">Nama Barang</label>
              <v-combobox
                dense
                placeholder="Pilih nama barang"
                outlined
                single-line
                :rules="rules"
                :items="filteredItemOrder"
                item-text="name"
                v-model="input.name"
              ></v-combobox>
              <!-- :items="input.orderNumber.items" -->

              <label class="font-weight-bold body-2">Jumlah Pesanan</label>
              <label class="body-2"
                >{{ input.orderAmount }} {{ input.package }}</label
              >

              <label class="font-weight-bold body-2">Jumlah Diterima</label>
              <v-text-field
                dense
                placeholder="Masukkan jumlah"
                hide-details="false"
                v-model.number="input.receivedAmount"
                type="number"
              ></v-text-field>

              <label class="font-weight-bold body-2"
                >Jumlah Barang Kurang</label
              >
              <v-text-field
                dense
                placeholder="Masukkan jumlah"
                hide-details="false"
                v-model.number="input.unmatchedAmount"
                type="number"
                readonly
              ></v-text-field>

              <label class="font-weight-bold body-2">Barang Kurang</label>
              <v-switch
                class="mt-0"
                inset
                hide-details="false"
                v-model="input.lessStuff"
              ></v-switch>
            </div>
          </v-col>
          <v-col class="text-left px-5" cols="4">
            <label class="font-weight-bold body-2">Alasan Pengembalian</label>
            <v-textarea
              dense
              placeholder="Deskripsikan alasan pengembalian"
              hide-details="false"
              outlined
              no-resize
              style="width: 93%"
              v-model="input.returnReason"
            ></v-textarea>
            <div class="input-data center">
              <label class="font-weight-bold body-2">Hibah</label>
              <v-radio-group
                row
                hide-details="false"
                class="mt-0 pt-0"
                v-model="input.hibah"
              >
                <v-radio label="Ya" :value="true"></v-radio>
                <v-radio label="Tidak" :value="false"></v-radio>
              </v-radio-group>

              <label class="font-weight-bold body-2 required"
                >Nomor Batch Barang</label
              >
              <v-text-field
                dense
                placeholder="Masukkan nomor batch"
                :rules="rules"
                v-model="input.batchNumber"
              ></v-text-field>

              <label class="font-weight-bold body-2 required">Package</label>
              <v-text-field
                dense
                outlined
                single-line
                readonly
                :rules="rules"
                placeholder="Package"
                v-model="input.package"
              ></v-text-field>

              <label class="font-weight-bold body-2 required"
                >Tanggal Expired</label
              >
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :rules="rules"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    placeholder="Pilih Tanggal"
                    hide-details="false"
                    v-model="input.expiredDate"
                    :rules="input.name.category === 'Obat' ? rules : []"
                    readonly
                    v-bind="attrs"
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="input.expiredDate"
                  :min="today"
                  @input="
                    $refs.menu.save(input.expiredDate);
                    menu = false;
                  "
                />
              </v-menu>
            </div>
          </v-col>
          <v-col class="text-left" cols="4">
            <div class="input-data">
              <label class="font-weight-bold body-2 required"
                >Harga Per Satuan</label
              >
              <v-currency-field
                dense
                prefix="Rp."
                :rules="rules"
                placeholder="Masukkan harga barang"
                v-model.number="input.unitPrice"
              ></v-currency-field>

              <label class="font-weight-bold body-2">Diskon (Rp)</label>
              <v-currency-field
                dense
                prefix="Rp."
                placeholder="Masukkan nominal"
                hide-details="false"
                v-model.number="input.discNominal"
              ></v-currency-field>

              <label class="font-weight-bold body-2">Diskon (%)</label>
              <v-text-field
                dense
                placeholder="Masukkan persentase"
                hide-details="false"
                v-model="input.discPercent"
              ></v-text-field>

              <label class="font-weight-bold">Subtotal</label>
              <label class="font-weight-bold">{{
                currency(subTotal || 0)
              }}</label>
            </div>
            <div class="text-right">
              <v-btn
                outlined
                tile
                color="primary"
                class="text-none add-to-table-btn"
                @click="addToTable()"
                >Tambahkan ke tabel</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-form>

      <template v-for="(item, idx) in itemsReceive">
        <div :key="'itemReceive-' + idx" class="pt-5">
          <h3 class="text-left">Tanda Terima ({{ item.receiveNumber }})</h3>
          <h3 class="text-left">Faktur ({{ item.invoiceNumber }})</h3>

          <v-data-table
            :headers="table.headers"
            :items="item.items"
            :items-per-page="item.items.length"
            class="data-table"
            hide-default-footer
          >
            <template v-slot:[`item.name`]="{ item }">
              <td class="font-weight-bold">{{ item.name }}</td>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <td>
                <v-icon @click="deleteItem(item, idx)" color="red"
                  >mdi-delete</v-icon
                >
              </td>
            </template>
          </v-data-table>

          <v-row no-gutters class="pt-5">
            <v-col cols="5">
              <div class="reason-style text-left">
                <label class="body-2 font-weight-bold text-left"
                  >Alasan Transaksi Pending</label
                >
                <v-select
                  chips
                  dense
                  multiple
                  :items="resource.reason"
                  v-model="itemsReceive[idx].pending_reason"
                  hide-details="false"
                ></v-select>

                <label class="font-weight-bold body-2">Metode Pembayaran</label>
                <v-select
                  dense
                  :rules="rules"
                  outlined
                  single-line
                  placeholder="Pilih Metode"
                  :items="resource.paymentMethod"
                  hide-details="false"
                  v-model="itemsReceive[idx].paymentMethod"
                ></v-select>

                <label class="font-weight-bold body-2">Biaya Materai</label>
                <v-currency-field
                  dense
                  prefix="Rp."
                  placeholder="Masukkan biaya materai"
                  hide-details="false"
                  v-model.number="itemsReceive[idx].materai"
                ></v-currency-field>

                <label class="font-weight-bold body-2">Biaya Ongkir</label>
                <v-currency-field
                  dense
                  prefix="Rp."
                  placeholder="Masukkan biaya ongkir"
                  hide-details="false"
                  v-model.number="itemsReceive[idx].deliveryFee"
                ></v-currency-field>
              </div>
            </v-col>
            <v-col class="px-5">
              <v-card color="#C1C8FF" rounded="lg" flat class="text-left pa-3">
                <v-row no-gutters>
                  <v-col cols="4">
                    <div style="display: flex; align-items: center">
                      <v-icon x-large color="white">mdi-cloud-upload</v-icon>
                      <h4 class="pl-5">Upload Bukti Faktur</h4>
                    </div>
                    <div class="py-3">
                      <input
                        type="file"
                        ref="files"
                        accept="image/png, image/jpeg, application/pdf"
                        multiple
                        v-on:change="handleFilesUpload(idx)"
                        v-show="false"
                      />
                      <v-btn
                        depressed
                        class="text-none primary--text"
                        color="white"
                        width="150"
                        @click="addFiles(idx)"
                        >Pilih File</v-btn
                      >
                    </div>
                    <div class="pb-3">
                      <label class="caption"
                        >Jenis file : (.pdf), (.jpg), (.png)</label
                      >
                      <br />
                      <label class="caption red--text"
                        >ukuran file max. 2 MB</label
                      >
                    </div>
                  </v-col>

                  <v-col>
                    <template
                      v-for="(arr, fileIndex) in item.invoices.failedFiles"
                    >
                      <v-row
                        class="failed-upload py-2"
                        no-gutters
                        align="center"
                        :key="'file-' + fileIndex"
                      >
                        <v-col cols="1" class="ml-2">
                          <v-icon
                            v-if="
                              arr.file.type.search(/.(jpeg|jpg|png)/i) !== -1
                            "
                            class="white--text"
                            >mdi-file-image</v-icon
                          >
                          <v-icon
                            v-else-if="arr.file.type.search(/.pdf/i) !== -1"
                            class="white--text"
                            >mdi-file-pdf-box</v-icon
                          >
                          <v-icon v-else class="icon">mdi-file-code</v-icon>
                        </v-col>
                        <v-col cols="9" class="mx-2">
                          <label class="text-left ma-0 caption">
                            File {{ arr.file.name }} gagal diunggah karena
                            <span class="yellow--text">{{ arr.message }}</span>
                          </label>
                        </v-col>
                        <v-col cols="1">
                          <button
                            class="btn-back"
                            @click="removeWarning(fileIndex, idx)"
                          >
                            <v-icon color="#fff">mdi-close</v-icon>
                          </button>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-for="(arrs, fileIndex) in item.invoices.files">
                      <v-row
                        class="pt-3"
                        no-gutters
                        align="center"
                        :key="'file-' + fileIndex"
                      >
                        <v-col cols="1">
                          <v-icon
                            v-if="arrs.type.search(/.(jpeg|jpg|png)/i) !== -1"
                            >mdi-file-image</v-icon
                          >
                          <v-icon v-else-if="arrs.type.search(/.pdf/i) !== -1"
                            >mdi-file-pdf-box</v-icon
                          >
                          <v-icon v-else class="icon">mdi-file-code</v-icon>
                        </v-col>
                        <v-col cols="6">
                          <label class="text-left">{{ arrs.name }}</label>
                          <!-- <v-progress-linear
                        class="upload-bar"
                        :value="progress"
                        v-if="isUploading"
                      ></v-progress-linear> -->
                          <!-- <h4 class="text-md-left" v-if="isUploading">
                        {{ progress }} done
                      </h4> -->
                        </v-col>
                        <v-col cols="2" class="mr-2">
                          <v-btn
                            small
                            block
                            color="primary"
                            depressed
                            class="text-none"
                            @click="previewFile(arrs)"
                            >Detail</v-btn
                          >
                        </v-col>
                        <v-col cols="2">
                          <v-btn
                            small
                            block
                            color="error"
                            depressed
                            class="text-none"
                            @click="removeFiles(fileIndex, idx)"
                            >Cancel</v-btn
                          >
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-container>
    <v-card-actions>
      <v-btn text class="text-none" @click="onCloseButtonClick()"
        >Kembali</v-btn
      >
      <v-spacer></v-spacer>
      <!-- <v-btn outlined tile color="primary" class="text-none" width="150">
        <v-icon>mdi-printer</v-icon>Print</v-btn
      > -->
      <v-btn
        class="text-none"
        color="primary"
        :disabled="disable"
        tile
        depressed
        width="150"
        @click="addReceiveOrder()"
        >Simpan</v-btn
      >
    </v-card-actions>
    <v-snackbar
      color="error"
      timeout="3000"
      style="cursor: pointer"
      v-model="snackbar"
    >
      Masukkan Metode Pembayaran
      <v-icon @click.native="snackbar = false" small color="#ffff"
        >mdi-close</v-icon
      >
    </v-snackbar>
    <v-snackbar
      style="cursor: pointer"
      color="error"
      timeout="3000"
      v-model="snackbar2"
    >
      Tidak Ada Data Penerimaan yang Disimpan
      <v-icon @click.native="snackbar2 = false" small color="#ffff"
        >mdi-close</v-icon
      >
    </v-snackbar>
    <v-snackbar
      style="cursor: pointer"
      color="error"
      timeout="3000"
      v-model="snackbar3"
    >
      {{ errorMessage }}
      <v-icon @click.native="snackbar3 = false" small color="#ffff"
        >mdi-close</v-icon
      >
    </v-snackbar>
  </v-card>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import alertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';
import Swal from 'sweetalert2';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'InputItemReceipt',
  mixins: [formatMixin, alertMixin],
  props: {
    isConfirmation: {
      type: Boolean,
      default: false,
    },
    spnumber: {
      type: String,
      default: null,
    },
    supplierName: {
      type: String,
      default: null,
    },
    isFromInputItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorMessage: '',
      isLoading: false,
      disable: false,
      resource: {
        paymentMethod: [
          { text: 'Cash', value: 'cash' },
          { text: 'Tempo', value: 'tempo' },
          { text: 'Konsinyasi', value: 'konsinyasi' },
          { text: 'Hibah', value: 'hibah' },
        ],
        reason: [
          'Jumlah Barang Tidak Sesuai',
          'Penggantian Nomor Faktur',
          'Belum Ada Nomor Faktur',
        ],
        factory: [],
        orderNumber: [],
        receivedDate: moment().format('DD/MM/YYYY'),
      },
      menu: false,
      snackbar: false,
      snackbar2: false,
      snackbar3: false,
      snackbar4: false,
      today: moment().format(),
      input: {
        receiveNumber: '',
        invoiceNumber: '',
        barcode: '',
        name: '',
        orderAmount: '',
        receivedAmount: null,
        unmatchedAmount: null,
        returnReason: '',
        lessStuff: false,
        hibah: false,
        batchNumber: '',
        package: '',
        expiredDate: null,
        unitPrice: null,
        discNominal: null,
        discPercent: null,
        supplier: '',
        orderNumber: '',
      },
      table: {
        headers: [
          { text: 'Nama Barang', value: 'name', class: 'white--text' },
          { text: 'Jumlah Pesan', value: 'orderAmount', class: 'white--text' },
          {
            text: 'Jumlah Diterima',
            value: 'receivedAmount',
            class: 'white--text',
          },
          {
            text: 'Jumlah Barang Kurang',
            value: 'unmatchAmount',
            class: 'white--text',
          },
          {
            text: 'Alasan Pengembalian',
            value: 'returnReason',
            class: 'white--text',
            sortable: false,
          },
          {
            text: 'Tanggal Expired',
            value: 'expiredDate',
            class: 'white--text',
          },
          { text: 'No Batch', value: 'batch', class: 'white--text' },
          {
            text: 'Harga Satuan',
            value: 'unitPrice',
            sortable: false,
            class: 'white--text',
          },
          {
            text: 'Disc',
            value: 'discount',
            sortable: false,
            class: 'white--text',
          },
          {
            text: 'Sub Total',
            value: 'subtotal',
            sortable: false,
            class: 'white--text',
          },
          {
            text: 'Hibah',
            value: 'hibah',
            sortable: false,
            class: 'white--text',
          },
          {
            text: '',
            value: 'delete',
            sortable: false,
          },
        ],
      },
      itemsReceive: [],
      rules: [v => !!v || 'Wajib diisi'],
    };
  },
  mounted() {
    this.getOrder();
  },
  watch: {
    'input.name'(val) {
      this.input.package = val.package;
      this.input.orderAmount = val.quantity;
    },
    'input.unmatchedAmount'(val) {
      if (val > 0) {
        this.input.lessStuff = true;
      } else {
        this.input.lessStuff = false;
      }
    },
    'input.orderAmount'(val) {
      if (val) {
        this.input.unmatchedAmount = this.input.orderAmount;
        this.input.receivedAmount = 0;
      }
    },
    'input.receivedAmount'(val) {
      if (val === '') {
        this.input.unmatchedAmount = this.input.orderAmount;
      }
      if (val) {
        this.input.unmatchedAmount = Math.max(this.input.orderAmount - val, 0);
      }
    },
    'input.discPercent'(val) {
      if (val) {
        this.input.discNominal = (this.input.unitPrice * val) / 100;
      } else {
        this.input.discNominal = 0;
      }
    },
    'input.discNominal'(val) {
      if (val) {
        this.input.discPercent = (val / this.input.unitPrice) * 100;
      } else {
        this.input.discPercent = 0;
      }
    },
  },
  computed: {
    subTotal() {
      const total_first =
        this.input.receivedAmount *
        this.input.unitPrice *
        this.input.name.itemsPerPackage;
      const total_disc =
        (this.input.receivedAmount *
          this.input.unitPrice *
          this.input.discPercent *
          this.input.name.itemsPerPackage) /
        100;
      return total_first - total_disc || 0;
    },
    addedItemsId() {
      const addedItems = this.itemsReceive.map(item =>
        item.items.map(x => x._id),
      );
      const addedItemsId = addedItems.flat();
      return addedItemsId;
    },
    filteredItemOrder() {
      if (this.input.orderNumber === '') return [];
      const items = this.input.orderNumber.items.filter(
        item =>
          !this.addedItemsId.some(x => x === item._id) &&
          !this.input.orderNumber.addedItems.some(x =>
            item.id_drugs ? x === item.id_drugs : x === item.id_goods,
          ),
      );
      return items;
    },
  },
  methods: {
    getConfirmationData() {
      this.resource.orderNumber = [];
      this.input.orderNumber = '';
      const index = this.resource.factory.findIndex(
        x => x.supplierName === this.supplierName,
      );
      this.resource.factory[index].detail.forEach(x => {
        this.resource.orderNumber.push({
          addedItems: x.addedItems,
          orderNumber: x.orderNumber,
          id_supplyRequest: x.id_supplyRequest,
          items: x.items,
          orderDate: x.orderDate,
          total: x.total,
        });
      });
      this.input.supplier = this.resource.factory[index];
      this.input.orderNumber = this.resource.orderNumber.filter(
        item => item.orderNumber === this.spnumber,
      )[0];
    },
    deleteItem(item, indexItemsReceive) {
      const index = this.itemsReceive[indexItemsReceive].items.indexOf(item);
      this.itemsReceive[indexItemsReceive].items.splice(index, 1);
    },
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    addFiles(idx) {
      this.$refs.files[idx].click();
    },
    async submitFiles(identifier, invoice) {
      const formData = new FormData();

      for (let i = 0; i < invoice.files.length; i++) {
        const file = invoice.files[i];

        formData.append(`files`, file);
      }
      formData.append('identifier', identifier);
      formData.append('tag', 'invoicesupplier');
      formData.append('created_by', this.$store.getters.userLoggedIn.id);

      await axios
        .post(Constant.apiUrl.concat('/upload'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    handleFilesUpload(idx) {
      const filesUploaded = this.$refs.files[idx].files;
      for (let i = 0; i < filesUploaded.length; i++) {
        if (this.verify(filesUploaded[i]) === '') {
          this.itemsReceive[idx].invoices.files.push(filesUploaded[i]);
        } else {
          this.itemsReceive[idx].invoices.failedFiles.push({
            file: filesUploaded[i],
            message: this.verify(filesUploaded[i]),
          });
        }
      }

      this.$refs.files[idx].value = '';
    },
    verify(file) {
      const maxSize = 2000000;
      const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
      if (file.size > maxSize) {
        return 'Maksimum Ukuran file 2MB';
      }

      const ext = file.name.split('.');

      if (!allowedTypes.includes(file.type)) {
        return `Tipe file .${ext[1]} tidak diperbolehkan`;
      }

      return '';
    },
    previewFile(file) {
      const url = URL.createObjectURL(file);
      window.open(url);
    },
    removeFiles(fileIndex, idx) {
      this.itemsReceive[idx].invoices.files.splice(fileIndex, 1);
    },
    removeWarning(fileIndex, idx) {
      this.itemsReceive[idx].invoices.failedFiles.splice(fileIndex, 1);
    },
    checkInvNumber() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/supply/check/invoice?invoice=${this.input.invoiceNumber}&supplier=${this.input.supplier.detail[0].id_supplier}`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          if (!data) {
            this.errorMessage =
              'Nomor Invoice atau Faktur Sudah Ada pada penerimaan sebelumnya';
            this.snackbar3 = true;
          }
        });
    },
    checkReceiveNumber() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/supply/check/recnumber?receiveNum=${this.input.receiveNumber}&supplier=${this.input.supplier.detail[0].id_supplier}`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          if (!data) {
            this.errorMessage =
              'Nomor Tanda Terima Sudah Ada pada penerimaan sebelumnya';
            this.snackbar3 = true;
          }
        });
    },
    async addToTable() {
      if (
        this.hasWhiteSpace(this.input.invoiceNumber.trim()) ||
        this.hasWhiteSpace(this.input.receiveNumber.trim())
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            'Nomor invoice dan nomor tanda terima tidak boleh mengandung spasi',
        });
        return;
      }
      if (this.$refs.form.validate()) {
        const payload = {
          id_drugs: this.input.name.id_drugs,
          id_goods: this.input.name.id_goods,
          _id: this.input.name._id,
          return_reason: this.input.returnReason,
          amount: {
            unfit_amount: this.input.unmatchedAmount,
            ordered_amount: this.input.orderAmount,
          },
          price: {
            price_per_unit: this.input.unitPrice,
            discount: this.input.discNominal,
            sub_total: this.subTotal,
          },
          isComplete: !this.input.lessStuff,
          isHibah: this.input.hibah,
          history: [
            {
              received_amount: this.input.receivedAmount,
              created_by: this.$store.getters.userLoggedIn.id,
              created_at: moment().format(),
            },
          ],
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
          supplier: this.input.supplier.detail[0].id_supplier,
          name: this.input.name.name,
          invoice_number: this.input.invoiceNumber.trim(),
          expiry_date: this.input.expiredDate,
          batch_number: this.input.batchNumber,
          package: this.input.package,
          receive_number: this.input.receiveNumber.trim(),
          received_amount: this.input.receivedAmount,
          barcode: this.input.barcode,
        };

        const item = {
          receiveNumber:
            payload.receive_number || 'Tidak ada nomor tanda terima',
          invoiceNumber: payload.invoice_number || 'Tidak ada nomor faktur',
          items: [],
          pending_reason: [],
          paymentMethod: '',
          deliveryFee: null,
          materai: null,
          invoices: {
            files: [],
            failedFiles: [],
          },
        };

        const drugs = payload.id_drugs
          ? {
              // id_supplyItem: id_supplyItem,
              _id: payload._id,
              id_drugs: payload.id_drugs,
              barcode: payload.barcode,
              name: payload.name,
              supplier: payload.supplier,
              orderAmount: payload.amount.ordered_amount,
              receivedAmount: payload.history[0].received_amount,
              unmatchAmount: payload.amount.unfit_amount,
              returnReason: payload.return_reason,
              expiredDate: payload.expiry_date,
              batch: payload.batch_number,
              unitPrice: payload.price.price_per_unit,
              discount: payload.price.discount,
              subtotal: payload.price.sub_total,
              hibah: payload.isHibah ? 'Ya' : 'Tidak',
              package: payload.package,
            }
          : '';

        const goods = payload.id_goods
          ? {
              // id_supplyItem: id_supplyItem,
              _id: payload._id,
              id_goods: payload.id_goods,
              barcode: payload.barcode,
              name: payload.name,
              supplier: payload.supplier,
              orderAmount: payload.amount.ordered_amount,
              receivedAmount: payload.history[0].received_amount,
              unmatchAmount: payload.amount.unfit_amount,
              returnReason: payload.return_reason,
              expiredDate: payload.expiry_date,
              batch: payload.batch_number,
              unitPrice: payload.price.price_per_unit,
              discount: payload.price.discount,
              subtotal: payload.price.sub_total,
              hibah: payload.isHibah ? 'Ya' : 'Tidak',
              package: payload.package,
            }
          : '';

        if (
          this.itemsReceive.some(
            x =>
              x.receiveNumber === item.receiveNumber ||
              x.invoiceNumber === item.invoiceNumber,
          )
        ) {
          const index = this.itemsReceive.findIndex(
            x => x.receiveNumber === item.receiveNumber,
          );
          this.itemsReceive[index].items.push(payload.id_drugs ? drugs : goods);
        } else {
          item.items.push(payload.id_drugs ? drugs : goods);
          this.itemsReceive.push(item);
        }

        this.input = {
          receiveNumber: payload.receive_number,
          invoiceNumber: payload.invoice_number,
          barcode: '',
          name: '',
          orderAmount: '',
          receivedAmount: null,
          unmatchedAmount: null,
          returnReason: '',
          lessStuff: false,
          hibah: false,
          batchNumber: '',
          package: '',
          expiredDate: null,
          unitPrice: null,
          discNominal: null,
          discPercent: null,
          supplier: this.input.supplier,
          orderNumber: this.input.orderNumber,
        };
        this.$refs.form.resetValidation();
      }
    },
    getOrder() {
      this.isLoading = true;
      axios
        .get(Constant.apiUrl.concat('/supply/request/dropdown'))
        .then(response => {
          const { data } = response.data;
          const listItems = data.map(arr => {
            return {
              addedItems: arr.detail_receive.map(
                item =>
                  item.item[item.item.length - 1].id_drugs ||
                  item.item[item.item.length - 1].id_goods,
              ),
              id_supplier: arr.id_supplier,
              id_supplyRequest: arr._id,
              supplierName: arr.supplier.name,
              orderNumber: arr.order_number,
              orderDate: moment(arr.order_date).format('DD/MM/YYYY'),
              bill_due: arr.supplier.detail[0].bill_due,
              items: arr.item,
              total: arr.item.reduce((a, { sub_total }) => a + sub_total, 0),
            };
          });

          this.resource.factory = _(listItems)
            .groupBy(x => x.supplierName)
            .map((value, key) => ({
              supplierName: key,
              detail: value,
            }))
            .value();
          // get confirmation data from detail item order
          if (this.isConfirmation) {
            this.getConfirmationData();
          }
        })
        .catch(() => {
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getOrderNumber() {
      this.resource.orderNumber = [];
      this.input.orderNumber = '';
      const index = this.resource.factory.findIndex(
        x => x.supplierName === this.input.supplier.supplierName,
      );
      this.resource.factory[index].detail.forEach(x => {
        this.resource.orderNumber.push({
          addedItems: x.addedItems,
          orderNumber: x.orderNumber,
          id_supplyRequest: x.id_supplyRequest,
          items: x.items,
          orderDate: x.orderDate,
          total: x.total,
        });
      });
    },
    hasWhiteSpace(s) {
      return s.indexOf(' ') >= 0;
    },
    addReceiveOrder() {
      if (this.itemsReceive.some(x => x.paymentMethod === '')) {
        this.snackbar = true;
        return;
      }
      if (
        this.itemsReceive.length === 0 ||
        this.itemsReceive.some(x => x.items.length === 0)
      ) {
        this.snackbar2 = true;
        return;
      }
      const payload = this.itemsReceive.map(arr => {
        return {
          id_supplyRequest: this.input.orderNumber.id_supplyRequest,
          receive_number: arr.receiveNumber,
          invoice_number: arr.invoiceNumber,
          payment_type: arr.paymentMethod,
          pending_reason: arr.pending_reason,
          isReceived: true,
          cost: {
            shipping_cost: arr.deliveryFee,
            total_cost: arr.items.reduce((a, { subtotal }) => a + subtotal, 0),
            stamp_cost: arr.materai,
          },
          item: arr.items.map(x => {
            if (x.id_drugs) {
              return {
                // drugs/goods:a,
                id_supplier: x.supplier,
                batch_number: x.batch,
                expiry_date: x.expiredDate,
                barcode: x.barcode,
                id_drugs: x.id_drugs,
                package: x.package,
                ordered_amount: x.orderAmount,
                received_amount: x.receivedAmount,
                unfit_amount: x.unmatchAmount || '',
                return_reason: x.returnReason,
                price: {
                  price_per_item: x.unitPrice,
                  discount: x.discount || '',
                  total: x.subtotal,
                  hibah_status: x.hibah === 'Ya',
                },
              };
            }
            if (x.id_goods) {
              return {
                id_supplier: x.supplier,
                batch_number: x.batch,
                expiry_date: x.expiredDate,
                id_goods: x.id_goods,
                barcode: x.barcode,
                package: x.package,
                ordered_amount: x.orderAmount,
                received_amount: x.receivedAmount,
                unfit_amount: x.unmatchAmount || '',
                return_reason: x.returnReason,
                price: {
                  price_per_item: x.unitPrice,
                  discount: x.discount || '',
                  total: x.subtotal,
                  hibah_status: x.hibah === 'Ya',
                },
              };
            }
          }),
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        };
      });

      axios
        .post(Constant.apiUrl.concat('/supply/itemlist'), payload)
        .then(res => {
          if (res) {
            Swal.fire(
              'Berhasil',
              'Penerimaan barang supplier berhasil',
              'success',
            );
            this.disable = true;
            this.itemsReceive.forEach(item => {
              this.submitFiles(
                `${this.input.orderNumber.orderNumber.trim()}${item.invoiceNumber.trim()}${
                  item.receiveNumber
                }`,
                item.invoices,
              );
            });
            if (this.isFromInputItem) {
              this.onCloseButtonClick();
            }
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cont-layout {
  height: 83vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #ededed;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 8px;
    background-color: #9e9e9e;
  }
}
.detail-order {
  display: grid;
  grid-template-columns: 30vh 30vh;
  grid-row-gap: 2vh;
}
.input-data {
  display: grid;
  grid-template-columns: 30vh 30vh;
  align-items: center;
  grid-template-rows: repeat(8, 7vh);
  &.center {
    grid-template-rows: repeat(4, 7vh);
  }
}
.reason-style {
  display: grid;
  align-items: baseline;
  grid-template-columns: 30vh 40vh;
  grid-row-gap: 2vh;
}
.failed-upload {
  background-color: #d00;
  color: white;
  border-radius: 5px;
}
::v-deep .v-data-table__wrapper {
  border-radius: 20px;
}
::v-deep .v-data-table-header {
  background-color: #3498db;
}
.data-table {
  ::v-deep tbody {
    background-color: #f0f9ff;
  }
}

.add-to-table-btn {
  margin-top: -90px;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
