var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-data-table',{staticClass:"data-table",attrs:{"headers":_vm.headers,"items":_vm.patients,"hide-default-footer":"","expanded":_vm.expanded,"height":_vm.height,"item-key":"idRegistration","single-expand":"","loading":_vm.loading,"items-per-page":_vm.itemsPerPage},on:{"click:row":(_, slot) => {
        slot.expand(!slot.isExpanded);
      }},scopedSlots:_vm._u([{key:`item.no`,fn:function({ index }){return [_c('td',[_vm._v(" "+_vm._s((_vm.pagination.page - 1) * _vm.itemsPerPage + index + 1)+" ")])]}},{key:`item.guarantorType`,fn:function({ item }){return [_c('td',{staticClass:"text-uppercase text-left"},[_vm._v(_vm._s(item.guarantorType))])]}},{key:"no-data",fn:function(){return [_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" Tidak ada pasien terdaftar ")])]},proxy:true},{key:"expanded-item",fn:function({ item, headers }){return [_c('td',{staticClass:"px-0 expand-wrapper",attrs:{"colspan":headers.length}},[_c('v-container',{staticClass:"pt-3"},[_c('div',{staticClass:"expand-container"},[_c('div'),_c('span',[_vm._v(_vm._s(`${item.gender ? 'Laki-laki' : 'Perempuan'} ${item.age} Tahun`))]),_c('span',[_vm._v(_vm._s(`Pasien ${item.isNew ? 'Baru' : 'Lama'}`))]),_c('span',[_vm._v(_vm._s(`Jenis Kasus: ${item.case}`))])])])],1)]}},{key:`item.dots`,fn:function({ item }){return [_c('v-menu',{attrs:{"bottom":"","left":"","offset-x":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","light":"","fab":"","text":""},on:{"click":function($event){return _vm.assignSelectedPatient(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"text-start",attrs:{"dense":""}},_vm._l((item.isFinish
              ? _vm.resource.menus.filter(
                  i =>
                    i.text !== 'Cetak Gelang Pasien' && i.text !== 'Data Bed',
                )
              : _vm.resource.menus),function(menu,idx){return _c('v-list-item',{key:`menu-reg-${idx}`,on:{"click":function($event){return menu.action(item)}}},[_c('span',[_vm._v(_vm._s(menu.text))])])}),1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":_vm.maxWidth[_vm.selectedForm]},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.selectedForm === 0 && _vm.dialog)?_c('social-data',{attrs:{"medical-record":_vm.selectedPatient.rmNumber},on:{"close-form":_vm.closeForm}}):_vm._e(),(_vm.selectedForm === 1 && _vm.dialog)?_c('bed-inpatient',{attrs:{"selected-patient":_vm.selectedPatient,"medical-record":_vm.selectedPatient.rmNumber,"is-detail":""},on:{"close-form":_vm.closeForm}}):(_vm.selectedForm === 2 && _vm.dialog)?_c('general-consent-form',{attrs:{"rm-number":_vm.selectedPatient.rmNumber,"id-inpatient":_vm.selectedPatient.id,"service-type":"Rawat Inap"},on:{"close-form":_vm.closeForm}}):(_vm.selectedForm === 3 && _vm.dialog)?_c('detail-spri',{attrs:{"patient":_vm.selectedPatient},on:{"close-form":_vm.closeForm}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }