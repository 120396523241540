import Constant from '@/const';
import axios from 'axios';

const getReferralLetter = ({ id_registration }) => {
  try {
    const response = axios.get(
      Constant.apiUrl.concat(
        `/letter/referral-patient-letter/registration/${id_registration}`,
      ),
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getReferralLetter;
