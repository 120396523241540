<template>
  <div>
    <h3>
      {{ title.toUpperCase() }}
    </h3>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    title: {
      type: String,
      default: '-',
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  background-color: #f8f8f8;
  padding: 0.7vw;
  color: #cfcfcf;
  margin-bottom: 3dvh;
}
</style>
