<template>
  <section>
    <v-card-title class="title font-weight-bold primary--text text-left">
      Skrining Gizi
    </v-card-title>
    <v-row v-if="!isAdult" class="mb-3">
      <v-col cols="11" class="grid-nutrition">
        <label>
          Apakah Pasien Tampak Kurus?
        </label>
        <div class="d-flex justify-center">
          <v-radio-group
            :disabled="isEmr"
            dense
            hide-details
            row
            v-model="isThin"
          >
            <v-radio
              label="Tidak"
              :value="{ check: false, score: 0 }"
            ></v-radio>
            <v-radio label="Ya" :value="{ check: true, score: 1 }"></v-radio>
          </v-radio-group>
        </div>
        <div class="text-start">
          <label>
            Apakah Terdapat Penurunan Berat Badan Selama 1 Bulan Terakhir?
          </label>
          <p>
            (berdasarkan penilaian objektif data berat badan bila ada, atau
            penilaian subyektif orang tua pasien atau untuk bayi &lt; 1 tahun
            berat badan tidak naik selama 3 bulan terakhir)
          </p>
        </div>
        <div class="d-flex justify-center">
          <v-radio-group
            :disabled="isEmr"
            dense
            hide-details
            row
            v-model="isLossWeight"
          >
            <v-radio
              label="Tidak"
              :value="{ check: false, score: 0 }"
            ></v-radio>
            <v-radio label="Ya" :value="{ check: true, score: 1 }"></v-radio>
          </v-radio-group>
        </div>
        <div class="text-start">
          <label>
            Apakah Terdapat Salah Satu dari Kondisi Tersebut?
          </label>
          <p>
            (diare >=5 per hari dan muntah >3 kali per hari dalam seminggu
            terakhir atau asupan makanan berkurang selama 1 minggu terakhir)
          </p>
        </div>
        <div class="d-flex justify-center">
          <v-radio-group
            :disabled="isEmr"
            dense
            hide-details
            row
            v-model="isHasCondition"
          >
            <v-radio
              label="Tidak"
              :value="{ check: false, score: 0 }"
            ></v-radio>
            <v-radio label="Ya" :value="{ check: true, score: 1 }"></v-radio>
          </v-radio-group>
        </div>
        <label>
          Apakah Terdapat Penyakit atau Keadaan yang Mengakibatkan Pasien
          Berisiko Mengalami Malnutrisi?
        </label>
        <div class="d-flex justify-center">
          <v-radio-group
            :disabled="isEmr"
            dense
            hide-details
            row
            v-model="isMalnutritionRisk"
          >
            <v-radio
              label="Tidak"
              :value="{ check: false, score: 0 }"
            ></v-radio>
            <v-radio label="Ya" :value="{ check: true, score: 2 }"></v-radio>
          </v-radio-group>
        </div>
      </v-col>
      <div class="d-flex justify-start align-center px-8">
        <h4 class="font-weight-bold primary--text mr-7">
          Total Skor : {{ nutritionScreeningResult?.score }}
        </h4>
        <h4 class="font-weight-bold primary--text mr-4">Hasil :</h4>
        <div :class="`chip-${nutritionScreeningResult?.color}`">
          {{ nutritionScreeningResult?.text }}
        </div>
      </div>
    </v-row>
    <v-row v-else class="mb-3">
      <v-col cols="11" class="grid-nutrition adult">
        <label>
          Apakah Pasien Mengalami Penurunan Berat badan yang Tidak Direncanakan?
        </label>
        <div>
          <div class="d-flex justify-end">
            <v-radio-group
              dense
              :disabled="isEmr"
              hide-details
              row
              v-model="isUnplannedWeightLoss"
            >
              <v-radio
                label="Tidak"
                :value="{ check: 'no', score: 0 }"
              ></v-radio>
              <v-radio
                label="Tidak Yakin"
                :value="{ check: 'notSure', score: 2 }"
              ></v-radio>
              <v-radio label="Ya" :value="true"></v-radio>
            </v-radio-group>
          </div>
          <div class="d-flex justify-end">
            <v-radio-group
              :disabled="isUnplannedWeightLoss !== true || isEmr"
              dense
              hide-details
              row
              v-model="isUnplannedWeightLossAmount"
            >
              <v-radio
                label="1-5 kg"
                :value="{ check: 'oneToFive', score: 1 }"
              ></v-radio>
              <v-radio
                label="6-10 kg"
                :value="{ check: 'sixToTen', score: 2 }"
              ></v-radio>
              <v-radio
                label="11-15 kg"
                :value="{ check: 'elevenToFifteen', score: 3 }"
              ></v-radio>
              <v-radio
                label=">15 kg"
                :value="{ check: 'aboveFifteen', score: 4 }"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
        <div class="text-start">
          <label>
            Apakah Asupan Makanan Pasien Buruk Akibat Nafsu Makan yang Menurun?
          </label>
          <p>
            Misalnya asupan makanana hanya 1/4 dari biasanya
          </p>
        </div>
        <div class="d-flex justify-end">
          <v-radio-group
            dense
            hide-details
            :disabled="isEmr"
            row
            v-model="isFoodIntakePoorDueToDecreasedAppetite"
          >
            <v-radio
              label="Tidak"
              :value="{ check: false, score: 0 }"
            ></v-radio>
            <v-radio label="Ya" :value="{ check: true, score: 1 }"></v-radio>
          </v-radio-group>
        </div>
        <label>
          Apakah Pasien Menderita Penyakit Berat?
        </label>
        <div class="d-flex justify-end">
          <v-radio-group
            :disabled="isEmr"
            dense
            hide-details
            row
            v-model="isHaveSeriousIllness"
          >
            <v-radio
              label="Tidak"
              :value="{ check: false, score: 0 }"
            ></v-radio>
            <v-radio label="Ya" :value="{ check: true, score: 2 }"></v-radio>
          </v-radio-group>
        </div>
      </v-col>
      <div class="d-flex justify-start align-center px-8">
        <h4 class="font-weight-bold primary--text mr-7">
          Total Skor : {{ nutritionScreeningResult?.score }}
        </h4>
        <h4 class="font-weight-bold primary--text mr-4">Hasil :</h4>
        <div :class="`chip-${nutritionScreeningResult?.color}`">
          {{ nutritionScreeningResult?.text }}
        </div>
      </div>
    </v-row>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'NutritionScreening',
);

export default {
  name: 'NutritionScreening',
  props: {
    isAdult: Boolean,
  },
  computed: {
    ...mapIGDAssessmentGetters(['getIsEmr']),
    ...mapGetters([
      'getIsThin',
      'getIsLossWeight',
      'getIsHasCondition',
      'getIsMalnutritionRisk',
      'getIsUnplannedWeightLoss',
      'getIsUnplannedWeightLossAmount',
      'getIsFoodIntakePoorDueToDecreasedAppetite',
      'getIsHaveSeriousIllness',
    ]),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    isThin: {
      get() {
        return this.getIsThin;
      },
      set(value) {
        this.setIsThin(value);
      },
    },
    isLossWeight: {
      get() {
        return this.getIsLossWeight;
      },
      set(value) {
        this.setIsLossWeight(value);
      },
    },
    isHasCondition: {
      get() {
        return this.getIsHasCondition;
      },
      set(value) {
        this.setIsHasCondition(value);
      },
    },
    isMalnutritionRisk: {
      get() {
        return this.getIsMalnutritionRisk;
      },
      set(value) {
        this.setIsMalnutritionRisk(value);
      },
    },
    isUnplannedWeightLoss: {
      get() {
        return this.getIsUnplannedWeightLoss;
      },
      set(value) {
        this.setIsUnplannedWeightLoss(value);
      },
    },
    isUnplannedWeightLossAmount: {
      get() {
        return this.getIsUnplannedWeightLossAmount;
      },
      set(value) {
        this.setIsUnplannedWeightLossAmount(value);
      },
    },
    isFoodIntakePoorDueToDecreasedAppetite: {
      get() {
        return this.getIsFoodIntakePoorDueToDecreasedAppetite;
      },
      set(value) {
        this.setIsFoodIntakePoorDueToDecreasedAppetite(value);
      },
    },
    isHaveSeriousIllness: {
      get() {
        return this.getIsHaveSeriousIllness;
      },
      set(value) {
        this.setIsHaveSeriousIllness(value);
      },
    },
    nutritionScreeningResult() {
      let score, result;
      if (this.isAdult) {
        if (this.isUnplannedWeightLoss !== true) {
          score =
            (this.isUnplannedWeightLoss?.score || 0) +
            (this.isFoodIntakePoorDueToDecreasedAppetite?.score || 0) +
            (this.isHaveSeriousIllness?.score || 0);
        } else {
          score =
            (this.isUnplannedWeightLossAmount?.score || 0) +
            (this.isFoodIntakePoorDueToDecreasedAppetite?.score || 0) +
            (this.isHaveSeriousIllness?.score || 0);
        }
        if (isNaN(score)) {
          result = null;
        }
        if (score <= 1) {
          result = {
            text: 'Tidak Berisiko Malnutrisi',
            color: 'green',
            score,
          };
        }
        if (score === 2) {
          result = {
            text: 'Risiko Malnutrisi',
            color: 'yellow',
            score,
          };
        }
        if (score >= 3) {
          result = {
            text: 'Malnutrisi',
            color: 'red',
            score,
          };
        }
      } else {
        score =
          (this.isThin?.score || 0) +
          (this.isLossWeight?.score || 0) +
          (this.isHasCondition?.score || 0) +
          (this.isMalnutritionRisk?.score || 0);

        if (isNaN(score)) {
          result = null;
        }
        if (score === 0) {
          result = {
            text: 'Berisiko Rendah',
            color: 'green',
            score,
          };
        }
        if (score >= 1 && score <= 3) {
          result = {
            text: 'Berisiko Sedang',
            color: 'yellow',
            score,
          };
        }
        if (score >= 4 && score <= 5) {
          result = {
            text: 'Berisiko Tinggi',
            color: 'red',
            score,
          };
        }
      }
      return result;
    },
  },
  methods: {
    ...mapMutations([
      'clearNutritionScreeningState',
      'setIsThin',
      'setIsLossWeight',
      'setIsHasCondition',
      'setIsMalnutritionRisk',
      'setIsUnplannedWeightLoss',
      'setIsUnplannedWeightLossAmount',
      'setIsFoodIntakePoorDueToDecreasedAppetite',
      'setIsHaveSeriousIllness',
    ]),
  },
  beforeDestroy() {
    this.clearNutritionScreeningState();
  },
};
</script>

<style lang="scss" scoped>
$standard-font-size: 0.9vw;

@mixin result-chip($fill, $outline) {
  background-color: $fill;
  border: solid $outline 1.5px;
  border-radius: 6px;
  color: $outline;
  font-weight: 450;
  padding: 0.3rem 0.5rem;
  width: max-content;
  height: max-content;
  font-size: calc($standard-font-size - 0.1vw);
}

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.grid-nutrition {
  @include grid-display(80% 20%, true);

  &.adult {
    @include grid-display(60% 40%, true);
  }
  p {
    font-weight: 300;
    text-align: start;
    margin-left: 1vw;
    font-size: 0.9vw;
    color: #757575;
    margin-top: 0.4em;
  }
}

.chip-green {
  @include result-chip(#e5fff0, #2d965a);
}
.chip-yellow {
  @include result-chip(#fff9e5, #e5a82e);
}
.chip-red {
  @include result-chip(#fff2f2, #eb4747);
}
.chip-grey {
  @include result-chip(#f6f6f6, #928f8f);
}

.title {
  font-size: 1.4rem;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
