import Constant from '../../const';
const axios = require('axios');
const moment = require('moment-timezone');

const getPublicQueue = async () => {
  try {
    const response = await axios.post(Constant.apiUrl.concat('/queue/public'), {
      date: moment().format('YYYY-MM-DD'),
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getPublicQueue;
