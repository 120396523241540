import Constant from '@/const';
import axios from 'axios';

const getGeneralConsent = async (id_inpatient, id_emr) => {
  const url = id_inpatient
    ? `/document/general-consent/inpatient/${id_inpatient}`
    : `/document/general-consent/${id_emr}`;
  try {
    const response = await axios.get(Constant.apiUrl.concat(url));
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getGeneralConsent;
