import Constant from '@/const';
import axios from 'axios';

const getPatientRegistration = async ({
  page = 1,
  itemCount = 10,
  name = '',
  address = '',
  sort = 'asc',
} = {}) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/patient/emr/all?page=${page}&itemCount=${itemCount}&search=${name}&address=${address}&sort=${sort}`,
      ),
    );
    return { data: response.data.data, length: response.data.length };
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getPatientRegistration;
