<template>
  <div class="main-procedure">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Data Prosedur
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            color="grey"
            icon
            text
            @click="refreshAndAutoUpdate"
          >
            <v-icon>mdi-autorenew </v-icon>
          </v-btn>
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            v-model="search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="table.headers"
              :items="items.dataProcedure.data"
              :search="table.search"
              :expanded="expanded"
              :loading="items.dataProcedure.isLoading"
              single-expand
              item-key="id"
              class="expand"
              :items-per-page="itemsPerRow"
              hide-default-footer
              @click:row="
                (item, slot) => {
                  slot.expand(!slot.isExpanded);
                }
              "
            >
              <template v-slot:expanded-item="{ item, headers }">
                <td :colspan="headers.length" class="expand">
                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                @click="handleOpenDialog('detail', item)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-clipboard-text</v-icon>
                              </v-btn>
                            </template>
                            <span>Detail Prosedur</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="handleOpenDialog('edit', item)"
                              >
                                <v-icon>mdi-clipboard-plus</v-icon>
                              </v-btn>
                            </template>

                            <span>Ubah Prosedur</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="handleDelete(item)"
                              >
                                <v-icon>mdi-close-thick</v-icon>
                              </v-btn>
                            </template>

                            <span>Hapus Prosedur</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-spacer></v-spacer>

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="procedurePages"
            total-visible="10"
            @input="handleGetAllProcedure()"
          ></v-pagination>
          <v-spacer></v-spacer>

          <v-btn color="primary" fab dark @click="handleOpenDialog('add')">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <template>
            <v-dialog v-model="dialog" :max-width="800">
              <FormProcedure
                v-if="dialog"
                :state="state"
                @close-dialog="handleCloseDialog"
              />
            </v-dialog>
          </template>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import FormProcedure from './components/Procedure/FormProcedure.vue';
import jwtMixin from '@/mixin/jwtMixin';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'MasterProcedure',
);

const _ = require('lodash');

export default {
  name: 'MasterdataProcedure',
  mixins: [jwtMixin],
  components: {
    FormProcedure,
  },
  data() {
    return {
      state: 'add',
      expanded: [],
      procedurePages: 0,
      table: {
        headers: [
          { text: 'Kode', value: 'code' },
          { text: 'Deskripsi', value: 'description' },
        ],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: 'kode',
        },
      },
      search: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
  },
  computed: {
    ...mapGetters(['getItems', 'getDialog']),

    items: {
      get() {
        return this.getItems;
      },
    },

    dialog: {
      get() {
        return this.getDialog;
      },
      set(val) {
        return this.setDialog(val);
      },
    },

    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    ...mapMutations(['setDialog', 'setForm', 'clearForm', 'setFilter']),
    ...mapActions([
      'resolveGetAllProcedure',
      'resolveDeleteProcedure',
      'resolvePutStatusProcedure',
    ]),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.handleGetAllProcedure();
    }, 650),

    handleOpenDialog(state, data) {
      this.state = state;
      state !== 'add' && this.setForm({ id: data.id });
      this.setDialog(true);
    },

    handleCloseDialog() {
      this.clearForm();
      this.setDialog(false);
    },

    handleDelete(data) {
      Swal.fire({
        title: 'Yakin?',
        text: `Anda akan menghapus Data Kode ${data.code}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result.value) {
          this.resolveDeleteProcedure(data.id).then(res => {
            if (res.status === 200) {
              Swal.fire('Berhasil', `${res.data.pesan}`, 'success');
              this.handleGetAllProcedure();
            } else {
              Swal.fire(
                `${data.name} gagal dihapus`,
                res?.response?.data?.pesan,
                'error',
              );
            }
          });
        }
      });
    },

    handleGetAllProcedure() {
      const itemCount = this.itemsPerRow;
      const halaman = this.table.pagination.page;
      let sortBy = '';
      const keyword = this.search;
      this.setFilter({
        halaman,
        itemCount,
        sortBy,
        keyword,
      });

      this.resolveGetAllProcedure({
        halaman,
        itemCount,
        sortBy,
        keyword,
      }).then(item => {
        this.procedurePages = Math.ceil(item / itemCount);
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.handleGetAllProcedure();
      this.interval = setInterval(this.handleGetAllProcedure, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 30%;
}

.hover:hover {
  color: #2196f3;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
