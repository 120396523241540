<template>
  <v-card>
    <v-card-title class="justify-center">
      <h4>Konfirmasi Pengiriman Barang Gudang</h4>
      <v-btn absolute right small icon @click="$emit('close-dialog')">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-container fluid>
      <v-card height="360" flat class="pa-3 mx-5 text-left" v-if="!isWarehouse">
        <div class="d-flex justify-space-between align-center mb-3">
          <label class="font-weight-medium  blue-text">Ringkasan Barang</label>
          <v-spacer></v-spacer>
          <v-btn outlined class="text-none py-4" color="primary">
            <v-icon class="mr-1">
              mdi-printer
            </v-icon>
            Print
          </v-btn>
        </div>
        <v-data-table
          :headers="table.header"
          :items="dataRequest"
          item-key="no"
          hide-default-footer
        >
        </v-data-table>
      </v-card>
    </v-container>
    <v-card-actions>
      <v-btn
        color="primary"
        class="text-none"
        text
        @click="$emit('close-dialog')"
        >Kembali</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn color="primary" depressed class="text-none" @click="confirmData()"
        >Konfirmasi</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
const axios = require('axios');
import AlertMixin from '@/mixin/alertMixin';
import moment from 'moment-timezone';
import Constant from '@/const';
import { createNamespacedHelpers } from 'vuex';
const { mapMutations } = createNamespacedHelpers('StorageRequestStore');

export default {
  name: 'DialogDetail',
  mixins: [AlertMixin],
  props: {
    dataRequest: {
      type: Array,
    },
    selectedItem: {
      type: Object,
    },
  },
  data() {
    return {
      table: {
        header: [
          { text: 'No', value: 'no', sortable: false },
          { text: 'Nama Barang', value: 'displayName', sortable: false },
          { text: 'Jenis Sediaan', value: 'strength_unit', sortable: false },
          { text: 'Nama Pabrik', value: 'factory', sortable: false },
          {
            text: 'Jumlah Diberikan',
            value: 'given_quantity',
            sortable: false,
          },
          {
            text: 'Stok Akhir Gudang',
            value: 'warehouseRemain',
            sortable: false,
          },
        ],
      },
    };
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    confirmData() {
      const payload = {
        id_clinic: this.selectedItem.from.id,
        id_warehouse: this.selectedItem.to.id,
        confirmation_date: moment().format(),
        status: this.selectedItem.status,
        request: this.dataRequest.map(item => {
          return {
            name: item.name,
            amount: +item.quantity,
            amount_given: +item.given_quantity,
            display_name: item.displayName,
            strength: item.strength,
            strength_unit: item.strength_unit,
            preparation: item.preparation,
            status: this.selectedItem.status ? 'given' : 'rejected',
            stock: item.listStock.map(i => {
              return {
                id_stock: i.id_stock,
              };
            }),
            fulfillment: item.listStock
              .filter(v => v.check)
              .map(i => {
                return {
                  id_stock: i.id_stock,
                  id_goods: i.id_goods,
                  id_drugs: i.id_drugs,
                  amount: +i.amountGiven,
                };
              }),
          };
        }),
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.id,
        },
      };
      axios
        .put(
          Constant.apiUrl.concat(`/stock/request/${this.selectedItem.id}`),
          payload,
        )
        .then(response => {
          if (response) {
            this.setSnackbar({
              show: true,
              color: 'success',
              text: 'Barang berhasil dikirim',
            });
            this.$emit('close-confirm');
          }
        })
        .catch(() => {
          this.setSnackbar({
            show: true,
            color: 'error',
            text:
              'Terjadi kesalahan, gagal memperbaharui permintaan barang gudang',
          });
          this.$emit('close-confirm');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.blue-text {
  color: #137bc0;
}
.request-detail {
  display: grid;
  grid-template-columns: 10% 25% 15% 20% 15% 15%;
  &.header {
    grid-template-rows: 5vh;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    align-items: center;
  }
}
::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }
  .v-data-table-header {
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
