import Constant from '@/const';
import axios from 'axios';

const getAllService = async (keyword, guarantorType) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/master/service?page=1&itemCount=10&sort=&search=${keyword ||
          ''}&category=${guarantorType}`,
      ),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getAllService;
