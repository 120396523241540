<template>
  <v-card elevation="0" class="px-11" style="overflow-x: hidden;">
    <v-container fluid>
      <div class="d-flex justify-space-between" v-if="isIGD">
        <h2 class="mb-4 text-start pl-2">
          Catatan Pemberian Obat
        </h2>
        <v-btn fab text @click="closeForm"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-row>
        <v-col cols="8">
          <v-row class="header-grid">
            <span class="font-weight-bold">No RM</span>
            <span class="black--text mx-6">{{ patientData.rmNumber }}</span>
            <span class=" font-weight-bold">Nama</span>
            <span class="black--text mx-6"
              >{{ patientData.meta.name }} ({{
                patientData.meta.gender ? 'L' : 'P'
              }})
            </span>
            <span class=" font-weight-bold">Tanggal Lahir</span>
            <span class="black--text mx-6">
              {{ patientData.meta.birthDate }} ({{ patientData.meta.age }}
              tahun)
            </span>
            <span class="font-weight-bold">DPJP</span>
            <span class="black--text  mx-6">{{ patientData.meta.doctor }}</span>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row class="mb-4">
            <span class="text-left font-weight-bold body-2"
              >Riwayat Alergi
            </span>
          </v-row>
          <v-row class="mt-n3">
            <span
              class="red--text font-weight-bold body-2"
              v-if="allergy?.length === 0"
              >Tidak ada alergi
            </span>
            <v-chip
              v-for="(allergy, idx) in allergy"
              :key="idx"
              color="#EB4747"
              class="allergy mr-2 mb-2"
              label
              small
              caption
              >{{ allergy }}
            </v-chip>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="px-0 mx-0">
      <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
      <div v-else style="height: 72vh;">
        <div class="main-wrapper">
          <div class="empty-alert grey--text" v-if="!table.items.length">
            Belum Ada Data Catatan Pemberian Obat
          </div>
          <div>
            <div class="header-data">
              <span>No</span>
              <span>Lanjut Obat</span>
              <span>Nama Obat</span>
              <span>Aturan Pakai</span>
              <span>Rute</span>
              <span>Diberikan di</span>
            </div>
            <div class="data-list" @scroll="onScrollY" id="drug-table">
              <v-expansion-panels accordion flat light tile>
                <v-expansion-panel
                  v-for="(item, index) in table.items"
                  :key="'drug' + index"
                  @click="onClick(item, index)"
                >
                  <v-expansion-panel-header
                    expand-icon=""
                    :class="{ disable: !item.isContinued }"
                    :color="item.isContinued ? '' : '#F4F5F5'"
                    class="grid-list"
                  >
                    <span>{{ item.no }}</span>
                    <v-switch
                      inset
                      dense
                      flat
                      light
                      @click="confirmOff(item)"
                      v-model="item.isContinued"
                    ></v-switch>
                    <div>
                      <p class="ma-0 pa-0">
                        {{ item.nonracik.drugName || item.racik.drugName }}
                      </p>
                      <p class="ma-0 pa-0 mt-1 grey--text">
                        {{
                          item.nonracik.id_drugs
                            ? (item.nonracik.unit_dosage
                                ? item.nonracik.dosage
                                : '') +
                              ' ' +
                              (item.nonracik.unit_dosage || '')
                            : ''
                        }}
                      </p>
                    </div>
                    <span class="text-left"
                      >{{ item.racik.usageRule || item.nonracik.usageRule }}
                    </span>
                    <div>
                      <p class="pa-0 ma-0 text-left">
                        {{ item.racik.route || item.nonracik.route }}
                      </p>
                      <p
                        v-if="item.isExtra"
                        class="pa-0 ma-0 mt-1"
                        style="color:  #e5a82e;"
                      >
                        Extra
                      </p>
                    </div>
                    <span class="text-left pr-2 ">
                      {{
                        item.racik.given?.toUpperCase() ||
                          item.nonracik.given?.toUpperCase()
                      }}
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="py-3" color="#F4F5F5">
                    <div class="expand-content">
                      <span></span>
                      <span
                        class="grey--text font-weight-medium caption text-left d-flex align-center"
                        >Diresepkan oleh
                        {{ item.racik.staff || item.nonracik.staff }}
                      </span>
                      <v-btn
                        color="primary"
                        class="caption font-weight-medium"
                        outlined
                        :disabled="
                          item.history.length > 0 ||
                            item.time_delivery.length > 0
                        "
                        v-if="isDoctor"
                        @click="isDoctor ? selectItem(3, item) : ''"
                      >
                        <v-icon small>mdi-square-edit-outline</v-icon>
                        <span class="mx-1">Edit Obat</span>
                        <v-icon small>mdi-arrow-right </v-icon>
                      </v-btn>
                      <v-btn
                        color="red"
                        outlined
                        v-if="isDoctor"
                        :disabled="
                          item.history.length > 0 ||
                            item.time_delivery.length > 0
                        "
                        class="caption font-weight-medium"
                        @click="isDoctor ? deleteItem(item, index) : ''"
                      >
                        <v-icon small>mdi-delete</v-icon>
                        <span class="mx-1">Hapus Obat</span>
                        <v-icon small>mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div
                v-if="!isExpand && table.items.length"
                style="height: 4.5vh;"
              ></div>
            </div>
          </div>
          <div
            class="time-header-container"
            id="time-header-id"
            @scroll="onScrollX"
          >
            <table :class="`table-header ${!headerDate.length ? 'empty' : ''}`">
              <div class="empty-text" v-if="!headerDate.length">
                Belum Ada Waktu Pemberian Obat
              </div>
              <thead v-else>
                <tr>
                  <th
                    style="min-width: 5.25vw;"
                    v-for="header in headerDate"
                    :key="header.date"
                    :colspan="header.time.length"
                  >
                    {{ header.date }}
                  </th>
                </tr>
                <tr>
                  <template v-for="header in headerDate">
                    <th v-for="(time, index) in header.time" :key="index">
                      {{ time.time }}
                    </th>
                  </template>
                </tr>
              </thead>
            </table>
            <div class="input-time-grid" @scroll="onScrollY" id="time-table">
              <div
                v-for="(props, idx) in table.items"
                :key="idx"
                class="row-input-time"
              >
                <template v-for="(item, index) in headerDate">
                  <div
                    :class="
                      `cell ${
                        headerDate[index]?.time?.length === 1 ? 'single' : ''
                      }`
                    "
                    v-for="(time, timeIndex) in item.time"
                    :key="'date' + index + 'time-' + timeIndex"
                  >
                    <div class="icon-wrapper">
                      <v-icon
                        medium
                        :color="setIcon(props, item.date, time.time).color"
                      >
                        {{ setIcon(props, item.date, time.time).icon }}
                      </v-icon>
                      <div
                        :class="setIcon(props, item.date, time.time).class"
                      ></div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="d-flex">
                <template v-for="(item, index) in headerDate">
                  <div
                    v-for="(_, timeIndex) in item.time"
                    :key="'masking' + index + 'time-' + timeIndex"
                    :class="
                      `masking ${isExpand ? 'expand-masking' : ''} ${
                        headerDate[index]?.time?.length === 1 ? 'single' : ''
                      }`
                    "
                  ></div
                ></template>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-table-wrapper">
          <div class="data-footer" v-if="table.items.length">
            <span>Nama Petugas</span>
          </div>
          <div
            class="time-footer-wrapper"
            id="time-footer-id"
            @scroll="onScrollX"
          >
            <div class="time-footer">
              <template v-for="(item, index) in headerDate">
                <div
                  :class="
                    `time-cell ${
                      headerDate[index]?.time?.length === 1 ? 'single' : ''
                    }`
                  "
                  v-for="(time, timeIndex) in item.time"
                  :key="'date' + index + 'time-' + timeIndex"
                >
                  <div class="d-flex flex-column justify-center align-center">
                    <v-chip
                      small
                      color="#B2FFD3"
                      text-color="#219653"
                      label
                      class="initial-chip"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ getNameInitial(time.created_by) }}
                          </span>
                        </template>
                        <span>{{ time.created_by }}</span>
                      </v-tooltip>
                    </v-chip>
                    <v-chip
                      small
                      color="#fff9e6"
                      text-color="#e5a82e"
                      label
                      v-if="time.verified_by"
                      class="mt-1 initial-chip"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ getNameInitial(time.verified_by) }}
                          </span>
                        </template>
                        <span>{{ time.verified_by }}</span>
                      </v-tooltip>
                    </v-chip>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </v-container>
    <v-footer color="white">
      <skeleton :isLoading="loading">
        <div class="description">
          <v-icon class="mr-1" small color="success">mdi-check-circle</v-icon>
          <span class="font-weight-medium mr-3 caption"
            >Lanjut dan berikan</span
          >
          <v-icon class="mr-1" small color="error">mdi-close-circle</v-icon>
          <span class="font-weight-medium mr-3 caption"
            >Hentikan pemberian</span
          >
          <v-icon class="mr-1" small>mdi-clock-time-four-outline</v-icon>
          <span class="font-weight-medium caption"
            >Lanjut dan belum diberi</span
          >
        </div>
      </skeleton>
      <v-spacer></v-spacer>
      <v-btn
        class="px-2 ml-6 action-btn"
        outlined
        :loading="loading"
        v-if="isDoctor || isNurse"
        color="primary"
        @click="selectItem(2, null)"
      >
        <span class="font-weight-bold">{{
          isDoctor ? 'ORDER OBAT FARMASI' : 'INPUT WAKTU PEMBERIAN'
        }}</span>
      </v-btn>
      <v-btn
        class="px-2 ml-6 action-btn"
        outlined
        :loading="loading"
        color="primary"
        v-if="isDoctor || isNurse"
        @click="selectItem(0, null)"
      >
        <span class="font-weight-bold">INPUT ALKES DAN BHP</span>
      </v-btn>
      <v-btn
        v-if="isDoctor || isNurse || isPharmacy"
        class="px-2 ml-6 action-btn"
        :loading="loading"
        depressed
        color="primary"
        @click="selectItem(1, null)"
      >
        <span class="font-weight-bold">{{
          isDoctor ? 'TAMBAH OBAT' : 'TELAAH OBAT'
        }}</span>
      </v-btn>
    </v-footer>
    <v-dialog v-model="dialog" width="1400" persistent>
      <BHPOrder
        v-if="selectedForm === 0 && dialog"
        :allergy="allergy"
        :idEmr="idEmr"
        :patientStatus="patientStatus"
        :patient-data="patientData"
        @close-dialog="dialog = false"
      />
      <DrugInput
        v-else-if="selectedForm === 1 && dialog && isDoctor"
        :patient-data="patientData"
        :allergy="allergy"
        @close-dialog="closeDialog()"
      />
      <DrugReviewHistory
        v-else-if="selectedForm === 1 && dialog && isNurse"
        :patient-data="patientData"
        :allergy="allergy"
        :idDrugAdministration="idDrugAdministration"
        @open-detail="openDetailRecipe()"
        @close-dialog="closeDialog()"
      />
      <PharmacyOrder
        v-else-if="selectedForm === 2 && dialog && isDoctor"
        :patient-data="patientData"
        :allergy="allergy"
        :drugs="orderPharmacy"
        :idEmr="idEmr"
        :idStaffMixRecipe="idStaffMixRecipe"
        :idStaffRecipe="idStaffRecipe"
        :patientStatus="patientStatus"
        @close-dialog="closeDialog()"
      />
      <InputDrugSchedule
        v-else-if="selectedForm === 2 && dialog && isNurse"
        :patient-data="patientData"
        :idRegistration="idRegistration"
        :patientStatus="patientStatus"
        :idDrugAdministration="idDrugAdministration"
        @close-dialog="closeDialog()"
      />
      <DrugEdit
        v-else-if="selectedForm === 3 && dialog"
        :patient-data="patientData"
        :drug-item="drugItem"
        :idDrugAdministration="idDrugAdministration"
        :allergy="allergy"
        @close-dialog="closeDialog()"
      />
      <DetailRecipe
        v-else-if="selectedForm === 5 && dialog && isNurse"
        :patient-data="patientData"
        :idDrugAdministration="idDrugAdministration"
        @close-dialog="closeDialog()"
        :allergy="allergy"
        :idEmr="idEmr"
        @back="back()"
      />
    </v-dialog>
    <v-snackbar
      timeout="4000"
      content-class="d-flex justify-space-between white--text align-center"
      elevation="0"
      small
      light
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn fab small text @click.native="() => (snackbar.show = false)"
        ><v-icon color="white" small>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
  </v-card>
</template>

<script>
import PharmacyOrder from './PharmacyOrder';
import DrugInput from './DrugInput';
import DrugReviewHistory from './DrugReviewHistory';
import InputDrugSchedule from './InputDrugSchedule';
import DrugEdit from './DrugEdit';
import DetailRecipe from './DetailRecipe';
import BHPOrder from './BHPOrder';
import Constant from '@/const';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import alertMixin from '@/mixin/alertMixin';
import Skeleton from '../../../SharedComponent/Skeleton';

const axios = require('axios');

export default {
  name: 'DrugRecord',
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    isIGD: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [alertMixin],
  components: {
    PharmacyOrder,
    InputDrugSchedule,
    DrugReviewHistory,
    DrugInput,
    DrugEdit,
    DetailRecipe,
    BHPOrder,
    Skeleton,
  },
  data() {
    return {
      isExpand: false,
      selectedIndex: null,
      sheet: false,
      search: '',
      menu: false,
      scrollTop: 0,
      menu2: false,
      previousIndex: 0,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      table: {
        headers: [
          {
            text: 'No',
            value: 'no',
            width: '5%',
            fixed: true,
          },
          {
            text: 'Jenis Obat',
            value: 'drugType',
            sortable: false,
            width: '10%',
            fixed: true,
          },
          {
            text: 'Nama Obat',
            value: 'drugName',
            sortable: false,
            width: '15%',
            fixed: true,
          },
          {
            text: 'Dosis/Aturan Pakai',
            value: 'dose',
            sortable: false,
            width: '10%',
            fixed: true,
          },
          {
            text: 'Waktu',
            value: 'time',
            sortable: false,
          },
        ],
        items: [],
        tableHardCode: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
          sortBy: '',
        },
      },
      headerDate: [],
      timetableWidth: 20,
      selectedForm: null,
      dialog: false,
      loading: false,
      idEmr: '',
      idDrugAdministration: '',
      idRegistration: '',
      idStaffMixRecipe: '',
      idStaffRecipe: '',
      patientStatus: '',
      expanded: [],
      tableInput: {
        headers: [
          { text: '', value: 'action' },
          { text: 'R/', value: 'no' },
          { text: 'Nama Obat', value: 'name' },
          { text: 'Jenis Sediaan', value: 'preparation' },
          { text: 'Dosis', value: 'dosage' },
          { text: 'Rute', value: 'route' },
          { text: 'Aturan Pakai', value: 'usage' },
          { text: 'Keterangan', value: 'note' },
        ],
        items: [],
      },
      drugItem: null,
      allergy: [],
      schedule: [],
      drugSchedule: [],
      inputSchedule: {
        drugSchedule: '',
        dateDrug: moment(new Date()).format('YYYY-MM-DD'),
        time: moment(new Date()).format('HH:mm'),
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshTable();
    });
  },
  computed: {
    poly() {
      let result;
      const routeArray = this.$route.path.split('/');
      switch (routeArray[routeArray.length - 1]) {
        case 'klinik-igd':
          result = 'igd';
          break;
        case 'rawat-inap':
          result = 'rawat_inap';
          break;
      }
      return result;
    },
    orderPharmacy() {
      return this.table.items.filter(x => x.isContinued);
    },
    isDoctor() {
      return (
        this.$store.getters.userLoggedIn.role.some(
          item => item.toLowerCase() === 'dokter',
        ) ||
        this.$store.getters.userLoggedIn.role.some(
          item => item.toLowerCase() === 'fisioterapis',
        )
      );
    },
    isNurse() {
      return this.$store.getters.userLoggedIn.role.some(
        item => item.toLowerCase() === 'perawat',
      );
    },
    isPharmacy() {
      return this.$store.getters.userLoggedIn.role.some(
        item => item.toLowerCase() === 'farmasi',
      );
    },
  },
  methods: {
    scrolling() {
      const time = this.$refs.time;
      const header = this.$refs.header;
      const footer = this.$refs.footer;

      footer.scrollLeft = time.scrollLeft;
      header.scrollLeft = time.scrollLeft;
    },
    scrollingFooter() {
      const time = this.$refs.time;
      const header = this.$refs.header;
      const footer = this.$refs.footer;

      time.scrollLeft = footer.scrollLeft;
      header.scrollLeft = footer.scrollLeft;
    },
    scrollingHeader() {
      const time = this.$refs.time;
      const header = this.$refs.header;
      const footer = this.$refs.footer;

      time.scrollLeft = header.scrollLeft;
      footer.scrollLeft = header.scrollLeft;
    },
    getNameInitial(name) {
      return name
        ? name
            .split(' ')
            .splice(0, 3)
            .map(s => s[0])
            .join('')
            .toUpperCase()
        : '';
    },
    setIcon(log, date, time) {
      const entry = log.history.filter(
        arr => arr.date === date && arr.time === time,
      );
      if (entry.length > 0) {
        if (
          entry.findIndex(arr => arr.drug_status === 'given') !== -1 &&
          log.nonracik?.isHighAlert
        )
          return { icon: 'mdi-check-circle', color: '#219653', class: 'high' };
        if (entry.findIndex(arr => arr.drug_status === 'given') !== -1)
          return { icon: 'mdi-check-circle', color: '#219653', class: '' };
        if (entry.findIndex(arr => arr.drug_status === 'discontinued') !== -1)
          return { icon: 'mdi-close-circle', color: '#EB4747', class: '' };
        if (
          entry.findIndex(
            arr =>
              arr.drug_status === 'pending' || arr.drug_status === 'postponed',
          ) !== -1
        )
          return { icon: 'mdi-clock-time-four-outline', color: '' };
        return { icon: '', color: '' };
      }
      return false;
    },

    onScrollY(e) {
      const drugsList = document.getElementById('drug-table');
      const timeList = document.getElementById('time-table');
      drugsList.scrollTop = e.target.scrollTop;
      timeList.scrollTop = e.target.scrollTop;
    },
    onScrollX(e) {
      const timeList = document.getElementById('time-header-id');
      const staffList = document.getElementById('time-footer-id');
      staffList.scrollLeft = e.target.scrollLeft;
      timeList.scrollLeft = e.target.scrollLeft;
    },
    onClick(item, index) {
      if (this.selectedIndex === null) {
        this.isExpand = true;
      } else if (this.selectedIndex === index) {
        this.isExpand = !this.isExpand;
      } else {
        this.isExpand = true;
      }
      this.selectedIndex = index;
      this.selectedData = item;
      this.isNew = false;
      this.table.items[this.previousIndex] = {
        ...this.table.items[this.previousIndex],
        isActive: false,
      };
      this.table.items[index] = {
        ...this.table.items[index],
        isActive: true,
      };
      this.previousIndex = index;
    },
    deleteItem(drug, index) {
      Swal.fire({
        title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Yakin Ingin Menghapus Obat ${drug.nama}</p></div>`,
        iconHtml: '<span class="mdi mdi-help-circle-outline"></span>',
        html:
          '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Jika Anda hapus, obat akan hilang dari list</p><p>Catatan Pemberian Obat</p></div>',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'button-confirm',
          cancelButton: 'button-error-blue',
        },
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.isConfirmed) {
          if (this.previousIndex === index) {
            this.previousIndex = 0;
          }
          axios
            .put(
              Constant.apiUrl.concat(
                `/inpatient/drop/${this.idDrugAdministration}/${drug._id}`,
              ),
            )
            .then(() => {
              this.snackbar = {
                show: true,
                text: 'Obat berhasil dihapus',
                color: '#31b057',
              };
              this.refreshTable();
            })
            .catch(() => {
              this.snackbar = {
                show: true,
                text: 'Terjadi kesalahan, obat gagal dihapus',
                color: 'error',
              };
            });
        }
      });
    },
    confirmOff(arr) {
      if (!arr.isContinued) {
        Swal.fire({
          title: 'Hentikan Obat?',
          text: `Anda akan menghentikan pemberian obat ${arr.nama} Pada Pasien`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#137BC0',
          cancelButtonColor: '#EB4747',
          confirmButtonText: 'Lanjut',
          cancelButtonText: 'Batal',
        }).then(willSwitch => {
          if (willSwitch.value) {
            const payload = {
              drugs: [
                {
                  history: [],
                  is_continued: arr.isContinued,
                  is_extra_drugs: arr.isExtra,
                  _id: arr._id,

                  nonracik:
                    arr.nonracik?.type === 'nonracik'
                      ? {
                          ...arr.nonracik,
                          name: arr.nonracik.drugName,
                          description: arr.nonracik.note,
                          general_rule: arr.nonracik.generalRules,
                          requested_quantity: arr.nonracik.requestedQuantity,
                          usage: arr.nonracik.usageRule,
                        }
                      : { time_delivery: [] },
                  racik:
                    arr.racik?.type === 'racik'
                      ? {
                          ...arr.racik,
                          name: arr.racik.drugName,
                          description: arr.racik.note,
                          general_rule: arr.racik.generalRules,
                          usage: arr.racik.usageRule,
                        }
                      : {
                          drugs: [],
                          time_delivery: [],
                        },
                  timestamps: {
                    created_by: this.$store.getters.userLoggedIn.id,
                  },
                },
              ],
            };
            axios
              .put(
                Constant.apiUrl.concat(
                  `/inpatient/drugadministration/${this.idDrugAdministration}`,
                ),
                payload,
              )
              .then(response => {
                if (response) {
                  this.refreshTable();
                }
              });
          } else {
            // eslint-disable-next-line no-param-reassign
            arr.isContinued = !arr.isContinued;
          }
        });
      } else {
        const payload = {
          drugs: [
            {
              history: [],
              is_continued: arr.isContinued,
              is_extra_drugs: arr.isExtra,

              nonracik: arr.nonracik || { time_delivery: [] },
              racik: arr.racik || { drugs: [], time_delivery: [] },
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
              _id: arr._id,
            },
          ],
        };
        axios
          .put(
            Constant.apiUrl.concat(
              `/inpatient/drugadministration/${this.idDrugAdministration}`,
            ),
            payload,
          )
          .then(response => {
            if (response) {
              this.refreshTable();
            }
          });
      }
    },
    openDetailRecipe() {
      this.selectedForm = 5;
    },
    back() {
      this.selectedForm = 1;
    },
    selectItem(val, item) {
      if (item !== null) {
        this.drugItem = item;
      }
      this.selectedForm = val;
      this.dialog = true;
    },
    closeDialog() {
      this.refreshTable();
      this.dialog = false;
    },
    closeForm() {
      this.$emit('close-form');
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    refreshTable() {
      switch (this.poly) {
        case 'igd':
          this.showAllDrugsIgd();
          break;
        case 'rawat_inap':
          this.showAllDrugsInpatient();
          break;
      }
    },
    async showAllDrugsIgd() {
      this.loading = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/patient/igd/cpo/${this.patientData.id_registration}`,
          ),
        );
        const [data] = response.data.data;
        const index = data.drug_administration.length - 1;
        const dataItem = data.drug_administration[index].drugs;
        const dataAdmin = data.drug_administration[index];
        this.idDrugAdministration = data.id_drugAdministration;
        this.idStaffMixRecipe = data.mix_staff_name[index]?._id || '';
        this.idStaffRecipe = data.recipe_staff_name[index]?._id || '';
        this.patientStatus = data.patient_status;
        this.idEmr = data.id_emr;
        this.headerDate =
          dataAdmin.distinct_date?.map(arr => ({
            ...arr,
            time: arr.time.filter((t, i) => {
              return arr.time.findIndex(o => o.time === t.time) === i;
            }),
          })) || [];
        this.table.items = dataItem
          .sort((a, b) => b.is_continued - a.is_continued)
          .map((drug, index) => {
            const drugDisplayName = drug.nonracik.name
              ? drug.nonracik.name
              : drug.racik.name;

            return {
              no: index + 1,
              isContinued: drug.is_continued,
              isExtra: drug.is_extra_drugs,
              isPotentNonRacik: drug.nonracik.isPotent || false,
              isPotentRacik: drug.racik.drugs[0]?.isPotent || false,
              patient_status: data.patient_status,
              _id: drug._id,
              nama: drugDisplayName,
              time_delivery: drug.time_delivery
                ? drug.time_delivery.map(arr => ({
                    ...arr,
                    text: `${arr.date} ${arr.time}`,
                  }))
                : [],
              racik:
                drug.racik.drugs.length > 0
                  ? {
                      ...drug.racik,
                      drugName: drug.racik.name,
                      quantity: drug.racik.quantity,
                      drugType: 'Obat Racik',
                      dosage: +drug.racik.dosage,
                      dose: drug.racik.usage,
                      price: drug.racik.price,
                      note: drug.racik.description,
                      date: moment(drug.racik.date)
                        .locale('id')
                        .format('DD MMMM YYYY hh:mm [WIB]'),
                      drugs: drug.racik.drugs.map(i => {
                        return {
                          ...i,
                          dosage: +i.dosage,
                        };
                      }),
                      route: drug.racik.route,
                      packaging: drug.racik.packaging,
                      isConfirmed: drug.racik.isApproved,
                      generalRules: drug.racik.general_rule,
                      usageRule: drug.racik.usage,
                      staff: drug.timestamps.created_by,
                      time_delivery: drug.racik.time_delivery,
                      type: drug.racik.drugs.length > 0 ? 'racik' : '',
                      given: data.patient_status
                        .toLowerCase()
                        .split(' ')
                        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' '),
                    }
                  : {},
              nonracik: drug.nonracik.id_drugs
                ? {
                    ...drug.nonracik,
                    note: drug.nonracik.description,
                    drugType: 'Obat Non Racik',
                    dosage: +drug.nonracik.dosage,
                    generalRules: drug.nonracik.general_rule,
                    id_drugs: drug.nonracik.id_drugs,
                    drugName: drug.nonracik.name,
                    packaging: drug.nonracik.packaging,
                    preparation: drug.nonracik.preparation,
                    quantity: drug.nonracik.quantity,
                    requestedQuantity: drug.nonracik.requested_quantity,
                    route: drug.nonracik.route || '',
                    dose: `${drug.nonracik.dosage} ${drug.nonracik.unit_dosage}`,
                    usageRule: drug.nonracik.usage,
                    id: drug._id,
                    isHighAlert: drug.nonracik.isHighAlert,
                    staff: drug.timestamps.created_by,
                    date: moment(drug.nonracik.date)
                      .locale('id')
                      .format('DD MMMM YYYY hh:mm [WIB]'),
                    isConfirmed: drug.nonracik.isApproved,
                    unit_dosage: drug.nonracik.unit_dosage,
                    type: drug.nonracik.id_drugs ? 'nonracik' : '',
                    time_delivery: drug.nonracik.time_delivery,
                    given: data.patient_status
                      .toLowerCase()
                      .split(' ')
                      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(' '),
                  }
                : {},
              history: drug.history.map(unit => {
                return {
                  ...unit,
                };
              }),
              created_at: drug.timestamps.created_at,
            };
          });

        this.allergy = data.social[0].allergy;
      } catch (error) {
        this.showErrorAxios(error);
        if (error) {
          this.table.items = [];
        }
      } finally {
        this.loading = false;
      }
    },
    showAllDrugsInpatient() {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/inpatient/${this.patientData.id_registration}`,
          ),
        )
        .then(response => {
          const [data] = response.data.data;
          const index = data.drug_administration.length - 1;
          const dataItem = data.drug_administration[index].drugs;
          const dataAdmin = data.drug_administration[index];
          this.idRegistration = data.id_registration;
          this.idDrugAdministration = data.drug_administration[0]._id;
          this.idStaffMixRecipe = data.mix_staff_name[index]?._id || '';
          this.idStaffRecipe = data.recipe_staff_name[index]?._id || '';
          this.patientStatus = data.patient_status;
          this.idEmr = data.id_emr;

          this.headerDate =
            dataAdmin.distinct_date?.map(arr => ({
              ...arr,
              time: arr.time.filter((t, i) => {
                return arr.time.findIndex(o => o.time === t.time) === i;
              }),
            })) || [];

          this.table.items = dataItem
            .sort((a, b) => b.is_continued - a.is_continued)
            .map((drug, index) => {
              const drugDisplayName = drug.nonracik.name
                ? drug.nonracik.name
                : drug.racik.name;

              return {
                no: index + 1,
                isContinued: drug.is_continued,
                isExtra: drug.is_extra_drugs,
                isPotentNonRacik: drug.nonracik.isPotent || false,
                isPotentRacik: drug.racik.drugs[0]?.isPotent || false,
                patient_status: data.patient_status,
                _id: drug._id,
                nama: drugDisplayName,
                time_delivery: drug.time_delivery
                  ? drug.time_delivery.map(arr => ({
                      ...arr,
                      text: `${arr.date} ${arr.time}`,
                    }))
                  : [],
                racik:
                  drug.racik.drugs.length > 0
                    ? {
                        ...drug.racik,
                        drugName: drug.racik.name,
                        quantity: drug.racik.quantity,
                        drugType: 'Obat Racik',
                        dosage: +drug.racik.dosage,
                        dose: drug.racik.usage,
                        price: drug.racik.price,
                        note: drug.racik.description,
                        date: moment(drug.racik.date)
                          .locale('id')
                          .format('DD MMMM YYYY hh:mm [WIB]'),
                        drugs: drug.racik.drugs.map(i => {
                          return {
                            ...i,
                            dosage: +i.dosage,
                          };
                        }),
                        route: drug.racik.route,
                        packaging: drug.racik.packaging,
                        isConfirmed: drug.racik.isApproved,
                        generalRules: drug.racik.general_rule,
                        usageRule: drug.racik.usage,
                        staff: drug.timestamps.created_by,
                        time_delivery: drug.racik.time_delivery,
                        type: drug.racik.drugs.length > 0 ? 'racik' : '',
                        given: data.patient_status
                          .toLowerCase()
                          .split(' ')
                          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                          .join(' '),
                      }
                    : {},
                nonracik: drug.nonracik.id_drugs
                  ? {
                      ...drug.nonracik,
                      note: drug.nonracik.description,
                      drugType: 'Obat Non Racik',
                      dosage: +drug.nonracik.dosage,
                      generalRules: drug.nonracik.general_rule,
                      id_drugs: drug.nonracik.id_drugs,
                      drugName: drug.nonracik.name,
                      packaging: drug.nonracik.packaging,
                      preparation: drug.nonracik.preparation,
                      quantity: drug.nonracik.quantity,
                      requestedQuantity: drug.nonracik.requested_quantity,
                      route: drug.nonracik.route || '',
                      dose: `${drug.nonracik.dosage} ${drug.nonracik.unit_dosage}`,
                      usageRule: drug.nonracik.usage,
                      id: drug._id,
                      isHighAlert: drug.nonracik.isHighAlert,
                      staff: drug.timestamps.created_by,
                      date: moment(drug.nonracik.date)
                        .locale('id')
                        .format('DD MMMM YYYY hh:mm [WIB]'),
                      isConfirmed: drug.nonracik.isApproved,
                      unit_dosage: drug.nonracik.unit_dosage,
                      type: drug.nonracik.id_drugs ? 'nonracik' : '',
                      time_delivery: drug.nonracik.time_delivery,
                      given: data.patient_status
                        .toLowerCase()
                        .split(' ')
                        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' '),
                    }
                  : {},
                history: drug.history.map(unit => {
                  return {
                    ...unit,
                  };
                }),
                created_at: drug.timestamps.created_at,
              };
            });
          this.allergy = data.social[0]?.allergy || [];
          this.loading = false;
        })
        .catch(error => {
          this.showErrorAxios(error);
          if (error) {
            this.table.items = [];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-table-wrapper {
  padding: 0 1vw;
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);

  .data-footer {
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1vw;
    background-color: #f4f5f5;
    border: 1px solid #9ca2a5;
    border-left: none;

    span {
      font-size: 0.8vw;
      font-weight: 500;
    }
  }

  .time-footer-wrapper {
    width: 100%;
    overflow-x: auto;

    .time-footer {
      display: flex;
      background-color: #f4f5f5;
      width: fit-content;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      height: 7vh;
      border: 1px solid #9ca2a5;
      border-left: none;
      border-right: none;

      .time-cell {
        min-width: 3.65vw;
        height: 7vh;
        display: flex;
        justify-content: center;
        align-content: center;
        padding-top: 0.5vw;
        border-right: 1px solid #9ca2a5;

        &.single {
          min-width: 5.25vw;
        }

        .initial-chip {
          display: flex;
          justify-content: center;
          width: fit-content;
          font-size: 0.65vw;
          height: 2vh;
          font-weight: 500;
        }
      }
    }
  }
}
.main-wrapper {
  padding: 0 1vw;
  position: relative;
  max-height: 72vh;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);

  .empty-alert {
    position: absolute;
    left: 40%;
    top: 120%;
  }

  .header-data {
    background-color: #f4f5f5;
    height: 8vh;
    width: 100%;
    display: grid;
    text-align: start;
    grid-template-columns: 5% 11% 31% 15% 15% 19%;
    align-items: center;
    column-gap: 0.7vw;
    font-weight: 500;
    font-size: 0.75vw;
    padding: 0 0.5vw;
    border-bottom: 1px solid #9ca2a5;
    border-right: 1px solid #9ca2a5;
  }

  .data-list {
    max-height: 58vh;
    overflow-x: hidden;
    border-right: 1px solid #9ca2a5 !important;
    overflow-y: auto;

    &::-webkit-scrollbar {
      background: #e1e0e0;
      width: 0.1vw;
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgray;
      width: 0.1vw;
      border-radius: 6px;
    }

    .grid-list {
      padding: 0;
      display: grid;
      grid-template-columns: 5% 11% 31% 15% 15% 19%;
      column-gap: 0.7vw;
      font-size: 0.75vw;
      padding: 0 0.5vw;
    }

    .expand-content {
      display: grid;
      grid-template-columns: 20% 35% 20% 25%;
      button {
        width: 6.5vw;
        height: 1.5vw;
        background-color: white !important;
        font-size: 0.6vw !important;
      }
    }
  }

  .time-header-container {
    overflow-x: auto;
    overflow-y: hidden;
    max-height: 73vh;
    &::-webkit-scrollbar {
      background: #e1e0e0;
      height: 0.4vw;
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgray;
      height: 0.4vw;
      border-radius: 6px;
    }
    .table-header {
      border-collapse: collapse;
      background-color: #f4f5f5;
      min-width: fit-content;

      &.empty {
        width: 100%;
      }

      &::v-deep thead tr th {
        border: 1px solid #b3b7b9 !important;
        border-top: none !important;
        border-left: none !important;
      }

      thead {
        height: 8vh;
        font-size: 0.75vw;

        th {
          min-width: 3.64vw;
          border-bottom: 1px solid #9ca2a5 !important;
        }
      }

      .empty-text {
        width: 100%;
        height: 8vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8vw;
        font-weight: 500;
      }
    }
    .input-time-grid {
      border-top: none;
      border-collapse: collapse;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      width: fit-content;
      max-height: 58vh;
      &::-webkit-scrollbar {
        background: #e1e0e0;
        height: 0vw;
      }
      &::-webkit-scrollbar-thumb {
        background-color: darkgray;
        height: 0vw;
        border-radius: 6px;
      }

      .row-input-time {
        display: flex;
        height: 63px;
        align-items: center;
        width: fit-content;

        .cell {
          border-right: 1px solid #9ca2a5;
          min-width: 3.65vw;

          &.single {
            min-width: 5.25vw;
          }

          .icon-wrapper {
            position: relative;
            padding-top: 1.3vw;
            padding-bottom: 1.3vw;
            .high {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: red;
              position: absolute;
              top: 31%;
              right: 28.5%;
            }
            .alert {
              position: absolute;
              top: 31%;
              right: 21.5%;
            }
          }
        }
      }

      .masking {
        border-right: 1px solid #9ca2a5;
        width: 3.65vw;
        height: 2.3vw;

        &.single {
          min-width: 5.25vw;
        }

        &.expand-masking {
          height: 2.7vw;
        }
      }
    }
  }
}

.main-container {
  height: 75vh;
}

.description {
  padding: 5px 10px;
  background-color: #f0f9ff;
  border: 0.5px solid #bdbdbd;
  border-radius: 3px;
}
.header-grid {
  padding-left: 1vw;
  padding-right: 1.5vw;
  margin-bottom: 2vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr 2fr);
  row-gap: 5px;
  font-size: 0.85vw;
  text-align: start;
}
.allergy {
  background-color: #fff2f2 !important;
  font-weight: 400;
  font-size: 0.85vw;
  justify-content: center;
  border: 0.5px solid #eb4747 !important;
  color: #eb4747;
}

.table-layout {
  height: 70vh;
}

.action-btn {
  letter-spacing: 0.01px;
  width: fit-content;
  font-size: 0.8vw;
  padding: 0;
}

::v-deep .v-snack__content {
  padding: 0px 0px 0px 1vw;
}
</style>
