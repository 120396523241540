import { render, staticRenderFns } from "./FormDiagnose.vue?vue&type=template&id=5ce2a000&scoped=true"
import script from "./FormDiagnose.vue?vue&type=script&lang=js"
export * from "./FormDiagnose.vue?vue&type=script&lang=js"
import style0 from "./FormDiagnose.vue?vue&type=style&index=0&id=5ce2a000&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ce2a000",
  null
  
)

export default component.exports