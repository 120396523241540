<template>
  <v-card class="main-container">
    <div class="content-wrapper">
      <v-snackbar
        class="snackbar"
        :timeout="6500"
        v-model="snackbar"
        tile
        color="red accent-2"
      >
        Jam Pemberian Belum Diisi
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Tutup
          </v-btn>
        </template>
      </v-snackbar>
      <section class="py-5 mx-8 card-header">
        <div class="d-flex justify-space-between">
          <h2 class="text-left">Input Waktu Pemberian</h2>
          <v-btn @click="$emit('close-dialog')" small fab text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </section>
      <div class="header-data self-container">
        <label for="date" class="text-left">Filter Jadwal Pemberian</label>
        <v-combobox
          placeholder="Pilih Jadwal"
          :items="resource.time"
          item-text="text"
          v-model="schedule"
          append-icon="mdi-chevron-down"
          return-object
          @change="setInput"
        ></v-combobox>

        <label class="text-right pr-3">Tanggal Pemberian</label>
        <label class="text-left pl-3 grey--text">{{
          schedule && schedule.formattedDate
        }}</label>
        <label for="time" class="text-left">Input Jam Pemberian</label>
        <v-text-field
          @input="formatTime(time)"
          v-model="time"
          placeholder="Input Jam Pemberian"
          :rules="timeGiven"
        ></v-text-field>
        <label
          v-if="
            input.drugs.filter(
              arr => arr.isHighAlertRacik || arr.isHighAlertNonRacik,
            ).length > 0
          "
          class="text-xs-left font-weight-regular"
        >
          Nama Verifikator
        </label>
        <v-form ref="form">
          <v-combobox
            v-if="
              input.drugs.filter(
                arr => arr.isHighAlertRacik || arr.isHighAlertNonRacik,
              ).length > 0
            "
            append-icon="mdi-chevron-down"
            :rules="isGivenHighAlertDrugs ? rules : []"
            :items="resource.nurse"
            clearable
            class="input-text"
            item-text="name"
            v-model="verified_by"
          ></v-combobox>
        </v-form>
      </div>
      <v-row class="self-container">
        <v-data-table
          height="220"
          :loading="isLoading"
          hide-default-footer
          class="table"
          :items="input.drugs"
          :headers="table.headers"
        >
          <template v-slot:item="item">
            <tr>
              <td class="text-center font-weight-regular body-2">
                <!-- {{ table.items.indexOf(item.item) + 1 }} -->
                {{ item.item.no }}
              </td>
              <td class="text-left font-weight-regular body-2">
                {{ item.item.nonracik.name || item.item.racik.name }}
                <v-icon
                  v-if="
                    item.item.isHighAlertRacik || item.item.isHighAlertNonRacik
                  "
                  color="amber"
                  >mdi-alert
                </v-icon>
              </td>
              <td>
                <v-chip
                  class="chip text-center text-capitalize font-weight-regular caption"
                  :class="{ extra: item.item.is_extra_drugs }"
                  :color="item.item.is_extra_drugs ? '#fff9e6' : '#fff2f2'"
                  label
                  >{{
                    item.item.is_extra_drugs
                      ? 'Extra'
                      : item.item.racik.isOdd || item.item.nonracik.isOdd
                      ? 'ODD'
                      : 'Non ODD'
                  }}</v-chip
                >
              </td>
              <td class="font-weight-regular text-center">
                <v-radio-group
                  light
                  dense
                  class="d-flex justify-center"
                  row
                  v-model="item.item.status"
                >
                  <v-radio
                    light
                    label="Belum Diberikan"
                    value="not_given"
                  ></v-radio>
                  <v-radio
                    light
                    label="Sudah Diberikan"
                    value="given"
                  ></v-radio>
                  <v-radio light label="Tidak Diberikan" value="discontinued">
                  </v-radio>
                  <v-radio
                    v-if="
                      !item.item.is_extra_drugs &&
                        (item.item.nonracik.isOdd || item.item.racik.isOdd)
                    "
                    light
                    label="Ditunda"
                    value="postponed"
                  ></v-radio>
                </v-radio-group>
              </td>
              <td class="font-weight-regular body-2">
                {{ patientStatus.toUpperCase() }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-row>
      <v-row class="self-container d-flex justify-start">
        <h3 class="primary--text">Telaah Obat</h3>
      </v-row>
      <v-row class="row-layouts px-12 pt-5">
        <v-col cols="4">
          <v-row>
            <v-col
              cols="7"
              class="pa-0 pt-2 text-left font-weight-bold body-2 mb-1"
              >Pasien</v-col
            >
            <v-col cols="2" class="font-weight-medium body-2">Ya</v-col>
            <v-col class="text-left font-weight-medium body-2 pl-0"
              >Tidak</v-col
            >
          </v-row>
          <v-divider
            style="width: 350px; margin-left: -13px;"
            class="mb-5"
          ></v-divider>
          <v-row
            align-start
            v-for="(item, index) in resource.patient"
            :key="'patient-' + index"
            class="block-input"
          >
            <v-col class="text-left mr-2 pa-0" cols="7">
              <label class="input-label">
                {{ item.title }}
              </label>
            </v-col>
            <v-col class="pl-5 pa-0">
              <v-radio-group v-model="item.value" class="pa-0 ma-0" row>
                <v-radio :value="1" class="pa-0"></v-radio>
                <v-radio :value="0" class="pa-0 mx-1"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col
              cols="7"
              class="pa-0 pt-2 text-left font-weight-bold body-2 mb-1"
              >Pemberian</v-col
            >
            <v-col cols="2" class="font-weight-medium body-2">Ya</v-col>
            <v-col class="text-left font-weight-medium body-2 pl-0"
              >Tidak</v-col
            >
          </v-row>
          <v-divider
            style="width: 350px; margin-left: -13px;"
            class="mb-5"
          ></v-divider>
          <v-row
            align-start
            v-for="(item, index) in resource.given"
            :key="'given-' + index"
            class="block-input"
          >
            <v-col class="text-left mr-2 pa-0" cols="7">
              <label class="input-label">
                {{ item.title }}
              </label>
            </v-col>
            <v-col class="pl-5 pa-0">
              <v-radio-group v-model="item.value" class="pa-0 ma-0" row>
                <v-radio :value="1" class="pa-0"></v-radio>
                <v-radio :value="0" class="pa-0 mx-1"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="d-flex justify-end align-end">
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed color="primary" class="ma-5" @click="submitHistory"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');

export default {
  name: 'InputDrugSchedule',
  mixins: [alertMixin],
  data() {
    return {
      snackbar: false,
      isLoading: false,
      drugs: [],
      time: '',
      schedule: '',
      verified_by: '',
      timeGiven: [v => this.checkTime(v) || 'Waktu Tidak Valid'],
      rules: [v => v || 'Wajib diisi'],
      resource: {
        patient: [
          { value: 0, title: 'Benar Pasien', label: 'true_patient' },
          { value: 0, title: 'Benar Obat', label: 'true_drug' },
          { value: 0, title: 'Benar Dosis', label: 'true_dosage' },
          { value: 0, title: 'Tanggal Resep', label: 'true_date' },
        ],
        given: [
          { value: 0, title: 'Benar Cara Pemberian', label: 'true_gift' },
          { value: 0, title: 'Benar Waktu Pemberian', label: 'true_time_gift' },
        ],
        time: [],
        nurse: [],
      },
      input: {
        drugs: [],
        review_receipt: {
          time: '12:00',
          date: '08-02-2021',
          true_patient: 1,
          true_drug: 1,
          true_dosage: 1,
          true_gift: 1,
          true_date: 1,
          true_time_gift: 1,
          created_at: moment().format('YYYY-MM-DD'),
          created_by: this.$store.getters.userLoggedIn.id,
        },
      },
      table: {
        headers: [
          {
            sortable: false,
            text: 'No',
            value: 'no',
            width: '3%',
          },
          {
            sortable: false,
            text: 'Nama Obat',
            value: 'name',
            width: '22%',
          },
          {
            sortable: false,
            text: 'Status Obat',
            value: 'status',
            class: 'text-sm-center',
            width: '5%',
          },
          {
            sortable: false,
            text: 'Status Pemberian',
            value: 'givenStatus',
            width: '38%',
            class: 'text-sm-center',
          },
          {
            sortable: false,
            text: 'Obat Diberikan di',
            value: 'place',
            class: 'text-sm-center',
            width: '15%',
          },
        ],
      },
    };
  },
  props: {
    idRegistration: {
      type: Array,
    },
    idDrugAdministration: {
      type: String,
    },
    patientStatus: {
      type: String,
    },
  },
  computed: {
    isGivenHighAlertDrugs() {
      let result = this.input.drugs.filter(
        i =>
          (i.status === 'given' || i.status === 'discontinued') &&
          (i.isHighAlertNonRacik || i.isHighAlertRacik),
      );
      return result.length > 0;
    },
  },
  mounted() {
    this.getDrugList();
    this.getNurseOnly();
  },
  methods: {
    checkTime(time) {
      const timeInput = moment(time, 'HH:mm');
      return timeInput.isValid();
    },
    formatTime(timeString) {
      if (timeString.length > 5) {
        this.time = timeString.slice(0, 5);
        return;
      }
      let time = timeString.replace(/\D/g, '').substr(0, 4);
      let size = time.length;

      if (size > 2) time = this.insertString(time, ':', 2);

      this.time = time;
    },
    insertString(originalString, newString, index) {
      return (
        originalString.substr(0, index) +
        newString +
        originalString.substr(index)
      );
    },
    getNurseOnly() {
      axios.get(Constant.apiUrl.concat('/master/staff')).then(response => {
        const nurseList = response.data.data.map(staff => {
          return {
            name: staff.detail.name,
            id: staff._id,
          };
        });
        this.resource.nurse = nurseList;
      });
    },
    async submitHistory() {
      if (this.$refs.form.validate()) {
        try {
          if (!this.time) {
            this.snackbar = true;
          } else {
            const payload = {
              drugs: this.input.drugs.map(arr => {
                if (arr.status === 'given' && arr.isOdd) {
                  // TODO: validasi by apakah schedule sebelumnya sudah diisi
                  const previousNotGiven = arr.time_delivery
                    .filter(ar => {
                      return (
                        moment(ar.date.split('+')[0]).format('YYYY-MM-DD') ===
                        moment().format('YYYY-MM-DD')
                      );
                    })
                    .findIndex(schd => {
                      return (
                        schd.time <
                          moment(this.schedule.time, 'HH:mm').format('HH:mm') &&
                        moment(schd.time, 'HHmm').format('HH:mm') >
                          moment(this.schedule.time, 'HHmm')
                            .add(2, 'hours')
                            .format('HH:mm') &&
                        arr.history.findIndex(
                          hst =>
                            hst.scheduled_time === schd.time &&
                            hst.date === moment().format('DD-MM-YYYY') &&
                            (hst.drug_status === 'given' ||
                              hst.drug_status === 'pending' ||
                              hst.drug_status === 'discontinued'),
                        ) === -1
                      );
                    });
                  if (previousNotGiven > -1) {
                    alert('Silahkan update jadwal pemberian obat sebelumnya');
                  }
                }
                return {
                  _id: arr._id,
                  is_continued: arr.is_continued,
                  is_extra_drugs: arr.is_extra_drugs,
                  nonracik: arr.nonracik || { time_delivery: [] },
                  racik: arr.racik || { drugs: [], time_delivery: [] },
                  history: [
                    {
                      date: moment(arr.date, 'DD/MM/YYYY').format('DD-MM-YYYY'),
                      time:
                        this.time !== ''
                          ? moment(this.time, 'HHmm').format('HH:mm')
                          : moment().format('HH:mm'),
                      is_continued: arr.is_continued,
                      is_extra_drugs: false,
                      scheduled_time: arr.scheduled_time
                        ? arr.scheduled_time
                        : this.time
                        ? moment(this.time, 'HHmm').format('HH:mm')
                        : moment().format('HH:mm'),
                      drug_status: arr.status,
                      created_by: this.$store.getters.userLoggedIn.id,
                      verified_by: this.verified_by.id,
                    },
                  ],
                  timestamps: {
                    created_by: this.$store.getters.userLoggedIn.id,
                  },
                };
              }),

              review_receipt: [
                {
                  time:
                    this.time !== ''
                      ? moment(this.time, 'HHmm').format('HH:mm')
                      : moment().format('HH:mm'),
                  date: moment(this.schedule.date).format('DD-MM-YYYY'),
                  true_patient: this.resource.patient[0].value, // Bakal diisi di bawah
                  true_drug: this.resource.patient[1].value,
                  true_dosage: this.resource.patient[2].value,
                  true_date: this.resource.patient[3].value,
                  true_gift: this.resource.given[0].value,
                  true_time_gift: this.resource.given[1].value,
                  created_at: moment().format('YYYY-MM-DD'),
                  created_by: this.$store.getters.userLoggedIn.id,
                },
              ],
            };
            const drugReview = this.resource.patient.concat(
              this.resource.given,
            );
            drugReview.forEach(arr => {
              payload.review_receipt[arr.label] = arr.value;
            });
            const response = await axios.put(
              Constant.apiUrl
                .concat('/inpatient/drugadministration/')
                .concat(this.idDrugAdministration),
              payload,
            );
            if (response) {
              this.showFillSuccess('Berhasil');
              this.$emit('close-dialog');
            }
          }
        } catch (error) {
          if (error) {
            this.showErrorAxios(
              'Waktu pemberian obat belum tersimpan. Silakan ulangi!',
            );
          }
        }
      }
    },
    setInput() {
      this.input.drugs = this.drugs
        .filter(arr => {
          const isExtra = arr.is_extra_drugs;
          const isNonODD =
            ((arr.nonracik.isOdd === false && arr.nonracik.name) ||
              (arr.racik.isOdd === false && arr.racik.drugs.length > 0)) &&
            !arr.is_extra_drugs;
          if (isExtra || isNonODD) return true;
          // check if drug is scheduled for selected time and include  discontinued unordered drug if  time is matched
          const isScheduledOnTime =
            arr.time_delivery.findIndex(item =>
              !item
                ? null
                : item.time === this.schedule.time &&
                  (moment(item.date).isSame(this.schedule.date, 'days') ||
                    !arr.is_continued),
            ) !== -1;
          // check if drug yet given
          const hasNoEntry =
            arr.history.findIndex(item => {
              return (
                item.scheduled_time === this.schedule.time &&
                item.date === moment(this.schedule.date).format('DD-MM-YYYY') &&
                item.drug_status !== 'not_given' &&
                item.drug_status !== 'postponed'
              );
            }) === -1;
          return this.schedule && isScheduledOnTime && hasNoEntry;
        })
        .map((item, index) => {
          const isScheduled =
            item.time_delivery.findIndex(
              schd =>
                schd.time === this.schedule.time &&
                moment(schd.date).isSame(this.schedule.date, 'days'),
            ) !== -1;
          // check if drug is scheduled for exactly selected time and date (means that drug is ordered today )

          const discontinuedStatus = isScheduled ? 'pending' : 'discontinued';
          return {
            ...item,
            verified_by: '',
            no: index + 1,
            isScheduled,
            scheduled_time: this.schedule.time,
            time: this.schedule.time,
            date: moment(this.schedule.date).format('DD/MM/YYYY'),
            status: item.is_continued ? 'not_given' : discontinuedStatus,
          };
        });
      this.time = this.schedule.time || '';
    },
    getDrugList() {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl
            .concat('/inpatient/drugadministration/')
            .concat(this.idDrugAdministration),
        )
        .then(response => {
          const drugHistoryList = response.data.data[0];
          const drugs = drugHistoryList.drugs;

          let time = { time_delivery: [] };
          if (drugHistoryList.drugs.length > 0) {
            time =
              drugHistoryList.drugs.length > 1
                ? drugHistoryList.drugs
                    .filter(drug => drug.time_delivery)
                    .reduce((a, b) => {
                      return {
                        time_delivery: a.time_delivery.concat(b.time_delivery),
                      };
                    }, drugHistoryList.drugs.filter(drug => drug.time_delivery)[0])
                : { time_delivery: drugHistoryList.drugs[0].time_delivery };
          }
          const filteredTime = time?.time_delivery
            ? time.time_delivery.filter(x => Boolean(x))
            : [];
          this.resource.time = filteredTime
            .filter((a, index) => filteredTime.indexOf(a) === index)
            .filter(d => {
              return (
                moment(d.date).format('YYYY-MM-DD') ===
                moment().format('YYYY-MM-DD')
              );
            })
            .map(arr => ({
              ...arr,
              text: `${moment(arr.date).format('DD/MM/YYYY')} pukul ${
                arr.time
              }`,
              formattedDate: moment(arr.date).format('DD/MM/YYYY'),
            }));

          function compare(a, b) {
            if (a.time > b.time) {
              return 1;
            }
            if (b.time > a.time) {
              return -1;
            }
            return 0;
          }

          this.resource.time.sort(compare);
          this.drugs = drugs.map((drug, index) => {
            const drugDisplayName = drug.nonracik.name
              ? drug.nonracik.name
              : drug.racik.name;
            return {
              no: index + 1,
              is_continued: drug.is_continued,
              is_extra_drugs: drug.is_extra_drugs,
              isHighAlertNonRacik: drug.nonracik.isHighAlert,
              isHighAlertRacik: drug.racik.drugs[0]?.isHighAlert,
              _id: drug._id,
              nama: drugDisplayName,
              time_delivery: drug.time_delivery
                ? drug.time_delivery.map(arr => ({
                    ...arr,
                    text: `${arr.date} ${arr.time}`,
                  }))
                : [],
              racik: drug.racik
                ? {
                    ...drug.racik,
                    drugs: drug.racik.drugs.map(drugRacik => {
                      return {
                        ...drugRacik,
                        dosage: drugRacik.dosage,
                        dosage_needed: drugRacik.dosage_needed,
                        id_drugs: drugRacik.id_drugs,
                        quantitity: drugRacik.quantitity,
                        requested_quantity: drugRacik.requested_quantity,
                        unit_dosage: drugRacik.unit_dosage,
                        _id: drugRacik._id,
                      };
                    }),
                    description: drug.racik.description,
                    general_rule: drug.racik.general_rule,
                    isOdd: drug.racik.isOdd,
                    name: drug.racik.name,
                    quantity: drug.racik.quantity,
                    requested_quantity: drug.racik.requested_quantity,
                    route: drug.racik.route,
                    time_delivery: drug.racik.time_delivery,
                    usage: drug.racik.usage,
                  }
                : {},
              nonracik: drug.nonracik
                ? {
                    ...drug.nonracik,
                    dosage: drug.nonracik.dosage,
                    id_drugs: drug.nonracik.id_drugs?._id,
                    drugType: drug.nonracik.id_drugs?.detail[0]?.group,
                    isOdd: drug.nonracik.isOdd,
                    name: drug.nonracik.name,
                    packaging: drug.nonracik.packaging,
                    preparation: drug.nonracik.preparation,
                    quantity: drug.nonracik.quantity,
                    requested_quantity: drug.nonracik.requested_quantity,
                    time_delivery: drug.nonracik.time_delivery,
                    unit_dosage: drug.nonracik.unit_dosage,
                    usage: drug.nonracik.usage,
                  }
                : {},
              history: drug.history.map(unit => {
                return {
                  ...unit,
                };
              }),
              created_at: drug.timestamps.created_at,
            };
          });

          this.setInput();
        })
        .catch(error => {
          if (error) {
            this.resource.drugs = [];
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.main-container {
  min-height: 8 0vh;
  overflow-x: hidden;
}

.content-wrapper {
  height: 33vw;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 6px;
    background-color: #e7e6e6;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 6px;
    background-color: rgb(144, 143, 143);
  }
}
.header-data {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 2fr repeat(2, 1fr);
  align-items: baseline;
  font-size: 0.9rem;
}
.row-layouts {
  width: 100%;
}
.self-container {
  width: 95%;
  margin: auto;
}
::v-deep .v-data-table-header {
  background-color: #f4f5f5;
  border-bottom-color: #9ca2a5;
  span {
    color: black;
  }
}
.chip {
  border: 1px solid #eb4747 !important;
  height: 25px;
  background-color: #fff2f2;
  color: #eb4747;
}
.extra {
  background-color: #fff9e6;
  border: 1px solid #e5a82e !important;
  height: 25px;
  color: #e5a82e;
}
.input-label {
  font-size: 12px;
}
.table {
  width: 100%;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 6px;
    background-color: #e7e6e6;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 6px;
    background-color: rgb(144, 143, 143);
  }
}
.snackbar {
  top: -85vh;
  right: 18vw;
  z-index: 100000;
}
</style>
