<template>
  <div>
    <h4 class="title font-weight-bold primary--text text-left mb-4">
      Pemeriksaan Fisik
    </h4>
    <v-row>
      <v-col cols="6" :class="isEmr ? 'grid-display-emr' : 'grid-display'">
        <label>Kepala</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          hide-details
          dense
          v-model="form.head"
        />
        <label>Mata</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.eye"
        />
        <label>Telinga</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.ear"
        />
        <label>Hidung</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.nose"
        />
        <label>Mulut</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.mouth"
        />
        <label>Leher</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.neck"
        />
        <label>Rambut</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.hair"
        />
        <label>Bibir</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.lips"
        />
        <label>Gigi Geligi</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.teeth"
        />
        <label>Lidah</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.tongue"
        />
        <label>Langit-langit</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.palate"
        />
        <label>Tenggorokan</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.throat"
        />
        <label>Tonsil</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.tonsil"
        />
        <label>Dada</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.chest"
        />
        <label>Payudara</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.breast"
        />
      </v-col>
      <v-col cols="6" :class="isEmr ? 'grid-display-emr' : 'grid-display'">
        <label>Punggung</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.back"
        />
        <label>Perut</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.stomach"
        />
        <label>Genitalia</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.genitalia"
        />
        <label>Anus/Dubur</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.anus"
        />
        <label>Lengan Atas</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.upper_arm"
        />
        <label>Lengan Bawah</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.forearm"
        />
        <label>Jari Tangan</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.finger"
        />
        <label>Kuku Tangan</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.hand_nail"
        />
        <label>Persendian Tangan</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.hand_joint"
        />
        <label>Tungkai Atas</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.upper_limb"
        />
        <label>Tungkai Bawah</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.lower_limb"
        />
        <label>Jari Kaki</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.toe"
        />
        <label>Kuku Kaki</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.toe_nail"
        />
        <label>Persendian Kaki</label>
        <v-text-field
          :readonly="isEmr"
          placeholder="Contoh: Normal"
          class="mb-5"
          dense
          hide-details
          v-model="form.leg_joint"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers(
  'ServiceAssessmentPhysicalCheck',
);
export default {
  name: 'ServiceAssessmentPhysicalCheck',
  props: {
    isEmr: Boolean,
  },
  computed: {
    ...mapGetters(['getPhysicalCheck']),
    form: {
      get() {
        return this.getPhysicalCheck;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.4rem;
}
$standard-font-size: 0.9vw;

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.grid-display {
  @include grid-display(10vw minmax(16vw, 26vw), true);
  column-gap: 1vw;
}

.grid-display-emr {
  @include grid-display(10vw minmax(16vw, 34vw), true);
  column-gap: 1vw;
}

.required:after {
  content: ' *';
  color: red;
}

h4 {
  color: #1976d2;
}
</style>
