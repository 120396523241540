const state = {
  isEmr: false,
};

const getters = {
  getIsEmr: state => state.isEmr,
};

const mutations = {
  setIsEmr(state, payload) {
    state.isEmr = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
