<template>
  <v-card class="main-card">
    <v-card-title class="justify-end">
      <h3>Pembuatan Surat</h3>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-tabs v-model="tab" centered>
        <v-tab
          v-if="
            isInpatient ||
              isIGD ||
              isGeneral ||
              isDental ||
              isKIA ||
              isVaccine ||
              isPhysioterapist ||
              isKB
          "
          key="outpatient"
          class="text-none font-weight-bold"
          style="width: 150px"
          >Surat Kontrol Rawat Jalan</v-tab
        >
        <v-tab
          v-if="
            !isInpatient ||
              isIGD ||
              isGeneral ||
              isDental ||
              isKIA ||
              isVaccine ||
              isPhysioterapist ||
              isKB
          "
          key="inpatient"
          class="text-none font-weight-bold"
          style="width: 150px"
          >Surat Perintah Rawat Inap</v-tab
        >
        <v-tab
          v-if="
            (isBpjs && isInpatient) ||
              (isBpjs && isIGD) ||
              (isBpjs && isGeneral) ||
              (isBpjs && isDental) ||
              (isBpjs && isKIA) ||
              (isBpjs && isVaccine) ||
              (isBpjs && isPhysioterapist) ||
              (isBpjs && isKB)
          "
          key="hospitalPcare"
          class="text-none font-weight-bold"
          style="width: 200px"
          >Surat Rujukan ke Rumah Sakit</v-tab
        >
        <v-tab
          v-if="
            (!isBpjs && isInpatient) ||
              (!isBpjs && isIGD) ||
              (!isBpjs && isGeneral) ||
              (!isBpjs && isDental) ||
              (!isBpjs && isKIA) ||
              (!isBpjs && isVaccine) ||
              (!isBpjs && isPhysioterapist) ||
              (!isBpjs && isKB)
          "
          key="hospital"
          class="text-none font-weight-bold"
          style="width: 200px"
          >Surat Rujukan ke Rumah Sakit</v-tab
        >
        <v-tab
          v-if="
            isInpatient ||
              isIGD ||
              isGeneral ||
              isDental ||
              isKIA ||
              isVaccine ||
              isPhysioterapist ||
              isKB
          "
          key="mortality"
          class="text-none font-weight-bold"
          style="width: 200px"
          >Surat Keterangan Kematian</v-tab
        >
        <v-tab
          v-if="
            isInpatient ||
              isIGD ||
              isGeneral ||
              isDental ||
              isKIA ||
              isVaccine ||
              isPhysioterapist ||
              isKB
          "
          key="sick"
          class="text-none font-weight-bold"
          style="width: 220px"
          >Surat Keterangan Sakit</v-tab
        >
        <v-tab
          v-if="
            !isInpatient ||
              isIGD ||
              isGeneral ||
              isDental ||
              isKIA ||
              isVaccine ||
              isPhysioterapist ||
              isKB
          "
          key="healthy"
          class="text-none font-weight-bold"
          style="width: 220px"
          >Surat Keterangan Dokter</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-if="
            isInpatient ||
              isIGD ||
              isGeneral ||
              isDental ||
              isKIA ||
              isVaccine ||
              isPhysioterapist ||
              isKB
          "
          key="outpatient"
        >
          <OutpatientWarrant
            ref="formOutpatientWarrant"
            :patient-data="patientData"
            @close-form="$emit('close-form')"
          />
        </v-tab-item>
        <v-tab-item
          v-if="
            !isInpatient ||
              isIGD ||
              isGeneral ||
              isDental ||
              isKIA ||
              isVaccine ||
              isPhysioterapist ||
              isKB
          "
          key="inpatient"
        >
          <InpatientWarrant
            ref="formInpatientWarrant"
            :patient-data="patientData"
            @close-form="$emit('close-form')"
          />
        </v-tab-item>
        <v-tab-item
          v-if="
            (isBpjs && isInpatient) ||
              (isBpjs && isIGD) ||
              (isBpjs && isGeneral) ||
              (isBpjs && isDental) ||
              (isBpjs && isKIA) ||
              (isBpjs && isVaccine) ||
              (isBpjs && isPhysioterapist) ||
              (isBpjs && isKB)
          "
          key="hospitalPcare"
        >
          <HospitalPcare
            ref="formHospitalPcare"
            :patient-data="patientData"
            @close-form="$emit('close-form')"
          />
        </v-tab-item>
        <v-tab-item
          v-if="
            (!isBpjs && isInpatient) ||
              (!isBpjs && isIGD) ||
              (!isBpjs && isGeneral) ||
              (!isBpjs && isDental) ||
              (!isBpjs && isKIA) ||
              (!isBpjs && isVaccine) ||
              (!isBpjs && isPhysioterapist) ||
              (!isBpjs && isKB)
          "
          key="hospital"
        >
          <Hospital
            ref="formHospital"
            :patient-data="patientData"
            @close-form="$emit('close-form')"
          />
        </v-tab-item>
        <v-tab-item
          v-if="
            isInpatient ||
              isIGD ||
              isGeneral ||
              isDental ||
              isKIA ||
              isVaccine ||
              isPhysioterapist ||
              isKB
          "
          key="mortality"
        >
          <Mortality
            ref="formMortality"
            :patient-data="patientData"
            @close-form="$emit('close-form')"
          />
        </v-tab-item>
        <v-tab-item
          v-if="
            isInpatient ||
              isIGD ||
              isGeneral ||
              isDental ||
              isKIA ||
              isVaccine ||
              isPhysioterapist ||
              isKB
          "
          key="sick"
        >
          <Sick
            ref="formSick"
            :patient-data="patientData"
            @close-form="$emit('close-form')"
          />
        </v-tab-item>
        <v-tab-item
          v-if="
            !isInpatient ||
              isIGD ||
              isGeneral ||
              isDental ||
              isKIA ||
              isVaccine ||
              isPhysioterapist ||
              isKB
          "
          key="healthy"
        >
          <Healthy
            ref="formHealthy"
            :patient-data="patientData"
            @close-form="$emit('close-form')"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import OutpatientWarrant from './OutpatientWarrant';
import InpatientWarrant from './InpatientWarrant';
import Hospital from './Hospital';
import HospitalPcare from './HospitalPcare';
import Mortality from './Mortality';
import Sick from './Sick';
import Healthy from './Healthy';

export default {
  components: {
    OutpatientWarrant,
    InpatientWarrant,
    Hospital,
    HospitalPcare,
    Mortality,
    Sick,
    Healthy,
  },
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[2];
    },
    isInpatient() {
      return this.routeController === 'rawat-inap';
    },
    isIGD() {
      return this.routeController === 'klinik-igd';
    },
    isGeneral() {
      return this.routeController === 'poli-umum';
    },
    isPhysioterapist() {
      return this.routeController === 'poli-fisioterapi';
    },
    isDental() {
      return this.routeController === 'poli-gigi';
    },
    isKIA() {
      return this.routeController === 'poli-KIA';
    },
    isKB() {
      return this.routeController === 'poli-kb';
    },
    isVaccine() {
      return this.routeController === 'poli-imunisasi';
    },
  },
  data() {
    return {
      tab: null,
      isBpjs:
        this.patientData.meta.guarantorType.toLowerCase() == 'bpjs'
          ? true
          : false,
    };
  },
  watch: {
    tab: {
      handler() {
        const formRefs = [
          'formOutpatientWarrant',
          'formInpatientWarrant',
          'formHospitalPcare',
          'formHospital',
          'formMortality',
          'formHealthy',
          'formSick',
        ];

        formRefs.forEach(refName => {
          const formRef = this?.$refs[refName];
          formRef && formRef.$refs?.form?.resetValidation();
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  font-family: 'Roboto', sans-serif;
}
::v-deep input,
::v-deep textarea,
::v-deep .v-select__selections {
  font-size: 14px;
}

::v-deep .v-text-field__suffix {
  font-size: 14px;
  font-weight: 700;
}
</style>
