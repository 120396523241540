<template>
  <v-container fluid class="pa-0">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      :mini-variant-width="isLargeDisplay ? 90 : 60"
      color="#DEEEF9"
      class="pa-2 sidebar"
      permanent
      :width="isLargeDisplay ? 450 : 400"
    >
      <v-list dense class="pa-0">
        <v-list-item
          :class="{
            'mx-auto': mini,
            'pa-0': mini && !isLargeDisplay,
            'pa-2 pl-4': !mini && isLargeDisplay,
            'pl-3 px-0': !mini && !isLargeDisplay,
          }"
          class="py-2 mb-5"
        >
          <v-btn :small="!isLargeDisplay" color="black" text fab v-if="mini"
            ><v-icon :small="!isLargeDisplay">mdi-menu</v-icon></v-btn
          >
          <h2 v-if="isLargeDisplay" class="sidebar-title">
            Dokumen
          </h2>
          <h3 v-else class="sidebar-title mr-10">
            Dokumen
          </h3>
          <v-btn small class="ml-auto" fab text @click.stop="mini = !mini">
            <v-icon :large="isLargeDisplay">mdi-chevron-left</v-icon>
          </v-btn></v-list-item
        >
        <template v-if="!mini">
          <div
            class="list"
            v-for="(item, index) in filteredItems"
            :key="`list-${index}`"
            @click="selectedForm = index"
            :class="{ active: selectedForm === index }"
          >
            <div class="label-container">
              <label>{{ item.label }}</label>
            </div>
          </div>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-card class="pa-0 main" tile>
      <div class="header-container">
        <div class="meta-container">
          <label class="mr-8">No RM</label>
          <label class="mr-13 font-weight-bold">{{
            patientData.rmNumber
          }}</label>
          <label class="mr-8">Nama</label>
          <label class="mr-13 font-weight-bold">{{
            `${this.patientData.meta.name} (${
              this.patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
            })`
          }}</label>
          <label class="mr-8">Tanggal Lahir</label>
          <label class="mr-13 font-weight-bold">{{
            `${patientBirthDate} (${this.patientData.meta.age} Tahun)`
          }}</label>
        </div>
        <v-btn fab text @click="$emit('close-dialog')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
      <v-divider></v-divider>
      <list-informed-consent v-if="selectedForm === 1" :unit="unit" />
      <general-consent-form
        v-if="selectedForm === 0 && props.generalConsent"
        is-from-service-dashboard
        :rm-number="socialData.rmNumber"
        service-type="Rawat Jalan"
      />
      <div
        class="d-flex align-center justify-center"
        style="height: 80vh; color: #757575"
        v-if="selectedForm === 0 && !props.generalConsent"
      >
        General Consent atas nama pasien
        {{ patientData.meta.name.split(',')[0] }} <br />
        belum diproses di bagian pendaftaran
      </div>
      <patient-list-ssc v-if="selectedForm === 2" />
      <operation-marking-list v-if="selectedForm === 3" />
    </v-card>
  </v-container>
</template>

<script>
import ListInformedConsent from '../../Registration/components/InformedConsent/ListInformedConsent.vue';
import OperationMarkingList from '../../Registration/components/OperationMarking/OperationMarkingList.vue';
import GeneralConsentForm from '../../Registration/components/GeneralConsentForm.vue';
import PatientListSsc from './ConsentDocuments/PatientListSsc.vue';

const moment = require('moment-timezone');

export default {
  name: 'PrintDocuments',
  components: {
    ListInformedConsent,
    PatientListSsc,
    GeneralConsentForm,
    OperationMarkingList,
  },
  props: {
    unit: String,
    patientData: Object,
    props: { type: Object, default: () => {} },
    menuGeneralConsent: {
      type: Boolean,
      default: true,
    },
    poly: String,
  },
  data() {
    return {
      drawer: true,
      mini: false,
      dialog: false,
      selectedForm: 0,
      itemList: [
        {
          label: 'General Consent',
        },
        {
          label: 'Informed Consent',
        },
        {
          label: 'Surgical Safety Checklist (SSC)',
        },
        {
          label: 'Penandaan Operasi',
        },
      ],
    };
  },
  computed: {
    isLargeDisplay() {
      return this.$vuetify.breakpoint.name === 'xl';
    },
    socialData() {
      return {
        name: this.patientData.meta.name,
        address: this.patientData.meta.address,
        telp: this.patientData.meta.phoneNumber || '',
        identityNumber: this.patientData.metaidentityNumber || '',
        age: this.patientData.meta.age,
        gender: this.patientData.meta.gender,
        rmNumber: this.patientData?.rmNumber,
        birthDate: moment(
          this.patientData.meta.birthDateUnformatted,
          'YYYY-MM-DD',
        ).format('DD/MM/YYYY'),
      };
    },
    patientBirthDate() {
      return moment(this.patientData.meta.birthDate, 'DD MMMM YYYY').format(
        'D MMMM YYYY',
      );
    },
    filteredItems() {
      if (
        this.poly === 'poli umum' ||
        this.poly === 'poli KIA' ||
        this.poly === 'poli fisioterapi' ||
        this.poly === 'poli KB'
      ) {
        return this?.itemList;
      } else {
        return this?.itemList?.slice(0, 3);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: 100vh;
  display: flex;

  .sidebar {
    overflow-y: hidden;
    max-width: 20vw;

    .list-label {
      font-size: 0.8vw;
      margin: 0.4vw 0;
    }

    .list {
      font-weight: 500;
      height: 3vw;
      text-align: center;
      cursor: pointer;
      border-radius: 8px;

      .label-container {
        display: flex;
        text-align: start;
        align-items: center;
        padding-left: 1vw;
        width: 100%;
        height: 100%;
      }

      :hover {
        background-color: #cbe4f6;
      }

      &.active {
        background-color: #fff;
        color: #137bc0;
        :hover {
          background-color: #fff;
        }
      }

      label {
        cursor: pointer;
      }
    }

    ::-webkit-scrollbar {
      width: 3px;
      background-color: #c2c2c2;
    }
    ::-webkit-scrollbar-thumb {
      width: 3px;
      background-color: #919191;
    }
  }

  .main {
    width: 100%;
    max-height: 100vh;
    overflow: hidden;

    .header-container {
      display: flex;
      justify-content: space-between;
      .meta-container {
        display: flex;
        align-content: center;
        padding: 1vw 3vw;
        min-height: 3vw;
      }
    }
  }
}
</style>
