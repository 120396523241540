import Constant from '@/const';
import axios from 'axios';

const putStatusStaff = async (id, payload) => {
  try {
    const response = await axios.put(
      Constant.apiUrl.concat(`/master/staff/status/${id}`),
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default putStatusStaff;
