<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title cols="8" class="text-left pl-2">
        <h2 class="title pa-0">Jadwal Dokter</h2>
        <h5 class="subtitle">Pengaturan Jadwal Dokter dan Kuota Pasien</h5>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <router-link to="/pendaftaran/jadwal-dokter">
        <v-btn class="mr-5" icon>
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </router-link>
      <v-btn
        dark
        color="primary"
        depressed
        to="/pendaftaran/jadwal-dokter/pengaturan/ubah-jadwal"
      >
        <span class="font-weight-medium text-capitalize">Reschedule</span>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <SchedulePractice />
  </div>
</template>

<script>
import SchedulePractice from './SchedulePractice';

export default {
  components: {
    SchedulePractice,
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.title {
  font-size: 22px;
  font-weight: 700;
}
</style>
