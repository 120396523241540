<template>
  <div class="main">
    <div :class="isShowQueueControlCard ? 'mb-3' : ''">
      <section class="grid-header">
        <div>
          <h2 class="text-capitalize">{{ polyName }}</h2>
          <h5>
            {{ patient?.served }} pasien dilayani,
            {{ patient?.unserved }} pasien belum dilayani, dari
            {{ patient?.total }} total pasien
            <v-progress-circular
              size="16"
              color="#adb5bd"
              width="2"
              :indeterminate="loading.serve"
              v-if="loading.serve"
            ></v-progress-circular>
          </h5>
        </div>
        <div class="px-0">
          <Datepicker
            :show="menu"
            :date="date.raw"
            @date-change="handleDateChange"
          />
        </div>
        <div class="px-0">
          <v-combobox
            open-on-clear
            outlined
            dense
            clearable
            hide-details
            :placeholder="
              `Pilih dokter ${
                polyName.toLowerCase() === 'poli kia' ? 'atau bidan' : ''
              }`
            "
            append-icon="mdi-chevron-down"
            class="ml-3"
            item-value="id"
            item-text="name"
            :items="resource?.doctors"
            v-model="selectedDoctor"
            :loading="loading.doctor"
            @change="onChangeDoctor"
          />
        </div>
        <div class="px-0">
          <v-btn color="grey" icon text @click="refresh()">
            <v-icon :class="{ refresh: isRefreshing }">mdi-autorenew </v-icon>
          </v-btn>
        </div>
        <div class="px-0">
          <v-text-field
            background-color="grey lighten-3"
            placeholder="Cari Pasien..."
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
          ></v-text-field>
        </div>
      </section>
    </div>
    <div
      v-if="isShowDetailQueueNonDoctor && !isDoctor && !isMidwife"
      class="d-flex align-center justify-space-between mx-5 my-3"
    >
      <div class="d-flex align-center queue-text">
        <span class="text-link" @click="toDetailPatient"
          >Display Antrean (TV)

          <v-icon color="primary">
            mdi-arrow-right
          </v-icon>
        </span>
        <span class="ml-3 text-link" @click="toDetailQueue"
          >Detail Antrean
          <v-icon color="primary">
            mdi-arrow-right
          </v-icon>
        </span>
      </div>
      <div class="d-flex align-center ">
        <v-icon :disabled="isTestSoundActive" color="primary"
          >mdi-volume-high</v-icon
        >
        <span
          @click="testSound"
          v-if="!isTestSoundActive"
          class="ml-2 text-link queue-text"
          >Test Sound</span
        >
        <label v-else class="ml-2 wait-sound-text">Sedang Diproses...</label>
      </div>
    </div>
    <dashboard-patient-queue-card
      v-if="isShowQueueControlCard && idUnit"
      poly="poli-umum"
      :payload="{
        id_unit: idUnit,
        id_staff: selectedDoctor?.id,
        date: date || moment().format('YYYY-MM-DD'),
      }"
    />
    <v-divider></v-divider>
    <v-container
      fluid
      :class="
        `pa-0 table-container ${
          isShowQueueControlCard ? 'show-queue-card' : ''
        } ${isShowDetailQueueNonDoctor && !isDoctor ? 'show-queue-nurse' : ''}`
      "
    >
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :items-per-page="itemsPerPage"
        @click:row="onClickRowTable"
        :loading="loading.table"
        :expanded="expanded"
        item-key="noRegist"
        no-data-text="Tidak ada pasien"
        single-expand
        hide-default-footer
      >
        <template v-slot:[`item.name`]="{ item }">
          <span class="mr-1">{{ item.name }}</span>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                v-if="item?.pcare?.visit && !item.pcare?.visit?.isSuccess"
                color="red"
                light
                small
                class="send-btn"
                height="500"
                @click="resendAssessmentPcare(item)"
                ><v-icon left class="icon" color="white" x-small
                  >mdi-send-outline</v-icon
                ></v-chip
              >
            </template>
            <span>{{ item?.pcare?.visit?.message }}</span>
          </v-tooltip>
          <v-icon
            v-if="item?.pcare?.visit && item.pcare?.visit?.isSuccess"
            color="green"
            >mdi-check-circle</v-icon
          >
        </template>
        <template v-slot:[`item.assessment`]="{ item }">
          <v-icon
            color="green"
            v-if="item.activityStatus.nurseAssessment === 'confirmed'"
            >mdi-check</v-icon
          >
          <v-icon v-if="item.activityStatus.nurseAssessment === 'not_scheduled'"
            >mdi-minus-thick</v-icon
          >
        </template>
        <template v-slot:[`item.nurseAssessment`]="{ item }">
          <v-icon
            color="green"
            v-if="item.activityStatus.nurseAssessment === 'confirmed'"
            >mdi-check</v-icon
          >
          <v-icon v-if="item.activityStatus.nurseAssessment === 'not_scheduled'"
            >mdi-minus-thick</v-icon
          >
        </template>
        <template v-slot:[`item.doctorAssessment`]="{ item }">
          <v-icon
            color="green"
            v-if="item.activityStatus.doctorAssessment === 'confirmed'"
            >mdi-check</v-icon
          >
          <v-icon
            v-if="item.activityStatus.doctorAssessment === 'not_scheduled'"
            >mdi-minus-thick</v-icon
          >
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon
            color="green"
            v-if="item.activityStatus.serviceStatus === 'confirmed'"
            >mdi-check</v-icon
          >
          <v-icon v-if="item.activityStatus.serviceStatus === 'not_scheduled'"
            >mdi-minus-thick</v-icon
          >
        </template>
        <template v-slot:[`item.laboratory`]="{ item }">
          <v-icon
            color="green"
            v-if="item.activityStatus.labStatus === 'confirmed'"
            >mdi-check</v-icon
          >
          <v-icon v-if="item.activityStatus.labStatus === 'scheduled'"
            >mdi-clock-time-three-outline</v-icon
          >
          <v-icon v-if="item.activityStatus.labStatus === 'not_scheduled'"
            >mdi-minus-thick</v-icon
          >
        </template>
        <template v-slot:[`item.erecipe`]="{ item }">
          <v-icon
            color="green"
            v-if="item.activityStatus.recipeStatus === 'confirmed'"
            >mdi-check</v-icon
          >
          <v-icon v-if="item.activityStatus.recipeStatus === 'scheduled'"
            >mdi-clock-time-three-outline</v-icon
          >
          <v-icon v-if="item.activityStatus.recipeStatus === 'not_scheduled'"
            >mdi-minus-thick</v-icon
          >
        </template>
        <template v-slot:expanded-item="{ item, headers }">
          <td :colspan="headers.length" class="px-0">
            <v-card flat>
              <div class="expanded-grid">
                <div></div>
                <span
                  >Terdaftar {{ item.timestamps.created_at }} WIB <br />
                  {{ item.date }}
                </span>
                <span>{{ item.gender }} {{ item.age }} Tahun</span>
                <span>{{ item.isNew }}</span>
              </div>
            </v-card>
            <v-card
              color="grey lighten-4"
              class="d-flex justify-end py-2 px-4"
              flat
            >
              <v-tooltip
                v-for="(menu, index) in resource.menus.filter(i =>
                  item.isFinish ? i.showMenuWhenPatientFinished : i,
                )"
                :key="`menu-${index}`"
                bottom
              >
                <template #activator="{ on: tooltip }">
                  <v-btn
                    icon
                    class="mx-2 hover"
                    v-on="{ ...tooltip }"
                    @click="selectItem(item, menu.index)"
                  >
                    <font-awesome-icon
                      v-if="menu.label !== 'Dokumen'"
                      :icon="menu.icon"
                    />
                    <v-icon v-else size="21" style="padding-top: 2px">{{
                      menu.icon
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  menu.label === 'Asesmen'
                    ? `${menu.label} ${(
                        polyName.split(' ')[1] || ''
                      ).replace(/^\w/, c => c.toUpperCase())}`
                    : menu.label
                }}</span>
              </v-tooltip>

              <v-menu left :offset-x="true" :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-btn icon v-bind="attrs" v-on="on" class="mx-2">
                      <v-icon size="21" style="padding-top: 2px">
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </span>
                </template>

                <v-list style="padding: 0px; min-width: 12.5rem;">
                  <v-list-item @click="selectItem(item, 8)" class="dropdown">
                    <v-list-item-title>
                      <span class="dropdown-item">
                        Identifikasi Pasien
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-footer class="d-flex justify-space-between" color="white">
      <v-pagination
        v-if="table?.items?.length"
        v-model="table.pagination.page"
        :length="table.pagination.length"
        total-visible="5"
        @input="refreshAndAutoUpdate()"
      ></v-pagination>
      <v-btn
        v-if="isShowDetailQueueButton"
        @click="toDetailQueue"
        color="primary"
        dark
        depressed
        class="text-capitalize"
        >Dashboard Antrean <v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn
      >
    </v-footer>
    <v-dialog
      v-model="dialog"
      :fullscreen="
        selectedForm === 1 ||
          selectedForm === 4 ||
          selectedForm === 3 ||
          selectedForm === 6
      "
      persistent
      :max-width="
        selectedForm === null ? 1400 : dialogWidth[selectedForm].width
      "
    >
      <general-assessment
        v-if="selectedForm === 1 && dialog && polyName === 'poli fisioterapi'"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <social-data-patients
        v-if="selectedForm === 0 && dialog"
        @close-form="closeForm"
        :medical-record="selectedPatient.rmNumber"
      />
      <general-poly-assessment
        v-else-if="selectedForm === 1 && dialog && polyName === 'poli umum'"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <!-- <physiotherapist-assessment
        v-if="selectedForm === 1 && dialog && polyName === 'poli fisioterapi'"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      /> -->
      <kia-poly-assessment
        v-if="selectedForm === 1 && dialog && polyName === 'poli KIA'"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <dental-poly-assessment
        v-if="selectedForm === 1 && dialog && polyName === 'poli gigi'"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <kb-poly-assessment
        v-if="selectedForm === 1 && dialog && polyName === 'poli KB'"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <bhp-and-action
        v-else-if="selectedForm === 2 && dialog"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <e-recipe
        v-else-if="selectedForm === 3 && dialog"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <lab-request
        v-else-if="selectedForm === 4 && dialog"
        @close-dialog="closeForm"
        :patient-data="selectedPatient"
        :activityStatus="activityStatusLab"
        confirmationState="order"
      />
      <warrant
        v-else-if="selectedForm === 5 && dialog"
        @close-form="closeForm"
        :patient-data="selectedPatient"
      />
      <print-documents
        :poly="polyName"
        unit="outpatient"
        :props="{ generalConsent: generalConsentPatient }"
        @close-dialog="closeForm"
        v-else-if="selectedForm === 6 && dialog"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <finish-service
        v-else-if="selectedForm === 7 && dialog"
        @close-form="closeForm"
        :patient-data="selectedPatient"
      />
      <identification-patient
        v-if="selectedForm === 8 && dialog"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
    </v-dialog>
    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar?.show"
      elevation="0"
      :color="snackbar?.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar?.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </div>
</template>

<script>
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapGetters: mapPatientQueueGetters } = createNamespacedHelpers(
  'PatientQueue',
);
const { mapMutations: icMutations } = createNamespacedHelpers(
  'InformedConsent',
);
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'ExaminationLaboratory',
);
const { mapActions } = createNamespacedHelpers('PatientQueue');
const { mapMutations: labMutations } = createNamespacedHelpers(
  'ExaminationLaboratory',
);

// api calls
import {
  getServedCount,
  getPatientServiceByUnit,
} from '@/fetchApi/Service/Dashboard';
import { postAssessmentPcare } from '@/fetchApi/BPJS';
// import { postAssessmentPcare } from '@/fetctApi/BPJS';
import { getAllUnit } from '@/fetchApi/MasterData/Unit';
import { getAllStaff } from '@/fetchApi/MasterData/Staff';

// mapper
import {
  mapPatientServiceDashboardData,
  mapSelectedPatientData,
} from './utils/serviceDashboardDataMapper';

// components
import GeneralAssessment from './components/GeneralAssessment/GeneralAssessment.vue';
import SocialDataPatients from '../SharedComponent/SocialData.vue';
import GeneralPolyAssessment from './components/GeneralPoly/GeneralPolyAssestment.vue';
import KiaPolyAssessment from './components/KIApoly/Assessment';
import DentalPolyAssessment from './components/DentalPoly/DentalPolyAssestment.vue';
import KbPolyAssessment from './components/KBPoly/KBPolyAssestment.vue';
// import PhysiotherapistAssessment from './components/Physiotherapist/PhysiotherapistAssestment.vue';
import BhpAndAction from './components/BHPandAction';
import ERecipe from './components/ERecipe';
import Warrant from './components/Letters/Warrant';
import PrintDocuments from './components/PrintDocuments.vue';
import LabRequest from '../Laboratorium/components/ExaminationOrder/ExaminationDialogV2.vue';
import FinishService from './components/FinishService.vue';
import DashboardPatientQueueCard from './components/PatientQueue/DashboardPatientQueueCard/DashboardPatientQueueCard.vue';
import IdentificationPatient from '@/components/SharedComponent/Dialog/IdentificationPatient/IdentificationPatient.vue';
import Datepicker from '@/components/SharedComponent/Datepicker/Datepicker.vue';

// utils
const moment = require('moment-timezone');
const _ = require('lodash');
const axios = require('axios');
import Swal from 'sweetalert2';

axios.defaults.headers.post['Content-Type'] = 'application/json';
export default {
  name: 'ServiceDashboard',
  components: {
    GeneralAssessment,
    SocialDataPatients,
    GeneralPolyAssessment,
    KiaPolyAssessment,
    DentalPolyAssessment,
    BhpAndAction,
    ERecipe,
    LabRequest,
    Warrant,
    PrintDocuments,
    FinishService,
    DashboardPatientQueueCard,
    IdentificationPatient,
    Datepicker,
    KbPolyAssessment,
    // PhysiotherapistAssessment,
  },
  data() {
    return {
      search: '',
      selectedDoctor: '',
      activityStatusLab: '',
      menu: false,
      dialog: false,
      isRefreshing: false,
      generalConsentPatient: false,
      idUnit: null,
      selectedForm: null,
      interval: null,
      expanded: [],
      initialHeader: [],
      isTestSoundActive: false,
      date: {
        raw: moment().format('YYYY-MM-DD'),
        formatted: moment().format('DD-MM-YYYY'),
      },
      loading: {
        serve: false,
        doctor: false,
        table: false,
      },
      patient: {
        served: 0,
        unserved: 0,
        total: 0,
      },
      resource: {
        doctors: [],
        menus: [
          {
            label: 'Data Sosial',
            icon: 'user',
            index: 0,
            showMenuWhenPatientFinished: true,
          },
          {
            label: 'Asesmen',
            icon: 'notes-medical',
            index: 1,
            showMenuWhenPatientFinished: true,
          },
          {
            label: 'Tindakan dan BHP',
            icon: 'syringe',
            index: 2,
            showMenuWhenPatientFinished: true,
          },
          {
            label: 'E-Resep',
            icon: 'prescription-bottle',
            index: 3,
            showMenuWhenPatientFinished: true,
          },
          {
            label: 'Pemeriksaan Laboratorium',
            icon: 'flask',
            index: 4,
            showMenuWhenPatientFinished: false,
          },
          {
            label: 'Surat',
            icon: 'print',
            index: 5,
            showMenuWhenPatientFinished: true,
          },
          {
            label: 'Dokumen',
            icon: 'mdi-printer',
            index: 6,
            showMenuWhenPatientFinished: true,
          },
          {
            label: 'Selesai',
            icon: 'clipboard-check',
            index: 7,
            showMenuWhenPatientFinished: false,
          },
        ],
      },
      table: {
        headers: [
          {
            text: 'No. Antrian',
            align: 'start',
            value: 'queue',
            width: '5%',
            sortable: false,
          },
          {
            text: 'No. Pendaftaran',
            value: 'noRegist',
            width: '5%',
            sortable: false,
          },
          { text: 'Nama', value: 'name', width: '15%', sortable: false },
          { text: 'No. RM.', value: 'noRM', width: '5%', sortable: false },
          {
            text: 'Tipe Penjamin',
            value: 'guarantorType',
            width: '7%',
            sortable: false,
          },
          {
            text: 'Nama Dokter',
            value: 'doctor',
            width: '10%',
            sortable: false,
          },
          {
            text: 'Assesmen',
            value: 'assessment',
            align: 'center',
            width: '6%',
            sortable: false,
          },
          {
            text: 'As. Perawat',
            value: 'nurseAssessment',
            align: 'center',
            width: '6%',
            sortable: false,
          },
          {
            text: 'As. Dokter',
            value: 'doctorAssessment',
            align: 'center',
            width: '6%',
            sortable: false,
          },
          {
            text: 'Tindakan',
            align: 'center',
            value: 'action',
            width: '6%',
            sortable: false,
          },
          {
            text: 'Lab',
            align: 'center',
            value: 'laboratory',
            width: '6%',
            sortable: false,
          },
          {
            text: 'E-Resep',
            value: 'erecipe',
            align: 'center',
            width: '6%',
            sortable: false,
          },
        ],
        items: [],
        pagination: {
          page: 1,
          length: null,
        },
      },
      dialogWidth: [
        { name: 'SocialData', width: 1200 },
        { name: 'GeneralPoly', width: 1400 },
        { name: 'BHPandAction', width: 1400 },
        { name: 'ERecipe', width: 1400 },
        { name: 'LabRequest', width: 1400 },
        { name: 'Warrant', width: 1400 },
        { name: 'PrintDocument', width: 1400 },
        { name: 'FinishPatient', width: 346 },
        { text: 'IdentificationPatient', width: 600 },
      ],
    };
  },
  mounted() {
    this.filterTableHeaders(true);
    this.soundFinish();
  },
  watch: {
    polyName() {
      this.onChangePoly();
      this.filterTableHeaders(false);
    },
    search() {
      this.table.pagination.page = 1;
      this.searchData(this);
    },
  },
  computed: {
    ...mapGetters(['getSnackbar']),
    ...mapPatientQueueGetters(['getIsHasSchedule']),
    // GENERAL POLY is poly except poli umum, poli gigi, poli KIA
    isGeneralPoly: {
      get() {
        return !['poli umum', 'poli gigi', 'poli KIA', 'poli KB'].includes(
          this.polyName,
        );
      },
    },
    snackbar: {
      get() {
        return this.getSnackbar;
      },
      set(value) {
        this.setSnackbar(value);
      },
    },
    isDoctor: {
      get() {
        return this.$store.getters.userLoggedIn.role.some(
          item => item.toLowerCase() === 'dokter',
        );
      },
    },
    isMidwife: {
      get() {
        return this.$store.getters.userLoggedIn.role.some(
          item => item.toLowerCase() === 'bidan',
        );
      },
    },
    isNurse: {
      get() {
        return this.$store.getters.userLoggedIn.role.some(
          item => item.toLowerCase() === 'perawat',
        );
      },
    },
    selectedPatient: {
      get() {
        return this.$store?.getters?.selectedPatient || null;
      },
    },
    itemsPerPage: {
      get() {
        let totalitems = '';
        switch (this.$vuetify.breakpoint.name) {
          case 'xs' || 'sm' || 'md':
            totalitems = 5;
            break;
          case 'lg':
            totalitems = 7;
            break;
          case 'xl':
            totalitems = this.isShowQueueControlCard ? 9 : 12;
            break;
          default:
            return 5;
        }
        return totalitems;
      },
    },
    today: {
      get() {
        return moment().format('YYYY-MM-DD');
      },
    },
    polyName: {
      get() {
        let nameArr = this.$route.path.split('/')[2].split('-');
        if (nameArr[1] === 'kb') nameArr[1] = nameArr[1].toUpperCase();

        return nameArr.join(' ');
      },
    },
    isShowQueueControlCard: {
      get() {
        return (
          this.date.raw === this.today &&
          this.selectedDoctor &&
          (this.isDoctor ||
            (this.polyName.toLowerCase() === 'poli kia' && this.isMidwife)) &&
          this.isHasSchedule
        );
      },
    },
    isShowDetailQueueNonDoctor: {
      get() {
        return (
          this.date.raw === this.today &&
          this.selectedDoctor &&
          this.isHasSchedule
        );
      },
    },
    isShowDetailQueueButton: {
      get() {
        return (
          this.date.raw === this.today &&
          this.selectedDoctor &&
          !this.isDoctor &&
          this.isHasSchedule
        );
      },
    },
    isHasSchedule: {
      get() {
        return this.getIsHasSchedule;
      },
    },
  },
  methods: {
    ...mapActions(['resolveGetPatientQueue']),
    ...mapMutations(['setShowDialog', 'setSnackbar', 'clearSnackbar']),
    ...labMutations(['setShowDialog', 'setSnackbar', 'clearSnackbar']),
    ...icMutations(['setDataPatient']),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.table.pagination.page = 1;
      v.refreshAndAutoUpdate();
    }, 650),
    testSound() {
      this.$socket.client.emit('message:check-sound');
    },
    async soundFinish() {
      this.$socket.client.on('message:check-sound-finish', () => {
        setTimeout(() => (this.isTestSoundActive = false), 3000);
      });
      this.$socket.client.on('message:check-sound-active', () => {
        this.isTestSoundActive = true;
      });
    },
    toDetailPatient() {
      window.open(`/antrean-pasien`, '_blank');
    },
    onChangePoly() {
      this.table.pagination.page = 1;
      this.patient = {
        served: 0,
        unserved: 0,
        total: 0,
      };
      this.selectedDoctor = null;
      this.table.items = [];
      this.date = {
        raw: this.today,
        formatted: moment(this.today, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      };
      this.refreshAndAutoUpdate();
    },
    filterTableHeaders(isMounted) {
      if (isMounted) {
        this.initialHeader = this.table.headers;
      }

      if (this.isGeneralPoly) {
        this.table.headers = this.initialHeader.filter(
          v => v.value !== 'nurseAssessment' && v.value !== 'doctorAssessment',
        );
      } else {
        this.table.headers = this.initialHeader.filter(
          v => v.value !== 'assessment',
        );
      }
    },
    onInputDate(isCleared) {
      this.table.pagination.page = 1;
      this.$refs.menu.save(this.formatDate(this.date.raw));
      if (isCleared) {
        this.date.raw = this.today;
      }
      this.refreshAndAutoUpdate();
    },
    handleDateChange(updatedDate) {
      this.date.raw = updatedDate;
      this.table.pagination.page = 1;
      this.refreshAndAutoUpdate();
    },
    onChangeDoctor() {
      this.table.pagination.page = 1;
      this.getAllPatients();
      this.handleGetPatientQueue();
    },
    toDetailQueue() {
      window.open(
        `/pelayanan/poli-umum/antrean/${this.selectedDoctor?.id}`,
        '_blank',
      );
      localStorage.setItem(
        'detailQueuePayload',
        JSON.stringify({
          id_unit: this.idUnit,
          id_staff: this.selectedDoctor?.id,
          date: this.date || moment().format('YYYY-MM-DD'),
        }),
      );
    },
    async handleGetPatientQueue() {
      await this.resolveGetPatientQueue({
        payload: {
          id_unit: this.idUnit,
          id_staff: this.selectedDoctor?.id,
          date: this.date.raw || moment().format('YYYY-MM-DD'),
        },
      });
    },
    refresh() {
      this.isRefreshing = true;
      this.refreshAndAutoUpdate();
    },
    closeForm() {
      this.dialog = false;
      this.refreshAndAutoUpdate();
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
    async resendAssessmentPcare(item) {
      try {
        await postAssessmentPcare(item.id_registration);
        Swal.fire('Asesmen Berhasil dikirim ulang', '', 'success');
      } catch (error) {
        this.showErrorAxios(error);
      } finally {
        // this.showAllPatients();
        this.getAllPatients();
      }
    },
    async getUnitId() {
      try {
        //  since this function called first, all loading state
        // should set to true at the start of this function
        this.loading = {
          serve: true,
          doctor: true,
          table: true,
        };

        const response = await getAllUnit({ keyword: this.polyName });
        const [unit] = response.data;
        this.idUnit = unit._id;
      } catch {
        this.idUnit = null;
      }
    },
    async getDoctorsByUnit() {
      try {
        const response = await getAllStaff({
          itemCount: 50,
          id_unit: this.idUnit,
        });

        this.resource.doctors = response.data.map(item => {
          return {
            id: item._id,
            name: item.detail.name,
          };
        });
      } catch {
        this.resource.doctors = [];
      } finally {
        this.loading.doctor = false;
      }
    },
    async resolveGetServedCount() {
      try {
        const params = {
          id_unit: this.idUnit,
          startDate: this.date.raw || this.today,
          endDate: this.date.raw || this.today,
        };

        const response = await getServedCount(params);
        const { servicedPatient, unservicedPatient } = response.data.data;
        this.patient = {
          served: servicedPatient,
          unserved: unservicedPatient,
          total: servicedPatient + unservicedPatient,
        };
      } catch {
        this.patient = {
          served: 0,
          unserved: 0,
          total: 0,
        };
      } finally {
        this.loading.serve = false;
      }
    },
    async getAllPatients() {
      try {
        this.loading.table = true;
        const params = {
          search: this.search || '',
          itemCount: this.itemsPerPage,
          unit_name: _.startCase(this.polyName),
          date: this.date.raw || this.today,
          doctor: this.selectedDoctor?.id,
          page: this.table.pagination.page,
        };
        const response = await getPatientServiceByUnit(params);

        this.table.pagination.length = Math.ceil(
          response.data.length / this.itemsPerPage,
        );

        this.table.items = mapPatientServiceDashboardData(response.data.data);
      } catch {
        this.table.items = [];
      } finally {
        this.loading.table = false;
        this.isRefreshing = false;
      }
    },
    onClickRowTable(value) {
      if (this.expanded?.[0]?.noRegist === value.noRegist) {
        this.expanded = [];
        return;
      }
      this.expanded = [];
      this.expanded.push(value);
    },
    async refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      await this.getUnitId();
      this.getAllPatients();
      this.resolveGetServedCount();
      this.getDoctorsByUnit();
      this.interval = setInterval(this.getAllPatients, 60000);
    },
    selectItem(item, index) {
      this.setDataPatient(item); // set store data for informed consent needs
      this.changeSelectedPatient(item); // set store data for "selected patient" needs
      this.activityStatusLab = item.activityStatus?.labStatus;
      this.setShowDialog(true); // set dialog for laboratory
      this.selectedForm = index;
      this.dialog = true;
    },
    changeSelectedPatient(item) {
      this.generalConsentPatient = item.hasGeneralConsent;
      this.$store.commit('changeSelectedPatient', mapSelectedPatientData(item));
    },
  },
  // component life cycles
  created() {
    this.refreshAndAutoUpdate();
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.main {
  .grid-header {
    display: grid;
    width: 100%;
    align-items: center;
    text-align: start;
    grid-template-columns: 1fr 0.6fr 0.6fr 0.1fr 0.6fr;
    padding: 0.5vw;

    h2 {
      font-size: 1.1vw;
    }

    h5 {
      font-size: 0.7vw;
      font-weight: 500;
      color: #adb5bd;
    }
  }

  .expanded-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 13.8vw 16.5vw 13.3vw auto;
    color: #9e9e9e;
    text-align: start;
    align-items: center;
  }

  .send-btn {
    cursor: pointer;
    width: 25px !important;
    padding-bottom: 3px;
    padding-left: 14px;
    border-radius: 7px !important;
    margin-left: 5px;
    .icon {
      rotate: -45deg;
    }
  }

  .refresh {
    animation: rotation 0.23s infinite;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .table-container {
    height: 72vh;
    overflow-y: auto;

    &.show-queue-card {
      height: 55vh;
      overflow-y: auto;
    }

    &.show-queue-nurse {
      height: 67vh;
      overflow-y: auto;
    }
  }

  ::v-deep .v-list-item .v-list-item__content {
    line-height: 1.1;
    flex: 1 0 100%;
    text-align: start;
  }

  ::v-deep {
    .v-data-table
      > .v-data-table__wrapper
      tbody
      tr.v-data-table__expanded__content {
      box-shadow: none;
    }

    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr
      > td {
      font-size: 0.75vw;
    }

    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:not(:last-child)
      > td:not(.v-data-table__mobile-row),
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:not(:last-child)
      > th:not(.v-data-table__mobile-row) {
      border: none;
    }

    .v-icon:hover {
      color: #2196f3;
    }

    tbody {
      border-left: 2px solid blue !important ;
    }
  }
}
.dropdown {
  min-height: 3rem;
  border: 1px solid black;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  text-align: left;
}
.dropdown:hover {
  background-color: #e6f6fe;
  cursor: pointer;
}
.dropdown-item {
  color: #404040;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.queue-text {
  font-weight: 500;
  color: #137bc0;
  letter-spacing: 0.001vw;
}

.wait-sound-text {
  font-weight: 500;
  color: #9ca2a5;
  letter-spacing: 0.001vw;
}

.text-link:hover {
  // text-decoration: underline;
  cursor: pointer;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
