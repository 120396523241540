import axiosClient from '../../apiClient';

const getReportBPJS = async params => {
  const { isExport, ...restParams } = params;
  return await axiosClient.get(
    `/report/${isExport ? 'referral_pcare_export' : 'patient-referral-pcare'}`,
    { params: { ...restParams } },
  );
};

const getReportDashboardBPJS = async ({ date = '', type = '' } = {}) => {
  const text = (() => {
    switch (type) {
      case 'doctor':
        return 'group-doctor';
      case 'instance':
        return 'group-instance';
      case 'total':
        return 'total-report';
    }
  })();

  return await axiosClient.get(
    `/report/patient-referral-bpjs/${text}?date=${date}`,
  );
};

export { getReportBPJS, getReportDashboardBPJS };
