import Constant from '@/const';
import axios from 'axios';

const getAssessmentIgd = async options => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/patient/igd/assessment/${options.id_registration}?triase_number=${options.triase_number}`,
      ),
    );
    return response.data.data;
  } catch (error) {
    throw new Error(error);
  }
};

export default getAssessmentIgd;
