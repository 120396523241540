import Constant from '@/const';
import axios from 'axios';

const postEarlyAssessmentInpatient = async (id, payload) => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat(`/inpatient/firstassesment/${id}`),
      payload,
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default postEarlyAssessmentInpatient;
