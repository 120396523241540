import Constant from '@/const';
import axios from 'axios';

const deleteDiagnose = async id => {
  try {
    const response = await axios.delete(
      Constant.apiUrl.concat(`/master/diagnose/${id}`),
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default deleteDiagnose;
