<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="font-weight-bold">{{ title }}</span>
      <v-btn absolute right icon @click="onCloseButtonClick()">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-container class="container-layout">
        <v-form lazy-validation ref="form">
          <v-row no-gutters>
            <v-col md="10" offset-md="1">
              <v-row>
                <v-col md="5">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="name">NIP</label>
                    </div>

                    <v-text-field
                      dense
                      :loading="isLoadingData"
                      :rules="rules.nip"
                      v-model="input.nip"
                      :readonly="!isUpdateable"
                      placeholder="Masukkan NIP"
                      @keyup="handleChangeNIP"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="name">Nama Lengkap</label>
                    </div>

                    <v-text-field
                      dense
                      :loading="isLoadingData"
                      :rules="rules.name"
                      :readonly="!isUpdateable"
                      v-model="input.name"
                      placeholder="Masukkan Nama Lengkap"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="name">NIK</label>
                    </div>

                    <v-text-field
                      dense
                      type="number"
                      counter="16"
                      :loading="isLoadingData"
                      v-on:keypress="isNumber($event)"
                      :rules="rules.nik"
                      :readonly="!isUpdateable"
                      v-model="input.nik"
                      placeholder="Masukkan NIK"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-radio">
                    <div class="detail-input__label">
                      <label class="required" for="name">Jenis Kelamin</label>
                    </div>
                    <v-radio-group
                      dense
                      :readonly="!isUpdateable"
                      v-model="input.gender"
                      :rules="rules.gender"
                      row
                    >
                      <v-radio label="Laki-laki" :value="true"></v-radio>
                      <v-radio label="Perempuan" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="detail-date">
                    <div class="detail-input__label">
                      <label class="required" for="Tanggal Lahir"
                        >Tanggal Lahir</label
                      >
                    </div>
                    <v-menu
                      dense
                      v-model="menu1"
                      :readonly="!isUpdateable"
                      :return-value.sync="input.birth.date"
                      :disabled="!isUpdateable"
                      transition="scale-transition"
                      ref="menu1"
                      min-width="290px"
                      offset-y
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :rules="rules.birthDate"
                          dense
                          class="detail-input__input-text"
                          :loading="isLoadingData"
                          v-model="input.birth.date"
                          placeholder="YYYY/MM/DD"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :max="today"
                        :readonly="!isUpdateable"
                        v-model="input.birth.date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu1 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu1.save(input.birth.date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="name">Tempat Lahir</label>
                    </div>

                    <v-text-field
                      dense
                      :readonly="!isUpdateable"
                      :rules="rules.birthPlace"
                      v-model="input.birth.place"
                      :loading="isLoadingData"
                      placeholder="Masukkan Tempat Lahir"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Alamat</label>
                    </div>
                    <!-- :rules="rules.address" -->
                    <v-textarea
                      dense
                      v-model="input.address"
                      :readonly="!isUpdateable"
                      :loading="isLoadingData"
                      class="detail-input__input-text"
                      outlined
                      name="input-7-4"
                    ></v-textarea>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">No Telepon</label>
                    </div>

                    <v-text-field
                      type="number"
                      dense
                      :loading="isLoadingData"
                      :readonly="!isUpdateable"
                      v-on:keypress="isNumber($event)"
                      v-model="input.telp"
                      placeholder="Masukkan No Telepon"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="agama">Agama</label>
                    </div>
                    <v-autocomplete
                      dense
                      placeholder="Pilih Agama"
                      class="detail-input__input-text"
                      append-icon="mdi-chevron-down"
                      :readonly="!isUpdateable"
                      :loading="isLoadingData"
                      v-model="input.religion"
                      :items="resource.religion"
                    ></v-autocomplete>
                  </div>
                </v-col>
                <v-col md="2"></v-col>
                <v-col md="5">
                  <div class="detail-input" v-if="formType === 'add'">
                    <div class="detail-input__label">
                      <label class="required" for="name">Password</label>
                    </div>

                    <v-text-field
                      :rules="rules.password"
                      placeholder="Password"
                      id="password"
                      autocomplete="new-password"
                      class="detail-input__input-text"
                      v-model="input.password"
                      :error-messages="passwordError"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      validate-on-blur
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="golongandarah">Golongan Darah</label>
                    </div>
                    <v-autocomplete
                      dense
                      placeholder="Pilih Golongan Darah"
                      class="detail-input__input-text"
                      append-icon="mdi-chevron-down"
                      :readonly="!isUpdateable"
                      :loading="isLoadingData"
                      v-model="input.bloodType"
                      :items="resource.bloodType"
                    ></v-autocomplete>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">STR</label>
                    </div>

                    <v-text-field
                      dense
                      placeholder="Masukkan STR"
                      class="detail-input__input-text"
                      required
                      :readonly="!isUpdateable"
                      :loading="isLoadingData"
                      v-model="input.str"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Batas STR</label>
                    </div>

                    <v-text-field
                      dense
                      placeholder="Masukkan Batas STR"
                      class="detail-input__input-text"
                      :readonly="!isUpdateable"
                      v-model="input.strLimit"
                      :loading="isLoadingData"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">SIP</label>
                    </div>

                    <v-text-field
                      dense
                      v-model="input.sip"
                      :readonly="!isUpdateable"
                      :loading="isLoadingData"
                      placeholder="Masukkan SIP"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Batas SIP</label>
                    </div>

                    <v-text-field
                      dense
                      placeholder="Masukkan Balas SIP"
                      class="detail-input__input-text"
                      :readonly="!isUpdateable"
                      :loading="isLoadingData"
                      v-model="input.sipLimit"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">SIK</label>
                    </div>

                    <v-text-field
                      dense
                      v-model="input.sik"
                      :readonly="!isUpdateable"
                      :loading="isLoadingData"
                      placeholder="Masukkan SIK"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Batas SIK</label>
                    </div>

                    <v-text-field
                      dense
                      v-model="input.sikLimit"
                      :loading="isLoadingData"
                      :readonly="!isUpdateable"
                      placeholder="Masukkan SIK"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="specialis">Pilih Spesialis</label>
                    </div>
                    <v-combobox
                      dense
                      placeholder="Pilih Spesialis"
                      append-icon="mdi-chevron-down"
                      class="detail-input__input-text"
                      :loading="isLoading.specialist"
                      :readonly="!isUpdateable"
                      v-model="input.specialist"
                      :items="resource.specialize"
                    >
                    </v-combobox>
                  </div>
                  <div class="detail-date">
                    <div class="detail-input__label">
                      <label for="name">Tanggal Mulai Praktek</label>
                    </div>
                    <v-menu
                      dense
                      offset-y
                      :disabled="!isUpdateable"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="input.practiceStartDate"
                      :loading="isLoadingData"
                      :readonly="!isUpdateable"
                      transition="scale-transition"
                      ref="menu2"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          class="detail-input__input-text"
                          v-model="input.practiceStartDate"
                          placeholder="YYYY/MM/DD"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="input.practiceStartDate"
                        :readonly="!isUpdateable"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu2.save(input.practiceStartDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col md="10" offset-md="1">
              <v-row>
                <v-col md="5">
                  <v-card-title class="justify-center">
                    <span class="font-weight-bold black--text"
                      >Rekening Bank</span
                    >
                  </v-card-title>

                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="namabank">Nama Bank</label>
                    </div>
                    <v-autocomplete
                      dense
                      return-object
                      :loading="isLoading.bank"
                      item-text="name"
                      placeholder="Pilih Bank"
                      :readonly="!isUpdateable"
                      class="detail-input__input-text"
                      append-icon="mdi-chevron-down"
                      v-model="input.bank.bank"
                      :items="resource.bank"
                    ></v-autocomplete>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Nomor Rekening</label>
                    </div>

                    <v-text-field
                      dense
                      type="number"
                      :readonly="!isUpdateable"
                      v-on:keypress="isNumber($event)"
                      v-model="input.bank.account"
                      placeholder="Masukkan Rekening"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                </v-col>
                <v-col md="2"></v-col>
                <v-col md="5">
                  <v-card-title class="justify-center">
                    <span class="font-weight-bold black--text">Data Role</span>
                  </v-card-title>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required">Role Utama</label>
                    </div>
                    <v-autocomplete
                      dense
                      validate-on-blur
                      :rules="rules.role"
                      :readonly="!isUpdateable"
                      placeholder="Pilih Role"
                      item-text="text"
                      item-value="value"
                      class="detail-input__input-text"
                      append-icon="mdi-chevron-down"
                      v-model="input.role.rolePrimary"
                      :items="resource.roles"
                      :loading="isLoading.role"
                    ></v-autocomplete>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label class="required" for="Poli">Unit / Poli</label>
                    </div>
                    <v-autocomplete
                      placeholder="Pilih Beberapa Poli"
                      append-icon="mdi-chevron-down"
                      class="detail-input__input-text"
                      :loading="isLoading.unit"
                      :readonly="!isUpdateable"
                      item-text="unit_name"
                      v-model="input.role.unit"
                      :rules="rules.unit"
                      :items="resource.units"
                      return-object
                      validate-on-blur
                      dense
                      multiple
                      hide-selected
                    ></v-autocomplete>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label>Role Sekunder</label>
                    </div>
                    <v-autocomplete
                      multiple
                      dense
                      validate-on-blur
                      :readonly="!isUpdateable"
                      placeholder="Pilih Role"
                      class="detail-input__input-text"
                      append-icon="mdi-chevron-down"
                      item-text="text"
                      item-value="value"
                      v-model="input.role.roleSecondary"
                      :search-input.sync="search"
                      @change="search = ''"
                      :items="
                        resource.roles.filter(
                          item =>
                            item?.text?.toLowerCase() !==
                            input?.role?.rolePrimary?.toLowerCase(),
                        )
                      "
                      :loading="isLoading.role"
                    ></v-autocomplete>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Detail</label>
                    </div>

                    <v-text-field
                      dense
                      :readonly="!isUpdateable"
                      v-model="input.role.detail"
                      placeholder="Masukkan Detail"
                      :loading="isLoadingData"
                      class="detail-input__input-text"
                      required
                    />
                  </div>
                  <div class="detail-radio">
                    <div class="detail-input__label">
                      <label class="required" for="name">Status</label>
                    </div>
                    <v-radio-group
                      dense
                      v-model="input.role.status"
                      :readonly="!isUpdateable"
                      :rules="rules.status"
                      row
                    >
                      <v-radio label="Aktif" :value="true"></v-radio>
                      <v-radio label="Tidak Aktif" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn width="100" text class="text-none" @click="onCloseButtonClick()">
        Kembali
      </v-btn>
      <v-btn
        v-if="isUpdateable"
        :loading="isLoading.save || isLoadingData"
        color="primary"
        width="100"
        class="text-none"
        depressed
        @click="
          postStaffData();
          updateData();
        "
      >
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import postStaff from '@/fetchApi/MasterData/Staff/postStaff';
import putStaff from '@/fetchApi/MasterData/Staff/putStaff';
import getRoles from '@/fetchApi/MasterData/Staff/getRoles';
import getBanks from '@/fetchApi/MasterData/Staff/getBanks';
import getSpecialist from '@/fetchApi/MasterData/Staff/getSpecialist';
import getAllUnit from '@/fetchApi/MasterData/Unit/getAllUnit';
import { scrollToEmptyInput } from '@/helper';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'MasterStaff',
);

const moment = require('moment');
const _ = require('lodash');

export default {
  data() {
    return {
      isAdmin: false,
      isLoading: {
        role: false,
        specialist: false,
        unit: false,
        bank: false,
        save: false,
      },
      showPassword: false,
      password: 'Password',
      date: null,
      search: null,
      menu1: false,
      menu2: false,
      startPractice_menu: false,
      passwordError: [],
      rules: {
        name: [v => !!v || 'Perlu mengisi nama'],
        nip: [v => !!v || 'Perlu mengisi NIP'],
        nik: [
          v => !!v || 'Perlu mengisi NIK',
          v => v.length < 17 || 'Maksimal 16 karakter',
        ],
        gender: [v => v !== '' || 'Perlu mengisi jenis kelamin'],
        birthDate: [v => !!v || 'Perlu mengisi tanggal lahir'],
        birthPlace: [v => !!v || 'Perlu mengisi tempat lahir'],
        address: [v => !!v || 'Perlu mengisi alamat'],
        role: [v => !!v || 'Perlu mengisi role'],
        unit: [v => v.length !== 0 || 'Perlu mengisi unit'],
        status: [v => v === true || v === false || 'Perlu mengisi status'],
        password: [
          v => v !== '' || 'Perlu mengisi password',
          v => v.length >= 8 || 'Minimal 8 karakter',
          v =>
            v.match(/([a-zA-Z]+.*\d+)|(\d+.*[a-zA-Z]+)/) ||
            'Password harus mengandung huruf dan angka',
          v =>
            !v.match(/[!@#$%^&*()_+{}:;<>,.?~\\/-]/) ||
            'Hanya boleh mengandung huruf dan angka',
        ],
      },
      resource: {
        status: [
          { text: 'Tersedia', value: true },
          { text: 'Tidak Tersedia', value: false },
        ],
        religion: [
          { text: 'Budha', value: 'budha' },
          { text: 'Hindu', value: 'hindu' },
          { text: 'Islam', value: 'islam' },
          { text: 'Katolik', value: 'katolik' },
          { text: 'Konghucu', value: 'konghucu' },
          { text: 'Kristen', value: 'kristen' },
        ],
        bloodType: [
          { text: 'A', value: 'a' },
          { text: 'B', value: 'b' },
          { text: 'AB', value: 'ab' },
          { text: 'O', value: 'o' },
          { text: 'A Rh+', value: 'a rh+' },
          { text: 'A Rh-', value: 'a rh-' },
          { text: 'AB Rh+', value: 'ab rh+' },
          { text: 'AB Rh-', value: 'ab rh-' },
          { text: 'B Rh+', value: 'b rh+' },
          { text: 'B Rh-', value: 'b rh-' },
          { text: 'O Rh+', value: 'o rh+' },
          { text: 'O Rh-', value: 'o rh-' },
        ],
        roles: [],
        units: [],
        specialize: [],
        bank: [],
      },
    };
  },
  computed: {
    ...mapGetters(['getItems', 'getFormType']),
    today() {
      return moment(new Date()).format();
    },
    input: {
      get() {
        return this.getItems.form.data;
      },
    },
    isLoadingData: {
      get() {
        return this.getItems.form.isLoading;
      },
    },
    isUpdateable: {
      get() {
        return this.getFormType !== 'detail';
      },
    },
    formType: {
      get() {
        return this.getFormType;
      },
    },
    title: {
      get() {
        let result;
        switch (this.getFormType) {
          case 'add':
            result = 'Tambah Data Staf';
            break;
          case 'detail':
            result = 'Detail Staf';
            break;
          case 'edit':
            result = 'Ubah Data Staf';
            break;
        }
        return result;
      },
    },
  },
  mounted() {
    this.getAllRole();
    this.getAllBank();
    this.getUnit();
    this.getAllSpecialize();
  },
  beforeDestroy() {
    this.clearFormState();
  },
  watch: {
    'input.password'(v) {
      if (v === '') {
        this.passwordError = ['Perlu mengisi password'];
      } else if (v.length <= 8) {
        this.passwordError = ['Minimal 8 karakter'];
      } else if (
        !v.match(/([a-zA-Z]+.*\d+)|(\d+.*[a-zA-Z]+)/) &&
        v.length >= 8
      ) {
        this.passwordError = ['Password harus mengandung huruf dan angka'];
      } else if (v.match(/[!@#$%^&*()_+{}:;<>,.?~\\/-]/) && v.length >= 8) {
        this.passwordError = ['Hanya boleh mengandung huruf dan angka'];
      } else {
        this.passwordError = [];
      }
    },
  },
  methods: {
    ...mapMutations(['clearFormState', 'setSnackbar']),
    ...mapActions(['resolvePostCheckNIPAvail']),
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    limitChar(type) {
      switch (type) {
        case 'nik':
          if (this.input.nik.length > 3) {
            this.input.nik = this.input.nik.substring(
              0,
              this.input.nik.length - 1,
            );
          }
      }
    },
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
    async updateData() {
      if (await this.$refs.form.validate()) {
        this.$emit('update-data');
      } else {
        scrollToEmptyInput();
      }
    },
    handleChangeNIP: _.debounce(function(event) {
      this.resolvePostCheckNIPAvail(event.target.value).then(res => {
        if (res === true) {
          this.rules.nip = [v => !!v || 'Perlu mengisi NIP'];
        } else {
          this.rules.nip = [
            v => !!v || 'Perlu mengisi NIP',
            res === true || 'NIP tidak boleh sama',
          ];
        }
      });
    }, 600),
    async getAllRole() {
      try {
        this.isLoading.role = true;
        const response = await getRoles();
        const { data } = response.data;
        this.resource.roles = data.map(role => {
          return {
            text: role.description,
            value: role.name,
          };
        });
      } catch {
        this.resource.roles = [];
      } finally {
        this.isLoading.role = false;
      }
    },
    async getAllBank() {
      try {
        this.isLoading.bank = true;
        const response = await getBanks();
        const { data } = response.data;
        this.resource.bank = data.map(bank => {
          return {
            value: bank._id,
            name: bank.name,
          };
        });
      } catch {
        this.resource.bank = [];
      } finally {
        this.isLoading.bank = false;
      }
    },
    async getAllSpecialize() {
      try {
        this.isLoading.specialist = true;
        const response = await getSpecialist();
        const { data } = response.data;
        this.resource.specialize = data.map(specialize => {
          return specialize.name;
        });
      } catch {
        this.resource.specialize = [];
      } finally {
        this.isLoading.specialist = false;
      }
    },
    async getUnit() {
      try {
        this.isLoading.unit = true;
        const response = await getAllUnit();
        const { data } = response;
        this.resource.units = data.map(unit => {
          return {
            id_unit: unit._id,
            unit_name: unit.name,
          };
        });
      } catch {
        this.resource.units = [];
      } finally {
        this.isLoading.unit = false;
      }
    },
    async postStaffData() {
      if (await this.$refs.form.validate()) {
        try {
          this.isLoading.save = true;
          const role = [this.input.role.rolePrimary].concat(
            this.input.role.roleSecondary,
          );
          const payload = {
            id: this.input.id || undefined,
            nip: this.input.nip,
            detail: {
              name: this.input.name,
              nik: this.input.nik,
              password: this.input.password,
              address: this.input.address,
              gender: this.input.gender,
              religion: this.input.religion,
              blood_type: this.input.bloodType,
              phone_no: this.input.telp,
              birth: {
                city: this.input.birth.place,
                date: this.input.birth.date,
              },
              str: this.input.str,
              end_str: this.input.strLimit,
              sip: this.input.sip,
              end_sip: this.input.sipLimit,
              sik: this.input.sik,
              end_sik: this.input.sikLimit,
              start_practice: this.input.practiceStartDate,
              specialize: this.input.specialist,
              bank: {
                id_bank: this.input.bank?.bank?.value || null,
                account: this.input.bank.account,
              },
            },
            role: {
              role: role.map(item => item.toLowerCase()),
              unit: this.input.role.unit.map(unit => {
                return {
                  id_unit: unit.id_unit,
                };
              }),
              detail: this.input.role.detail,
              status: this.input.role.status,
            },
          };
          if (this.formType === 'add') {
            await postStaff(payload);
          } else {
            await putStaff(payload);
          }
          Swal.fire('Berhasil', 'Data Staff Sudah Masuk', 'success');
          this.onCloseButtonClick();
        } catch (error) {
          if (error.request) {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Tidak dapat menyambung ke server',
            });
          }
          this.setSnackbar({
            show: true,
            color: 'error',
            text: 'Terjadi kesalahan, gagal menambahkan atau mengubah staf',
          });
        } finally {
          this.isLoading.save = false;
          this.$emit('update-data');
        }
      } else {
        scrollToEmptyInput();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  display: grid;
  grid-template-columns: 150px 250px;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}
.container-layout {
  height: 75vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 2px;
    background-color: #a3a3a3;
  }
  &::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: black;
  }
}
input:placeholder-shown {
  font-size: 0.5em;
}

.required:after {
  content: ' *';
  color: red;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
