<template>
  <v-card>
    <v-container class="container-layout">
      <h2 class="py-5 black--text text-left" v-if="isAssessment">
        Riwayat Asesmen Pasien
      </h2>
      <v-row class="justify-center align-center" style="height: 350px;" v-else>
        <h1>Belum ada riwayat asesmen pasien</h1>
      </v-row>
      <template v-for="(history, index) in history">
        <v-expansion-panels flat accordion focusable :key="'history-' + index">
          <v-expansion-panel>
            <v-expansion-panel-header
              hide-actions
              class="blue--text font-weight-bold heading-5 py-1"
              @click="expand()"
            >
              <v-col cols="1" style="width: 30px;" class="pa-0">
                <v-icon color="blue" v-if="!expandableDate">
                  mdi-chevron-right-box
                </v-icon>
                <v-icon color="blue" v-else>
                  mdi-chevron-down-box
                </v-icon>
              </v-col>
              <v-col>
                {{ history.date }}
                {{ history.detail.created_at }}
              </v-col>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-title class="title font-weight-bold primary--text">
                Data Subjektif
              </v-card-title>
              <v-row>
                <v-col cols="5">
                  <v-row no-gutters align="baseline">
                    <v-col cols="7">
                      <v-row no-gutters justify="start" class="mb-7 mr-10">
                        <label
                          for="Kategori Imunisasi"
                          class="black--text ml-4 font-weight-bold text-left"
                          >Kategori Imunisasi</label
                        >
                      </v-row>
                    </v-col>
                    <v-col cols="5" class="text-left">
                      <label class="text-left">{{
                        history.detail.subjective.vaccineCategory
                      }}</label>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5">
                  <v-row no-gutters align="baseline">
                    <v-col cols="5">
                      <v-row no-gutters justify="start" class="mb-7 mr-10"
                        ><label
                          for="Riwayat Alergi"
                          class="black--text font-weight-bold"
                          >Riwayat Alergi</label
                        ></v-row
                      >
                    </v-col>
                    <v-col cols="6" class="text-left">
                      <template v-for="(item, indexing) in history.allergy">
                        <ul :key="'anamnesis-' + indexing">
                          <li>{{ item }}</li>
                        </ul>
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5">
                  <v-row no-gutters align="baseline">
                    <v-col cols="7">
                      <v-row no-gutters justify="start" class="mb-7 mr-10">
                        <label
                          for="Riwayat Penyakit Sebelumnya"
                          class="black--text ml-4 font-weight-bold text-left"
                          >Riwayat Penyakit Sebelumnya</label
                        ></v-row
                      >
                    </v-col>
                    <v-col cols="5" class="text-left">
                      <template
                        v-for="(disease, indexing) in history.detail.subjective
                          .priorDisease"
                      >
                        <ul :key="'anamnesis-' + indexing">
                          <li>{{ disease }}</li>
                        </ul>
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-card-title
                class="title font-weight-bold primary--text text-left"
              >
                Data Objektif
              </v-card-title>
              <v-row>
                <v-col cols="5">
                  <v-row no-gutters align="baseline" class="grid-ttv">
                    <label
                      for="Tekanan Darah"
                      class="black--text font-weight-bold text-right mr-10"
                      >Tekanan Darah</label
                    >
                    <label class="text-left"
                      >{{ history.detail.objective.ttv.sistole }}/{{
                        history.detail.objective.ttv.diastole
                      }}
                      <b>mm/Hg</b></label
                    >

                    <label
                      for="Nadi"
                      class="black--text font-weight-bold text-right mr-10"
                      >Nadi</label
                    >
                    <label class="text-left"
                      >{{ history.detail.objective.ttv.pulse }}
                      <b>x/menit</b></label
                    >

                    <label
                      for="Berat Badan"
                      class="black--text font-weight-bold text-right mr-10"
                      >Berat Badan</label
                    >
                    <label class="text-left"
                      >{{ history.detail.objective.ttv.weight }}
                      <b>kg</b></label
                    >

                    <label
                      for="Tinggi Badan"
                      class="black--text font-weight-bold text-right mr-10"
                      >Tinggi Badan</label
                    >
                    <label class="text-left"
                      >{{ history.detail.objective.ttv.height }}
                      <b>cm</b></label
                    >
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row no-gutters align="baseline" class="grid-ttv">
                    <label
                      for="RR"
                      class="black--text font-weight-bold text-right mr-10"
                      >RR</label
                    >
                    <label class="text-left"
                      >{{ history.detail.objective.ttv.respiration }}
                      <b>x/menit</b></label
                    >
                    <label
                      for="Saturasi 02"
                      class="black--text font-weight-bold text-right mr-10"
                      >Saturasi O<sub>2</sub></label
                    >
                    <label class="text-left"
                      >{{ history.detail.objective.ttv.saturation }}
                      <b>%</b></label
                    >
                    <label
                      for="Suhu"
                      class="black--text font-weight-bold text-right mr-10"
                      >Suhu</label
                    >
                    <label class="text-left"
                      >{{ history.detail.objective.ttv.temperature }}
                      <b><sup>O</sup>C</b></label
                    >
                    <label
                      for="Kesadaran"
                      class="black--text font-weight-bold text-right mr-10"
                      >Kesadaran</label
                    >
                    <label class="text-left">
                      {{
                        findAwareness(history.detail.objective.ttv.awareness)
                      }}
                    </label>
                    <!-- <label class="text-left">{{}}</label> -->
                  </v-row>
                </v-col>
              </v-row>

              <v-card-title
                class="title font-weight-bold primary--text text-left"
              >
                Assesmen
              </v-card-title>
              <v-row class="pb-5">
                <v-col cols="5">
                  <v-row no-gutters align="baseline">
                    <v-col cols="5"
                      ><v-row no-gutters justify="end" class="mr-10"
                        ><label
                          for="Diagnosa Utama"
                          class="black--text font-weight-bold"
                          >Diagnosa Utama</label
                        ></v-row
                      ></v-col
                    >
                    <v-col class="text-left">
                      {{ history.detail.assessment.mainDiagnose.text }}
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="5">
                  <v-row no-gutters align="baseline">
                    <v-col cols="5"
                      ><v-row no-gutters justify="end" class="mr-10"
                        ><label
                          for="Diagnosa Sekunder"
                          class="black--text font-weight-bold"
                          >Diagnosa Sekunder</label
                        ></v-row
                      ></v-col
                    >
                    <v-col cols="6" class="text-left">
                      <template
                        v-for="(diagnose, index) in history.detail.assessment
                          .secondaryDiagnose"
                      >
                        <ul :key="'secondary diagnose-' + index">
                          <li>
                            {{ diagnose.text.code }} {{ diagnose.text.text }}
                          </li>
                        </ul>
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-card-title class="title font-weight-bold primary--text">
                Planning
              </v-card-title>
              <v-row>
                <v-col cols="5">
                  <v-row no-gutters align="baseline">
                    <v-col cols="5">
                      <v-row no-gutters justify="end" class="mb-7 mr-10">
                        <label
                          for="Tindakan"
                          class="black--text font-weight-bold"
                          >Tindakan</label
                        >
                      </v-row>
                    </v-col>
                    <v-col cols="6" class="text-left">
                      <template
                        v-for="(action, index) in history.detail.planning
                          .action"
                      >
                        <ul :key="'action-' + index">
                          <li>{{ action }}</li>
                        </ul>
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5">
                  <v-row no-gutters align="baseline">
                    <v-col cols="5">
                      <v-row no-gutters justify="end" class="mr-10">
                        <label
                          for="Kode ICD 9 CM"
                          class="black--text font-weight-bold"
                          >Kode ICD 9 CM</label
                        ></v-row
                      >
                    </v-col>
                    <v-col cols="7" class="text-left">
                      <template
                        v-for="(procedure, index) in history.detail.planning
                          .procedure"
                      >
                        <ul :key="'procedure-' + index">
                          <li>
                            {{
                              procedure.id.code + ' ' + procedure.description
                            }}
                          </li>
                        </ul>
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-container>
  </v-card>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'VaccineHistoryAssesment',
  mixins: [jwtMixin],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      history: [],
      resource: {
        awareness: [
          { text: 'Compos Mentis', value: '01' },
          { text: 'Somnolence', value: '02' },
          { text: 'Sopor', value: '03' },
          { text: 'Coma', value: '04' },
        ],
      },
      expandableDate: false,
      // expandableTime: false,
      isAssessment: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getHistory();
    });
  },
  methods: {
    findAwareness(awareness) {
      // return this.resource.awareness.find(x => x.value === awareness).text;
      let text;
      for (let i = 0; i < this.resource.awareness.length; i++) {
        if (this.resource.awareness[i].value === awareness) {
          text = this.resource.awareness[i].text;
          break;
        }
      }
      return text;
    },
    getHistory() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/patient/emr/history/checkup?id_emr=${this.patientData.id_emr}&id_unit=${this.patientData.meta.id_poly}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            // history = data.filter(v => v.assesment.main_diagnose);
            if (data.length >= 1) {
              this.history = data.map(item => {
                return {
                  date: item.date,
                  allergy: item.subjective.allergy_history,
                  detail: {
                    created_at: moment(item.timestamps.created_at)
                      .locale('id')
                      .format('[Pukul] LT'),
                    subjective: {
                      priorDisease: item.subjective.disease_history,
                      vaccineCategory: item.vaccination?.category || '',
                    },
                    assessment: {
                      mainDiagnose: {
                        text:
                          item.assesment?.main_diagnose?.diagnose === undefined
                            ? '-'
                            : item.assesment?.main_diagnose?.diagnose.code +
                              ' ' +
                              item.assesment?.main_diagnose?.diagnose
                                .description,
                      },
                      secondaryDiagnose: item.assesment?.other_diagnose.map(
                        item => {
                          return {
                            detail: item.description,
                            text: {
                              code: _.get(item, 'diagnose.code', ''),
                              text: _.get(item, 'diagnose.description', ''),
                              id: _.get(item, 'diagnose._id', ''),
                            },
                          };
                        },
                      ),
                    },
                    objective: {
                      ttv: {
                        pulse: item.objective.heart_rate,
                        sistole: item.objective.sistol,
                        diastole: item.objective.diastol,
                        respiration: item.objective.respiration_rate,
                        temperature: item.objective.temperature,
                        saturation: item.objective.saturation,
                        awareness: item.objective?.awarness,
                        weight: item.objective.weight,
                        height: item.objective.height,
                      },
                    },
                    planning: {
                      action: item.planning.treatment.filter(x => x !== ''),
                      procedure: item.planning.procedure.filter(x => x !== ''),
                    },
                  },
                };
              });
              this.isAssessment = true;
            } else {
              this.isAssessment = false;
            }
          })
          .catch(error => {
            if (error.status == 404) {
              this.isAssessment = false;
            }
          });
      });
    },
    range(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    expand() {
      this.expandableDate = !this.expandableDate;
      // switch (val) {
      //   case 'date':
      //     this.expandableDate = !this.expandableDate;
      //     break;
      //   case 'time':
      //     this.expandableTime = !this.expandableTime;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-layout {
  height: 75vh;
  overflow: auto;
  overflow-x: hidden;
  .grid-ttv {
    display: grid;
    grid-template-columns: 190px 250px;
    grid-template-rows: repeat(4, 30px);
    grid-row-gap: 20px;
  }
  .dental-input {
    display: grid;
    grid-template-columns: 100px 200px;
  }
}
::v-deep {
  .v-expansion-panel-header--active {
    background-color: #86bcdb;
    color: #fff !important;
    min-height: 56px;
    border-radius: 5px;

    & > .v-expansion-panel-header__icon > i {
      color: #fff !important;
    }
  }
}
</style>
