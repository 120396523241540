<template>
  <div>
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col cols="6">
            <v-toolbar-title class="font-weight-bold float-left">
              Data Bed
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn class="mr-2" color="grey" icon text @click="handleGetAllBed()">
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            v-model="search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-data-table
              :headers="table.headers"
              :items="items.dataBed.data"
              :loading="items.dataBed.isLoading"
              single-expand
              :height="height"
              item-key="id"
              :items-per-page="itemsPerRow"
              hide-default-footer
              :page.sync="table.pagination.page"
              style="width: 60%"
            >
              <template v-slot:[`item.no`]="{ item }">
                <td class="text-left">
                  {{
                    (table.pagination.page - 1) * itemsPerRow +
                      items.dataBed.data.indexOf(item) +
                      1
                  }}
                </td>
              </template>
              <template v-slot:[`item.availability`]="{ item }">
                <td class="text-left">
                  {{ getAvailability(item.availability, item.gender) }}
                </td>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      :disabled="item.availability === 'occupied'"
                      v-on="on"
                      @click="deleteBed(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Hapus Data Bed</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      :disabled="item.availability === 'occupied'"
                      v-on="on"
                      @click="handleOpenDialog('edit', item)"
                    >
                      <v-icon>mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Ubah Data Bed</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-spacer></v-spacer>

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            @input="handleGetAllBed()"
            :length="pageLength"
            total-visible="10"
          ></v-pagination>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            fab
            dark
            @click="handleOpenDialog('add', undefined)"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-footer>
    <v-dialog v-model="dialog" width="300" persistent>
      <FormBed
        v-if="dialog"
        :state="state"
        :numberBed="numberBed"
        @close-dialog="handleCloseDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import FormBed from './components/Bed/FormBed.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'MasterBed',
);

const _ = require('lodash');

export default {
  name: 'MasterDataBed',
  components: {
    FormBed,
  },
  data() {
    return {
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Nomor Bed', value: 'bed_number' },
          { text: 'Ketersediaan', value: 'availability' },
          { text: '', value: 'action' },
        ],
        items: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
        },
      },
      pageLength: 0,
      bedData: '',
      search: '',
      state: 'add',
      numberBed: null,
    };
  },
  mounted() {
    this.handleGetAllBed();
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
  },
  computed: {
    ...mapGetters(['getItems', 'getDialog']),

    items: {
      get() {
        return this.getItems;
      },
    },

    dialog: {
      get() {
        return this.getDialog;
      },
      set(val) {
        return this.setDialog(val);
      },
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '60vh';
          break;
        case 'lg':
          height = '63vh';
          break;
        case 'xl':
          height = '70vh';
          break;
      }
      return height;
    },
  },
  methods: {
    ...mapMutations(['setDialog', 'setFilter', 'clearForm', 'setForm']),
    ...mapActions(['resolveGetAllBed', 'resolveDeleteBed']),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.handleGetAllBed();
    }, 650),

    handleOpenDialog(state, data) {
      this.state = state;
      this.setForm({
        id: data?.id || '',
        bed_number: data?.bed_number || null,
        availability: data?.availability || '',
      });
      this.numberBed = data?.bed_number || null;
      this.setDialog(true);
    },

    handleCloseDialog() {
      this.clearForm();
      this.setDialog(false);
    },

    getAvailability(availability, gender) {
      let result = '';
      if (availability === 'available') {
        result = 'Tersedia';
      } else if (availability === 'broken') {
        result = 'Tidak Tersedia (Rusak)';
      } else if (availability === 'occupied' && gender === true) {
        result = 'Ditempati Pasien Laki-Laki';
      } else if (availability === 'occupied' && gender === false) {
        result = 'Ditempati Pasien Perempuan';
      } else if (availability === 'occupied' && gender === null) {
        result = 'Ditempati Pasien';
      } else {
        result = '';
      }

      return result;
    },

    handleGetAllBed() {
      const halaman = this.table.pagination.page;
      const itemCount = this.itemsPerRow;
      const keyword = this.search;
      let sortBy = '';
      this.setFilter({
        halaman,
        itemCount,
        sortBy,
        keyword,
      });

      this.resolveGetAllBed({
        halaman,
        itemCount,
        sortBy,
        keyword,
      }).then(item => {
        this.pageLength = Math.ceil(item / itemCount);
      });
    },

    deleteBed(data) {
      Swal.fire({
        title: 'Yakin?',
        text: `Anda akan menghapus Bed '${data.bed_number} '`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.value) {
          this.resolveDeleteBed(data.id).then(res => {
            if (res.status === 200) {
              Swal.fire(
                'Berhasil',
                `Bed "${data.bed_number}" Berhasil Dihapus!`,
                'success',
              );
              this.handleGetAllBed();
            } else {
              Swal.fire(`Bed "${data.bed_number}" gagal dihapus`, '', 'error');
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
