import Constant from '@/const';
import axios from 'axios';

const putUpdateIcareData = async payload => {
  try {
    await axios.put(
      Constant.apiUrl.concat('/integration/pcare/icare'),
      payload,
    );
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default putUpdateIcareData;
