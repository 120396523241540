<template>
  <div class="monthly-illness-report">
    <v-toolbar flat outlined extension-height="80" extended prominent>
      <v-container fluid>
        <v-row align="center">
          <v-col cols="5" class="text-left">
            <h2>Laporan Bulanan 1 (LB 1)</h2>
            <h5 class="caption grey--text float-left">
              Laporan Kesakitan
            </h5>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <div class="caption grey--text float-left">
            Terakhir diperbarui pada: 10 November 2022
          </div> -->
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="input.dateStart"
                  append-icon="mdi-chevron-down"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  hide-details="false"
                  placeholder="Pilih Tanggal Awal"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="input.dateStart" @input="menu = false">
              </v-date-picker>
            </v-menu>
          </v-col>
          <span class="mt-5">s.d</span>
          <v-col cols="2">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details="false"
                  v-model="input.dateEnd"
                  class="input"
                  outlined
                  placeholder="Pilih Tanggal Akhir"
                  append-icon="mdi-chevron-down"
                  readonly
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="input.dateEnd" @input="menu2 = false">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-btn
            @click="getDisease()"
            class="mt-3 ml-3"
            color="primary"
            depressed
          >
            <span>Cari</span>
          </v-btn>
          <v-spacer></v-spacer>
          <router-link to="/reporting/laporan-pelayanan/">
            <v-btn color="grey" icon text>
              <v-icon>mdi-home </v-icon>
            </v-btn>
          </router-link>
          <v-btn
            class="mt-3"
            color="primary"
            @click="downloadSicknessReport"
            depressed
          >
            <span>Unduh Laporan</span>
          </v-btn>
        </v-row>
      </v-container>
      <template class="mx-3" v-slot:extension>
        <v-tabs
          class="mt-14"
          v-model="tabs"
          grow
          height="50px"
          slider-color="primary"
        >
          <v-tab
            class="text-capitalize font-weight-bold"
            @click="getDiseaseGraph()"
          >
            Data Laporan
          </v-tab>
          <v-tab
            class="text-capitalize font-weight-bold"
            @click="getDiseaseGraph()"
          >
            Grafik
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-main>
      <v-container fluid>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-flex xs12 class="report-container">
              <v-flex xs12 class="grid-display report-header">
                <div class="report-head rowSpan13 center">
                  <span>
                    No
                  </span>
                </div>
                <div class="report-head rowSpan13 center">
                  <span>ICDX</span>
                </div>
                <div class="report-head rowSpan13 center">
                  <span>Nama Penyakit</span>
                </div>
                <div class="report-head rowSpan12 colSpan13 center">
                  <span>1 Bln- &lt;1 Th</span>
                </div>
                <div class="report-head rowSpan12 colSpan35 center">
                  <span>1-4 Th</span>
                </div>
                <div class="report-head rowSpan12 colSpan57 center">
                  <span>5-9 Th</span>
                </div>
                <div class="report-head rowSpan12 colSpan714 center">
                  <span>10-14 Th</span>
                </div>
                <div class="report-head rowSpan12 colSpan816 center">
                  <span>15-19 Th</span>
                </div>
                <div class="report-head rowSpan12 colSpan999 center">
                  <span>20-44 Th</span>
                </div>
                <div class="report-head rowSpan12 colSpan1000 center">
                  <span>45-54 Th</span>
                </div>
                <div class="report-head rowSpan12 colSpan1010 center">
                  <span>55-59 Th</span>
                </div>
                <div class="report-head rowSpan12 colSpan1020 center">
                  <span>60-69 Th</span>
                </div>
                <div class="report-head rowSpan12 colSpan1030 center">
                  <span>&gt;70 Th</span>
                </div>
                <div class="report-head rowSpan12 colSpan1040 center">
                  <span>Jumlah</span>
                </div>
                <div class="report-head rowSpan13 center">
                  <span>Total</span>
                </div>

                <div class="report-sub-head rowSpan23">
                  <span>L</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>P</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>L</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>P</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>L</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>P</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>L</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>P</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>L</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>P</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>L</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>P</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>L</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>P</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>L</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>P</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>L</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>P</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>L</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>P</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>L</span>
                </div>
                <div class="report-sub-head rowSpan23">
                  <span>P</span>
                </div>
              </v-flex>
              <v-progress-linear
                indeterminate
                height="3"
                v-if="isFetching"
                class="pa-0 ma-0"
              />
              <v-flex xs12 class="table-container">
                <v-flex xs12 class="item-container pa-0">
                  <template v-for="(item, index) in monthlySickness">
                    <v-flex :key="index" xs12 class="grid-display report-items">
                      <span class="text-xs-center">{{ item.no }}</span>
                      <span class="text-xs-left">{{ item.icdx }}</span>
                      <span class="text-xs-center">{{ item.diagnose }}</span>

                      <!-- <1 th L -->
                      <span class="text-xs-center">{{
                        item.detail.male_less1
                      }}</span>

                      <!-- <1 Th P -->
                      <span class="text-xs-center">{{
                        item.detail.female_less1
                      }}</span>

                      <!-- 1-4 th L -->
                      <span class="text-xs-center">{{
                        item.detail.male_from1till4
                      }}</span>

                      <!-- 1-4 Th P -->
                      <span class="text-xs-center">{{
                        item.detail.female_from1till4
                      }}</span>

                      <!-- 5-9 th L -->
                      <span class="text-xs-center">{{
                        item.detail.male_from5till9
                      }}</span>

                      <!-- 5-9 Th P -->
                      <span class="text-xs-center">{{
                        item.detail.female_from5till9
                      }}</span>

                      <!-- 10 - 14 Th L -->
                      <span class="text-xs-center">{{
                        item.detail.male_from10till14
                      }}</span>

                      <!-- 10 - 14 Th P -->
                      <span class="text-xs-center">{{
                        item.detail.female_from10till14
                      }}</span>

                      <!-- 15 - 19 Th L -->
                      <span class="text-xs-center">{{
                        item.detail.male_from15till19
                      }}</span>

                      <!-- 15 - 19 Th P -->
                      <span class="text-xs-center">{{
                        item.detail.female_from15till19
                      }}</span>

                      <!-- 20 - 44 Th L -->
                      <span class="text-xs-center">{{
                        item.detail.male_from20till44
                      }}</span>

                      <!-- 20 - 44 Th P -->
                      <span class="text-xs-center">{{
                        item.detail.female_from20till44
                      }}</span>

                      <!-- 45 - 54 Th L -->
                      <span class="text-xs-center">{{
                        item.detail.male_from45till54
                      }}</span>

                      <!-- 45 - 54 Th P -->
                      <span class="text-xs-center">{{
                        item.detail.female_from45till54
                      }}</span>

                      <!-- 55 - 59 L Th -->
                      <span class="text-xs-center">{{
                        item.detail.male_from55till59
                      }}</span>

                      <!-- 55 - 59 P Th -->
                      <span class="text-xs-center">{{
                        item.detail.female_from55till59
                      }}</span>

                      <!-- 60 - 69 L Th -->
                      <span class="text-xs-center">{{
                        item.detail.male_from60till69
                      }}</span>

                      <!-- 60 - 69 P Th -->
                      <span class="text-xs-center">{{
                        item.detail.female_from60till69
                      }}</span>

                      <!-- >70 Th L -->
                      <span class="text-xs-center">{{
                        item.detail.male_more70
                      }}</span>

                      <!-- >70 Th P -->
                      <span class="text-xs-center">{{
                        item.detail.female_more70
                      }}</span>

                      <!-- Total male -->
                      <span class="text-xs-center">{{
                        totalMale(
                          item.detail.male_less1,
                          item.detail.male_from1till4,
                          item.detail.male_from5till9,
                          item.detail.male_from10till14,
                          item.detail.male_from15till19,
                          item.detail.male_from20till44,
                          item.detail.male_from45till54,
                          item.detail.male_from55till59,
                          item.detail.male_from60till69,
                          item.detail.male_more70,
                        )
                      }}</span>

                      <!-- Total female -->
                      <span class="text-xs-center">{{
                        totalFemale(
                          item.detail.female_less1,
                          item.detail.female_from1till4,
                          item.detail.female_from5till9,
                          item.detail.female_from10till14,
                          item.detail.female_from15till19,
                          item.detail.female_from20till44,
                          item.detail.female_from45till54,
                          item.detail.female_from55till59,
                          item.detail.female_from60till69,
                          item.detail.female_more70,
                        )
                      }}</span>

                      <!-- Total -->
                      <span class="text-xs-center">{{
                        total(
                          item.detail.male_less1,
                          item.detail.male_from1till4,
                          item.detail.male_from5till9,
                          item.detail.male_from10till14,
                          item.detail.male_from15till19,
                          item.detail.male_from20till44,
                          item.detail.male_from45till54,
                          item.detail.male_from55till59,
                          item.detail.male_from60till69,
                          item.detail.male_more70,
                          item.detail.female_less1,
                          item.detail.female_from1till4,
                          item.detail.female_from5till9,
                          item.detail.female_from10till14,
                          item.detail.female_from15till19,
                          item.detail.female_from20till44,
                          item.detail.female_from45till54,
                          item.detail.female_from55till59,
                          item.detail.female_from60till69,
                          item.detail.female_more70,
                        )
                      }}</span>
                    </v-flex>
                  </template>
                </v-flex>
                <div class="border-container">
                  <template v-for="n in 26">
                    <div class="border" :key="'border-' + n"></div>
                  </template>
                </div>
              </v-flex>
              <v-progress-linear
                indeterminate
                height="3"
                v-if="isFetching"
                class="pa-0 ma-0"
              />
              <v-layout align-center>
                <v-pagination
                  v-model="pagination.page"
                  :length="pages"
                  :total-visible="10"
                  class="mr-5"
                  @input="getDisease()"
                ></v-pagination>
              </v-layout>
            </v-flex>
          </v-tab-item>

          <v-tab-item>
            <v-row>
              <v-col cols="11" class="mt-3 mx-auto">
                <v-card
                  color="#F0F9FF"
                  flat
                  rounded="xl"
                  class="graph-table pa-5"
                >
                  <v-row class="text-left px-3 input-graph">
                    <span
                      class="text-xl font-weight-bold mt-5 mr-3 label-graph-input"
                      >Nama Penyakit</span
                    >
                    <v-col cols="3">
                      <v-combobox
                        background-color="white"
                        outlined
                        dense
                        :items="resource.disease"
                        item-text="view"
                        item-value="code"
                        hide-details="false"
                        placeholder="Pilih Penyakit"
                        v-model="input.disease"
                        :search-input.sync="searchDiagnose"
                        @change="getDiseaseGraph()"
                        :loading="isDiagnoseLoading"
                        clearable
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-flex class="chart-container">
                    <!-- class="graph-img" -->
                    <apexcharts
                      height="310"
                      ref="disease"
                      type="line"
                      :options="diseaseGraph.chartOptions"
                      :series="diseaseGraph.series"
                    >
                    </apexcharts>
                  </v-flex>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Constant from '@/const';
import VueApexCharts from 'vue-apexcharts';
import alertMixin from '@/mixin/alertMixin';
import Swal from 'sweetalert2';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
// const _ = require('lodash');

export default {
  name: 'MonthlyReport',
  mixins: [jwtMixin, alertMixin],
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      tabs: null,
      menu: false,
      menu2: false,
      snackbar: false,
      isFetching: false,
      input: {
        dateStart: '',
        dateEnd: '',
        disease: '',
      },
      resource: {
        disease: [],
      },
      diseaseGraph: {
        chartOptions: {
          chart: {
            id: 'disease',
          },
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'Mei',
              'Jun',
              'Jul',
              'Ags',
              'Sep',
              'Okt',
              'Nov',
              'Des',
            ],
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#83B4EE', '#FBAF18'],
          legend: {
            show: true,
          },
        },
        series: [
          {
            name: 'Laki-Laki',
            data: [],
          },
          {
            name: 'Perempuan',
            data: [],
          },
        ],
      },
      isLoading: false,
      dateStart: '',
      dateEnd: '',
      monthlySickness: [],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        totalItems: null,
      },
      pages: 0,
      isDiagnoseLoading: false,
      searchDiagnose: '',
    };
  },
  watch: {
    searchDiagnose(val) {
      if (val !== '') {
        this.getDiagnose(val);
      }
    },
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    async getDiagnose(keyword) {
      this.isDiagnoseLoading = true;
      await axios
        .get(
          Constant.apiUrl.concat(
            `/master/diagnose?page=1&itemCount=10&search=${
              keyword === null ? '' : keyword
            }&sort=-code`,
          ),
        )
        .then(response => {
          let allDiagnose = response.data.data;
          this.resource.disease = allDiagnose.map(diagnose => {
            return {
              code: diagnose.code,
              text: diagnose.description,
              view: `${diagnose.code} - ${diagnose.description}`,
            };
          });
          this.isDiagnoseLoading = false;
        })
        .catch(() => {
          this.isDiagnoseLoading = false;
        });
    },
    getDisease() {
      // this.getPages();
      const itemCount = this.itemsPerRow;
      const halaman = this.pagination.page;
      this.isFetching = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/report/sickness?page=${halaman}&itemCount=${itemCount}&start_date=${this
              .input.dateStart || ''}&last_date=${this.input.dateEnd || ''}`,
          ),
        )
        .then(response => {
          const result = response.data.data;
          this.pages = Math.ceil(response.data.length / itemCount);
          this.monthlySickness = [
            // const payload = [
            ...result.map((arr, idx) => ({
              ...arr,
              no: idx + itemCount * halaman - itemCount + 1,
              icdx: arr.data[0]._id.ICDX,
              diagnose: arr.data[0]._id.diagnose.split(',')[0],
              // male_less1: (arr._id.age === '20-44') & arr._id.gender ? arr.count : 0
              detail: {
                male_less1: arr.detail.male1 || 0,
                female_less1: arr.detail.female1 || 0,
                male_from1till4: arr.detail.male14 || 0,
                female_from1till4: arr.detail.female14 || 0,
                male_from5till9: arr.detail.male59 || 0,
                female_from5till9: arr.detail.female59 || 0,
                male_from10till14: arr.detail.male1014 || 0,
                female_from10till14: arr.detail.female1014 || 0,
                male_from15till19: arr.detail.male1519 || 0,
                female_from15till19: arr.detail.female1519 || 0,
                male_from20till44: arr.detail.male2044 || 0,
                female_from20till44: arr.detail.female2044 || 0,
                male_from45till54: arr.detail.male4554 || 0,
                female_from45till54: arr.detail.female4554 || 0,
                male_from55till59: arr.detail.male5559 || 0,
                female_from55till59: arr.detail.female5559 || 0,
                male_from60till69: arr.detail.male6069 || 0,
                female_from60till69: arr.detail.female6069 || 0,
                male_more70: arr.detail.male70 || 0,
                female_more70: arr.detail.female70 || 0,
              },
            })),
          ];
        })
        .catch(() => {
          this.monthlySickness = [];
          this.pagination.totalItems = 0;
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    getDiseaseGraph() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/report/sickness/graph/${this.input.disease.code || ''}`,
          ),
        )
        .then(response => {
          const { graph } = response.data.data;

          this.$refs.disease.updateSeries(
            [
              {
                name: 'Laki-Laki',
                data: graph.L,
              },
              {
                name: 'Perempuan',
                data: graph.P,
              },
            ],
            false,
            true,
          );
        });
    },
    async downloadSicknessReport() {
      try {
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/report/sickness/sickness_report?start_date=${this.input
              .dateStart || ''}&last_date=${this.input.dateEnd || ''}`,
          ),
        );
        if (res.status === 200) {
          this.showFillSuccess('Export Berhasil');
          const { url } = res.config;
          window.open(url, '_blank');
        }
      } catch (error) {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat mengexport dokumen',
          });
        }
      }
    },
    totalMale(...args) {
      return args.reduce((a, b) => a + b);
    },
    totalFemale(...args) {
      return args.reduce((a, b) => a + b);
    },
    total(...args) {
      return args.reduce((a, b) => a + b);
    },
  },
  // beforeMount() {
  //   this.getPages();
  // },
  mounted() {
    this.$nextTick(() => {
      this.getDisease();
      this.getDiagnose();
    });
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
}

.graph-table {
  height: 50vh;
}

table thead {
  background: #ededed;
}

table th {
  font-weight: 500;
  font-size: 12px;
  padding: 0.5rem;
}

table .subheader {
  background: #f5f5f5;
  font-size: 9px;
}

table tbody td {
  border-right: 1px solid #ededed;
  border-left: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  border-collapse: collapse;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-container {
  width: 100%;
  margin-top: 10px;
}
.grid-display {
  display: grid;
  // grid-gap: 16px;
  // padding: 16px;

  &.report-header {
    grid-template-columns: 0.1fr 0.2fr 0.4fr repeat(22, 0.1fr) 0.15fr;
    // grid-auto-flow: column;
    grid-auto-columns: minmax(1fr);
    span {
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      color: #404040;
    }
    .report-head {
      background: #deeef9;
      padding-bottom: 10px;
      padding-top: 10px;
    }
    .report-sub-head {
      background: #f0f9ff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      text-align: center;

      span {
        font-size: 9px;
        color: #404040;
      }
    }
  }
  &.report-items {
    grid-template-columns: 0.1fr 0.2fr 0.4fr repeat(22, 0.1fr) 0.15fr;
    grid-auto-flow: column;
    grid-auto-columns: minmax(1fr);
    grid-gap: 1px;
    span {
      padding: 0.5rem;
      font-size: 0.85714285714rem;
      line-height: 1.28571428571rem;
      font-weight: 400;
      color: #000000;
      white-space: pre-wrap;
      overflow-wrap: break-word !important;
      white-space: normal !important;
      text-align: left;
      box-sizing: border-box;
    }
  }
  .rowSpan23 {
    grid-row-end: 3;
    grid-row-start: 2;
  }
  .rowSpan12 {
    grid-row-end: 2;
    grid-row-start: 1;
  }
  .rowSpan13 {
    grid-row-end: 3;
    grid-row-start: 1;
  }
  .colSpan13 {
    grid-column-end: 6;
    grid-column-start: 4;
  }
  .colSpan35 {
    grid-column-end: 8;
    grid-column-start: 6;
  }
  .colSpan57 {
    grid-column-end: 10;
    grid-column-start: 8;
  }
  .colSpan714 {
    grid-column-end: 12;
    grid-column-start: 10;
  }
  .colSpan816 {
    grid-column-end: 14;
    grid-column-start: 12;
  }
  .colSpan999 {
    grid-column-end: 16;
    grid-column-start: 14;
  }

  .colSpan1000 {
    grid-column-end: 18;
    grid-column-start: 16;
  }

  .colSpan1010 {
    grid-column-end: 20;
    grid-column-start: 18;
  }

  .colSpan1020 {
    grid-column-end: 22;
    grid-column-start: 20;
  }

  .colSpan1030 {
    grid-column-end: 24;
    grid-column-start: 22;
  }

  .colSpan1040 {
    grid-column-end: 26;
    grid-column-start: 24;
  }
}
.table-container {
  position: relative;
  height: 45vh;
  .border-container {
    z-index: 1;
    position: absolute;
    display: grid;
    grid-template-columns: 0.1fr 0.2fr 0.4fr repeat(22, 0.1fr) 0.15fr;
    left: 0fr;
    top: 0;
    width: 100%;
    height: 100%;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    .border {
      width: 1px;
      background: #e0e0e0;
    }
  }
  .item-container {
    z-index: 5;
    position: absolute;
    background: transparent;
    height: 44vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    scrollbar-color: black rgb(163, 163, 163);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 3px;
      background-color: rgb(163, 163, 163);
    }
    &::-webkit-scrollbar-thumb {
      width: 3px;
      background-color: black;
    }
  }
}

.input-graph {
  width: 80%;
  margin: auto;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 100%;
  width: 80%;

  .graph-img {
    height: 100px;
  }
}
</style>
