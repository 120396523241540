const state = {
  other: '',
  laboratory: '',
  radiology: '',
  ekg: '',
};

const getters = {
  getSupportingInvestigation: state => state,
};

const mutations = {
  clearSupportingInvestigationState(state) {
    Object.assign(state, {
      other: '',
      laboratory: '',
      radiology: '',
      ekg: '',
    });
  },
  setSupport(state, payload) {
    Object.assign(state, { ...payload });
  },
};

export default {
  state,
  getters,
  mutations,
};
