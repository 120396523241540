<template>
  <div class="submenu-wrapper ">
    <router-link
      tag="div"
      :to="sub.route"
      class="submenu"
      v-for="(sub, index) in submenus"
      :key="index"
    >
      <div class="icon">
        <img :src="require(`@/assets/image/${sub.icon}.svg`)" />
      </div>
      <p class="font-weight-bold subtitle-1">{{ sub.title }}</p>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    submenus: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.submenu-wrapper {
  display: flex;

  .submenu {
    width: 100px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 25px;
    }
  }

  .icon {
    width: 85px;
    margin: 0 auto;
    height: 85px;
    display: flex;
    justify-content: center;
    background: #f0f9ff;
    border-radius: 10px;
    position: relative;
    padding: 0;

    img {
      position: relative;
      z-index: 2;
      height: 60px;
      margin: auto;
    }
    &:hover {
      border: 2px solid $primary-color;
      cursor: pointer;
    }

    &:after {
      display: block;
      width: 35px;
      height: 35px;
      border-radius: 100%;
      background: #fff0d9;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}
</style>
