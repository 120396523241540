<template>
  <v-row no-gutters class="d-flex flex-column">
    <div class="cashflow-style">
      <label class="font-weight-bold text-left"
        >ALIRAN KAS DARI AKTIVITAS OPERASIONAL</label
      >
      <label class="font-weight-bold text-right"></label>
      <label class="text-left pl-3">Surplus (Defisit) Tahun Berjalan</label>
      <label class="text-right pr-3">{{ formattedCurrency(10000000) }}</label>
      <label class="text-left pl-3"
        >Penyesuaian untuk Beban Depresiasi dan Amortisasi</label
      >
      <label class="text-right pr-3">{{ formattedCurrency(10000000) }}</label>
    </div>
    <div class="sub-style px-3">
      <label></label>
      <label class="font-weight-bold text-right">{{
        formattedCurrency(10000000)
      }}</label>
    </div>

    <div class="cashflow-style px-3">
      <label class="text-left">(Kenaikan) Penurunan Piutang Usaha</label>
      <label class="text-right">{{ formattedCurrency(10000000) }}</label>
    </div>

    <div class="sub-style px-3">
      <label class="text-left font-weight-medium"
        >Kas Bersih Diterima (Digunakan) untuk aktivitas operasional</label
      >
      <label class="text-right">{{ formattedCurrency(10000000) }}</label>
    </div>

    <div class="cashflow-style pt-5">
      <label class="font-weight-bold text-left"
        >ALIRAN KAS DARI AKTIVITAS INVESTASI</label
      >
      <label class="font-weight-bold text-right"></label>
      <label class="text-left pl-3">(Kenaikan) Penurunan Investasi</label>
      <label class="text-right pr-3">{{ formattedCurrency(10000000) }}</label>
    </div>

    <div class="sub-style px-3">
      <label class="text-left font-weight-medium"
        >Kas Bersih Diterima (Digunakan) unruk aktivitas investasi</label
      >
      <label class="text-right">{{ formattedCurrency(10000000) }}</label>
    </div>

    <div class="cashflow-style pt-5">
      <label class="font-weight-bold text-left"
        >ALIRAN KAS DARI AKTIVITAS PENDANAAN</label
      >
      <label class="font-weight-bold text-right"></label>
      <label class="text-left pl-3">Kenaikan (Penurunan) Modal Pemilik</label>
      <label class="text-right pr-3">{{ formattedCurrency(10000000) }}</label>
    </div>
    <div class="sub-style px-3">
      <label class="text-left font-weight-medium"
        >Kas Bersih Diterima (Digunakan) unruk aktivitas pendanaan</label
      >
      <label class="text-right">{{ formattedCurrency(10000000) }}</label>
    </div>

    <div class="cashflow-style pt-5">
      <label class="font-weight-bold text-left"
        >KENAIKAN (PENURUNAN) BERSIH KAS DAN SETARA KAS</label
      >
      <label class="text-right pr-3">{{ formattedCurrency(10000000) }}</label>
      <label class="font-weight-bold text-left"
        >KENAIKAN DAN SETARA KAS AWAL PERIODE</label
      >
      <label class="text-right pr-3">{{ formattedCurrency(10000000) }}</label>
    </div>
    <div class="total-style px-3">
      <label class="font-weight-bold text-left"
        >KAS DAN SETARA KAS AKHIR PERIODE</label
      >
      <label class="font-weight-bold text-right">{{
        formattedCurrency(10000000)
      }}</label>
    </div>
  </v-row>
</template>

<script>
import formatMixin from '@/mixin/formatMixin.js';

export default {
  name: 'Standard',
  mixins: [formatMixin],
};
</script>

<style>
.cashflow-style {
  display: grid;
  grid-template-columns: 80fr 20fr;
}
.sub-style {
  background-color: #deeef9;
  height: 5vh;
  margin: 5px 0;
  text-align: right;
  display: grid;
  grid-template-columns: 80fr 20fr;
  align-content: center;
}

.total-style {
  background-color: #addaf9;
  height: 5vh;
  display: grid;
  grid-template-columns: 80fr 20fr;
  align-content: center;
  margin: 5px 0;
}
</style>
