<template>
  <div>
    <v-toolbar flat outlined>
      <v-row align="center" class="header-page">
        <div cols="4">
          <h2 class="title">Poli Imunisasi</h2>
        </div>
        <div cols="2" class="px-0">
          <Datepicker
            :show="menu"
            :date="date"
            @date-change="handleDateChange"
          />
        </div>
        <div cols="2" class="px-0">
          <v-autocomplete
            placeholder="Pilih Dokter"
            class="ml-3"
            outlined
            dense
            :items="resource.doctor"
            item-value="doctorId"
            item-text="doctor"
            v-model="chooseDoctor"
            @change="
              table.pagination.page = 1;
              showAllVaccinePolyPatients();
              handleGetPatientQueue();
            "
            clearable
            :loading="loading"
            hide-details="false"
          ></v-autocomplete>
        </div>
        <div cols="1" class="px-0">
          <v-btn color="grey" icon text @click="refresh()">
            <v-icon :class="{ refresh: isRefresh }">mdi-autorenew </v-icon>
          </v-btn>
        </div>
        <div cols="3" class="px-0">
          <v-text-field
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
          ></v-text-field>
        </div>
      </v-row>
    </v-toolbar>
    <dashboard-patient-queue-card
      v-if="isShowQueueControlCard"
      poly="poli-vaksin"
      :payload="{
        id_unit: idUnit,
        id_staff: chooseDoctor,
        date: date || moment().format('YYYY-MM-DD'),
      }"
    />
    <v-main>
      <v-container fluid class="px-0">
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          :items-per-page="itemsPerRow"
          :page.sync="table.pagination.page"
          item-key="noRegist"
          :height="height"
          :expanded="expanded"
          hide-default-footer
          @click:row="clickedRow"
          :loading="loading"
          single-expand
        >
          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length" class="my-border">
              <v-card flat>
                <v-row no-gutters>
                  <v-col cols="2" style="max-width: 13%"></v-col>
                  <v-col
                    cols="2"
                    class="text-left grey--text caption"
                    style="max-width: 14.5%"
                  >
                    <span
                      >Terdaftar {{ item.timestamps.created_at }} WIB <br />{{
                        item.date
                      }}</span
                    ></v-col
                  >
                  <v-col
                    cols="3"
                    class="text-left grey--text caption"
                    style="max-width: 26%"
                  >
                    <span>{{ item.gender }} {{ item.age }} Tahun</span></v-col
                  >
                  <v-col cols="2" class="text-left grey--text caption">{{
                    item.isNew
                  }}</v-col>
                </v-row>
              </v-card>

              <v-card flat color="grey lighten-4" class="text-right">
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-tooltip bottom>
                        <template #activator="{on: tooltip}">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 0)"
                          >
                            <font-awesome-icon icon="user" />
                          </v-btn>
                        </template>
                        <span>Data Sosial</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 1)"
                          >
                            <font-awesome-icon icon="notes-medical" />
                          </v-btn>
                        </template>
                        <span>Asesmen</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            v-if="!item.isFinish"
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 2)"
                          >
                            <font-awesome-icon icon="syringe" />
                          </v-btn>
                        </template>
                        <span>Tindakan dan BHP</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 4)"
                          >
                            <font-awesome-icon icon="print" />
                          </v-btn>
                        </template>
                        <span>Pembuatan Surat</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="selectItem(item, 3)"
                          >
                            <v-icon size="21" style="padding-top: 2px"
                              >mdi-printer</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Dokumen</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-container>
      <v-dialog
        v-model="dialog"
        :max-width="
          selectedForm === null ? 1400 : dialogWidth[selectedForm].width
        "
        :fullscreen="selectedForm === 1 || selectedForm === 3"
        persistent
      >
        <SocialDataPatients
          v-if="selectedForm === 0 && dialog"
          @close-form="dialog = false"
          :medical-record="selectedPatient.rmNumber"
        />
        <vaccine-poly-assestment
          v-if="selectedForm === 1 && dialog"
          :patient-data="selectedPatient"
          @close-form="dialog = false"
        />
        <BHPandAction
          v-else-if="selectedForm === 2 && dialog"
          :patient-data="selectedPatient"
          @close-form="dialog = false"
        />
        <PrintDocuments
          poly="vaccine"
          unit="outpatient"
          v-else-if="selectedForm === 3 && dialog"
          :props="{ generalConsent: generalConsentPatient }"
          @close-form="dialog = false"
        />
        <Warrant
          v-else-if="selectedForm === 4 && dialog"
          @close-form="closeForm"
          :patient-data="selectedPatient"
        />
      </v-dialog>
    </v-main>
    <v-footer color="white">
      <v-pagination
        v-if="table?.items?.length"
        v-model="table.pagination.page"
        :length="pageLength"
        total-visible="5"
        @input="refreshAndAutoUpdate()"
      ></v-pagination>
      <v-btn
        v-if="isShowDetailQueueButton"
        @click="toDetailQueue"
        color="primary"
        dark
        depressed
        class="text-capitalize"
        >Dashboard Antrean <v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn
      >
    </v-footer>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import SocialDataPatients from '../SharedComponent/SocialData.vue';
import VaccinePolyAssestment from './components/VaccinePoly/VaccinePolyAssestment.vue';
import Warrant from './components/Letters/Warrant';
import BHPandAction from './components/BHPandAction.vue';
import PrintDocuments from './components/PrintDocuments.vue';
import Datepicker from '@/components/SharedComponent/Datepicker/Datepicker.vue';
import Constant from '@/const';
import moment from 'moment-timezone';
import jwtMixin from '@/mixin/jwtMixin';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';
import DashboardPatientQueueCard from './components/PatientQueue/DashboardPatientQueueCard/DashboardPatientQueueCard.vue';
const { mapMutations: icMutations } = createNamespacedHelpers(
  'InformedConsent',
);

const { mapActions } = createNamespacedHelpers('PatientQueue');
const { mapGetters: mapPatientQueueGetters } = createNamespacedHelpers(
  'PatientQueue',
);
const _ = require('lodash');
const axios = require('axios');

export default {
  name: 'VaccinePoly',
  mixins: [jwtMixin, AlertMixin],
  components: {
    VaccinePolyAssestment,
    SocialDataPatients,
    BHPandAction,
    PrintDocuments,
    Warrant,
    DashboardPatientQueueCard,
    Datepicker,
  },
  data() {
    return {
      tab: null,
      expanded: [],
      dialog: false,
      menu: false,
      dialogSocial: false,
      isRefresh: false,
      assessment: false,
      dialogBHP: false,
      chooseDoctor: '',
      date: moment().format('YYYY-MM-DD'),
      today: moment().format('YYYY-MM-DD'),
      doctor: [],
      generalConsentPatient: false,
      loading: false,
      selectedForm: null,
      idUnit: '',
      unitName: '',
      search: '',
      resource: {
        doctor: [],
      },
      table: {
        headers: [
          {
            text: 'No. Antrian',
            align: 'start',
            value: 'queue',
            width: '10%',
          },
          { text: 'No. Pendaftaran', value: 'noRegist', width: '10%' },
          { text: 'Nama', value: 'name', width: '20%' },
          { text: 'No. RM.', value: 'noRM', width: '10%' },
          { text: 'Tipe Penjamin', value: 'guarantorType', width: '12%' },
          { text: 'Nama Dokter', value: 'doctor', width: '15%' },
        ],
        items: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
        },
      },
      dialogWidth: [
        { name: 'VaccinePoly', width: 1400 },
        { name: 'SocialData', width: 1200 },
        { name: 'BHPandAction', width: 1400 },
        { name: 'PrintDocument', width: 346 },
        { name: 'Warrant', width: 1400 },
      ],
      pageLength: 1,
    };
  },
  watch: {
    date(newVal) {
      if (newVal === null) {
        this.showAllVaccinePolyPatients();
      }
    },
    search() {
      this.searchData(this);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    isDoctor() {
      return (
        this.$store.getters.userLoggedIn.role.some(
          item => item.toLowerCase() === 'dokter',
        ) ||
        this.$store.getters.userLoggedIn.role.some(
          item => item.toLowerCase() === 'fisioterapis',
        )
      );
    },
    isShowQueueControlCard() {
      return (
        this.date === this.today &&
        this.chooseDoctor &&
        this.isDoctor &&
        this.isHasSchedule
      );
    },
    isShowDetailQueueButton() {
      return (
        this.date === this.today &&
        this.chooseDoctor &&
        !this.isDoctor &&
        this.isHasSchedule
      );
    },
    ...mapPatientQueueGetters(['getIsHasSchedule']),
    isHasSchedule: {
      get() {
        return this.getIsHasSchedule;
      },
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = this.isShowQueueControlCard ? 9 : 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '60vh';
          break;
        case 'lg':
          height = '65vh';
          break;
        case 'xl':
          height = '70vh';
          break;
      }
      return height;
    },
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
  },
  methods: {
    ...mapActions(['resolveGetPatientQueue']),
    ...icMutations(['setDataPatient']),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.table.pagination.page = 1;
      v.refreshAndAutoUpdate();
    }, 650),
    handleDateChange(updatedDate) {
      this.date = updatedDate;
      this.showAllVaccinePolyPatients();
    },
    refresh() {
      this.isRefresh = true;
      this.refreshAndAutoUpdate();
    },
    toDetailQueue() {
      window.open(
        `/pelayanan/poli-vaksinasi/antrean/${this.chooseDoctor}`,
        '_blank',
      );
      localStorage.setItem(
        'detailQueuePayload',
        JSON.stringify({
          id_unit: this.idUnit,
          id_staff: this.chooseDoctor,
          date: this.date || moment().format('YYYY-MM-DD'),
        }),
      );
    },
    async handleGetPatientQueue() {
      await this.resolveGetPatientQueue({
        payload: {
          id_unit: this.idUnit,
          id_staff: this.chooseDoctor,
          date: this.date || moment().format('YYYY-MM-DD'),
        },
      });
    },
    closeForm() {
      this.dialog = false;
      this.refreshAndAutoUpdate();
    },
    clickedRow(value) {
      if (this.expanded.length && this.expanded[0].number == value.number) {
        this.expanded = [];
      } else {
        this.expanded = [];
        this.expanded.push(value);
      }
    },
    getUnit() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/master/unit?page=1&itemCount=8&search=Poli KIA&sort=name',
            ),
          )
          .then(response => {
            const [unit] = response.data.data;
            this.idUnit = unit._id.toString();
            this.unitName = unit.name;
            this.getDoctor();
          });
      });
    },
    showAllVaccinePolyPatients() {
      const itemCount = this.itemsPerRow;
      let doctor = this.chooseDoctor;
      this.loading = true;
      const today = moment(new Date()).format('YYYY-MM-DD');
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/patient/registration/unit?search=${this.search || ''}&page=${
                this.table.pagination.page
              }&itemCount=${itemCount}&sort=&unit_name=Poli Imunisasi&date=${this
                .date || today}&doctor=${doctor || ''}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.pageLength = Math.ceil(
              response.data.length / this.itemsPerRow,
            );

            this.table.items = data.map(patient => {
              const addressData = [patient.emr.social[0].address.text];

              if (patient.emr.social[0].address.sub_district) {
                addressData.push(patient.emr.social[0].address.sub_district);
              }
              if (patient.emr.social[0].address.district) {
                addressData.push(patient.emr.social[0].address.district);
              }
              if (patient.emr.social[0].address.city) {
                addressData.push(patient.emr.social[0].address.city);
              }
              if (patient.emr.social[0].address.province) {
                addressData.push(patient.emr.social[0].address.province);
              }

              return {
                id_registration: patient._id.toString(),
                id_emr: patient.id_emr.toString(),
                isInformedConsentCreated:
                  patient.document?.informConsentCreated,
                hasGeneralConsent: patient.emr?.document?.generalConsentCreated,
                noRegist: patient.registration_number.toString(),
                polyId: patient.id_unit,
                doctorId: patient.doctor[0] ? patient.doctor[0]._id : '',
                doctor: patient.doctor[0]
                  ? patient.doctor[0].detail[0].name
                  : '',
                noQueue: patient.queue.number.toString(),
                queue: patient.queue_code
                  ? `${
                      patient.queue_code
                    }-${patient.queue.number.toString().padStart(3, '0')}`
                  : patient.queue.number.toString().padStart(3, '0'),
                isNew: patient.status.isNew ? 'Pasien Baru' : 'Pasien Lama',
                status: patient.status,
                isFinish: patient.queue.isFinish,
                time: patient.time,
                date: moment(patient.date)
                  .locale('id')
                  .format('LL'),
                profession: patient.emr.social[0].profession,
                //medical record
                noRM: patient.emr.medical_record_number,
                name: patient.emr.social[0].name,
                phoneNumber: patient.emr.social[0].phone_number,
                birthDate: moment(patient.emr.social[0].birth.date)
                  .locale('id')
                  .format('LL'),
                birthPlace: patient.emr.social[0].birth.place,
                age: patient.emr.social[0].age,
                gender: patient.emr.social[0].gender
                  ? 'Laki-laki'
                  : 'Perempuan',
                address: addressData.join(', '),
                identityType: patient.emr.social[0].identity.type,
                identityNumber: patient.emr.social[0].identity.number,
                //assurance
                guarantorType: _.startCase(patient.assurance.type),
                guarantorName: patient.emr.social[0].assurance.name,
                guarantorNumber: patient.emr.social[0].assurance.number,
                isProlanis: patient.emr.social[0].assurance.isProlanis,
                //allergy
                timestamps: {
                  created_at: moment(patient.timestamps.created_at)
                    .locale('id')
                    .format('LT'),
                  registered_at: patient.timestamps.created_at,
                },
              };
            });
          })
          .catch(error => {
            if (error) {
              this.table.items = [];
            }
          })
          .finally(() => {
            this.loading = false;
            this.isRefresh = false;
          });
      });
    },
    getDoctor() {
      this.runApiCalls(() => {
        axios
          .get(Constant.apiUrl.concat(`/master/staff?id_unit=${this.idUnit}`))
          .then(response => {
            const data = response.data.data;
            this.resource.doctor = data
              .filter(doctor => doctor.role.status === true)
              .map(doctor => {
                return {
                  doctorId: doctor._id,
                  doctor: doctor.detail.name,
                };
              });
          });
      });
    },
    changeSelectedPatient(item) {
      this.generalConsentPatient = item.hasGeneralConsent;
      const patientData = {
        id_emr: item.id_emr,
        id_registration: item.id_registration,
        isInformedConsentCreated: item.isInformedConsentCreated,
        patientType: item.status.type,
        rmNumber: item.noRM,
        noQueue: item.noQueue,
        queue: item.queue,
        registered_at: item.timestamps.registered_at,
        meta: {
          name: item.name,
          phoneNumber: item.phoneNumber,
          birthDate: item.birthDate,
          gender: item.gender,
          age: item.age,
          address: item.address,
          identityType: item.identityType,
          identityNumber: item.identityNumber,
          doctor: item.doctor,
          id_poly: item.polyId,
          polyName: this.unitName,
          profession: item.profession,
          guarantorType: item.guarantorType,
          guarantorName: item.guarantorName,
          guarantorNumber: item.guarantorNumber,
        },
      };
      this.$store.commit('changeSelectedPatient', patientData);
    },
    selectItem(item, index) {
      this.setDataPatient(item);
      if (index === 6) {
        Swal.fire({
          text: `Anda akan menyelesaikan pelayanan untuk pasien atas nama ${item.name}`,
          title: 'Apakah anda yakin?',
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Batal',
          confirmButtonText: 'Lanjutkan',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          icon: 'warning',
        }).then(result => {
          if (result.isConfirmed) {
            axios
              .post(
                Constant.apiUrl.concat(
                  `/patient/registration/finish/${item.id_registration}/${this.$store.getters.userLoggedIn.id}`,
                ),
              )
              .then(() => {
                Swal.fire('Pelayanan telah diselesaikan', '', 'success');
              })
              .catch(err => {
                this.showErrorAxios(err);
              })
              .finally(() => {
                this.showAllVaccinePolyPatients();
              });
          }
        });
      } else {
        this.changeSelectedPatient(item);
        this.selectedForm = index;
        this.dialog = true;
      }
    },
    async filterByDoctor() {
      let polyclinic = this.idUnit;
      let doctor = this.chooseDoctor;
      const today = moment().format('YYYY-MM-DD');

      this.loading = true;
      await axios
        .get(
          Constant.apiUrl.concat(
            `/patient/?polyclinic=${polyclinic}&doctor=${doctor}&startDate=${this
              .date || today}&endDate=${today}`,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            const data = response.data.data;

            this.table.items = data.map(patient => {
              return {
                _id: patient._id.toString(),
                //registration
                noRegist: patient.registration.registration_number.toString(),
                polyId: patient.registration.id_unit._id,
                poly: patient.registration.id_unit.name,
                doctorId: patient.registration.id_staff._id,
                doctor: patient.registration.id_staff.name,
                noQueue: patient.registration.queue.number.toString(),
                queue: patient.queue_code
                  ? `${
                      patient.queue_code
                    }-${patient.queue.number.toString().padStart(3, '0')}`
                  : patient.queue.number.toString().padStart(3, '0'),
                isNew:
                  patient.registration.isNew === true
                    ? 'Pasien Baru'
                    : 'Pasien Lama',
                status: patient.status,
                isFinish: patient.registration.queue.isFinish,
                time: patient.registration.time,
                date: moment(patient.registration.date)
                  .locale('id')
                  .format('LL'),
                //medical record
                rmId: patient.medical_record._id.toString(),
                noRM: patient.medical_record.medical_record_number,
                name: patient.medical_record.name,
                birthDate: moment(patient.medical_record.birth.date)
                  .locale('id')
                  .format('LL'),
                birthPlace: patient.medical_record.birth.place,
                age: patient.medical_record.age,
                gender:
                  patient.medical_record.gender === true
                    ? 'Laki-laki'
                    : 'Perempuan',
                //assurance
                guarantorType: patient.assurance.type,
                guarantorName: patient.assurance.name,
                guarantorNumber: patient.assurance.number,
                isProlanis: patient.assurance.isProlanis,
                //allergy
                allergy: patient.allergy,
                timestamps: {
                  created_at: moment(patient.timestamps.created_at)
                    .locale('id')
                    .format('LT'),
                  registered_at: patient.timestamps.created_at,
                },
              };
            });
            this.pageLength = Math.ceil(this.table.items.length / 5);
          }
        })
        .catch(() => {
          this.showAllVaccinePolyPatients();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getUnit();
      this.showAllVaccinePolyPatients();
      this.interval = setInterval(this.showAllVaccinePolyPatients, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.expand {
  border: none !important;
}

.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.hover:hover {
  color: #2196f3;
}

.title {
  text-align: left;
  font-weight: bold !important;
  padding-bottom: 0 !important;
}

.subtitle {
  text-align: left;
  color: #adb5bd !important;
  font-weight: 500;
}

.header-page {
  display: grid;
  grid-template-columns: 1fr 0.6fr 0.6fr 0.1fr 0.6fr;
  padding: 10px 10px 10px 10px;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }

  .v-icon:hover {
    color: #2196f3;
  }

  tbody {
    border-left: 2px solid blue !important ;
  }
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}
</style>
