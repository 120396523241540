import Constant from '@/const';
import axios from 'axios';

const getRoles = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/master/specialize'),
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getRoles;
