const state = {
  basicLifeSupport: [],
  invasiveAction: [],
  splintAction: [],
  support: [],
};

const getters = {
  getBasicLifeSupport: state => state.basicLifeSupport,
  getInvasiveAction: state => state.invasiveAction,
  getSplintAction: state => state.splintAction,
  getSupport: state => state.support,
};

const mutations = {
  clearEmergencyActionState() {
    Object.assign(state, {
      basicLifeSupport: [],
      invasiveAction: [],
      splintAction: [],
      support: [],
    });
  },
  setBasicLifeSupport(state, payload) {
    state.basicLifeSupport = payload;
  },
  setInvasiveAction(state, payload) {
    state.invasiveAction = payload;
  },
  setSplintAction(state, payload) {
    state.splintAction = payload;
  },
  setSupport(state, payload) {
    state.support = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
