import Constant from '@/const';
import axios from 'axios';

const getCompareData = async (id_cppt, id_history) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/inpatient/cppt/compare/${id_cppt}/${id_history}`,
      ),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getCompareData;
