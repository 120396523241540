import Constant from '@/const';
import axios from 'axios';

const getLastUnit = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/master/unit?sorts=unit_number:-1'),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getLastUnit;
