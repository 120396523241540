<template>
  <div class="main-div">
    <main class="main">
      <v-container fluid>
        <v-layout align-start justify-start column fill-width>
          <v-flex class="big-header">
            <v-layout align-center justify-center>
              <v-flex xs6 align-self-center class="header-text pl-10">
                <h1 class="primary-header">Laporan Mortalitas</h1>
                <h3 class="secondary-header">
                  Cek seluruh informasi mengenai laporan pelayanan di sini.
                </h3>
              </v-flex>
              <v-flex xs1></v-flex>
              <v-flex xs5>
                <v-img
                  contain
                  class="scientist-image"
                  lazy-src
                  :src="resource.picLocation[0]"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex class="text-submenu">
            <h3 class="text-head py-4">Kategori</h3>
          </v-flex>
          <v-flex class="menu-space">
            <v-layout align-start justify-start row fill-height>
              <template v-for="(item, index) in resource.category">
                <v-flex
                  xs2
                  class="menu-widget"
                  :key="'mortality-report-' + index"
                >
                  <v-layout fill-height column class="align-center">
                    <router-link :to="item.route">
                      <v-card flat rounded="lg" class="logo-place pa-3">
                        <v-img
                          contain
                          lazy-src
                          :src="resource.picLocation[index + 1]"
                        />
                      </v-card>
                    </router-link>
                    <v-flex class="font-weight-medium">
                      {{ item.text }}
                    </v-flex>
                  </v-layout>
                </v-flex>
              </template>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
      <v-dialog v-model="dialog" fullscreen> </v-dialog>
    </main>
  </div>
</template>

<script>
import mortality from '@/assets/image/illustration_mortality.svg';
import graphic from '@/assets/image/report_graphic.svg';
import patient from '@/assets/image/mortality_patient_death.svg';
import toddler from '@/assets/image/mortality_toddler_death.svg';
import stethoscope from '@/assets/image/report_stethoscope.svg';
import graphic2 from '@/assets/image/report_graphic2.svg';

export default {
  name: 'PharmacySubmenu',
  mixins: [],
  components: {
    // DrugQueue
  },
  data() {
    return {
      dialog: false,
      resource: {
        picLocation: [
          mortality,
          graphic,
          patient,
          toddler,
          stethoscope,
          graphic2,
        ],
        category: [
          {
            route: `/reporting/laporan-mortalitas/laporan-mortalitas-jumlah-kematian-ibu`,
            text: 'Mortalitas Jumlah Kematian Ibu',
          },
          {
            route: `/reporting/laporan-mortalitas/laporan-mortalitas-jumlah-kematian-pasien`,
            text: 'Mortalitas Jumlah Kematian Pasien',
          },
          {
            route: `/reporting/laporan-mortalitas/laporan-mortalitas-angka-kematian-balita`,
            text: 'Mortalitas Angka Kematian Balita',
          },
          {
            route: `/reporting/laporan-mortalitas/laporan-mortalitas-angka-kematian-neonatus`,
            text: 'Mortalitas Angka Kematian Neonatus',
          },
        ],
      },
    };
  },
  mounted() {
    if (this.routeController) {
      this.resource.category = [
        {
          route: `/reporting/laporan-mortalitas/laporan-mortalitas-jumlah-kematian-ibu`,
          text: 'Mortalitas Jumlah Kematian Ibu',
        },
        {
          route: `/reporting/laporan-mortalitas/laporan-mortalitas-jumlah-kematian-pasien`,
          text: 'Mortalitas Jumlah Kematian Pasien',
        },
        {
          route: `/reporting/laporan-mortalitas/laporan-mortalitas-angka-kematian-balita`,
          text: 'Mortalitas Angka Kematian Balita',
        },
        {
          route: `/reporting/laporan-mortalitas/laporan-mortalitas-angka-kematian-neonatus`,
          text: 'Mortalitas Angka Kematian Neonatus',
        },
      ];
    }
  },
  watch: {},
  computed: {
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[2];
    },
  },
  methods: {
    openQueue() {
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Roboto;
  font-style: normal;
  .main {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    padding: 0 25px 0 25px;

    .big-header {
      align-items: center;
      display: flex;
      width: 100%;
      min-height: 48vh;
      background: #f0f9ff;
      font-family: Roboto;
      font-style: normal;
      border-radius: 25px;
      .header-text {
        padding-left: 25px;
        text-align: left;
        .primary-header {
          font-size: 2.5em;
          color: #000000;
          font-weight: bold;
        }
        .secondary-header {
          font-size: 1.15em;
          font-weight: 500;
          color: #a6adb4;
        }
      }
      .scientist-image {
        z-index: 1;
        position: relative;
        top: 20px;
        width: 65%;
      }
    }
    .text-submenu {
      .text-head {
        // padding: 40px 0 30px 0;
        color: #000000;
        font-weight: bold;
      }
    }
    .menu-space {
      width: 100%;
      .menu-widget {
        margin: 20px;
        .logo-place {
          background: #f0f9ff;
          width: 85px;
          height: 85px;
          &:hover {
            border: 2px solid $primary-color;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
