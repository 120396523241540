<template>
  <v-form ref="form" class="form-container mt-4">
    <section>
      <h4>
        RIWAYAT KEHAMILAN INI
      </h4>
      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label>HPHT</label>
          <v-menu
            :close-on-content-click="false"
            dense
            ref="menu"
            v-model="menu.dateHpht"
            transition="scale-transition"
            offset-y
            min-width="380px"
            :disabled="isEMR"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="input-field"
                append-icon="mdi-chevron-down"
                placeholder="DD-MM-YYYY"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                single-line
                v-model="filterDateHpht"
              />
            </template>
            <v-date-picker
              width="380px"
              v-model="form.currentPregnancy.hpht"
              @change="handleGeneratePregnancyDueDate()"
              no-title
              scrollable
            />
          </v-menu>
        </v-col>
        <v-col cols="6" class="pl-10">
          <label>Pergerakan Janin Pertama Kali</label>
          <v-text-field
            dense
            single-line
            v-model.number="form.currentPregnancy.firstFetalMovement"
            class="input-field"
            placeholder="0"
            type="number"
            suffix="Minggu"
            :readonly="isEMR"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label>Taksiran Persalinan</label>
          <v-menu
            dense
            ref="menu"
            v-model="menu.dateEstimate"
            transition="scale-transition"
            offset-y
            min-width="380px"
            :disabled="isEMR"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="input-field"
                append-icon="mdi-chevron-down"
                placeholder="DD-MM-YYYY"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                single-line
                :value="formatDate()"
              />
            </template>
            <v-date-picker
              width="380px"
              v-model="form.currentPregnancy.estimatedDate"
              no-title
              scrollable
            />
          </v-menu>
        </v-col>
        <v-col cols="6" class="pl-10">
          <label>Bila Pergerakan Sudah Terasa</label>
          <v-radio-group
            class="input-field"
            row
            v-model="form.currentPregnancy.totalFetalMovement"
            :readonly="isEMR"
          >
            <v-radio label="Belum Terasa" value="1"></v-radio>
            <v-radio label="<10 kali" value="2"></v-radio>
            <v-radio label="10-30 kali" value="3"></v-radio>
            <v-radio label=">10 kali" value="4"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label>Keluhan per Trimester</label>
          <div
            v-for="(_, index) in form.currentPregnancy.complaintsPerTrimester"
            :key="index"
          >
            <div class=" d-flex justify-center align-baseline">
              <v-text-field
                dense
                single-line
                v-model="form.currentPregnancy.complaintsPerTrimester[index]"
                :placeholder="'Trimester ' + (index + 1)"
                class="input-field"
                :readonly="isEMR"
              />
              <v-btn
                icon
                :disabled="
                  isEMR ||
                    form.currentPregnancy.complaintsPerTrimester.length > 2
                "
                @click="handleAddComplaintsPerTrimester"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                :disabled="index === 0 || isEMR"
                icon
                @click="handleRemoveComplaintsPerTrimester(index)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-divider class="divider" />
    </section>

    <section>
      <h4>
        RIWAYAT KEHAMILAN SEBELUMNYA
      </h4>
      <div v-for="(item, index) in form.previousPregnancyHistory" :key="index">
        <v-divider v-if="index !== 0" class="divider mb-6" />
        <v-row no-gutters>
          <v-col cols="6" class="pr-10">
            <label>Hamil ke (G/P/A/Nifas)</label>
            <v-text-field
              dense
              single-line
              v-model.number="form.previousPregnancyHistory[index].pregnancyTo"
              readonly
              class="input-field"
              placeholder="0"
              type="number"
            />
          </v-col>
          <v-col cols="6" class="pl-10">
            <label>H/M</label>
            <v-autocomplete
              dense
              single-line
              v-model="form.previousPregnancyHistory[index].isAlive"
              :items="[
                { name: 'Hidup', key: true },
                { name: 'Mati', key: false },
              ]"
              item-text="name"
              class="input-field"
              placeholder="Hidup atau mati"
              append-icon="mdi-chevron-down"
              :readonly="isEMR"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="6" class="pr-10">
            <label>Umur</label>
            <v-row no-gutters>
              <v-col cols="6" class="pr-5">
                <v-text-field
                  dense
                  single-line
                  v-model.number="form.previousPregnancyHistory[index].ageYear"
                  class="input-field"
                  placeholder="0"
                  type="number"
                  suffix="Tahun"
                  :readonly="isEMR"
                />
              </v-col>
              <v-col cols="6" class="pl-5">
                <v-text-field
                  dense
                  single-line
                  v-model.number="form.previousPregnancyHistory[index].ageMonth"
                  class="input-field"
                  placeholder="0"
                  type="number"
                  suffix="Bulan"
                  :readonly="isEMR"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pl-10">
            <label>BBL (gr)</label>
            <v-text-field
              dense
              single-line
              v-model.number="form.previousPregnancyHistory[index].bbl"
              class="input-field"
              placeholder="0"
              suffix="gram"
              maxlength="4"
              @keydown="validateInput"
              :readonly="isEMR"
            />
            <!--  
            type="number"  -->
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="6" class="pr-10">
            <label>Jenis Kelamin</label>
            <v-autocomplete
              dense
              single-line
              v-model="form.previousPregnancyHistory[index].gender"
              :items="['Laki-laki', 'Perempuan', 'Tidak Ada']"
              class="input-field"
              placeholder="Pilih jenis kelamin"
              append-icon="mdi-chevron-down"
              :readonly="isEMR"
            />
          </v-col>
          <v-col cols="6" class="pl-10">
            <label>Komplikasi</label>
            <v-text-field
              dense
              single-line
              v-model="form.previousPregnancyHistory[index].complication"
              class="input-field"
              placeholder="Masukkan Keterangan"
              :readonly="isEMR"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="6" class="pr-10">
            <label>Penolong</label>
            <v-autocomplete
              dense
              single-line
              v-model="form.previousPregnancyHistory[index].helper"
              :items="items.helper"
              class="input-field"
              placeholder="Pilih penolong"
              append-icon="mdi-chevron-down"
              :readonly="isEMR"
            />
            <v-text-field
              v-if="form.previousPregnancyHistory[index].helper === 'Lain-Lain'"
              dense
              single-line
              v-model="form.previousPregnancyHistory[index].helperEtc"
              class="input-field"
              style="margin-top: -0.25rem !important;"
              placeholder="Masukkan penolong lainnya"
              :readonly="isEMR"
            />
          </v-col>
          <v-col cols="6" class="pl-10">
            <label>Keadaan Anak Saat Ini</label>
            <v-text-field
              dense
              single-line
              v-model="form.previousPregnancyHistory[index].currentCondition"
              class="input-field"
              placeholder="Masukkan Keterangan"
              :readonly="isEMR"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="6" class="pr-10">
            <label>Pilih Tempat</label>
            <v-autocomplete
              append-icon="mdi-chevron-down"
              dense
              single-line
              v-model="form.previousPregnancyHistory[index].place"
              :items="items.place"
              class="input-field"
              placeholder="Pilih tempat"
              :readonly="isEMR"
            />
            <v-text-field
              v-if="form.previousPregnancyHistory[index].place === 'Lain-Lain'"
              dense
              single-line
              v-model="form.previousPregnancyHistory[index].placeEtc"
              class="input-field"
              style="margin-top: -0.25rem !important;"
              placeholder="Masukkan tempat lainnya"
              :readonly="isEMR"
            />
          </v-col>
        </v-row>

        <v-row v-if="index + 1 === form.previousPregnancyHistory.length">
          <v-spacer />
          <v-btn
            v-if="index !== 0 && !isEMR"
            color="error"
            text
            tile
            class="text-none"
            @click="handleRemovePregnancyHistory(index)"
            >Hapus</v-btn
          >
          <v-btn
            v-if="!isEMR"
            color="primary"
            outlined
            tile
            depressed
            class="text-none mx-2"
            @click="handleAddPregnancyHistory()"
            >Tambah Baru</v-btn
          >
        </v-row>

        <v-divider
          class="divider"
          v-if="form.previousPregnancyHistory.length === index + 1"
        />
      </div>
    </section>
  </v-form>
</template>

<script>
import moment from 'moment-timezone';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'ObstetricsAssessment',
);

export default {
  name: 'PregnancyHistory',
  props: {
    isEMR: Boolean,
  },
  data() {
    return {
      menu: {
        dateHpht: false,
        dateEstimate: false,
      },
    };
  },
  computed: {
    ...mapGetters(['getForm', 'getItems']),
    form: {
      get() {
        return this.getForm.pregnancyHistory;
      },
    },
    items: {
      get() {
        return this.getItems;
      },
    },
    filterDateHpht: {
      get() {
        return this.form.currentPregnancy.hpht
          ? moment(this.form.currentPregnancy.hpht).format('DD-MM-YYYY')
          : '';
      },
      set(val) {
        this.form.currentPregnancy.hpht = val;
      },
    },
    filterDateEstimate: {
      get() {
        return this.form.currentPregnancy.estimatedDate
          ? moment(this.form.currentPregnancy.estimatedDate).format(
              'DD-MM-YYYY',
            )
          : this.form.currentPregnancy.estimatedDate;
      },
    },
  },
  methods: {
    ...mapMutations(['']),
    ...mapActions([
      'addComplaintsPerTrimester',
      'removeComplaintsPerTrimester',
      'addPregnancyHistory',
      'removePregnancyHistory',
      'resolveGeneratePregnancyDueDate',
    ]),
    handleAddComplaintsPerTrimester() {
      this.addComplaintsPerTrimester();
    },
    handleRemoveComplaintsPerTrimester(index) {
      this.removeComplaintsPerTrimester(index);
    },
    handleAddPregnancyHistory() {
      this.addPregnancyHistory();
    },
    handleRemovePregnancyHistory(index) {
      this.removePregnancyHistory(index);
    },
    handleGeneratePregnancyDueDate() {
      this.resolveGeneratePregnancyDueDate();
    },
    validateInput(event) {
      const inputValue = event.target.value;
      const maxLength = 5;
      const allowedKeys = [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'Control',
      ];
      const allowedCharacters = /^[0-9]*$/;
      if (allowedKeys.includes(event.key)) {
        return;
      }
      if (
        inputValue.length >= maxLength ||
        !allowedCharacters.test(event.key)
      ) {
        event.preventDefault();
      }
    },
    formatDate() {
      if (this.form.currentPregnancy.estimatedDate) {
        return moment(this.form.currentPregnancy.estimatedDate).format(
          'DD-MM-YYYY',
        );
      } else {
        return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: start;
  gap: 1.5rem;
  .divider {
    margin-top: 1.5rem;
    width: 100%;
  }
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: #3f484a;
  }
  .input-field {
    margin-top: 0.5rem;
    width: 100%;
  }
  h4 {
    font-weight: 400;
    color: #137bc0;
    text-align: start;
    margin-bottom: 0.7vw;
  }
}
section {
  width: 100%;
}
.required:after {
  content: ' *';
  color: red;
}
::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
