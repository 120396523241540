<template>
  <v-card>
    <v-card-title
      style="border-bottom: 1px solid #9CA2A5;"
      class="justify-space-between"
    >
      <h3>Ubah akun {{ data.account_code }}</h3>
      <v-btn icon @click="$emit('close-dialog')" color="red"
        ><v-icon>mdi-close-thick</v-icon></v-btn
      >
    </v-card-title>
    <v-row no-gutters align="center" class="pa-5">
      <div class="form-style">
        <label class="font-weight-bold body-2 text-left pb-4">Kode Akun</label>
        <label class="text-left">{{ input.code }}</label>

        <label class="font-weight-bold body-2 text-left">Nama Akun</label>
        <v-text-field
          dense
          placeholder="Masukkan Nama Akun"
          v-model="input.name"
        ></v-text-field>

        <label class="font-weight-bold text-left body-2">Kategori Akun</label>
        <v-select
          dense
          placeholder="Pilih Kategori"
          :items="resource.category"
          v-model="input.category"
        ></v-select>

        <label class="font-weight-bold text-left body-2">Saldo</label>
        <v-currency-field
          dense
          placeholder="Masukkan Saldo"
          v-model="input.balance"
          :decimal-length="2"
        ></v-currency-field>

        <label class="font-weight-bold text-left body-2">Tanggal Saldo</label>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="input.date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedDate"
              placeholder="Masukkan Tanggal"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="input.date"
            no-title
            scrollable
            @input="
              $refs.menu.save(input.date);
              menu = false;
            "
          />
        </v-menu>

        <label class="font-weight-bold text-left body-2">Status</label>

        <v-radio-group row v-model="input.status">
          <v-radio label="Aktif" :value="true"></v-radio>
          <v-radio label="Tidak Aktif" :value="false"></v-radio>
        </v-radio-group>
      </div>
    </v-row>
    <v-card-actions class="mt-10">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        tile
        depressed
        class="text-none"
        width="100"
        @click="editAcc()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import Constant from '@/const';

const axios = require('axios');

export default {
  name: 'EditAccount',
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      resource: {
        category: [
          'Aset',
          'Liabilitas',
          'Aset Lancar',
          'Kas dan Bank',
          'Piutang Usaha',
          'Piutang Lain',
          'Penyisihan Piutang Tak Tertagih',
          'Persediaan',
          'Investasi',
          'Aset Lancar Lainnya',
          'Aset Tetap',
          'Akumulasi Depresiasi',
          'Akumulasi Amortisasi',
          'Aset Lainnya',
          'Utang Jangka Panjang',
          'Utang Jangka Pendek',
          'Utang Usaha',
          'Utang Jangka Pendek Lain',
          'Ekuitas',
          'Pendapatan',
          'Biaya Pokok Pendapatan',
          'Beban',
          'Pendapatan Lain-lain',
          'Beban Lain-lain',
        ],
      },
      menu: false,
      input: {
        code: '',
        name: '',
        category: '',
        balance: '',
        date: null,
        status: '',
        meta: '',
      },
    };
  },
  mounted() {
    this.input = {
      code: this.data.account_code,
      name: this.data.name,
      category: this.data.category,
      balance: this.data.balance,
      date: this.data.date,
      status: this.data.status,
      meta: this.data.meta,
    };
  },
  computed: {
    formattedDate() {
      return this.input.date
        ? moment(this.input.date).format('DD/MM/YYYY')
        : this.input.date;
    },
  },
  // watch: {
  //   'input.type': (val) => {
  //     if(val === 'acc') this.input.code = this.data.code;
  //   }
  // },
  methods: {
    editAcc() {
      const payload = {
        account_code: this.input.code,
        type: this.input.type, // 'main' for main account, 'sub' for sub account
        name: this.input.name,
        category: this.input.category,
        balance: this.input.balance,
        date: moment(this.input.date).format(),
        status: this.input.status,
        meta: this.input.meta,
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.id,
        },
      };
      Swal.fire({
        text: `Apakah Anda yakin ingin menyimpan akun ${this.input.code}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#2D9CDB',
        cancelButtonColor: '#EB5757',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          // Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          axios
            .put(
              Constant.apiUrl.concat(
                `/finance/ledger/account/${this.data._id}`,
              ),
              payload,
            )
            .then(() => {
              this.$emit('close-form', 'edit', this.input.code);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-style {
  display: grid;
  grid-template-columns: 20vh 30vh;
  //   grid-row-gap: 2vh;
  text-align: left;
  align-items: baseline;
}
</style>
