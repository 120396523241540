import Constant from '@/const';
import axios from 'axios';

const postObstetricsAssessment = async (idInpatient, payload) => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat(`/pregnancyassessment/${idInpatient}`),
      payload,
    );
    return response;
  } catch (error) {
    return error;
  }
};

export default postObstetricsAssessment;
