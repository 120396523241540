<template>
  <v-card height="600">
    <v-card-title class="font-weight-bold d-flex justify-center">
      Input Pembayaran Jasa Medis
    </v-card-title>
    <v-container>
      <div class="header">
        <label for="date" class="font-weight-bold subtitle-2"
          >Tanggal Pembayaran
        </label>
        <v-menu v-model="menu" dense transition="scale-transition" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              name="date"
              outlined
              append-icon="mdi-chevron-down"
              dense
              placeholder="Pilih Tanggal"
              readonly
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedDate"
            :max="today"
            @input="
              $refs.menu.save(date);
              menu = false;
              getPaymentData();
            "
            scrollable
          >
          </v-date-picker>
        </v-menu>
        <label for="bank" class="font-weight-bold subtitle-2 text-right pr-3"
          >Rekening</label
        >
        <v-autocomplete
          outlined
          dense
          clearable
          :items="resource.account"
          hide-details
          placeholder="Pilih Rekening"
          append-icon="mdi-chevron-down"
          name="bank"
        >
        </v-autocomplete>
      </div>
      <v-divider></v-divider>
      <div class="data-container">
        <div
          class="card-item"
          v-for="(item, index) in items"
          :key="`item-${index}`"
        >
          <div class="grid">
            <label class="font-weight-bold caption text-left">{{
              index + 1
            }}</label>
            <label class="font-weight-bold caption text-left">Nama</label>
            <label class="font-weight-bold caption text-left">Periode</label>
            <label class="font-weight-bold caption text-left">Jasa Medis</label>
            <label class="font-weight-bold caption text-right">Jumlah</label>
          </div>
          <div class="grid items">
            <label></label>
            <label class="text-left caption pa-0 ma-0">{{ item.name }}</label>
            <label class="text-left caption pa-0 ma-0">{{
              formatDateTwo(item.periode)
            }}</label>
            <div>
              <p class="text-left caption pa-0 ma-0">Umum</p>
              <p class="text-left caption pa-0 ma-0">BPJS</p>
              <p class="text-left caption pa-0 ma-0">Asuransi Lainnya</p>
            </div>
            <div>
              <p class="text-right caption pa-0 ma-0">
                {{ currency(item.amount.general) }}
              </p>
              <p class="text-right caption pa-0 ma-0">
                {{ currency(item.amount.bpjs) }}
              </p>
              <p class="text-right caption pa-0 ma-0">
                {{ currency(item.amount.otherAssurance) }}
              </p>
            </div>
          </div>
          <div class="card-footer">
            <span class="font-weight-medium caption">{{
              currency(item.amount.total)
            }}</span>
          </div>
        </div>
      </div>
    </v-container>
    <div class="footer">
      <div class="cost-data">
        <div>
          <p
            class="mx-0 px-0 primary--text font-weight-bold subtitle-2 text-right"
          >
            {{ selectedData.length }} Dokter Terpilih
          </p>
          <p class="pa-0 ma-0 caption font-weight-bold text-right">
            {{ dateStart ? formatDateOne(dateStart) : startOfMonth }}
          </p>
          <p class="pa-0 ma-0 caption font-weight-bold text-right">
            s/d
          </p>
          <p class="pa-0 ma-0 caption font-weight-bold text-right">
            {{ dateEnd ? formatDateOne(dateEnd) : endOfMonth }}
          </p>
        </div>
        <div>
          <p class="subtitle-2 font-weight-regular text-left pl-10 my-2">
            Umum
          </p>
          <p class="subtitle-2 font-weight-regular text-left pl-10 my-2">
            BPJS
          </p>
          <p class="subtitle-2 font-weight-regular text-left pl-10 my-2">
            Asuransi Lainnya
          </p>
        </div>
        <div>
          <p class="subtitle-2 mx-0 font-weight-regular text-right my-2">
            {{ currency(totalGeneral) }}
          </p>
          <p class="subtitle-2 mx-0 font-weight-regular text-right my-2">
            {{ currency(totalBpjs) }}
          </p>
          <p class="subtitle-2 mx-0 font-weight-regular text-right my-2">
            {{ currency(totalAssurance) }}
          </p>
          <p
            class="subtitle-2 mx-0 primary--text font-weight-bold text-right my-2"
          >
            {{ currency(totalGeneral + totalBpjs + totalAssurance) }}
          </p>
        </div>
      </div>
      <div class="d-flex align-end justify-center pb-5">
        <v-btn
          text
          width="100"
          height="30"
          class="red--text text-capitalize font-weight-bold"
          @click="$emit('close-form')"
          >Batal</v-btn
        >
        <v-btn
          color="primary"
          depressed
          width="100"
          height="30"
          class="font-weight-bold ml-3 text-capitalize"
          @click="payMedService()"
          >Bayar</v-btn
        >
      </div>
    </div>
  </v-card>
</template>

<script>
import formatMixin from '@/mixin/formatMixin';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import alertMixin from '@/mixin/alertMixin';
import Constant from '@/const';
const axios = require('axios');

export default {
  name: 'InputMedServicePayment',
  mixins: [formatMixin, alertMixin],
  data() {
    return {
      isDisabled: true,
      menu: false,
      date: moment().format('YYYY-MM-DD'),
      selectedDate: '',
      today: moment().format('YYYY-MM-DD'),
      startOfMonth: moment()
        .startOf('month')
        .format('DD MMMM Y'),
      endOfMonth: moment()
        .endOf('month')
        .format('D MMMM Y'),
      resource: {
        account: ['Mandiri', 'BNI', 'BCA', 'Jenius'],
      },
      items: [],
    };
  },
  props: ['selectedData', 'dateStart', 'dateEnd'],
  computed: {
    totalGeneral() {
      return this.selectedData
        .map(data => {
          return data.general;
        })
        .reduce((a, b) => a + b);
    },
    totalBpjs() {
      return this.selectedData
        .map(data => {
          return data.bpjs;
        })
        .reduce((a, b) => a + b);
    },
    totalAssurance() {
      return this.selectedData
        .map(data => {
          return data.otherAssurance;
        })
        .reduce((a, b) => a + b);
    },
  },
  watch: {
    selectedDate() {
      this.date = this.selectedDate;
      this.getAllPatient();
    },
  },
  mounted() {
    this.getPaymentData();
  },
  methods: {
    getPaymentData() {
      this.items = this.selectedData.map(data => {
        return {
          id: data.doctorId,
          name: data.name,
          periode: data.periode,
          date: data.date,
          amount: {
            general: data.general,
            bpjs: data.bpjs,
            otherAssurance: data.otherAssurance,
            total: data.medserviceTotal,
          },
        };
      });
    },
    payMedService() {
      const today = moment().format('YYYY-MM-DD');
      const startMonth = moment()
        .startOf('month')
        .format('YYYY-MM-DD');
      const endMonth = moment()
        .endOf('month')
        .format('YYYY-MM-DD');
      const payload = {
        paymentDate: this.date || today,
        servicePaid: this.items.map(item => item.id),
        startDate: this.dateStart || startMonth,
        endDate: this.dateEnd || endMonth,
      };
      Swal.fire({
        title: 'Apakah Anda yakin ingin melanjutkan pembayaran ini?',
        text: '',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yakin',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(Constant.apiUrl.concat(`/medicalservice/pay`), payload)
            .then(() => {
              Swal.fire('Pembayaran jasa medis berhasil!', '', 'success');
              this.$emit('close-form');
            })
            .catch(error => {
              this.showErrorAxios(error);
            });
        }
      });
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    formatDateOne(date) {
      if (date) {
        return moment(date)
          .locale('id')
          .format('D MMMM Y');
      } else {
        return '';
      }
    },
    formatDateTwo(date) {
      const d = date.split('-');
      const d1 = d[0];
      const d2 = d[1];
      return (
        moment(d1, 'DD/MM/YYYY')
          .locale('id')
          .format('D MMMM Y') +
        ' s/d ' +
        moment(d2, 'DD/MM/YYYY')
          .locale('id')
          .format('D MMMM Y')
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-footer {
  display: flex;
  justify-content: flex-end;
  width: 95%;
}
.header {
  display: grid;
  grid-template-columns: 0.75fr 1fr 0.75fr 1fr;
  align-items: center;
  margin-bottom: 20px;
}
.data-container {
  padding: 5px 20px;
  height: 320px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(233, 232, 232);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: rgb(185, 183, 183);
  }
  .card-item {
    background-color: #f0f9ff;
    padding: 3px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .grid {
    width: 100%;
    grid-template-columns: 5% 20% 25% 20% 25%;
    display: grid;
    &.items {
      border-bottom: 1px solid #bedbef;
      padding-bottom: 5px;
    }
  }
}
.footer {
  display: grid;
  width: 100%;
  padding-top: 10px;
  height: 135.5px;
  grid-template-columns: 66% 34%;
  box-shadow: 0px -2px 5px 2px rgba(176, 174, 174, 0.75);
  -webkit-box-shadow: 0px -2px 5px 2px rgba(176, 174, 174, 0.75);
  -moz-box-shadow: 0px -2px 5px 2px rgba(176, 174, 174, 0.75);
  .cost-data {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr) 1.2fr;
  }
}
</style>
