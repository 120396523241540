<template>
  <div>
    <v-layout v-if="(nextPage = 1)">
      <v-flex>
        <v-layout>
          <v-flex xs6 mt-3 ml-6 class="title-head">
            <h2>Laporan Jumlah Kepesertaan Pasien</h2>
            <h5 class="title-head__sub">Update: {{ timestamp }}</h5>
          </v-flex>
          <v-flex xs1 mt-6 mr-3>
            <v-btn tile color="primary" @click="exportData()" elevation="0"
              >Export</v-btn
            >
          </v-flex>
          <v-flex mt-6>
            <v-btn
              class="mr-2"
              color="grey"
              to="/reporting/laporan-pelayanan/"
              icon
              text
            >
              <font-awesome-icon icon="home" size="lg" />
            </v-btn>
          </v-flex>
          <v-flex mt-6>
            <v-btn class="mr-2" color="grey" icon text @click="refresh()">
              <font-awesome-icon icon="sync-alt" size="lg" />
            </v-btn>
          </v-flex>
          <v-flex xs4 md3 mr-3 mt-3>
            <v-text-field
              class="search"
              background-color="grey lighten-3"
              placeholder="Search"
              v-model="search"
              solo
              hide-details
              flat
              prepend-inner-icon="mdi-magnify"
              single-line
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout justify-end>
          <v-flex xs2 mr-3 mt-6>
            <v-layout>
              <font-awesome-icon
                icon="calendar-alt"
                color="#3498DB"
                class="mt-4 ml-3 mr-2 fa-2x"
              ></font-awesome-icon>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormat"
                    label="Pilih tanggal awal"
                    @change="fillterData()"
                    v-bind="attrs"
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="fillterData()">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>
          <v-flex xs2 mr-3 mt-6>
            <v-layout>
              <font-awesome-icon
                icon="calendar-alt"
                color="#3498DB"
                class="mt-4 ml-3 mr-2 fa-2x"
              ></font-awesome-icon>
              <v-menu
                ref="menuStop"
                v-model="menuStop"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStop"
                    label="Pilih tanggal akhir"
                    clearable
                    v-bind="attrs"
                    @change="fillterData()"
                    v-on="on"
                  ></v-text-field
                  >x
                </template>
                <v-date-picker v-model="date2" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuStop = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="fillterData()">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-tabs v-model="tab" centered>
            <v-tab key="bpjs">
              <label class="text-none font-weight-bold">BPJS</label>
            </v-tab>
            <v-tab key="umum">
              <label class="text-none font-weight-bold">Umum</label>
            </v-tab>
            <v-tab key="assurance">
              <label class="text-none font-weight-bold">Asuransi</label>
            </v-tab>
          </v-tabs>
        </v-layout>
        <v-tabs-items v-model="tab">
          <v-tab-item :key="bpjs">
            <v-data-table
              :headers="bpjs.headers"
              :height="height"
              hide-default-footer
              :items="table.dataItemBpjs"
              :items-per-page="itemsPerRow"
              :page.sync="table.pagination.pageBpjs"
              :loading="isLoading"
            >
              <template v-slot:item="{ item, index }">
                <tr class="row-datatable" :key="index">
                  <td class="font-weight-bold text-sm-left">
                    {{
                      (table.pagination.pageBpjs - 1) * itemsPerRow + index + 1
                    }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.no_rm }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.name }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.no_bpjs }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.gender }}
                  </td>

                  <td class="font-weight-bold text-sm-left">
                    {{ `${item.age} tahun` }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.address }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.guarantorType }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-footer color="white">
              <v-container>
                <v-row>
                  <v-pagination
                    v-model="table.pagination.pageBpjs"
                    :length="itemPagesBpjs"
                    total-visible="5"
                  ></v-pagination>
                </v-row>
              </v-container>
            </v-footer>
          </v-tab-item>
          <v-tab-item :key="umum">
            <v-data-table
              :headers="umum.headers"
              hide-default-footer
              :items="table.dataItemUmum"
              :height="height"
              :items-per-page="itemsPerRow"
              :page.sync="table.pagination.pageUmum"
              :loading="isLoading"
            >
              <template v-slot:item="{ item, index }">
                <tr class="row-datatable" :key="index">
                  <td class="font-weight-bold text-sm-left">
                    {{
                      (table.pagination.pageUmum - 1) * itemsPerRow + index + 1
                    }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.no_rm }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.name }}
                  </td>

                  <td class="font-weight-bold text-sm-left">
                    {{ item.gender }}
                  </td>

                  <td class="font-weight-bold text-sm-left">
                    {{ `${item.age} tahun` }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.address }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.guarantorType }}
                  </td>
                </tr>
              </template>
            </v-data-table>

            <v-footer color="white">
              <v-container>
                <v-row>
                  <v-pagination
                    v-model="table.pagination.pageUmum"
                    :length="itemPagesUmum"
                    total-visible="5"
                  ></v-pagination>
                </v-row>
              </v-container>
            </v-footer>
          </v-tab-item>
          <v-tab-item :key="assurance">
            <v-data-table
              :headers="assurance.headers"
              :items="table.dataItemInsurance"
              :items-per-page="itemsPerRow"
              :height="height"
              hide-default-footer
              :loading="isLoading"
              :page.sync="table.pagination.pageAsuransi"
            >
              <template v-slot:item="{ item, index }">
                <tr class="row-datatable" :key="index">
                  <td class="font-weight-bold text-sm-left">
                    {{
                      (table.pagination.pageAsuransi - 1) * itemsPerRow +
                        index +
                        1
                    }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.no_rm }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.name }}
                  </td>

                  <td class="font-weight-bold text-sm-left">
                    {{ item.gender }}
                  </td>

                  <td class="font-weight-bold text-sm-left">
                    {{ `${item.age} tahun` }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.address }}
                  </td>
                  <td class="font-weight-bold text-sm-left">
                    {{ item.guarantorType }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-footer color="white">
              <v-container>
                <v-row>
                  <v-pagination
                    v-model="table.pagination.pageAsuransi"
                    :length="itemPagesInsurance"
                    total-visible="5"
                  ></v-pagination>
                </v-row>
              </v-container>
            </v-footer>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>
    </v-layout>
    <MembershipDashboard v-if="nextPage === 2" />
  </div>
</template>

<script>
import Constant from '@/const';
import MembershipDashboard from '../ServiceReport/MembershipDashboard';
const moment = require('moment-timezone');
import alertMixin from '@/mixin/alertMixin';
import Swal from 'sweetalert2';

const _ = require('lodash');
const axios = require('axios');

export default {
  mixins: [alertMixin],
  components: {
    MembershipDashboard,
  },
  props: {
    selected: Number,
    dateOne: String,
    dateTwo: String,
  },
  data() {
    return {
      search: '',
      isLoading: false,
      nextPage: 1,
      date2: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10),
      dateFormat: '',
      dateStop: '',
      itemPagesBpjs: '',
      itemPagesUmum: '',
      itemPagesInsurance: '',
      tab: '',
      timestamp: '',
      menu: false,
      menuStop: false,
      table: {
        dataItemBpjs: [],
        dataItemUmum: [],
        dataItemInsurance: [],
        pagination: {
          descending: true,
          pageBpjs: 1,
          pageUmum: 1,
          pageAsuransi: 1,
          rowsPerPage: 5,
          totalItems: null,
        },
      },
      bpjs: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'No. RM', value: 'no_rm' },
          { text: 'Nama Pasien', value: 'name' },
          { text: 'No. BPJS', value: 'no_bpjs' },
          { text: 'Jenis Kelamin', value: 'gender' },
          { text: 'Usia', value: 'age' },
          { text: 'Alamat', value: 'address' },
          { text: 'Tipe Penjamin', value: 'guarantorType' },
        ],
      },
      umum: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'No. RM', value: 'no_rm' },
          { text: 'Nama Pasien', value: 'name' },
          { text: 'Jenis Kelamin', value: 'gender' },
          { text: 'Usia', value: 'age' },
          { text: 'Alamat', value: 'address' },
          { text: 'Tipe Penjamin', value: 'guarantorType' },
        ],
      },
      assurance: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'No. RM', value: 'no_rm' },
          { text: 'Nama Pasien', value: 'name' },
          { text: 'Jenis Kelamin', value: 'gender' },
          { text: 'Usia', value: 'age' },
          { text: 'Alamat', value: 'address' },
          { text: 'Tipe Penjamin', value: 'guarantorType' },
        ],
      },
    };
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '44vh';
          break;
        case 'lg':
          height = '48vh';
          break;
        case 'xl':
          height = '57vh';
          break;
      }
      return height;
    },
  },
  watch: {
    date(val) {
      if (val) {
        this.dateFormat = this.date;
      }
    },

    date2(val) {
      if (val) {
        this.dateStop = this.date2;
      }
    },
    search() {
      this.searchData(this);
    },
  },
  beforeMount() {
    this.tab = this.selected;
    this.date2 = this.dateTwo;
    this.dateStop = this.dateTwo;
    this.date = this.dateOne;
  },
  mounted() {
    this.getNow();
    this.$nextTick(() => {
      //   this.getBpjs();
      //   this.getUmum();
      //   this.getInsurance();
      this.fillterData();
    });
  },
  methods: {
    searchData: _.debounce(v => {
      v.fillterDataGeneral();
      v.fillterDataBpjs();
      v.fillterDataInsurance();
    }),
    async exportData() {
      const tab = ['bpjs', 'umum', 'asuransi'];
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/report/patient-enrollment/${
              tab[this.tab]
            }/export?start_date=${this.dateFormat || ''}&last_date=${this
              .dateStop || ''}`,
          ),
        );
        if (response.status === 200) {
          this.showFillSuccess('Export Berhasil');
          const { url } = response.config;
          window.open(url, '_blank');
        }
      } catch (error) {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat mengexport dokumen',
          });
        }
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    refresh() {
      this.dateFormat = '';
      this.dateStop = '';
      this.date = '';
      this.date2 = '';
      this.search = '';
      // this.getBpjs();
      // this.getUmum();
      // this.getInsurance();
      this.fillterData();
    },
    fillterDataGeneral() {
      const type = 'umum';
      const date = this.date;
      const dateStop = this.dateStop;
      const search = this.search;
      const page = this.table.pagination.pageUmum;
      const itemCount = this.itemsPerRow;
      axios
        .get(
          Constant.apiUrl
            .concat('/report/patient-enrollment/')
            .concat(type)
            .concat('?page=')
            .concat(page || '')
            .concat('&page=')
            .concat(itemCount || '')
            .concat('&start_date=')
            .concat(date || '')
            .concat('&last_date=')
            .concat(dateStop || '')
            .concat('&search=')
            .concat(search || ''),
        )
        .then(response => {
          const getDataUmum = response.data.data;
          this.itemPagesUmum = Math.ceil(response.data.length / itemCount);
          this.table.dataItemUmum = getDataUmum.map(item => {
            return {
              no_rm: item.medical_record_number,
              name: item.social.name,

              age: Math.abs(
                moment(item.social.birth.date).diff(moment(), 'year'),
              ),
              gender: item.social.gender ? 'Laki - laki ' : 'Perempuan',
              address: item.social.address.text
                ? item.social.address.text
                : item.social.address.text +
                  ', ' +
                  item.social.address.sub_district
                ? item.social.address.sub_district + ', '
                : '' + item.social.address.district
                ? item.social.address.district + ', '
                : '' + item.social.address.city
                ? item.social.address.city + ', '
                : '' + item.social.address.province
                ? item.social.address.province
                : '',
              guarantorType: item.social.assurance.type,
            };
          });
          this.isLoading = false;
          this.menuStop = false;
        })
        .catch(() => {
          this.table.dataItemUmum = [];
          this.isLoading = false;
          this.menuStop = false;
        });
    },

    fillterDataInsurance() {
      const page = this.table.pagination.pageAsuransi;
      const itemCount = this.itemsPerRow;
      const type = 'asuransi';
      const date = this.date;
      const dateStop = this.dateStop;
      const search = this.search;
      axios
        .get(
          Constant.apiUrl
            .concat('/report/patient-enrollment/')
            .concat(type)
            .concat('?page=')
            .concat(page || '')
            .concat('&page=')
            .concat(itemCount || '')
            .concat('&start_date=')
            .concat(date || '')
            .concat('&last_date=')
            .concat(dateStop || '')
            .concat('&search=')
            .concat(search || ''),
        )
        .then(response => {
          const getDataInsurance = response.data.data;
          this.itemPagesInsurance = Math.ceil(response.data.length / itemCount);
          this.table.dataItemInsurance = getDataInsurance.map(item => {
            return {
              no_rm: item.medical_record_number,
              name: item.social.name,

              age: Math.abs(
                moment(item.social.birth.date).diff(moment(), 'year'),
              ),
              gender: item.social.gender ? 'Laki - laki ' : 'Perempuan',
              address: item.social.address.text
                ? item.social.address.text
                : item.social.address.text +
                  ', ' +
                  item.social.address.sub_district
                ? item.social.address.sub_district + ', '
                : '' + item.social.address.district
                ? item.social.address.district + ', '
                : '' + item.social.address.city
                ? item.social.address.city + ', '
                : '' + item.social.address.province
                ? item.social.address.province
                : '',
              guarantorType: item.social.assurance.type,
            };
          });
          this.isLoading = false;
          this.menuStop = false;
        })
        .catch(() => {
          this.table.dataItemInsurance = [];
          this.isLoading = false;
          this.menuStop = false;
        });
    },
    fillterDataBpjs() {
      const itemCount = this.itemsPerRow;
      const page = this.table.pagination.pageBpjs;
      const type = 'bpjs';
      const date = this.date;
      const dateStop = this.dateStop;
      const search = this.search;
      axios
        .get(
          Constant.apiUrl
            .concat('/report/patient-enrollment/')
            .concat(type)
            .concat('?page=')
            .concat(page || '')
            .concat('&page=')
            .concat(itemCount || '')
            .concat('&start_date=')
            .concat(date || '')
            .concat('&last_date=')
            .concat(dateStop || '')
            .concat('&search=')
            .concat(search || ''),
        )
        .then(response => {
          const getDataBpjs = response.data.data;
          // const today = new Date();
          // const year = 0
          this.itemPagesBpjs = Math.ceil(response.data.length / itemCount);
          this.table.dataItemBpjs = getDataBpjs.map(item => {
            return {
              no_rm: item.medical_record_number,
              name: item.social.name,
              no_bpjs: item.social.assurance.number,
              age: Math.abs(
                moment(item.social.birth.date).diff(moment(), 'year'),
              ),
              gender: item.social.gender ? 'Laki - laki ' : 'Perempuan',
              address: item.social.address.text
                ? item.social.address.text
                : item.social.address.text +
                  ', ' +
                  item.social.address.sub_district
                ? item.social.address.sub_district + ', '
                : '' + item.social.address.district
                ? item.social.address.district + ', '
                : '' + item.social.address.city
                ? item.social.address.city + ', '
                : '' + item.social.address.province
                ? item.social.address.province
                : '',
              guarantorType: item.social.assurance.type,
            };
          });
          this.isLoading = false;
          this.menuStop = false;
          this.menu = false;
        })
        .catch(() => {
          this.table.dataItemBpjs = [];
          this.isLoading = false;
          this.menuStop = false;
          this.menu = false;
        });
    },
    fillterData() {
      this.fillterDataGeneral();
      this.fillterDataBpjs();
      this.fillterDataInsurance();
    },
    // getBpjs() {
    //   this.isLoading = true;
    //   const itemCount = 5;
    //   axios
    //     .get(Constant.apiUrl.concat('/report/patient-enrollment/bpjs'))
    //     .then(response => {
    //       const getDataBpjs = response.data.data;
    //       // const today = new Date();
    //       // const year = 0
    //       this.itemPagesBpjs = Math.ceil(response.data.length / itemCount);
    //       this.table.dataItemBpjs = getDataBpjs.map(item => {
    //         return {
    //           no_rm: item.medical_record_number,
    //           name: item.social.name,
    //           no_bpjs: item.social.assurance.number,
    //           age: Math.abs(
    //             moment(item.social.birth.date).diff(moment(), 'year'),
    //           ),
    //           gender: item.social.gender ? 'Laki - laki ' : 'Perempuan',
    //           address: item.social.address.text,
    //           guarantorType: item.social.assurance.type.toUpperCase(),
    //         };
    //       });
    //       this.isLoading = false;
    //     });
    // },

    // getUmum() {
    //   this.isLoading = true;
    //   const itemCount = 5;
    //   axios
    //     .get(Constant.apiUrl.concat('/report/patient-enrollment/umum'))
    //     .then(response => {
    //       const getDataUmum = response.data.data;
    //       this.itemPagesUmum = Math.ceil(response.data.length / itemCount);
    //       this.table.dataItemUmum = getDataUmum.map(item => {
    //         return {
    //           no_rm: item.medical_record_number,
    //           name: item.social.name,

    //           age: Math.abs(
    //             moment(item.social.birth.date).diff(moment(), 'year'),
    //           ),
    //           gender: item.social.gender ? 'Laki - laki ' : 'Perempuan',
    //           address: `${item.social.address.text || ''} ${item.social.address
    //             .sub_district || ''} ${item.social.address.district ||
    //             ''} ${item.social.address.city || ''} ${item.social.address
    //             .province || ''}`,
    //           guarantorType: item.social.assurance.type.toUpperCase(),
    //         };
    //       });
    //       this.isLoading = false;
    //     });
    // },

    // getInsurance() {
    //   this.isLoading = true;
    //   const itemCount = 5;
    //   axios
    //     .get(Constant.apiUrl.concat('/report/patient-enrollment/asuransi'))
    //     .then(response => {
    //       const getDataInsurance = response.data.data;
    //       this.itemPagesInsurance = Math.ceil(response.data.length / itemCount);
    //       this.table.dataItemInsurance = getDataInsurance.map(item => {
    //         return {
    //           no_rm: item.medical_record_number,
    //           name: item.social.name,

    //           age: Math.abs(
    //             moment(item.social.birth.date).diff(moment(), 'year'),
    //           ),
    //           gender: item.social.gender ? 'Laki - laki ' : 'Perempuan',
    //           address: item.social.address.text,
    //           guarantorType: item.social.assurance.type.toUpperCase(),
    //         };
    //       });
    //       this.isLoading = false;
    //     });
    // },
    getNow() {
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const today = new Date();

      const date =
        today.getDate() +
        ' ' +
        monthNames[today.getMonth()] +
        ' ' +
        today.getFullYear();
      this.timestamp = date;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-head {
  text-align: left;
  &__sub {
    color: #828282;
  }
}
</style>
