<template>
  <v-form ref="form" class="form-container mt-4">
    <section v-for="(item, index) in form.birthNote" :key="index">
      <h4>CATATAN PERSALINAN {{ index + 1 }}</h4>
      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label>Tanggal Catatan</label>
          <v-row no-gutters>
            <v-col cols="9" class="pr-5">
              <v-menu
                dense
                :disabled="isEMR"
                ref="menu"
                v-model="notesRecordArr[index]"
                transition="scale-transition"
                offset-y
                min-width="380px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="input-field"
                    append-icon="mdi-chevron-down"
                    placeholder="DD-MM-YYYY"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    single-line
                    :value="formatBirthNoteDate(index)"
                  />
                </template>
                <v-date-picker
                  width="380px"
                  v-model="form.birthNote[index].date"
                  no-title
                  scrollable
                />
              </v-menu>
            </v-col>
            <v-col cols="3" class="pl-5">
              <v-text-field
                type="time"
                single-line
                dense
                @keydown="preventDefaultBehaviour($event)"
                v-model="form.birthNote[index].time"
                :readonly="isEMR"
                class="input-field"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="pl-10">
          <label>Penolong Persalinan</label>
          <v-autocomplete
            dense
            single-line
            :readonly="isEMR"
            v-model="form.birthNote[index].helper"
            :items="resource"
            item-text="name"
            item-value="id"
            placeholder="Pilih penolong"
            append-icon="mdi-chevron-down"
            class="input-field"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label>Persalinan Kala II-IV</label>
          <v-textarea
            outlined
            dense
            :readonly="isEMR"
            v-model="form.birthNote[index].birthTwoToFour"
            class="input-field"
            placeholder="Masukkan deskripsi"
          />
        </v-col>
        <v-col cols="6" class="pl-10">
          <label>Terapi</label>
          <v-textarea
            outlined
            dense
            :readonly="isEMR"
            v-model="form.birthNote[index].therapy"
            class="input-field"
            placeholder="Masukkan deskripsi"
          />
        </v-col>
      </v-row>

      <v-row v-if="index + 1 === form.birthNote.length">
        <v-spacer />
        <v-btn
          v-if="index !== 0 && !isEMR"
          color="error"
          text
          tile
          class="text-none"
          @click="handleRemovePostpartumRecord(index)"
          >Hapus</v-btn
        >
        <v-btn
          v-if="!isEMR"
          color="primary"
          outlined
          tile
          depressed
          class="text-none mx-2"
          @click="handleAddPostpartumRecord()"
          >Tambah Baru</v-btn
        >
      </v-row>
      <v-divider class="divider" />
    </section>

    <section>
      <h4>CATATAN NIFAS</h4>
      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label>Tanggal Catatan</label>
          <v-row no-gutters>
            <v-col cols="9" class="pr-5">
              <v-menu
                dense
                ref="menu"
                :disabled="isEMR"
                v-model="menu.notesRecord"
                transition="scale-transition"
                offset-y
                min-width="380px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="input-field"
                    append-icon="mdi-chevron-down"
                    placeholder="DD-MM-YYYY"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    single-line
                    v-model="formatDatePostpartumDate"
                  />
                </template>
                <v-date-picker
                  width="380px"
                  v-model="form.postpartumNote.date"
                  no-title
                  scrollable
                />
              </v-menu>
            </v-col>
            <v-col cols="3" class="pl-5">
              <v-text-field
                type="time"
                single-line
                dense
                @keydown="preventDefaultBehaviour($event)"
                :readonly="isEMR"
                v-model="form.postpartumNote.time"
                class="input-field"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="pl-10">
          <label>Nifas</label>
          <v-textarea
            outlined
            dense
            single-line
            :readonly="isEMR"
            v-model="form.postpartumNote.postpartum"
            class="input-field"
            placeholder="Masukkan deskripsi"
          />
        </v-col>
      </v-row>
    </section>
  </v-form>
</template>

<script>
import moment from 'moment-timezone';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'LabourAssessment',
);
const { mapGetters: staffGetters } = createNamespacedHelpers('MasterStaff');

export default {
  name: 'PostpartumRecord',
  props: {
    isEMR: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      menu: {
        notesRecord: false,
      },
    };
  },
  computed: {
    ...mapGetters(['getForm', 'getItems']),
    ...staffGetters({ staffItems: 'getItems' }),
    notesRecordArr: {
      get() {
        return [false];
      },
    },
    form: {
      get() {
        return this.getForm.noteAndPostpartum;
      },
    },
    items: {
      get() {
        return this.getItems;
      },
    },
    resource: {
      get() {
        return this.staffItems.dataStaff.data
          .filter(
            item =>
              item.role.role.some(
                item =>
                  item.toLowerCase() === 'bidan' ||
                  item.toLowerCase() === 'perawat',
              ) && item.role.status === true,
          )
          .map(item => {
            return { name: item.name, id: item.id };
          });
      },
    },
    formatDatePostpartumDate: {
      get() {
        return this.form.postpartumNote.date
          ? moment(this.form.postpartumNote.date).format('DD-MM-YYYY')
          : '';
      },
    },
  },
  methods: {
    ...mapMutations(['']),
    ...mapActions(['addPostpartumRecord', 'removePostpartumRecord']),
    preventDefaultBehaviour(event) {
      if (event.key === ' ') {
        event.preventDefault();
      }
    },
    handleAddPostpartumRecord() {
      this.addPostpartumRecord();
    },
    handleRemovePostpartumRecord(index) {
      this.removePostpartumRecord(index);
    },
    formatBirthNoteDate(index) {
      return this.form.birthNote[index].date
        ? moment(this.form.birthNote[index].date).format('DD-MM-YYYY')
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: start;
  gap: 1.5rem;
  .divider {
    margin-top: 1.5rem;
    width: 100%;
  }
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: #3f484a;
  }
  .input-field {
    margin-top: 0.5rem;
    width: 100%;
  }
  h4 {
    font-weight: 400;
    color: #137bc0;
    text-align: start;
    margin-bottom: 0.7vw;
  }
}
section {
  width: 100%;
}
::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

::v-deep
  .v-text-field__slot
  input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
