import Constant from '@/const';
import axios from 'axios';

const getAllergiesByIdEmr = id => {
  try {
    const response = axios.get(
      Constant.apiUrl.concat(`/patient/emr/allergy/${id}`),
    );
    return response;
  } catch (error) {
    const err = error;
    return err;
  }
};

export default getAllergiesByIdEmr;
