<template>
  <v-card class="d-flex asessment-card">
    <history-sidebar :is-general="true" v-if="!isEmr" ref="history" />
    <v-container fluid>
      <header-assessment
        :data="headerData"
        pl="2vw"
        pr="2vw"
        title="Assesmen Fisioterapi"
      />
      <hr class="mt-4" style="border-color: rgba(224, 224, 224, 0.3)" />
      <!-- <v-card-text class="pb-0"> -->
      <input-assessment
        :is-emr="isEmr"
        v-if="!emrData"
        :emr-data="emrData"
        @close-form="$emit('close-form')"
        :patient-data="patientData"
        @refresh-history="handleRefreshHistory"
      />
      <input-assessment
        v-else
        @refresh-history="handleRefreshHistory"
        @close-form="$emit('close-form')"
        :patient-data="patientData"
        :is-emr="isEmr"
        :emr-data="emrData"
      />
      <!-- </v-card-text> -->
    </v-container>
  </v-card>
</template>

<script>
import HistorySidebar from '../AssessmentComponents/HistorySidebar.vue';
import HeaderAssessment from '../AssessmentComponents/HeaderAssessment.vue';
import InputAssessment from './InputAssestment.vue';
const moment = require('moment');

export default {
  name: 'GeneralAssessment',
  components: {
    HistorySidebar,
    HeaderAssessment,
    InputAssessment,
  },
  data() {
    return {
      headerData: '',
    };
  },
  props: {
    patientData: Object,
    isEmr: Boolean,
    emrData: {
      type: Object,
      default: null,
    },
  },
  methods: {
    handleRefreshHistory() {
      this.$refs.history.showAllHistories();
    },
  },
  mounted() {
    this.headerData = [
      {
        label: 'No.RM',
        text: this.patientData.rmNumber,
      },
      {
        label: 'Nama',
        text: `${this.patientData.meta.name} (${
          this.patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
        })`,
      },
      {
        label: 'Tanggal Lahir',
        text: `${moment(this.patientData.meta.birthDate, 'DD MMMM YYYY').format(
          'DD/MM/YYYY',
        )} (${this.patientData.meta.age} Tahun)`,
      },
      {
        label: 'Hambatan dalam Berkomunikasi',
        text: this.patientData.commmunicationObstacles || 'Tidak Ada',
      },
    ];
  },
};
</script>

<style lang="scss" scoped>
.asessment-card {
  height: 100%;
}
</style>
