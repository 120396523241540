const moment = require('moment-timezone');

export default {
  methods: {
    mapUpdatePatientPayload(rmNumber, data) {
      return {
        id_emr: rmNumber,
        patient: {
          name: data.name,
          gender: data.gender,
          birth: {
            date: moment(data.birth.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            place: data.birth.place,
          },
          address: {
            province: data.address.province.name,
            city: data.address.city.name,
            district: data.address.district.name,
            sub_district: data.address.sub_district.name,
            text: data.address.text,
          },
          nickname: data.nickname,
          identity: {
            type: data.identity.type,
            number: data.identity.number,
          },
          phone_number: data.phone_number || '',
          religion: data.religion,
          blood_type: data.blood_type,
          ethnicity: data.ethnicity,
          language_mastering: data.language_mastering,
          disabilities: data.disabilities,
          communication_obstacles: data.communication_obstacles,
          education: data.education,
          profession: data.profession,
          father: data.father,
          mother: data.mother,
          assurance: data.assurance,
          martial_status: data.marital_status,
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        },
      };
    },
    mapSocialData(data) {
      return {
        name: data.name,
        age: data.age,
        nickname: data.nickname,
        gender: data.gender,
        ethnicity: data.ethnicity,
        language_mastering: data.language_mastering,
        disabilities: +data.disabilities,
        communication_obstacles: data.communication_obstacles,
        religion: data.religion,
        blood_type: data.blood_type,
        education: data.education,
        profession: data.profession,
        marital_status: data.martial_status,
        father: data.father,
        mother: data.mother,
        phone_number: data.phone_number || '',
        birth: {
          date: moment(data.birth.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          place: data.birth.place,
        },
        address: {
          city: data.address.city || '',
          district: data.address.district || '',
          province: data.address.province || '',
          sub_district: data.address.sub_district || '',
          text: data.address.text,
        },
        assurance: {
          name: data.assurance.name,
          number: data.assurance.number,
          isProlanis: data.assurance.isProlanis,
          type: data.assurance.type,
        },
        identity: {
          type: data.identity.type,
          number: data.identity.number,
        },
      };
    },
  },
};
