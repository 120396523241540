<template>
  <div>
    <v-toolbar flat min-height="84">
      <v-container fluid>
        <v-row align="center" class="pt-3">
          <v-col>
            <v-toolbar-title class="font-weight-bold text-left h2">
              Laporan Morbiditas
            </v-toolbar-title>
            <div class="caption grey--text text-left">10 Penyakit Terbesar</div>
          </v-col>
          <v-btn color="grey" icon text @click="getDisease()">
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-col cols="2" align-self="center">
            <v-select
              v-model="input.months"
              placeholder="Pilih bulan"
              outlined
              prepend-inner-icon="mdi-calendar-month"
              dense
              :items="resource.months"
              hide-details="false"
              clearable
              @change="getDisease()"
            ></v-select>
          </v-col>

          <v-divider style="max-width: 20px"></v-divider>

          <v-col cols="2">
            <v-select
              v-model="input.year"
              placeholder="Pilih tahun"
              outlined
              prepend-inner-icon="mdi-calendar-month"
              dense
              hide-details="false"
              :items="yearRange"
              clearable
              @change="getDisease()"
            ></v-select>
          </v-col>

          <v-btn tile color="primary" elevation="0" @click="download()"
            >Export</v-btn
          >
        </v-row>
      </v-container>
    </v-toolbar>

    <v-main class="pt-3 px-6">
      <v-container>
        <v-row>
          <v-col cols="7">
            <v-card class="card-graphic pa-3" elevation="0">
              <v-row>
                <v-col cols="6">
                  <h4 class="text-left" style="color: #4d5658">
                    Grafik Laporan Morbiditas
                  </h4>
                  <h5 class="text-left" style="color: #adb5bd">
                    10 daerah dengan penderita terbanyak
                  </h5>
                </v-col>
                <v-col cols="6">
                  <v-select
                    outlined
                    dense
                    :items="resource.disease"
                    placeholder="Pilih Penyakit"
                    v-model="input.diagnose"
                    @change="getDisease()"
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
              <div id="chart">
                <apexcharts
                  ref="morbidity"
                  type="bar"
                  height="350"
                  :options="morbidGraph.chartOptions"
                  :series="morbidGraph.series"
                ></apexcharts>
              </div>
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card color="#F0F9FF" elevation="0" class="pa-3">
              <v-card-title class="text-left pa-2 font-weight-bold"
                >10 Penyakit Terbesar</v-card-title
              >
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <div v-for="(disease, index) in tenBigDisease" :key="index">
                <v-divider></v-divider>
                <v-row justify-center align-center class="py-3 body-2">
                  <v-col cols="2" align-self="center">
                    <label>{{ index + 1 }}</label>
                  </v-col>

                  <v-col cols="7" align-self="center"
                    ><label>{{ disease.name }}</label></v-col
                  >
                  <v-col cols="3" align-self="center"
                    ><label>{{ disease.count }} pasien</label></v-col
                  >
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-snackbar
      timeout="4000"
      content-class="d-flex justify-space-between white--text align-center"
      elevation="0"
      small
      light
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn fab small text @click.native="() => (snackbar.show = false)"
        ><v-icon color="white" small>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
  </div>
</template>

<script>
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';
import VueApexCharts from 'vue-apexcharts';

const axios = require('axios');
const moment = require('moment-timezone');

// api
import { exportMorbidity } from '@/fetchApi/Reporting/Morbidity';

export default {
  name: 'MorbidityReport',
  mixins: [jwtMixin],
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      input: {
        year: new Date().getFullYear(),
        month: '',
        diagnose: '',
      },
      resource: {
        months: [],
        disease: [],
      },
      snackbar: {
        text: '',
        show: null,
        color: '',
      },
      morbidGraph: {
        series: [
          {
            name: 'Pasien',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            id: 'morbidity',
            type: 'bar',
            height: 350,
          },
          colors: ['#F2994A'],
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
            tickAmount: undefined,
            labels: {
              formatter: function(val) {
                return parseInt(val);
              },
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
        },
      },

      diseaseItem: [],
      tenBigDisease: [],
      month: '',
      year: '',
      count: [],
      loading: false,
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      for (let i = 0; i < 12; i++) {
        this.resource.months.push({
          text: moment()
            .startOf('year')
            .add(i, 'month')
            .locale('id')
            .format('MMMM'),
          value: i + 1,
        });
      }
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    yearRange() {
      let startYear = 2020;
      const currentYear = new Date().getFullYear();
      const years = [];
      while (startYear <= currentYear) {
        years.push(startYear++);
      }
      return years;
    },
  },
  methods: {
    getDisease() {
      this.morbidGraph.chartOptions.xaxis.categories = [];
      const thisYear = new Date().getFullYear();
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/report/morbiditas?year=${this.input.year ||
                thisYear}&month=${this.input.months || ''}&diagnose=${this.input
                .diagnose || ''}`,
            ),
          )
          .then(response => {
            const {
              topDisease,
              diseaseByRegion,
              listDisease,
            } = response.data.data;
            this.resource.disease = listDisease.map(x => {
              return x === null ? '' : x;
            });
            this.tenBigDisease = topDisease;
            const region = [];
            const count = [];
            diseaseByRegion.forEach((item, index) => {
              if (index < 10) {
                region.push(item._id === null ? '-' : item._id);
                count.push(item.count);
              }
            });

            this.$refs.morbidity.updateOptions({
              xaxis: {
                categories: region,
                tickAmount: Math.min(...count),
              },
            });
            this.$refs.morbidity.updateSeries(
              [
                {
                  name: 'Pasien',
                  data: count,
                },
              ],
              false,
              true,
            );
          });
      });
    },

    async download() {
      try {
        const params = {
          year: this.input.year || '',
          month: this.input.months || '',
          diagnose: '',
        };
        const response = await exportMorbidity(params);
        const { url } = response.config;
        const month = this.input.months || moment().format('M');
        const year = this.input.year || moment().format('YYYY');

        window.open(`${url}?year=${year}&month=${month}`, '_blank');
        this.snackbar = {
          show: true,
          text: 'Berhasil mengekspor laporan morbiditas',
          color: 'success',
        };
      } catch {
        this.snackbar = {
          show: true,
          text: 'Terjadi kesalahan, gagal mengekspor laporan morbiditas',
          color: 'error',
        };
      }
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getDisease();
      this.interval = setInterval(this.getDisease, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.title-head {
  text-align: left;
  &__sub {
    color: #828282;
  }
}
.card-graphic {
  background-color: #f0f9ff;
}
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  background: white;
}
.label-text {
  font-size: 14px;
  font-weight: 500;
  display: grid;
  text-align: left;
  padding-top: 10px;
  &.data-grid {
    grid-template-columns: 50px 70px 180px 100px;
    grid-row-gap: 2px;
    grid-auto-rows: 50px;
  }
}

::v-deep .v-snack__content {
  padding: 0px 0px 0px 1vw;
}
</style>
