import Constant from '@/const';
import axios from 'axios';
import AlertMixin from '@/mixin/alertMixin';

const postAssessment = async payload => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat(`/patient/igd/assessment/${payload.role}`),
      payload.payload,
    );
    return response;
  } catch (error) {
    AlertMixin.methods.showErrorAxios(error);
  }
};

export default postAssessment;
