var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{style:(_vm.addColor ? 'background-color: #f0f9ff;' : ''),attrs:{"flat":"","outlined":""}},[_vm._l((_vm.form.drugs),function(item,index){return _c('div',{key:`drug-${index}`},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.page === index),expression:"page === index"}],ref:"formDrugs",refInFor:true,staticClass:"form-container",attrs:{"lazy-validation":""}},[_c('label',{staticClass:"required"},[_vm._v("Nama Obat")]),_c('v-combobox',{ref:"drugName",refInFor:true,attrs:{"placeholder":"Masukkan Nama Obat","item-value":"id_stock","append-icon":"mdi-chevron-down","dense":"","single-line":"","return-object":"","readonly":_vm.isSaved,"filter":_vm.comboboxFilter,"loading":_vm.loading.drugs,"search-input":_vm.search[index],"items":_vm.resource.drugs,"rules":[
          v =>
            v?.quantity !== 0 || 'Obat habis. Silahkan pilih obat lainnya ',
          v =>
            (v?.quantity >= (Number(item.amount_requested) || 0) &&
              v?.quantity !== 0) ||
            'Stok tidak cukup',
        ]},on:{"change":function($event){_vm.updateDosage(item.detail?.strength || '', index);
          _vm.updateMixDrugQuantity({
            dosage: item.detail?.dosage_needed,
            index,
          });},"update:searchInput":function($event){return _vm.$set(_vm.search, index, $event)},"update:search-input":function($event){return _vm.$set(_vm.search, index, $event)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('v-layout',[_c('v-flex',{attrs:{"d-flex":"","justify-start":""}},[_c('span',{staticClass:"text-left"},[_vm._v(" "+_vm._s(`${data.item.text}`)+" ")])])],1)],1),_c('v-list-item-subtitle',[_c('v-layout',[_c('span',{staticClass:"mr-4"},[_vm._v(" Exp: "+_vm._s(data.item.expiryDateFormatted)+" ")]),_c('span',{staticClass:"mr-4"},[_vm._v(" No Batch: "+_vm._s(data.item.batchNumber)+" ")]),_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(`Sisa Stok : ${data.item.stock} ${data.item.packaging}`))]),_c('span',[_vm._v(_vm._s(_vm.currency(data.item.price || 0)))])])],1)],1)]}}],null,true),model:{value:(item.detail),callback:function ($$v) {_vm.$set(item, "detail", $$v)},expression:"item.detail"}}),_c('label',{staticClass:"required"},[_vm._v("Dosis")]),_c('v-text-field',{attrs:{"suffix":item.detail?.strength_unit || '',"readonly":_vm.isSaved,"hide-details":"","dense":"","single-line":""},on:{"keyup":function($event){return _vm.updateMixDrugQuantity({
            dosage: item.dosage_needed,
            index,
          })}},model:{value:(item.dosage_needed),callback:function ($$v) {_vm.$set(item, "dosage_needed", _vm._n($$v))},expression:"item.dosage_needed"}}),_c('label',{staticClass:"required"},[_vm._v("Jumlah Digunakan")]),_c('label',[_vm._v(_vm._s(item.detail === '' || item.detail === undefined ? '' : (item.amount_requested.toFixed(2) || 0) + ' ' + (item.detail?.preparation || '')))]),_c('label',{staticClass:"required"},[_vm._v("Jumlah Ditagihkan")]),_c('label',[_vm._v(_vm._s(item.detail === '' || item.detail === undefined ? '' : (item.amount_given || 0) + ' ' + (item.detail?.preparation || '')))])],1)],1)}),_c('div',{staticClass:"action-container"},[_c('div',{staticClass:"flex-item d-flex justify-start"},[_c('v-btn',{staticClass:"mr-2 primary--text",attrs:{"small":"","outlined":"","disabled":_vm.isSaved},on:{"click":function($event){return _vm.addDrug()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"mr-2 red--text",attrs:{"disabled":_vm.form.drugs.length === 1 || _vm.isSaved,"small":"","outlined":""},on:{"click":function($event){return _vm.deleteDrug()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1),_c('div',{staticClass:"flex-item"},[_c('v-btn',{staticClass:"primary--text",attrs:{"small":"","outlined":"","color":(_vm.notAvailablePage[0] >= _vm.page + 1 &&
            _vm.notAvailablePage[_vm.notAvailablePage.length - 1] >= _vm.page + 1) ||
          _vm.notAvailablePage.length === 0 ||
          _vm.page + 1 === 1
            ? 'primary'
            : 'error'},on:{"click":function($event){return _vm.previous()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('span',{staticClass:"font-weight-bold mx-2"},[_vm._v(_vm._s(`${_vm.page + 1} / ${_vm.form.drugs.length}`))]),_c('v-btn',{staticClass:"primary--text",attrs:{"small":"","outlined":"","color":(_vm.notAvailablePage[0] <= _vm.page + 1 &&
            _vm.notAvailablePage[_vm.notAvailablePage.length - 1] <= _vm.page + 1) ||
          _vm.notAvailablePage.length === 0 ||
          _vm.page + 1 === _vm.form.drugs.length
            ? 'primary'
            : 'error'},on:{"click":function($event){return _vm.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }