const moment = require('moment-timezone');

function isObjectHasCompleteValue(obj) {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object' && !isObjectHasCompleteValue(obj[key])) {
        // If the value is an object, recursively check its keys
        return false;
      }
      if (!obj[key]) {
        // If the value is falsy, return false
        return false;
      }
    }
  }
  return true;
}

const generateBadge = (state, tab, registeredAtInpatientDate) => {
  let result;

  // set up for initial assessment
  const inputtedArrivedTime = moment(
    state.initialAssessment?.arrived?.time,
    'HH:mm',
  );

  const patientRegisteredAtInpatientTime = moment(
    registeredAtInpatientDate?.time,
    'HH:mm',
  );

  const isArrivedTimeValid =
    state.initialAssessment?.arrived.date.formatted ===
    registeredAtInpatientDate.date
      ? inputtedArrivedTime.isAfter(patientRegisteredAtInpatientTime)
      : true;
  // end of set up for initial assessment

  // start set up for objective validation
  const isSistoleValid =
    isNaN(state.objective.sistole) ||
    state.objective.sistole === '' ||
    (state.objective.sistole >= 40 && state.objective.sistole <= 250);

  const isDiastoleValid =
    isNaN(state.objective.diastole) ||
    state.objective.diastole === '' ||
    (state.objective.diastole >= 30 && state.objective.diastole <= 180);

  const isHeartRateValid =
    isNaN(state.objective.heartRate) ||
    state.objective.heartRate === '' ||
    (state.objective.heartRate >= 30 && state.objective.heartRate <= 160);

  const isRrValid =
    isNaN(state.objective.rr) ||
    state.objective.rr === '' ||
    (state.objective.rr >= 5 && state.objective.rr <= 70);

  const isTemperatureValid =
    isNaN(state.objective.temperature) ||
    state.objective.temperature === '' ||
    (state.objective.temperature >= 20 && state.objective.temperature <= 45);

  const isHeightValid =
    isNaN(state.objective.height) ||
    state.objective.height === '' ||
    state.objective.height > 1;

  const isSaturationValid =
    isNaN(state.objective.saturation) ||
    state.objective.saturation === '' ||
    state.objective.saturation > 1;

  // end of set up for objective validation

  // start for switch case
  switch (tab) {
    case 0:
      result =
        isObjectHasCompleteValue(state.initialAssessment) && isArrivedTimeValid
          ? 'mdi-check-circle'
          : 'mdi-alert-circle';
      break;
    case 1:
      result =
        !state.anamnesis.currentHealthHistory ||
        (state.anamnesis.familyDiseaseHistory.check
          ? !state.anamnesis.familyDiseaseHistory.name.length
          : false)
          ? 'mdi-alert-circle'
          : 'mdi-check-circle';
      break;
    case 2:
      if (
        isSistoleValid &&
        isDiastoleValid &&
        isHeartRateValid &&
        isRrValid &&
        isTemperatureValid &&
        isHeightValid &&
        isSaturationValid
      ) {
        result = 'mdi-check-circle';
      } else {
        result = 'mdi-alert-circle';
      }

      if (!state.objective.awareness) {
        result = 'mdi-alert-circle';
      }

      break;
    case 3:
      result = state.physical.isSaved ? 'mdi-check-circle' : '';
      break;
    case 4:
      if (
        state.painAssessment.isSaved &&
        state.painAssessment.pain &&
        state.painAssessment.painFactor?.length &&
        state.painAssessment.painLike?.length
      ) {
        result = 'mdi-check-circle';
      } else {
        result = 'mdi-alert-circle';
      }

      if (state.painAssessment.isSaved && !state.painAssessment.pain) {
        result = 'mdi-check-circle';
      }

      if (!state.painAssessment.isSaved) {
        result = '';
      }

      break;
  }

  return result;
};

const generateBadgeObstetrics = (state, tab) => {
  const checkIsValid = state => {
    return state !== '' && state !== null && state !== undefined && state !== 0;
  };
  let result;
  const isSocialMenstrualHistoryValid =
    checkIsValid(state.socialMenstrualHistory?.staff) &&
    checkIsValid(state.socialMenstrualHistory.socialHistory.currentPregnancy);

  const isPregnancyHistoryValid = state.pregnancyHistory.previousPregnancyHistory.some(
    item => item.bbl !== 0,
  );

  const iskbAndImunizationHistoryValid =
    checkIsValid(state.kbAndImunizationHistory.kbStatus) &&
    (state.kbAndImunizationHistory.kbStatus === true
      ? state.kbAndImunizationHistory.kbMethod.length !== 0
      : true);

  const isObstreticalHistoryValid =
    checkIsValid(state.obstreticalHistory.uterinePalpation) &&
    checkIsValid(state.obstreticalHistory.fundusUteriHeight) &&
    checkIsValid(state.obstreticalHistory.fetalWeightEstimation) &&
    checkIsValid(state.obstreticalHistory.place) &&
    checkIsValid(state.obstreticalHistory.isConstraction);

  switch (tab) {
    case 0:
      if (isSocialMenstrualHistoryValid) {
        result = checkIsValid(state.socialMenstrualHistory.timestamps)
          ? 'mdi-check-circle'
          : '';
      } else {
        result = 'mdi-alert-circle';
      }
      break;
    case 1:
      if (isPregnancyHistoryValid) {
        result = checkIsValid(state.pregnancyHistory.timestamps)
          ? 'mdi-check-circle'
          : '';
      } else {
        result = 'mdi-alert-circle';
      }
      break;
    case 2:
      if (iskbAndImunizationHistoryValid) {
        result = checkIsValid(state.kbAndImunizationHistory.timestamps)
          ? 'mdi-check-circle'
          : '';
      } else {
        result = 'mdi-alert-circle';
      }
      break;
    case 3:
      if (isObstreticalHistoryValid) {
        result = checkIsValid(state.obstreticalHistory.timestamps)
          ? 'mdi-check-circle'
          : '';
      } else {
        result = 'mdi-alert-circle';
      }
      break;
  }
  return result;
};

const generateNeonatusBadge = (state, tab) => {
  switch (tab) {
    case 0:
      if (
        !state?.staff?.id ||
        !state?.time?.visit ||
        !state?.time?.fill ||
        !state?.mother?.data?.id_emr ||
        !state?.baby?.birthTime?.date?.raw ||
        !state?.baby?.birthTime?.time ||
        !state?.baby?.birthType
      ) {
        return 'mdi-alert-circle';
      }
      return 'mdi-check-circle';
    default:
      return state?.isSaved ? 'mdi-check-circle' : '';
  }
};
const generateBadgeLabour = (state, tab) => {
  const checkIsValid = state => {
    return state !== '' && state !== null && state !== undefined && state !== 0;
  };
  let result;

  const propertiesPregnancyHistoryToCheck = [
    state.pregnancyHistory?.visitData?.staff,
    state.pregnancyHistory?.visitData?.fillTime,
    state.pregnancyHistory?.visitData?.visitTime,
    state.pregnancyHistory?.currentPregnancy?.hisDate,
    state.pregnancyHistory?.currentPregnancy?.hisTime,
    state.pregnancyHistory?.currentPregnancy?.mucusOutDate,
    state.pregnancyHistory?.currentPregnancy?.mucusOutTime,
    state.pregnancyHistory?.currentPregnancy?.amnioticFluidOutDate,
    state.pregnancyHistory?.currentPregnancy?.amnioticFluidOutTime,
  ];
  const isPregnancyHistoryValid = propertiesPregnancyHistoryToCheck.every(
    checkIsValid,
  );

  const propertieskbAndMenstrualHistoryToCheck = [
    state.kbAndMenstrualHistory.menstruationHistory.uk,
    state.kbAndMenstrualHistory.menstruationHistory.hpl,
    state.kbAndMenstrualHistory.menstruationHistory.hpht,
    state.kbAndMenstrualHistory.menstruationHistory.range,
    state.kbAndMenstrualHistory.menstruationHistory.menarche,
    state.kbAndMenstrualHistory.menstruationHistory.periodCycle,
  ];
  const iskbAndMenstrualHistoryValid =
    checkIsValid(state.kbAndMenstrualHistory.kbHistory.kbStatus) &&
    (state.kbAndMenstrualHistory.kbHistory.kbStatus === true
      ? state.kbAndMenstrualHistory.kbHistory.kbMethod.length !== 0
      : true) &&
    propertieskbAndMenstrualHistoryToCheck.every(checkIsValid);

  switch (tab) {
    case 0:
      if (isPregnancyHistoryValid) {
        result = checkIsValid(state.pregnancyHistory.timestamps)
          ? 'mdi-check-circle'
          : '';
      } else {
        result = 'mdi-alert-circle';
      }
      break;
    case 1:
      if (iskbAndMenstrualHistoryValid) {
        result = checkIsValid(state.kbAndMenstrualHistory.timestamps)
          ? 'mdi-check-circle'
          : '';
      } else {
        result = 'mdi-alert-circle';
      }
      break;
    case 2:
      result = checkIsValid(state.noteAndPostpartum.timestamps)
        ? 'mdi-check-circle'
        : '';

      break;
  }
  return result;
};

module.exports = {
  generateBadge,
  generateBadgeObstetrics,
  generateNeonatusBadge,
  generateBadgeLabour,
};
