<template>
  <section>
    <h4 class="title font-weight-bold primary--text text-start mb-4">
      Data Subjektif
    </h4>
    <v-form ref="subjectiveForm">
      <v-row>
        <v-col cols="6">
          <v-row
            no-gutters
            align="baseline"
            :class="isEmr ? 'grid-display-emr' : 'grid-display'"
          >
            <template v-for="(anamnesis, index) in form.anamnesis">
              <label
                :key="'anamnesis-label-' + index"
                :class="{ required: !index }"
                >{{ index ? '' : 'Anamnesis' }}
              </label>
              <div :key="'anamnesis-' + index">
                <v-textarea
                  validate-on-blur
                  no-resize
                  height="100"
                  outlined
                  dense
                  single-line
                  required
                  :placeholder="'Anamnesis ' + (index + 1)"
                  v-model="form.anamnesis[index]"
                  :rules="isEmr ? [] : [v => !!v || 'Wajib diisi']"
                  :id="'anamnesis-' + index"
                  :readonly="isEmr"
                />
                <div style="margin-top: -3vh" class="d-flex justify-end">
                  <v-btn
                    @click="deleteAnamnesis(index)"
                    text
                    fab
                    small
                    :disabled="form.anamnesis.length < 2"
                    ><v-icon color="grey">mdi-delete</v-icon></v-btn
                  >
                  <v-btn
                    :disabled="isEmr"
                    @click="addAnamnesis()"
                    text
                    fab
                    small
                    ><v-icon color="grey">mdi-plus</v-icon></v-btn
                  >
                </div>
              </div>
            </template>
            <template v-for="(allergy, index) in form.allergy_history">
              <label :key="'allergy-label-' + index">{{
                index ? '' : 'Riwayat Alergi'
              }}</label>
              <div class="d-flex" :key="'allergy-' + index">
                <v-text-field
                  :readonly="isEmr"
                  dense
                  :placeholder="'Riwayat Alergi ' + (index + 1)"
                  v-model="form.allergy_history[index]"
                />
                <v-btn
                  :disabled="form.allergy_history.length < 2"
                  @click="deleteAllergy(index)"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-delete</v-icon></v-btn
                >
                <v-btn :disabled="isEmr" @click="addAllergy()" text fab small
                  ><v-icon color="grey">mdi-plus</v-icon></v-btn
                >
              </div>
            </template>
            <template v-for="(disease, index) in form.disease_history">
              <label :key="'disease-label' + index">{{
                index ? '' : 'Riwayat Penyakit Sebelumnya'
              }}</label>
              <div :key="'disease-' + index" class="d-flex">
                <v-text-field
                  :readonly="isEmr"
                  dense
                  :placeholder="'Riwayat Penyakit ' + (index + 1)"
                  v-model="form.disease_history[index]"
                />
                <v-btn
                  :disabled="form.disease_history.length < 2"
                  @click="deletePriorDisease(index)"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-delete</v-icon></v-btn
                >
                <v-btn
                  :disabled="isEmr"
                  @click="addPriorDisease()"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-plus</v-icon></v-btn
                >
              </div>
            </template>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row
            no-gutters
            align="baseline"
            :class="isEmr ? 'grid-display-emr' : 'grid-display'"
          >
            <label>Riwayat Pengobatan</label>
            <v-text-field
              :readonly="isEmr"
              dense
              placeholder="Riwayat Pengobatan"
              v-model="form.treatment_history"
            />
            <label>Status Psikologi</label>
            <v-radio-group
              :readonly="isEmr"
              row
              v-model="form.psychologyStatusCheckbox"
            >
              <v-radio label="Tidak Ada Kelainan" :value="false"></v-radio>
              <v-radio label="Ada" :value="true"></v-radio>
            </v-radio-group>
            <template v-if="form.psychologyStatusCheckbox">
              <label></label>
              <v-combobox
                :readonly="isEmr"
                v-model="form.psychological_status"
                :items="['Cemas', 'Takut', 'Marah', 'Sedih']"
                placeholder="Status Psikologis"
                append-icon="mdi-chevron-down"
                multiple
              >
                <template v-slot:prepend-item>
                  <p class="grey--text ma-4 text-start body-1">
                    Pilih atau Ketik Untuk Pilihan Lainnya
                  </p>
                </template>
              </v-combobox>
            </template>
            <label>Sosial Ekonomi</label>
            <v-text-field
              :readonly="isEmr"
              v-model="form.social_economy"
              class="mb-5"
              hide-details
              dense
              single-line
              placeholder="Pendidikan/Pekerjaan/Penghasilan Pasien"
            />
            <label>Spiritual</label>
            <v-text-field
              :readonly="isEmr"
              v-model="form.spiritual"
              hide-details
              dense
              single-line
              placeholder="Agama/Keyakinan/Nilai-Nilai Pasien"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'ServiceAssessmentSubjectiveData',
);
export default {
  name: 'ServiceAssessmentSubjectiveData',
  props: {
    isEmr: Boolean,
  },
  computed: {
    ...mapGetters(['getSubjectData']),
    form: {
      get() {
        return this.getSubjectData;
      },
    },
  },
  methods: {
    ...mapMutations([
      'addAnamnesis',
      'deleteAnamnesis',
      'addAllergy',
      'deleteAllergy',
      'addPriorDisease',
      'deletePriorDisease',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.4rem;
}
$standard-font-size: 0.9vw;

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.grid-display {
  @include grid-display(10vw minmax(16vw, 26vw), true);
  column-gap: 1vw;
}

.grid-display-emr {
  @include grid-display(10vw minmax(16vw, 34vw), true);
  column-gap: 1vw;
}

.required:after {
  content: ' *';
  color: red;
}

h4 {
  color: #1976d2;
}
</style>
