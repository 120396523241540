import { render, staticRenderFns } from "./RegisteredPatientTable.vue?vue&type=template&id=14da7024&scoped=true"
import script from "./RegisteredPatientTable.vue?vue&type=script&lang=js"
export * from "./RegisteredPatientTable.vue?vue&type=script&lang=js"
import style0 from "./RegisteredPatientTable.vue?vue&type=style&index=0&id=14da7024&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14da7024",
  null
  
)

export default component.exports