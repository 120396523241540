import Constant from '@/const';
import axios from 'axios';

const getLabRegistration = async ({
  page = 1,
  itemCount = 7,
  search = '',
  status = 'unprocess',
  date = '',
} = {}) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/patient/lab?page=${page}&itemCount=${itemCount}&search=${search}&status=${status}&date=${date}`,
      ),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getLabRegistration;
