<template>
  <v-card class="sidenav">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      mini-variant-width="75"
      color="#30383a"
      dark
      permanent
      :width="windowWidth <= 1200 ? 200 : 240"
    >
      <div class="menu-wrapper">
        <v-list-item class="pa-2" :class="{ 'mb-3 py-3': mini }">
          <v-img
            max-height="60"
            max-width="60"
            v-if="mini"
            src="@/assets/image/LOGO FIX PNG 1.svg"
          ></v-img>

          <v-list-item>
            <router-link tag="div" to="/">
              <a class="white--text">
                <h3 class="title">
                  {{ $store.getters.userLoggedIn.clinic.name }}
                </h3>
              </a>
            </router-link>
          </v-list-item>
          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
        <v-container class="mx-0 mb-4 pa-0 menus">
          <v-list dense class="pt-3">
            <v-list-item
              v-for="item in items"
              :key="item.title"
              :to="item.route"
              class="py-2 pl-2 pr-0 item-hover"
              :class="{ white: isAccordionOpen[item.slug] }"
              @click="onClick(item.slug)"
            >
              <!-- :to="item.route" -->
              <v-list-item-icon v-show="mini">
                <v-icon
                  class="icon-hover"
                  :color="isAccordionOpen[item.slug] ? 'yellow' : 'white'"
                >
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-col class="py-0">
                <div style="display: flex">
                  <v-icon
                    :large="isAccordionOpen[item.slug]"
                    :color="isAccordionOpen[item.slug] ? 'yellow' : 'white'"
                    :class="{ 'icon-hover': !isAccordionOpen[item.slug] }"
                  >
                    {{ item.icon }}
                  </v-icon>
                  <v-list-item-title
                    class="text-sm-left pl-5 icon-hover"
                    :class="{
                      'subtitle-1': isAccordionOpen[item.slug],
                      'black--text': isAccordionOpen[item.slug],
                    }"
                  >
                    {{ item.title }}
                  </v-list-item-title>
                </div>
                <v-row class="py-3 pl-8">
                  <v-list-item
                    v-for="child in item.children"
                    v-show="isAccordionOpen[item.slug]"
                    :key="child.title"
                    :class="{ active: route === child.route }"
                    :to="child.route"
                    class="mb-0 pr-0 children-hover"
                    @click="onClick(item.slug)"
                  >
                    <!-- :to="item.route" -->

                    <v-list-item-content>
                      <v-list-item-title
                        class="text-sm-left child-hover"
                        :class="{
                          'subtitle-2': isAccordionOpen[item.slug],
                          'black--text': isAccordionOpen[item.slug],
                        }"
                      >
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-row>
              </v-col>

              <!-- <v-list-item-content>
              
              
            </v-list-item-content> -->
            </v-list-item>
          </v-list>
          <div class="version">
            <label class="white--text caption"
              >Versi {{ appVersion }} / {{ apiVersion }}</label
            >
          </div>
        </v-container>
      </div>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import Constant from '@/const';

const _ = require('lodash');
const axios = require('axios');

export default {
  name: 'DashboardSidenav',
  props: {
    windowWidth: Number,
  },
  data() {
    return {
      apiVersion: '',
      drawer: true,
      mini: false,
      items: [],
      isAccordionOpen: {
        masterdata: false,
        emr: false,
        registration: false,
        service: false,
        billing: false,
        inventory: false,
        pharmacy: false,
        laboratorium: false,
        reporting: false,
        setting: false,
        bridging: false,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.items = Constant.menus
        .filter(menu => {
          return (
            (menu.children.some(
              child =>
                this.$store.getters.userLoggedIn.access.indexOf(child.code) !==
                -1,
            ) &&
              menu.children.length > 0) ||
            (menu.code &&
              this.$store.getters.userLoggedIn.access.indexOf(menu.code) !== -1)
          );
        })
        .map(menu => ({
          ...menu,
          isActive: false,
          children: _.get(menu, 'children', [])
            .filter(
              child =>
                this.$store.getters.userLoggedIn.access.indexOf(child.code) !==
                -1,
            )
            .map(child => ({
              ...child,
              isActive: false,
            }))
            .filter(x => !x.hidden),
        }));
      this.getApiVersion();
    });
  },
  computed: {
    route() {
      return `/${this.$route.path.split('/')[1]}/${
        this.$route.path.split('/')[2]
      }`;
    },
    appVersion() {
      return Constant.version;
    },
  },
  methods: {
    getApiVersion() {
      axios.get(Constant.apiUrl.concat('/application/ver')).then(response => {
        this.apiVersion = response.data;
      });
    },
    onClick(key) {
      this.toggleAccordion(key);
      this.closeOtherAccordion(key);
    },
    toggleAccordion(key) {
      this.isAccordionOpen[key] = !this.isAccordionOpen[key];
    },
    closeOtherAccordion(openKey) {
      Object.keys(this.isAccordionOpen).forEach(key => {
        if (openKey !== key) this.isAccordionOpen[key] = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidenav {
  .menu-wrapper {
    height: 85vh;
  }

  .menus {
    max-height: 78vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 2px;
      background-color: #a3a3a3;
    }
    &::-webkit-scrollbar-thumb {
      width: 2px;
      background-color: black;
    }
  }

  .version {
    position: absolute;
    border-color: white;
    border: 100px;
    width: 100%;
    top: 95%;
  }
  .title {
    margin: 0;
    text-align: left;
    padding: 0;
  }
  .subtitle {
    margin: 0;
    text-align: right;
    padding: 0;
  }
  .item-hover {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    &:hover {
      background-color: white;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      .icon-hover {
        color: black !important;
      }
    }
  }

  .children-hover:hover,
  .active {
    background-color: #3498db;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    .child-hover {
      color: white !important;
    }
  }
}
a {
  text-decoration: none;
}
</style>
