import Constant from '@/const';
import axios from 'axios';

const getAllBed = async (id_inpatient, payload) => {
  try {
    const response = await axios.put(
      Constant.apiUrl.concat(`/inpatient/bed/${id_inpatient}`),
      payload,
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getAllBed;
