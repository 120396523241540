import EventBus from '@/eventBus';
import bus from '@/eventBus/bus.js';

export default {
  mixins: [bus],
  created() {
    this.busListener();
  },
  data() {
    return {
      indonesianVoice: null,
    };
  },
  mounted() {
    this.initializeVoices();
    this.triggerEmptySpeech();
  },
  methods: {
    initializeVoices() {
      const loadVoices = () => {
        const voices = window.speechSynthesis.getVoices();
        this.indonesianVoice =
          voices.find(voice => voice.lang === 'id-ID') || voices[0];
      };

      if (window.speechSynthesis.getVoices().length === 0) {
        window.speechSynthesis.onvoiceschanged = loadVoices;
      } else {
        loadVoices();
      }
    },
    triggerEmptySpeech() {
      const speech = new SpeechSynthesisUtterance('');
      speech.rate = 1;
      speech.lang = 'id-ID';
      speech.voice = this.indonesianVoice;

      window.speechSynthesis.speak(speech);
    },
    emitMessageCall(payload) {
      this.$socket.client.emit('message:call', payload);
      // EventBus.$emit('message:call', payload);
    },
    async onMessageUpdate({ refresh, isCalling }) {
      this.$socket.client.on('message:update', async data => {
        await refresh(); // refresh data on page
        if (isCalling && data.queue_code)
          this.callPatient({
            ...data,
            patient_name: data.patient_name.toLowerCase(),
          });
      });
    },
    emitIsCalling(data) {
      EventBus.callPatient$emit('message:call', data);
    },
    async checkSound() {
      this.$socket.client.on('message:check-sound-call', () => {
        const audio = this.$refs.notification;
        if (audio.paused) {
          this.$socket.client.emit('message:check-sound-on');
          audio.play();
          this.$socket.client.emit('message:check-sound-end');
        } else {
          audio.pause();
          audio.currentTime = 0;
        }
      });
    },
    callPatient(data) {
      const methods = this;
      const text = [];
      const statusCall = status => {
        const trimmedStatus = status.trim().toUpperCase();
        const statusMapping = {
          'TN.': 'Tuan',
          'NN.': 'Nona',
          'NY.': 'Nyonya',
          'BY.': 'Bayi',
          'AN.': 'Anak',
          'SDR.': 'Saudara',
        };
        return statusMapping[trimmedStatus] || '';
      };

      // methods.speak('Nomor antrean', 1);
      text.push('Nomor antrean');

      const alphanumeric = data.queue_code
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .split('');
      for (let i = 0; i < alphanumeric.length; i++) {
        text.push(alphanumeric[i]);
      }

      text.push(
        `${statusCall(
          data.patient_name.split(' ')[data.patient_name.split(' ').length - 1],
        )} ${data.patient_name.split(' ').slice(0, -1)}`,
      );

      text.push(`Silahkan ke ruangan ${data.room}`);
      methods.speak(text, 1.1);
    },
    speak(words, rate) {
      const wordsText = !Array.isArray(words) ? [words] : words;

      window.speechSynthesis.cancel();

      const speakNext = index => {
        if (index >= wordsText.length) return;

        const speech = new SpeechSynthesisUtterance(wordsText[index]);
        speech.rate = rate;
        speech.lang = 'id-ID';
        speech.voice = this.indonesianVoice;

        window.speechSynthesis.speak(speech);
        speech.onend = () => speakNext(index + 1);
      };

      speakNext(0);
    },
  },
};
