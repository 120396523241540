import Constant from '@/const';
import axios from 'axios';

const getInpatientPatients = async params => {
  try {
    const response = await axios.get(Constant.apiUrl.concat('/inpatient'), {
      params,
    });
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getInpatientPatients;
