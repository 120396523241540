<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title class="text-left pl-2">
        <h2 class="title pa-0">Jadwal Dokter</h2>
        <h5 class="subtitle">Pengaturan Ubah Kuota Harian Pasien</h5>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <router-link to="/pendaftaran/jadwal-dokter">
        <v-btn icon class="mr-5">
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </router-link>
    </v-toolbar>
    <v-divider></v-divider>
    <v-row no-gutters class="pa-3">
      <v-col cols="6" class="pt-10">
        <v-row class="px-3" align="baseline">
          <v-col cols="5" class="text-left">
            <label class="body-2 font-weight-bold">Nama Dokter</label>
          </v-col>
          <v-col cols="5">
            <v-combobox
              :items="doctors"
              placeholder="Pilih Dokter"
              dense
              item-value="doctorId"
              item-text="doctorName"
              clearable
              v-model="input.doctor"
              hide-details="false"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row class="px-3" align="baseline">
          <v-col cols="5" class="text-left">
            <label class="body-2 font-weight-bold">Nama Poli</label>
          </v-col>
          <v-col cols="5">
            <v-select
              placeholder="Pilih Poli"
              dense
              :items="poly"
              item-value="unitId"
              item-text="unitName"
              v-model="input.poly"
              hide-details="false"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="px-3" align="baseline">
          <v-col cols="5" class="text-left">
            <label class="body-2 font-weight-bold">Tanggal Praktik</label>
          </v-col>
          <v-col cols="5">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="input.date"
                  label="mm/dd/yyy"
                  append-icon="mdi-chevron-down"
                  v-bind="attrs"
                  v-on="on"
                  hide-details="false"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker v-model="input.date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(input.date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="px-3" align="baseline">
          <v-col cols="5" class="text-left">
            <label class="body-2 font-weight-bold">Kuota Harian Pasien</label>
          </v-col>
          <v-col cols="5">
            <v-text-field
              placeholder="Jumlah Kuota Pasien"
              v-model="input.quota"
              dense
              type="number"
              min="1"
              :rules="requiredRule"
              oninput="if(this.value < 0) this.value = 1;"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5"></v-col>
          <v-col cols="4">
            <v-btn
              width="200px"
              color="primary"
              tile
              depressed
              class="text-none"
              @click="addData()"
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row class="justify-end" align="baseline">
          <v-col cols="3" class="text-left">
            <label class="body-2 font-weight-bold">Pilih Poli</label>
          </v-col>
          <v-col cols="4" class="">
            <v-select
              placeholder="Pilih Poli"
              dense
              :items="poly"
              item-value="unitName"
              item-text="unitName"
              v-model="polyclinic"
              return-object
              @change="showAllSchedule"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="justify-end">
          <v-card class="rounded-lg pa-3" width="600px" color="#D9EDFF" flat>
            <v-row no-gutters class="body-1 font-weight-bold"
              >Jadwal Dokter Spesialis {{ polyclinic.unitName }}</v-row
            >
            <v-row row class="caption font-weight-bold">
              <v-col class="text-left" cols="3">Nama Dokter</v-col>
              <v-col class="text-left">Hari Praktik</v-col>
              <v-col>Kuota Pasien</v-col>
              <v-col>Jam Mulai</v-col>
              <v-col>Jam Selesai</v-col>
            </v-row>
            <div v-if="doctorSchedule !== ''">
              <v-row
                v-for="doctorSchedule in doctorSchedule"
                :key="doctorSchedule.doctorName"
                class="caption pa-3"
              >
                <v-col class="text-left pa-0" cols="3">{{
                  doctorSchedule.doctorName
                }}</v-col>
                <v-col class="schedule-grid py-0 px-2">
                  <template
                    v-for="(schedule, index) in doctorSchedule.docSchedule"
                  >
                    <label class="text-left" :key="'date' + index">{{
                      schedule.date
                    }}</label>
                    <label :key="'quota' + index">{{ schedule.quota }}</label>
                    <label :key="'startTime' + index">{{
                      schedule.startTime
                    }}</label>
                    <label :key="'endTime' + index">{{
                      schedule.endTime
                    }}</label>
                  </template>
                </v-col>
              </v-row>
            </div>
            <div v-if="doctorSchedule === ''">
              <p class="font-weight-bold">Tidak ada jadwal</p>
            </div>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';

const axios = require('axios');
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
  name: 'QuotaSchedule',
  data() {
    return {
      doctors: [],
      doctor: [],
      poly: [],
      startTime: null,
      endTime: null,
      menu2: false,
      menu1: false,
      menu: false,
      polyclinic: '',
      requiredRule: [v => !!v || 'Wajib diisi!'],
      input: {
        doctor: '',
        poly: '',
        date: '',
        quota: '',
      },
      doctorSchedule: [],
      dateSchedule: new Date().toISOString().substr(0, 10),
      endDate: '',
    };
  },
  methods: {
    addData() {
      axios
        .put(Constant.apiUrl.concat('/schedule/quota'), {
          date: this.input.date,
          id_unit: this.input.poly,
          id_staff: this.input.doctor.doctorId,
          quota: this.input.quota,
        })
        .then(response => {
          if (response.status === 200) {
            Swal.fire('Jadwal Kuota berhasil diubah!', '', 'success');
            this.showAllSchedule();
            this.input = {
              date: '',
              poly: '',
              doctor: '',
              quota: '',
            };
          }
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Kuota belum berhasil diubah. Silakan ulangi!',
          });
        });
    },

    getPoly() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=&itemCount=&search=&sort=name',
          ),
        )
        .then(response => {
          if (response.status === 200) {
            this.poly = response.data.data
              .filter(
                x =>
                  x.detail.installation === 'Installasi Rawat Inap' ||
                  x.detail.installation === 'Instalasi Rawat Jalan',
              )
              .map(unit => {
                return {
                  unitId: unit._id.toString(),
                  unitName: unit.name,
                };
              });
          }
        });
    },

    getDoctor() {
      axios.get(Constant.apiUrl.concat('/master/staff')).then(response => {
        if (response.status === 200) {
          var data = response.data.data;
          this.doctors = data
            .filter(
              v =>
                v.role.role.some(item => item.toLowerCase() === 'dokter') &&
                v.role.status === true,
            )
            .map(doctor => {
              return {
                doctorId: doctor._id.toString(),
                doctorName: doctor.detail.name,
              };
            });
        }
      });
    },
    showAllSchedule() {
      axios
        .post(Constant.apiUrl.concat('/schedule/'), {
          startDate: this.dateSchedule,
          endDate: this.endDate,
          id_unit: this.polyclinic.unitId,
          id_staff: '',
          isActive: true,
        })
        .then(response => {
          if (response.status == 200) {
            let allSchedule = response.data.data;
            this.doctorSchedule = allSchedule.map(schedule => {
              return {
                doctorName: schedule.id_staff,
                polyclinic: schedule.id_unit,
                docSchedule: schedule.schedule,
              };
            });
          }
        });
    },
    getNextSevenDay(dateSchedule) {
      let startDate = dateSchedule;
      if (Object.prototype.toString.call(startDate) !== '[object Date]') {
        startDate = new Date(dateSchedule);
      }
      this.endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + 7,
      )
        .toISOString()
        .slice(0, 10);
    },
  },
  mounted() {
    this.getPoly();
    this.getDoctor();
    this.getNextSevenDay(this.date);
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.title {
  font-size: 22px;
  font-weight: 700;
}
.schedule-grid {
  display: grid;
  grid-template-columns: 100px 80px 135px 65px;
}
</style>
