<template>
  <v-card class="main-card">
    <v-card-title
      primary-title
      class="font-weight-bold py-0 d-flex justify-space-between"
    >
      Penjualan Obat Bebas
      <v-btn small text fab @click="$emit('close-form')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <div class="patient-data">
      <!-- first row -->
      <span class="text-left subtitle-2 font-weight-bold">No Transaksi</span>
      <span class="text-left subtitle-2 font-weight-regular grey--text">{{
        patientData.registNumber
      }}</span>
      <span class="text-left subtitle-2 font-weight-bold">Nama Operator</span>
      <span class="text-left subtitle-2 font-weight-regular grey--text">{{
        patientData.operatorName
      }}</span>
      <!-- second row -->
      <span class="text-left subtitle-2 font-weight-bold">Nama</span>
      <span class="text-left subtitle-2 font-weight-regular grey--text"
        >{{ patientData.name }} ({{ patientData.gender ? 'L' : 'P' }})</span
      >
      <span class="text-left subtitle-2 font-weight-bold">Usia</span>
      <span class="text-left subtitle-2 font-weight-regular grey--text"
        >{{ patientData.age }} Tahun</span
      >
      <span class="text-left subtitle-2 font-weight-bold"
        >Tanggal Pelayanan</span
      >
      <span class="text-left subtitle-2 font-weight-regular grey--text"
        >{{ patientData.date }} pukul {{ patientData.time }}</span
      >
    </div>
    <v-row>
      <v-col class="pa-9 pb-0">
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          item-key="id"
          :loading="isLoading"
          :items-per-page.sync="table.itemCount"
          :page.sync="table.page"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ table.items.indexOf(item) + 1 }}</td>
              <td class="text-left">{{ item.drug }}</td>
              <td v-if="item.isMixed || isPaid" class="text-left">
                {{ item.amount }}
              </td>
              <td v-else class="text-left">
                <v-text-field
                  type="number"
                  hide-details
                  single-line
                  @blur="updateAmount(item)"
                  @click="onClickInput(item.amount, 'amount')"
                  v-model="item.amount"
                ></v-text-field>
              </td>
              <td>{{ item.unit }}</td>
              <td class="text-right">{{ currency(item.pricePerUnit) }}</td>
              <td class="text-right">{{ currency(item.totalPrice) }}</td>
              <td v-if="isPaid" class="text-right">
                {{ item.percentDisc }}
              </td>
              <td v-else class="text-left">
                <v-text-field
                  suffix="%"
                  v-model="item.percentDisc"
                  @blur="updateDiscount(item)"
                  @click="onClickInput(item.percentDisc, 'discount')"
                  hide-details
                  type="number"
                  :min="0"
                  :max="100"
                  single-line
                ></v-text-field>
              </td>
              <td class="text-right">{{ currency(item.totalDisc) }}</td>
              <td class="text-right">{{ currency(item.subtotal) }}</td>
              <td>
                <v-icon v-if="!isPaid" color="red" @click="deleteItem(item)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
            <tr
              style="background-color: #f4f5f5 !important"
              v-show="table.items.length !== 0"
              v-if="isLastItem(item)"
            >
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-right">
                <span class="subtitle-2">{{ currency(totalPrice) }}</span>
              </td>
              <td></td>
              <td class="text-right">
                <span class="subtitle-2">{{ currency(totalDiscount) }}</span>
              </td>
              <td class="text-right">
                <span class="subtitle-2">{{ currency(totalAll) }}</span>
              </td>
              <td></td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="!isPaid">
      <v-col cols="8"></v-col>
      <v-col class="payment px-9" cols="3">
        <span class="font-weight-bold text-left subtitle-2">Sisa Tagihan</span>
        <span class="subtitle-2 text-left font-weight-regular">{{
          currency(totalRemainingBill)
        }}</span>
        <span class="font-weight-bold text-left subtitle-2"
          >Input Pembayaran</span
        >
        <span v-if="isPaid" class="text-left">-</span>
        <v-currency-field
          v-else
          v-model="input.payment"
          prefix="Rp."
          class="input-payment"
          hide-details
          single-line
        ></v-currency-field>
        <span class="font-weight-bold text-left subtitle-2">Kembalian</span>
        <span class="subtitle-2 text-left font-weight-regular">{{
          currency(
            remainingBill > input.payment ||
              input.payment === 0 ||
              !input.payment
              ? 0
              : input.payment - remainingBill,
          )
        }}</span>
      </v-col>
    </v-row>
    <v-btn
      class="btn-save"
      :loading="isLoadingPayment"
      v-if="!isPaid"
      color="primary"
      depressed
      @click="addPayment()"
      >SIMPAN</v-btn
    >
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.type === 'error' ? 'red' : 'primary'"
      content-class="text-center font-weight-bold"
      height="20"
      :timeout="2000"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import Constant from '@/const';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';
export default {
  name: 'OverCounterBilling',
  mixins: [formatMixin, AlertMixin],
  props: {
    idBilling: {
      type: String,
      default: '',
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingPayment: false,
      oldDiscountValue: 0,
      oldAmountValue: 0,
      snackbar: {
        type: '',
        show: false,
        message: '',
      },
      remainingBill: 0,
      input: { payment: 0 },
      patientData: {
        registNumber: '',
        name: '',
        operatorName: '',
        age: '',
        date: '',
        time: '',
        gender: '',
      },
      table: {
        headers: [
          {
            text: 'No',
            value: 'no',
            sortable: false,
            class: 'text-sm-center',
          },
          {
            text: 'Item Pelayanan',
            value: 'drug',
            sortable: false,
            class: 'text-sm-left',
            width: '25%',
          },
          {
            text: 'Jumlah',
            value: 'amount',
            sortable: false,
            class: 'text-sm-left',
            width: '10%',
          },
          {
            text: 'Satuan',
            value: 'unit',
            sortable: false,
            class: 'text-sm-center',
            width: '10%',
          },
          {
            text: 'Harga Satuan',
            value: 'pricePerUnit',
            sortable: false,
            class: 'text-sm-right',
            width: '10%',
          },
          {
            text: 'Harga Total',
            value: 'totalPrice',
            sortable: false,
            class: 'text-sm-right',
            width: '10%',
          },
          {
            text: 'Diskon (%)',
            value: 'percentDisc',
            sortable: false,
            class: 'text-sm-right',
            width: '10%',
          },
          {
            text: 'Diskon (Rp)',
            value: 'totalDisc',
            sortable: false,
            class: 'text-sm-right',
            width: '10%',
          },
          {
            text: 'Subtotal',
            value: 'subtotal',
            sortable: false,
            class: 'text-sm-right',
            width: '10%',
          },
          {
            text: '',
            value: '',
            sortable: false,
          },
        ],
        items: [],
        itemCount: 5,
        page: 1,
      },
    };
  },
  computed: {
    isLastItem() {
      const generateMultiplesOf = (n, length) => {
        const multiples = new Set();
        for (let i = n; i <= length; i += n) {
          multiples.add(i);
        }
        return multiples;
      };

      const itemCount = this.table.itemCount;
      const itemsLength = this.table.items.length;

      const multiplesOf5 = generateMultiplesOf(5, itemsLength);
      const multiplesOf10 = generateMultiplesOf(10, itemsLength);
      const multiplesOf15 = generateMultiplesOf(15, itemsLength);

      const lastItemIndex = itemsLength;

      return item => {
        const index = this.table.items.indexOf(item) + 1;
        if (index === lastItemIndex) return true;

        const isMultiple5 =
          itemCount === 5 && itemsLength > 5 && multiplesOf5.has(index);
        const isMultiple10 =
          itemCount === 10 && itemsLength > 10 && multiplesOf10.has(index);
        const isMultiple15 =
          itemCount === 15 && itemsLength > 15 && multiplesOf15.has(index);

        return isMultiple5 || isMultiple10 || isMultiple15;
      };
    },
    totalRemainingBill() {
      return this.remainingBill;
    },
    totalPrice() {
      const total = this.table.items.reduce(
        (a, b) => +a + +b.pricePerUnit * +b.amount,
        0,
      );
      return total;
    },
    totalDiscount() {
      const total = this.table.items.reduce((a, b) => +a + +b.totalDisc, 0);
      return total;
    },
    totalAll() {
      const total = this.table.items.reduce((a, b) => +a + +b.subtotal, 0);
      return total;
    },
  },
  mounted() {
    this.getBilling();
  },
  methods: {
    onClickInput(value, type) {
      if (type === 'amount') this.oldAmountValue = +value;
      if (type === 'discount') this.oldDiscountValue = +value;
    },
    async addPayment() {
      if (!this.input.payment) {
        Swal.fire({
          icon: 'error',
          title: 'Tidak Dapat Menyimpan Pembayaran',
          text: 'Pembayaran harus lebih dari 0',
        });
        return;
      }
      this.isLoadingPayment = true;
      const payload = {
        amount: this.input.payment,
        is_cash: true,
      };
      try {
        const response = await axios.put(
          Constant.apiUrl.concat(
            `/billing/drugssales/payment/${this.patientData.registNumber}`,
          ),
          payload,
        );
        if (response) {
          Swal.fire({
            icon: 'success',
            title: 'Berhasil Menyimpan',
            text: 'Berhasil menyimpan pembayaran penjualan obat bebas',
          });
          this.$emit('close-form');
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Gagal menyimpan pembayaran penjualan obat bebas',
        });
      } finally {
        this.isLoadingPayment = false;
      }
    },
    async deleteItem(item) {
      Swal.fire({
        title: 'Peringatan',
        text: `Apakah Anda Yakin Akan Menghapus ${item.drug}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'primary',
        cancelButtonColor: 'red',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          const payload = {
            id: item.id,
            type: item.type,
            name: item.drug,
            id_drugs: item.idDrug,
            id_stock: item.id_stock,
            drugs: item.idDrug
              ? undefined
              : item.drugs.map(x => {
                  return {
                    id_drugs: x.id_drugs,
                    id_stock: x.id_stock,
                  };
                }),
            amount: item.amount,
            price: item.pricePerUnit,
            updated_by: this.$store.getters.userLoggedIn.id,
            updated_at: moment().format(),
          };
          axios
            .put(
              Constant.apiUrl.concat(
                `/billing/drugssales/delete/${this.patientData.registNumber}`,
              ),
              payload,
            )
            .then(response => {
              if (response) {
                this.snackbar = {
                  show: true,
                  message: 'Berhasil menghapus item',
                  type: 'success',
                };
              }
            })
            .catch(() => {
              this.snackbar = {
                show: true,
                message: 'Gagal menghapus item',
                type: 'error',
              };
            })
            .finally(() => {
              this.table.items = [];
              this.getBilling();
            });
        }
      });
    },
    async updateAmount(item) {
      if (+this.oldAmountValue !== +item.amount) {
        // check if drug amount is change
        const payload = {
          id: item.id,
          type: item.type,
          name: item.drug,
          id_drugs: item.idDrug,
          id_stock: item.id_stock,
          drugs: item.idDrug
            ? undefined
            : item.drugs.map(x => {
                return {
                  id_drugs: x.id_drugs,
                  id_stock: x.id_stock,
                };
              }),
          amount: +item.amount,
          price: +item.pricePerUnit,
          updated_by: this.$store.getters.userLoggedIn.id,
          updated_at: moment().format(),
        };
        try {
          const response = await axios.put(
            Constant.apiUrl.concat(
              `/billing/drugssales/update/${this.patientData.registNumber}`,
            ),
            payload,
          );
          if (response) {
            this.snackbar = {
              show: true,
              message: 'Berhasil mengubah jumlah',
              type: 'success',
            };
          }
        } catch (error) {
          this.snackbar = {
            show: true,
            message: 'Gagal mengubah jumlah',
            type: 'error',
          };
        } finally {
          this.table.items = [];
          this.getBilling();
        }
      }
    },
    async updateDiscount(item) {
      // endpoint for count discount
      if (+this.oldDiscountValue !== +item.percentDisc) {
        // check if discount is change
        const payload = {
          id: item.id,
          type: item.type,
          name: item.drug,
          id_drugs: item.idDrug,
          id_stock: item.id_stock,
          drugs: item.idDrug
            ? undefined
            : item.drugs.map(x => {
                return {
                  id_drugs: x.id_drugs,
                  id_stock: x.id_stock,
                };
              }),
          amount: item.amount,
          discount: +item.percentDisc,
          price: +item.pricePerUnit,
          updated_by: this.$store.getters.userLoggedIn.id,
          updated_at: moment().format(),
        };
        try {
          const response = await axios.put(
            Constant.apiUrl.concat(
              `/billing/drugssales/discount/${this.patientData.registNumber}`,
            ),
            payload,
          );
          if (response) {
            this.snackbar = {
              show: true,
              message: 'Berhasil menambahkan diskon',
              type: 'success',
            };
          }
        } catch (error) {
          this.snackbar = {
            show: true,
            message: 'Gagal menambahkan diskon',
            type: 'error',
          };
        } finally {
          this.table.items = [];
          this.getBilling();
        }
      }
    },
    async getBilling() {
      this.isLoading = true;
      try {
        let mix, nonmix, medtool;
        const response = await axios.get(
          Constant.apiUrl.concat(`/billing/drugssales/${this.idBilling}`),
        );
        const { data } = response.data;
        this.remainingBill = data.remaining_bill;
        this.patientData = {
          registNumber: data.transaction_code,
          name: data.buyer_data.buyer_name,
          operatorName: data.timestamps.created_by?.name,
          age: data.buyer_data.buyer_age,
          gender: data.buyer_data.buyer_gender,
          date: moment(data.order_date).format('DD/MM/YYYY'),
          time: moment(data.order_date).format('HH:mm'),
          isMixed: false,
        };
        mix = data.detail.mixed_medicine.map(drug => {
          return {
            isMixed: true,
            drugs: drug.drugs.map(i => {
              return {
                id_drugs: i.id_drugs._id,
                id_stock: i.id_stock,
              };
            }),
            type: 'mixed_medicine',
            drug: drug?.name,
            amount: drug.amount,
            unit: drug.amount_unit,
            pricePerUnit: drug.price,
            id: drug._id,
            totalPrice: drug.price * drug.amount,
            percentDisc: drug.discount_percentage,
            totalDisc: drug.discount_price,
            subtotal: drug.price * drug.amount - drug.discount_price,
          };
        });
        nonmix = data.detail.non_mixed_medicine.map(drug => {
          return {
            isMixed: false,
            type: 'non_mixed_medicine',
            id_stock: drug.id_stock,
            drug: drug.id_drugs.detail?.name,
            amount: drug.amount,
            unit: drug.id_drugs.detail?.packaging?.filter(i => i.isDefault)[0]
              ?.package_unit,
            pricePerUnit: drug.price,
            id: drug._id,
            totalPrice: drug.price * drug.amount,
            percentDisc: drug.discount_percentage,
            totalDisc: drug.discount_price,
            subtotal: drug.price * drug.amount - drug.discount_price,
            idDrug: drug.id_drugs._id,
          };
        });
        medtool = data.detail.medical_goods.map(drug => {
          return {
            drug: drug.id_drugs
              ? drug.id_drugs.detail?.name
              : drug.id_goods.detail?.name,
            type: 'medical_goods',
            id_stock: drug.id_stock,
            amount: drug.amount,
            unit: drug.id_drugs
              ? drug.id_drugs.detail?.packaging?.filter(i => i.isDefault)[0]
                  ?.package_unit
              : drug.id_goods.detail?.packaging?.filter(i => i.isDefault)[0]
                  ?.package_unit,
            pricePerUnit: drug.price,
            id: drug._id,
            totalPrice: drug.price * drug.amount,
            percentDisc: drug.discount_percentage,
            totalDisc: drug.discount_price,
            subtotal: drug.price * drug.amount - drug.discount_price,
            idDrug: drug.id_drugs ? drug.id_drugs._id : drug.id_goods._id,
          };
        });
        this.table.items.push(...mix, ...nonmix, ...medtool);
      } catch (error) {
        this.table.items = [];
        this.showErrorAxios(error);
      } finally {
        this.isLoading = false;
      }
    },

    // FUNCTION TO UPDATE BILLING (MAYBE USED LATER)

    // async updateBilling(item) {
    //   try {
    //     const payload = {
    //       id: item.id,
    //       type: item.type,
    //       name: item.drug,
    //       // id_drugs: item.
    //       id_stock: item.id_stock,
    //       amount: item.amount,
    //       price: item.pricePerUnit,
    //       created_at: moment().format(),
    //       created_by: this.$store.getters.userLoggedIn.id,
    //       updated_at: moment().format(),
    //       updated_by: this.$store.getters.userLoggedIn.id,
    //     };
    //     const response = await axios.put(
    //       Constant.apiUrl.concat(
    //         `/billing/drugssales/update/${this.patientData.registNumber}`,
    //       ),
    //       payload,
    //     );
    //   } catch (error) {
    //     // eslint-disable-next-line
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.input-payment {
  text-align: center;
}
.payment {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 10px;
}
.patient-data {
  padding: 0px 1.5rem;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr) 4fr;
  row-gap: 0.3rem;
}
.main-card {
  overflow-x: hidden;
}
.btn-save {
  position: absolute;
  bottom: 3vh;
  right: 2vw;
}
</style>
