<template>
  <div class="container text-sm-left">
    <v-main>
      <v-container>
        <PageJumbotron
          title="Laporan Pendapatan"
          subtitle="Cek semua laporan yang bersangkutan dengan keuangan."
          image="illustration_billing-report.svg"
        />

        <h3 class="my-4 py-3">Submenu</h3>
        <SubMenu :submenus="submenus" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import PageJumbotron from '@/components/SharedComponent/PageJumbotron.vue';
import SubMenu from '@/components/SharedComponent/SubMenu.vue';

export default {
  data() {
    return {
      submenus: [
        {
          title: 'Laporan Verifikasi',
          icon: 'verification_report',
          route: '/keuangan/billing/LaporanBilling/LaporanVerifikasi',
        },
        {
          title: 'Laporan Pendapatan',
          icon: 'income_report',
          route: '/keuangan/billing/LaporanBilling/LaporanPendapatan',
        },

        {
          title: 'Rekapitulasi Piutang',
          icon: 'summary',
          route: '/keuangan/billing/LaporanBilling/RekapitulasiPiutang',
        },
      ],
    };
  },
  components: {
    PageJumbotron,
    SubMenu,
  },
};
</script>
