const state = {
  kid: {
    surgery: '',
    diagnose: '',
    cogntiveImpairment: '',
    medicamentosa: '',
  },
  adult: {
    heparinLockTherapy: { check: false, score: 0 },
    mentalStatus: { check: 'accordingToAbility', score: 0 },
    fallHistory: { check: false, score: 0 },
    secDiagnose: { check: false, score: 0 },
    walker: { check: 'normal', score: 0 },
    walk: { check: 'normal', score: 0 },
  },
};

const getters = {
  // kid
  getSurgery: state => state.kid.surgery,
  getDiagnose: state => state.kid.diagnose,
  getCogntiveImpairment: state => state.kid.cogntiveImpairment,
  getMedicamentosa: state => state.kid.medicamentosa,

  //adult
  getHeparinLockTherapy: state => state.adult.heparinLockTherapy,
  getMentalStatus: state => state.adult.mentalStatus,
  getFallHistory: state => state.adult.fallHistory,
  getSecDiagnose: state => state.adult.secDiagnose,
  getWalker: state => state.adult.walker,
  getWalk: state => state.adult.walk,
};

const mutations = {
  clearFallRiskState(state) {
    Object.assign(state, {
      kid: {
        surgery: '',
        diagnose: '',
        cogntiveImpairment: '',
        medicamentosa: '',
      },
      adult: {
        heparinLockTherapy: { check: false, score: 0 },
        mentalStatus: { check: 'accordingToAbility', score: 0 },
        fallHistory: { check: false, score: 0 },
        secDiagnose: { check: false, score: 0 },
        walker: { check: 'normal', score: 0 },
        walk: { check: 'normal', score: 0 },
      },
    });
  },
  // kid
  setSurgery(state, payload) {
    state.kid.surgery = payload;
  },
  setDiagnose(state, payload) {
    state.kid.diagnose = payload;
  },
  setCogntiveImpairment(state, payload) {
    state.kid.cogntiveImpairment = payload;
  },
  setMedicamentosa(state, payload) {
    state.kid.medicamentosa = payload;
  },

  // adult
  setHeparinLockTherapy(state, payload) {
    state.adult.heparinLockTherapy = payload;
  },
  setMentalStatus(state, payload) {
    state.adult.mentalStatus = payload;
  },
  setFallHistory(state, payload) {
    state.adult.fallHistory = payload;
  },
  setSecDiagnose(state, payload) {
    state.adult.secDiagnose = payload;
  },
  setWalker(state, payload) {
    state.adult.walker = payload;
  },
  setWalk(state, payload) {
    state.adult.walk = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
