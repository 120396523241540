<template>
  <div class="login-box">
    <div class="px-md-7 px-lg-10">
      <img src="@/assets/image/LOGO FIX PNG 1.svg" alt="Neurovi" />
      <h2>Log in.</h2>
      <span class="opacity-7">
        Log in with data that you entered during registration
      </span>
    </div>

    <v-form class="login-form">
      <!-- <label for="username"> Username </label> -->
      <div class="wrapper">
        <v-text-field
          label="Username"
          name="nip"
          id="nip"
          v-model="nip"
          @keydown="onEnter"
          append-icon="mdi-account"
          :rules="[rules.required]"
          outlined
        />
      </div>
      <div class="wrapper">
        <v-text-field
          outlined
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          name="password"
          @click:append="showPassword = !showPassword"
          v-model="password"
          @keydown="onEnter"
        ></v-text-field>
      </div>
      <div class="wrapper">
        <button type="submit" class="button" @click.prevent="getUnit()">
          L O G I N
        </button>
        <a href="#" class="opacity-7">Lupa Password?</a>
      </div>
    </v-form>
    <v-dialog
      v-model="dialog"
      :persistent="disableLogin"
      v-if="dialog"
      max-width="450"
    >
      <v-card v-if="disableLogin" class="pa-5">
        <v-icon color="red" size="64" class="mb-3"
          >mdi-close-circle-outline</v-icon
        >
        <h2 class="mb-6">
          Percobaan Login Terlalu Banyak, Silahkan Ulangi Dalam
          <span class="primary--text">{{ timer }} detik</span>
        </h2>
        <v-btn
          color="primary"
          :disabled="!isAllowRelogin"
          depressed
          @click="relogin()"
          >Ulangi</v-btn
        >
      </v-card>
      <v-card v-else class="pa-4">
        <v-card-title
          class="font-weight-bold pt-0 mb-4 d-flex justify-center"
          primary-title
        >
          Pilih Unit Kerja Anda
        </v-card-title>
        <v-form class="px-5 ma-0" ref="form">
          <v-combobox
            append-icon="mdi-chevron-down"
            v-model="selectedUnit"
            :items="unit"
            item-text="name"
            :rules="[rules.unit]"
            outlined
            single-line
            placeholder="Pilih Unit"
          >
          </v-combobox>
        </v-form>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" @click="unitConfirm()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Constant from '@/const';
import router from '@/router';
import AlertMixin from '@/mixin/alertMixin';

const axios = require('axios');

axios.defaults.headers.post['Content-Type'] = 'application/json';

let interval;
export default {
  name: 'LoginBox',
  mixins: [AlertMixin],
  data() {
    return {
      dialog: false,
      disableLogin: false,
      isAllowRelogin: false,
      showPassword: false,
      unit: [],
      selectedUnit: '',
      nip: '',
      password: '',
      loginAttempts: 0,
      timer: 0,
      rules: {
        required: value => !!value || 'Diperlukan.',
        unit: value => !!value || 'Masukkan Unit.',
        min: v => v.length >= 8 || 'Minimal 8 karakter',
      },
    };
  },
  watch: {
    dialog() {
      this.selectedUnit = '';
    },
  },
  mounted() {
    let attempts;
    attempts = JSON.parse(localStorage.getItem('loginAttempts'));
    if (attempts == 5) {
      localStorage.setItem('loginAttempts', this.loginAttempts);
      this.timer = 30;
      this.refresh();
      this.disableLogin = true;
      this.dialog = true;
    }
  },
  methods: {
    checkNikPassword() {
      if (this.nip === '') {
        document.getElementById('nip').focus();
        return false;
      }
      if (this.password === '') {
        document.getElementById('password').focus();
        return false;
      }
      return true;
    },
    onEnter(e) {
      if (e.keyCode === 13) {
        this.getUnit();
        e.preventDefault();
      }
    },
    relogin() {
      this.dialog = false;
      this.disableLogin = false;
    },
    recount() {
      if (!this.timer) {
        interval = clearInterval(interval);
        this.isAllowRelogin = true;
        this.loginAttempts = 0;
      } else {
        this.timer--;
      }
    },
    refresh() {
      if (interval !== null) {
        interval = clearInterval(interval);
      }
      this.recount();
      interval = setInterval(this.recount, 1000);
    },
    getUnit() {
      if (this.nip) {
        axios
          .post(Constant.apiUrl.concat('/master/staff/unit'), {
            nip: this.nip,
            password: this.password,
          })
          .then(response => {
            const { unit } = response.data.data;
            if (unit.length === 1) {
              this.selectedUnit = unit[0];
              this.login();
            } else {
              this.unit = unit;
              this.dialog = true;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showErrorAxios(error);
              let attempts = 0;
              this.loginAttempts++;
              localStorage.setItem('loginAttempts', this.loginAttempts);
              attempts = JSON.parse(localStorage.getItem('loginAttempts'));
              if (attempts == 5) {
                localStorage.setItem('loginAttempts', this.loginAttempts);
                this.timer = 30;
                this.refresh();
                this.disableLogin = true;
                this.dialog = true;
              }
              this.unit = [];
            }
          });
      }
    },
    unitConfirm() {
      if (this.$refs.form.validate()) {
        this.login();
      }
    },
    login() {
      if (this.checkNikPassword()) {
        const unit = this.selectedUnit.id_unit;
        axios
          .post(Constant.apiUrl.concat('/master/staff/login'), {
            nip: this.nip,
            password: this.password,
            unit,
          })
          .then(response => {
            if (response.status === 200) {
              this.$store.commit('saveToken', response.data.token);
              axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
              router.replace('/');
            }
          })
          .catch(error => {
            this.showErrorAxios(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  text-align: left;
  min-height: 100%;
  min-width: 100%;
  font-size: 1.2rem;
  font-family: 'Roboto', sans-serif;
  img {
    height: 2.7em;
    margin-bottom: 4em;
  }

  h1 {
    font-size: 2.2rem;
    margin-bottom: 0.25em;
  }
  span {
    font-size: 0.9rem;
    margin-bottom: 1em;
  }

  .login-form {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  label {
    opacity: 0.7;
    font-size: 0.9rem;
    margin-bottom: 0.5em;
  }

  a {
    display: block;
    margin: 0 auto;
    text-decoration: none;
    font-size: 1rem;
  }

  .button {
    font-size: 1rem;
    border-radius: 0.5em;
    border-style: solid;
    margin-bottom: 1em;
    padding: 1em;
    margin-top: 1em;
    background: #1f8ce5;
    color: #fff;
    font-weight: bolder;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    width: 30vw;
    align-content: center;
    @media only screen and (max-width: 600px) {
      width: 75vw;
    }
  }

  .opacity-4 {
    opacity: 0.4;
  }
  .opacity-7 {
    opacity: 0.7;
  }
}

// .login-box {
//   position: absolute;
//   font-family: 'Mont Extra Light', Helvetica, sans-serif;
//   right: 5vw;
//   bottom: 0;
//   background-image: url('~@/assets/image/loginbox-background.png');
//   background-repeat: no-repeat;
//   background-size: cover;
//   padding: 100px;
// }
// .login-form {
//   display: flex;
//   flex-direction: column;
//   text-align: left;
//   margin-top: 100px;
//   width: fit-content;
// }
// .input-label {
//   color: $primary-color;
//   font-weight: 700;
//   font-size: 1.25rem;
// }
// .input-text {
//   border-width: 0;
//   border-radius: 5%;
//   min-width: 300px;
//   max-width: 300px;
//   padding-left: 10px;
//   font-size: 1.25rem;
// }
// .input-text:focus {
//   outline: none;
// }
// .form-input {
//   display: flex;
//   flex-direction: row;
//   border-bottom: 2px solid $primary-color;
//   background-color: white;
//   margin-bottom: 15px;
//   align-items: center;
// }
// .form-input:focus-within {
//   border-bottom: 2px solid $darker-color;
// }
// .fa-icon {
//   color: $primary-color;
// }
// .link-forgot-password {
//   margin-top: -3px;
//   align-self: flex-end;
//   color: $primary-color;
//   text-decoration: none;
//   font-size: 13px;
//   font-weight: 700;
//   margin-bottom: 20px;
// }
// .btn-login {
//   background-color: $primary-color;
//   color: white;
//   border-radius: 10px;
//   border: none;
//   min-height: 35px;
// }
// .btn-login:hover {
//   background-color: $darker-color;
//   cursor: pointer;
// }

// @media only screen and (max-width: 425px) {
//   .login-box {
//     padding: 20px;
//     font-size: 0.75rem;
//     max-width: 350px;
//     position: absolute;
//     left: 56vw;
//     right: auto;
//     margin-left: -175px;
//     background-size: 100% 100%;
//   }
//   .login-form {
//     margin-bottom: 100px;
//     margin-right: auto;
//     margin-left: auto;
//   }
// }
</style>
