import Constant from '@/const';
import axios from 'axios';

const deletePatientRegistration = async id => {
  try {
    const response = await axios.delete(
      Constant.apiUrl.concat(`/patient/registration/delete/${id}`),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default deletePatientRegistration;
