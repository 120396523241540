<template>
  <v-card>
    <v-card-title class="justify-center font-weight-bold"
      >Permintaan Barang Gudang

      <v-btn depressed icon absolute right>
        <v-icon @click="onCloseButtonClick()"> mdi-close-thick </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container class="main-content scrollbar">
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="2" class="text-left body-2 black--text">
                <label for="Unit Asal">Unit Asal</label>
              </v-col>
              <v-col class="text-left body-2 black--text">
                <label for="Permintaan">Tambah Barang lain</label>
              </v-col>
            </v-row>
            <v-row align="baseline" justify="start">
              <v-col cols="2" class="text-left body-2 black--text required">
                <label>{{ unit.name }}</label>
              </v-col>
              <v-form ref="form" @submit.prevent="preventSubmit(e)">
                <v-col>
                  <v-autocomplete
                    :rules="rules.goods"
                    auto-select-first
                    @change="
                      getUnitStock();
                      getWarehouseStock();
                    "
                    dense
                    hide-details="auto"
                    placeholder="Pilih Barang"
                    :items="resource.goods"
                    item-text="displayName"
                    return-object
                    v-model="input.goods"
                    :loading="loading"
                    :search-input.sync="search"
                    clearable
                    append-icon="mdi-chevron-down"
                  />
                  <!-- <label v-else>Gudang</label> -->
                </v-col>
              </v-form>
              <v-col cols="2">
                <v-btn
                  depressed
                  :loading="loadingUnitStock"
                  color="primary"
                  outlined
                  tile
                  @click="add()"
                >
                  Tambah
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4">
                <v-text-field
                  placeholder="Nama Barang"
                  append-icon="mdi-magnify"
                  dense
                  hide-details="auto"
                  v-model="searchTo"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              :headers="addTable.headers"
              :items="addTable.items"
              :items-per-page="addTable.items.length"
              :search="searchTo"
              hide-default-footer
            >
              <template v-slot:[`item.no`]="{ item }">
                <td>{{ addTable.items.indexOf(item) + 1 }}</td>
              </template>
              <template v-slot:[`item.itemsQty`]="{ item, index }">
                <v-form :ref="'form' + index">
                  <td>
                    <v-text-field
                      v-model.number="item.quantity"
                      :rules="[
                        ...rules.amount,
                        v =>
                          v <= item.itemsStockTo || 'Jumlah melebihi sisa stok',
                      ]"
                      type="number"
                      min="0"
                      dense
                    />
                  </td>
                </v-form>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <td>
                  <v-btn icon @click="deleteItem(item)">
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn depressed tile color="primary" @click="addRequest()">
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { scrollToEmptyInput } from '@/helper';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'InputStorageRequest',
  data() {
    return {
      tabs: true,
      id_warehouse: this.$store.getters.userLoggedIn.config.id_unit_gudang,
      input: {
        storage: {
          name: '',
          id_drug: '',
        },
        goods: '',
      },
      resource: {
        goods: [],
      },
      unit: '',
      searchTo: '',
      table: {
        headers: [
          {
            text: 'No',
            value: 'no',
          },
          { text: 'Nama Barang', value: 'itemsName' },
          { text: 'Expired Date', value: 'expiredDate' },
          { text: 'Batch', value: 'batch' },
          { text: 'Sisa di Unit', value: 'itemsStockFrom' },
          { text: 'Koreksi Sisa di Unit', value: 'stockCorrection' },
          { text: 'Kemasan', value: 'package' },
          { text: 'Koreksi', value: 'correction', sortable: false },
        ],
        items: [],
      },
      addTable: {
        headers: [
          {
            text: 'No',

            value: 'no',
          },
          { text: 'Nama Barang', value: 'displayName' },
          { text: 'Sisa Unit', value: 'itemsStockFrom' },
          { text: 'Sisa Tujuan', value: 'itemsStockTo' },
          { text: 'Kemasan', value: 'package' },
          {
            text: 'Jumlah',
            value: 'itemsQty',
            width: '190px',
            class: 'required',
            sortable: false,
          },
          { text: '', value: 'actions', sortable: false },
        ],
        items: [],
      },
      rules: {
        goods: [v => !!v || 'Wajib diisi'],
        amount: [v => !!v || 'Wajib diisi'],
      },
      isShowTable: false,
      isAddTable: false,
      loading: false,
      loadingUnitStock: false,
      search: '',
    };
  },
  mounted() {
    this.getUnitById();
    this.getData();
  },
  watch: {
    search() {
      this.searchData(this);
    },
  },
  methods: {
    preventSubmit() {
      return;
    },
    onCloseButtonClick() {
      if (this.isAddTable) {
        this.isAddTable = false;
      } else {
        this.$emit('close-dialog');
      }
    },
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getData();
    }, 650),
    async getUnitById() {
      const id = this.$store.getters.userLoggedIn.unit_id;
      const res = await axios.get(Constant.apiUrl.concat(`/master/unit/${id}`));

      const { data } = res.data;
      this.unit = {
        id: data._id,
        name: data.name,
      };
    },
    getData() {
      this.loading = true;
      const keyword = this.search;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/drugs/goods/combine?page=1&itemCount=10&search=${keyword ||
              ''}`,
          ),
        )
        .then(response => {
          const data = response.data.data;

          this.resource.goods = data.map(arr => {
            return {
              name: arr.name,
              displayName: arr.display_name,
              strength: arr?.strength,
              strength_unit: arr?.strength_unit,
              packaging: arr?.preparation,
              category: arr?.category,
            };
          });
        })
        .catch(() => {
          this.resource.goods = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getUnitStock() {
      const id = this.$store.getters.userLoggedIn.unit_id;
      this.loadingUnitStock = true;

      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/itemlist?name=${this.input.goods.name}&strength=${
              this.input?.goods.strength
            }&strength_unit=${this.input.goods.strength_unit ||
              ''}&preparation=${this.input.goods.packaging || ''}&unit=${id}`,
          ),
        )
        .then(response => {
          const {
            totalUnitTo,
            totalUnitFrom,
            listWarehouse,
          } = response.data.data;
          this.input.goods.stock_unit = totalUnitTo;
          this.input.goods.stock_to = totalUnitFrom;
          this.input.goods.id_stock = listWarehouse;
        })
        .catch(() => {
          this.input.goods.stock_unit = 0;
        })
        .finally(() => {
          this.loadingUnitStock = false;
        });
    },
    add() {
      if (this.$refs.form.validate()) {
        const isDuplicate = this.addTable.items.some(
          item => item.displayName === this.input.goods.displayName,
        );
        if (isDuplicate) {
          Swal.fire(
            'Barang atau Obat sudah ditambahkan',
            'Silakan pilih barang/obat lain',
            'error',
          );
          return;
        }
        this.addTable.items.push({
          displayName: this.input.goods.displayName,
          name: this.input.goods.name,
          id_stock: this.input.goods.id_stock,
          strength: this.input.goods?.strength,
          strength_unit: this.input.goods?.strength_unit,
          itemsStockFrom: this.input.goods?.stock_unit,
          itemsStockTo: this.input.goods.stock_to,
          package: this.input.goods.packaging,
        });
        this.$refs.form.resetValidation();
        this.input = {
          storage: {
            name: '',
            id_drug: '',
          },
          goods: '',
        };
      }
    },
    async addRequest() {
      let formValidation = [];
      if (this.addTable.items.length === 0) {
        this.$refs.form.validate();
        return;
      }
      for (let i = 0; i < this.addTable.items.length; i++) {
        formValidation.push(this.$refs['form' + i].validate());
      }
      // check if every form is filled by user
      if (await formValidation.every(value => value)) {
        const payload = {
          id_clinic: this.unit.id,
          id_warehouse: this.id_warehouse,
          request: this.addTable.items.map(arr => {
            return {
              name: arr.name,
              display_name: arr.displayName,
              strength: arr?.strength,
              strength_unit: arr?.strength_unit,
              preparation: arr?.package,
              stock: arr.id_stock.map(v => {
                return {
                  id_stock: v,
                };
              }),
              amount: arr.quantity,
            };
          }),
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
            created_at: moment().format(),
          },
        };

        axios
          .post(Constant.apiUrl.concat('/stock/request'), payload)
          .then(() => {
            Swal.fire(
              'Sukses',
              'Permintaan Barang Gudang Berhasil Dipesan',
              'success',
            );
            this.$emit('close-dialog');
          });
      } else {
        scrollToEmptyInput();
      }
    },
    deleteItem(item) {
      const index = this.addTable.items.indexOf(item);
      this.addTable.items.splice(index, 1);
    },
  },
};
</script>

<style>
.main-content {
  height: 24rem;
  padding-right: 2rem;
  overflow-y: auto;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}

.required:after {
  content: ' *';
  color: red;
}
</style>
