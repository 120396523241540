import Constant from '@/const';
import axios from 'axios';

const putChangePassword = async payload => {
  try {
    const response = await axios.put(
      Constant.apiUrl.concat(`/master/staff/reset`),
      payload,
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default putChangePassword;
