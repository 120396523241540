<template>
  <div fluid class="container-main px-15 pt-5">
    <div class="warning-sign" v-if="!isHasIC">
      Pasien belum memiliki daftar Informed Consent, silahkan buat formulir
      tersebut terlebih dahulu
    </div>
    <div v-else></div>
    <div class="container-main__header d-flex justify-space-between">
      <h3>
        Daftar Formulir Penandaan Operasi atau Tindakan Medis
      </h3>
      <div class="d-flex justify-end">
        <v-btn
          style="letter-spacing: 0.01vw;"
          class="text-capitalize"
          color="primary"
          outlined
          @click="handlePrint()"
        >
          <v-icon class="mr-2">mdi-printer</v-icon>
          Formulir Kosong
        </v-btn>
        <v-btn
          style="letter-spacing: 0.01vw;"
          :class="['ml-3', 'text-capitalize', !isHasIC ? 'btn-disabled' : '']"
          depressed
          color="primary"
          @click="handleOpenDialog('add')"
          :disabled="!isHasIC"
        >
          <v-icon class="mr-2">mdi-plus</v-icon>
          Buat Formulir Baru
        </v-btn>
      </div>
    </div>
    <v-skeleton-loader
      v-if="items.operationMarking.isLoading"
      type="table"
    ></v-skeleton-loader>
    <v-data-table
      v-else
      :headers="table.headers"
      :items-per-page="itemsPerRow"
      :items="items.operationMarking.data"
      :loading="items.operationMarking.isLoading"
      class="elevation-0 container-main__data-table scrollbar mt-4"
      single-expand
      hide-default-footer
      disable-pagination
    >
      <template slot="no-data">
        <span>Tidak ada riwayat Penandaan Operasi atau Tindakan Medis</span>
      </template>
      <template v-slot:item="{ item }">
        <tr class="text-left" :key="item.index">
          <td>{{ item.date }}</td>
          <td>{{ item.action }}</td>
          <td>
            <v-menu top :offset-x="true" :close-on-content-click="true">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </span>
              </template>

              <v-list
                v-for="(itemList, index) in actionItems"
                :key="index"
                style="padding: 1px;"
              >
                <v-list-item
                  @click="itemList.action(item.id, item.created_at)"
                  class="container-main__data-table__dropdown"
                >
                  <v-list-item-title>
                    <span class="container-main__data-table__dropdown-item">
                      {{ itemList.label }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" :max-width="1120" persistent>
      <form-operation-marking
        ref="formOperationMarking"
        v-show="dialog"
        :state="stateForm"
        :idOM="idOMs"
      />
    </v-dialog>

    <form-operation-marking
      v-show="false"
      ref="formOperationMarking"
      :state="stateForm"
      :idOM="idOMs"
      :isBackground="true"
    />

    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show && stateForm === 'add'"
      elevation="0"
      :color="snackbar.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="
            snackbar.show = false;
            clearSnackbar();
          "
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import OperationMarking from './OperationMarking';
import FormOperationMarking from './FormOperationMarking.vue';

export default {
  ...OperationMarking,
  name: 'OperationMarkingList',
  components: {
    FormOperationMarking,
  },
  mounted() {
    this.clearSnackbar();
    this.resolveGetInformConsentList().then(item => {
      if (item === undefined) {
        this.isHasIC = false;
      } else {
        this.isHasIC = true;
      }
    });
    this.resolveGetOperationMarking();
  },
};
</script>

<style lang="scss" scoped>
.scrollbar {
  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}

.btn-disabled {
  background-color: #9e9e9e !important;
}
.warning-sign {
  background-color: #fff3f3;
  border: 1px solid #eb4646;
  font-size: 0.8vw;
  border-radius: 5px;
  width: fit-content;
  padding: 0.2vw 0.5vw;
  margin-bottom: 1vw;
}
.container-main {
  &__header {
    width: 100%;
    margin-bottom: 0.3vw;
    color: #4e4d4d;
  }

  &__data-table {
    height: 75vh;
    overflow: auto;
    overflow-x: hidden;
    padding: 0 15px 0 0;
    &__dropdown {
      min-height: 40px;
      border: 1px solid black;
      margin: 6px 12px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
    &__dropdown:hover {
      background-color: #e6f6fe;
      cursor: pointer;
    }
    &__dropdown-item {
      color: #404040;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }
  ::v-deep .v-data-table-header {
    background-color: rgba(194, 194, 194, 0.3);
  }
}
</style>
