import Constant from '@/const';
import axios from 'axios';

const getCheckDrugs = async id => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/master/drugs/checkstock/${id}`),
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getCheckDrugs;
