import Constant from '@/const';
import axios from 'axios';

const getAllDiagnose = async params => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/master/diagnose'),
      { params },
    );
    return response.data;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getAllDiagnose;
