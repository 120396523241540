<template>
  <div>
    <h4 class="title font-weight-bold primary--text text-left mb-4">
      Hasil Penunjang Lainnya
    </h4>
    <v-row>
      <v-col :class="isEmr ? 'grid-display-emr' : 'grid-display'">
        <label>Hasil EKG</label>
        <v-textarea
          :readonly="isEmr"
          no-resize
          outlined
          dense
          placeholder="Masukkan Hasil EKG"
          v-model="form.ekg"
        />
        <label>Hasil Laboratorium</label>
        <v-textarea
          :readonly="isEmr"
          no-resize
          outlined
          dense
          placeholder="Masukkan Hasil Laboratorium"
          v-model="form.laboratory"
        />
      </v-col>
      <v-col :class="isEmr ? 'grid-display-emr' : 'grid-display'">
        <label>Hasil Radiologi</label>
        <v-textarea
          :readonly="isEmr"
          no-resize
          outlined
          dense
          placeholder="Masukkan Hasil Radiologi"
          v-model="form.radiology"
        />
        <label>Catatan Lain</label>
        <v-textarea
          :readonly="isEmr"
          no-resize
          outlined
          dense
          placeholder="Masukkan Catatan Lainnya"
          v-model="form.other"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers(
  'ServiceAssessmentSupportingInvestigation',
);
export default {
  name: 'ServiceAssessmentSupportingInvestigation',
  props: {
    isEmr: Boolean,
  },
  computed: {
    ...mapGetters(['getSupportingInvestigation']),
    form: {
      get() {
        return this.getSupportingInvestigation;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.4rem;
}
$standard-font-size: 0.9vw;

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.grid-display {
  @include grid-display(10vw minmax(16vw, 26vw), true);
  column-gap: 1vw;
}

.grid-display-emr {
  @include grid-display(10vw minmax(16vw, 34vw), true);
  column-gap: 1vw;
}

.required:after {
  content: ' *';
  color: red;
}

h4 {
  color: #1976d2;
}
</style>
