<template>
  <v-container :style="style" fluid>
    <div class="text-start mb-5">
      <h2>{{ title }}</h2>
      <v-btn
        class="ml-3"
        v-if="closeBtn"
        text
        fab
        small
        @click="onClickCloseBtn"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>
    <div class="meta-container">
      <div v-for="(item, index) in data" :key="index" class="column">
        <label>
          {{ item.label }}
        </label>
        <p class="ma-0 pa-0">
          {{ item.text }}
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'HeaderContent',
  props: {
    data: { type: Array },
    title: { type: String },
    closeBtn: { type: Boolean },
    closeForm: { type: Function },
    pl: { type: String },
    pr: { type: String },
    pt: { type: String },
    pb: { type: String },
    ml: { type: String },
    mr: { type: String },
    mt: { type: String },
    mb: { type: String },
  },
  computed: {
    style() {
      return `
        --pl: ${this.pl}; 
        --pr: ${this.pr};
        --pt: ${this.pt};
        --pb: ${this.pb};
        --mb: ${this.mb};
        --mt: ${this.mt};
        --ml: ${this.ml};
        --mr: ${this.mr};
        `;
    },
  },
  methods: {
    onClickCloseBtn() {
      this.closeForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  font-size: 0.9vw;
  max-width: 100%;
  padding-left: var(--pl);
  padding-right: var(--pr);
  padding-top: var(--pt);
  padding-bottom: var(--pb);
  margin-left: var(--ml);
  margin-right: var(--mr);
  margin-top: var(--mt);
  margin-bottom: var(--mb);

  .meta-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 2vw;
    row-gap: 0.3vw;
    justify-content: flex-start;

    label {
      color: #9e9e9e;
    }
    p {
      margin: auto 2rem;
      font-weight: 400;
      color: #404040;
    }
  }

  .column {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    gap: 1vw;
    width: fit-content;
  }
}
</style>
