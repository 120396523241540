import moment from 'moment-timezone';

export default {
  methods: {
    differenceBetweenTwoDates(start, end) {
      const startDate = moment(start).format('YYYY-MM-DD');
      const endDate = moment(end).format('YYYY-MM-DD');
      const date1 = moment(startDate, 'YYYY-MM-DD');
      const date2 = moment(endDate, 'YYYY-MM-DD');
      date1.startOf('day');
      date2.startOf('day');
      const miliseconds1 = date1.valueOf();
      const miliseconds2 = date2.valueOf();
      const differenceInMiliseconds = miliseconds2 - miliseconds1;
      const differenceInDays = moment
        .duration(differenceInMiliseconds)
        .asDays();

      return differenceInDays;
    },
  },
};
