<template>
  <section>
    <h4>INSTRUKSI PPA</h4>
    <v-row>
      <v-col cols="6">
        <label>{{ label }}</label>
        <vue-editor
          v-model="form.ppaInstruction"
          class="pt-4"
          :editorToolbar="customToolbar"
          :disabled="!isEdit"
          :placeholder="isEdit ? label : ''"
        ></vue-editor>
        <span
          v-if="error.ppaInstruction.state && isEdit"
          class="error--text caption"
          >{{ error.ppaInstruction.message }}</span
        >
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers('CPPTStore');

export default {
  name: 'PPAInstruction',
  components: {
    VueEditor,
  },
  data() {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    };
  },
  watch: {
    'form.ppaInstruction'(val) {
      const char = val.replace(/<[^>]*>/g, ''); // regex to remove html tag inside 'val'
      if (char.length <= 3000) {
        this.setErrorVueEditor({
          ppaInstruction: {
            state: false,
            message: '',
          },
        });
      }
      if (char.length > 3000) {
        this.setErrorVueEditor({
          ppaInstruction: {
            state: true,
            message: 'Maksimal 3000 karakter',
          },
        });
      }
    },
  },
  computed: {
    ...mapGetters(['getForm', 'getErrorVueEditor']),
    error: {
      get() {
        return this.getErrorVueEditor;
      },
      set(value) {
        this.setErrorVueEditor(value);
      },
    },
    user: {
      get() {
        return this.getForm.isDetail
          ? this.rolePpa
          : this.$store.getters.userLoggedIn.role;
      },
    },
    rolePpa: {
      get() {
        return this.getForm.generalInformation?.ppa?.role;
      },
    },
    form: {
      get() {
        return this.getForm;
      },
    },
    isEdit: {
      get() {
        return this.getForm.isDetail ? this.getForm.isEdit : true;
      },
    },
    label: {
      get() {
        const isMidwifeOrDoctor = this.user.some(
          item =>
            item.toLowerCase() === 'dokter' || item.toLowerCase() === 'bidan',
        );
        if (isMidwifeOrDoctor) return 'Instruksi Tenaga Medis';
        return 'Instruksi Tenaga Kesehatan';
      },
    },
  },
  methods: {
    ...mapMutations(['setErrorVueEditor']),
  },
};
</script>

<style lang="scss" scoped>
section {
  text-align: start;
  padding-top: 2vw;
  padding-bottom: 3vw;

  label {
    font-weight: 500;
    font-size: 0.8vw;
  }

  .quillWrapper {
    min-height: 10vh;
    max-width: 40vw;
  }

  ::v-deep .ql-editor {
    max-height: 1vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 9px;
      background-color: #ededed;
    }
    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 9px;
      background-color: #9e9e9e;
    }
  }
}

h4 {
  font-weight: 400;
  color: #137bc0;
  margin-bottom: 0.7vw;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
