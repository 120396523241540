<template>
  <v-container fluid>
    <v-col>
      <v-data-table
        :headers="table.headers"
        :items="items"
        :items-per-page="items.length"
        class="datatable"
        hide-default-footer
      >
        <template v-slot:[`item.name`]="{ item }">
          <td class="font-weight-bold text-left">{{ item.name }}</td>
        </template>
        <template v-slot:[`item.unitPrice`]="{ item }">
          <td>Rp {{ formatNumber(item.unitPrice || 0) }}</td>
        </template>
        <template v-slot:[`item.discount`]="{ item }">
          <td>Rp {{ formatNumber(item.discount || 0) }}</td>
        </template>
        <template v-slot:[`item.subtotal`]="{ item }">
          <td>Rp {{ formatNumber(item.subtotal || 0) }}</td>
        </template>
        <template v-slot:[`item.hibah`]="{ item }">
          <td>{{ item.hibah ? 'Ya' : 'Tidak' }}</td>
        </template>
        <template v-slot:[`item.isHold`]="{ item }">
          <v-btn icon v-if="item.isHold" @click="onClickOnHold(item)">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="7">
      <v-card color="#C1C8FF" rounded="lg" flat class="text-left pa-3">
        <v-row no-gutters>
          <v-col cols="5">
            <div style="display: flex; align-items: center">
              <v-icon x-large color="white">mdi-cloud-upload</v-icon>
              <h4 class="pl-5">Upload Bukti Faktur</h4>
            </div>
            <div class="py-3">
              <input
                type="file"
                id="files"
                ref="files"
                accept="image/png, image/jpeg, application/pdf"
                multiple
                v-on:change="handleFilesUpload()"
                v-show="false"
              />
              <div class="d-flex">
                <v-btn
                  depressed
                  class="text-none primary--text mr-4"
                  color="white"
                  width="150"
                  @click="addFiles()"
                  >Pilih File</v-btn
                >
                <v-btn
                  depressed
                  class="text-none white--text"
                  @click="submitFiles()"
                  color="green"
                  width="150"
                  :loading="loadingUpload"
                  >Upload File</v-btn
                >
              </div>
            </div>
            <div class="pb-3">
              <label class="caption">Jenis file : (.pdf), (.jpg), (.png)</label>
              <br />
              <label class="caption red--text">ukuran file max. 2 MB</label>
            </div>
          </v-col>

          <v-col>
            <template v-for="(item, fileIndex) in invoices.failedFiles">
              <v-row
                class="failed-upload py-2"
                no-gutters
                align="center"
                :key="'file-' + fileIndex"
              >
                <v-col cols="1" class="ml-2">
                  <v-icon
                    v-if="item.file.type.search(/.(jpeg|jpg|png)/i) !== -1"
                    class="white--text"
                    >mdi-file-image</v-icon
                  >
                  <v-icon
                    v-else-if="item.file.type.search(/.pdf/i) !== -1"
                    class="white--text"
                    >mdi-file-pdf-box</v-icon
                  >
                  <v-icon v-else class="icon">mdi-file-code</v-icon>
                </v-col>
                <v-col cols="9" class="mx-2">
                  <label class="text-left ma-0 caption">
                    File {{ item.file.name }} gagal diunggah karena
                    <span class="yellow--text">{{ item.message }}</span>
                  </label>
                </v-col>
                <v-col cols="1">
                  <button class="btn-back" @click="removeWarning(fileIndex)">
                    <v-icon color="#fff">mdi-close</v-icon>
                  </button>
                </v-col>
              </v-row>
            </template>
            <template v-for="(item, fileIndex) in invoices.files">
              <v-row
                class="pt-3"
                no-gutters
                align="center"
                :key="'file-' + fileIndex"
              >
                <v-col cols="1">
                  <v-icon v-if="item.type.search(/.(jpeg|jpg|png)/i) !== -1"
                    >mdi-file-image</v-icon
                  >
                  <v-icon v-else-if="item.type.search(/.pdf/i) !== -1"
                    >mdi-file-pdf-box</v-icon
                  >
                  <v-icon v-else class="icon">mdi-file-code</v-icon>
                </v-col>
                <v-col cols="6">
                  <label class="text-left">{{ item.name }}</label>
                  <!-- <v-progress-linear
                        class="upload-bar"
                        :value="progress"
                        v-if="isUploading"
                      ></v-progress-linear> -->
                  <!-- <h4 class="text-md-left" v-if="isUploading">
                        {{ progress }} done
                      </h4> -->
                </v-col>
                <v-col cols="2" class="mr-2">
                  <v-btn
                    small
                    block
                    color="primary"
                    depressed
                    class="text-none"
                    @click="previewFile(item)"
                    >Detail</v-btn
                  >
                </v-col>
                <v-col cols="2">
                  <v-btn
                    small
                    block
                    color="error"
                    depressed
                    class="text-none"
                    @click="removeFiles(fileIndex)"
                    >Cancel</v-btn
                  >
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-dialog v-model="dialog" width="930">
      <v-card v-if="dialog" flat>
        <v-card-title class="justify-center">
          <h3>
            Input Data Penerimaan Barang <br />
            On Hold
          </h3>
        </v-card-title>
        <v-row no-gutters class="pa-5 mx-5">
          <v-col cols="6" class="pr-4">
            <div class="input-data text-left">
              <label class="font-weight-bold body-2">Tanggal Pemesanan</label>
              <label class="body-2">{{ selectedItem.orderDate }}</label>

              <label class="font-weight-bold body-2">Nama Barang</label>
              <label class="body-2">{{ selectedItem.name }}</label>

              <label class="font-weight-bold body-2">Jumlah Pesanan</label>
              <label class="body-2">{{
                `${selectedItem.orderAmount} ${selectedItem.package || ''}`
              }}</label>

              <label class="font-weight-bold body-2">Jumlah Diterima</label>
              <label class="body-2">{{
                `${selectedItem.receiveAmount} ${selectedItem.package || ''}`
              }}</label>

              <label class="font-weight-bold body-2"
                >Jumlah Barang On <br />Hold Diterima</label
              >
              <div style="display: flex">
                <v-text-field
                  type="number"
                  placeholder="Masukkan jumlah"
                  v-model="selectedItem.inputOnHold"
                  dense
                  hide-details="false"
                  class="pr-3"
                ></v-text-field>
              </div>

              <label class="font-weight-bold body-2">Hibah</label>
              <v-radio-group
                v-model="selectedItem.hibah"
                row
                hide-details="false"
              >
                <v-radio label="Ya" :value="true"></v-radio>
                <v-radio label="Tidak" :value="false"></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="input-data text-left">
              <label class="font-weight-bold body-2">Nomor Faktur</label>
              <v-text-field
                v-model="selectedItem.invoiceNumber"
                dense
                placeholder="Masukkan nomor faktur"
                :rules="rules.invoice"
              ></v-text-field>

              <label class="font-weight-bold body-2">Nomor Tanda Terima</label>
              <label class="body-2">{{ selectedItem.receiptNumber }}</label>

              <label class="font-weight-bold body-2">Tanggal Expired</label>
              <v-menu
                dense
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dateTemp"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedItem.expiredDate"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    placeholder="Pilih Tanggal"
                    append-icon="mdi-chevron-down"
                    outlined
                    single-line
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateTemp"
                  no-title
                  scrollable
                  :min="now"
                  @input="
                    menu = false;
                    $refs.menu.save(dateTemp);
                  "
                />
                <!-- $refs.menu.save(selec  tedItem.expiredDate); -->
              </v-menu>

              <label class="font-weight-bold body-2">Nomor Batch Barang</label>
              <v-text-field
                placeholder="Masukkan Nomor Batch"
                dense
                :rules="rules.batch"
                v-model="selectedItem.batch"
              ></v-text-field>

              <label class="font-weight-bold body-2">Package</label>
              <v-autocomplete
                placeholder="Pilih Package"
                v-model="selectedItem.package"
                :items="resource.package"
                append-icon="mdi-chevron-down"
                :loading="packageLoading"
                :search-input.sync="searchPackage"
                dense
                outlined
                single-line
              ></v-autocomplete>

              <label class="font-weight-bold body-2">Selesai</label>
              <v-switch inset v-model="selectedItem.isComplete"></v-switch>
            </div>
          </v-col>
        </v-row>
        <div class="px-5 mx-5">
          <h4 class="mb-3">Riwayat Penerimaan Barang</h4>
          <!-- <v-data-table
            :headers="historyTable.headers"
            :items="historyTable.items"
            :items-per-page="5"
          ></v-data-table> -->
          <div class="history-header mb-2">
            <span class="font-weight-bold text-center">{{
              historyTable.headers[0].text
            }}</span>
            <span class="font-weight-bold text-center">{{
              historyTable.headers[1].text
            }}</span>
            <span class="font-weight-bold text-center">{{
              historyTable.headers[2].text
            }}</span>
            <span class="font-weight-bold text-left">{{
              historyTable.headers[3].text
            }}</span>
          </div>
          <template v-for="(item, index) in selectedItem.history">
            <div class="history-header" :key="index">
              <span class="text-center">{{ `${item.created_at} WIB` }}</span>
              <span class="text-center">{{ item.received_amount }}</span>
              <span class="text-center">{{
                item.package ? item.package : '-'
              }}</span>
              <span class="text-left">{{ item.created_by }}</span>
            </div>
          </template>
        </div>

        <v-card-actions class="card-footer">
          <v-btn text class="text-none" @click="dialog = false">Kembali</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            tile
            depressed
            class="text-none"
            width="150"
            @click="saveData()"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import Constant from '@/const';
import AlertMixin from '@/mixin/alertMixin';

const axios = require('axios');

export default {
  name: 'ReceiveDetail',
  mixins: [AlertMixin],
  props: {
    fileIdentifier: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: null,
    },
    historyData: {
      type: Array,
      default: null,
    },
    allStaff: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      loadingUpload: false,
      menu: false,
      dateTemp: '',
      searchPackage: '',
      packageLoading: false,
      resource: {
        package: [],
      },
      invoices: {
        files: [],
        failedFiles: [],
      },
      table: {
        headers: [
          { text: 'Nama Barang', value: 'name', class: 'white--text' },
          {
            text: 'Jumlah Pesan',
            value: 'orderAmount',
            class: 'white--text text-center',
            width: '5%',
            sortable: false,
          },
          {
            text: 'Jumlah Diterima',
            value: 'receiveAmount',
            class: 'white--text text-center',
            width: '5%',
            sortable: false,
          },
          {
            text: 'Jumlah Barang Kurang',
            value: 'unmatchAmount',
            class: 'white--text text-center',
            width: '5%',
            sortable: false,
          },
          {
            text: 'Alasan Pengembalian',
            value: 'returnReason',
            class: 'white--text',
            sortable: false,
            width: '10%',
          },
          {
            text: 'Tanggal Expired',
            value: 'expiredDate',
            class: 'white--text',
          },
          { text: 'No Batch', value: 'batch', class: 'white--text' },
          {
            text: 'Harga Satuan',
            value: 'unitPrice',
            sortable: false,
            class: 'white--text',
          },
          {
            text: 'Disc',
            value: 'discount',
            sortable: false,
            class: 'white--text',
          },
          {
            text: 'Sub Total',
            value: 'subtotal',
            sortable: false,
            class: 'white--text',
          },
          {
            text: 'Hibah',
            value: 'hibah',
            sortable: false,
            class: 'white--text',
          },
          {
            text: 'On Hold',
            value: 'isHold',
            sortable: false,
            class: 'white--text',
          },
        ],
        items: [],
      },
      dialog: false,
      historyTable: {
        headers: [
          { text: 'Tanggal Penerimaan', value: 'receiveDate' },
          { text: 'Jumlah Barang Diterima', value: 'receiveAmount' },
          { text: 'Satuan', value: 'unit' },
          { text: 'Nama Staff Penerima', value: 'staffName' },
        ],
        items: [],
      },
      rules: {
        batch: [v => !!v || '* Wajib Diisi'],
        invoice: [v => !!v || '* Faktur sudah digunakan'],
      },
      selectedItem: {},
      now: moment(new Date()).format('YYYY-MM-DD'),
    };
  },
  watch: {
    'selectedItem.inputOnHold'(val) {
      if (
        +val + +this.selectedItem.receiveAmount >=
        +this.selectedItem.orderAmount
      ) {
        this.selectedItem.isComplete = true;
      } else {
        this.selectedItem.isComplete = false;
      }
    },
    dateTemp(val) {
      this.selectedItem.expiredDate = moment(val, 'YYYY-MM-DD').format(
        'DD-MM-YYYY',
      );
    },
    searchPackage(val) {
      this.getPackage(val);
    },
  },
  mounted() {
    this.table.items = this.items;
    this.getPackage();
  },
  methods: {
    findStaff(id) {
      const staffName = this.allStaff.filter(staff => staff.id === id);
      return staffName[0].name;
    },
    async submitFiles() {
      if (this.invoices.files.length > 0) {
        this.loadingUpload = true;
        const formData = new FormData();

        for (let i = 0; i < this.invoices.files.length; i++) {
          const file = this.invoices.files[i];

          formData.append(`files`, file);
        }
        formData.append('identifier', this.fileIdentifier);
        formData.append('tag', 'invoicesupplier');
        formData.append('created_by', this.$store.getters.userLoggedIn.id);

        await axios
          .post(Constant.apiUrl.concat('/upload'), formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            if (response) this.showFillSuccess('Berhasil Mengunggah File');
            this.invoices.files = [];
            this.$emit('get-data');
          })
          .catch(error => {
            this.showErrorAxios(error);
          })
          .finally(() => {
            this.loadingUpload = false;
          });
      } else {
        this.showFillWarning('Tidak Ada File yang Diunggah');
      }
    },
    getPackage(keyword) {
      this.packageLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/package/menu/inventory?page=1&itemCount=&search=${keyword ||
              ''}`,
          ),
        )
        .then(res => {
          const { data } = res.data;
          this.resource.package = data.map(unit => {
            return unit.name;
          });
        })
        .catch(() => (this.resource.package = []))
        .finally(() => {
          this.packageLoading = false;
        });
    },
    onClickOnHold(item) {
      this.selectedItem = {
        ...item,
        expiredDate:
          item.expiredDate !== '-'
            ? moment(item.expiredDate, 'DD/MM/YYYY').format('DD-MM-YYYY')
            : '',
        inputOnHold: item.orderAmount - item.receiveAmount,
        isComplete: false,
      };
      this.dialog = true;
    },
    addFiles() {
      this.$refs.files.click();
    },
    handleFilesUpload() {
      const filesUploaded = this.$refs.files.files;
      for (let i = 0; i < filesUploaded.length; i++) {
        if (this.verify(filesUploaded[i]) === '') {
          this.invoices.files.push(filesUploaded[i]);
        } else {
          this.invoices.failedFiles.push({
            file: filesUploaded[i],
            message: this.verify(filesUploaded[i]),
          });
        }
      }

      this.$refs.files.value = '';
    },
    verify(file) {
      const maxSize = 2000000;
      const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
      if (file.size > maxSize) {
        return 'Maksimum Ukuran file 2MB';
      }

      const ext = file.name.split('.');

      if (!allowedTypes.includes(file.type)) {
        return `Tipe file .${ext[1]} tidak diperbolehkan`;
      }

      return '';
    },
    previewFile(file) {
      const url = URL.createObjectURL(file);
      window.open(url);
    },
    removeFiles(fileIndex) {
      this.invoices.files.splice(fileIndex, 1);
    },
    removeWarning(fileIndex) {
      this.invoices.failedFiles.splice(fileIndex, 1);
    },
    async saveData() {
      try {
        const response = await axios.put(
          Constant.apiUrl
            .concat('/supply/itemlist/')
            .concat(this.selectedItem.id),
          {
            amount: {
              ordered_amount: this.selectedItem.orderAmount,
              unfit_amount: this.selectedItem.unmatchAmount,
            },
            received_amount: this.selectedItem.inputOnHold,
            isComplete: this.selectedItem.isComplete,
            isHibah: this.selectedItem.hibah,
            invoice_number: this.selectedItem.invoiceNumber,
            expiry_date:
              this.selectedItem.expiredDate !== ''
                ? moment(this.selectedItem.expiredDate, 'DD-MM-YYYY').format(
                    'YYYY-MM-DD',
                  )
                : null,
            batch_number: this.selectedItem.batch,
            package: this.selectedItem.package,
            created_by: this.$store.getters.userLoggedIn.id,
          },
        );
        if (response) {
          Swal.fire('Data Penerimaan Barang Berhasil Tersimpan', '', 'success');
          this.$emit('get-data');
          this.dialog = false;
        }
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
  },
};
</script>

<style lang="scss" scoped>
.card-footer {
  position: sticky;
  background-color: white;
  bottom: 0;
}
.failed-upload {
  background-color: #d00;
  color: white;
  border-radius: 5px;
}

.history-header {
  width: 100%;
  display: grid;
  grid-template-columns: 30% 20% 20% 30%;
  column-gap: 10px;
  font-size: 0.8rem;
}

.input-data {
  display: grid;
  grid-template-columns: 25vh 30vh;
  grid-template-rows: repeat(6, 8vh);
  align-items: baseline;
}
.datatable {
  ::v-deep .v-data-table__wrapper {
    border-radius: 20px;
  }
  ::v-deep .v-data-table-header {
    background-color: #3498db;
  }
  ::v-deep tbody {
    background-color: #f0f9ff;
  }
}
</style>
