<template>
  <v-row class="mb-6">
    <v-col>
      <div class="grid-display">
        <label>Bantuan Hidup Dasar</label>
        <v-combobox
          v-model="basicLifeSupport"
          :items="resource.basicLifeSupport"
          placeholder="Bantuan Hidup Dasar"
          append-icon="mdi-chevron-down"
          multiple
          :readonly="isEmr"
        >
          <template v-slot:prepend-item>
            <p class="grey--text ma-4 text-start body-1">
              Pilih atau Ketik Untuk Pilihan Lainnya
            </p>
          </template>
        </v-combobox>
        <label>Tindakan Invasif</label>
        <v-combobox
          :readonly="isEmr"
          v-model="invasiveAction"
          :items="resource.invasiveAction"
          placeholder="Tindakan Invasif"
          append-icon="mdi-chevron-down"
          multiple
        >
          <template v-slot:prepend-item>
            <p class="grey--text ma-4 text-start body-1">
              Pilih atau Ketik Untuk Pilihan Lainnya
            </p>
          </template>
        </v-combobox>
      </div>
    </v-col>
    <v-col>
      <div class="grid-display">
        <label>Tindakan Bidai</label>
        <v-combobox
          :readonly="isEmr"
          v-model="splintAction"
          :items="resource.splintAction"
          placeholder="Tindakan Bidai"
          append-icon="mdi-chevron-down"
          multiple
        >
          <template v-slot:prepend-item>
            <p class="grey--text ma-4 text-start body-1">
              Pilih atau Ketik Untuk Pilihan Lainnya
            </p>
          </template>
        </v-combobox>
        <label>Penunjang</label>
        <v-select
          v-model="support"
          :readonly="isEmr"
          :items="resource.support"
          placeholder="Penunjang"
          append-icon="mdi-chevron-down"
          multiple
        >
        </v-select>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers('EmergencyAction');
const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);

export default {
  name: 'EmergencyAction',
  data() {
    return {
      resource: {
        basicLifeSupport: ['RJP', 'Gudel', 'Intubasi', 'Trachestomy'],
        invasiveAction: [
          'IV Cath',
          'CVC',
          'Kateterisasi',
          'Heacting',
          'Suction',
        ],
        splintAction: ['Sling', 'Bidai', 'Gibs', 'Skin Traksi'],
        support: ['EKG', 'Rontgen', 'CT Scan', 'USG', 'GDS'],
      },
    };
  },
  computed: {
    ...mapIGDAssessmentGetters(['getIsEmr']),
    ...mapGetters([
      'getBasicLifeSupport',
      'getInvasiveAction',
      'getSplintAction',
      'getSupport',
    ]),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    basicLifeSupport: {
      get() {
        return this.getBasicLifeSupport;
      },
      set(value) {
        this.setBasicLifeSupport(value);
      },
    },
    splintAction: {
      get() {
        return this.getSplintAction;
      },
      set(value) {
        this.setSplintAction(value);
      },
    },
    invasiveAction: {
      get() {
        return this.getInvasiveAction;
      },
      set(value) {
        this.setInvasiveAction(value);
      },
    },
    support: {
      get() {
        return this.getSupport;
      },
      set(value) {
        this.setSupport(value);
      },
    },
  },
  methods: {
    ...mapMutations([
      'clearEmergencyActionState',
      'setBasicLifeSupport',
      'setInvasiveAction',
      'setSplintAction',
      'setSupport',
    ]),
  },
  beforeDestroy() {
    this.clearEmergencyActionState();
  },
};
</script>

<style lang="scss" scoped>
$standard-font-size: 0.9vw;

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.grid-display {
  @include grid-display(10vw minmax(16vw, 26vw), true);
  column-gap: 1vw;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
