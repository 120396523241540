<template>
  <v-card>
    <v-card-title class="justify-center font-weight-bold">
      Permintaan Barang Gudang
      <v-btn absolute right icon @click="$emit('close-dialog')">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <div class="text-left content-item">
      <label class="sub-title-font blue-text">Detail Transaksi</label>
      <v-row no-gutters>
        <v-col cols="12" class="">
          <v-card
            color="#F4F5F5"
            height="64"
            width="100%"
            flat
            class="detail-trans pa-3"
          >
            <div class="fill-detail mr-3">
              <label class="detail-text-field">Kode</label>
              <label class="body-2 font-weight-bold">{{
                selectedItem.code
              }}</label>
            </div>
            <div class="fill-detail mr-3">
              <label class="detail-text-field">Tanggal</label>
              <label class="body-2 font-weight-bold">{{
                selectedItem.created_at
              }}</label>
            </div>
            <div class="fill-detail mr-3">
              <label class="detail-text-field">Mutasi Dari</label>
              <label class="body-2 font-weight-bold">{{
                selectedItem.to.name
              }}</label>
            </div>
            <div style="display: grid">
              <label class="detail-text-field">Dikirim ke</label>
              <label class="body-2 font-weight-bold">{{
                selectedItem.from.name
              }}</label>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="mt-5 mb-2 d-flex justify-space-between align-center"
      >
        <label class="sub-title-font blue-text">Detail Permintaan</label>
        <v-btn
          outlined
          color="primary"
          depressed
          class="text-none font-weight-bold"
          v-if="isWarehouse && isRequsted"
          @click="sheet = true"
        >
          <v-icon>
            mdi-plus
          </v-icon>
          Tambah Baru</v-btn
        >
      </v-row>
      <!-- v-if="!isWarehouse" -->
      <v-data-iterator
        v-if="!isWarehouse || itemStatus !== 'requested'"
        :loading="isLoading"
        :items="requestedItems"
        hide-default-footer
      >
        <template v-slot:header>
          <v-row no-gutters align="center" class="request-detail">
            <span class="header-text text-center">No</span>
            <span class="header-text text-left">Nama Barang</span>
            <span class="header-text text-left">Jenis Sediaan</span>
            <span class="header-text text-left">Jumlah Permintaan</span>
            <span class="header-text text-left">Jumlah Diberikan</span>
            <span class="header-text text-left"
              >Stok Akhir {{ isWarehouse ? 'Gudang' : 'Unit' }}</span
            >
          </v-row>
        </template>
        <template v-slot:default="{ items }">
          <v-card
            v-for="(item, idx) in items"
            width="95vw"
            class="d-flex align-center"
            flat
            tile
            :key="'drug-' + idx"
          >
            <v-expansion-panels v-model="item.expand" flat>
              <v-expansion-panel>
                <v-expansion-panel-header
                  expand-icon=""
                  class="request-detail items pa-0"
                >
                  <label class="body-2 text-center">{{ idx + 1 }}</label>
                  <v-icon v-if="!isWarehouse && isRequsted"></v-icon>
                  <v-icon v-else-if="item.expand === 0"
                    >mdi-chevron-down</v-icon
                  >
                  <v-icon v-else>mdi-chevron-right</v-icon>
                  <label class="body-2">{{ item.displayName }}</label>
                  <label class="body-2">{{ item.preparation || '-' }}</label>
                  <label class="body-2">{{ item.req_quantity }}</label>
                  <label class="body-2">{{ item.amount_given }}</label>
                  <label class="body-2">{{
                    isWarehouse ? item.warehouseRemain : item.unit_stock
                  }}</label>
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content>
                  <v-row
                    v-for="(list, index) in item.fulfillment"
                    :key="'detail' + '-' + index"
                    no-gutters
                    align="center"
                    class="request-detail list"
                  >
                    <div></div>
                    <label class="body-2"
                      >{{
                        `${
                          list.id_drugs
                            ? list.id_drugs.detail[0].factory
                            : list.id_goods.detail[0].factory || ''
                        } ${formatDate(list.expiry_date)}`
                      }}
                      <span class="font-weight-regular">{{
                        `| ${list.batch_number}`
                      }}</span></label
                    >
                    <label class=" body-2">{{
                      list?.id_drugs?.detail[0]?.preparation || ''
                    }}</label>
                    <label class="body-2"></label>
                    <label class="body-2">{{ list.amount }}</label>
                    <label class="body-2">{{
                      isWarehouse
                        ? list.totalWarehouseQuantity
                        : list.totalUnitQuantity
                    }}</label>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel></v-expansion-panels
            >
          </v-card>
        </template>
      </v-data-iterator>
      <v-data-iterator
        :loading="isLoading"
        :items="requestedItems"
        hide-default-footer
        v-else
      >
        <template v-slot:header>
          <v-row
            no-gutters
            align="center"
            class="request-detail-warehouse header"
          >
            <label class="header-text grey-text text-center">No</label>
            <label class="header-text grey-text pl-4">Nama Barang</label>
            <label class="header-text grey-text">Sisa Unit</label>
            <label class="header-text grey-text">Stok Gudang</label>
            <label class="header-text grey-text">Jumlah Permintaan</label>
            <label class="header-text grey-text">Jumlah Diberikan</label>
            <label class="header-text grey-text">Satuan</label>
            <label class="header-text grey-text">Beri Barang</label>
          </v-row>
        </template>
        <template v-slot:default="{ items }">
          <v-card
            v-for="(drug, idx) in items"
            width="95vw"
            class="d-flex align-center"
            flat
            tile
            :key="'drug-' + idx"
          >
            <v-expansion-panels v-model="drug.expand" flat>
              <v-expansion-panel>
                <v-expansion-panel-header
                  expand-icon=""
                  class="request-detail-warehouse pa-0"
                >
                  <label class="body-2 text-center">{{
                    requestedItems.indexOf(drug) + 1
                  }}</label>
                  <v-icon v-if="drug.expand === 0">mdi-chevron-down</v-icon>
                  <v-icon v-else>mdi-chevron-right</v-icon>
                  <label class="body-2 ">{{ drug.displayName }}</label>
                  <label class="body-2 ">{{ drug.unitRemain || 0 }}</label>
                  <label class="body-2  text-left">{{
                    drug.warehouseRemain
                  }}</label>
                  <label class="body-2 ">{{ drug.req_quantity }}</label>
                  <label class="body-2  text-left">{{
                    itemGiven(drug, idx) + drug.amount_given
                  }}</label>
                  <label class="body-2 ">-</label>
                  <v-switch
                    @click.stop=""
                    hide-details="false"
                    inset
                    class="mt-0 pt-0"
                    v-model="items[idx].status"
                    :disabled="items[idx].noStock"
                    @change="unCheckAllItem(idx)"
                  ></v-switch>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row
                    v-for="(item, index) in drug.listStock"
                    :key="'detail' + '-' + index"
                    no-gutters
                    align="center"
                    class="request-detail-warehouse list"
                  >
                    <div></div>
                    <v-checkbox
                      dense
                      hide-details="false"
                      class="mt-0 pt-0"
                      v-model="item.check"
                      @change="autoFill(idx, index)"
                    ></v-checkbox>
                    <label
                      class="body-2 text-uppercase"
                      :class="{
                        'black--text font-weight-bold': item.isMenu,
                        'black--text font-weight-medium': item.isSubMenu,
                        'primary--text font-weight-bold':
                          !item.isMenu && !item.isSubMenu,
                      }"
                      >{{ `${item.factory} lala` }}
                      {{
                        `${formatDate(item.expiry_date)} | ${item.batch_number}`
                      }}
                    </label>
                    <label
                      class="body-2 text-uppercase"
                      :class="{
                        'black--text font-weight-bold': item.isMenu,
                        'grey--text font-weight-medium': item.isSubMenu,
                        'primary--text font-weight-bold':
                          !item.isMenu && !item.isSubMenu,
                      }"
                    ></label>
                    <label
                      class="body-2 text-left"
                      :class="{
                        'font-weight-bold': item.isMenu,
                      }"
                      >{{ item.quantity }}</label
                    >
                    <span></span>
                    <label
                      v-if="item.isMenu"
                      class="body-2"
                      :class="{ 'font-weight-bold': item.isMenu }"
                      >{{ item.amountGiven }}</label
                    >
                    <v-text-field
                      v-else-if="item.isSubMenu"
                      class="given-input"
                      v-model.number="item.amountGiven"
                      :disabled="!item.check || item.quantity < 0"
                      hide-details="true"
                      single-line
                      type="number"
                      @change="amountGivenValidation(idx, index)"
                    ></v-text-field>
                    <label
                      class="body-2 text-capitalize"
                      :class="{
                        'black--text font-weight-bold': item.isMenu,
                        'grey--text font-weight-medium': item.isSubMenu,
                        'primary--text font-weight-bold':
                          !item.isMenu && !item.isSubMenu,
                      }"
                    >
                      {{ item.package }}
                    </label>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </template>
      </v-data-iterator>
      <v-bottom-sheet v-model="sheet">
        <v-sheet class="px-5 pt-5">
          <h3 class="primary--text text-left font-weight-bold pb-3">
            Tambah Barang Baru
          </h3>
          <v-row no-gutters>
            <v-col cols="3" class="text-left">
              <label class="font-weight-bold body-2"
                >Nama Barang<span class="red--text">*</span></label
              >
              <v-autocomplete
                dense
                outlined
                placeholder="Pilih Barang"
                :items="resource.stocks"
                @change="
                  getExpStock();
                  getUnitStock();
                "
                item-text="displayName"
                v-model="input.stock"
                return-object
                :loading="addStockLoading"
                :search-input.sync="search"
              ></v-autocomplete>

              <label class="font-weight-bold body-2"
                >Tanggal ED<span class="red--text">*</span></label
              >
              <v-autocomplete
                :items="resource.items"
                :loading="expStockLoading"
                item-text="exp_date_display"
                return-object
                outlined
                dense
                v-model="input.expiredDate"
              ></v-autocomplete>
              <v-row no-gutters>
                <v-col cols="7">
                  <label class="font-weight-bold body-2"
                    >Jumlah Diberikan<span class="red--text">*</span></label
                  >
                  <v-text-field
                    outlined
                    placeholder="Masukkan jumlah barang"
                    dense
                    v-model="input.givenAmount"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-3">
                  <label class="font-weight-bold body-2">Sisa Stok</label>
                  <br />
                  <label class="body-2">{{ input.expiredDate.quantity }}</label>
                </v-col>
              </v-row>

              <v-row no-gutters justify="end">
                <v-btn outlined class="text-none" tile @click="addStuff()"
                  >Tambah</v-btn
                >
              </v-row>
            </v-col>
            <v-col class="px-5">
              <v-data-table
                :headers="addTable.headers"
                :items="addTable.items"
                :items-per-page="5"
              >
                <template v-slot:[`item.no`]="{ item }">
                  <td>{{ addTable.items.indexOf(item) + 1 }}</td>
                </template>
                <template v-slot:[`item.delete`]="{ item }">
                  <v-btn icon @click="deleteItem(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-footer color="white">
            <v-btn
              width="150"
              tile
              class="pa-4"
              depressed
              color="primary"
              @click="addToDetail()"
            >
              Simpan
            </v-btn>
          </v-footer>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <v-divider></v-divider>
    <v-card-actions class="card-action">
      <v-btn
        text
        class="text-none"
        color="primary"
        @click="$emit('close-dialog')"
        v-if="
          (isWarehouse && itemStatus === 'requested') ||
            (!isWarehouse && itemStatus !== 'requested')
        "
        >Kembali</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        depressed
        class="text-none"
        @click="addDetail()"
        v-if="isWarehouse && itemStatus === 'requested'"
        :disabled="itemStatus === 'received'"
      >
        Kirim
      </v-btn>
      <v-btn
        color="primary"
        depressed
        class="text-none"
        v-else-if="
          (isWarehouse && itemStatus !== 'requested') ||
            (!isWarehouse && itemStatus === 'requested')
        "
        @click="$emit('close-dialog')"
      >
        Kembali
      </v-btn>
      <v-btn
        color="primary"
        depressed
        class="text-none"
        v-else-if="!isWarehouse && itemStatus !== 'requested'"
        :disabled="itemStatus === 'received'"
        @click="addDetail()"
      >
        Terima
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="dialog" width="1000">
      <DialogDetail
        v-if="dialog"
        @close-dialog="dialog = false"
        @close-confirm="$emit('close-dialog')"
        :data-request="requestFinal"
        :selected-item="selectedItem"
      />
    </v-dialog>
    <Snackbar
      :show="snackbar.show"
      :color="snackbar.color"
      :text="snackbar.text"
      :close="
        () => {
          snackbar.show = false;
        }
      "
    />
  </v-card>
</template>

<script>
import Constant from '@/const';
import DialogDetail from './DialogDetail.vue';
import { Snackbar } from '@/components/SharedComponent';

const _ = require('lodash');
const axios = require('axios');
const moment = require('moment-timezone');

export default {
  name: 'DetailStorageRequest',
  props: {
    selectedItem: {
      type: Object,
    },
  },
  components: {
    DialogDetail,
    Snackbar,
  },
  data() {
    return {
      table: {
        items: [],
      },
      itemStatus: '',
      search: null,
      id_unit: '',
      id_warehouse: '',
      sheet: false,
      date: null,
      menu: false,
      addTable: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Nama Barang', value: 'displayName' },
          { text: 'Batch', value: 'batch_number' },
          { text: 'Kadaluarsa', value: 'expiry_date' },
          { text: 'Jumlah Diberikan', value: 'givenAmount' },
          { text: '', value: 'edit', sortable: false, width: '5%' },
          { text: '', value: 'delete', sortable: false, width: '5%' },
        ],
        items: [],
      },
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      resource: {
        stocks: [],
        items: [],
      },
      input: {
        stock: '',
        expiredDate: '',
        givenAmount: null,
        stock_unit: 0,
      },
      isLoading: false,
      addStockLoading: false,
      expStockLoading: false,
      dialog: false,
      selection: [],
      requestedItems: [],
      requestFinal: [],
    };
  },
  mounted() {
    this.getRequested();
    this.itemStatus = this.selectedItem.status;
  },
  watch: {
    search() {
      this.searchData(this);
    },
  },
  computed: {
    isWarehouse() {
      return this.$store.getters.userLoggedIn.is_warehouse;
    },
    addDisabled() {
      return this.input.stock === '' || this.input.givenAmount === null;
    },
    isRequsted() {
      return this.selectedItem.status === 'requested';
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getWarehouseStock();
    }, 650),
    unCheckAllItem(idx) {
      if (this.requestedItems[idx].status) return;
      for (let i of this.requestedItems[idx].listStock) {
        i.check = false;
      }
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    autoFill(idx, index) {
      // this.selectedItem.status = 'accepted';
      this.requestedItems[idx].status = true;

      let item = this.requestedItems[idx];
      let selectedListItem = this.requestedItems[idx].listStock[index];

      if (!selectedListItem.check) {
        selectedListItem.amountGiven = 0;
        return;
      }

      // NEED ENHANCE LATER FOR CONDITIONING
      if (item.quantity === 0) {
        if (item.req_quantity >= selectedListItem.quantity)
          selectedListItem.amountGiven = selectedListItem.quantity;
        else selectedListItem.amountGiven = item.req_quantity;
      } else if (item.quantity > 0 && item.req_quantity > item.quantity) {
        if (item.quantity >= selectedListItem.quantity) {
          selectedListItem.amountGiven = selectedListItem.quantity;
        } else {
          selectedListItem.amountGiven = item.req_quantity - item.quantity;
          if (selectedListItem.amountGiven > selectedListItem.quantity)
            selectedListItem.amountGiven = selectedListItem.quantity;
        }
      } else {
        selectedListItem.amountGiven = 0;
      }
    },
    amountGivenValidation(idx, index) {
      let selectedListItem = this.requestedItems[idx].listStock[index];

      if (selectedListItem.amountGiven > selectedListItem.quantity)
        selectedListItem.amountGiven = selectedListItem.quantity;
    },
    itemGiven(arr, index) {
      const result = arr.listStock
        .filter(item => item.check)
        .reduce((a, b) => +a + +b.amountGiven, 0);
      this.requestedItems[index].quantity = result;
      return result;
    },
    addStuff() {
      this.addTable.items.push({
        name: this.input.stock.name,
        id_stock: this.input.expiredDate.id_stock,
        id_drugs: this.input.expiredDate?.id_drugs,
        id_goods: this.input.expiredDate?.id_goods,
        displayName: this.input.stock.displayName,
        batch_number: this.input.expiredDate.batch_number,
        expiry_date: this.input.expiredDate.exp_date,
        givenAmount: this.input.givenAmount,
        strength_unit: this.input.stock.strength_unit,
        preparation: this.input.stock.preparation,
        warehouse_stock: this.input.expiredDate.quantity,
        stock_unit: this.input.stock_unit,
        factory: this.input.expiredDate.factory_stock,
      });

      this.input = {
        stock: '',
        expiredDate: '',
        givenAmount: null,
        stock_unit: 0,
      };
    },
    deleteItem(val) {
      const index = this.addTable.items.indexOf(val);
      this.addTable.items.splice(index, 1);
    },
    validation() {
      if (this.requestedItems.some(v => !v.status)) {
        this.snackbar = {
          show: true,
          color: 'error',
          text: 'Berikan barang untuk melanjutkan permintaan',
        };
        return false;
      } else {
        for (const req of this.requestedItems) {
          if (req.listStock.some(v => v.check)) {
            return true;
          } else {
            this.snackbar = {
              show: true,
              color: 'error',
              text: 'Checklist data pabrikan untuk melanjutkan permintaan',
            };
            return false;
          }
        }
      }
      return true;
    },
    addDetail() {
      switch (this.selectedItem.status) {
        case 'requested':
          if (!this.validation()) return;
          this.requestFinal = this.requestedItems
            .filter(x => x.status)
            .map((item, idx) => {
              const factory = [];
              const stock = item.listStock.filter(x => x.check);
              stock.forEach(item => {
                factory.push(item.factory);
              });
              return {
                ...item,
                no: idx + 1,
                strength_unit: item?.strength_unit || '-',
                listStock: item.listStock,
                factory: factory.join(', '),
                given_quantity: item.quantity + item.amount_given,
                quantity: item.req_quantity,
              };
            });
          this.dialog = true;
          break;
        case 'accepted':
          this.requestFinal = this.requestedItems.map((item, idx) => {
            const factory = [];
            item.listStock.map(item => {
              factory.push(item.factory);
            });
            return {
              ...item,
              no: idx + 1,
              strength_unit: item?.strength_unit || '-',
              factory: factory.join(', '),
              given_quantity: item.amount_given,
              quantity: item.req_quantity,
            };
          });
          this.dialog = true;
          break;
      }
    },
    getRequested() {
      this.isLoading = true;
      axios
        .get(Constant.apiUrl.concat(`/stock/request/${this.selectedItem.id}`))
        .then(response => {
          const { data } = response.data;
          this.id_warehouse = data.id_warehouse._id;
          this.id_unit = data.id_clinic._id;

          this.detailRequest = {
            from: data.id_warehouse.name,
            to: data.id_clinic.name,
            status: data.status,
          };

          this.requestedItems = data.request.map(arr => {
            const factory_stock = [];
            return {
              _id: arr._id,
              displayName: arr.display_name,
              name: arr.name,
              strength: arr.strength,
              strength_unit: arr?.strength_unit || '-',
              preparation: arr?.preparation || '-',
              amount_given: arr.amount_given || 0,
              fulfillment: arr.fulfillment,
              req_quantity: arr.amount,
              unitRemain: arr.unit_stock,
              warehouseRemain: arr.warehouse_remain,
              unit_stock: arr.unit_stock,
              noStock: arr.stock.length === 0 ? true : false,
              listStock: arr.stock
                .filter(
                  v => v.id_drugs?.detail[0].preparation === arr.preparation,
                )
                .map(item => {
                  const factory = item.id_drugs
                    ? item.id_drugs.detail[0].factory
                    : item.id_goods.detail[0].factory;
                  factory_stock.push(factory);
                  return {
                    factory: factory,
                    preparation: item.id_drugs?.detail[0].preparation,
                    strength_unit: item?.strength_unit || '-',
                    package: item?.package || '-',
                    id_drugs: item.id_drugs?._id,
                    id_goods: item.id_goods?._id,
                    id_stock: item._id,
                    expiry_date: item.expiry_date,
                    batch_number: item.batch_number,
                    quantity: item.quantity,
                    amountGiven: 0,
                    isSubMenu: true,
                    check: this.isRequsted ? false : true,
                  };
                }),
              isMenu: true,
              expand: 0,
              status: true,
              factory_stock: factory_stock.join(', '),
            };
          });
        })
        .catch(() => {
          this.requestedItems = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addToDetail() {
      const data = this.addTable.items.map(arr => {
        return {
          displayName: arr.displayName,
          name: arr.name,
          strength: arr?.strength,
          strength_unit: arr?.strength_unit,
          preparation: arr?.preparation,
          amount_given: 0,
          req_quantity: +arr.givenAmount,
          unitRemain: +arr.stock_unit,
          warehouseRemain: +arr.warehouse_stock,
          status: true,
          noStock: false,
          expand: 0,
          isMenu: true,
          factory_stock: arr.factory,
          listStock: [
            {
              factory: arr.factory,
              preparation: arr.preparation,
              strength_unit: arr?.strength_unit,
              id_drugs: arr?.id_drugs,
              id_goods: arr?.id_goods,
              id_stock: arr.id_stock,
              expiry_date: arr.expiry_date,
              batch_number: arr.batch_number,
              quantity: arr.quantity,
              amountGiven: +arr.givenAmount,
              isSubMenu: true,
              check: true,
            },
          ],
        };
      });
      data.forEach(x => {
        this.requestedItems.push(x);
      });
      this.addTable.items = [];
      this.selectedItem.status = 'accepted';
      this.sheet = false;
    },
    getWarehouseStock() {
      const keyword = this.search;
      this.addStockLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all?page=1&itemCount=10&search=${keyword ||
              ''}&strength=&unit=&unit_name=${this.id_warehouse}`,
          ),
        )
        .then(response => {
          const { stock } = response.data.data;

          this.resource.stocks = stock.map(arr => {
            return {
              name:
                arr.drug.length > 0
                  ? arr.drug[0].detail.name
                  : arr.good[0].detail.name,
              displayName: arr.display_name,
              strength: arr?.strength,
              strength_unit: arr?.strength_unit,
              preparation: arr?.preparation,
              category: arr?.category,
              factory: arr.factory,
            };
          });
        })
        .catch(() => {
          this.resource.stocks = [];
        })
        .finally(() => {
          this.addStockLoading = false;
        });
    },
    getExpStock() {
      this.expStockLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all?search=${this.input.stock.name}&strength=${this.input
              .stock.strength || ''}&unit=${this.input.stock.strength_unit ||
              ''}&unit_name=${this.id_warehouse}&factory=${this.input.stock
              .factory || ''}`,
          ),
        )
        .then(response => {
          const { stock } = response.data.data;
          this.resource.items = stock
            .filter(v => v.quantity !== 0)
            .map(v => {
              return {
                id_stock: _.get(v, '_id', ''),
                exp_date: _.get(v, 'expiry_date', ''),
                exp_date_display: moment(_.get(v, 'expiry_date', ''))
                  .locale('id')
                  .format('LL'),
                quantity: _.get(v, 'quantity', 0),
                batch_number: _.get(v, 'batch_number', ''),
                id_drugs: v?.id_drugs,
                id_goods: v?.id_goods,
                factory_stock:
                  v.drug.length != 0
                    ? v.drug[0].detail.factory
                    : v.good[0].detail.factory,
              };
            });
        })
        .catch(() => {
          this.resource.items = [];
        })
        .finally(() => {
          this.expStockLoading = false;
        });
    },
    getUnitStock() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all?search=${this.input.stock.name}&strength=${this.input
              .stock.strength || ''}&unit=${this.input.stock.strength_unit ||
              ''}&unit_name=${this.id_unit}&factory=${this.input.stock
              .factory || ''}`,
          ),
        )
        .then(response => {
          this.addStockLoading = true;
          const { stock } = response.data.data;
          if (stock || stock.length > 0) {
            const stok = stock.map(arr => arr.quantity).reduce((a, b) => a + b);
            this.input = {
              ...this.input,
              stock_unit: stok,
            };
          }
          this.input.stock_unit = 0;
          this.addStockLoading = false;
        })
        .catch(() => {
          this.addStockLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.blue-text {
  color: #137bc0;
}
.grey-text {
  color: #757575;
}
.sub-title-font {
  font-size: 110%;
  font-weight: 500;
}
.content-item {
  margin-top: 40px;
  padding: 0px 10% 0px 10%;
  overflow-x: hidden;
  overflow-y: auto;
  height: 82vh;
  &::-webkit-scrollbar {
    margin-right: 10vh;
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
  // overflow: auto;
}
.detail-text-field {
  font-size: 14px;
  color: #757575;
}
.header-text {
  font-size: 14px;
  font-weight: 500;
}
.given-input {
  padding: 0;
  min-width: 0px;
  width: 2.5vw;
}

.card-action {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 1px;
}
.detail-trans {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  border: 1px solid #9ca2a5 !important;
  border-radius: 5px;
  .fill-detail {
    display: grid;
    border-right: 0.5px solid #e0e0e0 !important;
  }
}
.request-detail {
  display: grid;
  grid-template-columns: 3% 47% repeat(4, 12.5%);
  width: 80vw;
  height: 7vh;
  align-content: center;
  border-radius: 4px;
  border-bottom: 1.5px solid #9ca2a5;
  background-color: #f4f5f5;

  &.items {
    grid-template-columns: 3% 2% 45% repeat(4, 12.5%);
    height: 2vh;
    border-bottom: 0px solid #9ca2a5;
    background-color: white;
  }

  &.list {
    grid-template-columns: 5% 45% repeat(4, 12.5%);
    height: 6.5vh;
    border-bottom: 0px solid #9ca2a5;
    background-color: #f0f9ff;
  }
}
.request-detail-warehouse {
  width: 80vw;
  display: grid;
  grid-template-columns: 2.5% 2.5% 37.5% 10% 8% 10% 10% 10% 5% 5%;
  height: 7vh;
  border-radius: 4px;
  align-content: center;

  &.header {
    grid-template-columns: 2.5% 40% 10% 8% 10% 10% 10% 10% 5%;
    border-bottom: 1.5px solid #9ca2a5;
    background-color: #f4f5f5;
  }
  &.list {
    grid-template-columns: 5% 2% 35.5% 10% 8% 10% 10% 10% 5% 5%;
    background-color: #f0f9ff;
    height: 6.5vh;
  }
}
.tree-grid {
  display: grid;
  grid-template-columns: 3% 27% 10% 22% 10% 15% 10%;
  align-items: center;
  grid-template-rows: 35px;
  border-radius: 5px;
  // background: #f4f5f5;
  &.batch {
    // background: #f0f9ff;
    grid-template-columns: 3% 13% 18% 10% 15.39% 5% 15% 10%;
  }
  .input-text {
    width: 50px;
    font-size: 0.9rem;
  }
}
.drug-type {
  background-color: #f4f5f5;
  height: 5vh;
  align-content: center;
}
::v-deep .v-treeview-node__root {
  background-color: #deeef9;
  height: 40px;
}
</style>
