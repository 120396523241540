import { render, staticRenderFns } from "./IncomeReport.vue?vue&type=template&id=3044fb39&scoped=true"
import script from "./IncomeReport.vue?vue&type=script&lang=js"
export * from "./IncomeReport.vue?vue&type=script&lang=js"
import style0 from "./IncomeReport.vue?vue&type=style&index=0&id=3044fb39&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3044fb39",
  null
  
)

export default component.exports