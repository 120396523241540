<template>
  <v-row no-gutters>
    <v-data-table
      :headers="headers"
      :items="patients"
      hide-default-footer
      :expanded="expanded"
      :height="height"
      item-key="id"
      single-expand
      @click:row="
        (_, slot) => {
          slot.expand(!slot.isExpanded);
        }
      "
      :loading="loading"
      class="data-table"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:no-data>
        <span class="subtitle-1 font-weight-bold">
          Tidak ada pasien waiting list
        </span>
      </template>
      <template v-slot:[`item.no`]="{ index }">
        <td>
          {{ (pagination.page - 1) * itemsPerPage + index + 1 }}
        </td>
      </template>
      <template v-slot:[`item.regist`]="{ item }">
        <v-btn
          color="primary"
          class="text-capitalize"
          @click.stop="openRegistrationForm(item)"
          small
          light
          depressed
          >Daftar</v-btn
        >
      </template>
      <template v-slot:[`item.dots`]="{ item }">
        <v-menu bottom left :offset-x="true" close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="assignSelectedPatient(item)"
              small
              light
              fab
              text
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list dense v-once class="text-start">
            <v-list-item
              v-for="(menu, index) in resource.menus"
              @click="menu.action()"
              :key="`menu-${index}`"
            >
              <span :class="index === 2 ? 'error--text' : ''">{{
                menu.text
              }}</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="px-0 expand-wrapper">
          <div class="expand-container">
            <div></div>
            <span>{{
              `${item.gender ? 'Laki-laki' : 'Perempuan'} ${item.age} Tahun`
            }}</span>
            <span>{{ `Pasien ${item.isNew ? 'Baru' : 'Lama'}` }}</span>
            <span>{{ `Jenis Kasus: ${item.case}` }}</span>
          </div>
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" scrollable persistent max-width="1200">
      <social-data-patients
        v-if="selectedForm === 0 && dialog"
        :selected-patient="selectedPatient"
        :patient-status="'rawat inap'"
        @close-form="closeForm"
        @back-dialog="dialog = false"
        @on-clicked="onClicked"
      />
      <bed-inpatient
        v-if="selectedForm === 1 && dialog"
        :selected-patient="selectedPatient"
        :medical-record="selectedPatient.rmNumber"
        @close-form="closeForm"
        @on-clicked="onClicked"
      />
      <social-data
        v-if="selectedForm === 2 && dialog"
        @close-form="closeForm"
        :medical-record="selectedPatient.rmNumber"
      />
      <detail-spri
        v-else-if="selectedForm === 3 && dialog"
        :patient="selectedPatient"
        @close-form="closeForm"
      />
    </v-dialog>
  </v-row>
</template>

<script>
// utils
import Swal from 'sweetalert2';

// components
import SocialDataPatients from '../SocialDataPatients';
import BedInpatient from './BedInpatient';
import SocialData from '../../../SharedComponent/SocialData';
import DetailSpri from './DetailSpri';

// api
import { deleteInpatient } from '@/fetchApi/Inpatient/Registration';

// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('InpatientRegistrationStore');

export default {
  name: 'WaitingListTable',
  components: { SocialDataPatients, BedInpatient, SocialData, DetailSpri },
  data() {
    return {
      expanded: [],
      selectedPatient: '',
      selectedForm: '',
      dialog: false,
      headers: [
        { text: 'No', value: 'no', align: 'center', sortable: false },
        {
          text: 'Tgl Rencana Rawat Inap',
          value: 'date',
          width: '13%',
          sortable: false,
        },
        { text: 'Nama', value: 'name', width: '35%', sortable: false },
        { text: 'No. RM', value: 'rm', sortable: false },
        {
          text: 'Tipe Penjamin',
          value: 'guarantor',
          width: '10%',
          sortable: false,
        },
        { text: 'Unit', value: 'unit', sortable: false },
        { text: '', value: 'regist', sortable: false, width: '5%' },
        { text: '', value: 'dots', sortable: false, width: '5%' },
      ],
      resource: {
        menus: [
          {
            text: 'Data Sosial',
            action: () => {
              this.onClicked(2);
            },
          },
          {
            text: 'Detail Surat Perintah Rawat Inap',
            action: () => {
              this.onClicked(3);
            },
          },
          {
            text: 'Hapus',
            action: () => {
              this.deleteWaitingListPatient();
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['getTableData', 'getLoading', 'getPagination']),
    pagination: {
      get() {
        return this.getPagination;
      },
    },
    loading: {
      get() {
        return this.getLoading.waiting;
      },
    },
    patients: {
      get() {
        return this.getTableData.waiting;
      },
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '55vh';
          break;
        case 'lg':
          height = '59vh';
          break;
        case 'xl':
          height = '67vh';
          break;
      }
      return height;
    },
    itemsPerPage() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    openRegistrationForm(item) {
      this.assignSelectedPatient(item);
      this.onClicked(0);
    },
    assignSelectedPatient(item) {
      this.selectedPatient = {
        id: item.id,
        rmNumber: item.rm,
        id_emr: item.id_emr,
        id_registration: item.idRegistration,
        caseType: item.case,
        meta: {
          name: item.name,
          age: item.age,
          guarantorType: item.guarantor,
          gender: item.gender,
          polyName: item.unit,
        },
      };
      this.$store.commit('changeSelectedPatient', this.selectedPatient);
    },
    onClicked(val) {
      if (val === null) return;
      this.selectedForm = val;
      this.dialog = true;
    },
    closeForm() {
      this.dialog = false;
      this.$emit('refresh');
    },
    deleteWaitingListPatient() {
      Swal.fire({
        title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Yakin Ingin Menghapus Pasien</p><p>${this.selectedPatient.meta.name}</p></div>`,
        iconHtml: '<span class="mdi mdi-help-circle-outline"></span>',
        html: `<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%;"><p>Jika dihapus, data pasien ${this.selectedPatient.meta.name}</p><p>akan hilang dari waiting list</p></div>`,
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'button-confirm',
          cancelButton: 'button-error-blue',
        },
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await deleteInpatient(this.selectedPatient.id);
            Swal.fire('Berhasil menghapus pasien', '', 'success').then(
              result => {
                if (result.isConfirmed) {
                  this.$emit('refresh');
                }
              },
            );
          } catch {
            Swal.fire('Terjadi kesalahan, gagal menghapus pasien', '', 'error');
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.data-table {
  width: 100%;
}

.expand-container {
  color: #9e9e9e;
  font-size: 0.6vw;
  display: grid;
  grid-template-columns: 17.2vw 30vw 18.5vw auto;
  text-align: start;
}

.expand-wrapper {
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
}

::v-deep {
  .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      // > tr:not(:last-child)
      > tr
      > td:not(.v-data-table__mobile-row),
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      // > tr:not(:last-child)
      > tr
      > th:not(.v-data-table__mobile-row) {
    // border: none;
    font-size: 0.8vw;
  }
}

.v-list-item {
  font-size: 0.8vw;
}
</style>
