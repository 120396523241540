import Constant from '../../../const';
const axios = require('axios');

const addSurgicalSafetyChecklistData = async payload => {
  const response = await axios.post(
    Constant.apiUrl.concat(`/document/surgical-safety-checklist`),
    payload,
  );

  return response;
};

export default addSurgicalSafetyChecklistData;
