import {
  putSupplier,
  postSupplier,
  getAllSupplier,
  deleteSupplier,
  getSupplierById,
} from '@/fetchApi/MasterData/Supplier';

import globalStore from '../../../index';

const state = {
  dashboard: {
    dialog: false,
    filter: {
      halaman: 0,
      itemCount: 0,
      sortBy: '',
      keyword: '',
    },
    snackbar: {
      text: '',
      color: '',
      show: false,
    },
  },
  form: {
    id: '',
    name: '',
    detail: {
      address: '',
      phone: '',
      email: '',
      bill_due: '',
    },
  },
  items: {
    dataSupplier: {
      isLoading: false,
      data: [],
    },
  },
};
const getters = {
  // Get State Dialog
  getDialog: state => state.dashboard.dialog,

  // Get State Items
  getItems: state => state.items,

  // Get State Form
  getForm: state => state.form,

  // Get Snackbar
  getSnackbar: state => state.dashboard.snackbar,
};
const mutations = {
  // Set State Dialog
  setDialog(state, payload) {
    state.dashboard.dialog = payload;
  },

  // Set State Items
  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: value,
      isLoading,
    };
  },

  // Set State Form
  setForm(state, payload) {
    state.form = payload;
  },

  // Set Filter
  setFilter(state, payload) {
    state.dashboard.filter = payload;
  },

  // Clear Form
  clearForm(state) {
    state.form = {
      id: '',
      name: '',
      detail: {
        address: '',
        phone: '',
        email: '',
        bill_due: '',
      },
    };
  },

  // Set snackbar
  setSnackbar(state, payload) {
    state.dashboard.snackbar = payload;
  },

  // Clear Snackbar
  clearSnackbar(state) {
    state.dashboard.snackbar = {
      show: false,
      color: 'success',
      text: '',
    };
  },
};
const actions = {
  // Get Data
  // Gett All Data Supplier
  async resolveGetAllSupplier(
    { commit, state },
    { halaman, itemCount, sortBy, keyword },
  ) {
    commit('setItems', {
      label: 'dataSupplier',
      value: [...state.items.dataSupplier.data],
      isLoading: true,
    });
    try {
      const supplierResponse = await getAllSupplier({
        halaman,
        itemCount,
        sortBy,
        keyword,
      });

      const supplierMapper = supplierResponse?.data?.map(item => ({
        id: item._id,
        name: item.name,
        address: item.detail.length
          ? item.detail.reverse()[0].address
          : item.detail[0].address,
        telp:
          item.detail.length > 1
            ? item.detail.reverse()[0].phone
            : item.detail[0].phone,
        email:
          item.detail.length > 1
            ? item.detail.reverse()[0].email
            : item.detail[0].email,
        dueDate:
          item.detail.length > 1
            ? item.detail.reverse()[0].bill_due
            : item.detail[0].bill_due,
      }));
      commit('setItems', {
        label: 'dataSupplier',
        value: supplierMapper,
        isLoading: false,
      });
      return supplierResponse?.length;
    } catch {
      commit('setItems', {
        label: 'dataSupplier',
        value: [],
        isLoading: false,
      });
      return 0;
    }
  },

  // Get Data Supplier By Id
  async resolveGetSupplierById({ state, commit }) {
    const responseGetSupplierById = await getSupplierById(state.form.id);
    try {
      commit('setForm', {
        id: responseGetSupplierById._id,
        name: responseGetSupplierById.name,
        detail: {
          address: responseGetSupplierById?.detail[0]?.address,
          phone: responseGetSupplierById?.detail[0]?.phone,
          email: responseGetSupplierById?.detail[0]?.email,
          bill_due: responseGetSupplierById?.detail[0]?.bill_due,
        },
      });
      return responseGetSupplierById;
    } catch (error) {
      return error;
    }
  },
  // End Get Data

  // Post Data
  async resolvePostSupplier({ state, commit, dispatch }) {
    const payload = {
      ...state.form,
      timestamps: {
        created_by: globalStore?.getters?.userLoggedIn?.id,
      },
    };
    delete payload.id;

    try {
      const postSupplierRes = await postSupplier(payload);
      if (postSupplierRes.status === 200) {
        commit('setDialog', false);
        commit('clearForm');
        await dispatch('resolveGetAllSupplier', {
          ...state.dashboard.filter,
        });
      }
      return postSupplierRes.status;
    } catch (error) {
      return error;
    }
  },
  // End Post Data

  // Put Data
  async resolvePutSupplier({ state, commit, dispatch }) {
    const payload = {
      ...state.form,
      timestamps: {
        created_by: globalStore?.getters?.userLoggedIn?.id,
      },
    };
    delete payload.id;
    try {
      const putSupplierRes = await putSupplier(state.form.id, payload);
      if (putSupplierRes.status === 200) {
        commit('setDialog', false);
        commit('clearForm');
        await dispatch('resolveGetAllSupplier', {
          ...state.dashboard.filter,
        });
      }
      return putSupplierRes.status;
    } catch (error) {
      return error;
    }
  },
  // End Put Data

  // Delete Data
  async resolveDeleteSupplier(_, id) {
    try {
      const responseDeleteSupplier = await deleteSupplier(id);
      return responseDeleteSupplier;
    } catch (error) {
      return error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
