<template>
  <v-menu
    dense
    v-model="localShow"
    :return-value.sync="localDate"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :close-on-content-click="clickCloseContent"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :placeholder="placeholder"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        :outlined="outlined"
        :append-icon="isIconDown ? 'mdi-chevron-down' : ''"
        :prepend-inner-icon="showIconCalendar ? 'mdi-calendar-month' : ''"
        dense
        :value="filterDate()"
        hide-details="false"
        :clearable="isClearable"
        @input="updateDate"
      ></v-text-field>
    </template>
    <v-date-picker
      :type="typeDate"
      v-if="!readonly"
      v-model="localDate"
      :no-title="type === 'filter'"
      :max="max"
      :min="min"
      scrollable
      :pickerDate.sync="pickerDate"
      event-color="red"
      :events="arrayEvents"
      @input="updateDate"
    >
      <div v-if="calendarEvent" class="event-calendar">
        <v-divider class="mb-1"></v-divider>
        <div
          v-for="(item, index) in filteredEvents"
          :key="`event-${index}`"
          class="d-flex justify-start"
        >
          <label class="event-date-desc grey--text">
            &#x2022;
            {{ item.dateText }} :
            {{ item.event_name }}
          </label>
        </div>
      </div>
    </v-date-picker>
  </v-menu>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
const axios = require('axios');

axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
  name: 'Datepicker',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: '',
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
    isIconDown: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'filter',
    },
    max: {
      type: String,
      default: '',
    },
    min: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showIconCalendar: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: 'Pilih Tanggal',
    },
    clickCloseContent: {
      type: Boolean,
      default: true,
    },
    typeDate: {
      type: String,
      default: 'date',
    },
    calendarEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localShow: this.show,
      localDate: this.date,
      arrayEvents: [],
      events: [],
      filteredEvents: [],
      pickerDate: null,
    };
  },
  mounted() {
    this.getCalendarEvents();
  },
  watch: {
    localDate: {
      handler(value) {
        this.localDate = this.date;
        if (!value) {
          const today = moment().format('YYYY-MM');
          this.pickerDate = today;
        }
      },
      deep: true,
    },
    pickerDate(value) {
      this.filteredEvents = this.events.filter(v => v.date.includes(value));
    },
  },
  methods: {
    getCalendarEvents(year) {
      // eslint-disable-next-line no-param-reassign
      if (!year) year = '2024';
      axios
        .get(Constant.apiUrl.concat(`/master/calendar-event?year=${year}`))
        .then(res => {
          const { data } = res.data;
          for (const i of data) {
            this.arrayEvents.push(i.date);
            this.events.push({
              ...i,
              dateText: moment(i.date).format('DD MMM'),
            });
          }
        });
    },
    filterDate() {
      return this.date
        ? moment(this.date).format(
            this.typeDate === 'date' ? 'DD-MM-YYYY' : 'MM-YYYY',
          )
        : '';
    },
    updateDate(newValue) {
      this.localDate = newValue;
      this.$emit('date-change', newValue);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-date-picker-table {
  height: auto;
}
.event-date-desc {
  text-align: left;
  font-size: 0.7vw;
  color: #616161;
}

.event-calendar {
  margin: 0 1.6vh;
  width: 90%;
  max-width: 30vh;
}
</style>
