<template>
  <v-card>
    <v-card-text>
      <v-row align="center">
        <v-col cols="8">
          <div class="d-flex align-center header">
            <label class="grey--text mr-2">No. RM</label>
            <label class="font-weight-bold mr-15">{{ patientData.noRM }}</label>
            <label class="grey--text mr-2">Nama</label>
            <label class="font-weight-bold mr-15">{{ patientData.name }}</label>
            <label class="grey--text mr-2">
              Tanggal Lahir
            </label>
            <label class="font-weight-bold">{{ patientData.birthDate }}</label>
          </div>
        </v-col>
        <v-col cols="4" class="d-flex justify-end align-center">
          <label class="font-weight-bold text-h6 mr-2">
            Formulir Informed Consent
          </label>
          <v-btn fab text @click="closeForm()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="mt-n4" />
    <v-container fluid class="px-4 py-0 my-0">
      <v-stepper
        non-linear
        :outlined="true"
        style="border: none; margin-top: 0px"
        v-model="step"
      >
        <v-stepper-header
          class="stepper"
          style="width: 550px; box-shadow: none; margin: auto;"
        >
          <div class="stepper-content" @click="handleBackForm" editable>
            <div :class="`stepper-number${step === 1 ? '-active' : ''}`">
              <h5>1</h5>
            </div>
            Form Pemberian Informasi
          </div>

          <v-divider></v-divider>

          <div class="stepper-content" @click="handleNextForm" editable>
            <div :class="`stepper-number${step === 2 ? '-active' : ''}`">
              <h5>2</h5>
            </div>
            Surat Pernyataan
          </div>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content :step="1" class="px-6 text-left mt-n4">
            <v-form
              ref="informationForm"
              style="height: 61vh;overflow: scroll;overflow-x: hidden; padding: 0 15px 0 0;"
              class="scrollbar"
            >
              <!-- First Section -->
              <v-row no-gutters>
                <v-col cols="6" class="pr-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <label for="doctor" class="required">
                        Dokter Penanggungjawab
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-autocomplete
                        validate-on-blur
                        :readonly="stateForm === 'detail'"
                        :items="items.staff.items"
                        :loading="items.staff.loading"
                        item-value="id"
                        item-text="name"
                        id="doctor"
                        append-icon="mdi-chevron-down"
                        placeholder="Pilih dokter penanggungjawab"
                        dense
                        :rules="rules.required"
                        v-model="statementLetter.doctor"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pl-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <label for="staff_executor" class="required">
                        Pemberi Informasi
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-autocomplete
                        :readonly="stateForm === 'detail'"
                        :items="items.staff.items"
                        :loading="items.staff.loading"
                        item-value="id"
                        item-text="name"
                        id="staff_executor"
                        append-icon="mdi-chevron-down"
                        placeholder="Pilih pemberi informasi"
                        validate-on-blur
                        dense
                        :rules="rules.required"
                        v-model="statementLetter.staff_executor"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="pr-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <label for="staff_information" class="required">
                        Pelaksana Tindakan
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-autocomplete
                        :readonly="stateForm === 'detail'"
                        :items="items.staff.items"
                        :loading="items.staff.loading"
                        item-value="id"
                        item-text="name"
                        id="staff_information"
                        append-icon="mdi-chevron-down"
                        placeholder="Pilih pelaksana tindakan"
                        dense
                        validate-on-blur
                        :rules="rules.required"
                        v-model="statementLetter.staff_information"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pl-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <label for="patient_receiver_info" class="required">
                        Penerima Informasi
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        placeholder="Masukkan penerima informasi"
                        :rules="rules.required"
                        v-model="statementLetter.patient_receiver_info"
                        validate-on-blur
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- End First Section -->
              <v-divider class="my-4" />
              <!-- Second Section -->
              <span class="grey--text">CHECKLIST PEMBERIAN INFORMASI</span>

              <v-row no-gutters>
                <v-col cols="6" class="pr-10">
                  <v-row class="align-start">
                    <v-col cols="4">
                      <span class="checkbox mt-5">
                        <input
                          type="checkbox"
                          :disabled="stateForm === 'detail'"
                          v-model="informationForm.diagnose.isCheck"
                          class="checkbox-item"
                        />
                        <label for="diagnose">
                          Diagnosis
                        </label>
                      </span>
                    </v-col>
                    <v-col cols="8">
                      <v-combobox
                        :readonly="stateForm === 'detail'"
                        v-if="isCheckup"
                        :clearable="stateForm !== 'detail'"
                        id="diagnose"
                        placeholder="Diagnosis"
                        :items="items.diagnosis.items"
                        :loading="items.diagnosis.loading"
                        item-text="name"
                        @change="onChangeDiagnosis"
                        :item-value="item => item"
                        append-icon="mdi-chevron-down"
                        v-model="informationForm.diagnose.value"
                        dense
                        multiple
                        chips
                        hide-selected
                      >
                        <template
                          v-slot:selection="{ attrs, item, select, selected }"
                        >
                          <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            @click="select"
                          >
                            <span>{{ item.name }}</span>
                          </v-chip>
                        </template>
                      </v-combobox>
                      <v-combobox
                        @change="onChangeDiagnosis"
                        :readonly="stateForm === 'detail'"
                        v-if="!isCheckup"
                        :clearable="stateForm !== 'detail'"
                        id="diagnose"
                        placeholder="diagnosis"
                        :items="items.diagnosis.items"
                        :loading="items.diagnosis.loading"
                        item-text="name"
                        :item-value="item => item"
                        append-icon="mdi-chevron-down"
                        v-model="informationForm.diagnose.value"
                        @keyup="handleChangeDiagnose"
                        dense
                        multiple
                        chips
                        hide-selected
                      >
                        <template
                          v-slot:selection="{ attrs, item, select, selected }"
                        >
                          <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            @click="select"
                          >
                            <span>{{ item.name }}</span>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pl-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <span class="checkbox">
                        <input
                          type="checkbox"
                          :disabled="stateForm === 'detail'"
                          v-model="informationForm.purpose.isCheck"
                          class="checkbox-item"
                        />
                        <label for="purpose">
                          Tujuan
                        </label>
                      </span>
                    </v-col>
                    <v-col cols="8"
                      ><v-text-field
                        :readonly="stateForm === 'detail'"
                        id="purpose"
                        placeholder="Tujuan"
                        v-model="informationForm.purpose.value"
                        :rules="rules.maxChar"
                        dense
                    /></v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="6" class="pr-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <span class="checkbox">
                        <input
                          type="checkbox"
                          :disabled="stateForm === 'detail'"
                          v-model="informationForm.base_diagnose.isCheck"
                          class="checkbox-item"
                        />
                        <label for="base_diagnose">
                          Dasar Diagnosis
                        </label>
                      </span>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        :rules="rules.maxChar"
                        id="base_diagnose"
                        placeholder="Dasar diagnosis"
                        v-model="informationForm.base_diagnose.value"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pl-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <span class="checkbox">
                        <input
                          type="checkbox"
                          :disabled="stateForm === 'detail'"
                          v-model="informationForm.complication.isCheck"
                          class="checkbox-item"
                        />
                        <label for="complication">
                          Komplikasi
                        </label>
                      </span>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        :rules="rules.maxChar"
                        id="actionIndication"
                        placeholder="Komplikasi"
                        v-model="informationForm.complication.value"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="6" class="pr-10">
                  <v-row class="align-start">
                    <v-col cols="4">
                      <span class="checkbox mt-1">
                        <input
                          type="checkbox"
                          :disabled="stateForm === 'detail'"
                          v-model="informationForm.action.isCheck"
                          class="checkbox-item"
                        />
                        <label for="action" class="required">
                          Tindakan Kedokteran
                        </label>
                      </span>
                    </v-col>
                    <v-col cols="8">
                      <v-autocomplete
                        :readonly="stateForm === 'detail'"
                        :items="items.services.items"
                        :loading="items.services.loading"
                        :item-value="item => item"
                        item-text="name"
                        id="action"
                        placeholder="Tindakan kedokteran"
                        append-icon="mdi-chevron-down"
                        v-model="informationForm.action.value"
                        validate-on-blur
                        dense
                        @keyup="handleChangeService"
                        :rules="
                          informationForm?.action?.value?.id !== 'etc'
                            ? rules.action
                            : []
                        "
                      />
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        v-show="informationForm?.action?.value?.id === 'etc'"
                        v-model="informationForm.action.etc"
                        placeholder="Masukan tindakan lain"
                        dense
                        @keyup="checkIsChanged"
                        :rules="
                          informationForm?.action?.value?.id === 'etc'
                            ? rules.required
                            : []
                        "
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pl-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <span class="checkbox">
                        <input
                          type="checkbox"
                          :disabled="stateForm === 'detail'"
                          v-model="informationForm.prognosis.isCheck"
                          class="checkbox-item"
                        />
                        <label for="Prognosis">
                          Prognosis
                        </label>
                      </span>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        :rules="rules.maxChar"
                        id="Prognosis"
                        placeholder="Prognosis"
                        v-model="informationForm.prognosis.value"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="6" class="pr-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <span class="checkbox">
                        <input
                          type="checkbox"
                          :disabled="stateForm === 'detail'"
                          v-model="informationForm.action_indication.isCheck"
                          class="checkbox-item"
                        />
                        <label for="action_indication">
                          Indikasi Tindakan
                        </label>
                      </span>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        :rules="rules.maxChar"
                        id="action_indication"
                        placeholder="Indikasi tindakan"
                        v-model="informationForm.action_indication.value"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pl-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <span class="checkbox">
                        <input
                          type="checkbox"
                          :disabled="stateForm === 'detail'"
                          v-model="informationForm.alt_risk.isCheck"
                          class="checkbox-item"
                        />
                        <label for="alt_risk">
                          Alternatif dan Risiko
                        </label>
                      </span>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        :rules="rules.maxChar"
                        id="alt_risk"
                        placeholder="Alternatif dan risiko"
                        v-model="informationForm.alt_risk.value"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="6" class="pr-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <span class="checkbox">
                        <input
                          type="checkbox"
                          :disabled="stateForm === 'detail'"
                          v-model="informationForm.action_risk.isCheck"
                          class="checkbox-item"
                        />
                        <label for="action_risk">
                          Risiko Tindakan
                        </label>
                      </span>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        :rules="rules.maxChar"
                        id="action_risk"
                        placeholder="Risiko tindakan"
                        v-model="informationForm.action_risk.value"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pl-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <span class="checkbox">
                        <input
                          type="checkbox"
                          :disabled="stateForm === 'detail'"
                          v-model="informationForm.cost.isCheck"
                          class="checkbox-item"
                        />
                        <label for="cost">
                          Biaya
                        </label>
                      </span>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        :rules="rules.maxChar"
                        id="cost"
                        placeholder="Biaya"
                        v-model="informationForm.cost.value"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="6" class="pr-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <span class="checkbox">
                        <input
                          type="checkbox"
                          :disabled="stateForm === 'detail'"
                          v-model="informationForm.procedure.isCheck"
                          class="checkbox-item"
                        />
                        <label for="procedure">
                          Tata Cara
                        </label>
                      </span>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        :rules="rules.maxChar"
                        id="procedure"
                        placeholder="Tata cara"
                        v-model="informationForm.procedure.value"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pl-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <span class="checkbox">
                        <input
                          type="checkbox"
                          :disabled="stateForm === 'detail'"
                          class="checkbox-item"
                          v-model="informationForm.others.isCheck"
                        />
                        <label for="others">
                          Lain-Lain
                        </label>
                      </span>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        :rules="rules.maxChar"
                        id="others"
                        placeholder="Lain-lain"
                        v-model="informationForm.others.value"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content :step="2" class="px-6 text-left mt-n4">
            <v-form
              ref="statementLetter"
              style="height: 61vh;overflow: scroll;overflow-x: hidden; padding: 0 15px 0 0;"
              class="scrollbar"
            >
              <v-row no-gutters>
                <v-col cols="6" class="pr-10">
                  <v-row class="align-center">
                    <v-col cols="4">
                      <label>
                        Tanggal
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <span>{{ today() }}</span>
                    </v-col>
                  </v-row>
                  <v-row
                    class="align-center"
                    style="margin-top: 30px !important;"
                  >
                    <v-col cols="4">
                      <label for="">
                        Yang Bertandatangan
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-radio-group
                        :readonly="stateForm === 'detail'"
                        row
                        class="mt-0 pt-5"
                        v-model="statementLetter.signer"
                      >
                        <v-radio
                          class="text-black"
                          label="Pasien"
                          value="pasien"
                        />
                        <v-radio class="text-black" label="Wali" value="wali" />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="align-center pa-1" no-gutters>
                    <v-col cols="4">
                      <label for="" class="required">
                        {{
                          statementLetter.signer === 'pasien'
                            ? 'Nama Pasien'
                            : 'Nama Wali'
                        }}
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        validate-on-blur
                        :readonly="stateForm === 'detail'"
                        v-show="statementLetter.signer === 'pasien'"
                        v-model="statementLetter.signer_name_patient"
                        placeholder="Nama Pasien"
                        dense
                        :rules="
                          statementLetter.signer === 'pasien'
                            ? [v => !!v || v === false || 'Wajib Diisi']
                            : []
                        "
                      />
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        v-show="statementLetter.signer === 'wali'"
                        v-model="statementLetter.signer_name"
                        placeholder="Nama wali"
                        dense
                        :rules="
                          statementLetter.signer === 'wali'
                            ? [v => !!v || v === false || 'Wajib Diisi']
                            : []
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-row class="align-center pa-1" no-gutters>
                    <v-col cols="4">
                      <label for=""> </label>
                    </v-col>
                    <v-col cols="8">
                      <v-combobox
                        :readonly="stateForm === 'detail'"
                        :items="familyStatus"
                        v-model="statementLetter.signer_as"
                        v-show="statementLetter.signer === 'wali'"
                        placeholder="Hubungan"
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row class="align-center pa-1 mt-3 mb-2" no-gutters>
                    <v-col cols="4">
                      <label class="required">
                        Umur
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-if="statementLetter.signer === 'pasien'"
                        type="text"
                        suffix="Tahun"
                        :readonly="true"
                        placeholder="Umur"
                        v-model="statementLetter.signer_age_patient"
                        validate-on-blur
                        dense
                        :rules="rules.numberOnly"
                        v-on:keypress="isNumber($event)"
                      />
                      <v-text-field
                        v-else
                        type="text"
                        suffix="Tahun"
                        validate-on-blur
                        :readonly="stateForm === 'detail'"
                        placeholder="Umur"
                        v-model="statementLetter.signer_age"
                        dense
                        :rules="rules.numberOnly"
                        v-on:keypress="isNumber($event)"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="align-center pa-1" no-gutters>
                    <v-col cols="4">
                      <label for="">
                        No Hp
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        type="text"
                        :readonly="stateForm === 'detail'"
                        placeholder="No hp"
                        v-model="statementLetter.no_telp"
                        dense
                        v-on:keypress="isNumber($event)"
                        :rules="rules.numberOnly"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pl-10">
                  <v-row class="align-start">
                    <v-col cols="4">
                      <label for="address">
                        Alamat Lengkap
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        :readonly="stateForm === 'detail'"
                        id="address"
                        placeholder="Masukkan alamat disini"
                        v-model="statementLetter.address"
                        dense
                        outlined
                        rows="3"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="align-center pa-1" no-gutters>
                    <v-col cols="4">
                      <label for="">
                        Nama Keluarga Pasien
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        placeholder="Nama keluarga pasien"
                        v-model="statementLetter.family_name"
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row class="align-center pa-1" no-gutters>
                    <v-col cols="4"> </v-col>
                    <v-col cols="8">
                      <v-combobox
                        :readonly="stateForm === 'detail'"
                        :items="familyStatus"
                        append-icon="mdi-chevron-down"
                        placeholder="Hubungan"
                        v-model="statementLetter.family_as"
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row class="align-center pa-1" no-gutters>
                    <v-col cols="4">
                      <label for="" class="required">
                        Pemberi informasi
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-autocomplete
                        :readonly="stateForm === 'detail'"
                        :items="items.staff.items"
                        :loading="items.staff.loading"
                        item-value="id"
                        item-text="name"
                        id="staff_executor"
                        append-icon="mdi-chevron-down"
                        placeholder="Pilih pemberi informasi"
                        validate-on-blur
                        dense
                        :rules="rules.required"
                        v-model="statementLetter.staff_executor"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="align-center pa-1" no-gutters>
                    <v-col cols="4">
                      <label for="">
                        Petugas pendamping
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-autocomplete
                        :readonly="stateForm === 'detail'"
                        :items="items.staff.items"
                        :loading="items.staff.loading"
                        item-value="id"
                        item-text="name"
                        id="staff_executor"
                        append-icon="mdi-chevron-down"
                        v-model="statementLetter.staff_companion"
                        placeholder="Pilih petugas pendamping"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider class="my-4" />
              <v-row no-gutters class="align-center">
                <v-col cols="6" class="pr-10">
                  <v-row no-gutters class="align-start">
                    <v-col cols="4">
                      <label for="action" class="required">
                        Tindakan Kedokteran
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-autocomplete
                        :readonly="stateForm === 'detail'"
                        :items="items.services.items"
                        :loading="items.services.loading"
                        :item-value="item => item"
                        item-text="name"
                        id="action"
                        placeholder="Tindakan kedokteran"
                        append-icon="mdi-chevron-down"
                        v-model="informationForm.action.value"
                        validate-on-blur
                        dense
                        @keyup="handleChangeService"
                        :rules="
                          informationForm?.action?.value?.id !== 'etc'
                            ? rules.action
                            : []
                        "
                      />
                      <v-text-field
                        :readonly="stateForm === 'detail'"
                        v-show="informationForm.action.value.id === 'etc'"
                        v-model="informationForm.action.etc"
                        placeholder="Masukan tindakan lain"
                        @keyup="checkIsChanged"
                        dense
                        :rules="
                          informationForm.action.value.id === 'etc'
                            ? rules.required
                            : []
                        "
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pl-10">
                  <v-row no-gutters class="align-center">
                    <v-col cols="4">
                      <label for="" class="required">
                        Persetujuan Tindakan
                      </label>
                    </v-col>
                    <v-col cols="8">
                      <v-radio-group
                        :readonly="stateForm === 'detail'"
                        validate-on-blur
                        row
                        class="mt-0 pt-5"
                        v-model="statementLetter.patient_action_agreement"
                        :rules="rules.required"
                      >
                        <v-radio
                          class="text-black"
                          label="Setuju"
                          :value="true"
                        />
                        <v-radio
                          class="text-black"
                          label="Tidak Setuju"
                          :value="false"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
    <v-card-actions
      v-if="state !== 'add'"
      class="px-10 pb-4 d-flex justify-space-between"
      style="padding: 0; margin: 0;"
    >
      <v-btn
        v-if="step === 2"
        color="primary"
        outlined
        dark
        dense
        depressed
        @click="handleBackForm"
      >
        <v-icon color="primary">
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        color="primary"
        dark
        dense
        depressed
        @click="handleNextForm"
      >
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-btn>
      <div class="d-flex">
        <v-btn
          color="primary"
          class="text-capitalize mr-3"
          depressed
          @click="setStateForm('edit')"
          v-if="stateForm === 'detail' && isEditable"
          >Edit</v-btn
        >
        <v-menu top :offset-x="true" :close-on-content-click="true">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                v-if="step === 2 && stateForm !== 'edit'"
                color="primary"
                class="text-capitalize"
                width="90"
                outlined
              >
                <v-icon>mdi-printer</v-icon>
                <span>Print</span>
              </v-btn>
            </span>
          </template>

          <v-list style="padding: 5px;">
            <v-list-item
              @click="handlePrint(idIC, 'form', stateForm)"
              class="dropdown"
            >
              <v-list-item-title>
                <span class="dropdown-item">
                  Print Formulir Informed Consent
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="handlePrint(idIC, 'letter', stateForm)"
              class="dropdown"
            >
              <v-list-item-title>
                <span class="dropdown-item">
                  Print Surat Pernyataan
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          color="error"
          class="text-capitalize mr-3"
          outlined
          @click="
            setStateForm('detail');
            handleGetByID();
          "
          v-if="stateForm === 'edit'"
          >Batal</v-btn
        >
        <v-btn
          color="primary"
          @click="updateData"
          class="text-capitalize"
          depressed
          v-if="stateForm === 'edit'"
          >Simpan Perubahan</v-btn
        >
      </div>
    </v-card-actions>
    <v-card-actions v-else class="px-10 pb-4" style="padding: 0; margin: 0;">
      <v-btn
        v-if="step === 2"
        color="primary"
        outlined
        dark
        dense
        depressed
        @click="handleBackForm"
      >
        <v-icon color="primary">
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-menu top :offset-x="true" :close-on-content-click="true">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-btn
              v-if="step === 2"
              color="primary"
              class="text-capitalize"
              width="90"
              outlined
            >
              <v-icon>mdi-printer</v-icon>
              <span>Print</span>
            </v-btn>
          </span>
        </template>

        <v-list style="padding: 5px;">
          <v-list-item
            @click="handlePrint(idIC, 'form', stateForm)"
            class="dropdown"
          >
            <v-list-item-title>
              <span class="dropdown-item">
                Print Formulir Informed Consent
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="handlePrint(idIC, 'letter', stateForm)"
            class="dropdown"
          >
            <v-list-item-title>
              <span class="dropdown-item">
                Print Surat Pernyataan
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-if="step === 1 || (step === 2 && stateForm !== 'detail')"
        color="primary"
        class="text-capitalize ml-4"
        :width="step === 1 ? 50 : null"
        dense
        depressed
        @click="handleNextForm"
        :disabled="isChanged && step === 2"
      >
        <v-icon v-if="step === 1">
          mdi-chevron-right
        </v-icon>
        <span v-if="step === 2">{{
          stateForm === 'add' ? 'Simpan' : 'Simpan Perubahan'
        }}</span>
      </v-btn>
    </v-card-actions>
    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbarEdit.show"
      elevation="0"
      :color="snackbarEdit.color"
      height="15"
      timeout="4100"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbarEdit.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbarEdit.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </v-card>
</template>

<script>
import InformedConsent from './InformedConsent.js';
export default {
  ...InformedConsent,
  name: 'FormInformedConsent',
  props: {
    state: String,
  },
};
</script>

<style lang="scss" scoped>
$active-color: #036de2;
@mixin stepper-state($isActive: true) {
  @if $isActive {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;
    color: white;
    background-color: $active-color;
  } @else {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;
    color: white;
    background-color: #9ca2a5;
  }
}
.header {
  color: #404040;
}
label {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 14px;
  color: #616161;
}
.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  .checkbox-item {
    min-width: 16px;
    min-height: 16px;
    cursor: pointer;
  }
  .checkbox-item:checked {
    accent-color: #2d965a;
    cursor: pointer;
  }
}
.required:after {
  content: ' *';
  color: red;
}
.scrollbar {
  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}
.dropdown {
  min-height: 40px;
  border: 1px solid black;
  margin: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}
.dropdown:hover {
  background-color: #e6f6fe;
  cursor: pointer;
}
.dropdown-item {
  color: #404040;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.stepper {
  display: flex;
  justify-content: center;
  max-width: 35vw;
  margin: auto;
  align-items: center;
  gap: 1vw;
  margin-bottom: 5vh;

  &-content {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.7vw;
    margin: 0 0.5vw;
  }

  &-number-active {
    @include stepper-state();
  }

  &-number {
    @include stepper-state(false);
  }
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
