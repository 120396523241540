import Constant from '../../const';
const axios = require('axios');

const startQueue = async payload => {
  try {
    const response = await axios.put(
      Constant.apiUrl.concat('/queue/start'),
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default startQueue;
