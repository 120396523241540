import Constant from '@/const';
import axios from 'axios';

const deleteSupplier = async id => {
  try {
    const response = await axios.delete(
      Constant.apiUrl.concat(`/master/supplier/${id}`),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default deleteSupplier;
