<template>
  <v-card class="main-container">
    <section class="pt-5 pb-2 mx-8 card-header">
      <div class="mb-8 d-flex justify-space-between">
        <h2 class="text-left">Detail Resep</h2>
        <v-btn @click="$emit('close-dialog')" small fab text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="meta-header">
        <label class="font-weight-medium text-left">No RM</label>
        <label class="grey--text text-left">{{ patientData.rmNumber }}</label>
        <label class="font-weight-medium text-left">Nama</label>
        <label class="grey--text text-left">{{
          `${patientData.meta.name} (${patientData.meta.gender[0]})`
        }}</label>
        <label class="font-weight-medium text-left">Tanggal Lahir</label>
        <label class="grey--text text-left">{{
          `${birthDate} (${age})`
        }}</label>
      </div>
    </section>
    <v-container class="item-container px-12" fluid>
      <v-row>
        <v-expansion-panels
          flat
          class="elevation-0"
          style="width: 90vw; margin: auto"
        >
          <v-expansion-panel
            v-for="(item, index) in items"
            :key="`panel-${index}`"
          >
            <v-expansion-panel-header class="panel-header">
              {{ `Resep ${index + 1}, ${item.date} pukul ${item.time} WIB` }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-data-table
                  :headers="headers"
                  :items="item.table"
                  item-key="name"
                  hide-default-footer
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <td v-if="item.type === 'Non Racik'" class="text-left">
                      {{ item.name }}
                    </td>
                    <td v-else class="text-left">
                      <p
                        :class="
                          `${item.drugs.length === 1 ? 'ma-0' : 'mx-0'} px-0`
                        "
                        :key="`drug-name-${index}`"
                        v-for="(drug, index) in item.drugs"
                      >
                        {{ drug.name }}
                      </p>
                    </td>
                  </template>
                  <template v-slot:[`item.dosage`]="{ item }">
                    <td v-if="item.type === 'Non Racik'" class="text-left">
                      {{ item.dosage }}
                    </td>
                    <td v-else class="text-left">
                      <p
                        :class="
                          `${item.drugs.length === 1 ? 'ma-0' : 'mx-0'} px-0`
                        "
                        :key="`drug-dosage-${index}`"
                        v-for="(drug, index) in item.drugs"
                      >
                        {{ drug.dosage }}
                      </p>
                    </td>
                  </template>
                  <template v-slot:[`item.preparation`]="{ item }">
                    <td v-if="item.type === 'Non Racik'" class="text-left">
                      {{ item.preparation }}
                    </td>
                    <td v-else class="text-left">
                      <p
                        :class="
                          `${item.drugs.length === 1 ? 'ma-0' : 'mx-0'} px-0`
                        "
                        :key="`drug-preparation-${index}`"
                        v-for="(drug, index) in item.drugs"
                      >
                        {{ drug.preparation }}
                      </p>
                    </td>
                  </template>
                </v-data-table>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>
    <v-row class="card-footer d-flex justify-end px-8">
      <v-btn @click="$emit('back')" color="primary" outlined>Kembali</v-btn>
    </v-row>
  </v-card>
</template>

<script>
// utils
import moment from 'moment-timezone';

// mixins
import alertMixin from '@/mixin/alertMixin';

// api
import { getDrugAdministration } from '@/fetchApi/Inpatient/CPO';

export default {
  name: 'DetailRecipe',
  mixins: [alertMixin],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    allergy: {
      type: Array,
    },
    idEmr: {
      type: String,
    },
    idDrugAdministration: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      birthDate: '',
      age: '',
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Jenis Obat',
          value: 'type',
          width: '9%',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Nama Obat',
          value: 'name',
          width: '15%',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Dosis',
          value: 'dosage',
          width: '6%',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Sediaan',
          value: 'preparation',
          width: '5%',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Jumlah Diminta',
          value: 'amountAsked',
          width: '7%',
          sortable: false,
          align: 'left  ',
        },
        {
          text: 'Jumlah Diberikan',
          value: 'amountGiven',
          width: '7%',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Rute',
          value: 'route',
          width: '7%',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Aturan Pakai',
          value: 'rule',
          width: '11%',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Aturan Umum',
          value: 'generalRule',
          width: '15%',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Aturan Lainnya',
          value: 'specialInstruction',
          width: '12%',
          sortable: false,
          align: 'left',
        },
      ],
      items: [],
    };
  },
  mounted() {
    this.getDrugList();
    this.birthDate = moment(this.patientData.meta.birthDate).format(
      'DD/MM/YYYY',
    );
    this.age = this.patientData.meta.age + ' tahun';
  },
  methods: {
    async getDrugList() {
      try {
        const response = await getDrugAdministration(this.idDrugAdministration);

        const [data] = response.data.data;

        this.items = data.review_pharmacy
          .filter(item => !item.id_recipe?.[0]?.medical_tools.length)
          .map(i => {
            return {
              date: moment(i.timestamps.created_at).format('D MMMM YYYY'),
              time: moment(i.timestamps.created_at).format('HH:mm'),
              table: this.mapDrugTable(i.id_recipe?.[0]),
            };
          });
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    mapDrugTable(data) {
      const result = [];
      if (data.recipe.length) {
        result.push(
          data.recipe?.map((v, index) => {
            return {
              no: index + 1,
              type: 'Non Racik',
              name: v?.name,
              generalRule: v?.general_rule || '-',
              specialInstruction: v?.special_instruction || '-',
              route: v?.route || '-',
              preparation: v?.preparation || '-',
              dosage: `${v?.dosage} ${v?.unit_dosage}`,
              rule: v?.usage || '-',
              amountAsked: `${v?.requested_quantity} ${v?.packaging}`,
              amountGiven: `${v?.quantity} ${v?.packaging}`,
            };
          }),
        );
      }
      if (data.mix_recipe.length) {
        result.push(
          data.mix_recipe?.map((v, index) => {
            return {
              no: index + 1,
              type: 'Racik',
              drugs: v.drugs.map(drug => {
                return {
                  name: drug.name,
                  preparation: drug.preparation,
                  dosage: `${drug.dosage} ${drug.unit_dosage}`,
                };
              }),
              generalRule: v?.general_rule || '-',
              specialInstruction: v?.special_instruction || '-',
              route: v?.route || '-',
              rule: v?.usage || '-',
              amountAsked: `${v?.requested_quantity || '-'} ${v?.packaging ||
                '-'}`,
              amountGiven: `${v?.quantity || '-'} ${v?.packaging || '-'}`,
            };
          }),
        );
      }
      return result.flat();
    },
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  height: 65vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.panel-header {
  overflow-x: hidden;
  background-color: #deeef9;
  font-weight: 500;
}
.meta-header {
  margin-bottom: 20px;
  overflow-x: hidden;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr 2fr);
}
.card-header {
  overflow-x: hidden;
  top: 0;
  position: sticky;
  background-color: white;
  z-index: 2;
}
.card-footer {
  overflow-x: hidden;
  bottom: 0;
  position: sticky;
  background-color: white;
  padding-top: 1vw;
  z-index: 2;
}
.main-container {
  overflow-x: hidden;
  min-height: 90vh;
}

::v-deep .v-data-table-header {
  background-color: #f4f5f5;
  border-bottom: 1.2px solid #9ca2a5;
}

::v-deep td {
  border-bottom: 1.2px solid #f4f5f5 !important;
}

.v-data-table::v-deep th {
  font-size: 12px !important;
}
.v-data-table::v-deep td {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #3f484a !important;
}

::v-deep .v-expansion-panel-header {
  border-bottom: 1px solid #86bcdb !important;
}
</style>
