const state = {
  head: '',
  ear: '',
  eye: '',
  nose: '',
  mouth: '',
  neck: '',
  hair: '',
  lips: '',
  teeth: '',
  tongue: '',
  palate: '',
  throat: '',
  tonsil: '',
  chest: '',
  breast: '',
  back: '',
  stomach: '',
  genitalia: '',
  anus: '',
  upper_arm: '',
  forearm: '',
  finger: '',
  hand_nail: '',
  hand_joint: '',
  upper_limb: '',
  lower_limb: '',
  toe: '',
  toe_nail: '',
  leg_joint: '',
};

const getters = {
  getPhysicalCheck: state => state,
};

const mutations = {
  clearPhysical(state) {
    Object.assign(state, {
      head: '',
      ear: '',
      eye: '',
      nose: '',
      mouth: '',
      neck: '',
      hair: '',
      lips: '',
      teeth: '',
      tongue: '',
      palate: '',
      throat: '',
      tonsil: '',
      chest: '',
      breast: '',
      back: '',
      stomach: '',
      genitalia: '',
      anus: '',
      upper_arm: '',
      forearm: '',
      finger: '',
      hand_nail: '',
      hand_joint: '',
      upper_limb: '',
      lower_limb: '',
      toe: '',
      toe_nail: '',
      leg_joint: '',
    });
  },
  setPhysical(state, payload) {
    Object.assign(state, { ...payload });
  },
};

export default {
  state,
  getters,
  mutations,
};
