import Constant from '@/const';
const axios = require('axios');

const getDrugStock = async params => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/stock/all/names`),
      { params },
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getDrugStock;
