var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-5 py-4 main-container",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-space-between px-1 py-2"},[_c('v-col',{staticClass:"pa-0 text-start"},[_c('h2',[_vm._v("General Consent")])]),_c('v-col',{staticClass:"pa-0 d-flex",attrs:{"cols":"4"}},[_c('v-combobox',{attrs:{"single-line":"","clearable":"","outlined":"","dense":"","items":_vm.resource.status,"item-text":"text","append-icon":"mdi-chevron-down","placeholder":"Semua Status"},on:{"change":() => {
            _vm.table.pagination.page = 1;
            _vm.showAllGeneralConsent();
          }},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('v-btn',{staticClass:"mx-5",attrs:{"color":"grey","icon":"","text":"","small":""},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',{class:{ refresh: _vm.isRefresh }},[_vm._v("mdi-autorenew")])],1),_c('v-text-field',{staticStyle:{"width":"30vh"},attrs:{"background-color":"grey lighten-3","placeholder":"Search","solo":"","dense":"","hide-details":"","flat":"","prepend-inner-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.table.headers,"items":_vm.table.items,"items-per-page":_vm.table.items.length,"hide-actions":"","loading":_vm.table.isLoading,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.no`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.no + '.')+" ")]}},{key:`item.status`,fn:function({ item }){return [_c('div',{staticClass:"text-capitalize text-center chip",class:{
          unprocessed: item.status === 'unprocess',
          signed: item.status === 'finished',
          processed: item.status === 'inprocess',
        }},[_vm._v(" "+_vm._s(_vm.generateStatus(item.status))+" ")])]}},{key:`item.action`,fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":"","color":"#3f484a"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-3",staticStyle:{"cursor":"pointer"},attrs:{"color":item.isSigned ? 'primary' : 'grey'},on:{"click":function($event){return _vm.previewFile(item.filePath)}}},'v-icon',attrs,false),on),[_vm._v("mdi-file-document-check")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.filePath ? 'Lihat Dokumen' : 'Belum Ada Dokumen')+" General Consent")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"#3f484a"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-3",staticStyle:{"cursor":"pointer"},attrs:{"color":item.isHasDocument ? 'primary' : 'grey'},on:{"click":function($event){return _vm.openGeneralConsentForm(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-file-document-edit")])]}}],null,true)},[_c('span',[_vm._v("Form General Consent")])])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"1700","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('GeneralConsentForm',{attrs:{"rm-number":_vm.selectedRmNumber,"serviceType":"Rawat Jalan"},on:{"close-form":function($event){_vm.dialog = false}}}):_vm._e()],1),_c('v-pagination',{staticClass:"pagination",attrs:{"total-visible":"12","length":_vm.table.pagination.paginationLength},on:{"input":function($event){return _vm.showAllGeneralConsent()}},model:{value:(_vm.table.pagination.page),callback:function ($$v) {_vm.$set(_vm.table.pagination, "page", $$v)},expression:"table.pagination.page"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }