<template>
  <v-card class="main-container">
    <v-card-title class="justify-space-between">
      <v-col class="pa-0">
        <span class="font-weight-bold">
          <span v-if="state === 'add'">Tambah</span>
          <span v-if="state === 'edit'">Ubah</span>
          Supplier
        </span>
      </v-col>
      <v-col class="pa-0" cols="1">
        <v-btn icon @click="$emit('close-dialog')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-col>
    </v-card-title>
    <v-row no-gutters justify="center" class="py-5">
      <v-form lazy-validation ref="form">
        <div class="form-style">
          <label class="body-2 required">Nama Supplier</label>
          <v-text-field
            placeholder="Masukkan Nama Supplier"
            dense
            v-model="form.name"
            :rules="[v => !!v || 'Wajib diisi']"
          ></v-text-field>

          <label class="body-2">Alamat</label>
          <v-textarea
            placeholder="Masukkan Alamat"
            outlined
            dense
            rows="4"
            no-resize
            v-model="form.detail.address"
          ></v-textarea>

          <label class="body-2">No. Telepon</label>
          <v-text-field
            placeholder="Masukkan Nomor Telepon"
            dense
            v-model="form.detail.phone"
          ></v-text-field>

          <label class="body-2">E-mail</label>
          <v-text-field
            placeholder="Masukkan Alamat Email"
            dense
            v-model="form.detail.email"
          ></v-text-field>

          <label class="body-2">Tanggal Jatuh Tempo</label>
          <v-row no-gutters align="baseline">
            <v-col class="pa-0" cols="4">
              <v-text-field
                dense
                class="pr-3"
                type="number"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                v-model.number="form.detail.bill_due"
              ></v-text-field>
            </v-col>
            <v-col class="pa-0">
              <label>hari</label>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        depressed
        class="text-none"
        width="110"
        @click="handleActionSupplier()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('MasterSupplier');

export default {
  name: 'FormSupplier',
  props: {
    state: {
      type: String,
      default: 'add',
    },
  },
  computed: {
    ...mapGetters(['getForm', 'getItems']),

    items: {
      get() {
        return this.getItems;
      },
    },

    form: {
      get() {
        return this.getForm;
      },
    },
  },
  mounted() {
    switch (this.state) {
      case 'add':
        break;
      case 'edit':
        this.resolveGetSupplierById();
        break;
    }
  },
  methods: {
    ...mapActions([
      'resolvePutSupplier',
      'resolvePostSupplier',
      'resolveGetSupplierById',
    ]),
    handleActionSupplier() {
      if (this.$refs.form.validate()) {
        switch (this.state) {
          case 'add':
            this.resolvePostSupplier().then(status => {
              if (status === 200) {
                Swal.fire('Berhasil', 'Data supplier sudah masuk', 'success');
              } else {
                Swal.fire('Gagal', 'Data supplier gagal ditambahkan', 'error');
              }
            });
            break;
          case 'edit':
            this.resolvePutSupplier().then(status => {
              if (status === 200) {
                Swal.fire(
                  'Berhasil',
                  'Data supplier berhasil diubah',
                  'success',
                );
              } else {
                Swal.fire('Gagal', 'Data supplier gagal diubah', 'error');
              }
            });
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-style {
  display: grid;
  grid-template-columns: 11vw 20vw;
  text-align: left;
  align-items: baseline;
}

.main-container {
  width: 38vw;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
