<template>
  <v-row no-gutters>
    <v-data-table
      :headers="headers"
      :items="patients"
      hide-default-footer
      :expanded="expanded"
      :height="height"
      item-key="idRegistration"
      single-expand
      :loading="loading"
      @click:row="
        (_, slot) => {
          slot.expand(!slot.isExpanded);
        }
      "
      class="data-table"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:[`item.no`]="{ index }">
        <td>
          {{ (pagination.page - 1) * itemsPerPage + index + 1 }}
        </td>
      </template>
      <template v-slot:[`item.guarantorType`]="{ item }">
        <td class="text-uppercase text-left">{{ item.guarantorType }}</td>
      </template>
      <template v-slot:no-data>
        <span class="subtitle-1 font-weight-bold">
          Tidak ada pasien terdaftar
        </span>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="px-0 expand-wrapper">
          <v-container class="pt-3">
            <div class="expand-container">
              <div></div>
              <span>{{
                `${item.gender ? 'Laki-laki' : 'Perempuan'} ${item.age} Tahun`
              }}</span>
              <span>{{ `Pasien ${item.isNew ? 'Baru' : 'Lama'}` }}</span>
              <span>{{ `Jenis Kasus: ${item.case}` }}</span>
            </div>
          </v-container>
        </td>
      </template>
      <template v-slot:[`item.dots`]="{ item }">
        <v-menu bottom left offset-x close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="assignSelectedPatient(item)"
              small
              light
              fab
              text
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-dots-horizontal </v-icon>
            </v-btn>
          </template>
          <v-list dense class="text-start">
            <v-list-item
              v-for="(menu, idx) in item.isFinish
                ? resource.menus.filter(
                    i =>
                      i.text !== 'Cetak Gelang Pasien' && i.text !== 'Data Bed',
                  )
                : resource.menus"
              :key="`menu-reg-${idx}`"
              @click="menu.action(item)"
            >
              <span>{{ menu.text }}</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" persistent :max-width="maxWidth[selectedForm]">
      <social-data
        v-if="selectedForm === 0 && dialog"
        @close-form="closeForm"
        :medical-record="selectedPatient.rmNumber"
      />
      <bed-inpatient
        v-if="selectedForm === 1 && dialog"
        :selected-patient="selectedPatient"
        :medical-record="selectedPatient.rmNumber"
        @close-form="closeForm"
        is-detail
      />
      <general-consent-form
        v-else-if="selectedForm === 2 && dialog"
        @close-form="closeForm"
        :rm-number="selectedPatient.rmNumber"
        :id-inpatient="selectedPatient.id"
        service-type="Rawat Inap"
      />
      <detail-spri
        v-else-if="selectedForm === 3 && dialog"
        :patient="selectedPatient"
        @close-form="closeForm"
      />
    </v-dialog>
  </v-row>
</template>

<script>
// utils
const moment = require('moment-timezone');

// components
import SocialData from '../../../SharedComponent/SocialData';
import DetailSpri from './DetailSpri';
import BedInpatient from './BedInpatient';
import GeneralConsentForm from '../GeneralConsentForm';

// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('InpatientRegistrationStore');

// mixins
import printPatientBracelet from '@/mixin/InpatientMixin/printPatientBracelet';
import printMixin from '@/mixin/printMixin';

export default {
  name: 'RegisteredPatientTable',
  components: { SocialData, DetailSpri, BedInpatient, GeneralConsentForm },
  mixins: [printPatientBracelet, printMixin],
  props: {
    status: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      expanded: [],
      selectedPatient: '',
      selectedForm: '',
      dialog: false,
      maxWidth: [1200, 1200, 3000, 1200],
      resource: {
        menus: [
          {
            text: 'Data Sosial',
            action: () => {
              this.onClicked(0);
            },
          },
          {
            text: 'Data Bed',
            action: () => {
              this.onClicked(1);
            },
          },
          {
            text: 'Formulir General Consent',
            action: () => {
              this.onClicked(2);
            },
          },
          {
            text: 'Detail Surat Perintah Rawat Inap',
            action: () => {
              this.onClicked(3);
            },
          },
          {
            text: 'Cetak Gelang Pasien',
            action: data => {
              this.onClicked(4, data);
            },
          },
        ],
      },
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          align: 'center',
          width: '3%',
        },
        {
          text: 'Tgl Terdaftar',
          value: 'registered_date',
          sortable: moment(),
          width: '10%',
        },
        {
          text: 'Tgl Keluar',
          value: 'exit_date',
          sortable: false,
          width: '10%',
        },
        { text: 'Nama Pasien', value: 'name', sortable: false },
        { text: 'No. RM', value: 'rm', width: '10%', sortable: false },
        {
          text: 'Tipe Penjamin',
          value: 'guarantor_type',
          width: '10%',
          sortable: false,
        },
        { text: 'Nomor Bed', value: 'bed', width: '7%', sortable: false },
        { text: 'DPJP', value: 'dpjp', width: '13%', sortable: false },
        { text: '', value: 'dots', width: '3%', sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(['getTableData', 'getLoading', 'getPagination']),
    pagination: {
      get() {
        return this.getPagination;
      },
    },
    loading: {
      get() {
        return this.getLoading.registered;
      },
    },
    patients: {
      get() {
        return this.getTableData.registered;
      },
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '55vh';
          break;
        case 'lg':
          height = '59vh';
          break;
        case 'xl':
          height = '67vh';
          break;
      }
      return height;
    },
    itemsPerPage() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    assignSelectedPatient(item) {
      this.selectedPatient = {
        id: item.id,
        rmNumber: item.rm,
        id_emr: item.id_emr,
        id_bed: item.id_bed,
        id_registration: item.idRegistration,
        caseType: item.case,
        meta: {
          name: item.name,
          age: item.age,
          guarantorType: item.guarantor_type,
          gender: item.gender,
          polyName: item.unit,
        },
      };
      this.$store.commit('changeSelectedPatient', this.selectedPatient);
    },
    onClicked(val, data) {
      if (val === null) return;
      if (val === 4) {
        const doc = this.printBracelet(data);
        this.printDocument(doc, {
          docName: 'Gelang Rawat Inap Pasien',
          rmNumber: data.rm,
        });
        return;
      }
      this.selectedForm = val;
      this.dialog = true;
    },
    closeForm() {
      this.dialog = false;
      this.$emit('refresh');
    },
  },
};
</script>

<style lang="scss" scoped>
.data-table {
  width: 100%;
}

.expand-container {
  color: #9e9e9e;
  font-size: 0.6vw;
  display: grid;
  grid-template-columns: 20vw 30vw 18.5vw auto;
  text-align: start;
}

.expand-wrapper {
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
}

::v-deep {
  .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      // > tr:not(:last-child)
      > tr
      > td:not(.v-data-table__mobile-row),
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      // > tr:not(:last-child)
      > tr
      > th:not(.v-data-table__mobile-row) {
    // border: none;
    font-size: 0.8vw;
  }
}

.v-list-item {
  font-size: 0.8vw;
}
</style>
