<template>
  <div class="d-flex flex-column justify-center align-center container">
    <img src="@/assets/image/403_image.png" alt="403 Forbidden Image" />
    <span class="py-4">
      Akses Ditolak
    </span>
    <p>
      Maaf, Anda tidak memiliki izin untuk mengakses halaman ini. Jika anda
      merasa ini adalah masalah, silakan menghubungi tim kami
    </p>
  </div>
</template>

<script>
export default {
  name: 'Restricted403',
};
</script>

<style scss scoped>
.container {
  height: 100vh;
  width: 600px;
}

span {
  color: #404040;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.11px;
}

p {
  color: #757575;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

img {
  height: 225px;
  width: 400px;
}
</style>
