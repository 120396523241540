import {
  getInitialObjectiveData,
  getCpptByIdInpatient,
  postCpptData,
  putCpptById,
  getCpptById,
} from '@/fetchApi/Inpatient/CPPT';
import {
  mapInitialObjectiveData,
  mapInitialStateData,
  mapDetailCppt,
  mapToPayload,
  mapCpptData,
} from './mapCpptData';

import moment from 'moment-timezone';

const _ = require('lodash');

const state = {
  id: {
    emr: '',
    inpatient: '',
  },
  selectedForm: 'dashboard',
  loading: {
    table: false,
  },
  table: {
    items: [],
  },
  history: [],
  errorVueEditor: {
    anamnesis: {
      state: false,
      message: '',
    },
    otherObjective: {
      state: false,
      message: '',
    },
    assessmentNote: {
      state: false,
      message: '',
    },
    ppaInstruction: {
      state: false,
      message: '',
    },
  },
  form: {
    id: '',
    isDetail: false,
    isEdit: false,
    generalInformation: {
      ppa: {
        name: '',
        role: '',
        id: '',
      },
      bed_number: '',
      date: moment().format('DD-MM-YYYY'),
      time: '',
    },
    subjective: {
      anamnesis: '',
    },
    objective: {
      sistole: '',
      diastole: '',
      temperature: '',
      heartRate: '',
      rr: '',
      saturation: '',
      gds: '',
      other: '',
    },
    assessment: {
      nurse: {
        diagnose: [
          {
            diagnose: '',
            planningResource: [],
            loading: false,
            planning: [
              {
                planning: '',
              },
            ],
          },
        ],
      },
      doctor: {
        diagnose: [
          {
            name: '',
            diagnose: '',
            loading: false,
          },
        ],
        planning: [''],
      },
      note: '',
    },
    ppaInstruction: '',
  },
  patient: {
    rmNumber: '',
    name: '',
    birthDate: '',
    address: '',
  },
  initialFormState: {
    time: '',
    anamnesis: '',
    sistole: '',
    diastole: '',
    temperature: '',
    heartRate: '',
    rr: '',
    saturation: '',
    gds: '',
    other: '', // other objective
    ppaInstruction: '',
    assessmentNote: '',
    doctor: {
      diagnose: [
        {
          name: '',
          icd: '',
        },
      ],
      planning: [''],
    },
    nurse: [
      {
        diagnose: '',
        planning: [''],
      },
    ],
  },
};

const getters = {
  getErrorVueEditor: state => state.errorVueEditor,
  getSelectedForm: state => state.selectedForm,
  getTableItems: state => state.table.items,
  getPatientData: state => state.patient,
  getLoading: state => state.loading,
  getHistory: state => state.history,
  getForm: state => state.form,
  getIsFormChanged: state => {
    const current = mapInitialStateData(state.form);

    return !_.isEqual(current, state.initialFormState);
  },
};

const mutations = {
  setPatientData(state, payload) {
    state.patient = payload;
  },
  setInitialFormState(state, payload) {
    state.initialFormState = {
      ...state.initialFormState,
      ...payload,
    };
  },
  navigateTo(state, payload) {
    state.selectedForm = payload;
  },
  setErrorVueEditor(state, payload) {
    state.errorVueEditor = { ...state.errorVueEditor, ...payload };
  },
  setTableItems(state, payload) {
    state.table.items = payload;
  },
  setForm(state, payload) {
    state.form = {
      ...state.form,
      ...payload,
    };
  },
  setCpptStoreId(state, payload) {
    state.id = payload;
  },
  addDoctorDiagnose(state) {
    state.form.assessment.doctor.diagnose.push({
      name: '',
      diagnose: '',
      loading: false,
    });
  },
  deleteDoctorDiagnose(state, index) {
    state.form.assessment.doctor.diagnose.splice(index, 1);
  },
  addDoctorPlanning(state) {
    state.form.assessment.doctor.planning.push('');
  },
  deleteDoctorPlanning(state, index) {
    state.form.assessment.doctor.planning.splice(index, 1);
  },
  addNurseDiagnose(state) {
    state.form.assessment.nurse.diagnose.push({
      diagnose: '',
      planningResource: [],
      loading: false,
      planning: [
        {
          planning: '',
        },
      ],
    });
  },
  deleteNurseDiagnose(state, index) {
    state.form.assessment.nurse.diagnose.splice(index, 1);
  },
  addNursePlanning(state, diagnoseIndex) {
    state.form.assessment.nurse.diagnose[diagnoseIndex].planning.push({
      planning: '',
    });
  },
  deleteNursePlanning(state, { diagnoseIndex, planningIndex }) {
    state.form.assessment.nurse.diagnose[diagnoseIndex].planning.splice(
      planningIndex,
      1,
    );
  },
  clearCpptState(state, type) {
    switch (type) {
      case 'form':
        // state.errorAnamnesis = false;
        state.form = {
          ...state.form,
          id: '',
          isDetail: false,
          isEdit: false,
          generalInformation: {
            ...state.form.generalInformation,
            ppa: {
              name: '',
              role: '',
              id: '',
            },
            // bed_number: '',
            date: moment().format('DD-MM-YYYY'),
            time: moment().format('HH:mm'),
          },
          subjective: {
            anamnesis: '',
          },
          objective: {
            sistole: '',
            diastole: '',
            temperature: '',
            heartRate: '',
            rr: '',
            saturation: '',
            gds: '',
            other: '',
          },
          assessment: {
            nurse: {
              diagnose: [
                {
                  diagnose: '',
                  planningResource: [],
                  loading: false,
                  planning: [
                    {
                      planning: '',
                    },
                  ],
                },
              ],
            },
            doctor: {
              diagnose: [
                {
                  name: '',
                  diagnose: '',
                  loading: false,
                },
              ],
              planning: [''],
            },
            note: '',
          },
          ppaInstruction: '',
        };
        break;
      case 'table':
        state.table = {
          items: [],
        };
        state.history = [];
        state.selectedForm = 'dashboard';
        break;
      case 'error-state':
        state.errorVueEditor = {
          anamnesis: {
            state: false,
            message: '',
          },
          otherObjective: {
            state: false,
            message: '',
          },
          assessmentNote: {
            state: false,
            message: '',
          },
          ppaInstruction: {
            state: false,
            message: '',
          },
        };
        break;
      case 'patient':
        state.patient = {
          rmNumber: '',
          name: '',
          birthDate: '',
          address: '',
        };
        break;
      case 'initial-state':
        state.initialFormState = {
          time: '',
          anamnesis: '',
          sistole: '',
          diastole: '',
          temperature: '',
          heartRate: '',
          rr: '',
          saturation: '',
          gds: '',
          other: '', // other objective
          ppaInstruction: '',
          assessmentNote: '',
          doctor: {
            diagnose: [
              {
                name: '',
                icd: '',
              },
            ],
            planning: [''],
          },
          nurse: [
            {
              diagnose: '',
              planning: [''],
            },
          ],
        };
    }
  },
};

const actions = {
  async resolveGetCpptTableItems({ state }) {
    try {
      state.loading.table = true;
      const response = await getCpptByIdInpatient(state.id.inpatient);
      const { data } = response.data;
      state.table.items = data.map(item => {
        return { id: item._id, ...mapCpptData(item.history[0]) };
      });
      state.history = data.map(item => {
        return {
          date: moment(
            item.history[item.history.length - 1].general_information.datetime,
          ).format('DD MMMM YYYY'),
          history: item.history.map(i => {
            return mapCpptData(i);
          }),
        };
      });
    } catch {
      state.table.items = [];
      state.history = [];
    } finally {
      state.loading.table = false;
    }
  },
  async resolveGetCpptHistory({ state }) {
    try {
      const response = await getCpptByIdInpatient(
        state.id.inpatient,
        'history',
      );
      const { data } = response.data;
      state.history = data.map(item => {
        return {
          date: moment(item.date, 'YYYY-MM-DD').format('DD MMMM YYYY'),
          history: item.cppt.map(i => {
            return {
              isEdited: i.history.length > 1,
              edited_at: {
                date: moment(i.history[0]?.timestamps?.created_at).format(
                  'DD-MM-YYYY',
                ),
                time: moment(i.history[0]?.timestamps?.created_at).format(
                  'HH:mm',
                ),
              },
              ...mapCpptData(i.history[0]),
            };
          }),
        };
      });
    } catch {
      state.history = [];
    }
  },
  async resolveGetInitialObjectiveData({ state, commit }) {
    try {
      const response = await getInitialObjectiveData(state.id.inpatient);
      const { data } = response.data;
      commit('setForm', { objective: mapInitialObjectiveData(data.objective) });
      commit('setInitialFormState', mapInitialObjectiveData(data.objective));
    } catch {
      commit('setForm', { objective: mapInitialObjectiveData(null) });
    }
  },
  async resolvePostCpptData({ state }, role) {
    try {
      const payload = mapToPayload({ state, role });
      await postCpptData(payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async resolvePutCppt(_, { id_cppt, role }) {
    try {
      const payload = mapToPayload({ state, role });
      await putCpptById({ history: payload.history[0] }, id_cppt);
    } catch (error) {
      throw new Error(error);
    }
  },
  async resolveGetCpptById({ commit }, id) {
    try {
      const response = await getCpptById(id);
      const { data } = response.data;
      commit('setForm', {
        id: data._id,
        isDetail: true,
        ...mapDetailCppt(data.history[0]),
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('navigateTo', 'form-cppt');
      commit('setInitialFormState', mapInitialStateData(state.form));
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
