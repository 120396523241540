<template>
  <div class="main-unit">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Data Supplier
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            color="grey"
            icon
            text
            @click="refreshAndAutoUpdate"
          >
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            class="input"
            v-model="search"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
          <v-btn
            medium
            color="primary"
            @click="exportMD()"
            class="ml-3 pa-3 font-weight-medium text-none export-button"
            :loading="isLoadingExport"
          >
            <v-icon small class="mr-1">mdi-download</v-icon>
            Unduh
          </v-btn>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="items.dataSupplier.isLoading"
              :headers="table.headers"
              :items="items.dataSupplier.data"
              :expanded="expanded"
              :items-per-page="itemsPerRow"
              single-expand
              item-key="id"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
              :page.sync="table.pagination.page"
              hide-default-footer
            >
              <template v-slot:[`item.no`]="{ item }">
                <td>
                  {{
                    (table.pagination.page - 1) * itemsPerRow +
                      items.dataSupplier.data.indexOf(item) +
                      1
                  }}
                </td>
              </template>
              <template v-slot:expanded-item="{ item, headers }">
                <td :colspan="headers.length" class="expand">
                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="handleOpenDialog('edit', item)"
                              >
                                <v-icon>
                                  mdi-clipboard-plus
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Ubah Supplier</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteItem(item)"
                              >
                                <v-icon>
                                  mdi-close-thick
                                </v-icon>
                              </v-btn>
                            </template>

                            <span>Hapus Supplier</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <Snackbar
      :show="snackbar.show"
      :color="snackbar.color"
      :text="snackbar.text"
      :close="
        () => {
          clearSnackbar();
        }
      "
    />

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            @input="handleGetAllSupplier()"
            :length="allPages"
            :total-visible="7"
          ></v-pagination>
          <v-spacer></v-spacer>

          <v-btn color="primary" fab dark @click="handleOpenDialog('add')">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-footer>
    <v-dialog v-model="dialog" width="auto">
      <FormSupplier
        v-if="dialog"
        :state="state"
        @close-dialog="handleCloseDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import jwtMixin from '@/mixin/jwtMixin';
import FormSupplier from './components/Supplier/FormSupplier.vue';
import { createNamespacedHelpers } from 'vuex';
import { Snackbar } from '@/components/SharedComponent';
import Constant from '@/const';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'MasterSupplier',
);

const _ = require('lodash');
const axios = require('axios');

export default {
  name: 'MasterdataUnit',
  mixins: [jwtMixin],
  components: {
    FormSupplier,
    Snackbar,
  },
  data() {
    return {
      expanded: [],
      search: '',
      allPages: 0,
      table: {
        headers: [
          { text: 'No', value: 'no', width: '6%' },
          { text: 'Nama', value: 'name', width: '20%' },
          { text: 'Alamat', value: 'address', width: '30%' },
          { text: 'Telp', value: 'telp' },
          { text: 'Email', value: 'email' },
          { text: 'Jatuh Tempo (Hari)', value: 'dueDate' },
        ],
        items: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: '',
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
  },
  computed: {
    ...mapGetters(['getItems', 'getDialog', 'getSnackbar']),

    items: {
      get() {
        return this.getItems;
      },
    },

    dialog: {
      get() {
        return this.getDialog;
      },
      set(val) {
        return this.setDialog(val);
      },
    },

    snackbar: {
      get() {
        return this.getSnackbar;
      },
    },

    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    ...mapMutations([
      'setDialog',
      'setFilter',
      'clearForm',
      'setForm',
      'setSnackbar',
      'clearSnackbar',
    ]),
    ...mapActions(['resolveGetAllSupplier', 'resolveDeleteSupplier']),
    searchData: _.debounce(v => {
      v.handleGetAllSupplier();
    }, 650),

    handleOpenDialog(state, data) {
      this.state = state;
      state !== 'add' && this.setForm({ id: data.id });
      this.setDialog(true);
    },

    handleCloseDialog() {
      this.clearForm();
      this.setDialog(false);
    },

    async exportMD() {
      try {
        this.isLoadingExport = true;
        const res = await axios.get(
          Constant.apiUrl.concat(`/master/supplier/export`),
        );
        if (res.status === 200) {
          const { url } = res.config;
          window.open(url, '_blank');
          this.setSnackbar({
            show: true,
            color: 'success',
            text: 'Dokumen berhasil diunduh',
          });
        }
        this.isLoadingExport = false;
      } catch (error) {
        if (error) {
          this.setSnackbar({
            show: true,
            color: 'error',
            text: 'Terjadi kesalahan',
          });
        }
        this.isLoadingExport = false;
      }
    },

    deleteItem(item) {
      Swal.fire({
        title: 'Yakin?',
        text: `Anda akan menghapus ${item.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result.value) {
          this.resolveDeleteSupplier(item.id).then(res => {
            if (res.status === 200) {
              Swal.fire('Berhasil', `${res.data.pesan}`, 'success');
              this.handleGetAllSupplier();
            } else {
              Swal.fire({
                title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>${res.response.data.pesan.replace(
                  /^bad request: /i,
                  '',
                )}</p></div>`,
                iconHtml: '<span class="mdi mdi-close-circle-outline"></span>',
              });
            }
          });
        }
      });
    },

    handleGetAllSupplier() {
      const itemCount = this.itemsPerRow;
      const halaman = this.table.pagination.page;
      const keyword = this.search;
      let sortBy = '';
      this.setFilter({
        halaman,
        itemCount,
        sortBy,
        keyword,
      });

      this.resolveGetAllSupplier({
        halaman,
        itemCount,
        sortBy,
        keyword,
      }).then(item => {
        this.allPages = Math.ceil(item / itemCount);
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.handleGetAllSupplier();
      this.interval = setInterval(this.handleGetAllSupplier, 60000);
    },
  },
  beforeDestroy() {
    this.clearSnackbar();
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 20%;
}

.hover:hover {
  color: #2196f3;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
