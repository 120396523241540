import Constant from '@/const';
import axios from 'axios';

const getDrugAdministration = async idDrugAdministration => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/inpatient/drugadministration/${idDrugAdministration}`,
      ),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getDrugAdministration;
