<template>
  <div class="inventory">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col style="display: grid" class="text-left">
            <v-toolbar-title class="font-weight-bold float-left">
              Pemesanan Barang
            </v-toolbar-title>
            <div class="caption grey--text float-left">
              Pemesanan barang ke supplier
            </div>
          </v-col>

          <v-spacer></v-spacer>
          <v-btn @click="filterBtn()" text>
            <span class="text-capitalize">Filters</span>
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
          <v-btn class="mr-2" color="grey" icon text>
            <v-icon @click="refresh()" :class="{ refresh: isRefresh }"
              >mdi-autorenew</v-icon
            >
          </v-btn>
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            v-model="table.search"
            single-line
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <div class="filter-menu mt-4" v-if="filter">
        <v-row no-gutters align="center" justify="center">
          <div style="max-width: 30%; min-width: 290px" class="mr-4">
            <Datepicker
              :show="datePicker"
              :date="date"
              :isClearable="true"
              label="Tanggal"
              @date-change="handleDateChange"
            />
          </div>
          <v-select
            class="input"
            item-text="name"
            item-value="id"
            :items="supplierName"
            dense
            v-model="filterSupplier"
            label="Nama Supplier"
            return-object
            outlined
            hide-details="false"
            clearable
            @change="
              table.pagination.page = 1;
              getAllSupply();
            "
          ></v-select>
        </v-row>
      </div>

      <v-container fluid>
        <v-col class="pa-0">
          <v-data-table
            class="flex-grow-1"
            :headers="table.headers"
            :items="table.dataItem"
            :items-per-page="itemsPerRow"
            hide-default-footer
            single-expand
            item-key="id"
            :loading="isLoading"
          >
            <template v-slot:item="{ item, index }">
              <tr class="row-datatable" :key="index">
                <td class="text-sm-left">
                  {{ (table.pagination.page - 1) * itemsPerRow + index + 1 }}
                </td>
                <td class="text-sm-left">
                  {{ item.billDate }}
                </td>
                <td class="text-sm-left">{{ item.sp }}</td>
                <td class="text-sm-left">
                  {{ item.suppllierName }}
                </td>
                <td class="text-sm-left">{{ item.user }}</td>
                <td class="text-sm-left">
                  <v-icon small class="mr-2" @click="onClickFooter(1, item)">
                    mdi-dots-vertical
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-container>
    </v-main>

    <v-footer color="white" class="py-0">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="itemPages"
            total-visible="7"
            @input="refreshAndAutoUpdate()"
          ></v-pagination>
          <v-spacer></v-spacer>

          <template>
            <v-btn
              color="primary"
              fab
              dark
              @click="onClickFooter(0, '')"
              width="50"
              height="50"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
            <v-dialog v-model="dialog" max-width="1200px">
              <InputItemOrder
                v-if="selectedForm == 0"
                @change-form="changeForm"
                @close-form="closeForm"
              />
              <DetailItemOrder
                v-if="selectedForm == 1"
                @change-form="changeForm"
                @close-form="closeForm"
              />
            </v-dialog>
          </template>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import Constant from '@/const';
import InputItemOrder from './ItemOrderComponent/InputItemOrder.vue';
import DetailItemOrder from './ItemOrderComponent/DetailItemOrder.vue';
import Datepicker from '@/components/SharedComponent/Datepicker/Datepicker.vue';
import jwtMixin from '@/mixin/jwtMixin';
const moment = require('moment-timezone');
const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'ItemOrder',
  mixins: [jwtMixin],
  components: {
    Datepicker,
    InputItemOrder,
    DetailItemOrder,
  },
  data() {
    return {
      datePicker: false,
      isLoading: false,
      isRefresh: false,
      filter: false,
      expand: false,
      dialog: false,
      filterSupplier: '',
      filterDate: '',
      date: '',
      itemPages: 0,
      editedItem: {
        noQueue: 0,
        billDate: '',
        sp: '',
        supplierName: '',
        user: '',
      },
      supplierName: '',
      show: false,
      table: {
        search: '',
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 7,
          totalItems: null,
        },
        headers: [
          { text: 'No.', value: 'index' },
          { text: 'Tanggal Pemesanan', value: 'billDate' },
          { text: 'Nomor Pemesanan', value: 'sp' },
          { text: 'Nama Supplier', value: 'suppllierName' },
          { text: 'Petugas Order', value: 'user' },
          { text: '', value: 'actions', sortable: false },
        ],
        dataItem: [],
      },
      selectedForm: null,
    };
  },
  watch: {
    'table.search'() {
      this.searchData(this);
    },
    dialog(newValue) {
      if (!newValue) {
        this.selectedForm = null;
      }
    },
    date(val) {
      if (val) {
        this.dateFormat = this.formatDate(this.date);
      } else if (val === null) {
        this.getAllSupply();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
      this.getSupplier();
      // this.fillterByDate();
      // this.fillterByName();
    });
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.table.pagination.page = 1;
      v.refreshAndAutoUpdate();
    }, 250),
    handleDateChange(updatedDate) {
      this.date = updatedDate;
      this.table.pagination.page = 1;
      this.refreshAndAutoUpdate();
    },
    refresh() {
      this.isRefresh = true;
      this.getAllSupply();
    },
    onClickFooter(index, order) {
      this.changeSelectedItem(order);

      switch (index) {
        default:
          this.changeForm(index);
          break;
      }
      this.dialog = true;
    },

    changeSelectedItem(order) {
      localStorage.setItem('order_id', order.id);
    },
    changeForm(item) {
      this.selectedForm = item;
    },

    closeForm() {
      this.dialog = false;
      this.getAllSupply();
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    filterBtn() {
      if (this.filter === false) {
        this.filter = true;
      } else {
        this.filter = false;
        (this.filterName = ''), (this.dateFormat = '');
        this.date = '';
      }
      this.getAllSupply();
    },

    getSupplier() {
      this.runApiCalls(() => {
        axios.get(Constant.apiUrl.concat('/master/supplier')).then(response => {
          const getSupplier = response.data.data;
          this.supplierName = getSupplier.map(supplier => {
            return {
              id: supplier._id,
              name: supplier.name,
            };
          });
        });
      });
    },
    getAllSupply() {
      this.isLoading = true;
      const itemCount = this.itemsPerRow;
      const supplier = this.filterSupplier ? this.filterSupplier.name : '';
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/supply/request?page=${
                this.table.pagination.page
              }&itemCount=${itemCount}&search=${this.table.search ||
                ''}&date=${this.date || ''}&supplier_name=${supplier}`,
            ),
          )
          .then(response => {
            if (response.data.data.length === 0) {
              this.isLoading = false;
              this.table.dataItem = [];
            }
            const { data } = response.data;
            const { length: dataLength } = response.data;
            this.itemPages = Math.ceil(dataLength / itemCount);
            this.table.dataItem = data
              .sort(
                (a, b) =>
                  moment(b.timestamps.created_at) -
                  moment(a.timestamps.created_at),
              )
              .map(dataItem => {
                return {
                  id: dataItem._id.toString(),
                  suppllierName: dataItem.supplier
                    ? dataItem.supplier.name
                    : '-',
                  idSupplier: dataItem.id_supplier ? dataItem.id_supplier : '-',
                  billDate: moment(dataItem.order_date).format('DD/MM/YYYY'),
                  sp: dataItem.order_number,
                  user: dataItem.staff[0].detail[0].name,
                };
              });
          })
          .catch(() => {
            this.itemPages = 0;
            this.table.dataItem = [];
          })
          .finally(() => {
            this.isLoading = false;
            this.isRefresh = false;
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllSupply();
      this.interval = setInterval(this.getAllSupply, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.input {
  max-width: 30%;
}
</style>
