import {
  putService,
  postServices,
  deleteServices,
  getServiceById,
  getAllServices,
  putStatusServices,
} from '@/fetchApi/MasterData/Service';
import { getAllDrugs } from '@/fetchApi/MasterData/Drugs';
import { getAllUnit } from '@/fetchApi/MasterData/Unit';

const _ = require('lodash');

const state = {
  dashboard: {
    dialog: false,
    filter: {
      halaman: 0,
      itemCount: 0,
      sortBy: '',
      keyword: '',
    },
    snackbar: {
      text: '',
      color: '',
      show: false,
    },
  },
  formTable: {
    medicalTools: { detail: null, quantity: null },
    consumables: { detail: null, quantity: null },
  },
  form: {
    id: '',
    name: '',
    category: '',
    sub_category: null,
    unit: [],
    cost: [
      {
        assurance: 'umum',
        medical_service: {
          staff: 0,
          specialize: 0,
          nurse: 0,
          midwife: 0,
          psychologist: 0,
          nutritionists: 0,
          health_analyst: 0,
          radiographer: 0,
        },
        consumable_material: [],
        consumable_tools: [],
        add_material_price: false,
        additional_material_price: 0,
        add_tool_price: false,
        additional_tool_price: 0,
        unit_load: 0,
        margin: 0,
        admin_load: 0,
      },
      {
        assurance: 'bpjs',
        medical_service: {
          staff: 0,
          specialize: 0,
          nurse: 0,
          midwife: 0,
          psychologist: 0,
          nutritionists: 0,
          health_analyst: 0,
          radiographer: 0,
        },
        consumable_material: [],
        consumable_tools: [],
        add_material_price: false,
        additional_material_price: 0,
        add_tool_price: false,
        additional_tool_price: 0,
        unit_load: 0,
        margin: 0,
        admin_load: 0,
      },
      {
        assurance: 'asuransi',
        medical_service: {
          staff: 0,
          specialize: 0,
          nurse: 0,
          midwife: 0,
          psychologist: 0,
          nutritionists: 0,
          health_analyst: 0,
          radiographer: 0,
        },
        consumable_material: [],
        consumable_tools: [],
        add_material_price: false,
        additional_material_price: 0,
        add_tool_price: false,
        additional_tool_price: 0,
        unit_load: 0,
        margin: 0,
        admin_load: 0,
      },
    ],
  },
  table: [
    {
      medicalTools: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'Alat Medis Habis Pakai', value: 'name' },
          { text: 'Jumlah', value: 'quantity' },
          { text: 'Satuan', value: 'unit' },
          { text: 'Harga', value: 'price' },
          { text: '', value: 'actions', sortable: false },
        ],
        items: [],
      },
      consumables: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'Bahan Medis Habis Pakai', value: 'name' },
          { text: 'Jumlah', value: 'quantity' },
          { text: 'Satuan', value: 'unit' },
          { text: 'Harga', value: 'price' },
          { text: '', value: 'actions', sortable: false },
        ],
        items: [],
      },
    },

    {
      medicalTools: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'Alat Medis Habis Pakai', value: 'name' },
          { text: 'Jumlah', value: 'quantity' },
          { text: 'Satuan', value: 'unit' },
          { text: 'Harga', value: 'price' },
          { text: '', value: 'actions', sortable: false },
        ],
        items: [],
      },
      consumables: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'Bahan Medis Habis Pakai', value: 'name' },
          { text: 'Jumlah', value: 'quantity' },
          { text: 'Satuan', value: 'unit' },
          { text: 'Harga', value: 'price' },
          { text: '', value: 'actions', sortable: false },
        ],
        items: [],
      },
    },
    {
      medicalTools: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'Alat Medis Habis Pakai', value: 'name' },
          { text: 'Jumlah', value: 'quantity' },
          { text: 'Satuan', value: 'unit' },
          { text: 'Harga', value: 'price' },
          { text: '', value: 'actions', sortable: false },
        ],
        items: [],
      },
      consumables: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'Bahan Medis Habis Pakai', value: 'name' },
          { text: 'Jumlah', value: 'quantity' },
          { text: 'Satuan', value: 'unit' },
          { text: 'Harga', value: 'price' },
          { text: '', value: 'actions', sortable: false },
        ],
        items: [],
      },
    },
  ],
  items: {
    dataServices: {
      isLoading: false,
      data: [],
    },
    dataUnit: {
      isLoading: false,
      data: [],
    },
    dataBHP: {
      isLoading: false,
      data: [],
    },
    dataAHP: {
      isLoading: false,
      data: [],
    },
  },
};
const getters = {
  // Get State Dialog
  getDialog: state => state.dashboard.dialog,

  // Get State Items
  getItems: state => state.items,

  // Get State Form
  getForm: state => state.form,

  // Get State Form Table
  getFormTable: state => state.formTable,

  // Get State Table
  getTable: state => state.table,

  // Get Snackbar
  getSnackbar: state => state.dashboard.snackbar,
};
const mutations = {
  // Set State Dialog
  setDialog(state, payload) {
    state.dashboard.dialog = payload;
  },

  // Set State Items
  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: value,
      isLoading,
    };
  },

  // Set State Form
  setForm(state, payload) {
    state.form = payload;
  },

  // Set Filter
  setFilter(state, payload) {
    state.dashboard.filter = payload;
  },

  // Set Table
  setTable(state, { medicalTools, consumables, tab }) {
    state.form.cost[tab].consumable_tools = medicalTools;
    state.form.cost[tab].consumable_material = consumables;
  },

  // Clear Form
  clearForm(state) {
    state.form = {
      id: '',
      name: '',
      category: '',
      sub_category: null,
      unit: [],
      cost: [
        {
          assurance: 'umum',
          medical_service: {
            staff: 0,
            specialize: 0,
            nurse: 0,
            midwife: 0,
            psychologist: 0,
            nutritionists: 0,
            health_analyst: 0,
            radiographer: 0,
          },
          additional_material_price: 0,
          additional_tool_price: 0,
          consumable_material: [],
          consumable_tools: [],
          unit_load: 0,
          margin: 0,
          admin_load: 0,
        },
        {
          assurance: 'bpjs',
          medical_service: {
            staff: 0,
            specialize: 0,
            nurse: 0,
            midwife: 0,
            psychologist: 0,
            nutritionists: 0,
            health_analyst: 0,
            radiographer: 0,
          },
          additional_material_price: 0,
          additional_tool_price: 0,
          consumable_material: [],
          consumable_tools: [],
          unit_load: 0,
          margin: 0,
          admin_load: 0,
        },
        {
          assurance: 'asuransi',
          medical_service: {
            staff: 0,
            specialize: 0,
            nurse: 0,
            midwife: 0,
            psychologist: 0,
            nutritionists: 0,
            health_analyst: 0,
            radiographer: 0,
          },
          additional_material_price: 0,
          additional_tool_price: 0,
          consumable_material: [],
          consumable_tools: [],
          unit_load: 0,
          margin: 0,
          admin_load: 0,
        },
      ],
    };

    state.formTable = {
      medicalTools: { detail: null, quantity: null },
      consumables: { detail: null, quantity: null },
    };

    state.table = [
      {
        medicalTools: {
          headers: [
            {
              text: 'No',
              align: 'start',
              sortable: false,
              value: 'no',
            },
            { text: 'Alat Medis Habis Pakai', value: 'name' },
            { text: 'Jumlah', value: 'quantity' },
            { text: 'Satuan', value: 'unit' },
            { text: 'Harga', value: 'price' },
            { text: '', value: 'actions', sortable: false },
          ],
          items: [],
        },
        consumables: {
          headers: [
            {
              text: 'No',
              align: 'start',
              sortable: false,
              value: 'no',
            },
            { text: 'Bahan Medis Habis Pakai', value: 'name' },
            { text: 'Jumlah', value: 'quantity' },
            { text: 'Satuan', value: 'unit' },
            { text: 'Harga', value: 'price' },
            { text: '', value: 'actions', sortable: false },
          ],
          items: [],
        },
      },

      {
        medicalTools: {
          headers: [
            {
              text: 'No',
              align: 'start',
              sortable: false,
              value: 'no',
            },
            { text: 'Alat Medis Habis Pakai', value: 'name' },
            { text: 'Jumlah', value: 'quantity' },
            { text: 'Satuan', value: 'unit' },
            { text: 'Harga', value: 'price' },
            { text: '', value: 'actions', sortable: false },
          ],
          items: [],
        },
        consumables: {
          headers: [
            {
              text: 'No',
              align: 'start',
              sortable: false,
              value: 'no',
            },
            { text: 'Bahan Medis Habis Pakai', value: 'name' },
            { text: 'Jumlah', value: 'quantity' },
            { text: 'Satuan', value: 'unit' },
            { text: 'Harga', value: 'price' },
            { text: '', value: 'actions', sortable: false },
          ],
          items: [],
        },
      },
      {
        medicalTools: {
          headers: [
            {
              text: 'No',
              align: 'start',
              sortable: false,
              value: 'no',
            },
            { text: 'Alat Medis Habis Pakai', value: 'name' },
            { text: 'Jumlah', value: 'quantity' },
            { text: 'Satuan', value: 'unit' },
            { text: 'Harga', value: 'price' },
            { text: '', value: 'actions', sortable: false },
          ],
          items: [],
        },
        consumables: {
          headers: [
            {
              text: 'No',
              align: 'start',
              sortable: false,
              value: 'no',
            },
            { text: 'Bahan Medis Habis Pakai', value: 'name' },
            { text: 'Jumlah', value: 'quantity' },
            { text: 'Satuan', value: 'unit' },
            { text: 'Harga', value: 'price' },
            { text: '', value: 'actions', sortable: false },
          ],
          items: [],
        },
      },
    ];
  },

  // Set snackbar
  setSnackbar(state, payload) {
    state.dashboard.snackbar = payload;
  },

  // Clear Snackbar
  clearSnackbar(state) {
    state.dashboard.snackbar = {
      show: false,
      color: 'success',
      text: '',
    };
  },
};
const actions = {
  // Get Data
  // Gett All Data Service
  async resolveGetAllServices(
    { commit, state },
    { halaman, itemCount, sortBy, keyword },
  ) {
    commit('setItems', {
      label: 'dataServices',
      value: [...state.items.dataServices.data],
      isLoading: true,
    });
    try {
      const serviceResponse = await getAllServices({
        halaman,
        itemCount,
        sortBy,
        keyword,
      });

      const serviceMapper = serviceResponse?.data?.map(item => ({
        id: item._id.toString(),
        servicesName: _.get(item, 'name', ''),
        category: _.get(item, 'category', ''),
        codeLis: _.get(item, 'code_lis', ''),
        subCategories: _.get(item, 'sub_category', ''),
        unit: _.get(item, 'unit', ''),
        assurance: _.get(item, 'cost.assurance', ''),
        total: _.get(item, 'cost.total', 0),
        cost: _.get(item, 'cost', ''),
        status: _.get(item, 'cost.isActive', ''),
      }));
      commit('setItems', {
        label: 'dataServices',
        value: serviceMapper,
        isLoading: false,
      });
      return serviceResponse?.length;
    } catch {
      commit('setItems', {
        label: 'dataServices',
        value: [],
        isLoading: false,
      });
      return 0;
    }
  },

  // Get Data Service By Id
  async resolveGetServiceById({ state, commit }, assurance) {
    commit('setForm', {
      ...state.form,
      assurance: assurance,
      cost: [
        {
          assurance: 'umum',
          medical_service: {
            staff: 0,
            specialize: 0,
            nurse: 0,
            midwife: 0,
            psychologist: 0,
            nutritionists: 0,
            health_analyst: 0,
            radiographer: 0,
          },
          additional_material_price: 0,
          additional_tool_price: 0,
          consumable_material: [],
          consumable_tools: [],
          unit_load: 0,
          margin: 0,
          admin_load: 0,
        },
        {
          assurance: 'bpjs',
          medical_service: {
            staff: 0,
            specialize: 0,
            nurse: 0,
            midwife: 0,
            psychologist: 0,
            nutritionists: 0,
            health_analyst: 0,
            radiographer: 0,
          },
          additional_material_price: 0,
          additional_tool_price: 0,
          consumable_material: [],
          consumable_tools: [],
          unit_load: 0,
          margin: 0,
          admin_load: 0,
        },
        {
          assurance: 'asuransi',
          medical_service: {
            staff: 0,
            specialize: 0,
            nurse: 0,
            midwife: 0,
            psychologist: 0,
            nutritionists: 0,
            health_analyst: 0,
            radiographer: 0,
          },
          additional_material_price: 0,
          additional_tool_price: 0,
          consumable_material: [],
          consumable_tools: [],
          unit_load: 0,
          margin: 0,
          admin_load: 0,
        },
      ],
    });
    const responseGetServiceById = await getServiceById(state.form.id);
    const tab = responseGetServiceById.cost.findIndex(
      arr => arr.assurance === assurance.toLowerCase(),
    );
    try {
      commit('setForm', { ...responseGetServiceById, assurance: assurance });
      const payload = {
        tab: tab,
        medicalTools: responseGetServiceById.cost[tab].consumable_tools.map(
          item => {
            return {
              quantity: item.quantity,
              id: item.id_drugs._id,
              name: item.id_drugs.detail[0].name,
              unit:
                item.id_drugs.detail[0].packaging?.length === 0
                  ? '-'
                  : item.id_drugs.detail[0].packaging[0].package,
              price: item.id_drugs.detail[0].hna * item.quantity,
            };
          },
        ),
        consumables: responseGetServiceById.cost[tab].consumable_material.map(
          item => {
            return {
              quantity: item.quantity,
              id: item.id_drugs._id,
              name: item.id_drugs.detail[0].name,
              unit:
                item.id_drugs.detail[0].packaging?.length === 0
                  ? '-'
                  : item.id_drugs.detail[0].packaging[0].package,
              price: item.id_drugs.detail[0].hna * item.quantity,
            };
          },
        ),
      };
      commit('setTable', payload);
      return responseGetServiceById;
    } catch (error) {
      return error;
    }
  },

  async resolveGetAllUnit({ commit, state }) {
    commit('setItems', {
      label: 'dataUnit',
      value: [...state.items.dataUnit.data],
      isLoading: true,
    });
    try {
      const unitResponse = await getAllUnit();
      const unitMapper = unitResponse?.data?.map(item => ({
        name: item.name,
        id: item._id,
      }));
      commit('setItems', {
        label: 'dataUnit',
        value: unitMapper,
        isLoading: false,
      });
      return unitMapper;
    } catch (error) {
      commit('setItems', {
        label: 'dataUnit',
        value: [],
        isLoading: false,
      });
      return error;
    }
  },

  async resolveGetAHP({ commit, state }, search) {
    commit('setItems', {
      label: 'dataAHP',
      value: [...state.items.dataAHP.data],
      isLoading: true,
    });
    try {
      const ahpResponse = await getAllDrugs({
        itemCount: 20,
        keyword: search,
        category: 'Alat Kesehatan',
      });
      const ahpMapper = ahpResponse?.data?.data?.map(item => {
        let defaultPackage = '';
        item.detail.packaging.find(v => {
          if (v.isDefault === true) {
            defaultPackage = v.package;
          }
        });
        return {
          id: item._id,
          name: item.detail.name,
          hpp: item.detail.hpp,
          hna: item.detail.hna,
          packaging: item.detail.packaging.length !== 0 ? defaultPackage : '-',
        };
      });
      commit('setItems', {
        label: 'dataAHP',
        value: ahpMapper,
        isLoading: false,
      });
      return ahpMapper;
    } catch (error) {
      commit('setItems', {
        label: 'dataAHP',
        value: [],
        isLoading: false,
      });
      return error;
    }
  },

  async resolveGetBHP({ commit, state }, search) {
    commit('setItems', {
      label: 'dataBHP',
      value: [...state.items.dataBHP.data],
      isLoading: true,
    });
    try {
      const bhpResponse = await getAllDrugs({
        itemCount: 20,
        keyword: search,
        category: 'BHP',
      });
      const bhpMapper = bhpResponse?.data?.data?.map(item => {
        let defaultPackage = '';

        item.detail.packaging.find(v => {
          if (v.isDefault === true) {
            defaultPackage = v.package;
          }
        });
        return {
          id: item._id,
          name: item.detail.name,
          hpp: item.detail.hpp,
          hna: item.detail.hna,
          strength: item.detail.strength,
          strength_unit: item.detail.strength_unit,
          packaging: item.detail.packaging.length !== 0 ? defaultPackage : '-',
        };
      });
      commit('setItems', {
        label: 'dataBHP',
        value: bhpMapper,
        isLoading: false,
      });
      return bhpMapper;
    } catch (error) {
      commit('setItems', {
        label: 'dataBHP',
        value: [],
        isLoading: false,
      });
      return error;
    }
  },
  // End Get Data

  // Post Data
  async resolvePostServices({ state, commit, dispatch }) {
    const payload = {
      ...state.form,
      unit: state.form.unit.map(item => item.id),
      cost: state.form.cost
        .filter(
          item =>
            item.unit_load !== 0 ||
            item.margin !== 0 ||
            !Object.values(item.medical_service).every(value => value === 0),
        )
        .map(val => {
          return {
            ...val,
            additional_material_price: val.add_material_price
              ? val.additional_material_price
              : 0,
            additional_tool_price: val.add_tool_price
              ? val.additional_tool_price
              : 0,
            consumable_material: val.consumable_material?.map(val => {
              return {
                id_drugs: val.id,
                quantity: val.quantity,
              };
            }),
            consumable_tools: val.consumable_tools?.map(val => {
              return {
                id_drugs: val.id,
                quantity: val.quantity,
              };
            }),
          };
        }),
    };
    delete payload.id;

    try {
      const postServiceRes = await postServices(payload);
      if (postServiceRes.status === 200) {
        commit('setDialog', false);
        commit('clearForm');
        await dispatch('resolveGetAllServices', {
          ...state.dashboard.filter,
        });
      }
      return postServiceRes.status;
    } catch (error) {
      return error;
    }
  },
  // End Post Data

  // Put Data
  async resolvePutService({ state, commit, dispatch }, item) {
    const payload = {
      ...state.form,
      assurance: item.assurance.toLowerCase(),
      unit: state.form.unit.map(item => item._id || item.id),
      cost: [
        {
          ...state.form.cost[item.index],
          assurance: item.assurance.toLowerCase(),
          consumable_material: state.form.cost[
            item.index
          ].consumable_material.map(val => {
            return {
              id_drugs: val.id,
              quantity: val.quantity,
            };
          }),
          consumable_tools: state.form.cost[item.index].consumable_tools.map(
            val => {
              return {
                id_drugs: val.id,
                quantity: val.quantity,
              };
            },
          ),
        },
      ],
    };
    delete payload.id;

    try {
      const putServiceRes = await putService(
        state.form._id,
        item.assurance,
        payload,
      );
      if (putServiceRes.status === 200) {
        commit('setDialog', false);
        commit('clearForm');
        await dispatch('resolveGetAllServices', {
          ...state.dashboard.filter,
        });
      }
      return putServiceRes.status;
    } catch (error) {
      return error;
    }
  },

  async resolvePutStatusServices(_, { id, isActive, assurance }) {
    try {
      await putStatusServices({ id, isActive, assurance });
    } catch (error) {
      return error;
    }
  },

  // Delete Data
  async resolveDeleteServices(_, payload) {
    try {
      await deleteServices(payload);
    } catch (error) {
      const err = error;
      throw err;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
