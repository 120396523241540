<template>
  <v-card class="history" flat>
    <v-card-title class="history-title grey--text py-xl-2 py-lg-0 pt-lg-1 px-6">
      {{ data.poly }}
    </v-card-title>
    <v-expansion-panels v-model="expand" accordion flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="history-time py-0">
          {{ data.date }} pada {{ data.time }} WIB
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-3">
          <section class="history-data">
            <template v-if="!data.isGeneral">
              <label>Diagnosa</label>
              <div>
                <ul v-if="data?.diagnoses?.filter(i => i).length > 0">
                  <li
                    v-for="(item, index) in data?.diagnoses?.filter(i => i)"
                    :key="`list-${index}`"
                  >
                    {{ item }}
                  </li>
                </ul>
                <div v-else>-</div>
              </div>
            </template>
            <template v-else>
              <label>Diagnosa (ICD-10)</label>
              <div>
                <ul v-if="data?.diagnoses?.filter(i => i).length > 0">
                  <li
                    v-for="(item, index) in data?.diagnoses?.filter(i => i)"
                    :key="`list-${index}`"
                  >
                    <span v-if="item.text && item.code">
                      {{ item.text }} ({{ item.code }} {{ item.description }})
                    </span>
                    <span v-else-if="item.code">
                      ({{ item.code }} {{ item.description }})
                    </span>
                    <span v-else-if="item.text">
                      {{ item.text }}
                    </span>
                  </li>
                </ul>
                <div v-else>-</div>
              </div>
            </template>
            <label>Terapi (Obat)</label>
            <div>
              <ul>
                <li
                  v-for="(item, index) in data?.therapies"
                  :key="`listTherapy-${index}`"
                >
                  <label>
                    {{ item.label }}
                  </label>
                  <br />
                  {{ item.drug }}
                </li>
              </ul>
              <div v-if="data?.therapies.length < 1">-</div>
            </div>
            <template v-if="!data.isGeneral">
              <label>Nama Dokter</label>
              <p>{{ data.doctor }}</p>
              <label>Nama Perawat</label>
              <p>{{ data.nurse }}</p>
            </template>
            <template v-else>
              <label>Nama Petugas Kesehatan</label>
              <p>{{ data.doctor || data.nurse || '-' }}</p>
            </template>
          </section>
          <template v-if="!data.isGeneral">
            <v-divider class="my-5" />
            <section class="history-data">
              <label>IMT</label>
              <div :class="`history-chip-${data.imt?.color}`">
                {{ data.imt?.text }}
                <div v-if="!data.imt">-</div>
              </div>
              <label>Skrining Gizi</label>
              <div :class="`history-chip-${data.nutritionScreening?.color}`">
                {{ data.nutritionScreening?.text }}
                <div v-if="!data.nutritionScreening">-</div>
              </div>
            </section>
            <v-divider class="my-5" />
            <section class="history-data">
              <label>Risiko Jatuh</label>
              <div>
                <div :class="`history-chip-${data.fallRisk?.color}`">
                  {{ data.fallRisk?.text }}
                  <div v-if="!data.fallRisk">-</div>
                </div>
              </div>
            </section>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
export default {
  name: 'HistoryCard',
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      expand: 0,
    };
  },
  mounted() {
    this.expand = this.data.index ? undefined : 0;
  },
};
</script>

<style lang="scss" scoped>
@mixin result-chip($fill, $outline) {
  background-color: $fill;
  border: solid $outline 1.5px;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 3px;
  color: $outline !important;
  font-weight: 450;
  padding: 0.3rem 0.5rem;
  width: max-content;
  max-height: 2rem;
  font-size: $font-standard-size !important;
}
$text-color: (
  'label': #9e9e9e,
  'data': #242424,
);
$font-standard-size: 0.75vw;

.history {
  width: 100% !important;
  border: 0.1em solid #c2c2c2;

  #{&}-title {
    font-size: 0.8vw !important;
  }
  #{&}-time {
    font-size: $font-standard-size !important;
    font-weight: 600;
  }
  #{&}-data {
    align-items: baseline;
    padding: 0.2rem 0.2rem;
    text-align: start;

    label {
      color: map-get($text-color, 'label');
      font-size: $font-standard-size;
      letter-spacing: 0.001vw;
    }

    p {
      padding: 0;
      margin: 0;
      font-size: $font-standard-size;
      text-align: start;
      margin-top: 0.3vw;
      color: map-get($text-color, 'data');
      margin-bottom: 0.7vw;
    }

    div,
    ul,
    li {
      font-size: $font-standard-size;
      text-align: start;
      margin-top: 0.3vw;
      margin-bottom: 0.7vw;
      color: map-get($text-color, 'data');
    }
  }

  #{&}-chip-green {
    @include result-chip(#e5fff0, #2d965a);
  }
  #{&}-chip-yellow {
    @include result-chip(#fff9e5, #e5a82e);
  }
  #{&}-chip-light-yellow {
    @include result-chip(#ffffff, #d1d600);
  }
  #{&}-chip-red {
    @include result-chip(#fff2f2, #eb4747);
  }
}
</style>
