<template>
  <v-row>
    <v-expansion-panels
      v-for="(income, index) in dataIncome"
      accordion
      multiple
      flat
      class="pt-3 pl-5"
      :key="'labelIncome-' + index"
      :readonly="income.type == 'Lain-lain'"
    >
      <v-expansion-panel :key="'dataIncome-' + index">
        <v-expansion-panel-header
          :hide-actions="income.type == 'Lain-lain'"
          class="font-weight-bold primary--text py-0 header-box"
        >
          <v-col>Pendapatan {{ income.type }}</v-col>
          <v-col class="text-right">{{ currency(income.total || 0) }}</v-col>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="content-background pb-0">
          <v-expansion-panels accordion flat>
            <v-expansion-panel
              v-for="(detail, idx) in income.detail"
              :key="'detailIncome-' + idx"
            >
              <v-expansion-panel-header
                hide-actions
                class="font-weight-bold py-0 pr-0 content-2"
              >
                <v-col> Pendapatan {{ detail.unit }} </v-col>
                <v-col class="text-right">{{
                  currency(detail.totalAmount || 0)
                }}</v-col>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                class="text-left font-weight-bold grey--text content-background"
                v-if="income.type !== 'Lain-lain'"
              >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import formatMixin from '@/mixin/formatMixin';

export default {
  mixins: [formatMixin],
  props: {
    dataIncome: {
      type: Array,
      default: null,
    },
  },
  data: () => ({}),
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
  },
};
</script>

<style lang="scss" scoped>
.header-box {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 30px;
}
.content-background {
  background-color: #d9edff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.content-2 {
  background-color: #d9edff;
  min-height: 30px;
}
::v-deep {
  .v-expansion-panel-header--active {
    background-color: #d9edff;
    min-height: 30px;
    border: none;
    border-bottom-left-radius: initial;
    border-bottom-right-radius: initial;
  }
}
</style>
