import Constant from '@/const';
import axios from 'axios';

const getInformConsentList = async idEMR => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/document/inform-consent/medtreatment/${idEMR}`),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getInformConsentList;
