<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-row>
        <v-col cols="3" class="text-sm-left ma-0 pa-0">
          <h2>Informasi Stok</h2>
        </v-col>
        <v-col cols="9" class="filter">
          <v-menu
            offset-y
            :close-on-content-click="false"
            v-model="datepicker"
            min-width="auto"
            v-if="tabs === 1"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-if="tabs === 1"
                class="mr-4 pt-2 input-filter date"
                dense
                flat
                v-on="on"
                v-bind="attrs"
                readonly
                append-icon="mdi-chevron-down"
                placeholder="Rentang Tanggal"
                v-model="date.formatted"
                :value="date.formatted"
                single-line
                clearable
                @change="showAllHistoryStocks()"
                @click:clear="clearDate()"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              :max="today"
              v-if="tabs === 1"
              multiple
              v-model="selectedDate"
              @input="changedate"
            ></v-date-picker>
          </v-menu>
          <v-autocomplete
            v-if="tabs === 0"
            clearable
            class="mr-8 pt-2 input-filter"
            dense
            flat
            append-icon="mdi-chevron-down"
            placeholder="Kategori"
            v-model="filter.category"
            :items="resource.category"
            @change="
              showAllStocks(true);
              showTotalPrice();
            "
            item-text="name"
            return-object
            single-line
            hide-details
          ></v-autocomplete>
          <v-autocomplete
            v-if="tabs === 0"
            class="mr-8 pt-2 input-filter"
            dense
            clearable
            flat
            :items="resource.status"
            @change="
              showAllStocks(true);
              showTotalPrice();
            "
            append-icon="mdi-chevron-down"
            placeholder="Status"
            v-model="filter.status"
            single-line
            hide-details
          ></v-autocomplete>
          <v-autocomplete
            v-if="tabs === 1"
            class="mr-4 pt-2 input-filter"
            dense
            flat
            @change="showAllHistoryStocks(true)"
            append-icon="mdi-chevron-down"
            placeholder="Kategori"
            :items="resource.category"
            v-model="filter.category"
            item-text="name"
            return-object
            single-line
            clearable
            hide-details
          ></v-autocomplete>
          <v-autocomplete
            class="mr-8 pt-2 input-filter"
            dense
            flat
            append-icon="mdi-chevron-down"
            placeholder="Unit"
            return-object
            item-text="name"
            v-model="filter.unit"
            :items="resource.unit"
            clearable
            single-line
            hide-details
          ></v-autocomplete>
          <!-- @input="showAllStocks(true)" -->
          <v-btn color="grey" class="mr-4" icon text @click="refresh()">
            <v-icon :class="{ refresh: isRefresh }">mdi-autorenew </v-icon>
          </v-btn>
          <v-combobox
            append-icon=""
            :items="resource.itemSuggestions"
            :loading="searchLoading"
            @keyup.enter="
              showAllStocks();
              showTotalPrice();
            "
            class="input-search mr-3"
            background-color="grey lighten-3"
            placeholder="Cari barang di sini.."
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
            :search-input.sync="search"
            @change="
              showAllStocks();
              showTotalPrice();
            "
          >
          </v-combobox>
          <v-btn
            v-if="!tabs"
            @click="exportStock()"
            color="primary"
            class="font-weight-bold"
            outlined
            :loading="isExportLoading"
            >Export</v-btn
          >
        </v-col>
      </v-row>
    </v-toolbar>
    <v-main>
      <v-container fluid>
        <v-tabs v-model="tabs" grow centered>
          <v-tab class="font-weight-bold text-capitalize">
            Informasi Detail Stok
          </v-tab>
          <v-tab class="font-weight-bold text-capitalize"> History Stok </v-tab>
        </v-tabs>
      </v-container>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <!-- :search="search" -->
          <v-data-table
            fixed-header
            :headers="table.detail.headers"
            :items="table.detail.items"
            :page.sync="pagination.detail.page"
            :height="height"
            class="detail-table mb-4"
            item-key="number"
            :loading="isDetailLoading"
            hide-default-footer
          >
            <template v-slot:item="{ item, index }">
              <tr :key="index">
                <td
                  class="text-sm-left ma-0"
                  :class="{ 'red--text': item.stock_remain < item.stock_min }"
                >
                  {{
                    (pagination.detail.page - 1) *
                      pagination.detail.itemsPerPage +
                      index +
                      1
                  }}
                </td>
                <td
                  class="text-sm-left pa-0 ma-0"
                  :class="{ 'red--text': item.stock_remain < item.stock_min }"
                >
                  {{ item.displayName }}
                </td>
                <td
                  class="text-sm-left pa-0 ma-0"
                  :class="{ 'red--text': item.stock_remain < item.stock_min }"
                >
                  {{ item.supplier }}
                </td>
                <td
                  class="pa-0 ma-0"
                  :class="{ 'red--text': item.stock_remain < item.stock_min }"
                >
                  {{ item.expiry_date }}
                </td>
                <td
                  class="pa-0 ma-0"
                  :class="{ 'red--text': item.stock_remain < item.stock_min }"
                >
                  {{ item.batch_number }}
                </td>
                <td
                  class="pa-0 ma-0"
                  :class="{ 'red--text': item.stock_remain < item.stock_min }"
                >
                  {{ item.stock_min }}
                </td>
                <td
                  class="pa-0 ma-0"
                  :class="{ 'red--text': item.stock_remain < item.stock_min }"
                >
                  {{ item.stock_remain }}
                </td>
                <td
                  class="text-sm-right pa-0 ma-0 pr-3"
                  :class="{ 'red--text': item.stock_remain < item.stock_min }"
                >
                  {{ item.sell_price }}
                </td>
                <td
                  class="text-sm-right pa-0 ma-0 pr-3"
                  :class="{ 'red--text': item.stock_remain < item.stock_min }"
                >
                  {{ item.inventory_price }}
                </td>
                <td class="text-sm-left pa-0 ma-0 pl-5">
                  <v-chip
                    small
                    caption
                    class="text-sm-center status-chip"
                    :class="{
                      worth: item.feasibility_status === 'Layak Pakai',
                      almost: item.feasibility_status === 'Hampir Expired',
                      expired: item.feasibility_status === 'Expired',
                    }"
                    >{{ item.feasibility_status }}</v-chip
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="d-flex justify-space-between pr-15">
            <v-pagination
              v-model="pagination.detail.page"
              :length="pagination.detail.itemPages"
              :total-visible="7"
              @input="showAllStocks(false, true)"
              prev-icon="mdi-chevron-left"
              next-icon="mdi-chevron-right"
            ></v-pagination>
            <div class="total-price">
              <label for="total-price" class="font-weight-bold subtitle-2"
                >Total Persediaan Barang {{ currency(totalPrice || 0) }}</label
              >
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            fixed-header
            :headers="table.history.headers"
            :items="table.history.items"
            :page.sync="pagination.history.page"
            :loading="isHistoryLoading"
            :height="height"
            class="detail-table px-2 mb-4"
            item-key="number"
            hide-default-footer
          >
            <template v-slot:no-data
              ><h3 class="grey--text">
                Tidak ada data pada tanggal yang dipilih
              </h3></template
            >
            <template v-slot:item="{ item, index }">
              <tr :key="index" class="mb-3">
                <td class="text-sm-left ma-0">
                  {{
                    (pagination.history.page - 1) *
                      pagination.history.itemsPerPage +
                      index +
                      1
                  }}
                </td>
                <td class="text-sm-left pa-0 ma-0">
                  {{ item.name }}
                </td>
                <td class="text-sm-left pa-0 pl-2 ma-0">
                  {{ item.supplier }}
                </td>
                <td class="pa-0 ma-0">
                  {{ item.expiry_date }}
                </td>
                <td class="pa-0 ma-0">
                  {{ item.batch }}
                </td>
                <td class="pa-0 ma-0 text-sm-left">
                  <span>{{ item.transaction_date.date }}</span> <br />
                  <span class="grey--text">{{
                    item.transaction_date.time
                  }}</span>
                </td>
                <td class="pa-0 ma-0 text-sm-left">
                  <span>{{ item.action }}</span> <br />
                  <!-- <span class="grey--text"
                    >Consume by {{ item.unit_to.unit }}</span
                  > -->
                </td>
                <td class="pa-0 ma-0">
                  {{ item.early_stock }}
                </td>
                <td class="pa-0 ma-0">
                  {{ item.stock_in }}
                </td>
                <td class="text-sm-right pa-0 ma-0 pr-3">
                  {{ item.stock_out }}
                </td>
                <td class="text-sm-right pa-0 ma-0 pr-3">
                  {{ item.stock_remain }}
                </td>
                <td class="text-sm-left pa-0 pt-3 mb-3 pl-3">
                  <span>{{ item.unit_from.unit }}</span> <br />
                  <span class="grey--text"
                    >Pengirim : {{ item.unit_from.sender }}</span
                  >
                </td>
                <td class="text-sm-left pa-0 pt-3 mb-3 pl-3">
                  <span>{{ item.unit_to.unit }}</span> <br />
                  <span class="grey--text"
                    >Penerima : {{ item.unit_to.sender }}</span
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
            <v-pagination
              v-model="pagination.history.page"
              :length="pagination.history.itemPages"
              prev-icon="mdi-chevron-left"
              next-icon="mdi-chevron-right"
              total-visible="7"
              @input="showAllHistoryStocks(false, true)"
            ></v-pagination>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-main>
  </v-container>
</template>

<script>
import Constant from '@/const';
import formatMixin from '@/mixin/formatMixin';
import alertMixin from '@/mixin/alertMixin';

import Swal from 'sweetalert2';
const axios = require('axios');
const moment = require('moment');
const _ = require('lodash');

let searchCount = 0;

export default {
  name: 'StockInfo',
  mixins: [formatMixin, alertMixin],
  data() {
    return {
      isRefresh: false,
      totalPrice: 0,
      datepicker: false,
      selectedDate: [],
      today: moment().format('YYYY-MM-DD'),
      search: '',
      isDetailLoading: false,
      isHistoryLoading: false,
      searchLoading: false,
      priceLoading: false,
      tabs: 0,
      page: 1,
      filter: {
        category: '',
        status: '',
        unit: '',
        date: '',
      },
      isExportLoading: false,
      resource: {
        category: [],
        status: ['Layak Pakai', 'Hampir Kadaluarsa', 'Kadaluarsa'],
        unit: [],
        itemSuggestions: [],
        date: '',
      },
      pagination: {
        detail: {
          itemsPerPage: 10,
          itemPages: 0,
          page: 1,
        },
        history: {
          itemsPerPage: 10,
          itemPages: 0,
          page: 1,
        },
      },
      date: {
        raw: [],
        formatted: '',
      },
      table: {
        detail: {
          headers: [
            {
              text: 'No',
              align: 'start',
              sortable: false,
              value: 'number',
              width: '2%',
              class: 'pr-0 black--text',
            },
            {
              text: 'Nama Barang',
              align: 'center',
              class: 'pa-0 ma-0 black--text',
              sortable: false,
              value: 'displayName',
              width: '15%',
            },
            {
              text: 'Supplier',
              align: 'start',
              class: 'pa-0 ma-0 black--text',
              sortable: false,
              value: 'supplier',
              width: '10%',
            },
            {
              text: 'Tgl Kadaluarsa',
              class: 'pa-0 ma-0 black--text',
              align: 'center',
              sortable: false,
              value: 'expiry_date',
              width: '11%',
            },
            {
              text: 'Nomor Batch',
              class: 'pa-0 ma-0 black--text',
              align: 'center',
              sortable: false,
              value: 'batch_number',
              width: '8%',
            },
            {
              text: 'Min Stok',
              class: 'pa-0 ma-0 black--text',
              align: 'center',
              sortable: true,
              value: 'stock_min',
              width: '8%',
            },
            {
              text: 'Sisa Stok',
              class: 'pa-0 ma-0 black--text',
              align: 'center',
              sortable: true,
              value: 'stock_remain',
              width: '8%',
            },
            {
              text: 'Harga Jual',
              align: 'right',
              class: 'pa-0 ma-0 pr-3 black--text',
              sortable: false,
              value: 'sell_price',
              width: '12%',
            },
            {
              text: 'Harga Persediaan',
              align: 'right',
              class: 'pa-0 ma-0 black--text pr-3',
              sortable: false,
              value: 'inventory_price',
              width: '12%',
            },
            {
              text: 'Status Kelayakan',
              class: 'pa-0 pl-5 ma-0 black--text',
              width: '19%',
              align: 'left',
              sortable: false,
              value: 'feasibility_status',
            },
          ],
          items: [],
        },
        history: {
          headers: [
            {
              text: 'No',
              align: 'start',
              sortable: false,
              value: 'number',
              width: '5%',
              class: 'pr-0 black--text',
            },
            {
              text: 'Nama Barang',
              align: 'center',
              class: 'pa-0 ma-0 black--text',
              sortable: false,
              value: 'name',
              width: '10%',
            },
            {
              text: 'Supplier',
              align: 'start',
              class: 'pa-0 pl-2 ma-0 black--text',
              sortable: false,
              value: 'supplier',
              width: '6%',
            },
            {
              text: 'Tgl Kadaluarsa',
              class: 'pa-0 ma-0 black--text',
              align: 'center',
              sortable: false,
              value: 'expiry_date',
              width: '7%',
            },
            {
              text: 'Batch',
              class: 'pa-0 ma-0 black--text',
              align: 'center',
              sortable: false,
              value: 'batch',
              width: '8%',
            },
            {
              text: 'Tgl Transaksi',
              class: 'pa-0 ma-0 black--text',
              align: 'center',
              sortable: false,
              value: 'transaction_date',
              width: '5%',
            },
            {
              text: 'Jenis Transaksi',
              class: 'pa-0 ma-0 black--text',
              align: 'center',
              sortable: false,
              value: 'action',
              width: '8%',
            },
            {
              text: 'Sisa Awal',
              class: 'pa-0 ma-0 black--text',
              align: 'center',
              sortable: false,
              value: 'early_stock',
              width: '8%',
            },
            {
              text: 'Stok Masuk',
              align: 'center',
              class: 'pa-0 ma-0 black--text',
              sortable: false,
              value: 'stock_in',
              width: '7%',
            },
            {
              text: 'Stok Keluar',
              align: 'center',
              class: 'pa-0 ma-0 black--text',
              sortable: false,
              value: 'stock_out',
              width: '7%',
            },
            {
              text: 'Sisa Stok',
              align: 'center',
              class: 'pa-0 ma-0 black--text',
              sortable: true,
              value: 'stock_remain',
              width: '7%',
            },
            {
              text: 'Asal Unit',
              class: 'pa-0 ma-0 black--text',
              width: '10%',
              align: 'center',
              sortable: false,
              value: 'unit_from',
            },
            {
              text: 'Ke Unit',
              class: 'pa-0 ma-0 black--text',
              width: '10%',
              align: 'center',
              sortable: false,
              value: 'unit_to',
            },
          ],
          items: [],
        },
      },
    };
  },
  computed: {
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '53vh';
          break;
        case 'lg':
          height = '58vh';
          break;
        case 'xl':
          height = '63vh';
          break;
      }
      return height;
    },
  },
  watch: {
    search() {
      this.pagination.detail.page = 1;
      this.pagination.history.page = 1;
      this.searchData(this);
    },
    date() {
      this.showAllHistoryStocks();
    },
    'filter.unit'(val) {
      // show all data when filter unit is cleared
      if (!val && this.tabs === 0) {
        this.showAllStocks(true, true);
        this.showTotalPrice(true);
      }
      if (val && this.tabs === 0) {
        this.showAllStocks(true);
        this.showTotalPrice();
      }
      if (!val && this.tabs === 1) this.showAllHistoryStocks(true, true);
      if (val && this.tabs === 1) this.showAllHistoryStocks(true);
    },
  },
  mounted() {
    this.changedate([moment().format('YYYY-MM-DD')]);
    this.showAllStocks();
    this.showTotalPrice();
    this.getCategory();
    this.getAllUnit();
    this.showAllHistoryStocks();
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      if (v.tabs) {
        v.showAllHistoryStocks();
        v.getSuggestions();
      } else {
        if (searchCount > 0) {
          v.getSuggestions();
        }
        searchCount += 1;
      }
    }, 650),
    changedate(data) {
      // if user selected 2 date
      if (data.length >= 2) {
        const slicedDate = data.slice(0, 2);
        slicedDate.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });

        const formateddate = [];
        for (let i = 0; i < slicedDate.length; i++) {
          formateddate.push(
            moment(slicedDate[i], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          );
        }
        this.selectedDate = slicedDate;
        this.date = {
          raw: slicedDate,
          formatted: formateddate.join(' s/d '),
        };
        this.datepicker = false;
      }

      // if user selected 1 date
      if (data.length === 1) {
        this.date = {
          raw: [data[0], ''],
          formatted: moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        };
      }
    },
    async exportStock() {
      try {
        this.isExportLoading = true;
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/stock/export?unit_name=${
              this.filter.unit ? this.filter.unit.id : ''
            }&category=${
              this.filter.category ? this.filter.category.name : ''
            }&status_filter=${this.filter.status ? this.filter.status : ''}`,
          ),
        );
        if (res.status === 200) {
          this.showFillSuccess('Export Berhasil');
          this.isExportLoading = false;
          const { url } = res.config;
          window.open(url, '_blank');
        }
      } catch (error) {
        if (error) {
          this.isExportLoading = false;
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat mengexport dokumen',
          });
        }
      }
    },
    feasibilityStatus(data) {
      let status = '';
      if (data == 'Hampir Kadaluarsa') {
        status = 'Hampir Expired';
      } else if (data == 'Kadaluarsa') {
        status = 'Expired';
      } else {
        status = data;
      }
      return status;
    },
    getAllUnit() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=&itemCount=&search=&sort=name',
          ),
        )
        .then(response => {
          const { data } = response.data;
          this.resource.unit = data
            .filter(
              x => x.detail.installation !== 'Administrasi dan Tata Usaha',
            )
            .map(unit => {
              return { name: unit.name, id: unit._id };
            });
          this.filter.unit = this.resource.unit.find(
            v => v.id == this.$store.getters.userLoggedIn.unit_id,
          );
        })
        .catch(() => {
          this.resource.unit = [];
        });
    },
    async getSuggestions() {
      this.resource.itemSuggestions = [];
      const keyword = this.search || '';
      this.searchLoading = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/stock/all/suggestion?page=1&search=${keyword}&itemCount=20`,
          ),
        );
        const { data } = response.data;
        this.resource.itemSuggestions = data
          .filter(arr => arr.drug.length > 0 || arr.good.length > 0)
          .map(item => {
            return item.display_name;
          });
      } catch (error) {
        if (error) {
          this.resource.itemSuggestions = [];
        }
      } finally {
        this.searchLoading = false;
      }
    },
    refresh() {
      this.isRefresh = true;
      if (this.tabs === 0) {
        this.showAllStocks();
      }
      if (this.tabs === 1) {
        this.showAllHistoryStocks();
      }
    },
    showAllStocks(isFilter = false, isShowAll = false) {
      let id_unit;
      if (isShowAll) {
        id_unit = '';
      } else {
        id_unit = this.$store.getters.userLoggedIn.unit_id;
      }
      if (isFilter) {
        this.pagination.detail.page = 1;
      }
      const page = this.pagination.detail.page;
      const itemCount = this.pagination.detail.itemsPerPage;
      const keyword = this.search || '';

      this.isDetailLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all?page=${page}&search=${keyword}&itemCount=${itemCount}&category=${
              this.filter.category ? this.filter.category.name : ''
            }&status_filter=${
              this.filter.status ? this.filter.status : ''
            }&unit_name=${this.filter.unit ? this.filter.unit.id : id_unit}`,
          ),
        )
        .then(res => {
          const { data } = res.data;
          const { length: dataLength } = res.data;
          this.pagination.detail.itemPages = Math.ceil(
            dataLength / this.pagination.detail.itemsPerPage,
          );
          this.table.detail.items = data.stock.map((arr, idx) => {
            return {
              number: page * idx + 1,
              name: arr.name,
              expiry_date: arr.expiry_date
                ? moment(arr.expiry_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
                : '-',
              displayName: arr.display_name,
              batch_number: arr.batch_number,
              supplier: arr.supplier || '-',
              stock_min: arr.min_stock,
              stock_remain: arr.quantity,
              sell_price: this.currency(arr.sellprice),
              inventory_price: this.currency(arr.hpp),
              feasibility_status: this.feasibilityStatus(
                arr.availability_status,
              ),
            };
          });
          this.isRefresh = false;
          this.isDetailLoading = false;
        })
        .catch(() => {
          this.isRefresh = false;
          this.table.detail.items = [];
          this.isDetailLoading = false;
        })
        .finally(() => {
          this.isRefresh = false;
          this.isDetailLoading = false;
        });
    },
    showAllHistoryStocks(isFilter = false, isShowAll = false) {
      let id_unit;
      if (isShowAll) {
        id_unit = '';
      } else {
        id_unit = this.$store.getters.userLoggedIn.unit_id;
      }
      if (isFilter) {
        this.pagination.history.page = 1;
      }
      this.isHistoryLoading = true;
      const keyword = this.search || '';
      let startDate = '';
      let endDate = '';
      if (this.date.raw.length > 0) {
        startDate = moment(this.date.raw[0]).format('YYYY-MM-DD');
        endDate =
          this.date.raw[1] == ''
            ? moment().format('YYYY-MM-DD')
            : moment(this.date.raw[1]).format('YYYY-MM-DD');
      }
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/history/all?page=${
              this.pagination.history.page
            }&itemCount=${this.pagination.history.itemsPerPage}&category=${
              this.filter.category ? this.filter.category.name : ''
            }&unit_name=${
              this.filter.unit ? this.filter.unit.id : id_unit
            }&startDate=${startDate}&endDate=${endDate}&search=${keyword}`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          const { length: dataLength } = response.data;
          this.pagination.history.itemPages = Math.ceil(
            dataLength / this.pagination.history.itemsPerPage,
          );
          this.table.history.items = data.map((arr, index) => {
            let defaultPackage = '-';
            if (arr.packaging.length > 0) {
              let packageUnit = arr.packaging.find(item => item.isDefault);
              if (packageUnit) defaultPackage = packageUnit.package_unit;
            }
            return {
              no: index + 1,
              name: arr.display_name,
              supplier: arr.supplier,
              action: arr.action,
              expiry_date: arr.expiry_date,
              batch: arr.batch_number,
              transaction_date: {
                date: moment(arr.timestamps.created_at).format('DD/MM/YYYY'),
                time: moment(arr.timestamps.created_at).format('HH:mm'),
              },
              early_stock: `${arr.detail.old_quantity} ${defaultPackage}`,
              stock_remain: `${arr.detail.new_quantity} ${defaultPackage}`,
              stock_in: `${arr.detail.in} ${defaultPackage}`,
              stock_out: `${arr.detail.out} ${defaultPackage}`,
              unit_from: {
                unit: arr.unit_from || '-',
                sender: arr.staff_from || '-',
              },
              unit_to: {
                unit: arr.unit_to || '-',
                sender: arr.staff_to || '-',
              },
            };
          });
        })
        .catch(() => {
          this.table.history.items = [];
          this.pagination.history.itemPages = 0;
        })
        .finally(() => {
          this.isHistoryLoading = false;
        });
    },
    showTotalPrice(isShowAll = false) {
      this.priceLoading = true;
      let id_unit;
      if (isShowAll) {
        id_unit = '';
      } else {
        id_unit = this.$store.getters.userLoggedIn.unit_id;
      }
      const keyword = this.search || '';
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/total?search=${keyword}&category=${
              this.filter.category ? this.filter.category.name : ''
            }&status_filter=${
              this.filter.status ? this.filter.status : ''
            }&unit_name=${this.filter.unit ? this.filter.unit.id : id_unit}`,
          ),
        )
        .then(response => {
          this.totalPrice = response.data.data;
        })
        .catch(() => {
          this.totalPrice = 0;
        })
        .finally(() => {
          this.priceLoading = false;
        });
    },
    clearDate() {
      this.date.raw = [];
      this.selectedDate = [];
      this.showAllHistoryStocks();
    },
    getCategory() {
      axios
        .get(Constant.apiUrl.concat('/master/category/'))
        .then(res => {
          const { data } = res.data;
          this.resource.category = data.map(category => {
            return category;
          });
        })
        .catch(() => (this.resource.category = []));
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.total-price {
  padding: 0.8rem;
  display: flex;
  align-items: center;
  background-color: #c4e3f5;
}

.detail-table {
  // max-height: 420px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
    background-color: #a3a3a3;
  }
  &::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: black;
  }
}

.filter {
  display: flex;
}

.input-filter {
  width: 10px;
  &.date {
    min-width: 0px;
    width: 100px;
  }
}

.input-search {
  width: 200px;
  min-width: 0px;
}

.status-chip {
  min-width: 100px;
  font-weight: 400;
  font-size: 14px;
  justify-content: center;
  border-radius: 2px;

  &.expired {
    background-color: #fff2f2 !important;
    border: 0.5px solid #eb4747 !important;
    color: #eb4747 !important;
  }
  &.worth {
    background-color: #e5fff0 !important;
    border: 0.5px solid #2d965a !important;
    color: #2d965a !important;
  }
  &.almost {
    background-color: #fff9e5 !important;
    border: 0.5px solid #f2c94c !important;
    color: #f2c94c !important;
  }
}

.refresh {
  animation: rotation 0.23s infinite;
}
</style>
