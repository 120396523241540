import Constant from '../../const';
const axios = require('axios');

const getActivation = async (type, no) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/integration/pcare/patient/${type}/${no}`),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getActivation;
