const state = {
  head: '',
  getLegJoint: '',
  ear: '',
  eye: '',
  nose: '',
  mouth: '',
  neck: '',
  hair: '',
  lips: '',
  teeth: '',
  tongue: '',
  palate: '',
  throat: '',
  tonsil: '',
  chest: '',
  breast: '',
  back: '',
  stomach: '',
  genitalia: '',
  anus: '',
  upper_arm: '',
  forearm: '',
  finger: '',
  hand_nail: '',
  hand_joint: '',
  upper_limb: '',
  lower_limb: '',
  toe: '',
  toe_nail: '',
  leg_joint: '',
};

const getters = {
  getHead: state => state.head,
  getPhysicalEye: state => state.eye,
  getEar: state => state.ear,
  getNose: state => state.nose,
  getMouth: state => state.mouth,
  getNeck: state => state.neck,
  getHair: state => state.hair,
  getLips: state => state.lips,
  getTeeth: state => state.teeth,
  getTongue: state => state.tongue,
  getPalate: state => state.palate,
  getThroat: state => state.throat,
  getTonsil: state => state.tonsil,
  getChest: state => state.chest,
  getBreast: state => state.breast,
  getBack: state => state.back,
  getStomach: state => state.stomach,
  getGenitalia: state => state.genitalia,
  getAnus: state => state.anus,
  getUpperArm: state => state.upper_arm,
  getForeArm: state => state.forearm,
  getFinger: state => state.finger,
  getHandNail: state => state.hand_nail,
  getHandJoint: state => state.hand_joint,
  getUpperLimb: state => state.upper_limb,
  getLowerLimb: state => state.lower_limb,
  getToe: state => state.toe,
  getToeNail: state => state.toe_nail,
  getLegJoint: state => state.leg_joint,
};

const mutations = {
  clearPhysicalCheckState(state) {
    Object.assign(state, {
      head: '',
      eye: '',
      ear: '',
      nose: '',
      mouth: '',
      neck: '',
      hair: '',
      lips: '',
      teeth: '',
      tongue: '',
      palate: '',
      throat: '',
      tonsil: '',
      chest: '',
      breast: '',
      back: '',
      stomach: '',
      genitalia: '',
      anus: '',
      upper_arm: '',
      forearm: '',
      finger: '',
      hand_nail: '',
      hand_joint: '',
      upper_limb: '',
      lower_limb: '',
      toe: '',
      toe_nail: '',
      leg_joint: '',
    });
  },
  setHead(state, payload) {
    state.head = payload;
  },
  setPhysicalEye(state, payload) {
    state.eye = payload;
  },
  setEar(state, payload) {
    state.ear = payload;
  },
  setNose(state, payload) {
    state.nose = payload;
  },
  setMouth(state, payload) {
    state.mouth = payload;
  },
  setNeck(state, payload) {
    state.neck = payload;
  },
  setHair(state, payload) {
    state.hair = payload;
  },
  setLips(state, payload) {
    state.lips = payload;
  },
  setTeeth(state, payload) {
    state.teeth = payload;
  },
  setTongue(state, payload) {
    state.tongue = payload;
  },
  setPalate(state, payload) {
    state.palate = payload;
  },
  setThroat(state, payload) {
    state.throat = payload;
  },
  setTonsil(state, payload) {
    state.tonsil = payload;
  },
  setChest(state, payload) {
    state.chest = payload;
  },
  setBreast(state, payload) {
    state.breast = payload;
  },
  setBack(state, payload) {
    state.back = payload;
  },
  setStomach(state, payload) {
    state.stomach = payload;
  },
  setGenitalia(state, payload) {
    state.genitalia = payload;
  },
  setAnus(state, payload) {
    state.anus = payload;
  },
  setUpperArm(state, payload) {
    state.upper_arm = payload;
  },
  setForeArm(state, payload) {
    state.forearm = payload;
  },
  setFinger(state, payload) {
    state.finger = payload;
  },
  setHandNail(state, payload) {
    state.hand_nail = payload;
  },
  setHandJoint(state, payload) {
    state.hand_joint = payload;
  },
  setUpperLimb(state, payload) {
    state.upper_limb = payload;
  },
  setLowerLimb(state, payload) {
    state.lower_limb = payload;
  },
  setToe(state, payload) {
    state.toe = payload;
  },
  setToeNail(state, payload) {
    state.toe_nail = payload;
  },
  setLegJoint(state, payload) {
    state.leg_joint = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
