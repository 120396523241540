<template>
  <div class="main-div">
    <main class="main">
      <v-container fluid>
        <v-layout align-start justify-start column fill-width>
          <v-flex class="big-header">
            <v-layout align-space-between justify-start row fill-height>
              <v-flex xs6 align-self-center class="header-text pl-10">
                <h1 class="primary-header">Akuntansi</h1>
                <h3 class="secondary-header">
                  Cek informasi mengenai akuntansi di sini.
                </h3>
              </v-flex>
              <v-flex xs1></v-flex>
              <v-flex xs5>
                <v-img
                  contain
                  class="accounting-image"
                  lazy-src
                  :src="resource.picLocation[0]"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex class="text-submenu">
            <h3 class="text-head py-4">Kategori</h3>
          </v-flex>
          <v-flex class="menu-space">
            <v-layout align-start justify-start row fill-height>
              <template v-for="(item, index) in resource.category">
                <v-flex xs1 class="menu-widget" :key="'accounting-' + index">
                  <v-layout fill-height column v-if="index === 1">
                    <v-card
                      flat
                      rounded="lg"
                      class="logo-place pa-3"
                      @click="journalDialog = true"
                    >
                      <v-img
                        contain
                        lazy-src
                        :src="resource.picLocation[index + 1]"
                      />
                    </v-card>

                    <v-flex class="font-weight-medium">
                      {{ item.text }}
                    </v-flex>
                    <v-dialog v-model="journalDialog" width="300" persistent>
                      <v-card height="199">
                        <v-card-title class="justify-end pa-0">
                          <v-btn icon color="red" @click="journalDialog = false"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </v-card-title>
                        <v-col class="py-0">
                          <h3>Jurnal</h3>
                        </v-col>
                        <v-col>
                          <v-btn
                            outlined
                            color="primary"
                            class="text-none"
                            style="border-width: 2px;"
                            width="230"
                            :to="item.route.journal_list"
                            >Daftar Jurnal</v-btn
                          >
                        </v-col>
                        <v-col>
                          <v-btn
                            outlined
                            color="primary"
                            class="text-none"
                            style="border-width: 2px;"
                            width="230"
                            :to="item.route.journal_post"
                            >Posting Jurnal</v-btn
                          >
                        </v-col>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                  <v-layout fill-height column v-else-if="index === 2">
                    <v-card
                      flat
                      rounded="lg"
                      class="logo-place pa-3"
                      @click="ledgerDialog = true"
                    >
                      <v-img
                        contain
                        lazy-src
                        :src="resource.picLocation[index + 1]"
                      />
                    </v-card>

                    <v-flex class="font-weight-medium">
                      {{ item.text }}
                    </v-flex>
                    <v-dialog v-model="ledgerDialog" width="300" persistent>
                      <v-card height="199">
                        <v-card-title class="justify-end pa-0">
                          <v-btn icon color="red" @click="ledgerDialog = false"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </v-card-title>
                        <v-col class="py-0">
                          <h3>Buku Besar</h3>
                        </v-col>
                        <v-col>
                          <v-btn
                            outlined
                            color="primary"
                            class="text-none"
                            style="border-width: 2px;"
                            width="230"
                            :to="item.route.ledger"
                            >Buku Besar</v-btn
                          >
                        </v-col>
                        <v-col>
                          <v-btn
                            outlined
                            color="primary"
                            class="text-none"
                            style="border-width: 2px;"
                            width="230"
                            :to="item.route.subsidiary_ledger"
                            >Buku Besar Pembantu</v-btn
                          >
                        </v-col>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                  <v-layout fill-height column v-else-if="index === 3">
                    <v-card
                      flat
                      rounded="lg"
                      class="logo-place pa-3"
                      @click="financialDialog = true"
                    >
                      <v-img
                        contain
                        lazy-src
                        :src="resource.picLocation[index + 1]"
                      />
                    </v-card>

                    <v-flex class="font-weight-medium">
                      {{ item.text }}
                    </v-flex>
                    <v-dialog v-model="financialDialog" width="300" persistent>
                      <v-card height="330">
                        <v-card-title class="justify-end pa-0">
                          <v-btn
                            icon
                            color="red"
                            @click="financialDialog = false"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </v-card-title>
                        <v-col class="py-0">
                          <h3>Laporan Keuangan</h3>
                        </v-col>
                        <v-col>
                          <v-btn
                            outlined
                            color="primary"
                            class="text-none"
                            style="border-width: 2px;"
                            width="230"
                            :to="item.route.incomeState"
                            >Laba Rugi</v-btn
                          >
                        </v-col>
                        <v-col>
                          <v-btn
                            outlined
                            color="primary"
                            class="text-none"
                            style="border-width: 2px;"
                            width="230"
                            :to="item.route.equity"
                            >Perubahan Ekuitas</v-btn
                          >
                        </v-col>
                        <v-col>
                          <v-btn
                            outlined
                            color="primary"
                            class="text-none"
                            style="border-width: 2px;"
                            width="230"
                            :to="item.route.financePosition"
                            >Posisi Keuangan</v-btn
                          >
                        </v-col>
                        <v-col>
                          <v-btn
                            outlined
                            color="primary"
                            class="text-none"
                            style="border-width: 2px;"
                            width="230"
                            :to="item.route.cashFlow"
                            >Arus Kas</v-btn
                          >
                        </v-col>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                  <v-layout fill-height column v-else>
                    <router-link :to="item.route">
                      <v-card flat rounded="lg" class="logo-place pa-3">
                        <v-img
                          contain
                          lazy-src
                          :src="resource.picLocation[index + 1]"
                        />
                      </v-card>
                    </router-link>
                    <v-flex class="font-weight-medium">
                      {{ item.text }}
                    </v-flex>
                  </v-layout>
                </v-flex>
              </template>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </main>
    <!-- <v-dialog v-model="dialog">
      <v-card height="600">
       
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import accounting from '@/assets/image/accounting.svg';
import add from '@/assets/image/account_add.svg';
import journal from '@/assets/image/account_journal.svg';
import ledger from '@/assets/image/account_ledger.svg';
import report from '@/assets/image/account_report.svg';
import close from '@/assets/image/account_close.svg';

export default {
  name: 'PharmacySubmenu',
  mixins: [],
  components: {},
  data() {
    return {
      dialog: false,
      journalDialog: false,
      ledgerDialog: false,
      financialDialog: false,
      resource: {
        picLocation: [accounting, add, journal, ledger, report, close],
        category: [
          {
            route: `/keuangan/akuntansi/daftar-akun`,
            text: 'Daftar Akun',
          },
          {
            route: {
              journal_list: `/keuangan/akuntansi/daftar-jurnal`,
              journal_post: `/keuangan/akuntansi/posting-jurnal`,
            },
            text: 'Jurnal',
          },
          {
            route: {
              ledger: `/keuangan/akuntansi/buku-besar`,
              subsidiary_ledger: `/keuangan/akuntansi/buku-besar-pembantu`,
            },
            text: 'Buku Besar',
          },
          {
            route: {
              incomeState: `/keuangan/akuntansi/laba-rugi`,
              equity: `/keuangan/akuntansi/perubahan-ekuitas`,
              financePosition: `/keuangan/akuntansi/posisi-keuangan`,
              cashFlow: `/keuangan/akuntansi/arus-kas`,
            },
            text: 'Laporan Keuangan',
          },
          // {
          //   route: `/keuangan/akuntansi/tutup-buku`,
          //   text: 'Tutup Buku',
          // },
        ],
      },
    };
  },
  mounted() {
    if (this.routeController) {
      this.resource.category = [
        {
          route: `/keuangan/akuntansi/daftar-akun`,
          text: 'Daftar Akun',
        },
        {
          route: {
            journal_list: `/keuangan/akuntansi/daftar-jurnal`,
            journal_post: `/keuangan/akuntansi/posting-jurnal`,
          },
          text: 'Jurnal',
        },
        {
          route: {
            ledger: `/keuangan/akuntansi/buku-besar`,
            subsidiary_ledger: `/keuangan/akuntansi/buku-besar-pembantu`,
          },
          text: 'Buku Besar',
        },
        {
          route: {
            incomeState: `/keuangan/akuntansi/laba-rugi`,
            equity: `/keuangan/akuntansi/perubahan-ekuitas`,
            financePosition: `/keuangan/akuntansi/posisi-keuangan`,
            cashFlow: `/keuangan/akuntansi/arus-kas`,
          },
          text: 'Laporan Keuangan',
        },
        // {
        //   route: `/keuangan/akuntansi/tutup-buku`,
        //   text: 'Tutup Buku',
        // },
      ];
    }
  },
  computed: {
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[2];
    },
  },
  methods: {
    openQueue() {
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Roboto;
  font-style: normal;
  .main {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    padding: 0 25px 0 25px;

    .big-header {
      align-items: center;
      display: flex;
      width: 100%;
      min-height: 48vh;
      background: #f0f9ff;
      font-family: Roboto;
      font-style: normal;
      border-radius: 25px;
      .header-text {
        text-align: left;
        .primary-header {
          font-size: 2.5em;
          color: #000000;
          font-weight: bold;
        }
        .secondary-header {
          font-size: 1.15em;
          font-weight: 500;
          color: #a6adb4;
        }
      }
      .accounting-image {
        z-index: 1;
        // right: 35px;
        position: relative;
        width: 80%;
      }
    }
    .text-submenu {
      .text-head {
        // padding: 40px 0 30px 0;
        color: #000000;
        font-weight: bold;
      }
    }
    .menu-space {
      width: 100%;
      .menu-widget {
        margin: 20px;
        .logo-place {
          background: #f0f9ff;
          margin: auto;
          width: 85px;
          height: 85px;
          &:hover {
            border: 2px solid $primary-color;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
