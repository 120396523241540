<template>
  <div class="main-div">
    <main class="main">
      <v-container fluid>
        <v-layout align-start justify-start column fill-width>
          <v-flex class="big-header">
            <v-layout align-space-between justify-start row fill-height>
              <v-flex xs6 align-self-center class="header-text pl-10">
                <h1 class="primary-header">Farmasi {{ serviceLabel }}</h1>
                <h3 class="secondary-header">
                  Cek Informasi mengenai farmasi {{ serviceLabel }}
                </h3>
              </v-flex>
              <v-flex xs1></v-flex>
              <v-flex xs5>
                <v-img
                  contain
                  class="scientist-image"
                  lazy-src
                  :src="resource.picLocation[0]"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex class="text-submenu">
            <h3 class="text-head py-4">Submenu</h3>
          </v-flex>
          <v-flex class="menu-space">
            <v-layout align-start justify-start row fill-height>
              <v-flex
                v-for="(item, index) in resource.subMenu"
                xs1
                class="menu-widget"
                :key="'subMenu-pharmacy-inpatient-' + index"
              >
                <v-layout fill-height column>
                  <router-link :to="item.route">
                    <v-card flat rounded="lg" class="logo-place pa-3">
                      <v-img contain lazy-src :src="item.icon" />
                    </v-card>
                  </router-link>
                  <v-flex class="font-weight-medium">
                    {{ item.text }}
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
      <v-dialog v-model="dialog" fullscreen>
        <DrugQueue></DrugQueue>
      </v-dialog>
    </main>
  </div>
</template>

<script>
import scientist from '@/assets/image/scientist.svg';
import medicine from '@/assets/image/medicine.svg';
import drugPill from '@/assets/image/drug_pill.svg';
import drugHand from '@/assets/image/drug_hand.svg';
import bookpill from '@/assets/image/pharmacy_bookpill.svg';
import otc from '@/assets/image/over_the_counter.svg';
import queue from '@/assets/image/tv-24px.svg';
import DrugQueue from './Submenu/DrugQueue';

export default {
  name: 'PharmacySubmenu',
  mixins: [],
  components: {
    DrugQueue,
  },
  data() {
    return {
      dialog: false,
      resource: {
        picLocation: [
          scientist,
          medicine,
          drugPill,
          drugHand,
          bookpill,
          queue,
          otc,
        ],
        subMenu: [],
      },
    };
  },
  mounted() {
    this.remap();
  },
  watch: {
    serviceLabel() {
      this.remap();
    },
  },
  computed: {
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[2];
    },
    serviceLabel() {
      switch (this.routeController) {
        case 'rawat-inap':
          return 'Rawat Inap';

        case 'rawat-jalan':
          return 'Rawat Jalan';

        case 'igd':
          return 'IGD';

        default:
          return '';
      }
    },
    isOutpatient() {
      return this.routeController === 'rawat-jalan';
    },
    isInpatient() {
      return this.routeController === 'rawat-inap';
    },
    isEmergency() {
      return this.routeController === 'igd';
    },
  },
  methods: {
    remap() {
      if (this.serviceLabel === 'IGD') {
        this.resource.subMenu = [
          {
            route: `/farmasi/${this.routeController}/permintaan-obat`,
            text: 'Permintaan Obat dan Alkes',
            icon: medicine,
          },
          {
            route: `/farmasi/${this.routeController}/pembuatan-obat`,
            text: 'Pembuatan Obat',
            icon: drugPill,
          },
          {
            route: `/farmasi/${this.routeController}/penyerahan-obat`,
            text: 'Penyerahan Obat',
            icon: drugHand,
          },
        ];
      }
      if (this.serviceLabel === 'Rawat Inap') {
        this.resource.subMenu = [
          {
            route: `/farmasi/${this.routeController}/permintaan-obat`,
            text: 'Permintaan Obat dan Alkes',
            icon: medicine,
          },
          {
            route: `/farmasi/${this.routeController}/pembuatan-obat`,
            text: 'Pembuatan Obat',
            icon: drugPill,
          },
          {
            route: `/farmasi/${this.routeController}/penyerahan-obat`,
            text: 'Penyerahan Obat',
            icon: drugHand,
          },
        ];
      }
      if (this.serviceLabel === 'Rawat Jalan') {
        this.resource.subMenu = [
          {
            route: `/farmasi/${this.routeController}/permintaan-obat`,
            text: 'Permintaan Obat dan Alkes',
            icon: medicine,
          },
          {
            route: `/farmasi/${this.routeController}/pembuatan-obat`,
            text: 'Pembuatan Obat',
            icon: drugPill,
          },
          {
            route: `/farmasi/${this.routeController}/penyerahan-obat`,
            text: 'Penyerahan Obat',
            icon: drugHand,
          },
          {
            route: `/farmasi/${this.routeController}/penjualan-obat-bebas`,
            text: 'Penjualan Obat Bebas',
            icon: otc,
          },
          // {
          //   route: `/farmasi/antrian-obat`,
          //   text: 'Antrian Obat',
          //   icon: queue,
          //   dialog: true,
          // },
        ];
      }
    },
    openQueue(index) {
      if (index === 5) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Roboto;
  font-style: normal;
  .main {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    padding: 0 25px 0 25px;

    .big-header {
      align-items: center;
      display: flex;
      width: 100%;
      min-height: 48vh;
      background: #f0f9ff;
      font-family: Roboto;
      font-style: normal;
      border-radius: 25px;
      .header-text {
        padding-left: 25px;
        text-align: left;
        .primary-header {
          font-size: 2.5em;
          color: #000000;
          font-weight: bold;
        }
        .secondary-header {
          font-size: 1.15em;
          font-weight: 500;
          color: #a6adb4;
        }
      }
      .scientist-image {
        z-index: 1;
        // right: 35px;
        // top: 30px;
        position: relative;
        width: 90%;
      }
    }
    .text-submenu {
      .text-head {
        // padding: 20px 0 0 0;
        color: #000000;
        font-weight: bold;
      }
    }
    .menu-space {
      width: 100%;
      .menu-widget {
        margin: 20px;
        .logo-place {
          margin: auto;
          background: #f0f9ff;
          width: 85px;
          height: 85px;
          &:hover {
            border: 2px solid $primary-color;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
