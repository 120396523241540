import Constant from '@/const';
import axios from 'axios';

const getAllStaff = async ({
  halaman = 1,
  itemCount = 10,
  id_unit = '',
  keyword = '',
  sortBy,
}) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/master/staff?page=${halaman}&itemCount=${itemCount}&sort=${sortBy}&id_unit=${id_unit}&search=${keyword ||
          ''}`,
      ),
    );
    return response.data;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getAllStaff;
