const state = {
  sistol: '',
  diastol: '',
  pulse: '',
  weight: '',
  height: '',
  belly_circumference: '',
  respiration_rate: '',
  saturation: '',
  temperature: '',
  awareness: '',
  djj: '',
  other: '',
};

const getters = {
  // get sistol
  getSistol: state => state.sistol,
  // get diastol
  getDiastol: state => state.diastol,
  // get pulse
  getPulse: state => state.pulse,
  // get weight
  getWeight: state => state.weight,
  // get height
  getHeight: state => state.height,
  // get belly circumference
  getBellyCircumference: state => state.belly_circumference,
  // get respiration rate
  getRespirationRate: state => state.respiration_rate,
  // get saturation
  getSaturation: state => state.saturation,
  // get temperature
  getTemperature: state => state.temperature,
  // get awareness
  getAwareness: state => state.awareness,
  // get djj
  getDjj: state => state.djj,
  getOther: state => state.other,
  getObjective: state => state,
};

const mutations = {
  clearObjectiveState() {
    Object.assign(state, {
      sistol: '',
      diastol: '',
      pulse: '',
      weight: '',
      height: '',
      belly_circumference: '',
      respiration_rate: '',
      saturation: '',
      temperature: '',
      awareness: '',
      djj: '',
      other: '',
    });
  },
  setSistol(state, payload) {
    state.sistol = payload;
  },
  setDiastol(state, payload) {
    state.diastol = payload;
  },
  setPulse(state, payload) {
    state.pulse = payload;
  },
  setWeight(state, payload) {
    state.weight = payload;
  },
  setHeight(state, payload) {
    state.height = payload;
  },
  setBellyCircumference(state, payload) {
    state.belly_circumference = payload;
  },
  setRespirationRate(state, payload) {
    state.respiration_rate = payload;
  },
  setSaturation(state, payload) {
    state.saturation = payload;
  },
  setTemperature(state, payload) {
    state.temperature = payload;
  },
  setAwareness(state, payload) {
    state.awareness = payload;
  },
  setDjj(state, payload) {
    state.djj = payload;
  },
  setOther(state, payload) {
    state.other = payload;
  },
  setObjective(state, payload) {
    Object.assign(state, { ...payload });
  },
};

export default {
  state,
  getters,
  mutations,
};
