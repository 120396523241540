<template>
  <v-container class="pt-10 px-0" fluid>
    <v-form ref="form">
      <v-row>
        <v-col class="pr-4">
          <label class="required">Riwayat Kesehatan Sekarang</label>
          <v-text-field
            v-model="form.currentHealthHistory"
            :rules="rule"
            :readonly="isEMR"
            single-line
            placeholder="Masukkan riwayat kesehatan sekarang"
            dense
          ></v-text-field>
        </v-col>
        <v-col class="pl-4">
          <label>Riwayat Kesehatan yang Lalu</label>
          <v-text-field
            :readonly="isEMR"
            v-model="form.pastHealthHistory"
            single-line
            dense
            placeholder="Masukkan riwayat kesehatan yang lalu"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-10" />
      <v-row>
        <v-col>
          <label class="required">Riwayat Penyakit Keluarga</label>

          <v-radio-group
            v-model="form.familyDiseaseHistory.check"
            :class="!form.familyDiseaseHistory.check ? 'mb-6' : 'mb-2'"
            :readonly="isEMR"
            light
            dense
            row
          >
            <v-radio light label="Tidak Ada" :value="false"></v-radio>
            <v-radio light label="Ada" :value="true"></v-radio>
          </v-radio-group>
          <v-combobox
            light
            single-line
            dense
            multiple
            style="width: 40%;"
            class="mb-5"
            placeholder="Riwayat Penyakit Keluarga"
            append-icon="mdi-chevron-down"
            :clearable="!isEMR"
            :readonly="isEMR"
            :rules="arrayRule"
            :items="resource.disease"
            v-if="form.familyDiseaseHistory.check"
            v-model="form.familyDiseaseHistory.name"
          >
            <template v-slot:prepend-item>
              <p class="grey--text ma-4 text-start body-1">
                Pilih atau Ketik Untuk Pilihan Lainnya
              </p>
            </template>
          </v-combobox>
          <label>Riwayat Alergi</label>
          <div
            class="d-flex"
            v-for="(_, index) in form.allergy"
            :key="`allergy-${index}`"
          >
            <v-text-field
              v-model="form.allergy[index]"
              :readonly="isEMR"
              single-line
              dense
              placeholder="Masukkan riwayat alergi"
              class="mb-3"
            ></v-text-field>
            <v-btn
              @click="onActionAllergy({ type: 'add', index })"
              v-if="!isEMR"
              text
              small
              fab
              ><v-icon color="#c2c2c2">mdi-plus</v-icon></v-btn
            >
            <v-btn
              @click="onActionAllergy({ type: 'delete', index })"
              v-if="!isEMR"
              :disabled="form.allergy.length === 1"
              text
              small
              fab
              ><v-icon color="#c2c2c2">mdi-delete</v-icon></v-btn
            >
          </div>
        </v-col>
        <v-col>
          <label>Riwayat Pengobatan</label>
          <v-text-field
            single-line
            dense
            :readonly="isEMR"
            v-model="form.treatmentHistory"
            placeholder="Riwayat Pengobatan"
            class="mb-4"
          ></v-text-field>
          <label>Status Psikologis</label>
          <v-radio-group
            :readonly="isEMR"
            v-model="form.psychologyStatus.check"
            light
            dense
            row
          >
            <v-radio light label="Tidak Ada Kelainan" :value="false"></v-radio>
            <v-radio light label="Ada" :value="true"></v-radio>
          </v-radio-group>
          <v-combobox
            light
            hide-details
            single-line
            dense
            multiple
            style="width: 40%;"
            class="mb-5"
            placeholder="Pilih Status Psikologis"
            append-icon="mdi-chevron-down"
            :readonly="isEMR"
            :items="resource.psychologyStatus"
            v-if="form.psychologyStatus.check"
            v-model="form.psychologyStatus.name"
          >
            <template v-slot:prepend-item>
              <p class="grey--text ma-4 text-start body-1">
                Pilih atau Ketik Untuk Pilihan Lainnya
              </p>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'EarlyAssessmentInpatientStore',
);

export default {
  name: 'Anamnesa',
  props: {
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rule: !this.isEMR ? [v => !!v || 'Wajib diisi'] : [],
      arrayRule: [v => !!v?.length || 'Wajib diisi'],
      resource: {
        disease: ['Diabetes', 'Asthma', 'Kanker', 'Hipertensi', 'Jantung'],
        psychologyStatus: ['Cemas', 'Takut', 'Marah', 'Sedih'],
      },
    };
  },
  computed: {
    ...mapGetters(['getAnamnesis']),
    form: {
      get() {
        return this.getAnamnesis;
      },
    },
  },
  methods: {
    ...mapMutations(['onActionAllergy']),
  },
};
</script>

<style lang="scss" scoped>
.col {
  text-align: start;
  padding: 0 0.75vw;
}
.required:after {
  content: ' *';
  color: red;
}

label {
  font-weight: 500;
  font-size: 0.8vw;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
