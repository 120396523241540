<template>
  <v-card class="py-5 main">
    <v-container class="pa-lg-0">
      <h1 class="mb-15">Form Anamnesis & TTV</h1>
      <v-form ref="form">
        <v-row>
          <v-col class="d-flex justify-end mr-lg-10">
            <div class="anamnesis-form left">
              <label for="anamnesis" class="required">Anamnesis</label>
              <v-textarea
                height="100"
                v-model="input.anamnesis"
                :rules="rules"
                no-resize
                outlined
                id="anamnesis"
                placeholder="Keluhan"
              ></v-textarea>
              <label for="sistole" class="required">Tekanan Darah</label>
              <div class="d-flex">
                <v-text-field
                  type="number"
                  oninput="if(this.value < 0) this.value = 0;"
                  :rules="[...rules, rangeRules(input.sistol, 'sistole')]"
                  v-model.number="input.sistol"
                  dense
                  placeholder="Sistolik"
                  id="sistole"
                />
                <span class="title mx-4 grey--text">/</span>
                <v-text-field
                  :rules="[...rules, rangeRules(input.diastol, 'diastole')]"
                  oninput="if(this.value < 0) this.value = 0;"
                  type="number"
                  dense
                  v-model.number="input.diastol"
                  placeholder="Diastolik"
                  suffix="mmhg"
                  id="sistole"
                />
              </div>
              <label for="pulse" class="required">Nadi</label>
              <v-text-field
                v-model.number="input.heart_rate"
                type="number"
                oninput="if(this.value < 0) this.value = 0;"
                dense
                :rules="[...rules, rangeRules(input.heart_rate, 'heart-rate')]"
                placeholder="Nadi"
                suffix="x/menit"
                id="pulse"
              />
              <label for="weight" class="required">Berat Badan</label>
              <v-text-field
                v-model.number="input.weight"
                oninput="if(this.value < 0) this.value = 0;"
                :rules="[...rules, rangeRules(input.weight, 'weight')]"
                dense
                type="number"
                placeholder="Berat Badan"
                suffix="kg"
                id="weight"
              />
              <label for="height" class="required">Tinggi Badan</label>
              <v-text-field
                v-model.number="input.height"
                type="number"
                oninput="if(this.value < 0) this.value = 0;"
                :rules="[...rules, rangeRules(input.height, 'height')]"
                dense
                placeholder="Tinggi Badan"
                suffix="cm"
                id="height"
              />
            </div>
          </v-col>
          <v-col class="px-xl-15">
            <div class="anamnesis-form right">
              <label for="lingkar-perut" class="required">Lingkar Perut</label>
              <v-text-field
                dense
                v-model.number="input.belly_circumference"
                oninput="if(this.value < 0) this.value = 0;"
                placeholder="Lingkar Perut"
                :rules="rules"
                type="number"
                suffix="cm"
                id="lingkar-perut"
              />
              <label for="rr" class="required">RR</label>
              <v-text-field
                oninput="if(this.value < 0) this.value = 0;"
                dense
                :rules="[...rules, rangeRules(input.respiration_rate, 'rr')]"
                type="number"
                placeholder="RR"
                v-model.number="input.respiration_rate"
                suffix="x/menit"
                id="rr"
              />
              <label for="saturation">Saturasi O<sub>2</sub></label>
              <v-text-field
                dense
                oninput="if(this.value < 0) this.value = 0;"
                v-model.number="input.saturation"
                placeholder="Saturasi O2"
                type="number"
                suffix="%"
                id="saturation"
              />
              <label for="temperature" class="required">Suhu</label>
              <v-text-field
                oninput="if(this.value < 0) this.value = 0;"
                dense
                :rules="[
                  ...rules,
                  rangeRules(input.temperature, 'temperature'),
                ]"
                placeholder="Suhu"
                suffix="°C"
                type="number"
                v-model.number="input.temperature"
                id="temperature"
              />
              <label for="awareness" class="required">Kesadaran</label>
              <v-select
                :rules="rules"
                :items="resource.awareness"
                v-model="input.awarness"
                item-value="value"
                append-icon="mdi-chevron-down"
                dense
                placeholder="Pilih kesadaran"
                id="awareness"
              />
            </div>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="d-flex justify-end pr-15">
        <div class="d-flex justify-space-between mb-5">
          <v-btn @click="back()" text>Kembali</v-btn>
          <v-btn color="primary" outlined class="ml-5" @click="next"
            >Lanjut</v-btn
          >
        </div>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Constant from '@/const';
const axios = require('axios');

export default {
  name: 'AnamnesisForm',
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      input: {
        anamnesis: '',
        sistol: '',
        diastol: '',
        heart_rate: '',
        weight: '',
        height: '',
        belly_circumference: '',
        respiration_rate: '',
        saturation: '',
        temperature: '',
        awarness: '',
      },
      resource: {
        awareness: [],
      },
      rules: [v => !!v || v === 0 || 'Wajib diisi'],
    };
  },
  mounted() {
    this.getAwarness();
    this.input = this.data;
  },
  methods: {
    rangeRules(value, type) {
      switch (type) {
        case 'sistole':
          if (value < 40 || value > 250) {
            return 'Angka tekanan darah tidak valid';
          }
          break;
        case 'diastole':
          if (value < 30 || value > 180) {
            return 'Angka tekanan darah tidak valid';
          }
          break;
        case 'heart-rate':
          if (value < 30 || value > 160) {
            return 'Angka nadi tidak valid';
          }
          break;
        case 'rr':
          if (value < 5 || value > 70) {
            return 'Angka RR tidak valid';
          }
          break;
        case 'temperature':
          if ((value < 20 || value > 45) && value !== '') {
            return 'Angka suhu tidak valid';
          }
          break;
        case 'height':
          if (value <= 1 || value > 300) {
            return 'Angka tinggi badan tidak valid';
          }
          break;

        case 'weight':
          if (value <= 1 || value > 600) {
            return 'Angka berat badan tidak valid';
          }
      }
    },
    next() {
      if (this.$refs.form.validate()) {
        this.input = {
          ...this.input,
          saturation: this.input.saturation || 0,
          temperature: this.input.temperature || 0,
        };
        this.$emit('next', this.input);
      }
    },
    back() {
      this.$emit('back');
    },
    getAwarness() {
      axios
        .get(Constant.apiUrl.concat('/master/awarness'))
        .then(response => {
          const data = response.data.data;
          this.resource.awareness = data.map(v => {
            return {
              value: v.code,
              text: v.description,
            };
          });
        })
        .catch(() => (this.resource.awareness = []));
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  overflow: hidden;
}
.anamnesis-form {
  display: grid;
  width: 100%;
  text-align: left;
  grid-template-columns: 1fr 2.5fr;
  row-gap: 1rem;
  margin-bottom: 5rem;
  &.left {
    padding-left: 4rem;
  }
  &.right {
    padding-right: 5rem;
  }
  label {
    font-weight: 700;
  }
}
.required:after {
  content: ' *';
  color: red;
}
</style>
