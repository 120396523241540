<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="font-weight-bold">
        <span v-if="state === 'add'">Tambah</span>
        <span v-if="state === 'edit'">Ubah</span>
        <span v-if="state === 'detail'">Detail</span>
        Unit
      </span>
      <v-btn absolute right icon @click="onCloseButtonClick()">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container style="padding: 40px;">
        <v-form ref="form">
          <v-row no-gutters>
            <v-col cols="5">
              <v-row class="detail-input">
                <v-col cols="4" class="detail-input__label">
                  <label for="name">Kode Unit</label>
                </v-col>
                <v-col class="detail-input__input-text text-start">
                  <span>
                    {{ form.unit_number }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="detail-input">
                <v-col cols="4" class="detail-input__label">
                  <label
                    for="barcode"
                    :class="state !== 'detail' ? 'required' : ''"
                    >Nama Unit</label
                  >
                </v-col>
                <v-col class="detail-input__input-text">
                  <v-text-field
                    placeholder="Masukkan Nama Unit"
                    v-model="form.name"
                    required
                    :rules="[v => !!v || 'Wajib diisi']"
                    :readonly="state === 'detail'"
                  />
                </v-col>
              </v-row>
              <v-row class="detail-input">
                <v-col cols="4" class="detail-input__label">
                  <label
                    for="category"
                    :class="state !== 'detail' ? 'required' : ''"
                    >Instalasi</label
                  >
                </v-col>
                <v-col class="detail-input__input-text">
                  <v-autocomplete
                    placeholder="Pilih Instalasi"
                    v-model="form.detail.installation"
                    :items="items.installation.data"
                    :loading="items.installation.isLoading"
                    dense
                    required
                    :append-icon="state !== 'detail' ? 'mdi-chevron-down' : ''"
                    :rules="[v => !!v || 'Wajib diisi']"
                    :readonly="state === 'detail'"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-spacer />
            <v-col cols="5">
              <v-row class="detail-input">
                <v-spacer />
                <v-col cols="4" class="detail-input__label">
                  <label
                    for="gudang"
                    :class="state !== 'detail' ? 'required' : ''"
                    >Gudang</label
                  >
                </v-col>
                <v-col cols="7" style="height: 45px;">
                  <v-radio-group
                    :mandatory="false"
                    v-model="form.detail.isWarehouse"
                    row
                    class="mt-0 pt-0"
                    required
                    :rules="[v => !!v || v === false || 'Wajib diisi']"
                    :readonly="state === 'detail'"
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row class="detail-input">
                <v-spacer />
                <v-col cols="4" class="detail-input__label">
                  <label
                    for="farmasi"
                    :class="state !== 'detail' ? 'required' : ''"
                    >Farmasi</label
                  >
                </v-col>
                <v-col cols="7" class="detail-input__input-text">
                  <v-radio-group
                    :mandatory="false"
                    v-model="form.detail.isPharmacy"
                    row
                    class="mt-0 pt-0"
                    required
                    :rules="[v => !!v || v === false || 'Wajib diisi']"
                    :readonly="state === 'detail'"
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="text-none" width="100" text @click="onCloseButtonClick()">
        Kembali
      </v-btn>
      <v-btn
        v-if="state !== 'detail'"
        color="primary"
        depressed
        width="100"
        class="text-none"
        @click="handleActionUnit()"
      >
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('MasterUnit');

export default {
  name: 'FormUnit',
  props: {
    state: {
      type: String,
      default: 'add',
    },
  },
  computed: {
    ...mapGetters(['getForm', 'getItems']),

    items: {
      get() {
        return this.getItems;
      },
    },

    form: {
      get() {
        return this.getForm;
      },
    },
  },
  mounted() {
    switch (this.state) {
      case 'add':
        this.resolveGetInstallation();
        this.resolveGetLastUnit();
        break;
      case 'detail':
        this.resolveGetUnitById();
        break;
      case 'edit':
        this.resolveGetUnitById();
        this.resolveGetInstallation();
        break;
    }
  },
  methods: {
    ...mapActions([
      'resolvePutUnit',
      'resolvePostUnit',
      'resolveGetUnitById',
      'resolveGetLastUnit',
      'resolveGetInstallation',
    ]),
    handleActionUnit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      switch (this.state) {
        case 'add':
          this.resolvePostUnit().then(status => {
            if (status === 200) {
              Swal.fire('Berhasil', 'Data unit sudah masuk', 'success');
            } else {
              Swal.fire('Gagal', 'Data unit gagal ditambahkan', 'error');
            }
          });
          break;
        case 'edit':
          this.resolvePutUnit().then(status => {
            if (status === 200) {
              Swal.fire('Berhasil', 'Data unit berhasil diubah', 'success');
            } else {
              Swal.fire('Gagal', 'Data unit gagal diubah', 'error');
            }
          });
          break;
      }
    },
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-input:nth-child(1) {
  .detail-input__input-text {
    height: 50px;
    color: black;
    font-weight: 700;
  }
}
.detail-input {
  align-items: center;
  &__label {
    text-align: left;
    color: #222;
  }

  &__input-text {
    height: 50px;
    color: #222;
    font-weight: 400;
    max-width: 66%;
  }
}

.required:after {
  content: ' *';
  color: red;
}
</style>
