<template>
  <v-row>
    <v-col class="text-sm-left ml-4 graph-head">
      <div>
        <h4 :style="{ textTransform: 'capitalize' }">
          Jumlah Kunjungan Pasien
          {{
            data.filterType !== 'all'
              ? data.filterType === 'bpjs'
                ? data.filterType.toUpperCase()
                : data.filterType
              : ''
          }}
        </h4>
        <label class="grey--text body-2"
          >Rata-rata angka jumlah kunjungan pasien
          {{ $store.getters.userLoggedIn.clinic.name }} tahun ini</label
        >
      </div>
      <div>
        <v-btn
          depressed
          outlined
          width="40"
          class="btn mx-md-1 mx-3"
          :class="{
            active: data.filterType === 'all',
            'primary--text': data.filterType === 'all',
            'grey--text': data.filterType !== 'all',
          }"
          @click="onChangeFilter('all')"
          >All</v-btn
        >
        <v-btn
          depressed
          outlined
          width="40"
          class="btn mx-md-1 mx-3"
          :class="{
            active: data.filterType === 'umum',
            'primary--text': data.filterType === 'umum',
            'grey--text': data.filterType !== 'umum',
          }"
          @click="onChangeFilter('umum')"
          >Umum</v-btn
        >
        <v-btn
          depressed
          outlined
          class="btn mx-md-1 mx-3"
          :class="{
            active: data.filterType === 'bpjs',
            'primary--text': data.filterType === 'bpjs',
            'grey--text': data.filterType !== 'bpjs',
          }"
          @click="onChangeFilter('bpjs')"
          >BPJS</v-btn
        >
        <v-btn
          depressed
          outlined
          width="70"
          class="btn mx-md-1 mx-3"
          :class="{
            active: data.filterType === 'asuransi',
            'primary--text': data.filterType === 'asuransi',
            'grey--text': data.filterType !== 'asuransi',
          }"
          @click="onChangeFilter('asuransi')"
          >Asuransi</v-btn
        >
      </div>
    </v-col>
    <v-col cols="12" class="graph">
      <apexcharts
        ref="realtimeChart"
        type="line"
        height="100%"
        :options="data.chartOptions"
        :series="data.series"
      ></apexcharts>
    </v-col> </v-row
></template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'DashboardChart',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    apexcharts: VueApexCharts,
  },
  mounted() {
    this.updateChartData();
  },
  methods: {
    updateChartData() {
      this.$refs.realtimeChart.updateSeries(this.data.series, false, true);
    },
    onChangeFilter(data) {
      this.$emit('on-change-filter', data);
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1904px) {
  .graph-head {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    .btn {
      border: 0px solid #137bc0;
      border-radius: 5px;
      text-transform: none;
      &.active {
        border: 1.5px solid #137bc0;
      }
    }
  }
}
@media screen and (max-width: 1903px) {
  .graph-head {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    label {
      font-size: 0.775rem !important;
    }
    .btn {
      border: 0px solid #137bc0;
      border-radius: 5px;
      text-transform: none;
      height: 25px;
      &.active {
        border: 1.5px solid #137bc0;
      }
      ::v-deep .v-btn__content {
        font-size: 0.8rem;
      }
    }
  }
}

.graph {
  position: relative;
  width: 100%;
  height: 47vh;

  &.loading {
    padding: 80px;
  }
}
</style>
