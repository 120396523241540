<template>
  <v-card>
    <h2 class="py-5">Surat Perintah Rawat Inap</h2>
    <v-btn @click="closeForm" text fab small><v-icon>mdi-close</v-icon></v-btn>
    <v-divider></v-divider>
    <inpatient-warrant :patient-data="patient" is-detail />
  </v-card>
</template>

<script>
import InpatientWarrant from '../../../Service/components/Letters/InpatientWarrant';

export default {
  components: { InpatientWarrant },
  name: 'DetailSpri',
  props: {
    patient: {
      type: Object,
      default: null,
    },
  },
  methods: {
    closeForm() {
      this.$emit('close-form');
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  position: absolute;
  top: 0.5vw;
  right: 1vw;
}
</style>
