<template>
  <v-card>
    <v-container fluid class="input-container">
      <v-layout row wrap class="header-layout">
        <v-flex xs12 class="header-container">
          <span class="label">No. Triase</span>
          <span class="content">{{ dataPatient.noTriase }}</span>
          <span class="label">Nama</span>
          <span class="content">{{ dataPatient.name }}</span>
          <span class="label">Jenis Kelamin</span>
          <span class="content">{{ dataPatient.gender }}</span>
          <span class="label">Alamat</span>
          <span class="content">{{ dataPatient.address }}</span>
          <span class="label">Kategori</span>
          <span class="content" v-if="dataPatient.category !== 5"
            >PACS {{ dataPatient.category }}</span
          >
          <span class="content" v-else>DOA</span>
        </v-flex>
      </v-layout>
      <!-- <h1 class="header">Data Pasien</h1>
      <div class="search">
        <v-text-field
          outline
          label="Nomor Triase Pasien"
          class="patient-input"
          v-model="triase_number"
          @keyup.prevent="onKeyUpTriase"
        />
        <v-btn @click="searchTriase" class="search-btn" color="primary">Cari</v-btn>
      </div>
      <v-data-table
        :headers="table.patient.headers"
        :items="table.patient.data"
        hide-actions
        single-line
        :no-data-text="table.patient.no_data"
        :loading="table.patient.isLoading"
      >
      </v-data-table> -->

      <div class="sub-wrapper">
        <h1 class="sub-header large">Rekomendasi Hasil Pencarian</h1>
        <span class="sub-header span">atau</span>
        <h3
          class="sub-header link blue--text"
          @click="flagManual = !flagManual"
        >
          Cari Pasien
        </h3>
      </div>
      <div class="search px-10" v-if="flagManual">
        <v-text-field
          outlined
          dense
          label="Nama Pasien atau Nomor Rekam Medis"
          class="patient-input"
          v-model="patientName"
          :loading="table.recommendation.isLoading"
          @keyup.enter="searchManual"
          @change="table.pages = 1"
        />
        <v-btn depressed color="primary" class="mx-5" @click="searchManual()"
          >Cari</v-btn
        >
      </div>
      <v-data-table
        :items="table.recommendation.data"
        :headers="table.recommendation.headers"
        :page.sync="table.pages"
        hide-default-footer
        single-line
        :no-data-text="table.recommendation.no_data"
        :loading="table.recommendation.isLoading"
        class="px-10"
      >
        <v-progress-linear color="blue" indeterminate></v-progress-linear>
        <template v-slot:[`item.no_queue`]="{ item }">
          <td class="text-sm-left">
            {{ item.no_queue + 1 }}
          </td>
        </template>
        <template v-slot:[`item.register_button`]="{ item }">
          <td class="font-weight-bold text-sm-left px-0">
            <v-btn depressed tile color="primary" @click="getData(item.no_rm)"
              >register</v-btn
            >
          </td>
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" width="1200">
        <SocialDataPatients
          v-if="dialog"
          :selected-patient="patientSelected"
          :patient-status="'igd'"
          :triase="dataPatient.noTriase"
          :get-triase-patient="getTriasePatient"
          @close-form="
            dialog = false;
            $emit('close-form');
          "
          @back-dialog="dialog = false"
        />
      </v-dialog>
    </v-container>
    <v-card-actions class="new-patient">
      <v-pagination
        v-model="table.pages"
        :length="pages"
        :total-visible="5"
        @input="searchManual()"
      />
      <v-spacer></v-spacer>
      <v-btn
        class="new-patient-button"
        depressed
        outlined
        color="primary"
        @click="getData(null)"
      >
        <v-icon>mdi-plus</v-icon>Buat Pasien Baru
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Axios from 'axios';
import Constant from '@/const';
import SocialDataPatients from '../SocialDataPatients.vue';
// import Swal from 'sweetalert2';

// const _ = require('lodash');

export default {
  name: 'DataMatchingModal',
  components: {
    SocialDataPatients,
  },
  props: {
    dataPatient: {
      type: Object,
      default: () => {
        return null;
      },
    },
    getTriasePatient: Function,
  },
  data() {
    return {
      //   triase_number: this.dataPatient.noTriase,
      flagManual: false,
      patientName: '',
      table: {
        recommendation: {
          no_data: '',
          data: [],
          headers: [
            { text: 'No.', value: 'no_queue' },
            { text: 'No. RM', value: 'no_rm' },
            { text: 'Nama', value: 'name' },
            { text: 'Jenis Kelamin', value: 'gender' },
            { text: 'Alamat', value: 'address', width: '20%' },
            { text: 'Nama Orang Tua', value: 'parent_name', sortable: false },
            {
              text: 'Nama Penanggung Jawab',
              value: 'person_in_charge',
              sortable: false,
            },
            {
              text: '',
              value: 'register_button',
              sortable: false,
            },
          ],
          pagination: {
            descending: false,
            page: 1,
            sortBy: 'no_queue',
            rowsPerPage: 8,
            totalItems: 0,
          },
          isLoading: false,
        },
        pages: 1,
      },
      patientSelected: null,
      dialog: false,
      pages: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.searchManual();
    });
  },
  //   computed: {
  //     pages() {
  //       if (
  //         this.table.recommendation.pagination.rowsPerPage == null ||
  //         this.table.recommendation.pagination.totalItems == null
  //       )
  //         return 0;

  //       return Math.ceil(
  //         this.table.recommendation.pagination.totalItems /
  //           this.table.recommendation.pagination.rowsPerPage,
  //       );
  //     },
  //   },
  methods: {
    // onClick(index) {
    //   this.$store.commit('changeTriaseState', this.triase_number);
    //   this.$emit('change-form', index);
    // },
    // register(noMr) {
    //   this.$store.commit('changeTriaseState', this.triase_number);
    //   const item = { index: 2, noMr };
    //   this.$emit('change-form', item);
    // else {
    //   Swal.fire({
    //     type: 'warning',
    //     title: 'Triase belum diisi',
    //     text: 'Isi nomor triase terlebih dahulu',
    //   });
    // },
    // onKeyUpTriase(e) {
    //   if (e.keyCode === 13) this.searchTriase();
    // },
    // // searchTriase() {
    // //   this.table.patient.isLoading = true;
    // //   Axios.get(
    // //     Constant.apiUrl.concat(
    // //       `/patient/igd/triase/${this.dataPatient.noTriase}`,
    // //     ),
    // //   )
    // //     .then(response => {
    // //       const patientData = response.data.data;
    // //       this.table.recommendation.pagination.totalItems =
    // //         response.data.data.length;
    // //       this.table.patient.data = [
    // //         {
    // //           no_queue: 1,
    // //           isRegistered: _.get(patientData, 'registered', false),
    // //           no_reg: patientData.triase_number,
    // //           name: patientData.detail_triase[0].triase_level.name,
    // //           gender: patientData.detail_triase[0].triase_level.gender,
    // //           address: patientData.detail_triase[0].triase_level.address,
    // //           parent_name:
    // //             patientData.detail_triase[0].triase_level.parent_name,
    // //           person_in_charge:
    // //             patientData.detail_triase[0].triase_level.person_in_charge,
    // //         },
    // //       ];
    // //     })
    // //     .catch(error => {
    // //       this.table.patient.data = [];
    // //       this.table.patient.no_data = `Tidak ada data karena ${error.response.data.pesan}`;
    // //     });
    // //   this.table.patient.isLoading = false;
    // //   this.getRecomendation();
    // // },
    // // getRecomendation() {
    // //   this.table.recommendation.isLoading = true;
    // //   Axios.get(
    // //     Constant.apiUrl.concat(
    // //       `/patient/igd/patient/${this.dataPatient.noTriase}?page=${this.table.recommendation.pagination.page}&itemCount=${this.table.recommendation.pagination.rowsPerPage}`,
    // //     ),
    // //   )
    // //     .then(response => {
    // //       const patientData = response.data.data;
    // //       this.table.recommendation.pagination.totalItems =
    // //         response.data.length;
    // //       this.table.recommendation.data = patientData.map((patient, index) => {
    // //         return {
    // //           no_queue: index + 1,
    // //           no_rm: patient.rm_patient,
    // //           name: patient.detail[0].name,
    // //           gender: patient.detail[0].gender ? 'Laki-laki' : 'Perempuan',
    // //           address: patient.detail[0].address.detail,
    // //           parent_name:
    // //             patient.detail[0].parents.mother !== ''
    // //               ? patient.detail[0].parents.mother
    // //               : patient.detail[0].parents.father,
    // //           person_in_charge: patient.detail[0].pic.name,
    // //         };
    // //       });
    // //     })
    // //     .catch(error => {
    // //       this.table.recommendation.no_data = `Tidak ada data rekomendasi karena ${error.response.data.pesan}`;
    // //       this.table.recommendation.data = [];
    // //     })
    // //     .finally(() => {
    // //       this.table.recommendation.isLoading = false;
    // //     });
    // // },
    // onKeyUpManual(e) {
    //   if (e.keyCode === 13) this.searchManual();
    // },
    searchManual() {
      this.table.recommendation.isLoading = true;
      let param = '';
      if (this.patientName === '') {
        param = this.dataPatient.name;
      } else {
        param = this.patientName.toString().trim();
      }

      Axios.get(
        Constant.apiUrl.concat(
          `/patient/emr/all?page=${this.table.pages}&itemCount=5&search=${param}&sort= `,
        ),
      )
        .then(response => {
          const patientData = response.data.data;
          this.pages = Math.ceil(response.data.length / 5);
          this.table.recommendation.data = patientData.map((patient, index) => {
            return {
              no_queue: index + 5 * this.table.pages - 5,
              no_rm: patient.medical_record_number,
              name: patient.social.name,
              gender: patient.social.gender ? 'Laki-laki' : 'Perempuan',
              address: patient.social.address.text
                ? patient.social.address.text
                : patient.social.address.text +
                  ', ' +
                  patient.social.address.sub_district
                ? patient.social.address.sub_district + ', '
                : '' + patient.social.address.district
                ? patient.social.address.district + ', '
                : '' + patient.social.address.city
                ? patient.social.address.city + ', '
                : '' + patient.social.address.province
                ? patient.social.address.province
                : '',
              parent_name:
                patient.social.mother !== ''
                  ? patient.social.mother
                  : patient.social.father,
              // person_in_charge: patient.social.pic.name,
            };
          });
        })
        .catch(error => {
          this.table.recommendation.no_data = `Tidak ada data rekomendasi karena ${error.response.data.pesan}`;
          this.table.recommendation.data = [];
        })
        .finally(() => {
          this.table.recommendation.isLoading = false;
        });
    },
    getData(val) {
      if (val === null) {
        this.patientSelected = {
          status: 'igd-new',
          ...this.dataPatient,
        };
      } else {
        this.patientSelected = {
          status: 'old',
          rmNumber: val,
        };
      }

      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-layout {
  margin: 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  .header-container {
    display: flex;
    padding: 10px 20px 5px 20px;
    align-items: center;
    justify-items: baseline;

    .label {
      margin-right: 20px;
      font-size: 0.9rem;
      color: #949a9d;
      height: 100%;
    }
    .content {
      margin-right: 20px;
      font-size: 0.9rem;
      font-weight: 700;
    }
  }
}
.input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.9rem;
  position: relative;
  .header {
    margin-top: 18px;
    height: 29px;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .sub-wrapper {
    display: flex;
    margin: auto;
    .sub-header {
      margin-top: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      height: 26px;
      &.large {
        font-size: 22px;
      }
      &.span {
        padding-top: 8px;
        margin-left: 10px;
      }
      &.link {
        padding-top: 5px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  .search {
    display: flex;
    margin: 18px;
    align-items: baseline;
    .patient-input {
      width: 546px;
      max-height: 40px;
      border-radius: 6px;
      // border: solid 1px #adb5bd;
      background-color: #ffffff;
    }
  }
  .new-patient {
    margin-top: 20px;
    .new-patient-button {
      .icon {
        margin-right: 12px;
      }
    }
  }
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}
</style>
