<template>
  <v-container fluid>
    <h4>
      PEMERIKSAAN FISIK
    </h4>
    <v-row class="pt-5">
      <v-col
        class="mb-1"
        cols="6"
        v-for="(item, index) in resource"
        :key="`phy-${index}`"
      >
        <label>{{ item.label }}</label>
        <v-text-field
          single-line
          dense
          light
          :readonly="isEMR"
          placeholder="Contoh: Normal"
          v-model="form[item.value]"
        ></v-text-field>
      </v-col>
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('EarlyAssessmentInpatientStore');
export default {
  name: 'PhysicalExamination',
  props: {
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resource: [
        {
          label: 'Kepala',
          value: 'head',
        },
        {
          label: 'Punggung',
          value: 'back',
        },
        {
          label: 'Mata',
          value: 'eye',
        },
        {
          label: 'Perut',
          value: 'stomach',
        },
        {
          label: 'Telinga',
          value: 'ear',
        },
        {
          label: 'Genitalia',
          value: 'genitalia',
        },
        {
          label: 'Hidung',
          value: 'nose',
        },
        {
          label: 'Anus/Dubur',
          value: 'anus',
        },
        {
          label: 'Mulut',
          value: 'mouth',
        },
        {
          label: 'Lengan Atas',
          value: 'upperArm',
        },
        {
          label: 'Leher',
          value: 'neck',
        },
        {
          label: 'Lengan Bawah',
          value: 'forearm',
        },
        {
          label: 'Rambut',
          value: 'hair',
        },
        {
          label: 'Jari Tangan',
          value: 'fingers',
        },
        {
          label: 'Bibir',
          value: 'lips',
        },
        {
          label: 'Kuku Tangan',
          value: 'handNail',
        },
        {
          label: 'Gigi Geligi',
          value: 'teeth',
        },
        {
          label: 'Persendian Tangan',
          value: 'handJoints',
        },
        {
          label: 'Lidah',
          value: 'tongue',
        },
        {
          label: 'Tungkai Atas',
          value: 'upperLimbs',
        },
        {
          label: 'Langit-langit',
          value: 'palate',
        },
        {
          label: 'Tungkai Bawah',
          value: 'lowerLimbs',
        },
        {
          label: 'Tenggorokan',
          value: 'throat',
        },
        {
          label: 'Jari Kaki',
          value: 'toes',
        },
        {
          label: 'Tonsil',
          value: 'tonsil',
        },
        {
          label: 'Kuku Kaki',
          value: 'toeNails',
        },
        {
          label: 'Dada',
          value: 'breast',
        },
        {
          label: 'Persendian Kaki',
          value: 'legJoints',
        },
        {
          label: 'Payudara',
          value: 'breasts',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getPhysical']),
    form: {
      get() {
        return this.getPhysical;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  text-align: start;
  padding: 0 0.75vw;
}

.required:after {
  content: ' *';
  color: red;
}

label {
  font-weight: 500;
  font-size: 0.8vw;
}

h4 {
  font-weight: 400;
  color: #137bc0;
  text-align: start;
  margin-bottom: 0.7vw;
}
</style>
