<template>
  <v-card tile>
    <v-card-title class="justify-space-between">
      <v-col class="pa-0">
        <span class="font-weight-bold">
          <span v-if="state === 'edit'">Ubah</span>
          <span v-if="state === 'add'">Tambah</span>
          Data Bed
        </span>
      </v-col>
    </v-card-title>
    <v-form ref="form">
      <v-row no-gutters class="px-6 py-3">
        <v-col cols="12" class="text-left">
          <label class="font-weight-bold body-2 required">Nomor Bed</label>
          <v-text-field
            dense
            outlined
            placeholder="Contoh : 101"
            type="number"
            v-model="form.bed_number"
            :rules="[v => !!v || 'Wajib diisi'].concat(rulesBed)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="text-left pt-3">
          <label class="font-weight-bold body-2 required"
            >Ketersediaan Bed</label
          >
          <v-radio-group
            row
            class="mt-0"
            v-model="form.availability"
            :rules="[v => !!v || 'Wajib diisi']"
            validate-on-blur
          >
            <v-radio label="Tersedia" value="available"></v-radio>
            <v-radio label="Rusak" value="broken"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        color="primary"
        class="text-none"
        @click="$emit('close-dialog')"
        >Batal</v-btn
      >
      <v-btn color="primary" class="text-none" depressed @click="handleAction"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('MasterBed');

export default {
  name: 'FormBed',
  props: {
    numberBed: Number,
    state: {
      type: String,
      default: 'add',
    },
  },
  computed: {
    ...mapGetters(['getForm', 'getItems']),

    items: {
      get() {
        return this.getItems;
      },
    },

    form: {
      get() {
        return this.getForm;
      },
    },

    rulesBed: {
      get() {
        const bedNumber = this.items.dataNumberBed.data;
        return [
          v =>
            !bedNumber?.includes(parseInt(v)) ||
            this.numberBed === parseInt(v) ||
            'Nomor bed sudah ada',
        ];
      },
    },
  },
  mounted() {
    this.resolveGetAllBed({ all: true });
  },
  methods: {
    ...mapActions(['resolvePutBed', 'resolvePostBed', 'resolveGetAllBed']),
    handleAction() {
      if (this.$refs.form.validate()) {
        switch (this.state) {
          case 'add':
            this.resolvePostBed().then(status => {
              if (status === 200) {
                Swal.fire(
                  'Berhasil',
                  'Data bed berhasil ditambahkan',
                  'success',
                );
              } else {
                Swal.fire('Gagal', 'Data bed gagal ditambahkan', 'error');
              }
            });
            break;
          case 'edit':
            this.resolvePutBed().then(status => {
              if (status === 200) {
                Swal.fire('Berhasil', 'Data bed berhasil diubah!', 'success');
              } else {
                Swal.fire('Gagal', 'Data bed gagal diubah!', 'error');
              }
            });
            break;
        }
      }
    },
  },
};
</script>

<style>
.required:after {
  content: ' *';
  color: red;
}
</style>
