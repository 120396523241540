var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"text-sm-left ml-4 graph-head"},[_c('div',[_c('h4',{style:({ textTransform: 'capitalize' })},[_vm._v(" Jumlah Kunjungan Pasien "+_vm._s(_vm.data.filterType !== 'all' ? _vm.data.filterType === 'bpjs' ? _vm.data.filterType.toUpperCase() : _vm.data.filterType : '')+" ")]),_c('label',{staticClass:"grey--text body-2"},[_vm._v("Rata-rata angka jumlah kunjungan pasien "+_vm._s(_vm.$store.getters.userLoggedIn.clinic.name)+" tahun ini")])]),_c('div',[_c('v-btn',{staticClass:"btn mx-md-1 mx-3",class:{
            active: _vm.data.filterType === 'all',
            'primary--text': _vm.data.filterType === 'all',
            'grey--text': _vm.data.filterType !== 'all',
          },attrs:{"depressed":"","outlined":"","width":"40"},on:{"click":function($event){return _vm.onChangeFilter('all')}}},[_vm._v("All")]),_c('v-btn',{staticClass:"btn mx-md-1 mx-3",class:{
            active: _vm.data.filterType === 'umum',
            'primary--text': _vm.data.filterType === 'umum',
            'grey--text': _vm.data.filterType !== 'umum',
          },attrs:{"depressed":"","outlined":"","width":"40"},on:{"click":function($event){return _vm.onChangeFilter('umum')}}},[_vm._v("Umum")]),_c('v-btn',{staticClass:"btn mx-md-1 mx-3",class:{
            active: _vm.data.filterType === 'bpjs',
            'primary--text': _vm.data.filterType === 'bpjs',
            'grey--text': _vm.data.filterType !== 'bpjs',
          },attrs:{"depressed":"","outlined":""},on:{"click":function($event){return _vm.onChangeFilter('bpjs')}}},[_vm._v("BPJS")]),_c('v-btn',{staticClass:"btn mx-md-1 mx-3",class:{
            active: _vm.data.filterType === 'asuransi',
            'primary--text': _vm.data.filterType === 'asuransi',
            'grey--text': _vm.data.filterType !== 'asuransi',
          },attrs:{"depressed":"","outlined":"","width":"70"},on:{"click":function($event){return _vm.onChangeFilter('asuransi')}}},[_vm._v("Asuransi")])],1)]),_c('v-col',{staticClass:"graph",attrs:{"cols":"12"}},[_c('apexcharts',{ref:"realtimeChart",attrs:{"type":"line","height":"100%","options":_vm.data.chartOptions,"series":_vm.data.series}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }