import {
  putUnit,
  postUnit,
  getAllUnit,
  deleteUnit,
  getLastUnit,
  getUnitById,
  getInstallation,
} from '@/fetchApi/MasterData/Unit';

const state = {
  dashboard: {
    dialog: false,
    filter: {
      halaman: 0,
      itemCount: 0,
      sortBy: '',
      keyword: '',
    },
  },
  form: {
    id: '',
    unit_number: 0,
    name: '',
    detail: {
      isPharmacy: null,
      isWarehouse: null,
      installation: '',
    },
  },
  items: {
    dataUnit: {
      isLoading: false,
      data: [],
    },
    installation: {
      isLoading: false,
      data: [],
    },
  },
};
const getters = {
  // Get State Dialog
  getDialog: state => state.dashboard.dialog,

  // Get State Items
  getItems: state => state.items,

  // Get State Form
  getForm: state => state.form,
};
const mutations = {
  // Set State Dialog
  setDialog(state, payload) {
    state.dashboard.dialog = payload;
  },

  // Set State Items
  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: value,
      isLoading,
    };
  },

  // Set State Form
  setForm(state, payload) {
    state.form = payload;
  },

  // Set Filter
  setFilter(state, payload) {
    state.dashboard.filter = payload;
  },

  // Clear Form
  clearForm(state) {
    state.form = {
      unit_number: 0,
      name: '',
      detail: {
        isPharmacy: null,
        isWarehouse: null,
        installation: '',
      },
    };
  },
};
const actions = {
  // Get Data
  // Gett All Data Unit
  async resolveGetAllUnit(
    { commit, state },
    { halaman, itemCount, sortBy, keyword },
  ) {
    commit('setItems', {
      label: 'dataUnit',
      value: [...state.items.dataUnit.data],
      isLoading: true,
    });
    try {
      const unitResponse = await getAllUnit({
        halaman,
        itemCount,
        sortBy,
        keyword,
      });

      const unitMapper = unitResponse?.data?.map(item => ({
        id: item._id.toString(),
        name: item.name,
        unit_number: item.unit_number,
        installation: item.detail.installation,
        isPharmacy: item.detail.isPharmacy ? 'Ya' : 'Tidak',
        isWarehouse: item.detail.isWarehouse ? 'Ya' : 'Tidak',
      }));
      commit('setItems', {
        label: 'dataUnit',
        value: unitMapper,
        isLoading: false,
      });
      return unitResponse?.length;
    } catch {
      commit('setItems', {
        label: 'dataUnit',
        value: [],
        isLoading: false,
      });
      return 0;
    }
  },

  // Get Data Unit By Id
  async resolveGetUnitById({ state, commit }) {
    const responseGetUnitById = await getUnitById(state.form.id);
    const { _id, unit_number, name, detail } = responseGetUnitById;
    try {
      commit('setForm', {
        ...state.form,
        id: _id,
        unit_number,
        name,
        detail,
      });
      return responseGetUnitById;
    } catch (error) {
      return error;
    }
  },

  // Gett Data Instalation
  async resolveGetInstallation({ commit, state }) {
    commit('setItems', {
      label: 'installation',
      value: [...state.items.installation.data],
      isLoading: true,
    });
    try {
      const installationResponse = await getInstallation();
      const installationMapper = installationResponse?.data?.map(
        item => item.name,
      );
      commit('setItems', {
        label: 'installation',
        value: installationMapper,
        isLoading: false,
      });
      return installationResponse?.length;
    } catch {
      commit('setItems', {
        label: 'installation',
        value: [],
        isLoading: false,
      });
      return 0;
    }
  },

  // Get Last Unit
  async resolveGetLastUnit({ commit, state }) {
    try {
      const lastUnitResponse = await getLastUnit();
      const maxNumber = lastUnitResponse.map(u => {
        return Number(u.unit_number);
      });
      const result = Math.max(...maxNumber) + 1;
      commit('setForm', {
        ...state.form,
        unit_number: result,
      });
    } catch (error) {
      return error;
    }
  },
  // End Get Data

  // Post Data
  async resolvePostUnit({ state, commit, dispatch }) {
    const payload = {
      ...state.form,
    };
    delete payload.id;

    try {
      const postUnitRes = await postUnit(payload);
      if (postUnitRes.status === 200) {
        commit('setDialog', false);
        commit('clearForm');
        await dispatch('resolveGetAllUnit', {
          ...state.dashboard.filter,
        });
      }
      return postUnitRes.status;
    } catch (error) {
      return error;
    }
  },
  // End Post Data

  // Put Data
  async resolvePutUnit({ state, commit, dispatch }) {
    const payload = {
      ...state.form,
      detail: [
        {
          ...state.form.detail,
        },
      ],
    };
    delete payload.id;

    try {
      const putUnitRes = await putUnit(state.form.id, payload);
      if (putUnitRes.status === 200) {
        commit('setDialog', false);
        commit('clearForm');
        await dispatch('resolveGetAllUnit', {
          ...state.dashboard.filter,
        });
      }
      return putUnitRes.status;
    } catch (error) {
      return error;
    }
  },
  // End Put Data

  // Delete Data
  async resolveDeleteUnit(_, id) {
    try {
      const responseDeleteUnit = await deleteUnit(id);
      return responseDeleteUnit;
    } catch (error) {
      return error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
