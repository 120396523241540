<template>
  <v-container class="pt-5 px-14" fluid>
    <v-form ref="form">
      <h4>
        DATA KUNJUNGAN
      </h4>
      <v-row>
        <v-col class="pr-4 mb-2" cols="6">
          <skeleton class="mb-3" :isLoading="isLoading">
            <label class="required">Nama Petugas</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-combobox
              append-icon="mdi-chevron-down"
              placeholder="Pilih petugas"
              item-text="name"
              item-value="id"
              :rules="rule"
              :loading="loading.staff"
              :items="resource.staff"
              v-model="form.staff"
              :readonly="isDisableInput"
              :clearable="!isDisableInput"
              single-line
              dense
            />
          </skeleton>
        </v-col>
        <v-col class="pl-4" cols="6">
          <skeleton class="mb-2" :isLoading="isLoading">
            <label class="required">Tanggal Pengisian</label>
          </skeleton>
          <div class="date-grid">
            <skeleton :isLoading="isLoading">
              <v-menu
                dense
                ref="menu_fill"
                v-model="menu.fill"
                :disabled="isDisableInput"
                :return-value.sync="form.dateFill.formatted"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    readonly
                    single-line
                    dense
                    append-icon="mdi-chevron-down"
                    v-bind="attrs"
                    v-on="on"
                    v-model="form.dateFill.formatted"
                  />
                </template>
                <v-date-picker
                  no-title
                  scrollable
                  v-model="form.dateFill.raw"
                  @input="$refs.menu_fill.save(formatDate(form.dateFill.raw))"
                ></v-date-picker>
              </v-menu>
            </skeleton>
            <skeleton :isLoading="isLoading">
              <v-text-field
                type="time"
                :rules="rule"
                v-model="form.time.fill"
                :readonly="isTimeFillReadonly || isDisableInput"
                @keydown="preventDefaultBehaviour($event)"
                single-line
                dense
              />
            </skeleton>
          </div>
        </v-col>
        <v-col cols="6">
          <skeleton class="mb-3" :isLoading="isLoading">
            <label class="required">Tanggal Kunjungan</label>
          </skeleton>
          <div class="date-grid">
            <skeleton :isLoading="isLoading">
              <v-menu
                dense
                ref="menu_visit"
                v-model="menu.visit"
                :return-value.sync="form.dateVisit.formatted"
                :close-on-content-click="false"
                :disabled="isDisableInput"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    v-model="form.dateVisit.formatted"
                    readonly
                    single-line
                    dense
                    append-icon="mdi-chevron-down"
                  />
                </template>
                <v-date-picker
                  no-title
                  scrollable
                  v-model="form.dateVisit.raw"
                  @input="$refs.menu_visit.save(formatDate(form.dateVisit.raw))"
                ></v-date-picker>
              </v-menu>
            </skeleton>
            <skeleton :isLoading="isLoading">
              <v-text-field
                type="time"
                :readonly="isTimeVisitReadonly || isDisableInput"
                :rules="rule"
                v-model="form.time.visit"
                @keydown="preventDefaultBehaviour($event)"
                single-line
                dense
              />
            </skeleton>
          </div>
        </v-col>
      </v-row>
      <v-divider class="my-10" />
      <h4>
        INFORMASI IBU
      </h4>
      <v-row>
        <v-col>
          <skeleton class="mb-3" :isLoading="isLoading">
            <label class="required">Pilih RM Ibu</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-combobox
              prepend-inner-icon="mdi-magnify"
              placeholder="Cari no RM atau nama"
              append-icon=""
              item-text="text"
              item-value="id_emr"
              :rules="rule"
              :items="resource.patient"
              :loading="loading.mother"
              v-model="form.mother.data"
              :readonly="isDisableInput"
              :search-input.sync="search"
              dense
              light
              single-line
            >
              <template v-slot:item="data">
                <v-list-item-content @click="mapMotherData(data.item)">
                  <v-list-item-title>
                    <p class="pa-0 ma-0 subtitle-2">{{ data.item.text }}</p>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span>{{ data.item.address }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-combobox>
          </skeleton>
          <skeleton class="mb-3" :isLoading="isLoading">
            <label>Nama Ibu</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-text-field
              v-model="form.mother.name"
              :readonly="isDisableInput"
              placeholder="Nama Ibu"
              dense
              light
              single-line
            />
          </skeleton>
          <skeleton class="mb-3" :isLoading="isLoading">
            <label>Tanggal Lahir</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-menu
              dense
              ref="menu_birthdate"
              v-model="menu.birthdate"
              :return-value.sync="form.mother.birthDate.formatted"
              :close-on-content-click="false"
              :disabled="isDisableInput"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  v-model="form.mother.birthDate.formatted"
                  append-icon="mdi-chevron-down"
                  placeholder="DD-MM-YYYY"
                  readonly
                  dense
                  light
                  single-line
                />
              </template>
              <v-date-picker
                no-title
                scrollable
                :max="today"
                v-model="form.mother.birthDate.raw"
                @input="
                  $refs.menu_birthdate.save(
                    formatDate(form.mother.birthDate.raw),
                  )
                "
              ></v-date-picker>
            </v-menu>
          </skeleton>
        </v-col>
        <v-col>
          <skeleton class="mb-3" :isLoading="isLoading">
            <label>Pekerjaan</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-text-field
              v-model="form.mother.profession"
              :readonly="isDisableInput"
              placeholder="Masukkan pekerjaan ibu"
              dense
              light
              single-line
            />
          </skeleton>
          <skeleton class="mb-3" :isLoading="isLoading">
            <label>Status (GPA)</label>
            <div class="gpa-flex">
              <span>G</span>
              <v-text-field
                type="number"
                class="mr-5"
                v-model="form.mother.status.g"
                :readonly="isDisableInput"
                single-line
                dense
                light
                placeholder="0"
              />
              <span>P</span>
              <v-text-field
                type="number"
                class="mr-5"
                :readonly="isDisableInput"
                v-model="form.mother.status.p"
                single-line
                dense
                light
                placeholder="0"
              />
              <span>A</span>
              <v-text-field
                type="number"
                :readonly="isDisableInput"
                v-model="form.mother.status.a"
                single-line
                dense
                light
                placeholder="0"
              />
            </div>
          </skeleton>
        </v-col>
      </v-row>
      <v-divider class="my-10" />
      <h4>
        INFORMASI AYAH
      </h4>
      <v-row>
        <v-col>
          <skeleton class="mb-3" :isLoading="isLoading">
            <label>Nama Ayah</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-text-field
              placeholder="Masukkan nama ayah"
              :readonly="isDisableInput"
              v-model="form.father.name"
              dense
              light
              single-line
            />
          </skeleton>
        </v-col>
        <v-col>
          <skeleton class="mb-3" :isLoading="isLoading">
            <label>Pekerjaan Ayah</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-text-field
              placeholder="Masukkan pekerjaan ayah"
              :readonly="isDisableInput"
              v-model="form.father.profession"
              dense
              light
              single-line
            />
          </skeleton>
        </v-col>
      </v-row>
      <v-divider class="my-10" />
      <h4>
        INFORMASI BAYI
      </h4>
      <v-row>
        <v-col>
          <skeleton class="mb-3" :isLoading="isLoading">
            <label class="required">Waktu Persalinan</label>
          </skeleton>
          <div class="date-grid">
            <skeleton :isLoading="isLoading">
              <v-menu
                dense
                ref="menu_birthtime"
                :disabled="isDisableInput"
                v-model="menu.birthtime"
                :return-value.sync="form.baby.birthTime.date.formatted"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    v-model="form.baby.birthTime.date.formatted"
                    :rules="rule"
                    append-icon="mdi-chevron-down"
                    placeholder="DD-MM-YYYY"
                    readonly
                    single-line
                    dense
                  />
                </template>
                <v-date-picker
                  no-title
                  scrollable
                  v-model="form.baby.birthTime.date.raw"
                  @input="
                    $refs.menu_birthtime.save(
                      formatDate(form.baby.birthTime.date.raw),
                    )
                  "
                ></v-date-picker>
              </v-menu>
            </skeleton>
            <skeleton :isLoading="isLoading">
              <v-text-field
                type="time"
                :rules="rule"
                :readonly="isDisableInput"
                v-model="form.baby.birthTime.time"
                @keydown="preventDefaultBehaviour($event)"
                single-line
                dense
              />
            </skeleton>
          </div>
          <skeleton class="mb-3" :isLoading="isLoading">
            <label class="required">Jenis Persalinan</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-text-field
              placeholder="Masukkan keterangan"
              :rules="rule"
              :readonly="isDisableInput"
              v-model="form.baby.birthType"
              dense
              light
              single-line
            />
          </skeleton>
          <skeleton class="mb-3" :isLoading="isLoading">
            <label>Berat Badan</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-text-field
              v-model="form.baby.weight"
              :readonly="isDisableInput"
              type="number"
              placeholder="0"
              suffix="gram"
              dense
              light
              single-line
            />
          </skeleton>
        </v-col>
        <v-col class="pt-1">
          <skeleton class="mb-3" :isLoading="isLoading">
            <label>Panjang Badan</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-text-field
              v-model="form.baby.length"
              :readonly="isDisableInput"
              type="number"
              placeholder="0"
              suffix="cm"
              dense
              light
              single-line
            />
          </skeleton>
          <skeleton class="mb-3" :isLoading="isLoading">
            <label>Lingkar Dada</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-text-field
              placeholder="Masukkan keterangan"
              v-model="form.baby.chestCircumference"
              :readonly="isDisableInput"
              dense
              light
              single-line
            />
          </skeleton>
          <skeleton class="mb-3" :isLoading="isLoading">
            <label>Lingkar Kepala</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-text-field
              placeholder="Masukkan keterangan"
              v-model="form.baby.headCircumference"
              :readonly="isDisableInput"
              dense
              light
              single-line
            />
          </skeleton>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Skeleton from '../../../../../SharedComponent/Skeleton';
import { getAllStaff } from '@/fetchApi/MasterData/Staff';
import { getPatientByEmr } from '@/fetchApi/EMR';
import { createNamespacedHelpers } from 'vuex';
import formatMixin from '@/mixin/formatMixin';

const { mapGetters, mapMutations } = createNamespacedHelpers(
  'NeonatusAssessment',
);

const moment = require('moment-timezone');
const _ = require('lodash');

export default {
  name: 'MotherAndBabyInfo',
  mixins: [formatMixin],
  components: {
    Skeleton,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      loading: {
        staff: false,
        mother: false,
      },
      resource: {
        staff: [],
        patient: [],
      },
      menu: {
        visit: false,
        birthdate: false,
        fill: false,
      },
    };
  },
  watch: {
    search() {
      this.searchPatient(this);
    },
  },
  computed: {
    ...mapGetters(['getForm', 'getInitialState']),
    rule: {
      get() {
        return this.isEMR ? [] : [v => !!v || 'Wajib diisi'];
      },
    },
    isTimeFillReadonly: {
      get() {
        return this.form.isSaved && this.form.time.fill ? true : false;
      },
    },
    isTimeVisitReadonly: {
      get() {
        return this.form.isSaved && this.form.time.visit ? true : false;
      },
    },
    initialState: {
      get() {
        return this.getInitialState.information;
      },
    },
    today: {
      get() {
        return moment().format('YYYY-MM-DD');
      },
    },
    form: {
      get() {
        return this.getForm.information;
      },
    },
    isDisableInput: {
      get() {
        return this.isEMR;
      },
    },
  },
  methods: {
    ...mapMutations(['setForm', 'setInitialState']),
    searchPatient: _.debounce(v => v.searchMotherData(v.search), 600),
    preventDefaultBehaviour(event) {
      if (event.key === ' ') {
        event.preventDefault();
      }
    },
    mapMotherData(item) {
      this.setForm({
        key: 'information',
        payload: {
          ...this.form,
          mother: {
            ...this.form.mother,
            profession: item.profession,
            birthDate: item.birthDate,
            name: item.name,
          },
        },
      });
    },
    async searchMotherData() {
      try {
        this.loading.mother = true;

        const params = {
          search: this.search,
          page: 1,
        };

        const response = await getPatientByEmr(params);
        const { data } = response.data;
        this.resource.patient = data.map(item => {
          return {
            name: item.social.name,
            id_emr: item._id,
            rm_number: item.medical_record_number,
            text: `${item.medical_record_number} - ${
              item.social.name
            } (${this.formatDate(item.social.birth.date)})`,
            profession: item.social.profession,
            address: this.formatAddress(item.social.address),
            birthDate: {
              raw: item.social.birth.date,
              formatted: this.formatDate(item.social.birth.date),
            },
          };
        });
      } catch {
        this.resource.patient;
      } finally {
        this.loading.mother = false;
      }
    },
    async getStaff() {
      try {
        this.loading.staff = true;
        const params = {
          halaman: 1,
          itemCount: '',
          sortBy: '',
          keyword: '',
        };
        const response = await getAllStaff(params);
        this.resource.staff = response.data
          .filter(item =>
            item.role.role.some(
              item =>
                item.toLowerCase() === 'bidan' ||
                item.toLowerCase() === 'perawat',
            ),
          )
          .map(item => {
            return {
              name: item.detail.name,
              id: item._id,
            };
          });
      } catch {
        this.resource.staff = [];
      } finally {
        this.loading.staff = false;
      }
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
  },
  // component life cycle
  mounted() {
    this.getStaff();
  },
};
</script>

<style lang="scss" scoped>
.col {
  text-align: start;
  padding: 0 0.75vw;

  .date-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1.5vw;
  }
}

h4 {
  font-weight: 400;
  color: #137bc0;
  text-align: start;
  margin-bottom: 1.5vw;
}

label {
  font-weight: 500;
  font-size: 0.8vw;
}

.required:after {
  content: ' *';
  color: red;
}

::v-deep
  .v-text-field__slot
  input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

.gpa-flex {
  display: flex;
  align-items: center;

  span {
    margin-top: -0.5vw;
    margin-right: 0.5vw;
  }
}
</style>
