import {
  putBed,
  postBed,
  getAllBed,
  deleteBed,
} from '@/fetchApi/MasterData/Bed';

import globalStore from '../../../index';

const state = {
  dashboard: {
    dialog: false,
    filter: {
      halaman: 0,
      itemCount: 0,
      sortBy: '',
      keyword: '',
    },
  },
  form: {
    id: '',
    bed_number: null,
    availability: '',
  },
  items: {
    dataBed: {
      isLoading: false,
      data: [],
    },
    dataNumberBed: {
      isLoading: false,
      data: [],
    },
  },
};
const getters = {
  // Get State Dialog
  getDialog: state => state.dashboard.dialog,

  // Get State Items
  getItems: state => state.items,

  // Get State Form
  getForm: state => state.form,
};

const mutations = {
  // Set State Dialog
  setDialog(state, payload) {
    state.dashboard.dialog = payload;
  },

  // Set State Items
  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: value,
      isLoading,
    };
  },

  // Set State Form
  setForm(state, payload) {
    state.form = payload;
  },

  // Set Filter
  setFilter(state, payload) {
    state.dashboard.filter = payload;
  },

  // Clear Form
  clearForm(state) {
    state.form = {
      id: '',
      bed_number: null,
      availability: '',
    };
  },
};

const actions = {
  // Get Data
  // Gett All Data Bed
  async resolveGetAllBed(
    { commit, state },
    { halaman = 1, itemCount = 10, sortBy = '', keyword = '', all = false },
  ) {
    !all &&
      commit('setItems', {
        label: 'dataBed',
        value: [...state.items.dataBed.data],
        isLoading: true,
      });
    try {
      const bedResponse = await getAllBed({
        halaman,
        itemCount,
        sortBy,
        keyword,
      });

      const bedMapper = bedResponse?.data?.map(item => ({
        id: item._id,
        bed_number: item.bed_number,
        availability: item.availability,
        gender: item.gender,
      }));

      const bedNumberMapper = bedResponse?.data?.map(item => item.bed_number);

      !all &&
        commit('setItems', {
          label: 'dataBed',
          value: bedMapper,
          isLoading: false,
        });

      all &&
        commit('setItems', {
          label: 'dataNumberBed',
          value: bedNumberMapper,
          isLoading: false,
        });
      return bedResponse?.length;
    } catch {
      !all &&
        commit('setItems', {
          label: 'dataBed',
          value: [],
          isLoading: false,
        });
      return 0;
    }
  },
  // End Get Data

  // Post Data
  async resolvePostBed({ state, commit, dispatch }) {
    const payload = {
      ...state.form,
      timestamps: {
        created_by: globalStore?.getters?.userLoggedIn?.id,
      },
    };
    delete payload.id;
    delete payload.gender;

    try {
      const postBedRes = await postBed(payload);
      if (postBedRes.status === 200) {
        commit('setDialog', false);
        commit('clearForm');
        await dispatch('resolveGetAllBed', {
          ...state.dashboard.filter,
        });
      }
      return postBedRes.status;
    } catch (error) {
      return error;
    }
  },
  // End Post Data

  // Put Data
  async resolvePutBed({ state, commit, dispatch }) {
    const payload = {
      ...state.form,
      timestamps: {
        created_by: globalStore?.getters?.userLoggedIn?.id,
      },
    };
    delete payload.id;
    delete payload.gender;
    try {
      const putBedRes = await putBed(state.form.id, payload);
      if (putBedRes.status === 200) {
        commit('setDialog', false);
        commit('clearForm');
        await dispatch('resolveGetAllBed', {
          ...state.dashboard.filter,
        });
      }
      return putBedRes.status;
    } catch (error) {
      return error;
    }
  },
  // End Put Data

  // Delete Data
  async resolveDeleteBed(_, id) {
    try {
      const responseDeleteBed = await deleteBed(id);
      return responseDeleteBed;
    } catch (error) {
      return error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
