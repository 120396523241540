export default {
  methods: {
    mapDetailBPJSPatient(source, itemCount, page) {
      return source.map((patient, idx) => {
        return {
          _id: patient._id,
          idRegistration: patient.id_registration,
          no: idx + itemCount * (page - 1) + 1,
          createdAt: patient.created_at,
          referralPlanDate: patient.referral_plan_date,
          patientName: patient.patient.name,
          unit: patient.unit,
          noRM: patient.medical_record_number,
          diagnose: `${patient.diagnose.code} - ${patient.diagnose.description}`,
          doctorName: patient.doctor.name,
          hospitalRef: patient.detail.instansi_name,
          bpjsNumber: patient?.patient?.assurance?.number || '-',
          referralPoly: patient?.referral_poly || '-',
        };
      });
    },
  },
};
