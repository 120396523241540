<template>
  <v-expansion-panels class="physical-panel" v-model="expand" accordion flat>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <label>PEMERIKSAAN FISIK</label>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="py-3 px-6">
        <div
          v-for="(item, index) in show"
          :key="`physical-${index}`"
          class="d-flex justify-space-between my-2"
        >
          <p class="ma-0 pa-0">{{ item.label }}</p>
          <label class="result">{{ item.result }}</label>
        </div>
        <v-btn text dark @click="showAction()" class="text-capitalize mt-2">
          <p class="primary--text subtitle-2">
            Lihat lebih {{ isShowAll ? 'sedikit' : 'banyak' }}
          </p>
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'PhysicalCheckCard',
  props: {
    data: { type: Array },
  },
  data() {
    return {
      expand: null,
      show: [],
      isShowAll: false,
    };
  },
  watch: {
    isShowAll(value) {
      if (value) {
        this.show = this.data;
      } else {
        this.show = this.data.slice(0, 5);
      }
    },
  },
  mounted() {
    this.show = this.data.slice(0, 5);
  },
  methods: {
    showAction() {
      this.isShowAll = !this.isShowAll;
    },
  },
};
</script>

<style lang="scss" scoped>
.physical-panel {
  width: 100%;
  margin-bottom: 1dvh;

  label {
    font-weight: 500;
  }

  .result {
    width: fit-content;
    max-width: 50%;
    text-align: start;
  }
}
</style>
