import Constant from '@/const';
import axios from 'axios';

const deleteRegisterLab = async idLab => {
  try {
    return await axios.put(
      Constant.apiUrl.concat(`/supportservice/laboratory/cancel/${idLab}`),
    );
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default deleteRegisterLab;
