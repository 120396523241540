import Constant from '@/const';
import axios from 'axios';

const getAlmostExpiredDrugData = () => {
  try {
    const response = axios.get(
      Constant.apiUrl.concat('/report/visitors/dashboard/drug'),
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getAlmostExpiredDrugData;
