import Constant from '@/const';
import axios from 'axios';

const getPatientNotFoundCount = async params => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/ihs/encounter/patient-notfound'),
      {
        params,
      },
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getPatientNotFoundCount;
