import Constant from '@/const';
const axios = require('axios');

const postUploadFile = async payload => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat('/upload'),
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default postUploadFile;
