import {
  getAllStaff,
  putChangePassword,
  getStaffById,
  postCheckNIPAvail,
} from '@/fetchApi/MasterData/Staff';

const state = {
  dashboard: {
    dialog: false,
    snackbar: {
      show: false,
      color: 'success',
      text: '',
    },
  },
  formType: false,
  items: {
    dataStaff: {
      isLoading: false,
      data: [],
    },
    form: {
      isLoading: false,
      data: {
        id: '',
        nip: '',
        name: '',
        nik: '',
        gender: '',
        birth: {
          date: '',
          place: '',
        },
        address: '',
        telp: '',
        religion: '',
        password: '',
        bloodType: '',
        str: '',
        strLimit: '',
        sip: '',
        sipLimit: '',
        sik: '',
        sikLimit: '',
        specialist: '',
        practiceStartDate: '',
        bank: {
          name: '',
          no: '',
          id: '',
        },
        role: {
          role: [],
          rolePrimary: '',
          roleSecondary: [],
          unit: [],
          detail: '',
          status: true,
        },
      },
    },
  },
};
const getters = {
  // Get State Dialog
  getDialog: state => state.dashboard.dialog,

  // Get State Snackbar
  getSnackbar: state => state.dashboard.snackbar,

  // Get State Items
  getItems: state => state.items,

  getFormType: state => state.formType,
};
const mutations = {
  clearFormState(state) {
    state.items.form.data = {
      id: '',
      nip: '',
      name: '',
      nik: '',
      gender: '',
      birth: {
        date: '',
        place: '',
      },
      address: '',
      telp: '',
      religion: '',
      password: '',
      bloodType: '',
      str: '',
      strLimit: '',
      sip: '',
      sipLimit: '',
      sik: '',
      sikLimit: '',
      specialist: '',
      practiceStartDate: '',
      bank: {
        name: '',
        no: '',
        id: '',
      },
      role: {
        role: [],
        rolePrimary: '',
        roleSecondary: [],
        unit: [],
        detail: '',
        status: null,
      },
    };
  },
  setFormType(state, payload) {
    state.formType = payload;
  },
  // Set State Dialog
  setDialog(state, payload) {
    state.dashboard.dialog = payload;
  },

  // Set State Snackbar
  setSnackbar(state, payload) {
    state.dashboard.snackbar = payload;
  },

  // Set State Items
  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: value,
      isLoading,
    };
  },
};
const actions = {
  // Get Data
  // Gett All Data Staff
  async resolveGetAllStaff(
    { commit, state },
    { halaman, itemCount, sortBy, keyword },
  ) {
    commit('setItems', {
      label: 'dataStaff',
      value: [...state.items.dataStaff.data],
      isLoading: true,
    });
    try {
      const staffResponse = await getAllStaff({
        halaman,
        itemCount,
        sortBy,
        keyword,
      });

      const staffMapper = staffResponse?.data?.map((item, index) => ({
        no: index + itemCount * halaman - itemCount + 1,
        address: item.detail.address,
        bank:
          item.detail.bank !== undefined
            ? {
                id_bank: item.detail.bank.id_bank,
                account: item.detail.bank.account,
              }
            : {
                id_bank: '',
                account: '',
              },
        birth: {
          city: item.detail.birth.city,
          date: item.detail.birth.date,
        },
        blood_type: item.detail.blood_type,
        end_sik: item.detail.end_sik,
        end_sip: item.detail.end_sip,
        end_str: item.detail.end_str,
        gender: item.detail.gender,
        name: item.detail.name,
        nik: item.detail.nik,
        phone_no: item.detail.phone_no || '-',
        religion: item.detail.religion,
        sik: item.detail.sik,
        sip: item.detail.sip,
        specialist: item.detail.specialize || '-',
        start_practice: item.detail.start_practice,
        str: item.detail.str,
        nip: item.nip.toString(),
        role: {
          detail: item.role.detail,
          role: item.role.role,
          status: item.role.status,
          unit: item.role.unit,
        },
        id: item._id.toString(),
      }));
      commit('setItems', {
        label: 'dataStaff',
        value: staffMapper,
        isLoading: true,
      });
      return staffResponse.length;
    } catch {
      commit('setItems', {
        label: 'dataStaff',
        value: [],
        isLoading: false,
      });
    } finally {
      commit('setItems', {
        label: 'dataStaff',
        value: [...state.items.dataStaff.data],
        isLoading: false,
      });
    }
  },
  // End Get data

  async resolveGetStaffById({ commit, state }, { id_staff }) {
    try {
      commit('setItems', {
        label: 'form',
        value: state.items.form.data,
        isLoading: true,
      });
      const response = await getStaffById(id_staff);
      const { detail, nip, role, _id } = response.data.data;
      const formData = {
        id: _id,
        nip: nip,
        name: detail.name,
        nik: detail.nik,
        gender: detail.gender,
        birth: {
          date: detail.birth?.date,
          place: detail.birth?.city,
        },
        address: detail.address,
        telp: detail.phone_no,
        religion: detail.religion,
        bloodType: detail.blood_type,
        str: detail.str,
        strLimit: detail.end_str,
        sip: detail.sip,
        sipLimit: detail.end_sip,
        sik: detail.sik,
        sikLimit: detail.end_sik,
        specialist: detail.specialize,
        practiceStartDate: detail.start_practice,
        bank: {
          bank: {
            name: detail.bank?.id_bank?.name,
            value: detail.bank?.id_bank?._id,
          },
          account: detail.bank?.account,
        },
        role: {
          role: role?.role,
          rolePrimary: role?.role[0].toLowerCase(),
          roleSecondary: role?.role?.splice(1).map(item => item.toLowerCase()),
          status: role?.status,
          unit: role?.unit,
          detail: role?.detail,
        },
      };
      commit('setItems', { label: 'form', value: formData });
    } catch {
      commit('setSnackbar', {
        show: true,
        color: 'error',
        text: 'Terjadi kesalahan, gagal mendapatkan data staf',
      });
    } finally {
      commit('setItems', {
        label: 'form',
        value: state.items.form.data,
        isLoading: false,
      });
    }
  },

  async resolvePutChangePassword({ commit }, { id_staff, password }) {
    try {
      const putChangePasswordRes = await putChangePassword({
        id: id_staff,
        password,
      });
      if (putChangePasswordRes.status === 200) {
        commit('setSnackbar', {
          show: () => {
            commit('setSnackbar', { ...state.dashboard.snackbar, show: true });
            return setTimeout(() => {
              commit('setSnackbar', {
                ...state.dashboard.snackbar,
                show: false,
              });
            }, 2000);
          },
          text: 'Password berhasil diperbaharui',
          color: 'success',
        });
      }
    } catch (error) {
      commit('setSnackbar', {
        show: () => {
          commit('setSnackbar', { ...state.dashboard.snackbar, show: true });
          return setTimeout(() => {
            commit('setSnackbar', { ...state.dashboard.snackbar, show: false });
          }, 2000);
        },
        text: 'Password gagal diperbaharui',
        color: 'error',
      });
      return error;
    }
  },

  // Resolve Check NIP
  async resolvePostCheckNIPAvail({ state }, nip) {
    const payload = {
      id: state.items.form.data.id || '',
      nip: nip,
    };
    const response = await postCheckNIPAvail(payload);
    const { isAvailable } = response.data.data;
    try {
      return isAvailable;
    } catch (error) {
      return error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
