<template>
  <v-card height="350">
    <v-card-title class="d-flex justify-center font-weight-bold mb-3">
      Detail Pembayaran Jasa Medis
    </v-card-title>
    <v-row class="main-container">
      <v-col cols="4">
        <div class="data-item">
          <label class="subtitle-2 font-weight-bold text-left"
            >Tgl Pembayaran</label
          >
          <label class="subtitle-2 text-left pl-2 font-weight-regular">{{
            formatDate(selectedData.paymentDate)
          }}</label>
          <label class="subtitle-2 font-weight-bold text-left"
            >Periode Dibayar</label
          >
          <label class="subtitle-2 text-left pl-2 font-weight-regular">{{
            formatDate(selectedData.paidPeriode)
          }}</label>
          <label class="subtitle-2 font-weight-bold text-left"
            >Nama Dokter</label
          >
          <label class="subtitle-2 text-left pl-2 font-weight-regular">{{
            selectedData.name
          }}</label>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="data-item">
          <label class="subtitle-2 font-weight-bold text-left"
            >Jasa Medis BPJS</label
          >
          <label class="subtitle-2 text-left pl-8 font-weight-regular">{{
            currency(selectedData.bpjs)
          }}</label>
          <label class="subtitle-2 font-weight-bold text-left"
            >Jasa Medis Umum</label
          >
          <label class="subtitle-2 text-left pl-8 font-weight-regular">{{
            currency(selectedData.general)
          }}</label>
          <label class="subtitle-2 font-weight-bold text-left"
            >Jasa Medis Asuransi Lainnya</label
          >
          <label class="subtitle-2 text-left pl-8 font-weight-regular">{{
            currency(selectedData.otherAssurance)
          }}</label>
          <label class="subtitle-2 font-weight-bold text-left"
            >Total Jasa Medis</label
          >
          <label class="subtitle-2 text-left pl-8 font-weight-regular">{{
            currency(selectedData.medserviceTotal)
          }}</label>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="data-item total-patient">
          <label class="subtitle-2 font-weight-bold text-left"
            >Jumlah Pasien BPJS</label
          >
          <label class="subtitle-2 text-left font-weight-regular"
            >{{ selectedData.bpjsPatient }} Pasien</label
          >
          <label class="subtitle-2 font-weight-bold text-left"
            >Jumlah Pasien Umum</label
          >
          <label class="subtitle-2 text-left font-weight-regular"
            >{{ selectedData.generalPatient }} Pasien</label
          >
          <label class="subtitle-2 font-weight-bold text-left"
            >Jumlah Pasien Asuransi Lain</label
          >
          <label class="subtitle-2 text-left font-weight-regular"
            >{{ selectedData.assurancePatient }} Pasien</label
          >
        </div>
      </v-col>
    </v-row>
    <v-card-footer class="d-flex justify-end pr-15 align-center">
      <v-btn
        @click="$emit('close-form')"
        text
        class="grey--text font-weight-medium text-capitalize"
        >Tutup</v-btn
      >
      <v-btn color="primary" height="30" depressed class="font-weight-bold ml-4"
        >Cetak</v-btn
      >
    </v-card-footer>
  </v-card>
</template>

<script>
import formatMixin from '@/mixin/formatMixin';
import moment from 'moment-timezone';

export default {
  name: 'DetailMedServicePayment',
  mixins: [formatMixin],
  data() {
    return {};
  },
  props: ['selectedData'],
  mounted() {
    this.detailPayment();
  },
  methods: {
    formatDate(date) {
      const d = date.split('-');
      const d1 = d[0];
      const d2 = d[1];
      if (d.length > 1) {
        return `${moment(d1, 'DD/MM/YYYY')
          .locale('id')
          .format('D MMMM Y')} s/d ${moment(d2, 'DD/MM/YYYY')
          .locale('id')
          .format('D MMMM Y')}`;
      } else {
        return moment(date, 'DD/MM/YYYY')
          .locale('id')
          .format('D MMMM Y');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  width: 99.9%;
  align-self: center;
  padding: 15px 5px 5px 25px;
  margin-bottom: 5px;
}
.data-item {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr;
  row-gap: 20px;
  &.total-patient {
    grid-template-columns: 2fr 1fr;
  }
}
</style>
