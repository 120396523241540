import Constant from '@/const';
import axios from 'axios';

const postRegistrationPcare = async idRegistration => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat(`/patient/reregistration/pcare/${idRegistration}`),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default postRegistrationPcare;
