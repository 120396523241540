<template>
  <v-container fluid :class="`d-block ${mini ? 'mini' : ''} ${containerClass}`">
    <v-scroll-x-transition hide-on-leave>
      <c-p-p-t-table
        :isEMR="isEMR"
        @show-snackbar="showSnackbar"
        v-if="selectedForm === 'dashboard'"
      />
    </v-scroll-x-transition>
    <v-scroll-x-transition hide-on-leave>
      <input-c-p-p-t-form
        @show-snackbar="showSnackbar"
        :isEMR="isEMR"
        v-if="selectedForm === 'form-cppt'"
      />
    </v-scroll-x-transition>
    <v-snackbar
      timeout="4000"
      content-class="d-flex justify-space-between white--text align-center"
      elevation="0"
      small
      light
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn fab small text @click.native="() => (snackbar.show = false)"
        ><v-icon color="white" small>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import CPPTTable from './CPPTTable.vue';
import InputCPPTForm from './InputCPPTForm.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations } = createNamespacedHelpers('CPPTStore');

export default {
  name: 'CPPTDashboard',
  components: {
    InputCPPTForm,
    CPPTTable,
  },
  data() {
    return {
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
    };
  },
  props: {
    mini: {
      type: Boolean,
      default: true,
    },
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['getSelectedForm']),
    selectedForm: {
      get() {
        return this.getSelectedForm;
      },
    },
    containerClass: {
      get() {
        switch (this.selectedForm) {
          case 'dashboard':
            return 'pt-10 pl-15 pr-5';
          default:
            return 'px-0';
        }
      },
    },
  },
  beforeDestroy() {
    this.clearCpptState('form');
    this.clearCpptState('table');
  },
  methods: {
    ...mapMutations(['navigateTo', 'clearCpptState']),
    showSnackbar(data) {
      this.snackbar = {
        show: data.show,
        text: data.text,
        color: data.color,
      };
    },
  },
  created() {
    this.navigateTo('dashboard');
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 80%;
  &.mini {
    width: 95%;
  }
}

::v-deep .v-snack__content {
  padding: 0px 0px 0px 1vw;
}
</style>
