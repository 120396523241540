const moment = require('moment-timezone');

const mapDetailCppt = data => {
  let assessment;
  const isDoctor = data.general_information.ppa.role.some(
    item => item.toLowerCase() === 'dokter',
  );
  if (isDoctor) {
    assessment = {
      diagnose: data.assesment.doctor.diagnose.map(i => {
        return {
          name: i.name,
          diagnose: i?.id_diagnose?.code
            ? {
                name: `${i.id_diagnose.code} ${i.id_diagnose.description}`,
                id: i.id_diagnose._id,
              }
            : '',
          loading: false,
        };
      }),
      planning: data.assesment.doctor.planning,
    };
  } else {
    assessment = {
      diagnose: data.assesment.nurse.diagnose.map(i => {
        return {
          diagnose: i.name
            ? {
                name: i.name || '',
                id: i.id_diagnose?._id || '',
              }
            : null,
          planningResource: [],
          loading: false,
          planning: i.planning.map(v => {
            return {
              planning: v.name
                ? {
                    name: v.name || '',
                    id: v.id_planning || '',
                  }
                : null,
            };
          }),
        };
      }),
    };
  }
  return {
    generalInformation: {
      ppa: {
        name: data.general_information.ppa.name,
        role: data.general_information.ppa.role,
        id: data.general_information.ppa._id,
      },
      bed_number: data.general_information.bed_number,
      date: moment(data.general_information.datetime).format('DD-MM-YYYY'),
      time: moment(data.general_information.datetime).format('HH:mm'),
    },
    subjective: {
      anamnesis: data.subjective.anamnesis,
    },
    objective: {
      sistole: data.objective.sistol,
      diastole: data.objective.diastol,
      temperature: data.objective.temperature,
      heartRate: data.objective.heart_rate,
      rr: data.objective.respiration_rate,
      saturation: data.objective.saturation,
      gds: data.objective.gds,
      other: data.objective.other,
    },
    assessment: {
      doctor: isDoctor ? assessment : undefined,
      nurse: !isDoctor ? assessment : undefined,
      note: data.assesment.note,
    },
    ppaInstruction: data.ppa_instruction.staff_instruction,
  };
};

const mapToPayload = ({ state, role }) => {
  const isDoctor = role.some(item => item.toLowerCase() === 'dokter');
  let assesment;
  if (isDoctor) {
    assesment = {
      doctor: {
        diagnose: defaultValue(
          state.form.assessment.doctor.diagnose
            .filter(i => i.name)
            .map(item => {
              return {
                name: item.name,
                id_diagnose: item.diagnose.id,
              };
            }),
          {
            name: '',
            id_diagnose: null,
          },
        ),
        planning: defaultValue(
          state.form.assessment.doctor.planning.filter(i => i),
          [''],
        ),
      },
      note: state.form.assessment.note,
    };
  } else {
    assesment = {
      nurse: {
        diagnose: defaultValue(
          state.form.assessment.nurse.diagnose
            .filter(i => i.diagnose?.name || i.diagnose)
            .map(i => {
              return {
                name: i.diagnose?.name || i.diagnose,
                id_diagnose: i.diagnose.id || null,
                planning: i.planning.map(p => {
                  return {
                    name: p.planning?.name || p.planning,
                    id_planning: p.planning.id || null,
                  };
                }),
              };
            }),
          [
            {
              name: '',
              id_diagnose: null,
              planning: [
                {
                  id_planning: null,
                  name: '',
                },
              ],
            },
          ],
        ),
      },
      note: state.form.assessment.note,
    };
  }

  return {
    id_emr: state.id.emr,
    id_inpatient: state.id.inpatient,
    history: [
      {
        general_information: {
          ppa: state.form.generalInformation.ppa.id,
          bed_number: state.form.generalInformation.bed_number,
          datetime: moment(
            `${state.form.generalInformation.date} ${state.form.generalInformation.time}`,
            'DD-MM-YYYY HH:mm',
          ).format(),
        },
        subjective: {
          anamnesis: state.form.subjective.anamnesis,
        },
        objective: {
          sistol: state.form.objective.sistole,
          diastol: state.form.objective.diastole,
          temperature: state.form.objective.temperature,
          heart_rate: state.form.objective.heartRate,
          respiration_rate: state.form.objective.rr,
          saturation: state.form.objective.saturation,
          gds: state.form.objective.gds,
          other: state.form.objective.other,
        },
        ppa_instruction: {
          staff_instruction: state.form.ppaInstruction,
        },
        assesment,
      },
    ],
    timestamps: {
      created_at: moment().format(),
      created_by: state.form.generalInformation.ppa.id,
    },
  };
};

const mapInitialObjectiveData = data => {
  return {
    sistole: data?.sistol || '',
    diastole: data?.diastol || '',
    temperature: data?.temperature || '',
    heartRate: data?.heart_rate || '',
    rr: data?.respiration_rate || '',
    saturation: data?.saturation || '',
    gds: data?.gds || '',
    other: '',
  };
};

const mapInitialStateData = source => {
  let result = {
    time: source.generalInformation.time,
    anamnesis: source.subjective.anamnesis,
    ...source.objective,
    ppaInstruction: source.ppaInstruction,
    assessmentNote: source.assessment.note,
    doctor: {
      diagnose: [
        {
          name: '',
          icd: '',
        },
      ],
      planning: [''],
    },
    nurse: [
      {
        diagnose: '',
        planning: [''],
      },
    ],
  };

  const doctor = {
    doctor: {
      diagnose: source?.assessment?.doctor?.diagnose?.map(item => {
        return {
          name: item?.name,
          icd: item?.diagnose?.id || '',
        };
      }),
      planning: source?.assessment?.doctor?.planning,
    },
  };

  const nurse = {
    nurse: source?.assessment?.nurse?.diagnose?.map(item => {
      return {
        diagnose: item.diagnose?.name || item?.diagnose || '',
        planning: item.planning?.map(i => i.planning || ''),
      };
    }),
  };

  if (
    source.generalInformation.ppa.role.some(
      item => item.toLowerCase() === 'dokter',
    )
  ) {
    result = {
      ...result,
      ...doctor,
    };
  } else {
    result = {
      ...result,
      ...nurse,
    };
  }

  return result;
};

const mapCompareData = source => {
  return {
    anamnesis: { value: source.subjective?.anamnesis || '-', isChanged: false },
    sistole: { value: source?.objective?.sistol || '-', isChanged: false },
    diastole: { value: source?.objective?.diastol || '-', isChanged: false },
    temperature: {
      value: source?.objective?.temperature || '-',
      isChanged: false,
    },
    heartRate: {
      value: source?.objective?.heart_rate || '-',
      isChanged: false,
    },
    rr: { value: source?.objective?.respiration_rate || '-', isChanged: false },
    saturation: {
      value: source?.objective?.saturation || '-',
      isChanged: false,
    },
    gds: {
      value: source?.objective?.gds || '-',
      isChanged: false,
    },
    otherObjective: {
      value: source?.objective?.other || '-',
      isChanged: false,
    },
    assessmentNote: {
      value: source.assesment.note || '-',
      isChanged: false,
    },
    ppaInstruction: {
      value: source.ppa_instruction.staff_instruction || '-',
      isChanged: false,
    },
  };
};

const mapCompareDataNurseAssessment = source => {
  return {
    nurse: {
      value: source?.map(diagnose => {
        return {
          name: diagnose?.name || '-',
          isChanged: false,
          planning: {
            value: diagnose?.planning?.map(plan => plan.name),
            isChanged: false,
          },
        };
      }),
      isChanged: false,
    },
  };
};

const mapCompareDataDoctorAssessment = source => {
  return {
    doctor: {
      diagnose: {
        value: source?.diagnose?.map(
          i =>
            `${i.name || ''}  ${
              i?.id_diagnose?.code
                ? `(${i.id_diagnose?.code} ${i.id_diagnose?.description})`
                : ''
            } `,
        ),
        isChanged: false,
      },
      planning: {
        value: source?.planning?.map(i => i),
        isChanged: false,
      },
    },
  };
};

const defaultValue = (array, defaultValue) => {
  return array?.length ? array : defaultValue;
};

const mapCpptData = source => {
  let assessment;

  if (
    source?.general_information?.ppa.role.some(
      item => item.toLowerCase() === 'dokter',
    )
  ) {
    assessment = {
      diagnose: source?.assesment?.doctor?.diagnose?.map(d => d?.name),
      icd: source?.assesment?.doctor?.diagnose?.map(
        i => `${i.id_diagnose?.code} ${i.id_diagnose?.description}`,
      ),
      planning: source?.assesment?.doctor?.planning,
      note: source?.assesment?.note || '-',
    };
  } else {
    assessment = {
      diagnose: source?.assesment?.nurse?.diagnose?.map(i => {
        return {
          name: i.name || '-',
          planning: i?.planning?.map(v => v.name),
        };
      }),
      note: source.assesment?.note || '-',
    };
  }

  return {
    bed: source?.general_information?.bed_number,
    history_id: source._id,
    date: moment(source?.general_information?.datetime)?.format('DD-MM-YYYY'),
    dateHistory: moment(source?.general_information?.datetime)?.format(
      'DD MMMM YYYY',
    ),
    time: moment(source?.general_information?.datetime)?.format('HH:mm'),
    ppa: {
      name: source?.general_information?.ppa.name,
      role: source?.general_information?.ppa.role,
      id: source?.general_information?.ppa._id,
    },
    anamnesis: source?.subjective?.anamnesis || '-',
    objective: {
      sistole: source?.objective?.sistol || '-',
      diastole: source?.objective?.diastol || '-',
      temperature: source?.objective?.temperature || '-',
      heartRate: source?.objective?.heart_rate || '-',
      rr: source?.objective?.respiration_rate || '-',
      saturation: source?.objective?.saturation || '-',
      gds: source?.objective?.gds || '-',
      other: source?.objective?.other || '-',
    },
    assessment,
    ppaInstruction: source?.ppa_instruction?.staff_instruction.trim() || '-',
    timestamps: {
      date: moment(source.timestamps?.created_at).format('DD-MM-YYYY'),
      time: moment(source.timestamps?.created_at).format('HH:mm'),
    },
  };
};

module.exports = {
  mapCompareDataDoctorAssessment,
  mapCompareDataNurseAssessment,
  mapInitialObjectiveData,
  mapInitialStateData,
  mapCompareData,
  mapDetailCppt,
  mapToPayload,
  mapCpptData,
};
