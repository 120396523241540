<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Masukkan Entry</h3>
    </v-card-title>
    <v-row no-gutters class="px-10" style="height: 73vh; overflow: auto;">
      <div class="text-left pb-5 mb-5">
        <h2 class="primary--text">Debit</h2>
        <template v-for="(debit, idx) in debits">
          <div class="debit-style" :key="'debit-' + idx">
            <label class="font-weight-bold body-2">Kode Akun</label>
            <v-combobox
              dense
              :items="resource.accountCode"
              :loading="isLoading"
              item-text="account_code"
              return-object
              v-model="debits[idx].account"
            ></v-combobox>

            <label class="font-weight-bold body-2">Nama Akun</label>
            <v-combobox
              dense
              :items="resource.accountCode"
              :loading="isLoading"
              v-model="debits[idx].account"
              item-text="name"
            ></v-combobox>

            <label class="font-weight-bold body-2">Tipe</label>
            <v-select
              dense
              :items="resource.type"
              v-model="debits[idx].type"
              @change="debiturName(idx)"
            ></v-select>

            <label class="font-weight-bold body-2">Nama</label>
            <v-combobox
              dense
              v-model="debits[idx].name"
              :items="resource.debitur"
              item-text="name"
              return-object
            ></v-combobox>

            <label class="font-weight-bold body-2">Jumlah</label>
            <v-currency-field
              dense
              v-model.number="debits[idx].amount"
              :decimal-length="2"
            ></v-currency-field>

            <v-btn icon @click="deleteItem('debit', idx)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn icon color="primary" v-if="idx === 0" @click="addDebit()"
              ><v-icon>mdi-plus-circle</v-icon></v-btn
            >
          </div>
        </template>
      </div>
      <div class="text-left pb-5 mb-5">
        <h2 class="primary--text">Kredit</h2>
        <template v-for="(credit, idx) in credits">
          <div class="debit-style" :key="'credit-' + idx">
            <label class="font-weight-bold body-2">Kode Akun</label>
            <v-combobox
              dense
              :items="resource.accountCode"
              item-text="account_code"
              return-object
              v-model="credits[idx].account"
            ></v-combobox>

            <label class="font-weight-bold body-2">Nama Akun</label>
            <v-combobox
              dense
              v-model="credits[idx].account"
              :items="resource.accountCode"
              item-text="name"
            ></v-combobox>

            <label class="font-weight-bold body-2">Tipe</label>
            <v-select
              dense
              :items="resource.type"
              v-model="credits[idx].type"
              @change="crediturName(idx)"
            ></v-select>

            <label class="font-weight-bold body-2">Nama</label>
            <v-autocomplete
              dense
              :items="resource.creditur"
              v-model="credits[idx].name"
              item-text="name"
              return-object
            ></v-autocomplete>

            <label class="font-weight-bold body-2">Jumlah</label>
            <v-currency-field
              dense
              v-model.number="credits[idx].amount"
              :decimal-length="2"
            ></v-currency-field>

            <v-btn icon @click="deleteItem('credit', idx)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn icon color="primary" v-if="idx === 0" @click="addCredit()"
              ><v-icon>mdi-plus-circle</v-icon></v-btn
            >
          </div>
        </template>
      </div>

      <v-row no-gutters justify="center">
        <v-card width="500" color="#D9EDFF" class="pa-4" flat height="55">
          <v-row no-gutters>
            <v-col>
              <label class="body-2 pr-3">Total Debit</label>
              <label class="font-weight-bold body-2">{{
                formatted(totalDebit)
              }}</label>
            </v-col>
            <v-col class="pl-5">
              <label class="body-2 pr-3">Total Kredit</label>
              <label
                class="font-weight-bold body-2"
                :class="
                  isBalance || totalDebit === 0 ? 'black--text' : 'red--text'
                "
                >{{ formatted(totalCredit) }}</label
              >
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-row>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        color="primary"
        class="text-none"
        width="112"
        @click="$emit('close-dialog')"
        >Kembali</v-btn
      >
      <v-btn
        color="primary"
        class="text-none"
        depressed
        @click="saveEntries()"
        width="112"
        :disabled="!isBalance"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';

const axios = require('axios');

export default {
  name: 'EntryJournal',
  data() {
    return {
      resource: {
        accountCode: [],
        type: ['Supplier', 'Instansi Rekanan', 'Karyawan', 'Pasien'],
        debitur: [],
        creditur: [],
      },
      debits: [
        {
          account: '',
          type: '',
          name: '',
          amount: null,
        },
      ],
      credits: [
        {
          account: '',
          type: '',
          name: '',
          amount: null,
        },
      ],
      isLoading: false,
    };
  },
  mounted() {
    this.getAccount();
  },
  computed: {
    isBalance() {
      return (
        this.totalDebit === this.totalCredit &&
        this.totalDebit !== 0 &&
        this.totalCredit !== 0
      );
    },
    totalDebit() {
      return this.debits.reduce((a, { amount }) => a + amount, 0);
    },
    totalCredit() {
      return this.credits.reduce((a, { amount }) => a + amount, 0);
    },
  },
  methods: {
    addDebit() {
      this.debits.push({
        account: '',
        type: '',
        name: '',
        amount: null,
      });
    },
    addCredit() {
      this.credits.push({
        account: '',
        type: '',
        name: '',
        amount: null,
      });
    },
    formatted(val) {
      var format = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      });
      return val ? format.format(val) : format.format(0);
    },
    deleteItem(type, idx) {
      if (idx !== 0) {
        if (type === 'debit') {
          this.debits.splice(idx, 1);
        } else {
          this.credits.splice(idx, 1);
        }
      }
    },
    getAccount() {
      this.isLoading = true;
      axios
        .get(Constant.apiUrl.concat('/finance/ledger/account?target=selection'))
        .then(response => {
          const { data } = response.data;
          this.resource.accountCode = data.filter(x => x.meta.level === 6);
          this.isLoading = false;
        });
    },
    async getStaff() {
      const data = await axios.get(
        Constant.apiUrl.concat(`/master/staff?page=1&itemCount=&sort=&search=`),
      );
      return data.data;
    },
    async getPatient() {
      const data = await axios.get(
        Constant.apiUrl.concat(
          `/patient/emr/all?page=1&itemCount=&search=&sort= `,
        ),
      );
      return data.data;
    },
    async debiturName(idx) {
      if (this.debits[idx].type === 'Karyawan') {
        const { data } = await this.getStaff();
        this.resource.debitur = data
          .map(arr => {
            return {
              id: arr._id,
              name: arr.detail.name,
              role: {
                detail: arr.role.detail,
                role: arr.role.role ? arr.role.role : '',
                status: arr.role.status,
              },
            };
          })
          .filter(x => x.role.status);
      } else if (this.debits[idx].type === 'Pasien') {
        const { data } = await this.getPatient();
        this.resource.debitur = data.map(arr => {
          return {
            id: arr._id,
            name: arr.social.name,
          };
        });
      }
    },
    async crediturName(idx) {
      if (this.credits[idx].type === 'Karyawan') {
        const { data } = await this.getStaff();
        this.resource.creditur = data.map(arr => {
          return {
            id: arr._id,
            name: arr.detail.name,
            role: {
              detail: arr.role.detail,
              role: arr.role.role ? arr.role.role : '',
            },
          };
        });
      } else if (this.credits[idx].type === 'Pasien') {
        const { data } = await this.getPatient();
        this.resource.creditur = data.map(arr => {
          return {
            id: arr._id,
            name: arr.social.name,
          };
        });
      }
    },
    saveEntries() {
      const list = this.debits
        .map(arr => {
          return {
            accountCode: {
              id: arr.account._id,
              code: arr.account.account_code,
              name: arr.account.name,
            },
            type: arr.type,
            name: arr.name,
            debit: arr.amount,
            credit: 0,
          };
        })
        .concat(
          this.credits.map(arr => {
            return {
              accountCode: {
                id: arr.account._id,
                code: arr.account.account_code,
                name: arr.account.name,
              },
              type: arr.type,
              name: arr.name,
              debit: 0,
              credit: arr.amount,
            };
          }),
        );
      this.$emit('entry-table', list);
      this.$emit('close-dialog');
    },
  },
};
</script>

<style lang="scss" scoped>
.debit-style {
  display: grid;
  grid-template-columns: repeat(5, auto 9vw) 2vw 2vw;
  column-gap: 2vw;
  align-items: baseline;
}
</style>
