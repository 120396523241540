<template>
  <v-container fluid class="pa-0">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      :mini-variant-width="isLargeDisplay ? 90 : 60"
      color="#DEEEF9"
      class="pa-2 sidebar"
      permanent
      :width="isLargeDisplay ? 450 : 400"
    >
      <v-list dense class="pa-0">
        <v-list-item
          :class="{
            'mx-auto': mini,
            'pa-0': mini && !isLargeDisplay,
            'pa-2 pl-4': !mini && isLargeDisplay,
            'pl-3 px-0': !mini && !isLargeDisplay,
          }"
          class="py-2 mb-5"
        >
          <v-btn :small="!isLargeDisplay" color="black" text fab v-if="mini"
            ><v-icon :small="!isLargeDisplay">mdi-menu</v-icon></v-btn
          >
          <h2 v-if="isLargeDisplay" class="sidebar-title">
            EMR Rawat Inap
          </h2>
          <h3 v-else class="sidebar-title mr-10">
            EMR Rawat Inap
          </h3>
          <v-btn small class="ml-auto" fab text @click.stop="mini = !mini">
            <v-icon :large="isLargeDisplay">mdi-chevron-left</v-icon>
          </v-btn></v-list-item
        >
        <template v-if="!mini">
          <div
            class="list"
            v-for="(item, index) in itemList"
            :key="`list-${index}`"
            @click="changeForm(item.label)"
            :class="{ active: selectedForm === item.label }"
          >
            <div class="label-container">
              <label>{{ item.label }}</label>
            </div>
          </div>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-card class="pa-0 main" tile>
      <inpatient-e-m-r-header
        v-if="headerData"
        :data="headerData"
        @close="closeForm"
      />
      <early-inpatient-assessment
        :isEMR="isEMR"
        v-if="selectedForm === 'Assesmen Awal Rawat Inap'"
      />
      <obstetrics-assessment
        v-if="selectedForm === 'Assesmen Kebidanan Ibu Hamil'"
        :patient-data="patientData"
        :isEMR="isEMR"
        @close="closeForm"
      />
      <labour-assessment
        v-if="selectedForm === 'Assesmen Persalinan'"
        :patient-data="patientData"
        @close="closeForm"
        :isEMR="isEMR"
      />
      <neonatus-assessment
        :baby="{
          ...patientData,
          birthDateAndAge,
        }"
        :isEMR="isEMR"
        v-if="selectedForm === 'Assesmen Neonatus'"
      />

      <drug-record
        v-if="selectedForm === 'Catatan Pemberian Obat'"
        :patient-data="patientData"
      />

      <c-p-p-t-dashboard
        :isEMR="isEMR"
        v-if="selectedForm === 'CPPT'"
        :mini="mini"
      />
      <general-consent-form
        is-inpatient-emr
        service-type="Rawat Inap"
        :rm-number="patientData.rmNumber"
        :id-inpatient="patientData.id_inpatient"
        v-if="selectedForm === 'General Consent'"
      />
      <list-informed-consent v-if="selectedForm === 'Informed Consent'" />
      <medical-resume-form
        v-if="selectedForm === 'Resume Medis'"
        :mini="mini"
        :isEMR="isEMR"
      />
    </v-card>
  </v-container>
</template>

<script>
import MedicalResumeForm from './Components/MedicalResume/MedicalResumeForm.vue';
import GeneralConsentForm from '../../../Registration/components/GeneralConsentForm.vue';
import EarlyInpatientAssessment from './Components/EarlyAssessment/EarlyInpatientAssessment';
import ObstetricsAssessment from './Components/ObstetricsAssessment/ObstetricsAssessment.vue';
import LabourAssessment from './Components/LabourAssessment/LabourAssessment.vue';
import CPPTDashboard from './Components/CPPT/CPPTDashboard.vue';
import NeonatusAssessment from './Components/Neonatus/NeonatusAssessment';
import ListInformedConsent from '../../../Registration/components/InformedConsent/ListInformedConsent.vue';
import InpatientEMRHeader from './Components/InpatientEMRHeader.vue';
import DrugRecord from '../Inpatient/DrugRecord';
import { createNamespacedHelpers } from 'vuex';
import Swal from 'sweetalert2';

const { mapMutations, mapGetters } = createNamespacedHelpers(
  'MedicalResumeStore',
);
const {
  mapMutations: cpptMutations,
  mapGetters: cpptGetters,
} = createNamespacedHelpers('CPPTStore');
const {
  mapGetters: earlyAssessmentGetters,
  mapActions: earlyAssessmentActions,
} = createNamespacedHelpers('EarlyAssessmentInpatientStore');
const {
  mapGetters: ObstetricsGetters,
  mapActions: ObstetricsActions,
} = createNamespacedHelpers('ObstetricsAssessment');
const {
  mapGetters: LabourGetters,
  mapActions: LabourActions,
} = createNamespacedHelpers('LabourAssessment');
const {
  mapGetters: neonatusGetters,
  mapActions: neonatusActions,
} = createNamespacedHelpers('NeonatusAssessment');

const moment = require('moment-timezone');
export default {
  name: 'InpatientEMR',
  components: {
    MedicalResumeForm,
    InpatientEMRHeader,
    EarlyInpatientAssessment,
    ListInformedConsent,
    NeonatusAssessment,
    ObstetricsAssessment,
    LabourAssessment,
    CPPTDashboard,
    GeneralConsentForm,
    DrugRecord,
  },
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: true,
      mini: false,
      dialog: false,
      selectedForm: 'Assesmen Awal Rawat Inap',
      headerData: '',
      itemList: [
        {
          label: 'Assesmen Awal Rawat Inap',
        },
        {
          label: 'CPPT',
        },
        {
          label: 'Catatan Pemberian Obat',
        },
        {
          label: 'General Consent',
        },
        {
          label: 'Informed Consent',
        },
        {
          label: 'Resume Medis',
        },
      ],
    };
  },
  watch: {
    selectedForm(val) {
      this.handleHeaderChange(val);
    },
  },
  computed: {
    ...mapGetters(['isFormChanged', 'getIsTimeChanged']),
    ...cpptGetters(['getSelectedForm', 'getIsFormChanged']),
    ...ObstetricsGetters([
      'getObstetricsIsChanged',
      'getObstetricsTab',
      'getObstetricsTabsResource',
    ]),
    ...LabourGetters([
      'getLabourIsChanged',
      'getLabourTab',
      'getLabourTabsResource',
    ]),
    ...earlyAssessmentGetters([
      'getTab',
      'getTabsResource',
      'getIsInitialAssessmentModified',
      'getIsAnamnesisModified',
      'getIsObjectiveModified',
      'getIsPhysicalModified',
      'getIsPainAssessmentModified',
    ]),
    ...neonatusGetters([
      'getIsInitialInformationModified',
      'getIsInitialPhysicalModified',
      'getIsInitialApgarModified',
      'getIsInitialStampModified',
    ]),
    isObstetricsIsChanged: {
      get() {
        return this.getObstetricsIsChanged;
      },
    },
    isLabourIsChanged: {
      get() {
        return this.getLabourIsChanged;
      },
    },
    isCpptFormChanged: {
      get() {
        return this.getIsFormChanged;
      },
    },
    isLargeDisplay: {
      get() {
        return this.$vuetify.breakpoint.name === 'xl';
      },
    },
    currentCpptForm: {
      get() {
        return this.getSelectedForm;
      },
    },
    patientBirthDate: {
      get() {
        const date = this.patientData.meta.birthDate;
        return moment(date, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('D MMMM YYYY');
      },
    },
    isMedicalResumeformChanged: {
      get() {
        return this.isFormChanged || this.getIsTimeChanged;
      },
    },
    birthDateAndAge: {
      get() {
        return `${this.patientBirthDate} (${this.calculateNeonatusAge(
          moment(this.patientData.meta.birthDate, [
            'DD/MM/YYYY',
            'YYYY-MM-DD',
          ]).format(),
          this.patientData.meta.age,
        )})`;
      },
    },
  },

  methods: {
    ...mapMutations(['clearState']),
    ...cpptMutations(['clearCpptState', 'navigateTo']),
    ...earlyAssessmentActions([
      'resolvePostEarlyAssessmentInpatient',
      'resolveGetEarlyAssessmentInpatient',
    ]),
    ...ObstetricsActions(['resolvePostObstetricsAssessment']),
    ...LabourActions(['resolvePostLabourAssessment']),
    ...neonatusActions(['resolvePostNeonatusAssessment']),
    changeForm(value) {
      if (value === 'CPPT') {
        this.navigateTo('dashboard'); // for directly navigating to dashboard CPPT
      }

      if (this.selectedForm === value) return;
      switch (this.selectedForm) {
        case 'Assesmen Awal Rawat Inap':
          switch (this.getTab) {
            case 0:
              if (this.getIsInitialAssessmentModified) {
                this.confirmation(
                  () => {
                    this.resolvePostEarlyAssessmentInpatient(0);
                    this.selectedForm = value;
                  },
                  `Data ${this.getTabsResource[0].text} Belum Tersimpan`,
                  true,
                  value,
                );
              } else {
                this.selectedForm = value;
              }
              break;
            case 1:
              if (this.getIsAnamnesisModified) {
                this.confirmation(
                  () => {
                    this.resolvePostEarlyAssessmentInpatient(1);
                    this.selectedForm = value;
                  },
                  `Data ${this.getTabsResource[1].text} Belum Tersimpan`,
                  true,
                  value,
                );
              } else {
                this.selectedForm = value;
              }
              break;
            case 2:
              if (this.getIsObjectiveModified) {
                this.confirmation(
                  () => {
                    this.resolvePostEarlyAssessmentInpatient(2);
                    this.selectedForm = value;
                  },
                  `Data ${this.getTabsResource[2].text} Belum Tersimpan`,
                  true,
                  value,
                );
              } else {
                this.selectedForm = value;
              }
              break;
            case 3:
              if (this.getIsPhysicalModified) {
                this.confirmation(
                  () => {
                    this.resolvePostEarlyAssessmentInpatient(3);
                    this.selectedForm = value;
                  },
                  `Data ${this.getTabsResource[3].text} Belum Tersimpan`,
                  true,
                  value,
                );
              } else {
                this.selectedForm = value;
              }
              break;
            case 4:
              if (this.getIsPainAssessmentModified) {
                this.confirmation(
                  () => {
                    this.resolvePostEarlyAssessmentInpatient(4);
                    this.selectedForm = value;
                  },
                  `Data ${this.getTabsResource[4].text} Belum Tersimpan`,
                  true,
                  value,
                );
              } else {
                this.selectedForm = value;
              }
              break;
          }
          break;
        case 'Assesmen Kebidanan Ibu Hamil':
          switch (this.getObstetricsTab) {
            case 0:
              if (this.isObstetricsIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostObstetricsAssessment(0);
                    this.selectedForm = value;
                  },
                  `Data ${this.getObstetricsTabsResource[0].text} Belum Tersimpan`,
                  true,
                  value,
                );
              } else {
                this.selectedForm = value;
              }
              break;
            case 1:
              if (this.isObstetricsIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostObstetricsAssessment(1);
                    this.selectedForm = value;
                  },
                  `Data ${this.getObstetricsTabsResource[1].text} Belum Tersimpan`,
                  true,
                  value,
                );
              } else {
                this.selectedForm = value;
              }
              break;
            case 2:
              if (this.isObstetricsIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostObstetricsAssessment(2);
                    this.selectedForm = value;
                  },
                  `Data ${this.getObstetricsTabsResource[2].text} Belum Tersimpan`,
                  true,
                  value,
                );
              } else {
                this.selectedForm = value;
              }
              break;
            case 3:
              if (this.isObstetricsIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostObstetricsAssessment(3);
                    this.selectedForm = value;
                  },
                  `Data ${this.getObstetricsTabsResource[3].text} Belum Tersimpan`,
                  true,
                  value,
                );
              } else {
                this.selectedForm = value;
              }
              break;
          }
          break;
        case 'Assesmen Persalinan':
          switch (this.getLabourTab) {
            case 0:
              if (this.isLabourIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostLabourAssessment(0);
                    this.selectedForm = value;
                  },
                  `Data ${this.getLabourTabsResource[0].text} Belum Tersimpan`,
                  true,
                  value,
                );
              } else {
                this.selectedForm = value;
              }
              break;
            case 1:
              if (this.isLabourIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostLabourAssessment(1);
                    this.selectedForm = value;
                  },
                  `Data ${this.getLabourTabsResource[1].text} Belum Tersimpan`,
                  true,
                  value,
                );
              } else {
                this.selectedForm = value;
              }
              break;
            case 2:
              if (this.isLabourIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostLabourAssessment(2);
                    this.selectedForm = value;
                  },
                  `Data ${this.getLabourTabsResource[2].text} Belum Tersimpan`,
                  true,
                  value,
                );
              } else {
                this.selectedForm = value;
              }
              break;
          }
          break;
        case 'CPPT':
          if (this.currentCpptForm === 'form-cppt' && this.isCpptFormChanged) {
            this.confirmation(
              () => (this.selectedForm = value),
              'Data CPPT Belum Tersimpan',
            );
          } else {
            this.selectedForm = value;
          }
          break;
        case 'Catatan Pemberian Obat':
          this.selectedForm = value;
          break;
        case 'General Consent':
          this.selectedForm = value;
          break;
        case 'Resume Medis':
          if (!this.isMedicalResumeformChanged) {
            this.selectedForm = value;
            return;
          }
          this.confirmation(
            () => (this.selectedForm = value),
            'Formulir Resume Medis Belum Tersimpan',
          );
          break;
        case 'Assesmen Neonatus':
          if (this.getIsInitialInformationModified) {
            this.neonatusConfirmation(
              () => {
                this.resolvePostNeonatusAssessment(0);
                this.selectedForm = value;
              },
              'Data Informasi Ibu & Bayi Belum Tersimpan',
              'Informasi Ibu dan Bayi',
              value,
            );
            return;
          }
          if (this.getIsInitialPhysicalModified) {
            this.neonatusConfirmation(
              () => {
                this.resolvePostNeonatusAssessment(1);
                this.selectedForm = value;
              },
              'Data Pengkajian Fisik Belum Tersimpan',
              'Pengkajian Fisik',
              value,
            );
            return;
          }
          if (this.getIsInitialApgarModified) {
            this.neonatusConfirmation(
              () => {
                this.resolvePostNeonatusAssessment(2);
                this.selectedForm = value;
              },
              'Data Apgar Score Belum Tersimpan',
              'Apgar Score',
              value,
            );
            return;
          }
          if (this.getIsInitialStampModified) {
            this.neonatusConfirmation(
              () => {
                this.resolvePostNeonatusAssessment(3);
                this.selectedForm = value;
              },
              'Data Cap Ibu & Bayi Belum Tersimpan',
              'Cap Ibu & Bayi',
              value,
            );
            return;
          }
          this.selectedForm = value;
          break;
        default:
          this.selectedForm = value;
      }
    },
    closeForm() {
      switch (this.selectedForm) {
        case 'Assesmen Awal Rawat Inap':
          switch (this.getTab) {
            case 0:
              if (this.getIsInitialAssessmentModified) {
                this.confirmation(
                  () => {
                    this.resolvePostEarlyAssessmentInpatient(0);
                    this.resolveGetEarlyAssessmentInpatient({
                      tab: 0,
                    });
                  },
                  `Data ${this.getTabsResource[0].text} Belum Tersimpan`,
                  true,
                );
              } else {
                this.$emit('close-form');
              }
              break;
            case 1:
              if (this.getIsAnamnesisModified) {
                this.confirmation(
                  () => {
                    this.resolvePostEarlyAssessmentInpatient(1);
                    this.resolveGetEarlyAssessmentInpatient({
                      tab: 1,
                    });
                  },
                  `Data ${this.getTabsResource[1].text} Belum Tersimpan`,
                  true,
                );
              } else {
                this.$emit('close-form');
              }
              break;
            case 2:
              if (this.getIsObjectiveModified) {
                this.confirmation(
                  () => {
                    this.resolvePostEarlyAssessmentInpatient(2);
                    this.resolveGetEarlyAssessmentInpatient({
                      tab: 2,
                    });
                  },
                  `Data ${this.getTabsResource[2].text} Belum Tersimpan`,
                  true,
                );
              } else {
                this.$emit('close-form');
              }
              break;
            case 3:
              if (this.getIsPhysicalModified) {
                this.confirmation(
                  () => {
                    this.resolvePostEarlyAssessmentInpatient(3);
                    this.resolveGetEarlyAssessmentInpatient({
                      tab: 3,
                    });
                  },
                  `Data ${this.getTabsResource[3].text} Belum Tersimpan`,
                  true,
                );
              } else {
                this.$emit('close-form');
              }
              break;
            case 4:
              if (this.getIsPainAssessmentModified) {
                this.confirmation(
                  () => {
                    this.resolvePostEarlyAssessmentInpatient(4);
                    this.resolveGetEarlyAssessmentInpatient({
                      tab: 4,
                    });
                  },
                  `Data ${this.getTabsResource[4].text} Belum Tersimpan`,
                  true,
                );
              } else {
                this.$emit('close-form');
              }
              break;
          }
          break;
        case 'Assesmen Kebidanan Ibu Hamil':
          switch (this.getObstetricsTab) {
            case 0:
              if (this.isObstetricsIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostObstetricsAssessment(0);
                    this.$emit('close-form');
                  },
                  `Data ${this.getObstetricsTabsResource[0].text} Belum Tersimpan`,
                  true,
                );
              } else {
                this.$emit('close-form');
              }
              break;
            case 1:
              if (this.isObstetricsIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostObstetricsAssessment(1);
                    this.$emit('close-form');
                  },
                  `Data ${this.getObstetricsTabsResource[1].text} Belum Tersimpan`,
                  true,
                );
              } else {
                this.$emit('close-form');
              }
              break;
            case 2:
              if (this.isObstetricsIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostObstetricsAssessment(2);
                    this.$emit('close-form');
                  },
                  `Data ${this.getObstetricsTabsResource[2].text} Belum Tersimpan`,
                  true,
                );
              } else {
                this.$emit('close-form');
              }
              break;
            case 3:
              if (this.isObstetricsIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostObstetricsAssessment(3);
                    this.$emit('close-form');
                  },
                  `Data ${this.getObstetricsTabsResource[3].text} Belum Tersimpan`,
                  true,
                );
              } else {
                this.$emit('close-form');
              }
              break;
          }
          break;
        case 'Assesmen Persalinan':
          switch (this.getLabourTab) {
            case 0:
              if (this.isLabourIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostLabourAssessment(0);
                    this.$emit('close-form');
                  },
                  `Data ${this.getLabourTabsResource[0].text} Belum Tersimpan`,
                  true,
                );
              } else {
                this.$emit('close-form');
              }
              break;
            case 1:
              if (this.isLabourIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostLabourAssessment(1);
                    this.$emit('close-form');
                  },
                  `Data ${this.getLabourTabsResource[1].text} Belum Tersimpan`,
                  true,
                );
              } else {
                this.$emit('close-form');
              }
              break;
            case 2:
              if (this.isLabourIsChanged) {
                this.confirmation(
                  () => {
                    this.resolvePostLabourAssessment(2);
                    this.$emit('close-form');
                  },
                  `Data ${this.getLabourTabsResource[2].text} Belum Tersimpan`,
                  true,
                );
              } else {
                this.$emit('close-form');
              }
              break;
          }
          break;
        case 'CPPT':
          if (this.currentCpptForm === 'form-cppt' && this.isCpptFormChanged) {
            Swal.fire({
              title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Data CPPT Belum Tersimpan</p></div>`,
              iconHtml: '<span class="mdi mdi-alert-circle-outline"></span>',
              html:
                '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Data yang anda input akan hilang. Kembali dan</p><p>simpan formulir terlebih dahulu</p></div>',
              showCancelButton: true,
              customClass: {
                confirmButton: 'button-error',
                cancelButton: 'button-confirm',
              },
              confirmButtonText: 'Keluar',
              cancelButtonText: 'Kembali',
            }).then(result => {
              if (result.isConfirmed) {
                this.$emit('close-form');
              }
            });
          } else {
            this.$emit('close-form');
          }
          break;
        case 'Resume Medis':
          if (!this.isMedicalResumeformChanged) {
            this.$emit('close-form');
            return;
          }
          this.confirmation(
            () => this.$emit('close-form'),
            'Formulir Resume Medis Belum Tersimpan',
          );
          break;
        case 'Assesmen Neonatus':
          if (this.getIsInitialInformationModified) {
            this.neonatusConfirmation(
              () => {
                this.resolvePostNeonatusAssessment(0);
                this.$emit('close-form');
              },
              'Data Informasi Ibu & Bayi Belum Tersimpan',
              'Informasi Ibu dan Bayi',
            );
            return;
          }
          if (this.getIsInitialPhysicalModified) {
            this.neonatusConfirmation(
              () => {
                this.resolvePostNeonatusAssessment(1);
                this.$emit('close-form');
              },
              'Data Pengkajian Fisik Belum Tersimpan',
              'Pengkajian Fisik',
            );
            return;
          }
          if (this.getIsInitialApgarModified) {
            this.neonatusConfirmation(
              () => {
                this.resolvePostNeonatusAssessment(2);
                this.$emit('close-form');
              },
              'Data Apgar Score Belum Tersimpan',
              'Apgar Score',
            );
            return;
          }
          if (this.getIsInitialStampModified) {
            this.neonatusConfirmation(
              () => {
                this.resolvePostNeonatusAssessment(3);
                this.$emit('close-form');
              },
              'Data Cap Ibu & Bayi Belum Tersimpan',
              'Cap Ibu & Bayi ',
            );
            return;
          }
          this.$emit('close-form');
          break;
        default:
          this.$emit('close-form');
      }
    },
    confirmation(action, text, isAssessment, destinationMenu) {
      Swal.fire({
        title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>${text}</p></div>`,
        iconHtml: '<span class="mdi mdi-alert-circle-outline"></span>',
        html:
          '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Data yang anda input akan hilang. Kembali dan</p><p>simpan formulir terlebih dahulu</p></div>',
        showCancelButton: true,
        reverseButtons: isAssessment,
        customClass: {
          confirmButton: isAssessment ? 'button-confirm' : 'button-error',
          cancelButton: isAssessment ? 'button-error-blue' : 'button-confirm',
        },
        confirmButtonText: isAssessment ? 'Simpan' : 'Keluar',
        cancelButtonText: isAssessment ? 'Keluar' : 'Kembali',
      }).then(result => {
        if (result.isConfirmed) {
          action();
        }
        if (!result.isConfirmed && isAssessment) {
          if (!destinationMenu) {
            this.$emit('close-form');
            return;
          }
          this.selectedForm = destinationMenu;
        }
      });
    },
    neonatusConfirmation(action, text, type, destinationMenu) {
      Swal.fire({
        title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>${text}</p></div>`,
        iconHtml: '<span class="mdi mdi-alert-circle-outline"></span>',
        html: `<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Jika keluar maka data ${type} yang</p><p>anda input akan hilang</p></div>`,
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'button-confirm',
          cancelButton: 'button-error-blue',
        },
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Keluar',
      }).then(result => {
        if (result.isConfirmed) {
          action();
        }
        if (!result.isConfirmed) {
          if (!destinationMenu) {
            this.$emit('close-form');
            return;
          }
          this.selectedForm = destinationMenu;
        }
      });
    },
    handleHeaderChange(form) {
      switch (form) {
        case 'Assesmen Awal Rawat Inap':
          this.headerData = {
            title: 'Assesmen Awal Rawat Inap',
            firstCol: {
              label: 'No RM',
              value: this.patientData.rmNumber,
            },
            secondCol: {
              label: 'Nama',
              value: `${this.patientData.meta.name} (${
                this.patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
              })`,
            },
            thirdCol: {
              label: 'Tanggal Lahir',
              value: `${this.patientBirthDate} (${this.patientData.meta.age} Tahun)`,
            },
          };
          break;
        case 'Assesmen Kebidanan Ibu Hamil':
          this.headerData = {
            title: 'Assesmen Kebidanan Ibu Hamil',
            firstCol: {
              label: 'No RM',
              value: this.patientData.rmNumber,
            },
            secondCol: {
              label: 'Nama',
              value: `${this.patientData.meta.name} (${
                this.patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
              })`,
            },
            thirdCol: {
              label: 'Tanggal Lahir',
              value: `${this.patientBirthDate} (${this.patientData.meta.age} Tahun)`,
            },
            fourthCol: {
              label: '',
              value: '',
            },
          };
          break;
        case 'Assesmen Persalinan':
          this.headerData = {
            title: 'Assesmen Persalinan',
            firstCol: {
              label: 'No RM',
              value: this.patientData.rmNumber,
            },
            secondCol: {
              label: 'Nama',
              value: `${this.patientData.meta.name} (${
                this.patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
              })`,
            },
            thirdCol: {
              label: 'Tanggal Lahir',
              value: `${this.patientBirthDate} (${this.patientData.meta.age} Tahun)`,
            },
            fourthCol: {
              label: '',
              value: '',
            },
          };
          break;
        case 'Assesmen Neonatus':
          this.headerData = {
            title: 'Assesmen Bayi Baru Lahir / Neonatus',
            firstCol: {
              label: 'No RM',
              value: this.patientData.rmNumber,
            },
            secondCol: {
              label: 'Nama',
              value: this.patientData.meta.name,
            },
            thirdCol: {
              label: 'Jenis Kelamin',
              value: this.patientData.meta.gender,
            },
            fourthCol: {
              label: 'Tanggal Lahir',
              value: this.birthDateAndAge,
            },
            fifthCol: {
              label: 'Alamat',
              value: this.patientData.meta.address,
            },
          };
          break;
        case 'CPPT':
          this.headerData = {
            title: 'Catatan Perkembangan Pasien Terintegrasi (CPPT)',
            firstCol: {
              label: 'No RM',
              value: this.patientData.rmNumber,
            },
            secondCol: {
              label: 'Nama',
              value: `${this.patientData.meta.name} (${
                this.patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
              })`,
            },
            thirdCol: {
              label: 'Tanggal Lahir',
              value: `${this.patientBirthDate} (${this.patientData.meta.age} Tahun)`,
            },
            fourthCol: {
              label: 'Alamat',
              value: this.patientData.meta.address,
            },
          };
          break;
        case 'Catatan Pemberian Obat':
          this.headerData = {
            title: 'Catatan Pemberian Obat',
          };
          break;
        case 'General Consent':
          this.headerData = {
            title: 'General Consent Rawat Inap',
            firstCol: {
              label: 'No RM',
              value: this.patientData.rmNumber,
            },
            secondCol: {
              label: 'Nama',
              value: `${this.patientData.meta.name} (${
                this.patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
              })`,
            },
            thirdCol: {
              label: 'Tanggal Lahir',
              value: `${this.patientBirthDate} (${this.patientData.meta.age} Tahun)`,
            },
          };
          break;
        case 'Informed Consent':
          this.headerData = {
            title: 'Informed Consent',
            firstCol: {
              label: 'No RM',
              value: this.patientData.rmNumber,
            },
            secondCol: {
              label: 'Nama',
              value: `${this.patientData.meta.name} (${
                this.patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
              })`,
            },
            thirdCol: {
              label: 'Tanggal Lahir',
              value: `${this.patientBirthDate} (${this.patientData.meta.age} Tahun)`,
            },
          };
          break;
        case 'Resume Medis':
          this.headerData = {
            title: 'Resume Medis',
            firstCol: {
              label: 'No RM',
              value: this.patientData.rmNumber,
            },
            secondCol: {
              label: 'Nama',
              value: `${this.patientData.meta.name} (${
                this.patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
              })`,
            },
            thirdCol: {
              label: 'Tanggal Lahir',
              value: `${this.patientBirthDate} (${this.patientData.meta.age} Tahun)`,
            },
            fourthCol: {
              label: 'Tipe Penjamin',
              value: this.patientData.meta.guarantorType.toUpperCase(),
            },
          };
          break;
      }
    },
    calculateNeonatusAge(birthday, age) {
      const current = moment();
      const fiveYear = 5 * 365;

      const ageInDays = current.diff(birthday, 'days');

      if (ageInDays > fiveYear) {
        return age + ' Tahun';
      }
      if (ageInDays <= 29) {
        return `${ageInDays} Hari`;
      }
      if (ageInDays <= fiveYear) {
        const years = Math.floor(ageInDays / 365);
        const months = Math.floor(ageInDays / 30);
        const remainingDays =
          ageInDays % 365 > 30 ? ageInDays % 30 : ageInDays % 365;
        const remainingMonths = months < 12 ? months : months % 12;

        return `${years ? `${years} Tahun ` : ''}${
          remainingMonths ? `${remainingMonths} Bulan ` : ''
        }${remainingDays ? `${remainingDays} Hari` : ''}`;
      }
    },
    setShowList() {
      if (this.patientData?.case_type?.toLowerCase() === 'neonatus') {
        this.itemList.splice(1, 0, { label: 'Assesmen Neonatus' });
      }
      if (this.patientData?.case_type?.toLowerCase() === 'obsgyn') {
        this.itemList.splice(
          0,
          1,
          ...[
            { label: 'Assesmen Kebidanan Ibu Hamil' },
            { label: 'Assesmen Persalinan' },
          ],
        );
      }
    },
  },
  beforeDestroy() {
    this.clearState();
    this.clearCpptState('patient');
  },
  mounted() {
    if (this.patientData?.case_type?.toLowerCase() === 'obsgyn') {
      this.selectedForm = 'Assesmen Kebidanan Ibu Hamil';
    }
    this.handleHeaderChange('Assesmen Awal Rawat Inap');
    this.setShowList();
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  max-width: 100vw;
  display: flex;
  position: fixed;

  .sidebar {
    overflow-y: hidden;
    max-width: 20vw;

    .list-label {
      font-size: 0.8vw;
      margin: 0.4vw 0;
    }

    .list {
      font-weight: 500;
      height: 3vw;
      text-align: center;
      cursor: pointer;
      border-radius: 8px;

      .label-container {
        display: flex;
        text-align: start;
        align-items: center;
        padding-left: 1vw;
        width: 100%;
        height: 100%;
      }

      :hover {
        background-color: #cbe4f6;
      }

      &.active {
        background-color: #fff;
        color: #137bc0;
        :hover {
          background-color: #fff;
        }
      }

      label {
        cursor: pointer;
      }
    }

    ::-webkit-scrollbar {
      width: 3px;
      background-color: #c2c2c2;
    }
    ::-webkit-scrollbar-thumb {
      width: 3px;
      background-color: #919191;
    }
  }

  .main {
    width: 100%;
    max-height: 100vh;
    overflow: hidden;
  }
}
</style>
