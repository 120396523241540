import Constant from '@/const';
import axios from 'axios';

const updatePharmacyDrug = async (params, payload) => {
  try {
    await axios.put(
      Constant.apiUrl.concat(`/pharmacy/item/${params}`),
      payload,
    );
  } catch (error) {
    throw new Error(error);
  }
};

export default updatePharmacyDrug;
