import moment from 'moment-timezone';

export default {
  computed: {
    formattedDate(date) {
      return date ? moment(date).format('DD/MM/YYYY') : date;
    },
  },
  methods: {
    formattedCurrency(val) {
      return val
        ? val.toLocaleString('de-DE', { minimumFractionDigits: 2 })
        : '0,00';
    },
    currency(val) {
      var format = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      });
      return format.format(val);
    },
    formatAddress(address) {
      const addressData = [address.text];
      if (address.sub_district) {
        addressData.push(address.sub_district?.name || address.sub_district);
      }
      if (address.district) {
        addressData.push(address.district?.name || address.district);
      }
      if (address.city) {
        addressData.push(address.city?.name || address.city);
      }
      if (address.province) {
        addressData.push(address.province?.name || address.province);
      }
      return addressData.join(', ');
    },
  },
};
