<template>
  <div class="mortality-mother">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Laporan Mortalitas Jumlah Kematian Ibu
            </v-toolbar-title>
          </v-col>
          <v-spacer></v-spacer>
          <router-link to="/reporting/laporan-mortalitas">
            <v-btn color="grey" icon text>
              <v-icon>mdi-home </v-icon>
            </v-btn>
          </router-link>
          <v-btn color="grey" icon text @click="getMortality(type)">
            <v-icon>mdi-autorenew </v-icon>
          </v-btn>
          <v-col cols="2">
            <v-autocomplete
              placeholder="Pilih Tahun"
              prepend-inner-icon="mdi-calendar-month"
              :items="yearRange"
              outlined
              dense
              hide-details="false"
              v-model="thisYear"
              @change="getMortality(type)"
            />
          </v-col>
          <v-btn depressed color="primary" class="mx-0 text-none" tile disabled>
            Export
          </v-btn>
        </v-row>
      </v-container>
      <template v-slot:extension>
        <v-tabs v-model="tabs" centered slider-color="primary">
          <v-tab
            class="text-capitalize font-weight-bold"
            @click="
              type = 'oneYear';
              getMortality('oneYear');
            "
          >
            Angka Kematian Dalam 1 Tahun
          </v-tab>
          <v-tab
            class="text-capitalize font-weight-bold"
            @click="
              type = 'fiveYears';
              getMortality('fiveYears');
            "
          >
            Angka Kematian 5 Tahun Terakhir
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-main>
      <v-container>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-row>
              <v-col cols="8">
                <v-card
                  color="#F0F9FF"
                  height="384"
                  flat
                  rounded="xl"
                  class="pa-3"
                >
                  <v-flex class="text-left px-3">
                    <h4>
                      Laporan Mortalitas Angka Kematian Ibu
                    </h4>
                    <label class="font-weight-medium caption grey--text">
                      Rata-rata angka laporan mortalitas angka kematian ibu di
                      tahun {{ thisYear }}
                    </label>
                  </v-flex>
                  <!-- <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                    v-show="isLoading"
                  ></v-progress-circular> -->
                  <apexcharts
                    ref="chartOneYear"
                    type="bar"
                    :options="pastOneYear.barOptions"
                    :series="pastOneYear.seriesBar"
                    height="320"
                  ></apexcharts>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card flat color="#F0F9FF" rounded="lg">
                  <v-card-title>
                    <h5>Penyebab Kematian Ibu</h5>
                  </v-card-title>
                  <template v-for="(death, index) in pastOneYear.deathCause">
                    <v-divider :key="'divider-' + index"></v-divider>
                    <v-card-text
                      class="d-flex justify-space-between"
                      :key="'deathCause-' + index"
                    >
                      <span>{{ index + 1 }}</span>
                      <span class="subtitle-2">{{ death.name }}</span>
                      <span class="subtitle-2">{{ death.count }} Pasien</span>
                    </v-card-text>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row>
              <v-col cols="8">
                <v-card
                  color="#F0F9FF"
                  height="384"
                  flat
                  rounded="xl"
                  class="pa-3"
                >
                  <v-flex class="text-left px-3">
                    <h4>
                      Laporan Mortalitas Jumlah Kematian Ibu
                    </h4>
                    <label class="font-weight-medium caption grey--text">
                      Rata-rata angka laporan mortalitas angka kematian Ibu
                      dalam 5 tahun terakhir
                    </label>
                  </v-flex>
                  <!-- <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                    v-if="isLoading"
                  ></v-progress-circular> -->
                  <apexchart
                    ref="chartFiveYear"
                    type="line"
                    :options="pastFiveYears.lineOptions"
                    :series="pastFiveYears.seriesLine"
                    height="320"
                  ></apexchart>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card flat color="#F0F9FF" rounded="lg">
                  <v-card-title>
                    <h5>Penyebab Kematian Ibu</h5>
                  </v-card-title>
                  <template v-for="(death, index) in pastFiveYears.deathCause">
                    <v-divider :key="'divider-' + index"></v-divider>
                    <v-card-text
                      class="d-flex justify-space-between"
                      :key="'deathCause-' + index"
                    >
                      <span>{{ index + 1 }}</span>
                      <span class="subtitle-2">{{ death.name }}</span>
                      <span class="subtitle-2">{{ death.count }} Pasien</span>
                    </v-card-text>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';
import VueApexCharts from 'vue-apexcharts';

const axios = require('axios');

export default {
  name: 'ReportMortalityMother',
  mixins: [jwtMixin],
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      tabs: null,
      thisYear: new Date().getFullYear(),
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      type: 'oneYear',
      pastOneYear: {
        seriesBar: [
          {
            name: 'Pasien',
            data: [],
          },
        ],

        barOptions: {
          chart: {
            id: 'chartOneYear',
            height: 350,
            type: 'bar',
            zoom: {
              enabled: false,
            },
            background: '#F0F9FF',
          },
          colors: ['#F2994A'],
          dataLabels: {
            enabled: true,
            formatter: function(val) {
              return val + ' Pasien';
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },

          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'Mei',
              'Jun',
              'Jul',
              'Ags',
              'Sep',
              'Okt',
              'Nov',
              'Des',
            ],
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
        },
        deathCause: [],
      },
      pastFiveYears: {
        seriesLine: [
          {
            name: 'Pasien',
            data: [],
          },
        ],

        lineOptions: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false,
            },
            background: '#F0F9FF',
          },
          colors: ['#F2994A'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },

          xaxis: {
            categories: [],
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
        },
        deathCause: [],
      },
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    yearRange() {
      let startYear = 1980;
      const currentYear = new Date().getFullYear();
      const years = [];
      while (startYear <= currentYear) {
        years.push(startYear++);
      }
      return years;
    },
  },
  methods: {
    async getMortality(type) {
      if (type === 'oneYear') {
        this.isLoading = true;
        await this.runApiCalls(() => {
          axios
            .get(
              Constant.apiUrl.concat(
                `/report/mortalitas?year=${this.thisYear}&isAnnual=1&category=nyonya&isNeonatus=`,
              ),
            )
            .then(response => {
              const mortalGraph = response.data.data.graph;
              this.pastOneYear.deathCause = response.data.data.deathCause;

              this.$refs.chartOneYear.updateSeries(
                [
                  {
                    name: 'Pasien',
                    data: mortalGraph,
                  },
                ],
                false,
                true,
              );

              this.isLoading = false;
            })
            .catch(() => {
              this.pastOneYear.seriesBar[0].data = [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
              ];
              this.isLoading = false;
            });
        });
      } else {
        const today = new Date();
        const last_year = today.getFullYear();
        const first_year = last_year - 4;
        this.isLoading = true;
        const categories = [];

        for (let i = first_year; i <= last_year; i++) {
          categories.push(i);
        }
        this.pastFiveYears.lineOptions.xaxis.categories = categories;

        this.runApiCalls(() => {
          axios
            .get(
              Constant.apiUrl.concat(
                `/report/mortalitas?year=${this.thisYear}&isAnnual=&category=nyonya&isNeonatus=`,
              ),
            )
            .then(response => {
              const mortalGraph = response.data.data.graph;
              this.$refs.chartFiveYear.updateSeries(
                [
                  {
                    name: 'Pasien',
                    data: mortalGraph,
                  },
                ],
                false,
                true,
              );
              this.pastFiveYears.deathCause = response.data.data.deathCause;
              this.isLoading = false;
            })
            .catch(() => {
              this.pastFiveYears.seriesLine[0].data = [0, 0, 0, 0, 0];
              this.isLoading = false;
            });
        });
      }
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getMortality('oneYear');
      this.interval = setInterval(this.getMortality, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 15%;
}

.radius {
  border-radius: 10px;
}

::v-deep {
  .v-input__icon .v-input__icon--prepend-inner {
    color: #3498db;
  }
}
</style>
