const state = {
  score: 0,
  pain_factor: [],
  pain_like: [],
  pain_radation: false,
  severity: 0,
  pain_frequency: 0,
  pain_duration: 0,
  pain_type: '',
  description: null,
};

const getters = {
  // get score
  getScore: state => state.score,
  // get pain factor
  getPainFactor: state => state.pain_factor,
  // get pain like
  getPainLike: state => state.pain_like,
  // get pain radiation
  getPainRadiation: state => state.pain_radation,
  // get pain severity
  getSeverity: state => state.severity,
  // get pain frequency
  getPainFrequency: state => state.pain_frequency,
  // get pain duration
  getPainDuration: state => state.pain_duration,
  // get description
  getDescription: state => state.description,
  // get pain type
  getPainType: state => {
    let result;
    if (!state.score) {
      result = 'Tidak Nyeri';
    }
    if (state.score >= 1 && state.score <= 3) {
      result = 'Nyeri Ringan';
    }
    if (state.score >= 4 && state.score <= 6) {
      result = 'Nyeri Sedang';
    }
    if (state.score >= 7 && state.score <= 10) {
      result = 'Nyeri Berat';
    }
    return result;
  },
};

const mutations = {
  clearPainAssessmentState(state) {
    Object.assign(state, {
      score: 0,
      pain_factor: [],
      pain_like: [],
      pain_radation: false,
      severity: null,
      pain_frequency: 0,
      pain_duration: 0,
      pain_type: '',
      description: null,
    });
  },
  setScore(state, payload) {
    state.score = payload;
  },
  setPainFactor(state, payload) {
    state.pain_factor = payload;
  },
  setPainLike(state, payload) {
    state.pain_like = payload;
  },
  setPainRadiation(state, payload) {
    state.pain_radation = payload;
  },
  setSeverity(state, payload) {
    state.severity = payload;
  },
  setPainFrequency(state, payload) {
    state.pain_frequency = payload;
  },
  setPainDuration(state, payload) {
    state.pain_duration = payload;
  },
  setDescription(state, payload) {
    state.description = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
