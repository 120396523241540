<template>
  <v-form ref="form" class="form-container mt-4">
    <section>
      <Skeleton :is-loading="isLoading" :max-content="true">
        <h4>
          DATA KUNJUNGAN
        </h4>
      </Skeleton>
      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <Skeleton :is-loading="isLoading">
            <label class="required">Nama Petugas</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-autocomplete
              dense
              single-line
              :rules="rules.required"
              v-model="form.visitData.staff"
              :readonly="isEMR"
              :items="resource"
              item-text="name"
              item-value="id"
              placeholder="Pilih nama petugas"
              append-icon="mdi-chevron-down"
              class="input-field"
            />
          </Skeleton>
        </v-col>
        <v-col cols="6" class="pl-10">
          <Skeleton :is-loading="isLoading">
            <label class="required">Tanggal Pengisian</label>
          </Skeleton>
          <v-row no-gutters>
            <v-col cols="9" class="pr-5">
              <v-menu
                dense
                ref="menu"
                v-model="menu.fillDate"
                transition="scale-transition"
                offset-y
                min-width="380px"
                :disabled="isEMR || form?.timestamps !== undefined"
              >
                <template v-slot:activator="{ on, attrs }">
                  <Skeleton :is-loading="isLoading">
                    <v-text-field
                      class="input-field"
                      append-icon="mdi-chevron-down"
                      placeholder="DD-MM-YYYY"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      single-line
                      v-model="formatDateFillDate"
                    />
                  </Skeleton>
                </template>
                <v-date-picker
                  width="380px"
                  :rules="rules.required"
                  v-model="form.visitData.fillDate"
                  no-title
                  scrollable
                  :disabled="form?.timestamps !== undefined"
                />
              </v-menu>
            </v-col>
            <v-col cols="3" class="pl-5">
              <Skeleton :is-loading="isLoading">
                <v-text-field
                  type="time"
                  single-line
                  dense
                  v-model="form.visitData.fillTime"
                  class="input-field"
                  :rules="rules.required"
                  :readonly="form?.timestamps !== undefined || isEMR"
                  @keydown="
                    e => {
                      preventDefaultBehaviour(e);
                      return (
                        form?.timestamps !== undefined && e.preventDefault()
                      );
                    }
                  "
                />
              </Skeleton>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <Skeleton :is-loading="isLoading">
            <label class="required">Tanggal Kunjungan</label>
          </Skeleton>
          <v-row no-gutters>
            <v-col cols="9" class="pr-5">
              <v-menu
                dense
                ref="menu"
                v-model="menu.visitDate"
                transition="scale-transition"
                offset-y
                min-width="380px"
                :disabled="isEMR || form?.timestamps !== undefined"
              >
                <template v-slot:activator="{ on, attrs }">
                  <Skeleton :is-loading="isLoading">
                    <v-text-field
                      class="input-field"
                      append-icon="mdi-chevron-down"
                      placeholder="DD-MM-YYYY"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      single-line
                      v-model="formatDateVisitDate"
                    />
                  </Skeleton>
                </template>
                <v-date-picker
                  width="380px"
                  :rules="rules.required"
                  v-model="form.visitData.visitDate"
                  no-title
                  scrollable
                  :disabled="form?.timestamps !== undefined"
                />
              </v-menu>
            </v-col>
            <v-col cols="3" class="pl-5">
              <Skeleton :is-loading="isLoading">
                <v-text-field
                  type="time"
                  single-line
                  dense
                  v-model="form.visitData.visitTime"
                  class="input-field"
                  :rules="rules.required"
                  :readonly="form?.timestamps !== undefined || isEMR"
                  @keydown="
                    e => {
                      preventDefaultBehaviour(e);
                      return (
                        form?.timestamps !== undefined && e.preventDefault()
                      );
                    }
                  "
                />
              </Skeleton>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="pl-10">
          <Skeleton :is-loading="isLoading">
            <label>Diagnosa</label>
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-text-field
              dense
              single-line
              :readonly="isEMR"
              v-model="form.visitData.diagnose"
              class="input-field"
              placeholder="Masukkan Diagnosa"
            />
          </Skeleton>
        </v-col>
      </v-row>
      <v-divider class="divider" />
    </section>

    <section>
      <Skeleton :is-loading="isLoading" :max-content="true">
        <h4>
          RIWAYAT KEHAMILAN SAAT INI
        </h4>
      </Skeleton>
      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <Skeleton :is-loading="isLoading">
            <label class="required">His Sejak</label>
          </Skeleton>
          <v-row no-gutters>
            <v-col cols="9" class="pr-5">
              <v-menu
                dense
                ref="menu"
                v-model="menu.hisDate"
                transition="scale-transition"
                offset-y
                min-width="380px"
                :disabled="isEMR"
              >
                <template v-slot:activator="{ on, attrs }">
                  <Skeleton :is-loading="isLoading">
                    <v-text-field
                      class="input-field"
                      append-icon="mdi-chevron-down"
                      placeholder="DD-MM-YYYY"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      single-line
                      v-model="formatDateHisDate"
                      :rules="rules.required"
                    />
                  </Skeleton>
                </template>
                <v-date-picker
                  width="380px"
                  :rules="rules.required"
                  v-model="form.currentPregnancy.hisDate"
                  no-title
                  scrollable
                />
              </v-menu>
            </v-col>
            <v-col cols="3" class="pl-5">
              <Skeleton :is-loading="isLoading">
                <v-text-field
                  @keydown="preventDefaultBehaviour($event)"
                  type="time"
                  single-line
                  dense
                  v-model="form.currentPregnancy.hisTime"
                  class="input-field"
                  :readonly="isEMR"
                  :rules="rules.required"
                />
              </Skeleton>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="pl-10">
          <Skeleton :is-loading="isLoading">
            <label class="required">Air Ketuban Pecah Sejak</label>
          </Skeleton>
          <v-row no-gutters>
            <v-col cols="9" class="pr-5">
              <v-menu
                dense
                ref="menu"
                v-model="menu.mucusOutDate"
                transition="scale-transition"
                offset-y
                min-width="380px"
                :disabled="isEMR"
              >
                <template v-slot:activator="{ on, attrs }">
                  <Skeleton :is-loading="isLoading">
                    <v-text-field
                      class="input-field"
                      append-icon="mdi-chevron-down"
                      placeholder="DD-MM-YYYY"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      single-line
                      v-model="formatDateMucusOutDate"
                      :rules="rules.required"
                    />
                  </Skeleton>
                </template>
                <v-date-picker
                  width="380px"
                  :rules="rules.required"
                  v-model="form.currentPregnancy.mucusOutDate"
                  no-title
                  scrollable
                />
              </v-menu>
            </v-col>
            <v-col cols="3" class="pl-5">
              <Skeleton :is-loading="isLoading">
                <v-text-field
                  @keydown="preventDefaultBehaviour($event)"
                  type="time"
                  single-line
                  dense
                  v-model="form.currentPregnancy.mucusOutTime"
                  class="input-field"
                  :rules="rules.required"
                  :readonly="isEMR"
                />
              </Skeleton>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <Skeleton :is-loading="isLoading">
            <label class="required">Keluar Lendir Sejak</label>
          </Skeleton>
          <v-row no-gutters>
            <v-col cols="9" class="pr-5">
              <v-menu
                dense
                ref="menu"
                v-model="menu.amnioticFluidOutDate"
                transition="scale-transition"
                offset-y
                min-width="380px"
                :disabled="isEMR"
              >
                <template v-slot:activator="{ on, attrs }">
                  <Skeleton :is-loading="isLoading">
                    <v-text-field
                      class="input-field"
                      append-icon="mdi-chevron-down"
                      placeholder="DD-MM-YYYY"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      single-line
                      v-model="formatDateAmnioticFluidOutDate"
                      :rules="rules.required"
                    />
                  </Skeleton>
                </template>
                <v-date-picker
                  width="380px"
                  :rules="rules.required"
                  v-model="form.currentPregnancy.amnioticFluidOutDate"
                  no-title
                  scrollable
                />
              </v-menu>
            </v-col>
            <v-col cols="3" class="pl-5">
              <Skeleton :is-loading="isLoading">
                <v-text-field
                  @keydown="preventDefaultBehaviour($event)"
                  type="time"
                  single-line
                  dense
                  v-model="form.currentPregnancy.amnioticFluidOutTime"
                  class="input-field"
                  :rules="rules.required"
                  :readonly="isEMR"
                />
              </Skeleton>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="divider" />
    </section>

    <section>
      <Skeleton :is-loading="isLoading" :max-content="true">
        <h4>
          RIWAYAT KEHAMILAN SEBELUMNYA
        </h4>
      </Skeleton>
      <div v-for="(item, index) in form.previousPregnancyHistory" :key="index">
        <v-divider v-if="index !== 0" class="divider mb-6" />
        <v-row no-gutters>
          <v-col cols="6" class="pr-10">
            <Skeleton :is-loading="isLoading">
              <label>Hamil ke (G/P/A/Nifas)</label>
            </Skeleton>
            <Skeleton :is-loading="isLoading">
              <v-text-field
                dense
                single-line
                v-model.number="
                  form.previousPregnancyHistory[index].pregnancyTo
                "
                readonly
                class="input-field"
                placeholder="0"
                type="number"
              />
            </Skeleton>
          </v-col>
          <v-col cols="6" class="pl-10">
            <Skeleton :is-loading="isLoading">
              <label>H/M</label>
            </Skeleton>
            <Skeleton :is-loading="isLoading">
              <v-autocomplete
                dense
                single-line
                v-model="form.previousPregnancyHistory[index].isAlive"
                :items="[
                  { name: 'Hidup', key: true },
                  { name: 'Mati', key: false },
                ]"
                item-text="name"
                class="input-field"
                placeholder="Hidup atau mati"
                append-icon="mdi-chevron-down"
                :readonly="isEMR"
              />
            </Skeleton>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="6" class="pr-10">
            <Skeleton :is-loading="isLoading">
              <label>Umur</label>
            </Skeleton>
            <v-row no-gutters>
              <v-col cols="6" class="pr-5">
                <Skeleton :is-loading="isLoading">
                  <v-text-field
                    dense
                    single-line
                    v-model.number="
                      form.previousPregnancyHistory[index].ageYear
                    "
                    class="input-field"
                    placeholder="0"
                    type="number"
                    suffix="Tahun"
                    :readonly="isEMR"
                  />
                </Skeleton>
              </v-col>
              <v-col cols="6" class="pl-5">
                <Skeleton :is-loading="isLoading">
                  <v-text-field
                    dense
                    single-line
                    v-model.number="
                      form.previousPregnancyHistory[index].ageMonth
                    "
                    class="input-field"
                    placeholder="0"
                    type="number"
                    suffix="Bulan"
                    :readonly="isEMR"
                  />
                </Skeleton>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pl-10">
            <Skeleton :is-loading="isLoading">
              <label>BBL (gr)</label>
            </Skeleton>
            <Skeleton :is-loading="isLoading">
              <v-text-field
                dense
                single-line
                v-model.number="form.previousPregnancyHistory[index].bbl"
                class="input-field"
                placeholder="0"
                suffix="gram"
                maxlength="4"
                @keydown="validateInput"
                :readonly="isEMR"
              />
            </Skeleton>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="6" class="pr-10">
            <Skeleton :is-loading="isLoading">
              <label>Jenis Kelamin</label>
            </Skeleton>
            <Skeleton :is-loading="isLoading">
              <v-autocomplete
                dense
                single-line
                v-model="form.previousPregnancyHistory[index].gender"
                :items="['Laki-laki', 'Perempuan', 'Tidak Ada']"
                class="input-field"
                placeholder="Pilih jenis kelamin"
                append-icon="mdi-chevron-down"
                :readonly="isEMR"
              />
            </Skeleton>
          </v-col>
          <v-col cols="6" class="pl-10">
            <Skeleton :is-loading="isLoading">
              <label>Komplikasi</label>
            </Skeleton>
            <Skeleton :is-loading="isLoading">
              <v-text-field
                dense
                single-line
                v-model="form.previousPregnancyHistory[index].complication"
                class="input-field"
                placeholder="Masukkan Keterangan"
                :readonly="isEMR"
              />
            </Skeleton>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="6" class="pr-10">
            <Skeleton :is-loading="isLoading">
              <label>Penolong</label>
            </Skeleton>
            <Skeleton :is-loading="isLoading">
              <v-autocomplete
                dense
                single-line
                v-model="form.previousPregnancyHistory[index].helper"
                :items="items.helper"
                class="input-field"
                placeholder="Pilih penolong"
                append-icon="mdi-chevron-down"
                :readonly="isEMR"
              />
            </Skeleton>
            <Skeleton :is-loading="isLoading">
              <v-text-field
                v-if="
                  form.previousPregnancyHistory[index].helper === 'Lain-Lain'
                "
                dense
                single-line
                v-model="form.previousPregnancyHistory[index].helperEtc"
                class="input-field"
                style="margin-top: -0.25rem !important;"
                placeholder="Masukkan penolong lainnya"
                :readonly="isEMR"
              />
            </Skeleton>
          </v-col>
          <v-col cols="6" class="pl-10">
            <Skeleton :is-loading="isLoading">
              <label>Keadaan Anak Saat Ini</label>
            </Skeleton>
            <Skeleton :is-loading="isLoading">
              <v-text-field
                dense
                single-line
                v-model="form.previousPregnancyHistory[index].currentCondition"
                class="input-field"
                placeholder="Masukkan Keterangan"
                :readonly="isEMR"
              />
            </Skeleton>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="6" class="pr-10">
            <Skeleton :is-loading="isLoading">
              <label>Pilih Tempat</label>
            </Skeleton>
            <Skeleton :is-loading="isLoading">
              <v-autocomplete
                append-icon="mdi-chevron-down"
                dense
                single-line
                v-model="form.previousPregnancyHistory[index].place"
                :items="items.place"
                class="input-field"
                placeholder="Pilih tempat"
                :readonly="isEMR"
              />
            </Skeleton>
            <Skeleton :is-loading="isLoading">
              <v-text-field
                v-if="
                  form.previousPregnancyHistory[index].place === 'Lain-Lain'
                "
                dense
                single-line
                v-model="form.previousPregnancyHistory[index].placeEtc"
                class="input-field"
                style="margin-top: -0.25rem !important;"
                placeholder="Masukkan tempat lainnya"
                :readonly="isEMR"
              />
            </Skeleton>
          </v-col>
        </v-row>

        <v-row v-if="index + 1 === form.previousPregnancyHistory.length">
          <v-spacer />
          <Skeleton :is-loading="isLoading">
            <v-btn
              v-if="index !== 0 && !isEMR"
              color="error"
              text
              tile
              class="text-none"
              @click="handleRemovePregnancyHistory(index)"
              >Hapus</v-btn
            >
          </Skeleton>
          <Skeleton :is-loading="isLoading">
            <v-btn
              v-if="!isEMR"
              color="primary"
              outlined
              tile
              depressed
              class="text-none mx-2"
              @click="handleAddPregnancyHistory()"
              >Tambah Baru</v-btn
            >
          </Skeleton>
        </v-row>
      </div>
    </section>
  </v-form>
</template>

<script>
import Skeleton from '../../../../../SharedComponent/Skeleton.vue';
import moment from 'moment-timezone';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'LabourAssessment',
);
const { mapGetters: staffGetters } = createNamespacedHelpers('MasterStaff');

export default {
  name: 'PregnancyHistory',
  props: {
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Skeleton,
  },
  data() {
    return {
      menu: {
        visitDate: false,
        fillDate: false,
        hisDate: false,
        mucusOutDate: false,
        amnioticFluidOutDate: false,
      },
    };
  },
  computed: {
    ...mapGetters(['getForm', 'getRules', 'getItems', 'getIsLoading']),
    ...staffGetters({ staffItems: 'getItems' }),
    form: {
      get() {
        return this.getForm.pregnancyHistory;
      },
    },
    rules: {
      get() {
        return this.isEMR ? [] : this.getRules;
      },
    },
    items: {
      get() {
        return this.getItems;
      },
    },
    isLoading: {
      get() {
        return this.getIsLoading || this.staffItems.dataStaff.isLoading;
      },
    },
    resource: {
      get() {
        return this.staffItems.dataStaff.data
          .filter(
            item =>
              item.role.role.some(
                item =>
                  item.toLowerCase() === 'perawat' ||
                  item.toLowerCase() === 'bidan',
              ) && item.role.status,
          )
          .map(item => {
            return { name: item.name, id: item.id };
          });
      },
    },
    formatDateFillDate: {
      get() {
        return this.form.visitData.fillDate
          ? moment(this.form.visitData.fillDate).format('DD-MM-YYYY')
          : '';
      },
      set(val) {
        this.form.visitData.fillDate = val;
      },
    },
    formatDateVisitDate: {
      get() {
        return this.form.visitData.visitDate
          ? moment(this.form.visitData.visitDate).format('DD-MM-YYYY')
          : '';
      },
      set(val) {
        this.form.visitData.visitDate = val;
      },
    },
    formatDateHisDate: {
      get() {
        return this.form.currentPregnancy?.hisDate
          ? moment(this.form.currentPregnancy?.hisDate).format('DD-MM-YYYY')
          : '';
      },
      set(val) {
        this.form.currentPregnancy.hisDate = val;
      },
    },
    formatDateMucusOutDate: {
      get() {
        return this.form.currentPregnancy?.mucusOutDate
          ? moment(this.form.currentPregnancy?.mucusOutDate).format(
              'DD-MM-YYYY',
            )
          : '';
      },
      set(val) {
        this.form.currentPregnancy.mucusOutDate = val;
      },
    },
    formatDateAmnioticFluidOutDate: {
      get() {
        return this.form.currentPregnancy?.amnioticFluidOutDate
          ? moment(this.form.currentPregnancy?.amnioticFluidOutDate).format(
              'DD-MM-YYYY',
            )
          : '';
      },
      set(val) {
        this.form.currentPregnancy.amnioticFluidOutDate = val;
      },
    },
  },
  methods: {
    ...mapMutations(['']),
    ...mapActions(['addPregnancyHistory', 'removePregnancyHistory']),
    preventDefaultBehaviour(event) {
      if (event.key === ' ') {
        event.preventDefault();
      }
    },
    handleAddPregnancyHistory() {
      this.addPregnancyHistory();
    },
    handleRemovePregnancyHistory(index) {
      this.removePregnancyHistory(index);
    },
    validateInput(event) {
      const inputValue = event.target.value;
      const maxLength = 5;
      const allowedKeys = [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'Control',
      ];
      const allowedCharacters = /^[0-9]*$/;
      if (allowedKeys.includes(event.key)) {
        return;
      }
      if (
        inputValue.length >= maxLength ||
        !allowedCharacters.test(event.key)
      ) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: start;
  gap: 1.5rem;
  .divider {
    margin-top: 1.5rem;
    width: 100%;
  }
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: #3f484a;
  }
  .input-field {
    margin-top: 0.5rem;
    width: 100%;
  }
  h4 {
    font-weight: 400;
    color: #137bc0;
    text-align: start;
    margin-bottom: 0.7vw;
  }
}
section {
  width: 100%;
}
.required:after {
  content: ' *';
  color: red;
}
::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

::v-deep
  .v-text-field__slot
  input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
