import Constant from '@/const';
import axios from 'axios';

const getInformConsentById = async idInformConsent => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/document/inform-consent/${idInformConsent}`),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getInformConsentById;
