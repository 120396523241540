const state = {
  anamnesis: [''],
  case_type: [],
  eye: '',
  motoric: '',
  verbal: '',
  total: '',
  sistol: null,
  diastol: null,
  pulse: null,
  weight: null,
  height: null,
  belly_circumference: null,
  respiration_rate: null,
  saturation: null,
  temperature: null,
  main_diagnose: {
    text: null,
    detail: '',
  },
  secondary_diagnose: [
    {
      text: null,
      detail: '',
    },
  ],
  differential_diagnose: [
    {
      text: null,
      description: '',
    },
  ],
  action_plan: [
    {
      info: '',
      time: '',
    },
  ],
  follow_up: '',
  refer_to: '',
  igd_therapy: '',
};

const getters = {
  getAnamnesis: state => state.anamnesis,
  getCaseType: state => state.case_type,
  getMainDiagnose: state => state.main_diagnose,
  getSecondaryDiagnose: state => state.secondary_diagnose,
  getDifferentialDiagnose: state => state.differential_diagnose,
  getEye: state => state.eye,
  getMotoric: state => state.motoric,
  getVerbal: state => state.verbal,
  getTotal: state => {
    let total =
      (+state.eye?.value || 0) +
      (+state.motoric?.value || 0) +
      (+state.verbal?.value || 0);
    if (total == 3) {
      return `${total} (Koma)`;
    }
    if (total == 4) {
      return `${total} (Semi-Koma)`;
    }
    if (total == 5 || total == 6) {
      return `${total} (Stupor)`;
    }
    if (total >= 7 && total <= 9) {
      return `${total} (Samnolen)`;
    }
    if (total >= 10 && total <= 11) {
      return `${total} (Delirium)`;
    }
    if (total >= 12 && total <= 13) {
      return `${total} (Apatis)`;
    }
    if (total >= 14 && total <= 15) {
      return `${total} (Koma)`;
    }
  },
  getSistol: state => state.sistol,
  getDiastol: state => state.diastol,
  getPulse: state => state.pulse,
  getWeight: state => state.weight,
  getHeight: state => state.height,
  getBellyCircumference: state => state.belly_circumference,
  getRespirationRate: state => state.respiration_rate,
  getSaturation: state => state.saturation,
  getTemperature: state => state.temperature,
  getActionPlan: state => state.action_plan,
  getFollowUp: state => state.follow_up,
  getReferTo: state => state.refer_to,
  getIgdTherapy: state => state.igd_therapy,
};

const mutations = {
  clearDoctorAssessmentState(state) {
    Object.assign(state, {
      anamnesis: [''],
      case_type: [],
      eye: '',
      motoric: '',
      verbal: '',
      total: '',
      sistol: null,
      diastol: null,
      pulse: null,
      weight: null,
      height: null,
      belly_circumference: null,
      respiration_rate: null,
      saturation: null,
      temperature: null,
      main_diagnose: {
        text: null,
        detail: '',
      },
      secondary_diagnose: [
        {
          text: null,
          detail: '',
        },
      ],
      differential_diagnose: [
        {
          text: null,
          description: '',
        },
      ],
      action_plan: [
        {
          info: '',
          time: '',
        },
      ],
      follow_up: '',
      refer_to: '',
      igd_therapy: '',
    });
  },
  setDoctorAnamnesis(state, payload) {
    state.anamnesis = payload;
  },
  setActionPlan(state, payload) {
    state.action_plan = payload;
  },
  addAnamnesis(state) {
    state.anamnesis.push('');
  },
  deleteAnamnesis(state, index) {
    state.anamnesis.splice(index, 1);
  },
  addActionPlan(state) {
    state.action_plan.push({
      info: '',
      time: '',
    });
  },
  deleteActionPlan(state, index) {
    state.action_plan.splice(index, 1);
  },
  setSecondaryDiagnose(state, payload) {
    state.secondary_diagnose = payload;
  },
  setDifferentialDiagnose(state, payload) {
    state.differential_diagnose = payload;
  },
  addSecondaryDiagnose(state) {
    state.secondary_diagnose.push({
      text: null,
      detail: '',
    });
  },
  addDiffDiagnose(state) {
    state.differential_diagnose.push({
      text: null,
      description: '',
    });
  },
  deleteSecondaryDiagnose(state, index) {
    state.secondary_diagnose.splice(index, 1);
  },
  deleteDiffDiagnose(state, index) {
    state.differential_diagnose.splice(index, 1);
  },
  setMainDiagnose(state, payload) {
    state.main_diagnose = payload;
  },
  setCaseType(state, payload) {
    state.case_type = payload;
  },
  setEye(state, payload) {
    state.eye = payload;
  },
  setMotoric(state, payload) {
    state.motoric = payload;
  },
  setVerbal(state, payload) {
    state.verbal = payload;
  },
  setDoctorSistol(state, payload) {
    state.sistol = payload;
  },
  setDoctorDiastol(state, payload) {
    state.diastol = payload;
  },
  setDoctorPulse(state, payload) {
    state.pulse = payload;
  },
  setDoctorWeight(state, payload) {
    state.weight = payload;
  },
  setDoctorHeight(state, payload) {
    state.height = payload;
  },
  setDoctorBellyCircumference(state, payload) {
    state.belly_circumference = payload;
  },
  setDoctorRespirationRate(state, payload) {
    state.respiration_rate = payload;
  },
  setDoctorSaturation(state, payload) {
    state.saturation = payload;
  },
  setDoctorTemperature(state, payload) {
    state.temperature = payload;
  },
  setFollowUp(state, payload) {
    state.follow_up = payload;
  },
  setReferTo(state, payload) {
    state.refer_to = payload;
  },
  setIgdTherapy(state, payload) {
    state.igd_therapy = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
