<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Detail Jurnal Transaksi</h3>
    </v-card-title>
    <v-row no-gutters class="pa-5" style="height: 71vh;">
      <v-col cols="4" class="px-5 mx-5">
        <div class="form-style">
          <label class="font-weight-bold body-2 pb-3">Kode Jurnal</label>
          <label class="body-2">{{ input.journalCode }}</label>

          <label class="font-weight-bold body-2">Tipe Transaksi</label>
          <v-combobox
            placeholder="Pilih Tipe Transaksi"
            dense
            :loading="isLoading"
            readonly
            :items="resource.transactionType"
            v-model="input.transactionType"
          ></v-combobox>

          <label class="font-weight-bold body-2">Tipe Jurnal</label>
          <v-combobox
            placeholder="Pilih Tipe Jurnal"
            :loading="isLoading"
            dense
            readonly
            :items="resource.journalType"
            v-model="input.journalType"
          ></v-combobox>

          <label class="font-weight-bold body-2">Unit</label>
          <v-combobox
            placeholder="Pilih Unit"
            :loading="isLoading"
            dense
            readonly
            :items="resource.unit"
            v-model="input.unit"
          ></v-combobox>

          <label class="font-weight-bold body-2">No. Bukti</label>
          <v-text-field
            placeholder="Masukkan No Bukti"
            :loading="isLoading"
            dense
            readonly
            v-model="input.invoiceNumber"
          ></v-text-field>

          <label class="font-weight-bold body-2">Uraian</label>
          <v-textarea
            placeholder="Masukkan Uraian"
            outlined
            :loading="isLoading"
            dense
            readonly
            rows="2"
            no-resize
            v-model="input.description"
          ></v-textarea>

          <label class="font-weight-bold body-2">Tanggal Transaksi</label>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatedDate"
                placeholder="Pilih Tanggal"
                dense
                :loading="isLoading"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :max="today"
              :min="today"
              v-model="date"
              @change="
                menu = false;
                checkBackdate();
              "
            />
          </v-menu>

          <label class="font-weight-bold body-2">Catatan</label>
          <label></label>
          <!-- <v-textarea
            placeholder="Masukkan Catatan"
            outlined
            dense
            rows="2"
            no-resize
            hide-details="false"
          ></v-textarea> -->
        </div>
      </v-col>

      <v-col cols="7">
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          :items-per-page="table.items.length"
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-left">{{ table.items.indexOf(item) + 1 }}</td>
              <td class="text-left">{{ item.code }}</td>
              <td class="text-left">{{ item.codeName }}</td>
              <td class="text-right">{{ formattedCurrency(item.debit) }}</td>
              <td class="text-right">{{ formattedCurrency(item.credit) }}</td>
            </tr>
          </template>
          <template v-slot:[`body.append`]="{}">
            <tr style="background-color: #F0F9FF;">
              <td colspan="3">
                <label class="font-weight-bold">TOTAL</label>
              </td>
              <td colspan="1" class="text-right">
                <label class="font-weight-bold">{{
                  currency(totalDebit)
                }}</label>
              </td>
              <td colspan="1" class="text-right">
                <label class="font-weight-bold">{{
                  currency(totalCredit)
                }}</label>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col class="px-5 mx-5">
        <v-row no-gutters class="pt-5">
          <v-col
            cols="5"
            :class="document.files.length > 0 ? 'files-style' : ''"
          >
            <v-row no-gutters>
              <template v-for="(arrs, fileIndex) in document.files">
                <v-col cols="6" class="pb-2 pr-2" :key="'file-' + fileIndex">
                  <v-card outlined class="pa-1" @click="openFile(arrs)">
                    <v-row no-gutters align="center">
                      <v-col cols="1">
                        <v-icon
                          v-if="arrs.name.search(/.(jpeg|jpg|png)/i) !== -1"
                          color="primary"
                          small
                          >mdi-file-image</v-icon
                        >
                        <v-icon
                          v-else-if="arrs.name.search(/.pdf/i) !== -1"
                          color="primary"
                          small
                          >mdi-file-pdf-box</v-icon
                        >
                        <v-icon v-else class="icon">mdi-file-code</v-icon>
                      </v-col>
                      <v-col
                        cols="10"
                        class="text-left px-2 d-flex justify-space-between align-center"
                      >
                        <label class="caption font-weight-bold">{{
                          arrs.name
                        }}</label>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="2" style="max-width: 12vw;" class="text-left">
            <label class="font-weight-bold body-2">Riwayat Aktivitas</label>
          </v-col>
          <v-col cols="4" class="text-left">
            <label class="grey--text body-2"
              >Printer menambahkan Jurnal pada {{ input.created_at.date }}
              {{ input.created_at.time }} WIB
            </label>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-card-actions class="pb-6">
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="primary"
        class="text-none"
        width="100"
        @click="$emit('close-dialog')"
        >Kembali</v-btn
      >
      <v-btn
        color="primary"
        class="text-none"
        depressed
        :disabled="item.status === 'Sudah Koreksi'"
        @click="correctionJournal()"
        width="100"
        >Koreksi</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import Constant from '@/const';
import formatMixin from '@/mixin/formatMixin';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'CorrectionJournal',
  mixins: [formatMixin, alertMixin],
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      menu: false,
      date: moment(new Date()).format('YYYY-MM-DD'),
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Kode Akun', value: 'code' },
          { text: 'Nama Akun', value: 'name' },
          { text: 'Debit', value: 'debit' },
          { text: 'Kredit', value: 'credit' },
        ],
        items: [],
      },
      today: moment(new Date()).format('YYYY-MM-DD'),
      document: {
        files: [],
        failedFiles: [],
      },
      dialogJournal: false,
      selectedForm: null,
      input: {
        journalCode: '',
        transactionType: '',
        journalType: '',
        unit: '',
        invoiceNumber: '',
        description: '',
        date: null,
        note: '',
        created_at: '',
      },
      resource: {
        transactionType: [
          'Transfer',
          'Utang',
          'Piutang',
          'Pendapatan',
          'Beban',
        ],
        journalType: ['Jurnal Umum', 'Jurnal Penutup', 'Jurnal Penyesuaian'],
        unit: [],
      },
    };
  },
  mounted() {
    this.getJournalById(this.item.id);
    this.getUnit();
  },
  computed: {
    formatedDate() {
      return this.input.date
        ? moment(this.input.date)
            .locale('id')
            .format('DD MMMM YYYY')
        : moment(this.date)
            .locale('id')
            .format('DD MMMM YYYY');
    },
    totalDebit() {
      return this.table.items.reduce((a, { debit }) => a + debit, 0);
    },
    totalCredit() {
      return this.table.items.reduce((a, { credit }) => a + credit, 0);
    },
  },
  methods: {
    getJournalById(id) {
      this.isLoading = true;
      axios
        .get(Constant.apiUrl.concat(`/finance/journal/${id}`))
        .then(response => {
          const [data] = response.data.data;
          this.input = {
            journalCode: _.get(data, 'journal_code', ''),
            transactionType: _.get(data, 'transaction_type', ''),
            journalType: _.get(data, 'journal_type', ''),
            unit: _.get(data, 'unit', ''),
            invoiceNumber: _.get(data, 'proof_code', ''),
            description: _.get(data, 'description', ''),
            date: moment(_.get(data, 'date', '')).format('YYYY-MM-DD'),
            note: _.get(data, 'note', ''),
            created_at: {
              date: moment(_.get(data, 'timestamps.created_at', '')).format(
                'DD/MM/YYYY',
              ),
              time: moment(_.get(data, 'timestamps.created_at', '')).format(
                'HH:mm',
              ),
            },
            status: {
              posted: data.status.posted,
              journal: data.status.journal,
            },
          };
          this.table.items = data.entries.map(arr => {
            return {
              code: _.get(arr, 'accountbalances.account_code', ''),
              codeName: _.get(arr, 'accountbalances.name', ''),
              debit: _.get(arr, 'credit', ''),
              credit: _.get(arr, 'debit', ''),
              id_accountBalance: _.get(arr, 'id_accountBalance', ''),
              name: _.get(arr, 'name', ''),
              type: _.get(arr, 'type', ''),
            };
          });

          this.document.files =
            data.files.length !== 0
              ? data.files.map(arr => {
                  return {
                    name: arr.original_file_name,
                    path: arr.path,
                    type: arr.original_file_name.split('.')[
                      arr.original_file_name.split('.').length - 1
                    ],
                  };
                })
              : [];
        })
        .catch(error => {
          this.showErrorAxios(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    correctionJournal() {
      Swal.fire({
        title: 'Peringatan',
        text: 'Apakah Anda yakin ingin mengoreksi Jurnal Transaksi ini? ',
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Koreksi',
        cancelButtonText: 'Batal',
        confirmButtonColor: '#3498DB',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          const formData = new FormData();

          this.document.files.forEach(arr => {
            formData.append('files', arr);
          });
          formData.append('transaction_type', this.input.transactionType);
          formData.append('journal_type', this.input.journalType);
          formData.append('unit', this.input.unit);
          formData.append('proof_code', this.input.invoiceNumber);
          formData.append('description', this.input.description);
          formData.append('date', this.input.date);
          formData.append('note', this.input.note);
          formData.append('transaction_value', this.totalDebit);
          this.table.items.forEach((arr, idx) => {
            formData.append(
              `entries[${idx}][id_accountBalance]`,
              arr.id_accountBalance,
            );
            formData.append(`entries[${idx}][debit]`, arr.debit);
            formData.append(`entries[${idx}][type]`, arr.type);
            formData.append(`entries[${idx}][name]`, arr.name);
            formData.append(`entries[${idx}][credit]`, arr.credit);
          });
          formData.append('status[posted]', this.input.status.posted);
          formData.append('status[journal]', 'Sudah Koreksi');
          formData.append(
            'timestamps[created_by]',
            this.$store.getters.userLoggedIn.id,
          );

          axios
            .put(
              Constant.apiUrl.concat(`/finance/journal/${this.item.id}`),
              formData,
            )
            .then(() => {
              Swal.fire({
                title: 'Koreksi Jurnal Transaksi Berhasil',
                icon: 'success',
                confirmButtonText: 'Selesai',
              }).then(result => {
                if (result.isConfirmed) {
                  this.$emit('close-dialog');
                }
              });
            })
            .catch(err => {
              this.showErrorAxios(err);
            });
        } else {
          Swal.fire({
            title: 'Koreksi Jurnal Transaksi Dibatalkan',
            icon: 'success',
            confirmButtonText: 'Selesai',
          }).then(result => {
            if (result.isConfirmed) {
              this.$emit('close-dialog');
            }
          });
        }
      });
    },
    removeFiles(fileIndex) {
      this.document.files.splice(fileIndex, 1);
    },
    formatSize(size) {
      return size < 1000000
        ? `${(size / 1000).toFixed(1)} KB`
        : `${(size / 1000000).toFixed(1)} MB`;
    },
    openFile(item) {
      window.open(Constant.apiUrl.concat(`/${item.path}`));
    },
    getUnit() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=1&itemCount=&search=&sort=name',
          ),
        )
        .then(response => {
          const { data } = response.data;
          this.resource.unit = data.map(arr => {
            return arr.name;
            // id: arr._id,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.entry-style {
  border-width: 2px;
  position: absolute;
  right: 2vw;
}
.form-style {
  display: grid;
  grid-template-columns: 12vw 15vw;
  text-align: left;
  align-items: baseline;
}
.files-style {
  height: 10vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: gray;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}
</style>
