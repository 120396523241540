import Constant from '@/const';
import axios from 'axios';

// status = inpatient(rawat inap) || outpatient(rawat jalan) || igd
const getCheckupRegist = async (idRegist, status = '') => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/patient/checkup/regist/${idRegist}/history?status=${status}`,
      ),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getCheckupRegist;
