const state = {
  snackbar: {
    text: '',
    color: '',
    show: false,
  },
};

const getters = {
  // Get Snackbar
  getSnackbar: state => state.snackbar,
};

const mutations = {
  clearSnackbar(state) {
    state.snackbar = {
      show: false,
      color: 'success',
      text: '',
    };
  },
  setSnackbar(state, payload) {
    state.snackbar = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
