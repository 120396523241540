<template>
  <v-container fluid>
    <div class="mb-5 d-flex justify-space-between align-center">
      <div class="text-start">
        <h2>Laporan Pasien MJKN</h2>
        <v-progress-linear
          v-if="this.loading.table"
          :indeterminate="true"
          color="success"
        ></v-progress-linear>
        <span v-else class="success--text subtitle-2"
          >{{ patientTotalText }}
        </span>
      </div>
      <div class="d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="grey"
              icon
              text
              @click="getMjknReportingData"
            >
              <v-icon :class="loading.table ? 'refresh' : ''"
                >mdi-autorenew
              </v-icon>
            </v-btn>
          </template>
          <span>Reload</span>
        </v-tooltip>
        <v-text-field
          background-color="grey lighten-3"
          prepend-inner-icon="mdi-magnify"
          placeholder="Cari nama pasien"
          class="ml-3 search-input"
          solo
          flat
          dense
          hide-details
          single-line
          v-model="search"
        />
        <v-btn
          color="primary"
          class="text-capitalize ml-3"
          depressed
          @click="exportData"
          :loading="loading.export"
          style="letter-spacing: 0.1px;"
          >Export
        </v-btn>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <div class="d-flex">
        <v-combobox
          dense
          single-line
          solo
          flat
          outlined
          class="mr-3"
          :items="resource.types"
          v-model="filter.type"
          append-icon="mdi-chevron-down"
        />
        <v-menu
          dense
          v-if="filter.type === 'Per Bulan'"
          ref="menu_month"
          v-model="menu.month"
          :close-on-content-click="true"
          :return-value.sync="month.formatted"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              flat
              single-line
              solo
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              v-model="month.formatted"
              prepend-inner-icon="mdi-calendar"
              placeholder="Pilih Bulan"
            />
          </template>
          <v-date-picker
            v-model="month.raw"
            type="month"
            locale="id"
            scrollable
            @input="$refs.menu_month.save(formatMonth(month.raw))"
          ></v-date-picker>
        </v-menu>
        <div>
          <v-menu
            dense
            v-if="
              filter.type === 'Per Tanggal' || filter.type === 'Rentang Tanggal'
            "
            ref="menu_startdate"
            v-model="menu.startDate"
            :close-on-content-click="true"
            :return-value.sync="startDate.formatted"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                flat
                single-line
                solo
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                v-model="startDate.formatted"
                prepend-inner-icon="mdi-calendar"
                :placeholder="
                  filter.type === 'Per Tanggal'
                    ? 'Pilih Tanggal'
                    : 'Tanggal Awal'
                "
              />
            </template>
            <v-date-picker
              v-model="startDate.raw"
              locale="id"
              scrollable
              @input="$refs.menu_startdate.save(formatDate(startDate.raw))"
            ></v-date-picker>
          </v-menu>
        </div>
        <span v-if="filter.type === 'Rentang Tanggal'" class="pt-3 mx-3"
          >s.d
        </span>
        <div>
          <v-menu
            dense
            v-if="filter.type === 'Rentang Tanggal'"
            ref="menu_enddate"
            v-model="menu.endDate"
            :close-on-content-click="true"
            :return-value.sync="endDate.formatted"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                flat
                single-line
                solo
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                v-model="endDate.formatted"
                prepend-inner-icon="mdi-calendar"
                placeholder="Tanggal Akhir"
              />
            </template>
            <v-date-picker
              v-model="endDate.raw"
              locale="id"
              scrollable
              @input="$refs.menu_enddate.save(formatDate(endDate.raw))"
            ></v-date-picker>
          </v-menu>
        </div>
      </div>
      <div class="d-flex">
        <v-combobox
          dense
          single-line
          solo
          flat
          outlined
          return-object
          hide-details
          class="mr-3"
          append-icon="mdi-chevron-down"
          item-value="id"
          item-text="name"
          :loading="loading.unit"
          @change="onChangeFilter"
          v-show="isFilter"
          v-model="filter.unit"
          :items="resource.units"
        />
        <v-combobox
          dense
          flat
          single-line
          solo
          outlined
          hide-details
          @change="onChangeFilter"
          v-model="filter.doctor"
          :items="resource.doctors"
          :loading="loading.doctor"
          v-show="isFilter"
          item-value="id"
          item-text="name"
          class="mr-3"
          append-icon="mdi-chevron-down"
        />
        <v-btn
          outlined
          light
          dense
          class="text-capitalize px-2 btn-filter"
          @click="filterSwitch"
          style="letter-spacing: 0.01px;"
          ><v-icon class="mr-1" small>mdi-filter-variant</v-icon> Filter</v-btn
        >
      </div>
    </div>
    <table class="main-table">
      <tr class="grid-table header-row">
        <th v-for="(header, index) in table.headers" :key="`header-${index}`">
          {{ header }}
        </th>
      </tr>
      <v-progress-linear
        :indeterminate="true"
        v-if="loading.table"
      ></v-progress-linear>
      <div v-if="table.items.length" class="table-wrapper">
        <tr
          class="grid-table data-row"
          v-for="(item, index) in table.items"
          :key="`row-${index}`"
        >
          <td>{{ item.no }}</td>
          <td>{{ item.booking_date || '-' }}</td>
          <td>{{ item.date || '-' }}</td>
          <td>{{ item.registration_number || '-' }}</td>
          <td>{{ item.queue_number || '-' }}</td>
          <td>{{ item.rm_number | '-' }}</td>
          <td>{{ item.patient_name || '-' }}</td>
          <td>{{ item.unit?.name }}</td>
          <td>{{ item.doctor?.name || '-' }}</td>
          <td>{{ item.bpjs_number || '-' }}</td>
          <td>{{ item.address || '-' }}</td>
        </tr>
      </div>

      <div
        class="text-center grey--text mt-5 pb-5"
        style="border-bottom: 1px solid #ededed;"
        v-else
      >
        Tidak ada data ditemukan
      </div>
    </table>

    <div class="pt-3 d-flex justify-start">
      <v-pagination
        v-if="table?.items?.length"
        v-model="pagination.page"
        :length="pagination.length"
        total-visible="4"
        @input="getMjknReportingData()"
      />
    </div>
    <v-snackbar
      timeout="4000"
      content-class="d-flex justify-space-between white--text align-center"
      elevation="0"
      small
      light
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn fab small text @click.native="() => (snackbar.show = false)"
        ><v-icon color="white" small>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
const moment = require('moment-timezone');
const _ = require('lodash');

import {
  getMjknReporting,
  exportMjknReporting,
} from '@/fetchApi/Reporting/MJKN';
import { getAllUnit } from '@/fetchApi/MasterData/Unit';
import { getAllStaff } from '@/fetchApi/MasterData/Staff';
import { limitText } from '@/helper/index';

export default {
  name: 'MjknReportingDashboard',
  data() {
    return {
      search: '',
      interval: null,
      isFilter: false,
      patientTotalText: '',
      month: {
        raw: moment().format('YYYY-MM'),
        formatted: moment().format('MM-YYYY'),
      },
      snackbar: {
        color: '',
        text: '',
        show: false,
      },
      startDate: {
        raw: '',
        formatted: '',
      },
      endDate: {
        raw: '',
        formatted: '',
      },
      menu: {
        month: false,
        endDate: false,
        startDate: false,
      },
      loading: {
        table: false,
        doctor: false,
        unit: false,
        export: false,
      },
      filter: {
        type: 'Per Bulan',
        unit: { name: 'Semua Poli', id: '' },
        doctor: { name: 'Semua Dokter', id: '' },
      },
      resource: {
        types: ['Semua Waktu', 'Per Bulan', 'Per Tanggal', 'Rentang Tanggal'],
        units: [{ name: 'Semua Poli', id: '' }],
        doctors: [{ name: 'Semua Dokter', id: '' }],
      },
      pagination: {
        page: 1,
        length: null,
      },
      table: {
        headers: [
          'No',
          'Tgl Booking',
          'Tgl Pelayanan',
          'Kode Registrasi',
          'No Antrean',
          'No RM',
          'Nama Pasien',
          'Poliklinik',
          'Nama Dokter',
          'No BPJS',
          'Alamat',
        ],
        items: [],
      },
      patientTotal: null,
    };
  },
  watch: {
    search() {
      this.pagination.page = 1;
      this.searchData(this);
    },
    'month.formatted'(val) {
      if (!val) return;
      this.onChangeFilter();
    },
    'startDate.formatted'(val) {
      if (!val) return;
      if (this.filter.type === 'Per Tanggal') {
        this.onChangeFilter();
      }
      if (this.filter.type === 'Rentang Tanggal' && !this.endDate.formatted)
        return;
      this.onChangeFilter();
    },
    'endDate.formatted'(val) {
      if (!val) return;
      if (this.filter.type === 'Rentang Tanggal' && !this.startDate.formatted)
        return;
      this.onChangeFilter();
    },
    'filter.type'(val) {
      if (!val) return;
      this.resetFilter();
      if (val === 'Semua Waktu') {
        this.onChangeFilter();
      }
    },
  },
  computed: {
    polyAndDoctor: {
      get() {
        return `${this.filter.unit.id ? `di ${this.filter.unit?.name}` : ''} ${
          this.filter.doctor.id ? `dengan ${this.filter.doctor?.name}` : ''
        }`;
      },
    },
    itemsPerPage() {
      let items = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          items = 5;
          break;
        case 'lg':
          items = 7;
          break;
        case 'xl':
          items = 9;
          break;
        default:
          return 5;
      }
      return items;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getMjknReportingData();
    }, 650),
    setPatientTotalText() {
      switch (this.filter.type) {
        case 'Semua Waktu':
          this.patientTotalText = `Total ${this.formatNumber(
            this.patientTotal,
          )} pasien terdaftar ${this.polyAndDoctor}`;
          break;
        case 'Per Bulan':
          this.patientTotalText = `${this.formatNumber(
            this.patientTotal,
          )} pasien terdaftar pada bulan ${this.formatMonth(
            this.month.raw,
            true,
          )} ${this.polyAndDoctor}`;
          break;
        case 'Per Tanggal':
          this.patientTotalText = `${this.formatNumber(
            this.patientTotal,
          )} pasien terdaftar pada tanggal ${this.startDate.formatted} ${
            this.polyAndDoctor
          }`;
          break;
        case 'Rentang Tanggal':
          this.patientTotalText = `${this.formatNumber(
            this.patientTotal,
          )} pasien terdaftar dari tanggal ${this.startDate.formatted} s.d ${
            this.endDate.formatted
          } ${this.polyAndDoctor}`;
          break;
      }
    },
    filterSwitch() {
      this.isFilter = !this.isFilter;
    },
    formatMonth(month, text) {
      if (text) {
        return moment(month, 'YYYY-MM')
          .locale('id')
          .format('MMMM YYYY');
      }
      return moment(month, 'YYYY-MM').format('MM-YYYY');
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },

    async exportData() {
      try {
        this.loading.export = true;
        const params = {
          doctor: this.filter.doctor.id || undefined,
          unit: this.filter.unit.id || undefined,
          month: this.month.formatted || undefined,
          startDate:
            this.filter.type === 'Rentang Tanggal'
              ? this.startDate.formatted
              : undefined,
          endDate:
            this.filter.type === 'Rentang Tanggal'
              ? this.endDate.formatted
              : undefined,
          date:
            this.filter.type === 'Per Tanggal'
              ? this.startDate.formatted
              : undefined,
        };

        const response = await exportMjknReporting(params);
        const { url } = response.config;
        window.open(
          `${url}?startDate=${response.config.params.startDate ||
            ''}&endDate=${response.config.params.endDate || ''}&month=${response
            .config.params.month || ''}&date=${response.config.params.date ||
            ''}&unit=${response.config.params.unit || ''}&doctor=${response
            .config.params.doctor || ''}`,
          '_blank',
        );

        this.snackbar = {
          text: 'Berhasil mengekspor data',
          show: true,
          color: 'success',
        };
      } catch {
        this.snackbar = {
          text: 'Terjadi kesalahan, gagal mengekspor data',
          show: true,
          color: 'error',
        };
      } finally {
        this.loading.export = false;
      }
    },
    async getUnits() {
      try {
        this.loading.unit = true;
        const response = await getAllUnit();
        const units = response.data
          .filter(
            item =>
              item.detail.installation?.toLowerCase() ===
              'instalasi rawat jalan',
          )
          .map(item => {
            return {
              name: item.name,
              id: item._id,
            };
          });

        this.resource.units.push(...units);
      } catch {
        this.resource.units = [{ name: 'Semua Poli', id: '' }];
      } finally {
        this.loading.unit = false;
      }
    },
    async getDoctors() {
      try {
        this.loading.doctor = true;
        const response = await getAllStaff({ halaman: 1, itemCount: null });
        const doctors = response.data
          .filter(
            item =>
              item.role.role.some(item => item.toLowerCase() === 'dokter') &&
              item.role.status,
          )
          .map(item => {
            return {
              name: item.detail.name,
              id: item._id,
            };
          });

        this.resource.doctors.push(...doctors);
      } catch {
        this.resource.doctors = [{ name: 'Semua Dokter', id: '' }];
      } finally {
        this.loading.doctor = false;
      }
    },
    async getMjknReportingData() {
      try {
        this.loading.table = true;
        const params = {
          page: this.pagination.page,
          itemCount: this.itemsPerPage,
          search: this.search || undefined,
          doctor: this.filter.doctor.id || undefined,
          unit: this.filter.unit.id || undefined,
          month: this.month.formatted || undefined,
          startDate:
            this.filter.type === 'Rentang Tanggal'
              ? this.startDate.formatted
              : undefined,
          endDate:
            this.filter.type === 'Rentang Tanggal'
              ? this.endDate.formatted
              : undefined,
          date:
            this.filter.type === 'Per Tanggal'
              ? this.startDate.formatted
              : undefined,
        };

        const response = await getMjknReporting(params);

        const { length } = response.data;
        this.pagination.length = Math.ceil(length / this.itemsPerPage);
        this.patientTotal = length;

        this.table.items = response.data.data.map((item, index) => {
          return {
            ...item,
            address: limitText(item.address, 60),
            no: (this.pagination.page - 1) * this.itemsPerPage + index + 1,
            date: item.date ? moment(item.date).format('DD/MM/YYYY') : '-',
            booking_date: item.booking_date
              ? moment(item.booking_date).format('DD/MM/YYYY')
              : '-',
          };
        });
      } catch {
        this.table.items = [];
        this.patientTotal = 0;
      } finally {
        this.loading.table = false;
      }
    },
    resetFilter() {
      this.month = {
        raw: '',
        formatted: '',
      };
      this.startDate = {
        raw: '',
        formatted: '',
      };
      this.endDate = {
        raw: '',
        formatted: '',
      };
    },
    formatNumber(num) {
      return num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
    async onChangeFilter() {
      this.pagination.page = 1;
      await this.getMjknReportingData();
      this.setPatientTotalText();
    },
  },
  // component life cycle
  async mounted() {
    await this.getMjknReportingData();
    this.setPatientTotalText();
    this.getDoctors();
    this.getUnits();
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  width: 15vw !important;
}

.btn-filter {
  border: 1px solid #757575 !important;
  color: #757575;
}

.main-table {
  width: 100%;
  font-size: 0.8vw;

  .header-row {
    border-top: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    padding: 0.5vw 0;
  }

  .data-row {
    border-bottom: 1px solid #ededed;
    padding: 0.9vw 0;
  }

  .grid-table {
    display: grid;
    text-align: left;
    align-items: center;
    grid-auto-rows: min-content;
    width: 100%;
    column-gap: 1vw;
    grid-template-columns: 0.4fr repeat(3, 5.5vw) 4vw 3.5vw 11vw 6vw 10vw 1.5fr 13vw;

    th {
      color: #757575;
    }

    td {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }

  .table-wrapper {
    height: 53vh;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px !important;
      border-radius: 5px;
      background-color: transparent !important;
    }
    &::-webkit-scrollbar-thumb {
      width: 5px !important;
      border-radius: 5px;
      background-color: rgb(163, 163, 163) !important;
      // background-color: black !important;
    }
  }
}

.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

::v-deep .v-snack__content {
  padding: 0px 0px 0px 1vw;
}
</style>
