import Constant from '@/const';
import axios from 'axios';

const getUnitById = async id => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/master/unit/${id}`),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getUnitById;
