<template>
  <div
    v-if="
      stateResult === 'request' &&
        (stateIsFinished === 'not_scheduled' ||
          stateIsFinished === 'scheduled' ||
          getLabReqById.isInputed === false)
    "
  >
    <h3 class="d-flex justify-center align-center empty-text-state">
      Belum ada hasil pemeriksaan laboratorium
    </h3>
  </div>
  <div v-else class="wrap-result pe-10">
    <template>
      <v-row no-gutters align="baseline">
        <v-col cols="4" class="d-flex justify-start align-center">
          <v-row no-gutters align="baseline">
            <v-col class="d-flex justify-start align-center" cols="6">
              <label class="required">
                Petugas Laboratorium
              </label>
            </v-col>
            <v-col class="d-flex justify-start align-center" cols="6">
              <v-form ref="formResult">
                <v-autocomplete
                  id="Dokter Laboratorium"
                  item-text="name"
                  placeholder="Pilih Dokter"
                  :items="staff"
                  :item-value="staff => staff.id"
                  v-model="inputResultStaff"
                  :append-icon="
                    stateResult === 'request' || labResult.isFinished === true
                      ? ''
                      : 'mdi-chevron-down'
                  "
                  dense
                  @input="handleChangeStaff($event)"
                  :readonly="
                    stateResult === 'request' || labResult.isFinished === true
                  "
                  :rules="[v => !!v || 'Wajib Diisi']"
                />
              </v-form>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="8"> </v-col>
      </v-row>
      <v-tabs v-model="tabs" class="tab-header">
        <v-tab
          v-for="({ category }, index) in labResultTestItem"
          :key="index"
          class="text-none tab-header__tab"
          >{{ category }}</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tabs" class="pt-6">
        <span v-for="({ testItem }, index2) in labResultTestItem" :key="index2">
          <v-tab-item class="text-start" :transition="false">
            <span v-for="(item, index3) in testItem" :key="index3">
              <label>{{ item.name }}</label>
              <v-data-table
                single-expand
                :headers="table.headers"
                :items="item.result"
                class="mb-4 mt-2 table-class"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.result`]="{ index }">
                  <span
                    v-if="
                      stateResult === 'request' || labResult.isFinished === true
                    "
                    >{{ item.result[index].result }}</span
                  >
                  <span v-else>
                    <v-text-field
                      v-model="item.result[index].result"
                      @input="handleChangeInputResultItem(index2, index)"
                      dense
                      name="inputResultItem"
                      style="width: 80%;"
                    ></v-text-field>
                  </span>
                </template>
              </v-data-table>
            </span>
          </v-tab-item>
        </span>
        <v-row no-gutters class="text-start">
          <v-col cols="6" class="pr-4">
            <h4 class="pb-2 label-text-area">
              Catatan/Kesan
            </h4>
            <v-textarea
              v-model="inputResultNotes"
              outlined
              name="input-7-4"
              value="Catatan/Kesan"
              @input="handleChangeInputResultNotes($event)"
              :readonly="
                stateResult === 'request' || labResult.isFinished === true
              "
              :rules="[
                v => (v || '').length < 3000 || 'Maksimal 3000 karakter',
              ]"
              maxlength="3000"
            ></v-textarea>
          </v-col>
          <v-col cols="6" class="pl-4">
            <h4 class="pb-2 label-text-area">Rekomendasi</h4>
            <v-textarea
              v-model="inputResultRecommendation"
              outlined
              name="input-7-4"
              value="Rekomendasi"
              @input="handleChangeInputResultRecommendation($event)"
              :readonly="
                stateResult === 'request' || labResult.isFinished === true
              "
              :rules="[
                v => (v || '').length < 3000 || 'Maksimal 3000 karakter',
              ]"
              maxlength="3000"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-tabs-items>
    </template>
    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      elevation="0"
      :color="snackbar.color"
      height="15"
      class="pb-15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import getAllUnit from '@/fetchApi/MasterData/Unit/getAllUnit';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'ExaminationLaboratory',
);

const _ = require('lodash');

export default {
  name: 'ResultExaminationTab',
  computed: {
    ...mapGetters([
      'getLabReqById',
      'getLabResultById',
      'getInputResultNotes',
      'getInputResultRecommendation',
      'getSnackbar',
      'getInitialResultById',
      'getStaff',
      'getInputResultStaff',
      'getInputResultTestItem',
    ]),
    snackbar: {
      get() {
        return this.getSnackbar;
      },
      set(value) {
        this.setSnackbar(value);
      },
    },
    labResult: {
      get() {
        return this.getLabResultById;
      },
    },
    labResultTestItem: {
      get() {
        const testItems =
          this?.stateResult === 'request'
            ? this?.getLabReqById?.test_item
            : this?.getLabResultById?.test_item;

        const clonedTestItems = _.cloneDeep(testItems);

        const data = _.groupBy(clonedTestItems, 'category');
        const result = Object.entries(data).map(([category, items]) => ({
          category,
          testItem: items,
        }));
        this.setInputResultTestItem(
          result.map(category => {
            return category.testItem.map(itemsResult => {
              return {
                id_service: itemsResult.id_service,
                name: itemsResult.name,
                category: itemsResult.category,
                timestamps: {
                  created_by: itemsResult.timestamps.created_by,
                  created_at: itemsResult.timestamps.created_at,
                },
                result: itemsResult.result,
              };
            });
          }),
        );
        return result;
      },
    },
    inputResultTestItem: {
      get() {
        return this.getInputResultTestItem;
      },
    },
    inputResultNotes: {
      get() {
        return this.getInputResultNotes;
      },
      set(value) {
        this.setInputResultNotes(value);
      },
    },
    inputResultRecommendation: {
      get() {
        return this.getInputResultRecommendation;
      },
      set(value) {
        this.setInputResultRecommendation(value);
      },
    },
    inputResultStaff: {
      get() {
        switch (this.stateResult) {
          case 'result':
            return this.getInputResultStaff;
          case 'request':
            return this.getLabReqById.id_staff;
          default:
            return null;
        }
      },
      set(value) {
        this.setInputResultStaff(value);
      },
    },
    initialResultById: {
      get() {
        return this.getInitialResultById;
      },
    },
    staff: {
      get() {
        return this.getStaff;
      },
    },
  },
  props: {
    stateResult: String,
    stateIsFinished: String,
  },
  methods: {
    ...mapMutations([
      'setInputResultNotes',
      'setInputResultRecommendation',
      'setSnackbar',
      'setInputResultIsChanged',
      'clearSnackbar',
      'setInputResultStaff',
      'setInputResultTestItem',
    ]),
    ...mapActions(['resolveGetLabResult', 'resolveGetStaff']),
    handleChangeStaff(event) {
      this.setInputResultIsChanged(
        _.isEqual(event, this.initialResultById.id_staff),
      );
    },
    handleChangeInputResultItem(index2) {
      this.setInputResultIsChanged(
        _.isEqual(
          this.inputResultTestItem[index2],
          this.initialResultById.test_item[index2],
        ),
      );
    },
    handleChangeInputResultNotes(event) {
      this.setInputResultIsChanged(
        _.isEqual(event, this.initialResultById.clinical_notes),
      );
    },
    handleChangeInputResultRecommendation(event) {
      this.setInputResultIsChanged(
        _.isEqual(event, this.initialResultById.recommendation),
      );
    },
    async getUnitLab() {
      const { data } = await getAllUnit({
        keyword: 'Laboratorium',
        halaman: 1,
      });
      this.unitLabId = data[0]._id;
      this.resolveGetStaff({ id_unit: this.unitLabId, isActive: true });
    },
  },
  mounted() {
    this.getUnitLab();
    this.clearSnackbar();
    // this.resolveGetStaff({ id_unit: this.unitLabId });
    switch (this.stateResult) {
      case 'result':
        this.resolveGetLabResult();
        this.setInputResultIsChanged(true);
        break;
      case 'request':
        return;
      default:
        break;
    }
    this.tabs = 0;
  },
  data() {
    return {
      tabs: null,
      unitLabId: '',
      table: {
        headers: [
          {
            text: 'Nama Item',
            value: 'name',
            sortable: false,
            width: '25%',
          },
          {
            text: 'Hasil',
            value: 'result',
            sortable: false,
            width: '22.5%',
          },
          {
            text: 'Unit',
            value: 'unit',
            sortable: false,
            width: '15%',
          },
          {
            text: 'Nilai Normal',
            value: 'normal',
            sortable: false,
            width: '15%',
          },
          {
            text: 'Metode',
            value: 'method',
            sortable: false,
            width: '22.5%',
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
$primaryBlue: #137bc0;
$secondaryBlue: #deeef9;
$blackText: #404040;
$greyText: #9e9e9e;

.required::after {
  content: '*';
  color: #eb4747;
}
.wrap-result {
  overflow-y: scroll;
  height: 62vh;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: grey;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}
.empty-text-state {
  height: 50vh;
  color: $greyText;
}
.tab-header {
  overflow-x: auto;
  width: 100%;
  &__tab {
    min-width: 220px;
  }
}

.label-text-area {
  color: #757575;
  font-weight: 500;
}

::v-deep .table-class {
  border: 1px solid white !important;
  border-radius: 8px;
  overflow: hidden;
}
::v-deep .v-data-table {
  tbody tr {
    background-color: $secondaryBlue !important;
  }
}
::v-deep .v-data-table-header {
  background-color: $primaryBlue !important;
  tr th {
    color: white !important;
  }
}
</style>
