import Constant from '@/const';
import axios from 'axios';

const getIcareData = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/integration/pcare/icare'),
    );
    return response.data.data;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getIcareData;
