const state = {
  other_notes: '',
  lab: '',
  radiology: '',
};

const getters = {
  getOtherNotes: state => state.other_notes,
  getLab: state => state.lab,
  getRadiology: state => state.radiology,
};

const mutations = {
  clearSupportingInvestigationState(state) {
    Object.assign(state, {
      other_notes: '',
      lab: '',
      radiology: '',
    });
  },
  setOtherNotes(state, payload) {
    state.other_notes = payload;
  },
  setLab(state, payload) {
    state.lab = payload;
  },
  setRadiology(state, payload) {
    state.radiology = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
