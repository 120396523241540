<template>
  <div
    v-if="data.show"
    :style="style"
    class="warning-pcare d-flex flex-row rounded-lg text-left"
  >
    <v-icon class="pa-1 align-start" color="red" dense>
      mdi-alert
    </v-icon>
    <label class="ml-1 warning-pcare-text">
      <strong style="font-size: 16px; line-height: 24px;">{{
        data.title
      }}</strong>
      <br />
      {{ data.text }}
    </label>
    <v-icon
      class="pa-1 align-start"
      style="cursor: pointer; width: 27px; height: 27px;"
      @click="data.remove"
      dense
    >
      mdi-close
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'AlertCard',
  props: {
    data: {
      type: Object,
      default: null,
    },
    rightPos: {
      type: String,
      default: '0vw',
    },
  },
  computed: {
    style() {
      return `
      --right: ${this.rightPos}; 
      `;
    },
  },
};
</script>

<style scoped lang="scss">
.warning-pcare {
  position: absolute;
  top: 7vh;
  right: var(--right);
  background-color: #fff3f3;
  border: 0.5px solid #bdbdbd;
  max-width: 570px;
  padding: 16px 16px 16px 20px;

  .warning-pcare-text {
    color: #404040;
    font-size: 14px;
    font-style: normal;
    line-height: 21px;
  }
}
</style>
