<template>
  <section>
    <div class="d-flex justify-space-between action">
      <v-btn
        color="primary"
        text
        class="text-capitalize font-weight-bold"
        style="letter-spacing: 0.01px;"
        @click="onNavigateAction('dashboard')"
        ><v-icon small class="mr-2">mdi-arrow-left</v-icon> Halaman Utama</v-btn
      >
      <v-btn
        text
        @click="drawHistory"
        :loading="getLoading.table"
        :color="getHistory?.length ? 'primary' : 'grey'"
        class="text-capitalize font-weight-bold"
        style="letter-spacing: 0.01px;"
        ><v-icon class="mr-2">mdi-menu</v-icon> Riwayat CPPT</v-btn
      >
    </div>
    <v-container
      fluid
      :class="
        `${drawer ? 'pl-13 pr-0' : 'pl-10 pr-0'} ma-0 pt-0 content-wrapper`
      "
    >
      <div class="form">
        <div class="input-wrapper">
          <general-information />
          <subjective-data ref="subjective" />
          <objective-data ref="objective" />
          <assessment-and-plan ref="assessment" />
          <p-p-a-instruction />
        </div>
        <div class="footer-wrapper">
          <v-btn
            color="primary"
            class="text-capitalize"
            light
            depressed
            v-if="!isDetail"
            @click="save"
            :loading="loading.save"
            style="letter-spacing: 0.001px;"
            >Simpan</v-btn
          >
          <template v-if="userCanEdit">
            <v-btn
              color="primary"
              class="text-capitalize"
              light
              depressed
              v-if="isEMR ? false : isDetail && !isEdit"
              @click="edit"
              style="letter-spacing: 0.001px;"
              >Ubah</v-btn
            >
            <v-btn
              color="error"
              class="text-capitalize mr-3"
              light
              outlined
              depressed
              v-if="isDetail && isEdit"
              @click="cancelEdit"
              style="letter-spacing: 0.001px;"
              >Batal</v-btn
            >
            <v-btn
              color="primary"
              class="text-capitalize"
              light
              depressed
              :loading="loading.save"
              @click="update"
              v-if="isDetail && isEdit"
              style="letter-spacing: 0.001px;"
              >Simpan Perubahan</v-btn
            >
          </template>
        </div>
      </div>
      <div :class="`history-container ${drawer ? 'show' : ''}`">
        <history-c-p-p-t-new
          :drawer="drawer"
          @close-history="() => (drawer = false)"
        />
      </div>
    </v-container>
    <v-snackbar
      timeout="4000"
      content-class="d-flex justify-space-between white--text align-center"
      elevation="0"
      small
      light
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn fab small text @click.native="() => (snackbar.show = false)"
        ><v-icon color="white" small>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
  </section>
</template>

<script>
import GeneralInformation from './Input/GeneralInformation.vue';
import SubjectiveData from './Input/SubjectiveData.vue';
import ObjectiveData from './Input/ObjectiveData.vue';
import AssessmentAndPlan from './Input/AssessmentAndPlan.vue';
import PPAInstruction from './Input/PPAInstruction.vue';
import Swal from 'sweetalert2';
import HistoryCPPTNew from './HistoryCPPTNew.vue';
import { createNamespacedHelpers } from 'vuex';

const { scrollToEmptyInput } = require('@/helper');
const { mapMutations, mapGetters, mapActions } = createNamespacedHelpers(
  'CPPTStore',
);
const moment = require('moment-timezone');
export default {
  name: 'InputCPTTForm',
  data() {
    return {
      drawer: false,
      loading: {
        save: false,
      },
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
    };
  },
  props: {
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PPAInstruction,
    AssessmentAndPlan,
    ObjectiveData,
    SubjectiveData,
    GeneralInformation,
    HistoryCPPTNew,
  },
  computed: {
    ...mapGetters(['getHistory', 'getForm', 'getLoading', 'getIsFormChanged']),
    formHasChanged: {
      get() {
        return this.getIsFormChanged;
      },
    },
    user: {
      get() {
        return this.$store.getters.userLoggedIn.role;
      },
    },
    form: {
      get() {
        return this.getForm;
      },
    },
    isDetail: {
      get() {
        return this.getForm.isDetail;
      },
    },
    isEdit: {
      get() {
        return this.getForm.isEdit;
      },
      set(value) {
        this.setForm({ isEdit: value });
      },
    },
    userCanEdit: {
      get() {
        return (
          this.isDetail &&
          this.$store.getters.userLoggedIn.id ===
            this.getForm.generalInformation.ppa.id
        );
      },
    },
  },
  mounted() {
    if (!this.isDetail) {
      this.resolveGetInitialObjectiveData();
      this.setInitialFormState({ time: moment().format('HH:mm') });
      this.form.generalInformation.time = moment().format('HH:mm');
    }
  },
  methods: {
    ...mapMutations([
      'navigateTo',
      'setErrorVueEditor',
      'setForm',
      'clearCpptState',
      'setInitialFormState',
    ]),
    ...mapActions([
      'resolveGetInitialObjectiveData',
      'resolveGetCpptTableItems',
      'resolveGetCpptHistory',
      'resolvePostCpptData',
      'resolveGetCpptById',
      'resolvePutCppt',
    ]),
    drawHistory() {
      if (!this.getHistory.length) return;
      this.drawer = !this.drawer;
    },
    edit() {
      this.isEdit = !this.isEdit;
    },
    async cancelEdit() {
      await this.resolveGetCpptById(this.form.id); // re-get data by id
      this.isEdit = false;
    },
    async validate() {
      this.validateVueEditor();
      await this.$refs['objective'].$refs.form.validate();
      await this.$refs['assessment'].$refs.form.validate();
      if (
        !this.validateVueEditor() ||
        !this.$refs['objective'].$refs.form.validate() ||
        !this.$refs['assessment'].$refs.form.validate()
      ) {
        return false;
      } else {
        return true;
      }
    },
    validateVueEditor() {
      // anamnesis validation
      if (!this.form.subjective.anamnesis) {
        this.setErrorVueEditor({
          anamnesis: {
            state: true,
            message: 'Wajib diisi',
          },
        });
        return false;
      }
      if (
        this.form.subjective.anamnesis?.replace(/<[^>]*>/g, '')?.length > 3000
      ) {
        this.setErrorVueEditor({
          anamnesis: {
            state: true,
            message: 'Maksimal 3000 karakter',
          },
        });
        return false;
      }
      // other objective validation
      if (this.form.objective.other?.replace(/<[^>]*>/g, '')?.length > 3000) {
        this.setErrorVueEditor({
          otherObjective: {
            state: true,
            message: 'Maksimal 3000 karakter',
          },
        });
        return false;
      }
      // assessment note validation
      if (this.form.assessment.note?.replace(/<[^>]*>/g, '')?.length > 3000) {
        this.setErrorVueEditor({
          assessmentNote: {
            state: true,
            message: 'Maksimal 3000 karakter',
          },
        });
        return false;
      }
      // ppa instruction validation
      if (this.form.ppaInstruction?.replace(/<[^>]*>/g, '')?.length > 3000) {
        this.setErrorVueEditor({
          ppaInstruction: {
            state: true,
            message: 'Maksimal 3000 karakter',
          },
        });
        return false;
      }
      return true;
    },
    update() {
      Swal.fire({
        title:
          '<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Apakah Anda Yakin Ingin</p><p>Melakukan Perubahan Data?</p></div>',
        iconHtml: '<span class="mdi mdi-help-circle-outline"></span>',
        html:
          '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Setelah berhasil melakukan perubahan, anda </p><p>masih bisa melihat perubahan sebelumnya</p><p></p>pada data yang anda ubah</div>',
        showCancelButton: true,
        customClass: {
          confirmButton: 'button-confirm',
          cancelButton: 'button-cancel',
        },
        reverseButtons: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            this.loading.save = true;
            const isValid = await this.validate();
            if (!isValid) {
              scrollToEmptyInput();
              return;
            }
            await this.resolvePutCppt({
              role: this.user,
              id_cppt: this.getForm.id,
            });

            this.resolveGetCpptTableItems(); // re-get data table and history

            await this.resolveGetCpptById(this.form.id); // re-get data by id
            await this.resolveGetCpptHistory();

            this.snackbar = {
              show: true,
              text: 'Data berhasil diubah',
              color: 'success',
            };
          } catch {
            this.snackbar = {
              show: true,
              text: 'Terjadi kesalahan, form CPPT gagal diubah',
              color: 'error',
            };
          } finally {
            this.loading.save = false;
            this.edit(); // change state back to not editing
          }
        }
      });
    },
    async save() {
      try {
        this.loading.save = true;
        const isValid = await this.validate();
        if (!isValid) {
          scrollToEmptyInput();
          this.scrollToEmptyEditor();
          return;
        }
        await this.resolvePostCpptData(this.user);
        this.onNavigateAction('dashboard', true);
        this.$emit('show-snackbar', {
          show: true,
          text: 'Form CPPT Berhasil Disimpan',
          color: 'success',
        });
      } catch {
        this.snackbar = {
          show: true,
          text: 'Terjadi kesalahan, form CPPT gagal disimpan',
          color: 'error',
        };
      } finally {
        this.loading.save = false;
      }
    },
    onNavigateAction(destination, isSave) {
      if (isSave) {
        this.navigateTo(destination);
        return;
      }

      if (this.formHasChanged) {
        Swal.fire({
          title:
            '<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Data CPPT Belum Tersimpan</p></div>',
          iconHtml:
            '<span style="color: #f2994a !important" class="mdi mdi-alert-circle-outline"></span>',
          html:
            '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Data yang anda input akan hilang. Kembali dan</p><p>simpan formulir terlebih dahulu</p></div>',
          showCancelButton: true,
          customClass: {
            confirmButton: 'button-cancel',
            cancelButton: 'button-confirm',
          },
          confirmButtonText: 'Keluar',
          cancelButtonText: 'Kembali',
        }).then(result => {
          if (!result.isConfirmed) return;
          this.navigateTo(destination);
        });
      } else {
        this.navigateTo(destination);
      }
    },
    scrollToEmptyEditor() {
      const el = document.querySelector('.error--text.caption:first-of-type');
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
    },
  },
  beforeDestroy() {
    this.clearCpptState('form');
    this.clearCpptState('error-state');
    this.clearCpptState('initial-state');

    // reset all validation
    this.$refs['objective'].$refs.form.resetValidation();
    this.$refs['assessment'].$refs.form.resetValidation();
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-bottom: 1px solid #e0e0e0;
  height: 5vh;
  padding: 0 2vw;
  width: 101%;
}

.history-container {
  transform: translateX(100%);
  padding: 0.6vw;
  height: 100vh;
  opacity: 1;
  flex: 0;
  transition: transform 0.1s ease, opacity 0.1s ease;

  &.show {
    transform: translateX(0%);
    opacity: 1;
    transition: transform 0.5s ease, opacity 0.5s ease;
    flex: 1;
    border-left: 1px solid #e0e0e0;
  }
}

.content-wrapper {
  display: flex;

  .form {
    flex: 1.3;

    .input-wrapper {
      padding-right: 1vw;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 75vh;

      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 9px;
        background-color: #ededed;
      }
      &::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 9px;
        background-color: #9e9e9e;
      }
    }

    .footer-wrapper {
      display: flex;
      justify-content: flex-end;
      padding: 1vw;
    }
  }
}

::v-deep .v-snack__content {
  padding: 0px 0px 0px 1vw;
}

.button-cancel {
  background-color: white !important;
  border: 1px solid #137bc0 !important;
  border-radius: 4px !important;
  color: #137bc0 !important;
}
</style>
