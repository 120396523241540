import Constant from '@/const';
import axios from 'axios';

const getCpptByIdInpatient = async (id, type) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/inpatient/cppt/all/${id}`),
      {
        params: {
          type,
        },
      },
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getCpptByIdInpatient;
