import JsPDF from 'jspdf';
import moment from 'moment-timezone';
import printMixin from './printMixin';
import QRCode from 'qrcode';
const { terbilang, limitText } = require('@/helper');

export default {
  methods: {
    definePaymentMethod(type) {
      let result;
      if (type === undefined) {
        result = '-';
      }
      if (type === true) {
        result = 'Tunai';
      }
      if (type === false) {
        result = 'Debit';
      }
      return result;
    },
    printBillingInvoice({ billingData, patientBill, billingItems }) {
      const doc = new JsPDF({
        orientation: 'potrait',
        unit: 'cm',
        format: 'a4',
      });
      doc.setProperties({
        title: `Print Ticket Invoice : ${billingData.rm_number}`,
      });
      this.letterHead(doc, this.$store.getters.userLoggedIn.clinic, 'cm');
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');

      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');

      doc.autoTable({
        body: [
          ['No.RM', ':', `${patientBill.rm_number}`],
          ['Tanggal Masuk', ':', `${patientBill.enterDate}`],
          ['Tanggal Keluar', ':', `${patientBill.leaveDate}`],
          ['Tipe Penjamin', ':', `${patientBill.assurance}`],
          [
            'Tipe Pembayaran',
            ':',
            this.definePaymentMethod(patientBill.isCash),
          ],
        ],
        startY: 5,
        style: {
          halign: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: {
            cellWidth: 3,
            cellPadding: 0.06,
          },
          1: { cellWidth: 0.5, cellPadding: 0.06 },
          2: { cellWidth: 7, cellPadding: 0.06 },
        },
        margin: {
          left: 12,
          right: 2,
        },
        theme: 'plain',
      });

      doc.autoTable({
        body: [
          ['Nama', ':', `${patientBill.name}`],
          ['Alamat', ':', `${limitText(patientBill.address, 70)}`],
          ['Dokter', ':', `${patientBill.doctor}`],
          ['Unit', ':', `${billingData.unit}`],
        ],
        startY: 5,
        styles: {
          halign: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellPadding: 0.06, cellWidth: 2 },
          1: { cellPadding: 0.06, cellWidth: 0.5 },
          2: { cellPadding: 0.06, cellWidth: 7 },
        },
        margin: {
          left: 1.1,
          right: 2,
        },
        theme: 'plain',
      });

      const data = billingItems.map(item => {
        return item.billing.map(i => {
          return [
            i.name,
            i.quantity,
            this.currency(i.price || 0),
            this.currency(i.subTotal || 0),
          ];
        });
      });
      const itemTable = doc.autoTable.previous;
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text(
        'Kuitansi Pasien',
        doc.internal.pageSize.width / 2,
        itemTable.finalY + 1.5,
        'center',
      );
      let lastDidDrawPageData = null;
      doc.autoTable({
        head: [['Item', 'Jumlah', 'Harga (Rp.)', 'Total (Rp.)']],
        body: data.flat(),
        startY: itemTable.finalY + 2,
        style: {
          halign: 'left',
          fontSize: 11,
        },
        headStyles: {
          fillColor: [255, 255, 255],
          lineColor: '#00000',
          halign: 'center',
          lineWidth: {
            right: 0.001,
            left: 0.001,
            top: 0.001,
            bottom: 0.01,
          },
        },
        columnStyles: {
          0: {
            fillColor: [255, 255, 255],
            lineColor: '#00000',
            lineWidth: {
              right: 0.001,
              left: 0.001,
              top: 0.001,
              bottom: 0.01,
            },
          },
          1: {
            cellWidth: 1.7,
            halign: 'center',
            fillColor: [255, 255, 255],
            lineColor: '#00000',
            lineWidth: {
              right: 0.001,
              left: 0.001,
              top: 0.001,
              bottom: 0.01,
            },
          },
          2: {
            cellWidth: 4.8,
            halign: 'center',
            fillColor: [255, 255, 255],
            lineColor: '#00000',
            lineWidth: {
              right: 0.001,
              left: 0.001,
              top: 0.001,
              bottom: 0.01,
            },
          },
          3: {
            cellWidth: 4.8,
            halign: 'center',
            fillColor: [255, 255, 255],
            lineColor: '#00000',
            lineWidth: {
              right: 0.001,
              left: 0.001,
              top: 0.001,
              bottom: 0.01,
            },
          },
        },
        margin: {
          left: 1,
          top: 5,
          right: 1,
        },
        didDrawPage: d => {
          lastDidDrawPageData = d;
        },
        theme: 'plain',
      });

      const table = doc.autoTable.previous;
      let lastTable = null;
      if (lastDidDrawPageData.cursor.y < 21.5) {
        lastTable = table.finalY + 0.7;
      } else {
        doc.addPage();
        lastTable = 4.6;
      }
      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');
      doc.autoTable({
        body: [
          ['Total Tagihan', ':', this.currency(patientBill.sub_total || 0)],
          ['Limit Asuransi', ':', this.currency(patientBill.insurance || 0)],
          ['Pembulatan', ':', this.currency(patientBill.kebajikan || 0)],
          ['Tagihan Pasien', ':', this.currency(patientBill.total_bill || 0)],
          [
            'Tagihan Yang Dibayar',
            ':',
            this.currency(patientBill.total_paid || 0),
          ],
          ['Sisa Tagihan', ':', this.currency(patientBill.remaining_bill || 0)],
          ['Keterangan', ':', patientBill.isPaid ? 'LUNAS' : 'BELUM LUNAS'],
        ],
        startY: lastTable + 0.5,
        didParseCell: data => {
          if (data.row.index === 3) {
            // eslint-disable-next-line no-param-reassign
            data.cell.styles.fontStyle = 'bold';
          }
          if (data.row.index === 6 && data.column.index === 2) {
            // eslint-disable-next-line no-param-reassign
            data.cell.styles.fontStyle = 'bold';
          }
        },
        style: {
          halign: 'left',
          fontSize: 10,
        },
        columnStyles: {
          0: { cellPadding: 0.1, cellWidth: 4.1 },
          1: { cellPadding: 0.1, cellWidth: 0.5 },
          2: { cellPadding: 0.1, cellWidth: 5 },
        },
        margin: {
          left: 11,
          top: 5,
          right: 2,
        },
        theme: 'plain',
      });

      const tablePrev = doc.autoTable.previous;
      doc.setFont('helvetica', 'bold');
      doc.text('Terbilang : ', 1.1, tablePrev.finalY + 0.5);
      this.generateQRCode(
        {
          letterName: 'Kuitansi',
          doctor: this.$store.getters.userLoggedIn.name,
          sip: null,
          doc: doc,
          x: 0.8,
          y: lastTable,
        },
        QRCode,
        moment,
      );
      doc.setFont('helvetica', 'bolditalic');
      doc.text(
        `${terbilang(patientBill.total_bill)}`,
        1.1,
        tablePrev.finalY + 1,
        {
          maxWidth: 8,
        },
      );

      doc.setFont('helvetica', 'normal');
      doc.text(
        `(Kasir : ${this.$store.getters.userLoggedIn.name})`,
        11,
        tablePrev.finalY + 2,
      );
      doc.text(
        `${moment(
          patientBill.isPaid || patientBill.assurance.toLowerCase() === 'bpjs'
            ? patientBill.repayment
            : patientBill.rawEnterDate,
        )
          .locale('id')
          .format('DD MMMM YYYY HH:mm:ss')}`,
        11,
        tablePrev.finalY + 2.5,
      );
      doc.setHeaderFunction(
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
          'cm',
        ),
      );
      return doc;
    },
  },
};
