<template>
  <v-card class="pa-3">
    <v-card-title class="pt-0">
      <v-col class="pa-0 text-left" cols="2">
        <v-row class="body-2">
          <v-col class="font-weight-bold grey--text" cols="4">
            <label>No. RM</label>
          </v-col>
          <v-col class="font-weight-bold">
            <label>{{ data.rm_number }}</label>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pa-0 text-left" cols="3">
        <v-row class="body-2">
          <v-col class="font-weight-bold grey--text" cols="3">
            <label>Nama</label>
          </v-col>
          <v-col class="font-weight-bold">
            <label>{{ data.name }}</label>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pa-0 text-left" cols="2">
        <v-row class="body-2">
          <v-col class="font-weight-bold grey--text" cols="4">
            <label>Unit</label>
          </v-col>
          <v-col class="font-weight-bold">
            <label>{{ data.unit }}</label>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pa-0 text-left" cols="2">
        <v-row class="body-2">
          <v-col class="font-weight-bold grey--text" cols="7">
            <label>Tipe Penjamin</label>
          </v-col>
          <v-col class="font-weight-bold">
            <label>{{ data.assurance }}</label>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pa-0 text-right" cols="3">
        <h4>Input Hak Kelas/Limit Asuransi</h4>
      </v-col>
    </v-card-title>
    <v-divider></v-divider>
    <v-container class="ml-0">
      <v-row class="row-layout">
        <!-- <v-col cols="3">
          <v-card
            width="300"
            height="210"
            elevation="0"
            class="card-element pa-3"
            @click="payment = 0"
            :class="{ active: payment === 0 }"
          >
            <div class="text-left">
              <p class="font-weight-bold mb-0">Hak Kelas</p>
              <p class="caption">Hak Kelas</p>
              <p class="caption mb-0">Tagihan Rumah Sakit</p>
              <p class="font-weight-bold">Rp {{ data.total_bill }}</p>
            </div>
            <v-text-field
              label="Tarif INA-CBG's Hak Kelas"
              placeholder="Masukkan nominal"
              outlined
              v-model="hakKelas"
            ></v-text-field>
          </v-card>
        </v-col> -->
        <v-col cols="3">
          <v-card
            width="300"
            height="210"
            elevation="0"
            class="card-element pa-3"
            @click="payment = 1"
            :class="{ active: payment === 1 }"
          >
            <div class="text-left">
              <p class="font-weight-bold mb-0">Limit Asuransi</p>
              <p class="caption">Limit Asuransi</p>
              <p class="caption mb-0">Tagihan Klinik</p>
              <p class="font-weight-bold">Rp {{ data.total_bill }}</p>
            </div>
            <v-text-field
              label="Nominal Limit"
              placeholder="Masukkan nominal"
              outlined
              v-model="limit"
            ></v-text-field>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-btn depressed text @click="closeForm()">
        Keluar
      </v-btn>
      <v-spacer />
      <v-btn depressed tile color="primary" @click="saveData(payment)"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';

const axios = require('axios');

export default {
  props: {
    data: Object,
  },
  data() {
    return {
      payment: 0,
      limit: '',
      hakKelas: '',
    };
  },
  methods: {
    closeForm() {
      this.$emit('close-form');
    },
    saveData(payment) {
      const id_billing = this.data.id;

      if (payment === 0) {
        axios
          .post(Constant.apiUrl.concat(`/billing/hak-kelas/${id_billing}`), {
            amount: this.hakKelas,
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          })
          .then(response => {
            if (response.status === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Hak Kelas/Limit Asuransi berhasil disimpan',
                showConfirmButton: false,
                timer: 1500,
              });
              this.closeForm();
            }
          });
      } else if (payment === 1) {
        axios
          .post(
            Constant.apiUrl.concat(`/billing/insurance-limit/${id_billing}`),
            {
              amount: this.limit,
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
            },
          )
          .then(response => {
            if (response.status === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Hak Kelas/Limit Asuransi berhasil disimpan',
                showConfirmButton: false,
                timer: 1500,
              });
              this.closeForm();
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row-layout {
  width: 1250px;
}

.card-element {
  background: #f2f2f2;
  color: #828282;
  &.active {
    background-image: linear-gradient(#ddb2ff, #a199ff);
    color: white;
  }
}
</style>
