<template>
  <v-container class="pt-5 px-14" fluid>
    <h4>CAP KAKI BAYI</h4>
    <v-row>
      <v-col>
        <label>
          Kaki Kanan
        </label>
        <skeleton :isLoading="isLoading">
          <stamp-picture-upload
            :isEMR="isEMR"
            @view-image="previewImage"
            type="baby"
            stamp="right_leg"
            ref="right_leg"
            :identifier="`${idInpatient}babyrightleg`"
          />
        </skeleton>
      </v-col>
      <v-col>
        <label>
          Kaki Kiri
        </label>
        <skeleton :isLoading="isLoading">
          <stamp-picture-upload
            :isEMR="isEMR"
            @view-image="previewImage"
            type="baby"
            stamp="left_leg"
            ref="left_leg"
            :identifier="`${idInpatient}babyleftleg`"
        /></skeleton>
      </v-col>
    </v-row>
    <v-divider class="my-15" />
    <h4>
      CAP IBU JARI IBU
    </h4>
    <v-row class="mb-5">
      <v-col>
        <label>
          Ibu Jari Kanan
        </label>
        <skeleton :isLoading="isLoading">
          <stamp-picture-upload
            :isEMR="isEMR"
            @view-image="previewImage"
            type="mother"
            stamp="right_thumb"
            ref="right_thumb"
            :identifier="`${idInpatient}motherrightthumb`"
        /></skeleton>
      </v-col>
      <v-col>
        <label>
          Ibu Jari Kiri
        </label>
        <skeleton :isLoading="isLoading">
          <stamp-picture-upload
            :isEMR="isEMR"
            @view-image="previewImage"
            type="mother"
            stamp="left_thumb"
            ref="left_thumb"
            :identifier="`${idInpatient}motherleftthumb`"
        /></skeleton>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      overlay-opacity="0.8"
      content-class="elevation-0"
    >
      <div>
        <img :src="url" alt="image" class="image" />
        <v-btn class="close-btn" text fab @click="closeDialog"
          ><v-icon color="white">mdi-close</v-icon></v-btn
        >
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import StampPictureUpload from './StampPictureUpload.vue';
import Skeleton from '../../../../../SharedComponent/Skeleton.vue';

const { mapGetters } = createNamespacedHelpers('NeonatusAssessment');

export default {
  components: { StampPictureUpload, Skeleton },
  name: 'MotherAndBabySign',
  props: {
    isEMR: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      url: '',
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(['getId']),
    idInpatient: {
      get() {
        return this.getId.inpatient;
      },
    },
  },
  methods: {
    previewImage(url) {
      this.dialog = true;
      this.url = url;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.close-btn {
  position: absolute;
  top: 2vh;
  right: 2vw;
}

.image {
  max-width: 80vw; /* Scale the image to fit within the parent container's width */
  max-height: 80vh; /* Scale the image to fit within the parent container's height */
  width: auto; /* Reset the width to its natural size */
  height: auto;
  margin: auto;
}

.col {
  text-align: start;
  padding: 0 0.75vw;
}

h4 {
  font-weight: 400;
  color: #137bc0;
  text-align: start;
  margin-bottom: 1.5vw;
}

label {
  font-weight: 500;
  font-size: 0.8vw;
}
</style>
