<template>
  <v-row no-gutters class="d-flex flex-column">
    <div class="income-style">
      <label class="font-weight-bold text-left">PENDAPATAN</label>
      <label class="font-weight-bold text-right">{{
        currMonth ? `${currYear} ${currMonth}` : currentMonth
      }}</label>
      <template v-for="(income, idx) in data.income">
        <label class="text-left px-3" :key="'accNameIncome' + idx">{{
          income.name
        }}</label>
        <label class="text-right" :key="'balanceIncome' + idx">{{
          currency(income.balance)
        }}</label>
      </template>
    </div>
    <div class="subtotal-style px-3">
      <label class="font-weight-bold text-left">JUMLAH PENDAPATAN</label>
      <label class="font-weight-bold text-right">{{
        currency(data.income_total)
      }}</label>
    </div>

    <div class="income-style">
      <label class="font-weight-bold text-left">BEBAN POKOK PENDAPATAN</label>
      <label class="font-weight-bold text-right"></label>
      <template v-for="(cor, idx) in data.cor">
        <label class="text-left px-3" :key="'cor' + idx">{{ cor.name }}</label>
        <label class="text-right" :key="'cor' + idx">{{
          currency(cor.balance)
        }}</label>
      </template>
    </div>
    <div class="subtotal-style px-3">
      <label class="font-weight-bold text-left"
        >JUMLAH BEBAN POKOK PENDAPATAN</label
      >
      <label class="font-weight-bold text-right">{{
        currency(data.cor_total)
      }}</label>
    </div>

    <div class="total-style px-3">
      <label class="font-weight-bold text-left">LABA KOTOR</label>
      <label class="font-weight-bold text-right">{{
        currency(data.gross_profit)
      }}</label>
    </div>

    <div class="income-style">
      <label class="font-weight-bold text-left">BEBAN USAHA</label>
      <label class="font-weight-bold text-right"></label>
      <template v-for="(opExpense, idx) in data.operating_expenses">
        <label class="text-left px-3" :key="'opExpense' + idx">{{
          opExpense.name
        }}</label>
        <label class="text-right" :key="'opexBalance' + idx">{{
          currency(opExpense.balance)
        }}</label>
      </template>
    </div>
    <div class="subtotal-style px-3">
      <label class="font-weight-bold text-left">JUMLAH BEBAN USAHA</label>
      <label class="font-weight-bold text-right">{{
        currency(data.opexpense_total)
      }}</label>
    </div>

    <div class="total-style px-3">
      <label class="font-weight-bold text-left">LABA USAHA</label>
      <label class="font-weight-bold text-right">{{
        currency(data.profit)
      }}</label>
    </div>

    <div class="income-style">
      <template v-for="(otherIncome, idx) in data.other_income">
        <label class="text-left px-3" :key="'otherIncome' + idx">{{
          otherIncome.name
        }}</label>
        <label class="text-right" :key="'otherBalance' + idx">{{
          currency(otherIncome.balance)
        }}</label>
      </template>
      <!-- <label class="text-left px-3">Pendapatan Lain-lain</label>
      <label class="text-right">{{
        currency(standardData.other_income_total)
      }}</label> -->
      <template v-for="(otherExpense, idx) in data.other_expense">
        <label class="text-left px-3" :key="'otherExpense' + idx">{{
          otherExpense.name
        }}</label>
        <label class="text-right" :key="'otexBalance' + idx">{{
          currency(otherExpense.balance)
        }}</label>
      </template>
      <!-- <label class="text-left px-3">Beban Lain-lain</label>
      <label class="text-right">{{ currency(10000000) }}</label> -->
    </div>

    <div class="total-style px-3">
      <label class="font-weight-bold text-left">LABA SEBELUM PAJAK</label>
      <label class="font-weight-bold text-right">{{
        currency(data.earnings_before_tax)
      }}</label>
    </div>

    <div class="income-style">
      <template v-for="(incomeTax, idx) in data.income_tax">
        <label class="text-left px-3" :key="'incomeTax' + idx">{{
          incomeTax.name
        }}</label>
        <label class="text-right" :key="'taxBalance' + idx">{{
          currency(incomeTax.balance)
        }}</label>
      </template>
    </div>

    <div class="total-style px-3">
      <label class="font-weight-bold text-left">LABA BERSIH</label>
      <label class="font-weight-bold text-right">{{
        currency(data.earnings_after_tax)
      }}</label>
    </div>
  </v-row>
</template>

<script>
import moment from 'moment-timezone';
import formatMixin from '@/mixin/formatMixin';

export default {
  name: 'Standard',
  mixins: [formatMixin],
  props: {
    currMonth: {
      type: String,
      default: null,
    },
    currYear: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  watch: {
    data(val) {
      this.standardData = val;
    },
  },
  data() {
    return {
      currentMonth: moment(new Date()).format('YYYY MMMM'),
      standardData: null,
    };
  },
};
</script>

<style>
.income-style {
  display: grid;
  grid-template-columns: 80fr 20fr;
}
.subtotal-style {
  background-color: #deeef9;
  height: 5vh;
  display: grid;
  grid-template-columns: 80fr 20fr;
  align-content: center;
  margin: 5px 0;
}

.total-style {
  background-color: #addaf9;
  height: 5vh;
  display: grid;
  grid-template-columns: 80fr 20fr;
  align-content: center;
  margin: 5px 0;
}
</style>
