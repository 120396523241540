import Constant from '@/const';
const axios = require('axios');

const getServedCount = async params => {
  const response = await axios.get(
    Constant.apiUrl.concat('/patient/registration/count'),
    { params },
  );

  return response;
};

export default getServedCount;
