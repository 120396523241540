import Constant from '@/const';
import axios from 'axios';

const getAllInformConsent = async idEMR => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/document/inform-consent/all/${idEMR}`),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getAllInformConsent;
