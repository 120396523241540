export default {
  methods: {
    mapPayloadAssessmentGeneral(source) {
      return {
        ...source,
        objective: {
          ...source.objective,
          heart_rate: source.objective.pulse,
          awarness:
            source.objective.awareness.code || source.objective.awareness,
        },
        assesment: {
          physical: source.physical,
          return_status:
            source.plan.return_status?.code || source.plan.return_status,
          other_inspection: source.plan.other_inspection,
          diagnoses: source.plan.diagnoses.map(item => {
            return {
              text: item.diagnose,
              id_diagnose: item?.icd10?.id,
              code: item?.icd10?.code,
              description: item?.icd10?.text,
            };
          }),
          psychological_status: source.subjective.psychological_status,
        },
        planning: {
          general_theraphy: source.plan.therapies.map(item => {
            return {
              text: item.therapy,
              icd9: {
                code: item?.icd9?.code,
                id: item?.icd9?.id,
                description: item?.icd9?.text,
              },
            };
          }),
          interventions: source.plan.intervention.map(item => {
            return {
              text: item.intervention,
              icd9:
                Array.isArray(item?.icd9) && item?.icd9.includes(null)
                  ? [null]
                  : item?.icd9.map(val => {
                      if (val?.id === undefined) {
                        return '';
                      } else {
                        return {
                          id: val?.id,
                          code: val?.code,
                          description: val?.text,
                        };
                      }
                    }),
            };
          }),
        },
        other: source.support,
      };
    },
  },
};
