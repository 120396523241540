<template>
  <div>
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Laporan Mortalitas Jumlah Kematian Neonatus
            </v-toolbar-title>
          </v-col>
          <v-spacer></v-spacer>
          <router-link to="/reporting/laporan-mortalitas">
            <v-btn color="grey" icon text>
              <v-icon>mdi-home </v-icon>
            </v-btn>
          </router-link>
          <v-btn color="grey" icon text @click="getMortality(type)">
            <v-icon>mdi-autorenew </v-icon>
          </v-btn>
          <v-col cols="2">
            <v-autocomplete
              placeholder="Pilih Tahun"
              prepend-inner-icon="mdi-calendar-month"
              :items="yearRange"
              outlined
              dense
              hide-details="false"
              v-model="thisYear"
              @change="getMortality(type)"
            />
          </v-col>
          <v-btn depressed color="primary" class="mx-0 text-none" tile disabled>
            Export
          </v-btn>
        </v-row>
      </v-container>
      <template v-slot:extension>
        <v-tabs v-model="tabs" centered slider-color="primary">
          <v-tab
            class="text-capitalize font-weight-bold"
            @click="
              type = 'oneYear';
              getMortality(type);
            "
          >
            Angka Kematian Dalam 1 Tahun
          </v-tab>
          <v-tab
            class="text-capitalize font-weight-bold"
            @click="
              type = 'fiveYears';
              getMortality(type);
            "
          >
            Angka Kematian 5 Tahun Terakhir
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-main>
      <v-container>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-row>
              <v-col cols="7">
                <v-card
                  color="#F0F9FF"
                  height="384"
                  flat
                  rounded="xl"
                  class="pa-3"
                >
                  <v-flex class="text-left px-3">
                    <h4>
                      Laporan Mortalitas Angka Kematian Neonatus
                    </h4>
                    <label class="font-weight-medium caption grey--text">
                      Rata-rata angka laporan mortalitas angka kematian Neonatus
                      di tahun {{ thisYear }}
                    </label>
                  </v-flex>
                  <!-- <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                    v-if="isLoading"
                  ></v-progress-circular> -->
                  <apexchart
                    ref="chartOneYear"
                    type="bar"
                    height="320"
                    :options="pastOneYear.chartOptions"
                    :series="pastOneYear.series"
                  ></apexchart>
                </v-card>
              </v-col>
              <v-col>
                <v-card color="#F0F9FF" flat rounded="lg" class="py-3">
                  <h4 class="text-left px-5 pb-2">
                    Penyebab Kematian Neonatus
                  </h4>
                  <template v-for="(death, index) in pastOneYear.deathCause">
                    <v-divider :key="'divider-' + index"></v-divider>
                    <v-card-text
                      class="d-flex justify-space-between"
                      :key="'deathCause-' + index"
                    >
                      <span>{{ index + 1 }}</span>
                      <span class="text-left">{{ death.name }}</span>
                      <span class="">{{ death.count }} Pasien</span>
                    </v-card-text>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row>
              <v-col cols="7">
                <v-card
                  color="#F0F9FF"
                  height="384"
                  flat
                  rounded="xl"
                  class="pa-3"
                >
                  <v-flex class="text-left px-3">
                    <h4>
                      Laporan Mortalitas Jumlah Kematian Neonatus
                    </h4>
                    <label class="font-weight-medium caption grey--text">
                      Rata-rata angka laporan mortalitas angka kematian Neonatus
                      dalam 5 tahun terakhir
                    </label>
                  </v-flex>
                  <!-- <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                    v-if="isLoading"
                  ></v-progress-circular> -->
                  <apexchart
                    ref="chartFiveYear"
                    type="line"
                    height="320"
                    :options="pastFiveYear.chartOptions"
                    :series="pastFiveYear.series"
                  ></apexchart>
                </v-card>
              </v-col>
              <v-col>
                <v-card color="#F0F9FF" flat rounded="lg" class="py-3">
                  <h4 class="text-left px-5 pb-2">
                    Penyebab Kematian Neonatus
                  </h4>
                  <template v-for="(death, index) in pastFiveYear.deathCause">
                    <v-divider :key="'divider-' + index"></v-divider>
                    <v-card-text
                      class="d-flex justify-space-between"
                      :key="'deathCause-' + index"
                    >
                      <span>{{ index + 1 }}</span>
                      <span class="text-left">{{ death.name }}</span>
                      <span class="">{{ death.count }} Pasien</span>
                    </v-card-text>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
// const _ = require('lodash');

export default {
  name: 'NeonatusMortalityReport',
  mixins: [jwtMixin],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      date: null,
      menu: false,
      tabs: null,
      type: 'oneYear',
      thisYear: new Date().getFullYear(),
      pastFiveYear: {
        series: [
          {
            name: 'Pasien',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          xaxis: {
            categories: [],
          },
          colors: ['#F2994A'],
          legend: {
            show: false,
          },
        },
        deathCause: [],
      },
      pastOneYear: {
        series: [
          {
            name: 'Pasien',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'bar',
            id: 'chartOneYear',
            zoom: {
              enabled: false,
            },
          },
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'Mei',
              'Jun',
              'Jul',
              'Ags',
              'Sep',
              'Okt',
              'Nov',
              'Des',
            ],
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#F2994A'],
          stroke: {
            curve: 'smooth',
            width: 2,
          },
        },
        deathCause: [],
      },
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    yearRange() {
      let startYear = 1980;
      const currentYear = new Date().getFullYear();
      const years = [];
      while (startYear <= currentYear) {
        years.push(startYear++);
      }
      return years;
    },
  },
  methods: {
    async getMortality(type) {
      if (type === 'oneYear') {
        this.isLoading = true;
        await this.runApiCalls(() => {
          axios
            .get(
              Constant.apiUrl.concat(
                `/report/mortalitas?year=${this.thisYear}&isAnnual=1&category=&isNeonatus=1`,
              ),
            )
            .then(response => {
              const mortalGraph = response.data.data.graph;
              this.pastOneYear.deathCause = response.data.data.deathCause;

              this.$refs.chartOneYear.updateSeries(
                [
                  {
                    name: 'Pasien',
                    data: mortalGraph,
                  },
                ],
                false,
                true,
              );

              this.isLoading = false;
            })
            .catch(() => {
              this.pastOneYear.series[0].data = [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
              ];
              this.isLoading = false;
            });
        });
      } else {
        const today = new Date();
        const last_year = today.getFullYear();
        const first_year = last_year - 4;
        this.isLoading = true;
        for (let i = first_year; i <= last_year; i++) {
          this.pastFiveYear.chartOptions.xaxis.categories.push(i);
        }

        this.runApiCalls(() => {
          axios
            .get(
              Constant.apiUrl.concat(
                `/report/mortalitas?year=${this.thisYear}&isAnnual=&category=&isNeonatus=1`,
              ),
            )
            .then(response => {
              const mortalGraph = response.data.data.graph;
              this.$refs.chartFiveYear.updateSeries(
                [
                  {
                    name: 'Pasien',
                    data: mortalGraph,
                  },
                ],
                false,
                true,
              );

              this.pastFiveYear.deathCause = response.data.data.deathCause;
              this.isLoading = false;
            })
            .catch(() => {
              this.pastFiveYear.series[0].data = [0, 0, 0, 0, 0];
              this.isLoading = false;
            });
        });
      }
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getMortality('oneYear');
      this.interval = setInterval(this.getMortality, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped></style>
