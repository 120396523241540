const state = {
  sistol: null,
  diastol: null,
  pulse: null,
  weight: null,
  height: null,
  belly_circumference: null,
  respiration_rate: null,
  saturation: null,
  temperature: null,
};

const getters = {
  // get sistol
  getSistol: state => state.sistol,
  // get diastol
  getDiastol: state => state.diastol,
  // get pulse
  getPulse: state => state.pulse,
  // get weight
  getWeight: state => state.weight,
  // get height
  getHeight: state => state.height,
  // get belly circumference
  getBellyCircumference: state => state.belly_circumference,
  // get respiration rate
  getRespirationRate: state => state.respiration_rate,
  // get saturation
  getSaturation: state => state.saturation,
  // get temperature
  getTemperature: state => state.temperature,
};

const mutations = {
  clearObjectiveState() {
    Object.assign(state, {
      sistol: null,
      diastol: null,
      pulse: null,
      weight: null,
      height: null,
      belly_circumference: null,
      respiration_rate: null,
      saturation: null,
      temperature: null,
    });
  },
  setSistol(state, payload) {
    state.sistol = payload;
  },
  setDiastol(state, payload) {
    state.diastol = payload;
  },
  setPulse(state, payload) {
    state.pulse = payload;
  },
  setWeight(state, payload) {
    state.weight = payload;
  },
  setHeight(state, payload) {
    state.height = payload;
  },
  setBellyCircumference(state, payload) {
    state.belly_circumference = payload;
  },
  setRespirationRate(state, payload) {
    state.respiration_rate = payload;
  },
  setSaturation(state, payload) {
    state.saturation = payload;
  },
  setTemperature(state, payload) {
    state.temperature = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
