<template>
  <v-container fluid class="pt-5 px-14">
    <v-skeleton-loader v-if="isLoading" type="table-tbody"></v-skeleton-loader>
    <div v-else class="table">
      <div class="cell">Kategori</div>
      <div class="cell">1'</div>
      <div class="cell">5'</div>
      <div class="cell">10'</div>
      <!-- first row -->
      <div class="cell">A = Appearance (Skin Color/Warna)</div>
      <div :class="`cell ${isMinute1Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'appearance'"
          :min="1"
          :items="resource.a1"
        />
      </div>
      <div :class="`cell ${isMinute5Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'appearance'"
          :min="5"
          :items="resource.a1"
          :disabled="isMinute5Disabled"
        />
      </div>
      <div :class="`cell ${isMinute10Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'appearance'"
          :min="10"
          :items="resource.a1"
          :disabled="isMinute10Disabled"
        />
      </div>
      <!-- second row -->
      <div class="cell">P = Pulse (Heart Rate/Denyut Jantung)</div>
      <div :class="`cell ${isMinute1Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'pulse'"
          :min="1"
          :items="resource.p"
        />
      </div>
      <div :class="`cell ${isMinute5Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'pulse'"
          :min="5"
          :items="resource.p"
          :disabled="isMinute5Disabled"
        />
      </div>
      <div :class="`cell ${isMinute10Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'pulse'"
          :min="10"
          :items="resource.p"
          :disabled="isMinute10Disabled"
        />
      </div>
      <!-- third row -->
      <div class="cell">
        G = Grimace (Reflex Irritability/Peka Rangsang)
      </div>
      <div :class="`cell ${isMinute1Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'grimace'"
          :min="1"
          :items="resource.g"
        />
      </div>
      <div :class="`cell ${isMinute5Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'grimace'"
          :min="5"
          :items="resource.g"
          :disabled="isMinute5Disabled"
        />
      </div>
      <div :class="`cell ${isMinute10Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'grimace'"
          :min="10"
          :items="resource.g"
          :disabled="isMinute10Disabled"
        />
      </div>
      <!-- fourth row -->
      <div class="cell">A = Activity (Tone/Tonus Otot)</div>
      <div :class="`cell ${isMinute1Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'activity'"
          :min="1"
          :items="resource.a2"
        />
      </div>
      <div :class="`cell ${isMinute5Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'activity'"
          :min="5"
          :items="resource.a2"
          :disabled="isMinute5Disabled"
        />
      </div>
      <div :class="`cell ${isMinute10Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'activity'"
          :min="10"
          :items="resource.a2"
          :disabled="isMinute10Disabled"
        />
      </div>
      <!-- fifth row -->
      <div class="cell">R = Respiration/Pernafasan</div>
      <div :class="`cell ${isMinute1Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'respiration'"
          :min="1"
          :items="resource.r"
        />
      </div>
      <div :class="`cell ${isMinute5Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'respiration'"
          :min="5"
          :items="resource.r"
          :disabled="isMinute5Disabled"
        />
      </div>
      <div :class="`cell ${isMinute10Disabled}`">
        <apgar-cell
          :isEMR="isEMR"
          :type="'respiration'"
          :min="10"
          :items="resource.r"
          :disabled="isMinute10Disabled"
        />
      </div>
      <!-- fifth row -->
      <div class="cell text-center">Hasil</div>
      <div :class="`cell ${isMinute1Disabled}`">
        <v-chip v-if="totalMinute1" :class="`chip ${totalMinute1?.color}`">
          {{ `${totalMinute1?.total} - ${totalMinute1?.text}` }}
        </v-chip>
      </div>
      <div :class="`cell ${isMinute5Disabled}`">
        <v-chip v-if="totalMinute5" :class="`chip ${totalMinute5?.color}`">
          {{ `${totalMinute5?.total} - ${totalMinute5?.text}` }}
        </v-chip>
      </div>
      <div :class="`cell ${isMinute10Disabled}`">
        <v-chip v-if="totalMinute10" :class="`chip ${totalMinute10?.color}`">
          {{ `${totalMinute10?.total} - ${totalMinute10?.text}` }}
        </v-chip>
      </div>
    </div>
  </v-container>
</template>

<script>
import ApgarCell from './ApgarCell.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('NeonatusAssessment');

export default {
  name: 'ApgarScore',
  components: { ApgarCell },
  props: {
    isEMR: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['getForm']),
    isMinute1Disabled: {
      get() {
        const namesArray = Object.values(this.getForm.apgar_score.minute1).map(
          item => item.name,
        );
        return namesArray.every(item => !item) && this.isEMR ? 'disabled' : '';
      },
    },
    isMinute5Disabled: {
      get() {
        const namesArrayMin1 = Object.values(
          this.getForm.apgar_score.minute1,
        ).map(item => item.name);
        const namesArrayMin5 = Object.values(
          this.getForm.apgar_score.minute5,
        ).map(item => item.name);

        if (this.isEMR) {
          return namesArrayMin5.every(item => !item) ? 'disabled' : '';
        } else {
          return !namesArrayMin1.every(item => item) ? 'disabled' : '';
        }
      },
    },
    isMinute10Disabled: {
      get() {
        const namesArrayMin5 = Object.values(
          this.getForm.apgar_score.minute5,
        ).map(item => item.name);
        const namesArrayMin10 = Object.values(
          this.getForm.apgar_score.minute10,
        ).map(item => item.name);

        if (this.isEMR) {
          return namesArrayMin10.every(item => !item) ? 'disabled' : '';
        } else {
          return !namesArrayMin5.every(item => item) ? 'disabled' : '';
        }
      },
    },
    totalMinute1: {
      get() {
        const scoresArray = Object.values(this.getForm.apgar_score.minute1).map(
          item => item.score,
        );
        if (scoresArray.some(value => value === null)) {
          return null;
        }
        const total = scoresArray.reduce((a, b) => a + b, 0);
        return {
          ...this.generateChip(total),
        };
      },
    },
    totalMinute5: {
      get() {
        const scoresArray = Object.values(this.getForm.apgar_score.minute5).map(
          item => item.score,
        );
        if (scoresArray.some(value => value === null)) {
          return null;
        }
        const total = scoresArray.reduce((a, b) => a + b, 0);
        return {
          ...this.generateChip(total),
        };
      },
    },
    totalMinute10: {
      get() {
        const scoresArray = Object.values(
          this.getForm.apgar_score.minute10,
        ).map(item => item.score);
        if (scoresArray.some(value => value === null)) {
          return null;
        }
        const total = scoresArray.reduce((a, b) => a + b, 0);
        return {
          ...this.generateChip(total),
        };
      },
    },
  },
  data() {
    return {
      menu: false,
      resource: {
        a1: [
          {
            text: 'Biru Putih',
            score: 0,
          },
          {
            text: 'Ujung-Ujung Putih',
            score: 1,
          },
          {
            text: 'Merah Jambu',
            score: 2,
          },
        ],
        p: [
          {
            text: '0',
            score: 0,
          },
          {
            text: '< 100',
            score: 1,
          },
          {
            text: '> 100',
            score: 2,
          },
        ],
        g: [
          {
            text: 'Tidak Ada',
            score: 0,
          },
          {
            text: 'Meringis',
            score: 1,
          },
          {
            text: 'Menangis',
            score: 2,
          },
        ],
        a2: [
          {
            text: 'Lemah',
            score: 0,
          },
          {
            text: 'Sedang',
            score: 1,
          },
          {
            text: 'Baik',
            score: 2,
          },
        ],
        r: [
          {
            text: 'Tidak Ada',
            score: 0,
          },
          {
            text: 'Tidak Teratur',
            score: 1,
          },
          {
            text: 'Baik',
            score: 2,
          },
        ],
      },
    };
  },
  methods: {
    generateChip(total) {
      if (total >= 0 && total <= 3) {
        return {
          color: 'red-chip',
          text: 'Asfiksi Berat',
          total,
        };
      }
      if (total >= 4 && total <= 6) {
        return {
          color: 'yellow-chip',
          text: 'Asfiksi Ringan Sedang',
          total,
        };
      }
      if (total >= 7 && total <= 9) {
        return {
          color: 'yellow-chip',
          text: 'Sedikit Asfiksi',
          total,
        };
      }
      if (total === 10) {
        return {
          color: 'green-chip',
          text: 'Bayi Normal',
          total,
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table {
  width: 100%;
  border: 1px solid #c2c2c2;
  border-radius: 7px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: start;

  .cell {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    padding: 0.6vw;
    font-size: 0.8vw;
    font-weight: 500;
    cursor: pointer;

    &.disabled {
      background-color: #f5f5f5;
      cursor: auto;
    }

    .chip {
      font-weight: 400;
      border-radius: 4px;

      &.red-chip {
        background-color: #fff2f2;
        border: 1px solid #eb4646;
        color: #eb4646;
      }
      &.yellow-chip {
        background-color: #fff9e5;
        border: 1px solid #e5a82e;
        color: #e5a82e;
      }
      &.green-chip {
        background-color: #e5fff0;
        border: 1px solid #2d965a;
        color: #2d965a;
      }
    }
  }

  .cell:nth-child(4n) {
    border-right: none;
  }

  .cell:nth-last-child(-n + 4) {
    border-bottom: none;
    cursor: auto;
  }

  .cell:nth-child(-n + 4) {
    color: #757575;
    background-color: #fafafa;
    cursor: auto;
  }

  .cell:nth-child(4n + 5) {
    cursor: auto;
  }

  .cell:nth-child(1) {
    border-top-left-radius: 7px;
  }
  .cell:nth-child(4) {
    border-top-right-radius: 7px;
  }
}
</style>
