<template>
  <v-form ref="form">
    <section>
      <h4>DATA SUBJEKTIF</h4>
      <v-row>
        <v-col cols="6">
          <label class="required">Anamnesis</label>
          <vue-editor
            :rules="rule"
            v-model="form.anamnesis"
            :editorToolbar="customToolbar"
            :disabled="!isEdit"
            :placeholder="isEdit ? 'Anamnesis' : ''"
            class="pt-4"
          ></vue-editor>
          <span
            v-if="error.anamnesis.state && isEdit"
            class="error--text caption"
            >{{ error.anamnesis.message }}</span
          >
        </v-col>
      </v-row>
    </section>
  </v-form>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers('CPPTStore');

export default {
  name: 'SubjectiveData',
  components: {
    VueEditor,
  },
  watch: {
    'form.anamnesis'(val) {
      const char = val.replace(/<[^>]*>/g, ''); // regex to remove html tag inside 'val'
      if (val || char.length <= 3000) {
        this.setErrorVueEditor({
          anamnesis: {
            state: false,
            message: '',
          },
        });
      }
      if (!val) {
        this.setErrorVueEditor({
          anamnesis: {
            state: true,
            message: 'Wajib diisi',
          },
        });
      }
      if (char.length > 3000) {
        this.setErrorVueEditor({
          anamnesis: {
            state: true,
            message: 'Maksimal 3000 karakter',
          },
        });
      }
    },
  },
  computed: {
    ...mapGetters(['getForm', 'getErrorVueEditor']),
    error: {
      get() {
        return this.getErrorVueEditor;
      },
      set(value) {
        this.setErrorVueEditor(value);
      },
    },
    form: {
      get() {
        return this.getForm.subjective;
      },
    },
    isEdit: {
      get() {
        return this.getForm.isDetail ? this.getForm.isEdit : true;
      },
    },
  },
  data() {
    return {
      rule: [v => !!v || 'Wajib diisi'],
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    };
  },
  methods: {
    ...mapMutations(['setErrorVueEditor']),
  },
};
</script>

<style lang="scss" scoped>
section {
  text-align: start;
  padding-top: 2vw;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 3vw;

  label {
    font-weight: 500;
    font-size: 0.8vw;
  }

  .quillWrapper {
    min-height: 10vh;
    max-width: 40vw;
  }

  ::v-deep .ql-editor {
    max-height: 1vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 9px;
      background-color: #ededed;
    }
    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 9px;
      background-color: #9e9e9e;
    }
  }
}

h4 {
  font-weight: 400;
  color: #137bc0;
  margin-bottom: 0.7vw;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
