<template>
  <v-row no-gutters class="d-flex flex-column">
    <div class="equity-style">
      <label class="font-weight-bold text-left">EKUITAS AWAL PERIODE</label>
      <label class="font-weight-bold text-right"></label>
      <template v-for="(init, idx) in data.initial_equity_data">
        <label class="text-left" :key="'init-' + idx">{{ init.name }}</label>
        <label class="text-right" :key="'initBalance-' + idx">{{
          currency(init.balance)
        }}</label>
      </template>
    </div>
    <div class="subtotal-style px-3">
      <label class="font-weight-bold text-left"
        >SALDO EKUITAS AWAL PERIODE</label
      >
      <label class="font-weight-bold text-right">{{
        currency(data.initial_equity ? data.initial_equity : 0)
      }}</label>
    </div>

    <div class="equity-style">
      <label class="font-weight-bold text-left">PENINGKATAN EKUITAS</label>
      <label class="font-weight-bold text-right"></label>
      <template v-for="(increase, idx) in data.equity_increase_data">
        <label class="text-left" :key="'increaseEquity-' + idx">{{
          increase.name
        }}</label>
        <label class="text-right" :key="'ineqBalance-' + idx">{{
          currency(increase.balance)
        }}</label>
      </template>
    </div>
    <div class="subtotal-style px-3">
      <label class="font-weight-bold text-left"
        >JUMLAH PENINGKATAN EKUITAS</label
      >
      <label class="font-weight-bold text-right">{{
        currency(data.equity_increase ? data.equity_increase : 0)
      }}</label>
    </div>

    <div class="equity-style">
      <label class="font-weight-bold text-left">PENURUNAN EKUITAS</label>
      <label class="font-weight-bold text-right"></label>
      <template v-for="(decrease, idx) in data.equity_decrease_data">
        <label class="text-left" :key="'decreaseEquity-' + idx">{{
          decrease.name
        }}</label>
        <label class="text-right" :key="'deceqBalance-' + idx">{{
          currency(decrease.balance)
        }}</label>
      </template>
    </div>
    <div class="subtotal-style px-3">
      <label class="font-weight-bold text-left">JUMLAH PENURUNAN EKUITAS</label>
      <label class="font-weight-bold text-right">{{
        currency(data.equity_decrease ? data.equity_decrease : 0)
      }}</label>
    </div>

    <div class="total-style px-3">
      <label class="font-weight-bold text-left"
        >SALDO EKUITAS AKHIR PERIODE</label
      >
      <label class="font-weight-bold text-right">{{
        currency(data.equity_final ? data.equity_final : 0)
      }}</label>
    </div>
  </v-row>
</template>

<script>
import formatMixin from '@/mixin/formatMixin';

export default {
  name: 'Standard',
  mixins: [formatMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
.equity-style {
  display: grid;
  grid-template-columns: 80fr 20fr;
}
.subtotal-style {
  background-color: #deeef9;
  height: 5vh;
  display: grid;
  grid-template-columns: 80fr 20fr;
  align-content: center;
  margin: 5px 0;
}

.total-style {
  background-color: #addaf9;
  height: 5vh;
  display: grid;
  grid-template-columns: 80fr 20fr;
  align-content: center;
  margin: 5px 0;
}
</style>
