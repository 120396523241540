import Constant from '@/const';
import axios from 'axios';

const getAllDoctorAndNurse = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/master/staff?role[]=Dokter&role[]=dokter&role[]=Perawat&role[]=perawat`,
      ),
    );
    return response.data;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getAllDoctorAndNurse;
