// api
import {
  getWaitingListPatient,
  getRegisteredInpatient,
} from '@/fetchApi/Inpatient/Registration';

// mixins
import mapInpatientDashboardData from '@/mixin/MapperMixins/Registration/InpatientRegistration/mapInpatientDashboardData';

const state = {
  table: {
    waiting: [],
    registered: [],
  },
  loading: {
    waiting: false,
    registered: false,
  },
  pagination: {
    page: 1,
    total: 0,
  },
};

const getters = {
  getTableData: state => state.table,
  getLoading: state => state.loading,
  getPagination: state => state.pagination,
};

const mutations = {
  clearState(state) {
    state.table = {
      waiting: [],
      registered: [],
    };
    state.loading = {
      waiting: false,
      registered: false,
    };
    state.pagination = {
      descending: false,
      page: 1,
      rowsPerPage: 5,
      sortBy: '',
      total: 0,
    };
  },
  setTableData(state, { key, payload }) {
    state.table[key] = payload;
  },
  setLoading(state, { key, payload }) {
    state.loading[key] = payload;
  },
  setTotalPage(state, payload) {
    state.pagination.total = payload;
  },
  setPage(state, payload) {
    state.pagination.page = payload;
  },
};

const actions = {
  async resolveGetAllWaitingListPatient({ commit }, params) {
    try {
      commit('setLoading', { key: 'waiting', payload: true });
      const response = await getWaitingListPatient(params);
      const { length: totalPatient } = response.data;
      commit('setTableData', {
        key: 'waiting',
        payload: mapInpatientDashboardData.methods.mapWaitingListData(
          response.data.data,
        ),
      });

      return { totalPatient };
    } catch (error) {
      commit('setTableData', {
        key: 'waiting',
        payload: [],
      });
      return { totalPatient: 0 };
    } finally {
      commit('setLoading', { key: 'waiting', payload: false });
    }
  },
  async resolveGetAllRegisteredPatient({ commit }, params) {
    try {
      commit('setLoading', { key: 'registered', payload: true });
      const response = await getRegisteredInpatient(params);
      const { length: totalPatient } = response.data;
      commit('setTableData', {
        key: 'registered',
        payload: mapInpatientDashboardData.methods.mapRegisteredData(
          response.data.data,
        ),
      });
      return { totalPatient };
    } catch (error) {
      commit('setTableData', {
        key: 'registered',
        payload: [],
      });
      return { totalPatient: 0 };
    } finally {
      commit('setLoading', { key: 'registered', payload: false });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
