<template>
  <v-card class="px-5 main-card">
    <v-card-title class="mb-3" primary-title>
      Input Pendapatan Kapitasi BPJS
    </v-card-title>
    <v-container class="px-5 text-start">
      <v-form ref="form">
        <label for="month" class="required">Pilih Bulan</label>
        <v-menu offset-y :close-on-content-click="false" v-model="monthPicker">
          <template v-slot:activator="{ on }">
            <v-text-field
              :error-messages="dateErrorMessage"
              readonly
              v-model="dateText"
              v-on="on"
              placeholder="Pilih Bulan"
              outlined
              dense
              single-line
              required
              :rules="rule"
            ></v-text-field>
          </template>
          <v-date-picker
            type="month"
            :max="lastMonth"
            v-model="selectedMonth"
            @input="changeDate"
          ></v-date-picker>
        </v-menu>
        <label
          for="total-patient"
          class="
        required"
          >Total Pasien BPJS</label
        >
        <v-text-field
          oninput="if(this.value < 0) this.value = 0;"
          v-model.number="input.total_patient"
          required
          suffix="Pasien"
          type="number"
          name="total-patient"
          outlined
          dense
          single-line
          placeholder="Total Pasien BPJS"
          :rules="rule"
        ></v-text-field>
        <label for="amount" class="required">Jumlah Pendapatan</label>
        <v-currency-field
          v-model="input.total_income"
          required
          prefix="Rp."
          name="amount"
          type="number"
          outlined
          dense
          single-line
          placeholder="Jumlah Pendapatan"
          :rules="rule"
        ></v-currency-field>
      </v-form>
    </v-container>
    <v-footer color="white" class="footer-action d-flex justify-space-around">
      <v-btn color="red" outlined class="text-capitalze" @click="closeDialog()"
        >Batal</v-btn
      >
      <v-btn
        color="primary"
        @click="dataValidation()"
        dark
        depressed
        class="text-capitalze"
        >{{ isEdit ? 'Ubah' : 'Simpan' }}</v-btn
      >
    </v-footer>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';
const moment = require('moment');

const axios = require('axios');
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
  name: 'InputBPJSCapitaion',
  mixins: [AlertMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      monthPicker: false,
      selectedMonth: '',
      dateErrorMessage: '',
      dateText: '',
      rule: [v => !!v || 'Belum diisi'],
      input: {
        date: '',
        total_patient: '',
        total_income: '',
      },
    };
  },
  computed: {
    lastMonth() {
      return moment()
        .subtract(1, 'months')
        .endOf('month')
        .format();
    },
  },
  mounted() {
    if (this.isEdit) {
      this.input = { ...this.data };
      this.dateText = moment(this.input.date, 'MM/YYYY')
        .locale('id')
        .format('MMMM, YYYY');
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
    changeDate() {
      this.dateText = moment(this.selectedMonth, 'YYYY-MM')
        .locale('id')
        .format('MMMM, YYYY');
      this.input.date = moment(this.selectedMonth, 'YYYY-MM').format('MM/YYYY');
      this.dateChecker(this.input.date);
      this.monthPicker = false;
    },
    dataValidation() {
      if (this.$refs.form.validate()) {
        if (this.isEdit) this.updateCapitationIncome();
        else this.addCapitationIncome();
      }
    },
    dateChecker() {
      if (this.isEdit && this.input.date == this.data.date) return;
      axios
        .post(Constant.apiUrl.concat(`/finance/captincome/check`), {
          date: this.input.date,
        })
        .then(() => {
          this.dateErrorMessage = '';
        })
        .catch(() => {
          this.dateErrorMessage = 'Bulan sudah diinput';
        });
    },
    addCapitationIncome() {
      axios
        .post(Constant.apiUrl.concat(`/finance/captincome`), this.input)
        .then(
          () =>
            Swal.fire(
              'Input Pendapatan Kapitasi BPJS Berhasil Disimpan',
              '',
              'success',
            ),
          this.closeDialog(),
        )
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    updateCapitationIncome() {
      const { id } = this.input;
      axios
        .put(Constant.apiUrl.concat(`/finance/captincome/${id}`), this.input)
        .then(response => {
          const { pesan } = response.data;
          Swal.fire({
            icon: 'success',
            title: pesan,
          });
          this.closeDialog();
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat mengubah data',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  max-height: 58vh;
  min-height: 43vh;
  position: relative;
  width: 100%;
}
.required:after {
  content: ' *';
  color: red;
}
</style>
