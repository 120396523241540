<template>
  <v-card flat>
    <v-container class="container-layout" fluid>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title class="title font-weight-bold primary--text">
          Data Subjektif
        </v-card-title>
        <v-row>
          <v-col cols="5">
            <v-row no-gutters align="baseline">
              <v-col cols="5">
                <v-row no-gutters justify="end" class="mb-7 mr-10">
                  <label for="Anamnesis" class="black--text font-weight-bold"
                    >Anamnesis</label
                  >
                </v-row>
              </v-col>
              <v-col cols="7">
                <template v-for="(anamnesis, index) in input.anamnesis">
                  <v-text-field
                    dense
                    :placeholder="'Anamnesis ' + (index + 1)"
                    :key="'anamnesis-' + index"
                    @keyup="addAnamnesis($event, index)"
                    v-model="input.anamnesis[index]"
                    :rules="index === 0 ? rules : []"
                    :id="'anamnesis-' + index"
                    :readonly="emrData !== null"
                  />
                </template>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row no-gutters align="baseline">
              <v-col cols="6">
                <v-row no-gutters justify="end" class="mb-7 mr-10">
                  <label
                    for="Riwayat Penyakit Sebelumnya"
                    class="black--text font-weight-bold"
                    >Riwayat Penyakit Sebelumnya</label
                  ></v-row
                >
              </v-col>
              <v-col cols="6">
                <template v-for="(disease, index) in input.priorDisease">
                  <v-text-field
                    dense
                    :placeholder="'Riwayat Penyakit ' + (index + 1)"
                    :key="'disease-' + index"
                    v-model="input.priorDisease[index]"
                    @keyup="addDisease($event, index)"
                    :readonly="emrData !== null"
                  />
                </template>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" offset="5" class="py-0">
            <v-row no-gutters align="baseline">
              <v-col cols="6">
                <v-row no-gutters justify="end" class="mb-7 mr-10"
                  ><label
                    for="Riwayat Alergi"
                    class="black--text font-weight-bold"
                    >Riwayat Alergi</label
                  ></v-row
                >
              </v-col>
              <v-col cols="6">
                <template v-for="(allergy, index) in input.allergy">
                  <v-text-field
                    :readonly="emrData !== null"
                    dense
                    :placeholder="'Riwayat Alergi ' + (index + 1)"
                    :key="'allergy-' + index"
                    v-model="input.allergy[index]"
                    @keyup="addAllergy($event, index)"
                  />
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text text-left">
          Data Objektif <br />
          TTV
        </v-card-title>
        <v-row>
          <v-col cols="6">
            <v-row no-gutters align="baseline" class="grid-ttv">
              <label
                for="Tekanan Darah"
                class="black--text font-weight-bold text-right mr-10"
                >Tekanan Darah</label
              >
              <div class="d-flex">
                <v-text-field
                  :readonly="emrData !== null"
                  dense
                  placeholder="Sistolik"
                  v-model="input.ttv.sistole"
                  :rules="rules"
                  id="sistole"
                />
                <span class="title mx-4 grey--text">/</span>
                <v-text-field
                  :readonly="emrData !== null"
                  dense
                  placeholder="Diastolik"
                  suffix="mmhg"
                  v-model="input.ttv.diastole"
                  :rules="rules"
                  id="diastole"
                />
              </div>

              <label
                for="Nadi"
                class="black--text font-weight-bold text-right mr-10"
                >Nadi</label
              >
              <v-text-field
                :readonly="emrData !== null"
                dense
                placeholder="Nadi"
                suffix="x/menit"
                style="width: 25vh;"
                v-model="input.ttv.pulse"
                :rules="rules"
                id="pulse"
              />

              <label
                for="Berat Badan"
                class="black--text font-weight-bold text-right mr-10"
                >Berat Badan</label
              >
              <v-text-field
                :readonly="emrData !== null"
                dense
                placeholder="Berat Badan"
                suffix="kg"
                style="width: 25vh;"
                v-model="input.ttv.weight"
                :rules="rules"
                id="weight"
              />

              <label
                for="Tinggi Badan"
                class="black--text font-weight-bold text-right mr-10"
                >Tinggi Badan</label
              >
              <v-text-field
                :readonly="emrData !== null"
                dense
                placeholder="Tinggi Badan"
                suffix="cm"
                style="width: 25vh;"
                v-model="input.ttv.height"
                :rules="rules"
                id="height"
              />
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row no-gutters align="baseline" class="grid-ttv">
              <label
                for="RR"
                class="black--text font-weight-bold text-right mr-10"
                >RR</label
              >
              <v-text-field
                :readonly="emrData !== null"
                dense
                placeholder="RR"
                suffix="x/menit"
                v-model="input.ttv.respiration"
                :rules="rules"
                id="respiration"
              />
              <label
                for="Saturasi 02"
                class="black--text font-weight-bold text-right mr-10"
                >Saturasi O<sub>2</sub></label
              >
              <v-text-field
                :readonly="emrData !== null"
                dense
                placeholder="Saturasi 02"
                suffix="%"
                v-model="input.ttv.saturation"
                :rules="rules"
                style="width: 25vh;"
                id="saturation"
              />
              <label
                for="Suhu"
                class="black--text font-weight-bold text-right mr-10"
                >Suhu</label
              >
              <v-text-field
                :readonly="emrData !== null"
                dense
                placeholder="Suhu Tubuh"
                suffix="°C"
                v-model="input.ttv.temperature"
                :rules="rules"
                style="width: 25vh;"
                id="temperature"
              />
              <label
                for="Kesadaran"
                class="black--text font-weight-bold text-right mr-10"
                >Kesadaran</label
              >
              <v-select
                :readonly="emrData !== null"
                dense
                placeholder="Pilih Kesadaran"
                :items="resource.awareness"
                v-model="input.ttv.awareness"
                :rules="rules"
                id="awareness"
                item-value="code"
                item-text="name"
              />
            </v-row>
          </v-col>
        </v-row>

        <!-- <v-card-title class="title font-weight-bold primary--text text-left">
          Pemeriksaan Fisik
        </v-card-title>
        <v-row>
          <v-col cols="6">
            <v-row align="baseline">
              <v-col cols="4" class="text-right pr-7">
                <label class="black--text font-weight-bold">Kepala</label>
              </v-col>
              <v-col>
                <v-text-field
                  placeholder="Contoh: Normal"
                  hide-details="false"
                  dense
                  v-model="input.physical.head"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-row align="baseline">
              <v-col cols="4" class="text-right pr-7">
                <label class="black--text font-weight-bold">Mata</label>
              </v-col>
              <v-col>
                <v-text-field
                  placeholder="Contoh: Normal"
                  dense
                  hide-details="false"
                  v-model="input.physical.eye"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-row align="baseline">
              <v-col cols="4" class="text-right pr-7">
                <label class="black--text font-weight-bold">Mulut</label>
              </v-col>
              <v-col>
                <v-text-field
                  placeholder="Contoh: Normal"
                  dense
                  hide-details="false"
                  v-model="input.physical.mouth"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-row align="baseline">
              <v-col cols="4" class="text-right pr-7">
                <label class="black--text font-weight-bold">Leher</label>
              </v-col>
              <v-col>
                <v-text-field
                  placeholder="Contoh: Normal"
                  dense
                  hide-details="false"
                  v-model="input.physical.neck"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-row align="baseline">
              <v-col cols="4" class="text-right pr-7">
                <label class="black--text font-weight-bold">Perut</label>
              </v-col>
              <v-col>
                <v-text-field
                  placeholder="Contoh: Normal"
                  dense
                  hide-details="false"
                  v-model="input.physical.stomach"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-row align="baseline">
              <v-col cols="4" class="text-right pr-7">
                <label class="black--text font-weight-bold">Alat Gerak</label>
              </v-col>
              <v-col>
                <v-text-field
                  placeholder="Contoh: Normal"
                  dense
                  hide-details="false"
                  v-model="input.physical.moving_part"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-row align="baseline">
              <v-col cols="4" class="text-right pr-7">
                <label class="black--text font-weight-bold">Genitalia</label>
              </v-col>
              <v-col>
                <v-text-field
                  placeholder="Contoh: Normal"
                  dense
                  hide-details="false"
                  v-model="input.physical.genitalia"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->

        <v-card-title class="title font-weight-bold primary--text text-left">
          Hasil Penunjang Lainnya
        </v-card-title>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col cols="4" class="text-right pr-7">
                <label for="Hasil EKG" class="black--text font-weight-bold"
                  >Hasil EKG</label
                >
              </v-col>
              <v-col>
                <v-textarea
                  :readonly="emrData !== null"
                  no-resize
                  outlined
                  dense
                  placeholder="Masukkan Hasil EKG"
                  v-model="input.ekg"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col cols="4" class="text-right pr-7">
                <label
                  for="Hasil Radiologi"
                  class="black--text font-weight-bold"
                  >Hasil Radiologi</label
                >
              </v-col>
              <v-col>
                <v-textarea
                  :readonly="emrData !== null"
                  no-resize
                  outlined
                  dense
                  placeholder="Masukkan Hasil Radiologi"
                  v-model="input.radiology"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col cols="4" class="text-right pr-7">
                <label
                  for="Hasil Laboratorium"
                  class="black--text font-weight-bold"
                  >Hasil Laboratorium</label
                >
              </v-col>
              <v-col>
                <v-textarea
                  :readonly="emrData !== null"
                  no-resize
                  outlined
                  dense
                  placeholder="Masukkan Hasil Laboratorium"
                  v-model="input.laboratorium"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col cols="4" class="text-right pr-7">
                <label for="Catatan Lain" class="black--text font-weight-bold"
                  >Catatan Lain</label
                >
              </v-col>
              <v-col>
                <v-textarea
                  :readonly="emrData !== null"
                  no-resize
                  outlined
                  dense
                  placeholder="Masukkan Catatan Lainnya"
                  v-model="input.othersNote"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text text-left">
          Assesmen
        </v-card-title>
        <v-row class="pb-5">
          <v-col cols="5">
            <v-row no-gutters align="baseline">
              <v-col cols="5"
                ><v-row no-gutters justify="end" class="mr-10"
                  ><label
                    for="Diagnosa Utama"
                    class="black--text font-weight-bold"
                    >Diagnosa Utama</label
                  ></v-row
                ></v-col
              >
              <v-col
                ><v-combobox
                  :readonly="emrData !== null"
                  dense
                  placeholder="Pilih Diagnosa Utama"
                  hide-details="auto"
                  :items="resource.diagnose"
                  item-text="text"
                  v-model="input.mainDiagnose.text"
                  :loading="diagnoseLoading"
                  :search-input.sync="searchDiagnose"
                  :rules="rules"
                  id="mainDiagnose"
                  @change="getDiagnose('')"
              /></v-col>
            </v-row>
          </v-col>
          <v-col cols="7">
            <v-row no-gutters align="baseline">
              <v-col cols="5"
                ><v-row no-gutters justify="end" class="mr-5"
                  ><label
                    for="Informasi Keterangan Kritis"
                    class="black--text font-weight-bold"
                    >Informasi Keterangan Klinis</label
                  ></v-row
                ></v-col
              >
              <v-col cols="5" class="pl-5"
                ><v-text-field
                  :readonly="emrData !== null"
                  dense
                  placeholder="Masukkan Informasi"
                  hide-details="false"
                  v-model="input.mainDiagnose.detail"
              /></v-col>
            </v-row>
          </v-col>
          <template v-for="(diagnose, index) in input.secondaryDiagnose">
            <v-col cols="5" :key="'diagnoseLabel-' + index">
              <v-row no-gutters align="baseline">
                <v-col cols="5" v-if="index === 0"
                  ><v-row no-gutters justify="end" class="mr-10"
                    ><label
                      for="Diagnosa Sekunder"
                      class="black--text font-weight-bold"
                      >Diagnosa Sekunder</label
                    ></v-row
                  ></v-col
                >
                <v-col cols="5" v-else></v-col>
                <v-col
                  ><v-combobox
                    :readonly="emrData !== null"
                    dense
                    placeholder="Pilih Diagnosa Sekunder"
                    hide-details="false"
                    :items="resource.diagnose"
                    item-text="text"
                    :loading="diagnoseLoading"
                    v-model="input.secondaryDiagnose[index].text"
                    @change="changeDiagnose($event)"
                    :search-input.sync="searchDiagnoseSecondary[index]"
                /></v-col>
                <v-col v-if="!isEmr" cols="1">
                  <v-btn icon @click="deleteItem(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="7" :key="'diagnoseInfo-' + index">
              <v-row no-gutters align="baseline">
                <v-col cols="5"
                  ><v-row no-gutters justify="end" class="mr-5"
                    ><label
                      for="Informasi Keterangan Kritis"
                      class="black--text font-weight-bold"
                      >Informasi Keterangan Klinis</label
                    ></v-row
                  ></v-col
                >
                <v-col cols="5" class="pl-5"
                  ><v-text-field
                    :readonly="emrData !== null"
                    dense
                    placeholder="Masukkan Informasi"
                    hide-details="false"
                    v-model="input.secondaryDiagnose[index].detail"
                /></v-col>
              </v-row>
            </v-col>
          </template>

          <v-col cols="5">
            <v-row no-gutters align="baseline">
              <template v-for="(cause, index) in input.deathCause">
                <v-col cols="5" :key="'causeLabel-' + index" v-if="index === 0"
                  ><v-row no-gutters justify="end" class="mr-10"
                    ><label
                      for="Penyebab Kematian"
                      class="black--text font-weight-bold"
                      >Penyebab Kematian</label
                    ></v-row
                  ></v-col
                >
                <v-col v-else cols="5" :key="'causeLabel-' + index"></v-col>
                <v-col cols="6" :key="'causeInput-' + index"
                  ><v-autocomplete
                    :readonly="emrData !== null"
                    dense
                    placeholder="Pilih Penyebab Kematian"
                    :items="resource.diagnose"
                    item-text="text"
                    :loading="diagnoseLoading"
                    v-model="input.deathCause[index]"
                    @change="addDeathCause()"
                    clearable
                    :search-input.sync="searchDeathCause[index]"
                /></v-col>
                <v-col cols="1" :key="'causeDelete-' + index">
                  <v-btn icon @click="deleteItemCause(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text">
          Planning
        </v-card-title>
        <v-row>
          <v-col cols="5">
            <v-row no-gutters align="baseline">
              <v-col cols="5">
                <v-row no-gutters justify="end" class="mb-7 mr-10">
                  <label for="Tindakan" class="black--text font-weight-bold"
                    >Tindakan</label
                  >
                </v-row>
              </v-col>
              <v-col cols="6">
                <template v-for="(action, index) in input.actions">
                  <v-text-field
                    :readonly="emrData !== null"
                    dense
                    :placeholder="'Tindakan ' + (index + 1)"
                    :key="'action-' + index"
                    v-model="input.actions[index]"
                    @keyup="addActions($event, index)"
                    :rules="index === 0 ? rules : []"
                    :id="'action-' + index"
                  />
                </template>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="7">
            <v-row no-gutters align="baseline">
              <v-col cols="5">
                <v-row no-gutters justify="end" class="mr-5">
                  <label for="Terapi" class="black--text font-weight-bold"
                    >Terapi</label
                  ></v-row
                >
              </v-col>
              <v-col cols="5" class="pl-5">
                <template v-for="(therapy, index) in input.therapies">
                  <v-text-field
                    :readonly="emrData !== null"
                    dense
                    :placeholder="'Terapi ' + (index + 1)"
                    :key="'therapy' + index"
                    v-model="input.therapies[index]"
                    @keyup="addTherapies($event, index)"
                  />
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-card-actions class="d-flex justify-end">
      <v-btn
        width="108"
        height="36"
        class="text-none"
        text
        @click="$emit('close-form')"
      >
        Kembali
      </v-btn>
      <v-btn
        @click="saveData()"
        width="108"
        height="36"
        color="primary"
        depressed
        tile
        class="text-none"
        :disabled="!valid"
        v-if="!isEmr"
      >
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import AlertMixin from '@/mixin/alertMixin';
import jwtMixin from '@/mixin/jwtMixin';
const { scrollToEmptyInput } = require('@/helper');

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'InputAssessment',
  mixins: [AlertMixin, jwtMixin],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    isEmr: {
      type: Boolean,
    },
    emrData: {
      type: Object,
      default: null,
    },
    tab: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      input: {
        anamnesis: [''],
        priorDisease: [''],
        allergy: [''],
        ttv: {
          sistole: '',
          diastole: null,
          pulse: null,
          weight: null,
          height: null,
          respiration: null,
          saturation: null,
          temperature: null,
          awareness: '',
        },
        ekg: '',
        radiology: '',
        laboratorium: '',
        othersNote: '',
        mainDiagnose: {
          text: '',
          detail: '',
        },
        secondaryDiagnose: [
          {
            text: '',
            detail: '',
          },
        ],
        isChronic: null,
        deathCause: [''],
        returnStatus: '',
        actions: [''],
        therapies: [''],
        physical: {
          head: '',
          eye: '',
          mouth: '',
          neck: '',
          stomach: '',
          genitalia: '',
          moving_part: '',
        },
      },
      resource: {
        awareness: [],
        return_status: [
          { text: 'Berobat Jalan', value: '3' },
          { text: 'Rujuk Lanjut', value: '4' },
          { text: 'Rujuk Internal', value: '5' },
        ],
        diagnose: [],
      },
      diagnoseLoading: false,
      searchDiagnose: '',
      searchDiagnoseSecondary: [],
      searchDeathCause: [],
      valid: true,
      rules: [v => !!v || 'Wajib diisi'],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  watch: {
    searchDiagnose(val) {
      if (val) {
        this.getDiagnose(val);
      }
      this.getDiagnose('');
    },
    searchDiagnoseSecondary(val) {
      if (val) {
        const oldValues = this.input.secondaryDiagnose.map(diagnosa => {
          return diagnosa.text.text;
        });
        const index = val.findIndex((v, i) => {
          return v !== oldValues[i];
        });
        this.diagnoseLoading = true;
        this.getDiagnose(val[index]);
      }
    },
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    addAnamnesis(event, index) {
      if (event.keyCode === 9) return;
      if (
        (this.input.anamnesis[index] !== '') &
        (this.input.anamnesis.length - 1 === index)
      ) {
        this.input.anamnesis.push('');
      } else if (
        this.input.anamnesis[index] === '' &&
        this.input.anamnesis.length !== 1
      ) {
        this.input.anamnesis.splice(index, 1);
      }
    },
    addDisease(event, index) {
      if (event.keyCode === 9) return;
      if (
        (this.input.priorDisease[index] !== '') &
        (this.input.priorDisease.length - 1 === index)
      ) {
        this.input.priorDisease.push('');
      } else if (
        this.input.priorDisease[index] === '' &&
        this.input.priorDisease.length !== 1
      ) {
        this.input.priorDisease.splice(index, 1);
      }
    },
    addAllergy(event, index) {
      if (event.keyCode === 9) return;
      if (
        (this.input.allergy[index] !== '') &
        (this.input.allergy.length - 1 === index)
      ) {
        this.input.allergy.push('');
      } else if (
        this.input.allergy[index] === '' &&
        this.input.allergy.length !== 1
      ) {
        this.input.allergy.splice(index, 1);
      }
    },
    addActions(event, index) {
      if (event.keyCode === 9) return;
      if (
        (this.input.actions[index] !== '') &
        (this.input.actions.length - 1 === index)
      ) {
        this.input.actions.push('');
      } else if (
        this.input.actions[index] === '' &&
        this.input.actions.length !== 1
      ) {
        this.input.actions.splice(index, 1);
      }
    },
    addTherapies(event, index) {
      if (event.keyCode === 9) return;
      if (
        (this.input.therapies[index] !== '') &
        (this.input.therapies.length - 1 === index)
      ) {
        this.input.therapies.push('');
      } else if (
        this.input.therapies[index] === '' &&
        this.input.therapies.length !== 1
      ) {
        this.input.therapies.splice(index, 1);
      }
    },
    getDiagnose(keyword) {
      this.diagnoseLoading = true;
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/master/diagnose?page=1&itemCount=100&search=${
                keyword === null ? '' : keyword
              }&sort=-code`,
            ),
          )
          .then(response => {
            let allDiagnose = response.data.data;
            this.resource.diagnose = allDiagnose.map(diagnose => {
              return {
                id: diagnose._id.toString(),
                code: diagnose.code,
                text: diagnose.description,
              };
            });
            this.diagnoseLoading = false;
          })
          .catch(() => {
            this.diagnoseLoading = false;
          });
      });
    },
    addDiagnose() {
      this.input.secondaryDiagnose.push({
        text: '',
        detail: '',
      });
    },
    changeDiagnose(event) {
      this.$nextTick(() => {
        if (event === '') return;
        this.addDiagnose();
      });
    },
    addDeathCause() {
      this.input.deathCause.push('');
    },
    deleteItem(index) {
      if (index !== 0) {
        this.input.secondaryDiagnose.splice(index, 1);
      } else {
        this.input.secondaryDiagnose = [
          {
            text: '',
            detail: '',
          },
        ];
      }
    },
    deleteItemCause(index) {
      if (index !== 0) {
        this.input.deathCause.splice(index, 1);
      } else {
        this.input.deathCause = [
          {
            text: '',
            detail: '',
          },
        ];
      }
    },
    getAwarness() {
      axios.get(Constant.apiUrl.concat('/master/awarness')).then(response => {
        const data = response.data.data;
        this.resource.awareness = data.map(v => {
          return {
            code: v.code,
            name: v.description,
          };
        });
      });
    },
    saveData() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        scrollToEmptyInput();
      } else {
        const payload = {
          allergy: this.input.allergy.filter(x => x !== ''),
          checkup: {
            subjective: {
              anamnesis: this.input.anamnesis.filter(x => x !== ''),
              disease_history: this.input.priorDisease.filter(x => x !== ''),
            },
            objective: {
              heart_rate: parseInt(this.input.ttv.pulse),
              sistol: parseInt(this.input.ttv.sistole),
              diastol: parseInt(this.input.ttv.diastole),
              respiration_rate: parseInt(this.input.ttv.respiration),
              temperature: parseInt(this.input.ttv.temperature),
              saturation: parseInt(this.input.ttv.saturation),
              awarness: this.input.ttv.awareness,
              weight: parseInt(this.input.ttv.weight),
              height: parseInt(this.input.ttv.height),
            },
            assesment: {
              main_diagnose: this.input.mainDiagnose,
              other_diagnose: this.input.secondaryDiagnose,
              death_cause: this.input.deathCause,
              isChronic: this.input.isChronic,
              return_status: this.input.returnStatus,
              physical: this.input.physical,
            },
            planning: {
              treatment: this.input.actions.filter(x => x !== ''),
              therapy: this.input.therapies.filter(x => x !== ''),
              procedure: [],
            },
            other: {
              ekg: this.input.ekg,
              laboratory: this.input.laboratorium,
              radiology: this.input.radiology,
              ctg: '',
              other: this.input.othersNote,
            },
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          },
        };

        axios
          .post(
            Constant.apiUrl.concat(
              `/inpatient/checkup/${this.patientData.id_inpatient}`,
            ),
            payload,
          )
          .then(() => {
            Swal.fire('Data Asesmen Berhasil Disimpan!', '', 'success');
            this.$emit('close-form');
          })
          .catch(err => {
            this.showErrorAxios(err);
          });
      }
    },
    getDataAssessment() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/patient/checkup/regist/${this.patientData.id_registration}/${
                this.isEmr ? 'history' : 'service'
              }?status=inpatient`,
            ),
          )
          .then(response => {
            const asesmen = response.data.data;
            this.input = {
              anamnesis: asesmen.subjective.anamnesis,
              priorDisease: asesmen.subjective.disease_history,
              allergy: asesmen.allergy,
              ttv: {
                sistole: asesmen.objective.sistol || '',
                diastole: asesmen.objective.diastol || '',
                pulse: asesmen.objective.heart_rate || '',
                weight: asesmen.objective.weight || '',
                height: asesmen.objective.height || '',
                respiration: asesmen.objective.respiration_rate || '',
                saturation: asesmen.objective.saturation || '',
                temperature: asesmen.objective.temperature || '',
                awareness: asesmen.objective.awarness || '',
              },
              ekg: _.get(asesmen, 'other.ekg', ''),
              radiology: _.get(asesmen, 'other.radiology', ''),
              laboratorium: _.get(asesmen, 'other.laboratory', ''),
              othersNote: _.get(asesmen, 'other.other', ''),
              secondaryDiagnose: asesmen.assesment.other_diagnose.map(arr => {
                return {
                  text: {
                    id: _.get(arr, 'diagnose._id', ''),
                    text: _.get(arr, 'diagnose.description', ''),
                    code: _.get(arr, 'diagnose.code', ''),
                  },
                  detail: arr.description,
                };
              }),
              mainDiagnose: asesmen.assesment?.main_diagnose
                ? {
                    detail: asesmen.assesment.main_diagnose.description,
                    text: {
                      id: asesmen.assesment.main_diagnose.diagnose._id,
                      text:
                        asesmen.assesment.main_diagnose.diagnose.description,
                      code: asesmen.assesment.main_diagnose.diagnose.code,
                    },
                  }
                : { detail: '', text: '' },
              isChronic: _.get(asesmen, 'assesment.isChronic'),
              deathCause: _.get(asesmen, 'assesment.death_cause'),
              returnStatus: _.get(asesmen, 'assesment.return_status'),
              actions: _.get(asesmen, 'planning.treatment', ['']),
              therapies: _.get(asesmen, 'planning.therapy', ['']),
            };
            if (!this.isEmr) {
              this.addDiagnose();
              this.input.anamnesis.push('');
              this.input.priorDisease.push('');
              this.input.allergy.push('');
              this.input.actions.push('');
              this.input.therapies.push('');
            }
            this.$refs.form.resetValidation();
          })
          .catch(error => {
            this.showErrorAxios(error);
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getDataAssessment();
      this.getDiagnose();
      this.getAwarness();
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.container-layout {
  height: 72vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
  .title {
    font-size: 1.4rem;
  }
  .grid-ttv {
    display: grid;
    grid-template-columns: 220px 280px;
  }
  .dental-stage {
    display: flex;
    justify-content: center;
  }
  .dental-input {
    display: grid;
    grid-template-columns: 190px 250px;
  }
}
</style>
