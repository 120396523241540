import Constant from '@/const';
import axios from 'axios';

const exportMorbidity = async params => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/report/morbiditas/export'),
      { params },
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default exportMorbidity;
