<template>
  <v-card>
    <v-card-title
      style="border-bottom: 1px solid #9CA2A5;"
      class="justify-space-between"
    >
      <h3>Buat akun baru dari {{ data.account_code }}</h3>
      <v-btn icon @click="$emit('close-dialog')" color="red"
        ><v-icon>mdi-close-thick</v-icon></v-btn
      >
    </v-card-title>
    <v-row no-gutters align="center" class="pa-5">
      <v-form ref="form">
        <div class="form-style">
          <label class="font-weight-bold body-2">Tipe</label>
          <v-radio-group
            row
            v-model="input.type"
            :rules="rules.type"
            dense
            @change="generateCode()"
          >
            <v-radio label="Akun" value="main"></v-radio>
            <v-radio label="Sub Akun" value="sub"></v-radio>
          </v-radio-group>

          <label class="font-weight-bold body-2 text-left pb-4 pt-1"
            >Kode Akun</label
          >
          <label class="text-left">{{ input.code }}</label>

          <label class="font-weight-bold body-2 text-left">Nama Akun</label>
          <v-text-field
            dense
            placeholder="Masukkan Nama Akun"
            v-model="input.name"
            :rules="rules.name"
          ></v-text-field>

          <label class="font-weight-bold text-left body-2">Kategori Akun</label>
          <v-combobox
            dense
            placeholder="Pilih Kategori"
            :items="resource.category"
            :rules="rules.category"
            v-model="input.category"
          ></v-combobox>

          <label class="font-weight-bold text-left body-2">Saldo</label>
          <v-currency-field
            dense
            placeholder="Masukkan Saldo"
            :rules="rules.balance"
            v-model="input.balance"
            :decimal-length="2"
          ></v-currency-field>

          <label class="font-weight-bold text-left body-2">Tanggal Saldo</label>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="input.date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formattedDate"
                placeholder="Masukkan Tanggal"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="rules.date"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="input.date"
              no-title
              scrollable
              @input="
                $refs.menu.save(input.date);
                menu = false;
              "
            />
          </v-menu>

          <label class="font-weight-bold text-left body-2">Status</label>

          <v-radio-group row :rules="rules.status" v-model="input.status" dense>
            <v-radio label="Aktif" :value="true"></v-radio>
            <v-radio label="Tidak Aktif" :value="false"></v-radio>
          </v-radio-group>
        </div>
      </v-form>
    </v-row>
    <v-card-actions class="mt-10">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        tile
        depressed
        class="text-none"
        width="100"
        :disabled="disableButton"
        @click="saveAcc()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import Constant from '@/const';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');

export default {
  name: 'InputNewAccount',
  mixins: [alertMixin],
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      resource: {
        category: [
          'Aset',
          'Liabilitas',
          'Aset Lancar',
          'Kas dan Bank',
          'Piutang Usaha',
          'Piutang Lain',
          'Penyisihan Piutang Tak Tertagih',
          'Persediaan',
          'Investasi',
          'Aset Lancar Lainnya',
          'Aset Tetap',
          'Akumulasi Depresiasi',
          'Akumulasi Amortisasi',
          'Aset Lainnya',
          'Utang Jangka Panjang',
          'Utang Jangka Pendek',
          'Utang Usaha',
          'Utang Jangka Pendek Lain',
          'Ekuitas',
          'Pendapatan',
          'Biaya Pokok Pendapatan',
          'Beban',
          'Pendapatan Lain-lain',
          'Beban Lain-lain',
        ],
      },
      menu: false,
      input: {
        type: '',
        code: '',
        name: '',
        category: '',
        balance: '',
        date: null,
        status: '',
      },
      rules: {
        type: [v => !!v || 'Perlu mengisi tipe'],
        name: [v => !!v || 'Perlu mengisi nama'],
        category: [v => !!v || 'Perlu mengisi kategori'],
        balance: [v => !!v || 'Perlu mengisi saldo'],
        date: [v => !!v || 'Perlu mengisi tanggal'],
        status: [v => v !== null || 'Perlu mengisi status'],
      },
      disableButton: false,
    };
  },
  // watch: {
  //   'input.type': (val) => {
  //     if(val === 'acc') this.input.code = this.data.code;
  //   }
  // },
  computed: {
    formattedDate() {
      return this.input.date
        ? moment(this.input.date).format('DD/MM/YYYY')
        : this.input.date;
    },
  },
  methods: {
    saveAcc() {
      if (this.$refs.form.validate()) {
        const payload = {
          account_code: this.input.code,
          type: this.input.type, // 'main' for main account, 'sub' for sub account
          name: this.input.name,
          category: this.input.category,
          balance: this.input.balance,
          date: moment(this.input.date).format(),
          status: this.input.status,
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        };
        Swal.fire({
          text: `Apakah Anda yakin ingin menyimpan akun ${this.input.code}?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#2D9CDB',
          cancelButtonColor: '#EB5757',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          reverseButtons: true,
        }).then(result => {
          if (result.isConfirmed) {
            if (this.input.type === 'main') {
              axios
                .post(
                  Constant.apiUrl.concat('/finance/ledger/account'),
                  payload,
                )
                .then(() => {
                  this.$emit('close-form', 'save', this.input.code);
                })
                .catch(err => {
                  this.showErrorAxios(err);
                });
            } else {
              axios
                .post(
                  Constant.apiUrl.concat(
                    `/finance/ledger/account?id_accountBalance=${this.data._id}`,
                  ),
                  payload,
                )
                .then(() => {
                  this.$emit('close-form', 'save', this.input.code);
                })
                .catch(err => {
                  this.showErrorAxios(err);
                });
            }
          }
        });
      } else {
        this.disableButton = true;
      }
    },
    generateCode() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/finance/ledger/account/code/${this.data._id}?type=${this.input.type}`,
          ),
        )
        .then(response => {
          this.input.code = response.data.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-style {
  display: grid;
  grid-template-columns: 20vh 30vh;
  // grid-row-gap: 2vh;
  text-align: left;
  align-items: baseline;
}
</style>
