<template>
  <v-card>
    <v-container class="container-layout">
      <h2 class="py-5 black--text text-left" v-if="isAssessment">
        Riwayat Asesmen Pasien
      </h2>
      <v-row class="justify-center align-center" style="height: 350px;" v-else>
        <h1>Belum ada riwayat asesmen pasien</h1>
      </v-row>
      <template v-for="(history, index) in history">
        <v-expansion-panels flat accordion focusable :key="'history-' + index">
          <v-expansion-panel>
            <v-expansion-panel-header
              hide-actions
              class="blue--text font-weight-bold heading-5 py-1"
              @click="expand('date')"
            >
              <v-col cols="1" style="width: 30px;" class="pa-0">
                <v-icon color="blue" v-if="!expandableDate">
                  mdi-chevron-right-box
                </v-icon>
                <v-icon color="blue" v-else>
                  mdi-chevron-down-box
                </v-icon>
              </v-col>
              <v-col>
                {{ history.date }}
              </v-col>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels flat accordion focusable class="mt-2">
                <!-- <template v-for="(detail, child) in history.detail"> -->
                <v-expansion-panel>
                  <v-expansion-panel-header
                    hide-actions
                    class="blue--text font-weight-bold heading-6 py-1"
                    @click="expand('time')"
                  >
                    <v-col cols="1" style="width: 30px;" class="pa-0">
                      <v-icon color="blue" v-if="!expandableTime">
                        mdi-chevron-right-box
                      </v-icon>
                      <v-icon color="blue" v-else>
                        mdi-chevron-down-box
                      </v-icon>
                    </v-col>
                    <v-col>
                      {{ history.detail.created_at }}
                    </v-col>
                    <v-col cols="4" class="text-right">
                      Petugas : {{ history.detail.created_by }}
                    </v-col>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card-title class="title font-weight-bold primary--text">
                      Data Subjektif
                    </v-card-title>
                    <v-row>
                      <v-col cols="5">
                        <v-row no-gutters align="baseline">
                          <v-col cols="5">
                            <v-row no-gutters justify="end" class="mb-7 mr-10">
                              <label
                                for="Anamnesis"
                                class="black--text font-weight-bold"
                                >Anamnesis</label
                              >
                            </v-row>
                          </v-col>
                          <v-col cols="7" class="text-left">
                            <template
                              v-for="(anamnesis, indexing) in history.detail
                                .subjective.anamnesis"
                            >
                              <ul :key="'anamnesis-' + indexing">
                                <li>{{ anamnesis }}</li>
                              </ul>
                            </template>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row no-gutters align="baseline">
                          <v-col cols="6">
                            <v-row no-gutters justify="end" class="mb-7 mr-10">
                              <label
                                for="Riwayat Penyakit Sebelumnya"
                                class="black--text font-weight-bold"
                                >Riwayat Penyakit Sebelumnya</label
                              ></v-row
                            >
                          </v-col>
                          <v-col cols="6" class="text-left">
                            <template
                              v-for="(disease, indexing) in history.detail
                                .subjective.priorDisease"
                            >
                              <ul :key="'anamnesis-' + indexing">
                                <li>{{ disease }}</li>
                              </ul>
                            </template>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6" offset="5" class="py-0">
                        <v-row no-gutters align="baseline">
                          <v-col cols="6">
                            <v-row no-gutters justify="end" class="mb-7 mr-10"
                              ><label
                                for="Riwayat Alergi"
                                class="black--text font-weight-bold"
                                >Riwayat Alergi</label
                              ></v-row
                            >
                          </v-col>
                          <v-col cols="6" class="text-left">
                            <template
                              v-for="(allergy, indexing) in history.allergy"
                            >
                              <ul :key="'anamnesis-' + indexing">
                                <li>{{ allergy }}</li>
                              </ul>
                            </template>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-card-title
                      class="title font-weight-bold primary--text text-left"
                    >
                      Data Objektif <br />
                      TTV
                    </v-card-title>
                    <v-row>
                      <v-col cols="5">
                        <v-row no-gutters align="baseline" class="grid-ttv">
                          <label
                            for="Tekanan Darah"
                            class="black--text font-weight-bold text-right mr-10"
                            >Tekanan Darah</label
                          >
                          <label class="text-left"
                            >{{ history.detail.objective.ttv.sistole }}/{{
                              history.detail.objective.ttv.diastole
                            }}
                            <b>mm/Hg</b></label
                          >

                          <label
                            for="Nadi"
                            class="black--text font-weight-bold text-right mr-10"
                            >Nadi</label
                          >
                          <label class="text-left"
                            >{{ history.detail.objective.ttv.pulse }}
                            <b>x/menit</b></label
                          >

                          <label
                            for="Berat Badan"
                            class="black--text font-weight-bold text-right mr-10"
                            >Berat Badan</label
                          >
                          <label class="text-left"
                            >{{ history.detail.objective.ttv.weight }}
                            <b>kg</b></label
                          >

                          <label
                            for="Tinggi Badan"
                            class="black--text font-weight-bold text-right mr-10"
                            >Tinggi Badan</label
                          >
                          <label class="text-left"
                            >{{ history.detail.objective.ttv.height }}
                            <b>cm</b></label
                          >
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row no-gutters align="baseline" class="grid-ttv">
                          <label
                            for="RR"
                            class="black--text font-weight-bold text-right mr-10"
                            >RR</label
                          >
                          <label class="text-left"
                            >{{ history.detail.objective.ttv.respiration }}
                            <b>x/menit</b></label
                          >
                          <label
                            for="Saturasi 02"
                            class="black--text font-weight-bold text-right mr-10"
                            >Saturasi O<sub>2</sub></label
                          >
                          <label class="text-left"
                            >{{ history.detail.objective.ttv.saturation }}
                            <b>%</b></label
                          >
                          <label
                            for="Suhu"
                            class="black--text font-weight-bold text-right mr-10"
                            >Suhu</label
                          >
                          <label class="text-left"
                            >{{ history.detail.objective.ttv.temperature }}
                            <b><sup>O</sup>C</b></label
                          >
                          <label
                            for="Kesadaran"
                            class="black--text font-weight-bold text-right mr-10"
                            >Kesadaran</label
                          >
                          <label class="text-left">{{
                            resource.awareness.find(
                              x =>
                                x.value ===
                                history.detail.objective.ttv.awareness,
                            ).text
                          }}</label>
                        </v-row>
                      </v-col>
                    </v-row>

                    <!-- <v-card-title
                      class="title font-weight-bold primary--text text-left"
                    >
                      Pemeriksaan Fisik
                    </v-card-title>
                    <v-row align="baseline">
                      <v-col cols="6">
                        <v-row align="baseline">
                          <v-col cols="4" class="text-right pr-7">
                            <label class="black--text font-weight-bold"
                              >Kepala</label
                            >
                          </v-col>
                          <v-col class="text-left">
                            <label>{{
                              history.detail.objective.assessment.physical.head
                            }}</label>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align="baseline">
                      <v-col cols="6">
                        <v-row align="baseline">
                          <v-col cols="4" class="text-right pr-7">
                            <label class="black--text font-weight-bold"
                              >Mata</label
                            >
                          </v-col>
                          <v-col class="text-left">
                            <label>{{
                              history.detail.objective.assessment.physical.eye
                            }}</label>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align="baseline">
                      <v-col cols="6">
                        <v-row align="baseline">
                          <v-col cols="4" class="text-right pr-7">
                            <label class="black--text font-weight-bold"
                              >Mulut</label
                            >
                          </v-col>
                          <v-col class="text-left">
                            <label>{{
                              history.detail.objective.assessment.physical.mouth
                            }}</label>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align="baseline">
                      <v-col cols="6">
                        <v-row align="baseline">
                          <v-col cols="4" class="text-right pr-7">
                            <label class="black--text font-weight-bold"
                              >Leher</label
                            >
                          </v-col>
                          <v-col class="text-left">
                            <label>{{
                              history.detail.objective.assessment.physical.neck
                            }}</label>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align="baseline">
                      <v-col cols="6">
                        <v-row align="baseline">
                          <v-col cols="4" class="text-right pr-7">
                            <label class="black--text font-weight-bold"
                              >Perut</label
                            >
                          </v-col>
                          <v-col class="text-left">
                            <label>{{
                              history.detail.objective.assessment.physical
                                .stomach
                            }}</label>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align="baseline">
                      <v-col cols="6">
                        <v-row align="baseline">
                          <v-col cols="4" class="text-right pr-7">
                            <label class="black--text font-weight-bold"
                              >Alat Gerak</label
                            >
                          </v-col>
                          <v-col class="text-left">
                            <label>{{
                              history.detail.objective.assessment.physical
                                .moving_part
                            }}</label>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row align="baseline">
                      <v-col cols="6">
                        <v-row align="baseline">
                          <v-col cols="4" class="text-right pr-7">
                            <label class="black--text font-weight-bold"
                              >Genitalia</label
                            >
                          </v-col>
                          <v-col class="text-left">
                            <label>{{
                              history.detail.objective.assessment.physical
                                .genitalia
                            }}</label>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row> -->

                    <v-card-title
                      class="title font-weight-bold primary--text text-left"
                    >
                      Hasil Penunjang Lainnya
                    </v-card-title>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="4" class="text-right pr-7">
                            <label
                              for="Hasil EKG"
                              class="black--text font-weight-bold"
                              >Hasil EKG</label
                            >
                          </v-col>
                          <v-col class="text-left">
                            <label>{{
                              history.detail.objective.support.ekg
                            }}</label>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="4" class="text-right pr-7">
                            <label
                              for="Hasil Radiologi"
                              class="black--text font-weight-bold"
                              >Hasil Radiologi</label
                            >
                          </v-col>
                          <v-col class="text-left">
                            <label>{{
                              history.detail.objective.support.radiology
                            }}</label>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="4" class="text-right pr-7">
                            <label
                              for="Hasil Laboratorium"
                              class="black--text font-weight-bold"
                              >Hasil Laboratorium</label
                            >
                          </v-col>
                          <v-col class="text-left">
                            <label>{{
                              history.detail.objective.support.laboratory
                            }}</label>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="4" class="text-right pr-7">
                            <label
                              for="Catatan Lain"
                              class="black--text font-weight-bold"
                              >Catatan Lain</label
                            >
                          </v-col>
                          <v-col class="text-left">
                            <label>{{
                              history.detail.objective.support.other
                            }}</label>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-card-title
                      class="title font-weight-bold primary--text text-left"
                    >
                      Assesmen
                    </v-card-title>
                    <v-row class="pb-5">
                      <v-col cols="5">
                        <v-row no-gutters align="baseline">
                          <v-col cols="5"
                            ><v-row no-gutters justify="end" class="mr-10"
                              ><label
                                for="Diagnosa Utama"
                                class="black--text font-weight-bold"
                                >Diagnosa Utama</label
                              ></v-row
                            ></v-col
                          >
                          <v-col class="text-left">
                            {{
                              history.detail.objective.assessment.mainDiagnose
                                .text.code
                            }}.
                            {{
                              history.detail.objective.assessment.mainDiagnose
                                .text.text
                            }}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="7">
                        <v-row no-gutters align="baseline">
                          <v-col cols="5"
                            ><v-row no-gutters justify="end" class="mr-5"
                              ><label
                                for="Informasi Keterangan Kritis"
                                class="black--text font-weight-bold"
                                >Informasi Keterangan Klinis</label
                              ></v-row
                            ></v-col
                          >
                          <v-col cols="5" class="pl-5 text-left">{{
                            history.detail.objective.assessment.mainDiagnose
                              .detail
                          }}</v-col>
                        </v-row>
                      </v-col>
                      <template
                        v-for="(diagnose, index) in history.detail.objective
                          .assessment.secondaryDiagnose"
                      >
                        <v-col cols="5" :key="'diagnoseLabel-' + index">
                          <v-row no-gutters align="baseline">
                            <v-col cols="5" v-if="index === 0"
                              ><v-row no-gutters justify="end" class="mr-10"
                                ><label
                                  for="Diagnosa Sekunder"
                                  class="black--text font-weight-bold"
                                  >Diagnosa Sekunder</label
                                ></v-row
                              ></v-col
                            >
                            <v-col cols="5" v-else></v-col>
                            <v-col class="text-left"
                              >{{ diagnose.text.code }}.
                              {{ diagnose.text.text }}</v-col
                            >
                          </v-row>
                        </v-col>
                        <v-col cols="7" :key="'diagnoseInfo-' + index">
                          <v-row no-gutters align="baseline">
                            <v-col cols="5"
                              ><v-row no-gutters justify="end" class="mr-5"
                                ><label
                                  for="Informasi Keterangan Kritis"
                                  class="black--text font-weight-bold"
                                  >Informasi Keterangan Klinis</label
                                ></v-row
                              ></v-col
                            >
                            <v-col cols="5" class="pl-5 text-left">{{
                              diagnose.detail
                            }}</v-col>
                          </v-row>
                        </v-col>
                      </template>

                      <v-col cols="5">
                        <v-row no-gutters align="baseline">
                          <template
                            v-for="(cause, index) in history.detail.objective
                              .assessment.deathCause"
                          >
                            <v-col
                              cols="5"
                              :key="'causeLabel-' + index"
                              v-if="index === 0"
                              ><v-row no-gutters justify="end" class="mr-10"
                                ><label
                                  for="Penyebab Kematian"
                                  class="black--text font-weight-bold"
                                  >Penyebab Kematian</label
                                ></v-row
                              ></v-col
                            >
                            <v-col
                              v-else
                              cols="5"
                              :key="'causeLabel-' + index"
                            ></v-col>
                            <v-col
                              cols="7"
                              :key="'causeInput-' + index"
                              class="text-left"
                              ><label>{{ cause }}</label></v-col
                            >
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-card-title class="title font-weight-bold primary--text">
                      Planning
                    </v-card-title>
                    <v-row>
                      <v-col cols="5">
                        <v-row no-gutters align="baseline">
                          <v-col cols="5">
                            <v-row no-gutters justify="end" class="mb-7 mr-10">
                              <label
                                for="Tindakan"
                                class="black--text font-weight-bold"
                                >Tindakan</label
                              >
                            </v-row>
                          </v-col>
                          <v-col cols="6" class="text-left">
                            <template
                              v-for="(action, index) in history.detail.objective
                                .action"
                            >
                              <ul :key="'action-' + index">
                                <li>{{ action }}</li>
                              </ul>
                            </template>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="7">
                        <v-row no-gutters align="baseline">
                          <v-col cols="5">
                            <v-row no-gutters justify="end" class="mr-5">
                              <label
                                for="Terapi"
                                class="black--text font-weight-bold"
                                >Terapi</label
                              ></v-row
                            >
                          </v-col>
                          <v-col cols="5" class="pl-5 text-left">
                            <template
                              v-for="(therapy, index) in history.detail
                                .objective.therapy"
                            >
                              <ul :key="'therapy-' + index">
                                <li>{{ therapy }}</li>
                              </ul>
                            </template>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- </template>  -->
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-container>
    <v-card-actions class="d-flex justify-end">
      <v-btn
        width="108"
        height="36"
        class="text-none"
        text
        @click="$emit('close-form')"
      >
        Kembali
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'HistoryCPPT',
  mixins: [jwtMixin],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      history: [],
      resource: {
        awareness: [
          { text: 'Compos Mentis', value: 1 },
          { text: 'Somnolence', value: 2 },
          { text: 'Sopor', value: 3 },
          { text: 'Coma', value: 4 },
        ],
        return_status: ['Berobat Jalan', 'Rujuk Lanjut', 'Rujuk Internal'],
      },
      expandableDate: false,
      expandableTime: false,
      isAssessment: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getHistory();
    });
  },
  methods: {
    async getHistory() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/patient/emr/history/checkup?id_emr=${this.patientData.id_emr}&id_unit=${this.patientData.meta.id_poly}`,
            ),
          )
          .then(response => {
            const history = response.data.data;
            this.history = history.map(item => {
              return {
                date: item.date,
                allergy: item.allergy,
                detail: {
                  created_by: item.timestamps.created_by,
                  created_at: moment(item.timestamps.created_at)
                    .locale('id')
                    .format('[Pukul] LT'),
                  subjective: {
                    anamnesis: item.subjective.anamnesis,
                    priorDisease: item.subjective.disease_history,
                  },
                  objective: {
                    ttv: {
                      pulse: item.objective.heart_rate,
                      sistole: item.objective.sistol,
                      diastole: item.objective.diastol,
                      respiration: item.objective.respiration_rate,
                      temperature: item.objective.temperature,
                      saturation: item.objective.saturation,
                      awareness: item.objective.awarness,
                      weight: item.objective.weight,
                      height: item.objective.height,
                    },
                    support: item.other,
                    assessment: {
                      isChronic: item.assesment.isChronic,
                      returnStatus: parseInt(item.assesment.return_status),
                      deathCause: item.assesment.death_cause,
                      mainDiagnose: {
                        detail: item.assesment.main_diagnose.description,
                        text: {
                          code: item.assesment.main_diagnose.diagnose.code,
                          text:
                            item.assesment.main_diagnose.diagnose.description,
                          id: item.assesment.main_diagnose.diagnose._id,
                        },
                      },
                      secondaryDiagnose: item.assesment.other_diagnose
                        .map(item => {
                          return {
                            detail: item.description,
                            text: {
                              code: _.get(item, 'diagnose.code', ''),
                              text: _.get(item, 'diagnose.description', ''),
                              id: _.get(item, 'diagnose._id', ''),
                            },
                          };
                        })
                        .filter(x => x.text.id !== ''),
                      physical: item.assesment.physical,
                    },
                    action: item.planning.treatment.filter(x => x !== ''),
                    therapy: item.planning.therapy.filter(x => x !== ''),
                  },
                },
              };
            });
            this.history.allergy = response.data.data.allergy;
            this.isAssessment = true;
          })
          .catch(error => {
            if (error.status == 404) {
              this.isAssessment = false;
            }
          });
      });
    },
    range(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    getDiagnoseById(id) {
      axios
        .get(Constant.apiUrl.concat(`/master/diagnose/${id}`))
        .then(response => {
          let diagnose = response.data.data;

          this.history.objective.assessment.mainDiagnose = {
            text: {
              id: diagnose._id.toString(),
              code: diagnose.code,
              text: diagnose.description,
            },
          };
        });
    },
    expand(val) {
      switch (val) {
        case 'date':
          this.expandableDate = !this.expandableDate;
          break;
        case 'time':
          this.expandableTime = !this.expandableTime;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-layout {
  height: 460px;
  overflow: auto;
  overflow-x: hidden;
  .grid-ttv {
    display: grid;
    grid-template-columns: 190px 250px;
    grid-template-rows: repeat(4, 30px);
  }
  .dental-input {
    display: grid;
    grid-template-columns: 100px 200px;
  }
}
::v-deep {
  .v-expansion-panel-header--active {
    background-color: #86bcdb;
    color: #fff !important;
    min-height: 56px;
    border-radius: 5px;

    & > .v-expansion-panel-header__icon > i {
      color: #fff !important;
    }
  }
}
</style>
