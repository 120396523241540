<template>
  <v-card>
    <v-card-title class="justify-end">
      <h3>Detail Penerimaan Barang Supplier</h3>
    </v-card-title>
    <v-container fluid class="px-5 container-layout">
      <v-row no-gutters>
        <v-col class="text-left" cols="3">
          <div class="detail-order">
            <label class="font-weight-bold body-2">Nomor Pemesanan</label>
            <label class="body-2 text-uppercase">{{
              selectedData.orderNumber
            }}</label>

            <label class="font-weight-bold body-2">Nomor Tanda Terima</label>
            <label class="body-2 text-uppercase">{{
              selectedData.receiptNumber
            }}</label>

            <label class="font-weight-bold body-2">Nomor Faktur</label>
            <label class="body-2 text-uppercase">{{
              selectedData.invoiceNumber
            }}</label>

            <label class="font-weight-bold body-2">Nama Supplier</label>
            <label class="body-2">{{ selectedData.supplierName }}</label>
          </div>
        </v-col>
        <v-col class="text-left" cols="3">
          <div class="detail-order">
            <label class="font-weight-bold body-2">Tanggal Pemesanan</label>
            <label class="body-2">{{ selectedData.orderDate }}</label>

            <label class="font-weight-bold body-2">Tanggal Penerimaan</label>
            <label class="body-2">{{ selectedData.receiptDate }}</label>

            <label class="font-weight-bold body-2">Tanggal Jatuh Tempo</label>
            <label class="body-2">{{ selectedData.dueDate }}</label>

            <label class="font-weight-bold body-2">Metode Pembayaran</label>
            <label class="body-2 text-capitalize">{{
              selectedData.paymentMethod
            }}</label>
          </div>
        </v-col>
        <v-col class="text-left" cols="3">
          <div class="detail-order">
            <label class="font-weight-bold body-2">Biaya Ongkir</label>
            <label class="body-2"
              >Rp {{ formatNumber(selectedData.deliveryFee || 0) }}</label
            >

            <label class="font-weight-bold body-2">Biaya Materai</label>
            <label class="body-2"
              >Rp {{ formatNumber(selectedData.stampCost || 0) }}</label
            >

            <label class="font-weight-bold">Total Biaya</label>
            <label>Rp {{ formatNumber(selectedData.total || 0) }}</label>
          </div>
        </v-col>
        <v-col class="text-left" cols="3">
          <div class="detail-order">
            <label class="font-weight-bold body-2"
              >Alasan Transaksi Pending</label
            >
            <div>
              <template v-for="(pending, idx) in selectedData.pendingReason">
                <v-chip small :key="'reason-' + idx">{{ pending }}</v-chip>
              </template>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-tabs v-model="tabs" grow>
        <v-tab class="text-none">Detail Penerimaan</v-tab>
        <v-tab class="text-none">Riwayat Penerimaan</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <ReceiveDetail
            :allStaff="staffList"
            :items="data"
            :fileIdentifier="
              `${selectedData.orderNumber}${selectedData.invoiceNumber}${selectedData.receiptNumber}`
            "
            @get-data="this.getDataById"
          />
        </v-tab-item>
        <v-tab-item>
          <HistoryDetail
            :allStaff="staffList"
            :files="files"
            :historyData="historyData"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <v-card-actions>
      <v-btn text class="text-none" @click="onCloseButtonClick()"
        >Kembali</v-btn
      >
      <v-spacer></v-spacer>
      <!-- <v-btn outlined tile color="primary" class="text-none" width="150">
        <v-icon>mdi-printer</v-icon>Print</v-btn
      > -->
      <!-- <v-btn class="text-none" color="primary" tile depressed width="150"
        >Simpan</v-btn
      > -->
    </v-card-actions>
  </v-card>
</template>

<script>
import ReceiveDetail from './DetailComponent/ReceiveDetail.vue';
import HistoryDetail from './DetailComponent/HistoryDetail.vue';
import Constant from '@/const';
import moment from 'moment-timezone';

const axios = require('axios');

export default {
  name: 'DetailItemReceipt',
  props: {
    selectedData: {
      type: Object,
    },
  },
  components: {
    ReceiveDetail,
    HistoryDetail,
  },
  data() {
    return {
      files: [],
      resource: {
        paymentMethod: [
          { text: 'Cash', value: 'cash' },
          { text: 'Tempo', value: 'tempo' },
          { text: 'Konsinyasi', value: 'konsinyasi' },
          { text: 'Hibah', value: 'hibah' },
        ],
        reason: [
          'Jumlah Barang Tidak Sesuai',
          'Penggantian Nomor Faktur',
          'Belum Ada Nomor Faktur',
        ],
      },
      tabs: null,
      data: [],
      historyData: [],
      staffList: [],
    };
  },
  mounted() {
    this.getDataById();
    this.getStaff();
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    getStaff() {
      axios.get(Constant.apiUrl.concat(`/master/staff`)).then(response => {
        const { data } = response.data;
        this.staffList = data.map(staff => {
          return {
            name: staff.detail.name,
            id: staff._id,
          };
        });
      });
    },
    getDataById() {
      this.historyData = [];
      try {
        axios
          .get(
            Constant.apiUrl
              .concat('/supply/itemlist/')
              .concat(this.selectedData.id),
          )
          .then(response => {
            const { data } = response.data;
            this.files = data[0].files.map(file => {
              return {
                ...file,
                invoiceNumber: this.selectedData.invoiceNumber,
              };
            });
            this.data = data[0].item.supply_item.map(item => {
              if (item.goods.length > 0) {
                return {
                  id: item._id,
                  history: item.history.map(hist => {
                    return {
                      ...hist,
                      created_at: moment(hist.created_at).format(
                        'DD/MM/YYYY HH:mm',
                      ),
                    };
                  }),
                  name: item.goods[0].detail[0].name,
                  package:
                    item.goods[0].detail[0].packaging.length > 0
                      ? item.goods[0].detail[0].packaging.filter(
                          i => i.isDefault,
                        )[0].package
                      : '',
                  orderAmount: item.amount.ordered_amount,
                  receiveAmount: item.amount.unfit_amount
                    ? Math.abs(
                        item.amount.ordered_amount - item.amount.unfit_amount,
                      )
                    : item.amount.ordered_amount,
                  unmatchAmount: item.amount.unfit_amount
                    ? item.amount.unfit_amount
                    : '-',
                  returnReason: item.return_reason,
                  expiredDate: item.stocks[0].expiry_date
                    ? moment(item.stocks[0].expiry_date).format('DD/MM/YYYY')
                    : '-',
                  batch: item.stocks[0].batch_number,
                  unitPrice: item.price.price_per_unit,
                  discount: item.price.discount,
                  subtotal: item.price.sub_total,
                  hibah: item.isHibah,
                  isHold: !item.isComplete,
                  orderDate: this.selectedData.orderDate,
                  invoiceNumber: this.selectedData.invoiceNumber,
                  receiptNumber: this.selectedData.receiptNumber,
                };
              }
              if (item.drugs.length > 0) {
                return {
                  id: item._id,
                  history: item.history.map(hist => {
                    return {
                      ...hist,
                      created_at: moment(hist.created_at).format(
                        'DD/MM/YYYY HH:mm',
                      ),
                    };
                  }),
                  name: item.drugs[0].detail[0].name,
                  package:
                    item.drugs[0].detail[0].packaging.length > 0
                      ? item.drugs[0].detail[0].packaging.filter(
                          i => i.isDefault,
                        )[0].package
                      : '',
                  orderAmount: item.amount.ordered_amount,
                  receiveAmount: item.amount.unfit_amount
                    ? Math.abs(
                        item.amount.ordered_amount - item.amount.unfit_amount,
                      )
                    : item.amount.ordered_amount,
                  unmatchAmount: item.amount.unfit_amount
                    ? item.amount.unfit_amount
                    : '-',
                  returnReason: item.return_reason,
                  expiredDate: item.stocks[0].expiry_date
                    ? moment(item.stocks[0].expiry_date).format('DD/MM/YYYY')
                    : '-',
                  batch: item.stocks[0].batch_number,
                  unitPrice: item.price.price_per_unit,
                  discount: item.price.discount,
                  subtotal: item.price.sub_total,
                  hibah: item.isHibah,
                  isHold: !item.isComplete,
                  orderDate: this.selectedData.orderDate,
                  invoiceNumber: this.selectedData.invoiceNumber,
                  receiptNumber: this.selectedData.receiptNumber,
                };
              }
            });
            data[0].item.supply_item.forEach(item => {
              const history = item.history.map(i => {
                if (item.goods.length > 0) {
                  return {
                    receiveDate: moment(i.created_at).format('DD/MM/YYYY'),
                    receiveTime: moment(i.created_at).format(
                      'DD/MM/YYYY HH:mm',
                    ),
                    invoiceNumber: i.invoice_number,
                    name: item.goods[0].detail[0].name,
                    receiveAmount: Math.abs(i.received_amount),
                    batch: i.batch_number,
                    expiredDate: i.expiry_date
                      ? moment(i.expiry_date).format('DD/MM/YYYY')
                      : '-',
                    staffName: i.created_by,
                    status: !i.isComplete ? 'Belum Selesai' : 'Selesai',
                    package: i.package ? i.package : '-',
                  };
                }
                if (item.drugs.length > 0) {
                  return {
                    receiveDate: moment(i.created_at).format('DD/MM/YYYY'),
                    receiveTime: moment(i.created_at).format(
                      'DD/MM/YYYY HH:mm',
                    ),
                    invoiceNumber: i.invoice_number,
                    name: item.drugs[0].detail[0].name,
                    receiveAmount: Math.abs(i.received_amount),
                    batch: i.batch_number,
                    expiredDate: i.expiry_date
                      ? moment(i.expiry_date).format('DD/MM/YYYY')
                      : '-',
                    staffName: i.created_by,
                    status: !i.isComplete ? 'Belum Selesai' : 'Selesai',
                    package: i.package ? i.package : '-',
                  };
                }
              });
              this.historyData.push(...history);
            });
          });
      } catch {
        this.historyData = [];
      }
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
  },
};
</script>

<style lang="scss" scoped>
.container-layout {
  height: 82vh;
  overflow: auto;
}
.detail-order {
  display: grid;
  grid-template-columns: 11vw 13vw;
  grid-row-gap: 2vh;
}
</style>
