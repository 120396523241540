const state = {
  active_tbc: {
    text: 'TBC Aktif',
    checked: '',
    type: 'airborne',
  },
  measless: {
    text: 'Campak (Measless)',
    checked: '',
    type: 'airborne',
  },
  mdr_tb: {
    text: '(MDR TB)',
    checked: '',
    type: 'airborne',
  },
  sars: {
    text: 'SARS',
    checked: '',
    type: 'airborne',
  },
  aspergilosis: {
    text: 'Aspergilosis',
    checked: '',
    type: 'airborne',
  },
  aricella_zooster: {
    text: 'Aricella Zooster',
    checked: '',
    type: 'airborne',
  },
  ispa: {
    text: 'ISPA',
    checked: '',
    type: 'droplet',
  },
  swine_flu: {
    text: 'Swine Flu (H1N1)',
    checked: '',
    type: 'droplet',
  },
  rebulla: {
    text: 'Rebulla',
    checked: '',
    type: 'droplet',
  },
  dipteria: {
    text: 'Dipteria',
    checked: '',
    type: 'droplet',
  },
  pneumonia: {
    text: 'Pneumonia',
    checked: '',
    type: 'droplet',
  },
  mumps: {
    text: 'Mumps (Parotitis)',
    checked: '',
    type: 'droplet',
  },
  bird_flu: {
    text: 'Flu Burung (H5N1)',
    checked: '',
    type: 'droplet',
  },
  hepatits_a: {
    text: 'Hepatitis A',
    checked: '',
    type: 'contact',
  },
  mdro_hiv: {
    text: 'MDRO HIV',
    checked: '',
    type: 'contact',
  },
  herpes_simplek: {
    text: 'Herpes Simplek',
    checked: '',
    type: 'contact',
  },
  scabies: {
    text: 'Scabies',
    checked: '',
    type: 'contact',
  },
  konjungtivity: {
    text: 'Konjungtivitas',
    checked: '',
    type: 'contact',
  },
  varicella_zooster: {
    text: 'Varicella-Zooster',
    checked: '',
    type: 'contact',
  },
  other_airborne: '',
  other_droplet: '',
  other_contact: '',
};

const getters = {
  getActiveTbc: state => state.active_tbc,
  getMeasless: state => state.measless,
  getMdrTb: state => state.mdr_tb,
  getSars: state => state.sars,
  getAspergilosis: state => state.aspergilosis,
  getPneumonia: state => state.pneumonia,
  getAricellaZooster: state => state.aricella_zooster,
  getOtherAirborne: state => state.other_airborne,
  getIspa: state => state.ispa,
  getSwineFlu: state => state.swine_flu,
  getRebulla: state => state.rebulla,
  getDipteria: state => state.dipteria,
  getMumps: state => state.mumps,
  getBirdFlu: state => state.bird_flu,
  getHepatitisA: state => state.hepatits_a,
  getMdroHiv: state => state.mdro_hiv,
  getHerpesSimplek: state => state.herpes_simplek,
  getScabies: state => state.scabies,
  getKonjungtivity: state => state.konjungtivity,
  getVaricellaZooster: state => state.varicella_zooster,
  getOtherDroplet: state => state.other_droplet,
  getOtherContact: state => state.other_contact,
};

const mutations = {
  clearInfectiousAssessmentState() {
    Object.assign(state, {
      active_tbc: {
        text: 'TBC Aktif',
        checked: '',
        type: 'airborne',
      },
      measless: {
        text: 'Campak (Measless)',
        checked: '',
        type: 'airborne',
      },
      mdr_tb: {
        text: '(MDR TB)',
        checked: '',
        type: 'airborne',
      },
      sars: {
        text: 'SARS',
        checked: '',
        type: 'airborne',
      },
      aspergilosis: {
        text: 'Aspergilosis',
        checked: '',
        type: 'airborne',
      },
      aricella_zooster: {
        text: 'Aricella Zooster',
        checked: '',
        type: 'airborne',
      },
      ispa: {
        text: 'ISPA',
        checked: '',
        type: 'droplet',
      },
      swine_flu: {
        text: 'Swine Flu (H1N1)',
        checked: '',
        type: 'droplet',
      },
      rebulla: {
        text: 'Rebulla',
        checked: '',
        type: 'droplet',
      },
      dipteria: {
        text: 'Dipteria',
        checked: '',
        type: 'droplet',
      },
      pneumonia: {
        text: 'Pneumonia',
        checked: '',
        type: 'droplet',
      },
      mumps: {
        text: 'Mumps (Parotitis)',
        checked: '',
        type: 'droplet',
      },
      bird_flu: {
        text: 'Flu Burung (H5N1)',
        checked: '',
        type: 'droplet',
      },
      hepatits_a: {
        text: 'Hepatitis A',
        checked: '',
        type: 'contact',
      },
      mdro_hiv: {
        text: 'MDRO HIV',
        checked: '',
        type: 'contact',
      },
      herpes_simplek: {
        text: 'Herpes Simplek',
        checked: '',
        type: 'contact',
      },
      scabies: {
        text: 'Scabies',
        checked: '',
        type: 'contact',
      },
      konjungtivity: {
        text: 'Konjungtivitas',
        checked: '',
        type: 'contact',
      },
      varicella_zooster: {
        text: 'Varicella-Zooster',
        checked: '',
        type: 'contact',
      },
      other_airborne: '',
      other_droplet: '',
      other_contact: '',
    });
  },
  setActiveTbc(state, payload) {
    state.active_tbc = payload;
  },
  setMeasless(state, payload) {
    state.measless = payload;
  },
  setMdrTb(state, payload) {
    state.mdr_tb = payload;
  },
  setSars(state, payload) {
    state.sars = payload;
  },
  setAspergilosis(state, payload) {
    state.aspergilosis = payload;
  },
  setAricellaZooster(state, payload) {
    state.aricella_zooster = payload;
  },
  setOtherAirborne(state, payload) {
    state.other_airborne = payload;
  },
  setOtherDroplet(state, payload) {
    state.other_droplet = payload;
  },
  setOtherContact(state, payload) {
    state.other_contact = payload;
  },
  setIspa(state, payload) {
    state.ispa = payload;
  },
  setSwineFlu(state, payload) {
    state.swine_flu = payload;
  },
  setRebulla(state, payload) {
    state.rebulla = payload;
  },
  setDipteria(state, payload) {
    state.dipteria = payload;
  },
  setPneumonia(state, payload) {
    state.pneumonia = payload;
  },
  setMumps(state, payload) {
    state.mumps = payload;
  },
  setBirdFlu(state, payload) {
    state.bird_flu = payload;
  },
  setHepatitisA(state, payload) {
    state.hepatits_a = payload;
  },
  setMdroHiv(state, payload) {
    state.mdro_hiv = payload;
  },
  setHerpesSimplek(state, payload) {
    state.herpes_simplek = payload;
  },
  setScabies(state, payload) {
    state.scabies = payload;
  },
  setKonjungtivity(state, payload) {
    state.konjungtivity = payload;
  },
  setVaricellaZooster(state, payload) {
    state.varicella_zooster = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
