import Constant from '@/const';
const axios = require('axios');

const deleteFile = async payload => {
  try {
    await axios.delete(
      Constant.apiUrl.concat('/upload'),
      { data: payload },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default deleteFile;
