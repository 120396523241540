import Constant from '@/const';
import axios from 'axios';

const postStaff = async payload => {
  try {
    await axios.post(Constant.apiUrl.concat('/master/staff/regis'), payload);
  } catch (error) {
    throw new Error(error);
  }
};

export default postStaff;
