import JsPDF from 'jspdf';
import printMixin from '../printMixin';

export default {
  methods: {
    printKidCardDocument(data) {
      const doc = new JsPDF({
        orientation: 'potrait',
        unit: 'in',
        format: 'a4',
      });
      doc.setProperties({
        title: `Resume Medis-${this.$store.getters.selectedPatient.rmNumber}`,
      });

      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      this.pdfTextPrint(doc, 'KARTU ANAK', 1.81);

      const baby = [
        ['No RM', ':', data.rm_number || '-'],
        ['Nama Anak', ':', data.babyName || '-'],
        ['Tanggal Lahir', ':', data.birthdate || '-'],
        ['Jenis Kelamin', ':', data.gender || '-'],
        ['Jenis Persalinan', ':', data.brithType || '-'],
      ];
      const parents = [
        ['Nama Ayah', ':', data.father.name || '-'],
        ['Pekerjaan Ayah', ':', data.father.profession || '-'],
        ['Nama Ibu', ':', data.mother.name || '-'],
        ['Pekerjaan Ibu', ':', data.mother.profession || '-'],
      ];
      const apgar = [
        ['Menit 1', ':', data.apgar.minute1 || '-'],
        ['Menit 5', ':', data.apgar.minute5 || '-'],
        ['Menit 10', ':', data.apgar.minute10 || '-'],
      ];

      const checkResult = [
        ['Berat Badan', ':', data.weight || '-'],
        ['Lingkar Kepala', ':', data.headCircumference || '-'],
        ['Panjang Badan', ':', data.length || '-'],
        ['Lingkar Dada', ':', data.chestCircumference || '-'],
        ['Catatan Tambahan', ':', data.postscript || '-'],
        ['Catatan Bawaan', ':', data.built_in_note || '-'],
        ['Kelainan Bayi', ':', data.abnormalities || '-'],
      ];

      const iterableArray = [
        { data: baby, title: 'INFORMASI BAYI' },
        { data: parents, title: 'INFORMASI ORANG TUA' },
        { data: apgar, title: 'APGAR' },
        { data: checkResult, title: 'DATA PEMERIKSAAN BAYI' },
      ];

      let lastLineTable = 2.2;

      doc.setTextColor(64, 64, 64);
      doc.setFontSize(11);

      for (let i = 0; i < iterableArray.length; i++) {
        this.pdfTextPrint(
          doc,
          iterableArray[i].title,
          lastLineTable,
          'left',
          0.77,
        );

        doc.autoTable({
          body: iterableArray[i].data,
          startY: lastLineTable + 0.2,
          styles: {
            halign: 'left',
            fontSize: 11,
          },
          columnStyles: {
            0: { cellPadding: 0.07, cellWidth: 1.7 },
            1: { cellPadding: 0.07, cellWidth: 0.2 },
            2: { cellPadding: 0.07, cellWidth: 4 },
          },
          margin: {
            left: 0.7,
            top: 1.7,
          },
          theme: 'plain',
        });
        lastLineTable = doc.autoTable.previous.finalY + 0.5;
      }

      doc.addPage();

      if (data?.images?.baby_right.img) {
        doc.addImage(
          data.images.baby_right.img,
          'PNG',
          1,
          2.7,
          this.scaleDown(data.images.baby_right.width),
          this.scaleDown(data.images.baby_right.height),
        );
      }
      if (data?.images?.baby_left.img) {
        doc.addImage(
          data.images.baby_left.img,
          'PNG',
          4.35,
          2.7,
          this.scaleDown(data.images.baby_left.width),
          this.scaleDown(data.images.baby_left.height),
        );
      }
      if (data?.images?.mother_right.img) {
        doc.addImage(
          data.images.mother_right.img,
          'PNG',
          1,
          6.6,
          this.scaleDown(data.images.mother_right.width),
          this.scaleDown(data.images.mother_right.height),
        );
      }
      if (data?.images?.mother_left.img) {
        doc.addImage(
          data.images.mother_left.img,
          'PNG',
          4.35,
          6.6,
          this.scaleDown(data.images.mother_left.width),
          this.scaleDown(data.images.mother_left.height),
        );
      }

      this.pdfTextPrint(doc, 'CAP BAYI & IBU', 2, 'left', 0.77);
      doc.setFont('Helvetica', 'normal');
      this.pdfTextPrint(doc, 'Kaki Kanan Bayi', 2.5, 'left', 1.8);
      this.pdfTextPrint(doc, 'Kaki Kiri Bayi', 2.5, 'left', 5.3);
      this.pdfTextPrint(doc, 'Ibu Jari Kanan Ibu', 6.4, 'left', 1.8);
      this.pdfTextPrint(doc, 'Ibu Jari Kiri Ibu', 6.4, 'left', 5.3);
      doc.setLineWidth(0.00000001);
      doc.rect(0.68, 2.2, 6.9, 7.7);
      doc.line(0.68, 6.1, 7.58, 6.1);
      doc.line(4.12, 2.2, 4.12, 9.89);

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Head
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
          'in',
        );
      }

      return doc;
    },
    scaleDown(value) {
      return value * 0.0039;
    },
  },
};
