<template>
  <v-row class="mb-6">
    <v-col cols="6">
      <v-row no-gutters align="baseline" class="grid-display">
        <label>Tekanan Darah</label>
        <div class="d-flex">
          <v-text-field
            dense
            placeholder="Sistolik"
            :readonly="isEmr"
            :rules="[rangeRules(sistol, 'sistol')]"
            v-model="sistol"
            id="sistol"
            type="number"
          />
          <span class="title mx-4 grey--text">/</span>
          <v-text-field
            dense
            :rules="[rangeRules(diastol, 'diastol')]"
            placeholder="Diastolik"
            type="number"
            :readonly="isEmr"
            suffix="mmhg"
            v-model="diastol"
            id="diastol"
          />
        </div>

        <label>Nadi</label>
        <v-text-field
          dense
          placeholder="Nadi"
          type="number"
          :readonly="isEmr"
          suffix="x/menit"
          v-model="pulse"
          :rules="[rangeRules(pulse, 'heart-rate')]"
          id="pulse"
        />

        <label>Berat Badan</label>
        <v-text-field
          dense
          placeholder="Berat Badan"
          suffix="kg"
          :readonly="isEmr"
          type="number"
          v-model="weight"
          id="weight"
        />

        <label>Tinggi Badan</label>
        <v-text-field
          dense
          placeholder="Tinggi Badan"
          type="number"
          suffix="cm"
          :readonly="isEmr"
          :rules="[rangeRules(height, 'height')]"
          v-model="height"
          id="height"
        />
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row no-gutters align="baseline" class="grid-display">
        <label>Lingkar Perut</label>
        <v-text-field
          dense
          placeholder="Lingkar perut"
          type="number"
          :readonly="isEmr"
          suffix="cm"
          v-model="bellyCircumference"
          id="lingkar-perut"
        />
        <label>RR</label>
        <v-text-field
          dense
          placeholder="RR"
          :rules="[rangeRules(respirationRate, 'rr')]"
          suffix="x/menit"
          :readonly="isEmr"
          type="number"
          v-model="respirationRate"
          id="respiration"
        />
        <label>Saturasi O<sub>2</sub></label>
        <v-text-field
          dense
          type="number"
          placeholder="Saturasi 02"
          :readonly="isEmr"
          suffix="%"
          v-model="saturation"
          id="saturation"
        />
        <label>Suhu</label>
        <v-text-field
          dense
          placeholder="Suhu Tubuh"
          :rules="[rangeRules(temperature, 'temperature')]"
          :readonly="isEmr"
          suffix="°C"
          v-model="temperature"
          type="number"
          id="temperature"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers('ObjectiveData');
const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);

export default {
  name: 'ObjectiveData',
  computed: {
    ...mapIGDAssessmentGetters(['getIsEmr']),
    ...mapGetters([
      'getSistol',
      'getDiastol',
      'getPulse',
      'getWeight',
      'getHeight',
      'getBellyCircumference',
      'getRespirationRate',
      'getSaturation',
      'getTemperature',
    ]),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    sistol: {
      get() {
        return this.getSistol;
      },
      set(value) {
        this.setSistol(value);
      },
    },
    diastol: {
      get() {
        return this.getDiastol;
      },
      set(value) {
        this.setDiastol(value);
      },
    },
    weight: {
      get() {
        return this.getWeight;
      },
      set(value) {
        this.setWeight(value);
      },
    },
    height: {
      get() {
        return this.getHeight;
      },
      set(value) {
        this.setHeight(value);
      },
    },
    bellyCircumference: {
      get() {
        return this.getBellyCircumference;
      },
      set(value) {
        this.setBellyCircumference(value);
      },
    },
    respirationRate: {
      get() {
        return this.getRespirationRate;
      },
      set(value) {
        this.setRespirationRate(value);
      },
    },
    saturation: {
      get() {
        return this.getSaturation;
      },
      set(value) {
        this.setSaturation(value);
      },
    },
    temperature: {
      get() {
        return this.getTemperature;
      },
      set(value) {
        this.setTemperature(value);
      },
    },
    pulse: {
      get() {
        return this.getPulse;
      },
      set(value) {
        this.setPulse(value);
      },
    },
  },
  beforeDestroy() {
    this.clearObjectiveState();
  },
  methods: {
    ...mapMutations([
      'clearObjectiveState',
      'setSistol',
      'setDiastol',
      'setPulse',
      'setWeight',
      'setHeight',
      'setBellyCircumference',
      'setRespirationRate',
      'setSaturation',
      'setTemperature',
    ]),
    rangeRules(value, type) {
      switch (type) {
        case 'sistol':
          if (value < 40 || value > 250) {
            return 'Angka tekanan darah tidak valid';
          } else {
            return true;
          }
        case 'diastol':
          if (value < 30 || value > 180) {
            return 'Angka tekanan darah tidak valid';
          } else {
            return true;
          }
        case 'heart-rate':
          if (value < 30 || value > 160) {
            return 'Angka nadi tidak valid';
          } else {
            return true;
          }
        case 'rr':
          if (value < 5 || value > 70) {
            return 'Angka RR tidak valid';
          } else {
            return true;
          }
        case 'temperature':
          if ((value < 20 || value > 45) && value !== '') {
            return 'Angka suhu tidak valid';
          } else {
            return true;
          }
        case 'height':
          if (value <= 1) {
            return 'Angka tinggi badan tidak valid';
          } else {
            return true;
          }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$standard-font-size: 0.9vw;

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.grid-display {
  @include grid-display(10vw minmax(16vw, 26vw), true);
  column-gap: 1vw;
}
</style>
