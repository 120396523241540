<template>
  <v-row no-gutters class="d-flex flex-column">
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="font-weight-bold text-left mr-auto">PENDAPATAN</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Januari</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Februari</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Maret</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Januari</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Februari</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="font-weight-bold">2021 Maret</label>
      </v-col>
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto px-3">Pendapatan JKN</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">100.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex subtotalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto"
        >JUMLAH PENDAPATAN</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex" style="height: 3vh;">
      <label class="font-weight-bold text-left mr-auto"
        >BEBAN POKOK PENDAPATAN</label
      >
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto px-3">Pendapatan JKN</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex subtotalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto"
        >JUMLAH BEBAN POKOK PENDAPATAN</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex totalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto">LABA KOTOR</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex" style="height: 3vh;">
      <label class="font-weight-bold text-left mr-auto">BEBAN USAHA</label>
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto px-3">Pendapatan JKN</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>
    <div class="d-flex subtotalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto"
        >JUMLAH BEBAN USAHA</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex totalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto">LABA USAHA</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto px-3">Pendapatan Lain-lain</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>
    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto px-3">Beban Lain-lain</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex totalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto"
        >LABA SEBELUM PAJAK</label
      >
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex pr-2" style="height: 3vh;">
      <label class="text-left mr-auto px-3">Beban Pajak</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>

    <div class="d-flex totalComp-style px-2">
      <label class="font-weight-bold text-left mr-auto">LABA BERSIH</label>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
      <v-col cols="2" class="pa-0 column-style">
        <label class="text-right">10.000.000,00</label>
      </v-col>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'Standard',
};
</script>

<style lang="scss" scoped>
.column-style {
  max-width: 12%;
  text-align: right;
}
.subtotalComp-style {
  background-color: #deeef9;
  height: 5vh;
  align-items: center;
  margin: 5px 0;
}

.totalComp-style {
  background-color: #addaf9;
  height: 5vh;
  align-items: center;
  margin: 5px 0;
}
</style>
