<template>
  <v-card class="input-container">
    <v-card-title class="justify-center">
      <h3>Data Sosial Pasien</h3>
    </v-card-title>
    <v-card-text class="px-10 pb-1">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="pa-5 row-layout" no-gutters>
          <v-col cols="5" offset="1">
            <v-row>
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="name">No. RM</label>
              </v-col>
              <v-col class="text-left">
                <label class="text-left black--text">
                  {{ medicalRecord }}
                </label>
              </v-col>
            </v-row>
            <v-row align="baseline">
              <v-col
                cols="4"
                class="required black--text text-left font-weight-bold pr-0"
              >
                <label for="name">Nama Lengkap</label>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  placeholder="Masukkan Nama Pasien"
                  v-model="dataSocial.name"
                  :rules="rules.name"
                  :readonly="!isEdit"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="baseline">
              <v-col
                cols="4"
                class="required black--text text-left font-weight-bold"
              >
                <label for="status">Status Pasien</label>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  :readonly="!isEdit"
                  placeholder="Pilih Status"
                  :items="input.status"
                  v-model="dataSocial.nickname"
                  :rules="rules.status"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="4"
                class="required black--text text-left font-weight-bold"
              >
                <label for="name">Jenis Kelamin</label>
              </v-col>
              <v-col>
                <v-radio-group
                  v-model="dataSocial.gender"
                  :readonly="!isEdit"
                  row
                  class="mt-0 pt-0"
                >
                  <v-radio label="Laki-laki" :value="true"></v-radio>
                  <v-radio label="Perempuan" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col
                cols="4"
                class="required black--text text-left font-weight-bold font-weight-bold"
              >
                <label for="name">Tempat Lahir</label>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  :readonly="!isEdit"
                  placeholder="Masukkan Tempat Lahir"
                  class="mt-0"
                  v-model="dataSocial.birth.place"
                  :rules="rules.birthPlace"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col
                cols="4"
                class="required black--text text-left font-weight-bold"
              >
                <label for="status">Tanggal Lahir</label>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  @input="formatDate(dataSocial.birth.date)"
                  v-model="dataSocial.birth.date"
                  :readonly="!isEdit"
                  placeholder="dd/mm/yyyy"
                  class="mt-0 pt-0"
                  maxlength="10"
                  :rules="rules.birthDate"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="status">Usia</label>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="dataSocial.age"
                  readonly
                  class="mt-0 pt-0"
                  maxlength="10"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="4"
                class="required black--text text-left font-weight-bold"
              >
                <label for="name">Alamat</label>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  name="input-7-4"
                  placeholder="Masukkan Alamat"
                  v-model="dataSocial.address.text"
                  :rules="rules.address"
                  :readonly="!isEdit"
                  required
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="status">Provinsi</label>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="dataSocial.address.province"
                  :items="input.province"
                  :readonly="!isEdit"
                  @input="getCity"
                  dense
                  :clearable="isEdit"
                  item-text="name"
                  placeholder="Pilih Provinsi"
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="status">Kabupaten</label>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="dataSocial.address.city"
                  :items="input.city"
                  :clearable="isEdit"
                  :loading="isLoading"
                  :readonly="!isEdit"
                  @change="getDistrict"
                  dense
                  item-text="name"
                  placeholder="Pilih Kabupaten"
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="status">Kecamatan</label>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="dataSocial.address.district"
                  :items="input.district"
                  :loading="isLoading"
                  :readonly="!isEdit"
                  @change="getSubDistrictData"
                  dense
                  :clearable="isEdit"
                  item-text="name"
                  placeholder="Pilih Kecamatan"
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="status">Kelurahan</label>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="dataSocial.address.sub_district"
                  :items="input.sub_district"
                  :loading="isLoading"
                  :readonly="!isEdit"
                  dense
                  :clearable="isEdit"
                  item-text="name"
                  placeholder="Pilih Kelurahan"
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="status">No.Telepon</label>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="dataSocial.phone_number"
                  :readonly="!isEdit"
                  dense
                  type="number"
                  placeholder="Masukkan Nomor Telepon"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col
                cols="4"
                class="black--text text-left font-weight-bold pr-0"
              >
                <label for="status">Jenis Identitas</label>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  :readonly="!isEdit"
                  placeholder="Pilih Identitas"
                  :items="input.identityType"
                  v-model="dataSocial.identity.type"
                ></v-select>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="name">No. Identitas</label>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  :readonly="!isEdit"
                  placeholder="Masukkan Nomor Identitas"
                  v-model="dataSocial.identity.number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="5" class="ml-10">
            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="status">Agama</label>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  :readonly="!isEdit"
                  placeholder="Pilih Agama"
                  class="mt-0"
                  :items="input.religion"
                  v-model="dataSocial.religion"
                ></v-select>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col
                cols="4"
                class="black--text text-left font-weight-bold pr-0"
              >
                <label for="status">Golongan Darah</label>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  :readonly="!isEdit"
                  placeholder="Pilih Golongan Darah"
                  class="mt-0"
                  :items="input.bloodType"
                  v-model="dataSocial.blood_type"
                  required
                ></v-select>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="status">Pendidikan</label>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  :readonly="!isEdit"
                  placeholder="Pilih Pendidikan"
                  class="mt-0"
                  :items="input.education"
                  v-model="dataSocial.education"
                  required
                ></v-select>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="status">Pekerjaan</label>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  :readonly="!isEdit"
                  placeholder="Pilih Pekerjaan"
                  class="mt-0"
                  v-model="dataSocial.profession"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="status">Status Kawin</label>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  :readonly="!isEdit"
                  placeholder="Pilih Status Kawin"
                  class="mt-0"
                  :items="input.maritalStatus"
                  v-model="dataSocial.marital_status"
                  required
                ></v-select>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="name">Nama Ayah</label>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  :readonly="!isEdit"
                  placeholder="Masukkan Nama Ayah"
                  class="mt-0"
                  v-model="dataSocial.father"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="name">Nama Ibu</label>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  :readonly="!isEdit"
                  placeholder="Masukkan Nama Ibu"
                  class="mt-0"
                  v-model="dataSocial.mother"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="status">Etnis</label>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  auto-select-first
                  dense
                  placeholder="Pilih Etnis"
                  class="mt-0"
                  :items="input.ethnicity"
                  v-model="dataSocial.ethnicity"
                  :clearable="isEdit"
                  :readonly="!isEdit"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="name">Bahasa yang dikuasai</label>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  placeholder="Bahasa yang dikuasai"
                  class="mt-0"
                  v-model="dataSocial.language_mastering"
                  :readonly="!isEdit"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="name">Hambatan dalam Berkomunikasi</label>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  placeholder="Hambatan dalam Berkomunikasi"
                  class="mt-0"
                  v-model="dataSocial.communication_obstacles"
                  :items="input.communicationObstacles"
                  :readonly="!isEdit"
                  :clearable="isEdit"
                ></v-select>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="name">Penyandang Disabilitas</label>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  placeholder="Disabilitas"
                  class="mt-0"
                  v-model="dataSocial.disabilities"
                  :readonly="!isEdit"
                  :items="input.disabilities"
                  :clearable="isEdit"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="dataSocial.assurance.type === 'bpjs'" align="baseline">
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label>Nomor BPJS</label>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  :readonly="!isEdit"
                  placeholder="Masukkan Nomor BPJS"
                  class="mt-0"
                  v-model="dataSocial.assurance.number"
                  hide-details="false"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="black--text text-left font-weight-bold">
                <label for="name">PROLANIS</label>
              </v-col>
              <v-col cols="6">
                <v-radio-group
                  v-model="dataSocial.assurance.isProlanis"
                  :readonly="!isEdit"
                  row
                  class="mt-0 pt-0"
                  hide-details="false"
                >
                  <v-radio label="Ya" :value="true"></v-radio>
                  <v-radio label="Bukan" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row
              v-if="dataSocial.assurance.type === 'asuransi'"
              align="baseline"
            >
              <v-col
                cols="4"
                class="black--text text-left font-weight-bold pr-0"
              >
                <label>Nama Asuransi</label>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  placeholder="Pilih Nama Asuransi"
                  :readonly="!isEdit"
                  class="mt-0 pt-0"
                  :items="input.assurance"
                  v-model="dataSocial.assurance.name"
                  hide-details="false"
                ></v-select>
              </v-col>
              <v-col
                cols="4"
                class="black--text text-left font-weight-bold pr-0"
              >
                <label for="name">Nomor Polis Asuransi</label>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  placeholder="Masukkan Nomor Polis Asuransi"
                  class="mt-0"
                  :readonly="!isEdit"
                  v-model="dataSocial.assurance.number"
                  hide-details="false"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        color="primary"
        :disabled="isEdit"
        @click="isEdit = true"
      >
        Edit Data
      </v-btn>
      <v-btn depressed :disabled="!isEdit" @click="validate()" color="primary">
        Update Data
      </v-btn>
      <v-btn depressed @click="onCloseButtonClick()"> Kembali </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// mixins
import jwtMixin from '@/mixin/jwtMixin';
import Mapper from '@/mixin/MapperMixins/SocialData';
import alertMixin from '../../mixin/alertMixin';

// api
import {
  getSocialDataByRmNumber,
  putUpdateSocialData,
  getAssurance,
  getRegion,
  getCity,
  getDistrict,
  getSubDistrict,
} from '@/fetchApi/patient';

// utils
import Swal from 'sweetalert2';
const moment = require('moment-timezone');
// const _ = require('lodash');
export default {
  name: 'SocialData',
  mixins: [jwtMixin, Mapper, alertMixin],
  props: {
    medicalRecord: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      date: null,
      menu: false,
      isLoading: false,
      isEdit: false,
      valid: true,
      dataSocial: {
        assurance: {
          name: '',
          number: '',
          isProlanis: '',
          type: '',
        },
        age: '',
        name: '',
        nickname: '',
        gender: '',
        ethnicity: '',
        language_mastering: '',
        communication_obstacles: '',
        disabilities: 0,
        birth: {
          date: '',
          place: '',
        },
        address: {
          city: '',
          district: '',
          province: '',
          sub_district: '',
          text: '',
        },
        identity: {
          type: '',
          number: '',
        },
        religion: '',
        blood_type: '',
        education: '',
        profession: '',
        marital_status: '',
        father: '',
        mother: '',
      },
      rules: {
        name: [v => !!v || 'Perlu mengisi nama'],
        status: [v => !!v || 'Perlu memilih status pasien'],
        birthPlace: [v => !!v || 'Perlu mengisi tempat lahir'],
        birthDate: [v => !!v || 'Perlu mengisi tanggal lahir'],
        address: [v => !!v || 'Perlu mengisi alamat'],
        guarantorType: [v => !!v || 'Perlu memilih jenis jaminan'],
      },
      input: {
        status: [
          { text: 'Nyonya', value: 'nyonya' },
          { text: 'Tuan', value: 'tuan' },
          { text: 'Nona', value: 'nona' },
          { text: 'Saudara', value: 'saudara' },
          { text: 'Anak', value: 'anak' },
          { text: 'Bayi', value: 'bayi' },
        ],
        identityType: ['Tidak Ada Identitas', 'KTP', 'SIM', 'Passport'],
        religion: [
          { text: 'Islam', value: 'islam' },
          { text: 'Kristen', value: 'kristen' },
          { text: 'Budha', value: 'budha' },
          { text: 'Hindu', value: 'hindu' },
          { text: 'Katolik', value: 'katolik' },
        ],
        bloodType: [
          { text: 'A', value: 'a' },
          { text: 'AB', value: 'ab' },
          { text: 'B', value: 'b' },
          { text: 'O', value: 'o' },
        ],
        education: [
          { text: 'Tidak Sekolah', value: '-' },
          { text: 'TK', value: 'tk' },
          { text: 'SD', value: 'sd' },
          { text: 'SMP', value: 'smp' },
          { text: 'SMA', value: 'sma' },
          { text: 'Diploma I', value: 'diploma1' },
          { text: 'Diploma II', value: 'diploma2' },
          { text: 'Diploma III', value: 'diploma3' },
          { text: 'Diploma IV', value: 'diploma4' },
          { text: 'Sarjana', value: 'sarjana' },
          { text: 'Magister', value: 'magister' },
          { text: 'Doktor', value: 'doktor' },
        ],
        maritalStatus: [
          { text: 'Belum Kawin', value: 'belum kawin' },
          { text: 'Kawin', value: 'kawin' },
          { text: 'Duda', value: 'duda' },
          { text: 'Janda', value: 'janda' },
        ],
        ethnicity: [
          { text: 'Suku Bali', value: 'bali' },
          { text: 'Suku Banjar', value: 'banjar' },
          { text: 'Suku Batak', value: 'batak' },
          { text: 'Suku Betawi', value: 'betawi' },
          { text: 'Suku Bugis', value: 'bugis' },
          { text: 'Suku Cina, RRC, Taiwan', value: 'cina' },
          { text: 'Suku Cirebon', value: 'cirebon' },
          { text: 'Suku Dayak', value: 'dayak' },
          { text: 'Suku Gorontalo', value: 'Gorontalo' },
          { text: 'Suku Jawa', value: 'jawa' },
          { text: 'Suku Madura', value: 'madura' },
          { text: 'Suku Makassar', value: 'makassar' },
          { text: 'Suku Melayu', value: 'melayu' },
          { text: 'Suku Minahasa', value: 'minahasa' },
          { text: 'Suku Minangkabau', value: 'minangkabau' },
          { text: 'Suku Nias', value: 'nias' },
          { text: 'Suku Sasak', value: 'sasak' },
          { text: 'Suku Asal Aceh', value: 'aceh' },
          { text: 'Suku Asal Banten', value: 'banten' },
          { text: 'Suku Asal Kalimantan lainnya', value: 'kalimantan' },
          { text: 'Suku Asal Maluku', value: 'maluku' },
          { text: 'Suku Asal Nusa Tenggara Timur', value: 'ntt' },
          { text: 'Suku Asal Papua', value: 'papua' },
          { text: 'Suku Asal Sulawesi lainnya', value: 'sulawesi' },
          { text: 'Suku Asal Sumatera lainnya', value: 'sumatera' },
          { text: 'Suku Luar Negeri', value: 'ln' },
          { text: 'Suku Asal Jambi', value: 'jambi' },
          { text: 'Suku Asal Lampung', value: 'lampung' },
          { text: 'Suku Asal Sumatera Selatan', value: 'sumatra selatan' },
          { text: 'Suku Nusa Tenggara Barat lainnya', value: 'ntb' },
          { text: 'Suku Sunda', value: 'sunda' },
        ],
        communicationObstacles: [
          { text: 'Tidak Ada', value: '' },
          { text: 'Bahasa', value: 'bahasa' },
          { text: 'Lingkungan', value: 'lingkungan' },
          { text: 'Fisik', value: 'fisik' },
          { text: 'Psikologi', value: 'psikologi' },
        ],
        disabilities: [
          { text: 'Tidak ada', value: 0 },
          { text: 'Fisik', value: 1 },
          { text: 'Mental', value: 2 },
          { text: 'Fisik dan Mental', value: 3 },
        ],
        province: [],
        city: [],
        district: [],
        sub_district: [],
        assurance: [],
      },
    };
  },
  watch: {
    'dataSocial.birth.date'(val) {
      if (val.length > 10) {
        this.dataSocial.birth.date = this.dataSocial.birth.date.slice(0, 10);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        this.getSocialData();
        this.getAllInsurance();
      });
    });
    this.getProvince();
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    async getAllInsurance() {
      try {
        const response = await getAssurance();
        this.input.assurance = response.data.data.map(item => item.name);
      } catch {
        this.input.assurance = [];
      }
    },
    insertString(originalString, newString, index) {
      return (
        originalString.substr(0, index) +
        newString +
        originalString.substr(index)
      );
    },
    formatDate(dateString) {
      if (dateString.length > 10) {
        this.dataSocial.birth.date = dateString.slice(0, 10);
        return;
      }
      let date = dateString.replace(/\D/g, '').substr(0, 10);
      let size = date.length;

      if (size > 4) date = this.insertString(date, '/', 4);

      if (size > 2) date = this.insertString(date, '/', 2);

      // if (size > 8) date = date.slice(0, 8);
      this.dataSocial.birth.date = date;

      if (date.length === 10) {
        date = moment(date, 'DD/MM/YYYY').format();
        this.dataSocial.age = moment().diff(date, 'years');
      }
    },
    async getProvince() {
      try {
        const response = await getRegion();
        this.input.province = response.data.data.map(item => {
          return {
            id: item._id.toString(),
            name: item.name,
          };
        });
      } catch {
        this.input.province = [];
      }
    },
    async getCity() {
      try {
        this.isLoading = true;

        const response = await getCity(this.dataSocial.address.province.id);
        this.input.city = response.data.data.map(city => {
          return {
            id: city._id.toString(),
            name: city.name,
          };
        });
      } catch {
        this.input.city = [];
      } finally {
        this.isLoading = false;
      }
    },
    async getDistrict() {
      try {
        this.isLoading = true;
        const response = await getDistrict(this.dataSocial.address.city.id);
        this.input.district = response.data.data.map(district => {
          return {
            id: district._id.toString(),
            name: district.name,
          };
        });
      } catch {
        this.input.district = [];
      } finally {
        this.isLoading = false;
      }
    },
    async getSubDistrictData() {
      try {
        this.isLoading = true;
        const response = await getSubDistrict(
          this.dataSocial.address.district.id,
        );
        this.input.sub_district = response.data.data.map(subdistrict => {
          return {
            id: subdistrict._id.toString(),
            name: subdistrict.name,
          };
        });
      } catch {
        this.input.sub_district = [];
      } finally {
        this.isLoading = false;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.updatePatient();
      }
    },
    async updatePatient() {
      try {
        const payload = this.mapUpdatePatientPayload(
          this.medicalRecord,
          this.dataSocial,
        );

        await putUpdateSocialData(this.medicalRecord, payload);
        Swal.fire('Data Pasien berhasil diubah', '', 'success');
        this.getSocialData();
        this.isEdit = false;
      } catch {
        Swal.fire('Data Pasien gagal diubah', '', 'error');
      }
    },
    async getSocialData() {
      try {
        const response = await getSocialDataByRmNumber(this.medicalRecord);
        const { data } = response.data;
        this.dataSocial = this.mapSocialData(data);
        this.getCity();
        this.getDistrict();
        this.getSubDistrictData();
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
}
::v-deep .v-dialog.v-dialog--active {
  width: 900px;
}
.row-scroll {
  height: 75vh;
}
.input-container {
  font-size: 0.9rem;
  font-family: 'Roboto';
  position: relative;
  .row-layout {
    height: 75vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 10px;
      background-color: #ededed;
    }
    &::-webkit-scrollbar-thumb {
      width: 8px;
      border-radius: 10px;
      background-color: #9e9e9e;
    }
  }
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}
.required:after {
  content: ' *';
  color: red;
}
</style>
