<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Persetujuan Jurnal Transaksi</h3>
    </v-card-title>

    <v-row no-gutters class="py-3">
      <v-col cols="3">
        <div class="detail">
          <label class="font-weight-bold body-2 text-right">Kode Jurnal</label>
          <label class="body-2">{{ detail.journalNumber }}</label>

          <label class="font-weight-bold body-2 text-right">No. Bukti</label>
          <label class="body-2">{{ detail.invoiceNumber }}</label>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="detail">
          <label class="font-weight-bold body-2 text-right"
            >Tipe Transaksi</label
          >
          <label class="body-2">{{ detail.transactionType }}</label>

          <label class="font-weight-bold body-2 text-right"
            >Tanggal Transaksi</label
          >
          <label class="body-2">{{ detail.displayDate }}</label>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="detail">
          <label class="font-weight-bold body-2 text-right">Tipe Jurnal</label>
          <label class="body-2">{{ detail.journalType }}</label>

          <label class="font-weight-bold body-2 text-right">Uraian</label>
          <label>{{ detail.backdateReason }}</label>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="detail">
          <label class="font-weight-bold body-2 text-right">Unit</label>
          <label class="body-2">{{ detail.unit }}</label>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="pa-5" style="height: 50vh; overflow: auto;">
      <v-col cols="7" class="mx-5">
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          :items-per-page="table.items.length"
          hide-default-footer
        >
          <template v-slot:[`item`]="{ item }">
            <tr>
              <td class="text-left">{{ table.items.indexOf(item) + 1 }}</td>
              <td class="text-left">{{ item.accountCode }}</td>
              <td class="text-left">{{ item.accountName }}</td>
              <td class="text-right">{{ formattedCurrency(item.debit) }}</td>
              <td class="text-right">{{ formattedCurrency(item.credit) }}</td>
            </tr>
          </template>
          <template v-slot:[`body.append`]="{}">
            <tr style="background-color: #F0F9FF;">
              <td colspan="3">
                <label class="font-weight-bold">TOTAL</label>
              </td>
              <td colspan="1" class="text-right">
                <label>{{ currency(totalDebit) }}</label>
              </td>
              <td colspan="1" class="text-right">
                <label>{{ currency(totalCredit) }}</label>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="4">
        <v-card
          width="330"
          height="40"
          color="#EBF3F9"
          class="pa-2"
          flat
          outlined
        >
          <v-icon color="primary">mdi-information</v-icon>
          <label class="body-2"
            >Menampilkan catatan terkait jurnal backdate</label
          >
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters class="px-5">
      <v-col cols="6" class="text-left mx-5">
        <v-row no-gutters>
          <v-col
            v-for="(arrs, fileIndex) in document"
            cols="5"
            style="cursor: pointer;"
            @click="openFile(arrs)"
            class="pb-2 pr-2"
            :key="'file-' + fileIndex"
          >
            <v-card outlined class="pa-1">
              <v-row no-gutters align="center">
                <v-col cols="2" class="text-center">
                  <v-icon
                    v-if="arrs.type.search(/.(jpeg|jpg|png)/i) !== -1"
                    color="primary"
                    small
                    >mdi-file-image</v-icon
                  >
                  <v-icon
                    v-else-if="arrs.type.search(/.pdf/i) !== -1"
                    color="primary"
                    small
                    >mdi-file-pdf-box</v-icon
                  >
                  <v-icon v-else class="icon">mdi-file-code</v-icon>
                </v-col>
                <v-col cols="10" class="text-left">
                  <label style="cursor: pointer;" class="caption">{{
                    arrs.name
                  }}</label>
                </v-col>
                <!-- <v-col cols="1">
                    <v-btn icon @click="removeFiles(fileIndex)" x-small
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </v-col> -->
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <label class="font-weight-bold body-2 pr-5">Riwayat Aktivitas</label>
        <label class="body-2 grey--text"
          >Printer menambahkan Jurnal pada {{ detail.created_at.date }}
          {{ detail.created_at.time }} WIB
        </label>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="red" class="text-none" @click="agreement('reject')"
        >Tolak</v-btn
      >
      <v-btn
        color="primary"
        depressed
        class="text-none"
        @click="agreement('agree')"
        >Setujui</v-btn
      >
    </v-card-actions>

    <v-dialog v-model="dialog" width="512">
      <v-card v-if="dialog">
        <v-card-title class="justify-center py-5">
          <h3>Penolakan Jurnal</h3>
        </v-card-title>
        <v-divider />
        <v-row no-gutters style="display: grid;" class="text-left pa-5">
          <label class="font-weight-bold pb-3">Alasan Penolakan:</label>
          <v-textarea
            placeholder="Tuliskan Alasan"
            outlined
            dense
            rows="5"
            required
            hide-details="false"
            no-resize
            v-model="reason"
          ></v-textarea>
          <p class="red--text caption">*required</p>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            width="112"
            outlined
            color="primary"
            class="text-none"
            @click="dialog = false"
            >Kembali</v-btn
          >
          <v-btn
            width="112"
            color="primary"
            class="text-none"
            depressed
            :disabled="reason === ''"
            @click="saveRejected()"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import moment from 'moment-timezone';
import formatMixin from '@/mixin/formatMixin';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');

export default {
  name: 'JournalAgreement',
  mixins: [formatMixin, alertMixin],
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Kode Akun', value: 'accountCode' },
          { text: 'Nama Akun', value: 'accountName' },
          { text: 'Debit', value: 'debit' },
          { text: 'Kredit', value: 'credit' },
        ],
        items: [],
      },
      document: [],
      reason: '',
      dialog: false,
      detail: '',
    };
  },
  mounted() {
    this.getJournalById();
  },
  computed: {
    totalDebit() {
      return this.table.items.reduce((a, { debit }) => a + debit, 0);
    },
    totalCredit() {
      return this.table.items.reduce((a, { credit }) => a + credit, 0);
    },
  },
  methods: {
    openFile(item) {
      window.open(Constant.apiUrl.concat(`/${item.path}`));
    },
    agreement(type) {
      if (type === 'agree') {
        Swal.fire({
          title: 'Peringatan',
          text: 'Apakah Anda yakin ingin menyetujui Jurnal Transaksi ini? ',
          icon: 'warning',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yakin',
          cancelButtonText: 'Batal',
          confirmButtonColor: '#3498DB',
          reverseButtons: true,
        }).then(result => {
          if (result.isConfirmed) {
            // const formData = new FormData();

            // this.document.forEach(arr => {
            //   formData.append('new_files', arr.path);
            // });
            // formData.append('transaction_type', this.detail.transactionType);
            // formData.append('journal_type', this.detail.journalType);
            // formData.append('unit', this.detail.unit);
            // formData.append('proof_code', this.detail.invoiceNumber);
            // formData.append('description', this.detail.description);
            // formData.append('date', this.detail.date);
            // formData.append('note', this.detail.note);
            // formData.append('transaction_value', this.totalDebit);
            // formData.append('backdate_reason', this.detail.backdateReason);
            // this.table.items.forEach((arr, idx) => {
            //   formData.append(`entries[${idx}][id_accountBalance]`, arr.id);
            //   formData.append(`entries[${idx}][debit]`, arr.debit);
            //   formData.append(`entries[${idx}][type]`, arr.type);
            //   formData.append(`entries[${idx}][name]`, arr.name);
            //   formData.append(`entries[${idx}][credit]`, arr.credit);
            // });
            // formData.append('status[posted]', true);
            // formData.append('status[journal]', 'Belum Dikoreksi');
            // formData.append(
            //   'timestamps[created_by]',
            //   this.$store.getters.userLoggedIn.id,
            // );
            const payload = {
              transaction_type: this.detail.transactionType,
              journal_type: this.detail.journalType,
              unit: this.detail.unit,
              proof_code: this.detail.invoiceNumber,
              description: this.detail.description,
              date: this.detail.date,
              note: this.detail.note,
              transaction_value: this.totalDebit,
              backdate_reason: this.detail.backdateReason,
              status: {
                posted: true,
                journal: 'Belum Dikoreksi',
              },
              entries: this.table.items.map(item => {
                return {
                  id_accountBalance: item.id,
                  debit: item.debit,
                  type: item.type,
                  name: item.name,
                  credit: item.credit,
                };
              }),
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
            };

            axios
              .put(
                Constant.apiUrl.concat(`/finance/journal/${this.id}`),
                payload,
              )
              .then(() => {
                Swal.fire({
                  title: 'Persetujuan Jurnal Transaksi Berhasil',
                  icon: 'success',
                  confirmButtonText: 'Selesai',
                });
                this.$emit('close-dialog');
              })
              .catch(err => {
                this.showErrorAxios(err);
              });
          }
        });
      } else {
        this.dialog = true;
      }
    },
    saveRejected() {
      // const formData = new FormData();

      // this.document.forEach(arr => {
      //   formData.append('new_files', arr.path);
      // });
      // formData.append('transaction_type', this.detail.transactionType);
      // formData.append('journal_type', this.detail.journalType);
      // formData.append('unit', this.detail.unit);
      // formData.append('proof_code', this.detail.invoiceNumber);
      // formData.append('description', this.detail.description);
      // formData.append('date', this.detail.date);
      // formData.append('note', this.reason);
      // formData.append('transaction_value', this.totalDebit);
      // formData.append('backdate_reason', this.detail.backdateReason);
      // this.table.items.forEach((arr, idx) => {
      //   formData.append(
      //     `entries[${idx}][id_accountBalance]`,
      //     arr.id_accountBalance,
      //   );
      //   formData.append(`entries[${idx}][debit]`, arr.debit);
      //   formData.append(`entries[${idx}][type]`, arr.type);
      //   formData.append(`entries[${idx}][name]`, arr.name);
      //   formData.append(`entries[${idx}][credit]`, arr.credit);
      // });
      // formData.append('status[posted]', false);
      // formData.append('status[journal]', 'Ditolak');
      // formData.append(
      //   'timestamps[created_by]',
      //   this.$store.getters.userLoggedIn.id,
      // );

      const payload = {
        transaction_type: this.detail.transactionType,
        journal_type: this.detail.journalType,
        unit: this.detail.unit,
        proof_code: this.detail.invoiceNumber,
        description: this.detail.description,
        date: this.detail.date,
        note: this.reason,
        transaction_value: this.totalDebit,
        backdate_reason: this.detail.backdateReason,
        status: {
          posted: false,
          journal: 'Ditolak',
        },
        entries: this.table.items.map(item => {
          return {
            id_accountBalance: item.id_accountBalance,
            debit: item.debit,
            type: item.type,
            name: item.name,
            credit: item.credit,
          };
        }),
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.id,
        },
      };

      axios
        .put(Constant.apiUrl.concat(`/finance/journal/${this.id}`), payload)
        .then(() => {
          Swal.fire({
            title: 'Penolakan Jurnal Transaksi Berhasil',
            icon: 'success',
            confirmButtonText: 'Selesai',
          });
          this.$emit('close-dialog');
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
    getJournalById() {
      axios
        .get(Constant.apiUrl.concat(`/finance/journal/${this.id}`))
        .then(response => {
          const [data] = response.data.data;
          this.detail = {
            journalNumber: data.journal_code || '',
            displayDate: moment(data.date).format('DD/MM/YYYY'),
            date: data.date,
            transactionType: data.transaction_type || '',
            description: data.description || '',
            unit: data.unit || '',
            invoiceNumber: data.proof_code || '',
            valueTransaction: data.transaction_value || '',
            created_at: {
              date: moment(data.timestamps.created_at).format('DD/MM/YY'),
              time: moment(data.timestamps.created_at).format('HH:mm'),
            },
            backdateReason: data.backdate_reason || '',
            journalType: data.journal_type || '',
            note: data.note || '',
          };

          this.table.items = data.entries.map(arr => {
            return {
              id: arr.id_accountBalance,
              accountCode: arr.accountbalances?.account_code,
              accountName: arr.accountbalances?.name,
              debit: arr.debit,
              credit: arr.credit,
              name: arr.name,
              type: arr.type,
            };
          });

          this.document = data.files.length
            ? data.files.map(arr => {
                return {
                  name: arr.original_file_name,
                  path: arr.path,
                  type: arr.original_file_name.split('.')[
                    arr.original_file_name.split('.').length - 1
                  ],
                };
              })
            : [];
        });
    },
  },
};
</script>

<style>
.detail {
  display: grid;
  grid-template-columns: 10vw 10vw;
  text-align: left;
  justify-content: end;
  grid-row-gap: 3vh;
  grid-column-gap: 2vw;
}
</style>
