<template>
  <v-row
    class="mb-6"
    style="display:block
  "
  >
    <v-row v-for="(item, index) in nursingProblem" :key="'problem-' + index">
      <v-col>
        <div class="grid-display">
          <label>Masalah Keperawatan</label>
          <div class="d-flex">
            <v-combobox
              :clearable="!isEmr"
              :readonly="isEmr"
              v-model="item.content"
              :items="resource.nursingProblem"
              @change="onChangeNursingProblemState(index)"
              placeholder="Masalah Keperawatan"
              append-icon="mdi-chevron-down"
            />
            <v-btn
              @click="deleteNursingProblem(index)"
              :disabled="nursingProblem.length < 2 || isEmr"
              fab
              text
              small
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </div>
        </div>
      </v-col>
      <v-col>
        <div
          class="grid-display"
          v-for="(plan, idx) in nursingProblem[index].treatmentPlan"
          :key="'plan-' + idx"
        >
          <label>Rencana Tindakan</label>
          <div class="d-flex">
            <v-combobox
              :clearable="!isEmr"
              :readonly="isEmr"
              v-model="plan.content"
              :items="
                item.content
                  ? filterResource(
                      resource.treatmentPlan[item.content.value || 0],
                      index,
                    )
                  : []
              "
              placeholder="Masalah Keperawatan"
              append-icon="mdi-chevron-down"
            />
            <v-btn text fab small
              ><v-icon
                color="grey"
                :disabled="item.treatmentPlan.length < 2 || isEmr"
                @click="deleteTreatmentPlan({ index, idx })"
                >mdi-delete</v-icon
              ></v-btn
            >
            <v-btn
              :disabled="!item.content || isEmr"
              text
              fab
              small
              @click="addTreatmentPlan(index)"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-btn
          @click="addNursingProblem"
          v-if="!isEmr"
          color="white"
          depressed
          rounded
          class="text-capitalize"
          ><span class="primary--text"
            ><v-icon color="primary" class="mr-3">mdi-plus</v-icon>Tambah
            item</span
          ></v-btn
        >
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);
const { mapGetters, mapMutations } = createNamespacedHelpers('NursingProblem');

export default {
  name: 'NursingProblem',
  data() {
    return {
      resource: {
        nursingProblem: [
          { text: 'Nyeri Akut', value: 0 },
          { text: 'Hipertermi', value: 1 },
          { text: 'Hipotermi', value: 2 },
          { text: 'Ketidakefektifan Bersihan Jalan Nafas', value: 3 },
          { text: 'Ketidakefektifan Pola Nafas', value: 4 },
          { text: 'Gangguan Pertukaran Gas', value: 5 },
          { text: 'Ketidakseimbangan Nutrisi', value: 6 },
          { text: 'Ansietas', value: 7 },
          { text: 'Penurusan Curah Jantung', value: 8 },
          { text: 'Risiko Infeksi', value: 9 },
          { text: 'Diare', value: 10 },
          { text: 'Konstipasi', value: 11 },
          { text: 'Hambatan Eliminasi Urin', value: 12 },
          { text: 'Hiperbilirubinemia Neonatal', value: 13 },
          { text: 'Intoleran Aktivitas', value: 14 },
          { text: 'Kesiapan Proses Kehamilan-Melahirkan', value: 15 },
          { text: 'Gangguan Pola Tidur', value: 16 },
          { text: 'Defisien Pengetahuan', value: 17 },
          { text: 'Hambatan Mobilitas Fisik', value: 18 },
          { text: 'Kerusakan Integritas Jaringan', value: 19 },
          { text: 'Kelebihan Volume Cairan', value: 20 },
          { text: 'Risiko Ketidakstabilan Kadar Glukosa Darah', value: 21 },
          { text: 'Mual', value: 22 },
          { text: 'Risiko Ketidakseimbangan Volume Cairan', value: 23 },
          { text: 'Risiko Pendarahan', value: 24 },
        ],
        treatmentPlan: [
          [
            {
              text: 'Melakukan pengkajian nyeri secara komprehensif',
              value: 0,
            },
            { text: 'Mengajarkan teknik non farmakologi', value: 1 },
            {
              text: 'Observasi reaksi non verbal dari ketidaknyamanan',
              value: 2,
            },
            { text: 'Melakukan kolaborasi medis', value: 2 },
            { text: 'Memberikan analgetik untuk mengurangi nyeri', value: 3 },
          ],
          [
            { text: 'Monitoring suhu dan tanda vital lainnya', value: 0 },
            { text: 'Monitoring warna dan suhu kulit', value: 1 },
            { text: 'Monitoring tekanan darah, nadi, dan RR', value: 2 },
            { text: 'Monitoring intake dan output', value: 3 },
            { text: 'Berikan pasien selimut atau pakaian ringan', value: 4 },
            { text: 'Memberikan antipiretik', value: 5 },
            {
              text: 'Memberikan kompres pada bagian aksilla dan lipat paha',
              value: 6,
            },
          ],
          [
            { text: 'Monitoring suhu', value: 0 },
            { text: 'Monitoring suhu, warna, dan kelembapan kulit', value: 1 },
            {
              text: 'Menempatkan pasien pada posisi supine/terlentang',
              value: 2,
            },
            {
              text:
                'Menganjurkan pasien mengkonsumsi cairan hangat dan tinggi karbohidrat tanpa alkohol atau kafein',
              value: 3,
            },
            {
              text:
                'Memberikan pemanas pasif (selimut, pneutup kepala dan pakaian hangat)',
              value: 4,
            },
            {
              text:
                'Monitoring gejala-gejala hipotermia ringan (takipnea, menggigil, hipertensi, dan diuresis)',
              value: 5,
            },
          ],
          [
            {
              text:
                'Membuka jalan nafas menggunakan teknik chin lift atau jaw thrust bila perlu',
              value: 0,
            },
            {
              text: 'Evaluasi adanya nyeri dada (intensitas, lokasi, durasi)',
              value: 1,
            },
            {
              text: 'Bantu mengeluarkan secret dengan batuk atau suction',
              value: 2,
            },
            {
              text: 'Auskultasi suara nafas, catat adanya suara tambahan',
              value: 3,
            },
            { text: 'Monitoring respirasi dan status O2', value: 4 },
          ],
          [
            {
              text:
                'Membuka jalan nafas menggunakan teknik chin lift atau jaw thrust bila perlu',
              value: 0,
            },
            { text: 'Memposisikan pasien semifowler', value: 1 },
            { text: 'Monitoring respirasi dan status O2', value: 2 },
            {
              text: 'Bantu mengeluarkan secret dengan batuk atau suction',
              value: 3,
            },
          ],
          [
            {
              text:
                'Membuka jalan nafas menggunakan teknik chin lift atau jaw thrust bila perlu',
              value: 0,
            },
            { text: 'Memposisikan pasien semifowler', value: 1 },
            { text: 'Monitoring respirasi dan status O2', value: 2 },
            {
              text:
                'Monitoring rata-rata, kedalaman, irama, dan usaha respirasi',
              value: 3,
            },
            { text: 'Monitoring suara nafas, seperti mendengkur', value: 4 },
            {
              text:
                'Mencatat pergerakan dada (kesimetrisan, penggunaan otot tambahan, retraksi otot supraclavicular dan intercostal)',
              value: 5,
            },
            {
              text:
                'Monitoring pola nafas: bradipnea, takipnea, kussmaul, hiperventilasi',
              value: 6,
            },
            {
              text:
                'Auskultasi suara paru setelah tindakan untuk mengetahui hasilnya',
              value: 7,
            },
            {
              text: 'Mencatat onset, karakteristik dan durasi batuk',
              value: 8,
            },
          ],
          [
            { text: 'Mengkaji adanya alergi makanan', value: 0 },
            {
              text:
                'Kolaborasi dengan ahli gizi untuk menentukan jumlah kalori dan nutrisi yang dibutuhkan pasien',
              value: 1,
            },
            { text: 'Menganjurkan pasien untuk meningkatkan intake', value: 2 },
            { text: 'Monitoring adanya penurunan berat badan', value: 3 },
            {
              text:
                'Monitoring turgor kulit, kekeringan, rambut kusam, dan mudah patah',
              value: 4,
            },
            { text: 'Monitoring mual dan muntah', value: 5 },
          ],
          [
            {
              text:
                'Menjelaskan semua prosedur dan apa yang dirasakan selama prosedur',
              value: 0,
            },
            { text: 'Mengidentifikasikan tingkat kecemasan', value: 1 },
            {
              text: 'Menginstruksikan pasien untuk melakukan teknik relaksasi',
              value: 2,
            },
            {
              text:
                'Melakukan usapan pada punggung/leher dengan cara yang tepat',
              value: 3,
            },
            { text: 'Mendengarkan dengan penuh perhatian', value: 4 },
            {
              text:
                'Memberikan aktivitas pengganti yang bertujuan untuk mengurangi tekanan',
              value: 5,
            },
          ],
          [
            {
              text: 'Evaluasi adanya nyeri dada (intensitas, lokasi, durasi)',
              value: 0,
            },
            { text: 'Mencatat adanya disritmia jantung', value: 1 },
            {
              text: 'Mencatat status pernafasan yang menandakan gagal jantung',
              value: 2,
            },
            { text: 'Monitoring balance cairan', value: 3 },
            {
              text:
                'Monitoring tekanan darah, nadi, dan RR sebelum, selama dan setelah aktivitas',
              value: 4,
            },
            { text: 'Monitoring toleransi aktivitas pasien', value: 5 },
            { text: 'Monitoring intake output', value: 6 },
            {
              text: 'Kolaborasi medis untuk meningkatkan output urine',
              value: 7,
            },
            { text: 'Monitoring tanda dan gejala dari oedema', value: 8 },
          ],
          [
            { text: 'Monitoring adanya tanda-tanda infeksi', value: 0 },
            {
              text: 'Mempertahankan lingkungan aseptik selama pemasangan alat',
              value: 1,
            },
            { text: 'Tingkatkan intake nutrisi yang tepat', value: 2 },
            {
              text: 'Cuci tangan sebelum dan sesudah kontak dengan pasien',
              value: 3,
            },
            { text: 'Membatasi jumlah pengunjung', value: 4 },
            {
              text: 'Kolaborasi medis untuk pemberian antibiotik yang sesuai',
              value: 5,
            },
            { text: 'Anjurkan pasien untuk meminum antibiotik', value: 6 },
          ],
          [
            { text: 'Melakukan pengkajian riwayat diare', value: 0 },
            { text: 'Monitoring tanda dan gejala diare', value: 1 },
            {
              text:
                'Mengkaji warna, volume, frekuensi, dan konsitensi defekasi',
              value: 2,
            },
            { text: 'Monitoring turgor kulit atau tanda dehidrasi', value: 3 },
            {
              text:
                'Instrusikan untuk memberikan makan rendah serat, tinggi protein, dan tinggi kalori',
              value: 4,
            },
            {
              text: 'Kolaborasi medis untuk pemberian obat anti diare',
              value: 5,
            },
          ],
          [
            { text: 'Monitoring tanda dan gejala konstipasi', value: 0 },
            { text: 'Monitoring bunyi bising usus', value: 1 },
            {
              text: 'Mendorong pasien untuk meningkatkan intake cairan',
              value: 2,
            },
            { text: 'Instruksikan untuk diet tinggi serat', value: 3 },
            {
              text:
                'Instruksikan pasien atau keluarga untuk mencatat warna, volume, frekuensi, dan konsistensi feses',
              value: 4,
            },
          ],
          [
            {
              text:
                'Memantau eliminasi urine termasuk frekuensi, konsistensi, bau, volume, dan warna yang sesuai',
              value: 0,
            },
            { text: 'Pantau adanya tanda dan gejala retensi urine', value: 1 },
            { text: 'Mencatat eliminasi urine terakhir', value: 2 },
            {
              text: 'Anjurkan pasien/keluarga untuk mencatat output urine',
              value: 3,
            },
            { text: 'Anjurkan pasien untuk minum 8 gelas per hari', value: 4 },
            {
              text:
                'Kolaborasi medis jika terdapat tanda dan gejala infeksi saluran kemih',
              value: 5,
            },
          ],
          [
            {
              text:
                'Melakukan pengkajian riwayat maternal dan bayi mengenai adanya faktor risiko terjadinya hyperbilurubinemia (misalnya Rh atau kompatibilitas ABO, sepsis, prematur, malpresentasi)',
              value: 0,
            },
            { text: 'Observasi tanda-tanda (warna) kuning', value: 1 },
            {
              text:
                'Melakukan pemeriksaan kadar serum bilirubin sesuai kebutuhan atau permintaan dokter',
              value: 2,
            },
            {
              text:
                'Melakukan edukasi pada keluarga mengenai prosedur perawatan fototerapi',
              value: 3,
            },
            {
              text:
                'Observasi tanda-tanda dehidrasi (misalnya depresi fontanel, turgor, kulit buruk, kehilangan berat badan)',
              value: 4,
            },
            { text: 'Timbang berat badan setiap hari', value: 5 },
            { text: 'Merubah posisi bayi setiap 4 jam per protokol', value: 6 },
            { text: 'Bantu pemberian makan 8 kali per hari', value: 7 },
          ],
          [
            {
              text:
                'Menciptakan lingkungan yang aman untuk dapat melakukan pergerakan otot secara berkala sesuai dengan indikasi',
              value: 0,
            },
            {
              text:
                'Membantu melakukan ROM aktif/pasif untuk menghilangkan ketegangan otot',
              value: 1,
            },
            {
              text:
                'Membantu pasien dalam aktivitas sehari-hari yang teratur sesuai kebutuhan (ambulasi, berpindah, bergerak dan perawatan diri)',
              value: 2,
            },
            {
              text:
                'Menganjurkan aktivitas fisik (misalnya ambulasi, ADL) sesuai dengan kemampuan (energi) pasien',
              value: 3,
            },
            {
              text:
                'Monitoring respon oksigen pasien (tekanan darah, nadi, respirasi) saat perawatan maupun saat melakukan perawatan mandiri',
              value: 4,
            },
            {
              text:
                'Kolaborasi dengan (ahli) terapis fisik, okupasi dan terapis rekreasional dalam perencanaan dan pemantauan program aktivitas, jika diperlukan',
              value: 5,
            },
          ],
          [
            {
              text:
                'Mengajarkan ibu dan pasangan mengenai tanda-tanda persalinan',
              value: 0,
            },
            {
              text:
                'Eksplorasi mengenai rencana persalinan (misalnya, lingkungan persalinan, yang membantu ibu, yang akan hadir/menemani, pilihan makanan, dan rencana kepulangan)',
              value: 1,
            },
            {
              text:
                'Menginformasikan kapan harus datang ke rumah sakit dalam rangka persiapan menghadapin persalinan',
              value: 2,
            },
            {
              text:
                'Diskusikan keuntungan dan kerugian dalam hal menyusui dengan ASI dan (pemberian makan) dengan botol susu',
              value: 3,
            },
            {
              text:
                'Mengkaji ulang riwayat prenatal untuk adanya faktor-faktor pencetus terjadinya komplikasi',
              value: 4,
            },
            {
              text:
                'Mencatat obat-obatan yang dikonsumsi ibu selama periode prenatal',
              value: 5,
            },
          ],
          [
            {
              text:
                'Memperkirakan tidur/siklus pasien di dalam perawatan perencanaan',
              value: 0,
            },
            {
              text: 'Monitoring pola tidur pasien dan jumlah jam tidur',
              value: 1,
            },
            {
              text:
                'Menyesuaikan lingkungan (misalnya cahaya, kebisingan, suhu, kasur, dan tempat tidur) untuk meningkatkan tidur',
              value: 2,
            },
            {
              text:
                'Monitoring makanan sebelum tidur dan intake minuman yang dapat memfasilitasi/mengganggu tidur',
              value: 3,
            },
            {
              text:
                'Mengidentifikasi efek dari obat (yang dikonsumsi) pasien terhadap pola tidur',
              value: 4,
            },
          ],
          [
            {
              text:
                'Menginformasikan pada pasien atau orang terdekat mengenai kapan dan di mana tindakan akan dilakukan, lama tindakan, dan siapa yang akan melakukan tindakan',
              value: 0,
            },
            {
              text:
                'Mengkaji pengalaman pasien sebelumnya dan tingkat pengetahuan pasien terkait tindakan yang akan dilakukan',
              value: 1,
            },
            { text: 'Menjelaskan prosedur penanganan', value: 2 },
            {
              text:
                'Mengkaji tingkat pengetahuan pasien terkait dengan proses penyakit yang spesifik',
              value: 3,
            },
            {
              text:
                'Menjelaskan tanda dan gejala yang umum dari penyakit, sesuai kebutuhan',
              value: 4,
            },
            {
              text:
                'Memberikan informasi pada pasien mengenai kondisinya, sesuai kebutuhan',
              value: 5,
            },
          ],
          [
            { text: 'Menjelaskan alasan diperlukannya tirah baring', value: 0 },
            {
              text: 'Memasang teralis tempat tidur, dengan cara yang tepat',
              value: 1,
            },
            {
              text:
                'Meletakan lampu pangggilan berada dalam jangkauan (pasien)',
              value: 2,
            },
            {
              text:
                'Mengajarkan latihan di tempat tidur, dengan cara yang tepat',
              value: 3,
            },
            {
              text:
                'Membalikkan pasien yang tidak dapat mobilisasi paling tidak setiap 2 jam, sesuai dengan jadwal yang spesifik',
              value: 4,
            },
          ],
          [
            { text: 'Monitoring tanda-tanda vital', value: 0 },
            { text: 'Mengkaji lokasi dan luasnya edema, jika ada', value: 1 },
            { text: 'Mempertahankan asepsi untuk pasien berisiko', value: 2 },
            { text: 'Memeriksa kondisi luka operasi, dengan tepat', value: 3 },
            {
              text:
                'Monitoring karakteristik luka, termasuk drainase, warna, ukuran, dan bau',
              value: 4,
            },
            {
              text: 'Memberikan balutan yang sesuai dengan jenis luka',
              value: 5,
            },
            {
              text:
                'Mengganti balutan sesuai dengan jumlah eksudat dan drainase',
              value: 6,
            },
            { text: 'Bandingkan dan catat setiap perubahan luka', value: 7 },
            {
              text:
                'Mengajarkan pasien dan keluarga mengenai tanda dan gejala infeksi',
              value: 8,
            },
            { text: 'Dokumentasi lokasi luka, ukuran, dan tampilan', value: 9 },
          ],
          [
            {
              text: 'Monitoring dan mencatat intake dan ouput (pasien)',
              value: 0,
            },
            { text: 'Anjurkan menggunakan kateter urin', value: 1 },
            {
              text:
                'Monitoring status hidrasi (misalnya membran mukosa lembab, denyut nadi adekuat, tekanan darah ortostatik)',
              value: 2,
            },
            { text: 'Monitoring tanda-tanda vital', value: 3 },
            {
              text:
                'Monitoring perubahan berat badan pasien sebelum dan setelah dialisis',
              value: 4,
            },
            { text: 'Mengkaji lokasi dan luasnya edema, jika ada', value: 5 },
            {
              text:
                'Monitoring reaksi pasien terhadap terapi elektrolit yang diresepkan',
              value: 6,
            },
            {
              text:
                'Kolaborasi medis jika ditemukan tanda dan gejala kelebihan volume cairan memburuk',
              value: 7,
            },
          ],
          [
            {
              text: 'Monitoring kadar glukosa darah, sesuai indikasi',
              value: 0,
            },
            {
              text:
                'Monitoring tanda dan gejala hiperglikemia: poliuria, plidipsi, polifagi, kelemahan, letargi, malaise, pandangan kabur, atau sakit kepala',
              value: 1,
            },
            { text: 'Memberikan insulin sesua advice', value: 2 },
            {
              text:
                'Konsultasikan dengan dokter tanda dan gejala hiperglikemia yang menetap atau memburuk',
              value: 3,
            },
            {
              text:
                'Membatasi aktivitas pasien ketika kadar glukosa darah lebih dari 250mg/dl, khususnya jika keonurin terjadi',
              value: 4,
            },
            {
              text:
                'Monitoring tanda dan gejala hipoglikemia (misalnya gemetar, sempoyongan, berkeringan, jantung berdebar-debar, kecemasan, takikardia, palpitasi, menggigil, mual, lapar, pusing)',
              value: 5,
            },
            {
              text: 'Memberikan sumber karbohidrat sederhana, sesuai indikasi',
              value: 6,
            },
            {
              text: 'Memberikan glukosa secara intravena, sesuai indikasi',
              value: 7,
            },
            { text: 'Monitoring diet pasien', value: 8 },
          ],
          [
            {
              text:
                'Melakukan penilaian terhadap mual, termasuk frekuensi, durasi, tingkat keparahan dan faktor-faktor pencetus',
              value: 0,
            },
            {
              text:
                'Mengkaji riwatat diet pasien seperti (makanan) yang disukai dan tidak disukai serta preferensi (makanan) terkait budaya',
              value: 1,
            },
            {
              text:
                'Mengidentifikasikan faktor-faktor yang menyebabkan mual (misalnya obat-obatan dan prosedur)',
              value: 2,
            },
            {
              text:
                'Mengendalikan faktor-faktor lingkungan yang mungkin membangkitkan mual (misalnya, bau yang tidak menyenangkan, suara, dan stimulasi visual yang menyenangkan)',
              value: 3,
            },
            {
              text:
                'Mendorong pola makan dengan porsi sedikit makanan yang menarik bagi pasien',
              value: 4,
            },
            {
              text:
                'Menginstruksikan pasien mengenai diet tinggi karbohidrat dan rendah lemak, yang sesuai',
              value: 5,
            },
          ],
          [
            {
              text:
                'Menjaga intake/asupan yang akurat dan catat output (pasien)',
              value: 0,
            },
            { text: 'Monitoring tanda-tanda vital', value: 1 },
            {
              text:
                'Monitoring status hidrasi (misalnya membran mukosa lembab, denyut nadi adekuat, tekanan darah ortostatik)',
              value: 2,
            },
            { text: 'Memberikan terapi IV, seperti yang ditentukan', value: 3 },
            {
              text:
                'Monitoring perubahan berat badan pasien sebelum dan setelah dialisis',
              value: 4,
            },
          ],
          [
            {
              text:
                'Mencatat nilai hemoglobin dan hematokrit sebelum dan setelah pasien kehilangan darah sesuai indikasi',
              value: 0,
            },
            {
              text:
                'Monitoring tanda-tanda vital ortostatik, termasuk tekanan darah',
              value: 1,
            },
            {
              text:
                'Menganjurkan pasien untuk tirah baring jika terjadi pendarahan aktif',
              value: 2,
            },
            { text: 'Memberikan produk darah', value: 3 },
            {
              text: 'Menganjurkan pasien mengkonumsi makanan kaya vitamin K',
              value: 4,
            },
            { text: 'Mencegah konstipasi', value: 5 },
            {
              text: 'Menganjurkan pasien menggunakan sikat gigi berbulu lembut',
              value: 6,
            },
            { text: 'Monitoring tanda-tanda pendarahan', value: 7 },
          ],
        ],
      },
    };
  },
  computed: {
    ...mapIGDAssessmentGetters(['getIsEmr']),
    ...mapGetters(['getNursingProblem']),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    nursingProblem: {
      get() {
        return this.getNursingProblem;
      },
      set(value) {
        this.setNursingProblem(value);
      },
    },
  },
  methods: {
    ...mapMutations([
      'clearNursingProblemState',
      'setNursingProblem',
      'addNursingProblem',
      'addTreatmentPlan',
      'deleteNursingProblem',
      'deleteTreatmentPlan',
      'onChangeNursingProblemState',
    ]),
    filterResource(array, index) {
      let selected = [];
      let result;
      this.nursingProblem[index].treatmentPlan.forEach(i => {
        selected.push(i.content);
      });
      result = array.filter(a => !selected.includes(a));
      return result;
    },
  },
  beforeDestroy() {
    this.clearNursingProblemState();
  },
};
</script>

<style lang="scss" scoped>
$standard-font-size: 0.9vw;

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.grid-display {
  @include grid-display(10vw minmax(16vw, 26vw), true);
  column-gap: 1vw;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
