import Constant from '@/const';
import axios from 'axios';

const getCpptById = async idEMR => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/patient/emr/cppt/${idEMR}`),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getCpptById;
