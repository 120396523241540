import Constant from '@/const';
import axios from 'axios';

const getLaboratoryServices = async assurance => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/supportservice/laboratory/lablist?assurance=${assurance}`,
      ),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getLaboratoryServices;
