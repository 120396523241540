<template>
  <v-card class="card">
    <div class="card-header">
      <h2>Form Keseimbangan Cairan Pasien</h2>
      <v-btn fab text @click="closeForm()"><v-icon>mdi-close</v-icon></v-btn>
    </div>
    <v-container class="card-body px-15">
      <v-row>
        <v-col
          ><div class="grid-display">
            <label class="text-left pl-10">Waktu Pencatatan</label>
            <v-menu
              :disabled="isEmr"
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="time"
                  readonly
                  append-icon="mdi-chevron-down"
                  v-bind="attrs"
                  v-on="on"
                  class="my-0 ml-0 mb-3 pa-0"
                  dense
                  hide-details
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu"
                v-model="time"
                full-width
                format="24hr"
                @click:minute="$refs.menu.save(time)"
              ></v-time-picker>
            </v-menu></div
        ></v-col>
        <v-col></v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="grid-display">
            <h4 class="grey--text text--lighten-1 text-left pl-10">
              PEMASUKAN
            </h4>
            <div></div>
            <label class="text-left pl-10">Oral</label>
            <v-text-field
              v-model="oral"
              dense
              :readonly="isEmr"
              single-line
              hide-details
              placeholder="0"
              suffix="cc"
              type="number"
            ></v-text-field>
            <label class="grey--text text--lighten-1 text-left pl-10">
              Cairan IV
            </label>
            <div></div>
            <label class="text-left pl-10">Infus</label>
            <v-text-field
              v-model="infus"
              :readonly="isEmr"
              dense
              single-line
              placeholder="0"
              hide-details
              suffix="cc"
              type="number"
            ></v-text-field>
            <label class="text-left pl-10">Infus Elektrolit</label>
            <v-text-field
              v-model="electrolitInfus"
              dense
              :readonly="isEmr"
              single-line
              placeholder="0"
              hide-details
              suffix="cc"
              type="number"
            ></v-text-field>
            <template v-for="(item, index) in other">
              <v-text-field
                :key="index"
                class="pl-10"
                :readonly="isEmr"
                v-model="item.title"
                dense
                single-line
                hide-details
              ></v-text-field>
              <v-text-field
                :key="'value' + index"
                v-model="item.value"
                dense
                single-line
                :readonly="isEmr"
                placeholder="0"
                hide-details
                suffix="cc"
                type="number"
              ></v-text-field>
            </template>
            <div></div>
            <div class="d-flex justify-end">
              <v-btn
                small
                color="grey"
                text
                v-if="!isEmr"
                fab
                @click="deleteOtherInfus"
                :disabled="other.length == 0"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
              <v-btn
                v-if="!isEmr"
                small
                color="grey"
                text
                fab
                @click="addOtherInfus"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="grid-display">
            <h4 class="grey--text text--lighten-1 text-left pl-10">
              PENGELUARAN
            </h4>
            <div></div>
            <label class="text-left pl-10">Urine</label>
            <v-text-field
              v-model="urine"
              dense
              :readonly="isEmr"
              single-line
              placeholder="0"
              hide-details
              suffix="cc"
              type="number"
            ></v-text-field>
            <label class="text-left pl-10">BAB</label>
            <v-text-field
              v-model="bab"
              :readonly="isEmr"
              dense
              placeholder="0"
              single-line
              hide-details
              suffix="cc"
              type="number"
            ></v-text-field>
            <label class="text-left pl-10">Aspirasi</label>
            <v-text-field
              v-model="aspiration"
              dense
              :readonly="isEmr"
              single-line
              placeholder="0"
              hide-details
              suffix="cc"
              type="number"
            ></v-text-field>
            <label class="text-left pl-10">Mentah</label>
            <v-text-field
              v-model="raw"
              dense
              placeholder="0"
              single-line
              :readonly="isEmr"
              hide-details
              suffix="cc"
              type="number"
            ></v-text-field>
            <div></div>
            <div class="d-flex justify-end">
              <v-btn
                v-if="!isEditing"
                :disabled="isEmr"
                @click="onClickAddTable"
                color="primary"
                outlined
                class="text-capitalize"
                ><span class="primary-text">
                  Tambahkan ke Tabel
                </span></v-btn
              >
              <v-btn
                v-else
                @click="saveChanges(editingIndex)"
                color="primary"
                :disabled="isEmr"
                outlined
                class="text-capitalize"
                ><span class="primary-text">Simpan Perubahan</span></v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-10 table">
          <div class="table-header">
            <label
              v-for="(header, index) in tables.headers"
              :key="'header-' + index"
              >{{ header }}</label
            >
          </div>
          <div
            v-for="(item, index) in table"
            :key="'row-' + index"
            class="table-body"
          >
            <label>{{ item.time }}</label>
            <div>
              <label v-for="(inc, x) in item.income" :key="`income-${x}`">{{
                inc
              }}</label>
            </div>
            <div>
              <label
                v-for="(sub, x) in item.sub_income"
                :key="`sub-income-${x}`"
                >{{ sub.label }}</label
              >
              <label
                v-for="(additional, x) in item.otherSubIncome"
                :key="`sub-income-other${x}`"
                >{{ additional.label }}</label
              >
            </div>
            <div>
              <label
                v-for="(sub_val, y) in item.sub_income"
                :key="`sub-value-${y}`"
                >{{ sub_val.value }} cc</label
              >
              <label
                v-for="(additional_val, x) in item.otherSubIncome"
                :key="`sub-value-other${x}`"
                >{{ additional_val.value }} cc</label
              >
            </div>
            <div>
              <label v-for="(out, z) in item.outcome" :key="`sub-value-${z}`">{{
                out.label
              }}</label>
            </div>
            <div>
              <label
                v-for="(out_val, a) in item.outcome"
                :key="`sub-value-${a}`"
                >{{ out_val.value }} cc</label
              >
            </div>
            <div class="d-flex flex-row">
              <v-btn v-if="!isEmr" @click="editData(index)" text fab small
                ><v-icon color="grey">mdi-pencil</v-icon></v-btn
              >
              <v-btn v-if="!isEmr" @click="deleteRow(index)" text fab small
                ><v-icon color="grey">mdi-delete</v-icon></v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-footer class="d-flex justify-end">
      <v-btn
        v-if="!isEmr"
        color="primary"
        @click="saveData"
        class="text-capitalize"
        depressed
        >Simpan</v-btn
      >
    </v-footer>
    <v-snackbar timeout="4000" v-model="snackbar" color="success"
      ><span class="font-weight-bold">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          fab
          small
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);
const { mapGetters, mapMutations } = createNamespacedHelpers('FluidBalance');
const moment = require('moment-timezone');

export default {
  name: 'FluidBalance',
  props: {
    existingTable: Array,
  },
  data() {
    return {
      menu: false,
      isEditing: false,
      snackbar: false,
      snackbarText: false,
      editingIndex: null,
      tables: {
        headers: [
          'Jam',
          'Pemasukan',
          'Sub Pemasukan',
          'Jumlah',
          'Pengeluaran',
          'Jumlah',
          '',
        ],
      },
    };
  },
  computed: {
    ...mapIGDAssessmentGetters(['getIsEmr']),
    ...mapGetters([
      'getTime',
      'getOral',
      'getInfus',
      'getElectrolitInfus',
      'getOther',
      'getUrine',
      'getBab',
      'getAspiration',
      'getRaw',
      'getTable',
    ]),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    time: {
      get() {
        return this.getTime;
      },
      set(value) {
        this.setTime(value);
      },
    },
    oral: {
      get() {
        return this.getOral;
      },
      set(value) {
        this.setOral(value);
      },
    },
    infus: {
      get() {
        return this.getInfus;
      },
      set(value) {
        this.setInfus(value);
      },
    },
    electrolitInfus: {
      get() {
        return this.getElectrolitInfus;
      },
      set(value) {
        this.setElectrolitInfus(value);
      },
    },
    other: {
      get() {
        return this.getOther;
      },
      set(value) {
        this.setOther(value);
      },
    },
    urine: {
      get() {
        return this.getUrine;
      },
      set(value) {
        this.setUrine(value);
      },
    },
    bab: {
      get() {
        return this.getBab;
      },
      set(value) {
        this.setBab(value);
      },
    },
    aspiration: {
      get() {
        return this.getAspiration;
      },
      set(value) {
        this.setAspiration(value);
      },
    },
    raw: {
      get() {
        return this.getRaw;
      },
      set(value) {
        this.setRaw(value);
      },
    },
    table: {
      get() {
        return this.getTable;
      },
      set(value) {
        this.setTable(value);
      },
    },
  },
  mounted() {
    this.setTime(moment().format('HH:mm'));
    if (this.existingTable.length > 0) {
      this.setTable(this.existingTable);
    }
  },
  beforeDestroy() {
    this.clearFluidBalanceState();
  },
  methods: {
    ...mapMutations([
      'setTime',
      'setTable',
      'setRaw',
      'setAspiration',
      'setBab',
      'setUrine',
      'setOther',
      'setInfus',
      'setElectrolitInfus',
      'setOral',
      'clearFluidBalanceState',
      'addOtherInfus',
      'deleteOtherInfus',
      'addDataToTable',
      'deleteRow',
      'editRow',
      'saveRowChanges',
    ]),
    editData(index) {
      this.editRow(index);
      this.editingIndex = index;
      this.isEditing = true;
    },
    saveChanges(index) {
      this.saveRowChanges(index);
      this.editingIndex = null;
      this.isEditing = false;
      this.formatSnackbar('Data tabel berhasil diubah');
    },
    onClickAddTable() {
      this.addDataToTable();
      this.formatSnackbar('Data berhasil ditambahkan ke tabel');
    },
    saveData() {
      this.$emit('save', this.table);
      this.closeForm();
    },
    closeForm() {
      this.$emit('close-form');
    },
    formatSnackbar(text) {
      this.snackbar = true;
      this.snackbarText = text;
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  height: 90vh;
  overflow: auto;

  #{&}-header {
    display: grid;
    height: 8vh;
    align-items: center;
    grid-template-columns: 95% 5%;
    margin-bottom: 2rem;
    box-shadow: 1px 7px 5px -3px rgba(0, 0, 0, 0.07);
    -webkit-box-shadow: 1px 7px 5px -3px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 1px 7px 5px -3px rgba(0, 0, 0, 0.07);
  }

  #{&}-body {
    max-height: 30dvh;
    overflow: auto;
  }

  .grid-display {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 1.5rem;
  }

  .table {
    height: 30vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 3px;
      background-color: rgb(222, 219, 219);
    }
    &::-webkit-scrollbar-thumb {
      width: 3px;
      background-color: rgb(118, 118, 118);
    }

    .table-header {
      display: grid;
      grid-template-columns: 12% 16% 16% 16% 16% 14% 10%;
      text-align: start;
      background-color: #f4f5f5;
      padding: 1rem;
      border-bottom: 2px solid #d5d2d2;

      label {
        font-size: 0.93rem;
        color: #757575;
      }
    }

    .table-body {
      display: grid;
      grid-template-columns: 12% 16% 16% 16% 16% 14% 10%;
      text-align: start;
      padding: 1rem;
      border-bottom: 1px solid #d5d2d2;

      div {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  footer {
    position: absolute;
    width: 100%;
    bottom: 5px;
    background-color: white;
    padding: 1rem 3rem;
  }

  ::v-deep .v-data-table-header {
    background-color: #f4f5f5 !important;
  }
}
</style>
