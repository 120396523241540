import Constant from '@/const';
import axios from 'axios';

const postLabRegistration = async payload => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat(`/patient/lab/registration`),
      payload,
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default postLabRegistration;
