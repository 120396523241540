import Constant from '../../../const';
const axios = require('axios');

const getActionByEmr = async idEmr => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/document/inform-consent-action/all/${idEmr}`),
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getActionByEmr;
