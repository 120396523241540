<template>
  <v-form ref="form" class="form-container mt-4">
    <section>
      <h4>RIWAYAT KB & PENYAKIT</h4>
      <v-row no-gutters>
        <v-col cols="6" class="pr-10 mb-4">
          <label class="required">Status KB</label>
          <v-radio-group
            class="input-field"
            row
            v-model="form.kbHistory.kbStatus"
            :readonly="isEMR"
            :rules="[v => v !== '' || 'Wajib diisi']"
          >
            <v-radio label="Belum pernah KB" :value="false"></v-radio>
            <v-radio label="Pernah KB" :value="true"></v-radio>
          </v-radio-group>
          <div
            class="input-field d-flex flex-wrap"
            style="width: 100% !important; gap: 0.25rem; margin-top: -2rem;"
            v-if="form?.kbHistory.kbStatus === true"
            :rules="
              isEMR
                ? []
                : [form.kbHistory.kbMethod.length !== 0 || 'Wajib diisi']
            "
          >
            <div
              v-for="(item, index) in items.kbMethod"
              :key="index"
              style="min-width: 4.5rem;"
            >
              <v-checkbox
                :rules="
                  isEMR
                    ? []
                    : [form.kbHistory.kbMethod.length !== 0 || 'Wajib diisi']
                "
                v-model="form.kbHistory.kbMethod"
                :readonly="isEMR"
                :label="item"
                color="primary"
                :value="item"
                hide-details
                :update:error="true"
              />
            </div>
          </div>
          <span
            v-show="
              form.kbHistory.kbMethod.length === 0 &&
                form?.kbHistory.kbStatus === true &&
                this.showError === true
            "
            class="errorMessage"
            >Wajib diisi</span
          >
        </v-col>
        <v-col cols="6" class="pl-10 mt-4">
          <label>Keluhan</label>
          <v-text-field
            dense
            single-line
            :readonly="isEMR"
            v-model="form.kbHistory.diseaseSurgeryHistory"
            placeholder="Masukkan keluhan"
            class="input-field"
          />
        </v-col>
      </v-row>
      <v-divider class="divider" />
    </section>

    <section>
      <h4>
        Riwayat Menstruasi
      </h4>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label class="required">Menarche Teratur</label>
          <v-radio-group
            class="input-field"
            row
            :readonly="isEMR"
            v-model="form.menstruationHistory.menarche"
            :rules="isEMR ? [] : [v => v !== null || 'Wajib diisi']"
          >
            <v-radio label="Ya" :value="true"></v-radio>
            <v-radio label="Tidak" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="6" class="pl-10">
          <label class="required">HPHT</label>
          <v-menu
            :close-on-content-click="false"
            dense
            ref="menu"
            :disabled="isEMR"
            v-model="menu.dateHpht"
            transition="scale-transition"
            offset-y
            min-width="380px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="input-field"
                append-icon="mdi-chevron-down"
                placeholder="DD-MM-YYYY"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                single-line
                v-model="filterDateHpht"
                :rules="rules.required"
              />
            </template>
            <v-date-picker
              width="380px"
              v-model="form.menstruationHistory.hpht"
              @change="handleGeneratePregnancyDueDate()"
              no-title
              scrollable
            />
          </v-menu>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label class="required">Siklus (Hari)</label>
          <v-text-field
            dense
            single-line
            :readonly="isEMR"
            v-model.number="form.menstruationHistory.periodCycle"
            class="input-field"
            placeholder="00"
            type="number"
            @keydown="validateInput($event)"
            :rules="rules.required"
          />
        </v-col>
        <v-col cols="6" class="pl-10">
          <label class="required">HPL/Taksiran Persalinan</label>
          <v-menu
            :close-on-content-click="false"
            dense
            ref="menu"
            :disabled="isEMR"
            v-model="menu.dateHpl"
            transition="scale-transition"
            offset-y
            min-width="380px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="input-field"
                append-icon="mdi-chevron-down"
                placeholder="DD-MM-YYYY"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                single-line
                :value="filterDateHpl()"
                :rules="rules.required"
              />
            </template>
            <v-date-picker
              width="380px"
              v-model="form.menstruationHistory.hpl"
              no-title
              scrollable
            />
          </v-menu>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6" class="pr-10">
          <label class="required">Lama (Hari)</label>
          <v-text-field
            dense
            single-line
            :readonly="isEMR"
            v-model.number="form.menstruationHistory.range"
            class="input-field"
            placeholder="00"
            type="number"
            @keydown="validateInput($event)"
            :rules="rules.required"
          />
        </v-col>
        <v-col cols="6" class="pl-10">
          <label class="required">UK (Minggu)</label>
          <v-text-field
            dense
            single-line
            :readonly="isEMR"
            v-model.number="form.menstruationHistory.uk"
            class="input-field"
            placeholder="0"
            type="number"
            :rules="rules.required"
          />
        </v-col>
      </v-row>
    </section>
  </v-form>
</template>

<script>
import moment from 'moment-timezone';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'LabourAssessment',
);

export default {
  name: 'kbAndMenstrualHistory',
  props: {
    isEMR: Boolean,
  },
  data() {
    return {
      showError: false,
      menu: {
        dateHpht: false,
        dateHpl: false,
      },
    };
  },
  mounted() {
    if (this.form.kbHistory.kbMethod.length === 0) {
      this.showError = true;
    }
  },
  computed: {
    ...mapGetters(['getForm', 'getItems', 'getRules']),
    rules: {
      get() {
        return this.isEMR ? [] : this.getRules;
      },
    },
    form: {
      get() {
        return this.getForm.kbAndMenstrualHistory;
      },
    },
    items: {
      get() {
        return this.getItems;
      },
    },
    filterDateHpht: {
      get() {
        return this.form.menstruationHistory.hpht
          ? moment(this.form.menstruationHistory.hpht).format('DD-MM-YYYY')
          : '';
      },
      set(val) {
        this.form.menstruationHistory.hpht = val;
      },
    },
  },
  watch: {
    'form.kbStatus': {
      handler() {
        if (this.form?.kbHistory.kbStatus === false) {
          this.showError = false;
        }
      },
      deep: true,
    },
    'form.kbHistory.kbMethod': {
      handler() {
        if (this.form.kbHistory.kbMethod.length === 0) {
          this.showError = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations(['']),
    ...mapActions(['resolveGeneratePregnancyDueDate']),
    handleGeneratePregnancyDueDate() {
      this.resolveGeneratePregnancyDueDate();
    },
    filterDateHpl() {
      if (this.form.menstruationHistory.hpl) {
        return moment(this.form.menstruationHistory.hpl).format('DD-MM-YYYY');
      } else {
        return '';
      }
    },
    validateInput(event) {
      const inputValue = event.target.value;
      const maxLength = 4;
      const allowedKeys = [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'ctrlKey',
      ];
      if (inputValue.length >= maxLength && !allowedKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: start;
  gap: 1.5rem;
  .divider {
    margin-top: 1.5rem;
    width: 100%;
  }
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: #3f484a;
  }
  .input-field {
    margin-top: 0.5rem;
    width: 100%;
  }
  h4 {
    font-weight: 400;
    color: #137bc0;
    text-align: start;
    margin-bottom: 0.7vw;
  }
}
section {
  width: 100%;
}
.errorMessage {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #eb4747;
}
.required:after {
  content: ' *';
  color: red;
}
::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
