<template>
  <div>
    <v-row>
      <v-col cols="6"
        ><v-skeleton-loader type="article"></v-skeleton-loader
      ></v-col>
      <v-col><v-skeleton-loader type="article"></v-skeleton-loader></v-col>
      <v-col><v-skeleton-loader type="article"></v-skeleton-loader></v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <v-skeleton-loader class="my-3" type="article"></v-skeleton-loader>
        <v-skeleton-loader class="my-3" type="article"></v-skeleton-loader>
        <v-skeleton-loader class="my-3" type="article"></v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader class="my-3" type="article"></v-skeleton-loader>
        <v-skeleton-loader class="my-3" type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'EMRDashboardSkletonLoader',
};
</script>

<style lang="scss" scoped></style>
