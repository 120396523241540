const moment = require('moment-timezone');

const state = {
  time: moment().format('HH:mm'),
  oral: '',
  infus: '',
  electrolitInfus: '',
  other: [],
  urine: '',
  bab: '',
  aspiration: '',
  raw: '',
  table: [],
};

const getters = {
  getTime: state => state.time,
  getOral: state => state.oral,
  getInfus: state => state.infus,
  getElectrolitInfus: state => state.electrolitInfus,
  getOther: state => state.other,
  getUrine: state => state.urine,
  getBab: state => state.bab,
  getAspiration: state => state.aspiration,
  getRaw: state => state.raw,
  getTable: state => state.table,
};

const mutations = {
  clearFluidBalanceState(state) {
    Object.assign(state, {
      time: '',
      oral: '',
      infus: '',
      electrolitInfus: '',
      other: [],
      urine: '',
      bab: '',
      aspiration: '',
      raw: '',
      table: [],
    });
  },
  addDataToTable(state) {
    state.table.push({
      time: state.time,
      income: ['Oral', 'Cairan IV'],
      sub_income: [
        {
          label: '-',
          value: state.oral || 0,
        },
        {
          label: 'Infus',
          value: state.infus || 0,
        },
        {
          label: 'Infus Elektrolit',
          value: state.electrolitInfus || 0,
        },
      ],
      otherSubIncome: state.other.map(item => {
        return {
          label: item.title,
          value: item.value,
        };
      }),
      outcome: [
        {
          label: 'Urine',
          value: state.urine || 0,
        },
        {
          label: 'BAB',
          value: state.bab || 0,
        },
        {
          label: 'Aspirasi',
          value: state.aspiration || 0,
        },
        {
          label: 'Mentah',
          value: state.raw || 0,
        },
      ],
    });
    state.oral = '';
    state.infus = '';
    state.electrolitInfus = '';
    state.other = [];
    state.urine = '';
    state.bab = '';
    state.aspiration = '';
    state.raw = '';
  },
  editRow(state, index) {
    const editedData = state.table[index];
    state.time = editedData.time;
    state.oral = editedData.sub_income[0].value;
    state.infus = editedData.sub_income[1].value;
    state.electrolitInfus = editedData.sub_income[2].value;
    state.other = editedData.otherSubIncome.map(i => {
      return {
        title: i.label,
        value: i.value,
      };
    });
    state.urine = editedData.outcome[0].value;
    state.bab = editedData.outcome[1].value;
    state.aspiration = editedData.outcome[2].value;
    state.raw = editedData.outcome[3].value;
  },
  saveRowChanges(state, index) {
    state.table[index] = {
      time: state.time,
      income: ['Oral', 'Cairan IV'],
      sub_income: [
        {
          label: '-',
          value: state.oral || 0,
        },
        {
          label: 'Infus',
          value: state.infus || 0,
        },
        {
          label: 'Infus Elektrolit',
          value: state.electrolitInfus || 0,
        },
      ],
      otherSubIncome: state.other.map(item => {
        return {
          label: item.title,
          value: item.value,
        };
      }),
      outcome: [
        {
          label: 'Urine',
          value: state.urine || 0,
        },
        {
          label: 'BAB',
          value: state.bab || 0,
        },
        {
          label: 'Aspirasi',
          value: state.aspiration || 0,
        },
        {
          label: 'Mentah',
          value: state.raw || 0,
        },
      ],
    };
    state.oral = '';
    state.infus = '';
    state.electrolitInfus = '';
    state.other = [];
    state.urine = '';
    state.bab = '';
    state.aspiration = '';
    state.raw = '';
  },
  deleteRow(state, index) {
    state.table.splice(index, 1);
  },
  addOtherInfus(state) {
    state.other.push({ title: '', value: '' });
  },
  deleteOtherInfus(state) {
    state.other.splice(-1, 1);
  },
  setTime(state, payload) {
    state.time = payload;
  },
  setOral(state, payload) {
    state.oral = payload;
  },
  setElectrolitInfus(state, payload) {
    state.electrolitInfus = payload;
  },
  setInfus(state, payload) {
    state.infus = payload;
  },
  setOther(state, payload) {
    state.other = payload;
  },
  setUrine(state, payload) {
    state.urine = payload;
  },
  setBab(state, payload) {
    state.bab = payload;
  },
  setAspiration(state, payload) {
    state.aspiration = payload;
  },
  setRaw(state, payload) {
    state.raw = payload;
  },
  setTable(state, payload) {
    payload.forEach(item => {
      state.table.push(item);
    });
  },
};

export default {
  state,
  getters,
  mutations,
};
