<template>
  <section :style="style">
    <section-title title="pemeriksaan penunjang" />
    <v-row>
      <v-col class="grid-display">
        <label>Hasil Laboratorium</label>
        <v-textarea
          :readonly="isEmr"
          v-model="lab"
          :rules="rule"
          no-resize
          outlined
          dense
          placeholder="Masukkan Hasil Laboratorium"
        />
        <label>Hasil Radiologi</label>
        <v-textarea
          :rules="rule"
          :readonly="isEmr"
          v-model="radiology"
          no-resize
          outlined
          dense
          placeholder="Masukkan Hasil Radiologi"
        />
      </v-col>
      <v-col class="grid-display">
        <label>Catatan Lain</label>
        <v-textarea
          :rules="rule"
          no-resize
          outlined
          dense
          placeholder="Masukkan Catatan Lainnya"
          :readonly="isEmr"
          v-model="other_notes"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import SectionTitle from './SectionTitle.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'SupportingInvestigation',
);
const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);
export default {
  name: 'SupportingInvestigation',
  components: { SectionTitle },
  data() {
    return {
      rule: [v => v.split(' ').length <= 3000 || 'Maksimal 3000 karakter!'],
    };
  },
  props: {
    pl: { type: String },
    pr: { type: String },
    pt: { type: String },
    pb: { type: String },
    ml: { type: String },
    mr: { type: String },
    mt: { type: String },
    mb: { type: String },
  },
  computed: {
    style() {
      return `
      --pl: ${this.pl};
      --pr: ${this.pr};
      --pt: ${this.pt};
      --pb: ${this.pb};
      --mb: ${this.mb};
      --mt: ${this.mt};
      --ml: ${this.ml};
      --mr: ${this.mr};
      `;
    },
    ...mapGetters(['getOtherNotes', 'getLab', 'getRadiology']),
    ...mapIGDAssessmentGetters(['getIsEmr']),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    other_notes: {
      get() {
        return this.getOtherNotes;
      },
      set(value) {
        this.setOtherNotes(value);
      },
    },
    lab: {
      get() {
        return this.getLab;
      },
      set(value) {
        this.setLab(value);
      },
    },
    radiology: {
      get() {
        return this.getRadiology;
      },
      set(value) {
        this.setRadiology(value);
      },
    },
  },
  beforeDestroy() {
    this.clearSupportingInvestigationState();
  },
  methods: {
    ...mapMutations([
      'setOtherNotes',
      'setLab',
      'setRadiology',
      'clearSupportingInvestigationState',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: 0.9vw;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

section {
  padding-left: var(--pl);
  padding-right: var(--pr);
  padding-top: var(--pt);
  padding-bottom: var(--pb);
  margin-left: var(--ml);
  margin-right: var(--mr);
  margin-top: var(--mt);
  margin-bottom: var(--mb);
}

.grid-display {
  @include grid-display(14vw minmax(16vw, 30vw), true);
}
</style>
