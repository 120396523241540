import Constant from '@/const';
import axios from 'axios';

const getHomeData = async filterType => {
  try {
    const response = axios.get(
      Constant.apiUrl.concat(`/report/visitors/dashboard/visit/${filterType}`),
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getHomeData;
