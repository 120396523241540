import Constant from '@/const';
import axios from 'axios';

const putConfirmLabRequest = async (idLab, payload) => {
  try {
    const response = await axios.put(
      Constant.apiUrl.concat(
        `/supportservice/laboratory/request/${idLab}/confirm`,
      ),
      payload,
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default putConfirmLabRequest;
