import Constant from '@/const';
import axios from 'axios';

const getStaffById = async id_staff => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/master/staff/find/${id_staff}`),
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getStaffById;
