<template>
  <v-card class="px-5">
    <v-card-title class="justify-end" v-if="data.isWadiah === true">
      <h4>Input Dana Wadiah</h4>
    </v-card-title>
    <v-card-title
      class="justify-end"
      v-else-if="data.isWadiah === false && selectedButton === 'debit'"
    >
      <h4>Input Pembayaran Debit</h4>
    </v-card-title>
    <v-card-title class="justify-end" v-else>
      <h4>Input Pembayaran Tunai</h4>
    </v-card-title>
    <v-container class="container-scroll-input">
      <v-row style="width: 500px;">
        <v-col cols="4">
          <v-row>
            <v-col class="grey--text font-weight-bold body-2 text-left">
              <label>No. RM</label>
            </v-col>
            <v-col class="font-weight-bold body-2 text-left">
              <span>{{ data.rm_number }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col
              class="grey--text font-weight-bold body-2 text-left"
              cols="3"
            >
              <label>Nama</label>
            </v-col>
            <v-col class="font-weight-bold body-2 text-left">
              <span>{{ data.name }} ({{ data.gender ? 'P' : 'L' }})</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row style="width: 500px;" class="pb-3">
        <v-col cols="5">
          <v-row>
            <v-col
              class="grey--text font-weight-bold body-2 text-left"
              cols="5"
            >
              <label>Unit</label>
            </v-col>
            <v-col class="font-weight-bold body-2 text-left">
              <span>{{ data.unit }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="data.isWadiah === false">
          <v-row>
            <v-col
              class="grey--text font-weight-bold body-2 text-left"
              cols="5"
            >
              <label>Tipe Penjamin</label>
            </v-col>
            <v-col class="font-weight-bold body-2 text-left">
              <span>{{ data.assurance }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <h4 class="text-left py-3">Ringkasan Tagihan</h4>
      <v-row style="width: 500px;">
        <v-col class="font-weight-bold body-2 text-left" cols="4">
          <label>Total Tagihan</label>
        </v-col>
        <v-col class="body-2 text-left">
          <span>Rp {{ formatNumber(data.total_bill) }}</span>
        </v-col>
      </v-row>
      <v-row style="width: 500px;">
        <v-col class="font-weight-bold body-2 text-left" cols="4">
          <label>Total Wadiah</label>
        </v-col>
        <v-col class="body-2 text-left">
          <span>Rp {{ formatNumber(data.total_wadiah) }}</span>
        </v-col>
      </v-row>
      <v-row style="width: 500px;">
        <v-col class="font-weight-bold body-2 text-left" cols="4">
          <label>Sisa Tagihan</label>
        </v-col>
        <v-col class="body-2 text-left">
          <span>Rp {{ formatNumber(data.remaining_bill) }}</span>
        </v-col>
      </v-row>
      <v-row style="width: 500px;">
        <v-col class="font-weight-bold body-2 text-left" cols="4">
          <label>Sisa Saldo Wadiah</label>
        </v-col>
        <v-col class="body-2 text-left">
          <span
            >Rp
            {{
              Math.sign(data.total_wadiah - data.remaining_bill) === -1
                ? 0
                : formatNumber(data.total_wadiah - data.remaining_bill)
            }}</span
          >
        </v-col>
      </v-row>
      <v-row style="width: 500px;" class="pb-3">
        <v-col class="font-weight-bold body-2 text-left" cols="4">
          <label>Total Bayar</label>
        </v-col>
        <v-col class="font-weight-bold body-2 text-left">
          <span>Rp {{ formatNumber(totalPayment) }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <h4 class="text-left py-3" v-if="data.isWadiah === true">
        Topup Saldo Wadiah
      </h4>
      <h4 class="text-left py-3" v-else>Input Pembayaran</h4>
      <div class="text-sm-left mt-2" v-if="selectedButton === 'debit'">
        <p class="caption font-weight-bold text-sm-left mb-2">Bank</p>
        <v-btn-toggle
          active-class="billing"
          :value="resource.bankList.value"
          v-model="inputDana.bank"
        >
          <v-btn
            v-for="(bank, index) in resource.bankList"
            :key="index"
            :value="bank.value"
            class="mb-2"
          >
            {{ bank.text }}
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-row class="my-0">
        <v-col xs3 class="pr-2" v-if="selectedButton === 'debit'" cols="6">
          <v-text-field
            label="ID Kartu"
            placeholder="Masukkan ID Kartu (Opsional)"
            v-mask="'#### - #### - #### - ####'"
            v-model="inputDana.cardNumber"
            :error-messages="formValidation.cardNumber"
            hide-details="false"
          ></v-text-field>
        </v-col>
        <v-col xs3 class="py-0" cols="6">
          <v-currency-field
            label="Masukkan nominal"
            v-model="totalInput"
            :autofocus="true"
            :error-messages="formValidation.totalPayment"
            prefix="Rp."
            outlined
            hide-details="false"
          ></v-currency-field>
        </v-col>
      </v-row>
      <div class="text-left pt-3">
        <v-btn
          @click="saveInput"
          depressed
          class="text-none"
          color="primary"
          v-if="data.isWadiah === true"
        >
          Topup
        </v-btn>
        <v-row v-else>
          <v-col class="caption font-weight-medium" cols="2">
            <label>Kembalian</label>
          </v-col>
          <v-col class="caption font-weight-medium">
            <span>Rp {{ formatNumber(change) }}</span>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <v-card-actions>
      <v-btn @click="closeForm" class="text-none" text>
        Kembali
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="data.isWadiah === true"
        outlined
        tile
        color="primary"
        class="text-none"
      >
        Print
      </v-btn>
      <v-btn
        v-else
        tile
        depressed
        :disabled="selectedButton === 'debit' ? !inputDana.bank : false"
        class="text-none"
        color="primary"
        @click="saveInput"
      >
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import AlertMixin from '@/mixin/alertMixin';

const axios = require('axios');

export default {
  name: 'PaymentInput',
  mixins: [AlertMixin],
  props: {
    data: Object,
    selectedButton: String,
  },
  data() {
    return {
      resource: {
        bankList: [
          { text: 'BCA', value: '014' },
          { text: 'Bank Mandiri', value: '008' },
          { text: 'Bank Mega', value: '426' },
          { text: 'BNI', value: '009' },
          { text: 'BRI', value: '002' },
          { text: 'Bank Permata', value: '013' },
          { text: 'CIMB Niaga', value: '022' },
        ],
      },
      formValidation: {
        cardNumber: null,
        totalPayment: null,
      },
      inputDana: {
        bank: '',
        cardNumber: '',
      },
      totalPayment: null,
      totalInput: 0,
      isCloseDetail: true,
    };
  },
  mounted() {
    this.total();
  },
  watch: {
    totalInput(val) {
      if (val !== '') {
        if (val[0] === '0') {
          const slice = val.slice(1);
          this.totalInput = slice;
        }
      } else {
        this.totalInput = 0;
      }
    },
  },
  computed: {
    change() {
      return Math.sign(parseInt(this.totalInput) - this.totalPayment) === -1
        ? 0
        : parseInt(this.totalInput) - this.totalPayment;
    },
  },
  methods: {
    closeForm() {
      this.$emit('close-form');
    },
    saveInput() {
      const id_billing = this.data.id;
      // ;
      if (this.data.isWadiah === true) {
        if (this.selectedButton === 'debit') {
          axios
            .post(Constant.apiUrl.concat(`/billing/pay-wadiah/${id_billing}`), {
              amount: parseInt(this.totalInput),
              isCash: false,
              card_number: this.inputDana.cardNumber,
              bank: '5fbf86a460045c812123d9af',
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
            })
            .then(response => {
              if (response.status === 200) {
                this.closeForm();
                Swal.fire({
                  icon: 'success',
                  title: 'Topup Saldo berhasil',
                  showConfirmButton: false,
                  timer: 2000,
                });
                this.totalInput = 0;
                this.inputDana = '';
              }
            })
            .catch(err => {
              this.showErrorAxios(err);
            });
        } else if (this.selectedButton === 'tunai') {
          axios
            .post(Constant.apiUrl.concat(`/billing/pay-wadiah/${id_billing}`), {
              amount: parseInt(this.totalInput),
              isCash: true,
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
            })
            .then(response => {
              if (response.status === 200) {
                this.closeForm();
                Swal.fire({
                  icon: 'success',
                  title: 'Topup Saldo berhasil',
                  showConfirmButton: false,
                  timer: 2000,
                });
                this.totalInput = 0;
              }
            })
            .catch(err => {
              this.showErrorAxios(err);
            });
        }
      } else {
        if (this.selectedButton === 'debit') {
          axios
            .post(
              Constant.apiUrl.concat(`/billing/pay-billing/${id_billing}`),
              {
                amount: parseInt(this.totalInput),
                isCash: false,
                card_number: this.inputDana.cardNumber,
                bank: this.inputDana.bank,
                timestamps: {
                  created_by: this.$store.getters.userLoggedIn.id,
                },
              },
            )
            .then(response => {
              if (response.status === 200) {
                this.closeForm();
                Swal.fire({
                  icon: 'success',
                  title: 'Pembayaran berhasil',
                  showConfirmButton: false,
                  timer: 2000,
                });
                this.inputDana = '';
                this.$emit('is-close-detail');
              }
            })
            .catch(err => {
              this.showErrorAxios(err);
            });
        } else if (this.selectedButton === 'tunai') {
          axios
            .post(
              Constant.apiUrl.concat(`/billing/pay-billing/${id_billing}`),
              {
                amount: parseInt(this.totalInput),
                isCash: true,
                timestamps: {
                  created_by: this.$store.getters.userLoggedIn.id,
                },
              },
            )
            .then(response => {
              if (response.status === 200) {
                this.closeForm();
                Swal.fire({
                  icon: 'success',
                  title: 'Pembayaran berhasil',
                  showConfirmButton: false,
                  timer: 2000,
                });
                this.totalInput = 0;
                this.$emit('is-close-detail');
              }
            })
            .catch(err => {
              this.showErrorAxios(err);
            });
        }
      }
    },
    formatNumber(num) {
      return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : 0;
    },
    total() {
      this.totalPayment =
        Math.sign(this.data.total_wadiah - this.data.remaining_bill) === -1
          ? Math.abs(this.data.total_wadiah - this.data.remaining_bill)
          : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-scroll-input {
  overflow-y: scroll;
  height: 450px;
  &::-webkit-scrollbar {
    width: 2px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}

.v-btn-toggle {
  box-shadow: none;
  border: none;
  flex-wrap: wrap;

  .v-btn {
    margin-right: 10px;
    border: 1px solid #a6adb4 !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    background-color: white !important;
    padding: 0 20px !important;
    min-width: initial;
    text-transform: none;

    .v-btn__content {
      color: #a6adb4 !important;
    }

    &.v-btn--active {
      border-color: transparent;
      background: linear-gradient(180deg, #b3d1ff 0%, #99b4ff 100%);

      color: white !important;
    }

    &.bg-billing {
      background-color: linear-gradient(
        180deg,
        #b3d1ff 0%,
        #99b4ff 100%
      ) !important;
    }
  }
}
</style>
