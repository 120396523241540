import Constant from '@/const';
import axios from 'axios';

const getAllBed = async ({ halaman = 1, itemCount = 10, sortBy, keyword }) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/master/bed?page=${halaman}&itemCount=${itemCount}&sort=${sortBy}&search=${keyword}`,
      ),
    );
    return response.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getAllBed;
