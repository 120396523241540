import _ from 'lodash';
import moment from 'moment-timezone';
import { postGeneratePregnancyDueDate } from '@/fetchApi/Inpatient/ObstreticalAssessment';
import {
  getPregnancyHistory,
  getLabourAssessment,
  postLabourAssessment,
} from '@/fetchApi/Inpatient/LabourAssessment';
import { generateBadgeLabour } from './InpatientAssessmentUtils';

const state = {
  dashboard: {
    tab: 0,
    isNew: false,
    loading: false,
    isLoading: true,
    showSuccessMessage: {
      text: null,
      show: false,
    },
    tabsResource: [
      {
        key: 'pregnancyHistory',
        text: 'Riwayat Kehamilan',
      },
      {
        key: 'kbAndMenstrualHistory',
        text: 'Riwayat KB & Menstruasi',
      },
      {
        key: 'noteAndPostpartum',
        text: 'Catatan Persalinan & Nifas',
      },
    ],
  },
  rules: {
    required: [v => !!v || 'Wajib diisi'],
    radioRules: [v => v !== null || 'Wajib diisi'],
  },
  items: {
    kbMethod: [
      'Suntik',
      'IUD',
      'Pil',
      'Kondom',
      'Kalender',
      'MOW',
      'MOP',
      'Implan',
    ],
    place: ['Rumah', 'RS', 'BPS', 'Puskesmas', 'Lain-Lain'],
    helper: ['Bidan', 'Dokter', 'Dukun', 'Lain-Lain'],
  },
  patientData: {
    id_inpatient: '',
    id_emr: '',
  },
  form: {
    pregnancyHistory: {
      visitData: {
        staff: '',
        fillDate: moment().format('YYYY-MM-DD'),
        fillTime: moment().format('HH:MM'),
        visitDate: moment().format('YYYY-MM-DD'),
        visitTime: moment().format('HH:MM'),
        diagnose: '',
      },
      currentPregnancy: {
        hisDate: '',
        hisTime: '',
        mucusOutDate: '',
        mucusOutTime: '',
        amnioticFluidOutDate: '',
        amnioticFluidOutTime: '',
      },
      previousPregnancyHistory: [
        {
          pregnancyTo: 1,
          ageYear: null,
          ageMonth: null,
          gender: '',
          helper: '',
          helperEtc: '',
          place: '',
          placeEtc: '',
          isAlive: null,
          bbl: null,
          complication: '',
          currentCondition: '',
        },
      ],
    },
    kbAndMenstrualHistory: {
      kbHistory: {
        kbStatus: null,
        kbMethod: [],
        diseaseSurgeryHistory: '',
      },
      menstruationHistory: {
        menarche: null,
        periodCycle: null,
        range: null,
        hpht: '',
        hpl: '',
        uk: '',
      },
    },
    noteAndPostpartum: {
      birthNote: [
        {
          date: '',
          time: '',
          birthTwoToFour: '',
          helper: '',
          therapy: '',
        },
      ],
      postpartumNote: {
        date: '',
        time: '',
        postpartum: '',
      },
    },
  },
  initialForm: {
    pregnancyHistory: {
      visitData: {
        staff: '',
        fillDate: moment().format('YYYY-MM-DD'),
        fillTime: moment().format('HH:MM'),
        visitDate: moment().format('YYYY-MM-DD'),
        visitTime: moment().format('HH:MM'),
        diagnose: '',
      },
      currentPregnancy: {
        hisDate: '',
        hisTime: '',
        mucusOutDate: '',
        mucusOutTime: '',
        amnioticFluidOutDate: '',
        amnioticFluidOutTime: '',
      },
      previousPregnancyHistory: [
        {
          pregnancyTo: 1,
          ageYear: null,
          ageMonth: null,
          gender: '',
          helper: '',
          helperEtc: '',
          place: '',
          placeEtc: '',
          isAlive: null,
          bbl: null,
          complication: '',
          currentCondition: '',
        },
      ],
    },
    kbAndMenstrualHistory: {
      kbHistory: {
        kbStatus: null,
        kbMethod: [],
        diseaseSurgeryHistory: '',
      },
      menstruationHistory: {
        menarche: null,
        periodCycle: null,
        range: null,
        hpht: '',
        hpl: '',
        uk: '',
      },
    },
    noteAndPostpartum: {
      birthNote: [
        {
          date: '',
          time: '',
          birthTwoToFour: '',
          helper: '',
          therapy: '',
        },
      ],
      postpartumNote: {
        date: '',
        time: '',
        postpartum: '',
      },
    },
  },
};

const getters = {
  // Get Login Data
  getLoginData: (_, getters, rootState) => {
    return rootState.userLoggedIn;
  },

  // Get Tab
  getLabourTab: state => state.dashboard.tab,

  // Get Tabs Resource
  getLabourTabsResource: state => state.dashboard.tabsResource,

  // Get State Form
  getForm: state => state.form,

  // Get State Rules
  getRules: state => state.rules,

  // Get State Items
  getItems: state => state.items,

  // Get State patientData
  getPatientData: state => state.patientData,

  getLoading: state => state.dashboard.loading,

  // Get Show Message
  getShowSuccessMessage: state => state.dashboard.showSuccessMessage,

  // Get Change
  getLabourIsChanged: state => {
    return !_.isEqual(state.initialForm, state.form);
  },

  // Get Loading
  getIsLoading: state => state.dashboard.isLoading,

  // Get Is New or Not
  getIsNew: state => state.dashboard.isNew,
};

const mutations = {
  // reset autofill
  resetTimeAutofill(state) {
    state.form.pregnancyHistory.visitData = {
      ...state.form.pregnancyHistory.visitData,
      fillDate: '',
      fillTime: '',
      visitDate: '',
      visitTime: '',
    };
  },
  // Set Tab
  setTab(state, value) {
    state.dashboard.tab = value.destination;
  },

  setEstimatedDate(state, payload) {
    state.form.kbAndMenstrualHistory.menstruationHistory.hpl = payload;
  },

  setShowSuccessMessage(state, payload) {
    state.dashboard.showSuccessMessage = payload;
  },

  // Set Patient Data
  setPatientData(state, value) {
    state.patientData = value;
  },

  setTabsResource(state, { index, payload }) {
    state.dashboard.tabsResource.splice(index, 1, {
      ...state.dashboard.tabsResource[index],
      ...payload,
    });
  },

  // Set isNew
  setIsNew(state, value) {
    state.dashboard.isNew = value;
  },

  // Set Loading
  setIsLoading(state, value) {
    state.dashboard.isLoading = value;
  },

  // Set All State Form
  setForm(state, payload) {
    state.form = payload;
  },

  // Set State initialForm
  setInitialForm(state, payload) {
    state.initialForm = payload;
  },

  setPreviousPregnancyHistory(state, payload) {
    state.form.pregnancyHistory.previousPregnancyHistory = payload;
  },
  setInitialPreviousPregnancyHistory(state, payload) {
    state.initialForm.pregnancyHistory.previousPregnancyHistory = payload;
  },

  // Set Default Value
  setDefaultValue(state, value) {
    const { staff } = value;
    state.form.pregnancyHistory.visitData.staff = staff;
    state.initialForm.pregnancyHistory.visitData.staff = staff;
  },

  clearForm(state) {
    state.form = {
      pregnancyHistory: {
        visitData: {
          staff: '',
          fillDate: moment().format('YYYY-MM-DD'),
          fillTime: moment().format('HH:MM'),
          visitDate: moment().format('YYYY-MM-DD'),
          visitTime: moment().format('HH:MM'),
          diagnose: '',
        },
        currentPregnancy: {
          hisDate: '',
          hisTime: '',
          mucusOutDate: '',
          mucusOutTime: '',
          amnioticFluidOutDate: '',
          amnioticFluidOutTime: '',
        },
        previousPregnancyHistory: [
          {
            pregnancyTo: 1,
            ageYear: null,
            ageMonth: null,
            gender: '',
            helper: '',
            helperEtc: '',
            place: '',
            placeEtc: '',
            isAlive: null,
            bbl: null,
            complication: '',
            currentCondition: '',
          },
        ],
      },
      kbAndMenstrualHistory: {
        kbHistory: {
          kbStatus: null,
          kbMethod: [],
          diseaseSurgeryHistory: '',
        },
        menstruationHistory: {
          menarche: null,
          periodCycle: null,
          range: null,
          hpht: '',
          hpl: '',
          uk: '',
        },
      },
      noteAndPostpartum: {
        birthNote: [
          {
            date: '',
            time: '',
            birthTwoToFour: '',
            helper: '',
            therapy: '',
          },
        ],
        postpartumNote: {
          date: '',
          time: '',
          postpartum: '',
        },
      },
    };
    state.initialForm = {
      pregnancyHistory: {
        visitData: {
          staff: '',
          fillDate: moment().format('YYYY-MM-DD'),
          fillTime: moment().format('HH:MM'),
          visitDate: moment().format('YYYY-MM-DD'),
          visitTime: moment().format('HH:MM'),
          diagnose: '',
        },
        currentPregnancy: {
          hisDate: '',
          hisTime: '',
          mucusOutDate: '',
          mucusOutTime: '',
          amnioticFluidOutDate: '',
          amnioticFluidOutTime: '',
        },
        previousPregnancyHistory: [
          {
            pregnancyTo: 1,
            ageYear: null,
            ageMonth: null,
            gender: '',
            helper: '',
            helperEtc: '',
            place: '',
            placeEtc: '',
            isAlive: null,
            bbl: null,
            complication: '',
            currentCondition: '',
          },
        ],
      },
      kbAndMenstrualHistory: {
        kbHistory: {
          kbStatus: null,
          kbMethod: [],
          diseaseSurgeryHistory: '',
        },
        menstruationHistory: {
          menarche: null,
          periodCycle: null,
          range: null,
          hpht: '',
          hpl: '',
          uk: '',
        },
      },
      noteAndPostpartum: {
        birthNote: [
          {
            date: '',
            time: '',
            birthTwoToFour: '',
            helper: '',
            therapy: '',
          },
        ],
        postpartumNote: {
          date: '',
          time: '',
          postpartum: '',
        },
      },
    };
    state.dashboard.tabsResource = [
      {
        key: 'pregnancyHistory',
        text: 'Riwayat Kehamilan',
      },
      {
        key: 'kbAndMenstrualHistory',
        text: 'Riwayat KB & Imunisasi',
      },
      {
        key: 'noteAndPostpartum',
        text: 'Catatan Persalinan & Nifas',
      },
    ];
    state.dashboard.tab = 0;
  },
};

const actions = {
  addPregnancyHistory({ state }) {
    state.form.pregnancyHistory.previousPregnancyHistory.push({
      pregnancyTo:
        state.form.pregnancyHistory.previousPregnancyHistory.length + 1,
      ageYear: null,
      ageMonth: null,
      gender: '',
      helper: '',
      helperEtc: '',
      place: '',
      placeEtc: '',
      isAlive: { name: 'Hidup', key: true },
      bbl: null,
      complication: '',
      currentCondition: '',
    });
  },
  removePregnancyHistory({ state }, index) {
    state.form.pregnancyHistory.previousPregnancyHistory.splice(index, 1);
  },

  addPostpartumRecord({ state }) {
    state.form.noteAndPostpartum.birthNote.push({
      recordDate: {
        date: '',
        time: '',
      },
      helper: '',
      helperEtc: '',
      therapy: '',
      stageOfLabor: '',
    });
  },
  removePostpartumRecord({ state }, index) {
    state.form.noteAndPostpartum.birthNote.splice(index, 1);
  },

  formValidation({ commit, state }, payload) {
    if (payload.destination > payload.origin) {
      for (let i = payload.origin; i < payload.destination; i++) {
        commit('setTabsResource', {
          index: i,
          payload: {
            icon: generateBadgeLabour(state.form, i),
          },
        });
      }
    } else {
      for (let i = payload.origin; i > payload.destination; i--) {
        commit('setTabsResource', {
          index: i,
          payload: {
            icon: generateBadgeLabour(state.form, i),
          },
        });
      }
    }
  },

  // Generate Pregnancy Due Date
  async resolveGeneratePregnancyDueDate({ commit, state }) {
    try {
      const response = await postGeneratePregnancyDueDate({
        date: state.form.kbAndMenstrualHistory.menstruationHistory.hpht,
      });
      commit('setEstimatedDate', response.data.data.date);
    } catch (error) {
      return error;
    }
  },

  // Generate Pregnancy History
  async resolveGetPregnancyHistory({ commit, state }) {
    try {
      const response = await getPregnancyHistory(state.patientData.id_emr);
      commit(
        'setPreviousPregnancyHistory',
        response.data.data.map(item => {
          return {
            ...item,
            isAlive: item.isAlive
              ? { name: 'Hidup', key: true }
              : { name: 'Mati', key: false },
            place: state.items.place.some(
              val => val === item.place || item.place === '',
            )
              ? item.place
              : 'Lain-Lain',
            placeEtc: state.items.place.some(
              val => val === item.place || item.place === '',
            )
              ? ''
              : item.place,
            helper: state.items.helper.some(
              val => val === item.helper || item.helper === '',
            )
              ? item.helper
              : 'Lain-Lain',
            helperEtc: state.items.helper.some(
              val => val === item.helper || item.helper === '',
            )
              ? ''
              : item.helper,
          };
        }),
      );
      commit(
        'setInitialPreviousPregnancyHistory',
        response.data.data.map(item => {
          return {
            ...item,
            isAlive: item.isAlive
              ? { name: 'Hidup', key: true }
              : { name: 'Mati', key: false },
            place: state.items.place.some(
              val => val === item.place || item.place === '',
            )
              ? item.place
              : 'Lain-Lain',
            placeEtc: state.items.place.some(
              val => val === item.place || item.place === '',
            )
              ? ''
              : item.place,
            helper: state.items.helper.some(
              val => val === item.helper || item.helper === '',
            )
              ? item.helper
              : 'Lain-Lain',
            helperEtc: state.items.helper.some(
              val => val === item.helper || item.helper === '',
            )
              ? ''
              : item.helper,
          };
        }),
      );
    } catch (error) {
      commit('setPreviousPregnancyHistory', [
        {
          pregnancyTo: 1,
          ageYear: null,
          ageMonth: null,
          gender: '',
          helper: '',
          helperEtc: '',
          place: '',
          placeEtc: '',
          isAlive: null,
          bbl: null,
          complication: '',
          currentCondition: '',
        },
      ]);
      commit('setInitialPreviousPregnancyHistory', [
        {
          pregnancyTo: 1,
          ageYear: null,
          ageMonth: null,
          gender: '',
          helper: '',
          helperEtc: '',
          place: '',
          placeEtc: '',
          isAlive: null,
          bbl: null,
          complication: '',
          currentCondition: '',
        },
      ]);
      return error;
    }
  },

  // Get
  async resolveGetLabourAssessment({ commit, state, dispatch }) {
    commit('setIsLoading', true);
    try {
      const response = await getLabourAssessment(
        state.patientData.id_inpatient,
      );
      const data = response.data.data;
      const dataInitial = response.data.data;
      const dataMapper = {
        ...data,
        pregnancyHistory: {
          ...data.pregnancyHistory,
          visitData: {
            staff: data.pregnancyHistory?.visitData?.staff || '',
            fillDate:
              data.pregnancyHistory?.visitData?.fillDate ||
              moment().format('YYYY-MM-DD'),
            fillTime:
              data.pregnancyHistory?.visitData?.fillTime ||
              moment().format('HH:MM'),
            visitDate:
              data.pregnancyHistory?.visitData?.visitDate ||
              moment().format('YYYY-MM-DD'),
            visitTime:
              data.pregnancyHistory?.visitData?.visitTime ||
              moment().format('HH:MM'),
            diagnose: data.pregnancyHistory?.visitData?.diagnose || '',
          },
          currentPregnancy: {
            hisDate: data.pregnancyHistory?.currentPregnancy?.hisDate || '',
            hisTime: data.pregnancyHistory?.currentPregnancy?.hisTime || '',
            mucusOutDate:
              data.pregnancyHistory?.currentPregnancy?.mucusOutDate || '',
            mucusOutTime:
              data.pregnancyHistory?.currentPregnancy?.mucusOutTime || '',
            amnioticFluidOutDate:
              data.pregnancyHistory?.currentPregnancy?.amnioticFluidOutDate ||
              '',
            amnioticFluidOutTime:
              data.pregnancyHistory?.currentPregnancy?.amnioticFluidOutTime ||
              '',
          },
          previousPregnancyHistory:
            data.pregnancyHistory.previousPregnancyHistory.length === 0
              ? [
                  {
                    pregnancyTo: 1,
                    ageYear: null,
                    ageMonth: null,
                    gender: '',
                    helper: '',
                    helperEtc: '',
                    place: '',
                    placeEtc: '',
                    isAlive: { name: 'Hidup', key: true },
                    bbl: null,
                    complication: '',
                    currentCondition: '',
                  },
                ]
              : data.pregnancyHistory.previousPregnancyHistory.map(item => {
                  return {
                    ...item,
                    isAlive: item.isAlive
                      ? { name: 'Hidup', key: true }
                      : { name: 'Mati', key: false },
                    place: state.items.place.some(
                      val => val === item.place || item.place === '',
                    )
                      ? item.place
                      : 'Lain-Lain',
                    placeEtc: state.items.place.some(
                      val => val === item.place || item.place === '',
                    )
                      ? ''
                      : item.place,
                    helper: state.items.helper.some(
                      val => val === item.helper || item.helper === '',
                    )
                      ? item.helper
                      : 'Lain-Lain',
                    helperEtc: state.items.helper.some(
                      val => val === item.helper || item.helper === '',
                    )
                      ? ''
                      : item.helper,
                  };
                }),
        },
        kbAndMenstrualHistory:
          data.kbAndMenstrualHistory.menstruationHistory !== undefined
            ? { ...data.kbAndMenstrualHistory }
            : {
                kbHistory: {
                  kbStatus: null,
                  kbMethod: [],
                  diseaseSurgeryHistory: '',
                },
                menstruationHistory: {
                  menarche: null,
                  periodCycle: null,
                  range: null,
                  hpht: '',
                  hpl: '',
                  uk: '',
                },
              },
        noteAndPostpartum: {
          ...data.noteAndPostpartum,
          postpartumNote:
            data.noteAndPostpartum.postpartumNote !== undefined
              ? { ...data.noteAndPostpartum.postpartumNote }
              : { date: '', time: '', postpartum: '' },
          birthNote:
            data.noteAndPostpartum.birthNote.length === 0
              ? [
                  {
                    date: '',
                    time: '',
                    birthTwoToFour: '',
                    helper: '',
                    therapy: '',
                  },
                ]
              : data.noteAndPostpartum.birthNote.map(item => {
                  return {
                    ...item,
                  };
                }),
        },
      };
      const dataInitialMapper = {
        ...dataInitial,
        pregnancyHistory: {
          ...dataInitial.pregnancyHistory,
          visitData: {
            staff: dataInitial.pregnancyHistory?.visitData?.staff || '',
            fillDate:
              dataInitial.pregnancyHistory?.visitData?.fillDate ||
              moment().format('YYYY-MM-DD'),
            fillTime:
              dataInitial.pregnancyHistory?.visitData?.fillTime ||
              moment().format('HH:MM'),
            visitDate:
              dataInitial.pregnancyHistory?.visitData?.visitDate ||
              moment().format('YYYY-MM-DD'),
            visitTime:
              dataInitial.pregnancyHistory?.visitData?.visitTime ||
              moment().format('HH:MM'),
            diagnose: dataInitial.pregnancyHistory?.visitData?.diagnose || '',
          },
          currentPregnancy: {
            hisDate:
              dataInitial.pregnancyHistory?.currentPregnancy?.hisDate || '',
            hisTime:
              dataInitial.pregnancyHistory?.currentPregnancy?.hisTime || '',
            mucusOutDate:
              dataInitial.pregnancyHistory?.currentPregnancy?.mucusOutDate ||
              '',
            mucusOutTime:
              dataInitial.pregnancyHistory?.currentPregnancy?.mucusOutTime ||
              '',
            amnioticFluidOutDate:
              dataInitial.pregnancyHistory?.currentPregnancy
                ?.amnioticFluidOutDate || '',
            amnioticFluidOutTime:
              dataInitial.pregnancyHistory?.currentPregnancy
                ?.amnioticFluidOutTime || '',
          },
          previousPregnancyHistory:
            dataInitial.pregnancyHistory.previousPregnancyHistory.length === 0
              ? [
                  {
                    pregnancyTo: 1,
                    ageYear: null,
                    ageMonth: null,
                    gender: '',
                    helper: '',
                    helperEtc: '',
                    place: '',
                    placeEtc: '',
                    isAlive: { name: 'Hidup', key: true },
                    bbl: null,
                    complication: '',
                    currentCondition: '',
                  },
                ]
              : dataInitial.pregnancyHistory.previousPregnancyHistory.map(
                  item => {
                    return {
                      ...item,
                      isAlive: item.isAlive
                        ? { name: 'Hidup', key: true }
                        : { name: 'Mati', key: false },
                      place: state.items.place.some(
                        val => val === item.place || item.place === '',
                      )
                        ? item.place
                        : 'Lain-Lain',
                      placeEtc: state.items.place.some(
                        val => val === item.place || item.place === '',
                      )
                        ? ''
                        : item.place,
                      helper: state.items.helper.some(
                        val => val === item.helper || item.helper === '',
                      )
                        ? item.helper
                        : 'Lain-Lain',
                      helperEtc: state.items.helper.some(
                        val => val === item.helper || item.helper === '',
                      )
                        ? ''
                        : item.helper,
                    };
                  },
                ),
        },
        kbAndMenstrualHistory:
          dataInitial.kbAndMenstrualHistory.menstruationHistory !== undefined
            ? {
                ...dataInitial.kbAndMenstrualHistory,
                kbHistory: { ...dataInitial.kbAndMenstrualHistory.kbHistory },
                menstruationHistory: {
                  ...dataInitial.kbAndMenstrualHistory.menstruationHistory,
                },
              }
            : {
                kbHistory: {
                  kbStatus: null,
                  kbMethod: [],
                  diseaseSurgeryHistory: '',
                },
                menstruationHistory: {
                  menarche: null,
                  periodCycle: null,
                  range: null,
                  hpht: '',
                  hpl: '',
                  uk: '',
                },
              },
        noteAndPostpartum: {
          ...dataInitial.noteAndPostpartum,
          postpartumNote:
            dataInitial.noteAndPostpartum.postpartumNote !== undefined
              ? { ...dataInitial.noteAndPostpartum.postpartumNote }
              : { date: '', time: '', postpartum: '' },
          birthNote:
            dataInitial.noteAndPostpartum.birthNote.length === 0
              ? [
                  {
                    date: '',
                    time: '',
                    birthTwoToFour: '',
                    helper: '',
                    therapy: '',
                  },
                ]
              : dataInitial.noteAndPostpartum.birthNote.map(item => {
                  return {
                    ...item,
                  };
                }),
        },
      };
      commit('setForm', dataMapper);
      commit('setInitialForm', dataInitialMapper);
      const sections = state.dashboard.tabsResource.map(item => item.key);
      sections.forEach((section, index) => {
        if (dataMapper[section].timestamps !== undefined) {
          commit('setTabsResource', {
            index: index,
            payload: {
              icon: generateBadgeLabour(dataMapper, index),
            },
          });
        } else {
          commit('setTabsResource', {
            index: index,
            payload: {
              icon: '',
            },
          });
        }
      });
      if (data.pregnancyHistory.timestamps === undefined) {
        dispatch('resolveGetPregnancyHistory');
      }
      if (data.noteAndPostpartum.timestamps !== undefined) {
        commit('setIsNew', false);
      } else {
        commit('setIsNew', true);
      }
      return response;
    } catch (error) {
      dispatch('resolveGetPregnancyHistory');
      commit('setIsNew', true);
      return error;
    } finally {
      commit('setIsLoading', false);
    }
  },

  async resolvePostLabourAssessment({ state, dispatch, commit }, index) {
    const selectedTab = state.dashboard.tabsResource[index];
    let payload;
    switch (index) {
      case 0:
        payload = {
          pregnancyHistory: {
            ...state.form.pregnancyHistory,
            visitData: {
              ...state.form.pregnancyHistory.visitData,
              staff:
                typeof state.form.pregnancyHistory.visitData.staff === 'object'
                  ? state.form.pregnancyHistory.visitData.staff?.id || null
                  : state.form.pregnancyHistory.visitData.staff || null,
            },
            previousPregnancyHistory: state.form.pregnancyHistory.previousPregnancyHistory.map(
              item => {
                return {
                  ...item,
                  place:
                    item.place === 'Lain-Lain' ? item.placeEtc : item.place,
                  helper:
                    item.helper === 'Lain-Lain' ? item.helperEtc : item.helper,
                  isAlive: item.isAlive.key,
                };
              },
            ),
          },
        };
        break;
      case 1:
        payload = {
          kbAndMenstrualHistory: {
            ...state.form.kbAndMenstrualHistory,
            kbMethod:
              state.form.kbAndMenstrualHistory.kbHistory.kbStatus === false
                ? []
                : state.form.kbAndMenstrualHistory.kbHistory.kbMethod,
          },
        };
        break;
      case 2:
        payload = {
          noteAndPostpartum: {
            ...state.form.noteAndPostpartum,
          },
        };
        break;
    }
    payload.id_emr = state.patientData.id_emr;
    try {
      state.dashboard.loading = true;
      if (!_.isEqual(state.initialForm, state.form)) {
        const res = await postLabourAssessment(
          state.patientData.id_inpatient,
          payload,
        );
        if (res.status === 200) {
          commit('setShowSuccessMessage', {
            text: selectedTab.text,
            show: true,
          });
          setTimeout(() => {
            commit('setShowSuccessMessage', {
              text: '',
              show: false,
            });
          }, 3000);
          dispatch('resolveGetLabourAssessment');
        } else {
          commit('setShowSuccessMessage', {
            text: selectedTab.text,
            show: true,
            isFail: true,
          });
          setTimeout(() => {
            commit('setShowSuccessMessage', {
              text: '',
              show: false,
            });
          }, 3000);
          return res;
        }
      }
    } catch (error) {
      return error;
    } finally {
      state.dashboard.loading = false;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
