var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.getLoading.table)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):[_c('v-row',{staticClass:"d-flex justify-end"},[(!_vm.isEMR)?_c('v-btn',{staticClass:"text-capitalize pr-3 pl-1",staticStyle:{"letter-spacing":"0.01px"},attrs:{"color":"primary","dense":"","light":"","depressed":""},on:{"click":function($event){return _vm.navigateTo('form-cppt')}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Buat Baru")])],1):_vm._e()],1),_c('v-row',[_c('div',{staticClass:"grid-header"},[_c('span',[_vm._v("Tanggal & Jam")]),_c('span',[_vm._v("PPA")]),_c('span',[_vm._v("Hasil Pemeriksaan, Analisa, dan Penatalaksanaan Pasien")]),_c('span',[_vm._v("Instruksi PPA")])]),_c('v-container',{attrs:{"fluid":""}},[(!_vm.items?.length)?_c('div',{staticClass:"pt-5"},[_c('p',{staticClass:"grey--text subtitle-2"},[_vm._v(" Tidak ada riwayat CPPT ")])]):_vm._l((_vm.items),function(item,index){return _c('div',{key:`row-${index}`,staticClass:"grid-data"},[_c('div',{staticClass:"col py-0"},[_c('p',[_vm._v(_vm._s(`${item.date}, ${item.time}`))]),_c('p',[_vm._v("No Bed: "+_vm._s(item.bed))])]),_c('div',{staticClass:"col py-0"},[_c('p',[_vm._v(_vm._s(item.ppa.name))]),_c('v-chip',{class:`text-capitalize chip ${
                      item.ppa.role.some(
                        item => item.toLowerCase() === 'dokter',
                      )
                        ? 'doctor'
                        : 'nurse'
                    }`},[_vm._v(" "+_vm._s(_vm.generatePpaRole(item.ppa.role))+" ")])],1),_c('div',{staticClass:"col"},[_c('v-row',[_c('v-col',{staticClass:"pa-0 pl-3"},[_c('p',{staticClass:"primary--text"},[_vm._v("DATA SUBJEKTIF")]),_c('p',{staticClass:"sub-title"},[_vm._v("Anamnesis")]),_c('p',{domProps:{"innerHTML":_vm._s(item.anamnesis)}}),_c('p',{staticClass:"primary--text"},[_vm._v("DATA OBJEKTIF")]),_c('div',{staticClass:"objective"},[_c('label',[_vm._v("Tekanan Darah")]),_c('span',[_vm._v(_vm._s(`${item.objective.sistole}/${item.objective.diastole} mmHg`))]),_c('label',[_vm._v("Suhu")]),_c('span',[_vm._v(_vm._s(item.objective.temperature)+" °C")]),_c('label',[_vm._v("Nadi")]),_c('span',[_vm._v(_vm._s(item.objective.heartRate)+" x/menit")]),_c('label',[_vm._v("RR")]),_c('span',[_vm._v(_vm._s(item.objective.rr)+" x/menit")]),_c('label',[_vm._v("Saturasi")]),_c('span',[_vm._v(_vm._s(item.objective.saturation)+" %")])]),_c('p',{staticClass:"sub-title"},[_vm._v("GDS")]),_c('p',[_vm._v(" "+_vm._s(item.objective.gds)+" ")]),_c('p',{staticClass:"sub-title"},[_vm._v("Data Objektif Lainnya")]),_c('p',{domProps:{"innerHTML":_vm._s(item.objective.other)}})]),_c('v-col',{staticClass:"pa-0 pl-3"},[_c('p',{staticClass:"primary--text"},[_vm._v("ASSESMEN & PLAN")]),(
                        item.ppa.role.some(
                          item => item.toLowerCase() === 'dokter',
                        )
                      )?[_c('p',{staticClass:"sub-title"},[_vm._v("Diagnosa (ICD 10)")]),(
                          !item.assessment?.diagnose?.length ||
                            !item.assessment?.diagnose?.[0]
                        )?_c('p',[_vm._v(" - ")]):_c('ul',{staticClass:"mb-4"},_vm._l((item
                            .assessment?.diagnose),function(diagnose,indexDoctorDiagnose){return _c('li',{key:`diagnose-${indexDoctorDiagnose}`},[_vm._v(" "+_vm._s(diagnose)+" ("+_vm._s(item.assessment.icd[indexDoctorDiagnose])+") ")])}),0),_c('p',{staticClass:"sub-title"},[_vm._v("Rencana Tindakan")]),(
                          !item.assessment.planning?.length ||
                            !item.assessment.planning?.[0]
                        )?_c('p',[_vm._v(" - ")]):_c('ul',_vm._l((item.assessment.planning),function(v,idx){return _c('li',{key:`planning-doctor-${idx}`},[_vm._v(" "+_vm._s(v)+" ")])}),0),_c('p',{staticClass:"sub-title"},[_vm._v("Catatan")]),_c('p',{domProps:{"innerHTML":_vm._s(item.assessment?.note)}})]:[_vm._l((item.assessment?.diagnose),function(x,idx){return _c('div',{key:`diagnose-nurse-${idx}`},[_c('p',{staticClass:"sub-title"},[_vm._v("Diagnosa "+_vm._s(idx + 1))]),_c('p',[_vm._v(_vm._s(x.name))]),_c('p',{staticClass:"sub-title"},[_vm._v("Rencana Tindakan")]),(!x.planning?.length || !x.planning?.[0])?_c('p',[_vm._v("-")]):_c('ul',_vm._l((x.planning),function(r,planningIdx){return _c('li',{key:`planning-${planningIdx}-diagnose-${idx}`},[_vm._v(" "+_vm._s(r)+" ")])}),0)])}),_c('p',{staticClass:"sub-title"},[_vm._v("Catatan")]),_c('p',{domProps:{"innerHTML":_vm._s(item.assessment?.note)}})]],2)],1)],1),_c('div',{staticClass:"col py-0"},[_c('p',{domProps:{"innerHTML":_vm._s(item.ppaInstruction)}})]),_c('div',{staticClass:"d-flex align-center"},[_c('v-menu',{attrs:{"left":"","bottom":"","offset-x":true,"close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","light":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#9ca2a5"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('div',{staticClass:"menu"},_vm._l((_vm.menu),function(i,indexMenu){return _c('div',{key:`menu-${indexMenu}`,staticClass:"menu-item",on:{"click":function($event){return _vm.onClickMenu(indexMenu, index)}}},[(
                          indexMenu === 2
                            ? _vm.showDeleteCpptBtn(indexMenu, item)
                            : true
                        )?_c('p',{class:`ma-0 pa-3 ${indexMenu === 2 ? 'red--text' : ''}`},[_vm._v(" "+_vm._s(i)+" ")]):_vm._e()])}),0)])],1)])})],2)],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('detail-changes',{attrs:{"index":_vm.selectedCpptIndex},on:{"close":_vm.closeDialog}}):_vm._e()],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }