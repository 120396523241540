<template>
  <v-card class="rounded-lg pa-3 schedule-list" color="#D9EDFF" elevation="0">
    <v-row v-if="data.date" class="date">
      <label class="text-start">Hari</label>
      <label class="font-weight-bold text-start">{{ data.day }}</label>
      <label class="text-start">Tanggal</label>
      <label class="font-weight-bold text-start">{{ data.date }}</label>
    </v-row>
    <v-row no-gutters class="caption font-weight-bold schedule-grid header">
      <v-col class="text-left">Nama Dokter</v-col>
      <v-col class="text-left">Poli</v-col>
      <v-col>Jam Praktik</v-col>
      <v-col>Ruang</v-col>
    </v-row>
    <v-progress-linear
      v-if="data.loading"
      indeterminate
      color="cyan"
    ></v-progress-linear>
    <div v-show="!data.loading" v-if="data.schedule !== ''">
      <v-row
        v-for="doctorSchedule in data.schedule"
        :key="doctorSchedule.scheduleId"
        class="caption pa-3"
      >
        <v-col class="text-left pa-0" cols="4">
          <label>{{ doctorSchedule.doctorName }}</label>
        </v-col>
        <v-col class="text-left pa-0" cols="3">
          <label :key="'poly' + index">{{ doctorSchedule.unit }}</label>
        </v-col>
        <v-col class="schedule-grid pa-0">
          <template v-for="(schedule, index) in doctorSchedule.schedule">
            <label :key="'startTime-' + index">{{
              `${schedule.startTime} - ${schedule.endTime}`
            }}</label>
            <label :key="'room-' + index">{{ schedule.room }}</label>
          </template>
        </v-col>
      </v-row>
    </div>
    <div v-if="data.schedule === ''">
      <p class="font-weight-bold">Tidak ada jadwal</p>
    </div>
  </v-card>
</template>
<script>
const moment = require('moment');

export default {
  name: 'DoctorScheduleListCard',
  props: {
    data: Object,
  },
  methods: {
    generateDay(date) {
      return moment(date)
        .locale('id')
        .format('dddd');
    },
  },
};
</script>
<style lang="scss" scoped>
.schedule-list {
  width: 100%;

  .date {
    padding-top: 0.8rem;
    padding-left: 0.8rem;
    display: grid;
    margin-bottom: 1rem;
    width: 20rem;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
  }
  .schedule-grid {
    display: grid;
    grid-template-columns: 1fr 0.5fr;

    &.header {
      grid-template-columns: 33.5% 0.9fr 1fr 0.5fr;
    }
  }
}
</style>
