import Constant from '@/const';
import axios from 'axios';

const postCheckNIPAvail = async payload => {
  try {
    const req = await axios.post(
      Constant.apiUrl.concat(`/master/staff/nip/check/${payload.id}`),
      { nip: payload.nip },
    );
    return req;
  } catch (error) {
    throw new Error(error);
  }
};

export default postCheckNIPAvail;
