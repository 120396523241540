import Constant from '@/const';
import axios from 'axios';

const getNeonatusAssessment = async (id, type) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/inpatient/neonatus/get/${id}?type=${type}`),
    );
    return response.data.data;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getNeonatusAssessment;
