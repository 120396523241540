<template>
  <div class="patientbilling">
    <v-toolbar flat outlined min-height="84">
      <v-container fluid>
        <v-row align="center">
          <div class="d-flex flex-column justify-start">
            <v-toolbar-title class="font-weight-bold text-left ">
              Pembayaran Tunai
            </v-toolbar-title>
            <div class="caption grey--text ">
              Pasien terdaftar 0 Pasien terlayani
            </div>
            <div class="caption grey--text ">
              Tagihan lunas Tagihan belum lunas
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-menu
            class="ma-0 pa-0"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                placeholder="Masukkan Tanggal"
                class="input mr-4 mt-5 text-left"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
          <v-btn class="mr-2" color="grey" icon text>
            <v-icon>mdi-home </v-icon>
          </v-btn>
          <v-btn class="mr-2" color="grey" icon text>
            <v-icon>mdi-autorenew </v-icon>
          </v-btn>
          <v-text-field
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-container>
        <v-tabs center-active v-model="tabs" class="d-flex justify-center">
          <v-tab class="font-weight-bold text-capitalize">Wadiah</v-tab>
          <v-tab class="font-weight-bold text-capitalize">Tagihan Pasien</v-tab>
        </v-tabs>
      </v-container>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <template>
            <v-data-table
              :headers="wadiah.headers"
              :items="wadiah.item"
              :items-per-page="5"
              :expanded="expanded"
              single-expand
              item-key="number"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
              hide-default-footer
            >
              <template v-slot:expanded-item="{ headers }">
                <td :colspan="headers.length" class="expand">
                  <v-card flat>
                    <v-row align="center">
                      <v-col
                        cols="2"
                        class="text-left pl-15 ml-6 grey--text caption"
                      >
                        <span
                          >Terdaftar 10:30 WIB <br />
                          10/2/2020</span
                        ></v-col
                      >
                      <v-col
                        cols="2"
                        class="text-left ml-3 pl-10 grey--text caption"
                      >
                        <span>Laki-laki 20 Tahun</span></v-col
                      >
                      <v-col cols="7" class="text-right">
                        <v-chip
                          small
                          class="ma-2"
                          color="red"
                          text-color="white"
                        >
                          Belum Lunas
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-account</v-icon>
                              </v-btn>
                            </template>

                            <span>Tooltip</span>
                          </v-tooltip>
                          <v-dialog
                            v-model="dialog"
                            fullscreen
                            hide-overlay
                            transition="scale-transition"
                          >
                            <template #activator="{ on: dialog }">
                              <v-tooltip bottom>
                                <template #activator="{ on: tooltip }">
                                  <v-btn
                                    x-small
                                    icon
                                    class="mx-2"
                                    v-on="{ ...tooltip, ...dialog }"
                                  >
                                    <v-icon>mdi-receipt</v-icon>
                                  </v-btn>
                                </template>
                                <span>Tooltip text</span>
                              </v-tooltip>
                            </template>

                            <dialog-billing @close-form="closeForm" />
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </template>
        </v-tab-item>
        <v-tab-item>
          <template>
            <v-data-table
              :headers="patientBill.headers"
              :items="patientBill.item"
              :items-per-page="5"
              hide-default-footer
            ></v-data-table>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-main>
    <v-footer color="white">
      <div class="text-center">
        <v-pagination v-model="page" :length="6"></v-pagination>
      </div>
    </v-footer>
  </div>
</template>

<script>
import DialogBilling from '../SharedComponent/Dialog/DialogBilling.vue';
export default {
  components: { DialogBilling },
  methods: {
    closeForm() {
      this.dialog = false;
    },
  },
  data() {
    return {
      expanded: [],
      menu2: false,
      dialog: false,
      tabs: null,
      patientBill: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'number',
          },
          { text: 'No. Pendaftaran', value: 'regNumber' },
          { text: 'Nama', value: 'name' },
          { text: 'No. RM', value: 'rmNumber' },
          { text: 'Tipe Penjamin', value: 'guarantorType' },
          { text: 'Unit', value: 'unit' },
          { text: 'Saldo', value: 'Balance' },
          { text: 'Status', value: 'status' },
        ],
      },
      wadiah: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'number',
          },
          { text: 'No. Pendaftaran', value: 'regNumber' },
          { text: 'Nama', value: 'name' },
          { text: 'No. RM', value: 'rmNumber' },
          { text: 'Tipe Penjamin', value: 'guarantorType' },
          { text: 'Unit', value: 'unit' },
          { text: 'Saldo', value: 'balance' },
          { text: 'Status', value: 'status' },
        ],
        item: [
          {
            number: 1,
            regNumber: 1397,
            name: 'Mirza Fatma Azizah',
            rmNumber: '000060',
            guarantorType: 'JAMKESDA',
            unit: 'TRANSIT',
            balance: '20.000',
            status: 'Pasien Terdaftar',
          },
        ],
      },
      page: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-toolbar__content {
    height: 84px !important;
  }
}
</style>
