<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="9" class="px-3">
        <v-row no-gutters class="pa-5 chart-style">
          <div class="text-left">
            <h3 class="font-weight-bold">
              Grafik Komponen Statistik BOR (Bed Occupancy Rate)
            </h3>
            <label class="caption grey--text">
              Rata-rata keseluruhan angka statistik BOR (Bed Occupancy Rate)
              tahun
              {{ thisYear }}
            </label>
          </div>

          <apexcharts
            ref="bor"
            height="300"
            width="750"
            type="line"
            :options="options"
            :series="series"
          ></apexcharts>
        </v-row>
      </v-col>
      <v-col cols="3" class="text-left">
        <label class="text-left body-2 font-weight-bold">Tahun</label>
        <v-select
          dense
          :items="yearRange"
          outlined
          placeholder="Pilih Tahun"
          prepend-inner-icon="mdi-calendar"
          v-model="year"
          @change="
            getBOR();
            getBTO();
            getLOS();
            getTOI();
          "
        ></v-select>
        <label class="body-2 my-5 font-weight-bold">Keterangan</label>
        <v-btn
          depressed
          color="#80c2ff"
          class="text-none white--text mt-3"
          style="border-radius: 10px"
          width="200"
          >Standar BOR : 60 - 80%</v-btn
        >
        <v-btn
          depressed
          color="#f2994a"
          class="text-none white--text my-2"
          style="border-radius: 10px"
          width="200"
          >Standar BTO : 30 Kali</v-btn
        >
        <v-btn
          depressed
          color="#27ae60"
          class="text-none white--text mb-2"
          style="border-radius: 10px"
          width="200"
          >Standar LOS : 3 - 12 Hari</v-btn
        >
        <v-btn
          depressed
          color="#bb6bd9"
          class="text-none white--text"
          style="border-radius: 10px"
          width="200"
          >Standar TOI : 1 - 3 Hari</v-btn
        >
      </v-col>
    </v-row>
    <v-progress-circular
      v-if="loading"
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <v-data-table
      v-else
      :headers="table.headers"
      :items="table.items"
      :items-per-page="5"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr class="font-weight-bold caption">
          <td>{{ item.statisticComponent }}</td>
          <td :class="colorText(item.statisticComponent, item.jan)">
            {{ item.jan }}
            {{
              item.statisticComponent === 'BOR'
                ? '%'
                : item.statisticComponent === 'BTO'
                ? 'kali'
                : 'hari'
            }}
          </td>
          <td :class="colorText(item.statisticComponent, item.feb)">
            {{ item.feb }}
            {{
              item.statisticComponent === 'BOR'
                ? '%'
                : item.statisticComponent === 'BTO'
                ? 'kali'
                : 'hari'
            }}
          </td>
          <td :class="colorText(item.statisticComponent, item.mar)">
            {{ item.mar }}
            {{
              item.statisticComponent === 'BOR'
                ? '%'
                : item.statisticComponent === 'BTO'
                ? 'kali'
                : 'hari'
            }}
          </td>
          <td :class="colorText(item.statisticComponent, item.apr)">
            {{ item.apr }}
            {{
              item.statisticComponent === 'BOR'
                ? '%'
                : item.statisticComponent === 'BTO'
                ? 'kali'
                : 'hari'
            }}
          </td>
          <td :class="colorText(item.statisticComponent, item.may)">
            {{ item.may }}
            {{
              item.statisticComponent === 'BOR'
                ? '%'
                : item.statisticComponent === 'BTO'
                ? 'kali'
                : 'hari'
            }}
          </td>
          <td :class="colorText(item.statisticComponent, item.jun)">
            {{ item.jun }}
            {{
              item.statisticComponent === 'BOR'
                ? '%'
                : item.statisticComponent === 'BTO'
                ? 'kali'
                : 'hari'
            }}
          </td>
          <td :class="colorText(item.statisticComponent, item.jul)">
            {{ item.jul }}
            {{
              item.statisticComponent === 'BOR'
                ? '%'
                : item.statisticComponent === 'BTO'
                ? 'kali'
                : 'hari'
            }}
          </td>
          <td :class="colorText(item.statisticComponent, item.aug)">
            {{ item.aug }}
            {{
              item.statisticComponent === 'BOR'
                ? '%'
                : item.statisticComponent === 'BTO'
                ? 'kali'
                : 'hari'
            }}
          </td>
          <td :class="colorText(item.statisticComponent, item.sep)">
            {{ item.sep }}
            {{
              item.statisticComponent === 'BOR'
                ? '%'
                : item.statisticComponent === 'BTO'
                ? 'kali'
                : 'hari'
            }}
          </td>
          <td :class="colorText(item.statisticComponent, item.oct)">
            {{ item.oct }}
            {{
              item.statisticComponent === 'BOR'
                ? '%'
                : item.statisticComponent === 'BTO'
                ? 'kali'
                : 'hari'
            }}
          </td>
          <td :class="colorText(item.statisticComponent, item.nov)">
            {{ item.nov }}
            {{
              item.statisticComponent === 'BOR'
                ? '%'
                : item.statisticComponent === 'BTO'
                ? 'kali'
                : 'hari'
            }}
          </td>
          <td :class="colorText(item.statisticComponent, item.dec)">
            {{ item.dec }}
            {{
              item.statisticComponent === 'BOR'
                ? '%'
                : item.statisticComponent === 'BTO'
                ? 'kali'
                : 'hari'
            }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import Constant from '@/const';
import alertMixin from '../../../mixin/alertMixin';
import Swal from 'sweetalert2';

const axios = require('axios');

export default {
  name: 'ReportingBOR',
  mixins: [alertMixin],
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      table: {
        headers: [
          {
            text: 'Komponen Statistik',
            value: 'statisticComponent',
            sortable: false,
          },
          { text: 'Jan', value: 'jan', sortable: false, align: 'center' },
          { text: 'Feb', value: 'feb', sortable: false, align: 'center' },
          { text: 'Mar', value: 'mar', sortable: false, align: 'center' },
          { text: 'Apr', value: 'apr', sortable: false, align: 'center' },
          { text: 'Mei', value: 'may', sortable: false, align: 'center' },
          { text: 'Jun', value: 'jun', sortable: false, align: 'center' },
          { text: 'Jul', value: 'jul', sortable: false, align: 'center' },
          { text: 'Agu', value: 'aug', sortable: false, align: 'center' },
          { text: 'Sep', value: 'sep', sortable: false, align: 'center' },
          { text: 'Okt', value: 'oct', sortable: false, align: 'center' },
          { text: 'Nov', value: 'nov', sortable: false, align: 'center' },
          { text: 'Des', value: 'dec', sortable: false, align: 'center' },
        ],
        items: [
          {
            statisticComponent: 'BOR',
            jan: '',
            feb: '',
            mar: '',
            apr: '',
            may: '',
            jun: '',
            jul: '',
            aug: '',
            sep: '',
            oct: '',
            nov: '',
            dec: '',
          },
          {
            statisticComponent: 'BTO',
            jan: '',
            feb: '',
            mar: '',
            apr: '',
            may: '',
            jun: '',
            jul: '',
            aug: '',
            sep: '',
            oct: '',
            nov: '',
            dec: '',
          },
          {
            statisticComponent: 'LOS',
            jan: '',
            feb: '',
            mar: '',
            apr: '',
            may: '',
            jun: '',
            jul: '',
            aug: '',
            sep: '',
            oct: '',
            nov: '',
            dec: '',
          },
          {
            statisticComponent: 'TOI',
            jan: '',
            feb: '',
            mar: '',
            apr: '',
            may: '',
            jun: '',
            jul: '',
            aug: '',
            sep: '',
            oct: '',
            nov: '',
            dec: '',
          },
        ],
      },
      thisYear: new Date().getFullYear(),
      options: {
        chart: {
          id: 'bor',
          background: '#F0F9FF',
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Mei',
            'Jun',
            'Jul',
            'Ags',
            'Sep',
            'Okt',
            'Nov',
            'Des',
          ],
        },
        legend: {
          show: false,
        },
        yaxis: {
          title: {
            text: '%',
          },
          min: 0,
          max: 100,
        },
        annotations: {
          yaxis: [
            {
              y: 60,
              y2: 80,
              borderColor: '#000',
              fillColor: '#FEB019',
              opacity: 0.2,
              label: {
                borderColor: '#80c2ff',
                style: {
                  fontSize: '10px',
                  background: '#80c2ff',
                },
                text: 'Standar BOR',
              },
            },
          ],
        },
      },
      series: [
        {
          name: 'BOR',
          data: [],
        },
      ],
      loading: false,
      year: '',
    };
  },
  mounted() {
    this.getBOR();
    this.getBTO();
    this.getLOS();
    this.getTOI();
  },
  computed: {
    yearRange() {
      let startYear = 2019;
      const currentYear = new Date().getFullYear();
      const years = [];
      while (startYear <= currentYear) {
        years.push(startYear++);
      }
      return years;
    },
  },
  methods: {
    async getBOR() {
      try {
        this.loading = true;
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/master/bed/stat/bor?year=${this.year || this.thisYear}`,
          ),
        );
        const { data } = res.data;
        const series = [];

        for (const [key, value] of Object.entries(data)) {
          this.table.items[0][key] =
            value.bor === `Bed hasn't exist` || value.bor === null
              ? 0
              : value.bor.toFixed(2);
          series.push(
            value.bor === `Bed hasn't exist` || value.bor === null
              ? 0
              : value.bor.toFixed(2),
          );
        }

        this.$refs.bor.updateSeries([
          {
            name: 'BOR',
            data: series,
          },
        ]);
      } catch {
        this.loading = false;
      }
    },
    async getBTO() {
      try {
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/master/bed/stat/bto?year=${this.year || this.thisYear}`,
          ),
        );
        const { data } = res.data;

        for (const [key, value] of Object.entries(data)) {
          this.table.items[1][key] = value === `Bed hasn't exist` ? 0 : value;
        }
      } catch (error) {
        if (error.request) {
          Swal.fire({
            icon: 'error',
            title: 'Tidak dapat menyambung ke server',
            text: 'Periksa koneksi internet anda',
          });
        }
      }
    },
    async getLOS() {
      try {
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/master/bed/stat/los?year=${this.year || this.thisYear}`,
          ),
        );
        const { data } = res.data;
        for (const [key, value] of Object.entries(data)) {
          this.table.items[2][key] =
            value.los === `No discharged patient` || value.los === null
              ? 0
              : Math.floor(value.los);
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Tidak dapat menyambung ke server',
          text: 'Periksa koneksi internet anda',
        });
      }
    },
    async getTOI() {
      try {
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/master/bed/stat/toi?year=${this.year || this.thisYear}`,
          ),
        );
        const { data } = res.data;
        for (const [key, value] of Object.entries(data)) {
          this.table.items[3][key] =
            value.toi === `Bed hasn't existed yet` || value.toi === null
              ? 0
              : value.toi;
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Tidak dapat menyambung ke server',
          text: 'Periksa koneksi internet anda',
        });
      }
    },
    colorText(stat, val) {
      if (
        (stat === 'BOR' && (val < 60 || val > 80)) ||
        (stat === 'BTO' && val !== 30) ||
        (stat === 'LOS' && (val < 3 || val > 12)) ||
        (stat === 'TOI' && (val < 1 || val > 3))
      )
        return 'red--text';
      else return 'black--text';
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-style {
  background: #f0f9ff;
  border-radius: 25px;
  height: 56vh;

  .chart-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    text-align: left;
    padding-left: 20px;
  }
  .chart-subtitle {
    padding-left: 20px;
    text-align: left;
    font-family: Roboto;
    color: #adb5bd;
  }
  .chart-container {
    position: relative;
    margin: auto;
    height: 100%;
    width: 100%;
  }
}
.information {
  font-size: 12px !important;
}
.year {
  font-size: 14px !important;
}

.info-box {
  padding: 0.8em 1em;
  margin: 0.5em 0.5em 0.5em 0;
  width: 160px;
  height: 30px;
  font-size: 12px;

  color: #fff;
  border-radius: 10px;

  &--blue {
    background: #80c2ff;
  }
  &--orange {
    background: #f2994a;
  }
  &--green {
    background: #27ae60;
  }
  &--purple {
    background: #bb6bd9;
  }
}

::v-deep {
  thead tr th span {
    color: #3498db;
  }

  tbody tr td {
    color: rgb(39, 39, 39);
    font-weight: bold;
    font-size: 12px !important;
  }

  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
