import Constant from '@/const';
import axios from 'axios';

const getServiceLaboratory = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/master/service/laboratory`),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getServiceLaboratory;
