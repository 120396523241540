<template>
  <v-card class="main-container">
    <v-card-title class="justify-center">
      <h3>
        <span v-if="state === 'add'">Tambah</span>
        <span v-if="state === 'edit'">Ubah</span>
        <span v-if="state === 'detail'">Detail</span>
        Prosedur
      </h3>
      <v-btn absolute right @click="onClose()" small icon>
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters justify="center">
          <v-col md="8">
            <v-form lazy-validation ref="form">
              <div class="detail-input">
                <div class="detail-input__label">
                  <label
                    for="name"
                    :class="state !== 'detail' ? 'required' : ''"
                  >
                    Kode Prosedur
                  </label>
                </div>
                <v-text-field
                  v-model="form.code"
                  placeholder="Masukkan Kode Prosedur"
                  class="detail-input__input-text"
                  required
                  :readonly="state === 'detail'"
                  :rules="[v => !!v || 'Wajib diisi']"
                />
              </div>
              <div class="detail-input">
                <div class="detail-input__label">
                  <label
                    for="name"
                    :class="state !== 'detail' ? 'required' : ''"
                  >
                    Deskripsi Prosedur
                  </label>
                </div>
                <v-textarea
                  v-model="form.description"
                  placeholder="Tambahkan Deskripsi Prosedur"
                  class="detail-input__input-text"
                  name="deskripsidiagnosa"
                  :readonly="state === 'detail'"
                  outlined
                  height="200"
                  required
                  :rules="[v => !!v || 'Wajib diisi']"
                ></v-textarea>
              </div>
            </v-form>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-none" color="secondary" text @click="onClose()">
            Kembali
          </v-btn>
          <v-btn
            v-if="state !== 'detail'"
            depressed
            class="text-none"
            color="primary"
            @click="handleActionProcedure()"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('MasterProcedure');

export default {
  props: {
    state: {
      type: String,
      default: 'add',
    },
  },
  computed: {
    ...mapGetters(['getForm']),
    form: {
      get() {
        return this.getForm;
      },
    },
  },
  mounted() {
    if (this.state !== 'add') {
      this.resolveGetProcedureById();
    }
  },
  methods: {
    ...mapActions([
      'resolvePutProcedure',
      'resolvePostProcedure',
      'resolveGetProcedureById',
    ]),

    onClose() {
      this.$emit('close-dialog');
    },

    handleActionProcedure() {
      if (!this.$refs.form.validate()) return;
      switch (this.state) {
        case 'add':
          this.resolvePostProcedure().then(status => {
            if (status === 200) {
              Swal.fire('Berhasil', 'Data prosedur sudah masuk', 'success');
            } else {
              Swal.fire('Gagal', 'Data prosedur gagal ditambahkan', 'error');
            }
          });
          break;
        case 'edit':
          this.resolvePutProcedure().then(status => {
            if (status === 200) {
              Swal.fire('Berhasil', 'Data prosedur berhasil diubah', 'success');
            } else {
              Swal.fire('Gagal', 'Data prosedur gagal diubah', 'error');
            }
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    justify-content: flex-start;
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    width: 150px;
  }
}
.required:after {
  content: ' *';
  color: red;
}
</style>
