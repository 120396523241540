<template>
  <v-container class="px-15 pt-5 d-block" fluid>
    <div class="warning-sign" v-if="!isHasIc">
      Pasien belum memiliki daftar Informed Consent, silakan buat formulir
      tersebut terlebih dahulu
    </div>
    <div class="header d-flex justify-space-between">
      <h3>Daftar Formulir Surgical Safety Checklist (SSC)</h3>
      <div class="d-flex justify-end">
        <v-btn
          style="letter-spacing: 0.01vw;"
          class="text-capitalize"
          color="primary"
          :loading="loading"
          outlined
          @click="printSurgicalSafetyChecklistDocument()"
          ><v-icon class="mr-2">mdi-printer</v-icon>Formulir Kosong</v-btn
        >
        <v-btn
          @click="openDialog()"
          style="letter-spacing: 0.01vw;"
          :loading="loading"
          class="ml-3 text-capitalize"
          depressed
          :class="{ 'btn-disabled': !isHasIc }"
          color="primary"
          ><v-icon class="mr-2">mdi-plus</v-icon>Buat Formulir Baru</v-btn
        >
      </div>
    </div>
    <div class="table-container">
      <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
      <v-data-table
        v-else
        :headers="table.headers"
        :items="table.items"
        :items-per-page="table.items.length"
        hide-default-footer
        item-key="id"
      >
        <template v-slot:[`item.click_action`]="{ item }">
          <v-menu left bottom :offset-x="true" :close-on-content-click="true">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </span>
            </template>

            <div class="container-list">
              <div v-for="(itemList, index) in actionItems" :key="index">
                <v-btn
                  @click="
                    !index ? openDialog(item.id) : printExistingSsc(item.id)
                  "
                  text
                  class="item-list text-capitalize"
                >
                  {{ itemList.label }}
                </v-btn>
              </div>
            </div>
          </v-menu>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialog" persistent>
      <surgical-safety-checklist v-if="dialog" :closeForm="closeDialog"
    /></v-dialog>
    <v-snackbar :timeout="4000" v-model="snackbar.add" color="success">
      Formulir Surgical Safety Checklist berhasil disimpan
      <v-btn fab text small @click.native="snackbar.add = false"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import PatientListSsc from './PatientListSsc';
import SurgicalSafetyChecklist from './SurgicalSafetyChecklist.vue';
export default {
  name: 'PatientListSsc',
  ...PatientListSsc,
  components: {
    SurgicalSafetyChecklist,
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-height: 80vh;
  overflow: hidden;

  .table-container {
    max-height: 70vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.4rem;
      border-radius: 1rem;
      background-color: rgb(214, 214, 214);
    }
    &::-webkit-scrollbar-thumb {
      width: 0.4rem;
      border-radius: 1rem;
      background-color: rgb(146, 146, 146);
    }
  }

  .header {
    width: 100%;
    margin-bottom: 0.3vw;
    color: #4e4d4d;

    .btn-disabled {
      background-color: #9e9e9e !important;
    }
  }
}

.container-list {
  padding: 0.5vw;
  background-color: white;

  .item-list {
    border-radius: 3px;
    margin: 0.2vw auto;
    padding: 1vw 0.4vw;
    font-size: 0.7vw;
    font-weight: 400;
    cursor: pointer;
    border: 1px solid #e0e0e0;
    width: 10vw;
    height: 1vw;
    color: rgb(35, 35, 35);
    letter-spacing: 0.00001vw;
    display: flex;
    justify-content: flex-start;
  }
}

.warning-sign {
  background-color: #fff3f3;
  border: 1px solid #eb4646;
  font-size: 0.8vw;
  border-radius: 5px;
  width: fit-content;
  padding: 0.2vw 0.5vw;
  margin-bottom: 1vw;
}

::v-deep .v-data-table-header {
  background-color: rgba(194, 194, 194, 0.3);
}
</style>
