import Constant from '@/const';
import axios from 'axios';

const getPatientRegistrations = async params => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/patient/registration/unit'),
      { params },
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getPatientRegistrations;
