import Constant from '@/const';
import axios from 'axios';

const exportSatuSehatReport = async params => {
  try {
    const { month = '', startDate = '', endDate = '', date = '' } = params;
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/ihs/export?month=${month}&startDate=${startDate}&endDate=${endDate}&date=${date}`,
      ),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default exportSatuSehatReport;
