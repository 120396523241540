<template>
  <v-card id="main">
    <v-progress-circular
      color="primary"
      width="3"
      size="20"
      indeterminate
      v-if="loading"
      id="loading"
    ></v-progress-circular>
    <v-btn @click="close" text fab id="close"><v-icon>mdi-close</v-icon></v-btn>
    <v-card-title class="d-flex justify-center mb-5" primary-title>
      <h2>Pendaftaran Pasien</h2>
    </v-card-title>
    <v-card-text>
      <v-form lazy-validation ref="form" class="form-container">
        <v-row class="px-10">
          <v-col class="grid-display">
            <label>No RM</label>
            <label>{{ rm }}</label>
            <label class="required">Nama Lengkap</label>
            <v-text-field
              dense
              readonly
              :rules="rules.name"
              placeholder="Masukkan Nama Pasien"
              v-model="patient.name"
              required
              hide-details
            ></v-text-field>
            <label class="required">Status Pasien</label>
            <v-select
              dense
              hide-details
              placeholder="Pilih Status"
              :rules="rules.status"
              :items="resource.status"
              v-model="patient.nickname"
              required
              append-icon="mdi-chevron-down"
            ></v-select>
            <label class="required">Jenis Kelamin</label>
            <v-radio-group
              readonly
              :rules="rules.gender"
              v-model="patient.gender"
              required
              row
              class="mt-0 pt-0"
            >
              <v-radio label="Laki-laki" :value="true"></v-radio>
              <v-radio label="Perempuan" :value="false"></v-radio>
            </v-radio-group>
            <label>Tempat Lahir</label>
            <v-text-field
              dense
              placeholder="Masukkan Tempat Lahir"
              class="mt-0"
              v-model="patient.birth.place"
              required
            ></v-text-field>
            <label class="required">Tanggal Lahir</label>
            <v-text-field
              v-model="patient.birth.show"
              :rules="rules.birthDate"
              readonly
              placeholder="dd/mm/yyyy"
              class="mt-0 pt-0"
              maxlength="10"
              required
            ></v-text-field>
            <label>Usia</label>
            <v-text-field
              v-model="patient.age"
              readonly
              class="mt-0 pt-0"
              maxlength="10"
            ></v-text-field>
          </v-col>
          <v-col class="grid-display">
            <label>Jenis Identitas</label>
            <v-select
              dense
              placeholder="Pilih Identitas"
              :items="resource.identityType"
              v-model="patient.identity.type"
              readonly
              append-icon="mdi-chevron-down"
            ></v-select>
            <label>No. Identitas</label>
            <v-text-field
              dense
              readonly
              placeholder="Masukkan Nomor Identitas"
              type="number"
              v-model="patient.identity.number"
            ></v-text-field>
            <label class="required">Jenis Penjamin</label>
            <span class="text-start">BPJS</span>
            <label class="required">Status Pendaftaran</label>
            <span class="text-start">Baru</span>

            <label class="required">Nomor BPJS</label>
            <v-text-field
              dense
              placeholder="Masukkan Nomor BPJS"
              :rules="[...rules.bpjs, ...rules.bpjsStatus]"
              v-model="patient.assurance.number"
              class="mt-0"
              readonly
              :error-messages="bpjs_error"
              :append-icon="checkNo.bpjs ? 'mdi-check-circle' : undefined"
            >
            </v-text-field>
            <label>Nomor NIK</label>
            <v-text-field
              dense
              readonly
              placeholder="Masukkan Nomor NIK"
              v-model="patient.identity.number"
              class="mt-0"
              :error-messages="nik_error"
              required
              type="number"
            ></v-text-field>
            <label v-if="patient.assurance.type === 'bpjs'"
              >Nama Provider</label
            >
            <v-text-field
              v-if="patient.assurance.type === 'bpjs'"
              dense
              class="mt-0"
              v-model="patient.assurance.provider"
              readonly
            >
            </v-text-field>
            <label>PROLANIS</label>
            <v-radio-group
              v-model="patient.assurance.isProlanis"
              row
              class="mt-0 pt-0"
              hide-details="false"
            >
              <v-radio label="Ya" :value="true"></v-radio>
              <v-radio label="Bukan" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-divider class="my-4"></v-divider>
        <v-row class="px-10">
          <v-col class="grid-display">
            <label for="name" class="required">Alamat</label>
            <v-textarea
              outlined
              id="address"
              name="input-7-4"
              placeholder="Masukkan Alamat"
              v-model="patient.address.text"
              :rules="rules.address"
              required
            />

            <label for="status">Provinsi</label>
            <v-autocomplete
              v-model="patient.address.province"
              :items="resource.province"
              @input="getCity"
              dense
              clearable
              item-text="name"
              placeholder="Pilih Provinsi"
              return-object
              append-icon="mdi-chevron-down"
            />

            <label for="status">Kabupaten</label>
            <v-autocomplete
              v-model="patient.address.city"
              :items="resource.city"
              :loading="isLoading"
              @change="getDistrict"
              dense
              clearable
              item-text="name"
              placeholder="Pilih Kabupaten"
              return-object
              append-icon="mdi-chevron-down"
            />

            <label for="status">Kecamatan</label>
            <v-autocomplete
              v-model="patient.address.district"
              :items="resource.district"
              :loading="isLoading"
              @change="getSubDistrict"
              dense
              clearable
              item-text="name"
              placeholder="Pilih Kecamatan"
              return-object
              append-icon="mdi-chevron-down"
            />

            <label for="status">Kelurahan</label>
            <v-autocomplete
              v-model="patient.address.sub_district"
              :items="resource.sub_district"
              :loading="isLoading"
              dense
              clearable
              item-text="name"
              placeholder="Pilih Kelurahan"
              return-object
              append-icon="mdi-chevron-down"
            />

            <label for="status">No.Telepon</label>
            <v-text-field
              v-model="patient.phone_number"
              dense
              type="number"
              placeholder="Masukkan Nomor Telepon"
            />

            <label for="status">Agama</label>
            <v-select
              :items="resource.religion"
              v-model="patient.religion"
              dense
              placeholder="Pilih Agama"
              class="mt-0"
              required
              append-icon="mdi-chevron-down"
            />

            <label for="status">Golongan Darah</label>
            <v-select
              :items="resource.blood_type"
              v-model="patient.blood_type"
              dense
              placeholder="Pilih Golongan Darah"
              class="mt-0"
              required
              append-icon="mdi-chevron-down"
            />
          </v-col>

          <v-col class="grid-display">
            <label for="status">Pendidikan</label>
            <v-select
              :items="resource.education"
              v-model="patient.education"
              append-icon="mdi-chevron-down"
              dense
              placeholder="Pilih Pendidikan"
              class="mt-0"
              required
            />

            <label for="status">Pekerjaan</label>
            <v-text-field
              v-model="patient.profession"
              dense
              placeholder="Pilih Pekerjaan"
              class="mt-0"
              required
            />

            <label for="status">Status Kawin</label>
            <v-select
              :items="resource.maritalStatus"
              v-model="patient.martial_status"
              append-icon="mdi-chevron-down"
              dense
              placeholder="Pilih Status Kawin"
              class="mt-0"
              required
            />

            <label for="name">Nama Ayah</label>
            <v-text-field
              v-model="patient.father"
              dense
              placeholder="Masukkan Nama Ayah"
              class="mt-0"
              required
            />

            <label for="name">Nama Ibu</label>
            <v-text-field
              v-model="patient.mother"
              dense
              placeholder="Masukkan Nama Ibu"
              class="mt-0"
              required
            />

            <label for="status">Etnis</label>
            <v-autocomplete
              :items="resource.ethnicity"
              v-model="patient.ethnicity"
              auto-select-first
              dense
              placeholder="Pilih Etnis"
              class="mt-0"
              clearable
              append-icon="mdi-chevron-down"
            />

            <label for="name">Bahasa yang dikuasai</label>
            <v-text-field
              dense
              placeholder="Bahasa yang dikuasai"
              class="mt-0"
              v-model="patient.language_mastering"
            />

            <label for="name">Hambatan dalam Berkomunikasi</label>
            <v-select
              v-model="patient.communication_obstacles"
              :items="resource.communicationObstacles"
              append-icon="mdi-chevron-down"
              dense
              placeholder="Hambatan dalam Berkomunikasi"
              class="mt-0"
              clearable
            />

            <label for="name">Penyandang Disabilitas</label>
            <v-select
              v-model="patient.disabilities"
              :items="resource.disabilities"
              append-icon="mdi-chevron-down"
              dense
              placeholder="Disabilitas"
              class="mt-0"
              clearable
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="close">Kembali</v-btn>
      <v-btn
        :disabled="!isFormValid"
        @click="next"
        outlined
        lighten-1
        color="primary"
        class="ml-4"
        >Lanjut</v-btn
      >
    </v-card-actions>
    <v-dialog :max-width="1200" v-model="dialog">
      <anamnesis-form />
    </v-dialog>
    <div
      v-if="warning.show"
      class="warning-pcare d-flex flex-row rounded-lg text-left"
    >
      <v-icon class="pa-1 align-start" color="red" dense>
        mdi-alert
      </v-icon>
      <label class="ml-1 warning-pcare-text">
        <strong style="font-size: 16px; line-height: 24px;">{{
          warning.title
        }}</strong>
        <br />
        {{ warning.text }}
      </label>
      <v-icon
        class="pa-1 align-start"
        style="cursor: pointer; width: 27px; height: 27px;"
        dense
        @click="closeWarning()"
      >
        mdi-close
      </v-icon>
    </div>
  </v-card>
</template>

<script>
import Constant from '@/const';
import AnamnesisForm from '../AnamnesisForm.vue';
import Swal from 'sweetalert2';
import { getActivation } from '@/fetchApi/PCare';
import { scrollToEmptyInput } from '@/helper/index';

const axios = require('axios');
const moment = require('moment');
export default {
  name: 'SocialDataMJKN',
  components: { AnamnesisForm },
  props: {
    rm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isLoading: false,
      isBpjsActive: true,
      warning: {
        text: '',
        show: false,
        title: '',
      },
      patient: {
        name: '',
        nickname: '',
        gender: '',
        age: '',
        assurance: {
          type: '',
          name: '',
          number: '',
          isProlanis: false,
          isBpjsBased: true,
          provider: '',
        },
        identity: {
          type: '',
          number: '',
        },
        birth: {
          place: '',
          date: '',
        },
        address: {
          province: '',
          city: '',
          district: '',
          sub_district: '',
          text: '',
        },
        language_mastering: '',
        communication_obstacles: null,
        disabilities: null,
        religion: '',
        blood_type: '',
        education: '',
        profession: '',
        father: '',
        mother: '',
        martial_status: '',
        phone_number: '',
        ethnicity: '',
      },
      resource: {
        status: [
          { text: 'Nyonya', value: 'nyonya' },
          { text: 'Tuan', value: 'tuan' },
          { text: 'Nona', value: 'nona' },
          { text: 'Saudara', value: 'saudara' },
          { text: 'Anak', value: 'anak' },
          { text: 'Bayi', value: 'bayi' },
        ],
        identityType: ['Tidak Ada Identitas', 'KTP', 'SIM', 'Passport'],
        province: [],
        city: [],
        district: [],
        sub_district: [],
        religion: [
          { text: 'Islam', value: 'islam' },
          { text: 'Kristen', value: 'kristen' },
          { text: 'Budha', value: 'budha' },
          { text: 'Hindu', value: 'hindu' },
          { text: 'Katolik', value: 'katolik' },
        ],
        blood_type: [
          { text: 'A', value: 'a' },
          { text: 'AB', value: 'ab' },
          { text: 'B', value: 'b' },
          { text: 'O', value: 'o' },
        ],
        education: [
          { text: 'Tidak Sekolah', value: '-' },
          { text: 'TK', value: 'tk' },
          { text: 'SD', value: 'sd' },
          { text: 'SMP', value: 'smp' },
          { text: 'SMA', value: 'sma' },
          { text: 'Diploma I', value: 'diploma1' },
          { text: 'Diploma II', value: 'diploma2' },
          { text: 'Diploma III', value: 'diploma3' },
          { text: 'Diploma IV', value: 'diploma4' },
          { text: 'Sarjana', value: 'sarjana' },
          { text: 'Magister', value: 'magister' },
          { text: 'Doktor', value: 'doktor' },
        ],
        maritalStatus: [
          { text: 'Belum Kawin', value: 'belum kawin' },
          { text: 'Kawin', value: 'kawin' },
          { text: 'Duda', value: 'duda' },
          { text: 'Janda', value: 'janda' },
        ],
        ethnicity: [
          { text: 'Suku Bali', value: 'bali' },
          { text: 'Suku Banjar', value: 'banjar' },
          { text: 'Suku Batak', value: 'batak' },
          { text: 'Suku Betawi', value: 'betawi' },
          { text: 'Suku Bugis', value: 'bugis' },
          { text: 'Suku Cina, RRC, Taiwan', value: 'cina' },
          { text: 'Suku Cirebon', value: 'cirebon' },
          { text: 'Suku Dayak', value: 'dayak' },
          { text: 'Suku Gorontalo', value: 'gorontalo' },
          { text: 'Suku Jawa', value: 'jawa' },
          { text: 'Suku Madura', value: 'madura' },
          { text: 'Suku Makassar', value: 'makassar' },
          { text: 'Suku Melayu', value: 'melayu' },
          { text: 'Suku Minahasa', value: 'minahasa' },
          { text: 'Suku Minangkabau', value: 'minangkabau' },
          { text: 'Suku Nias', value: 'nias' },
          { text: 'Suku Sasak', value: 'sasak' },
          { text: 'Suku Asal Aceh', value: 'aceh' },
          { text: 'Suku Asal Banten', value: 'banten' },
          { text: 'Suku Asal Kalimantan lainnya', value: 'kalimantan' },
          { text: 'Suku Asal Maluku', value: 'maluku' },
          { text: 'Suku Asal Nusa Tenggara Timur', value: 'ntt' },
          { text: 'Suku Asal Papua', value: 'papua' },
          { text: 'Suku Asal Sulawesi lainnya', value: 'sulawesi' },
          { text: 'Suku Asal Sumatera lainnya', value: 'sumatera' },
          { text: 'Suku Luar Negeri', value: 'ln' },
          { text: 'Suku Asal Jambi', value: 'jambi' },
          { text: 'Suku Asal Lampung', value: 'lampung' },
          { text: 'Suku Asal Sumatera Selatan', value: 'sumatra selatan' },
          { text: 'Suku Nusa Tenggara Barat lainnya', value: 'ntb' },
          { text: 'Suku Sunda', value: 'sunda' },
        ],
        communicationObstacles: [
          { text: 'Tidak Ada', value: '' },
          { text: 'Bahasa', value: 'bahasa' },
          { text: 'Lingkungan', value: 'lingkungan' },
          { text: 'Fisik', value: 'fisik' },
          { text: 'Psikologi', value: 'psikologi' },
        ],
        disabilities: [
          { text: 'Tidak ada', value: 0 },
          { text: 'Fisik', value: 1 },
          { text: 'Mental', value: 2 },
          { text: 'Fisik dan Mental', value: 3 },
        ],
      },
      checkNo: {
        bpjs: false,
        nik: false,
      },
      bpjs_error: [],
      nik_error: [],
      rules: {
        name: [v => !!v || 'Perlu mengisi nama'],
        gender: [v => v !== '' || 'Perlu memilih jenis kelamin'],
        status: [v => !!v || 'Perlu memilih status pasien'],
        bpjsStatus: [
          () => this.isBpjsActive || 'Status Keanggotaan: Tidak Aktif',
        ],
        birthPlace: [v => !!v || 'Perlu mengisi tempat lahir'],
        birthDate: [
          v => !!v || 'Perlu mengisi tanggal lahir',
          v => this.checkDateFormat(v) || 'Tanggal Tidak Valid',
        ],
        religion: [v => !!v || 'Perlu memilih agama'],
        bpjs: [v => !!v || 'Perlu mengisi nomor BPJS'],
        address: [
          v => !!v || 'Perlu mengisi alamat',
          v => (v && v.length <= 300) || 'Alamat maksimal 300 karakter',
        ],
      },
    };
  },
  computed: {
    isFormValid() {
      return (
        this.patient.name &&
        this.patient.nickname &&
        this.patient.assurance.number &&
        this.patient.birth.date
      );
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.getRegion();
      await this.getPatientData().finally(() => {
        this.checkActivation();
        this.getCity();
        this.getDistrict();
        this.getSubDistrict();
      });
    });
  },
  methods: {
    async getPatientData() {
      if (!this.rm) return;
      try {
        this.loading = true;
        const response = await axios.get(
          Constant.apiUrl.concat(`/patient/emr/social/${this.rm}`),
        );
        const { data } = response.data;
        this.patient = {
          ...this.patient,
          idEmr: data.id_emr,
          idRegistration: this.$store.getters.selectedPatient.id_registration,
          isCheckIn: true,
          address: {
            city: data.address?.city || { id: '', name: '' },
            district: data.address?.district || { id: '', name: '' },
            province: data.address?.province || { id: '', name: '' },
            sub_district: data.address?.sub_district || { id: '', name: '' },
            text: data.address?.text || '',
          },
          phone_number: data.phone_number,
          ethnicity: data.ethnicity,
          language_mastering: data.language_mastering,
          communication_obstacles: data.communication_obstacles,
          disabilities:
            Number(data.disabilities) === null ? 0 : Number(data.disabilities),
          religion: data.religion,
          blood_type: data.blood_type,
          education: data.education,
          profession: data.profession,
          father: data.father,
          mother: data.mother,
          martial_status: data.martial_status,
          name: data.name,
          nickname: data.nickname,
          gender: data.gender,
          age: data.age,
          assurance: {
            ...this.patient.assurance,
            type: data.assurance?.type,
            name: data.assurance?.name,
            number: data.assurance?.number,
            isProlanis: data.assurance?.isProlanis,
            isBpjsBased: !data.identity?.number && data.identity.type !== 'KTP',
          },
          identity: {
            number: data.identity?.number,
            type: data.identity?.type,
          },
          birth: {
            place: data.birth?.place,
            date: data.birth?.date,
            show: moment(data.birth?.date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          },
        };
      } catch (error) {
        if (error) {
          this.patient = {
            name: '',
            nickname: '',
            gender: '',
            age: '',
            assurance: {
              type: '',
              name: '',
              number: '',
              isProlanis: false,
              isBpjsBased: true,
              provider: '',
            },
            identity: {
              type: '',
              number: '',
            },
            birth: {
              place: '',
              date: '',
            },
          };
        }
      } finally {
        this.loading = false;
      }
    },
    checkDateFormat(date) {
      const dateInput = moment(date, 'DD/MM/YYYY');
      return dateInput.isValid();
    },
    closeWarning() {
      this.warning.show = false;
    },
    async checkActivation() {
      try {
        if (
          this.patient.assurance?.number === '' ||
          !this.patient.assurance?.number
        )
          return;

        const response = await getActivation(
          'bpjs',
          this.patient.assurance.number,
        );
        const { data } = response.data;
        this.patient.assurance.provider = data.kdProviderPst.nmProvider;

        if (!data.aktif) {
          this.isBpjsActive = false;
          this.checkNo.bpjs = false;
        } else {
          this.checkNo.bpjs = true;
        }
      } catch (error) {
        if (error.response?.status >= 500) {
          this.pcareServerError = true;
          this.warning = {
            show: true,
            title: 'Bridging BPJS Error',
            text:
              'Anda masih bisa melanjutkan pendaftaran. Silakan kirim ulang setelah menyelesaikannya',
          };
        }
        if (error.response?.status < 500) {
          Swal.fire(
            'No BPJS Tidak Ditemukan',
            'Anda tidak dapat melakukan pendaftaran dengan No BPJS ini',
            'info',
          );
        }
      }
    },

    async getRegion() {
      this.isLoading = true;
      await axios
        .get(Constant.apiUrl.concat(`/master/region/province?search=`))
        .then(response => {
          var province = response.data.data;
          this.resource.province = province.map(prov => {
            return {
              id: prov._id.toString(),
              name: prov.name,
            };
          });
        })
        .finally(() => (this.isLoading = false));
    },

    async getCity() {
      const provId = this.patient.address.province.id;
      this.isLoading = true;
      await axios
        .get(
          `${Constant.apiUrl}/master/region/regency?province=${provId}&search=`,
        )
        .then(response => {
          var cityLoc = response.data.data;
          this.resource.city = cityLoc.map(city => {
            return {
              id: city._id.toString(),
              name: city.name,
            };
          });
        })
        .catch(() => {
          this.resource.city = [];
        })
        .finally(() => (this.isLoading = false));
    },

    async getDistrict() {
      const cityId = this.patient.address.city.id;
      this.isLoading = true;
      await axios
        .get(`${Constant.apiUrl}/master/region/district?regency=${cityId}`)
        .then(response => {
          if (response.status === 200) {
            var districtLoc = response.data.data;
            this.resource.district = districtLoc.map(district => {
              return {
                id: district._id.toString(),
                name: district.name,
              };
            });
          }
        })
        .finally(() => (this.isLoading = false));
    },

    async getSubDistrict() {
      const districtId = this.patient.address.district.id;
      this.isLoading = true;
      await axios
        .get(
          `${Constant.apiUrl}/master/region/subdistrict?district=${districtId}`,
        )
        .then(response => {
          if (response.status === 200) {
            var subdistrictLoc = response.data.data;
            this.resource.sub_district = subdistrictLoc.map(subdistrict => {
              return {
                id: subdistrict._id.toString(),
                name: subdistrict.name,
              };
            });
          }
        })
        .finally(() => (this.isLoading = false));
    },

    close() {
      this.$emit('close-form');
    },
    async next() {
      if (await this.$refs.form.validate()) {
        this.$emit('mjkn-next', this.patient);
      } else {
        scrollToEmptyInput();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#main {
  overflow: hidden;
  position: relative;
  padding: 1vw 2rem;
  min-height: 90vh;

  #close {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  }
  #loading {
    position: absolute;
    top: 0.5rem;
    left: 1rem;
  }
  .grid-display {
    display: grid;
    grid-template-columns: 45% 55%;
    row-gap: 2rem;
    padding: 2rem;

    label {
      font-weight: 600;
      text-align: start;
    }
  }

  .required:after {
    content: ' *';
    color: red;
  }
}

.warning-pcare {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 7vh;
  background-color: #fff3f3;
  border: 0.5px solid #bdbdbd;
  max-width: 570px;
  padding: 16px 16px 16px 20px;
}

.form-container {
  height: 68vh;
  overflow-x: hidden;
  width: 100%;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 4px;
    background-color: rgb(242, 242, 242);
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 4px;
    background-color: rgb(207, 207, 207);
  }
}
</style>
