<template>
  <v-container class="pt-10 pl-0 pr-3" fluid>
    <v-form ref="form">
      <v-row>
        <v-col class="pr-4">
          <skeleton :isLoading="isLoading">
            <label class="required">Nama Petugas</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-combobox
              append-icon="mdi-chevron-down"
              placeholder="Pilih petugas"
              v-model="form.staff"
              :items="resource.staff"
              :loading="loading.staff"
              :rules="rule"
              :readonly="isEMR"
              item-text="name"
              item-value="id"
              :clearable="!isEMR"
              single-line
              dense
          /></skeleton>
        </v-col>
        <v-col class="pl-4">
          <skeleton :isLoading="isLoading">
            <label class="required">Waktu Pengisian</label>
          </skeleton>
          <div class="date-grid">
            <skeleton :isLoading="isLoading">
              <v-text-field v-model="form.date" readonly single-line dense
            /></skeleton>
            <skeleton :isLoading="isLoading">
              <v-text-field
                v-model="form.time"
                :readonly="form.isSaved || isEMR"
                :rules="rule"
                type="time"
                @keydown="preventDefaultBehaviour($event)"
                single-line
                dense
            /></skeleton>
          </div>
        </v-col>
      </v-row>
      <v-divider class="my-10" />
      <v-row>
        <v-col cols="6" class="pr-4">
          <skeleton :isLoading="isLoading">
            <label class="required">Tiba di Ruangan</label>
          </skeleton>
          <div class="date-grid">
            <skeleton :isLoading="isLoading">
              <v-menu
                dense
                ref="menu_arrived"
                v-model="menu.arrived"
                :return-value.sync="form.arrived.date.formatted"
                :disabled="isEMR"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    v-model="form.arrived.date.formatted"
                    :rules="rule"
                    readonly
                    dense
                    single-line
                    append-icon="mdi-chevron-down"
                    placeholder="DD-MM-YYYY"
                  />
                </template>
                <v-date-picker
                  no-title
                  scrollable
                  :min="registeredAtInpatientUnit.date"
                  v-model="form.arrived.date.raw"
                  @input="
                    $refs.menu_arrived.save(formatDate(form.arrived.date.raw))
                  "
                ></v-date-picker>
              </v-menu>
            </skeleton>
            <skeleton :isLoading="isLoading">
              <v-text-field
                :readonly="isEMR"
                v-model="form.arrived.time"
                :rules="[...rules.arrived, ...rule]"
                type="time"
                @keydown="preventDefaultBehaviour($event)"
                ref="arrived_time"
                single-line
                dense
              />
            </skeleton>
          </div>
        </v-col>
      </v-row>
      <v-divider class="my-10" />
      <skeleton :isLoading="isLoading">
        <h4>PENGKAJIAN DIMULAI</h4>
      </skeleton>
      <v-row class="pt-4">
        <v-col class="pr-4">
          <skeleton :isLoading="isLoading">
            <label class="required">Waktu Pengkajian Dimulai</label></skeleton
          >
          <div class="date-grid">
            <skeleton :isLoading="isLoading">
              <v-menu
                dense
                ref="menu_start"
                v-model="menu.start"
                :disabled="isEMR"
                :close-on-content-click="true"
                :return-value.sync="form.assessmentStart.date.formatted"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    v-model="form.assessmentStart.date.formatted"
                    :rules="rule"
                    readonly
                    dense
                    single-line
                    append-icon="mdi-chevron-down"
                    placeholder="DD-MM-YYYY"
                  />
                </template>
                <v-date-picker
                  no-title
                  scrollable
                  :min="today"
                  v-model="form.assessmentStart.date.raw"
                  @input="
                    $refs.menu_start.save(
                      formatDate(form.assessmentStart.date.raw),
                    )
                  "
                ></v-date-picker> </v-menu
            ></skeleton>
            <skeleton :isLoading="isLoading">
              <v-text-field
                v-model="form.assessmentStart.time"
                :readonly="isEMR"
                :rules="rule"
                @keydown="preventDefaultBehaviour($event)"
                type="time"
                single-line
                dense
              />
            </skeleton>
          </div>
          <skeleton :isLoading="isLoading">
            <label class="required">Diperoleh dari</label></skeleton
          >
          <skeleton :isLoading="isLoading">
            <v-text-field
              v-model="form.assessmentStart.obtainedFrom"
              :readonly="isEMR"
              :rules="rule"
              placeholder="Masukkan nama jelas"
              single-line
              dense
            ></v-text-field>
          </skeleton>
        </v-col>
        <v-col class="pl-4">
          <skeleton :isLoading="isLoading">
            <label class="required">Hubungan dengan Pasien</label>
          </skeleton>
          <skeleton :isLoading="isLoading">
            <v-combobox
              placeholder="Pilih hubungan"
              append-icon="mdi-chevron-down"
              v-model="form.assessmentStart.relationWithPatient"
              :items="resource.relation"
              :readonly="isEMR"
              :rules="rule"
              :clearable="!isEMR"
              single-line
              dense
              :menu-props="{
                maxHeight: 220,
              }"
          /></skeleton>
        </v-col>
      </v-row>
      <v-divider class="my-10" />
      <v-row>
        <v-col>
          <skeleton :isLoading="isLoading">
            <label class="required">Cara Masuk</label></skeleton
          >
          <skeleton :isLoading="isLoading">
            <v-combobox
              placeholder="Pilih cara masuk"
              append-icon="mdi-chevron-down"
              v-model="form.entryMethod"
              :readonly="isEMR"
              :items="resource.enter"
              :rules="rule"
              :clearable="!isEMR"
              single-line
              dense
          /></skeleton>
        </v-col>
        <v-col>
          <skeleton :isLoading="isLoading">
            <label class="required">Asal Masuk</label></skeleton
          >
          <skeleton :isLoading="isLoading">
            <v-radio-group
              v-model="form.entryFrom"
              :rules="rule"
              :readonly="isEMR"
              light
              dense
              row
            >
              <v-radio light label="IGD Klinik" value="IGD Klinik"></v-radio>
              <v-radio
                light
                label="Poli Klinik Umum"
                value="Poli Klinik Umum"
              ></v-radio>
              <v-radio
                light
                label="Unit VK"
                value="Unit VK"
              ></v-radio> </v-radio-group
          ></skeleton>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('EarlyAssessmentInpatientStore');
const { mapGetters: mapMedicalResumeGetters } = createNamespacedHelpers(
  'MedicalResumeStore',
);
import { getAllStaff } from '@/fetchApi/MasterData/Staff';
import Skeleton from '../../../../../SharedComponent/Skeleton.vue';

const moment = require('moment-timezone');

export default {
  name: 'InitialAssessment',
  components: {
    Skeleton,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rule: !this.isEMR ? [v => !!v || 'Wajib diisi'] : [],
      menu: {
        arrived: null,
        start: null,
      },
      resource: {
        staff: [],
        relation: [
          'Ayah',
          'Ibu',
          'Saudara Kandung',
          'Kakek',
          'Nenek',
          'Suami',
          'Istri',
          'Anak',
          'Pasien Sendiri',
        ],
        enter: [
          'Jalan tanpa bantuan',
          'Jalan dengan bantuan',
          'Menggunakan kursi roda',
          'Menggunakan stretcher',
        ],
      },
      loading: {
        staff: false,
      },
      rules: {
        arrived: [
          () => {
            if (this.form.arrived.date.formatted !== this.getForm.date.in)
              return true;
            const timeIn = moment(this.getForm.time.in, 'HH:mm');
            const inputtedTime = moment(this.form.arrived.time, 'HH:mm');
            return (
              inputtedTime.isAfter(timeIn) ||
              'Jam tidak boleh sebelum jam pasien masuk ke rawat inap'
            );
          },
        ],
        assessmentStart: [
          () => {
            if (this.form.assessmentStart.date.raw !== this.today) return true;
            const inputtedTime = moment(
              this.form.assessmentStart.time,
              'HH:mm',
            );
            const now = moment().format();
            return (
              inputtedTime.isAfter(now) ||
              'Jam tidak boleh sebelum jam saat ini'
            );
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['getInitialAssessment', 'getInitialState']),
    ...mapMedicalResumeGetters(['getForm']),
    registeredAtInpatientUnit: {
      get() {
        return {
          date: moment(this.getForm.date.in, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          time: moment(this.getForm.time.in, 'HH:mm').format(),
        };
      },
    },
    form: {
      get() {
        return this.getInitialAssessment;
      },
    },
    initialState: {
      get() {
        return this.getInitialState;
      },
    },
    today: {
      get() {
        return moment().format('YYYY-MM-DD');
      },
    },
  },
  mounted() {
    this.getStaff();
    this.autofill();
  },
  methods: {
    preventDefaultBehaviour(event) {
      if (event.key === ' ') {
        event.preventDefault();
      }
    },
    autofill() {
      this.form.date = moment().format('DD-MM-YYYY');
      this.form.time = moment().format('HH:mm');
      this.initialState.initialAssessment.date = moment().format('DD-MM-YYYY');
      this.initialState.initialAssessment.time = moment().format('HH:mm');
      if (
        !this.$store.getters.userLoggedIn.role.some(
          item => item.toLowerCase() === 'perawat',
        )
      ) {
        return;
      }
      this.form.staff = {
        name: this.$store.getters.userLoggedIn.name,
        id: this.$store.getters.userLoggedIn.id,
      };
      this.initialState.initialAssessment.staff = {
        name: this.$store.getters.userLoggedIn.name,
        id: this.$store.getters.userLoggedIn.id,
      };
    },
    async getStaff() {
      try {
        this.loading.staff = true;
        const params = {
          halaman: 1,
          itemCount: '',
          sortBy: '',
          keyword: '',
        };
        const response = await getAllStaff(params);
        this.resource.staff = response.data
          .filter(item =>
            item.role.role.some(
              item =>
                item.toLowerCase() === 'perawat' ||
                item.toLowerCase() === 'bidan',
            ),
          )
          .map(item => {
            return {
              name: item.detail.name,
              id: item._id,
            };
          });
      } catch {
        this.resource.staff = [];
      } finally {
        this.loading.staff = false;
      }
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  text-align: start;
  padding: 0 0.75vw;

  .date-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1.5vw;
  }
}

.required:after {
  content: ' *';
  color: red;
}

label {
  font-weight: 500;
  font-size: 0.8vw;
}

h4 {
  font-weight: 400;
  color: #137bc0;
  text-align: start;
  margin-bottom: 0.7vw;
}

::v-deep
  .v-text-field__slot
  input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
