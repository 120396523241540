<template>
  <div class="income">
    <div class="meta">
      <h3 class="text-left">Jumlah Pendapatan</h3>
      <p class="pa-0 ma-0 text-left grey--text">
        Perbandingan pendapatan
        {{ $store.getters.userLoggedIn.clinic.name }} bulan ini dengan bulan
        lalu
      </p>
    </div>
    <div v-if="!data.isRefreshing" class="amount">
      {{ currency(data.income.current) }}
    </div>
    <div v-if="data.isRefreshing" class="amount">
      <v-progress-circular
        v-if="data.isRefreshing || data.isLoading.income"
        color="white"
        size="30"
        indeterminate
      />
    </div>
    <div class="traffic-data">
      <h3 class="text-center">TRAFFIC</h3>
      <div class="traffic">
        <p class="ma-0 pa-0 text-left grey--text">
          Bulan Lalu
        </p>
        <p class="ma-0 pa-0 font-weight-bold text-right">
          {{ currency(data.income.lastMonth) }}
        </p>
        <p class="ma-0 pa-0 text-left grey--text">
          Selisih
        </p>
        <p class="ma-0 pa-0 font-weight-bold text-right">
          {{ currency(data.income.diff) }}
        </p>
        <p class="ma-0 pa-0 text-left grey--text">
          Trend
        </p>
        <div class="text-right">
          <v-chip
            color="success"
            :class="{
              up: data.income.trend === 'Meningkat',
              down: data.income.trend === 'Menurun',
              static: data.income.trend === 'Statis',
            }"
            >{{ data.income.trend }}</v-chip
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatMixin from '@/mixin/formatMixin';

export default {
  name: 'IncomeCard',
  mixins: [formatMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1904px) {
  .income {
    display: grid;
    grid-template-rows: repeat(2, 1fr) 1.5fr;
    min-height: 50vh;
    border-radius: 8px;
    border: 2.5px solid #e0e0e0;
    row-gap: 2.7rem;
    padding: 2rem;
    font-size: 1.1rem;

    .amount {
      background-color: #137bc0;
      display: flex;
      height: 55%;
      border-radius: 4px;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 1.5rem;
      font-weight: 500;
    }

    .traffic-data {
      .traffic {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 1.1rem;
        .up {
          max-width: 100px;
          font-weight: 600;
          font-size: 14px;
          justify-content: center;
          border-radius: 5px;
          background-color: #e5fff0 !important;
          border: 0.5px solid #2d965a !important;
          color: #2d965a !important;
        }
        .down {
          max-width: 100px;
          font-weight: 600;
          font-size: 14px;
          justify-content: center;
          border-radius: 5px;
          background-color: #fff2f2 !important;
          border: 0.5px solid #eb4747 !important;
          color: #eb4747 !important;
        }
        .static {
          max-width: 100px;
          font-weight: 600;
          font-size: 14px;
          justify-content: center;
          border-radius: 5px;
          background-color: #f0f9ff !important;
          border: 0.5px solid #2b81d6 !important;
          color: #2b81d6 !important;
        }
      }
      h3 {
        margin-bottom: 1rem;
      }
    }
  }
}
@media screen and (max-width: 1903px) {
  .income {
    display: grid;
    grid-template-rows: repeat(2, 1fr) 1.5fr;
    min-height: 50vh;
    border-radius: 8px;
    border: 2.5px solid #e0e0e0;
    padding: 2rem;
    font-size: 0.9rem;

    .amount {
      background-color: #137bc0;
      display: flex;
      height: 35%;
      border-radius: 4px;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 1.2rem;
      font-weight: 500;
    }

    .traffic-data {
      .traffic {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 0.8rem;

        .chip {
          max-width: 100px;
          font-weight: 600;
          font-size: 14px;
          justify-content: center;
          border-radius: 5px;
          background-color: #e5fff0 !important;
          border: 0.5px solid #2d965a !important;
          color: #2d965a !important;
        }
        .up {
          max-width: 100px;
          font-weight: 600;
          font-size: 14px;
          justify-content: center;
          border-radius: 5px;
          background-color: #e5fff0 !important;
          border: 0.5px solid #2d965a !important;
          color: #2d965a !important;
        }
        .down {
          max-width: 100px;
          font-weight: 600;
          font-size: 14px;
          justify-content: center;
          border-radius: 5px;
          background-color: #fff2f2 !important;
          border: 0.5px solid #eb4747 !important;
          color: #eb4747 !important;
        }
      }
      h3 {
        margin-bottom: 2.2rem;
      }
    }
  }
  .visit-data {
    display: grid;
    width: 90%;
    grid-template-columns: 1fr 3px 1fr;
    column-gap: 10px;
    row-gap: 1px;
  }
}
</style>
