<template>
  <v-row class="px-4 info-header">
    <v-col class="grid-meta">
      <label><skeleton :isLoading="loading">No. RM</skeleton></label>

      <span>
        <skeleton :isLoading="loading"> {{ patient.rmNumber }}</skeleton>
      </span>

      <label><skeleton :isLoading="loading">Nama</skeleton></label>
      <span>
        <skeleton :isLoading="loading">
          {{ patient.name }} ({{ patient.gender }})
        </skeleton>
      </span>

      <label><skeleton :isLoading="loading">Tanggal Lahir</skeleton></label>

      <span
        ><skeleton :isLoading="loading">
          {{ patient.birthDate }} ({{ patient.age }}
          Tahun)
        </skeleton>
      </span>
    </v-col>
    <v-col class="grid-meta">
      <label><skeleton :isLoading="loading">Unit</skeleton></label>
      <span
        ><skeleton :isLoading="loading">
          {{ patient.unit }}
        </skeleton></span
      ><label><skeleton :isLoading="loading">Tipe Penjamin</skeleton></label>
      <span
        ><skeleton :isLoading="loading">
          {{ patient.assurance }}
        </skeleton></span
      >
      <label><skeleton :isLoading="loading">DPJP</skeleton></label
      ><span
        ><skeleton :isLoading="loading">{{ patient.doctor }}</skeleton></span
      ></v-col
    ><v-col class="text-start">
      <span class="font-weight-bold red--text">
        <skeleton :isLoading="loading">RIWAYAT ALERGI</skeleton>
      </span>
      <v-col class="text-start px-0">
        <skeleton :isLoading="loading">
          <div class="allergies d-flex flex-row">
            <div class="allergy" v-if="allergies.drug?.length">
              <label>Alergi Obat</label>
              <p class="pa-0 mx-0">
                {{ allergies.drug.join(', ') }}
              </p>
            </div>
            <div class="allergy" v-if="allergies.food?.length">
              <label>Alergi Makanan</label>
              <p class="pa-0 mx-0">
                {{ allergies.food.join(', ') }}
              </p>
            </div>
            <div class="allergy" v-if="allergies.air?.length">
              <label>Alergi Udara</label>
              <p class="pa-0 mx-0">
                {{ allergies.air.join(', ') }}
              </p>
            </div>
            <div class="allergy" v-if="allergies.other?.length">
              <label>Alergi Lainnya</label>
              <p class="pa-0 mx-0">
                {{ allergies.other.join(', ') }}
              </p>
            </div>
          </div>
        </skeleton>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Skeleton from '../../SharedComponent/Skeleton.vue';

const { mapGetters } = createNamespacedHelpers('DrugRequestStore');
const { mapGetters: mapGettersData } = createNamespacedHelpers(
  'HeaderPharmacyStore',
);

export default {
  name: 'PharmacyHeader',
  components: {
    Skeleton,
  },
  computed: {
    ...mapGetters(['getLoading']),
    ...mapGettersData(['getAllergies', 'getPatient']),
    loading: {
      get() {
        return this.getLoading.pharmacy;
      },
    },
    allergies: {
      get() {
        return this.getAllergies;
      },
    },
    patient: {
      get() {
        return this.getPatient;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.info-header {
  label {
    color: #9e9e9e;
  }

  display: grid;
  grid-template-columns: 0.9fr 0.9fr 2fr;
  font-size: 0.85vw;
  font-weight: 400;
}

.allergies {
  text-align: start;

  .allergy {
    margin-right: 1.3vw;
  }

  label {
    color: #9e9e9e;
  }
  p {
    color: #404040;
  }
}

.grid-meta {
  display: grid;
  grid-template-columns: 28% 72%;
  text-align: start;
  gap: 0.3vw;
}
</style>
