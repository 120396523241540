<template>
  <v-form ref="form" lazy-validation>
    <v-row class="px-15 mb-3">
      <v-col class="grid">
        <label class="required">Waktu Pelaksanaan</label>
        <div class="d-flex">
          <v-text-field
            dense
            readonly
            class="mr-2"
            v-model="signOut.time.date"
          />
          <v-text-field
            @input="formatTime(signOut.time.time)"
            :readonly="isDetail || !isEditable"
            dense
            class="ml-2"
            v-model="signOut.time.time"
            placeholder="00:00"
            :rules="[...rules, ...timeRule]"
          />
        </div>
        <label class="required">Petugas yang Mengisi</label>
        <v-autocomplete
          dense
          single-line
          :clearable="isEditable"
          :readonly="!isEditable"
          :rules="rules"
          :items="resource.doctor.concat(resource.staff)"
          item-text="name"
          append-icon="mdi-chevron-down"
          placeholder="Petugas yang Mengisi"
          v-model="signOut.staffFill"
          return-object
        />
      </v-col>
      <v-col class="grid">
        <label class="required">Dokter Pelaksana Tindakan</label>
        <v-autocomplete
          dense
          single-line
          :clearable="isEditable"
          :readonly="!isEditable"
          :rules="rules"
          :items="resource.doctor"
          item-text="name"
          append-icon="mdi-chevron-down"
          placeholder="Dokter Pelaksana Tindakan"
          v-model="signOut.doctor"
          return-object
        />
        <label class="required">Petugas Pendamping</label>
        <v-autocomplete
          dense
          single-line
          :clearable="isEditable"
          :readonly="!isEditable"
          :rules="rules"
          :items="resource.staff"
          v-model="signOut.staff"
          item-text="name"
          append-icon="mdi-chevron-down"
          placeholder="Petugas Pendamping"
          return-object
        />
      </v-col>
    </v-row>
    <v-divider class="mb-8"></v-divider>
    <v-row class="px-15">
      <v-col class="grid">
        <label class="required pr-15"
          >Apakah nama tindakan prosedur telah dikonfirmasi?</label
        >
        <v-radio-group
          :readonly="!isEditable"
          :rules="rules"
          row
          v-model="signOut.procedureActionName"
        >
          <v-radio label="Sudah" :value="true"></v-radio>
          <v-radio label="Belum" :value="false"></v-radio>
        </v-radio-group>
        <label class="required pr-15"
          >Apakah perhitungan jumlah instrumen, kassa dan jarum sudah
          tepat?</label
        >
        <v-radio-group
          :readonly="!isEditable"
          :rules="rules"
          row
          v-model="signOut.instrument"
        >
          <v-radio label="Sudah" :value="true"></v-radio>
          <v-radio label="Belum" :value="false"></v-radio>
        </v-radio-group>
        <label class="required pr-15"
          >Dari tindakan yang dilakukan, Apakah membutuhkan pengambilan
          specimen?</label
        >
        <v-radio-group
          :readonly="!isEditable"
          :rules="rules"
          row
          v-model="signOut.speciment"
        >
          <v-radio label="Ya" :value="true"></v-radio>
          <v-radio label="Tidak" :value="false"></v-radio>
        </v-radio-group>
        <label class="pr-15" :class="{ 'grey--text': !signOut.speciment }"
          >Apakah spesimen sudah diberi label (nama pasien, nomor
          register)?</label
        >
        <v-radio-group
          :disabled="!signOut.speciment"
          :readonly="!isEditable"
          row
          v-model="signOut.isSpecimentLabeled"
        >
          <v-radio label="Sudah" :value="true"></v-radio>
          <v-radio label="Belum" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col>
        <div class="grid">
          <label class="pr-15"
            >Apakah ada kesulitan dalam memakai peralatan medis?</label
          >
          <v-radio-group
            :readonly="!isEditable"
            row
            v-model="signOut.isAnyDifficulties"
          >
            <v-radio label="Ya" :value="true"></v-radio>
            <v-radio label="Tidak" :value="false"></v-radio>
          </v-radio-group>
          <label class="pr-15"
            >Apakah ada perhatian khusus untuk pemulihan dan rencana perawatan
            pasien?</label
          >
          <v-radio-group
            :readonly="!isEditable"
            row
            v-model="signOut.isSpecialAttention"
          >
            <v-radio label="Ya" :value="true"></v-radio>
            <v-radio label="Tidak" :value="false"></v-radio>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('SurgicalSafetyChecklist');

const moment = require('moment-timezone');
export default {
  name: 'SignOutPhase',
  data() {
    return {
      rules: [v => !!v || v === false || 'Wajib diisi'],
      timeRule: [
        v => this.checkTimeFormat(v) || 'Waktu Tidak Valid',
        v => this.isBeforeTimeOut(v),
      ],
    };
  },
  watch: {
    input: {
      handler: function() {
        this.$emit('update-data', this.input);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'getSignOut',
      'getTimeOut',
      'getResource',
      'getIsEmr',
      'getIsEdit',
      'getIsDetail',
    ]),
    isEditable: {
      get() {
        if (this.isEmr) {
          return false;
        } else if (!this.isDetail) {
          return true;
        } else {
          return this.isEdit;
        }
      },
    },
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    signOut: {
      get() {
        return this.getSignOut;
      },
    },
    timeOut: {
      get() {
        return this.getTimeOut;
      },
    },
    resource: {
      get() {
        return this.getResource;
      },
    },
    isEdit: {
      get() {
        return this.getIsEdit;
      },
    },
    isDetail: {
      get() {
        return this.getIsDetail;
      },
    },
  },
  methods: {
    checkTimeFormat(time) {
      if (time.length !== 5) return false;
      const timeInput = moment(time, 'HH:mm');
      return timeInput.isValid();
    },
    isBeforeTimeOut(time) {
      return (
        moment(time, 'HH:mm').isAfter(
          moment(this.timeOut.time.time, 'HH:mm'),
        ) || 'Tidak boleh sebelum waktu fase Time Out'
      );
    },
    insertString(originalString, newString, index) {
      return (
        originalString.substr(0, index) +
        newString +
        originalString.substr(index)
      );
    },
    formatTime(timeString) {
      if (timeString.length > 5) {
        this.signOut.time.time = timeString.slice(0, 5);
        return;
      }
      let time = timeString.replace(/\D/g, '').substr(0, 4);
      let size = time.length;

      if (size > 2) time = this.insertString(time, ':', 2);

      this.signOut.time.time = time;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 40% 50%;
  row-gap: 3dvh;
  text-align: start;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
