<template>
  <v-container fluid class="px-0 pt-9">
    <v-form ref="form">
      <v-row>
        <v-col>
          <label>Tekanan Darah</label>
          <div class="d-flex">
            <v-text-field
              :rules="[rangeRules(form.sistole, 'sistol')]"
              type="number"
              v-model="form.sistole"
              :readonly="isEMR"
              single-line
              placeholder="00"
              dense
            ></v-text-field>
            <span class="mx-4">/</span>
            <v-text-field
              single-line
              :rules="[rangeRules(form.diastole, 'diastol')]"
              v-model="form.diastole"
              :readonly="isEMR"
              type="number"
              placeholder="00"
              class="mb-5"
              dense
              suffix="mmhg"
            ></v-text-field>
          </div>
          <label>Nadi</label>
          <v-text-field
            single-line
            :rules="[rangeRules(form.heartRate, 'heart-rate')]"
            v-model="form.heartRate"
            :readonly="isEMR"
            type="number"
            placeholder="00"
            class="mb-5"
            dense
            suffix="x/menit"
          ></v-text-field>
          <label>Berat Badan</label>
          <v-text-field
            single-line
            placeholder="00"
            type="number"
            :readonly="isEMR"
            class="mb-5"
            v-model="form.weight"
            dense
            suffix="kg"
          ></v-text-field>
          <label>Tinggi Badan</label>
          <v-text-field
            single-line
            type="number"
            placeholder="00"
            :rules="[rangeRules(form.height, 'height')]"
            :readonly="isEMR"
            v-model="form.height"
            class="mb-5"
            dense
            suffix="cm"
          ></v-text-field>
          <label>Lingkar Perut</label>
          <v-text-field
            single-line
            type="number"
            placeholder="00"
            :readonly="isEMR"
            class="mb-5"
            v-model="form.bellyCircumference"
            dense
            suffix="cm"
          ></v-text-field>
        </v-col>
        <v-col>
          <label>RR</label>
          <v-text-field
            single-line
            :rules="[rangeRules(form.rr, 'rr')]"
            :readonly="isEMR"
            v-model="form.rr"
            type="number"
            placeholder="00"
            class="mb-5"
            dense
            suffix="x/menit"
          ></v-text-field>
          <label>Saturasi O<sub>2</sub></label>
          <v-text-field
            single-line
            :rules="[rangeRules(form.saturation, 'saturation')]"
            :readonly="isEMR"
            v-model="form.saturation"
            type="number"
            placeholder="00"
            class="mb-5"
            dense
            suffix="x/menit"
          ></v-text-field>
          <label>Suhu</label>
          <v-text-field
            single-line
            :rules="[rangeRules(form.temperature, 'temperature')]"
            :readonly="isEMR"
            v-model="form.temperature"
            type="number"
            placeholder="00"
            class="mb-5"
            dense
            suffix="°C"
          ></v-text-field>
          <label class="required">Tingkat Kesadaran</label>
          <v-combobox
            single-line
            dense
            :clearable="!isEMR"
            :readonly="isEMR"
            v-model="form.awareness"
            :items="resource.awareness"
            :rules="rule"
            placeholder="Tingkat kesadaran"
            append-icon="mdi-chevron-down"
          ></v-combobox>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('EarlyAssessmentInpatientStore');

export default {
  name: 'ObjectiveData',
  props: {
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resource: {
        awareness: ['Coma', 'Sopor', 'Somnolence', 'Compos Mentis'],
      },
      rule: !this.isEMR ? [v => !!v || 'Wajib diisi'] : [],
    };
  },
  computed: {
    ...mapGetters(['getObjective']),
    form: {
      get() {
        return this.getObjective;
      },
    },
  },
  methods: {
    rangeRules(value, type) {
      switch (type) {
        case 'sistol':
          if (!value && value !== 0) {
            return true;
          }
          if (value < 40 || value > 250) {
            return 'Angka tekanan darah tidak valid';
          } else {
            return true;
          }
        case 'diastol':
          if (!value && value !== 0) {
            return true;
          }
          if (value < 30 || value > 180) {
            return 'Angka tekanan darah tidak valid';
          } else {
            return true;
          }
        case 'heart-rate':
          if (!value && value !== 0) {
            return true;
          }
          if (value < 30 || value > 160) {
            return 'Angka nadi tidak valid';
          } else {
            return true;
          }
        case 'rr':
          if (!value && value !== 0) {
            return true;
          }
          if (value < 5 || value > 70) {
            return 'Angka RR tidak valid';
          } else {
            return true;
          }
        case 'temperature':
          if (!value && value !== 0) {
            return true;
          }
          if (value < 20 || value > 45) {
            return 'Angka suhu tidak valid';
          } else {
            return true;
          }
        case 'height':
          if (!value && value !== 0) {
            return true;
          }
          if (value <= 1) {
            return 'Angka tinggi badan tidak valid';
          } else {
            return true;
          }
        case 'saturation':
          if (!value && value !== 0) {
            return true;
          }
          if (value <= 1) {
            return 'Angka saturasi wajib diisi';
          } else {
            return true;
          }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  text-align: start;
  padding: 0 0.75vw;
}

.required:after {
  content: ' *';
  color: red;
}

label {
  font-weight: 500;
  font-size: 0.8vw;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
