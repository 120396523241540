<template>
  <v-card>
    <v-container class="pa-8" fluid>
      <v-snackbar color="primary" v-model="snackbar" timeout="2000">
        <div class="d-flex justify-center">
          <span>
            Berhasil menyalin nama pasien
          </span>
        </div>
      </v-snackbar>
      <h2 class="mb-10">Data Pasien Berdasarkan BPJS</h2>
      <div class="form-data">
        <label class="font-weight-medium text-start">No BPJS</label>
        <v-text-field
          hide-details
          dense
          single-line
          readonly
          :value="data.no"
        ></v-text-field>
        <label class="font-weight-medium text-start">Nama Lengkap</label>
        <v-text-field
          hide-details
          dense
          single-line
          readonly
          :value="data.name"
          append-icon="mdi-content-copy"
          @click:append="copyName()"
        ></v-text-field>
        <label class="font-weight-medium text-start">Tanggal Lahir</label>
        <v-text-field
          hide-details
          dense
          single-line
          readonly
          :value="data.birthDate"
        ></v-text-field>
        <label class="font-weight-medium text-start">Usia</label>
        <v-text-field
          hide-details
          dense
          single-line
          readonly
          :value="data.age"
        ></v-text-field>
        <label class="font-weight-medium text-start">No KTP</label>
        <v-text-field
          hide-details
          dense
          single-line
          readonly
          :value="data.ktpNo"
        ></v-text-field>
      </div>
      <div class="text-end">
        <v-btn depressed color="primary" @click="closeForm()">KEMBALI</v-btn>
      </div>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'BPJSCorrectionData',
  props: {
    data: Object,
  },
  data() {
    return {
      snackbar: false,
    };
  },
  mounted() {
    this.data = {
      ...this.data,
    };
  },
  methods: {
    copyName() {
      navigator.clipboard.writeText(this.data.name);
      this.snackbar = true;
    },
    closeForm() {
      this.$emit('close-form');
    },
  },
};
</script>

<style scoped lang="scss">
.form-data {
  width: 100%;
  display: grid;
  grid-template-columns: 30% 70%;
  row-gap: 1.9rem;
  margin-bottom: 4rem;
}
</style>
