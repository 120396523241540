<template
  ><div>
    <v-skeleton-loader v-if="getLoading.table" type="table"></v-skeleton-loader>
    <template v-else>
      <v-row class="d-flex justify-end">
        <v-btn
          v-if="!isEMR"
          style="letter-spacing: 0.01px;"
          color="primary"
          class="text-capitalize pr-3 pl-1"
          @click="navigateTo('form-cppt')"
          dense
          light
          depressed
        >
          <v-icon class="mr-1">mdi-plus</v-icon>
          <span>Buat Baru</span>
        </v-btn>
      </v-row>
      <v-row>
        <div class="grid-header">
          <span>Tanggal & Jam</span>
          <span>PPA</span>
          <span>Hasil Pemeriksaan, Analisa, dan Penatalaksanaan Pasien</span>
          <span>Instruksi PPA</span>
        </div>
        <v-container fluid>
          <div class="pt-5" v-if="!items?.length">
            <p class="grey--text subtitle-2">
              Tidak ada riwayat CPPT
            </p>
          </div>
          <template v-else>
            <div
              class="grid-data"
              v-for="(item, index) in items"
              :key="`row-${index}`"
            >
              <!-- date & time -->
              <div class="col py-0">
                <p>{{ `${item.date}, ${item.time}` }}</p>
                <p>No Bed: {{ item.bed }}</p>
              </div>
              <!-- PPA  -->
              <div class="col py-0">
                <p>{{ item.ppa.name }}</p>
                <v-chip
                  :class="
                    `text-capitalize chip ${
                      item.ppa.role.some(
                        item => item.toLowerCase() === 'dokter',
                      )
                        ? 'doctor'
                        : 'nurse'
                    }`
                  "
                >
                  {{ generatePpaRole(item.ppa.role) }}
                </v-chip>
              </div>
              <div class="col">
                <v-row>
                  <v-col class="pa-0 pl-3">
                    <p class="primary--text">DATA SUBJEKTIF</p>
                    <p class="sub-title">Anamnesis</p>
                    <p v-html="item.anamnesis"></p>
                    <p class="primary--text">DATA OBJEKTIF</p>
                    <div class="objective">
                      <label>Tekanan Darah</label>
                      <span>{{
                        `${item.objective.sistole}/${item.objective.diastole} mmHg`
                      }}</span>
                      <label>Suhu</label>
                      <span>{{ item.objective.temperature }} &deg;C</span>
                      <label>Nadi</label>
                      <span>{{ item.objective.heartRate }} x/menit</span>
                      <label>RR</label>
                      <span>{{ item.objective.rr }} x/menit</span>
                      <label>Saturasi</label>
                      <span>{{ item.objective.saturation }} %</span>
                    </div>
                    <p class="sub-title">GDS</p>
                    <p>
                      {{ item.objective.gds }}
                    </p>
                    <p class="sub-title">Data Objektif Lainnya</p>
                    <p v-html="item.objective.other"></p>
                  </v-col>
                  <v-col class="pa-0 pl-3">
                    <p class="primary--text">ASSESMEN & PLAN</p>
                    <template
                      v-if="
                        item.ppa.role.some(
                          item => item.toLowerCase() === 'dokter',
                        )
                      "
                    >
                      <p class="sub-title">Diagnosa (ICD 10)</p>
                      <p
                        v-if="
                          !item.assessment?.diagnose?.length ||
                            !item.assessment?.diagnose?.[0]
                        "
                      >
                        -
                      </p>
                      <ul v-else class="mb-4">
                        <li
                          v-for="(diagnose, indexDoctorDiagnose) in item
                            .assessment?.diagnose"
                          :key="`diagnose-${indexDoctorDiagnose}`"
                        >
                          {{ diagnose }} ({{
                            item.assessment.icd[indexDoctorDiagnose]
                          }})
                        </li>
                      </ul>
                      <p class="sub-title">Rencana Tindakan</p>
                      <p
                        v-if="
                          !item.assessment.planning?.length ||
                            !item.assessment.planning?.[0]
                        "
                      >
                        -
                      </p>
                      <ul v-else>
                        <li
                          v-for="(v, idx) in item.assessment.planning"
                          :key="`planning-doctor-${idx}`"
                        >
                          {{ v }}
                        </li>
                      </ul>
                      <p class="sub-title">Catatan</p>
                      <p v-html="item.assessment?.note"></p>
                    </template>
                    <template v-else>
                      <div
                        v-for="(x, idx) in item.assessment?.diagnose"
                        :key="`diagnose-nurse-${idx}`"
                      >
                        <p class="sub-title">Diagnosa {{ idx + 1 }}</p>
                        <p>{{ x.name }}</p>
                        <p class="sub-title">Rencana Tindakan</p>
                        <p v-if="!x.planning?.length || !x.planning?.[0]">-</p>
                        <ul v-else>
                          <li
                            v-for="(r, planningIdx) in x.planning"
                            :key="`planning-${planningIdx}-diagnose-${idx}`"
                          >
                            {{ r }}
                          </li>
                        </ul>
                      </div>
                      <p class="sub-title">Catatan</p>
                      <p v-html="item.assessment?.note"></p>
                    </template>
                  </v-col>
                </v-row>
              </div>
              <div class="col py-0">
                <p v-html="item.ppaInstruction"></p>
              </div>
              <div class="d-flex align-center">
                <v-menu left bottom :offset-x="true" close-on-content-click>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" fab small light text>
                      <v-icon color="#9ca2a5">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <div class="menu">
                    <div
                      v-for="(i, indexMenu) in menu"
                      :key="`menu-${indexMenu}`"
                      class="menu-item"
                      @click="onClickMenu(indexMenu, index)"
                    >
                      <p
                        :class="
                          `ma-0 pa-3 ${indexMenu === 2 ? 'red--text' : ''}`
                        "
                        v-if="
                          indexMenu === 2
                            ? showDeleteCpptBtn(indexMenu, item)
                            : true
                        "
                      >
                        {{ i }}
                      </p>
                    </div>
                  </div>
                </v-menu>
              </div>
            </div>
          </template>
        </v-container>
      </v-row>
      <v-dialog v-model="dialog" persistent>
        <detail-changes
          v-if="dialog"
          :index="selectedCpptIndex"
          @close="closeDialog"
        />
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import DetailChanges from './DetailChanges.vue';
import Swal from 'sweetalert2';
import { deleteCpptById } from '@/fetchApi/Inpatient/CPPT';

const { mapMutations, mapActions, mapGetters } = createNamespacedHelpers(
  'CPPTStore',
);

const _ = require('lodash');

export default {
  components: { DetailChanges },
  props: {
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  name: 'CPPTTable',
  data() {
    return {
      dialog: false,
      selectedCpptIndex: '',
      menu: ['Lihat Detail', 'Detail Perubahan Data', 'Hapus Riwayat'],
    };
  },
  computed: {
    ...mapGetters(['getTableItems', 'getLoading']),
    items: {
      get() {
        return this.getTableItems;
      },
    },
  },
  mounted() {
    this.resolveGetCpptTableItems();
  },
  methods: {
    ...mapMutations(['navigateTo']),
    ...mapActions(['resolveGetCpptTableItems', 'resolveGetCpptById']),
    generatePpaRole(roles) {
      if (roles.some(item => item.toLowerCase() === 'dokter')) {
        return 'Dokter';
      } else if (roles.some(item => item.toLowerCase() === 'perawat')) {
        return 'Perawat';
      } else if (roles.some(item => item.toLowerCase() === 'bidan')) {
        return 'Bidan';
      } else {
        return roles[0];
      }
    },
    onClickMenu(indexMenu, indexItem) {
      switch (indexMenu) {
        case 0:
          this.resolveGetCpptById(this.items[indexItem].id);
          break;
        case 1:
          this.dialog = true;
          this.selectedCpptIndex = indexItem;
          break;
        case 2:
          this.deleteCppt(this.items[indexItem]);
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    deleteCppt(data) {
      Swal.fire({
        title:
          '<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Yakin Ingin Menghapus Riwayat</p><p>CPPT Ini?</p></div>',
        iconHtml: '<span class="mdi mdi-help-circle-outline"></span>',
        html: `<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Anda akan menghapus data riwayat CPPT yang</p><p>diinput oleh ${
          data.ppa.name
        } (${_.capitalize(this.generatePpaRole(data.ppa.role))}) pada ${
          data.dateHistory
        }, ${data.time}</p>`,
        showCancelButton: true,
        customClass: {
          confirmButton: 'button-confirm',
          cancelButton: 'button-cancel',
        },
        reverseButtons: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await deleteCpptById(data.id);
            this.resolveGetCpptTableItems();
            this.$emit('show-snackbar', {
              show: true,
              text: 'Riwayat berhasil dihapus',
              color: 'success',
            });
          } catch {
            this.$emit('show-snackbar', {
              show: true,
              text: 'Terjadi kesalahan, riwayat gagal dihapus',
              color: 'error',
            });
          }
        }
      });
    },
    showDeleteCpptBtn(index, item) {
      if (this.isEMR) return false;
      return this.$store.getters.userLoggedIn.id === item.ppa.id;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin grid-layout($grid) {
  display: grid;
  width: 100%;
  font-size: 0.8vw;
  text-align: start;
  grid-template-columns: $grid;
}

.grid-header {
  @include grid-layout(10vw 13vw 34.5vw 10vw);
  color: #9e9e9e;
  border-bottom: 1px solid #9e9e9e;
  height: 3vw;
  margin-top: 1vw;
  background-color: #f4f5f5;
  align-items: center;

  span {
    padding-left: 2vw;
  }
}

.container {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  margin: 0;

  &::-webkit-scrollbar {
    width: 5px !important;
    border-radius: 5px;
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px !important;
    border-radius: 5px;
    background-color: rgb(163, 163, 163) !important;
  }

  .grid-data {
    @include grid-layout(10vw 13vw 34.5vw 16vw 2vw);
    width: 100%;
    padding-top: 0.5vw;
    color: #404040;
    border-bottom: 1px solid #e0e0e0;

    .objective {
      @include grid-layout(repeat(2, 1fr));
      row-gap: 0.5vw;
      margin-bottom: 0.7vw;

      label {
        color: #757575;
      }
    }

    .col {
      padding-left: 2vw;

      p {
        padding: auto 0px;
        margin: 0px 0px 0.5vw 0px;
      }

      .sub-title {
        margin-bottom: 0.1vw;
        color: #757575;
      }
    }
  }
}

.chip {
  height: 1.5vw;
  padding: 0px 0.3vw;
  font-size: 0.75vw;
  border-radius: 3px;
  font-weight: 500;

  &.doctor {
    color: #404040;
    background-color: #fafafa;
    border: 1px solid #404040;
  }
  &.nurse {
    color: #2f80ed;
    background-color: #f0f9ff;
    border: 1px solid #2f80ed;
  }
}

.menu {
  width: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: start;
  align-content: flex-start;
  font-size: 0.75vw;

  .menu-item:hover {
    background-color: #ededed;
    cursor: pointer;
  }

  .v-list-item {
    max-height: 0.1vw !important;
  }
}

.button-cancel {
  background-color: white !important;
  border: 1px solid #137bc0 !important;
  border-radius: 4px !important;
  color: #137bc0 !important;
}
</style>
