const moment = require('moment-timezone');

// start of early inpatient assessment helper function
const formatDate = (date, time) => {
  return moment(`${date} ${time}`, 'DD-MM-YYYY HH:mm').format();
};

const generatePainType = score => {
  let result;

  if (!score) {
    result = 'Tidak Nyeri';
  }
  if (score >= 1 && score <= 3) {
    result = 'Nyeri Ringan';
  }
  if (score >= 4 && score <= 6) {
    result = 'Nyeri Sedang';
  }
  if (score >= 7 && score <= 10) {
    result = 'Nyeri Berat';
  }

  return result;
};
// end of early inpatient assessment helper function

// for early inpatient assessment payload
const mapToPayload = (source, type) => {
  let payload;

  const timestamps = {
    created_at: moment().format(),
    created_by: source.initialAssessment?.staff?.id,
  };

  let id = {
    id_registration: source.id.registration,
    id_emr: source.id.emr,
    id_inpatient: source.id.inpatient,
  };

  switch (type) {
    case 'initial_assessment':
      payload = {
        ...id,
        initial_assessment: {
          is_saved: true,
          staff: source.initialAssessment?.staff?.id,
          time_fill: formatDate(
            source.initialAssessment.date,
            source.initialAssessment.time,
          ),
          time_arrived: {
            date: source.initialAssessment.arrived.date.raw,
            time: source.initialAssessment.arrived.time,
          },
          start_assessment: {
            time_start: {
              date: source.initialAssessment.assessmentStart.date.raw,
              time: source.initialAssessment.assessmentStart.time,
            },

            obtained_from:
              source.initialAssessment.assessmentStart.obtainedFrom,
            relationship_with_patient:
              source.initialAssessment.assessmentStart.relationWithPatient,
          },
          entry_method: source.initialAssessment.entryMethod,
          entry_from: source.initialAssessment.entryFrom,
          ...timestamps,
        },
      };
      break;
    case 'anamnesis':
      payload = {
        ...id,
        anamnesis: {
          current_health_history: source.anamnesis.currentHealthHistory,
          past_health_history: source.anamnesis.pastHealthHistory,
          family_disease_history: {
            check: source.anamnesis.familyDiseaseHistory.check,
            name: source.anamnesis.familyDiseaseHistory.check
              ? source.anamnesis.familyDiseaseHistory.name
              : [],
          },
          allergy_history: source.anamnesis.allergy,
          treatment_history: source.anamnesis.treatmentHistory,
          psychology_status: {
            check: source.anamnesis.psychologyStatus.check,
            name: source.anamnesis.psychologyStatus.check
              ? source.anamnesis.psychologyStatus.name
              : [],
          },
          is_saved: true,
          ...timestamps,
        },
      };
      break;
    case 'objective':
      payload = {
        ...id,
        objective: {
          sistol: source.objective.sistole,
          diastol: source.objective.diastole,
          heart_rate: source.objective.heartRate,
          weight: source.objective.weight,
          height: source.objective.height,
          belly_circumference: source.objective.bellyCircumference,
          respiration_rate: source.objective.rr,
          saturation: source.objective.saturation,
          temperature: source.objective.temperature,
          awarness: source.objective.awareness,
          is_saved: true,
          ...timestamps,
        },
      };
      break;
    case 'physical':
      payload = {
        ...id,
        physical: {
          head: source.physical.head,
          eye: source.physical.eye,
          ear: source.physical.ear,
          nose: source.physical.nose,
          mouth: source.physical.mouth,
          neck: source.physical.neck,
          hair: source.physical.hair,
          lips: source.physical.lips,
          teeth: source.physical.teeth,
          tongue: source.physical.tongue,
          palate: source.physical.palate,
          throat: source.physical.throat,
          tonsil: source.physical.tonsil,
          chest: source.physical.chest,
          breast: source.physical.breasts,
          back: source.physical.back,
          stomach: source.physical.stomach,
          genitalia: source.physical.genitalia,
          anus: source.physical.anus,
          upper_arm: source.physical.upperArm,
          forearm: source.physical.forearm,
          finger: source.physical.fingers,
          hand_nail: source.physical.handNail,
          hand_joint: source.physical.handJoints,
          upper_limb: source.physical.upperLimbs,
          lower_limb: source.physical.lowerLimbs,
          toe: source.physical.toes,
          toe_nail: source.physical.toeNails,
          leg_joint: source.physical.legJoints,
          is_saved: true,
          ...timestamps,
        },
      };
      break;
    case 'pain_assessment':
      payload = {
        ...id,
        pain_assessment: {
          is_saved: true,
          type: generatePainType(source.painAssessment.pain),
          detail: {
            score: source.painAssessment.pain,
            pain_factor: source.painAssessment.painFactor,
            pain_like: source.painAssessment.painLike,
            pain_radiation: source.painAssessment.painRadiation,
            severity: source.painAssessment.severity,
            pain_frequency: source.painAssessment.painFrequency,
            pain_duration: source.painAssessment.painDuration,
            description: source.painAssessment.description,
          },
          ...timestamps,
        },
      };
      break;
  }

  return payload;
};

// for early inpatient assessment payload
const mapDataByType = (source, type) => {
  let result;
  switch (type) {
    case 'initial_assessment':
      result = {
        isSaved: source?.initial_assessment?.is_saved || null,
        staff: source?.initial_assessment?.staff?._id
          ? {
              name: source.initial_assessment.staff.name,
              id: source.initial_assessment.staff._id,
            }
          : null,
        date: source?.initial_assessment?.time_fill
          ? moment(source?.initial_assessment?.time_fill).format('DD-MM-YYYY')
          : '',
        time: source?.initial_assessment?.time_fill
          ? moment(source?.initial_assessment?.time_fill).format('HH:mm')
          : '',
        arrived: {
          date: {
            raw: source?.initial_assessment?.time_arrived?.date || '',
            formatted: source?.initial_assessment?.time_arrived?.date
              ? moment(
                  source?.initial_assessment?.time_arrived.date,
                  'YYYY-MM-DD',
                ).format('DD-MM-YYYY')
              : '',
          },
          time: source?.initial_assessment?.time_arrived?.time || '',
        },
        assessmentStart: {
          date: {
            raw:
              source?.initial_assessment?.start_assessment?.time_start?.date ||
              '',
            formatted: source?.initial_assessment?.start_assessment?.time_start
              ?.date
              ? moment(
                  source?.initial_assessment?.start_assessment?.time_start.date,
                  'YYYY-MM-DD',
                ).format('DD-MM-YYYY')
              : '',
          },
          time:
            source?.initial_assessment?.start_assessment?.time_start?.time ||
            '',
          obtainedFrom:
            source?.initial_assessment?.start_assessment?.obtained_from || '',
          relationWithPatient:
            source?.initial_assessment?.start_assessment
              ?.relationship_with_patient || '',
        },
        entryMethod: source?.initial_assessment?.entry_method || '',
        entryFrom: source?.initial_assessment?.entry_from || '',
      };
      break;
    case 'anamnesis':
      result = {
        isSaved: source?.anamnesis?.is_saved,
        currentHealthHistory: source?.anamnesis?.current_health_history,
        pastHealthHistory: source?.anamnesis?.past_health_history,
        familyDiseaseHistory: {
          check: source?.anamnesis?.family_disease_history?.check,
          name: source?.anamnesis?.family_disease_history?.name,
        },
        allergy: source?.anamnesis?.allergy_history?.length
          ? source.anamnesis?.allergy_history
          : [''],
        psychologyStatus: {
          check: source.anamnesis?.psychology_status?.check,
          name: source?.anamnesis?.psychology_status?.name,
        },
        treatmentHistory: source?.anamnesis?.treatment_history,
      };
      break;
    case 'objective':
      result = {
        isSaved: source?.objective?.is_saved,
        sistole: source?.objective?.sistol || '',
        diastole: source?.objective?.diastol || '',
        heartRate: source?.objective?.heart_rate || '',
        weight: source?.objective?.weight || '',
        height: source?.objective?.height || '',
        bellyCircumference: source?.objective?.belly_circumference || '',
        rr: source?.objective?.respiration_rate || '',
        saturation: source?.objective?.saturation || '',
        temperature: source?.objective?.temperature || '',
        awareness: source?.objective?.awarness || '',
      };
      break;
    case 'physical':
      result = {
        head: source.physical.head || '',
        back: source.physical.back || '',
        eye: source.physical.eye || '',
        stomach: source.physical.stomach || '',
        ear: source.physical.ear || '',
        genitalia: source.physical.genitalia || '',
        nose: source.physical.nose || '',
        anus: source.physical.anus || '',
        mouth: source.physical.mouth || '',
        upperArm: source.physical.upper_arm || '',
        neck: source.physical.neck || '',
        forearm: source.physical.forearm || '',
        hair: source.physical.hair || '',
        fingers: source.physical.finger || '',
        lips: source.physical.lips || '',
        handNail: source.physical.hand_nail || '',
        teeth: source.physical.teeth || '',
        handJoints: source.physical.hand_joint || '',
        tongue: source.physical.tongue || '',
        upperLimbs: source.physical.upper_limb || '',
        palate: source.physical.palate || '',
        lowerLimbs: source.physical.lower_limb || '',
        throat: source.physical.throat || '',
        toes: source.physical.toe || '',
        tonsil: source.physical.tonsil || '',
        toeNails: source.physical.toe_nail || '',
        breast: source.physical.breast || '',
        legJoints: source.physical.leg_joint || '',
        breasts: source.physical.breast || '',
        isSaved: source.physical.is_saved,
      };
      break;
    case 'pain_assessment':
      result = {
        isSaved: source.pain_assessment.is_saved,
        pain: source.pain_assessment.detail.score || 0,
        painFactor: source.pain_assessment.detail.pain_factor || null,
        painLike: source.pain_assessment.detail.pain_like || null,
        painRadiation: source.pain_assessment.detail.pain_radiation || false,
        severity: source.pain_assessment.detail.severity || 0,
        painFrequency: source.pain_assessment.detail.pain_frequency || 0,
        painDuration: source.pain_assessment.detail.pain_duration || 0,
        description: source.pain_assessment.detail.description || null,
      };
      break;
  }

  return result;
};

module.exports = { mapToPayload, mapDataByType };
