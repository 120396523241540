import { render, staticRenderFns } from "./PainAssessment.vue?vue&type=template&id=b4bf8446&scoped=true"
import script from "./PainAssessment.vue?vue&type=script&lang=js"
export * from "./PainAssessment.vue?vue&type=script&lang=js"
import style0 from "./PainAssessment.vue?vue&type=style&index=0&id=b4bf8446&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4bf8446",
  null
  
)

export default component.exports