<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-text-field
          background-color="grey lighten-3"
          placeholder="Search"
          v-model="search"
          solo
          flat
          dense
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="py-5">
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        fixed-header
        :search="search"
        height="250"
        :items-per-page="historyData.length"
        hide-default-footer
      ></v-data-table>
    </div>

    <v-row no-gutters class="text-left px-4">
      <label class="body-2 font-weight-bold">Riwayat File Bukti Faktur</label>
    </v-row>

    <v-data-table
      :headers="historyTable.headers"
      :items="historyTable.items"
      :items-per-page="historyTable.items.length"
      hide-default-footer
    >
      <template v-slot:[`item.files`]="{ item }">
        <v-row no-gutters>
          <v-btn
            depressed
            :key="'files-' + idx"
            height="70"
            @click="openFile(item.path)"
            class="files text-none pl-0"
          >
            <div style="display: grid">
              <v-icon
                v-if="item.files.search(/.(jpg|jpeg|png)/i) !== -1"
                x-large
                >mdi-file-image-outline</v-icon
              >
              <v-icon v-if="item.files.search(/.pdf/i) !== -1" x-large
                >mdi-file-document-outline</v-icon
              >
              <label class="caption primary--text">{{ item.files }}</label>
            </div>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
export default {
  name: 'HistoryDetail',
  props: {
    historyData: {
      type: Array,
      default: null,
    },
    allStaff: {
      type: Array,
      default: null,
    },
    files: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      search: '',
      table: {
        headers: [
          {
            text: 'Tanggal Penerimaan',
            value: 'receiveTime',
            class: 'black--text',
          },
          {
            text: 'Nomor Faktur',
            value: 'invoiceNumber',
            sortable: false,
            class: 'black--text',
          },
          {
            text: 'Nama Barang',
            value: 'name',
            sortable: false,
            class: 'black--text',
          },
          {
            text: 'Jumlah Diterima',
            value: 'receiveAmount',
            sortable: false,
            class: 'black--text',
          },
          {
            text: 'Tanggal Expired',
            value: 'expiredDate',
            sortable: false,
            class: 'black--text',
          },
          {
            text: 'Nomor Batch',
            value: 'batch',
            sortable: false,
            class: 'black--text',
          },
          {
            text: 'Nama Staff',
            value: 'staffName',
            sortable: false,
            class: 'black--text',
          },
          {
            text: 'Status',
            value: 'status',
            sortable: false,
            class: 'black--text',
          },
        ],
        items: [],
      },
      historyTable: {
        headers: [
          {
            text: 'Tanggal Penerimaan',
            value: 'receiveDate',
            sortable: false,
            class: 'black--text',
          },
          {
            text: 'Nomor Faktur',
            value: 'invoiceNumber',
            sortable: false,
            class: 'black--text',
          },
          {
            text: 'Nama Staff',
            value: 'staffName',
            sortable: false,
            class: 'black--text',
          },
          {
            text: 'File Pendukung',
            value: 'files',
            width: '60%',
            sortable: false,
            class: 'black--text',
          },
        ],
        items: [
          {
            receiveDate: '10/12/2020 14:00 WIB',
            invoiceNumber: 'FA1234',
            staffName: 'Anggraeta Pramudita, S.Farm',
            files: ['IMG-201212000.jpg', 'Invoice FA12345.pdf'],
          },
        ],
      },
    };
  },
  watch: {
    historyData(val) {
      if (val) {
        this.mapData();
      }
    },
  },
  mounted() {
    this.mapData();
  },
  methods: {
    mapData() {
      this.table.items = this.historyData;
      this.historyTable.items = this.files.map(file => {
        return {
          staffName: file.created_by,
          invoiceNumber: this.historyData[0].invoiceNumber,
          receiveDate: `${moment(file.created_at).format(
            'DD/MM/YYYY HH:mm',
          )} WIB`,
          files: file.original_file_name,
          path: file.path,
        };
      });
    },
    openFile(path) {
      window.open(Constant.apiUrl.concat(`/${path}`));
    },
  },
};
</script>

<style>
.files {
  background-color: transparent !important;
}
</style>
