<template>
  <v-row class="mb-6">
    <v-col cols="6" class="grid-display">
      <template v-for="(item, index) in anamnesis">
        <label :key="'anamnesis-label-' + index">{{
          index ? '' : 'Anamnesis'
        }}</label>
        <div :key="'anamnesis-' + index">
          <v-textarea
            no-resize
            height="100"
            :readonly="isEmr"
            outlined
            dense
            single-line
            :rules="rule"
            :placeholder="'Anamnesis ' + (index + 1)"
            v-model="anamnesis[index]"
            :id="'anamnesis-' + index"
          />
          <div style="margin-top: -3vh" class="d-flex justify-end">
            <v-btn
              :disabled="anamnesis.length < 2 || isEmr"
              @click="deleteAnamnesis(index)"
              text
              fab
              small
              ><v-icon color="grey">mdi-delete</v-icon></v-btn
            >
            <v-btn :disabled="isEmr" @click="addAnamnesis()" text fab small
              ><v-icon color="grey">mdi-plus</v-icon></v-btn
            >
          </div>
        </div>
      </template>
      <template v-for="(item, index) in allergy">
        <label :key="'allergy-label-' + index">{{
          index ? '' : 'Riwayat Alergi'
        }}</label>
        <div class="d-flex" :key="'allergy-' + index">
          <v-text-field
            :readonly="isEmr"
            dense
            :placeholder="'Riwayat Alergi ' + (index + 1)"
            v-model="allergy[index]"
            :rules="rule"
          />
          <v-btn
            :disabled="allergy.length < 2 || isEmr"
            @click="deleteAllergy(index)"
            text
            fab
            small
            ><v-icon color="grey">mdi-delete</v-icon></v-btn
          >
          <v-btn :disabled="isEmr" @click="addAllergy()" text fab small
            ><v-icon color="grey">mdi-plus</v-icon></v-btn
          >
        </div>
      </template>
      <template v-for="(item, index) in priorDisease">
        <label :key="'disease-label' + index">{{
          index ? '' : 'Riwayat Penyakit Sebelumnya'
        }}</label>
        <div :key="'disease-' + index" class="d-flex">
          <v-text-field
            :rules="rule"
            dense
            :readonly="isEmr"
            :placeholder="'Riwayat Penyakit ' + (index + 1)"
            v-model="priorDisease[index]"
          />
          <v-btn
            :disabled="priorDisease.length < 2 || isEmr"
            @click="deletePriorDisease(index)"
            text
            fab
            small
            ><v-icon color="grey">mdi-delete</v-icon></v-btn
          >
          <v-btn :disabled="isEmr" @click="addPriorDisease()" text fab small
            ><v-icon color="grey">mdi-plus</v-icon></v-btn
          >
        </div>
      </template>
    </v-col>
    <v-col cols="6">
      <v-row no-gutters align="baseline" class="grid-display">
        <label>Riwayat Pengobatan</label>
        <v-text-field
          :readonly="isEmr"
          dense
          placeholder="Riwayat Pengobatan"
          v-model="treatmentHistory"
        />
        <label>Status Psikologi</label>
        <v-radio-group :disabled="isEmr" row v-model="psychologyStatusCheckbox">
          <v-radio label="Tidak Ada Kelainan" :value="false"></v-radio>
          <v-radio label="Ada" :value="true"></v-radio>
        </v-radio-group>
        <template v-if="psychologyStatusCheckbox">
          <label></label>
          <v-combobox
            v-model="psychologyStatus"
            :readonly="isEmr"
            :items="resource.psychologyStatus"
            placeholder="Status Psikologis"
            append-icon="mdi-chevron-down"
            multiple
          >
            <template v-slot:prepend-item>
              <p class="grey--text ma-4 text-start body-1">
                Pilih atau Ketik Untuk Pilihan Lainnya
              </p>
            </template>
          </v-combobox>
        </template>
        <label>Sosial Ekonomi</label>
        <v-text-field
          v-model="socialEconomy"
          class="mb-5"
          hide-details
          :readonly="isEmr"
          dense
          single-line
          placeholder="Pendidikan/Pekerjaan/Penghasilan Pasien"
        />
        <label>Spiritual</label>
        <v-text-field
          v-model="spiritual"
          hide-details
          dense
          :readonly="isEmr"
          single-line
          placeholder="Agama/Keyakinan/Nilai-Nilai Pasien"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers('SubjectiveData');
const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);

export default {
  name: 'SubjectiveData',
  data() {
    return {
      rule: [v => v.split(' ').length <= 3000 || 'Maksimal 3000 karakter!'],
      resource: {
        psychologyStatus: ['Cemas', 'Takut', 'Marah', 'Sedih'],
      },
    };
  },
  computed: {
    ...mapIGDAssessmentGetters(['getIsEmr']),
    ...mapGetters([
      'getAnamnesis',
      'getAllergy',
      'getPriorDisease',
      'getTreatmentHistory',
      'getPsychologyStatus',
      'getPsychologyStatusCheckbox',
      'getSocialEconomy',
      'getSpiritual',
    ]),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    anamnesis: {
      get() {
        return this.getAnamnesis;
      },
      set(value) {
        this.setAnamnesis(value);
      },
    },
    allergy: {
      get() {
        return this.getAllergy;
      },
      set(value) {
        this.setAllergy(value);
      },
    },
    priorDisease: {
      get() {
        return this.getPriorDisease;
      },
      set(value) {
        this.setPriorDisease(value);
      },
    },
    treatmentHistory: {
      get() {
        return this.getTreatmentHistory;
      },
      set(value) {
        this.setTreatmentHistory(value);
      },
    },
    psychologyStatusCheckbox: {
      get() {
        return this.getPsychologyStatusCheckbox;
      },
      set(value) {
        this.setPsychologyStatusCheckbox(value);
      },
    },
    psychologyStatus: {
      get() {
        return this.getPsychologyStatus;
      },
      set(value) {
        this.setPsychologyStatus(value);
      },
    },
    socialEconomy: {
      get() {
        return this.getSocialEconomy;
      },
      set(value) {
        this.setSocialEconomy(value);
      },
    },
    spiritual: {
      get() {
        return this.getSpiritual;
      },
      set(value) {
        this.setSpiritual(value);
      },
    },
  },
  methods: {
    ...mapMutations([
      'addAnamnesis',
      'deleteAnamnesis',
      'addAllergy',
      'deleteAllergy',
      'addPriorDisease',
      'deletePriorDisease',
      'setAnamnesis',
      'setAllergy',
      'setPriorDisease',
      'setTreatmentHistory',
      'setPsychologyStatusCheckbox',
      'setPsychologyStatus',
      'setSocialEconomy',
      'setSpiritual',
      'clearSubjectiveState',
    ]),
  },
  beforeDestroy() {
    this.clearSubjectiveState();
  },
};
</script>

<style lang="scss" scoped>
$standard-font-size: 0.9vw;

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.grid-display {
  @include grid-display(10vw minmax(16vw, 26vw), true);
  column-gap: 1vw;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
