import {
  postMedicalResume,
  getMedicalResume,
} from '@/fetchApi/Inpatient/MedicalResume';
import { mapMedicalResumeData } from './mapMedicalResumeData';

const moment = require('moment-timezone');
const _ = require('lodash');

const state = {
  loading: {
    data: false,
    mainDiagnose: false,
  },
  isTimeChanged: false,
  form: {
    isEditable: false,
    id_inpatient: '',
    id_emr: '',
    doctor: '',
    date: {
      in: {
        formatted: '',
        raw: '',
      },
      out: {
        formatted: '',
        raw: '',
      },
    },
    time: {
      in: '',
      out: '',
    },
    room: '',
    reasonIn: '',
    diagnoseIn: '',
    diseaseHistory: '',
    physicalCheckup: '',
    labResult: '',
    supportCheckup: '',
    therapy: [''],
    consultationResult: '',
    mainDiagnose: {
      name: '',
      id_diagnose: '',
      search: null,
    },
    secondaryDiagnose: [
      {
        name: '',
        id_diagnose: '',
        loading: false,
      },
    ],
    procedure: [
      {
        name: '',
        id_procedure: '',
        loading: false,
      },
    ],
    allergyHistory: [''],
    takehomeDrug: [''],
    diet: '',
    instruction: '',
    finishedType: {
      name: '',
      description: '',
    },
    treatmentContinued: {
      name: '',
      description: '',
    },
  },
  initialState: {
    doctor: '',
    date: {
      in: '',
      out: '',
    },
    time: {
      in: '',
      out: '',
    },
    room: '',
    reasonIn: '',
    diagnoseIn: '',
    diseaseHistory: '',
    physicalCheckup: '',
    labResult: '',
    supportCheckup: '',
    therapy: [''],
    consultationResult: '',
    mainDiagnose: '',
    secondaryDiagnose: [
      {
        name: '',
        id_diagnose: '',
      },
    ],
    procedure: [
      {
        name: '',
        id_procedure: '',
      },
    ],
    allergyHistory: [''],
    takehomeDrug: [''],
    diet: '',
    instruction: '',
    finishedType: {
      name: '',
      description: '',
    },
    treatmentContinued: {
      name: '',
      description: '',
    },
  },
};
const getters = {
  getForm: state => state.form,
  getInitialState: state => state.initialState,
  getLoading: state => state.loading,
  getIsTimeChanged: state => state.isTimeChanged,
  isFormChanged: state => !_.isEqual(state.form, state.initialState),
};
const mutations = {
  setLoading(state, payload) {
    state.loading = {
      ...state.loading,
      ...payload,
    };
  },
  clearState(state) {
    (state.isTimeChanged = false),
      (state.loading = {
        data: false,
        mainDiagnose: false,
      });
    state.form = {
      ...state.form,
      isEditable: false,
      // id_inpatient: '',
      id_emr: '',
      doctor: '',
      date: {
        in: {
          formatted: '',
          raw: '',
        },
        out: {
          formatted: '',
          raw: '',
        },
      },
      time: {
        in: '',
        out: '',
      },
      room: '',
      reasonIn: '',
      diagnoseIn: '',
      diseaseHistory: '',
      physicalCheckup: '',
      labResult: '',
      supportCheckup: '',
      therapy: [''],
      consultationResult: '',
      mainDiagnose: {
        name: '',
        id_diagnose: '',
        search: null,
      },
      secondaryDiagnose: [
        {
          name: '',
          id_diagnose: '',
          loading: false,
        },
      ],
      procedure: [
        {
          name: '',
          id_procedure: '',
          loading: false,
        },
      ],
      allergyHistory: [''],
      takehomeDrug: [''],
      diet: '',
      instruction: '',
      finishedType: {
        name: '',
        description: '',
      },
      treatmentContinued: {
        name: '',
        description: '',
      },
    };
    state.initialState = {
      id_inpatient: '',
      id_emr: '',
      doctor: '',
      date: {
        in: {
          formatted: '',
          raw: '',
        },
        out: {
          formatted: '',
          raw: '',
        },
      },
      time: {
        in: '',
        out: '',
      },
      room: '',
      reasonIn: '',
      diagnoseIn: '',
      diseaseHistory: '',
      physicalCheckup: '',
      labResult: '',
      supportCheckup: '',
      therapy: [''],
      consultationResult: '',
      mainDiagnose: {
        name: '',
        id_diagnose: '',
        search: null,
      },
      secondaryDiagnose: [
        {
          name: '',
          id_diagnose: '',
          loading: false,
        },
      ],
      procedure: [
        {
          name: '',
          id_procedure: '',
          loading: false,
        },
      ],
      allergyHistory: [''],
      takehomeDrug: [''],
      diet: '',
      instruction: '',
      finishedType: {
        name: '',
        description: '',
      },
      treatmentContinued: {
        name: '',
        description: '',
      },
    };
  },
  setForm(state, payload) {
    state.form = {
      ...state.form,
      ...payload,
    };
  },
  setInitialState(state, payload) {
    state.initialState = {
      ...state.initialState,
      ...payload,
    };
  },
  setIsTimeChanged(state, payload) {
    state.isTimeChanged = payload;
  },
  mutateArrayVariable(state, { label, type, index, value }) {
    if (type === 'add') {
      state.form[label].push(value);
    }
    if (type === 'delete') {
      state.form[label].splice(index, 1);
    }
  },
};
const actions = {
  async resolvePostMedicalResume({ state, rootGetters }) {
    try {
      const payload = {
        id_inpatient: state.form.id_inpatient,
        id_emr: state.form.id_emr,
        dateTime: {
          dateIn: moment(
            `${state.form.date.in.formatted} ${state.form.time.in}`,
            'DD-MM-YYYY HH:mm',
          ).format(),
          dateout: moment(
            `${state.form.date.out.formatted} ${state.form.time.out}`,
            'DD-MM-YYYY HH:mm',
          ).format(),
        },
        doctor: state.form.doctor.id,
        room: state.form.room,
        reasonIn: state.form.reasonIn,
        diagnoseIn: state.form.diagnoseIn,
        disease_history: state.form.diseaseHistory,
        physical_checkup: state.form.physicalCheckup,
        lab_result: state.form.labResult,
        support_checkup: state.form.supportCheckup,
        therapy: state.form.therapy,
        consultation_result: state.form.consultationResult,
        main_diagnose: {
          name: state.form.mainDiagnose.name,
          id_diagnose:
            state.form.mainDiagnose.id_diagnose.id_diagnose || undefined,
        },
        secondary_diagnose: state.form.secondaryDiagnose.map(item => {
          return {
            name: item.name,
            id_diagnose: item.id_diagnose.id_diagnose || undefined,
          };
        }),
        procedure: state.form.procedure.map(item => {
          return {
            name: item.name,
            id_procedure: item.id_procedure.id_procedure || undefined,
          };
        }),
        allergy_history: state.form.allergyHistory,
        takehome_drug: state.form.takehomeDrug,
        diet: state.form.diet,
        instruction: state.form.instruction,
        finished_type: {
          name: state.form.finishedType.name,
          description: state.form.finishedType.description,
        },
        treatment_continued: {
          name: state.form.treatmentContinued.name,
          description: state.form.treatmentContinued.description,
        },
        timestamps: {
          created_by: rootGetters.userLoggedIn.id,
        },
      };
      await postMedicalResume(payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async resolveGetMedicalResume({ state, commit }) {
    try {
      commit('setLoading', { data: true });
      const response = await getMedicalResume(state.form.id_inpatient);
      const { data } = response.data;
      const mapData = mapMedicalResumeData(data, state);
      commit('setForm', mapData);
      commit('setInitialState', mapData);
    } catch (error) {
      commit('clearState');
      throw new Error(error);
    } finally {
      commit('setLoading', { data: false });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
