<template>
  <v-menu
    :disabled="disabled === 'disabled' || isEMR"
    v-model="menu"
    light
    close-on-content-click
    tile
    nudge-bottom="30"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on" class="d-flex justify-space-between font-weight-regular">
        <p class="ma-0 pa-0">{{ cellValue.name }}</p>
        <v-icon>mdi-chevron-down</v-icon>
      </div>
    </template>
    <v-card elevation="0" class="text-start px-4 py-4" flat>
      <v-list dense light>
        <v-list-item-group color="primary">
          <v-list-item
            color="primary"
            v-for="(item, index) in items"
            :key="`row-${index}`"
            class="row"
            @click="onSelectAction(item)"
          >
            {{ item.text }}
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations } = createNamespacedHelpers(
  'NeonatusAssessment',
);
export default {
  name: 'ApgarCell',
  props: {
    items: {
      type: Array,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: null,
    },
    disabled: {
      type: String,
      default: '',
    },
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['getForm']),
    cellValue: {
      get() {
        return this.getForm.apgar_score[`minute${this.min}`][this.type];
      },
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    ...mapMutations(['setApgarScore']),
    onSelectAction(item) {
      this.setApgarScore({
        min: this.min,
        key: this.type,
        payload: {
          name: item.text,
          score: item.score,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.row {
  font-size: 0.8vw;
  cursor: pointer;
}
</style>
