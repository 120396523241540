import Constant from '@/const';
import axios from 'axios';

const getDiagnose = async ({ keyword }) => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/master/diagnose?page=1&itemCount=10&search=${
          keyword === null ? '' : keyword
        }&sort=-code`,
      ),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getDiagnose;
