<template>
  <div>
    <div class="bordered mb-4">
      <div class="d-flex align-center">
        <h3 class="text-start mr-3">Jumlah Kunjungan</h3>
        <v-progress-circular
          width="3"
          color="primary"
          size="20"
          indeterminate
          v-show="loading.updating"
        />
      </div>
      <skeleton :isLoading="loading.mounting">
        <div class="chart-container">
          <apexcharts
            ref="realtimeChart"
            type="line"
            height="100%"
            :options="chartOptions"
            :series="series"
          />
        </div>
      </skeleton>
    </div>
    <v-skeleton-loader v-if="loading.mounting" type="table" />
    <detail-table-patient-visit
      v-else
      @switch-view="switchView"
      :isAssuranceType="isAssuranceType"
    />
  </div>
</template>

<script>
// utils
const moment = require('moment-timezone');
import VueApexCharts from 'vue-apexcharts';

// components
import Skeleton from '../../../SharedComponent/Skeleton.vue';
import DetailTablePatientVisit from './DetailTablePatientVisit';

// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('PatientVisitStore');

export default {
  name: 'PatientVisitData',
  props: {
    isAssuranceType: {
      type: Boolean,
      default: false,
    },
    filterType: {
      type: String,
      default: 'Tahun',
    },
  },
  components: {
    apexcharts: VueApexCharts,
    DetailTablePatientVisit,
    Skeleton,
  },
  watch: {
    filterType() {
      this.$refs.realtimeChart.updateOptions({
        tooltip: {
          x: {
            formatter: this.formatter,
          },
        },
      });
    },
  },
  computed: {
    ...mapGetters(['getSeries', 'getResources', 'getLoading', 'getFilter']),
    loading: {
      get() {
        return this.getLoading;
      },
    },
    year: {
      get() {
        return this.getFilter.year;
      },
    },
    month: {
      get() {
        return this.getFilter.month;
      },
    },
    chartOptions: {
      get() {
        return {
          chart: {
            id: 'realtimeChart',
            width: '730px',
            height: 300,
          },
          tooltip: {
            x: {
              formatter: this.formatter,
            },
          },
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'Mei',
              'Jun',
              'Jul',
              'Agu',
              'Sep',
              'Okt',
              'Nov',
              'Des',
            ],
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            horizontalAlign: 'left',
            itemMargin: {
              horizontal: 8,
            },
          },
          toolbar: {
            show: false,
          },
        };
      },
    },
    series: {
      get() {
        return this.isAssuranceType
          ? this.getSeries.assurance
          : this.getSeries.unit;
      },
    },
  },
  methods: {
    switchView() {
      this.$emit('switch-view');
    },
    formatter(value) {
      return this.month?.formatted
        ? `${value} ${moment(this.month?.formatted, 'MM-YYYY').format(
            'MMMM',
          )} ${moment(this.month?.formatted, 'MM-YYYY').format('YYYY')}`
        : `${this.chartOptions.xaxis.categories[value - 1]} ${this.year}`;
    },
  },
};
</script>

<style scoped lang="scss">
.bordered {
  border: 2px solid #e0e0e0;
  border-radius: 0.6vw;
  padding: 1vw;

  .chart-container {
    min-height: 40vh;
  }
}
</style>
