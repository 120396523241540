import Constant from '@/const';
import axios from 'axios';

const putReloadRecipe = async id => {
  try {
    await axios.put(Constant.apiUrl.concat(`/patient/reload/${id}`));
  } catch (error) {
    throw new Error(error);
  }
};

export default putReloadRecipe;
