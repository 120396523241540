<template>
  <v-card class="pa-3">
    <v-form ref="form">
      <v-row class="px-15 mx-10 mt-0 mb-16 scrollbar">
        <v-col cols="6">
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Nomor Surat</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ numberLetter }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Nama Pasien</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.name }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">No RM</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.rmNumber }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Tanggal Lahir</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.birthDate }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Alamat</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.address }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Asal Klinik</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.polyName }}</label>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold required">Diijinkan Pulang</label>
            </v-col>
            <v-col cols="7" class="">
              <v-select
                append-icon="mdi-chevron-down"
                :rules="[v => !!v || 'Wajib diisi']"
                dense
                class="pt-0 mt-0"
                placeholder="Pilih"
                :items="resource.returnStatus"
                item-text="text"
                item-value="value"
                v-model="input.returnStatus"
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold required">Nama Dokter</label>
            </v-col>
            <v-col cols="7" class="">
              <v-autocomplete
                append-icon="mdi-chevron-down"
                :rules="[v => !!v || 'Wajib diisi']"
                dense
                class="pt-0 mt-0"
                placeholder="Pilih Dokter"
                clearable
                auto-select-first
                :items="resource.doctor"
                item-text="doctorName"
                return-object
                v-model="input.doctor"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold required"
                >Tanggal Kontrol Kembali</label
              >
            </v-col>
            <v-col cols="6" class="">
              <Datepicker
                :outlined="false"
                :show="control"
                :date="input.controlDate"
                :min="today"
                :calendar-event="true"
                @date-change="handleDateChange"
              />
            </v-col>
          </v-row>
          <v-row
            v-for="(diagnose, index) in input.diagnose"
            align="center"
            :key="'diagnose-' + index"
          >
            <v-col v-if="index === 0" cols="5" class="text-left">
              <label class="font-weight-bold required">Diagnosa</label>
            </v-col>
            <v-col v-else cols="5"></v-col>
            <v-col cols="6" class="">
              <v-combobox
                append-icon="mdi-chevron-down"
                :rules="index === 0 ? [v => !!v > 0 || 'Wajib diisi'] : []"
                placeholder="Diagnosa"
                class="mt-0 pt-0"
                @focus="diagnoseActiveIndex = index"
                dense
                :loading="diagnoseLoading"
                clearable
                :items="resource.diagnose"
                item-text="display_name"
                item-value="id"
                v-model="input.diagnose[index]"
                return-object
                :search-input.sync="searchDiagnose[index]"
              ></v-combobox>
            </v-col>
            <v-col cols="1" class="d-flex">
              <v-btn icon @click="addDiagnose()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn v-if="index !== 0" icon @click="deleteItem(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-for="(therapy, index) in input.therapies"
            align="center"
            :key="'therapy-' + index"
          >
            <v-col v-if="index === 0" cols="5" class="text-left">
              <label class="font-weight-bold">Terapi</label>
            </v-col>
            <v-col v-else cols="5"></v-col>
            <v-col cols="6" class="">
              <v-text-field
                :placeholder="'Terapi ' + (index + 1)"
                class="pt-0 mt-0"
                hide-details="false"
                dense
                v-model="input.therapies[index]"
                :maxLength="3000"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="flex-item d-flex justify-start">
              <v-btn @click="addTherapy()" small icon class="mr-2 "
                ><v-icon>mdi-plus</v-icon></v-btn
              >
              <v-btn
                @click="deleTherapy(index)"
                v-if="index !== 0"
                small
                icon
                class="mr-2 "
                ><v-icon small>mdi-delete</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-row
            v-for="(suggest, index) in input.suggestions"
            align="center"
            :key="'suggestion-' + index"
          >
            <v-col v-if="index === 0" cols="5" class="text-left">
              <label class="font-weight-bold">Anjuran</label>
            </v-col>
            <v-col v-else cols="5"></v-col>
            <v-col cols="6" class="">
              <v-text-field
                :placeholder="'Anjuran ' + (index + 1)"
                class="pt-0 mt-0"
                hide-details="false"
                dense
                v-model="input.suggestions[index]"
                :maxLength="3000"
                @keyup="addSuggestion($event, index)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="pa-0">
      <v-btn class="text-none" plain @click="$emit('close-form')">
        Kembali
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        tile
        outlined
        color="primary"
        class="text-none"
        width="116"
        :disabled="disabled"
        @click="printOutpatientLetter()"
      >
        <font-awesome-icon icon="print" />
        Print
      </v-btn>
      <v-btn
        color="primary"
        tile
        elevation="0"
        class="text-none"
        width="108"
        @click="addOutpatientLetter()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';
import 'jspdf-autotable';
import moment from 'moment-timezone';
import jwtMixin from '@/mixin/jwtMixin';
import printMixin from '@/mixin/printMixin';
import Letter from '@/mixin/Letter';
import { scrollToEmptyInput } from '@/helper';
import Datepicker from '@/components/SharedComponent/Datepicker/Datepicker.vue';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'OutpatientWarrant',
  mixins: [AlertMixin, jwtMixin, printMixin, Letter],
  components: { Datepicker },
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      menu: false,
      today: moment().format('YYYY-MM-DD'),
      diagnoseActiveIndex: 0,
      control: false,
      resource: {
        returnStatus: [
          { text: 'Sembuh', value: 'sembuh' },
          { text: 'Belum Sembuh', value: 'belum sembuh' },
        ],
        diagnose: [],
        doctor: [],
      },
      input: {
        returnStatus: '',
        doctor: '',
        // expiredDate: '',
        controlDate: '',
        diagnose: [''],
        therapies: [''],
        suggestions: [''],
        numberLetter: '',
      },
      date: null,
      diagnoseLoading: false,
      searchDiagnose: [],
      timestamps: new Date(),
      numberLetter: '',
      disabled: false,
    };
  },
  computed: {
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
    isPhysiotherapy() {
      return (
        this.patientData.meta.polyName.toLowerCase() === 'poli fisioterapi'
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(async () => {
        this.getDiagnose('');
        await this.getDoctor();
        this.getOutpatientLetter();
      });
    });
  },
  watch: {
    searchDiagnose(value) {
      if (!value) return;
      this.searchData(this);
    },
  },
  methods: {
    handleDateChange(updatedDate) {
      this.input.controlDate = updatedDate;
    },
    addTherapy() {
      this.input.therapies.push('');
    },
    deleTherapy(index) {
      this.input.therapies.splice(index, 1);
    },
    addSuggestion(event, index) {
      if (event.keyCode === 9) return;
      if (
        (this.input.suggestions[index] !== '') &
        (this.input.suggestions.length - 1 === index)
      ) {
        this.input.suggestions.push('');
      } else if (
        this.input.suggestions[index] === '' &&
        this.input.suggestions.length !== 1
      ) {
        this.input.suggestions.splice(index, 1);
      }
    },
    addDiagnose() {
      this.input.diagnose.push('');
      this.getDiagnose('');
    },
    getDiagnose(keyword) {
      this.diagnoseLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/diagnose?page=1&itemCount=20&search=${
              keyword === null ? '' : keyword
            }`,
          ),
        )
        .then(response => {
          let allDiagnose = response.data.data;
          this.resource.diagnose = allDiagnose.map(diagnose => {
            return {
              id: diagnose._id.toString(),
              code: diagnose.code,
              text: diagnose.description,
              display_name: diagnose.code + ' - ' + diagnose.description,
            };
          });
          this.diagnoseLoading = false;
        })
        .catch(() => {
          this.diagnoseLoading = false;
        });
    },
    deleteItem(index) {
      this.input.diagnose.splice(index, 1);
    },
    getDoctor() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/staff?role[]=Dokter&role[]=Bidan&role[]=dokter&role[]=bidan${
              this.isPhysiotherapy
                ? '&role[]=fisioterapis&role[]=Fisioterapis'
                : ''
            }`,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.resource.doctor = data.map(doctor => {
              return {
                doctorId: doctor._id.toString(),
                doctorName: doctor.detail.name,
              };
            });
          }
        })
        .catch(() => {
          this.resource.doctor = [];
        });
    },
    async addOutpatientLetter() {
      if (await this.$refs.form.validate()) {
        const payload = {
          id_registration: this.patientData.id_registration,
          letter_number: this.numberLetter,
          detail: [
            {
              id_unit: this.patientData.meta.id_poly,
              return_type: this.input.returnStatus,
              doctor: this.input.doctor.doctorId,
              return_control_date: this.input.controlDate,
              diagnosis: this.input.diagnose
                .filter(x => x !== '')
                .map(arr => {
                  return arr.id;
                }),
              therapies: this.input.therapies.filter(x => x !== ''),
              suggestions: this.input.suggestions.filter(x => x !== ''),
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
            },
          ],
        };
        axios
          .post(
            Constant.apiUrl.concat('/letter/out-patient-control-letter'),
            payload,
          )
          .then(() => {
            Swal.fire(
              'Surat Keterangan Rawat Jalan Berhasil Dibuat',
              '',
              'success',
            );
            this.getOutpatientLetter();
          })
          .catch(err => {
            this.showErrorAxios(err);
          });
      } else {
        scrollToEmptyInput();
      }
    },
    generateNumber() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/out-patient-control-letter/generate/${this.patientData.meta.id_poly}`,
          ),
        )
        .then(response => {
          this.numberLetter = response.data.data;
        })
        .catch(error => {
          if (error) {
            Swal.fire('Gagal Menggenerate nomor surat', '', 'error');
          }
        });
    },
    getOutpatientLetter() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/out-patient-control-letter/registration/${this.patientData.id_registration}`,
          ),
        )
        .then(response => {
          this.disabled = false;
          const outpatient = response.data.data;
          this.input = {
            returnStatus: outpatient.detail.return_type,
            doctor: {
              doctorId: outpatient.detail.doctor.id,
              doctorName: outpatient.detail.doctor.name,
              sip: outpatient.detail.sip,
            },
            expiredDate: outpatient.detail.expired_date,
            controlDate: outpatient.detail.return_control_date,
            diagnose: outpatient.detail.diagnose.map(arr => {
              return {
                ...arr,
                text: arr.name,
              };
            }),
            therapies:
              outpatient.detail.therapies.length > 0
                ? outpatient.detail.therapies
                : [''],
            suggestions:
              outpatient.detail.suggestions.length > 0
                ? outpatient.detail.suggestions
                : [''],
            numberLetter: outpatient.letter_number,
          };

          this.numberLetter = outpatient.letter_number;

          outpatient.detail.diagnosis.forEach(x => {
            this.getDiagnoseById(x);
          });
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.disabled = true;
            this.generateNumber();
            this.input.doctor = {
              doctorId: this.patientData.meta.doctorId,
              doctorName: this.patientData.meta.doctor,
            };
            this.getDataAssessment();
          } else this.showErrorAxios(err);
        });
    },
    printOutpatientLetter() {
      const doc = this.generateOutpatientLetter(this.patientData, this.input);
      this.printDocument(doc, {
        docName: 'Surat Kontrol Rawat Jalan',
        rmNumber: this.patientData.rmNumber,
      });
    },
    getDiagnoseById(id) {
      axios
        .get(Constant.apiUrl.concat(`/master/diagnose/${id}`))
        .then(response => {
          this.input.diagnose.push({
            id: response.data.data._id,
            code: response.data.data.code,
            text: response.data.data.description,
            display_name: `${response.data.data.code} - ${response.data.data.description}`,
          });
        })
        .catch(() => {
          this.input.diagnose = [''];
        });
    },
    async getDataAssessment() {
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/patient/checkup/regist/${this.patientData.id_registration}/service`,
          ),
        );
        const { data } = response.data;
        if (data.assesment?.main_diagnose) {
          this.input = {
            ...this.input,
            therapies:
              data.planning.therapy.length > 0 ? data.planning?.therapy : [''],
            doctor: {
              doctorId: this.patientData.meta.doctorId,
              doctorName: this.patientData.meta.doctor,
            },
            diagnose: [
              {
                code: data.assesment?.main_diagnose?.diagnose.code,
                id: data.assesment?.main_diagnose?.diagnose._id,
                text: data.assesment?.main_diagnose?.diagnose.description,
                display_name: `${data.assesment?.main_diagnose?.diagnose.code} - ${data.assesment?.main_diagnose?.diagnose.description}`,
              },
            ],
            suggestions: [''],
          };
        }
        if (data.timestamps?.doctor_by) {
          this.input = {
            ...this.input,
            therapy: data.planning.therapy.filter(i => i).join(', '),
          };
        }
      } catch {
        this.input = {
          ...this.input,
        };
      }
    },
    searchData: _.debounce(
      v => v.getDiagnose(v.searchDiagnose[v.diagnoseActiveIndex]),
      650,
    ),
  },
};
</script>

<style lang="scss" scoped>
.body-container {
  padding-left: 150px;
}

.form-body {
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }

  &__data {
    height: 20px;
  }
}

.scrollbar {
  height: 400px;
  overflow-y: auto;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #c2c2c2;
  }
}

.required::after {
  content: '*';
  color: #eb4747;
}
</style>
