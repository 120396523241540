import Constant from '@/const';
import axios from 'axios';

const postMedicalResume = async payload => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat('/inpatient/resume'),
      payload,
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default postMedicalResume;
