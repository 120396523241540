<template>
  <v-container class="pa-0" fluid>
    <v-row no-gutters>
      <v-toolbar flat outlined height="130" style="display: grid;">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters align="center" justify="space-between">
              <v-col cols="6" class="text-left">
                <h2>Daftar Jurnal</h2>
              </v-col>
              <v-col cols="1" class="text-right">
                <router-link to="/keuangan/akuntansi">
                  <v-btn icon><v-icon>mdi-home</v-icon></v-btn>
                </router-link>
              </v-col>
              <v-col class="mx-3" cols="1">
                <v-btn depressed color="primary" class="text-none"
                  >Export</v-btn
                >
              </v-col>
              <v-col cols="3" class="pr-0">
                <v-text-field
                  class="input"
                  background-color="grey lighten-3"
                  placeholder="Search"
                  solo
                  dense
                  hide-details
                  flat
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  v-model="search"
                ></v-text-field>
              </v-col>
              <v-btn icon @click="refreshAndAutoUpdate()"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </v-row>
          </v-col>
          <v-col class="pt-3" cols="10">
            <v-row no-gutters align="center">
              <v-col>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="filter.date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filter.date"
                      placeholder="Pilih Tanggal"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details="false"
                      clearable
                      @click:clear="
                        filter.date = null;
                        getJournal();
                      "
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filter.date"
                    @input="
                      $refs.menu.save(filter.date);
                      menu = false;
                      getJournal();
                    "
                  />
                </v-menu>
              </v-col>

              <v-icon>mdi-minus</v-icon>

              <v-col>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="filter.endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filter.endDate"
                      placeholder="Pilih Tanggal"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details="false"
                      clearable
                      @click:clear="
                        filter.endDate = null;
                        getJournal();
                      "
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filter.endDate"
                    @input="
                      $refs.menu2.save(filter.endDate);
                      menu2 = false;
                      getJournal();
                    "
                  />
                </v-menu>
              </v-col>
              <v-col class="pl-3 pr-2">
                <v-select
                  placeholder="Tipe Transaksi"
                  outlined
                  dense
                  hide-details="false"
                  :items="resource.transactionType"
                  clearable
                ></v-select>
              </v-col>
              <v-col class="pr-3 pl-1">
                <v-select
                  placeholder="User"
                  outlined
                  dense
                  hide-details="false"
                  :items="resource.user"
                  clearable
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  placeholder="Unit"
                  outlined
                  dense
                  hide-details="false"
                  :items="resource.unit"
                  clearable
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-row>

    <v-tabs grow v-model="tabs">
      <v-tab class="text-none">Belum Terposting</v-tab>
      <v-tab class="text-none">Sudah Terposting</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-row no-gutters>
          <v-data-table
            :headers="table.headers"
            :items="table.items"
            single-expand
            :expanded.sync="expanded"
            item-key="journalNumber"
            show-expand
            fixed-header
            :items-per-page="-1"
            height="60vh"
            :loading="isLoading"
            hide-default-footer
          >
            <template v-slot:[`item.no`]="{ item }">
              <td>{{ table.items.indexOf(item) + 1 }}</td>
            </template>
            <template v-slot:[`item.invoiceNumber`]="{ item }">
              <a>{{ item.invoiceNumber }}</a>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-card
                flat
                width="88"
                height="29"
                :color="item.status === 'Ditolak' ? '#FFF2F2' : '#FFF9E5'"
                class="text-center py-1 caption"
              >
                <label
                  class="font-weight-bold"
                  :class="
                    item.status === 'Ditolak' ? 'red--text' : 'orange--text'
                  "
                >
                  {{ item.status }}</label
                >
              </v-card>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn icon color="primary" @click="onClicked(1, item)"
                ><v-icon>mdi-information</v-icon></v-btn
              >
            </template>
            <template v-slot:expanded-item="{ item }">
              <td></td>
              <td></td>
              <td class="text-left">
                <label class="caption grey--text font-weight-bold"
                  >Dibuat oleh</label
                >
                <br />
                <label class="caption grey--text"></label>
              </td>
              <td class="text-left">
                <label class="caption grey--text font-weight-bold"
                  >Dibuat pada</label
                >
                <br />
                <label class="caption grey--text">{{
                  item.created_at.date
                }}</label>
              </td>
              <td class="text-left" :colspan="4">
                <div class="account-style">
                  <label class="caption grey--text font-weight-bold"
                    >Kode Akun</label
                  >
                  <label class="caption grey--text font-weight-bold"
                    >Nama Akun</label
                  >
                  <label class="caption grey--text font-weight-bold"
                    >Debit</label
                  >
                  <label class="caption grey--text font-weight-bold"
                    >Kredit</label
                  >
                  <template v-for="(acc, idx) in item.entries">
                    <a
                      href="/keuangan/akuntansi/daftar-akun"
                      text
                      class="caption primary--text"
                      :key="'code-' + idx"
                    ></a>
                    <label
                      :key="'name-' + idx"
                      class="caption grey--text"
                    ></label>
                    <label :key="'debit-' + idx" class="caption grey--text">{{
                      formatted(acc.debit)
                    }}</label>
                    <label :key="'credit-' + idx" class="caption grey--text">{{
                      formatted(acc.credit)
                    }}</label>
                  </template>
                </div>
              </td>
            </template>
          </v-data-table>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row no-gutters>
          <v-data-table
            :headers="table.headers"
            :items="table.posted"
            single-expand
            :expanded.sync="expandedPosted"
            item-key="journalNumber"
            show-expand
            fixed-header
            height="60vh"
            :loading="isLoading"
            :items-per-page="-1"
            hide-default-footer
          >
            <template v-slot:[`item.no`]="{ item }">
              <td>{{ table.posted.indexOf(item) + 1 }}</td>
            </template>
            <template v-slot:[`item.invoiceNumber`]="{ item }">
              <td>{{ item.invoiceNumber }}</td>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-card
                flat
                width="100"
                height="29"
                :color="
                  item.status === 'Sudah Koreksi' ? 'grey lighten-3' : '#FFF9E5'
                "
                class="text-center py-1 caption"
              >
                <label
                  class="font-weight-bold"
                  :class="
                    item.status === 'Sudah Koreksi'
                      ? 'black--text'
                      : 'red--text'
                  "
                >
                  {{ item.status }}</label
                >
              </v-card>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn icon color="primary" @click="onClicked(2, item)"
                ><v-icon>mdi-information</v-icon></v-btn
              >
            </template>
            <template v-slot:expanded-item="{ item }">
              <td></td>
              <td></td>
              <td class="text-left">
                <label class="caption grey--text font-weight-bold"
                  >Dibuat oleh</label
                >
                <br />
                <label class="caption grey--text"></label>
              </td>
              <td class="text-left">
                <label class="caption grey--text font-weight-bold"
                  >Dibuat pada</label
                >
                <br />
                <label class="caption grey--text">{{
                  item.created_at.date
                }}</label>
              </td>
              <td class="text-left" :colspan="4">
                <div class="account-style">
                  <label class="caption grey--text font-weight-bold"
                    >Kode Akun</label
                  >
                  <label class="caption grey--text font-weight-bold"
                    >Nama Akun</label
                  >
                  <label class="caption grey--text font-weight-bold"
                    >Debit</label
                  >
                  <label class="caption grey--text font-weight-bold"
                    >Kredit</label
                  >
                  <template v-for="(acc, idx) in item.entries">
                    <a
                      href="/keuangan/akuntansi/daftar-akun"
                      text
                      class="caption primary--text"
                      :key="'code-' + idx"
                    ></a>
                    <label
                      :key="'name-' + idx"
                      class="caption grey--text"
                    ></label>
                    <label :key="'debit-' + idx" class="caption grey--text">{{
                      formatted(acc.debit)
                    }}</label>
                    <label :key="'credit-' + idx" class="caption grey--text">{{
                      formatted(acc.credit)
                    }}</label>
                  </template>
                </div>
              </td>
            </template>
          </v-data-table>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <v-btn
      fab
      color="primary"
      depressed
      class="btn-style"
      @click="onClicked(0, null)"
      ><v-icon large>mdi-plus-thick</v-icon></v-btn
    >

    <v-dialog v-model="dialog" persistent>
      <TransactionJournal
        v-if="selectedForm === 0 && dialog"
        :is-correction="isCorrection"
        @close-dialog="
          dialog = false;
          isCorrection = false;
          getJournal();
        "
      />
      <DetailJournal
        v-else-if="selectedForm === 1 && dialog"
        :item="selectedItem"
        @close-dialog="dialog = false"
      />
      <CorrectionJournal
        v-else-if="selectedForm === 2 && dialog"
        :item="selectedItem"
        @close-dialog="
          dialog = false;
          getJournal();
        "
        @correction="correct"
      />
    </v-dialog>
  </v-container>
</template>

<script>
// import Swal from 'sweetalert2';
import TransactionJournal from './JournalList/TransactionJournal.vue';
import DetailJournal from './JournalList/DetailJournal.vue';
import CorrectionJournal from './JournalList/CorrectionJournal.vue';
import Constant from '@/const';
import moment from 'moment-timezone';
import formatMixin from '@/mixin/formatMixin';

const axios = require('axios');

export default {
  name: 'JournalList',
  mixins: [formatMixin],
  components: {
    TransactionJournal,
    DetailJournal,
    CorrectionJournal,
  },
  data() {
    return {
      expanded: [],
      expandedPosted: [],
      menu: false,
      menu2: false,
      table: {
        headers: [
          { text: '', value: 'data-table-expand', width: '5%' },
          {
            text: 'No',
            value: 'no',
            sortable: false,
          },
          {
            text: 'No. Jurnal',
            value: 'journalNumber',
            width: '15%',
            sortable: false,
          },
          { text: 'Tanggal', value: 'displayDate' },
          {
            text: 'Tipe Transaksi',
            value: 'transactionType',
            sortable: false,
            width: '15%',
          },
          { text: 'Uraian', value: 'detail', sortable: false, width: '25%' },
          { text: 'Unit', value: 'unit', sortable: false, width: '15%' },
          {
            text: 'No. Bukti',
            value: 'invoiceNumber',
            sortable: false,
            width: '15%',
          },
          {
            text: 'Nilai Transaksi',
            value: 'valueTransaction',
            sortable: false,
          },
          { text: 'Status', value: 'status', sortable: false, width: '10%' },
          { text: '', value: 'action', sortable: false },
        ],
        items: [],
        posted: [],
      },
      selectedData: null,
      selectedForm: null,
      selectedItem: null,
      dialog: false,
      search: '',
      loading: false,
      tabs: null,
      isCorrection: false,
      resource: {
        transactionType: [
          'Transfer',
          'Utang',
          'Piutang',
          'Pendapatan',
          'Beban',
        ],
        user: [],
        unit: [],
      },
      isLoading: false,
      filter: {
        date: null,
        endDate: null,
        transactionType: '',
        user: '',
        unit: '',
      },
    };
  },
  mounted() {
    this.refreshAndAutoUpdate();
  },
  computed: {
    isFinance() {
      return this.$store.getters.userLoggedIn.role === 'Keuangan';
    },
  },
  methods: {
    onClicked(idx, item) {
      if (idx !== 0) {
        this.selectedItem = item;
      }
      this.selectedForm = idx;
      this.dialog = true;
    },
    correct() {
      this.selectedForm = 0;
      this.isCorrection = true;
      this.dialog = true;
    },
    getJournal() {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/finance/journal?target=journal?startDate=${this.filter.date ||
              ''}?endDate=${this.filter.endDate || ''}`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          this.table.items = data
            .filter(x => !x.status.posted)
            .map(arr => {
              return {
                id: arr._id,
                journalNumber: arr.journal_code,
                date: arr.date,
                displayDate: moment(arr.date).format('DD/MM/YYYY'),
                transactionType: arr.transaction_type || '-',
                detail: arr.description || '-',
                unit: arr.unit || '-',
                invoiceNumber: arr.proof_code,
                valueTransaction: arr.transaction_value
                  ? this.formattedCurrency(arr.transaction_value)
                  : 0,
                status: arr.status.journal,
                entries: arr.entries,
                created_at: {
                  date: moment(arr.timestamps.created_at).format('DD/MM/YYYY'),
                  time: moment(arr.timestamps.created_at).format('hh:mm'),
                },
                note: arr.note,
                journalType: arr.journal_type,
                files: arr.files.map(x => {
                  return x.file;
                }),
              };
            });
          this.table.posted = data
            .filter(x => x.status.posted)
            .map(arr => {
              return {
                id: arr._id,
                journalNumber: arr.journal_code,
                date: arr.date,
                displayDate: moment(arr.date).format('DD/MM/YYYY'),
                transactionType: arr.transaction_type || '-',
                detail: arr.description || '-',
                unit: arr.unit || '-',
                invoiceNumber: arr.proof_code,
                valueTransaction: arr.total_transaction,
                status: arr.status.journal,
                entries: arr.entries,
                created_at: {
                  date: moment(arr.timestamps.created_at).format('DD/MM/YYYY'),
                  time: moment(arr.timestamps.created_at).format('HH:mm'),
                },
                note: arr.note,
                journalType: arr.journal_type,
                files: arr.files.map(x => {
                  return x.file;
                }),
              };
            });
          this.isLoading = false;
          this.resource.unit = this.table.items.map(arr => {
            return arr.unit;
          });
        });
    },
    // getJournalPosted() {
    //   this.isLoading = true;
    //   axios
    //     .get(Constant.apiUrl.concat(`/finance/journal?target=journal`))
    //     .then(response => {
    //       const { data } = response.data;
    //       this.table.posted = data
    //         .filter(x => x.status.posted)
    //         .map(arr => {
    //           return {
    //             id: arr._id,
    //             journalNumber: arr.journal_code,
    //             date: arr.date,
    //             displayDate: moment(arr.date).format('DD/MM/YYYY'),
    //             transactionType: arr.transaction_type || '-',
    //             detail: arr.description || '-',
    //             unit: arr.unit || '-',
    //             invoiceNumber: arr.proof_code,
    //             valueTransaction: arr.total_transaction,
    //             status: arr.status.journal,
    //             entries: arr.entries,
    //             created_at: {
    //               date: moment(arr.timestamps.created_at).format('DD/MM/YYYY'),
    //               time: moment(arr.timestamps.created_at).format('HH:mm'),
    //             },
    //             note: arr.note,
    //             journalType: arr.journal_type,
    //             files: arr.files.map(x => {
    //               return x.file;
    //             }),
    //           };
    //         });
    //       this.isLoading = false;
    //     });
    // },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.cancelAutoUpdate();
      }
      this.isLoading = true;
      this.getJournal();
      // this.getJournalPosted();
      this.interval = setInterval(this.getJournal, 60000);
    },
    cancelAutoUpdate() {
      this.interval = clearInterval(this.interval);
    },
    formatted(val) {
      var format = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      });
      return val ? format.format(val) : format.format(0);
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>

<style lang="scss" scoped>
.btn-style {
  position: absolute;
  bottom: 5vh;
  right: 5vh;
}
a {
  text-decoration: none;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
  vertical-align: baseline;
  font-size: 0.8rem;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 10px;
}
::v-deep .v-data-table {
  width: 100%;
  height: 60vh;
  overflow: auto;
}

.account-style {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr 2fr;
}
</style>
