<template>
  <v-card class="pt-3" flat>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="py-1">
        <v-col cols="4">
          <v-row align="baseline">
            <v-col cols="5" class="text-right">
              <label class="body-2 font-weight-bold black--text">Nama</label>
            </v-col>
            <v-col class="py-0">
              <v-text-field
                placeholder="Masukkan Nama"
                dense
                v-model="triaseLevel.name"
                :rules="rules.name"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row align="baseline">
            <v-col class="text-right" cols="5">
              <label class="body-2 font-weight-bold black--text"
                >Jenis Kelamin</label
              >
            </v-col>
            <v-col class="py-0">
              <v-select
                :items="gender"
                placeholder="Pilih Jenis Kelamin"
                dense
                :rules="rules.gender"
                v-model="triaseLevel.gender"
                required
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row align="baseline">
            <v-col class="text-right py-0">
              <label class="body-2 font-weight-bold black--text"
                >Nama Penanggung Jawab (Opsional)</label
              >
            </v-col>
            <v-col>
              <v-text-field
                placeholder="Masukkan nama"
                hide-details="false"
                dense
                v-model="triaseLevel.pic"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="py-1">
        <v-col cols="4">
          <v-row align="baseline">
            <v-col cols="5" class="text-right">
              <label class="body-2 font-weight-bold black--text">Alamat</label>
            </v-col>
            <v-col class="py-0">
              <v-text-field
                placeholder="Masukkan alamat"
                :rules="rules.address"
                dense
                v-model="triaseLevel.address"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <v-row align="baseline">
            <v-col class="text-right">
              <label class="body-2 font-weight-bold black--text"
                >Nama Orang Tua (Opsional)</label
              >
            </v-col>
            <v-col>
              <v-text-field
                placeholder="Masukkan nama"
                hide-details="false"
                dense
                v-model="triaseLevel.parentsName"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="py-1">
        <v-col cols="10">
          <v-row align="baseline">
            <v-col class="text-right" cols="2">
              <label class="body-2 font-weight-bold black--text">PACS</label>
            </v-col>
            <v-col cols="2" class="">
              <v-btn
                :outlined="triaseLevel.pacs !== 1"
                :class="{ 'white--text': triaseLevel.pacs === 1 }"
                color="red"
                tile
                depressed
                width="153"
                @click="onClick(1)"
                >PACS 1</v-btn
              >
            </v-col>
            <v-col cols="2" class="">
              <v-btn
                :outlined="triaseLevel.pacs !== 2"
                :class="{ 'white--text': triaseLevel.pacs === 2 }"
                tile
                color="#F2C94C"
                width="153"
                depressed
                @click="onClick(2)"
                >PACS 2</v-btn
              >
            </v-col>
            <v-col cols="2" class="">
              <v-btn
                :outlined="triaseLevel.pacs !== 3"
                :class="{ 'white--text': triaseLevel.pacs === 3 }"
                tile
                color="#F2C94C"
                width="153"
                depressed
                @click="onClick(3)"
                >PACS 3</v-btn
              >
            </v-col>
            <v-col cols="2" class="">
              <v-btn
                :outlined="triaseLevel.pacs !== 4"
                :class="{ 'white--text': triaseLevel.pacs === 4 }"
                tile
                color="#27AE60"
                width="153"
                depressed
                @click="onClick(4)"
                >PACS 4</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="py-1">
        <v-col cols="10">
          <v-row align="center">
            <v-col cols="2" class="text-right">
              <label class="body-2 font-weight-bold black--text"
                >Kesadaran</label
              >
            </v-col>
            <v-col cols="2">
              <v-btn
                :color="triaseLevel.awareness === 1 ? 'primary' : '#A6ADB4'"
                :outlined="triaseLevel.awareness !== 1"
                tile
                depressed
                width="153"
                class="text-none"
                @click="awareClick(1)"
                >Sadar Penuh</v-btn
              >
            </v-col>
            <v-col cols="2">
              <v-btn
                :color="triaseLevel.awareness === 2 ? 'primary' : '#A6ADB4'"
                :outlined="triaseLevel.awareness !== 2"
                tile
                width="153"
                depressed
                class="text-none"
                @click="awareClick(2)"
                >Respon Suara</v-btn
              >
            </v-col>
            <v-col cols="2">
              <v-btn
                :color="triaseLevel.awareness === 3 ? 'primary' : '#A6ADB4'"
                :outlined="triaseLevel.awareness !== 3"
                tile
                width="153"
                depressed
                class="text-none"
                @click="awareClick(3)"
                >Respon Nyeri</v-btn
              >
            </v-col>
            <v-col cols="2">
              <v-btn
                :color="triaseLevel.awareness === 4 ? 'primary' : '#A6ADB4'"
                :outlined="triaseLevel.awareness !== 4"
                tile
                width="153"
                depressed
                class="text-none"
                @click="awareClick(4)"
                >Tidak Nyeri</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="py-1">
        <v-col cols="4">
          <v-row align="baseline">
            <v-col cols="5" class="text-right">
              <label class="body-2 font-weight-bold black--text"
                >Tekanan Darah</label
              >
            </v-col>
            <v-col class="py-0 d-flex">
              <v-text-field
                placeholder="Sistol"
                dense
                v-model.number="triaseLevel.sistole"
                type="number"
                max="3"
                required
                oninput="if (this.value.length > this.max) this.value = this.value.slice(0, this.max);"
              ></v-text-field>
              <p class="px-2">/</p>
              <v-text-field
                placeholder="Diastol"
                suffix="mmHg"
                dense
                v-model.number="triaseLevel.diastole"
                type="number"
                max="3"
                required
                oninput="if (this.value.length > this.max) this.value = this.value.slice(0, this.max);"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row align="baseline">
            <v-col cols="5" class="text-right">
              <label class="body-2 font-weight-bold black--text"
                >Temperatur</label
              >
            </v-col>
            <v-col class="py-0">
              <v-text-field
                placeholder="Masukkan nilai"
                suffix="celcius"
                dense
                v-model.number="triaseLevel.temperature"
                type="number"
                max="4"
                hint="ex: 35.5"
                required
                oninput="if (this.value.length > this.max) this.value = this.value.slice(0, this.max);"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="py-1">
        <v-col cols="4">
          <v-row align="baseline">
            <v-col cols="5" class="text-right">
              <label class="body-2 font-weight-bold black--text">Nadi</label>
            </v-col>
            <v-col class="py-0">
              <v-text-field
                placeholder="Masukkan nilai"
                suffix="x/menit"
                dense
                v-model.number="triaseLevel.pulse"
                type="number"
                max="3"
                required
                oninput="if (this.value.length > this.max) this.value = this.value.slice(0, this.max);"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row align="baseline">
            <v-col cols="5" class="text-right">
              <label class="body-2 font-weight-bold black--text"
                >Saturasi O<sub>2</sub></label
              >
            </v-col>
            <v-col class="py-0">
              <v-text-field
                placeholder="Masukkan nilai"
                suffix="%"
                dense
                v-model.number="triaseLevel.saturation"
                type="number"
                max="3"
                required
                oninput="if (this.value.length > this.max) this.value = this.value.slice(0, this.max);"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="py-1">
        <v-col cols="4">
          <v-row align="baseline">
            <v-col cols="5" class="text-right">
              <label class="body-2 font-weight-bold black--text"
                >Pernapasan</label
              >
            </v-col>
            <v-col class="py-0">
              <v-text-field
                placeholder="Masukkan nilai"
                suffix="x/menit"
                dense
                type="number"
                v-model.number="triaseLevel.respiration"
                max="2"
                required
                oninput="if (this.value.length > this.max) this.value = this.value.slice(0, this.max);"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row align="baseline">
            <v-col cols="5" class="text-right">
              <label class="body-2 font-weight-bold black--text">Nyeri</label>
            </v-col>
            <v-col class="py-0">
              <v-text-field
                placehold5r="Masukkan nilai"
                dense
                type="number"
                v-model.number="triaseLevel.pain"
                max="2"
                :rules="[v => v <= 10 || 'Maksimal Nilai 10']"
                required
                oninput="if (this.value.length > this.max) this.value = this.value.slice(0, this.max);"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="py-1">
        <v-col cols="4">
          <v-row align="center">
            <v-col cols="5" class="text-right">
              <label class="body-2 font-weight-bold black--text"
                >Berat Badan</label
              >
            </v-col>
            <v-col class="py-0">
              <v-text-field
                placeholder="Masukkan nilai"
                suffix="kg"
                dense
                hide-details="false"
                type="number"
                v-model.number="triaseLevel.weight"
                max="3"
                oninput="if (this.value.length > this.max) this.value = this.value.slice(0, this.max);"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row align="center">
            <v-col cols="5" class="text-right">
              <label class="body-2 font-weight-bold black--text"
                >Tinggi Badan</label
              >
            </v-col>
            <v-col class="py-0">
              <v-text-field
                placeholder="Masukkan nilai"
                suffix="cm"
                dense
                hide-details="false"
                type="number"
                v-model.number="triaseLevel.height"
                max="3"
                oninput="if (this.value.length > this.max) this.value = this.value.slice(0, this.max);"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <v-card-actions class="px-0">
      <v-btn text class="text-none" @click="$emit('close-form')">
        Kembali
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="primary"
        tile
        elevation="0"
        width="153"
        class="text-none"
        @click="printTriase()"
        v-if="this.isFilled || this.triaseNumber"
        >Cetak Dokumen</v-btn
      >
      <v-btn
        color="primary"
        tile
        elevation="0"
        width="153"
        class="text-none"
        :disabled="!valid || isFilled"
        @click="addTriase()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import AlertMixin from '@/mixin/alertMixin';
import moment from 'moment-timezone';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import Constant from '@/const';
import Swal from 'sweetalert2';
const axios = require('axios');

export default {
  name: 'LevelTriase',
  mixins: [AlertMixin],
  props: {
    triaseLevel: {
      type: Object,
    },
    wpss: {
      type: Object,
    },
    triase: {
      type: Object,
    },
    triaseNumber: {
      type: Number,
      default: null,
    },
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      gender: [
        { text: 'Laki-laki', value: true },
        { text: 'Perempuan', value: false },
      ],
      rules: {
        name: [v => !!v || 'Perlu mengisi nama'],
        gender: [v => v !== '' || 'Perlu mengisi jenis kelamin'],
        address: [v => !!v || 'Perlu mengisi alamat'],
      },
      valid: true,
      timestamps: new Date(),
      isFilled: false,
      triaseNumberTemp: null,
    };
  },
  watch: {
    input(val) {
      this.triaseLevel = val;
    },
    triaseNumber(val) {
      this.triaseNumberTemp = val;
    },
  },
  methods: {
    onClick(val) {
      this.triaseLevel.pacs = val;
    },
    awareClick(val) {
      this.triaseLevel.awareness = val;
    },
    addTriase() {
      if (this.$refs.form.validate()) {
        const payload = {
          triase_number: this.triaseNumberTemp,
          detail_triase: [
            {
              filled_at: moment(new Date())
                .locale('id')
                .format(),
              triase_level: {
                name: this.triaseLevel.name,
                gender: this.triaseLevel.gender,
                person_in_charge: this.triaseLevel.pic,
                address: this.triaseLevel.address,
                parent_name: this.triaseLevel.parentsName,
                pacs: this.triaseLevel.pacs,
                awareness: this.triaseLevel.awareness,
                blood_pressure_sistole: this.triaseLevel.sistole,
                blood_pressure_diastole: this.triaseLevel.diastole,
                temperature: this.triaseLevel.temperature,
                pulse: this.triaseLevel.pulse,
                saturation: this.triaseLevel.saturation,
                respiratory: this.triaseLevel.respiration,
                pain: this.triaseLevel.pain,
                weight_body: this.triaseLevel.weight,
                height_body: this.triaseLevel.height,
              },
              wpss: this.wpss,
              triase: {
                ...this.triase,
                trauma_mechanism: {
                  ...this.triase?.trauma_mechanism,
                  icd_10: this.triase?.trauma_mechanism?.icd_10?._id,
                },
              },
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
            },
          ],
        };
        axios
          .post(Constant.apiUrl.concat('/patient/igd/triase'), payload)
          .then(() => {
            if (this.patientData !== null) {
              Swal.fire('Triase Berhasil diperbaharui', '', 'success');
              this.$emit('close-form');
            } else {
              Swal.fire('Triase Berhasil didaftarkan', '', 'success');
              this.$emit('close-form');
            }
          })
          .catch(err => {
            this.showErrorAxios(err);
          })
          .finally(() => {
            this.isFilled = true;
          });
      }
    },
    printTriase() {
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: [2.24, 1.5],
      });

      const marginX = 0.2;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };

      const alignLeft = (text, addingLine, y, x = 0) => {
        doc.text(text, x, y || addLine(addingLine), {
          align: 'left',
          charSpace: 0,
        });
      };

      doc.setProperties({
        title: `Surat Triase`,
      });
      doc.setFontSize(5.5);
      doc.setFont('Helvetica', 'bold');
      alignCenter('NEUROVI CLINIC MEDICAL SYSTEM', undefined, 0.2, 0);
      doc.setFont('Helvetica', 'normal');
      doc.setLineWidth(0.02);
      doc.line(0.1, 0.3, 2.1, 0.3);
      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');

      doc.autoTable({
        body: [
          ['Nomer Reg.', ':', `${this.triaseNumber}`],
          ['Nama Pasien', ':', `${this.triaseLevel.name}`],
          [
            'Jenis Kelamin',
            ':',
            `${this.triaseLevel.gender ? 'Laki-laki' : 'Perempuan'}`,
          ],
          ['Alamat', ':', `${this.triaseLevel.address}`],
          ['Nama Orang Tua', ':', `${this.triaseLevel.parentsName || '-'}`],
          ['NPJP', ':', `${this.triaseLevel.pic || '-'}`],
          [],
        ],
        startY: 0.4,
        style: {
          halign: 'left',
          textColor: 0,
        },
        columnStyles: {
          0: { cellWidth: 0.6 },
          1: { cellWidth: 0.1 },
          2: { cellWidth: 1.2 },
        },
        styles: {
          cellPadding: 0,
          fontSize: 5.5,
        },
        // didDrawCell: e => {
        //   e.doc.internal.write(0, 'Tw');
        // },
        margin: {
          left: 0.2,
          right: marginX,
          bottom: 0,
        },
        theme: 'plain',
      });

      const lastLine = doc.autoTable.previous.finalY;

      doc.setFontSize(5.5);
      doc.setFont('Helvetica', 'normal');
      alignLeft(
        `Dibuat oleh ${this.$store.getters.userLoggedIn.name} pada : ${moment(
          this.timestamps,
        )
          .locale('id')
          .format('DD MMMM YYYY HH:mm')} `,
        undefined,
        lastLine + 0.1,
        0.2,
      );

      doc.viewerPreferences({ FitWindow: true }, true);
      doc.autoPrint();
      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.width = '1px';
      hiddFrame.style.height = '1px';
      hiddFrame.style.opacity = '0.01';
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent,
      );
      if (isSafari) {
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand('print', false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output('bloburl');
      document.body.appendChild(hiddFrame);
    },
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
