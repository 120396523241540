import Constant from '@/const';
import axios from 'axios';

const getGenerateRmNumber = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/patient/emr/generate'),
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getGenerateRmNumber;
