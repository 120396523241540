import Vue from 'vue';
import Vuex from 'vuex';
import {
  MasterBed,
  MasterUnit,
  MasterDrugs,
  MasterStaff,
  MasterGoods,
  MasterServices,
  MasterSupplier,
  MasterDiagnose,
  MasterProcedure,
  MasterLaboratory,
} from './modules/MasterData';
import {
  FallRisk,
  FluidBalance,
  IGDAssessment,
  ObjectiveData,
  PhysicalCheck,
  PainAssessment,
  NursingProblem,
  SubjectiveData,
  EmergencyAction,
  NutritionScreening,
  DoctorAssessmentIGD,
  InfectiousAssessment,
  SupportingInvestigation,
} from './modules/Assessment';
import InpatientRegistrationStore from './modules/patientRegistration/InpatientRegistrationStore';
import OldNewPatientRegistration from './modules/oldNewPatientRegistration/OldNewPatientRegistration';
import PatientQueue from './modules/PatientQueue/PatientQueue';
import ExaminationLaboratory from './modules/examinationLaboratory/ExaminationLaboratory';
import PatientRegistration from './modules/patientRegistration/PatientRegistration';
import InformedConsent from './modules/informedConsent/InformedConsent';
import SurgicalSafetyChecklistStore from './modules/SSC/SurgicalSafetyChecklistStore';
import {
  ServiceAssessmentPlanning,
  ServiceAssessmentPhysicalCheck,
  ServiceAssessmentObjectiveData,
  ServiceAssessmentSubjectiveData,
  ServiceAssessmentSupportingInvestigation,
} from './modules/Assessment/ServiceAssessment';
import StorageRequestStore from './modules/Inventory/StorageRequest/StorageRequestStore';
import DrugRequestStore from './modules/Pharmacy/Request/DrugRequestStore';
import HeaderPharmacyStore from './modules/Pharmacy/HeaderPharmacyStore';
import OperationMarking from './modules/OperationMarking/OperationMarking';
import MedicalResumeStore from './modules/Inpatient/MedicalResume/MedicalResumeStore';
import CPPTStore from './modules/Inpatient/CPPT/CPPTStore';
import EarlyAssessmentInpatientStore from './modules/Inpatient/Assessment/EarlyAssessmentInpatientStore';
import NeonatusAssessment from './modules/Inpatient/Assessment/NeonatusAssessment';
import ObstetricsAssessment from './modules/Inpatient/Assessment/ObstetricsAssessment';
import LabourAssessment from './modules/Inpatient/Assessment/LabourAssessment';
import PatientVisitStore from './modules/Reporting/PatientVisit/PatientVisitStore';
import DrugUsageReportStore from './modules/Reporting/DrugUsageReportStore';

const moment = require('moment-timezone');
const jwt = require('jsonwebtoken');

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token'),
    userLoggedIn: null,
    selectedPatient: null,
    mjknPatient: null,
    clinic: {
      name: 'Neurovi Clinic Medical System',
      address: '',
    },
  },
  getters: {
    token: state => state.token,
    userLoggedIn: state => {
      if (state.token !== null) {
        state.userLoggedIn = jwt.decode(state.token).tokenStaff;
        return jwt.decode(state.token).tokenStaff;
      }
      return null;
    },
    isJwtVerified: state => {
      try {
        const decoded = jwt.decode(state.token);
        const now = moment().valueOf() / 1000;
        if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
          throw Error;
        }
        return true;
      } catch {
        return false;
      }
    },
    tokenTime: state => {
      try {
        const decoded = jwt.decode(state.token);
        return decoded.exp;
      } catch {
        return false;
      }
    },
    selectedPatient: state => state.selectedPatient,
    mjknPatient: state => state.mjknPatient,
  },
  mutations: {
    saveToken(state, token) {
      localStorage.setItem('token', token);
      state.token = token;
    },
    removeToken(state) {
      state.token = null;
    },
    changeSelectedPatient(state, patient) {
      state.selectedPatient = patient;
    },
    addMjknPatient(state, patient) {
      state.mjknPatient = patient;
    },
    resetMjknPatient(state) {
      state.mjknPatient = null;
    },
  },
  actions: {},
  modules: {
    // Master Data
    MasterDrugs: {
      namespaced: true,
      ...MasterDrugs,
    },
    MasterGoods: {
      namespaced: true,
      ...MasterGoods,
    },
    MasterStaff: {
      namespaced: true,
      ...MasterStaff,
    },
    MasterDiagnose: {
      namespaced: true,
      ...MasterDiagnose,
    },
    MasterProcedure: {
      namespaced: true,
      ...MasterProcedure,
    },
    MasterUnit: {
      namespaced: true,
      ...MasterUnit,
    },
    MasterServices: {
      namespaced: true,
      ...MasterServices,
    },
    MasterSupplier: {
      namespaced: true,
      ...MasterSupplier,
    },
    MasterBed: {
      namespaced: true,
      ...MasterBed,
    },
    MasterLaboratory: {
      namespaced: true,
      ...MasterLaboratory,
    },
    // End Master Data

    OldNewPatientRegistration: {
      namespaced: true,
      ...OldNewPatientRegistration,
    },
    // Subjective data for assessment
    SubjectiveData: {
      namespaced: true,
      ...SubjectiveData,
    },
    // Objective data for assessment
    ObjectiveData: {
      namespaced: true,
      ...ObjectiveData,
    },
    // Pain assessment data for assessment
    PainAssessment: {
      namespaced: true,
      ...PainAssessment,
    },
    // Nutrition screening data for assessment
    NutritionScreening: {
      namespaced: true,
      ...NutritionScreening,
    },
    // Fall risk data for assessment
    FallRisk: {
      namespaced: true,
      ...FallRisk,
    },
    // emergency action data for assessment
    EmergencyAction: {
      namespaced: true,
      ...EmergencyAction,
    },
    // nursing problem data for assessment
    NursingProblem: {
      namespaced: true,
      ...NursingProblem,
    },
    // fluid balance data for assessment
    FluidBalance: {
      namespaced: true,
      ...FluidBalance,
    },
    // infectious assessment data for assessment
    InfectiousAssessment: {
      namespaced: true,
      ...InfectiousAssessment,
    },
    // doctor igd assessment
    DoctorAssessmentIGD: {
      namespaced: true,
      ...DoctorAssessmentIGD,
    },
    // supporting investigation for igd doctor assessment
    SupportingInvestigation: {
      namespaced: true,
      ...SupportingInvestigation,
    },
    // physical check for igd doctor assessment
    PhysicalCheck: {
      namespaced: true,
      ...PhysicalCheck,
    },
    // physical check for igd doctor assessment
    IGDAssessment: {
      namespaced: true,
      ...IGDAssessment,
    },
    PatientQueue: {
      namespaced: true,
      ...PatientQueue,
    },
    // Laboratory
    ExaminationLaboratory: {
      namespaced: true,
      ...ExaminationLaboratory,
    },
    PatientRegistration: {
      namespaced: true,
      ...PatientRegistration,
    },
    // Informed Consent
    InformedConsent: {
      namespaced: true,
      ...InformedConsent,
    },
    // Surgical Safety Consent
    SurgicalSafetyChecklist: {
      namespaced: true,
      ...SurgicalSafetyChecklistStore,
    },
    // Objective data for services assessment
    ServiceAssessmentObjectiveData: {
      namespaced: true,
      ...ServiceAssessmentObjectiveData,
    },
    // assesment general
    ServiceAssessmentPlanning: {
      namespaced: true,
      ...ServiceAssessmentPlanning,
    },
    // assesment general
    ServiceAssessmentPhysicalCheck: {
      namespaced: true,
      ...ServiceAssessmentPhysicalCheck,
    },
    // assesment general
    ServiceAssessmentSubjectiveData: {
      namespaced: true,
      ...ServiceAssessmentSubjectiveData,
    },
    // assesment general
    ServiceAssessmentSupportingInvestigation: {
      namespaced: true,
      ...ServiceAssessmentSupportingInvestigation,
    },

    // Pharmacy: drug request
    HeaderPharmacyStore: {
      namespaced: true,
      ...HeaderPharmacyStore,
    },
    // Pharmacy: drug request
    DrugRequestStore: {
      namespaced: true,
      ...DrugRequestStore,
    },
    // Operation Marking
    OperationMarking: {
      namespaced: true,
      ...OperationMarking,
    },
    // Operation Marking
    MedicalResumeStore: {
      namespaced: true,
      ...MedicalResumeStore,
    },
    // CPPT
    CPPTStore: {
      namespaced: true,
      ...CPPTStore,
    },
    // Inpatient Dashboard
    InpatientRegistrationStore: {
      namespaced: true,
      ...InpatientRegistrationStore,
    },
    // Early Assessment
    EarlyAssessmentInpatientStore: {
      namespaced: true,
      ...EarlyAssessmentInpatientStore,
    },
    // Obstetrics Assessment
    ObstetricsAssessment: {
      namespaced: true,
      ...ObstetricsAssessment,
    },
    // Labour Assessment
    LabourAssessment: {
      namespaced: true,
      ...LabourAssessment,
    },
    // Neonatus Assessment
    NeonatusAssessment: {
      namespaced: true,
      ...NeonatusAssessment,
    },
    // Storage Request
    StorageRequestStore: {
      namespaced: true,
      ...StorageRequestStore,
    },
    // Patient Visit Reporting
    PatientVisitStore: {
      namespaced: true,
      ...PatientVisitStore,
    },
    // DrugUsageReport
    DrugUsageReportStore: {
      namespaced: true,
      ...DrugUsageReportStore,
    },
  },
});
