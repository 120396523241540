import Constant from '../const';
import moment from 'moment-timezone';

export default {
  methods: {
    pdfTextPrint(doc, text, y, mode = 'center', offset = 0, options) {
      const textWidth =
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      let textOffset = 0;
      if (mode === 'center') {
        textOffset = (doc.internal.pageSize.width - textWidth) / 2 + offset;
      }
      if (mode === 'halfCenterStart') {
        textOffset = (doc.internal.pageSize.width - textWidth) / 4 + offset;
      }
      if (mode === 'halfCenterEnd') {
        textOffset = (doc.internal.pageSize.width - textWidth - offset) * 0.75;
      } else if (mode === 'right') {
        textOffset = doc.internal.pageSize.width - textWidth - offset;
      } else if (mode === 'left') {
        textOffset += offset;
      }
      doc.text(text, textOffset, y, options);
    },
    printDocument(doc, data) {
      if (data) {
        doc.setProperties({
          title: `${data?.docName}_${data?.rmNumber}_${moment().format(
            'DD-MM-YYYY',
          )}`,
        });
      }
      doc.autoPrint({ variant: 'non-conform' });
      const hiddFrame = document.createElement('iframe');
      hiddFrame.style.position = 'fixed';
      hiddFrame.style.width = '1px';
      hiddFrame.style.height = '1px';
      hiddFrame.style.opacity = '0.01';
      var userAgent = navigator.userAgent;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Android/i)
      ) {
        doc.save(
          `${data?.docName}_${data?.rmNumber}_${moment().format(
            'DD-MM-YYYY',
          )}.pdf`,
        );
      }
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent,
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand('print', false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output('bloburl');
      document.body.appendChild(hiddFrame);
    },
    letterHead(doc, clinic, unit = 'in') {
      let size;
      switch (unit) {
        case 'in':
          size = 1;
          break;
        case 'cm':
          size = 2.54;
          break;
        default:
          size = 1;
          break;
      }
      const letterData = Constant.client.find(
        item => item.name.toLowerCase() === clinic.name.toLowerCase(),
      );
      let width = doc.internal.pageSize.getWidth();
      doc.addImage(
        letterData.letterHead.src,
        'PNG',
        (8.27 - letterData.letterHead.width) / 2,
        letterData.letterHead.height === 1.5 ? 0.1 : 0,
        letterData.letterHead.width * size,
        letterData.letterHead.height * size,
      );
      if (
        letterData.letterHead.src === '/img/kopsurat_jmc_header.3ff7eec3.png'
      ) {
        doc.addImage(
          letterData.letterFoot.src,
          'PNG',
          (8.27 * size - width) / 2,
          5.7 * (size !== 1 ? size + 0.3 : size),
          width + (size !== 1 ? size : 0),
          letterData.letterFoot.height * size,
          '',
          'FAST',
        );
      }
    },
    generateQRCode(
      { letterName, doctor, sip, time = new Date(), doc, x, y },
      QRCode,
      moment,
    ) {
      QRCode.toDataURL(
        [
          `Sesuai dengan ketentuan yang berlaku, ${this.$store.getters.userLoggedIn.clinic.name} mengatur bahwa ${letterName} ini telah ditandatangani secara elektronik oleh:`,
          doctor,
          sip !== null ? `SIP: ${sip}` : null,
          '',
          `Pada tanggal: ${moment(time)
            .locale('id')
            .format('DD MMMM YYYY HH:mm [WIB]')}`,
        ]
          .filter(item => item !== null)
          .join('\n'),
        {
          type: 'image/jpeg',
          width: 170,
          height: 170,
          color: {
            dark: '#000000',
            light: '#ffffff',
          },
        },
        (err, url) => {
          if (err) {
            return err;
          }
          doc.addImage(url, 'JPEG', x, y);
        },
      );
    },
    letterFooter(doc, moment, staff) {
      doc.setFontSize(9);
      doc.setFont('Helvetica', 'normal');
      doc.text(`Petugas: ${staff}`, 0.7, 10.77, {
        align: 'left',
        charSpace: 0,
      });
      doc.text(
        `Dicetak pada: ${moment()
          .locale('id')
          .format('dddd, D MMMM YYYY [pukul] HH.mm [WIB]')}`,
        0.7,
        11.07,
        { align: 'left', charSpace: 0 },
      );
    },
  },
};
