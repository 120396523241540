import axiosClient from '../../fetchApi/apiClient';
// import moment from 'moment';

const getDrugUsageReport = async ({ startDate, endDate, type } = {}) => {
  return await axiosClient.get(
    `/report/drug-usage?startDate=${startDate}&endDate=${endDate}&type=${type}`,
  );
};

export default getDrugUsageReport;
