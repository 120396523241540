<template>
  <v-card>
    <v-card-title class="d-flex justify-center">
      Detail Obat
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form">
        <v-row no-gutters>
          <v-col cols="12" class="mt-4 px-8">
            <div class="detail-input">
              <div class="detail-input__label">
                <label class="required">Nama Obat</label>
              </div>
              <v-combobox
                :readonly="!isEditable"
                :items="resource.medicalTools"
                dense
                :loading="isLoading"
                single-line
                append-icon="mdi-chevron-down"
                id="medtool-name"
                return-object
                item-text="displayName"
                item-value="_id"
                v-model="input.item"
                :rules="isEditable ? rules.stock : []"
                class="detail-input__input-text"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-layout>
                        <v-flex class="d-flex justify-start">
                          <span class="text-left">
                            {{ `${data.item.displayName}` }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-layout>
                        <span class="mr-4">
                          Exp:
                          {{ data.item.expiry }}
                        </span>
                        <span class="mr-4">
                          No Batch: {{ data.item.batch }}
                        </span>
                        <span class="mr-4">{{
                          `Sisa Stok : ${data.item.stock} ${data.item.packaging}`
                        }}</span>
                        <span>{{ `Rp. ${data.item.hpp || 0}` }}</span>
                      </v-layout>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-combobox>
            </div>
            <div class="detail-input">
              <div class="detail-input__label">
                <label>Satuan</label>
              </div>
              <div class="detail-input__text-label">
                <label>{{ input.item.packaging }}</label>
              </div>
            </div>
            <div class="detail-input">
              <div class="detail-input__label">
                <label>Jumlah Diminta</label>
              </div>
              <v-text-field
                dense
                v-model="input.amountDemanded"
                readonly="true"
              />
            </div>
            <div class="detail-input">
              <div class="detail-input__label">
                <label class="required">Jumlah Diberikan</label>
              </div>
              <v-text-field
                type="number"
                :rules="rules.required"
                dense
                v-model="input.amountGiven"
                :readonly="!isEditable"
              />
            </div>
          </v-col>
        </v-row>
      </v-form>
      <v-row no-gutters class="d-flex justify-end mb-10">
        <div class="price-text">
          <label class="price-text__label">Harga Obat</label>
          <label class="price-text__price">{{ currency(price) }}</label>
        </div>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters class="d-flex justify-space-between py-3">
        <v-btn
          class="text-none action-button action-button__back"
          text
          @click="close()"
        >
          Kembali
        </v-btn>
        <div v-if="isEditable === true">
          <v-btn
            class="text-none"
            color="error"
            @click="isEditable = false"
            outlined
          >
            Batal
          </v-btn>
          <v-btn
            class="text-none action-button"
            color="primary"
            @click="saveDrug()"
          >
            Simpan Perubahan
          </v-btn>
        </div>
        <v-btn
          v-else
          class="text-none action-button"
          color="primary"
          @click="isEditable = true"
        >
          Ubah
        </v-btn>
      </v-row>
    </v-card-text>
    <v-snackbar
      absolute
      timeout="4100"
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      :color="snackbar.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </v-card>
</template>

<script>
import { updatePharmacyDrug } from '@/fetchApi/Pharmacy';
import Constant from '@/const';
const axios = require('axios');
import formatMixin from '@/mixin/formatMixin';

export default {
  mixins: [formatMixin],
  props: {
    item: {
      type: Object,
      default: null,
    },
    id_recipe: {
      type: String,
      default: null,
    },
    id_pharmacy: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      rules: {
        required: [v => !!v || 'Wajib diisi'],
        stock: [
          v =>
            (v?.stock >= this.input.amountGiven && v?.stock !== 0) ||
            'Alkes tidak cukup',
        ],
      },
      snackbar: {
        show: false,
        color: '',
        text: '',
      },
      isEditable: false,
      isLoading: false,
      resource: {
        medicalTools: [],
      },
      input: {
        item: '',
        amountGiven: '',
        amountDemanded: '',
      },
    };
  },
  mounted() {
    this.getMedicalTools('');
    this.mapToInput();
  },
  computed: {
    price: {
      get() {
        return this.input.item.hpp * this.input.amountDemanded;
      },
    },
  },
  methods: {
    close() {
      this.$emit('close-form');
    },
    mapToInput() {
      this.input = {
        item: {
          hpp: this.item.pricePerItem,
          id_drugs: this.item.id,
          id_stock: this.item.id_stock,
          name: this.item.id_drugs.name,
          displayName: this.item.name,
          packaging: this.item.packaging,
          stock: this.item.availability_stock,
        },
        amountGiven: +this.item.amountGiven,
        amountDemanded: +this.item.amountDemanded,
      };
    },
    async showSnackbar(error = false) {
      this.snackbar = {
        text: !error
          ? 'Data alkes berhasil diubah'
          : 'Data alkes tidak berhasil diubah',
        show: true,
        color: !error ? 'success' : 'error',
      };
      setTimeout(() => {
        this.snackbar.show = false;
      }, 4000);
      return;
    },
    async getMedicalTools(keyword = '') {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all/names?page=1&itemCount=30&category=Alat Kesehatan&name=${keyword}`,
          ),
        )
        .then(response => {
          const tools = response.data.data;
          this.resource.medicalTools = tools.map(arr => {
            return {
              batch: arr.batch_number,
              id_drugs: arr.id_drugs,
              id_stock: arr._id,
              name: arr.drugs.detail.name,
              displayName: `${arr.drugs.detail.name} (${arr.drugs.detail.factory})`,
              packaging: arr.package,
              hpp: arr.price,
              expiry: arr.expiry_date,
              stock: arr.quantity,
            };
          });
        })
        .catch(() => {
          this.resource.medicalTools = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async saveDrug() {
      try {
        if (!this.$refs.form.validate()) return;

        const { item } = this.input;
        const payload = {
          id_recipe: this.id_recipe,
          id_stock: item.id_stock,
          id_drugs: item.id_drugs,
          type: 'medtool',
          recipe_number: this.item.recipe_number,
          name: item.name,
          packaging: item.packaging,
          quantity: +this.input.amountGiven,
          requested_quantity: +this.input.amountDemanded,
          availability_stock: item.stock,
          available_stock: true,
          expiry: item.expiry,
        };
        await updatePharmacyDrug(this.id_pharmacy, payload);
        this.isEditable = false;
        this.showSnackbar();
      } catch (error) {
        this.showSnackbar(true);
      }
    },
  },
};
</script>

<style
  scoped
  lang="scss"
  src="../../../sass/pharmacy/editPharmacy.scss"
></style>
