<template>
  <v-card elevation="0">
    <v-card-title
      class="justify-space-between font-weight-medium primary--text py-0 pl-0 mb-2"
    >
      <span class="heading-5"
        >{{ isEdit ? 'Edit Obat' : 'Tambah Obat Baru' }}
      </span>
      <div>
        <v-btn
          outlined
          @click="resetAll()"
          class="red--text caption"
          max-height="30"
          v-if="!isEdit"
        >
          <font-awesome-icon icon="sync-alt" class="mt-0 mr-2" />
          <span class="font-weight-medium text-capitalize">Reset Form</span>
        </v-btn>
        <v-btn
          color="primary"
          class="ml-3 caption"
          max-height="30"
          :disabled="!isEdit"
          @click="changeIsEditState()"
          depressed
        >
          <v-icon left small>mdi-plus</v-icon>
          <span class="font-weight-medium text-capitalize">Form Obat Baru</span>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text class="main-body">
      <v-row class="px-3 mt-2">
        <v-col>
          <div v-if="!isEdit" class="grid">
            <label>Jenis Obat</label>
            <v-radio-group
              class="pa-0 ma-0"
              dense
              light
              hide-details
              v-model="formType"
              row
            >
              <v-radio light :value="true" label="Racik"></v-radio>
              <v-radio light :value="false" label="Non Racik"></v-radio>
            </v-radio-group>
          </div>
          <mix-form v-if="formType" ref="mix" />
          <non-mix-form ref="nonmix" v-if="!formType" />
        </v-col>
        <v-col>
          <drug-schedule :refs="refs" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// import components
import MixForm from './DrugForm/MixForm.vue';
import NonMixForm from './DrugForm/NonMixForm.vue';
import DrugSchedule from './DrugForm/DrugSchedule.vue';

// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'DrugRequestStore',
);

export default {
  components: { NonMixForm, MixForm, DrugSchedule },
  name: 'PharmacyDrugForm',
  computed: {
    ...mapGetters(['getForm']),
    snackbar: {
      get() {
        return this.getForm.snackbar;
      },
      set(value) {
        this.setForm({ label: 'snackbar', value });
      },
    },
    form: {
      get() {
        return this.getForm;
      },
    },
    formType: {
      get() {
        return this.getForm.type;
      },
      set(value) {
        this.setForm({ label: 'type', value });
      },
    },
    isEdit: {
      get() {
        return this.getForm.isEdit;
      },
      set(value) {
        this.setForm({ label: 'isEdit', value });
      },
    },
    refs: {
      get() {
        return {
          ref: this.$refs,
        };
      },
    },
  },
  beforeDestroy() {
    this.clearState('form');
  },
  methods: {
    ...mapMutations(['setForm', 'clearState']),
    resetAll() {
      this.clearState('form');
      this?.$refs?.nonmix?.$refs?.form?.resetValidation();
      this?.$refs?.mix?.$refs?.form?.resetValidation();
    },
    changeIsEditState() {
      this.isEdit = false;
      this.resetAll();
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 0.8vw;
  align-items: center;
  text-align: start;
  width: 95%;
  color: #101010;
  margin-bottom: 1vw;

  label {
    font-size: 0.72vw;
  }
}

.main-body {
  background-color: #deeef9;
}

.snackbar {
  top: -68vh;
  right: 18vw;
}
</style>
