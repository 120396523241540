import Constant from '@/const';
import axios from 'axios';

const exportSatuSehatReport = async params => {
  try {
    const { startDate = '', endDate = '', month = '', date = '' } = params;
    const response = await axios.get(
      Constant.apiUrl.concat(
        `/ihs/export/patient-not-found?month=${month}&date=${date}&startDate=${startDate}&endDate=${endDate}`,
      ),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default exportSatuSehatReport;
