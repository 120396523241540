<template>
  <v-card class="identification-card">
    <v-card-title class="identification-card__title">
      <h4>Identifikasi Pasien</h4>
    </v-card-title>
    <v-card-text v-if="isRegist" class="identification-card__body">
      <v-row class="mb-1">
        <v-col cols="2">
          <span>Staff</span>
        </v-col>
        <v-col cols="1">
          <span>:</span>
        </v-col>
        <v-col style="margin-left: -2rem; color: #404040">
          <span>{{ patientData.createdBy }}</span>
        </v-col>
      </v-row>

      <table-component :patientData="patientData" />
      <v-divider />
    </v-card-text>
    <v-card-text v-else class="identification-card__body">
      <v-tabs grow v-model="tabs">
        <v-tab class="text-none" style="font-size: 0.75rem !important; "
          >Assesmen Perawat</v-tab
        >
        <v-tab class="text-none" style="font-size: 0.75rem !important; "
          >Assesmen Dokter</v-tab
        >
        <v-tab class="text-none" style="font-size: 0.75rem !important; "
          >Tindakan</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-row class="my-1 ">
            <v-col cols="2">
              <span>Staff</span>
            </v-col>
            <v-col cols="1">
              <span>:</span>
            </v-col>
            <v-col style="margin-left: -2rem; color: #404040">
              <span>{{
                patientData.identification_patient?.assesment_nurse_by || '-'
              }}</span>
            </v-col>
          </v-row>

          <table-component
            :patientData="patientData"
            :isEmpty="
              patientData.identification_patient?.assesment_nurse_by ===
                undefined
            "
          />
          <v-divider />
        </v-tab-item>
        <v-tab-item>
          <v-row class="my-1 ">
            <v-col cols="2">
              <span>Staff</span>
            </v-col>
            <v-col cols="1">
              <span>:</span>
            </v-col>
            <v-col style="margin-left: -2rem; color: #404040">
              <span>{{
                patientData.identification_patient?.assesment_doctor_by || '-'
              }}</span>
            </v-col>
          </v-row>

          <table-component
            :patientData="patientData"
            :isEmpty="
              patientData.identification_patient?.assesment_doctor_by ===
                undefined
            "
          />
          <v-divider />
        </v-tab-item>
        <v-tab-item
          style="max-height: 46.5vh;overflow: auto;overflow-x: hidden; padding: 0 1rem 0 0;"
          class="scrollbar"
        >
          <div
            v-if="patientData.identification_patient?.intervention.length === 0"
          >
            <v-row>
              <v-col cols="3">
                <span>Operator</span>
              </v-col>
              <v-col cols="1">
                <span>:</span>
              </v-col>
              <v-col style="margin-left: -2rem; color: #404040">
                <span>-</span>
              </v-col>
            </v-row>

            <v-row style="margin-top: -1rem; margin-bottom: 0.25rem;">
              <v-col cols="3">
                <span>Jenis Tindakan</span>
              </v-col>
              <v-col cols="1">
                <span>:</span>
              </v-col>
              <v-col style="margin-left: -2rem; color: #404040">
                <span>-</span>
              </v-col>
            </v-row>

            <table-component
              :patientData="patientData"
              :isEmpty="
                patientData.identification_patient?.intervention.length === 0
              "
            />
          </div>
          <div
            v-else
            v-for="(item, index) in patientData.identification_patient
              ?.intervention"
            :key="index"
          >
            <v-row class="mt-1">
              <v-col cols="3">
                <span>Operator</span>
              </v-col>
              <v-col cols="1">
                <span>:</span>
              </v-col>
              <v-col style="margin-left: -2rem; color: #404040">
                <span>{{ item.staff }}</span>
              </v-col>
            </v-row>

            <v-row style="margin-top: -1rem; margin-bottom: 0.1rem;">
              <v-col cols="3">
                <span>Jenis Tindakan</span>
              </v-col>
              <v-col cols="1">
                <span>:</span>
              </v-col>
              <v-col style="margin-left: -2rem; color: #404040">
                <span>{{ item.service_name }}</span>
              </v-col>
            </v-row>

            <table-component
              :patientData="patientData"
              :isEmpty="
                patientData.identification_patient?.intervention.length === 0
              "
            />
            <v-divider />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        class="text-none"
        dense
        depressed
        @click="handleClose"
      >
        Kembali
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import TableComponent from './TableComponent.vue';
export default {
  name: 'IdentificationPatient',
  props: {
    patientData: Object,
    isRegist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabs: '',
    };
  },
  components: {
    TableComponent,
  },
  methods: {
    handleClose() {
      this.$emit('close-form');
    },
  },
};
</script>

<style scoped lang="scss">
.identification-card {
  text-align: left;
  padding: 0.5rem;
  &__title {
    padding: 0.5rem 1rem !important;
    margin-top: -0.5rem;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: #404040;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__body {
    padding: 0.5rem 1rem !important;
    font-size: 0.75rem;
  }
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}
</style>
