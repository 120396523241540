import Constant from '@/const';
import axios from 'axios';

const getPregnancyHistory = async idInpatient => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/inpatient/pregnancyhistory/${idInpatient}`),
    );
    return response;
  } catch (error) {
    return error;
  }
};

export default getPregnancyHistory;
