<template>
  <div class="inventory">
    <!-- <v-toolbar flat outlined height="100"> -->
    <v-container fluid>
      <v-row align="center">
        <v-col>
          <v-toolbar-title class="font-weight-bold float-left">
            Stok Opname
          </v-toolbar-title>
        </v-col>

        <v-spacer></v-spacer>

        <v-btn depressed color="#F4F5F5" @click="openFilter()">
          <img src="@/assets/image/ic_filter.png" class="mr-2" />
          <span class="black--text font-weight-bold text-capitalize"
            >Filters</span
          >
        </v-btn>
        <v-btn class="mr-2" color="grey" icon text @click="getStockOpname()">
          <v-icon>mdi-autorenew</v-icon>
        </v-btn>
        <v-text-field
          class="input"
          background-color="grey lighten-3"
          placeholder="Search"
          v-model="table.search"
          solo
          dense
          hide-details
          flat
          prepend-inner-icon="mdi-magnify"
          single-line
        ></v-text-field>
      </v-row>
      <v-row v-if="isOpenFilter">
        <v-col cols="2">
          <v-menu
            dense
            ref="menu1"
            v-model="menu.firstDate"
            :close-on-content-click="false"
            :return-value.sync="filter.firstDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                placeholder="Tanggal Awal"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                v-model="filter.firstDate"
                clearable
                hide-details="false"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.firstDate"
              no-title
              scrollable
              @input="
                $refs.menu1.save(filter.firstDate);
                refreshAndAutoUpdate();
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-menu
            dense
            ref="menu2"
            v-model="menu.lastDate"
            :close-on-content-click="false"
            :return-value.sync="filter.lastDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                placeholder="Tanggal Akhir"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                v-model="filter.lastDate"
                clearable
                hide-details="false"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.lastDate"
              no-title
              scrollable
              @input="
                $refs.menu2.save(filter.lastDate);
                refreshAndAutoUpdate();
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-menu
            dense
            ref="menu3"
            v-model="menu.firstDateConfirm"
            :close-on-content-click="false"
            :return-value.sync="filter.firstDateConfirm"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                placeholder="Konfirmasi Awal"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                v-model="filter.firstDateConfirm"
                clearable
                hide-details="false"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.firstDateConfirm"
              no-title
              scrollable
              @input="
                $refs.menu3.save(filter.firstDateConfirm);
                refreshAndAutoUpdate();
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-menu
            dense
            ref="menu4"
            v-model="menu.lastDateConfirm"
            :close-on-content-click="false"
            :return-value.sync="filter.lastDateConfirm"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                placeholder="Konfirmasi Akhir"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                v-model="filter.lastDateConfirm"
                clearable
                hide-details="false"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.lastDateConfirm"
              no-title
              scrollable
              @input="
                $refs.menu4.save(filter.lastDateConfirm);
                refreshAndAutoUpdate();
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pa-0 mx-0" style="min-width: 100%;">
      <v-data-table
        class="opname-table"
        :headers="table.headers"
        :items="table.dataItem"
        :items-per-page="itemsPerRow"
        hide-default-footer
        :loading="isLoading"
      >
        <template v-slot:item="{ item, index }">
          <tr class="row-datatable" :key="index">
            <td class="text-center">
              {{ (table.pagination.page - 1) * itemsPerRow + index + 1 }}
            </td>
            <td class="text-sm-left">
              {{ item.opnameDate }}
            </td>
            <td class="text-sm-left">
              {{ item.approvalDate }}
            </td>
            <td class="text-sm-left">
              {{ item.user }}
            </td>
            <td class="text-sm-center text-capitalize">
              <v-chip
                :class="{
                  requested: item.status === 'requested',
                  rejected: item.status === 'rejected',
                  resolved: item.status === 'resolved',
                }"
                class="status-chip text-capitalize"
                >{{ item.status }}</v-chip
              >
            </td>
            <td class="text-sm-center font-weight-bold text-capitalize">
              {{ item.approval ? 'sudah' : 'belum' }}
            </td>
            <td class="text-sm-left">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="onClickFooter(1, item)"
                  >
                    mdi-dots-vertical
                  </v-icon>
                </template>
                <span class="mr-2"
                  ><v-icon color="white" small>mdi-pencil</v-icon></span
                ><span class="font-weight-medium">Detail Opname</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-container>
    <v-footer color="white">
      <v-container>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="itemPages"
            total-visible="5"
            @input="getStockOpname()"
          ></v-pagination>
          <v-spacer></v-spacer>

          <template>
            <v-dialog v-model="dialog" min-height="1200px" min-width="1200px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  fab
                  dark
                  @click="onClickFooter(0, '')"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <InputStockOpname
                v-if="selectedForm == 0"
                @change-form="changeForm"
                @close-form="closeForm"
              />
              <DetailStockOpname
                v-if="selectedForm == 1"
                @change-form="changeForm"
                @close-form="closeForm"
              />
            </v-dialog>
          </template>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import InputStockOpname from './StockOpnameComponent/InputStockOpname';
import DetailStockOpname from './StockOpnameComponent/DetailStockOpname';
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
const moment = require('moment-timezone');
const _ = require('lodash');
export default {
  name: 'StockOpname',
  mixins: [jwtMixin],
  components: {
    InputStockOpname,
    DetailStockOpname,
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      menu: {
        firstDate: '',
        lastDate: '',
        firstDateConfirm: '',
        lastDateConfirm: '',
      },
      isOpenFilter: false,
      filter: {
        firstDate: '',
        lastDate: '',
        firstDateConfirm: '',
        lastDateConfirm: '',
      },
      editedItem: {
        noQueue: 0,
        billDate: '',
        sp: '',
        supplierName: '',
        user: '',
      },
      itemPages: 0,
      show: false,
      table: {
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 5,
          totalItems: null,
        },
        search: '',
        headers: [
          { text: 'No.', value: 'noQueue', class: 'text-sm-center pl-3' },
          { text: 'Tanggal Opname', value: 'opnameDate', sortable: false },
          { text: 'Tanggal Approval', value: 'approvalDate', sortable: false },
          { text: 'User', value: 'user', sortable: false },
          { text: 'Status', value: 'status', align: 'center', sortable: false },
          {
            text: 'Approval',
            value: 'approval',
            align: 'center',
            sortable: false,
          },
          { text: '', value: 'detail', sortable: false },
        ],
        dataItem: [],
      },
      selectedForm: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.selectedForm = null;
      }
    },
    'table.search'() {
      this.table.pagination.page = 1;
      this.searchData(this);
    },
    'filter.firstDate'(val) {
      if (val === null) {
        this.filter.firstDate = '';
        this.getStockOpname();
      }
    },
    'filter.endDate'(val) {
      if (val === null) {
        this.filter.endDate = '';
        this.getStockOpname();
      }
    },
    'filter.firstDateConfirm'(val) {
      if (val === null) {
        this.filter.firstDateConfirm = '';
        this.getStockOpname();
      }
    },
    'filter.lastDateConfirm'(val) {
      if (val === null) {
        this.filter.lastDateConfirm = '';
        this.getStockOpname();
      }
    },
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      v.getStockOpname();
    }, 500),
    onClickFooter(index, opname) {
      this.changeSelectedItem(opname);

      switch (index) {
        default:
          this.changeForm(index);
          break;
      }
      this.dialog = true;
    },
    openFilter() {
      this.isOpenFilter = !this.isOpenFilter;
    },
    changeSelectedItem(opname) {
      localStorage.setItem('opname_id', opname.id);
    },
    changeForm(item) {
      this.selectedForm = item;
    },

    closeForm() {
      this.dialog = false;
      this.getStockOpname();
    },

    getStockOpname() {
      this.isLoading = true;

      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/transaction/opname?search=${this.table.search}&page=${
                this.table.pagination.page
              }&itemCount=${this.itemsPerRow}&startDate=${this.filter
                .firstDate || ''}&endDate=${this.filter.lastDate ||
                ''}&startConfirm=${this.filter.firstDateConfirm ||
                ''}&endConfirm=${this.filter.lastDateConfirm || ''}`,
            ),
          )
          .then(response => {
            const { data, length: dataLength } = response.data;
            this.itemPages = Math.ceil(dataLength / this.itemsPerRow);
            this.table.dataItem = data.map(item => {
              return {
                id: item._id,
                status: item.detail[0].status,
                approval: item.detail[0].status !== 'requested',
                opnameDate: moment(item.timestamps.created_at).format(
                  'DD/MM/YYYY',
                ),
                approvalDate: item.detail[0].timestamps.confirmed_at
                  ? moment(item.detail[0].timestamps.confirmed_at).format(
                      'DD/MM/YYYY',
                    )
                  : '-',
                user: item.timestamps.created_by[0].detail[0].name,
              };
            });
            this.isLoading = false;
          })
          .catch(() => {
            this.table.dataItem = [];
            this.isLoading = false;
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getStockOpname();
      this.interval = setInterval(this.getStockOpname, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 30%;
}

.opname-table {
  max-width: 90vw;
}
.status-chip {
  &.requested {
    color: orange;
    background-color: #fff9e5;
  }
  &.rejected {
    background-color: #fff2f2;
    color: red;
  }
  &.resolved {
    color: green;
    background-color: #e5fff0;
  }
}
</style>
