import Constant from '@/const';
import axios from 'axios';

const getLabourAssessment = async idInpatient => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat(`/birthassessment/${idInpatient}`),
    );
    return response;
  } catch (error) {
    return error;
  }
};

export default getLabourAssessment;
