<template>
  <v-row no-gutters class="d-flex flex-column">
    <div class="position-style">
      <label class="font-weight-bold text-left primary--text">ASET</label>
      <label class="font-weight-bold text-right"></label>
      <label class="font-weight-bold text-left">ASET LANCAR</label>
      <label class="font-weight-bold text-right"></label>

      <template v-for="(cash, idx) in data.cash_and_bank">
        <label class="text-left pl-3" :key="'cash-' + idx">{{
          cash.name
        }}</label>
        <label class="text-right pr-3" :key="'cashBalance-' + idx">{{
          formattedCurrency(cash.balance)
        }}</label>
      </template>

      <template v-for="(accReceivable, idx) in data.account_receivable">
        <label class="text-left pl-3" :key="'accReceivable-' + idx">{{
          accReceivable.name
        }}</label>
        <label class="text-right pr-3" :key="'accReceivableBalance-' + idx">{{
          formattedCurrency(accReceivable.balance)
        }}</label>
      </template>

      <template v-for="(otherReceivable, idx) in data.other_receivable">
        <label class="text-left pl-3" :key="'otherReceivable-' + idx">{{
          otherReceivable.name
        }}</label>
        <label class="text-right pr-3" :key="'otherReceivableBalance-' + idx">{{
          formattedCurrency(otherReceivable.balance)
        }}</label>
      </template>

      <template v-for="(supply, idx) in data.supply">
        <label class="text-left pl-3" :key="'supply-' + idx">{{
          supply.name
        }}</label>
        <label class="text-right pr-3" :key="'supplyBalance-' + idx">{{
          formattedCurrency(supply.balance)
        }}</label>
      </template>

      <template v-for="(otherAsset, idx) in data.other_current_asset">
        <label class="text-left pl-6 grey--text" :key="'otherAsset-' + idx">{{
          otherAsset.name
        }}</label>
        <label class="text-right pr-3" :key="'otherAsseteBalance-' + idx">{{
          formattedCurrency(otherAsset.balance)
        }}</label>
      </template>
    </div>
    <div class="subtotal-style px-3">
      <label class="font-weight-bold text-left">JUMLAH ASET LANCAR</label>
      <label class="font-weight-bold text-right">{{
        formattedCurrency(data.current_asset_total)
      }}</label>
    </div>

    <div class="position-style">
      <label class="font-weight-bold text-left">ASET TIDAK LANCAR</label>
      <label class="font-weight-bold text-right"></label>
      <label class="text-left pl-3">Biaya Perolehan</label>
      <label class="text-right pr-3"></label>

      <!-- <template>
        <label class="text-left pl-6 grey--text">Tanah</label>
        <label class="text-right pr-3">Rp 10.000.000,00</label>
      </template> -->

      <label class="text-left pl-6 grey--text font-weight-bold"
        >Jumlah Biaya Perolehan</label
      >
      <label class="text-right pr-3">{{
        formattedCurrency(data.book_value_total)
      }}</label>

      <label class="text-left pl-3">Akumulasi Penyusutan</label>
      <label class="text-right pr-3"></label>

      <template v-for="(depreciation, idx) in data.accumulated_depreciation">
        <label class="text-left pl-6 grey--text" :key="'depreciation-' + idx">{{
          depreciation.name
        }}</label>
        <label class="text-right pr-3" :key="'depBalance-' + idx">{{
          formattedCurrency(depreciation.balance)
        }}</label>
      </template>
      <label class="text-left pl-6 grey--text font-weight-bold"
        >Jumlah Akumulasi Penyusutan</label
      >
      <label class="text-right pr-3">{{
        formattedCurrency(data.accumulated_depreciation_total)
      }}</label>
    </div>
    <div class="subtotal-style px-3">
      <label class="font-weight-bold text-left">JUMLAH ASET TIDAK LANCAR</label>
      <label class="font-weight-bold text-right">{{
        formattedCurrency(data.fixed_asset_total)
      }}</label>
    </div>
    <div class="subtotal-style px-3">
      <label class="font-weight-bold text-left primary--text"
        >JUMLAH ASET</label
      >
      <label class="font-weight-bold text-right primary--text">{{
        formattedCurrency(data.total_asset)
      }}</label>
    </div>

    <div class="position-style">
      <label class="font-weight-bold text-left primary--text">UTANG</label>
      <label class="font-weight-bold text-right"></label>
      <label class="font-weight-bold text-left">UTANG JANGKA PENDEK</label>
      <label class="font-weight-bold text-right"></label>

      <template v-for="(accPayable, idx) in data.account_payable">
        <label class="text-left pl-3" :key="'accPayable-' + idx">{{
          accPayable.name
        }}</label>
        <label class="text-right pr-3" :key="'accPayableBalance-' + idx">{{
          formattedCurrency(accPayable.balance)
        }}</label>
      </template>

      <label class="text-left pl-3">Utang Usaha Jangka Pendek Lainnya</label>
      <label class="text-right pr-3"></label>
      <template v-for="(shortTerm, idx) in data.short_term_debt">
        <label class="text-left pl-6 grey--text" :key="'shortTerm-' + idx">{{
          shortTerm.name
        }}</label>
        <label class="text-right pr-3" :key="'shortBalance-' + idx">{{
          formattedCurrency(shortTerm.balance)
        }}</label>
      </template>
    </div>
    <div class="subtotal-style px-3">
      <label class="font-weight-bold text-left"
        >JUMLAH UTANG JANGKA PENDEK</label
      >
      <label class="font-weight-bold text-right">{{
        formattedCurrency(data.short_term_debt_total)
      }}</label>
    </div>

    <div class="position-style">
      <label class="font-weight-bold text-left">UTANG JANGKA PANJANG</label>
      <label class="font-weight-bold text-right"></label>
      <template v-for="(longTerm, idx) in data.long_term_debt">
        <label class="text-left pl-3" :key="'longTerm-' + idx">{{
          longTerm.name
        }}</label>
        <label class="text-right pr-3" :key="'longTermBalance-' + idx">{{
          formattedCurrency(longTerm.balance)
        }}</label>
      </template>
    </div>
    <div class="subtotal-style px-3">
      <label class="font-weight-bold text-left"
        >JUMLAH UTANG JANGKA PANJANG</label
      >
      <label class="font-weight-bold text-right">{{
        formattedCurrency(data.long_term_debt_total)
      }}</label>
    </div>
    <div class="subtotal-style px-3">
      <label class="font-weight-bold text-left primary--text"
        >JUMLAH UTANG</label
      >
      <label class="font-weight-bold text-right primary--text">{{
        formattedCurrency(data.total_debt)
      }}</label>
    </div>

    <div class="position-style">
      <label class="font-weight-bold text-left primary--text">EKUITAS</label>
      <label class="font-weight-bold text-right"></label>
      <template v-for="(equity, idx) in data.equity">
        <label class="text-left pl-3" :key="'equity-' + idx">{{
          equity.name
        }}</label>
        <label class="text-right pr-3" :key="'equityBalance-' + idx">{{
          formattedCurrency(equity.balance)
        }}</label>
      </template>
    </div>
    <div class="subtotal-style px-3">
      <label class="font-weight-bold text-left">JUMLAH EKUITAS</label>
      <label class="font-weight-bold text-right">{{
        formattedCurrency(data.equity_total)
      }}</label>
    </div>

    <div class="total-style px-3">
      <label class="font-weight-bold text-left">JUMLAH UTANG DAN EKUITAS</label>
      <label class="font-weight-bold text-right">{{
        formattedCurrency(data.debt_and_equity_total)
      }}</label>
    </div>
  </v-row>
</template>

<script>
import formatMixin from '@/mixin/formatMixin';

export default {
  name: 'Standard',
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  mixins: [formatMixin],
};
</script>

<style>
.position-style {
  display: grid;
  grid-template-columns: 80fr 20fr;
}
.subtotal-style {
  background-color: #deeef9;
  height: 5vh;
  display: grid;
  grid-template-columns: 80fr 20fr;
  align-content: center;
  margin: 3px 0;
}

.total-style {
  background-color: #addaf9;
  height: 5vh;
  display: grid;
  grid-template-columns: 80fr 20fr;
  align-content: center;
  margin: 3px 0;
}
</style>
