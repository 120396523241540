<template>
  <v-card flat class="main">
    <div style="overflow: auto;">
      <v-row class="pt-2" no-gutters>
        <v-col cols="8">
          <div class="score-grid">
            <label for=""></label>
            <label class="body-2 font-weight-bold black--text">Skor 0</label>
            <label class="body-2 font-weight-bold black--text">Skor 1</label>
            <label class="body-2 font-weight-bold black--text">Skor 2</label>
            <label class="body-2 font-weight-bold black--text">Skor 3</label>

            <label class="body-2 font-weight-bold black--text text-right pr-5"
              >Kesadaran Penuh</label
            >
            <v-card
              flat
              tile
              :color="wpss.awareness === 0 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold white--text"
              :class="wpss.awareness !== 0 ? 'grey--text' : 'white--text'"
              >Sadar Penuh</v-card
            >
            <label for=""></label>
            <label for=""></label>
            <v-card
              flat
              tile
              :color="wpss.awareness === 3 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.awareness !== 3 ? 'grey--text' : 'white--text'"
              >Selain Sadar Penuh</v-card
            >

            <label class="body-2 font-weight-bold black--text text-right pr-5"
              >Tekanan Darah Sistolik</label
            >
            <v-card
              flat
              tile
              :color="wpss.blood_pressure_sistolic === 0 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="
                wpss.blood_pressure_sistolic !== 0
                  ? 'grey--text'
                  : 'white--text'
              "
              >100-140</v-card
            >
            <v-card
              flat
              tile
              :color="wpss.blood_pressure_sistolic === 1 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="
                wpss.blood_pressure_sistolic !== 1
                  ? 'grey--text'
                  : 'white--text'
              "
              >&lt;100 atau &gt;140</v-card
            >
            <v-card
              flat
              tile
              :color="wpss.blood_pressure_sistolic === 2 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="
                wpss.blood_pressure_sistolic !== 2
                  ? 'grey--text'
                  : 'white--text'
              "
              >&le;89 atau &ge;160</v-card
            >
            <label for=""></label>

            <label class="body-2 font-weight-bold black--text text-right pr-5"
              >Temperatur</label
            >
            <v-card
              flat
              tile
              :color="wpss.temperature === 0 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.temperature !== 0 ? 'grey--text' : 'white--text'"
              >36.00-37.5</v-card
            >
            <v-card
              flat
              tile
              :color="wpss.temperature === 1 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.temperature !== 1 ? 'grey--text' : 'white--text'"
              >&gt;37.5</v-card
            >
            <label for=""></label>
            <v-card
              flat
              tile
              :color="wpss.temperature === 3 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.temperature !== 3 ? 'grey--text' : 'white--text'"
            >
              &lt; 35.5</v-card
            >

            <label class="body-2 font-weight-bold black--text text-right pr-5"
              >Nadi</label
            >
            <v-card
              flat
              tile
              :color="wpss.pulse === 0 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.pulse !== 0 ? 'grey--text' : 'white--text'"
              >60-100</v-card
            >
            <v-card
              flat
              tile
              :color="wpss.pulse === 1 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.pulse !== 1 ? 'grey--text' : 'white--text'"
              >101-120</v-card
            >
            <v-card
              flat
              tile
              :color="wpss.pulse === 2 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.pulse !== 2 ? 'grey--text' : 'white--text'"
              >&gt;120 atau &lt;60</v-card
            >
            <label for=""></label>

            <label class="body-2 font-weight-bold black--text text-right pr-5"
              >Respirasi</label
            >
            <v-card
              flat
              tile
              :color="wpss.respiratory === 0 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.respiratory !== 0 ? 'grey--text' : 'white--text'"
              >16-24</v-card
            >
            <v-card
              flat
              tile
              :color="wpss.respiratory === 1 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.respiratory !== 1 ? 'grey--text' : 'white--text'"
              >25-28</v-card
            >
            <v-card
              flat
              tile
              :color="wpss.respiratory === 2 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.respiratory !== 2 ? 'grey--text' : 'white--text'"
              >&ge;25 atau &le;16</v-card
            >
            <label for=""></label>

            <label class="body-2 font-weight-bold black--text text-right pr-5"
              >Saturasi O<sub>2</sub> (%)</label
            >
            <v-card
              flat
              tile
              :color="wpss.saturation === 0 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.saturation !== 0 ? 'grey--text' : 'white--text'"
              >96-100</v-card
            >
            <v-card
              flat
              tile
              :color="wpss.saturation === 1 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.saturation !== 1 ? 'grey--text' : 'white--text'"
              >94-95</v-card
            >
            <v-card
              flat
              tile
              :color="wpss.saturation === 2 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.saturation !== 2 ? 'grey--text' : 'white--text'"
              >90-93</v-card
            >
            <v-card
              flat
              tile
              :color="wpss.saturation === 3 ? '#3498DB' : ''"
              width="153"
              height="34"
              class="pt-1 text-center font-weight-bold"
              :class="wpss.saturation !== 3 ? 'grey--text' : 'white--text'"
            >
              &lt; 90</v-card
            >

            <label class="body-2 font-weight-bold black--text text-right pr-5"
              >Total :</label
            >
            <label class="text-left">{{ totalSum }}</label>
          </div>
        </v-col>
        <v-col cols="4" class="text-left pt-3">
          <label class="body-2 font-weight-bold black--text"
            >Catatan Khusus</label
          >
          <v-textarea
            placeholder="Deskripsi..."
            outlined
            rows="10"
            class="pt-3"
            dense
            v-model="wpss.note"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row no-gutters align="center">
        <v-col cols="2" class="text-right px-6" style="max-width: 150px;">
          <label class="body-2 font-weight-bold black--text">Keputusan</label>
        </v-col>
        <v-col
          cols="2"
          class="text-left"
          v-if="wpss.decision === 'ruang resusitasi'"
        >
          <v-btn
            color="#F65555"
            tile
            elevation="0"
            class="white--text text-none"
            >Ruang Resusitasi</v-btn
          >
        </v-col>
        <v-col
          cols="3"
          class="text-left"
          style="max-width:240px;"
          v-if="wpss.decision === 'ruang non-resusitasi'"
        >
          <v-btn
            color="#F2C94C"
            tile
            elevation="0"
            class="white--text text-none"
            >Ruang Non-Resusitasi</v-btn
          >
        </v-col>
        <v-col
          cols="2"
          class="text-left"
          style="max-width:170px;"
          v-if="wpss.decision === 'klinik umum'"
        >
          <v-btn
            color="#27AE60"
            tile
            elevation="0"
            class="white--text text-none"
            >Klinik Umum</v-btn
          >
        </v-col>
        <v-col
          cols="2"
          class="text-left"
          v-if="wpss.decision === 'death on arrival'"
        >
          <v-btn color="black" tile elevation="0" class="white--text text-none"
            >Death On Arrival</v-btn
          >
        </v-col>
      </v-row>
    </div>

    <v-card-actions class="px-0 footer">
      <v-btn text class="text-none" @click="$emit('close-form')">
        Kembali
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        tile
        elevation="0"
        width="153"
        class="text-none"
        @click="addTriase()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment-timezone';
const _ = require('lodash');

import Constant from '@/const';
import Swal from 'sweetalert2';
const axios = require('axios');

export default {
  name: 'WPSS',
  props: {
    triaseLevel: {
      type: Object,
    },
    wpss: {
      type: Object,
    },
    triaseNumber: {
      type: Number,
    },
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      score: {
        awareness: 0,
        blood_pressure_sistolic: 0,
        pulse: 0,
        temperature: 0,
        respiratory: 0,
        saturation: 0,
      },
      totalSum: 0,
    };
  },
  mounted() {
    const triase = this.triaseLevel;
    this.score = {
      awareness: _.get(triase, 'awareness', 0),
      blood_pressure_sistolic: _.get(triase, 'sistole', 0),
      pulse: _.get(triase, 'pulse', 0),
      temperature: _.get(triase, 'temperature', 0),
      respiratory: _.get(triase, 'respiration', 0),
      saturation: _.get(triase, 'saturation', 0),
    };
    this.count();
  },
  methods: {
    count() {
      if (this.score.awareness === 1) {
        this.wpss.awareness = 0;
      } else {
        this.wpss.awareness = 3;
      }

      if (
        this.score.blood_pressure_sistolic <= 89 ||
        this.score.blood_pressure_sistolic >= 160
      ) {
        this.wpss.blood_pressure_sistolic = 2;
      } else if (
        this.score.blood_pressure_sistolic >= 100 &&
        this.score.blood_pressure_sistolic <= 140
      ) {
        this.wpss.blood_pressure_sistolic = 0;
      } else {
        this.wpss.blood_pressure_sistolic = 1;
      }

      if (this.score.temperature >= 36 && this.score.temperature <= 37.5) {
        this.wpss.temperature = 0;
      } else if (this.score.temperature > 37.5) {
        this.wpss.temperature = 1;
      } else {
        this.wpss.temperature = 3;
      }

      if (this.score.pulse >= 60 && this.score.pulse <= 100) {
        this.wpss.pulse = 0;
      } else if (this.score.pulse >= 101 && this.score.pulse <= 120) {
        this.wpss.pulse = 1;
      } else {
        this.wpss.pulse = 2;
      }

      if (this.score.respiratory >= 16 && this.score.respiratory <= 24) {
        this.wpss.respiratory = 0;
      } else if (this.score.respiratory >= 25 && this.score.respiratory <= 28) {
        this.wpss.respiratory = 1;
      } else {
        this.wpss.respiratory = 2;
      }

      if (this.score.saturation >= 96 && this.score.saturation <= 100) {
        this.wpss.saturation = 0;
      } else if (this.score.saturation >= 94 && this.score.saturation <= 95) {
        this.wpss.saturation = 1;
      } else if (this.score.saturation >= 90 && this.score.saturation <= 93) {
        this.wpss.saturation = 2;
      } else {
        this.wpss.saturation = 3;
      }

      this.totalSum =
        this.wpss.awareness +
        this.wpss.blood_pressure_sistolic +
        this.wpss.pulse +
        this.wpss.temperature +
        this.wpss.respiratory +
        this.wpss.saturation;

      if (this.totalSum >= 0 && this.totalSum <= 2) {
        this.wpss.decision = 'klinik umum';
      } else if (this.totalSum >= 3 && this.totalSum <= 6) {
        this.wpss.decision = 'ruang non-resusitasi';
      } else if (this.totalSum >= 7 && this.totalSum <= 9) {
        this.wpss.decision = 'ruang resusitasi';
      } else {
        this.wpss.decision = 'death on arrival';
      }
    },
    addTriase() {
      const payload = {
        id_patient: null,
        id_registration: null,
        id_poly: null,
        triase_number: this.triaseNumber,
        detail_triase: [
          {
            filled_at: moment(new Date())
              .locale('id')
              .format(),
            triase_level: {
              name: this.triaseLevel.name,
              gender: this.triaseLevel.gender,
              person_in_charge: this.triaseLevel.pic,
              address: this.triaseLevel.address,
              parent_name: this.triaseLevel.parentsName,
              pacs: this.triaseLevel.pacs,
              awareness: this.triaseLevel.awareness,
              blood_pressure_sistole: this.triaseLevel.sistole,
              blood_pressure_diastole: this.triaseLevel.diastole,
              temperature: this.triaseLevel.temperature,
              pulse: this.triaseLevel.pulse,
              saturation: this.triaseLevel.saturation,
              respiratory: this.triaseLevel.respiration,
              pain: this.triaseLevel.pain,
              weight_body: this.triaseLevel.weight,
              height_body: this.triaseLevel.height,
            },
            wpss: this.wpss,
            triase: {
              ...this.triase,
              trauma_mechanism: {
                ...this.triase?.trauma_mechanism,
                icd_10: this.triase?.trauma_mechanism?.icd_10?._id,
              },
            },
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          },
        ],
      };

      axios
        .post(Constant.apiUrl.concat('/patient/igd/triase'), payload)
        .then(() => {
          if (this.patientData !== null) {
            Swal.fire('Triase Berhasil diperbaharui', '', 'success');
            this.$emit('close-form');
          } else {
            Swal.fire('Triase Berhasil didaftarkan', '', 'success');
            this.$emit('close-form');
          }
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.score-grid {
  display: grid;
  grid-row-gap: 15px;
  grid-template-columns: 150px repeat(4, 150px);
  align-items: center;
}
.main {
  position: relative;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: -20vh;
}
</style>
