<template>
  <v-expansion-panels class="health-panel" v-model="expand" accordion flat>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <label>STATUS KESEHATAN</label>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="py-3 px-6">
        <div
          v-for="(item, index) in show"
          :key="`health-status-${index}`"
          class="d-flex justify-space-between my-2"
        >
          <p class="ma-0 pa-0">{{ item.label }}</p>
          <label v-if="!item.isChip" :class="{ 'text-capitalize': !index }">{{
            item.result
          }}</label>
          <div v-else :class="`ml-3 health-panel-chip-${item.result?.color}`">
            {{ item.result?.text }}
            <div v-if="!item.result">-</div>
          </div>
        </div>
        <v-divider light class="my-3"></v-divider>
        <!-- fall risk -->
        <div class="d-flex justify-space-between my-2">
          <p class="ma-0 pa-0">{{ fallRiskData.label }}</p>
          <div :class="`ml-3 health-panel-chip-${fallRiskData.result?.color}`">
            {{ fallRiskData.result?.text }}
            <div v-if="!fallRiskData.result">-</div>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'HealthStatus',
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      expand: 0,
      fallRiskData: '',
      show: [],
    };
  },
  mounted() {
    this.fallRiskData = this.data.slice(-1)[0];
    this.show = this.data.slice(0, -1);
  },
};
</script>

<style scoped lang="scss">
$font-standard-size: 0.8vw;

@mixin result-chip($fill, $outline) {
  background-color: $fill;
  border: solid $outline 1.5px;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 6px;
  color: $outline !important;
  font-weight: 450;
  padding: 0.3rem 0.5rem;
  width: max-content;
  max-height: 2rem;
  font-size: calc($font-standard-size - 0.2vw) !important;
}
.health-panel {
  width: 100%;

  label {
    font-weight: 500;
  }

  #{&}-chip-green {
    @include result-chip(#e5fff0, #2d965a);
  }
  #{&}-chip-yellow {
    @include result-chip(#fff9e5, #e5a82e);
  }
  #{&}-chip-light-yellow {
    @include result-chip(#ffffff, #d1d600);
  }
  #{&}-chip-red {
    @include result-chip(#fff2f2, #eb4747);
  }
  #{&}-chip-grey {
    @include result-chip(#f6f6f6, #928f8f);
  }
}
</style>
